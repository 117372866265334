import Constants from '../../../../config/Constants';
import { formatData, parseBlockData } from '../../../global/BlockLayoutHelper';
import _ from 'lodash';
import HelperFunctions from '../../../global/HelperFunctions';

export function getAreaBlocks(area, variantsWithData = []) {
    const { freeForm, freeFormLabel, categoryId } = area;
    const allBlocks = [];

    variantsWithData.forEach(({ blockData = [], variant }) => {
        if (categoryId === 10) {
            const description =
                area.areaBlocks.length === 1
                    ? area.areaBlocks[0].baseContent
                    : '<p>Dit informatiedocument geeft alleen een samenvatting van de verzekering. In de [PolisvoorwaardenLink] staat uitgebreid waarvoor iemand wel en niet is verzekerd.</p>';

            allBlocks.push({
                description,
                descriptionExtra: '',
                id: 0,
                name: 'over-deze-kaart',
                type: 0,
                variantId: parseInt(variant[2]),
            });
            return;
        }

        blockData
            .filter((block) => {
                if (freeForm) {
                    return (
                        block.categoryId === categoryId &&
                        freeFormLabel.names.includes(block.name) &&
                        block.title.toLowerCase() === area.title.toLowerCase() &&
                        freeFormLabel.types.includes(block.type)
                    );
                }

                return block.categoryId === categoryId && block.name.startsWith('vrijerubriek') === false;
            })
            .forEach((block) => {
                allBlocks.push({
                    ...block,
                    variantId: parseInt(variant[2]),
                });
            });
    });

    const filteredBlocks = freeForm ? allBlocks : filterBlocks(allBlocks, area);

    return createAreaBlocks(area, filteredBlocks);
}

export function filterBlocks(blockData = [], area) {
    const { categoryId, title } = area;
    const label = area.label.name;
    const areaTitle = title.toLowerCase();

    if (categoryId === 1) {
        return blockData.filter((block) => block.type === 0);
    }

    if (categoryId === 2) {
        if (label === 'wat-is-verzekerd') {
            return blockData.filter((block) => block.type === 0 && block.position === 0);
        }

        if (label === 'wat-is-verzekerd-dekking') {
            return blockData.filter(
                (block) => (block.type === 0 || block.type === 1) && block.title.toLowerCase() === areaTitle
            );
        }

        if (label === 'wat-is-niet-verzekerd-dekking') {
            return blockData.filter((block) => block.type === 2 && block.title.toLowerCase() === areaTitle);
        }

        return [];
    }

    if (categoryId === 3) {
        if (label === 'wat-is-niet-verzekerd') {
            return blockData.filter(
                (block) =>
                    (block.type === 0 && (block.name === 'wanneer-niet' || block.name === 'wat-niet-verzekerd')) ||
                    (block.type === 2 && (block.name === 'wanneer-niet' || block.name === 'wat-niet-verzekerd'))
            );
        }

        if (label === 'wat-is-verzekerd-dekking') {
            return blockData.filter(
                (block) => (block.type === 0 || block.type === 1) && block.title.toLowerCase() === areaTitle
            );
        }

        if (label === 'wat-is-niet-verzekerd-dekking') {
            return blockData.filter(
                (block) =>
                    (block.type === 0 || block.type === 2 || block.type === 4) &&
                    block.title.toLowerCase() === areaTitle
            );
        }

        return [];
    }

    if (categoryId === 4) {
        if (label === 'zijn-er-dekkingsbeperkingen') {
            return blockData.filter(
                (block) =>
                    ((block.type === 0 || block.type === 2) && block.position === 0) ||
                    (block.type === 1 && block.name === 'let-op' && block.position === 0)
            );
        }

        if (label === 'zijn-er-dekkingsbeperkingen-detail') {
            return blockData.filter(
                (block) =>
                    (block.type === 0 || block.type === 2 || block.type === 4) &&
                    block.title.toLowerCase() === areaTitle
            );
        }

        return [];
    }

    if (categoryId === 5) {
        return blockData.filter((block) => block.type === 0 && block.position === 0);
    }

    if (categoryId === 6) {
        if (label === 'wat-zijn-mijn-verplichtingen') {
            return blockData.filter(
                (block) =>
                    block.type === 0 &&
                    (block.name === 'gezondheid' || block.name === 'medisch' || block.name === 'verplichtingen')
            );
        }

        if (label === 'wat-zijn-mijn-verplichtingen-detail') {
            return blockData.filter((block) => block.type === 4 && block.name === 'gezondheid');
        }

        return [];
    }

    if (categoryId === 7) {
        if (label === 'hoe-en-wanneer-betaal-ik') {
            return blockData.filter(
                (block) => block.type === 0 && (block.name === 'premie' || block.name === 'hoe-betaal-ik')
            );
        }

        if (label === 'hoe-en-wanneer-betaal-ik-detail') {
            return blockData.filter(
                (block) =>
                    (block.type === 0 || block.type === 1 || block.type === 2 || block.type === 4) &&
                    (block.name === 'premiekorting' ||
                        block.name === 'beloning' ||
                        block.name === 'stijging' ||
                        block.name === 'premie' ||
                        block.name === 'premie-hoogte') &&
                    block.title.toLowerCase() === areaTitle
            );
        }

        return [];
    }

    if (categoryId === 8) {
        return blockData.filter(
            (block) =>
                block.type === 0 &&
                (block.name === 'afsluiten' || block.name === 'looptijd' || block.name === 'wanneer')
        );
    }

    if (categoryId === 9) {
        return blockData.filter(
            (block) => block.type === 0 && (block.name === 'opzeggen' || block.name === 'afsluiten')
        );
    }

    if (categoryId === 10) {
        return blockData;
    }

    return [];
}

export function createAreaBlocks(area, filteredBlocks = []) {
    const { areaBlocks = [] } = area;
    const blocksToAdd = [];
    const combinedBlocks = combineBlocks(filteredBlocks);

    // Only 1 block found, simple text block
    if (areaBlocks.length === 1) {
        blocksToAdd.push(
            ...combinedBlocks.map((block, index) =>
                createBlock(block.description, block.variantIds, blocksToAdd, areaBlocks[index], block)
            )
        );
    } else {
        const layoutBlocks = areaBlocks.filter((block) => block.type === Constants.blockTypes.blockLayout);

        if (layoutBlocks.length === 1) {
            combinedBlocks.forEach((eiopaBlock) => {
                const defaultBlock = areaBlocks.find(
                    (block) => block.type === Constants.blockTypes.text && block.properties.textLayout === 'default'
                );
                const infoBlock = areaBlocks.find(
                    (block) => block.type === Constants.blockTypes.text && block.properties.textLayout === 'info'
                );

                const newBlock1 = createBlock(
                    eiopaBlock.description,
                    eiopaBlock.variantIds,
                    blocksToAdd,
                    defaultBlock,
                    eiopaBlock
                );
                const newBlock2 = createBlock(
                    eiopaBlock.descriptionExtra,
                    eiopaBlock.variantIds,
                    blocksToAdd,
                    infoBlock,
                    eiopaBlock
                );
                const newBlock3 = createBlock(
                    parseBlockData(formatData([[[{ id: newBlock1.key }]], [[{ id: newBlock2.key }]]])),
                    eiopaBlock.variantIds,
                    blocksToAdd,
                    layoutBlocks[0],
                    eiopaBlock
                );

                blocksToAdd.push(newBlock1, newBlock2, newBlock3);
            });
        }
    }

    return blocksToAdd;
}

const createBlock = (content, variantIds, entities = [], parent = undefined, eiopaBlock) => {
    return {
        id: null,
        key: generateKey(entities),
        type: parent?.type ?? Constants.blockTypes.text,
        eiopaType: eiopaBlock.type,
        eiopaId: eiopaBlock.id,
        eiopaIds: eiopaBlock.eiopaIds,
        baseContent: content,
        exportProperties: parent?.exportProperties ?? {},
        sortOrder: parent?.sortOrder ?? 0,
        variantIds,
        properties: parent
            ? {
                  ...parent.properties,
                  parentId: parent.id,
              }
            : {},
    };
};

export const generateKey = (entities = []) => {
    const randomKey = Math.floor(Math.random() * 9999) + 1;

    entities.forEach((entity) => {
        if (entity.key === randomKey) {
            return generateKey(entities);
        }
    });

    return randomKey;
};

const combineBlocks = (blocks) => {
    const allBlocks = blocks.map((block) => ({
        ...block,
        variantIds: [],
        eiopaIds: [],
    }));

    return allBlocks.reduce(function (o, block) {
        // Get the index of the key-value pair.
        const occurs = o.reduce(function (n, item, i) {
            return item.description === block.description && item.descriptionExtra === block.descriptionExtra ? i : n;
        }, -1);

        // If the baseContent matches
        if (occurs >= 0) {
            // append the variantId to its list of variantIds
            o[occurs].variantIds = o[occurs].variantIds.concat(block.variantId);
            o[occurs].eiopaIds = o[occurs].eiopaIds.concat(block.id);
        } else {
            // add the current block to o
            o = o.concat([
                {
                    ...block,
                    variantIds: [block.variantId],
                    eiopaIds: [block.id],
                },
            ]);
        }

        return o;
    }, []);
};

export function cleanDescription(content) {
    const trimmed = _.trim(
        content
            .replace('<br>', '')
            .replace(/<p>\s*<\/p>/g, '') // empty <p> elements
            .replace(/\s+/g, ' ') // replace all whitespace characters with normal space
            .replace('. </p>', '.</p>') // whitespace just before closing </p>
    );

    // Check if content is wrapped in <p> tag
    if (trimmed !== '' && trimmed.startsWith('<p>') === false) {
        return `<p>${trimmed}</p>`;
    }

    return trimmed;
}

export function cleanTitle(content) {
    // Trim spaces and remove dot from end of string
    return _.trim(content.replace(/\.$/, ''));
}

export function getCategoryId(label, parentLabel) {
    if (label === 'welk-soort-verzekering-is-dit') {
        return 1;
    }

    if (label === 'wat-is-verzekerd' || parentLabel === 'wat-is-verzekerd') {
        return 2;
    }

    if (label === 'wat-is-niet-verzekerd' || parentLabel === 'wat-is-niet-verzekerd') {
        return 3;
    }

    if (label === 'zijn-er-dekkingsbeperkingen' || parentLabel === 'zijn-er-dekkingsbeperkingen') {
        return 4;
    }

    if (label === 'waar-ben-ik-gedekt') {
        return 5;
    }

    if (label === 'wat-zijn-mijn-verplichtingen' || parentLabel === 'wat-zijn-mijn-verplichtingen') {
        return 6;
    }

    if (label === 'hoe-en-wanneer-betaal-ik' || parentLabel === 'hoe-en-wanneer-betaal-ik') {
        return 7;
    }

    switch (label) {
        case 'wanneer-begint-eindigt-dekking':
            return 8;
        case 'hoe-zeg-ik-mijn-contract-op':
            return 9;
        case 'over-deze-kaart':
            return 10;
    }
}

export function areaHasCustomPrefix(area) {
    const canChangeCustomPrefix = _.get(area, 'properties.templateParameters.canChangeCustomPrefix', false);
    const prefixOptions = _.get(area, 'properties.templateParameters.prefixOptions', '');
    const hasPrefixBlocks = area.blocks.some((block) => block.eiopaType === 1);

    return canChangeCustomPrefix && prefixOptions.includes('Keuze:') && hasPrefixBlocks;
}

export const getBlockIdsInLayout = (blocks = []) => {
    let allBlockKeys = [];

    for (const block of blocks) {
        if (block.type === Constants.blockTypes.blockLayout) {
            const blockIds = getBlockLayoutBlockIds(block);
            allBlockKeys.push(...blockIds);
        }
    }

    return allBlockKeys;
};

export const getBlockLayoutBlockIds = (block) => {
    const blockKeys = [];
    const currentBlockData = HelperFunctions.tryParseJSON(block.baseContent);

    if (currentBlockData && currentBlockData.hasOwnProperty('data')) {
        currentBlockData.data.forEach((_row) => {
            _row.forEach((_column) => {
                _column.forEach((_item) => {
                    blockKeys.push(_item.id);
                });
            });
        });
    }

    return blockKeys;
};

export const getParentArea = (areaId, areas = []) => {
    let parent;

    for (const area of areas) {
        if (area.exportProperties.headingLevel === '2') {
            parent = area;
        }

        if (area.id === areaId) {
            return parent;
        }
    }

    return undefined;
};

export const getChoiceVariantIds = (variantsWithData = [], blocks = []) => {
    const variantIds = [];

    variantsWithData.forEach(({ variant }) => {
        const variantId = parseInt(variant[2]);
        const variantBlocks = blocks.filter((block) => {
            return block.variantIds.includes(variantId);
        });

        if (variantBlocks.some((block) => block.eiopaType === 1)) {
            variantIds.push(variantId);
        }
    });

    return variantIds;
};

export const removeInvisibleSpaces = (value) => {
    return value.replace(/\u200B/g, '');
};
