import { useGetLabelEntitiesQuery } from 'features/metadata/metadata';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';

const emptyArray = [];

export function useGetLabelEntities(entityType, entityId) {
    const organisationId = useActiveOrganisation();

    return useGetLabelEntitiesQuery(
        {
            entityType,
            entityId,
            organisationId,
        },
        {
            selectFromResult: ({ data, isLoading, isUninitialized }) => ({
                entityLabelEntities: data ?? emptyArray,
                isLoading,
                isUninitialized,
            }),
        },
    );
}
