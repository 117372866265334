import { useContext, useMemo, useState } from 'react';
import { ImportContext } from '../IpidImport';
import { diffWords } from 'diff';
import striptags from 'striptags';
import Parser from 'html-react-parser';
import BasicEditor from './BasicEditor';

export const HtmlEditor = ({ id, value = '', areaKey, parentBlock, area, parentId }) => {
    const { updateAreaBlockContent } = useContext(ImportContext);
    const [showEditor, setShowEditor] = useState(false);

    const handleChange = (event) => {
        updateAreaBlockContent(areaKey, id, event.target.value);
    };

    if (parentBlock && !showEditor) {
        const allBlocks = area.blocks.filter((_block) => _block.properties.parentId === parentId);

        if (allBlocks.length > 1) {
            return (
                <DiffRender blockId={id} allBlocks={allBlocks} value={striptags(value)} setShowEditor={setShowEditor} />
            );
        }
    }

    return (
        <BasicEditor
            showRef={false}
            onChange={handleChange}
            value={value}
            id={`jsTinymceEditor-${id}`}
            name={`jsTinymceEditor-${id}`}
            showEditor={showEditor}
            setShowEditor={setShowEditor}
        />
    );
};

function DiffRender({ blockId, allBlocks = [], value = '', setShowEditor }) {
    const diffText = useMemo(() => {
        const localBlocks = allBlocks.filter((_block) => _block.key !== blockId);
        let baseContent = striptags(localBlocks[0].baseContent);
        let diffContent = '';

        if (localBlocks.length > 1) {
            localBlocks.forEach((_block, index) => {
                if (index > 0) {
                    const diff = diffWords(baseContent, striptags(_block.baseContent));

                    diff.forEach((part) => {
                        if (!part.added) {
                            diffContent = diffContent + part.value;
                        }
                    });
                }
            });
        } else {
            diffContent = baseContent;
        }

        let text = '';
        const diff = diffWords(diffContent, value);

        diff.forEach((part) => {
            if (part.added) {
                text = text + `<ins>${part.value}</ins>`;
            } else if (part.removed) {
                text = text + `<del>${part.value}</del>`;
            } else {
                text = text + part.value;
            }
        });

        return text;
    }, [value, allBlocks]);

    return (
        <div
            className="area-text-block basic-editor-text-block area-text-block-no-editor"
            onClick={() => setShowEditor(true)}
        >
            <div className="mce-content-body">{Parser(`<p>${diffText}</p>`)}</div>
        </div>
    );
}
