import _ from 'lodash';
import { useGetOrganisationModules } from 'hooks/useGetOrganisationModules';
import { useUserProfile } from 'hooks/useUserProfile';
import Constants from 'config/Constants';

export default function RestrictedContent({ module = '', permission = '', children }) {
    const userPermissions = useCurrentPermissions();

    if (!hasModuleEnabled(module, userPermissions) || !hasPermission(permission, userPermissions)) {
        return null;
    }

    return <>{children}</>;
}

const hasPermission = (permission, userPermissions) => {
    const permissions = _.isString(permission) ? [permission] : permission;

    return permissions.some((_permission) => hasPermissions(_permission, userPermissions));
};

const hasPermissions = (permission, userPermissions) => {
    if (permission === '') {
        return true;
    }

    // Sample object: ['Document', 'Write', 'All']
    const permissionObject = permission.split('.');

    if (permissionObject.length > 3 || permissionObject.length <= 1) {
        // Not supported
        return false;
    }
    return userPermissions['permissions']?.some((_permissions) => {
        // Sample object: ['Document', 'ReadWrite', 'Shared']
        const userPermissionObject = _permissions.split('.');

        if (userPermissionObject.length > 3 || userPermissionObject.length <= 1) {
            // Not supported
            return false;
        }

        if (userPermissionObject[0] !== permissionObject[0]) {
            return false;
        }

        if (userPermissionObject[1] !== permissionObject[1]) {
            return false;
        }

        // No third argument needed to check
        if (!(2 in permissionObject)) {
            return true;
        }

        // User permission object doesn't have a third argument
        if (!(2 in userPermissionObject)) {
            return false;
        }

        return userPermissionObject[2] === permissionObject[2];
    });
};

const hasModuleEnabled = (module, userPermissions) => {
    if (module === '') {
        return true;
    }

    return userPermissions.modules.includes(module);
};

function useCurrentPermissions() {
    const userProfile = useUserProfile();
    const modules = useGetOrganisationModules();

    return {
        permissions: userProfile?.permissions ?? [],
        modules,
    };
}

export function useHasPermission(permission) {
    const userPermissions = useCurrentPermissions();

    return hasPermissions(permission, userPermissions);
}

export function useHasModuleEnabled(module) {
    const userPermissions = useCurrentPermissions();

    return hasModuleEnabled(module, userPermissions);
}

export function useGetUserModules() {
    const userPermissions = useCurrentPermissions();
    const modules = useGetOrganisationModules();

    return modules.filter((module) => {
        if (!hasModuleEnabled(module, userPermissions)) {
            return false;
        }

        const modulePermission = Constants.permissions.menu[module];

        return modulePermission ? hasPermission(modulePermission, userPermissions) : false;
    });
}
