import { Modal } from 'react-bootstrap';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { InputSelect } from 'pages/publications_v2/helpers/FieldHelper';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { ModalFooter } from 'pages/global/FormModal';
import { useAddTeamUserMutation } from 'features/comments/commentApi';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { useParams } from 'react-router-dom';
import { useGetTeamUsers } from 'pages/task_team/hooks/useGetTeamUsers';
import { useGetAvailableUsers } from 'pages/task_team/hooks/useGetAvailableUsers';

export function AddTeamUserModal({ team }) {
    const [addTeamUser, setAddTeamUser] = useQueryParam('addTeamUser', BooleanParam);
    const organisationId = useActiveOrganisation();

    if (!addTeamUser) {
        return null;
    }

    return (
        <AddModalInner
            team={team}
            teamUser={{
                organisationId,
                userId: '',
            }}
            close={close}
        />
    );

    function close() {
        setAddTeamUser(undefined);
    }
}

export function AddModalInner({ team, teamUser, close }) {
    const { t } = useTranslation();
    const [addTeamUser] = useAddTeamUserMutation();
    const { id } = useParams();
    const teamUsers = useGetTeamUsers(id);
    const allAvailableUsers = useGetAvailableUsers();

    const teamUserUserIds = teamUsers.map((teamUser) => teamUser.userId);
    const availableUsers = allAvailableUsers.filter((user) => teamUserUserIds.includes(user.id) === false);

    return (
        <>
            <Modal show={true} onHide={close}>
                <Modal.Header closeButton>
                    <Modal.Title>Gebruiker toevoegen</Modal.Title>
                </Modal.Header>
                <Formik initialValues={teamUser} onSubmit={handleSubmit} validationSchema={validationSchema}>
                    {({ isValid, isSubmitting, dirty }) => {
                        return (
                            <Form>
                                <Modal.Body>
                                    <InputSelect
                                        name="userId"
                                        options={availableUsers}
                                        props={{
                                            placeholder: 'Selecteer een gebruiker...',
                                        }}
                                    />
                                </Modal.Body>

                                <ModalFooter
                                    onHide={close}
                                    isSubmitting={isSubmitting}
                                    dirty={dirty}
                                    isValid={isValid}
                                />
                            </Form>
                        );
                    }}
                </Formik>
            </Modal>
        </>
    );

    function handleSubmit(values) {
        addTeamUser({
            ...values,
            team: team['@id'],
        }).then(() => {
            close();
        });
    }
}

const validationSchema = Yup.object().shape({
    userId: Yup.string().required(),
});
