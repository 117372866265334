import { MutableRefObject, useEffect, useRef, useState } from 'react';

export const useHeight = <T extends Element>(): [number, MutableRefObject<T | null>] => {
    const ref = useRef<T>(null);
    const [rect, setRect] = useState<DOMRect>();

    const set = () => setRect(ref.current?.getBoundingClientRect());

    const useEffectInEvent = (event: string, useCapture = false) => {
        useEffect(() => {
            set();
            window.addEventListener(event, set, useCapture);
            return () => window.removeEventListener(event, set, useCapture);
        }, []);
    };

    useEffectInEvent('resize');
    useEffectInEvent('scroll', true);

    return [Math.ceil(rect?.height ?? 0), ref];
};
