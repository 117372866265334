import { useCallback } from 'react';
import { Badge, Dropdown, DropdownButton } from 'react-bootstrap';
import HelperFunctions from '../global/HelperFunctions';
import { Checkbox } from '../publications_v2/helpers/FieldHelper';
import cx from 'classnames';
import { useGetPublicationUsersForGroup, useGetUserGroups } from '../publications_v2/hooks/publicationUsers';
import { useDeleteUserGroupMutation } from 'features/publications/publicationApi';
import { useTranslation } from 'react-i18next';
import { BooleanParam, NumberParam, useQueryParam } from 'use-query-params';
import { LightOutlineButton, NewItemButton } from 'components/Buttons';
import { AddGroupModal } from 'pages/publicationUsers/modals/AddGroupModal';
import { generatePath, Link } from 'react-router-dom';
import { USER_GROUPS_PATH } from 'scenes/PublicationsV2';
import { EditGroupModal } from 'pages/publicationUsers/modals/EditGroupModal';
import { SelectAllCheckbox } from 'components/SelectAllCheckbox';
import { DeleteButton, EditButton } from 'components/ActionButtons';
import { PaginatedTable, TableProvider, useSelectedItems, useSelectedItemsDispatch } from 'components/PaginatedTable';
import { ArrowDownShort, CloudDownload } from 'react-bootstrap-icons';
import PublicationApi from 'api/PublicationApi';
import { saveAs } from 'file-saver';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';

export default function PublicationGroups() {
    return (
        <TableProvider>
            <RenderGroups />
        </TableProvider>
    );
}

function RenderGroups() {
    const { t } = useTranslation('publications');
    const [, setAddGroup] = useQueryParam('addGroup', BooleanParam);
    const [, setEditGroupId] = useQueryParam('egid', NumberParam);
    const organisationId = useActiveOrganisation();

    const editGroup = useCallback((id) => {
        setEditGroupId(id);
    }, []);

    return (
        <>
            <AddGroupModal />
            <EditGroupModal />

            <div className="dr-container p-4">
                <div className="subheader">
                    <h3>{t('accessControl.userGroups')}</h3>

                    <div className="d-flex align-items-center ml-auto">
                        <LightOutlineButton onClick={handleDownload}>
                            <CloudDownload />
                            <div>{t('global:btn.export')}</div>
                        </LightOutlineButton>
                        <NewItemButton label={t('accessControl.newUserGrpBtn')} onClick={() => setAddGroup(true)} />
                    </div>
                </div>

                <GroupsList editGroup={editGroup} />
            </div>
        </>
    );

    function handleDownload() {
        PublicationApi.exportPublicationUserGroups({ organisationId })
            .then((response) => {
                saveAs(response.data, response.headers['x-suggested-filename'] ?? 'export.xlsx');
            })
            .catch(async (error) => {
                if (error.response.status === 400) {
                    const response = await error.response.data.text();
                    const responseObject = HelperFunctions.tryParseJSON(response);
                    HelperFunctions.alertModal(t('global:error.errorTitle', { title: responseObject.error }));
                } else {
                    HelperFunctions.alertModal(t('global:error.exportFailed'));
                }
            });
    }
}

function GroupsList({ editGroup }) {
    const { t } = useTranslation();
    const userGroups = useGetUserGroups();
    const { selectedItems = [] } = useSelectedItems();

    return (
        <PaginatedTable
            items={userGroups}
            searchPlaceholder={t('publications:accessControl.searchGroupPlaceholder')}
            colSpan={5}
            actionsButton={<Actions />}
        >
            {({ items = [] }) => (
                <>
                    <thead>
                        <tr>
                            <th>
                                <SelectAllCheckbox items={items} />
                            </th>
                            <th>
                                {t('publications:accessControl.headers.name')} <ArrowDownShort />
                            </th>
                            <th>{t('publications:accessControl.headers.emailDomains')}</th>
                            <th>#&nbsp;{t('publications:accessControl.users')}</th>
                            <th>{t('global:btn.actions')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((group) => (
                            <UserGroup
                                group={group}
                                isSelected={selectedItems.includes(group['@id'])}
                                editGroup={editGroup}
                                key={`group-${group.id}`}
                            />
                        ))}
                    </tbody>
                </>
            )}
        </PaginatedTable>
    );
}

function UserGroup({ group, isSelected = false, editGroup }) {
    const { t } = useTranslation();
    const [deleteUserGroup] = useDeleteUserGroupMutation();
    const users = useGetPublicationUsersForGroup(group);
    const dispatch = useSelectedItemsDispatch();

    return (
        <tr className={cx({ 'row-selected': isSelected })}>
            <td>
                <Checkbox
                    id={`group-checkbox-${group.id}`}
                    checked={isSelected}
                    onChange={() => {
                        dispatch({
                            type: 'toggle',
                            id: group['@id'],
                        });
                    }}
                />
            </td>
            <td>
                <Link
                    to={generatePath(USER_GROUPS_PATH, {
                        page: 'view-group',
                        id: group.id,
                    })}
                >
                    {group.name}
                </Link>
            </td>
            <td>
                {group.allowedEmailDomains.length > 0
                    ? group.allowedEmailDomains.map((domain, index) => (
                          <div key={`group-${group.id}-domain-${index}`}>{domain}</div>
                      ))
                    : '-'}
            </td>
            <td>
                <Badge variant="primary">{users.length}</Badge>
            </td>
            <td className="actions">
                <div>
                    <EditButton onClick={() => editGroup(group.id)} />
                    <DeleteButton onClick={handleGroupDelete} />
                </div>
            </td>
        </tr>
    );

    function handleGroupDelete() {
        HelperFunctions.confirmModal(
            t('global:confirm.confirmDelete', { name: group.name }),
            'danger',
            false,
            t('global:confirm.yesDelete'),
            t('global:btn.cancel')
        )
            .then(() => {
                deleteUserGroup(group['@id']);
            })
            .catch(() => {});
    }
}

function Actions() {
    const { t } = useTranslation('publications');
    const { selectedItems = [] } = useSelectedItems();
    const [deleteUserGroup] = useDeleteUserGroupMutation();
    const dispatch = useSelectedItemsDispatch();

    return (
        <div className="d-flex align-items-center">
            <DropdownButton
                id="actions-dropdown"
                title={t('accessControl.actions')}
                variant="primary"
                disabled={selectedItems.length === 0}
                size="sm"
            >
                <Dropdown.Item onClick={deleteCheckedGroups}>
                    <span className="text-danger">{t('btn.delete')}&hellip;</span>
                </Dropdown.Item>
            </DropdownButton>

            <div className="text-muted small ml-2">
                {selectedItems.length > 0 && (
                    <>{t('publications:accessControl.numGroupsSelected', { count: selectedItems.length })}</>
                )}
            </div>
        </div>
    );

    function deleteCheckedGroups() {
        HelperFunctions.confirmModal(
            t('publications:accessControl.confirmGroupDeleteCount', { count: selectedItems.length }),
            'danger',
            false,
            t('global:confirm.yesDelete'),
            t('global:btn.cancel')
        )
            .then(() => {
                Promise.all(selectedItems.map((groupId) => deleteUserGroup(groupId))).then(() => {
                    dispatch({
                        type: 'select',
                        items: [],
                    });
                });
            })
            .catch(() => {});
    }
}
