import { FormModal, ModalFooter } from '../../../global/FormModal';
import { Form as FForm, Formik } from 'formik';
import { Modal, Table } from 'react-bootstrap';
import { FieldSelect } from '../../../publications_v2/helpers/FieldHelper';
import * as Yup from 'yup';
import HelperFunctions from '../../../global/HelperFunctions';
import { useUpdateAreasMutation } from '../../../../features/documents/documents';
import { useGetTemplate } from '../../../documents_v2/hooks/useGetTemplate';
import { useGetBaseVariant } from '../../../operations_list/hooks/useGetBaseVariant';
import { SelectionTableRows } from '../../../documents_v2/views/view/NewItemFromTemplate';
import { useTranslation } from 'react-i18next';

export default function AddAreaFromParent({ document, handleClose, payload }) {
    const [updateAreas] = useUpdateAreasMutation();
    const template = useGetTemplate(document?.modelId, document?.modelSource) ?? [];
    const { sections = [] } = template;
    const baseVariant = useGetBaseVariant(document?.id);
    const baseVariantId = baseVariant?.id;
    const { t } = useTranslation('documents');

    const templateOptions = sections.map((_section) => {
        return {
            value: _section.id,
            label: _section.title,
            options: _section.areas
                .filter((_area) => _area.deletedAt === null)
                .map((_area) => {
                    return {
                        value: _area.id,
                        label: _area.title,
                    };
                }),
        };
    });

    const handleSubmit = (values, { setSubmitting }) => {
        const selectedAreasSectionFromTemplate = sections.find((_section) =>
            _section.areas.some((_area) => _area.id === values.properties.parentId)
        );
        const selectedArea = selectedAreasSectionFromTemplate.areas.find(
            (_area) => _area.id === values.properties.parentId
        );

        // Handle save
        updateAreas({
            documentId: document.id,
            body: [
                {
                    ...values,
                    title: selectedArea.title,
                    properties: {
                        ...selectedArea.properties,
                        parentId: values.properties.parentId,
                        modelId: document.modelId,
                        sourceSlug: document.modelSource,
                    },
                },
            ],
        }).then(() => {
            setSubmitting(false);
            handleClose();
        });
    };

    return (
        <FormModal
            show
            onHide={handleClose}
            title={t('document.navbar.main.reviewDocument.addAreaFromParent.makeArticleOnTemplate')}
        >
            <Formik
                initialValues={{
                    ...payload,
                    documentVariants: [baseVariantId],
                    properties: {
                        parentId: null,
                    },
                }}
                validationSchema={NewAreaSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, dirty, isValid, values, setFieldValue }) => (
                    <FForm autoComplete="off">
                        <Modal.Body className="template-modal" style={{ minHeight: 300 }}>
                            <FieldSelect
                                label={t('document.navbar.main.reviewDocument.addAreaFromParent.templateChapter')}
                                options={templateOptions}
                                name="properties.parentId"
                                props={{
                                    placeholder: `${t(
                                        'document.navbar.main.reviewDocument.addAreaFromParent.titleSelect'
                                    )}...`,
                                }}
                            />
                            <div className="uk-form-controls">
                                {values.properties.parentId && (
                                    <AreaDocumentVariantsTable
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        sections={sections}
                                        baseVariant={baseVariant}
                                    />
                                )}
                            </div>
                        </Modal.Body>

                        <ModalFooter
                            isValid={isValid}
                            btnSubmitText={t('document.navbar.main.reviewDocument.addAreaFromParent.btn.addArticle')}
                            dirty={dirty}
                            isSubmitting={isSubmitting}
                            onHide={handleClose}
                        />
                    </FForm>
                )}
            </Formik>
        </FormModal>
    );
}

const NewAreaSchema = Yup.object().shape({
    properties: Yup.object().shape({
        parentId: Yup.number().required('Required'),
    }),
});

function AreaDocumentVariantsTable({ values, sections, baseVariant }) {
    const selectedAreasSection = sections.find((_section) =>
        _section.areas.some((_area) => _area.id === values.properties.parentId)
    );

    const selectedArea = selectedAreasSection.areas.find((_area) => _area.id === values.properties.parentId);

    const documentVariantsList = HelperFunctions.variantAsList(baseVariant);
    const { t } = useTranslation('documents');

    return (
        <Table striped borderless responsive className="dr-side-container" id={'template-area-variants-table'}>
            <thead>
                <tr className="font-weight-bold font-size-sm">
                    <th>{t('document.navbar.main.reviewDocument.addAreaFromParent.variants')}</th>
                </tr>
            </thead>
            <tbody>
                {values.properties.parentId && (
                    <SelectionTableRows
                        areas={[selectedArea]}
                        documentVariantsList={documentVariantsList}
                        documentBaseVariant={baseVariant}
                        nameField={values.documentVariants}
                    />
                )}
            </tbody>
        </Table>
    );
}
