import { useGetModelVersionByTemplateCategoriesQuery } from 'features/templates/templatesApi';
import HelperFunctions from 'pages/global/HelperFunctions';
import { useCurrentOrganisation } from 'hooks/useCurrentOrganisation';

const emptyArray = [];

export function useGetModelsByTemplateCategory() {
    const currentOrganisation = useCurrentOrganisation();
    const currentOrganisationId = currentOrganisation?.id;

    const { modelsByTemplateCategory } = useGetModelVersionByTemplateCategoriesQuery(currentOrganisationId, {
        selectFromResult: ({ data }) => ({
            modelsByTemplateCategory: data
                ? data
                      .map((modelVersion) => {
                          return {
                              id: `${modelVersion.source}-${modelVersion.documentId}`,
                              documentId: modelVersion.documentId,
                              name: modelVersion.name,
                              source: modelVersion.source,
                              templateCategory: modelVersion.templateCategory,
                          };
                      })
                      .sort(HelperFunctions.sortByString('name'))
                : emptyArray,
        }),
        skip: !currentOrganisationId,
    });

    return modelsByTemplateCategory;
}
