import { useDispatch, useSelector } from 'react-redux';
import { fetchOperationsLists, getAllOpLists } from '../../../features/operationsList/operationsListSlice';
import { useEffect, useState } from 'react';

export function useGetOpLists() {
    const dispatch = useDispatch();
    const opLists = useSelector(getAllOpLists());
    const [isLoaded, setLoaded] = useState(false);

    useEffect(() => {
        if (opLists.length === 0 && !isLoaded) {
            dispatch(fetchOperationsLists());
            setLoaded(true);
        }
    }, [opLists, isLoaded]);

    return opLists;
}
