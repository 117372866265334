import HelperFunctions from '../../../../global/HelperFunctions';
import { useGetDocument } from '../../../hooks/useGetDocument';
import { useEntityTemplateParameters } from '../../../hooks/useEntityTemplateParameters';
import { entityTypes } from 'pages/translation/config/Constants';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useGetModelsByTemplateCategory } from 'pages/documents_v2/hooks/useGetModelsByTemplateCategory';
import React, { useEffect } from 'react';
import { DocRevSelect } from 'pages/publications_v2/helpers/FieldHelper';
import _ from 'lodash';

export default function ChooseTemplateModal({
    required = false,
    isClearable = true,
    label = 'document.navbar.settings.tabs.settings.linkTemplate',
}) {
    const document = useGetDocument();
    const restrictions = useEntityTemplateParameters(document, entityTypes.DOCUMENT, document);
    const { values, setValues } = useFormikContext();
    const { t } = useTranslation('documents');
    const modelsByTemplateCategory = useGetModelsByTemplateCategory();

    useEffect(() => {
        const selectedModelId = values.modelId;

        if (modelsByTemplateCategory && modelsByTemplateCategory.length === 1 && required && selectedModelId === null) {
            const firstModel = modelsByTemplateCategory[0];

            // Select the first (and only) model as value
            setValues({
                ...values,
                modelId: firstModel.documentId,
                modelSource: firstModel.source,
            });
        }
    }, [required, modelsByTemplateCategory, values]);

    if (modelsByTemplateCategory.length === 0 && !required) {
        return null;
    }

    const options = getOptions();
    const isDisabled = restrictions?.canUnlinkTemplate === false || options.length === 0;

    return (
        <div className="mb-3">
            <div className="mb-2 font-weight-bolder label-title">
                {t(label)}
                {required && <>*</>}
            </div>
            <DocRevSelect
                name="modelId"
                options={options}
                selectedValue={values.modelId ? `${values.modelSource}-${values.modelId}` : undefined}
                props={{
                    required,
                    isDisabled,
                    isClearable,
                    placeholder: t('document.navbar.settings.tabs.settings.linkTemplateSelect'),
                }}
                onChange={handleChange}
            />
        </div>
    );

    function getOptions() {
        const templateCategoryGroups = {};

        for (const model of modelsByTemplateCategory) {
            if (model.templateCategory) {
                if (templateCategoryGroups.hasOwnProperty(model.templateCategory.name) === false) {
                    templateCategoryGroups[model.templateCategory.name] = {
                        label: model.templateCategory.name,
                        options: [],
                    };
                }

                templateCategoryGroups[model.templateCategory.name].options.push({
                    ...model,
                    label: model.name,
                    value: model.id,
                });
            }
        }

        if (_.isEmpty(templateCategoryGroups)) {
            return HelperFunctions.prepareDropdownData(modelsByTemplateCategory);
        }

        const optionsWithoutCategory = modelsByTemplateCategory.filter((model) => model.templateCategory === undefined);

        return [
            ...HelperFunctions.prepareDropdownData(optionsWithoutCategory),
            ...Object.values(templateCategoryGroups),
        ];
    }

    function handleChange(selection) {
        if (selection) {
            setValues({
                ...values,
                modelId: selection.documentId,
                modelSource: selection.source,
            });
        } else {
            setValues({
                ...values,
                modelId: null,
                modelSource: null,
            });
        }
    }
}
