import { useUserProfile } from 'hooks/useUserProfile';
import { useGetAccountSubscriptionQuery } from 'features/security/authApi';

export function useGetSubscription() {
    const userProfile = useUserProfile();

    const { subscriptionResult } = useGetAccountSubscriptionQuery(userProfile.accountId, {
        selectFromResult: ({ data }) => ({
            subscriptionResult: data ?? {},
        }),
    });

    return subscriptionResult;
}
