import { generatePath, Link, useParams } from 'react-router-dom';
import cx from 'classnames';
import { Nav } from 'react-bootstrap';
import { VIEW_TEAM_PATH } from 'scenes/TaskTeam';
import { CheckSquareFill, GearFill, PeopleFill, Stack } from 'react-bootstrap-icons';
import { useAbility } from 'ability/useAbility';
import { useGetTeamUser } from 'pages/task_team/hooks/useGetTeamUser';

export function TeamSidebar({ team }) {
    const { id, page = 'teams' } = useParams();
    const ability = useAbility();
    const teamUser = useGetTeamUser(id);

    const availableSettings = getSettings();

    return (
        <div className="py-4 bg-lightBlueLight">
            <div className="d-flex align-items-center justify-content-between mb-2 px-4">
                <div className="font-weight-bold">Team</div>
            </div>

            <Nav className="sidebar-nav flex-column">
                {availableSettings.map((_setting, index) => (
                    <Link
                        to={generatePath(VIEW_TEAM_PATH, {
                            id,
                            page: _setting.id,
                        })}
                        className={cx('nav-link font-weight-normal', {
                            active: _setting.pages.includes(page),
                        })}
                        key={`sidebar-item-${index}`}
                        style={{ fontSize: '12px' }}
                    >
                        {_setting.icon}
                        {_setting.name}
                    </Link>
                ))}
            </Nav>
        </div>
    );

    function getSettings() {
        let settings = [
            {
                id: 'users',
                pages: ['users'],
                name: 'Gebruikers',
                icon: <PeopleFill />,
            },
        ];

        if (ability.can('manage', teamUser)) {
            settings.push(
                {
                    id: 'rules',
                    pages: ['rules'],
                    name: 'Onderwerp/doelgroepen',
                    icon: <Stack />,
                },
                {
                    id: 'checklist',
                    pages: ['checklist'],
                    name: 'Checklist',
                    icon: <CheckSquareFill />,
                },
                {
                    id: 'settings',
                    pages: ['settings'],
                    name: 'Instellingen',
                    icon: <GearFill />,
                },
            );
        }

        return settings;
    }
}
