import { useGetDocumentGroupsQuery } from '../../../features/documents/documents';
import HelperFunctions from '../../global/HelperFunctions';
import { useActiveOrganisation } from '../../../hooks/useActiveOrganisation';

const emptyArray = [];

export function useGetDocumentGroups(parentId = null) {
    const activeOrganisation = useActiveOrganisation();

    const { documentGroups } = useGetDocumentGroupsQuery(undefined, {
        selectFromResult: ({ data }) => ({
            documentGroups: data
                ? data
                      .filter((group) => group.organisationId === activeOrganisation && group.parent === parentId)
                      .sort(HelperFunctions.dynamicSort('sortOrder'))
                : emptyArray,
        }),
    });

    return documentGroups;
}
