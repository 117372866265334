import { ProductFruits as ReactProductFruits } from 'react-product-fruits';
import { useUserProfile } from 'hooks/useUserProfile';
import { useGetOrganisationUser } from 'hooks/useGetOrganisationUser';
import getEnv from 'config/Env';

export function ProductFruits() {
    const userProfile = useUserProfile();
    const organisationUser = useGetOrganisationUser(userProfile.userId);
    const workspaceCode = getEnv('PRODUCT_FRUITS_WORKSPACE_CODE');
    const env = getEnv('APP_ENV');
    const docrevEnv = getEnv('DOCREV_ENV');

    if (_.isEmpty(workspaceCode) || _.isEmpty(docrevEnv)) {
        return null;
    }

    const userInfo = getUserInfo();

    return <ReactProductFruits workspaceCode={workspaceCode} language="en" user={userInfo} debug={env === 'dev'} />;

    function getUserInfo() {
        return {
            username: userProfile.userId,
            firstname: userProfile.firstName,
            signUpAt: organisationUser.createdAt,
            props: {
                hostname: window.location.hostname,
                docrev_env: docrevEnv,
            },
        };
    }
}
