import { NumberParam, useQueryParam } from 'use-query-params';
import { FormModal, ModalFooter } from 'pages/global/FormModal';
import { useGetVariant } from '../../../hooks/useGetVariant';
import { Form as FForm, Formik } from 'formik';
import Products from '../../../../reimbursements/views/propositions/edit/Products';
import {
    useAddPropositionMutation,
    useGetDocumentVariantPropositionsQuery,
    useUpdatePropositionMutation,
} from 'features/reimbursements/reimbursements';
import LoadingSpinner from '../../../../global/LoadingSpinner';
import { idToUri } from 'pages/global/UriHelper';

export function LinkProductModal() {
    const [productModal, setProductModal] = useQueryParam('productModal', NumberParam);
    const [categoryId, setCategoryId] = useQueryParam('categoryId', NumberParam);
    const variant = useGetVariant(productModal);

    const showModal = productModal !== undefined;
    const showContent = showModal && variant;

    const hide = () => {
        setProductModal(undefined);
        setCategoryId(undefined);
    };

    return (
        <FormModal
            scrollable
            size="xl"
            onHide={hide}
            show={productModal !== undefined}
            title={variant ? `Polissoorten koppelen: ${variant.name}` : 'Polissoorten koppelen'}
        >
            {showContent && <ModalContent variant={variant} hide={hide} />}
        </FormModal>
    );
}

function ModalContent({ variant, hide }) {
    const [categoryId, setCategoryId] = useQueryParam('categoryId', NumberParam);
    const [addProposition] = useAddPropositionMutation();
    const [updateProposition] = useUpdatePropositionMutation();

    const categoryUri = idToUri(categoryId, 'Category');

    const { proposition, isLoading } = useGetDocumentVariantPropositionsQuery(variant.id, {
        selectFromResult: ({ data, isLoading }) => {
            let proposition = {
                name: '',
                addPrimaryAndSecondary: true,
                documentVariantId: null,
                sortOrder: null,
                variantPackageProducts: [],
            };

            if (data && data.length > 0) {
                proposition = data.length === 1 ? data[0] : data.find((item) => item.category === categoryUri);
            }

            return {
                isLoading,
                proposition,
            };
        },
    });

    const handleSubmit = (values, { setSubmitting }) => {
        if (proposition.hasOwnProperty('id')) {
            updateProposition({
                uri: proposition['@id'],
                ...values,
                variantPackageProducts: values.variantPackageProducts.map((_item, index) => ({
                    ..._item,
                    id: _item['@id'] ?? null,
                    sortOrder: index,
                })),
            }).then(() => {
                setSubmitting(false);
            });
        } else {
            addProposition({
                ...values,
                documentVariantId: variant.id,
                category: categoryUri,
            }).then(({ data }) => {
                setSubmitting(false);
            });
        }
    };

    return (
        <Formik initialValues={proposition} enableReinitialize={true} onSubmit={handleSubmit}>
            {({ isSubmitting, isValid, dirty }) => (
                <>
                    <FForm autoComplete="off" className="modal-body" id="link-product">
                        {isLoading && proposition === undefined ? (
                            <LoadingSpinner size="sm" />
                        ) : (
                            <Products categoryId={categoryId} />
                        )}
                    </FForm>

                    <ModalFooter
                        isSubmitting={isSubmitting}
                        isValid={isValid && !isLoading}
                        dirty={dirty}
                        onHide={hide}
                        form="link-product"
                    />
                </>
            )}
        </Formik>
    );
}
