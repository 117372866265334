import { useEffect, useState } from 'react';
import mammoth from 'mammoth/mammoth.browser';
import Parser from 'html-react-parser';

export function DocViewer({ data }) {
    const [html, setHtml] = useState();

    useEffect(() => {
        mammoth
            .convertToHtml({ arrayBuffer: data }, { includeDefaultStyleMap: true })
            .then(({ value }) => {
                setHtml(value);
            })
            .catch((reason) => {
                setHtml(`<p>${reason}</p>`);
            });
    }, []);

    if (!html) {
        return null;
    }

    return <div id="docx">{Parser(html)}</div>;
}
