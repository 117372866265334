import { generatePath, Link, useParams } from 'react-router-dom';
import { BarChartFill, Calendar2CheckFill, GearFill, PeopleFill } from 'react-bootstrap-icons';
import { Navbar } from 'react-bootstrap';
import { VIEW_PATH } from 'scenes/TaskTeam';
import cx from 'classnames';
import { DEFAULT_VIEW } from 'pages/task_team/views/Index';
import { useAbility } from 'ability/useAbility';
import { useCanViewReports } from 'pages/task_team/hooks/permissions';

export function SubNav() {
    const { view = DEFAULT_VIEW } = useParams();
    const ability = useAbility();
    const canViewReports = useCanViewReports();

    const navItems = getNavItems();

    return (
        <Navbar fixed="top" className="subnav bg-light border-bottom px-3 py-0">
            <div className="nav w-100">
                {navItems.map((navItem) => (
                    <div className="nav-item" key={`nav-item-${navItem.id}`}>
                        <Link
                            to={generatePath(VIEW_PATH, {
                                view: navItem.id,
                            })}
                            className={cx('nav-link d-flex align-items-center', {
                                active: view === navItem.id,
                            })}
                        >
                            {navItem.icon}
                            {navItem.title}
                        </Link>
                    </div>
                ))}
            </div>
        </Navbar>
    );

    function getNavItems() {
        let navItems = [
            { id: 'checks', title: 'Uitingen', icon: <Calendar2CheckFill className="mr-2" /> },
            { id: 'teams', title: 'Teams', icon: <PeopleFill className="mr-2" /> },
        ];

        if (canViewReports) {
            navItems.push({
                id: 'reports',
                title: 'Rapporten',
                icon: <BarChartFill className="mr-2" />,
            });
        }

        if (ability.can('manage', 'TaskTeam.Settings')) {
            navItems.push({
                id: 'settings',
                title: 'Instellingen',
                icon: <GearFill className="mr-2" />,
            });
        }

        return navItems;
    }
}
