import { ArrayParam, useQueryParam, withDefault } from 'use-query-params';
import { useMemo } from 'react';
import { useGetAreaBlocks } from 'pages/documents_v2/hooks/useGetAreaBlocks';
import striptags from 'striptags';
import { diffWords } from 'diff';
import Parser from 'html-react-parser';
import Constants from 'config/Constants';
import { parseBlockContent } from 'pages/global/BlockLayoutHelper';
import { Alert, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const SelectedBlocksParam = withDefault(ArrayParam, []);

export function BlockCompare() {
    const { t } = useTranslation('documents');
    const [selectedBlocks, setSelectedBlocks] = useQueryParam('selectedBlocks', SelectedBlocksParam);
    const selectedBlockIds = selectedBlocks.map((id) => parseInt(id));

    return <div className="mt-4">{renderBlockCompareContent()}</div>;

    function renderBlockCompareContent() {
        return (
            <>
                {renderWarning()}
                <RenderBlocks selectedBlockIds={selectedBlockIds} />
            </>
        );
    }

    function renderWarning() {
        if (selectedBlockIds.length > 1) {
            return null;
        }

        return (
            <Alert variant="info">
                {t('document.navbar.main.editor.right.compareBlocks.select')}{' '}
                <span className="font-weight-bold">
                    {selectedBlockIds.length === 0
                        ? t('document.navbar.main.editor.right.compareBlocks.twoBlocks')
                        : t('document.navbar.main.editor.right.compareBlocks.otherBlock')}
                </span>{' '}
                {t('document.navbar.main.editor.right.compareBlocks.toCompare')}
            </Alert>
        );
    }
}

function RenderBlocks({ selectedBlockIds = [] }) {
    const { t } = useTranslation('documents');
    const { areaBlocks } = useGetAreaBlocks();
    const allBlocks = areaBlocks.filter((block) => selectedBlockIds.includes(block.key));

    const [blockA, blockB] = allBlocks;

    return (
        <div>
            {blockA && (
                <div className="mb-3">
                    <div className="mb-2">{t('document.navbar.main.editor.right.compareBlocks.block')} #1:</div>
                    {renderAreaBlock(blockA, blockB)}
                </div>
            )}

            {blockB && (
                <div className="mb-3">
                    <div className="mb-2">{t('document.navbar.main.editor.right.compareBlocks.block')} #2:</div>
                    {renderAreaBlock(blockB, blockA)}
                </div>
            )}
        </div>
    );

    function renderAreaBlock(block, targetBlock = undefined) {
        const BlockView = BlockViews[block.type] ?? DefaultBlockView;

        return <BlockView block={block} targetBlock={targetBlock} />;
    }
}

function TextBlock({ block, targetBlock }) {
    return <DiffRender value={block.latestContent} targetValue={targetBlock?.latestContent} />;
}

function BlockLayout({ block, targetBlock }) {
    const { areaBlocks } = useGetAreaBlocks();

    const blockContent = useMemo(() => {
        return parseBlockContent(block.baseContent);
    }, [block]);

    const rows = blockContent?.data ?? [];

    return (
        <Table bordered className="mb-2">
            <tbody>
                {rows.map((el, ind) => (
                    <tr key={block.key + '-row-' + ind}>
                        {el.map((col, colIndex) => {
                            return (
                                <td key={block.id + '-col-' + colIndex}>
                                    <div className="area-read-only p-0">
                                        {col.map((item, index) => (
                                            <div key={`layout-${item.id}-${index}`}>
                                                {renderBlockLayoutBlock(item, targetBlock, ind, colIndex, index)}
                                            </div>
                                        ))}
                                    </div>
                                </td>
                            );
                        })}
                    </tr>
                ))}
            </tbody>
        </Table>
    );

    function renderBlockLayoutBlock(item, targetBlock = undefined, rowIndex, colIndex, itemIndex) {
        const block = areaBlocks.find((_block) => _block.key === item.id);
        const newTargetBlock = getItemTargetBlock(block, targetBlock, rowIndex, colIndex, itemIndex);

        if (!block) {
            return null;
        }

        return <TextBlock block={block} targetBlock={newTargetBlock} />;
    }

    function getItemTargetBlock(block, targetBlock = undefined, rowIndex, colIndex, itemIndex) {
        if (!targetBlock) {
            return undefined;
        }

        if (targetBlock.type === Constants.blockTypes.text) {
            return targetBlock;
        }

        const targetBlockLayout = parseBlockContent(targetBlock.baseContent);
        const targetRows = targetBlockLayout?.data ?? [];
        const targetItem = targetRows[rowIndex][colIndex][itemIndex];

        if (targetItem) {
            return areaBlocks.find((_block) => _block.key === targetItem.id);
        }

        return undefined;
    }
}

function DiffRender({ value = '', targetValue = undefined }) {
    const diffText = useMemo(() => {
        const source = striptags(value);

        if (!targetValue) {
            return source;
        }

        let text = '';
        const diff = diffWords(source, striptags(targetValue));

        diff.forEach((part) => {
            if (part.added) {
                text = text + `<ins>${part.value}</ins>`;
            } else if (part.removed) {
                text = text + `<del>${part.value}</del>`;
            } else {
                text = text + part.value;
            }
        });

        return text;
    }, [value, targetValue]);

    return (
        <div className="area-text-block basic-editor-text-block area-text-block-no-editor">
            <div className="mce-content-body">{Parser(`<p>${diffText}</p>`)}</div>
        </div>
    );
}

const BlockViews = {
    [Constants.blockTypes.text]: TextBlock,
    [Constants.blockTypes.blockLayout]: BlockLayout,
};

function DefaultBlockView() {
    return <div>block not supported</div>;
}
