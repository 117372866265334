import { useParams } from 'react-router-dom';
import { Form as FForm, Formik, FormikHelpers } from 'formik';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { LoadingSpinner } from '../../../global/Spinner';
import * as Yup from 'yup';
import { FormField, InputCheck } from '../../../publications_v2/helpers/FieldHelper';
import {
    useGetTranslationContainerQuery,
    useUpdateTranslationContainerMutation,
} from '../../../../features/translations/translationApi';
import { TranslationParams } from '../../config/interfaces';
import { DocumentContainer, ReimbursementContainer, TranslationContainer } from '../../../../models/translation.models';
import { useGetCategory } from '../../../../features/reimbursements/reimbursementHooks';
import { useTranslation } from 'react-i18next';

export default function General() {
    const { translationContainerId }: TranslationParams = useParams();
    const { t } = useTranslation('translations');
    const { translationContainer } = useGetTranslationContainerQuery(translationContainerId, {
        selectFromResult: ({ data }) => ({
            translationContainer: data,
        }),
    });

    return (
        <>
            <div className="subheader">
                <h3>{t('translation.navbar.settings.contentSidebar.properties.title')}</h3>
            </div>

            <Row>
                <Col xl={8}>
                    {translationContainer && <PropertiesForm translationContainer={translationContainer} />}
                </Col>
            </Row>
        </>
    );
}

function PropertiesForm({ translationContainer }: { translationContainer: TranslationContainer }) {
    const [updateTranslationContainer] = useUpdateTranslationContainerMutation();
    const { t } = useTranslation('translations');

    const handleSubmit = (values: FormData, { setSubmitting }: FormikHelpers<FormData>) => {
        updateTranslationContainer({
            uri: translationContainer['@id'],
            body: values,
        }).then(() => {
            setSubmitting(false);
        });
    };

    const getInitialValues = () => {
        if ('fields' in translationContainer) {
            return {
                name: translationContainer.name,
                fields: translationContainer.fields ?? [],
            } as FormData;
        }

        return {
            name: translationContainer.name,
        } as FormData;
    };

    return (
        <Formik
            initialValues={getInitialValues()}
            validationSchema={GeneralSchema}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({ dirty, isSubmitting, isValid }) => (
                <FForm autoComplete="off">
                    <FormField name="name" label={t('translation.navbar.settings.contentSidebar.properties.name')} />

                    {'fields' in translationContainer && (
                        <ReimbursementFields translationContainer={translationContainer as ReimbursementContainer} />
                    )}

                    <Button type="submit" disabled={isSubmitting || !dirty || !isValid}>
                        {t('btn.save')}
                    </Button>
                    <LoadingSpinner isSubmitting={isSubmitting} />
                </FForm>
            )}
        </Formik>
    );
}

function ReimbursementFields({ translationContainer }: { translationContainer: ReimbursementContainer }) {
    const { category } = useGetCategory(translationContainer.categoryId);
    const { t } = useTranslation('translations');

    return (
        <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4}>
                {t('translation.navbar.settings.contentSidebar.properties.fields')}
            </Form.Label>
            <Col sm={8} className="pt-2">
                {category?.reimbursementProductFields.map((field) => (
                    <div className="form-check" key={`field-${field.slug}`}>
                        <InputCheck
                            name="fields"
                            props={{
                                value: field.slug,
                                id: `field-${field.slug}`,
                                className: 'form-check-input',
                            }}
                        />
                        <label className="form-check-label" htmlFor={`field-${field.slug}`}>
                            {field.name}
                        </label>
                    </div>
                ))}
            </Col>
        </Form.Group>
    );
}

interface FormData {
    name: string;
    fields?: string[];
}

const GeneralSchema = Yup.object().shape({
    name: Yup.string().required(),
});
