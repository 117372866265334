import { TranslationKey } from '../../../../../models/translation.models';
import { useGetEntityReference } from '../../../hooks/useGetEntityReference';
import { useGetReimbursementProductFieldQuery } from '../../../../../features/translations/translationApi';
import React from 'react';
import { TranslationKeyInner } from '../TranslationKeyCard';

export function ReimbursementProductFieldTranslationKey({
    languageIso,
    translationKey,
}: {
    languageIso: string;
    translationKey: TranslationKey;
}) {
    const entityReference = useGetEntityReference(translationKey);

    const { reimbursementProductField } = useGetReimbursementProductFieldQuery(
        `/api/reimbursement-api/api/reimbursement_product_fields/${entityReference?.entityId}`,
        {
            selectFromResult: ({ data }) => ({
                reimbursementProductField: data,
            }),
            skip: entityReference === undefined,
        }
    );

    if (entityReference === undefined || reimbursementProductField === undefined) {
        return null;
    }

    return (
        <TranslationKeyInner
            parentEntity={reimbursementProductField}
            languageIso={languageIso}
            translationKey={translationKey}
            entityReference={entityReference}
        />
    );
}
