import { Modal } from 'react-bootstrap';
import { StringParam, useQueryParam } from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { ModalFooter } from 'pages/global/FormModal';
import { useUpdateTaskMutation } from 'features/comments/commentApi';
import { Select } from 'components/Select';
import { useGetTask } from 'pages/task_team/hooks/useGetTask';
import { useGetAvailableUsers } from 'pages/task_team/hooks/useGetAvailableUsers';

export function ChangeTaskOwnerModal() {
    const [changeOwner, setChangeOwner] = useQueryParam('changeOwner', StringParam);

    if (!changeOwner) {
        return null;
    }

    return <ChangeOwnerModalWrapper id={changeOwner} close={close} />;

    function close() {
        setChangeOwner(undefined);
    }
}

function ChangeOwnerModalWrapper({ id, close }) {
    const { t } = useTranslation();
    const task = useGetTask(id);
    const [updateTask] = useUpdateTaskMutation();

    return (
        <>
            <Modal show={true} onHide={close}>
                <Modal.Header closeButton>
                    <Modal.Title>Eigenaar aanpassen: {renderTitle()}</Modal.Title>
                </Modal.Header>

                <Formik
                    enableReinitialize={true}
                    initialValues={{ createdBy: task?.createdBy }}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    {({ isValid, isSubmitting, dirty, setFieldValue }) => {
                        return (
                            <Form>
                                <Modal.Body>
                                    {task && <ModalBody task={task} setFieldValue={setFieldValue} />}
                                </Modal.Body>

                                <ModalFooter
                                    onHide={close}
                                    isSubmitting={isSubmitting}
                                    dirty={dirty}
                                    isValid={isValid}
                                />
                            </Form>
                        );
                    }}
                </Formik>
            </Modal>
        </>
    );

    function handleSubmit(values) {
        updateTask({ uri: task['@id'], body: values }).then(() => {
            close();
        });
    }

    function renderTitle() {
        if (!task) {
            return null;
        }

        return (
            <>
                #{task.sequence} {task.title}
            </>
        );
    }
}

function ModalBody({ task, setFieldValue }) {
    const allAvailableUsers = useGetAvailableUsers();

    if (allAvailableUsers.length === 0) {
        return null;
    }

    return (
        <div>
            <Select
                label="Eigenaar"
                id="createdBy"
                isMulti={false}
                options={allAvailableUsers}
                defaultValue={allAvailableUsers.find((user) => user.value === task.createdBy)}
                handleChange={(selection) => handleChange(selection, 'createdBy')}
            />
        </div>
    );

    function handleChange(selection, field) {
        setFieldValue(field, selection.value);
    }
}

const validationSchema = Yup.object().shape({
    createdBy: Yup.string().required(),
});
