import { Nav } from 'react-bootstrap';
import { generatePath, Link, useParams } from 'react-router-dom';
import React from 'react';
import { EDIT_PATH } from '../../../../../scenes/DocumentsV2';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

export default function TemplateSideBar() {
    const { documentId, page = 'parameters' } = useParams();
    const { t } = useTranslation('documents');
    const availableSettings = [
        {
            id: 'parameters',
            name: t('document.navbar.template.sidebar.features'),
        },
        {
            id: 'versions',
            name: t('document.navbar.template.sidebar.versions'),
        },
    ];

    return (
        <div className="py-4">
            <div className="d-flex align-items-center justify-content-between mb-2 px-4">
                <span className="font-weight-bold text-color">Template</span>
            </div>

            <Nav className="sidebar-nav flex-column">
                {availableSettings.map((_setting, index) => (
                    <Link
                        to={generatePath(EDIT_PATH, {
                            documentId: documentId,
                            view: 'models',
                            page: _setting.id,
                        })}
                        className={cx('nav-link font-weight-normal', {
                            active: page === _setting.id,
                        })}
                        key={`sidebar-item-${index}`}
                        style={{ fontSize: '12px' }}
                    >
                        {_setting.name}
                    </Link>
                ))}
            </Nav>
        </div>
    );
}
