import React from "react";
import Language from "../../../language/Language";
import _ from "lodash";


/**
 * Same as {@see resolveOpListVariants} but uses document as input
 * @param document
 * @returns {*[]}
 */
export function resolveOpListVariantsByDocument(document) {
    if (!document || !document.baseVariant) {
        return [];
    }

    return resolveOpListVariants(document.baseVariant);
}

/**
 * Resolve the document variants used for operationLists.
 * This includes the baseVariant and it's children in a flat array.
 *
 * @param baseVariant
 * @returns {*[]}
 */
export function resolveOpListVariants(baseVariant) {
    if (!baseVariant) {
        return [];
    }

    let variants = [];

    const baseVariantClone = _.cloneDeep(baseVariant);
    baseVariantClone.name = Language.getTranslation('baseVariant');
    variants.push(baseVariantClone);
    variants = variants.concat(baseVariantClone.children);

    return variants;
}
