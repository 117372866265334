import { useGetDocument } from 'pages/documents_v2/hooks/useGetDocument';
import { AreaLayout, AreaLayoutPageRight } from 'pages/documents/misc/AreaLayout';
import { useGetAreaBlocks } from 'pages/documents_v2/hooks/useGetAreaBlocks';
import { AreaLayoutNav, AreaLayoutPageLeft } from 'pages/documents_v2/views/edit_area/AreaLayout';
import { FieldArray, Form as FForm, Formik, useFormikContext } from 'formik';
import { useGetOneLiners } from 'pages/documents_v2/views/edit/one_liners/hooks/useGetOneLiners';
import { useParams } from 'react-router-dom';
import { AreaNav } from 'pages/documents_v2/views/edit/one_liners/edit_area/AreaNav';
import { InfoButton } from 'components/Buttons';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { useUpdateOneLinersMutation } from 'features/metadata/metadata';
import { AreaOneLiner } from 'pages/documents_v2/views/edit/one_liners/edit_area/AreaOneLiner';
import { useSelector } from 'react-redux';
import { views } from 'pages/documents_v2/config/views';
import { Preview } from 'pages/documents/misc/_EditArea/Preview';
import { OneLinerCategories } from 'pages/documents_v2/views/edit/one_liners/edit_area/OneLinerCategories';
import Constants from 'config/Constants';
import BaseBlocks from 'pages/documents/misc/_EditArea/BaseBlocks';
import { useContext, useMemo, useState } from 'react';
import { AreaContext } from 'pages/documents_v2/views/Area';
import AreaHistory from 'pages/documents/misc/_EditArea/AreaHistory';
import { useGetAreaHistory } from 'pages/documents_v2/views/edit_area/hooks/useGetAreaHistory';
import HelperFunctions from 'pages/global/HelperFunctions';
import { useGetVariant } from 'pages/documents_v2/hooks/useGetVariant';
import VariantViewButtons from 'pages/documents/misc/VariantViewButtons';

export function EditAreaOneLiners() {
    const document = useGetDocument();
    const { areaId } = useParams();

    if (!document || document.properties?.enableOneLiners !== true) {
        return null;
    }

    return <EditOneLiners areaId={parseInt(areaId)} documentId={document.id} />;
}

function EditOneLiners({ areaId, documentId }) {
    const areaData = useGetAreaBlocks();
    const oneLiners = useGetOneLiners(areaId);
    const [updateOneLiners] = useUpdateOneLinersMutation();

    if (!areaData) {
        return null;
    }

    const { area } = areaData;

    return (
        <Formik enableReinitialize={true} initialValues={{ oneLiners }} onSubmit={handleSubmit}>
            {({ values, dirty }) => {
                return (
                    <FForm>
                        <AreaLayout>
                            <AreaLayoutNav area={area}>
                                <AreaNav />
                            </AreaLayoutNav>

                            <AreaLayoutPageLeft area={area} showRightSide={true} dirty={dirty}>
                                <FieldArray name="oneLiners">
                                    {(arrayHelpers) => (
                                        <OneLiners
                                            area={area}
                                            oneLiners={values.oneLiners}
                                            areaId={areaId}
                                            arrayHelpers={arrayHelpers}
                                            documentId={documentId}
                                        />
                                    )}
                                </FieldArray>
                            </AreaLayoutPageLeft>

                            <AreaLayoutPageRight showRightSide={true}>
                                <RightSideContent />
                            </AreaLayoutPageRight>
                        </AreaLayout>
                    </FForm>
                );
            }}
        </Formik>
    );

    function handleSubmit(values, { setSubmitting }) {
        updateOneLiners({
            oneLiners: values.oneLiners.map((oneLiner) => ({
                ...oneLiner,
                '@id': undefined,
                id: oneLiner.hasOwnProperty('@id') ? oneLiner['@id'] : undefined,
            })),
        }).then(() => {
            setSubmitting(false);
        });
    }
}

function OneLiners({ area, areaId, arrayHelpers, oneLiners = [], documentId }) {
    const { setFieldValue } = useFormikContext();
    const { push } = arrayHelpers;
    const organisationId = useActiveOrganisation();

    return (
        <div>
            <div style={{ height: 110 }}>
                <div className="d-flex align-items-center">
                    <VariantViewButtons area={area} />

                    <div className="ml-auto">
                        <InfoButton
                            onClick={() =>
                                push({
                                    areaId,
                                    sectionId: area.sectionId,
                                    documentId,
                                    organisationId,
                                    content: '',
                                    categories: [],
                                    metadata: [],
                                })
                            }
                        >
                            One-liner toevoegen
                        </InfoButton>
                    </div>
                </div>
            </div>

            <div>
                {oneLiners.map((oneLiner, index) => (
                    <AreaOneLiner
                        oneLiner={oneLiner}
                        index={index}
                        remove={() => {
                            setFieldValue(`oneLiners[${index}].deleted`, true);
                        }}
                        key={`area-one_liner-${index}`}
                    />
                ))}
            </div>
        </div>
    );
}

function RightSideContent() {
    const rightSideEditorView = useSelector((state) => state.documents_v2.rightSideEditorView);

    return (
        <>
            {rightSideEditorView === views.PREVIEW && <Preview />}

            {rightSideEditorView === views.ONE_LINER_CATEGORIES && <OneLinerCategories />}

            {rightSideEditorView === views.CHANGES && <Changes />}
        </>
    );
}

function Changes() {
    const document = useGetDocument();
    const { area, baseBlocks } = useGetAreaBlocks();
    const { status } = useGetDocument();
    const { activeVariantId } = useContext(AreaContext);
    const activeVariant = useGetVariant(activeVariantId);
    const [activeBlockUpdateGroup, setActiveBlockUpdateGroup] = useState(0);
    const history = useGetAreaHistory(area?.id);

    const areaBaseBlocks = useMemo(() => {
        if (activeBlockUpdateGroup > 0) {
            const group = HelperFunctions.getByValue(history, 'endGroupId', activeBlockUpdateGroup);

            return group?.blocks ?? [];
        }

        return baseBlocks;
    }, [activeBlockUpdateGroup, baseBlocks, history]);

    return (
        <>
            {status !== Constants.documentStatus.draft && (
                <>
                    <div style={{ height: 110 }}>
                        {history && history.length > 0 && (
                            <AreaHistory
                                activeBlockUpdateGroup={activeBlockUpdateGroup}
                                history={history}
                                switchVersionHistory={switchVersionHistory}
                            />
                        )}
                    </div>

                    <BaseBlocks
                        baseBlocks={areaBaseBlocks}
                        rightSideProps={getRightSideProps()}
                        area={area}
                        activeVariantId={activeVariantId}
                    />
                </>
            )}
        </>
    );

    function switchVersionHistory(startUpdateGroupId, blockUpdateGroupId) {
        setActiveBlockUpdateGroup(blockUpdateGroupId);
    }

    function getRightSideProps() {
        return {
            activeVariant,
            areaId: area.id,
            blockDisplayOptions: {
                markDeletedBlocks: true,
                canRestoreDeletedBlocks: false,
                text: {
                    ...Constants.defaultBlockDisplayOptions.text,
                    markChanges: true,
                },
                table: {
                    ...Constants.defaultBlockDisplayOptions.table,
                    markChanges: true,
                },
            },
            context: Constants.blockContext.editor,
            document,
            editorDisplaySection: Constants.editorDisplaySections.right,
            readOnly: true,
            showTableContent: true,
        };
    }
}
