import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Index from '../pages/labels/Index';
import { ReactTitle } from 'components/ReactTitle';

export const BASE_PATH = '/labels';

function Labels() {
    let { path } = useRouteMatch();

    return (
        <>
            <ReactTitle title="Labels" />

            <Switch>
                <Route path={[`${path}`, `${path}/:folderId`]} exact strict render={() => <Index />} />
            </Switch>
        </>
    );
}

export default Labels;
