import { generatePath, Link, useParams } from 'react-router-dom';
import { LightOutlineButton, NewItemButton } from 'components/Buttons';
import {
    useGetPublicationGroup,
    useGetPublicationUsersForGroup,
    useGetUserGroups,
} from 'pages/publications_v2/hooks/publicationUsers';
import { parseInt } from 'lodash';
import { useTranslation } from 'react-i18next';
import { BooleanParam, NumberParam, useQueryParam } from 'use-query-params';
import { useDeletePublicationUserMutation, useEditPublicationUserMutation } from 'features/publications/publicationApi';
import { Checkbox, TranslatableSwitch } from 'pages/publications_v2/helpers/FieldHelper';
import HelperFunctions from 'pages/global/HelperFunctions';
import { ArrowDownShort, ChevronRight, CloudDownload, PersonPlus } from 'react-bootstrap-icons';
import { USER_GROUPS_PATH } from 'scenes/PublicationsV2';
import { DeleteButton, EditButton } from 'components/ActionButtons';
import cx from 'classnames';
import { SelectAllCheckbox } from 'components/SelectAllCheckbox';
import { TableProvider, useSelectedItems, useSelectedItemsDispatch } from 'components/PaginatedTable';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import PublicationApi from 'api/PublicationApi';
import { saveAs } from 'file-saver';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';

export function ViewGroup() {
    return (
        <TableProvider>
            <RenderViewGroup />
        </TableProvider>
    );
}

function RenderViewGroup() {
    const { t } = useTranslation();
    const { id } = useParams();
    const group = useGetPublicationGroup(parseInt(id));
    const [, setAddUser] = useQueryParam('addUser', BooleanParam);
    const organisationId = useActiveOrganisation();

    if (!group) {
        return null;
    }

    return (
        <div className="dr-container p-4">
            <div className="subheader">
                <h3>
                    <Link
                        className="text-muted"
                        to={generatePath(USER_GROUPS_PATH, {
                            page: 'groups',
                        })}
                    >
                        {t('publications:accessControl.userGroups')}
                    </Link>
                    <ChevronRight className="text-muted mx-2" />
                    {group.name}
                </h3>

                <div className="d-flex align-items-center ml-auto">
                    <LightOutlineButton onClick={handleDownload}>
                        <CloudDownload />
                        <div>{t('global:btn.export')}</div>
                    </LightOutlineButton>
                    <NewItemButton
                        icon={<PersonPlus />}
                        label={t('publications:accessControl.newUserBtn')}
                        onClick={() => setAddUser(true)}
                    />
                </div>
            </div>

            <GroupUsers group={group} />
        </div>
    );

    function handleDownload() {
        PublicationApi.exportPublicationUsers({ organisationId, groupId: group.id })
            .then((response) => {
                saveAs(response.data, response.headers['x-suggested-filename'] ?? 'export.xlsx');
            })
            .catch(async (error) => {
                if (error.response.status === 400) {
                    const response = await error.response.data.text();
                    const responseObject = HelperFunctions.tryParseJSON(response);
                    HelperFunctions.alertModal(t('global:error.errorTitle', { title: responseObject.error }));
                } else {
                    HelperFunctions.alertModal(t('global:error.exportFailed'));
                }
            });
    }
}

function GroupUsers({ group }) {
    const { t } = useTranslation('publications');
    const users = useGetPublicationUsersForGroup(group);
    const { selectedItems = [] } = useSelectedItems();

    return (
        <div>
            {users.length > 0 ? (
                <div>
                    <div className="mb-2">
                        <Actions />
                    </div>

                    <table className="table dr-table">
                        <thead>
                            <tr>
                                <th>
                                    <SelectAllCheckbox items={users} />
                                </th>
                                <th>
                                    {t('publications:accessControl.headers.name')} <ArrowDownShort />
                                </th>
                                <th>{t('publications:accessControl.headers.groups')}</th>
                                <th>{t('publications:accessControl.headers.newsletter')}</th>
                                <th>{t('publications:accessControl.headers.updates')}</th>
                                <th>{t('global:btn.actions')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user) => (
                                <UserRow
                                    user={user}
                                    isSelected={selectedItems.includes(user['@id'])}
                                    key={`user-row-${user.id}`}
                                />
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={6}>
                                    {t('publications:accessControl.numUsers', { count: group.publicationUsers.length })}
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            ) : (
                <div className="small">{t('publications:accessControl.emptyGroup')}</div>
            )}
        </div>
    );
}

export function UserRow({ user, isSelected = false }) {
    const { t } = useTranslation();
    const dispatch = useSelectedItemsDispatch();
    const userGroups = useGetUserGroups();
    const [editPublicationUser] = useEditPublicationUserMutation();
    const [deletePublicationUser] = useDeletePublicationUserMutation();
    const [, setEditUserId] = useQueryParam('euid', NumberParam);

    const groupNames = getUsersGroupNames();

    return (
        <tr className={cx({ 'row-selected': isSelected })}>
            <td>
                <Checkbox
                    id={`group-checkbox-${user.id}`}
                    checked={isSelected}
                    onChange={() => {
                        dispatch({
                            type: 'toggle',
                            id: user['@id'],
                        });
                    }}
                />
            </td>
            <td>
                <div>
                    <Link
                        to={generatePath(USER_GROUPS_PATH, {
                            page: 'view-user',
                            id: user.id,
                        })}
                    >
                        {user.fullName}
                    </Link>
                </div>
                <div>{user.email}</div>
            </td>
            <td>
                <div>{groupNames.join(', ')}</div>
            </td>
            <td>
                <TranslatableSwitch
                    id={`user-${user.id}-isNewsletterActive`}
                    name={`user-${user.id}-isNewsletterActive`}
                    checked={user.isNewsletterActive}
                    onChange={() => toggleSwitch('isNewsletterActive', !user.isNewsletterActive)}
                />
            </td>
            <td>
                <TranslatableSwitch
                    id={`user-${user.id}-isDocumentUpdatesActive`}
                    name={`user-${user.id}-isDocumentUpdatesActive`}
                    checked={user.isDocumentUpdatesActive}
                    onChange={() => toggleSwitch('isDocumentUpdatesActive', !user.isDocumentUpdatesActive)}
                />
            </td>
            <td className="actions">
                <div>
                    <EditButton onClick={() => setEditUserId(user.id)} />
                    <DeleteButton onClick={handleUserDelete} />
                </div>
            </td>
        </tr>
    );

    function toggleSwitch(name, newValue) {
        editPublicationUser({
            uri: user['@id'],
            body: {
                [name]: newValue,
            },
        });
    }

    function handleUserDelete() {
        HelperFunctions.confirmModal(
            t('global:confirm.confirmDelete', { name: user.fullName }),
            'danger',
            false,
            t('global:confirm.yesDelete'),
            t('global:btn.cancel')
        )
            .then(() => {
                deletePublicationUser(user['@id']);
            })
            .catch(() => {});
    }

    /**
     * @returns {string[]}
     */
    function getUsersGroupNames() {
        return userGroups.filter((group) => user.userGroups.includes(group['@id'])).map((group) => group.name);
    }
}

function Actions() {
    const { t } = useTranslation();
    const { selectedItems = [] } = useSelectedItems();
    const [deletePublicationUser] = useDeletePublicationUserMutation();
    const dispatch = useSelectedItemsDispatch();

    return (
        <div className="d-flex align-items-center">
            <DropdownButton
                id="actions-dropdown"
                title={t('publications:accessControl.actions')}
                variant="primary"
                disabled={selectedItems.length === 0}
                size="sm"
            >
                <Dropdown.Item onClick={deleteCheckedUsers}>
                    <span className="text-danger">{t('global:btn.delete')}&hellip;</span>
                </Dropdown.Item>
            </DropdownButton>

            <div className="text-muted small ml-2">
                {selectedItems.length > 0 && (
                    <>
                        <>{t('publications:accessControl.numUsersSelected', { count: selectedItems.length })}</>
                    </>
                )}
            </div>
        </div>
    );

    function deleteCheckedUsers() {
        HelperFunctions.confirmModal(
            t('publications:accessControl.confirmUserDeleteCount', { count: selectedItems.length }),
            'danger',
            false,
            t('global:confirm.yesDelete'),
            t('global:btn.cancel')
        )
            .then(() => {
                Promise.all(selectedItems.map((userId) => deletePublicationUser(userId))).then(() => {
                    dispatch({
                        type: 'select',
                        items: [],
                    });
                });
            })
            .catch(() => {});
    }
}
