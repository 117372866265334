import { useState } from 'react';
import { read, utils } from 'xlsx';
import { Badge, Modal } from 'react-bootstrap';
import { useGetBaseVariant } from '../documents_v2/hooks/useGetBaseVariant';
import HelperFunctions from '../global/HelperFunctions';
import { FieldArray, Form as FForm, Formik } from 'formik';
import { Dropzone } from './Dropzone';
import { IconButton } from '../../components/Buttons';
import { TrashFill } from 'react-bootstrap-icons';
import { FormModal, ModalFooter } from '../global/FormModal';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { useGetDocumentVariants } from '../documents_v2/hooks/useGetDocumentVariants';
import { documentApi, useAddTagMutation, useUpdateTagMutation } from '../../features/documents/documents';
import { useActiveOrganisation } from '../../hooks/useActiveOrganisation';
import { useTranslation } from 'react-i18next';

function ImportTagsModal({ handleClose, documentTags }) {
    const activeOrganisation = useActiveOrganisation();
    const [tags, setTags] = useState([]);
    const { documentId } = useParams();
    const baseVariant = useGetBaseVariant();
    const documentVariants = useGetDocumentVariants();
    const { t } = useTranslation('documents');

    const [updateTag] = useUpdateTagMutation();
    const [addTag] = useAddTagMutation();
    const [getDocumentTag] = documentApi.useLazyGetDocumentTagQuery();

    const showDropzone = tags.length === 0;

    const formatBoolean = (textValue) => {
        if (
            textValue === 'Ja' ||
            textValue === 'j' ||
            textValue === 'Yes' ||
            textValue === 'J' ||
            textValue === '1' ||
            textValue === 1
        ) {
            return true;
        }

        return false;
    };

    const formatString = (textValue) => {
        return _.trim(textValue);
    };

    const validateRows = (rows) => {
        return rows
            .map((row) => {
                // Try to find existing tag
                const name = (row['Naam'] ?? '').replace(/\s+/g, '');
                const existingTag = HelperFunctions.getByValue(documentTags, 'name', name);

                return {
                    baseVariant: formatBoolean(row['Basisvariant'] ?? ''),
                    url: row['Link'] ?? '',
                    name,
                    new: existingTag === undefined,
                    id: existingTag ? existingTag.id : undefined,
                    description: formatString(row['Omschrijving'] ?? ''),
                    text: formatString(row['Tekst'] ?? ''),
                    tooltip: formatString(row['Tooltip'] ?? ''),
                    variants: formatString(row['Varianten'] ?? ''),
                    filteredVariants: [],
                };
            })
            .map((tag) => {
                const filteredVariants = [];

                if (tag.baseVariant) {
                    filteredVariants.push(baseVariant);
                }

                // Add selected variants only
                if (tag.variants === 'alle') {
                    // Add all variants (except the base and groups)
                    filteredVariants.push(
                        ...documentVariants.filter((variant) => variant.parentId !== null && !variant.variantGroup)
                    );
                } else if (tag.variants !== '') {
                    // Add variants by name
                    const variantNames = tag.variants.split(',').map((name) => name.trim());
                    const filteredDocumentVariants = documentVariants.filter((variant) =>
                        variantNames.includes(variant.name)
                    );

                    filteredVariants.push(...filteredDocumentVariants);
                }

                tag.filteredVariants = filteredVariants;

                return tag;
            })
            .filter((tag) => {
                return tag.filteredVariants.length > 0;
            });
    };

    const handleImport = (file) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            const wb = read(event.target.result, {raw: true});
            const sheets = wb.SheetNames;

            if (sheets.length) {
                const rows = utils.sheet_to_json(wb.Sheets[sheets[0]], {
                    raw: true,
                    rawNumbers: true,
                });

                setTags(validateRows(rows));
            }
        };

        reader.readAsArrayBuffer(file);
    };


    const createTagItem = (documentVariantId, tag) => {
        return {
            documentVariantId,
            documentVariant: documentVariantId,
            text: tag.text,
            tooltip: tag.tooltip,
            url: tag.url,
        };
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        const formData = [];

        for (const tag of values.tags) {
            let existingTagItems = [];
            const tagItems = [];

            // Append to existing tag or create new?
            const existingIndex = formData.findIndex((tagData) => tagData.name === tag.name);

            if (existingIndex >= 0) {
                existingTagItems = [...formData[existingIndex].tagItems];
            } else {
                // Get tag items from api
                if (tag.id) {
                    const { data } = await getDocumentTag({ documentId, id: tag.id }, true);
                    existingTagItems = data?.tagItems ?? [];
                }
            }

            // Loop through existing tag items to update them
            existingTagItems.forEach((tagItem) => {
                const updatedTagItem = tag.filteredVariants.find((variant) => variant.id === tagItem.documentVariantId);

                if (updatedTagItem) {
                    // Update
                    tagItems.push({
                        ...tagItem,
                        text: tag.text,
                        tooltip: tag.tooltip,
                        url: tag.url,
                    });
                } else {
                    tagItems.push(tagItem);
                }
            });

            // Add rest of tagItems
            tag.filteredVariants.forEach((variant) => {
                const existingTagItem = tagItems.find((tagItem) => tagItem.documentVariantId === variant.id);

                if (existingTagItem === undefined) {
                    tagItems.push(createTagItem(variant.id, tag));
                }
            });

            if (existingIndex < 0) {
                // New tag
                formData.push({
                    document: parseInt(documentId),
                    name: tag.name,
                    id: tag.id,
                    description: tag.description,
                    tagItems,
                });
            } else {
                formData[existingIndex].tagItems = tagItems;
            }
        }

        for (const tagData of formData) {
            if (tagData.id) {
                // Update
                await updateTag({ organisationId: activeOrganisation, id: tagData.id, body: tagData });
            } else {
                // Create
                await addTag({ organisationId: activeOrganisation, body: tagData });
            }
        }

        setSubmitting(false);
        handleClose();
    };

    return (
        <FormModal title={t('document.navbar.tags.importModal.modalTitle')} show={true} onHide={handleClose} size="lg">
            <Formik
                initialValues={{
                    tags,
                }}
                enableReinitialize={true}
                onSubmit={handleSubmit}
            >
                {({ values, isSubmitting, isValid }) => (
                    <FForm>
                        <Modal.Body>
                            {showDropzone && (
                                <div className="row mb-3">
                                    <div className="col">
                                        <Dropzone onDropComplete={(file) => handleImport(file)} />
                                    </div>
                                </div>
                            )}
                            <div className="row">
                                <div className="col">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="border-top-0">
                                                    {t('document.navbar.tags.importModal.columns.name')}
                                                </th>
                                                <th scope="col" className="border-top-0">
                                                    {t('document.navbar.tags.importModal.columns.variants')}
                                                </th>
                                                <th scope="col" className="border-top-0">
                                                    {t('document.navbar.tags.importModal.columns.text')}
                                                </th>
                                                <th scope="col" className="border-top-0">
                                                    Link
                                                </th>
                                                <th scope="col" className="border-top-0">
                                                    Tooltip
                                                </th>
                                                <th scope="col" className="border-top-0"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <FieldArray name="tags">
                                                {({ remove }) => (
                                                    <>
                                                        {values.tags?.length ? (
                                                            values.tags?.map((tag, index) => (
                                                                <TagRow
                                                                    tag={tag}
                                                                    remove={() => remove(index)}
                                                                    key={`tag-${index}`}
                                                                />
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="7" className="text-center">
                                                                    {t('document.navbar.tags.importModal.noTags')}
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </>
                                                )}
                                            </FieldArray>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Modal.Body>

                        <ModalFooter
                            isSubmitting={isSubmitting}
                            onHide={handleClose}
                            isValid={isValid}
                            dirty={values.tags.length > 0}
                        />
                    </FForm>
                )}
            </Formik>
        </FormModal>
    );
}

function TagRow({ tag, remove }) {
    const { t } = useTranslation('documents');
    return (
        <tr>
            <td className="align-middle">
                <div className="d-flex align-items-center">
                    <div>{tag.name}</div>

                    {tag.new && (
                        <Badge variant="warning" className="ml-2">
                            {t('document.navbar.tags.importModal.newBadge')}
                        </Badge>
                    )}
                </div>

                {tag.description !== '' && (
                    <div className="small pt-1">
                        <em>{tag.description}</em>
                    </div>
                )}
            </td>
            <td className="align-middle">
                {tag.filteredVariants
                    .map((variant) =>
                        variant.parentId === null ? t('document.navbar.tags.importModal.basicVariant') : variant.name
                    )
                    .join(', ')}
            </td>
            <td className="align-middle">{tag.text}</td>
            <td className="align-middle">{tag.url}</td>
            <td className="align-middle">{tag.tooltip}</td>
            <td className="align-middle">
                <IconButton
                    className="text-danger"
                    icon={<TrashFill size={16} className="text-danger" />}
                    onClick={() => remove()}
                />
            </td>
        </tr>
    );
}

export default ImportTagsModal;
