import { InputField } from 'pages/publications_v2/helpers/FieldHelper';
import { FormGroup } from 'components/SettingsSidebar/helpers/FormHelpers';
import { MultipleSelect } from 'pages/task_team/helpers/FormHelper';
import { useGetTeams } from 'pages/task_team/hooks/useGetTeams';

export function RejectionReason() {
    const teams = useGetTeams();

    return (
        <div className="sidebar-form">
            <FormGroup label="Naam *" htmlFor="name" help="De naam van de reden.">
                <InputField
                    name="settings.name"
                    props={{
                        maxLength: 255,
                        required: true,
                        id: 'name',
                        placeholder: 'Naam van de reden',
                    }}
                />
            </FormGroup>

            <FormGroup label="Teams" htmlFor="teams" help="">
                <MultipleSelect
                    options={teams}
                    name="settings.teams"
                    props={{
                        placeholder: 'Selecteer team(s)...',
                    }}
                />
            </FormGroup>
        </div>
    );
}
