import { generatePath, useHistory, useParams } from 'react-router-dom';
import {
    useUpdateEntityReferenceMutation,
    useUpdateTranslationKeyMutation,
} from '../../../../features/translations/translationApi';
import { useContext } from 'react';
import { AccordionContext, Button, Dropdown, SplitButton } from 'react-bootstrap';
import { TRANSLATE_PATH } from '../../../../scenes/Translations';
import EntityStatus, { EntityChildrenStatus } from './EntityStatus';
import { isEnabledForTranslation } from '../../helpers/EntityReferenceHelper';
import { isEnabledForTranslation as isTranslationKeyEnabledForTranslation } from '../../helpers/TranslationKeyHelper';
import Constants from '../../../../config/Constants';
import RestrictedTranslationContent from '../../RestrictedTranslationContent';
import TranslationStatus from './TranslationStatus';
import { useTranslation } from 'react-i18next';

export default function EntityOptions({
    entityReference,
    languageIso,
    showEntityStatus = true,
    showEntityChildrenStatus = false,
    showTranslationStatus = false,
    translationKey,
    entityChildren = [],
}) {
    const { translationContainerId } = useParams();
    const history = useHistory();
    const [updateEntityReference] = useUpdateEntityReferenceMutation();
    const [updateTranslationKey] = useUpdateTranslationKeyMutation();
    const currentEventKey = useContext(AccordionContext);
    const { t } = useTranslation('translations');

    const { entityType, entityId, uri } = entityReference;
    const hasEntityReference = uri !== undefined;

    const enabledForTranslation =
        translationKey !== undefined
            ? isTranslationKeyEnabledForTranslation(translationKey, languageIso)
            : isEnabledForTranslation(entityReference, languageIso);

    const handleExclusionUpdate = (enabled = true) => {
        if (translationKey !== undefined) {
            updateTranslationKey({
                uri: translationKey['@id'],
                body: {
                    enabledForTranslation: {
                        ...translationKey.enabledForTranslation,
                        [languageIso]: enabled,
                    },
                },
            });
        } else {
            updateEntityReference({
                uri: entityReference['@id'],
                body: {
                    enabledForTranslation: {
                        ...entityReference.enabledForTranslation,
                        [languageIso]: enabled,
                    },
                },
            });
        }
    };

    return (
        <div className="ml-auto d-flex align-items-center flex-shrink-0">
            {showEntityChildrenStatus && entityChildren.length > 0 && (
                <EntityChildrenStatus
                    entityReference={entityReference}
                    entityChildren={entityChildren}
                    languageIso={languageIso}
                />
            )}

            {enabledForTranslation ? (
                <>
                    {!showEntityChildrenStatus && showEntityStatus && (
                        <EntityStatus languageIso={languageIso} entityReference={entityReference} />
                    )}
                    {showTranslationStatus && (
                        <TranslationStatus
                            languageIso={languageIso}
                            translationKey={translationKey}
                            hasEntityReference={entityReference !== undefined}
                        />
                    )}

                    <RestrictedTranslationContent
                        id={translationContainerId}
                        roles={[
                            Constants.translationContainerTeamRoles.manager,
                            Constants.translationContainerTeamRoles.finalEditor,
                            Constants.translationContainerTeamRoles.editor,
                        ]}
                    >
                        <SplitButton
                            id={`${entityType}-${entityId}-btn`}
                            title={t('translation.navbar.dashboard.document.btn.edit')}
                            variant="primary"
                            className="ml-3"
                            menuAlign="right"
                            disabled={!hasEntityReference}
                            onClick={() => {
                                history.push(
                                    generatePath(TRANSLATE_PATH, {
                                        language: languageIso,
                                        translationContainerId,
                                        entityType,
                                        entityId,
                                        translationKeyId: translationKey?.id ?? undefined,
                                    })
                                );
                            }}
                        >
                            <Dropdown.Item
                                eventKey={currentEventKey}
                                onSelect={(eventKey, e) => {
                                    e.stopPropagation();
                                    handleExclusionUpdate(false);
                                }}
                            >
                                {t('translation.navbar.dashboard.document.actions.disableTranslation')}
                            </Dropdown.Item>
                        </SplitButton>
                    </RestrictedTranslationContent>

                    <RestrictedTranslationContent
                        id={translationContainerId}
                        roles={[Constants.translationContainerTeamRoles.spectator]}
                    >
                        <Button
                            className="ml-3"
                            onClick={() => {
                                history.push(
                                    generatePath(TRANSLATE_PATH, {
                                        language: languageIso,
                                        translationContainerId,
                                        entityType,
                                        entityId,
                                        translationKeyId: translationKey?.id ?? undefined,
                                    })
                                );
                            }}
                        >
                            {t('translation.navbar.dashboard.document.btn.edit')}
                        </Button>
                    </RestrictedTranslationContent>
                </>
            ) : (
                <>
                    <RestrictedTranslationContent
                        id={translationContainerId}
                        roles={[
                            Constants.translationContainerTeamRoles.manager,
                            Constants.translationContainerTeamRoles.finalEditor,
                            Constants.translationContainerTeamRoles.editor,
                        ]}
                    >
                        <Button
                            variant="success"
                            className="ml-3"
                            disabled={!hasEntityReference}
                            onClick={() => {
                                handleExclusionUpdate(true);
                            }}
                        >
                            {t('translation.navbar.dashboard.document.btn.enable')}
                        </Button>
                    </RestrictedTranslationContent>

                    <RestrictedTranslationContent
                        id={translationContainerId}
                        roles={[Constants.translationContainerTeamRoles.spectator]}
                    >
                        <Button variant="success" className="ml-auto" disabled={true}>
                            {t('translation.navbar.dashboard.document.btn.enable')}
                        </Button>
                    </RestrictedTranslationContent>
                </>
            )}
        </div>
    );
}
