import { Document } from '../../../../models/documents.models';
import { useContext } from 'react';
import AreaBlocks from '../_ViewArea/AreaBlocks';
import { useGetAreaBlocksQuery } from '../../../../features/documents/documents';
import Constants from '../../../../config/Constants';
import { useParams } from 'react-router-dom';
import { AreaContext, AreaContextType } from '../../../documents_v2/views/Area';
import { actions } from '../../../documents_v2/config/views';
import { useGetVariant } from '../../../documents_v2/hooks/useGetVariant';
import HelperFunctions from '../../../global/HelperFunctions';
import { useGetBaseVariant } from '../../../documents_v2/hooks/useGetBaseVariant';
import { useGetDocument } from '../../../documents_v2/hooks/useGetDocument';

export function Preview() {
    const document: Document | undefined = useGetDocument(undefined, true);
    const { activeVariantId } = useContext(AreaContext) as AreaContextType;
    const { areaId, view }: { areaId: string; view: string } = useParams();

    const baseVariant = useGetBaseVariant();
    const activeVariant = useGetVariant(activeVariantId);

    const { areaData, isFetching } = useGetAreaBlocksQuery(
        { areaId: parseInt(areaId), variantId: activeVariantId },
        {
            selectFromResult: ({ data, isFetching }) => ({
                areaData: data,
                isFetching,
            }),
            skip: activeVariantId === 0,
        }
    );

    if (areaData === undefined) {
        return null;
    }

    return (
        <div
            className="area-preview"
            style={{
                marginTop: 110,
                opacity: isFetching ? 0.5 : undefined,
            }}
        >
            {areaData && (
                <AreaBlocks
                    area={areaData.area}
                    areaBlocks={areaData.areaBlocks}
                    baseBlocks={areaData.baseBlocks}
                    activeVariantId={activeVariantId}
                    leftSideBlockProps={{
                        activeVariant,
                        blockDisplayOptions: {
                            showBlockToolbar: true,
                            showBlockToolbarButtons: false,
                            text: {
                                ...Constants.defaultBlockDisplayOptions.text,
                            },
                            table: {
                                ...Constants.defaultBlockDisplayOptions.table,
                            },
                        },
                        context:
                            view === actions.CHECK
                                ? Constants.blockContext.checkChanges
                                : Constants.blockContext.editor,
                        document: {
                            ...document,
                            baseVariant,
                            documentVariants: HelperFunctions.variantAsList(baseVariant),
                        },
                        readOnly: true,
                        documentVariants: areaData.area.documentVariants,
                        editorDisplaySection: Constants.editorDisplaySections.right,
                    }}
                />
            )}
        </div>
    );
}
