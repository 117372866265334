import { Redirect, Route, Switch } from 'react-router-dom';
import Tags from '../scenes/Tags';
import Constants, { Permissions } from '../config/Constants';
import Reimbursements, { BASE_PATH as REIMBURSEMENTS_BASE_PATH } from '../scenes/Reimbursements';
import Labels from '../scenes/Labels';
import PublicationsV2, { BASE_PATH as PUBLICATIONS_BASE_PATH } from '../scenes/PublicationsV2';
import MediaLibrary from '../scenes/MediaLibrary';
import DocumentsV2, { BASE_PATH as DOCUMENTS_BASE_PATH } from '../scenes/DocumentsV2';
import Translations, { BASE_PATH as TRANSLATIONS_BASE_PATH } from '../scenes/Translations';
import Account, { BASE_PATH as ACCOUNTS_BASE_PATH } from '../scenes/Account';
import { BASE_PATH as TASK_TEAM_BASE_PATH, TaskTeam } from '../scenes/TaskTeam';
import RestrictedContent, { useGetUserModules } from './global/RestrictedContent';
import OperationsList from '../scenes/OperationsList';
import { ADMIN_BASE_PATH, AdminScene } from 'scenes/Admin';
import { ACCOUNT_CANCELLED_PATH, AccountCancelled } from 'pages/account/views/AccountCancelled';
import { moduleRoutes } from 'pages/Nav';
import { BASE_PATH as OPERATIONS_LIST_BASE_PATH } from 'scenes/OperationsList';
import { BASE_PATH as MEDIA_LIBRARY_BASE_PATH } from 'scenes/MediaLibrary';
import { BASE_PATH as TAGS_BASE_PATH } from 'scenes/Tags';
import { BASE_PATH as LABELS_BASE_PATH } from 'scenes/Labels';

export default function Routes() {
    return (
        <Switch>
            <Route exact path="/">
                <RedirectToModule />
            </Route>
            <Route path="/documents/:documentId/publications">
                <Redirect to={PUBLICATIONS_BASE_PATH} />
            </Route>
            <Route
                path={PUBLICATIONS_BASE_PATH}
                render={() => (
                    <RestrictedContent
                        module={Constants.modules.publications}
                        permission={Constants.permissions.menu.publications}
                    >
                        <PublicationsV2 />
                    </RestrictedContent>
                )}
            />
            <Route
                path={DOCUMENTS_BASE_PATH}
                render={() => (
                    <RestrictedContent
                        module={Constants.modules.documents}
                        permission={Constants.permissions.menu.documents}
                    >
                        <DocumentsV2 />
                    </RestrictedContent>
                )}
            />
            <Route
                path={TAGS_BASE_PATH}
                render={() => (
                    <RestrictedContent module={Constants.modules.tags} permission={Constants.permissions.menu.tags}>
                        <Tags />
                    </RestrictedContent>
                )}
            />
            <Route
                path={LABELS_BASE_PATH}
                render={() => (
                    <RestrictedContent module={Constants.modules.labels} permission={Constants.permissions.menu.labels}>
                        <Labels />
                    </RestrictedContent>
                )}
            />
            <Route
                path={REIMBURSEMENTS_BASE_PATH}
                render={() => (
                    <RestrictedContent
                        module={Constants.modules.reimbursements}
                        permission={Constants.permissions.menu.reimbursements}
                    >
                        <Reimbursements />
                    </RestrictedContent>
                )}
            />
            <Route
                path={OPERATIONS_LIST_BASE_PATH}
                render={() => (
                    <RestrictedContent
                        module={Constants.modules.operations_list}
                        permission={Constants.permissions.menu.operations_list}
                    >
                        <OperationsList />
                    </RestrictedContent>
                )}
            />
            <Route
                path={MEDIA_LIBRARY_BASE_PATH}
                render={() => (
                    <RestrictedContent
                        module={Constants.modules.documents}
                        permission={Constants.permissions.menu.media}
                    >
                        <MediaLibrary />
                    </RestrictedContent>
                )}
            />
            <Route
                path={TRANSLATIONS_BASE_PATH}
                render={() => (
                    <RestrictedContent
                        module={Constants.modules.translations}
                        permission={Constants.permissions.menu.translations}
                    >
                        <Translations />
                    </RestrictedContent>
                )}
            />
            <Route path={ACCOUNTS_BASE_PATH} render={() => <Account />} />
            <Route path={ADMIN_BASE_PATH} render={() => <AdminScene />} />
            <Route path={ACCOUNT_CANCELLED_PATH} render={() => <AccountCancelled />} />

            <Route
                path={TASK_TEAM_BASE_PATH}
                render={() => (
                    <RestrictedContent module={Constants.modules.task_team} permission={Permissions.TaskTeam.Read}>
                        <TaskTeam />
                    </RestrictedContent>
                )}
            />

            <Route>
                <Redirect to={'/'} />
            </Route>
        </Switch>
    );
}

function RedirectToModule() {
    const modules = useGetUserModules();

    if (modules.length === 0) {
        return null;
    }

    const url = getUrl(modules);

    if (!url) {
        return null;
    }

    return <Redirect to={url} />;

    function getUrl(modules = []) {
        const url = moduleRoutes[modules[0]];

        if (url) {
            return url;
        }

        modules.shift();

        if (modules.length > 0) {
            return getUrl(modules);
        }

        return undefined;
    }
}
