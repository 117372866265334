import { useGetTeams } from 'pages/task_team/hooks/useGetTeams';
import HelperFunctions from 'pages/global/HelperFunctions';
import { FilterSelect } from 'pages/task_team/helpers/FilterHelper';
import { REVIEWS_FILTER_PARAM, useGetDefaultFilterOptions } from 'pages/task_team/views/checks/Reviews';
import { useGetAvailableUsers } from 'pages/task_team/hooks/useGetAvailableUsers';
import { useGetTaskStatusOptions } from 'pages/task_team/config/useGetTaskStatusOptions';

export function FilterAssignments() {
    const teams = useGetTeams();
    const defaultFilterOptions = useGetDefaultFilterOptions();
    const availableUsers = useGetAvailableUsers();
    const taskStatusOptions = useGetTaskStatusOptions();

    return (
        <>
            <FilterSelect
                placeholder="Toegewezen aan"
                label="Toegewezen aan..."
                options={availableUsers}
                name="assignedTo"
                defaultFilterOptions={defaultFilterOptions}
                filterParamName={REVIEWS_FILTER_PARAM}
            />

            <FilterSelect
                placeholder="Status"
                label="Status..."
                options={taskStatusOptions}
                name="status"
                defaultFilterOptions={defaultFilterOptions}
                filterParamName={REVIEWS_FILTER_PARAM}
            />

            <FilterSelect
                placeholder="Team"
                label="Team..."
                options={HelperFunctions.prepareDropdownData(teams, 'name')}
                name="team"
                filterParamName={REVIEWS_FILTER_PARAM}
            />

            <FilterSelect
                placeholder="Eigenaar"
                label="Eigenaar..."
                options={availableUsers}
                name="task.createdBy"
                filterParamName={REVIEWS_FILTER_PARAM}
            />
        </>
    );
}
