import { Form, Modal } from 'react-bootstrap';
import { Form as FForm, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { FormModal, ModalFooter } from '../../global/FormModal';
import HelperFunctions from '../../global/HelperFunctions';
import { FieldSelect } from '../../publications_v2/helpers/FieldHelper';
import { translationContainerTypes } from '../config/Constants';
import { useGetDocuments } from '../../documents_v2/hooks/useGetDocuments';
import CloneApi from '../../../api/CloneApi';
import { translationApi } from '../../../features/translations/translationApi';
import _ from 'lodash';
import { useGetCategories } from '../../reimbursements/hooks/useGetCategories';
import { idToUri } from '../../global/UriHelper';
import { useTranslation } from 'react-i18next';

export function DuplicateModal({ showModal, handleClose, translationContainer }) {
    const dispatch = useDispatch();
    const { t } = useTranslation('translations');

    if (!showModal) {
        return null;
    }

    const handleSubmit = (values, { setSubmitting }) => {
        const entity =
            translationContainer['@type'] === translationContainerTypes.DOCUMENT
                ? 'document_containers'
                : 'reimbursement_containers';

        CloneApi.createCloneTask(entity, translationContainer.id, _.toString(values.targetEntityId))
            .then(() => {
                // Invalidate tags to force reload
                dispatch({
                    type: `${translationApi.reducerPath}/invalidateTags`,
                    payload: [
                        {
                            type: 'TranslationContainer',
                            id: 'LIST',
                        },
                    ],
                });

                handleClose();
            })
            .catch(() => {
                setSubmitting(false);
            });
    };

    return (
        <FormModal show={showModal} onHide={handleClose} title={t('translation.duplicate.title')}>
            <Formik
                initialValues={{
                    targetEntityId: null,
                }}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, isValid }) => (
                    <FForm autoComplete="off">
                        <Modal.Body>
                            <p className="mb-4">
                                {translationContainer.name} {t('translation.duplicate.duplicate')}
                            </p>

                            {translationContainer['@type'] === translationContainerTypes.DOCUMENT && (
                                <TargetDocumentSelect documentId={translationContainer.documentId} />
                            )}

                            {translationContainer['@type'] === translationContainerTypes.REIMBURSEMENT && (
                                <TargetCategorySelect categoryId={translationContainer.categoryId} />
                            )}
                        </Modal.Body>

                        <ModalFooter
                            isValid={isValid}
                            isSubmitting={isSubmitting}
                            onHide={handleClose}
                            btnSubmitText={t('translation.duplicate.btn.yesDuplicate') || ''}
                        />
                    </FForm>
                )}
            </Formik>
        </FormModal>
    );
}

function TargetDocumentSelect({ documentId }) {
    const documents = useGetDocuments();
    const documentClones = documents.filter((_document) => _document.clonedFromId === documentId);
    const { t } = useTranslation('translations');

    if (documentClones.length === 0) {
        return null;
    }

    return (
        <Form.Group>
            <FieldSelect
                label={t('translation.duplicate.targetDocument')}
                name="targetEntityId"
                options={HelperFunctions.prepareDropdownData(documentClones)}
                props={{
                    placeholder: `${t('translation.duplicate.titleSelectDocument')}...`,
                    isClearable: true,
                }}
            />
        </Form.Group>
    );
}

function TargetCategorySelect({ categoryId }) {
    const categories = useGetCategories();
    const categoryClones = categories.filter((_category) => _category.clonedFrom === idToUri(categoryId, 'Category'));
    const { t } = useTranslation('translations');

    if (categoryClones.length === 0) {
        return null;
    }

    return (
        <Form.Group>
            <FieldSelect
                label={t('translation.duplicate.targetReimbursement')}
                name="targetEntityId"
                options={HelperFunctions.prepareDropdownData(categoryClones)}
                props={{
                    placeholder: `${t('translation.duplicate.titleSelectReimbursement')}...`,
                    isClearable: true,
                }}
            />
        </Form.Group>
    );
}
