import { FormModal } from '../../global/FormModal';
import { Button, Modal } from 'react-bootstrap';
import { LinkedBlocks } from '../../documents/misc/_Blocks/LinkedBlocks';
import { useTranslation } from 'react-i18next';

export function ShowLinkedBlocksModal({ block, handleClose }) {
    const { t } = useTranslation('documents');

    return (
        <FormModal
            show={true}
            onHide={handleClose}
            title={t('document.navbar.main.editor.left.blocks.modals.showLinkedBlockModal.linkedArticles')}
            size="lg"
        >
            <>
                <Modal.Body>
                    <LinkedBlocks block={block} />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="btn btn-secondary" onClick={() => handleClose()}>
                        {t('btn.close')}
                    </Button>
                </Modal.Footer>
            </>
        </FormModal>
    );
}
