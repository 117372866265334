import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthenticationTypes, handleAuthenticationChange, resetState } from 'features/security/securitySlice';
import { ReactTitle } from 'components/ReactTitle';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import SSO from './pages/authentication/SSO';
import Logout from './pages/authentication/Logout';
import LoadingSpinner from './pages/global/LoadingSpinner';
import TwoFactorLogin from './pages/authentication/TwoFactorLogin';
import AppRouter from './router/AppRouter';
import { getBaseName } from './config/Env';
import 'uikit/dist/css/uikit.min.css';
import './css/main.css';
import './App.scss';
import { Toaster } from 'react-hot-toast';
import { getRefreshToken, resetTokenData, saveTokenData } from 'api/AuthApi';
import { NumberParam, QueryParamProvider, useQueryParam } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import queryString from 'query-string';
import { Welcome } from 'pages/welcome/Welcome';
import { useLocalStorage } from 'react-storage-complete';
import { useLoginUsingRefreshTokenMutation } from 'features/security/authApi';
import { Favicon } from 'components/Favicon';
import { LoginRouter } from 'pages/authentication/LoginRouter';
import { WELCOME_BASE_PATH } from 'pages/welcome/config/Constants';

export const LOGOUT_PATH = '/logout';

export default function App() {
    const baseName = getBaseName();

    return (
        <div className="uk-width-1-1 uk-height-viewport">
            <ReactTitle title="" />
            <Favicon />
            <Toaster />

            <Router basename={baseName}>
                <QueryParamProvider
                    adapter={ReactRouter5Adapter}
                    options={{
                        searchStringToObject: queryString.parse,
                        objectToSearchString: queryString.stringify,
                    }}
                >
                    <Switch>
                        <Route path="/sso/check_token/jwt/:code">
                            <SSO />
                        </Route>
                        <Route path={LOGOUT_PATH}>
                            <Logout />
                        </Route>
                        <Route path={WELCOME_BASE_PATH} render={() => <Welcome />} />
                        <Route>
                            <Main />
                        </Route>
                    </Switch>
                </QueryParamProvider>
            </Router>
        </div>
    );
}

function Main() {
    const dispatch = useDispatch();
    const [loginUsingRefreshToken] = useLoginUsingRefreshTokenMutation();
    const { authentication } = useSelector((state) => state.security);

    const [forceOrganisationId] = useQueryParam('organisationId', NumberParam);
    const [activeOrganisationFromLocalStorage] = useLocalStorage('activeOrganisation');

    const organisationId = forceOrganisationId ?? activeOrganisationFromLocalStorage;

    useEffect(() => {
        switch (authentication) {
            case AuthenticationTypes.UNKNOWN:
                const refreshToken = getRefreshToken();

                if (_.isEmpty(refreshToken)) {
                    // Show login
                    dispatch(resetState());
                } else {
                    // Try to authenticate using refresh token
                    loginUsingRefreshToken({ refreshToken, organisationId })
                        .then(({ data }) => {
                            const { token, refreshToken } = data;

                            // Save in application
                            saveTokenData(token, refreshToken)
                                .then(() => {
                                    dispatch(handleAuthenticationChange({ organisationId }));
                                })
                                .catch(() => {
                                    resetTokenData();
                                    dispatch(resetState());
                                });
                        })
                        .catch(() => {
                            resetTokenData();
                            dispatch(resetState());
                        });
                }

                break;
        }
    }, [authentication]);

    if (authentication === AuthenticationTypes.NONE) {
        return <LoginRouter />;
    }

    if (authentication === AuthenticationTypes.PARTIAL) {
        return <TwoFactorLogin />;
    }

    if (authentication === AuthenticationTypes.FULL) {
        return <AppRouter />;
    }

    return (
        <div className="pt-5">
            <LoadingSpinner />
        </div>
    );
}
