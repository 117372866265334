import { useGetTeamQuery } from 'features/comments/commentApi';

export function useGetTeam(id) {
    const { team } = useGetTeamQuery(id, {
        selectFromResult: (result) => ({
            team: result.data,
        }),
    });

    return team;
}
