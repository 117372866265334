import React, { useCallback, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import BlockContainer from '../BlockParser';
import { getBlockData } from '../../../global/BlockLayoutHelper';
import cx from 'classnames';
import { LayoutBlockPagination } from '../../../documents_v2/views/edit_area/blocks/block_types/BlockLayoutBlock';

export default function BlockLayout(props) {
    return <ReadOnlyBlockLayout {...props} />;
}

function ReadOnlyBlockLayout(props) {
    const [itemOffset, setItemOffset] = useState(0);
    const { block, baseBlocks, itemsPerPage = 20, context, editorDisplaySection } = props;

    const blockData = useMemo(() => {
        return getBlockData(block);
    }, [block]);

    const blockLayout = blockData.data;
    const showTableHeader = block.properties?.showTableHeader ?? true;

    const pageCount = Math.ceil(blockLayout.length / itemsPerPage);

    const handlePageClick = useCallback(
        (event) => {
            const newOffset = event.selected * itemsPerPage;
            setItemOffset(newOffset);
        },
        [itemsPerPage]
    );

    const currentItems = useMemo(() => {
        const newPageCount = Math.ceil(blockLayout.length / itemsPerPage);
        let tempOffset = itemOffset;

        if (newPageCount < pageCount) {
            tempOffset = 0;
        }

        const endOffset = tempOffset + itemsPerPage;

        return blockLayout.slice(tempOffset, endOffset);
    }, [blockLayout, itemsPerPage, itemOffset]);

    return (
        <div className={cx({ 'p-3': context === 'checkChanges' && editorDisplaySection === 'left' })}>
            {blockLayout.length >= itemsPerPage && (
                <div className="pt-4">
                    <LayoutBlockPagination
                        currentItemsLength={currentItems.length}
                        handlePageClick={handlePageClick}
                        itemOffset={itemOffset}
                        pageCount={pageCount}
                        totalItemsLength={blockLayout.length}
                        itemsPerPage={itemsPerPage}
                        readOnly={true}
                    />
                </div>
            )}

            <div
                className={cx(`block-layout blockLayout-type-${block.properties.type ?? 'default'}`, {
                    'show-table-header': showTableHeader,
                })}
                style={{
                    paddingLeft: 15,
                    paddingRight: 15,
                }}
            >
                {currentItems.map((el, ind) => (
                    <Row
                        className="flex-grow-1 flex-nowrap"
                        style={{
                            marginBottom: ind < currentItems.length - 1 ? -1 : undefined,
                        }}
                        key={'readonly-' + block.key + '-row-' + ind}
                    >
                        {el.map((col, colIndex) => (
                            <div
                                className="col border-muted border pt-4 py-2"
                                key={'readonly-' + block.key + '-col-' + colIndex}
                            >
                                {col.map((item, index) => (
                                    <ReadOnlyLayoutItem
                                        {...props}
                                        block={baseBlocks.find((_block) => _block.key === item.id)}
                                        key={`readonly-block-${block.key}-layout-${item.id}-${index}`}
                                    />
                                ))}
                            </div>
                        ))}
                    </Row>
                ))}
            </div>
        </div>
    );
}

function ReadOnlyLayoutItem(props) {
    const { block } = props;

    if (!block || block.deletedAt) {
        return null;
    }

    return (
        <div style={{ marginBottom: '-1.5rem' }}>
            <BlockContainer {...props} baseBlock={block} blockId={block.id} readOnly={true} />
        </div>
    );
}
