import React, { useState } from 'react';
import _ from 'lodash';

export const EditContext = React.createContext({
    selectedEntities: [],
    setSelectedEntities: () => {
    },
    getSelectedEntity: () => {
    },
    toggleEntity: () => {
    },
    entityIsSelected: () => {
    },
    search: '',
    setSearch: () => {
    },
    showSidebar: false,
    setShowSidebar: () => {
    },
});

export default function EditContextWrapper(props) {
    const [selectedEntities, setSelectedEntities] = useState([]);
    const [search, setSearch] = useState('');
    const [showSidebar, setShowSidebar] = useState(false);

    const entityIsSelected = (uri, property = undefined) => {
        return selectedEntities.find(({ entityReference, translationKey }) => {
            if (entityReference.uri !== uri) {
                return false;
            }

            if (property === undefined) {
                return true;
            }

            return translationKey?.property === property;
        }) !== undefined;
    };

    const getSelectedEntity = (uri, property = undefined) => {
        return selectedEntities.find(({ entityReference, translationKey }) => {
            if (entityReference.uri !== uri) {
                return false;
            }

            if (property === undefined) {
                return true;
            }

            return translationKey?.property === property;
        });
    };

    const toggleEntity = (payload, fromCheckbox = false) => {
        const { id, translationKey } = payload;
        const isSelected = entityIsSelected(id, translationKey?.property);

        if (!isSelected) {
            // Add it
            if (fromCheckbox) {
                setSelectedEntities([
                    ...selectedEntities,
                    payload,
                ]);
            } else {
                setSelectedEntities([payload]);
                setShowSidebar(true);
            }
        } else {
            // Remove it
            if (fromCheckbox) {
                setSelectedEntities(selectedEntities.filter(({ entityReference, translationKey }) => {
                    return !(entityReference.uri === id && _.get(payload, 'translationKey.property', '') === _.get(translationKey, 'property', ''));
                }));
            } else {
                if (selectedEntities.length > 1) {
                    setSelectedEntities([payload]);
                } else {
                    setSelectedEntities([]);
                }
            }
        }
    };

    return (
        <EditContext.Provider value={{
            selectedEntities,
            setSelectedEntities,
            getSelectedEntity,
            toggleEntity,
            entityIsSelected,
            search,
            setSearch,
            showSidebar,
            setShowSidebar,
        }}>
            {props.children}
        </EditContext.Provider>
    );
}
