import { useContext, useMemo } from 'react';
import HelperFunctions from '../../global/HelperFunctions';
import { filterVariants } from './_EditArea/EditAreaSidebar';
import Select from 'react-select';
import { Button } from 'react-bootstrap';
import { ArrowCounterclockwise, CircleFill } from 'react-bootstrap-icons';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { findDeep } from 'deepdash-es/standalone';
import { AreaContext } from '../../documents_v2/views/Area';
import { actions } from '../../documents_v2/config/views';
import { Notification } from 'components/Notification';
import { useGetBaseVariant } from '../../documents_v2/hooks/useGetBaseVariant';
import { useTranslation } from 'react-i18next';

export default function VariantViewButtons({ area, changesDetected = false }) {
    const { activeVariantId, setActiveVariantId } = useContext(AreaContext);
    const { view } = useParams();
    const baseVariant = useGetBaseVariant();
    const { t } = useTranslation('documents');

    const { numberOfEdits } = area;
    const baseVariantName = t('document.navbar.main.editor.left.subnav.selectVariant');

    const getVariantCount = (variantId) => {
        if (view === actions.CHECK) {
            const variantEdits = HelperFunctions.getByValue(numberOfEdits.unprocessedEdits.variants, 'id', variantId);

            return variantEdits ? variantEdits.count : 0;
        }

        return 0;
    };

    const handleVariantChange = (newVariantId) => {
        if (activeVariantId === newVariantId) {
            return;
        }

        if (changesDetected) {
            HelperFunctions.confirmModal(
                'confirmCancelChanges',
                'danger',
                true,
                t('document.navbar.main.editor.left.subnav.confirmSelection'),
            )
                .then(() => {
                    setActiveVariantId(newVariantId);
                })
                .catch(() => {});
        } else {
            setActiveVariantId(newVariantId);
        }
    };

    const variants = useMemo(() => {
        if (baseVariant === undefined) {
            return [];
        }

        const filtered = HelperFunctions.flatten(filterVariants(baseVariant.children, area.documentVariants));
        const localVariants = [];
        const baseVariantCount = getVariantCount(baseVariant.id);

        // Check if any of the variant have edits
        const variantsHaveEdits = filtered.some((variant) =>
            variant.parentId === null ? false : getVariantCount(variant.id) > 0,
        );

        localVariants.push({
            label: (
                <VariantSelectRow
                    name={baseVariantName}
                    checkCount={baseVariantCount}
                    showWarningIndicator={variantsHaveEdits}
                />
            ),
            value: baseVariant.id,
            variant: {
                prefix: '',
                name: baseVariantName,
            },
        });

        filtered
            .filter((_variant) => _variant.parentId === baseVariant.id || _variant.variantGroup)
            .forEach((_variant) => {
                if (_variant.variantGroup) {
                    localVariants.push({
                        label: <VariantSelectRow name={_variant.name} prefix={_variant.prefix} />,
                        options: filtered
                            .filter(
                                (_childVariant) =>
                                    _childVariant.parentId === _variant.id && _childVariant.variantGroup === false,
                            )
                            .map((_childVariant) => {
                                const childVariantCount = getVariantCount(_childVariant.id);

                                return {
                                    label: (
                                        <VariantSelectRow
                                            name={_childVariant.name}
                                            prefix={_childVariant.prefix}
                                            checkCount={childVariantCount}
                                        />
                                    ),
                                    value: _childVariant.id,
                                    variant: {
                                        prefix: _childVariant.prefix ?? '',
                                        name: _childVariant.name,
                                    },
                                };
                            }),
                    });
                } else {
                    const variantCount = getVariantCount(_variant.id);

                    localVariants.push({
                        label: (
                            <VariantSelectRow name={_variant.name} prefix={_variant.prefix} checkCount={variantCount} />
                        ),
                        value: _variant.id,
                        variant: {
                            prefix: _variant.prefix ?? '',
                            name: _variant.name,
                        },
                    });
                }
            });

        return localVariants;
    }, [baseVariant, area.documentVariants, numberOfEdits, baseVariantName]);

    return (
        <>
            {variants.length > 0 && (
                <VariantViewButtonsSelect variants={variants} handleVariantChange={handleVariantChange} />
            )}

            {baseVariant && activeVariantId !== 0 && baseVariant.id !== activeVariantId && (
                <>
                    <Button
                        variant="link"
                        className="px-2 mr-3"
                        onClick={() => handleVariantChange(baseVariant.id)}
                        data-uk-tooltip={t('document.navbar.main.editor.left.subnav.backToBasicVariant')}
                    >
                        <ArrowCounterclockwise /> {t('document.navbar.main.editor.left.subnav.selectVariant')}
                    </Button>
                </>
            )}
        </>
    );
}

export function VariantSelectRow({ name, prefix = null, checkCount = 0, showWarningIndicator = false }) {
    return (
        <div className="d-flex align-items-center justify-content-between">
            <div>
                {!_.isNil(prefix) && <span className="text-muted">{prefix}&nbsp;&nbsp;</span>}
                <span>{name}</span>
            </div>

            {checkCount > 0 && <Notification count={checkCount} />}
            {checkCount === 0 && showWarningIndicator && <CircleFill className="text-danger" size={8} />}
        </div>
    );
}

function VariantViewButtonsSelect({ variants, handleVariantChange }) {
    const { activeVariantId } = useContext(AreaContext);

    const filterOptions = ({ label, value, data }, input) => {
        if (_.isEmpty(input)) {
            return true;
        }

        const { variant } = data;
        const title = variant.prefix + variant.name;

        return title.toLowerCase().includes(input.toLowerCase());
    };

    const selectedOption = useMemo(() => {
        const option = findDeep(
            variants,
            (value) => {
                const option = value;

                if (option.hasOwnProperty('value') === false) {
                    return undefined;
                }

                return option.value === activeVariantId;
            },
            {
                childrenPath: 'options',
            },
        );

        if (option) {
            return option.value;
        }

        return undefined;
    }, [activeVariantId, variants]);

    return (
        <Select
            placeholder=""
            styles={customStyles}
            options={variants}
            value={selectedOption}
            onChange={(item) => handleVariantChange(item.value)}
            className="variant-view"
            classNamePrefix="variant-view"
            filterOption={filterOptions}
            menuPortalTarget={document.body}
            theme={(theme) => {
                return {
                    ...theme,
                    borderRadius: 4,
                };
            }}
        />
    );
}

const customStyles = {
    groupHeading: (provided) => ({
        ...provided,
        color: '#255478',
        fontWeight: 'bold',
        paddingTop: 4,
        paddingBottom: 4,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 200 }),
};
