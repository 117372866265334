import { useParams } from 'react-router-dom';
import { List } from '../organisations/List';
import { Edit } from '../organisations/Edit';
import { View } from '../organisations/View';
import { Create } from '../organisations/Create';

const RenderAction = {
    list: List,
    view: View,
    edit: Edit,
    create: Create,
};

export function Organisations({ account }) {
    const { action = 'list' } = useParams();

    const ViewToRender = RenderAction[action] ?? DefaultView;

    return <ViewToRender account={account} />;
}

function DefaultView() {
    return <div>no view</div>;
}
