import { useEffect, useState } from 'react';
import EditorHelper from '../../../global/EditorHelper';
import Modal from '../../../global/Modal';
import CloseButton from '../../../global/CloseButton';
import HelperFunctions from '../../../global/HelperFunctions';
import TreeMenu from 'react-simple-tree-menu';
import { useGetDocuments } from 'pages/documents_v2/hooks/useGetDocuments';
import { documentApi } from 'features/documents/documents';

export default function CrossRefModal(props) {
    const [selectedEntity, setSelectedEntity] = useState(false);

    const insertRef = () => {
        EditorHelper.insertCrossReference(
            selectedEntity[selectedEntity.entity],
            selectedEntity.entity,
            props.editor,
            selectedEntity.document
        );
        props.toggleCrossRefModal();
    };

    return (
        <Modal opened={true} modalStyle="centeredAutoWidth" onRequestClose={props.toggleCrossRefModal}>
            <div className="uk-width-968-fixed uk-padding-medium" style={{ maxHeight: '90vh' }}>
                <div className="uk-grid">
                    <div className="uk-width-1-1">
                        <CloseButton className="uk-float-right" close={props.toggleCrossRefModal} />
                        <h2 className="uk-margin-remove-top uk-margin-bottom">Kruisverwijzing invoegen</h2>

                        {selectedEntity && <div>{selectedEntity.title}</div>}

                        <DocumentsTree setEntity={setSelectedEntity} />

                        <div className="uk-clearfix uk-padding-top uk-padding-bottom">
                            <button
                                className="uk-button uk-button-primary uk-float-right"
                                disabled={selectedEntity === false}
                                onClick={insertRef}
                            >
                                Invoegen
                            </button>
                            <button
                                className="uk-button uk-button-light uk-float-right uk-margin-small-right"
                                onClick={props.toggleCrossRefModal}
                            >
                                Sluiten
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

function DocumentsTree({ setEntity }) {
    const allDocuments = useGetDocuments();
    const [documents, setDocuments] = useState([]);
    const [treeData, setTreeData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [getDocument] = documentApi.useLazyGetDocumentQuery();

    useEffect(() => {
        setDocuments(allDocuments);
    }, [allDocuments]);

    useEffect(() => {
        setTreeData(getTreeData(documents, isLoading));
    }, [documents, isLoading]);

    return <TreeMenu data={treeData} hasSearch={false} onClickItem={onClickItem} />;

    function getTreeData(documents = [], isLoading = false) {
        if (documents.length === 0) {
            return [];
        }

        return documents.map((document) => {
            return {
                key: 'first-level-' + document.id,
                label: document.name,
                entity: 'document',
                entityId: document.id,
                documentId: document.id,
                nodes: document.sections
                    ? document.sections.map((section) => {
                          return {
                              key: 'second-level-' + document.id + '-' + section.id,
                              label: section.title,
                              entity: 'section',
                              entityId: section.id,
                              documentId: document.id,
                              nodes: section.areas
                                  ? section.areas.map((area) => {
                                        return {
                                            key: 'third-level-' + document.id + '-' + section.id + '-' + area.id,
                                            label: area.title,
                                            entity: 'area',
                                            entityId: area.id,
                                            documentId: document.id,
                                            nodes: [],
                                        };
                                    })
                                  : [],
                          };
                      })
                    : [
                          {
                              key: 'second-level-' + document.id + '-0',
                              label: isLoading ? 'Bezig met laden...' : 'Klik om document te laden',
                              entity: 'placeholder',
                              entityId: false,
                              documentId: document.id,
                              nodes: [],
                          },
                      ],
            };
        });
    }

    function onClickItem(item) {
        switch (item.entity) {
            case 'document':
            case 'placeholder':
                setEntity(false);
                const currentDocument = HelperFunctions.getByValue(documents, 'id', item.documentId);

                // No need to load data
                if (currentDocument.hasOwnProperty('sections')) {
                    break;
                }

                setLoading(true);

                getDocument({ id: item.documentId }).then(({ data }) => {
                    const documentData = data;
                    const sections = trimSections(documentData.sections);

                    const newDocuments = documents.map((document) => {
                        if (document.id === documentData.id) {
                            return {
                                id: document.id,
                                name: document.name,
                                sections,
                            };
                        }

                        return document;
                    });

                    setLoading(false);
                    setDocuments(newDocuments);
                });
                break;
            case 'area':
                setEntity({
                    ...item,
                    area: {
                        id: item.entityId,
                        title: item.label,
                    },
                    document: {
                        id: item.documentId,
                    },
                });
                break;
            case 'section':
                setEntity({
                    ...item,
                    section: {
                        id: item.entityId,
                        title: item.label,
                    },
                    document: {
                        id: item.documentId,
                    },
                });
                break;
        }
    }

    function trimSections(sections = []) {
        return sections.map((section) => {
            return {
                id: section.id,
                title: section.title,
                areas: trimAreas(section.areas),
            };
        });
    }

    function trimAreas(areas = []) {
        return areas.map((area) => {
            return {
                id: area.id,
                title: area.title,
            };
        });
    }
}
