import { useFormikContext } from 'formik';
import _ from 'lodash';
import { useContext, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import HelperFunctions from '../../../pages/global/HelperFunctions';
import RestrictedContent from '../../../pages/global/RestrictedContent';
import { Permissions } from 'config/Constants';
import { InputMultipleSelect } from 'pages/publications_v2/helpers/FieldHelper';
import { idToUri } from 'pages/global/UriHelper';
import { SlideContext } from 'pages/global/Sidebar';
import { EditVariantFormContent } from 'pages/labels/EditVariantFormContent';
import { useGetLabelFolders } from 'pages/labels/hooks/useGetLabelFolders';
import { useGetLabels } from 'pages/labels/hooks/useGetLabels';
import { useSidebarContext } from '../hooks/useSidebarContext';
import { useEntityTemplateParameters } from 'pages/documents_v2/hooks/useEntityTemplateParameters';
import { useGetDocument } from 'pages/documents_v2/hooks/useGetDocument';
import { useTranslation } from 'react-i18next';
import { metaApi, useGetLabelEntitiesQuery } from 'features/metadata/metadata';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import LoadingSpinner from '../../../pages/global/LoadingSpinner';
import { LightOutlineButton } from 'components/Buttons';

export function EditLabels() {
    const { entity, entityType } = useSidebarContext();
    const labelFolders = useGetLabelFolders(entityType);
    const { t } = useTranslation('documents');
    const activeOrganisation = useActiveOrganisation();

    const { entityLabelEntities, entityLabelsLoading } = useGetLabelEntitiesQuery(
        {
            entityType,
            entityId: entity.id,
            organisationId: activeOrganisation,
        },
        {
            selectFromResult: ({ data, isLoading }) => ({
                entityLabelEntities: data ?? [],
                entityLabelsLoading: isLoading,
            }),
        }
    );

    const { isLoading, isUninitialized } = metaApi.endpoints.getLabelFolders.useQueryState({
        organisationId: activeOrganisation,
    });

    if (!isUninitialized && !isLoading && labelFolders.length === 0) {
        return <div>{t('document.navbar.main.settingsSidebar.labels.noLabels')}</div>;
    }

    return (
        <RestrictedContent permission={Permissions.LabelEntity['Read.All']}>
            <MetaForm
                entityLabelEntities={entityLabelEntities}
                labelFolders={labelFolders}
                isLoading={isLoading || entityLabelsLoading}
            />
        </RestrictedContent>
    );
}

function MetaForm({ entityLabelEntities = [], labelFolders = [], isLoading = false }) {
    const { entity, entityType, documentId } = useSidebarContext();
    const hasVariants = entity.hasOwnProperty('documentVariants');
    const { size, setSize } = useContext(SlideContext);
    const [showVariants, setShowVariants] = useState(false);
    const { values, setFieldValue } = useFormikContext();
    const document = useGetDocument(documentId);
    const restrictions = useEntityTemplateParameters(entity, entityType, document);
    const { t } = useTranslation('documents');

    // const hasVariantData = hasVariants
    //     ? entityLabelEntities.some((labelEntity) => labelEntity.variantId !== null)
    //     : false;

    const toggleLabel = (labels, actionValue) => {
        const { action, removedValue, name } = actionValue;

        if (action === 'remove-value') {
            setFieldValue(
                'entityLabels',
                values.entityLabels.filter((_label) => _label.id !== removedValue.id)
            );
        } else if (action === 'clear') {
            if (name.startsWith('variant')) {
                const parts = name.split('|');

                // Extract variant id
                const variantId = parseInt(parts[0].substring(8));

                // Extract folder id
                const folderName = parts[1].substring(7);
                const folderId = folderName === 'root' ? null : idToUri(folderName, 'LabelFolder');

                setFieldValue(
                    'entityLabels',
                    values.entityLabels.filter((_label) => {
                        if (_label.labelEntity.variantId === variantId) {
                            return _label.folder !== folderId;
                        }

                        return true;
                    })
                );
            } else {
                const folderName = name.substring(7);
                const folderId = folderName === 'root' ? null : idToUri(folderName, 'LabelFolder');

                setFieldValue(
                    'entityLabels',
                    values.entityLabels.filter((_label) => {
                        if (_label.labelEntity.variantId === null) {
                            return _label.folder !== folderId;
                        }

                        return true;
                    })
                );
            }
        } else {
            setFieldValue(
                'entityLabels',
                _.unionWith(values.entityLabels, labels, _.isEqual).sort(HelperFunctions.dynamicSort('name'))
            );
        }
    };

    const formContent = <PrimaryFormContent toggleLabel={toggleLabel} labelFolders={labelFolders} />;

    return (
        <>
            {isLoading && (
                <div className="position-absolute" style={{ top: '1.7rem', left: '2rem' }}>
                    <LoadingSpinner size="small" inline={true} />
                </div>
            )}

            <RestrictedContent permission={Permissions.LabelEntity['Write.All']}>
                {hasVariants && (
                    <div className="d-flex flex-row-reverse mb-3">
                        <LightOutlineButton
                            disabled={entity.documentVariants.length < 2 || restrictions?.canChangeLabels === false}
                            // variant={hasVariantData ? 'primary' : 'outline-secondary'}
                            onClick={() => {
                                setSize(showVariants ? 'sm' : 'lg');
                                setShowVariants(!showVariants);
                            }}
                        >
                            {t('document.navbar.main.settingsSidebar.labels.btn.perVariant')}
                            {size === 'sm' ? (
                                <ChevronRight className="ml-2 mr-0" />
                            ) : (
                                <ChevronLeft className="ml-2 mr-0" />
                            )}
                        </LightOutlineButton>
                    </div>
                )}
            </RestrictedContent>

            {showVariants ? (
                <div className="label-container dr-side-container px-0">
                    <EditVariantFormContent
                        documentId={documentId}
                        documentVariantIds={entity.documentVariants}
                        toggleLabel={toggleLabel}
                        entityType={entityType}
                    >
                        <>{formContent}</>
                    </EditVariantFormContent>
                </div>
            ) : (
                <div className="label-container dr-side-container">
                    <div className="pl-3 overflow-auto">{formContent}</div>
                </div>
            )}
        </>
    );
}

function PrimaryFormContent({ toggleLabel, labelFolders }) {
    return (
        <>
            {labelFolders.map((_folder) => (
                <MetaFolder folder={_folder} toggleLabel={toggleLabel} key={`meta-folder-${_folder.id}`} />
            ))}
        </>
    );
}

function MetaFolder({ folder, toggleLabel }) {
    const { id, name } = folder;
    const { values } = useFormikContext();
    const labels = useGetLabels(folder['@id']);
    const { t } = useTranslation('documents');

    return (
        <>
            <div className="mb-2">{name}</div>

            <div className="mb-3">
                <InputMultipleSelect
                    name={`labels-${id}`}
                    options={HelperFunctions.prepareDropdownData(labels, 'name', '@id')}
                    onChange={(selectedOptions, action) => {
                        const selectedLabels = selectedOptions
                            ? selectedOptions.map((option) => {
                                  if (!option.labelEntity) {
                                      return {
                                          ...option,
                                          labelEntity: {
                                              variantId: null,
                                          },
                                      };
                                  }

                                  return option;
                              })
                            : null;

                        toggleLabel(selectedLabels, action);
                    }}
                    props={{
                        placeholder: `${t('document.navbar.main.settingsSidebar.labels.selectTitle')}...`,
                    }}
                    value={values.entityLabels.filter(
                        (_label) =>
                            _label.folder === folder['@id'] && _label?.labelEntity?.variantId === null
                    )}
                />
            </div>
        </>
    );
}
