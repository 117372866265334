import { useAppDispatch } from '../../../../store';
import { Button } from 'react-bootstrap';
import React from 'react';
import cx from 'classnames';
import { BagPlusFill, ChevronRight, PencilSquare } from 'react-bootstrap-icons';
import RestrictedReimbursementContent from '../../RestrictedReimbursementContent';
import Constants from '../../../../config/Constants';
import AssignLabelButton from '../../../labels/AssignLabelButton';
import { setEditReimbursement } from '../../../../features/reimbursements/reimbursementSlice';
import { Draggable } from 'react-beautiful-dnd';
import { ReimbursementProductsMemo } from './ReimbursementProducts';
import { EditCounterMemo } from './ProductEdits';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { VIEW_PATH } from '../../../../scenes/Reimbursements';
import { useGetReimbursement } from '../../../../features/reimbursements/reimbursementHooks';
import { RxDragHandleDots2 } from 'react-icons/rx';
import { TeamAvatars } from './TeamAvatars';
import { useTranslation } from 'react-i18next';

export default function CategoryReimbursementWrapper({
    reimbursementId,
    categoryUri,
    categoryId,
    index,
}: {
    reimbursementId: number;
    categoryUri: string;
    categoryId: number;
    index: number;
}) {
    const params: { reimbursementId: string | undefined } = useParams();
    const expanded = params.reimbursementId === `${reimbursementId}`;

    return (
        <CategoryReimbursementMemo
            reimbursementId={reimbursementId}
            categoryId={categoryId}
            categoryUri={categoryUri}
            index={index}
            expanded={expanded}
        />
    );
}

function CategoryReimbursement({
    reimbursementId,
    categoryUri,
    categoryId,
    index,
    expanded,
}: {
    reimbursementId: number;
    categoryUri: string;
    categoryId: number;
    index: number;
    expanded: boolean;
}) {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { t } = useTranslation('reimbursements');
    const reimbursement = useGetReimbursement(categoryId, reimbursementId);

    if (!reimbursement) {
        return null;
    }

    const isDisabled = !reimbursement.enabled;

    return (
        <Draggable draggableId={reimbursement['@id']} index={index} isDragDisabled={expanded}>
            {(provided, snapshot) => {
                return (
                    <div
                        className={cx('data-table-item-wrapper', {
                            'is-dragging': snapshot.isDragging,
                        })}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <div
                            className={cx('data-table-item', {
                                'data-table-item-expanded': expanded,
                                'data-table-item-disable': isDisabled,
                            })}
                        >
                            <div
                                className="data-table-item-header pl-1 pr-4 py-3"
                                onClick={() => {
                                    if (!isDisabled) {
                                        handleClick();
                                    }
                                }}
                            >
                                <div className="d-flex align-items-center">
                                    <div className="d-flex align-items-center mr-3">
                                        <RxDragHandleDots2
                                            size={12}
                                            className="icon-grip text-muted mr-1"
                                            style={{ minWidth: 12 }}
                                            onClick={(event) => event.stopPropagation()}
                                        />

                                        <ChevronRight size={14} className="chevron mr-3" style={{ minWidth: 14 }} />

                                        <BagPlusFill
                                            size={18}
                                            className={cx('mr-2', {
                                                'text-dark-blue': !isDisabled,
                                                'text-muted': isDisabled,
                                            })}
                                            style={{ minWidth: 18 }}
                                        />

                                        <div
                                            className={cx({
                                                'text-secondary': !isDisabled,
                                                'text-muted': isDisabled,
                                            })}
                                            style={{ minWidth: 50 }}
                                        >
                                            {reimbursement.code}
                                        </div>
                                    </div>

                                    <div
                                        className="d-flex flex-column"
                                        style={{ marginBottom: reimbursement.description !== '' ? -5 : undefined }}
                                    >
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex align-items-center field-title mr-3">
                                                {reimbursement.name}
                                                {isDisabled && <> {t('reimbursement.product.inactive')}</>}
                                            </div>

                                            {reimbursement?.teams?.length > 0 && (
                                                <div className="mr-2">
                                                    <TeamAvatars teams={reimbursement?.teams ?? []} />
                                                </div>
                                            )}

                                            <RestrictedReimbursementContent
                                                categoryId={categoryId}
                                                roles={[
                                                    Constants.reimbursementTeamRoles.admin,
                                                    Constants.reimbursementTeamRoles.manager,
                                                ]}
                                            >
                                                <Button
                                                    variant="icon"
                                                    className="flex-shrink-0 btn-icon-only btn-icon-light p-0 mx-1"
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        dispatch(
                                                            setEditReimbursement({
                                                                ...reimbursement,
                                                                category: categoryUri,
                                                            }),
                                                        );
                                                    }}
                                                    data-uk-tooltip={t('reimbursement.product.tooltip.edit')}
                                                >
                                                    <PencilSquare size={14} />
                                                </Button>
                                            </RestrictedReimbursementContent>

                                            <RestrictedReimbursementContent
                                                roles={[
                                                    Constants.reimbursementTeamRoles.editor,
                                                    Constants.reimbursementTeamRoles.finalEditor,
                                                    Constants.reimbursementTeamRoles.manager,
                                                    Constants.reimbursementTeamRoles.admin,
                                                ]}
                                            >
                                                <AssignLabelButton
                                                    context={{
                                                        entity: {
                                                            ...reimbursement,
                                                            title: reimbursement.name,
                                                        },
                                                        entityType: 'reimbursement',
                                                    }}
                                                />
                                            </RestrictedReimbursementContent>
                                        </div>

                                        {reimbursement.description !== '' && (
                                            <div
                                                className={cx('d-inline-block text-truncate', {
                                                    'text-secondary': !isDisabled,
                                                    'text-muted': isDisabled,
                                                })}
                                                style={{ fontSize: 11 }}
                                            >
                                                {reimbursement.description}
                                            </div>
                                        )}
                                    </div>

                                    {reimbursement.unprocessedEdits > 0 && (
                                        <div className="ml-auto">
                                            <EditCounterMemo
                                                unprocessedEdits={reimbursement.unprocessedEdits}
                                                reimbursementId={reimbursementId}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className={cx('collapse', { show: expanded })}>
                                <div className="pt-1 px-4 pb-3">
                                    {expanded && (
                                        <ReimbursementProductsMemo
                                            reimbursementId={reimbursementId}
                                            categoryId={categoryId}
                                            categoryUri={categoryUri}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }}
        </Draggable>
    );

    function handleClick() {
        history.push(
            generatePath(VIEW_PATH, {
                categoryId,
                reimbursementId: expanded ? undefined : reimbursementId,
            }),
        );
    }
}

const CategoryReimbursementMemo = React.memo(CategoryReimbursement);
