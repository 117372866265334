import { useDispatch, useSelector } from 'react-redux';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Accordion, AccordionContext, Badge, Col, Container, Row } from 'react-bootstrap';
import cx from 'classnames';
import { CaretRightFill, Display, FileEarmarkLock2, JournalRichtext, PersonLock } from 'react-bootstrap-icons';
import { publicationIsSelected, selectPublicationById, toggleRow } from 'features/publications/publicationsSlice';
import { PublicationRowDetails } from '../publication_row/PublicationRowDetails';
import Constants, { Permissions } from '../../../../config/Constants';
import _ from 'lodash';
import RestrictedContent, { useHasPermission } from '../../../global/RestrictedContent';
import { RxDragHandleDots2 } from 'react-icons/rx';
import { useGetDocuments } from 'pages/documents_v2/hooks/useGetDocuments';
import { generatePath, Link, useHistory, useParams } from 'react-router-dom';
import { EDIT_PATH } from 'scenes/PublicationsV2';
import { Checkbox } from '../../helpers/FieldHelper';
import { PrimaryButton } from 'components/Buttons';
import { useTranslation } from 'react-i18next';
import { InView, useInView } from 'react-intersection-observer';
import { PublicationStatusBadgeWithTooltip } from './PublicationStatusBadge';
import { useGetPublicationVersions } from 'pages/publications_v2/hooks/useGetPublicationVersions';
import { VIEW_PATH } from 'scenes/DocumentsV2';

export default function Publication({
    publication,
    setShowExportChangesModal,
    setShowPublishModal,
    setShowDuplicateModal,
}) {
    const history = useHistory();
    const { folderId } = useParams();
    const { t } = useTranslation('publications');

    return (
        <ScrollablePublication currentPublicationId={publication.id}>
            <PublicationRowWrapper publicationId={publication.id}>
                <Container fluid>
                    <Accordion.Toggle
                        as="div"
                        className="row align-items-center"
                        eventKey={`publication-${publication.id}`}
                    >
                        <Col
                            className={cx({
                                'col-md-5': !publication.multiVersions,
                                'col-md-4': publication.multiVersions,
                            })}
                        >
                            <div className="d-flex cursor-pointer">
                                <PublicationName publicationId={publication.id} />
                                <CaretRightFill className="caret flex-shrink-0 ml-3" size={12} />
                            </div>
                        </Col>
                        <Col md={2}>
                            <PublicationStatus publicationId={publication.id} />
                        </Col>
                        {publication.multiVersions && (
                            <InView threshold={0.75}>
                                {({ inView, ref }) => (
                                    <Col md={2}>
                                        <div ref={ref}>
                                            <PublicationStatusMulti
                                                inView={inView}
                                                publicationId={publication.id}
                                                isPublished={
                                                    publication.status === Constants.publicationStatus.published
                                                }
                                            />
                                        </div>
                                    </Col>
                                )}
                            </InView>
                        )}
                        <Col
                            className={cx({
                                'col-md-5': !publication.multiVersions,
                                'col-md-4': publication.multiVersions,
                            })}
                        >
                            <div className="d-flex flex-row-reverse align-items-center justify-content-between">
                                <RestrictedContent permission={Permissions.Publication['Write.All']}>
                                    <PrimaryButton
                                        onClick={() => {
                                            history.push(
                                                generatePath(EDIT_PATH, {
                                                    publicationId: publication.id,
                                                    action: 'properties',
                                                    groupId: folderId ? folderId : undefined,
                                                }),
                                            );
                                        }}
                                        className="flex-shrink-0"
                                        data-uk-tooltip="Eigenschappen"
                                        size="sm"
                                    >
                                        {t('publications.btn.edit')}
                                    </PrimaryButton>
                                </RestrictedContent>

                                <PublicationDocuments documentIds={publication.documentIds} />
                            </div>
                        </Col>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={`publication-${publication.id}`}>
                        <Row>
                            <div className="col pt-2">
                                <PublicationRowDetails
                                    publication={publication}
                                    setShowExportChangesModal={setShowExportChangesModal}
                                    setShowPublishModal={setShowPublishModal}
                                    setShowDuplicateModal={setShowDuplicateModal}
                                />
                            </div>
                        </Row>
                    </Accordion.Collapse>
                </Container>
            </PublicationRowWrapper>
        </ScrollablePublication>
    );
}

function ScrollablePublication({ currentPublicationId, children }) {
    const { publicationId } = useParams();
    const [didScroll, setDidScroll] = useState(false);
    const innerRef = useRef();

    const { ref, inView } = useInView({
        threshold: 0,
    });

    useEffect(() => {
        const pid = publicationId ? parseInt(publicationId) : undefined;

        if (currentPublicationId !== pid) {
            return;
        }

        if (!inView && !didScroll) {
            if (innerRef.current) {
                innerRef.current.scrollIntoView({ behavior: 'smooth' });
                setDidScroll(true);
            }
        } else {
            setDidScroll(true);
        }
    }, [currentPublicationId, publicationId, inView, innerRef.current]);

    return (
        <div ref={ref}>
            <div ref={innerRef}>{children}</div>
        </div>
    );
}

function PublicationRowWrapper({ publicationId, children }) {
    const rowIsSelected = useSelector(publicationIsSelected(publicationId));
    const hasReadPermission = useHasPermission(Permissions.Publication['Read.All']);
    const currentEventKey = useContext(AccordionContext);
    const expanded = currentEventKey === `publication-${publicationId}`;

    return useMemo(() => {
        return (
            <div
                className={cx('d-flex align-items-center p-2 mr-2', {
                    'data-table-item-expanded': expanded,
                    'data-table-item-selected': rowIsSelected,
                    'data-table-item-disable': hasReadPermission === false,
                })}
            >
                <PublicationSelect publicationId={publicationId} />
                <div
                    className={cx('d-flex align-items-center flex-grow-1 border py-3 mr-2 ml-2', {
                        'bg-light': expanded,
                        'bg-white cursor-pointer uk-background-theme-light-hover': !expanded,
                    })}
                >
                    {children}
                </div>
            </div>
        );
    }, [expanded, rowIsSelected, hasReadPermission, publicationId]);
}

function PublicationSelect({ publicationId }) {
    const dispatch = useDispatch();
    const rowIsSelected = useSelector(publicationIsSelected(publicationId));

    const handleChange = () => {
        dispatch(toggleRow(publicationId));
    };

    return (
        <div className="align-self-start d-flex flex-shrink-0" style={{ marginTop: 21 }}>
            <RestrictedContent permission={Permissions.Publication['Write.All']}>
                <RxDragHandleDots2 size={22} className="icon-grip flex-shrink-0 mr-2 ml-0 text-muted" />

                <Checkbox
                    id={`publication-check-${publicationId}`}
                    name={`publication-check-${publicationId}`}
                    checked={rowIsSelected}
                    onChange={handleChange}
                />
            </RestrictedContent>
        </div>
    );
}

function PublicationName({ publicationId }) {
    const publication = useSelector((state) => selectPublicationById(state.publications, publicationId));
    const { pdf = false, pdfProtection = false } = publication;
    const { t } = useTranslation('publications');

    const isProtected = pdf && pdfProtection;
    const enableGroupProperties = publication.accessControlEnabled ?? false;

    return (
        <div>
            <div className="d-flex align-items-center font-weight-bolder">
                {!enableGroupProperties && (
                    <JournalRichtext size={18} className="text-dark-blue mr-2" style={{ minWidth: 18 }} />
                )}

                {enableGroupProperties && (
                    <PersonLock
                        size={20}
                        className="text-dark-blue mr-2"
                        style={{ minWidth: 20 }}
                        data-uk-tooltip="Toegangsbeperking ingeschakeld"
                    />
                )}

                {publication.name}

                {isProtected && (
                    <FileEarmarkLock2
                        size={15}
                        className="flex-shrink-0 ml-2"
                        data-uk-tooltip={t('publications.tooltip.pdfSecurity')}
                    />
                )}

                {publication.includeInKiosk && (
                    <Display
                        size={15}
                        className="flex-shrink-0 ml-2"
                        data-uk-tooltip={t('publications.tooltip.includeInKiosk')}
                    />
                )}

                {publication.new && (
                    <Badge variant="warning" className="ml-2 small">
                        {t('publications.badges.new')}
                    </Badge>
                )}
            </div>
        </div>
    );
}

function PublicationStatus({ publicationId }) {
    const publication = useSelector((state) => selectPublicationById(state.publications, publicationId));
    const { status } = publication;
    const { t } = useTranslation('publications');

    if (status === Constants.publicationStatus.published) {
        return <PublicationStatusPublished publication={publication} />;
    }

    const statusLabels = {
        [Constants.publicationStatus.publishing_failed]: t('publications.badges.failed'),
        [Constants.publicationStatus.draft]: t('publications.badges.notPublished'),
        [Constants.publicationStatus.publishing]: `${t('publications.badges.publishing')}...`,
    };

    return (
        <div className="font-weight-bolder font-size-base">
            <Badge
                variant={cx({
                    secondary: status === Constants.publicationStatus.draft,
                    danger: status === Constants.publicationStatus.publishing_failed,
                    warning: status === Constants.publicationStatus.publishing,
                })}
            >
                {statusLabels[status]}
            </Badge>
        </div>
    );
}

function PublicationStatusMulti({ inView = false, publicationId, isPublished = false }) {
    const { t } = useTranslation('publications');

    const skipLoadingVersions = !inView || !isPublished;
    const publicationVersions = useGetPublicationVersions(publicationId, skipLoadingVersions);

    const groupedVersions = _.groupBy(publicationVersions, 'prefix');

    return (
        <>
            {Object.keys(groupedVersions).map((prefix) => {
                const primaryVersion = groupedVersions[prefix].find((version) => {
                    return version.primaryVersion && version.status === Constants.publicationVersionStatus.published;
                });

                if (!primaryVersion) {
                    return null;
                }

                return (
                    <a
                        href={primaryVersion.htmlUrl}
                        className="badge badge-primary mr-1"
                        target="_blank"
                        data-uk-tooltip={`${t('publication.tooltip.newWindow', { name: 'Html' })}`}
                        key={`publication-${publicationId}-prefix-${prefix}`}
                    >
                        {prefix}
                    </a>
                );
            })}
        </>
    );
}

function PublicationStatusPublished({ publication }) {
    const { t } = useTranslation('publications');
    const hasCreatedAt = _.has(publication, 'publishedAt');
    const hasCreatorName = _.has(publication, 'publishedBy');
    const showTooltip = hasCreatedAt && hasCreatorName;

    const TheBadge = (
        <Badge
            variant="success"
            style={{
                cursor: showTooltip ? 'help' : undefined,
            }}
        >
            {t('publications.badges.published')}
        </Badge>
    );

    if (showTooltip) {
        return (
            <PublicationStatusBadgeWithTooltip
                publishedBy={publication.publishedBy}
                publishedAt={publication.publishedAt}
            >
                {TheBadge}
            </PublicationStatusBadgeWithTooltip>
        );
    }

    return TheBadge;
}

function PublicationDocuments({ documentIds }) {
    const allDocuments = useGetDocuments();
    const documents = allDocuments
        .filter((_document) => documentIds.includes(_document.id))
        .map((_document) => _document.name);

    return (
        <div
            className="font-weight-bolder mr-2 pl-2"
            style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }}
        >
            {documents.length === 1 ? (
                <>
                    <Link
                        to={generatePath(VIEW_PATH, {
                            documentId: documentIds[0],
                        })}
                        target="_blank"
                    >
                        {documents[0]}
                    </Link>
                </>
            ) : (
                <>{documents.join(', ')}</>
            )}
        </div>
    );
}
