import { useParams } from 'react-router-dom';
import { FieldArray, Form as FForm, Formik } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import { LoadingSpinner } from '../../../global/Spinner';
import { PlusCircle, XCircleFill } from 'react-bootstrap-icons';
import { InputField } from '../../../publications_v2/helpers/FieldHelper';
import HelperFunctions from '../../../global/HelperFunctions';
import { useUpdateCategoryMutation } from '../../../../features/reimbursements/reimbursements';
import * as yup from 'yup';
import { useGetCategory } from '../../../../features/reimbursements/reimbursementHooks';
import { WarningButton } from '../../../../components/Buttons';
import { useTranslation } from 'react-i18next';

export default function Fields() {
    const { t } = useTranslation('reimbursements');

    return (
        <>
            <div className="subheader">
                <h3>{t('settings.fields.title')}</h3>
            </div>

            <Row>
                <Col sm={12}>
                    <FieldsForm />
                </Col>
            </Row>
        </>
    );
}

function FieldsForm() {
    const { categoryId } = useParams();
    const { category } = useGetCategory(parseInt(categoryId));
    const [updateCategory] = useUpdateCategoryMutation();
    const { t } = useTranslation('reimbursements');

    if (!category) {
        return null;
    }

    const handleSubmit = (values, { setSubmitting }) => {
        updateCategory({ uri: category['@id'], ...values }).then(() => {
            setSubmitting(false);
        });
    };

    return (
        <Formik
            initialValues={{
                reimbursementProductFields: category.reimbursementProductFields ?? [],
            }}
            validationSchema={FieldsSchema}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({ values, dirty, isSubmitting, isValid }) => (
                <FForm autoComplete="off">
                    <Row className="mb-2">
                        <Col md={3} className="font-weight-bold">
                            {t('settings.fields.name')}
                        </Col>
                        <Col md={2} className="font-weight-bold">
                            {t('settings.fields.default')}
                        </Col>
                        <Col md={2} className="font-weight-bold">
                            {t('settings.fields.slug')}
                        </Col>
                        <Col md={2} className="font-weight-bold">
                            {t('settings.fields.maxChars')}
                        </Col>
                        <Col md={2} className="font-weight-bold">
                            {t('settings.fields.description')}
                        </Col>
                    </Row>
                    <FieldArray name="reimbursementProductFields">
                        {({ remove, push }) => (
                            <>
                                {values.reimbursementProductFields.map((_field, index) => (
                                    <Field
                                        index={index}
                                        field={_field}
                                        remove={() => remove(index)}
                                        key={`field-${index}`}
                                    />
                                ))}

                                <div className="mt-4 mb-5">
                                    <Button
                                        variant="info"
                                        size="sm"
                                        className="d-flex align-items-center btn-icon my-1"
                                        onClick={() => {
                                            push({
                                                name: '',
                                                slug: '',
                                            });
                                        }}
                                    >
                                        <PlusCircle size={16} />
                                        <div>{t('settings.fields.addField')}</div>
                                    </Button>
                                </div>
                            </>
                        )}
                    </FieldArray>

                    <WarningButton type="submit" disabled={isSubmitting || !dirty || !isValid}>
                        {t('btn.save')}
                    </WarningButton>
                    <LoadingSpinner isSubmitting={isSubmitting} />
                </FForm>
            )}
        </Formik>
    );
}

function Field({ field, remove, index }) {
    const { name, slug } = field;
    const { t } = useTranslation('reimbursements');

    const handleRemove = () => {
        if (slug === '') {
            remove();
        } else {
            HelperFunctions.confirmModal(
                t('settings.fields.note', { name }),
                'danger',
                false,
                t('settings.fields.btn.yesDelete'),
                t('btn.cancel')
            ).then(() => {
                remove();
            });
        }
    };

    return (
        <Row className="align-items-center mb-2">
            <Col md={3}>
                <InputField name={`reimbursementProductFields.${index}.name`} />
            </Col>
            <Col md={2}>
                <InputField name={`reimbursementProductFields.${index}.placeholder`} />
            </Col>
            <Col md={2}>
                <InputField name={`reimbursementProductFields.${index}.slug`} />
            </Col>
            <Col md={2}>
                <InputField name={`reimbursementProductFields.${index}.max_length`} />
            </Col>
            <Col md={2}>
                <InputField name={`reimbursementProductFields.${index}.description`} />
            </Col>
            <Col md={1}>
                <Button
                    variant="icon"
                    size="sm"
                    className="text-danger ml-2 d-inline btn-icon-only"
                    data-uk-tooltip={t('settings.fields.tooltip.deleteField')}
                    onClick={handleRemove}
                >
                    <XCircleFill size={16} style={{ minWidth: 16 }} />
                </Button>
            </Col>
        </Row>
    );
}

const FieldsSchema = yup.object().shape({
    reimbursementProductFields: yup.array().of(
        yup.object().shape({
            name: yup.string().required(),
            slug: yup.string().required(),
        })
    ),
});
