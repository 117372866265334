import Constants from '../../../../config/Constants';
import Language from '../../../../language/Language';
import CustomIcons from '../../../global/CustomIcons';
import EditorToolbar, { LinkedBlockLabel } from '../_BlockParser/EditorToolbar';
import BlockName from '../_BlockParser/BlockName';
import { Block } from '../BlockParser';
import { LinkedBlocks } from './LinkedBlocks';
import { BlockHeightWrapper } from './BlockHeightWrapper';
import { Badge } from 'react-bootstrap';
import { BlockType } from 'pages/documents_v2/views/edit_area/blocks/BlockType';
import { useTranslation } from 'react-i18next';

export function BlockWrapper({
    blockId,
    state,
    props,
    toggleBlockLinks,
    toggleBlockSettings,
    toggleTableEditMode,
    toggleToolbar,
}) {
    const { block, editorDisplaySection, blockIdsInLayout = [] } = props;
    const { t } = useTranslation('documents');

    const editorToolbarProps = {
        blockId: blockId,
        changesSaved: state.changesSaved,
        editMode: state.editMode,
        editTableMode: state.editTableMode,
        showBlockLinks: state.showBlockLinks,
        textLayout: state.formData.textLayout,
        toggleBlockLinks: toggleBlockLinks,
        toggleBlockSettings: toggleBlockSettings,
        toggleTableEditMode: toggleTableEditMode,
    };

    const blockDisplayOptions = props.blockDisplayOptions
        ? props.blockDisplayOptions
        : Constants.defaultBlockDisplayOptions;
    const markBlockDeleted = blockDisplayOptions.markDeletedBlocks && block.deleted;
    const markBlockScheduledForkDeletion = blockDisplayOptions.markDeletedBlocks && block.scheduledForDeletion;
    const showDeleteUndo = blockDisplayOptions.canRestoreDeletedBlocks;

    const notEditingOrShowingLinks = !state.editMode && !state.showBlockLinks;
    const showToolbar = state.showToolbar && !blockDisplayOptions.showBlockToolbarButtons;

    return (
        <BlockHeightWrapper
            blockId={blockId}
            blockIsInLayout={blockIdsInLayout.includes(block.key)}
            editorDisplaySection={editorDisplaySection}
            markAsDeleted={
                blockDisplayOptions.markDeletedBlocks && (markBlockDeleted || markBlockScheduledForkDeletion)
            }
        >
            <>
                {markBlockDeleted && (
                    <div className="py-2 uk-background-danger-light uk-text-danger text-center small">
                        {Language.getTranslation(showDeleteUndo ? 'blockWillBeDeletedOnSave' : 'blockDeleted')}
                        {showDeleteUndo && (
                            <a onClick={() => props.toggleDeleteBlock(blockId, false)}>
                                <CustomIcons icon="undo" className="ml-3 mr-1" />{' '}
                                {Language.getTranslation('undoDeleteBlock', 'buttons')}
                            </a>
                        )}
                    </div>
                )}
                {markBlockScheduledForkDeletion && (
                    <div className="py-2 uk-background-danger-light uk-text-danger text-center small">
                        {t('document.navbar.main.editor.left.blocks.badges.markedForDeletion')}
                    </div>
                )}

                {state.showBlockLinks && <LinkedBlocks block={block} />}

                {notEditingOrShowingLinks && (
                    <>
                        <Block
                            {...props}
                            blockDisplayOptions={blockDisplayOptions}
                            blockId={blockId}
                            blockPrefix={state.formData.blockPrefix}
                            users={state.users}
                            editTableMode={state.editTableMode}
                            toggleToolbar={toggleToolbar}
                            showToolbar={state.showToolbar}
                        />

                        {showToolbar && (
                            <div className="editor-toolbar">
                                <div className="uk-button-group uk-button-group-small uk-margin-small-right px-2">
                                    {block.new && (
                                        <Badge variant="success" size="6px" className="mr-2 p-1">
                                            {Language.getTranslation('blockIsNew')}
                                        </Badge>
                                    )}

                                    <BlockType
                                        block={block}
                                        isInsideBlockLayout={blockIdsInLayout.includes(block.key)}
                                    />

                                    {block.type === Constants.blockTypes.linked && (
                                        <LinkedBlockLabel className="px-2" block={block} />
                                    )}
                                </div>
                            </div>
                        )}

                        <BlockName
                            block={block.type === Constants.blockTypes.linked ? props.baseBlock ?? {} : block}
                            editorDisplaySection={props.editorDisplaySection}
                        />
                    </>
                )}

                {state.showToolbar && blockDisplayOptions.showBlockToolbarButtons && (
                    <EditorToolbar {...props} {...editorToolbarProps} />
                )}
            </>
        </BlockHeightWrapper>
    );
}
