import { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormModal } from '../../global/FormModal';
import { PrimaryButton } from 'components/Buttons';
import { LightbulbFill } from 'react-bootstrap-icons';
import { DocRevSelect } from '../../publications_v2/helpers/FieldHelper';
import Parser from 'html-react-parser';
import Spinner from '../../global/Spinner';
import cx from 'classnames';
import { useGetAiSuggestionMutation } from 'features/openai/openAIApi';
import { useCurrentOrganisation } from 'hooks/useCurrentOrganisation';
import { useTranslation } from 'react-i18next';
import { AreaContext } from 'pages/documents_v2/views/Area';
import { useParams } from 'react-router-dom';

export function BlockImprovementModal({ block, handleClose, handleBlockChange }) {
    const currentOrganisation = useCurrentOrganisation();
    const { t } = useTranslation('documents');
    const [getAiSuggestion] = useGetAiSuggestionMutation();
    const { activeVariantId } = useContext(AreaContext);
    const { areaId } = useParams();

    const [errorMessage, setErrorMessage] = useState('');
    const [opinion, setOpinion] = useState(undefined);
    const [suggestion, setSuggestion] = useState(undefined);
    const [isLoading, setLoading] = useState(true);
    const [suggestionLoading, setSuggestionLoading] = useState(false);
    const [option1, setOption1] = useState('b1');
    const [option2, setOption2] = useState('formal');

    const hasRateLimit = currentOrganisation && currentOrganisation.aiRateLimit >= 0;
    const rateLimitExceeded = hasRateLimit && currentOrganisation.aiUsageCount >= currentOrganisation.aiRateLimit;
    const strippedString = block.latestContent;

    useEffect(() => {
        if (rateLimitExceeded) {
            return;
        }

        setLoading(true);

        getAiSuggestion({
            entityType: 'block',
            entityId: `${block.id}`,
            prompt: `is_${option1}`,
            properties: {
                areaId: parseInt(areaId),
                variantId: activeVariantId,
            },
        }).then(({ data }) => {
            if (data) {
                const { message, success = false } = data;

                if (success) {
                    setOpinion(message);
                    setErrorMessage('');
                } else {
                    setOpinion(undefined);
                    setErrorMessage(
                        t('document.navbar.main.editor.left.blocks.modals.blockImprovementModal.errorMessage'),
                    );
                }

                setLoading(false);
            }
        });
    }, [strippedString, rateLimitExceeded, option1]);

    return (
        <FormModal
            show={true}
            onHide={handleClose}
            title={t('document.navbar.main.editor.left.blocks.modals.blockImprovementModal.doesTextMeet', {
                level: option1,
            })}
            size="lg"
        >
            <>
                <Modal.Body>
                    <div>
                        {errorMessage !== '' && <div className="text-danger">{errorMessage}</div>}

                        {rateLimitExceeded ? (
                            <div className="text-danger">
                                {t('document.navbar.main.editor.left.blocks.modals.blockImprovementModal.cannotUse')}
                            </div>
                        ) : (
                            <div className="px-3 pt-3">
                                <div className="d-flex align-items-center mb-3">
                                    {t(
                                        'document.navbar.main.editor.left.blocks.modals.blockImprovementModal.optionToMake',
                                    )}
                                    <div className="d-inline-flex mx-2">
                                        <DocRevSelect
                                            name="option1"
                                            size="sm"
                                            selectedValue={option1}
                                            onChange={(selectedValue) => setOption1(selectedValue.value)}
                                            options={[
                                                {
                                                    label: 'A2',
                                                    value: 'a2',
                                                },
                                                {
                                                    label: 'B1',
                                                    value: 'b1',
                                                },
                                                {
                                                    label: 'B2',
                                                    value: 'b2',
                                                },
                                                {
                                                    label: 'C1',
                                                    value: 'c1',
                                                },
                                                {
                                                    label: 'C2',
                                                    value: 'c2',
                                                },
                                            ]}
                                        />
                                    </div>
                                    {t(
                                        'document.navbar.main.editor.left.blocks.modals.blockImprovementModal.levelText',
                                    )}
                                    <div className="d-inline-flex mx-2">
                                        <DocRevSelect
                                            name="option2"
                                            size="sm"
                                            selectedValue={option2}
                                            onChange={(selectedValue) => setOption2(selectedValue.value)}
                                            options={[
                                                {
                                                    label: t(
                                                        'document.navbar.main.editor.left.blocks.modals.blockImprovementModal.formal',
                                                    ),
                                                    value: 'formal',
                                                },
                                                {
                                                    label: t(
                                                        'document.navbar.main.editor.left.blocks.modals.blockImprovementModal.informal',
                                                    ),
                                                    value: 'informal',
                                                },
                                            ]}
                                        />
                                    </div>
                                    {t('document.navbar.main.editor.left.blocks.modals.blockImprovementModal.style')}
                                </div>

                                {isLoading && <Spinner className="" />}

                                {!!opinion && (
                                    <>
                                        <div className="mb-3">
                                            <em
                                                className={cx({
                                                    'text-body': !isLoading,
                                                    'text-muted': isLoading,
                                                })}
                                            >
                                                {opinion}
                                            </em>
                                        </div>

                                        <div className="d-flex align-items-center">
                                            <PrimaryButton
                                                onClick={() => {
                                                    loadSuggestion();
                                                }}
                                                disabled={suggestionLoading}
                                            >
                                                <LightbulbFill />{' '}
                                                {t(
                                                    'document.navbar.main.editor.left.blocks.modals.blockImprovementModal.submitSuggestion',
                                                    { level: option1 },
                                                )}
                                            </PrimaryButton>

                                            {suggestionLoading && <Spinner />}
                                        </div>

                                        {suggestion && (
                                            <div className="pt-3">
                                                <div className="font-weight-bold mb-2">
                                                    {t(
                                                        'document.navbar.main.editor.left.blocks.modals.blockImprovementModal.suggestion',
                                                    )}
                                                    :
                                                </div>
                                                <div className="area-read-only editor-right-side p-0 border-0">
                                                    {Parser(suggestion)}
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}

                                {opinion && (
                                    <div className="text-secondary small mt-5">
                                        {t(
                                            'document.navbar.main.editor.left.blocks.modals.blockImprovementModal.generatedByAi',
                                        )}
                                        {hasRateLimit && (
                                            <>
                                                &nbsp;
                                                {t(
                                                    'document.navbar.main.editor.left.blocks.modals.blockImprovementModal.limitedChecks',
                                                    {
                                                        count:
                                                            currentOrganisation.aiRateLimit -
                                                            currentOrganisation.aiUsageCount,
                                                    },
                                                )}
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="btn btn-secondary" onClick={() => handleClose()}>
                        {t('btn.cancel')}
                    </Button>
                    <Button
                        variant="btn btn-warning"
                        disabled={!suggestion}
                        type="button"
                        onClick={() => handleSubmit()}
                    >
                        {t('document.navbar.main.editor.left.blocks.modals.blockImprovementModal.btn.useText')}
                    </Button>
                </Modal.Footer>
            </>
        </FormModal>
    );

    function loadSuggestion() {
        setSuggestion(undefined);
        setSuggestionLoading(true);

        getAiSuggestion({
            entityType: 'block',
            entityId: `${block.id}`,
            prompt: `suggest_${option1}_${option2}`,
            properties: {
                areaId: parseInt(areaId),
                variantId: activeVariantId,
            },
        }).then(({ data }) => {
            if (data) {
                const { message, success = false } = data;

                if (success) {
                    setSuggestion(message);
                } else {
                    setSuggestion(undefined);
                    setErrorMessage(
                        t('document.navbar.main.editor.left.blocks.modals.blockImprovementModal.errorMessage'),
                    );
                }

                setSuggestionLoading(false);
            }
        });
    }

    function handleSubmit() {
        let newValue = suggestion.trim();

        if (!newValue.startsWith('<')) {
            newValue = `<p>${newValue}</p>`;
        }

        handleBlockChange(newValue);
        handleClose();
    }
}
