import { Nav } from 'react-bootstrap';
import { generatePath, Link, useParams } from 'react-router-dom';
import { EDIT_PATH } from 'scenes/DocumentsV2';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useGetDocument } from 'pages/documents_v2/hooks/useGetDocument';

export default function Sidebar() {
    const { documentId, page = 'properties' } = useParams();
    const { t } = useTranslation('documents');
    const document = useGetDocument();

    const availableSettings = getSettings();

    return (
        <div className="py-4 bg-lightBlueLight">
            <div className="d-flex align-items-center justify-content-between mb-2 px-4">
                <span className="text-color font-weight-bold">{t('document.navbar.settings.sidebar.title')}</span>
            </div>

            <Nav className="sidebar-nav flex-column">
                {availableSettings.map((_setting, index) => (
                    <Link
                        to={generatePath(EDIT_PATH, {
                            documentId,
                            view: 'settings',
                            page: _setting.id,
                        })}
                        className={cx('nav-link font-weight-normal', {
                            active: page === _setting.id,
                        })}
                        key={`sidebar-item-${index}`}
                        style={{ fontSize: '12px' }}
                    >
                        {_setting.name}
                    </Link>
                ))}
            </Nav>
        </div>
    );

    function getSettings() {
        let settings = [
            {
                id: 'properties',
                name: t('document.navbar.settings.sidebar.settings'),
            },
            {
                id: 'team',
                name: t('document.navbar.settings.sidebar.team'),
            },
            {
                id: 'revisions',
                name: t('document.navbar.settings.sidebar.planning'),
            },
            {
                id: 'audit_trail',
                name: t('document.navbar.settings.sidebar.audit'),
            },
        ];

        if (document?.properties?.enableOneLiners === true) {
            settings.push({
                id: 'one_liners',
                name: 'One-liners',
            });
        }

        return settings;
    }
}
