import { useEffect } from 'react';
import { FormGroup } from 'components/SettingsSidebar/helpers/FormHelpers';
import { MultipleSelect } from 'pages/task_team/helpers/FormHelper';
import { InputField, InputSelect } from 'pages/publications_v2/helpers/FieldHelper';
import { Field, useFormikContext } from 'formik';
import { TaskLabels } from 'pages/task_team/modals/view_task_modal/TaskLabels';
import { Dropzone } from 'pages/task_team/helpers/Dropzone';
import { TaskFileAttachments } from 'pages/task_team/views/checks/TaskFileAttachments';
import { DateTime } from 'luxon';
import { useGetAvailableUsers } from 'pages/task_team/hooks/useGetAvailableUsers';
import { fileType } from 'pages/task_team/config/constants';
import { useGetTaskAttachments } from 'pages/task_team/hooks/useGetTaskAttachments';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { useGetTaskUsers } from 'pages/task_team/hooks/useGetTaskUsers';

export function Step5Finish({ active = false }) {
    const { values } = useFormikContext();

    return (
        <div className="sidebar-form">
            <TaskEvidence active={active} task={values} />
            <VersionEvaluation active={active} />

            {values.hasOwnProperty('id') && (
                <FormGroup label="Tags" help="Optioneel, voeg tags toe aan de uiting">
                    <TaskLabels taskId={values.id} />
                </FormGroup>
            )}
        </div>
    );
}

export function TaskEvidence({ task, active = false }) {
    const taskUsers = useGetTaskUsers('pre-approved');
    const attachments = useGetTaskAttachments(task['@id']);
    const files = attachments.filter((attachment) => attachment.type === fileType.TYPE_EVIDENCE);

    const userOptions = taskUsers.map((taskUser) => ({
        ...taskUser,
        name: taskUser.fullName,
        '@id': taskUser.userId,
    }));

    return (
        <>
            <FormGroup
                label="Afgestemd met (niet verplicht)"
                htmlFor="preApprovedBy"
                help={
                    <span>
                        Juridische Zaken: Uiting is <span className="font-weight-bold">vooraf</span> afgestemd met en
                        akkoord bevonden door
                    </span>
                }
            >
                <MultipleSelect options={userOptions} name="preApprovedBy" />
            </FormGroup>

            <FormGroup
                label="Bewijs voor afstemming"
                htmlFor="evidence"
                help="Voeg 1 of meerdere bestanden (.msg of .eml) toe"
            >
                <div className="mb-3">
                    <Dropzone task={task} type="evidence" />
                </div>
                {active && <TaskFileAttachments files={files} canDelete={true} />}
            </FormGroup>
        </>
    );
}

export function VersionEvaluation({ active = false }) {
    const { values, setFieldValue } = useFormikContext();
    const { reEvaluation, reEvaluationAmount, reEvaluationStartDate } = values;
    const [addCheck] = useQueryParam('addCheck', BooleanParam);

    const minDate = DateTime.now().startOf('day').toISO().slice(0, 16);

    useEffect(() => {
        if (reEvaluation && reEvaluationStartDate === '') {
            updateReEvaluationStartDate();
            return;
        }

        if (!reEvaluation && reEvaluationStartDate !== '') {
            setFieldValue('reEvaluationStartDate', '');
        }
    }, [reEvaluation]);

    useEffect(() => {
        if (reEvaluation) {
            updateReEvaluationStartDate();
        }
    }, [reEvaluationAmount]);

    return (
        <FormGroup label="Periodieke herbeoordeling" htmlFor="reEvaluation" help="">
            <div className="d-flex align-items-center mb-3">
                <Field name="reEvaluation" id="reEvaluation" className="mr-2" type="checkbox" />
                <div className="mr-2">Ja, iedere</div>

                <div style={{ minWidth: 85 }}>
                    <InputSelect
                        name="reEvaluationAmount"
                        options={getReEvaluationAmountOptions()}
                        props={{
                            isDisabled: values.reEvaluation === false,
                        }}
                    />
                </div>
                <div className="ml-2">maanden</div>
            </div>

            <div className="d-flex align-items-center">
                <div className="mr-2">Voor het eerst op</div>
                <div>
                    <InputField
                        name="reEvaluationStartDate"
                        props={{
                            required: values.reEvaluation === true && active,
                            id: 'reEvaluationStartDate',
                            type: 'datetime-local',
                            disabled: values.reEvaluation === false,
                            min: values.reEvaluation === true && active ? minDate : undefined,
                        }}
                    />
                </div>
            </div>
        </FormGroup>
    );

    function updateReEvaluationStartDate() {
        const startDate = DateTime.fromISO(values.startDate);
        const newStartDate = startDate.plus({ months: reEvaluationAmount });

        setFieldValue('reEvaluationStartDate', newStartDate.toISO().slice(0, 16));
    }

    function getReEvaluationAmountOptions() {
        // When in the add modal, only show the 12 months option
        if (addCheck === true) {
            return [
                {
                    label: '12',
                    value: 12,
                },
            ];
        }

        return reEvaluationAmountOptions;
    }
}

const reEvaluationAmountOptions = [
    {
        label: '1',
        value: 1,
    },
    {
        label: '3',
        value: 3,
    },
    {
        label: '6',
        value: 6,
    },
    {
        label: '12',
        value: 12,
    },
    {
        label: '18',
        value: 18,
    },
    {
        label: '24',
        value: 24,
    },
];
