import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { useOrganisationTags } from 'components/SettingsSidebar/views/comments/CommentTags';
import { useGetLabelsQuery } from 'features/metadata/metadata';
import HelperFunctions from 'pages/global/HelperFunctions';

const emptyArray = [];

export function useGetCommentTags(comment) {
    const activeOrganisation = useActiveOrganisation();
    const organisationTags = useOrganisationTags();

    const { commentLabels = [] } = useGetLabelsQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                commentLabels: data ?? emptyArray,
            }),
        },
    );

    return getCommentTags(comment, commentLabels, organisationTags);
}

function getCommentTags(comment, commentLabels = [], availableTags = []) {
    let tags = [];

    comment.tags.forEach((tagData) => {
        let tag;

        if (tagData.hasOwnProperty('uri')) {
            tag = HelperFunctions.getByValue(commentLabels, '@id', tagData.uri);
        } else {
            // Old style
            tag = HelperFunctions.getByValue(availableTags, 'id', tagData.id);
        }

        if (tag !== undefined) {
            tags.push(tag);
        }
    });

    return tags;
}
