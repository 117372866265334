import { Form, Modal } from 'react-bootstrap';
import { Field, Form as FForm, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { FormModal, ModalFooter } from '../../global/FormModal';
import Select from 'react-select';
import { addOpList } from '../../../features/operationsList/operationsListSlice';
import { useGetDocuments } from '../../documents_v2/hooks/useGetDocuments';
import { useActiveOrganisation } from '../../../hooks/useActiveOrganisation';
import HelperFunctions from '../../global/HelperFunctions';
import { useTranslation } from 'react-i18next';

export default function NewOpListModal(props) {
    const { showModal = false } = props;
    const dispatch = useDispatch();
    const activeOrganisation = useActiveOrganisation();
    const documents = useGetDocuments();
    const { t } = useTranslation('changelist');

    const handleSubmit = async (values, { setSubmitting }) => {
        const action = await dispatch(
            addOpList({
                ...values,
                documentIds: values.documentIds.map((_document) => _document.value),
            })
        );

        const newOpList = action.payload.operationLists[Object.keys(action.payload.operationLists)[0]];

        // Done
        setSubmitting(false);
        props.handleClose(newOpList);
    };

    const options = HelperFunctions.prepareDropdownData(documents);

    return (
        <FormModal
            show={showModal}
            onHide={() => props.handleClose()}
            title={t('changelist.view.newOpListModal.newChangeList')}
        >
            <Formik
                initialValues={{ name: '', documentIds: [], organisationId: activeOrganisation }}
                validationSchema={NewOpListSchema}
                onSubmit={handleSubmit}
            >
                {({ setFieldValue, isSubmitting, dirty, isValid, handleBlur, touched, errors }) => (
                    <FForm autoComplete="off">
                        <Modal.Body>
                            <Form.Group>
                                <Form.Label htmlFor="name">{t('changelist.view.newOpListModal.name')}</Form.Label>
                                <Field
                                    id="name"
                                    name="name"
                                    as={Form.Control}
                                    isInvalid={touched['name'] && errors['name']}
                                    isValid={touched['name'] && !errors['name']}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label htmlFor="documentIds">
                                    {t('changelist.view.newOpListModal.documents')}
                                </Form.Label>
                                <Field
                                    isMulti
                                    as={Select}
                                    id="documentIds"
                                    name="documentIds"
                                    options={options}
                                    onBlur={handleBlur}
                                    onChange={(selectedOptions) => {
                                        setFieldValue('documentIds', selectedOptions ?? []);
                                    }}
                                />
                            </Form.Group>
                        </Modal.Body>

                        <ModalFooter
                            dirty={dirty}
                            isValid={isValid}
                            isSubmitting={isSubmitting}
                            onHide={() => props.handleClose()}
                        />
                    </FForm>
                )}
            </Formik>
        </FormModal>
    );
}

const NewOpListSchema = Yup.object().shape({
    name: Yup.string().required(),
    documentIds: Yup.array().required(),
    organisationId: Yup.number().required(),
});
