import { ExclamationTriangleFill } from 'react-bootstrap-icons';
import { WarningButton } from '../../../components/Buttons';
import { LoadingSpinner } from '../../global/Spinner';
import RestrictedContent from '../../global/RestrictedContent';
import Constants, { Permissions } from '../../../config/Constants';
import { AddToOpListBtn } from '../../operations_list/edit_area/AddToOpList';
import { useParams } from 'react-router-dom';
import { actions } from '../../documents_v2/config/views';
import CustomIcons from '../../global/CustomIcons';
import { useGetDocument } from '../../documents_v2/hooks/useGetDocument';
import { useTranslation } from 'react-i18next';

export function AreaNav({
    area,
    handleSave,
    changesDetected = false,
    refreshingContent,
    changesSaved,
    nrOfChanges,
    selectedPart,
    processSelectedChange,
    processAllChanges,
}) {
    const { t } = useTranslation('documents');
    const { view } = useParams();
    const document = useGetDocument();

    return (
        <>
            <LoadingSpinner isSubmitting={changesDetected && refreshingContent} />

            {changesDetected && !changesSaved && (
                <>
                    <ExclamationTriangleFill className="text-warning mr-1" />
                    <div className="mr-3 small text-warning">
                        {t('document.navbar.main.editor.navbar.unsavedChanges')}
                    </div>
                </>
            )}

            {view === actions.CHECK && (
                <AreaCheckButtons
                    nrOfChanges={nrOfChanges}
                    selectedPart={selectedPart}
                    processSelectedChange={processSelectedChange}
                    processAllChanges={processAllChanges}
                />
            )}

            {document && document.activeRevision && view === actions.EDIT && (
                <RestrictedContent
                    permission={Permissions.OperationListEntry.Create}
                    module={Constants.modules.operations_list}
                >
                    <AddToOpListBtn area={area} />
                </RestrictedContent>
            )}

            {view !== actions.VIEW && (
                <>
                    <WarningButton onClick={handleSave} disabled={changesDetected === false || refreshingContent}>
                        {t('btn.save')}
                    </WarningButton>

                    <div
                        style={{
                            borderRight: '1px solid #e5e5e5',
                            height: 32,
                            margin: '0 12px 0 18px',
                        }}
                    >
                        &nbsp;
                    </div>
                </>
            )}
        </>
    );
}

function AreaCheckButtons({ nrOfChanges = 0, selectedPart, processSelectedChange, processAllChanges }) {
    const { t } = useTranslation('documents');

    return (
        <>
            <button
                className="uk-button uk-button-width-small uk-button-success uk-margin-right"
                onClick={() => processSelectedChange('accept')}
                type="button"
                disabled={selectedPart.part === false || nrOfChanges === 0}
            >
                <CustomIcons className="uk-margin-small-right" icon="acceptOne" />
                {t('document.navbar.main.editor.navbar.btn.accept')}
            </button>
            <button
                className="uk-button uk-button-width-small uk-button-danger uk-margin-right"
                onClick={() => processSelectedChange('reject')}
                type="button"
                disabled={selectedPart.part === false || nrOfChanges === 0}
            >
                <CustomIcons className="uk-margin-small-right" icon="rejectOne" />
                {t('document.navbar.main.editor.navbar.btn.reject')}
            </button>
            <button
                className="uk-button uk-button-width-small uk-button-success uk-margin-right"
                onClick={() => processAllChanges('accept')}
                type="button"
                disabled={nrOfChanges === 0}
            >
                <CustomIcons className="uk-margin-small-right" icon="acceptAll" />
                {t('document.navbar.main.editor.navbar.btn.acceptAll')}
            </button>
            <button
                className="uk-button uk-button-width-small uk-button-danger mr-5"
                onClick={() => processAllChanges('reject')}
                type="button"
                disabled={nrOfChanges === 0}
            >
                <CustomIcons className="uk-margin-small-right" icon="rejectAll" />
                {t('document.navbar.main.editor.navbar.btn.rejectAll')}
            </button>
        </>
    );
}
