import TagHelper from '../../../../pages/global/TagHelper';
import { useGetCommentTags } from 'pages/comments/hooks/useGetCommentTags';
import { Badge } from 'react-bootstrap';

export default function Tags({ comment }) {
    const tags = useGetCommentTags(comment);

    if (tags.length === 0) {
        return null;
    }

    return (
        <div className="d-flex justify-content-end">
            {tags.map((tag, index) => (
                <div className="mt-2" key={`comment-${comment.id}-tag-${index}`}>
                    <Badge variant="primary" className={`badge-color-${TagHelper.getTagColor(tag)} ml-1`}>
                        {tag.name}
                    </Badge>
                </div>
            ))}
        </div>
    );
}
