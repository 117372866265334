import { generatePath, Link, useParams } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import { ExclamationTriangleFill, Gear } from 'react-bootstrap-icons';
import cx from 'classnames';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { COLLECTIVES_EDIT_PATH } from '../../../../../scenes/Reimbursements';
import { useTranslation } from 'react-i18next';

export default function Sidebar() {
    const { categoryId, collectiveId, folderId, action } = useParams();
    const { errors } = useFormikContext();
    const { t } = useTranslation('reimbursements');

    return (
        <div className="py-4">
            <div className="d-flex align-items-center justify-content-between mb-2 px-4">
                <span className="text-uppercase small text-muted font-weight-light">
                    {t('collectivity.editCollectivity.sidebar.settings')}
                </span>
            </div>

            <Nav className="sidebar-nav flex-column">
                <Link
                    to={generatePath(COLLECTIVES_EDIT_PATH, {
                        categoryId,
                        action: 'properties',
                        collectiveId,
                        folderId,
                    })}
                    className={cx('nav-link d-flex align-items-center', {
                        active: action === 'properties',
                    })}
                >
                    <Gear size={14} className="mr-1" />
                    {t('collectivity.editCollectivity.sidebar.properties')}
                    {_.has(errors, 'name') && <ErrorIcon />}
                </Link>
            </Nav>
        </div>
    );
}

function ErrorIcon() {
    const { t } = useTranslation('reimbursements');

    return (
        <ExclamationTriangleFill
            className="text-danger ml-auto"
            data-uk-tooltip={t('collectivity.editCollectivity.sidebar.errors')}
        />
    );
}
