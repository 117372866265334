import cx from 'classnames';
import _ from 'lodash';
import React, { useContext } from 'react';
import { EditContext } from '../view/EditContextWrapper';
import EntityOptions from './EntityOptions';
import {
    useGetTranslationKeysQuery,
    useGetVariantPackagesQuery,
} from '../../../../features/translations/translationApi';
import { entityTypes } from '../../config/Constants';
import { MemoizedTranslationKeyCardTitle } from './TranslationKeyCardTitle';
import { useGetEntityReference } from '../../hooks/useGetEntityReference';
import { ReimbursementProductFieldTranslationKey } from './reimbursementContainer/ReimbursementProductFieldTranslationKey';
import { useGetEntity } from '../../hooks/useGetEntity';
import { isEnabledForTranslation } from '../../helpers/TranslationKeyHelper';

function TranslationKey({ translationKeyUri, languageIso, reimbursementContainerUri, entityType }) {
    const { translationKey } = useGetTranslationKeysQuery(
        {
            uri: reimbursementContainerUri,
            entityType,
        },
        {
            selectFromResult: ({ data }) => ({
                translationKey: data ? data.find((item) => item['@id'] === translationKeyUri) : undefined,
            }),
        }
    );

    if (translationKey === undefined) {
        return null;
    }

    if (entityType === entityTypes.REIMBURSEMENT_PRODUCT_FIELD) {
        return <ReimbursementProductFieldTranslationKey languageIso={languageIso} translationKey={translationKey} />;
    }

    if (entityType === entityTypes.VARIANT_PACKAGE) {
        return <VariantPackageTranslationKey languageIso={languageIso} translationKey={translationKey} />;
    }

    return null;
}

function VariantPackageTranslationKey({ translationKey, languageIso }) {
    const entityReference = useGetEntityReference(translationKey);

    const { variantPackages } = useGetVariantPackagesQuery(undefined, {
        selectFromResult: ({ data }) => ({
            variantPackages: data ? data : [],
        }),
    });

    if (entityReference === undefined) {
        return null;
    }

    const entity = variantPackages.find((_package) => _package.id === _.toInteger(entityReference.entityId));

    if (entity === undefined) {
        return null;
    }

    return (
        <TranslationKeyInner
            parentEntity={entity}
            languageIso={languageIso}
            translationKey={translationKey}
            entityReference={entityReference}
        />
    );
}

export function TranslationKeyInner({ translationKey, languageIso, parentEntity, entityReference }) {
    const { entityIsSelected, toggleEntity } = useContext(EditContext);
    const entity = useGetEntity(entityReference, parentEntity);

    const hasEntityReference = entityReference !== undefined;
    const isSelected = hasEntityReference && entityIsSelected(entityReference.uri, translationKey.property);

    return (
        <>
            <CardCheckbox translationKey={translationKey} parentEntity={parentEntity} />

            <div
                className={cx('dr-card-row d-flex w-100 align-items-center', {
                    selected: isSelected,
                })}
                style={{ minHeight: 52.97, padding: '0.85rem 1.25rem' }}
                onClick={({ target }) => {
                    if (
                        _.isString(target.className) &&
                        !target.className.includes('prevent-toggle') &&
                        !target.className.includes('btn') &&
                        hasEntityReference
                    ) {
                        toggleEntity({
                            id: entityReference.uri,
                            entityReference,
                            entity: {
                                ...entity,
                                title:
                                    translationKey.entityType === entityTypes.VARIANT_PACKAGE
                                        ? 'Polissoort'
                                        : 'Vergoeding',
                            },
                            translationKey,
                        });
                    }
                }}
            >
                <MemoizedTranslationKeyCardTitle
                    title={_.get(entity, translationKey.property, '')}
                    translationKeyUri={translationKey['@id']}
                    languageIso={languageIso}
                    enabledForTranslation={isEnabledForTranslation(translationKey, languageIso)}
                />

                <TranslationKeyOptions
                    translationKey={translationKey}
                    languageIso={languageIso}
                    entityReference={entityReference}
                />
            </div>
        </>
    );
}

export function CardCheckbox({ translationKey, parentEntity }) {
    const { toggleEntity, entityIsSelected } = useContext(EditContext);
    const entityReference = useGetEntityReference(translationKey);
    const entity = useGetEntity(entityReference, parentEntity);

    const hasEntityReference = entityReference !== undefined;
    const hasEntity = entity !== undefined;
    const isSelected = hasEntityReference && entityIsSelected(entityReference.uri, translationKey.property);

    return (
        <div className="d-flex flex-shrink-0 align-items-center mr-3">
            <input
                type="checkbox"
                className="mr-2"
                disabled={!hasEntityReference || !hasEntity}
                checked={isSelected}
                onChange={() => {
                    toggleEntity(
                        {
                            id: entityReference.uri,
                            entityReference,
                            entity: {
                                ...entity,
                                title:
                                    translationKey.entityType === entityTypes.VARIANT_PACKAGE
                                        ? 'Polissoort'
                                        : 'Vergoeding',
                            },
                            translationKey,
                        },
                        true
                    );
                }}
            />
        </div>
    );
}

export function TranslationKeyOptions({ translationKey, languageIso, entityReference }) {
    return (
        <EntityOptions
            entityReference={entityReference}
            languageIso={languageIso}
            showEntityStatus={false}
            showTranslationStatus={true}
            translationKey={translationKey}
        />
    );
}

export const MemoizedTranslationKey = React.memo(TranslationKey);
