import HelperFunctions from '../../../../pages/global/HelperFunctions';
import Constants from '../../../../config/Constants';
import { useTranslation } from 'react-i18next';
import { useCurrentOrganisation } from 'hooks/useCurrentOrganisation';
import Select from 'react-select';
import { useGetAvailableTags } from 'pages/comments/hooks/useGetAvailableTags';
import { Form } from 'react-bootstrap';

export default function CommentTags({ tags, setTags }) {
    const { t } = useTranslation('documents');
    const availableTags = useGetAvailableTags();

    if (availableTags.length === 0) {
        return null;
    }

    return (
        <>
            <Form.Label className="font-weight-bold small mb-1">Tags</Form.Label>
            <Select
                options={HelperFunctions.prepareDropdownData(availableTags)}
                defaultValue={tags}
                onChange={handleChange}
                isMulti={true}
                value={tags}
                placeholder={`${t('document.navbar.main.sidebar.selectVariant.titleSelect')}...`}
                className="docrev-react-select-container"
                classNamePrefix="docrev-react-select"
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                theme={(theme) => ({ ...theme, borderRadius: 4 })}
            />
        </>
    );

    function handleChange(selectedOptions) {
        setTags(selectedOptions);
    }
}

export function useOrganisationTags() {
    const currentOrganisation = useCurrentOrganisation();
    const commentTags = currentOrganisation?.commentTags ?? [];

    // Return organisation tags or default tags
    return commentTags.length > 0 ? commentTags : Constants.comments.tags;
}
