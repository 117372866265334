import {
    batchDeletePublications,
    batchPublishPublications,
    createPublicationPreviewToken,
    deletePublicationVersion,
    fetchPublication,
    publishPublication,
    publishPublicationVersion,
} from 'features/publications/publicationsSlice';
import Constants from '../../../config/Constants';
import HelperFunctions from '../../global/HelperFunctions';
import PublicationApi from '../../../api/PublicationApi';
import { publicationApi } from 'features/publications/publicationApi';

export const openPreview = (dispatch, publication, type) => {
    if (type === undefined) {
        return false;
    }

    dispatch(
        createPublicationPreviewToken({
            id: publication.id,
            formData: {
                publication: publication['@id'],
                type,
                action: Constants.publicationToken.preview,
            },
        }),
    );
};

export const openExportChanges = (dispatch, publication, since, type = 'pdf') => {
    return dispatch(
        createPublicationPreviewToken({
            id: publication.id,
            formData: {
                publication: publication['@id'],
                type,
                action: Constants.publicationToken.export_changes,
                since,
            },
        }),
    );
};

export const remove = (dispatch, publication, t) => {
    if (publication.status === Constants.publicationStatus.published) {
        HelperFunctions.alertModal(t('publication.dropdown.deleteModal.error')).then(() => {
            return false;
        });
    } else {
        HelperFunctions.confirmModal(
            `${t('publication.dropdown.deleteModal.confirmDeletion', { publication: publication.name })}`,
            'danger',
            false,
            t('publication.dropdown.deleteModal.btn.confirm'),
            t('btn.cancel'),
        )
            .then(() => {
                dispatch(
                    batchDeletePublications({
                        formData: {
                            publications: [publication.id],
                        },
                    }),
                ).then(() => {
                    // Invalidate Tags
                    dispatch(publicationApi.util.invalidateTags([{ type: 'Publication', id: 'LIST' }]));
                });
            })
            .catch(() => {});
    }
};

export const publish = (dispatch, publication, formData) => {
    return dispatch(publishPublication({ id: publication.id, formData })).then((result) => {
        return result;
    });
};

export const dePublish = (dispatch, publication, version, t) => {
    HelperFunctions.confirmModal(
        `${t('publication.columnTitles.depublishModal.confirm', { name: publication.name })}`,
        'danger',
        false,
        t('publication.columnTitles.depublishModal.btn.confirm'),
        t('btn.cancel'),
    )
        .then(() => {
            dispatch(
                publishPublication({
                    id: publication.id,
                    formData: {
                        publication: publication['@id'],
                        publicationVersion: version ? version['@id'] : undefined,
                        action: Constants.publicationToken.action_unpublish,
                    },
                }),
            ).then(() => {
                // Reload versions when done
                dispatch(publicationApi.util.invalidateTags([{ type: 'Publication', id: publication['@id'] }]));
                dispatch(fetchPublication({ id: publication.id }));
            });
        })
        .catch(() => {});
};

export const compareVersions = (selectedRows) => {
    const rowsSorted = [...selectedRows].sort((a, b) => a - b);

    return PublicationApi.compareVersions({
        base: rowsSorted[0],
        current: rowsSorted[1],
    });
};

export const comparePublications = (selectedRows) => {
    return PublicationApi.comparePublications({
        base: selectedRows[0],
        current: selectedRows[1],
    });
};

export const batchPublish = (dispatch, formData) => {
    return dispatch(batchPublishPublications({ formData }));
};

export const removeVersion = (dispatch, publication, version, index, t) => {
    let versionNumber = `${index}`;

    if (publication.multiVersions) {
        versionNumber = `${version.prefix} - ${version.counter}`;
    }

    HelperFunctions.confirmModal(
        t('publications:publication.columnData.actions.removeVersionConfirm', { number: versionNumber }),
        'danger',
        false,
        t('global:confirm.yesDelete'),
        t('global:btn.cancel'),
    )
        .then(() => {
            dispatch(deletePublicationVersion({ uri: version['@id'] })).then(() => {
                // Reload versions when done
                dispatch(fetchPublication({ id: publication.id }));
                dispatch(publicationApi.util.invalidateTags([{ type: 'Publication', id: publication['@id'] }]));
            });
        })
        .catch(() => {});
};

export const publishVersion = (dispatch, publication, version, index, t) => {
    let versionNumber = `${index}`;

    if (publication.multiVersions) {
        versionNumber = `${version.prefix} - ${version.counter}`;
    }

    HelperFunctions.confirmModal(
        t('publications:publication.columnData.actions.publishVersionConfirm', { number: versionNumber }),
        undefined,
        false,
        t('publications:publication.btn.yesPublish'),
        t('global:btn.cancel'),
    )
        .then(() => {
            dispatch(
                publishPublicationVersion({
                    id: publication.id,
                    formData: {
                        publication: publication['@id'],
                        publicationVersion: version['@id'],
                        action: Constants.publicationToken.action_publish,
                    },
                }),
            ).then(() => {
                // Reload versions when done
                dispatch(publicationApi.util.invalidateTags([{ type: 'Publication', id: publication['@id'] }]));
                dispatch(fetchPublication({ id: publication.id }));
            });
        })
        .catch(() => {});
};
