import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { getProfile, setActiveOrganisation } from 'api/AuthApi';

export const AuthenticationTypes = {
    UNKNOWN: 'unknown',
    NONE: 'none',
    PARTIAL: 'partial',
    SSO: 'sso',
    FULL: 'full',
};

const UserAdapter = createEntityAdapter();
const OrganisationAdapter = createEntityAdapter();

const securitySlice = createSlice({
    name: 'security',
    initialState: {
        activeOrganisation: parseInt(localStorage.getItem('activeOrganisation') ?? 0),
        organisationUsers: UserAdapter.getInitialState(),
        organisations: OrganisationAdapter.getInitialState(),
        userProfile: false,
        authentication: AuthenticationTypes.UNKNOWN,
    },
    reducers: {
        handleAuthenticationChange(state, action) {
            const userProfile = getProfile();
            let activeOrganisationId = action?.payload?.organisationId;

            state.userProfile = userProfile;
            localStorage.setItem('userEmail', userProfile.email);

            if (activeOrganisationId === undefined && userProfile.hasOwnProperty('organisations')) {
                activeOrganisationId = userProfile.organisations[0].id;
            }

            if (state.activeOrganisation === 0 || state.activeOrganisation !== activeOrganisationId) {
                if (activeOrganisationId !== undefined) {
                    setActiveOrganisation(activeOrganisationId);
                    state.activeOrganisation = activeOrganisationId;
                }
            }

            // Set authentication type
            if (userProfile.hasOwnProperty('requiresSecondFactorAuthentication')) {
                state.authentication = AuthenticationTypes.PARTIAL;
            } else {
                state.authentication = AuthenticationTypes.FULL;
            }
        },
        resetState(state) {
            Object.assign(state, {
                ...securitySlice.getInitialState(),
                activeOrganisation: 0,
                authentication: AuthenticationTypes.NONE,
            });
        },
    },
});

export const { handleAuthenticationChange, resetState } = securitySlice.actions;

export default securitySlice.reducer;
