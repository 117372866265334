import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { useHeight } from '../../../../../hooks/useHeight';
import { getBlockHeight, setBlockPosition } from '../../../../../features/documents/documentsV2Slice';
import { throttle } from 'lodash';

export function BlockHeightWrapper({ blockId, editorDisplaySection, isInsideBlockLayout = false, children }) {
    const dispatch = useDispatch();

    const altHeight = useSelector(
        useMemo(() => {
            return getBlockHeight(blockId, editorDisplaySection === 'left' ? 'right' : 'left');
        }, [blockId, editorDisplaySection]),
    );

    const [divHeight, ref] = useHeight();

    const throttledDispatch = useMemo(
        () =>
            throttle(
                (newHeight) =>
                    dispatch(
                        setBlockPosition({
                            blockId: blockId,
                            view: editorDisplaySection,
                            rect: {
                                height: newHeight,
                            },
                        }),
                    ),
                300,
            ),
        [dispatch, blockId, editorDisplaySection],
    );

    useEffect(() => {
        throttledDispatch(divHeight);
        return () => throttledDispatch.cancel();
    }, [divHeight, throttledDispatch]);

    const minHeight = useMemo(() => {
        if (isInsideBlockLayout) {
            return undefined;
        }
        return altHeight > divHeight ? altHeight : undefined;
    }, [altHeight, divHeight, isInsideBlockLayout]);

    return (
        <div
            className="edit-area-block-content-wrapper flex-grow-1 position-relative"
            style={{
                minHeight,
            }}
        >
            {isInsideBlockLayout ? <div>{children}</div> : <div ref={ref}>{children}</div>}
        </div>
    );
}
