import { Route, useRouteMatch } from 'react-router-dom';
import Index from '../pages/media_library/views/Index';
import React from 'react';
import { ReactTitle } from 'components/ReactTitle';

export const BASE_PATH = '/media-library';

const MediaLibrary = () => {
    const { url } = useRouteMatch();

    return (
        <>
            <ReactTitle title="Media" />
            <Route path={url}>
                <Index />
            </Route>
        </>
    );
};

export default MediaLibrary;
