import { Field } from 'formik';

export function FormGroup({ label, htmlFor = '', help = '', labelCol = 6, children }) {
    const inputCol = 12 - labelCol;

    return (
        <div className="sidebar-form-group">
            <div className="row">
                <div className={`col-${labelCol}`}>
                    <label htmlFor={htmlFor} className="small pt-1 mb-0 font-weight-bold">
                        {label}
                    </label>
                    {help !== '' && <small className="form-text mt-0">{help}</small>}
                </div>
                <div className={`col-${inputCol} pt-1`}>{children}</div>
            </div>
        </div>
    );
}

export function CheckBox({ label, name, props = {}, help = '' }) {
    return (
        <div className="form-checkbox">
            <div className="form-check">
                <Field name={name} id={name} type="checkbox" className="form-check-input" {...props} />
                <label className="form-check-label font-weight-bolder small" htmlFor={name}>
                    {label}
                </label>
            </div>
            {help !== '' && <small className="form-text">{help}</small>}
        </div>
    );
}
