import { Alert, Modal } from 'react-bootstrap';
import { Form as FForm, Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { FormModal, ModalFooter } from '../../global/FormModal';
import { generatePath, useHistory } from 'react-router-dom';
import { MODEL_IMPORT_PATH, VIEW_PATH } from 'scenes/DocumentsV2';
import { FormFieldWithTitle, InputSelectWithTitle } from '../../publications_v2/helpers/FieldHelper';
import { DocumentTypeSelect } from '../views/settings/Properties';
import { useAddDocumentMutation } from 'features/documents/documents';
import { useCurrentOrganisation } from 'hooks/useCurrentOrganisation';
import Constants, { AccountRestrictions } from '../../../config/Constants';
import ChooseTemplateModal from '../views/edit/templates/ChooseTemplateModal';
import { useTranslation } from 'react-i18next';
import { useHasAccountRestriction } from 'hooks/useHasAccountRestriction';
import { useGetDocuments } from 'pages/documents_v2/hooks/useGetDocuments';
import { useEffect, useMemo } from 'react';
import { isDocumentLimitReached, updateAbilityForDocuments } from 'ability/Ability';
import { useAbility } from 'ability/useAbility';
import { useUserProfile } from 'hooks/useUserProfile';
import { useGetModelsByTemplateCategory } from 'pages/documents_v2/hooks/useGetModelsByTemplateCategory';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';
import _ from 'lodash';
import HelperFunctions from 'pages/global/HelperFunctions';
import { useGetPublicationDomains } from 'pages/publications_v2/hooks/useGetPublicationDomains';

export default function NewDocumentModal() {
    const [showNewDocumentModal, setShowNewDocumentModal] = useQueryParam('newDocument', BooleanParam);
    const { t } = useTranslation('documents');

    return (
        <FormModal
            dialogClassName="model-new-document"
            show={showNewDocumentModal}
            onHide={handleClose}
            title={t('documents.newDocument.title')}
        >
            <>{showNewDocumentModal && <NewDocumentModalContent handleClose={handleClose} />}</>
        </FormModal>
    );

    function handleClose() {
        setShowNewDocumentModal(undefined);
    }
}

function NewDocumentModalContent({ handleClose }) {
    const history = useHistory();
    const currentOrganisation = useCurrentOrganisation();
    const [addDocument] = useAddDocumentMutation();
    const { t } = useTranslation('documents');
    const allDocuments = useGetDocuments();
    const ability = useAbility();
    const userProfile = useUserProfile();
    const [startTour] = useQueryParam('startTour', StringParam);

    const allowOnlyFromDocumentTemplate = useHasAccountRestriction(
        AccountRestrictions.document.allowOnlyFromDocumentTemplate,
    );

    const canCreateDocument = ability.can('create', 'Document');
    const numberOfDocuments = allDocuments.length;

    useEffect(() => {
        updateAbilityForDocuments(ability, numberOfDocuments, userProfile, currentOrganisation);
    }, [numberOfDocuments, currentOrganisation, userProfile]);

    const initialValues = getInitialValues();
    const validationSchema = getValidationSchema();
    const limitReached = isDocumentLimitReached(numberOfDocuments, currentOrganisation);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, dirty, isValid }) => (
                <FForm autoComplete="off">
                    <Modal.Body>
                        {limitReached && (
                            <Alert variant="danger">
                                Limit reached for current subscription. Please upgrade your plan to allow for more
                                documents.
                            </Alert>
                        )}

                        <RenderFields />
                    </Modal.Body>

                    <ModalFooter
                        isValid={isValid && canCreateDocument}
                        dirty={dirty}
                        btnSubmitText={t('documents.newDocument.btn.save')}
                        isSubmitting={isSubmitting}
                        onHide={handleClose}
                    />
                </FForm>
            )}
        </Formik>
    );

    function getInitialValues() {
        return {
            name: '',
            type: Constants.documentType.default,
            modelId: null,
            modelSource: null,
            domainId: undefined,
        };
    }

    function handleSubmit(values, { setSubmitting }) {
        let body = values;

        if (!!values.modelId) {
            body.autoCreateFromVariant = true;
            body.variantGroupName = currentOrganisation.name;
        }

        addDocument({
            organisationId: currentOrganisation.id,
            body,
        }).then(({ data }) => {
            setSubmitting(false);

            if (!!values.modelId) {
                // Redirect to the import path
                let redirectPath = generatePath(MODEL_IMPORT_PATH, {
                    documentId: data.id,
                    domainId: values.domainId,
                });

                if (!_.isEmpty(startTour)) {
                    redirectPath = redirectPath + `?fromTour=${startTour}`;
                }

                history.push(redirectPath);
            } else {
                // Redirect to the new Document
                history.push(
                    generatePath(VIEW_PATH, {
                        documentId: data.id,
                    }),
                );
            }
        });
    }

    function getValidationSchema() {
        return Yup.object().shape({
            name: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required(),
            type: Yup.string().required(),
            modelId: allowOnlyFromDocumentTemplate ? Yup.number().required() : Yup.number().nullable(),
            modelSource: Yup.string()
                .nullable()
                .when('modelId', {
                    is: (modelId) => !!modelId,
                    then: Yup.string().required(),
                }),
            variantName: Yup.string().when('modelId', {
                is: (modelId) => !!modelId,
                then: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required(),
            }),
            domainId: Yup.number().when('modelId', {
                is: (modelId) => !!modelId,
                then: Yup.number().required(),
            }),
        });
    }
}

function RenderFields() {
    const { t } = useTranslation('documents');
    const { values } = useFormikContext();
    const { modelId, type } = values;

    const allowOnlyFromDocumentTemplate = useHasAccountRestriction(
        AccountRestrictions.document.allowOnlyFromDocumentTemplate,
    );

    const isTemplateSelected = modelId !== null;

    return (
        <div>
            {!allowOnlyFromDocumentTemplate && (
                <>
                    <DocumentTypeSelect />
                </>
            )}

            {type === Constants.documentType.default && (
                <ChooseTemplateModal
                    required={allowOnlyFromDocumentTemplate}
                    isClearable={!allowOnlyFromDocumentTemplate}
                    label={
                        allowOnlyFromDocumentTemplate ? 'documents.newDocumentFromTemplate.templateLabel' : undefined
                    }
                />
            )}

            {!isTemplateSelected && !allowOnlyFromDocumentTemplate && (
                <FormFieldWithTitle
                    name="name"
                    label={t('documents.newDocument.name')}
                    props={{ required: true, placeholder: t('documents.newDocument.titleSelect') }}
                />
            )}

            {(isTemplateSelected || allowOnlyFromDocumentTemplate) && <CreateFromTemplateFields />}
        </div>
    );
}

function CreateFromTemplateFields() {
    const { t } = useTranslation('documents');
    const { values, setValues } = useFormikContext();
    const { modelId, domainId } = values;
    const modelsByTemplateCategory = useGetModelsByTemplateCategory();
    const publicationDomains = useGetPublicationDomains();

    const model = useMemo(() => {
        if (modelId) {
            return modelsByTemplateCategory.find((_model) => _model.documentId === modelId);
        }
    }, [modelId]);

    const domainOptions = getDomainOptions(model);

    useEffect(() => {
        if (model) {
            setValues({
                ...values,
                name: getName(model),
                domainId: _.isEmpty(domainId) && domainOptions.length > 0 ? domainOptions[0].id : undefined,
            });
        }
    }, [model]);

    return (
        <>
            <FormFieldWithTitle
                name="name"
                label={`${t('documents.newDocumentFromTemplate.name')}`}
                props={{
                    required: true,
                    placeholder: `${t('documents.newDocumentFromTemplate.nameSelect')}`,
                    className: 'tour-new-document-field-name',
                }}
            />

            <FormFieldWithTitle
                name="variantName"
                label={`${t('documents.newDocumentFromTemplate.variantName')}`}
                props={{
                    required: true,
                    placeholder: `${t('documents.newDocumentFromTemplate.variantNameSelect')}...`,
                    className: 'tour-new-document-field-variantName',
                }}
            />

            <InputSelectWithTitle
                name="domainId"
                title={t('publications:publication.settings.content.layout.titles.domain')}
                options={domainOptions}
                props={{ required: true }}
            />
        </>
    );

    function getDomainOptions(model) {
        if (model) {
            if (!_.isEmpty(model?.templateCategory?.domain)) {
                const filteredDomains = publicationDomains.filter((publicationDomain) =>
                    publicationDomain.domain.startsWith(model.templateCategory.domain),
                );

                return HelperFunctions.prepareDropdownData(filteredDomains, 'domain', 'id');
            }

            return HelperFunctions.prepareDropdownData(publicationDomains, 'domain', 'id');
        }

        return [];
    }

    function getName(model) {
        if (model.name.startsWith('{') && model.name.endsWith('}')) {
            return '';
        }

        return model.name;
    }
}
