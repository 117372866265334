import { useContext, useMemo, useState } from 'react';
import { useEntityTemplateParameters } from 'pages/documents_v2/hooks/useEntityTemplateParameters';
import { useGetDocument } from 'pages/documents_v2/hooks/useGetDocument';
import { useGetTemplate } from 'pages/documents_v2/hooks/useGetTemplate';
import { replace } from 'lodash';
import HelperFunctions from '../../../global/HelperFunctions';
import { BlockTypeContent } from 'pages/documents_v2/views/edit_area/blocks/Block';
import { ChevronLeft } from 'react-bootstrap-icons';
import cx from 'classnames';
import Constants from '../../../../config/Constants';
import { BlockType } from 'pages/documents_v2/views/edit_area/blocks/BlockType';
import { AddBlockModal } from 'pages/documents_v2/modals/AddBlockModal';
import { BlockName } from 'pages/documents_v2/views/edit_area/blocks/BlockName';
import { InfoButton } from 'components/Buttons';
import { EditAreaContext } from 'pages/documents_v2/views/edit_area/EditArea';
import { useTranslation } from 'react-i18next';

export default function EditTemplateBlocks({ area }) {
    const document = useGetDocument();
    const template = useGetTemplate(document?.modelId, document?.modelSource) ?? [];

    const areas = useMemo(() => {
        const sections = _.get(template, 'sections', []);

        if (_.isArray(sections)) {
            return sections.flatMap((section) => section.areas);
        }

        return Object.values(sections).flatMap((section) => section.areas);
    }, [template]);

    const thisAreas = areas ? areas.filter((a) => a.id === area.properties.parentId) : [];

    const areaBlocks = thisAreas
        .flatMap((area) => area.blocks)
        .map((block) => ({
            ...block,
            key: block.id,
        }));

    const blockIdsInLayout = getBlockIdsInLayout(areaBlocks);

    const blocksToRender = useMemo(() => {
        if (blockIdsInLayout.length === 0) {
            return areaBlocks;
        }

        return areaBlocks.filter((block) => !blockIdsInLayout.includes(block.id));
    }, [areaBlocks]);

    return (
        <div>
            <TemplateBlocksList blocks={blocksToRender} area={area} areaBlocks={areaBlocks} />
            {/* <AddAllBlocksFromTemplate /> */}
        </div>
    );
}

function TemplateBlocksList({ blocks, area, areaBlocks }) {
    return (
        <>
            {blocks.map((block, index) => (
                <div key={block.id} className="align-items-center mb-4">
                    <TemplateBlockCard
                        block={block}
                        index={index}
                        area={area}
                        areaBlocks={areaBlocks}
                    />
                </div>
            ))}
        </>
    );
}

function TemplateBlockCard({ block, index, area, areaBlocks }) {
    const { t } = useTranslation('documents');
    const document = useGetDocument();
    const restrictions = useEntityTemplateParameters(block, 'block', document);
    const [showAddBlockModal, setShowAddBlockModal] = useState(false);
    const { insertBlock, generateKey } = useContext(EditAreaContext);

    const parentBlockTemplateParameters = block?.properties?.templateParameters;

    const handleInsertBlock = (formValues) => {
        if (block.type === 'blockLayout') {
            const currentBlockData = HelperFunctions.tryParseJSON(block.baseContent);

            // TODO! refactor later into a generateRandomKey function that takes in a an array
            // and generates random numbers based on the length of the array
            // increasing the ceiling so much that the probablity of duplicates would be marginal (<0.001%)
            const numberOfRows = currentBlockData.data.length;
            const numberOfColumns = currentBlockData.data.length > 0 ? currentBlockData.data[0].length : 0;
            const numberOfCells = numberOfRows * numberOfColumns;
            const randomCeiling = numberOfCells * 1000;
            let randomIds = new Set();

            const generateUniqueKeys = () => {
                let randomKey;
                do {
                    randomKey = Math.floor(Math.random() * randomCeiling) + 1;
                } while (randomIds.has(randomKey));

                randomIds.add(randomKey);

                return randomKey;
            };

            if (currentBlockData && currentBlockData.data) {
                let blockContent = block.baseContent;

                const innerLayoutBlockKeys = [];
                currentBlockData.data.forEach((row) => {
                    row.forEach((column) => {
                        column.forEach((item) => {
                            if (item.id) {
                                innerLayoutBlockKeys.push(item.id);
                            }
                        });
                    });
                });

                const InnerBlocks = areaBlocks.filter((block) => innerLayoutBlockKeys.includes(block.id));

                InnerBlocks.forEach((innerBlock) => {
                    const blockWithVariants = {
                        id: null,
                        key: generateUniqueKeys(),
                        documentVariants: formValues.variants,
                        latestContent: innerBlock.baseContent,
                        properties: {
                            ...innerBlock.properties,
                            templateParameters: parentBlockTemplateParameters,
                            parentId: innerBlock.id,
                        },
                        type: innerBlock.type,
                    };

                    blockContent = JSON.parse(blockContent);
                    while (
                        blockContent.data.length > 0 &&
                        blockContent.data[blockContent.data.length - 1].every((row) => String(row).trim() === '')
                    ) {
                        blockContent.data.pop();
                    }
                    blockContent = JSON.stringify(blockContent);

                    blockContent = replace(blockContent, `"id":${innerBlock.id}`, `"id":${blockWithVariants.key}`);

                    insertBlock(blockWithVariants);
                });

                const parentLayoutBlock = {
                    id: null,
                    key: generateUniqueKeys(),
                    documentVariants: formValues.variants,
                    latestContent: blockContent,
                    properties: {
                        ...block.properties,
                        templateParameters: parentBlockTemplateParameters,
                        parentId: block.id,
                    },
                    type: block.type,
                };

                insertBlock(parentLayoutBlock);
            }
        } else {
            const blockWithVariants = {
                id: null,
                key: generateKey(),
                documentVariants: formValues.variants,
                latestContent: block.baseContent,
                properties: {
                    ...block.properties,
                    templateParameters: parentBlockTemplateParameters,
                    parentId: block.id,
                },
                type: block.type,
            };

            insertBlock(blockWithVariants);
        }

        setShowAddBlockModal(false);
    };

    return (
        <div className="card">
            <div className="card-body p-2">
                <div
                    className={cx(`edit-area-block-type-${block.type}`, {
                        [`blockLayout-type-${block.properties.type ?? 'default'}`]:
                            block.type === Constants.blockTypes.blockLayout,
                    })}
                >
                    <BlockName block={block} />

                    <div className="editor-toolbar">
                        <BlockType block={block} />
                    </div>

                    <BlockTypeContent
                        block={block}
                        index={index}
                        isDragging={false}
                        readOnly={true}
                        areaBlocks={areaBlocks}
                        restrictions={restrictions}
                    />
                </div>
            </div>
            <div className="card-footer">
                <InfoButton
                    className="d-flex align-items-center ml-2"
                    onClick={() => setShowAddBlockModal(true)}
                    data-uk-tooltip="Blok toevoegen"
                >
                    <ChevronLeft size={16} className="mr-1" />
                    {t('document.navbar.main.btn.useText')}
                </InfoButton>
            </div>

            {showAddBlockModal && (
                <AddBlockModal
                    defaultBlockType={block.type}
                    hide={() => setShowAddBlockModal(false)}
                    onSubmit={handleInsertBlock}
                    defaultVariants={[]}
                    defaultVariantOption="all"
                    area={area}
                    canChangeBlockType={false}
                />
            )}
        </div>
    );
}

function getBlockIdsInLayout(blocks = []) {
    // Gets all the block Ids that are inside blockLayouts
    const allBlockKeys = [];

    blocks.forEach((block) => {
        if (block.type === 'blockLayout') {
            const currentBlockData = HelperFunctions.tryParseJSON(block?.baseContent) ?? {};
            if (currentBlockData.data) {
                currentBlockData.data.forEach((row) => {
                    // Check if row is defined before iterating over its elements
                    if (row) {
                        row.forEach((column) => {
                            if (column) {
                                column.forEach((item) => {
                                    if (item.id) {
                                        allBlockKeys.push(item.id);
                                    }
                                });
                            }
                        });
                    }
                });
            }
        }
    });
    return allBlockKeys;
}
