import { PrimaryButton } from 'components/Buttons';
import { set } from 'features/app/appSlice';
import { useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';
import Constants from 'config/Constants';
import { entityTypes } from 'pages/translation/config/Constants';

export function Labels() {
    const dispatch = useDispatch();
    const { values } = useFormikContext();

    return <PrimaryButton onClick={handleClick}>Labels bewerken</PrimaryButton>;

    function handleClick() {
        dispatch(
            set({
                key: 'sidebarContext',
                value: {
                    type: 'sidebarSettings',
                    context: {
                        entity: {
                            ...values,
                            title: values.name,
                        },
                        entityType: entityTypes.PUBLICATION,
                        landingTab: 'labels',
                    },
                },
            })
        );
    }
}
