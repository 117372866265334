import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { useGetTasksQuery } from 'features/comments/commentApi';

export function useGetMyTasksCount() {
    const organisationId = useActiveOrganisation();

    let params = {
        organisationId,
    };

    const { totalItems } = useGetTasksQuery(
        { myTasks: true, params },
        {
            selectFromResult: (result) => {
                const { currentData } = result;

                return {
                    totalItems: currentData ? currentData['hydra:totalItems'] : 0,
                };
            },
        },
    );

    return totalItems;
}
