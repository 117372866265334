import React, { useMemo } from 'react';
import { Badge, Button, Dropdown, Navbar } from 'react-bootstrap';
import { generatePath, Link, NavLink, useHistory } from 'react-router-dom';
import { ChevronRight, QuestionCircleFill } from 'react-bootstrap-icons';
import _ from 'lodash';
import LanguageSwitcher from '../language/LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import { SETTINGS_PATH } from 'scenes/Account';
import { useCurrentOrganisation } from 'hooks/useCurrentOrganisation';
import { useUserProfile } from 'hooks/useUserProfile';
import Constants, { Permissions } from '../config/Constants';
import RestrictedContent, { useHasPermission } from './global/RestrictedContent';
import { ADMIN_SETTINGS_PATH } from 'scenes/Admin';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { BASE_PATH } from 'scenes/DocumentsV2';
import cx from 'classnames';
import { STATUS_CANCELED, STATUS_PAUSED, STATUS_TRIALING } from 'pages/account/views/Billing';
import { ACCOUNT_CANCELLED_PATH } from 'pages/account/views/AccountCancelled';
import { LOGOUT_PATH } from 'App';
import { useGetSubscriptionStatus } from 'hooks/useGetSubscriptionStatus';

export default function MainContentNav(props) {
    const { pages = [], title = '' } = props;

    return (
        <Navbar fixed="top" className="breadcrumbs border-bottom px-4">
            {(pages.length > 0 || title !== '') && (
                <div className="links d-flex flex-wrap align-items-center">
                    {title !== '' && <div>{title}</div>}
                    {pages.length > 0 && <NavBreadCrumbs pages={pages} />}
                </div>
            )}

            <div className="ml-auto">
                <div className="d-flex align-items-center">
                    {props.children}

                    <div className="d-flex align-items-center" style={{ marginRight: '30px' }}>
                        <SubscriptionTrialBadge />
                        <NavBarDocs />
                        <NavBarAvatar />
                    </div>
                </div>
            </div>
        </Navbar>
    );
}

export function NavBreadCrumbs({ pages = [] }) {
    const pageCount = pages.length - 1;
    const { t } = useTranslation('documents');

    return (
        <>
            {pages.map((page, index) => {
                const last = index >= pageCount;

                // Apply translation only to the last child
                const pageTitle = last ? t(page.title) : page.title;

                return (
                    <React.Fragment key={`nav-pages-${index}`}>
                        {page.url && !last && <Link to={page.url}>{pageTitle}</Link>}
                        {!page.url || last ? (
                            <div>{last ? _.truncate(pageTitle, { length: 80 }) : pageTitle}</div>
                        ) : null}

                        {!last && <ChevronRight className="text-secondary mx-2" size={12} />}
                    </React.Fragment>
                );
            })}
        </>
    );
}

const Organisations = () => {
    const userProfile = useUserProfile();
    const activeOrganisation = useActiveOrganisation();
    const { t } = useTranslation('global');

    return (
        <>
            <Dropdown.Divider />
            <Dropdown.Header>{t('avatar.organization')}:</Dropdown.Header>
            {userProfile.organisations.map(function (organisation) {
                return (
                    <Dropdown.Item
                        disabled={activeOrganisation === organisation.id}
                        href={BASE_PATH + '?organisationId=' + organisation.id}
                        key={`select-organisation-${organisation.id}`}
                    >
                        <div
                            className={cx({
                                current: activeOrganisation === organisation.id,
                            })}
                        >
                            {organisation.name} {activeOrganisation === organisation.id && <span>(actief)</span>}
                        </div>
                    </Dropdown.Item>
                );
            })}
        </>
    );
};

function SubscriptionTrialBadge() {
    const { t } = useTranslation('users');
    const status = useGetSubscriptionStatus();
    const canManageBilling = useHasPermission(Permissions.Account['Billing.Manage']);
    const history = useHistory();

    if (status === STATUS_CANCELED) {
        history.push(ACCOUNT_CANCELLED_PATH);
        return null;
    }

    if (status === STATUS_PAUSED) {
        return (
            <Link
                to={generatePath(SETTINGS_PATH, {
                    view: 'billing',
                })}
                className="badge badge-warning mr-3"
            >
                {t('profile.subscriptionExpired')}
            </Link>
        );
    }

    if (status !== STATUS_TRIALING) {
        return null;
    }

    if (canManageBilling) {
        return <>{renderAsLink()}</>;
    }

    return (
        <Badge variant="warning" className="mr-3">
            {t('profile.trialModelActive')}
        </Badge>
    );

    function renderAsLink() {
        return (
            <Link
                to={generatePath(SETTINGS_PATH, {
                    view: 'billing',
                })}
                className="badge badge-warning mr-3"
            >
                {t('profile.trialModelActive')}
            </Link>
        );
    }
}

function NavBarDocs() {
    const currentOrganisation = useCurrentOrganisation();
    const website = currentOrganisation?.website ?? '';

    if (_.isEmpty(website)) {
        return null;
    }

    return (
        <Button
            href={website}
            target="_blank"
            variant="icon"
            className="d-flex align-items-center p-0 mr-3 button"
            style={{
                color: '#ff9434',
            }}
        >
            <QuestionCircleFill className="mr-1" size={20} />
            Help
        </Button>
    );
}

function NavBarAvatar() {
    const userProfile = useUserProfile();
    const history = useHistory();
    const { t } = useTranslation('global');

    const isSuperAdmin = userProfile.roles.includes(Constants.userRoles.superAdmin);

    const initialsString = useMemo(() => {
        const name = userProfile.firstName + ' ' + userProfile.lastName;
        const creatorName = name.split(' '); // split the name into an array of names
        const initials = creatorName.slice(0, 2).map((name) => name.charAt(0).toUpperCase()); // get the first two initials

        return initials.join(''); // join the initials into a string
    }, [userProfile]);

    return (
        <Dropdown className="avatar">
            <Dropdown.Toggle as="span" variant="link" id="user-dropdown">
                {initialsString}
            </Dropdown.Toggle>

            <Dropdown.Menu align="right">
                <Dropdown.Item active={true} as={NavLink} to={generatePath(SETTINGS_PATH, { view: 'profile' })}>
                    {t('avatar.manageAccount')}
                </Dropdown.Item>

                {isSuperAdmin && (
                    <RestrictedContent permission={Permissions.Account.Create}>
                        <Dropdown.Item as={NavLink} to={generatePath(ADMIN_SETTINGS_PATH, { view: 'accounts' })}>
                            {t('avatar.admin')}
                        </Dropdown.Item>
                    </RestrictedContent>
                )}

                <Organisations />
                <Dropdown.Divider />
                <Dropdown.Item
                    onClick={() => {
                        history.push(LOGOUT_PATH);
                    }}
                >
                    {t('avatar.logout')}
                </Dropdown.Item>
                <Dropdown.Divider />
                <div className="ml-3">
                    <LanguageSwitcher />
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
}

{
    /* function NavBarAvatar() {
    const names = creatorName.split(' '); // split the name into an array of names
    const initials = names.slice(0, 2).map((name) => name.charAt(0).toUpperCase()); // get the first two initials
    const initialsString = initials.join(''); // join the initials into a string
    return (
        <Dropdown className="avatar">
            <Dropdown.Toggle as="span" variant="link" id="user-dropdown">
                {initialsString}
            </Dropdown.Toggle>
        </Dropdown>
    );
} */
}
