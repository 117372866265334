import { generatePath, Link, useParams } from 'react-router-dom';
import { Navbar } from 'react-bootstrap';
import { EDIT_PATH } from '../../../../scenes/Translations';
import cx from 'classnames';
import { translationContainerTypes, views } from '../../config/Constants';
import {
    DocumentContainer,
    ReimbursementContainer,
    TranslationContainer,
    TranslationLanguage,
} from '../../../../models/translation.models';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useGetDocument } from '../../../documents_v2/hooks/useGetDocument';

export function SubNav({
    translationContainer,
    translationLanguage,
}: {
    translationContainer: TranslationContainer;
    translationLanguage: TranslationLanguage;
}) {
    if (translationContainer['@type'] === translationContainerTypes.DOCUMENT) {
        return (
            <DocumentSubNav
                documentContainer={translationContainer as DocumentContainer}
                translationLanguage={translationLanguage}
            />
        );
    }

    if (translationContainer['@type'] === translationContainerTypes.REIMBURSEMENT) {
        return (
            <ReimbursementSubNav
                reimbursementContainer={translationContainer as ReimbursementContainer}
                translationLanguage={translationLanguage}
            />
        );
    }

    return null;
}

function DocumentSubNav({
    documentContainer,
    translationLanguage,
}: {
    documentContainer: DocumentContainer;
    translationLanguage: TranslationLanguage;
}) {
    const {
        view,
    }: {
        view: string;
    } = useParams();
    const { documentId } = documentContainer;
    const { t } = useTranslation('reimbursements');

    const document = useGetDocument(documentId, true);

    return (
        <Navbar fixed="top" className="subnav bg-white border-bottom px-3 py-0">
            <div className="nav w-100">
                <div className="nav-item">
                    <Link
                        to={generatePath(EDIT_PATH, {
                            translationContainerId: documentContainer.id,
                            translationLanguageId: translationLanguage.id,
                            view: views.DOCUMENT,
                        })}
                        className={cx('nav-link', {
                            active: view === views.DOCUMENT || view === undefined,
                        })}
                    >
                        Document
                    </Link>
                </div>
                <div className="nav-item">
                    <Link
                        to={generatePath(EDIT_PATH, {
                            translationContainerId: documentContainer.id,
                            translationLanguageId: translationLanguage.id,
                            view: views.TAGS,
                        })}
                        className={cx('nav-link', {
                            active: view === views.TAGS,
                        })}
                    >
                        Tags
                    </Link>
                </div>
                {!_.isNil(document?.reimbursementCategoryId) && (
                    <div className="nav-item">
                        <Link
                            to={generatePath(EDIT_PATH, {
                                translationContainerId: documentContainer.id,
                                translationLanguageId: translationLanguage.id,
                                view: views.PRODUCTS,
                            })}
                            className={cx('nav-link', {
                                active: view === views.PRODUCTS,
                            })}
                        >
                            {t('collectivity.editCollectivity.sidebar.policyTypes')}
                        </Link>
                    </div>
                )}
            </div>
        </Navbar>
    );
}

function ReimbursementSubNav({
    reimbursementContainer,
    translationLanguage,
}: {
    reimbursementContainer: ReimbursementContainer;
    translationLanguage: TranslationLanguage;
}) {
    const {
        view,
    }: {
        view: string;
    } = useParams();
    const { t } = useTranslation('reimbursements');

    return (
        <Navbar fixed="top" className="subnav bg-light border-bottom px-3 py-0">
            <div className="nav w-100">
                <div className="nav-item">
                    <Link
                        to={generatePath(EDIT_PATH, {
                            translationContainerId: reimbursementContainer.id,
                            translationLanguageId: translationLanguage.id,
                            view: views.REIMBURSEMENTS,
                        })}
                        className={cx('nav-link', {
                            active: view === views.REIMBURSEMENTS || view === undefined,
                        })}
                    >
                        {t('product.form.reimbursement')}
                    </Link>
                </div>
                <div className="nav-item">
                    <Link
                        to={generatePath(EDIT_PATH, {
                            translationContainerId: reimbursementContainer.id,
                            translationLanguageId: translationLanguage.id,
                            view: views.PRODUCTS,
                        })}
                        className={cx('nav-link', {
                            active: view === views.PRODUCTS,
                        })}
                    >
                        {t('collectivity.editCollectivity.sidebar.policyTypes')}
                    </Link>
                </div>
            </div>
        </Navbar>
    );
}
