import _ from 'lodash';
import Parser from 'html-react-parser';
import { Card } from 'react-bootstrap';
import { getTypeAsString } from '../../../helpers/EntityHelper';
import { findAll } from 'highlight-words-core';
import { useContext } from 'react';
import { EditContext } from '../../view/EditContextWrapper';

export default function ReimbursementProductProperties({ selectedEntity }) {
    const { search } = useContext(EditContext);
    const { entity, translationKey } = selectedEntity;
    const { property, entityType } = translationKey;

    let title = _.get(entity, property, '');

    if (search !== '') {
        const chunks = findAll({
            searchWords: [search],
            textToHighlight: title
        });

        title = chunks
            .map(chunk => {
                const { end, highlight, start } = chunk;
                const text = title.substr(start, end - start);
                if (highlight) {
                    return `<mark>${text}</mark>`;
                } else {
                    return text;
                }
            })
            .join('');
    }

    return (<>
        <div className="mb-4" role="table">
            <div
                style={{
                    lineHeight: '20px',
                }}
                className="d-flex"
                role="row"
            >
                <div
                    style={{
                        flex: '0 0 100px',
                        color: '#5f6368',
                    }}
                    className=""
                    role="cell"
                >
                    Type
                </div>
                <div
                    style={{
                        flex: '1 1 auto',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                    className=""
                    role="cell"
                >
                    {getTypeAsString(property, entityType)}
                </div>
            </div>
        </div>
        <Card>
            <Card.Body>
                <div className="rendered-content p-0">
                    {Parser(title)}
                </div>
            </Card.Body>
        </Card>
    </>);
}
