import HelperFunctions from '../../../../../global/HelperFunctions';
import { Col, Row } from 'react-bootstrap';
import Block from './Block';
import { useContext } from 'react';
import { TranslationContext } from '../../Translate';
import _ from 'lodash';
import cx from 'classnames';

export default function BlockLayout(props) {
    const { visibleEntities } = useContext(TranslationContext);
    const { id, latestContent } = props.block;
    const blockLayoutData = HelperFunctions.tryParseJSON(latestContent);

    if (!blockLayoutData) {
        return null;
    }

    const { data, properties = {} } = blockLayoutData;
    const { showTableHeader = true } = properties;
    const blockUris = data.flat(2).map((_item) => `/api/block/${_item.id}`);

    return (
        <div
            className={cx('block-layout', {
                // Hide table when all containing Blocks are invisible
                'd-none': _.intersection(visibleEntities, blockUris).length === 0,
            })}
        >
            {data.map((el, ind) => (
                <Row
                    style={{
                        marginBottom: -1,
                        backgroundColor: showTableHeader && ind === 0 ? '#f6f8fa' : undefined,
                    }}
                    key={`layout-${id}-row-${ind}`}
                >
                    {el.map((col, colIndex) => (
                        <Col
                            className="border-muted border"
                            style={{ marginRight: -1 }}
                            key={`layout-${id}-row-${ind}-col-${colIndex}`}
                        >
                            <div
                                style={{
                                    paddingTop: '2rem',
                                }}
                            >
                                {col.map((item) => (
                                    <RenderLayoutBlock {...props} item={item} key={`layout-${id}-item-${item.id}`} />
                                ))}
                            </div>
                        </Col>
                    ))}
                </Row>
            ))}
        </div>
    );
}

function RenderLayoutBlock(props) {
    const { areaData, item } = props;
    const { baseBlocks = [] } = areaData;
    const block = baseBlocks.find((_block) => _block.id === item.id);

    if (!block || block.deletedAt) {
        return null;
    }

    return <Block {...props} block={block} />;
}
