import { Check2Circle, DashCircle, ExclamationCircle } from 'react-bootstrap-icons';
import { getStatus, isEnabledForTranslation, isTranslationInProgress } from '../../helpers/EntityReferenceHelper';
import { useGetEntityReference } from '../../../../features/translations/translationApi';
import { Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export function EntityReferenceStatus({
    translationContainerUri,
    entityType,
    entityUri,
    languageIso,
}: {
    translationContainerUri: string;
    entityType: string;
    entityUri: string;
    languageIso: string;
}) {
    const entityReference = useGetEntityReference(translationContainerUri, entityType, entityUri);
    const { t } = useTranslation('translations');

    if (entityReference === undefined) {
        return null;
    }

    if (isEnabledForTranslation(entityReference, languageIso) === false) {
        return (
            <div className="d-flex align-items-center text-secondary">
                <DashCircle className="mr-1" />
                <div>{t('translation.navbar.dashboard.document.status.excluded')}</div>
            </div>
        );
    }

    if (isTranslationInProgress(entityReference, languageIso)) {
        const contentHashChanged = getStatus(entityReference, 'contentHashChanged', languageIso);
        const fromImport = getStatus(entityReference, 'fromImport', languageIso);

        return (
            <div className="d-flex align-items-center">
                <div className="d-flex align-items-center text-danger">
                    <ExclamationCircle size={14} className="mr-1" />
                    <div>{t('translation.navbar.dashboard.document.status.notYetReady')}</div>
                </div>

                {contentHashChanged > 0 && (
                    <Badge
                        variant="danger ml-2"
                        style={{
                            fontSize: 9,
                        }}
                    >
                        {t('translation.navbar.dashboard.document.status.tooltip.sourceUpdated')}
                    </Badge>
                )}

                {fromImport > 0 && (
                    <Badge
                        variant="danger ml-2"
                        style={{
                            fontSize: 9,
                        }}
                    >
                        {t('translation.navbar.dashboard.translation.fromImport')}
                    </Badge>
                )}
            </div>
        );
    }

    return (
        <div className="d-flex align-items-center text-success">
            <Check2Circle size={14} className="mr-1" />
            <div>{t('translation.navbar.dashboard.document.status.translationComplete')}</div>
        </div>
    );
}
