import Joyride from 'react-joyride';
import { useEffect, useState } from 'react';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';
import { matchPath, useLocation } from 'react-router-dom';
import { INDEX_PATH } from 'scenes/PublicationsV2';
import { useSelector } from 'react-redux';
import { selectPublicationById } from 'features/publications/publicationsSlice';
import { globalStyles } from 'components/Tours';

function useParams(path) {
    const { pathname } = useLocation();
    const match = matchPath(pathname, { path });
    return match?.params || {};
}

export function IpidEuNewPublication() {
    const [stepIndex, setStepIndex] = useState(0);
    const [run, setRun] = useState(false);
    const [startTour, setStartTour] = useQueryParam('startTour', StringParam);
    const [showPublishModal] = useQueryParam('showPublishModal', NumberParam);
    const { publicationId } = useParams(INDEX_PATH)
    const publication = useSelector((state) => selectPublicationById(state.publications, parseInt(publicationId)));

    useEffect(() => {
        if (publication && run === false) {
            setRun(true);
        }
    }, [publication]);

    useEffect(() => {
        if (showPublishModal && stepIndex === 0) {
            setTimeout(() => {
                setStepIndex(1);
            }, 1000);
        } else if (!showPublishModal && stepIndex > 0 && run === true) {
            resetState();
        }
    }, [showPublishModal]);

    const steps = [
        {
            target: `#publication-item-${publicationId} .btn-warning`,
            content: 'Create a new publication now',
            disableBeacon: true,
            spotlightClicks: true,
            hideFooter: true,
            hideCloseButton: true,
            disableOverlayClose: true,
        },
        {
            target: `.modal-footer .btn-warning`,
            content: 'Click here to publish your document',
            disableBeacon: true,
            spotlightClicks: true,
            hideFooter: true,
            hideCloseButton: true,
            disableOverlayClose: true,
        },
    ];

    return (
        <Joyride
            run={run}
            // callback={handleJoyrideCallback}
            // continuous={true}
            // scrollToFirstStep={true}
            disableCloseOnEsc={true}
            steps={steps}
            stepIndex={stepIndex}
            styles={globalStyles}
        />
    );

    function resetState() {
        setRun(false);
        setStepIndex(0);
        setStartTour(undefined);
    }
}
