import { useGetTaskTeamResultsMutation } from 'features/comments/commentApi';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import HelperFunctions from 'pages/global/HelperFunctions';
import { prepareTaskFormData } from 'pages/task_team/helpers/FormHelper';
import { TaskChecklist } from 'pages/task_team/views/checks/TaskChecklist';
import { ExclamationCircleFill } from 'react-bootstrap-icons';

export function Step3Result({ active = false }) {
    const { values } = useFormikContext();
    const [getTaskTeamResults] = useGetTaskTeamResultsMutation();
    const [teams, setTeams] = useState([]);

    const formValues = {
        ...prepareTaskFormData(values),
        id: null,
        '@id': null,
    };

    useEffect(() => {
        if (active) {
            getTaskTeamResults(formValues).then(({ data }) => {
                const { teams = [] } = data;

                setTeams([...teams].sort(HelperFunctions.sortByString('name')));
            });
        }
    }, [active]);

    return (
        <div>
            <div className="border border-secondary border-radius p-4 mb-4">
                <div className="d-flex align-items-start">
                    <ExclamationCircleFill className="text-secondary" size={72} />
                    <div className="ml-4">
                        <Teams teams={teams} />

                        <div>
                            <strong>Let op!</strong> Je moet je uiting in bepaalde gevallen eerst afstemmen met
                            Juridische Zaken. Het bewijs daarvan voeg je toe in de stap 'Evidence & herbeoordelen'.
                            <br />
                            Vermoed je dat de uiting klantimpact kan hebben? Stem dan eerst af met KS via{' '}
                            <a href="mailto:zorg.klantsignalen@zilverenkruis.nl">
                                zorg.klantsignalen@zilverenkruis.nl
                            </a>{' '}
                            voordat je de uiting beoordeelt. Check de lijst hieronder of hier sprake van is.
                        </div>
                    </div>
                </div>
            </div>

            <div className="dr-modal-content-light">
                <div className="font-weight-bold">Checklist</div>
                <div className="mb-3">Lees hieronder waarop jouw uiting wordt beoordeeld.</div>

                {active && <TaskChecklist task={formValues} />}
            </div>
        </div>
    );
}

function Teams({ teams = [] }) {
    return (
        <div className="mb-3">
            <div className="font-weight-bold mb-1">Jouw uiting krijgt de volgende checks:</div>
            <div>
                {teams.map((team) => (
                    <div className="mb-1" key={`team-row-${team.id}`}>
                        - {team.name}
                    </div>
                ))}
            </div>
        </div>
    );
}
