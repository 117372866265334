import { generatePath, Redirect, Route } from 'react-router-dom';
import Index from '../pages/documents_v2/views/Index';
import { ReactTitle } from 'components/ReactTitle';
import { View } from 'pages/documents_v2/views/view/View';
import { Edit } from 'pages/documents_v2/views/Edit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IpidImport } from 'pages/documents_v2/views/IpidImport';
import RestrictedContent from '../pages/global/RestrictedContent';
import { Permissions } from 'config/Constants';
import { ModelImport } from 'pages/documents_v2/views/ModelImport';

export const BASE_PATH = '/documents';
export const INDEX_PATH = `${BASE_PATH}/:folder(folder)?/:folderId?`;
export const VIEW_PATH = `${BASE_PATH}/view/:documentId`;
export const EDIT_PATH = `${BASE_PATH}/edit/:documentId/:view/:page?`;
export const EDIT_AREA_PATH = `${BASE_PATH}/review/id/:documentId/:view/areaId/:areaId`;
export const MODEL_IMPORT_PATH = `${BASE_PATH}/model_import/:documentId/:domainId`;

const OLD_VIEW_PATH = '/documents/review/id/:documentId';
export const IPID_IMPORT_PATH = `/documents/import/ipid/:view?`;

const DocumentsV2 = () => {
    const { t } = useTranslation('documents');
    return (
        <>
            <ReactTitle title={t('document.navbar.main.mainContentNav')} />

            <Route path={INDEX_PATH} exact strict render={() => <Index />} />
            <Route path={VIEW_PATH} exact strict render={() => <View />} />
            <Route path={EDIT_PATH} exact strict render={() => <Edit />} />
            <Route path={MODEL_IMPORT_PATH} exact strict render={() => <ModelImport />} />
            <Route
                path={IPID_IMPORT_PATH}
                exact
                strict
                render={() => (
                    <RestrictedContent permission={Permissions.Templates.Create}>
                        <IpidImport />
                    </RestrictedContent>
                )}
            />

            <Route
                path={OLD_VIEW_PATH}
                exact
                strict
                render={({ match }) => {
                    return (
                        <Redirect
                            to={generatePath(VIEW_PATH, {
                                documentId: match.params.documentId,
                            })}
                        />
                    );
                }}
            />
        </>
    );
};

export default DocumentsV2;
