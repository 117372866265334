import { Col, Row } from 'react-bootstrap';
import { LoadingSpinner } from '../../../global/Spinner';
import { FieldArray, Form as FForm, Formik, FormikHelpers } from 'formik';
import { Category, TeamUser as TeamUserModel } from '../../../../models/reimbursement.models';
import { useGetCategory } from '../../../../features/reimbursements/reimbursementHooks';
import { useParams } from 'react-router-dom';
import { User } from '../../../../models/auth.models';
import { DocRevSelect, InputSelect } from '../../../publications_v2/helpers/FieldHelper';
import React, { useState } from 'react';
import Constants from '../../../../config/Constants';
import { useUpdateCategoryMutation } from '../../../../features/reimbursements/reimbursements';
import * as Yup from 'yup';
import { InfoButton, WarningButton } from '../../../../components/Buttons';
import { teamUserRoles } from '../../config/roles';
import { useTranslation } from 'react-i18next';
import { useGetOrganisationUsers } from '../../../../hooks/useGetOrganisationUsers';
import { useGetUserFullName } from '../../../../hooks/useGetUserFullName';
import { useGetOrganisationUser } from '../../../../hooks/useGetOrganisationUser';

export function ReviewTeam() {
    const params: { categoryId: string } = useParams();
    const { category } = useGetCategory(parseInt(params.categoryId));
    const { t } = useTranslation('reimbursements');

    return (
        <>
            <div className="subheader">
                <h3>{t('settings.team.title')}</h3>
            </div>

            {category && <ReviewTeamForm category={category} />}
        </>
    );
}

function ReviewTeamForm({ category }: { category: Category }) {
    const [updateCategory] = useUpdateCategoryMutation();
    const { t } = useTranslation('reimbursements');

    const handleSubmit = (values: Category, helpers: FormikHelpers<Category>) => {
        updateCategory({
            uri: category['@id'],
            teamUsers: values.teamUsers.map((teamUser) => ({
                id: teamUser['@id'],
                userId: teamUser.userId,
                role: teamUser.role,
            })),
        }).then(() => {
            helpers.setSubmitting(false);
        });
    };

    return (
        <Formik
            initialValues={category}
            validationSchema={ReviewTeamFormSchema}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({
                dirty,
                isSubmitting,
                isValid,
                values,
            }: {
                dirty: boolean;
                isSubmitting: boolean;
                isValid: boolean;
                values: Category;
            }) => (
                <FForm autoComplete="off">
                    <FieldArray name="teamUsers">
                        {({ remove, push }) => (
                            <>
                                <AddTeamUser push={push} teamUsers={values?.teamUsers ?? []} />
                                <TeamUsers remove={remove} teamUsers={values?.teamUsers ?? []} />
                            </>
                        )}
                    </FieldArray>

                    <div className="d-flex flex-row-reverse align-items-center mt-3">
                        <WarningButton type="submit" disabled={isSubmitting || !dirty || !isValid} className="ml-2">
                            {t('btn.save')}
                        </WarningButton>
                        <LoadingSpinner isSubmitting={isSubmitting} />
                    </div>
                </FForm>
            )}
        </Formik>
    );
}

function TeamUsers({ remove, teamUsers }: { remove: (index: number) => void; teamUsers: TeamUserModel[] }) {
    const users: User[] = useGetOrganisationUsers();
    const { t } = useTranslation('reimbursements');

    const allUsers: Array<{ userId: string; role: string; index: number }> = users
        .filter((_user) => teamUsers.some((teamUser) => teamUser.userId === _user.id))
        .map((_user) => {
            const teamUser = teamUsers.find((teamUser) => teamUser.userId === _user.id);

            return {
                userId: _user.id,
                role: teamUser?.role ?? '',
                index: teamUsers.findIndex((teamUser) => teamUser.userId === _user.id),
            };
        });

    return (
        <Row>
            <Col xs={3}>
                <div className="font-weight-bold pt-1" style={{ color: '#344054', fontSize: 13 }}>
                    {t('settings.team.teamMembers')}
                </div>
                <div className="text-secondary mt-1" style={{ fontSize: 12 }}>
                    {t('settings.team.note')}
                </div>
            </Col>
            <Col>
                <div className="border bg-white" style={{ borderRadius: '0.2rem' }}>
                    <table className="table table-team">
                        <thead>
                            <tr>
                                <th style={{ width: '50%' }}>{t('settings.team.name')}</th>
                                <th style={{ width: '30%' }}>{t('settings.team.role')}</th>
                                <th style={{ width: '20%' }}>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            <FieldArray name="teamUsers">
                                {() => (
                                    <>
                                        {allUsers.map((user) => (
                                            <TeamUser
                                                userId={user.userId}
                                                remove={() => remove(user.index)}
                                                index={user.index}
                                                key={`team=user-${user.index}`}
                                            />
                                        ))}
                                    </>
                                )}
                            </FieldArray>
                        </tbody>
                    </table>
                </div>
            </Col>
        </Row>
    );
}

function TeamUser({ userId, remove, index }: { userId: string; remove: () => void; index: number }) {
    const fullName: string = useGetUserFullName(userId, false);
    const user: User | undefined = useGetOrganisationUser(userId);
    const { t } = useTranslation('reimbursements');

    return (
        <tr>
            <td>
                <div className="font-weight-bolder">{fullName}</div>
                <div className="text-secondary">{user?.jobDescription}</div>
            </td>

            <td className="align-middle">
                <InputSelect
                    name={`teamUsers.${index}.role`}
                    options={teamUserRoles.map((role) => ({
                        label: t(role.label),
                        value: role.value,
                    }))}
                />
            </td>

            <td className="align-middle">
                <div className="d-flex flex-row-reverse">
                    <a onClick={() => remove()} className="font-weight-bold text-secondary">
                        {t('settings.team.delete')}
                    </a>
                </div>
            </td>
        </tr>
    );
}

function AddTeamUser({
    push,
    teamUsers,
}: {
    push: (teamUser: Partial<TeamUserModel>) => void;
    teamUsers: TeamUserModel[];
}) {
    const users: User[] = useGetOrganisationUsers();
    const [role, setRole] = useState(Constants.reimbursementTeamRoles.manager);
    const [userId, setUserId] = useState('');
    const { t } = useTranslation('reimbursements');

    const availableUsers = users
        .filter((_user) => !teamUsers.some((_documentUser) => _documentUser.userId === _user.id))
        .map((_user) => {
            return {
                label: _user.firstName + ' ' + _user.lastName,
                value: _user.id,
            };
        });

    return (
        <div className="border-bottom mb-4 pb-4">
            <Row>
                <Col xs={3}>
                    <div className="font-weight-bold pt-1" style={{ color: '#344054', fontSize: 13 }}>
                        {t('settings.team.addMember')}
                    </div>
                </Col>
                <Col>
                    <Row className="form-row">
                        <Col xs={8}>
                            <DocRevSelect
                                onChange={(user: { label: string; value: string }) => {
                                    setUserId(user.value);
                                }}
                                selectedValue={userId}
                                name="newUser"
                                options={availableUsers}
                                props={{
                                    isDisabled: availableUsers.length === 0,
                                    placeholder: `${t('settings.team.titleSelect')}...`,
                                }}
                            />
                        </Col>
                        <Col>
                            <DocRevSelect
                                onChange={(role: { label: string; value: string }) => setRole(role.value)}
                                selectedValue={role}
                                name="newUserRole"
                                options={teamUserRoles.map((role) => ({
                                    label: t(role.label),
                                    value: role.value,
                                }))}
                                props={{
                                    isDisabled: availableUsers.length === 0 || userId === '',
                                }}
                            />
                        </Col>
                    </Row>

                    <div className="d-flex flex-row-reverse mt-2">
                        <InfoButton
                            disabled={userId === ''}
                            onClick={() => {
                                const newUser = {
                                    userId,
                                    role,
                                } as Partial<TeamUserModel>;

                                push(newUser);
                                setUserId('');
                                setRole(Constants.reimbursementTeamRoles.manager);
                            }}
                        >
                            {t('settings.team.add')}
                        </InfoButton>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

const ReviewTeamFormSchema = Yup.object().shape({
    teamUsers: Yup.array()
        .compact((v) => v.role !== Constants.reimbursementTeamRoles.admin)
        .min(1, 'at least 1')
        .required('required'),
});
