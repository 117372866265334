import { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Plus, TrashFill } from 'react-bootstrap-icons';
import CirclePicker from '../../global/CirclePicker';
import { FieldArray, useFormikContext } from 'formik';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FormField, InputSelect } from '../../publications_v2/helpers/FieldHelper';
import { useSelector } from 'react-redux';
import { selectAllDepartmentTasksForOpList } from '../../../features/operationsList/taskSlice';
import { findDeep } from 'deepdash-es/standalone';
import { RxDragHandleDots2 } from 'react-icons/rx';
import { useTranslation } from 'react-i18next';

export function StatusCodeRow({ opList, item, index, isExpanded = false, setExpandedItem, remove, hasExpandedItem }) {
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const { setFieldValue } = useFormikContext();
    const { t } = useTranslation('changelist');

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',
        padding: '6px 18px 6px 10px',
        margin: 0,
        borderBottom: isDragging ? '1px solid #fff' : '1px solid #efefef',
        background: '#fff',
        boxShadow: isDragging ? '0px 0px 4px 1px #efefef' : '',
        opacity: hasExpandedItem && !isExpanded ? 0.25 : undefined,

        // styles we need to apply on draggables
        ...draggableStyle,
    });

    return (
        <Draggable
            draggableId={`dep-row-${index}`}
            index={index}
            isDragDisabled={displayColorPicker || hasExpandedItem}
        >
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, hasExpandedItem)}
                >
                    <div>
                        {!isExpanded && (
                            <div className="d-flex align-items-center" style={{ height: 35 }}>
                                <div className="text-muted mr-1">
                                    <RxDragHandleDots2 />
                                </div>

                                <div className="mr-1">
                                    <CirclePicker
                                        show={displayColorPicker}
                                        hide={() => setDisplayColorPicker(false)}
                                        toggleShow={(event) => {
                                            event.preventDefault();
                                            if (!hasExpandedItem) {
                                                setDisplayColorPicker(!displayColorPicker);
                                            }
                                        }}
                                        color={item.color}
                                        handleChange={(color) =>
                                            setFieldValue(`statusCodes[${index}].color`, color.hex)
                                        }
                                    />
                                </div>

                                <Button
                                    variant="link"
                                    className="text-body"
                                    size="sm"
                                    onClick={() => setExpandedItem(index)}
                                >
                                    {item.label}
                                </Button>

                                {!item.required && (
                                    <Button
                                        size="sm"
                                        variant="icon"
                                        className="ml-auto text-danger p-1"
                                        data-uk-tooltip={!hasExpandedItem ? t('changelist.tooltip.delete') : undefined}
                                        onClick={() => {
                                            if (!hasExpandedItem) {
                                                remove(index);
                                            }
                                        }}
                                    >
                                        <TrashFill />
                                    </Button>
                                )}
                            </div>
                        )}

                        {isExpanded && (
                            <EditStatusCode
                                opList={opList}
                                statusCode={item}
                                index={index}
                                setExpandedItem={setExpandedItem}
                                displayColorPicker={displayColorPicker}
                                setDisplayColorPicker={setDisplayColorPicker}
                            />
                        )}
                    </div>
                </div>
            )}
        </Draggable>
    );
}

function EditStatusCode({ opList, statusCode, index, setExpandedItem, displayColorPicker, setDisplayColorPicker }) {
    const { requiredConditions = [] } = statusCode;
    const tasks = useSelector(selectAllDepartmentTasksForOpList(opList));
    const { setFieldValue } = useFormikContext();
    const { t } = useTranslation('changelist');

    const fieldOptions = tasks.map((_task) => ({
        label: _task.label,
        options: _task.fields
            .filter((_field) => _field.type === 'select')
            .map((_field) => ({
                label: _field.label,
                value: _field['@id'],
            })),
    }));

    return (
        <div className="px-4 py-3">
            <Row>
                <Col sm={8}>
                    <FormField
                        label="Naam"
                        name={`statusCodes[${index}].label`}
                        props={{ readOnly: statusCode.required }}
                    />

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={4}>
                            {t('changelist.misc.color')}
                        </Form.Label>
                        <Col sm={8}>
                            <CirclePicker
                                show={displayColorPicker}
                                hide={() => setDisplayColorPicker(false)}
                                toggleShow={(event) => {
                                    event.preventDefault();
                                    setDisplayColorPicker(!displayColorPicker);
                                }}
                                color={statusCode.color}
                                handleChange={(color) => setFieldValue(`statusCodes[${index}].color`, color.hex)}
                            />
                        </Col>
                    </Form.Group>
                </Col>
            </Row>

            <FieldArray name={`statusCodes[${index}].requiredConditions`}>
                {({ remove, push }) => (
                    <div className="mb-4">
                        <div>
                            <label className="col-form-label pt-1">{t('changelist.misc.prerequisites')}</label>
                        </div>

                        {requiredConditions.length > 0 && (
                            <Row className="align-items-center small text-secondary mb-2" noGutters>
                                <Col sm={5}>{t('changelist.misc.task')}</Col>
                                <Col sm={3}>{t('changelist.misc.conditionStatus')}&hellip;</Col>
                                <Col sm={3}>{t('changelist.misc.prerequisite')}</Col>
                            </Row>
                        )}

                        {requiredConditions.map((_condition, index2) => {
                            let selectedTaskField = undefined;
                            const selectedTaskFieldResults = _condition.field
                                ? findDeep(
                                      tasks,
                                      (value) => {
                                          if (value['@type'] !== 'TaskField') {
                                              return undefined;
                                          }

                                          return value['@id'] === _condition.field;
                                      },
                                      {
                                          childrenPath: 'fields',
                                      }
                                  )
                                : undefined;

                            if (selectedTaskFieldResults) {
                                selectedTaskField = selectedTaskFieldResults.value;
                            }

                            return (
                                <Row className="align-items-center mb-2" noGutters key={`condition-row-${index2}`}>
                                    <Col sm={5}>
                                        <InputSelect
                                            name={`statusCodes[${index}].requiredConditions[${index2}].field`}
                                            options={fieldOptions}
                                            props={{
                                                placeholder: `${t('changelist.misc.task1')}...`,
                                                className: 'mr-2',
                                            }}
                                        />
                                    </Col>
                                    <Col sm={3}>
                                        <InputSelect
                                            name={`statusCodes[${index}].requiredConditions[${index2}].type`}
                                            options={[
                                                {
                                                    label: t('changelist.misc.notEqual'),
                                                    value: 'not_equals',
                                                },
                                                {
                                                    label: t('changelist.misc.equal'),
                                                    value: 'equals',
                                                },
                                            ]}
                                            props={{
                                                className: 'mr-2',
                                            }}
                                        />
                                    </Col>
                                    <Col sm={3}>
                                        <InputSelect
                                            name={`statusCodes[${index}].requiredConditions[${index2}].fieldOption`}
                                            options={
                                                selectedTaskField
                                                    ? selectedTaskField.options.map((_option) => ({
                                                          label: _option,
                                                          value: _option,
                                                      }))
                                                    : []
                                            }
                                            props={{
                                                placeholder: `${t('changelist.misc.condition')}…`,
                                                isDisabled: selectedTaskField === undefined,
                                                className: 'mr-1',
                                            }}
                                        />
                                    </Col>
                                    <Col sm={1}>
                                        <Button
                                            size="sm"
                                            variant="icon"
                                            className="ml-auto text-danger p-1"
                                            data-uk-tooltip={t('changelist.misc.delete')}
                                            onClick={() => remove(index2)}
                                        >
                                            <TrashFill />
                                        </Button>
                                    </Col>
                                </Row>
                            );
                        })}

                        <Button variant="link" size="sm" className="px-0" onClick={() => push({ type: 'not_equals' })}>
                            <Plus /> {t('changelist.misc.addRule')}
                        </Button>
                    </div>
                )}
            </FieldArray>

            <Button size="sm" onClick={() => setExpandedItem(undefined)}>
                {t('btn.save')}
            </Button>
            <Button size="sm" variant="link" onClick={() => setExpandedItem(undefined)}>
                {t('btn.cancel')}
            </Button>
        </div>
    );
}
