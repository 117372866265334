import { useGetDocument } from '../hooks/useGetDocument';
import { useGetDocumentTags } from '../../tags/hooks/useGetDocumentTags';
import { BlocksTagsModal } from '../../tags/BlocksTagModal';

export function EditTagModal({ block, tag, handleClose }) {
    const document = useGetDocument();

    // documentVariants = array of ids
    const { documentVariants } = block;
    const tags = useGetDocumentTags();

    const documentTag = tags.find((_tag) => _tag.name === tag);

    return (
        <BlocksTagsModal
            documentId={document.id}
            tagId={documentTag.id}
            showModal={true}
            closeModal={handleClose}
            filterByVariants={documentVariants}
        />
    );
}
