import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import { selectAllOpListUsers, selectDepartmentsFromOpList } from './operationsListSlice';

const selectOpListUser = (opListUri) =>
    createSelector(
        [
            (state) => state.operations.users.ids.map((id) => state.operations.users.entities[id]), // this is the same as selectAllOpListUsers
            (state) => state.security.userProfile,
        ],
        (opListUsers, currentUser) => {
            const { userId } = currentUser;

            return opListUsers
                .filter((opListUser) => opListUser.opList === opListUri)
                .find((opListUser) => opListUser.userId === userId);
        }
    );

export const selectUserDepartments = (opList) =>
    createSelector(
        [
            selectDepartmentsFromOpList(opList['@id']),
            selectOpListUser(opList['@id']),
            (state) => state.security.userProfile,
        ],
        (departments, opListUser, securityUser) => {
            if (opListUser) {
                return departments.filter((_department) => _department['@id'] === opListUser.opDepartment);
            } else if (securityUser) {
                return departments.filter((_department) => _department.managers.includes(securityUser.userId));
            }

            return [];
        }
    );

export const selectDepartmentUsers = (opList, organisationUsers = []) =>
    createSelector(
        [
            () => organisationUsers,
            (state) => selectAllOpListUsers(state.operations),
            selectUserDepartments(opList),
        ],
        (organisationUsers, allOpListUsers, departments = []) => {
            if (departments.length === 0) {
                return [];
            }

            const departmentUserIds = allOpListUsers
                .filter((_opListUser) =>
                    departments.find((department) => _opListUser.opDepartment === department['@id'])
                )
                .map((_opListUser) => _opListUser.userId);

            return organisationUsers.filter((_user) => departmentUserIds.includes(_user.id));
        }
    );

export const selectDepartmentsOpListUsers = (opList, organisationUsers = []) =>
    createSelector(
        [
            () => organisationUsers,
            (state) => selectAllOpListUsers(state.operations),
            selectUserDepartments(opList),
        ],
        (organisationUsers, allOpListUsers, departments = []) => {
            if (departments.length === 0) {
                return [];
            }

            return _.cloneDeep(allOpListUsers)
                .filter((_opListUser) =>
                    departments.find((department) => _opListUser.opDepartment === department['@id'])
                )
                .map((_opListUser) => {
                    _opListUser.securityUser = _.find(organisationUsers, ['id', _opListUser.userId]);
                    return _opListUser;
                });
        }
    );

export const selectDepartmentLabels = (opList, labels) =>
    createSelector(
        [selectUserDepartments(opList), selectDepartmentsFromOpList(opList['@id'])],
        (userDepartments, opListDepartments) => {
            const departments = opList.userIsAdmin || opList.userIsEditor ? opListDepartments : userDepartments;

            if (departments.length === 0) {
                return [];
            }

            return labels.filter((_label) => {
                if (_label.folder) {
                    return departments.find((department) => department.labels.includes(_label.folder));
                }

                return false;
            });
        }
    );

// Selects departments that the user is attached to (does not filter on manager status)
// (User should only be attached to a single department)
export const selectDepartmentUserIsManagerOf = (opList = {}) =>
    createSelector(
        [selectDepartmentsFromOpList(opList['@id']), (state) => state.security.userProfile],
        (departments, securityUser) => {
            if (!departments) {
                return null;
            }

            if (!securityUser) {
                return null;
            }

            return departments.find((_department) => _department.managers.includes(securityUser.userId));
        }
    );

export const selectDepartmentUserBelongsTo = (opList = {}) =>
    createSelector(
        [
            selectDepartmentsFromOpList(opList['@id']),
            selectOpListUser(opList['@id']),
            (state) => state.security.userProfile,
        ],
        (departments, opListUser, securityUser) => {
            if (!opList) {
                return null;
            }

            if (opListUser) {
                return departments.find((_department) => _department['@id'] === opListUser.opDepartment);
            } else if (securityUser) {
                return departments.find((_department) => _department.managers.includes(securityUser.userId));
            }
        }
    );
