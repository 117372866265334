import _ from 'lodash';
import Parser from 'html-react-parser';
import { Card } from 'react-bootstrap';
import { getTypeAsString } from '../../../helpers/EntityHelper';
import { findAll } from 'highlight-words-core';
import { useContext } from 'react';
import { EditContext } from '../../view/EditContextWrapper';
import {
    Product,
    Reimbursement,
    ReimbursementProduct,
    ReimbursementProductField,
} from '../../../../../models/reimbursement.models';
import { EntityReference, ReimbursementContainer, TranslationKey } from '../../../../../models/translation.models';
import {
    useGetTranslationContainerEntityReferencesQuery,
    useGetTranslationContainerQuery,
} from '../../../../../features/translations/translationApi';
import {
    useGetProductsQuery,
    useGetReimbursementProductsQuery,
    useGetReimbursementsQuery,
} from '../../../../../features/reimbursements/reimbursements';
import { ChevronRight } from 'react-bootstrap-icons';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function ReimbursementProductFieldProperties({
    selectedEntity,
}: {
    selectedEntity: {
        entity: ReimbursementProductField;
        entityReference: EntityReference;
        id: string;
        translationKey: TranslationKey;
    };
}) {
    const { search } = useContext(EditContext);
    const { entity, translationKey } = selectedEntity;
    const { property, entityType } = translationKey;
    const { t } = useTranslation('reimbursements');

    const { translationContainerId }: { translationContainerId: string } = useParams();

    const { translationContainer } = useGetTranslationContainerQuery(translationContainerId, {
        selectFromResult: ({ data }) => ({
            translationContainer: data ? (data as ReimbursementContainer) : undefined,
        }),
    });

    const { parentEntityReferences } = useGetTranslationContainerEntityReferencesQuery(
        {
            uri: translationKey.translationContainer,
        },
        {
            selectFromResult: ({ data }) => ({
                parentEntityReferences: data
                    ? data
                          .filter((item) => translationKey.entityReferences.includes(item['@id']))
                          .map((item) => data.find((_item) => _item['@id'] === item.parent))
                    : [],
            }),
        }
    );

    let title = _.get(entity, property, '');

    if (search !== '') {
        const chunks = findAll({
            searchWords: [search],
            textToHighlight: title,
        });

        title = chunks
            .map((chunk) => {
                const { end, highlight, start } = chunk;
                const text = title.substr(start, end - start);
                if (highlight) {
                    return `<mark>${text}</mark>`;
                } else {
                    return text;
                }
            })
            .join('');
    }

    return (
        <>
            <div className="mb-4" role="table">
                <div
                    style={{
                        lineHeight: '20px',
                    }}
                    className="d-flex"
                    role="row"
                >
                    <div
                        style={{
                            flex: '0 0 100px',
                            color: '#5f6368',
                        }}
                        className=""
                        role="cell"
                    >
                        Type
                    </div>
                    <div
                        style={{
                            flex: '1 1 auto',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                        className=""
                        role="cell"
                    >
                        {getTypeAsString(property, entityType)}
                    </div>
                </div>
            </div>
            <Card>
                <Card.Body>
                    <div className="rendered-content p-0">{Parser(title)}</div>
                </Card.Body>
            </Card>

            {translationContainer && (
                <div className="mt-3">
                    <div
                        className="mb-2"
                        style={{
                            flex: '0 0 100px',
                            color: '#5f6368',
                        }}
                    >
                        {t('reimbursement.product.misc.occursIn')}
                    </div>
                    <div style={{ fontSize: 11 }}>
                        {parentEntityReferences.length > 0 ? (
                            <>
                                {parentEntityReferences.map((entityReference) => (
                                    <ParentName
                                        entityReference={entityReference}
                                        categoryId={translationContainer.categoryId}
                                        key={`ref-${entityReference?.id}`}
                                    />
                                ))}
                            </>
                        ) : (
                            <>{t('reimbursement.product.misc.noneFound')}</>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}

function ParentName({
    entityReference,
    categoryId,
}: {
    entityReference: EntityReference | undefined;
    categoryId: number;
}) {
    const { reimbursementProduct }: { reimbursementProduct: ReimbursementProduct | undefined } =
        useGetReimbursementProductsQuery(`/api/reimbursement-api/api/categories/${categoryId}`, {
            selectFromResult: ({ data }) => ({
                reimbursementProduct: data
                    ? data.find((item: ReimbursementProduct) => `${item.id}` === entityReference?.entityId)
                    : undefined,
            }),
            skip: entityReference === undefined,
        });

    const { reimbursement }: { reimbursement: Reimbursement | undefined } = useGetReimbursementsQuery(
        reimbursementProduct?.category,
        {
            selectFromResult: ({ data }) => {
                return {
                    reimbursement: data
                        ? data.find(
                              (reimbursement: Reimbursement) =>
                                  reimbursement['@id'] === reimbursementProduct?.reimbursement
                          )
                        : undefined,
                };
            },
            skip: reimbursementProduct === undefined,
        }
    );

    const {
        product,
        selectedProduct,
        parentProduct,
    }: { product: Product | undefined; selectedProduct: Product | undefined; parentProduct: Product | undefined } =
        useGetProductsQuery(categoryId, {
            selectFromResult: ({ data }) => {
                if (data) {
                    const product = data.find((_product: Product) => _product['@id'] === reimbursementProduct?.product);
                    const selectedProduct = data.find(
                        (_product: Product) => _product['@id'] === reimbursementProduct?.selectedProduct
                    );

                    return {
                        product,
                        selectedProduct,
                        parentProduct: data.find((_product: Product) => _product['@id'] === product.parent),
                    };
                }

                return {
                    product: undefined,
                    selectedProduct: undefined,
                    parentProduct: undefined,
                };
            },
            skip: reimbursementProduct === undefined,
        });

    if (
        entityReference === undefined ||
        reimbursement === undefined ||
        product === undefined ||
        parentProduct === undefined
    ) {
        return null;
    }

    return (
        <div className="d-flex align-items-center flex-wrap mb-1">
            <div>{reimbursement.name}</div>
            <ChevronRight className="text-secondary" style={{ margin: '0 2px' }} />
            <div>{parentProduct.name}</div>
            <ChevronRight className="text-secondary" style={{ margin: '0 2px' }} />
            <div>{product.name}</div>
            {selectedProduct && selectedProduct.id !== product.id && (
                <>
                    <ChevronRight className="text-secondary" style={{ margin: '0 2px' }} />
                    <div>{selectedProduct.name}</div>
                </>
            )}
        </div>
    );
}
