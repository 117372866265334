import { useGetFileQuery } from 'features/mediaLibrary/mediaApi';

export function useGetFile(uri) {
    const { file } = useGetFileQuery(uri, {
        selectFromResult: ({ data }) => ({
            file: data,
        }),
        skip: !uri,
    });

    return file;
}
