import { useState } from 'react';
import _ from 'lodash';
import { Form, Modal } from 'react-bootstrap';
import { useGetDocumentVariants } from '../../documents_v2/hooks/useGetDocumentVariants';
import { useTranslation } from 'react-i18next';
import { PrimaryButton, SecondaryButton } from 'components/Buttons';

/**
 * Form where the search query, field to search, and optionally the variant. can be specified.
 *
 * @param close
 * @param search
 * @param documentId
 * @returns {JSX.Element}
 * @constructor
 */
export default function Setup({ close, search, documentId = null }) {
    // If a document is specified that means the modal is running in documentMode. Resolve the documentVariants
    const documentVariants = useGetDocumentVariants();

    return <SetupForm close={close} search={search} documentVariants={documentVariants} />;
}

function SetupForm({ close, search, documentVariants }) {
    const [searchQuery, setSearchQuery] = useState('');
    const [tagField, setTagField] = useState('text');
    const [variantId, setVariantId] = useState();
    const { t } = useTranslation('documents');

    const formValid = tagField && !_.isEmpty(searchQuery);

    return (
        <>
            <div className="text-darkBlueAlt">
                <Modal.Body>
                    <p>{t('document.navbar.tags.searchModal.searchTagsTitle')}</p>

                    <Form.Group>
                        <Form.Label>{t('document.navbar.tags.searchModal.searchTermTitle')}</Form.Label>
                        <input
                            type="text"
                            className="uk-input"
                            id="searchQuery"
                            name="searchQuery"
                            placeholder={`${t('document.navbar.tags.searchModal.searchTermPlaceholder')}...`}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            autoFocus={true}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{t('document.navbar.tags.searchModal.searchField')}</Form.Label>
                        <div onChange={(e) => setTagField(e.target.value)}>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="SARField"
                                    id="SARField1"
                                    value={'text'}
                                    defaultChecked={true}
                                />
                                <label className="form-check-label" htmlFor="SARField1">
                                    Tekst
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="SARField"
                                    id="SARField2"
                                    value={'url'}
                                />
                                <label className="form-check-label" htmlFor="SARField2">
                                    Link
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="SARField"
                                    id="SARField3"
                                    value={'tooltip'}
                                />
                                <label className="form-check-label" htmlFor="SARField3">
                                    Tooltip
                                </label>
                            </div>
                        </div>
                    </Form.Group>

                    {/* documentVariants var is only filled if in documentMode, this allows specifying which variant to search tag overrides for.s */}
                    {documentVariants && documentVariants.length > 1 && (
                        <Form.Group>
                            <Form.Label>{t('document.navbar.tags.searchModal.searchLimit')}</Form.Label>
                            <div onChange={(e) => setVariantId(e.target.value)}>
                                <DocumentVariantRadio documentVariants={documentVariants} />
                            </div>
                        </Form.Group>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <SecondaryButton onClick={close}>{t('btn.cancel')}</SecondaryButton>
                    <PrimaryButton disabled={!formValid} onClick={() => search(searchQuery, tagField, variantId)}>
                        {t('document.navbar.tags.searchModal.btn.search')}
                    </PrimaryButton>
                </Modal.Footer>
            </div>
        </>
    );
}

function DocumentVariantRadio({ documentVariants }) {
    return documentVariants.map((documentVariant, index) => {
        const inputId = `SARVariant${index}`;

        return (
            <div className="form-check" key={`dvr-container-${index}`}>
                <input
                    className="form-check-input"
                    type="radio"
                    name="SARVariant"
                    id={inputId}
                    value={documentVariant.id}
                    key={`dvr-input-${index}`}
                />
                <label className="form-check-label" htmlFor={inputId} key={`dvr-label-${index}`}>
                    {'default' === documentVariant.name ? 'Basisvariant' : documentVariant.name}
                </label>
            </div>
        );
    });
}
