import React from 'react';
import { Modal } from 'react-bootstrap';
import { Field, Form as FForm, Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { FormModal, ModalFooter } from '../global/FormModal';
import { useGetLabelFoldersQuery, useUpdateLabelMutation } from '../../features/metadata/metadata';
import { useTranslation } from 'react-i18next';

export default function MoveLabelModal(props) {
    const { label, showModal = false } = props;
    const [updateLabel] = useUpdateLabelMutation();
    const { activeOrganisation } = useSelector((state) => state.security);
    const { t } = useTranslation('labels');

    const { folders = [] } = useGetLabelFoldersQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                folders: data ?? [],
            }),
        }
    );

    if (label === null) {
        return null;
    }

    const options = folders.map((_item) => {
        return {
            value: _item['@id'],
            label: _item.name,
            isDisabled: _item['@id'] === label.folder,
        };
    });

    const handleSubmit = (values, { setSubmitting }) => {
        updateLabel({ uri: label['@id'], ...values }).then(() => {
            setSubmitting(false);

            // Close modal
            props.handleClose();
        });
    };

    return (
        <FormModal show={showModal} onHide={props.handleClose} title={t('moveLabelModal.moveLabel')}>
            <Formik initialValues={{ folder: '' }} validationSchema={MoveLabelSchema} onSubmit={handleSubmit}>
                {({ values, setFieldValue, handleBlur, isSubmitting, errors, touched }) => (
                    <FForm autoComplete="off">
                        <Modal.Body>
                            <p>
                                {t('moveLabelModal.selectFolder')}{' '}
                                <span className="font-weight-bold">{label.name}</span>:
                            </p>
                            <Field
                                as={Select}
                                name="folder"
                                placeholder={`${t('moveLabelModal.titleSelectFolder')}...`}
                                value={options.find((_option) => _option.value === values.folder) ?? ''}
                                options={options}
                                onBlur={handleBlur}
                                onChange={(selectedOption) => {
                                    setFieldValue('folder', selectedOption.value);
                                }}
                            />
                        </Modal.Body>

                        <ModalFooter isSubmitting={isSubmitting} onHide={props.handleClose} />
                    </FForm>
                )}
            </Formik>
        </FormModal>
    );
}

const MoveLabelSchema = Yup.object().shape({
    folder: Yup.string().required('Required'),
});
