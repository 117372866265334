import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

export default class Modal extends React.Component {
    constructor (props) {
        super(props);
    }

    onRequestClose = (e) => {
        if (this.props.onRequestClose !== undefined) {
            this.props.onRequestClose(e);
        }
    };

    render () {
        const modalStyle = this.props.modalStyle !== undefined ? this.props.modalStyle : 'fullScreen';
        const resolvedStyle = {
            content: {...defaultModalStyle, ...modalStyles[modalStyle]},
            overlay: { backgroundColor: "rgba(206, 206, 206, 0.75)", zIndex: 1021, },
        };

        return (
            <Fragment>
                <ReactModal isOpen={this.props.opened} contentLabel="Modal Window" style={resolvedStyle} ariaHideApp={false} onRequestClose={this.onRequestClose}>
                    {this.props.children}
                </ReactModal>
            </Fragment>
        );
    }
}

const defaultModalStyle = {
    top: '30px',
    left: '30px',
    right: '30px',
    bottom: '30px',
    padding: '0px',
    borderRadius: 'none',
};

const modalStyles = {
    fullScreen: {
        top: '30px',
        left: '30px',
        right: '30px',
        bottom: '30px',
    },
    centeredAutoWidth: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '90vh',
    },
};

Modal.propTypes = {
    modalStyle: PropTypes.string,
    opened: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func,
};
