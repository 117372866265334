import { useAddPublicationUserMutation, useEditPublicationUserMutation } from 'features/publications/publicationApi';
import { useGetPublicationUser, useGetUserGroups } from 'pages/publications_v2/hooks/publicationUsers';
import { Modal } from 'react-bootstrap';
import { NumberParam, useQueryParam } from 'use-query-params';
import { UserForm } from 'pages/publicationUsers/modals/UserForm';
import { generatePath, useHistory } from 'react-router-dom';
import { USER_GROUPS_PATH } from 'scenes/PublicationsV2';
import { useTranslation } from 'react-i18next';

export function EditUserModal() {
    const [euid, setEditUserId] = useQueryParam('euid', NumberParam);

    if (!euid) {
        return null;
    }

    return <EditPublicationUser id={euid} />;
}

function EditPublicationUser({ id }) {
    const publicationUser = useGetPublicationUser(id);
    const [euid, setEditUserId] = useQueryParam('euid', NumberParam);

    if (!publicationUser) {
        return null;
    }

    return <EditUserModalInner editedUser={publicationUser} close={close} />;

    function close() {
        setEditUserId(undefined);
    }
}

export function EditUserModalInner({ editedUser, close }) {
    const { t } = useTranslation();
    const history = useHistory();
    const [editPublicationUser] = useEditPublicationUserMutation();
    const [addPublicationUser] = useAddPublicationUserMutation();
    const userGroups = useGetUserGroups();

    const allOptions = userGroups.map((group) => ({
        label: group.name,
        value: group['@id'],
    }));

    const defaultValue = allOptions.filter((option) => {
        return editedUser.userGroups?.includes(option.value);
    });

    const initialValues = getInitialValues();

    return (
        <>
            <Modal show={true} onHide={close}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {editedUser.hasOwnProperty('id')
                            ? t('publications:accessControl.modal.editUser')
                            : t('publications:accessControl.modal.addUser')}
                    </Modal.Title>
                </Modal.Header>
                <UserForm
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    onClose={close}
                    defaultValue={defaultValue}
                />
            </Modal>
        </>
    );

    function getInitialValues() {
        return {
            name: editedUser.name || '',
            surname: editedUser.surname || '',
            company: editedUser.company || '',
            email: editedUser.email || '',
            comments: editedUser.comments || '',
            userGroups: allOptions.filter((option) => editedUser.userGroups.includes(option.value)),
            isNewsletterActive: editedUser.isNewsletterActive || false,
            isDocumentUpdatesActive: editedUser.isDocumentUpdatesActive || false,
        };
    }

    function handleSubmit(values) {
        const sanitizedValues = {
            ...values,
            name: values.name.trim(),
            surname: values.surname.trim(),
            company: values.company.trim(),
            email: values.email.trim(),
            userGroups: values.userGroups.map((option) => option.value),
        };

        const updatedUser = {
            ...editedUser,
            ...sanitizedValues,
        };

        if (editedUser.hasOwnProperty('id')) {
            // Edit
            editPublicationUser({ uri: editedUser['@id'], body: updatedUser }).then(() => {
                close();
            });
        } else {
            // Create
            addPublicationUser(updatedUser).then(({ data }) => {
                history.push(
                    generatePath(USER_GROUPS_PATH, {
                        page: 'view-user',
                        id: data.id,
                    })
                );
            });
        }
    }
}
