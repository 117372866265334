import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default function AllMatchesProcessed({ close, resetState }) {
    const { t } = useTranslation('documents');

    return (
        <>
            <Modal.Body>
                <p>{t('document.navbar.search.results.resultsCovered')}</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="light" onClick={close}>
                    {t('btn.close')}
                </Button>
                <Button variant="primary" onClick={resetState}>
                    {t('document.navbar.search.btn.back')}
                </Button>
            </Modal.Footer>
        </>
    );
}
