import { FormModal } from '../../../global/FormModal';
import { NumberParam, useQueryParam } from 'use-query-params';
import { Modal } from 'react-bootstrap';
import { SecondaryButton } from '../../../../components/Buttons';
import { useGetInsurerUsersQuery } from '../../../../features/documents/ipidApi';
import { DateTime } from 'luxon';

export function InsurerUsers() {
    const [iid, setIid] = useQueryParam('iid', NumberParam);

    const hide = () => {
        setIid(undefined);
    };

    return (
        <FormModal onHide={hide} show={iid !== undefined} title="Gebruikers">
            {iid !== undefined && <ModalContent insurerId={iid} hide={hide} />}
        </FormModal>
    );
}

function ModalContent({ insurerId, hide }) {
    const { users } = useGetInsurerUsersQuery(insurerId, {
        selectFromResult: ({ data }) => ({
            users: data
                ? data.filter((user) => {
                      const lastLogin = DateTime.fromISO(user.lastLogin);

                      return lastLogin.isValid && user.active && !user.banned && !user.deleted;
                  })
                : [],
        }),
    });

    return (
        <>
            <Modal.Body>{users.length > 0 && <Users users={users} />}</Modal.Body>
            <Modal.Footer>
                <SecondaryButton onClick={hide}>Sluiten</SecondaryButton>
            </Modal.Footer>
        </>
    );
}

function Users({ users = [] }) {
    return (
        <div className="small">
            {users.map((user, index) => (
                <div key={`user-${index}`}>
                    {user.displayName} &lt;{user.email.toLowerCase()}&gt;
                </div>
            ))}
        </div>
    );
}
