import { Col, Form, Modal } from 'react-bootstrap';
import { FormModal, ModalFooter } from '../../global/FormModal';
import { Field, Form as FForm, Formik, FormikHelpers } from 'formik';
import HelperFunctions from '../../global/HelperFunctions';
import { saveAs } from 'file-saver';
import { translationApi } from '../../../features/translations/translationApi';
import { TranslationLanguage } from '../../../models/translation.models';
import { getLanguageName } from '../helpers/LanguageHelper';
import { useTranslation } from 'react-i18next';

export function DownloadXliffModal({
    translationLanguage,
    hide,
}: {
    translationLanguage: TranslationLanguage;
    hide: () => void;
}) {
    const { languageIso } = translationLanguage;
    const [trigger] = translationApi.useLazyGetTranslationLanguageExportQuery();
    const { t } = useTranslation('translations');

    const handleClose = () => {
        hide();
    };

    interface Values {
        content_verified: boolean;
        content_unverified: boolean;
        content_not_translated_yet: boolean;
        content_changed: boolean;
        content_excluded: boolean;
    }

    const handleSubmit = (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
        trigger({
            uri: translationLanguage['@id'],
            params: values,
        }).then((response) => {
            const { status, data } = response;

            if (status === 'rejected' || data === undefined) {
                HelperFunctions.alertModal(t('etc.error'));
            } else {
                saveAs(new Blob([data], { type: 'application/xml;charset=utf-8' }), `${languageIso}.xlf`);
            }

            setSubmitting(false);
        });
    };

    type OptionType = {
        label: string;
        value: string;
    };

    const filterOptions: OptionType[] = [
        {
            label: 'Vertaald en goedgekeurd',
            value: 'content_verified',
        },
        {
            label: 'Vertaald en nog niet goedgekeurd',
            value: 'content_unverified',
        },
        {
            label: 'Nog niet vertaald',
            value: 'content_not_translated_yet',
        },
        {
            label: 'Bron is gewijzigd (en nog niet goedgekeurd)',
            value: 'content_changed',
        },
        {
            label: 'Uitgesloten van vertaling',
            value: 'content_excluded',
        },
    ];

    return (
        <FormModal show={true} onHide={handleClose} title={t('etc.xliffDownload')}>
            <Formik
                initialValues={{
                    content_verified: true,
                    content_unverified: true,
                    content_not_translated_yet: true,
                    content_changed: true,
                    content_excluded: false,
                }}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, values }) => (
                    <FForm autoComplete="off">
                        <Modal.Body>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>
                                        <strong>{t('etc.language')}</strong>
                                    </Form.Label>
                                    <Form.Control
                                        className="pt-0"
                                        plaintext
                                        readOnly
                                        defaultValue={getLanguageName(languageIso)}
                                    />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>{t('etc.filterOptions')}</Form.Label>
                                    {filterOptions.map((option, index) => (
                                        <Field
                                            name={option.value}
                                            id={`download-option-${index}`}
                                            as={Form.Check}
                                            type="checkbox"
                                            label={option.label}
                                            key={`download-option-${index}`}
                                        />
                                    ))}
                                </Form.Group>
                            </Form.Row>
                        </Modal.Body>

                        <ModalFooter
                            btnSubmitText={t('etc.downloadFile') || ''}
                            isSubmitting={isSubmitting}
                            onHide={handleClose}
                            isValid={
                                values.content_verified ||
                                values.content_unverified ||
                                values.content_not_translated_yet ||
                                values.content_changed
                            }
                        />
                    </FForm>
                )}
            </Formik>
        </FormModal>
    );
}
