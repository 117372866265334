import { UserForm } from './UserForm';
import Constants from '../../../config/Constants';
import { useGetUser } from 'hooks/useGetUser';
import { useParams } from 'react-router-dom';

export function EditUser() {
    const { id } = useParams();
    const user = useGetUser(id);

    return (
        <div>
            <div className="subheader">
                <h3>Gebruiker bewerken</h3>
            </div>

            {user && <UserForm user={user} context={Constants.userFormContext.update} />}
        </div>
    );
}
