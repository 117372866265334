import { useGetMyTeamUsersQuery } from 'features/comments/commentApi';

const emptyArray = [];

export function useGetMyTeamUsers() {
    const { teamUsers } = useGetMyTeamUsersQuery(undefined, {
        selectFromResult: ({ data }) => ({
            teamUsers: data ?? emptyArray,
        }),
    });

    return teamUsers;
}
