import Constants from '../../../config/Constants';

export const translationContainerTypes = {
    DOCUMENT: 'DocumentContainer',
    REIMBURSEMENT: 'ReimbursementContainer',
};

export const translationContainerTypesValues = {
    DocumentContainer: 'Document',
    ReimbursementContainer: 'Vergoeding',
};

export const translationLanguageStatus = {
    CREATED: 'created',
    SYNC_IN_PROGRESS: 'sync_in_progress',
    SYNC_FAILED: 'sync_failed',
    TRANSLATION_IN_PROGRESS: 'translation_in_progress',
};

export const translationLanguageStatusValues = {
    created: 'translation.navbar.dashboard.constants.created',
    translation_in_progress: 'translation.navbar.dashboard.constants.translationInProgress',
    sync_in_progress: 'translation.navbar.dashboard.constants.synchronizing',
    sync_failed: 'translation.navbar.dashboard.constants.syncError',
};

export const entityTypes = {
    AREA: 'area',
    BLOCK: 'block',
    BRAND: 'brand',
    ONE_LINER: 'oneLiner',
    ONE_LINER_CATEGORY: 'oneLinerCategory',
    CUSTOMER_JOURNEY: 'customerJourney',
    DEPARTMENT: 'department',
    PRODUCT: 'product',
    REIMBURSEMENT: 'reimbursement',
    REIMBURSEMENT_PRODUCT: 'reimbursementProduct',
    REIMBURSEMENT_PRODUCT_FIELD: 'reimbursementProductField',
    REJECTION_REASON: 'rejectionReason',
    SECTION: 'section',
    TAG: 'tag',
    TAG_ITEM: 'tagItem',
    TARGET_AUDIENCE: 'targetAudience',
    TASK: 'task',
    TASK_TYPE: 'taskType',
    TEAM: 'team',
    TEAM_USER: 'teamUser',
    TOPIC: 'topic',
    DOCUMENT: 'document',
    DOCUMENT_VARIANT: 'documentVariant',
    VARIANT_PACKAGE: 'variantPackage',
    OP_ENTRY: 'opEntry',
    OP_ENTRY_VARIANT: 'OpEntryVariant',
    OP_ENTRY_LABEL: 'OpEntryLabel',
    COLLECTIVE: 'collective',
    PUBLICATION: 'publication',
};

export const views = {
    DOCUMENT: 'document',
    TAGS: 'tags',
    REIMBURSEMENTS: 'reimbursements',
    PRODUCTS: 'products',
};

export const teamRoles = {
    [Constants.translationContainerTeamRoles.manager]: 'translation.navbar.settings.contentSidebar.team.roles.admin',
    [Constants.translationContainerTeamRoles.finalEditor]:
        'translation.navbar.settings.contentSidebar.team.roles.finalEditor',
    [Constants.translationContainerTeamRoles.editor]: 'translation.navbar.settings.contentSidebar.team.roles.editor',
    [Constants.translationContainerTeamRoles.spectator]:
        'translation.navbar.settings.contentSidebar.team.roles.spectator',
};
