import { useGetLabelFoldersQuery, useGetLabelsQuery } from './metadata';
import { useAppSelector } from '../../store';
import { Label, LabelFolder } from '../../models/labels.models';

const emptyFolderArray: LabelFolder[] = [];
const emptyLabelArray: Label[] = [];

export function useGetFolders() {
    const { activeOrganisation } = useAppSelector((state) => state.security);

    const { folders }: { folders: LabelFolder[] } = useGetLabelFoldersQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                folders: data ?? emptyFolderArray,
            }),
        }
    );

    return { folders };
}

export function useGetLabels() {
    const { activeOrganisation } = useAppSelector((state) => state.security);

    const { labels }: { labels: Label[] } = useGetLabelsQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                labels: data ?? emptyLabelArray,
            }),
        }
    );

    return { labels };
}
