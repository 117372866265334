import { Col, FormControl } from 'react-bootstrap';
import cx from 'classnames';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFilter } from 'features/documents/documentsV2Slice';
import RestrictedContent from '../../../global/RestrictedContent';
import { Permissions } from 'config/Constants';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';
import { IPID_IMPORT_PATH } from 'scenes/DocumentsV2';
import { InfoButton, SecondaryButton } from 'components/Buttons';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { Can } from 'ability/Can';

export default function Filters() {
    const history = useHistory();
    const dispatch = useDispatch();
    const { search } = useSelector((state) => state.documents_v2.filters);
    const { t } = useTranslation(['documents']);
    const [newDocumentModal, setShowNewDocumentModal] = useQueryParam('newDocument', BooleanParam);

    function setStoreFilter(key, value) {
        dispatch(setFilter({ key, value }));
    }

    const handleChange = (event) => {
        setStoreFilter('search', event.target.value);
    };

    const debouncedResults = useMemo(() => {
        return debounce(handleChange, 300);
    }, []);

    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    });

    return (
        <div className="content-static-filters px-4 py-3">
            <div className="form-row align-items-center">
                <Col sm={3} md={5} lg={4} xl={3}>
                    <FormControl
                        defaultValue={search}
                        type="search"
                        placeholder={`${t('documents.searchBar')}...`}
                        className={cx('nav-form-search', { 'has-content': search !== '' })}
                        style={{ marginLeft: '15px' }}
                        aria-label="Zoeken"
                        onChange={debouncedResults}
                    />
                </Col>
                <RestrictedContent permission={Permissions.Document.Create}>
                    <div className="d-flex align-items-center ml-auto">
                        <Can I="create" a="IpidImport">
                            <div className="mr-2" style={{ marginTop: '5px' }}>
                                <SecondaryButton
                                    onClick={() => {
                                        history.push(generatePath(IPID_IMPORT_PATH));
                                    }}
                                >
                                    Ipid import
                                </SecondaryButton>
                            </div>
                        </Can>
                        <div style={{ marginRight: '30px', marginTop: '5px' }}>
                            <InfoButton className="tour-btn-new-document" onClick={() => setShowNewDocumentModal(true)}>
                                {t('documents.btn.new')}
                            </InfoButton>
                        </div>
                    </div>
                </RestrictedContent>
            </div>
        </div>
    );
}
