import { useSelector } from 'react-redux';
import { DataTable, DataTableGroup } from '../../../data_table/DataTable';
import { Accordion, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Constants from '../../../../config/Constants';
import cx from 'classnames';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { DocumentRow } from './DocumentRow';
import { useGetDocuments } from '../../hooks/useGetDocuments';
import { useGetDocumentGroups } from '../../hooks/useGetDocumentGroups';
import { useGetDocumentGroupsQuery } from '../../../../features/documents/documents';
import { useTranslation } from 'react-i18next';

export default function Documents({ handleMove }) {
    const { folderId } = useParams();
    const documents = useGetDocuments(folderId === undefined ? null : folderId);
    const isDropDisabled = folderId === undefined || folderId === Constants.folders.trash;

    return (
        <div className="py-3">
            <DataTable variant="documents">
                <div
                    className={cx('mb-4', {
                        'dr-container': documents.length > 0 || folderId !== undefined,
                    })}
                >
                    {folderId !== undefined && <GroupTitle folderId={folderId} />}

                    {(folderId !== undefined || documents.length > 0) && (
                        <Droppable
                            isDropDisabled={isDropDisabled}
                            droppableId={`document-group-${folderId ?? 'root'}`}
                            type="item"
                        >
                            {(provided, snapshot) => (
                                <div
                                    className={cx('data-table-group data-table-group-droppable', {
                                        'dragging-over': snapshot.isDraggingOver,
                                        'drop-disabled': isDropDisabled,
                                    })}
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    <div className="py-3">
                                        <GroupHeader />

                                        <Accordion>
                                            <GroupDocuments folderId={folderId} />
                                        </Accordion>

                                        {provided.placeholder}
                                    </div>
                                </div>
                            )}
                        </Droppable>
                    )}
                </div>

                {folderId === undefined && <RootLevelGroups handleMove={handleMove} />}
            </DataTable>
        </div>
    );
}

function GroupTitle({ folderId }) {
    const { t } = useTranslation('documents');

    const { group } = useGetDocumentGroupsQuery(undefined, {
        selectFromResult: ({ data }) => ({
            group: data ? data.find((_group) => _group.id === parseInt(folderId)) : undefined,
        }),
        skip: folderId === Constants.folders.trash,
    });

    return (
        <div
            className="data-table-group-header py-2 pl-3 font-weight-bolder d-flex align-items-center"
            style={{ backgroundColor: '#f3f5f7', borderRadius: '0.2rem 0.2rem 0px 0px' }}
        >
            {group?.name ?? ''}

            {folderId === Constants.folders.trash && <>{t('documents.message.trash')}</>}
        </div>
    );
}

function GroupDocuments({ folderId }) {
    const { t } = useTranslation('documents');
    const { search } = useSelector((state) => state.documents_v2.filters);
    const documents = useGetDocuments(folderId === undefined ? null : folderId, search);
    const isDragDisabled = folderId === Constants.folders.trash;

    return (
        <div className="data-table-items pb-4">
            {documents.map((_document, index) => (
                <Draggable
                    draggableId={`document-item-${_document.id}`}
                    index={index}
                    isDragDisabled={isDragDisabled}
                    key={`root-document-${_document.id}`}
                >
                    {(provided, snapshot) => (
                        <div
                            id={`document-item-${_document.id}`}
                            className={cx('data-table-item-wrapper', {
                                'is-dragging': snapshot.isDragging,
                            })}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(provided.draggableProps.style)}
                        >
                            <DocumentRow document={_document} />
                        </div>
                    )}
                </Draggable>
            ))}

            {documents.length === 0 && folderId !== undefined && (
                <div className="p-3">
                    <div className="small text-secondary" style={{ lineHeight: '21px', marginLeft: '33px' }}>
                        <span style={{ marginLeft: '30px' }}>{t('documents.message.noDocuments')}</span>
                    </div>
                </div>
            )}
        </div>
    );
}

function RootLevelGroups({ handleMove }) {
    const groups = useGetDocumentGroups();

    return (
        <>
            {groups.map((_group, index) => (
                <DataTableGroup
                    name={_group.name}
                    level={0}
                    handleMove={(direction) => {
                        const isLast = groups.length - 1 === index;

                        if (direction === 'down' && isLast) {
                            return;
                        }

                        handleMove(direction, _group, index);
                    }}
                    key={`group-${_group.id}`}
                >
                    <ChildGroups parentId={_group.id} handleMove={handleMove} />
                </DataTableGroup>
            ))}
        </>
    );
}

function ChildGroups({ parentId, handleMove }) {
    const groups = useGetDocumentGroups(parentId);

    return (
        <>
            {groups.map((_group, index) => (
                <ChildGroup
                    group={_group}
                    handleMove={handleMove}
                    index={index}
                    isLast={groups.length - 1 === index}
                    key={`group-${_group.id}`}
                />
            ))}
        </>
    );
}

function ChildGroup({ group, handleMove, index, isLast }) {
    const { search } = useSelector((state) => state.documents_v2.filters);
    const documents = useGetDocuments(group.id, search);

    if (documents.length === 0 && search !== '') {
        return null;
    }

    return (
        <Droppable droppableId={`document-group-${group.id}`} type="item">
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={cx('data-table-group-droppable dr-container mb-4', {
                        'dragging-over': snapshot.isDraggingOver,
                    })}
                >
                    <DataTableGroup
                        name={group.name}
                        level={1}
                        handleMove={(direction) => {
                            if (direction === 'down' && isLast) {
                                return;
                            }
                            handleMove(direction, group, index);
                        }}
                    >
                        <div className="mb-2 py-3">
                            <GroupHeader />
                            <GroupDocuments group={group} folderId={group.id} />
                            {provided.placeholder}
                        </div>
                    </DataTableGroup>
                </div>
            )}
        </Droppable>
    );
}

function GroupHeader() {
    const { t } = useTranslation('documents');
    return (
        <div className="d-flex align-items-center px-2 small font-weight-bolder" style={{ marginLeft: 22 }}>
            <div className="d-flex flex-grow-1 mx-2">
                <Container fluid>
                    <Row className="align-items-center">
                        <div className="col-7 col-lg-5 col-xxl-4 text-muted">
                            <div className="pl-2" style={{ marginLeft: 18 }}>
                                {t('documents.columnTitles.name')}
                            </div>
                        </div>
                        <div className="col-2 d-none d-lg-block text-muted">{t('documents.columnTitles.status')}</div>
                        <div className="col-2 d-none d-xl-block col-xxl-1 text-muted">
                            {t('documents.columnTitles.activeRound')}
                        </div>
                        <div className="col-3 d-none d-xxl-block text-muted">{t('documents.columnTitles.role')}</div>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

const getItemStyle = (draggableStyle) => ({
    userSelect: 'none',
    ...draggableStyle,
});
