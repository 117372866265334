import { useGetOrganisationTagsQuery } from '../../../features/documents/documents';
import { useSelector } from 'react-redux';

const emptyArray = [];

export function useGetOrganisationTags() {
    const { activeOrganisation } = useSelector((state) => state.security);

    const { organisationTags } = useGetOrganisationTagsQuery(activeOrganisation, {
        selectFromResult: ({ data }) => ({
            organisationTags: data ?? emptyArray,
        }),
    });

    return organisationTags;
}
