import { Nav } from 'react-bootstrap';
import { actions, views } from '../../config/views';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { setRightSideEditorView } from '../../../../features/documents/documentsV2Slice';
import { Document } from '../../../../models/documents.models';
import Constants from '../../../../config/Constants';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetDocument } from '../../hooks/useGetDocument';
import { useTranslation } from 'react-i18next';
import { BooleanParam, useQueryParam } from 'use-query-params';

export function RightSideViewSwitch({ showRightSide = false }: { showRightSide: boolean }) {
    const rightSideEditorView = useAppSelector((state) => state.documents_v2.rightSideEditorView);
    const dispatch = useAppDispatch();
    const document: Document | undefined = useGetDocument();
    const { view, areaId }: { view: string; areaId: string } = useParams();
    const { t } = useTranslation('documents');
    const [blockSelectEnabled, setBlockSelectEnabled] = useQueryParam('blockSelectEnabled', BooleanParam);

    const status = document?.status ?? Constants.documentStatus.draft;

    const showChangesButton =
        view !== actions.CHECK &&
        (status === Constants.documentStatus.reviewInProgress || status === Constants.documentStatus.reviewFinished);

    useEffect(() => {
        const defaultViews = {
            [Constants.documentStatus.draft]: views.PREVIEW,
            [Constants.documentStatus.reviewInProgress]: views.CHANGES,
            [Constants.documentStatus.reviewFinished]: views.CHANGES,
        };

        if (view === actions.EDIT) {
            if (rightSideEditorView !== defaultViews[status]) {
                dispatch(setRightSideEditorView(defaultViews[status]));
            }
        } else if (view === actions.CHECK) {
            if (rightSideEditorView !== views.PREVIEW) {
                dispatch(setRightSideEditorView(views.PREVIEW));
            }
        } else if (view === actions.EDIT_ONE_LINERS) {
            if (rightSideEditorView !== views.ONE_LINER_CATEGORIES) {
                dispatch(setRightSideEditorView(views.ONE_LINER_CATEGORIES));
            }
        }
    }, [areaId]);

    useEffect(() => {
        setBlockSelectEnabled(rightSideEditorView === views.BLOCK_COMPARE);
    }, [rightSideEditorView]);

    return (
        <div className="d-flex border-bottom subnav pt-2" style={{ marginLeft: '2.5rem', marginRight: '2.5rem' }}>
            <Nav activeKey={rightSideEditorView} onSelect={(eventKey) => dispatch(setRightSideEditorView(eventKey))}>
                {showChangesButton && (
                    <Nav.Item>
                        <Nav.Link eventKey={views.CHANGES} disabled={!showRightSide}>
                            {t('document.navbar.main.editor.right.RightSideViewSwitch.history')}
                        </Nav.Link>
                    </Nav.Item>
                )}

                {showPreview() && (
                    <Nav.Item>
                        <Nav.Link
                            eventKey={views.PREVIEW}
                            disabled={!showRightSide || status === Constants.documentStatus.reviewFinished}
                        >
                            {t('document.navbar.main.editor.right.RightSideViewSwitch.preview')}
                        </Nav.Link>
                    </Nav.Item>
                )}

                {showVariant() && (
                    <Nav.Item>
                        <Nav.Link eventKey={views.VARIANTS} disabled={!showRightSide}>
                            {t('document.navbar.main.editor.right.RightSideViewSwitch.variants')}
                        </Nav.Link>
                    </Nav.Item>
                )}

                {showBlockCompare() && (
                    <Nav.Item>
                        <Nav.Link eventKey={views.BLOCK_COMPARE} disabled={!showRightSide}>
                            {t('document.navbar.main.editor.right.RightSideViewSwitch.block_compare')}
                        </Nav.Link>
                    </Nav.Item>
                )}

                {showOneLinerCategories() && (
                    <Nav.Item>
                        <Nav.Link eventKey={views.ONE_LINER_CATEGORIES} disabled={!showRightSide}>
                            One-liner categorieën
                        </Nav.Link>
                    </Nav.Item>
                )}

                {document?.modelId && view === actions.EDIT && (
                    <Nav.Item>
                        <Nav.Link eventKey={views.TEMPLATE} disabled={!showRightSide}>
                            {t('document.navbar.main.editor.right.RightSideViewSwitch.templateBlocks')}
                        </Nav.Link>
                    </Nav.Item>
                )}
            </Nav>
        </div>
    );

    function showPreview() {
        if (view === actions.EDIT_ONE_LINERS) {
            return false;
        }

        return status === Constants.documentStatus.draft || status === Constants.documentStatus.reviewInProgress;
    }

    function showVariant() {
        return view !== actions.EDIT_ONE_LINERS;
    }

    function showBlockCompare() {
        return view !== actions.EDIT_ONE_LINERS;
    }

    function showOneLinerCategories() {
        return view === actions.EDIT_ONE_LINERS;
    }
}
