import { BooleanParam, NumberParam, StringParam, useQueryParam, useQueryParams } from 'use-query-params';
import { Modal } from 'react-bootstrap';
import { LightOutlineButton } from 'components/Buttons';
import { useCallback } from 'react';
import { useGetTask } from 'pages/task_team/hooks/useGetTask';
import { taskVersionStatus } from 'pages/task_team/config/constants';
import { Formik } from 'formik';
import { useGetSelectedVersion } from 'pages/task_team/modals/view_task_modal/TaskVersions';
import { useGetTaskVersion } from 'pages/task_team/hooks/useGetTaskVersion';
import { useGetTaskVersionAttachments } from 'pages/task_team/hooks/useGetTaskVersionAttachments';

export function NewVersionModal() {
    const [taskId] = useQueryParam('tid', StringParam);
    const [newVersion] = useQueryParam('newVersion', BooleanParam);
    const [, setQuery] = useQueryParams({ tid: StringParam, newVersion: BooleanParam });

    const close = useCallback(() => {
        setQuery({
            tid: undefined,
            newVersion: undefined,
        });
    }, []);

    if (!newVersion) {
        return null;
    }

    return (
        <Modal show={true} onHide={close} size="xl" backdrop="static" keyboard={false}>
            <Modal.Header className="align-items-center">
                <Modal.Title>Nieuwe versie</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <NewVersionBody taskId={taskId} />
            </Modal.Body>

            <Modal.Footer>
                <LightOutlineButton onClick={close} className="mr-auto">
                    Sluiten
                </LightOutlineButton>
            </Modal.Footer>
        </Modal>
    );
}

function NewVersionBody({ taskId }) {
    const task = useGetTask(taskId);
    const activeVersion = useGetTaskVersion(task?.activeVersion['@id']);
    const attachments = useGetTaskVersionAttachments(task?.activeVersion['@id']);

    if (!task) {
        return null;
    }

    // const { activeVersion } = task;

    console.log(task);
    console.log(activeVersion);
    console.log(attachments);

    if (activeVersion?.status === taskVersionStatus.STATUS_DRAFT) {
        return null;
    }

    return (
        <Formik
            initialValues={{
                attachments: attachments,
            }}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            // validationSchema={validationSchema}
        >
            <div>test</div>
        </Formik>
    );

    function handleSubmit(values) {}
}
