import { Modal } from 'react-bootstrap';
import { StringParam, useQueryParam } from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { useGetTaskTeamAssignment } from 'pages/task_team/hooks/useGetTaskTeamAssignment';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { ModalFooter } from 'pages/global/FormModal';
import { useGetTeamUsers } from 'pages/task_team/hooks/useGetTeamUsers';
import HelperFunctions from 'pages/global/HelperFunctions';
import { useUpdateTaskTeamAssignmentMutation } from 'features/comments/commentApi';
import { Select } from 'components/Select';

export function AssignTaskModal() {
    const [assignUser, setAssignUser] = useQueryParam('assignTask', StringParam);

    if (!assignUser) {
        return null;
    }

    return <AssignTaskModalWrapper id={assignUser} close={close} />;

    function close() {
        setAssignUser(undefined);
    }
}

function AssignTaskModalWrapper({ id, close }) {
    const { t } = useTranslation();
    const assignment = useGetTaskTeamAssignment(id);
    const [updateTaskTeamAssignment] = useUpdateTaskTeamAssignmentMutation();

    return (
        <>
            <Modal show={true} onHide={close}>
                <Modal.Header closeButton>
                    <Modal.Title>{renderTitle()}</Modal.Title>
                </Modal.Header>

                <Formik
                    enableReinitialize={true}
                    initialValues={{ assignedTo: assignment?.assignedTo }}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    {({ isValid, isSubmitting, dirty, setFieldValue }) => {
                        return (
                            <Form>
                                <Modal.Body>
                                    {assignment && (
                                        <AssignmentBody assignment={assignment} setFieldValue={setFieldValue} />
                                    )}
                                </Modal.Body>

                                <ModalFooter
                                    onHide={close}
                                    isSubmitting={isSubmitting}
                                    dirty={dirty}
                                    isValid={isValid}
                                />
                            </Form>
                        );
                    }}
                </Formik>
            </Modal>
        </>
    );

    function handleSubmit(values) {
        updateTaskTeamAssignment({ uri: assignment['@id'], body: values }).then(() => {
            close();
        });
    }

    function renderTitle() {
        if (!assignment) {
            return null;
        }

        return (
            <>
                #{assignment.task.sequence}-{assignment.taskVersion.versionNumber} {assignment.task.title}
            </>
        );
    }
}

function AssignmentBody({ assignment, setFieldValue }) {
    const teamUsers = useGetTeamUsers(assignment.team.id);

    const teamUsersWithReviewPermissions = teamUsers.filter((teamUser) => {
        const canReviewTask = teamUser.user.enabled && teamUser.permissions.canReviewTask === true;

        if (!canReviewTask) {
            return false;
        }

        // If Task is confidential, only allow Users who can see confidential tasks
        if (assignment.task.confidential === true) {
            return teamUser.permissions.canViewConfidentialTasks === true;
        }

        return true;
    });

    const options = HelperFunctions.prepareDropdownData(teamUsersWithReviewPermissions, 'fullName', 'userId');

    if (options.length === 0) {
        return null;
    }

    return (
        <div>
            <div className="font-weight-bold mb-2">{assignment.team.name}</div>

            <Select
                label="Toewijzen aan"
                id="assignedTo"
                isMulti={false}
                options={options}
                defaultValue={
                    assignment.assignedTo ? options.find((user) => user.userId === assignment.assignedTo) : undefined
                }
                handleChange={(selection) => handleChange(selection, 'assignedTo')}
            />
        </div>
    );

    function handleChange(selection, field) {
        setFieldValue(field, selection.value);
    }
}

const validationSchema = Yup.object().shape({
    assignedTo: Yup.string().required(),
});
