import { useFormikContext } from 'formik';
import { FieldSelect, Switch } from '../helpers/FieldHelper';
import { useTranslation } from 'react-i18next';
import { useGetDomainKioskCategoriesQuery } from 'features/security/authApi';
import HelperFunctions from '../../global/HelperFunctions';
import { Alert } from 'react-bootstrap';
import { useGetPublicationDomains } from 'pages/publications_v2/hooks/useGetPublicationDomains';

export function Kiosk() {
    const { values } = useFormikContext();
    const { domainId, includeInKiosk = false } = values;
    const { t } = useTranslation('publications');
    const publicationDomains = useGetPublicationDomains();

    const domain = publicationDomains.find((_option) => _option.id === domainId);

    return (
        <div className="p-4">
            {!domain && <Alert variant="warning">{t('publication.settings.content.kiosk.domainNotSet')}</Alert>}

            {domain && domain.enableKiosk === false && (
                <Alert variant="warning">{t('publication.settings.content.kiosk.notEnabledForDomain')}</Alert>
            )}

            <Switch
                name="includeInKiosk"
                label={t('publication.settings.content.kiosk.includeInKiosk')}
                props={{
                    disabled: !domain || domain.enableKiosk === false,
                }}
            />

            {includeInKiosk && domain && <CategorySelect domain={domain} />}
        </div>
    );
}

function CategorySelect({ domain }) {
    const { t } = useTranslation('publications');

    const { categories } = useGetDomainKioskCategoriesQuery(domain.id, {
        selectFromResult: ({ data }) => ({
            categories: data ?? [],
        }),
    });

    return (
        <FieldSelect
            label={t('publication.settings.content.kiosk.category')}
            name={`kioskCategoryId`}
            options={HelperFunctions.prepareDropdownData(categories, 'categoryName')}
            emptyValue={null}
            props={{
                placeholder: `${t('publication.settings.content.reimbursements.titleSelect')}...`,
                isClearable: true,
            }}
        />
    );
}
