import Dropzone from 'react-dropzone';
import { read, utils } from 'xlsx';
import { ImportContext } from '../IpidImport';
import { useContext, useMemo, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { IconButton, PrimaryButton, WarningButton } from 'components/Buttons';
import { CheckCircleFill, PencilSquare, People, TrashFill, XCircleFill } from 'react-bootstrap-icons';
import HelperFunctions from '../../../global/HelperFunctions';
import { templatesApi, useGetModelVersionByTemplateCategoriesQuery } from 'features/templates/templatesApi';
import { useCurrentOrganisation } from 'hooks/useCurrentOrganisation';
import { Select } from 'components/Select';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { ipidApi, useGetInsurerQuery, useGetInsurersQuery } from 'features/documents/ipidApi';
import { IPID_IMPORT_PATH } from 'scenes/DocumentsV2';
import { InsurerTemplateCards } from './InsurerTemplateCards';
import { removeInvisibleSpaces } from './Helpers';
import { NumberParam, useQueryParam } from 'use-query-params';
import { InsurerUsers } from './InsurerUsers';
import _ from 'lodash';

export function Start() {
    const { formData, setFormData } = useContext(ImportContext);
    const { importedVariants = [], documentTemplateId } = formData;
    const history = useHistory();
    const [getCard] = ipidApi.useLazyGetCardQuery();

    return (
        <>
            <div className="content-static-body" style={{ bottom: 65 }}>
                <div className="d-flex align-items-stretch h-100">
                    <div className="flex-grow-1 overflow-auto">
                        <div className="p-4">
                            <ImportFilter />

                            {documentTemplateId && (
                                <>
                                    <Dropzone maxFiles={1} accept={{ 'text/csv': ['.csv'] }} onDrop={handleDrop}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps({ style })} className="cursor-pointer mb-4">
                                                <input {...getInputProps()} />
                                                <div className="text-muted pr-2">Selecteer csv...</div>
                                            </div>
                                        )}
                                    </Dropzone>

                                    <AddVariantForm />
                                    <SelectedVariants />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-white position-absolute border-top" style={{ right: 0, bottom: 0, left: 0, height: 65 }}>
                <div className="d-flex align-items-center justify-content-end pr-5" style={{ height: 65 }}>
                    <WarningButton
                        disabled={importedVariants.length === 0}
                        onClick={() => {
                            history.push(
                                generatePath(IPID_IMPORT_PATH, {
                                    view: 'card',
                                })
                            );
                        }}
                    >
                        Volgende
                    </WarningButton>
                </div>
            </div>

            <InsurerUsers />
        </>
    );

    function handleDrop(acceptedFiles) {
        if (acceptedFiles.length === 1) {
            const file = acceptedFiles[0];
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);

            reader.onload = async (e) => {
                const data = e.target.result;
                const workbook = read(new Uint8Array(data), { type: 'array', raw: true, FS: ';' });
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const csvData = utils.sheet_to_json(worksheet, {
                    header: 1,
                    blankrows: false,
                    defval: '',
                    rawNumbers: true,
                });

                // Remove header row
                if (csvData.length > 1) {
                    csvData.shift();
                }

                Promise.all(
                    csvData.map((row) => {
                        return new Promise((resolve) => {
                            getCard(row[2]).then(({ data }) => {
                                resolve([
                                    documentTemplateId,
                                    row[1],
                                    data.id ?? 0,
                                    data.latestVersion ?? 0,
                                    data.name ?? '',
                                    row[5],
                                    data.polisvoorwaarden ?? '',
                                ]);
                            });
                        });
                    })
                ).then((values) => {
                    setFormData({
                        ...formData,
                        importedVariants: [...importedVariants, ...values],
                    });
                });
            };
        }
    }
}

function ImportFilter() {
    const { formData } = useContext(ImportContext);
    const { insurerId, document } = formData;
    const currentOrganisation = useCurrentOrganisation();
    const insurerIds = currentOrganisation?.insurerIds ?? [];

    const { insurers } = useGetInsurersQuery(insurerIds, {
        selectFromResult: ({ data }) => ({
            insurers: data ? [...data].sort(HelperFunctions.sortByString('name')) : [],
        }),
        skip: currentOrganisation === undefined,
    });

    const { selectedInsurer } = useGetInsurerQuery(insurerId, {
        selectFromResult: ({ data }) => ({
            selectedInsurer: data,
        }),
        skip: !insurerId,
    });

    return (
        <Container fluid>
            <Row>
                <Col sm={12} md={10} lg={8}>
                    <div className="ipid-import-template-filters">
                        <InsurerSelect insurers={insurers} />
                        <TemplateDocumentSelect selectedInsurer={insurerId ? selectedInsurer : undefined} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    {insurerId && selectedInsurer && document && (
                        <InsurerTemplateCards insurer={selectedInsurer} template={document} />
                    )}
                </Col>
            </Row>
        </Container>
    );
}

function InsurerSelect({ insurers }) {
    const { formData, setFormData } = useContext(ImportContext);
    const { insurerId } = formData;
    const [iid, setIid] = useQueryParam('iid', NumberParam);

    if (insurers.length === 0) {
        return null;
    }

    const options = HelperFunctions.prepareDropdownData(insurers);

    return (
        <div className="d-flex align-items-center mb-2">
            <div className="flex-grow-1">
                <Select
                    defaultValue={insurerId ? options.find((option) => option.id === insurerId) : undefined}
                    isClearable
                    id="insurerId"
                    label="Verzekeraar"
                    options={options}
                    handleChange={handleInsurerIdChange}
                />
            </div>

            <IconButton
                icon={<People size={18} />}
                disabled={!insurerId}
                onClick={() => setIid(insurerId)}
                className="ml-2 mr-1"
                tooltip="Bekijk gebruikers"
            />
        </div>
    );

    function handleInsurerIdChange(selectedValue) {
        setFormData({
            ...formData,
            insurerId: selectedValue ? selectedValue.id : undefined,
        });
    }
}

function TemplateDocumentSelect({ selectedInsurer }) {
    const { formData, setFormData } = useContext(ImportContext);
    const { documentTemplateId, document } = formData;
    const currentOrganisation = useCurrentOrganisation();
    const currentOrganisationId = currentOrganisation?.id;
    const [getActiveModelVersion] = templatesApi.useLazyGetActiveModelVersionQuery();

    const templateCategories = currentOrganisation?.templateCategories ?? [];
    const { modelsByTemplateCategory } = useGetModelVersionByTemplateCategoriesQuery(currentOrganisationId, {
        selectFromResult: ({ data }) => ({
            modelsByTemplateCategory: data
                ? data.map((modelVersion) => {
                      const templateCategory = templateCategories.find(
                          (_category) => _category.id === modelVersion.templateCategoryId
                      );

                      const cardTemplateId = modelVersion.fields?.version ?? '';
                      const cardTemplateIds = cardTemplateId.split(',').map((templateId) => parseInt(templateId));

                      return {
                          id: `${modelVersion.source}-${modelVersion.documentId}`,
                          documentId: modelVersion.documentId,
                          name:
                              (templateCategory ? templateCategory.name + ' > ' : '') +
                              modelVersion.name +
                              ` (id=${modelVersion.documentId})`,
                          modelVersionName: removeInvisibleSpaces(modelVersion.name),
                          source: modelVersion.source,
                          cardTemplateIds,
                      };
                  })
                : [],
        }),
        skip: !currentOrganisationId,
    });

    const handleChange = (selectedValue) => {
        if (selectedValue) {
            getActiveModelVersion({
                id: parseInt(selectedValue.documentId),
                source: selectedValue.source,
            }).then(({ data }) => {
                if (data?.payloadJson) {
                    const template = HelperFunctions.tryParseJSON(data.payloadJson);

                    setFormData({
                        ...formData,
                        documentTemplateId: parseInt(selectedValue.documentId),
                        documentTemplateSource: selectedValue.source,
                        document: template,
                    });
                }
            });
        }
    };

    const options = useMemo(() => {
        if (modelsByTemplateCategory.length === 0) {
            return [];
        }

        const templateOptions = HelperFunctions.prepareDropdownData(modelsByTemplateCategory).sort(
            HelperFunctions.sortByString('label')
        );

        if (selectedInsurer) {
            if (selectedInsurer.activeTemplates.length === 0) {
                return [];
            }

            return templateOptions.filter((option) =>
                selectedInsurer.activeTemplates.some((template) => {
                    if (option.cardTemplateIds.length > 0) {
                        return option.cardTemplateIds.includes(template.id);
                    }

                    return option.modelVersionName === template.cardName;
                })
            );
        }

        return templateOptions;
    }, [modelsByTemplateCategory, selectedInsurer]);

    return (
        <div className="mb-4">
            <div className="mb-2">
                <Select
                    defaultValue={
                        documentTemplateId
                            ? options.find((option) => option.documentId === documentTemplateId)
                            : undefined
                    }
                    id="templateCategoryId"
                    label="Template document"
                    options={options}
                    handleChange={handleChange}
                    isDisabled={options.length === 0}
                    isClearable={true}
                />
            </div>

            <div className="d-flex align-items-center">
                <label
                    htmlFor="documentName"
                    className="ml-3 mr-2 col-form-label font-weight-bold"
                    style={{ color: '#6c757d', fontSize: 13 }}
                >
                    Document naam
                </label>
                <Form.Control
                    id="documentName"
                    placeholder="Document naam"
                    disabled={document === undefined}
                    value={document?.name ?? ''}
                    onChange={(event) => {
                        setFormData({
                            ...formData,
                            document: {
                                ...formData.document,
                                name: event.target.value,
                            },
                        });
                    }}
                />
            </div>
        </div>
    );
}

function AddVariantForm() {
    const [getCard] = ipidApi.useLazyGetCardQuery();
    const { formData, setFormData } = useContext(ImportContext);
    const { importedVariants = [], documentTemplateId } = formData;

    const [addRowformData, setAddRowFormData] = useState({
        label: '',
        card_id: '',
    });

    const resetForm = () => {
        setAddRowFormData({
            label: '',
            card_id: '',
        });
    };

    const handleSubmit = () => {
        getCard(addRowformData.card_id).then(({ data }) => {
            if (!data) {
                HelperFunctions.alertModal('Kaart niet gevonden');
                return;
            }

            if (data.deleted === 1 || data.latestVersion === 0) {
                HelperFunctions.alertModal('Kaart niet gepubliceerd');
                return;
            }

            setFormData({
                ...formData,
                importedVariants: [
                    ...importedVariants,
                    [
                        documentTemplateId,
                        _.trim(addRowformData.label),
                        data.id,
                        data.latestVersion,
                        _.trim(data.name),
                        '',
                        _.trim(data.polisvoorwaarden),
                        _.trim(data.url),
                        data.templates.id,
                    ],
                ],
            });

            resetForm();
        });
    };

    return (
        <div className="mb-4">
            <Form
                inline
                onSubmit={(event) => {
                    event.preventDefault();
                }}
            >
                <Form.Control
                    onChange={(event) => {
                        setAddRowFormData({
                            ...addRowformData,
                            label: event.target.value,
                        });
                    }}
                    value={addRowformData.label}
                    name="label"
                    className="mr-2"
                    placeholder="label"
                />
                <Form.Control
                    onChange={(event) => {
                        setAddRowFormData({
                            ...addRowformData,
                            card_id: event.target.value,
                        });
                    }}
                    value={addRowformData.card_id}
                    name="card_id"
                    className="mr-2"
                    placeholder="card_id"
                />
                <PrimaryButton
                    type="submit"
                    disabled={addRowformData.label === '' || addRowformData.card_id === ''}
                    onClick={handleSubmit}
                >
                    Toevoegen
                </PrimaryButton>
            </Form>
        </div>
    );
}

function SelectedVariants() {
    const { formData } = useContext(ImportContext);
    const { importedVariants = [], documentTemplateId } = formData;

    if (importedVariants.length === 0 || !documentTemplateId) {
        return null;
    }

    return (
        <table className="table">
            <thead>
                <tr>
                    <th />
                    <th>Label</th>
                    <th>card_id</th>
                    <th>version_id</th>
                    <th>template_id</th>
                    <th>Naam</th>
                    <th>Url</th>
                </tr>
            </thead>
            <tbody>
                {importedVariants.map((variant, index) => (
                    <Variant variant={variant} index={index} key={`variant-${index}`} />
                ))}
            </tbody>
        </table>
    );
}

function Variant({ variant, index }) {
    const { formData, setFormData } = useContext(ImportContext);
    const { importedVariants = [] } = formData;

    const removeRow = () => {
        setFormData({
            ...formData,
            importedVariants: importedVariants.filter((data, idx) => idx !== index),
        });
    };

    return (
        <tr>
            <td>
                <IconButton icon={<TrashFill size={18} className="text-danger" />} onClick={removeRow} />
            </td>
            <td>
                <VariantLabel label={variant[1]} index={index} />
            </td>
            <td>{variant[2]}</td>
            <td>
                <CardVersion version={variant[3]} index={index} />
            </td>
            <td>{variant[8]}</td>
            <td>{variant[4]}</td>
            <td className="small">
                {_.isEmpty(variant[7]) ? (
                    '-'
                ) : (
                    <a href={variant[7]} target="_blank">
                        {variant[7]}
                    </a>
                )}
            </td>
        </tr>
    );
}

function VariantLabel({ label, index }) {
    const { formData, setFormData } = useContext(ImportContext);
    const { importedVariants = [] } = formData;

    const [editMode, setEditMode] = useState(false);
    const [labelData, setLabelData] = useState(label);

    const handleSave = () => {
        setEditMode(false);

        setFormData({
            ...formData,
            importedVariants: importedVariants.map((row, idx) => {
                if (idx === index) {
                    return [row[0], _.trim(labelData), row[2], row[3], _.trim(row[4]), _.trim(row[5])];
                }

                return row;
            }),
        });
    };

    const handleCancel = () => {
        setLabelData(label);
        setEditMode(false);
    };

    return (
        <div className="d-flex align-items-center">
            {editMode ? (
                <Form inline>
                    <Form.Control
                        onChange={(event) => {
                            setLabelData(event.target.value);
                        }}
                        value={labelData}
                        name="label"
                        className="mr-2"
                        placeholder="label"
                    />
                    <IconButton
                        tooltip="Opslaan"
                        icon={<CheckCircleFill size={20} className="text-warning" />}
                        className="mr-1"
                        onClick={handleSave}
                    />
                    <IconButton
                        tooltip="Annuleer"
                        icon={<XCircleFill size={20} className="text-muted" />}
                        onClick={handleCancel}
                    />
                </Form>
            ) : (
                <>
                    <div className="mr-2">{label}</div>
                    <IconButton icon={<PencilSquare size={14} />} onClick={() => setEditMode(true)} />
                </>
            )}
        </div>
    );
}

function CardVersion({ version, index }) {
    const { formData, setFormData } = useContext(ImportContext);
    const { importedVariants = [] } = formData;

    const [editMode, setEditMode] = useState(false);
    const [versionData, setVersionData] = useState(version);

    return (
        <div className="d-flex align-items-center">
            {editMode ? (
                <Form inline>
                    <Form.Control
                        onChange={(event) => {
                            setVersionData(event.target.value);
                        }}
                        value={versionData}
                        name="label"
                        className="mr-2"
                        placeholder="version"
                    />
                    <IconButton
                        tooltip="Opslaan"
                        icon={<CheckCircleFill size={20} className="text-warning" />}
                        className="mr-1"
                        onClick={handleSave}
                    />
                    <IconButton
                        tooltip="Annuleer"
                        icon={<XCircleFill size={20} className="text-muted" />}
                        onClick={handleCancel}
                    />
                </Form>
            ) : (
                <>
                    <div className="mr-2">{version}</div>
                    <IconButton icon={<PencilSquare size={14} />} onClick={() => setEditMode(true)} />
                </>
            )}
        </div>
    );

    function handleSave() {
        setEditMode(false);

        setFormData({
            ...formData,
            importedVariants: importedVariants.map((row, idx) => {
                if (idx === index) {
                    return [row[0], _.trim(row[1]), row[2], versionData, _.trim(row[4]), _.trim(row[5])];
                }

                return row;
            }),
        });
    }

    function handleCancel() {
        setVersionData(version);
        setEditMode(false);
    }
}

const style = {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    padding: '2rem',
    height: '100%',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
};
