import ApiConnector from './ApiConnector';
import Constants from '../config/Constants';
import getEnv from '../config/Env';
import ApiConnector_v2 from './ApiConnector_v2';
import baseAPI from './ApiConnector_v2';

const apiBaseUrl = getEnv('REIMBURSEMENT_API_BASE_URL');
const apiUrl = getEnv('REIMBURSEMENT_API_URL');
const uriPrefix = '/api/reimbursement-api';

function prepareFormData(formData) {
    const propsWithPrefix = ['category', 'parent'];

    propsWithPrefix.forEach((propName) => {
        if (
            formData.hasOwnProperty(propName) &&
            formData[propName] !== null &&
            formData[propName].startsWith(uriPrefix) === false
        ) {
            formData[propName] = uriPrefix + formData[propName];
        }
    });

    return formData;
}

const ReimbursementApi = {
    batchEdit(formData) {
        return baseAPI.post(apiUrl + '/batch_edits', formData);
    },
    createCrossRefMigrationTask(formData) {
        return ApiConnector_v2.post(apiUrl + '/cross_ref_migration_tasks', formData);
    },
    createProduct(formData) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiPost(apiUrl + '/products', prepareFormData(formData))
                .then((result) => resolve(result))
                .catch((context) => reject(context));
        });
    },
    createProductLink(formData) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiPost(apiUrl + '/product_links', formData)
                .then((result) => resolve(result))
                .catch((context) => reject(context));
        });
    },
    createProductType(formData) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiPost(apiUrl + '/product_types', prepareFormData(formData))
                .then((result) => resolve(result))
                .catch((context) => reject(context));
        });
    },
    createReimbursement(formData) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiPost(apiUrl + '/reimbursements', prepareFormData(formData))
                .then((result) => resolve(result))
                .catch((context) => reject(context));
        });
    },
    createReimbursementCode(formData) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiPost(apiUrl + '/reimbursement_codes', formData)
                .then((result) => resolve(result))
                .catch((context) => reject(context));
        });
    },
    createReimbursementCodeTranslation(formData) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiPost(apiUrl + '/reimbursement_code_translations', formData)
                .then((result) => resolve(result))
                .catch((context) => reject(context));
        });
    },
    createVariantPackage(formData) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiPost(apiUrl + '/variant_packages', formData)
                .then((result) => resolve(result))
                .catch((context) => reject(context));
        });
    },
    createVariantPackageProduct(formData) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiPost(apiUrl + '/variant_package_products', formData)
                .then((result) => resolve(result))
                .catch((context) => reject(context));
        });
    },
    deleteVariantPackageProduct(id) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiDelete(apiUrl + '/variant_package_products/' + id)
                .then((result) => resolve(result))
                .catch((context) => reject(context));
        });
    },
    updateProduct(id, formData) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiPut(apiUrl + '/products/' + id, formData)
                .then((result) => resolve(result))
                .catch((context) => reject(context));
        });
    },
    updateProductLink(id, formData) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiPut(apiUrl + '/product_links/' + id, formData)
                .then((result) => resolve(result))
                .catch((context) => reject(context));
        });
    },
    deleteProductLink(id) {
        return ApiConnector.apiDelete(`${apiUrl}/product_links/${id}`);
    },
    updateProductType(id, formData) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiPut(apiUrl + '/product_types/' + id, formData)
                .then((result) => resolve(result))
                .catch((context) => reject(context));
        });
    },
    updateReimbursementCode(id, formData) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiPut(apiUrl + '/reimbursement_codes/' + id, formData)
                .then((result) => resolve(result))
                .catch((context) => reject(context));
        });
    },
    updateReimbursementCodeTranslation(id, formData) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiPut(apiUrl + '/reimbursement_code_translations/' + id, formData)
                .then((result) => resolve(result))
                .catch((context) => {
                    getEnv('APP_ENV') === Constants.environments.dev &&
                        console.log('unable to update translations', context);
                    reject(context);
                });
        });
    },
    deleteReimbursementCode(id) {
        return ApiConnector.apiDelete(`${apiUrl}/reimbursement_codes/${id}`);
    },
    updateVariantPackage(id, formData) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiPut(apiUrl + '/variant_packages/' + id, formData)
                .then((result) => resolve(result))
                .catch((context) => reject(context));
        });
    },
    updateVariantPackageProduct(id, formData) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiPut(apiUrl + '/variant_package_products/' + id, formData)
                .then((result) => resolve(result))
                .catch((context) => reject(context));
        });
    },
    get(id, idIsUri = false) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiGet((idIsUri ? apiBaseUrl : apiUrl) + id)
                .then((result) => resolve(result))
                .catch((context) => {
                    getEnv('APP_ENV') === Constants.environments.dev && console.log('unable to get data', context);
                    reject(context);
                });
        });
    },
    getCategories() {
        return new Promise((resolve, reject) => {
            ApiConnector.apiGet(apiUrl + '/categories')
                .then((result) => resolve(result))
                .catch((context) => {
                    getEnv('APP_ENV') === Constants.environments.dev &&
                        console.log('unable to load reimbursements', context);
                    reject(context);
                });
        });
    },
    getCategory(categoryId) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiGet(apiUrl + '/categories/' + categoryId)
                .then((result) => resolve(result))
                .catch((context) => {
                    getEnv('APP_ENV') === Constants.environments.dev && console.log('unable to load category', context);
                    reject(context);
                });
        });
    },
    getProducts(categoryId) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiGet(apiUrl + '/categories/' + categoryId + '/products')
                .then((result) => resolve(result))
                .catch((context) => {
                    getEnv('APP_ENV') === Constants.environments.dev && console.log('unable to load products', context);
                    reject(context);
                });
        });
    },
    getProductTypes(categoryId) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiGet(apiUrl + '/categories/' + categoryId + '/product_types')
                .then((result) => resolve(result))
                .catch((context) => {
                    getEnv('APP_ENV') === Constants.environments.dev && console.log('unable to load products', context);
                    reject(context);
                });
        });
    },
    getReimbursementCodes(categoryId) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiGet(apiUrl + '/categories/' + categoryId + '/reimbursement_codes')
                .then((result) => resolve(result))
                .catch((context) => {
                    getEnv('APP_ENV') === Constants.environments.dev &&
                        console.log('unable to load reimbursement codes', context);
                    reject(context);
                });
        });
    },
    getReimbursementBlockData(
        blockId,
        code,
        variantId,
        categoryId,
        locale = 'nl',
        showChanges = false,
        showChangesInPreview = false,
        changesFromTimestamp = null
    ) {
        return new Promise((resolve, reject) => {
            const params = {
                blockId,
                content: {
                    code,
                },
                variantId,
                categoryId,
                locale,
            };
            if (showChanges) {
                params['showChanges'] = true;
            }
            if (showChangesInPreview) {
                params['showChangesInPreview'] = true;
            }
            if (changesFromTimestamp) {
                params['changesFromTimestamp'] = changesFromTimestamp;
            }

            ApiConnector.apiGet(apiUrl + '/reimbursements/rendered-products', undefined, { content: params })
                .then((result) => {
                    const data = result['hydra:member'];

                    if (data.length === 1) {
                        resolve(data[0]?.reimbursement?.renderedReimbursementProducts ?? {});
                    }

                    resolve({});
                })
                .catch((context) => {
                    getEnv('APP_ENV') === Constants.environments.dev &&
                        console.log('unable to load reimbursement products', context);
                    reject(context);
                });
        });
    },
    getReimbursementCodeTranslations(codeId) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiGet(apiUrl + '/reimbursement_codes/' + codeId + '/translations')
                .then((result) => resolve(result))
                .catch((context) => {
                    getEnv('APP_ENV') === Constants.environments.dev &&
                        console.log('unable to load translations', context);
                    reject(context);
                });
        });
    },
    getReimbursementProducts(reimbursementUri) {
        return new Promise((resolve, reject) => {
            const url = apiBaseUrl + reimbursementUri + '/reimbursement_products';

            ApiConnector.apiGet(url)
                .then((result) => resolve(result))
                .catch((context) => {
                    getEnv('APP_ENV') === Constants.environments.dev &&
                        console.log('unable to load reimbursement products', context);
                    reject(context);
                });
        });
    },
    getReimbursements(categoryId) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiGet(apiUrl + '/categories/' + categoryId + '/reimbursements')
                .then((result) => resolve(result))
                .catch((context) => {
                    getEnv('APP_ENV') === Constants.environments.dev &&
                        console.log('unable to load reimbursements', context);
                    reject(context);
                });
        });
    },
    getVariantPackage(variantId) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiGet(apiUrl + '/variant_packages?documentVariantId[]=' + variantId)
                .then((result) => resolve(result))
                .catch((context) => {
                    getEnv('APP_ENV') === Constants.environments.dev &&
                        console.log('unable to load reimbursements', context);
                    reject(context);
                });
        });
    },
};

export default ReimbursementApi;
