import { StringParam, useQueryParam } from 'use-query-params';
import { XLg } from 'react-bootstrap-icons';
import { IconButton } from 'components/Buttons';
import { useGetFile } from 'pages/task_team/hooks/useGetFile';
import { FileIcon } from 'pages/task_team/views/checks/TaskFileAttachments';
import { DownloadFileIcon, RenderFile } from 'pages/task_team/modals/view_task_modal/PreviewFile';

export function PreviewFileModal() {
    const [previewFileId] = useQueryParam('previewFile', StringParam);

    if (!previewFileId) {
        return null;
    }

    return (
        <div className="media-viewer-popup d-flex flex-column">
            <MediaViewer fileId={previewFileId} />
        </div>
    );
}

function MediaViewer({ fileId }) {
    const [, setPreviewFileId] = useQueryParam('previewFile', StringParam);
    const file = useGetFile(fileId);

    return (
        <>
            <div className="media-viewer-header d-flex justify-content-between px-4 py-3">
                {file && (
                    <div className="file-icon flex-center">
                        <FileIcon mime={file.mime} />
                        {file.fileName}
                    </div>
                )}
                <div>
                    {file && <DownloadFileIcon file={file} />}
                    <IconButton
                        tooltip={'Sluiten'}
                        className="ml-4"
                        icon={<XLg />}
                        onClick={() => setPreviewFileId(undefined)}
                    />
                </div>
            </div>

            <div className="media-viewer-content flex-fill p-4 bg-white mx-4 mb-3">
                {file && <RenderFile file={file} />}
            </div>
        </>
    );
}
