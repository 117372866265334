import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Field, Form as FForm, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { FormModal, ModalFooter } from '../../global/FormModal';
import _ from 'lodash';
import { addOpTeam, patchOpTeam } from '../../../features/operationsList/operationsListSlice';
import { useTranslation } from 'react-i18next';

export default function EditTeamModal({ showModal = false, opList, department, team, handleClose }) {
    const editMode = !!team.id;
    const dispatch = useDispatch();
    const { t } = useTranslation('changelist');

    const handleSubmit = (values, { setSubmitting }) => {
        const formData = {
            ...values,
            opDepartment: department['@id'],
            opList: opList['@id'],
        };

        return Promise.resolve()
            .then(() => {
                if (editMode) {
                    return dispatch(patchOpTeam({ uri: team['@id'], formData }));
                }
                return dispatch(addOpTeam(formData));
            })
            .then((data) => {
                setSubmitting(false);
                handleClose(data);
            });
    };

    return (
        <FormModal
            show={showModal}
            onHide={() => handleClose()}
            title={
                editMode ? t('changelist.task.editTeamModal.editTeam') : t('changelist.task.editTeamModal.createTeam')
            }
        >
            <Formik
                initialValues={{
                    name: _.get(team, 'name', ''),
                }}
                validationSchema={TeamSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, isValid, dirty, touched, errors }) => (
                    <FForm autoComplete="off">
                        <Modal.Body>
                            <Form.Group>
                                <Form.Label htmlFor="name">{t('changelist.task.editTeamModal.teamName')}</Form.Label>
                                <Field
                                    id="name"
                                    name="name"
                                    as={Form.Control}
                                    isInvalid={touched['name'] && errors['name']}
                                    isValid={touched['name'] && !errors['name']}
                                />
                            </Form.Group>
                        </Modal.Body>

                        <ModalFooter
                            isSubmitting={isSubmitting}
                            isValid={isValid}
                            dirty={dirty}
                            onHide={() => handleClose()}
                        />
                    </FForm>
                )}
            </Formik>
        </FormModal>
    );
}

const TeamSchema = Yup.object().shape({
    name: Yup.string().required(),
});
