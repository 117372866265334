import { useParams } from 'react-router-dom';
import Sidebar from './settings/Sidebar';
import Properties from './settings/Properties';
import Team from './settings/Team';
import Revisions from './settings/Revisions';
import AuditTrail from './settings/AuditTrail';
import Constants from '../../../config/Constants';
import RestrictedDocumentContent from '../RestrictedDocumentContent';
import OneLiners from 'pages/documents_v2/views/settings/OneLiners';

export function Settings() {
    const { documentId } = useParams();

    return (
        <>
            <RestrictedDocumentContent documentId={documentId} roles={[Constants.userDocumentRole.documentManager]}>
                <div className="content-static-body has-subnav">
                    <div className="d-flex align-items-stretch h-100">
                        <div className="content-sidebar-document overflow-auto bg-light">
                            <Sidebar />
                        </div>
                        <div className="flex-grow-1 overflow-auto">
                            <div id="content" className="px-5 pt-5 pb-3">
                                <Setting />
                            </div>
                        </div>
                    </div>
                </div>
            </RestrictedDocumentContent>
        </>
    );
}

function Setting() {
    const { page = 'properties' } = useParams();

    switch (page) {
        case 'team':
            return <Team />;
        case 'revisions':
            return <Revisions />;
        case 'audit_trail':
            return <AuditTrail />;
        case 'properties':
            return <Properties />;
        case 'one_liners':
            return <OneLiners />;
    }
}
