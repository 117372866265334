import { useFormikContext } from 'formik';
import { useGetTopics } from 'pages/task_team/hooks/useGetTopics';
import { useGetTargetAudiences } from 'pages/task_team/hooks/useGetTargetAudiences';
import { useGetBrands } from 'pages/task_team/hooks/useGetBrands';
import { FormGroup } from 'components/SettingsSidebar/helpers/FormHelpers';
import { InputField, InputSelect } from 'pages/publications_v2/helpers/FieldHelper';
import HelperFunctions from 'pages/global/HelperFunctions';
import { CheckBox, MultipleSelect, TextArea } from 'pages/task_team/helpers/FormHelper';
import { useGetCustomerJourneys } from 'pages/task_team/hooks/useGetCustomerJourneys';
import { useGetTaskTypes } from 'pages/task_team/hooks/useGetTaskTypes';
import { ExclamationCircleFill } from 'react-bootstrap-icons';
import { useGetChecklists } from 'pages/task_team/hooks/useGetChecklists';
import { DateTime } from 'luxon';
import { useGetAllTeamRules } from 'pages/task_team/hooks/useGetTeamRules';

export function Step2Properties({ active = false }) {
    const { values } = useFormikContext();
    const brands = useGetBrands();
    const customerJourneys = useGetCustomerJourneys();
    const taskTypes = useGetTaskTypes();

    const abort = values.numberOfRecipients === 'one' || values.contentType === 'textual';

    if (abort) {
        return (
            <div>
                <div className="border border-secondary border-radius p-4">
                    <div className="d-flex align-items-start">
                        <ExclamationCircleFill className="text-secondary" size={72} />
                        <div className="ml-4">
                            <div className="font-weight-bold mb-2">
                                Deze uiting hoeft verder niet door de OKÉ-check.
                            </div>

                            {values.numberOfRecipients === 'one' && (
                                <div className="mb-3">
                                    Het gaat hier om een uiting naar slechts één lezer/ontvanger.
                                </div>
                            )}

                            {values.contentType === 'textual' && (
                                <div>
                                    Het betreft een correctie in een tarief, spelling & grammatica of het toevoegen van
                                    een paar woorden.
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const minDate = DateTime.now().startOf('day').toISO().slice(0, 16);

    return (
        <div className="sidebar-form">
            <AudienceTopicSelect />

            <FormGroup
                label="Voor welk merk is de uiting?*"
                htmlFor="brands"
                help="Welk(e) merk(en) zijn betrokken bij deze uiting?"
            >
                <MultipleSelect
                    options={brands}
                    name="brands"
                    props={{
                        placeholder: 'Selecteer merken...',
                    }}
                />
            </FormGroup>

            <FormGroup
                label="Expliciete toestemming"
                htmlFor="explicitConsent"
                help="Vraagt de uiting om uitdrukkelijke toestemming voor het gebruik van bijzondere persoonsgegevens?"
            >
                <CheckBox name="explicitConsent" label="De uiting vraagt om uitdrukkelijke toestemming" />
            </FormGroup>

            <FormGroup label="Classificatie" htmlFor="classification" help="">
                <CheckBox name="confidential" label="Onder embargo" />
            </FormGroup>

            <FormGroup label="Startdatum*" htmlFor="startDate" help="">
                <InputField
                    name="startDate"
                    props={{
                        required: active,
                        id: 'startDate',
                        type: 'datetime-local',
                        min: active ? minDate : undefined,
                    }}
                />
            </FormGroup>

            <FormGroup label="Deadline OKÉ-check*" htmlFor="deadline" help="">
                <InputField
                    name="deadline"
                    props={{
                        required: active,
                        id: 'deadline',
                        type: 'datetime-local',
                        min: active ? minDate : undefined,
                    }}
                />
            </FormGroup>

            <FormGroup label="Geplande publicatiedatum" htmlFor="publicationDate" help="">
                <InputField
                    name="publicationDate"
                    props={{
                        id: 'publicationDate',
                        type: 'datetime-local',
                        min: active ? minDate : undefined,
                    }}
                />
            </FormGroup>

            <FormGroup
                label="Doel van de uiting*"
                htmlFor="description"
                help="Geef een korte omschrijving van het doel van de uiting"
            >
                <TextArea
                    name="description"
                    props={{
                        required: active,
                        placeholder: 'Doel van de uiting...',
                    }}
                />
            </FormGroup>

            <FormGroup label="Klantreis*" htmlFor="customerJourney" help="">
                <InputSelect
                    name="customerJourney"
                    options={HelperFunctions.prepareDropdownData(customerJourneys, 'name', '@id')}
                    props={{
                        placeholder: 'Selecteer klantreis...',
                    }}
                />
            </FormGroup>

            <FormGroup label="Type uiting*" htmlFor="taskType" help="">
                <InputSelect
                    name="taskType"
                    options={HelperFunctions.prepareDropdownData(taskTypes, 'name', '@id')}
                    props={{
                        placeholder: 'Selecteer type uiting...',
                    }}
                />
            </FormGroup>
        </div>
    );
}

export function AudienceTopicSelect() {
    const { values } = useFormikContext();
    const topics = useGetTopics();
    const targetAudiences = useGetTargetAudiences();
    const teamRules = useGetAllTeamRules();

    const availableTargetAudiences = getAvailableTargetAudiences();
    const availableTopics = getAvailableTopics();

    return (
        <>
            <FormGroup
                label="Wat is de doelgroep?*"
                htmlFor="targetAudiences"
                help="Geef een doelgroep op die betrekking heeft op deze uiting."
            >
                <MultipleSelect
                    options={availableTargetAudiences}
                    name="targetAudiences"
                    maxLimit={1}
                    props={{
                        placeholder: 'Selecteer doelgroep...',
                    }}
                />
            </FormGroup>

            <FormGroup
                label="Waarover gaat de uiting?*"
                htmlFor="topics"
                help="Geef één of meerdere onderwerpen op waar deze uiting over gaat."
            >
                <MultipleSelect
                    options={availableTopics}
                    name="topics"
                    props={{
                        placeholder: 'Selecteer onderwerpen...',
                    }}
                />
            </FormGroup>
        </>
    );

    function getAvailableTargetAudiences() {
        const availableTargetAudienceUris = teamRules.reduce((acc, teamRule) => {
            teamRule.targetAudiences.forEach((uri) => {
                if (acc.includes(uri) === false) {
                    acc.push(uri);
                }
            });

            return acc;
        }, []);

        return targetAudiences.filter((targetAudience) => availableTargetAudienceUris.includes(targetAudience['@id']));
    }

    function getAvailableTopics() {
        const teamRulesFromSelectedTargetAudiences = teamRules.filter(
            (teamRule) =>
                values.targetAudiences.length > 0 &&
                _.intersection(teamRule.targetAudiences, values.targetAudiences).length > 0,
        );

        const availableTopicUris = teamRulesFromSelectedTargetAudiences.reduce((acc, teamRule) => {
            if (acc.includes(teamRule.topic) === false) {
                acc.push(teamRule.topic);
            }

            return acc;
        }, []);

        return topics.filter((topic) => availableTopicUris.includes(topic['@id']));
    }
}
