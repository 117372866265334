import { useGetAllTeamRulesQuery, useGetTeamRulesQuery } from 'features/comments/commentApi';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';

const emptyArray = [];

export function useGetTeamRules(teamId) {
    const { teamRules } = useGetTeamRulesQuery(teamId, {
        selectFromResult: ({ currentData }) => ({
            teamRules: currentData ?? emptyArray,
        }),
        skip: teamId === undefined,
    });

    return teamRules;
}

export function useGetAllTeamRules() {
    const organisationId = useActiveOrganisation();

    const { teamRules } = useGetAllTeamRulesQuery(
        { params: { organisationId } },
        {
            selectFromResult: ({ currentData }) => ({
                teamRules: currentData ?? emptyArray,
            }),
        },
    );

    return teamRules;
}
