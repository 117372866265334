import HelperFunctions from '../../global/HelperFunctions';
import { getNames, registerLocale, getName } from '@cospired/i18n-iso-languages';

const appLocale = 'nl';
import locale from '@cospired/i18n-iso-languages/langs/nl.json';

registerLocale(locale);

export function getLanguageOptions() {
    const allLanguagesAsObject = getNames(appLocale);

    return Object.keys(allLanguagesAsObject)
        .map((key) => {
            return {
                value: key,
                label: allLanguagesAsObject[key],
            };
        })
        .sort(HelperFunctions.sortByString('label'));
}

export function getLanguageName(languageIso: string) {
    return getName(languageIso, appLocale);
}
