import { Route } from 'react-router-dom';
import { ReactTitle } from '../components/ReactTitle';
import { Settings } from '../pages/account/views/Settings';

export const BASE_PATH = '/accounts';
export const SETTINGS_PATH = `${BASE_PATH}/:view/:action?/:id?`;

const Account = () => {
    return (
        <>
            <ReactTitle title="Account" />

            <Route path={SETTINGS_PATH} render={() => <Settings />} />
        </>
    );
};

export default Account;
