import { StringParam, useQueryParam } from 'use-query-params';
import { useGetTeamRuleQuery } from 'features/comments/commentApi';
import { AddModalInner } from 'pages/task_team/modals/AddTeamRuleModal';

export function EditTeamRuleModal({ team }) {
    const [editTeamRuleId, setEditTeamRule] = useQueryParam('editTeamRule', StringParam);

    const { teamRule } = useGetTeamRuleQuery(editTeamRuleId, {
        selectFromResult: (result) => ({
            teamRule: result.currentData,
        }),
        skip: editTeamRuleId === undefined,
    });

    if (!editTeamRuleId || !teamRule) {
        return null;
    }

    return <AddModalInner initialValues={teamRule} team={team} close={close} />;

    function close() {
        setEditTeamRule(undefined);
    }
}
