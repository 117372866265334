import { useState } from 'react';
import HelperFunctions from '../../../global/HelperFunctions';
import { Modal } from 'react-bootstrap';
import Spinner from '../../../global/Spinner';
import { useGetDocument } from 'pages/documents_v2/hooks/useGetDocument';
import { Select } from 'components/Select';
import { PrimaryButton, SecondaryButton } from 'components/Buttons';
import { useTranslation } from 'react-i18next';
import { documentApi, useUpdateAreaSortOrderMutation } from 'features/documents/documents';
import { useDispatch } from 'react-redux';

export function MoveAreaModal({ area, closeModal }) {
    const [updateAreaSortOrder] = useUpdateAreaSortOrderMutation();
    const document = useGetDocument(undefined, true);
    const [selectedArea, setSelectedArea] = useState('');
    const [saveInProgress, setSaveInProgress] = useState(false);
    const { t } = useTranslation('documents');
    const dispatch = useDispatch();

    const documentSections = document?.sections ?? [];
    const sections = [...documentSections].sort(HelperFunctions.dynamicSort('sortOrder'));

    const options = sections.map((section) => {
        const sectionAreas = section.areas ?? [];
        const areas = [...sectionAreas].sort(HelperFunctions.dynamicSort('sortOrder'));

        return {
            label: section.title,
            options: areas.map((_area) => {
                return {
                    label: _area.title,
                    value: _area.id,
                    isDisabled: _area.id === area.id,
                };
            }),
        };
    });

    return (
        <Modal show={true} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {t('document.navbar.main.reviewDocument.moveAreaModal.moveArticleOne', { name: area.title })}
                    &hellip;
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Select id="area-select" isMulti={false} options={options} handleChange={handleFormChange} />
            </Modal.Body>

            <Modal.Footer>
                {saveInProgress && <Spinner />}
                <SecondaryButton onClick={closeModal} disabled={saveInProgress}>
                    {t('btn.close')}
                </SecondaryButton>
                <PrimaryButton disabled={selectedArea === '' || saveInProgress} onClick={handleFormSubmit}>
                    {t('btn.save')}
                </PrimaryButton>
            </Modal.Footer>
        </Modal>
    );

    function handleFormChange(selectedOption) {
        setSelectedArea(selectedOption.value);
    }

    function findTargetAreaSection(areaId) {
        let target = undefined;

        sections.forEach((section) => {
            section.areas.forEach((_area) => {
                if (target === undefined && _area.id === areaId) {
                    target = [_area, section];
                }
            });
        });

        return target;
    }

    async function handleFormSubmit() {
        setSaveInProgress(true);

        // Get the Area
        const [sourceArea, sourceSection] = findTargetAreaSection(area.id);
        const [targetArea, targetSection] = findTargetAreaSection(selectedArea);

        if (targetSection.id === area.sectionId) {
            // Move within Section
            const areas = [...targetSection.areas].sort(HelperFunctions.dynamicSort('sortOrder'));

            const sourceIndex = areas.findIndex((_area) => _area.id === area.id);
            let targetIndex = areas.findIndex((_area) => _area.id === selectedArea);

            if (targetIndex < sourceIndex) {
                targetIndex++;
            }

            const areasReordered = HelperFunctions.arrayMove(areas, sourceIndex, targetIndex)
                .map((_area, index) => {
                    return {
                        ..._area,
                        oldSortOrder: _area.sortOrder,
                        sortOrder: index + 1,
                    };
                })
                .filter((_area) => _area.sortOrder !== _area.oldSortOrder);

            await updateSortOrder(areasReordered);
        } else {
            // Move to new Section
            const sourceAreas = [...sourceSection.areas].sort(HelperFunctions.dynamicSort('sortOrder'));
            const destinationAreas = [...targetSection.areas].sort(HelperFunctions.dynamicSort('sortOrder'));

            // Remove from old Section
            const sourceIndex = sourceAreas.findIndex((_area) => _area.id === area.id);
            const [removed] = sourceAreas.splice(sourceIndex, 1);

            let updatedArea = {
                ...removed,
                sectionId: targetSection.id,
            };

            // Update source Areas
            const areasReordered = sourceAreas
                .map((_area, index) => {
                    return {
                        ..._area,
                        oldSortOrder: _area.sortOrder,
                        sortOrder: index + 1,
                    };
                })
                .filter((_area) => _area.sortOrder !== _area.oldSortOrder);

            // Insert into destination group
            const targetIndex = destinationAreas.findIndex((_area) => _area.id === selectedArea);
            destinationAreas.splice(targetIndex + 1, 0, updatedArea);

            const destinationAreasReordered = destinationAreas
                .map((_area, index) => {
                    if (updatedArea.id === _area.id) {
                        updatedArea.sortOrder = index + 1;
                    }

                    return {
                        ..._area,
                        oldSortOrder: _area.sortOrder,
                        sortOrder: index + 1,
                        sectionId: targetSection.id,
                    };
                })
                .filter((_area) => _area.sortOrder !== _area.oldSortOrder || _area.id === area.id);

            const combined = [...areasReordered, ...destinationAreasReordered];

            await updateSortOrder(combined);
            invalidateTags(targetSection.id);
        }

        closeModal();
    }

    function updateSortOrder(payload) {
        const body = payload.map((area) => ({
            id: area.id,
            sortOrder: area.sortOrder,
            section: area.sectionId,
        }));

        return updateAreaSortOrder({ sectionId: area.sectionId, body });
    }

    function invalidateTags(sectionId) {
        dispatch(documentApi.util.invalidateTags([{ type: 'Section', id: sectionId }]));
    }
}
