import { generatePath, useHistory, useParams } from 'react-router-dom';
import { CollectionFill, PlusCircle, ThreeDotsVertical } from 'react-bootstrap-icons';
import { COLLECTIVES_PATH } from '../../../../scenes/Reimbursements';
import { Button, Dropdown, Nav } from 'react-bootstrap';
import React, { useState } from 'react';
import HelperFunctions from '../../../global/HelperFunctions';
import cx from 'classnames';
import DropdownMenu from '../../../global/DropdownMenu';
import {
    reimbursementApi,
    useDeleteCollectiveGroupMutation,
    useGetCollectiveGroupsQuery,
    useUpdateBatchReimbursementsMutation,
    useUpdateCollectiveGroupMutation,
} from '../../../../features/reimbursements/reimbursements';
import EditCollectiveGroupModal from '../modals/EditCollectiveGroupModal';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { RxDragHandleDots2 } from 'react-icons/rx';
import { useTranslation } from 'react-i18next';

const emptyArray = [];

export default function Sidebar({ categoryUri }) {
    const history = useHistory();
    const { categoryId, folderId } = useParams();
    const [showEditCollectiveGroupModal, setShowEditCollectiveGroupModal] = useState(false);
    const [updateCollectiveGroup] = useUpdateCollectiveGroupMutation();
    const [updateBatchReimbursements] = useUpdateBatchReimbursementsMutation();
    const dispatch = useDispatch();
    const { t } = useTranslation('reimbursements');

    const { collectiveGroups, allGroups } = useGetCollectiveGroupsQuery(categoryUri, {
        selectFromResult: ({ data }) => ({
            collectiveGroups: data
                ? data.filter((_item) => _item.parent === null).sort(HelperFunctions.dynamicSort('sortOrder'))
                : emptyArray,
            allGroups: data,
        }),
    });

    const handleMove = (direction, group, index, groups) => {
        if (direction === 'up' && index === 0) {
            return;
        }

        const destinationIndex = direction === 'up' ? index - 1 : index + 1;

        const newGroups = HelperFunctions.arrayMove(groups, index, destinationIndex).map((_item, sortOrder) => ({
            ..._item,
            sortOrder,
        }));

        newGroups.forEach((_group) => {
            const currentGroup = groups.find((_item) => _item['@id'] === _group['@id']);

            if (currentGroup.sortOrder !== _group.sortOrder) {
                updateCollectiveGroup({
                    uri: _group['@id'],
                    sortOrder: _group.sortOrder,
                });
            }
        });
    };

    const onDragEnd = (result) => {
        const { destination, source } = result;

        if (!destination) {
            // dropped outside the list
            return;
        }

        const sourceGroup = source.droppableId;
        const destinationGroup = destination.droppableId;

        // Sort within group
        if (sourceGroup === destinationGroup) {
            if (source.index === destination.index) {
                // Sort order not changed
                return;
            }

            const oldGroups = allGroups
                .filter((_group) => _group.parent === sourceGroup)
                .sort(HelperFunctions.dynamicSort('sortOrder'));
            const newGroups = HelperFunctions.arrayMove(oldGroups, source.index, destination.index).map(
                (_collective, index) => {
                    // Reset sort order
                    return {
                        ..._collective,
                        sortOrder: index,
                    };
                }
            );

            updateBatch(sourceGroup, newGroups);

            return;
        }

        // Moved into new group

        // Remove from old group
        const newGroups1 = allGroups
            .filter((_group) => _group.parent === sourceGroup)
            .sort(HelperFunctions.dynamicSort('sortOrder'));
        const [removed] = newGroups1.splice(source.index, 1);

        updateBatch(sourceGroup, newGroups1);

        // Add to new group
        const newGroup = {
            ...removed,
            parent: destinationGroup,
        };

        const oldGroups2 = allGroups
            .filter((_group) => _group.parent === destinationGroup)
            .sort(HelperFunctions.dynamicSort('sortOrder'));
        oldGroups2.splice(destination.index, 0, newGroup);

        const newGroups2 = oldGroups2.map((_group, index) => {
            // Reset sort order
            return {
                ..._group,
                sortOrder: index,
            };
        });

        updateBatch(destinationGroup, newGroups2).then(() => {
            dispatch(reimbursementApi.util.invalidateTags([{ type: 'CollectiveGroup', id: 'LIST' }]));
        });
    };

    const updateBatch = (groupUri, newGroups) => {
        return updateBatchReimbursements({
            uri: categoryUri,
            collectiveGroups: newGroups.map((_group) => {
                return {
                    idAsInt: _group.id,
                    id: _group['@id'],
                    changes: {
                        sortOrder: _group.sortOrder,
                        parent: _group.parent,
                    },
                };
            }),
        });
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <div className="pt-4">
                <Nav className="folder-nav flex-column mb-3">
                    <Nav.Link
                        className="d-flex align-items-center"
                        active={folderId === undefined}
                        onClick={() => {
                            history.push(generatePath(COLLECTIVES_PATH, { categoryId }));
                        }}
                        style={{
                            marginLeft: '9px',
                        }}
                    >
                        <div className="font-weight-bolder">
                            <CollectionFill size={14} className="mr-2" />
                            {t('collectivity.sidebar.all')}
                        </div>

                        <Button
                            variant="link"
                            className="ml-auto text-body p-0"
                            onClick={(event) => {
                                event.stopPropagation();

                                setShowEditCollectiveGroupModal({
                                    sortOrder:
                                        collectiveGroups.length > 0
                                            ? collectiveGroups[collectiveGroups.length - 1].sortOrder + 1
                                            : 0,
                                    category: categoryUri,
                                    name: '',
                                });
                            }}
                            data-uk-tooltip={`${t('collectivity.sidebar.tooltip.newFolder')}...`}
                        >
                            <PlusCircle size={15} />
                        </Button>
                    </Nav.Link>
                </Nav>

                {collectiveGroups.map((_group, index) => (
                    <RootGroup
                        group={_group}
                        setShowEditCollectiveGroupModal={setShowEditCollectiveGroupModal}
                        index={index}
                        isLast={collectiveGroups.length - 1 === index}
                        handleMove={(direction, group, index) => handleMove(direction, group, index, collectiveGroups)}
                        handleMoveFunction={handleMove}
                        key={`sidebar-group-${_group.id}`}
                    />
                ))}

                {!!showEditCollectiveGroupModal && (
                    <EditCollectiveGroupModal
                        formData={showEditCollectiveGroupModal}
                        handleClose={() => setShowEditCollectiveGroupModal(false)}
                    />
                )}
            </div>
        </DragDropContext>
    );
}

function RootGroup({ group, setShowEditCollectiveGroupModal, index, isLast, handleMove, handleMoveFunction }) {
    const { childGroups } = useGetCollectiveGroupsQuery(group.category, {
        selectFromResult: ({ data }) => ({
            childGroups: data
                ? data.filter((_item) => _item.parent === group['@id']).sort(HelperFunctions.dynamicSort('sortOrder'))
                : [],
        }),
    });

    return (
        <div className="mb-3">
            <div
                className="nav-dropdown-hover d-flex align-items-center mb-1"
                style={{
                    padding: '0.6rem 1.15rem 0.4rem 1.5rem',
                    margin: '0 1px',
                }}
            >
                <div className="font-weight-bold cursor-pointer">
                    <span className="sidebar-titles">{group.name}</span>
                </div>

                <div className="ml-auto mr-1">
                    <GroupActions
                        setShowModal={setShowEditCollectiveGroupModal}
                        folder={group}
                        groups={childGroups}
                        index={index}
                        isLast={isLast}
                        handleMove={handleMove}
                    >
                        <DropDownToggle folderId={group.id} />
                    </GroupActions>
                </div>
            </div>

            <Droppable droppableId={group['@id']} type="GROUP">
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        style={{
                            minHeight: 45,
                        }}
                        className="mx-2"
                        {...provided.droppableProps}
                    >
                        <Nav
                            className={cx('folder-nav flex-column', {
                                'dragging-over': snapshot.isDraggingOver,
                            })}
                        >
                            {childGroups.map((group, childIndex) => (
                                <Group
                                    folder={group}
                                    setShowModal={setShowEditCollectiveGroupModal}
                                    index={childIndex}
                                    isLast={childGroups.length - 1 === childIndex}
                                    handleMove={(direction, group, index) =>
                                        handleMoveFunction(direction, group, index, childGroups)
                                    }
                                    key={`sidebar-group-${group.id}`}
                                />
                            ))}

                            {provided.placeholder}
                        </Nav>
                    </div>
                )}
            </Droppable>
        </div>
    );
}

function Group({ folder, setShowModal, index, isLast, handleMove }) {
    const history = useHistory();
    const { folderId, categoryId } = useParams();
    const isActive = parseInt(folderId) === folder.id;

    return (
        <Draggable draggableId={folder['@id']} index={index}>
            {(provided) => (
                <Nav.Link
                    as="div"
                    className="nav-dropdown-hover d-flex align-items-center"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={provided.draggableProps.style}
                    active={isActive}
                    onClick={() => {
                        if (isActive === false) {
                            history.push(
                                generatePath(COLLECTIVES_PATH, {
                                    categoryId,
                                    folderId: folder.id,
                                })
                            );
                        }
                    }}
                >
                    <RxDragHandleDots2 size={12} className="icon-grip text-muted" />
                    <div>{folder.name}</div>

                    <div className="d-flex align-items-center ml-auto">
                        <GroupActions
                            setShowModal={setShowModal}
                            folder={folder}
                            index={index}
                            isLast={isLast}
                            handleMove={handleMove}
                        >
                            <DropDownToggle folderId={folder.id} />
                        </GroupActions>
                    </div>
                </Nav.Link>
            )}
        </Draggable>
    );
}

function GroupActions({ setShowModal, folder, groups = [], handleMove, index, isLast = false, children }) {
    const [dropdownIsOpen, setDropDownOpen] = useState(false);
    const showMoveItems = typeof handleMove === 'function';
    const { t } = useTranslation('reimbursements');

    const { categoryId } = useParams();
    const history = useHistory();
    const [deleteCollectiveGroup] = useDeleteCollectiveGroupMutation();

    const handleSelect = (eventKey) => {
        switch (eventKey) {
            case 'insert':
                setShowModal({
                    parent: folder['@id'],
                    sortOrder: groups.length > 0 ? groups[groups.length - 1].sortOrder + 1 : 0,
                    category: folder.category,
                    name: '',
                });
                break;
            case 'edit':
                setShowModal(folder);
                break;
            case 'moveUp':
                handleMove('up', folder, index);
                break;
            case 'moveDown':
                handleMove('down', folder, index);
                break;
            case 'delete':
                HelperFunctions.confirmModal(
                    `${t('collectivity.sidebar.delete', { name: folder.name })}`,
                    'danger',
                    false,
                    t('collectivity.sidebar.btn.yesDelete'),
                    t('btn.cancel')
                ).then(() => {
                    deleteCollectiveGroup(folder['@id']).then(() => {
                        // Redirect to main page
                        history.push(
                            generatePath(COLLECTIVES_PATH, {
                                categoryId,
                            })
                        );
                    });
                });
                break;
        }
    };

    const canDelete = () => {
        return folder.collectives.length === 0 && folder.children.length === 0;
    };

    return (
        <Dropdown
            className={cx('d-flex align-items-center cursor-pointer', {
                'dropdown-open': dropdownIsOpen,
            })}
            onSelect={handleSelect}
            onToggle={(isOpen) => {
                setDropDownOpen(isOpen);
            }}
        >
            {children}

            <DropdownMenu>
                {folder.parent === null && (
                    <Dropdown.Item eventKey="insert" onClick={(event) => event.stopPropagation()}>
                        {t('collectivity.sidebar.dropdown.createSubfolder')}&hellip;
                    </Dropdown.Item>
                )}

                <Dropdown.Item eventKey="edit" onClick={(event) => event.stopPropagation()}>
                    {t('collectivity.sidebar.dropdown.changeName')}&hellip;
                </Dropdown.Item>

                {showMoveItems && (
                    <>
                        <Dropdown.Item eventKey="moveUp" disabled={index === 0}>
                            {t('collectivity.sidebar.dropdown.moveUp')}
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="moveDown" disabled={isLast}>
                            {t('collectivity.sidebar.dropdown.moveDown')}
                        </Dropdown.Item>
                    </>
                )}

                <Dropdown.Divider />

                <Dropdown.Item eventKey="delete" disabled={!canDelete()}>
                    <span className={canDelete() ? 'text-danger' : 'text-muted'}>
                        {t('collectivity.sidebar.dropdown.delete')}&hellip;
                    </span>
                </Dropdown.Item>
            </DropdownMenu>
        </Dropdown>
    );
}

function DropDownToggle({ folderId }) {
    return (
        <Dropdown.Toggle
            bsPrefix="docrev-dropdown-toggle"
            as={ThreeDotsVertical}
            size={16}
            id={`folder-${folderId}-dropdown`}
        />
    );
}
