import { Modal } from 'react-bootstrap';
import { FormFieldWithTitle, InputCreatableSelect } from 'pages/publications_v2/helpers/FieldHelper';
import { MultiSelect } from 'components/Select';
import { ModalFooter } from 'pages/global/FormModal';
import { Form, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useGetPublicationUsers } from 'pages/publications_v2/hooks/publicationUsers';
import HelperFunctions from 'pages/global/HelperFunctions';

export function GroupForm({ handleClose }) {
    const { t } = useTranslation();
    const { values, isValid, dirty, isSubmitting, setFieldValue } = useFormikContext();
    const publicationUsers = useGetPublicationUsers();

    // const allowedEmailDomainsOptions = values.allowedEmailDomains.map((domain) => ({
    //     label: domain,
    //     value: domain,
    // }));

    const publicationUsersOptions = getPublicationUsersOptions();

    // const publicationUsersDefaultValue = publicationUsersOptions.filter((option) => {
    //     return values.publicationUsers?.includes(option.value);
    // });

    console.log(values);

    return (
        <Form>
            <Modal.Body>
                <FormFieldWithTitle
                    label={t('publications:accessControl.modal.GrpName')}
                    name="name"
                    type="text"
                    props={{ required: true }}
                />

                <div className="mb-3">
                    <InputCreatableSelect
                        label={t('publications:accessControl.modal.allowedEmailDomains')}
                        id="allowedEmailDomains"
                        options={values.allowedEmailDomains}
                        value={values.allowedEmailDomains}
                        onChange={(selectedOptions) =>
                            setFieldValue(
                                'allowedEmailDomains',
                                selectedOptions.map((option) => ({
                                    label: option,
                                    value: option,
                                }))
                            )
                        }
                    />
                </div>

                <div>
                    <MultiSelect
                        label={t('publications:accessControl.users')}
                        id="publicationUsers"
                        options={publicationUsersOptions}
                        defaultValue={values.publicationUsers}
                        handleChange={(selectedOptions) => setFieldValue('publicationUsers', selectedOptions)}
                    />
                </div>
            </Modal.Body>

            <ModalFooter onHide={handleClose} isSubmitting={isSubmitting} dirty={dirty} isValid={isValid} />
        </Form>
    );

    function getPublicationUsersOptions() {
        const publicationUsersOptions = publicationUsers.map((user) => ({
            label: `${user.name} ${user.surname}`,
            email: user.email,
            value: user['@id'],
        }));

        if (values.allowedEmailDomains.length > 0) {
            // Filter out users with email
            return publicationUsersOptions.filter((option) => {
                return values.allowedEmailDomains.some((domainOption) => {
                    return option.email.endsWith(domainOption.value);
                });
            });
        }

        return publicationUsersOptions;
    }
}
