import { entityTypes } from 'pages/translation/config/Constants';
import { EditChapter } from './EditChapter';
import { useSidebarContext } from '../hooks/useSidebarContext';
import { Blocks } from './Block/Blocks';
import { useGetDocument } from 'pages/documents_v2/hooks/useGetDocument';
import { useEntityTemplateParameters } from 'pages/documents_v2/hooks/useEntityTemplateParameters';
import { Team } from 'components/SettingsSidebar/views/Tasks/Team';
import { TeamUser } from 'components/SettingsSidebar/views/Tasks/TeamUser';
import { Department } from 'components/SettingsSidebar/views/Tasks/Department';
import { CustomerJourney } from 'components/SettingsSidebar/views/Tasks/CustomerJourney';
import { TaskType } from 'components/SettingsSidebar/views/Tasks/TaskType';
import { Brand } from 'components/SettingsSidebar/views/Tasks/Brand';
import { TargetAudience } from 'components/SettingsSidebar/views/Tasks/TargetAudience';
import { Topic } from 'components/SettingsSidebar/views/Tasks/Topic';
import { RejectionReason } from 'components/SettingsSidebar/views/Tasks/RejectionReason';
import { Category } from 'components/SettingsSidebar/views/OneLiners/Category';
import { OneLiner } from 'components/SettingsSidebar/views/OneLiners/OneLiner';

export function EditSettings() {
    const { entity, entityType, documentId } = useSidebarContext();
    const document = useGetDocument(documentId);
    const restrictions = useEntityTemplateParameters(entity, entityType, document);

    if (entityType === entityTypes.SECTION || entityType === entityTypes.AREA) {
        return <EditChapter />;
    }

    if (entityType === entityTypes.BLOCK) {
        return <Blocks restrictions={restrictions} />;
    }

    if (entityType === entityTypes.TEAM) {
        return <Team />;
    }

    if (entityType === entityTypes.TEAM_USER) {
        return <TeamUser />;
    }

    if (entityType === entityTypes.DEPARTMENT) {
        return <Department />;
    }

    if (entityType === entityTypes.CUSTOMER_JOURNEY) {
        return <CustomerJourney />;
    }

    if (entityType === entityTypes.TASK_TYPE) {
        return <TaskType />;
    }

    if (entityType === entityTypes.BRAND) {
        return <Brand />;
    }

    if (entityType === entityTypes.TOPIC) {
        return <Topic />;
    }

    if (entityType === entityTypes.TARGET_AUDIENCE) {
        return <TargetAudience />;
    }

    if (entityType === entityTypes.REJECTION_REASON) {
        return <RejectionReason />;
    }

    if (entityType === entityTypes.ONE_LINER_CATEGORY) {
        return <Category />;
    }

    if (entityType === entityTypes.ONE_LINER) {
        return <OneLiner />;
    }

    return null;
}
