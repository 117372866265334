import React from 'react';
import {useDroppable} from '@dnd-kit/core';

export const TYPE_DROPPABLE = 'droppable';

export default function Droppable(props) {
    const {id, isDragging} = props
    const {isOver, setNodeRef} = useDroppable({
        id, data: {type: TYPE_DROPPABLE}
    });

    return (
        <div ref={setNodeRef} style={getListStyle(isOver, isDragging)}>
            {props.children}
        </div>
    );
}

const getListStyle = (isDraggingOver, isDragging) => ({
    background: isDraggingOver ? '#00e67614' : (isDragging ? '#fafafa' : undefined),
    borderColor: isDraggingOver ? '#00e676' : (isDragging ? '#eeeeee' : '#fff'),
    borderWidth: 2,
    borderStyle: 'dashed',
    minHeight: isDragging ? 150 : undefined,
});
