import { StringParam, useQueryParam } from 'use-query-params';
import { useGetChecklistQuery } from 'features/comments/commentApi';
import { AddModalInner } from 'pages/task_team/modals/AddChecklistModal';

export function EditChecklistModal({ team }) {
    const [editChecklistId, setEditChecklist] = useQueryParam('editChecklist', StringParam);

    const { checklist } = useGetChecklistQuery(editChecklistId, {
        selectFromResult: (result) => ({
            checklist: result.data,
        }),
        skip: editChecklistId === undefined,
    });

    if (!editChecklistId || !checklist) {
        return null;
    }

    return <AddModalInner checklist={checklist} team={team} close={close} />;

    function close() {
        setEditChecklist(undefined);
    }
}
