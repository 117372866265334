import { useGetDocument } from '../../hooks/useGetDocument';
import MainContentNav from '../../../Navbar';
import { BASE_PATH } from 'scenes/DocumentsV2';
import { SubNav } from '../SubNav';
import DocumentSidebar from '../DocumentSidebar/Sidebar';
import RestrictedDocumentContent from '../../RestrictedDocumentContent';
import Constants from '../../../../config/Constants';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGetDocumentVariants } from '../../hooks/useGetDocumentVariants';
import { FiAlertTriangle } from 'react-icons/fi';
import LoadingSpinner from '../../../global/LoadingSpinner';
import HelperFunctions from '../../../global/HelperFunctions';
import { Section } from './Section';
import { useGetBaseVariant } from '../../hooks/useGetBaseVariant';
import { AddNewItem } from './AddNewItem';
import { NumberParam, useQueryParam } from 'use-query-params';
import { useState } from 'react';
import { MoveAreaModal } from 'pages/documents/misc/_ReviewDocument/MoveAreaModal';
import { LibraryImportModal } from 'pages/documents_v2/modals/LibraryImportModal';
import AddAreaFromTemplate from '../../../documents/misc/_ReviewDocument/AddAreaFromTemplate';
import AddAreaFromParent from '../../../documents/misc/_ReviewDocument/AddAreaFromParent';

export function View() {
    const { documentId } = useParams();

    return (
        <>
            <ViewContentNav />
            <SubNav />

            <div className="content-static-body has-subnav">
                <div className="d-flex align-items-stretch h-100">
                    <div className="overflow-auto w-100">
                        <div className="mt-5 mx-5 d-flex">
                            <div className="content-sidebar-document-1 bg-light" style={{ marginLeft: '-19px' }}>
                                <DocumentSidebar />
                            </div>

                            <div className="w-100">
                                <div className="dr-container p-5 mb-4">
                                    <RestrictedDocumentContent
                                        documentId={documentId}
                                        roles={Object.values(Constants.userDocumentRole)}
                                    >
                                        <ViewInner />
                                    </RestrictedDocumentContent>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function ViewContentNav() {
    const document = useGetDocument();
    const { t } = useTranslation('documents');

    return (
        <MainContentNav
            pages={[{ title: t('document.navbar.main.mainContentNav'), url: BASE_PATH }, { title: document?.name }]}
        />
    );
}

function ViewInner() {
    const { t } = useTranslation('documents');
    const [sid] = useQueryParam('sid', NumberParam);
    const [areaToMove, setAreaToMove] = useState(undefined);
    const [libraryImport, setLibraryImport] = useState(false);
    const [addAreaFromTemplateModal, setAddAreaFromTemplateModal] = useState(false);
    const [addAreaFromParentModal, setAddAreaFromParentModal] = useState(false);
    const document = useGetDocument();

    const variants = useGetDocumentVariants();
    const baseVariant = useGetBaseVariant();

    if (!baseVariant || !document || document.hasOwnProperty('sections') === false) {
        return <LoadingSpinner />;
    }

    const sections = getSections();
    const expandByDefault = sections.length === 1 && sid === undefined;
    const hasVariants = variants.length > 1;
    const documentType = getDocumentType();

    return (
        <>
            <div className="d-flex justify-content-between">
                <div className="small mb-5 mt-2">
                    {documentType}: {document.name}
                </div>

                {!(
                    document.type === Constants.documentType.library || document.type === Constants.documentType.model
                ) &&
                    !hasVariants && (
                        <div className="badge badge-warning align-self-start d-none d-md-none d-xl-block">
                            <span className="uk-icon mr-2">
                                <FiAlertTriangle size={20} />
                            </span>
                            {t('document.navbar.main.tooltips.noVariantsCreated')}
                        </div>
                    )}
            </div>

            <div className="pb-1">
                {sections.map((section) => (
                    <Section
                        section={section}
                        toggleMoveModal={toggleMoveModal}
                        setLibraryImport={setLibraryImport}
                        setAddAreaFromTemplateModal={setAddAreaFromTemplateModal}
                        setAddAreaFromParentModal={setAddAreaFromParentModal}
                        expandByDefault={expandByDefault}
                        key={`section-${section.id}`}
                    />
                ))}
            </div>

            {sections.length === 0 && (
                <>
                    {document.status === Constants.documentStatus.reviewFinished && document.activeRevision !== null ? (
                        <p className="uk-margin-large-top uk-text-large uk-text-center uk-text-muted">
                            {t('document.navbar.main.titles.noRevision')}
                        </p>
                    ) : (
                        <p className="uk-margin-medium-top uk-text-muted">
                            {t('document.navbar.main.titles.noContent')}
                        </p>
                    )}
                </>
            )}

            {/**
             * here lies the new add template button
             */}
            {document.status !== Constants.documentStatus.reviewFinished && (
                <AddNewItem document={document} requiredRoles={{ not: [Constants.userDocumentRole.spectator] }} />
            )}

            {areaToMove !== undefined && (
                <MoveAreaModal area={areaToMove} closeModal={() => setAreaToMove(undefined)} />
            )}

            {!!libraryImport && (
                <LibraryImportModal
                    libraryImportData={libraryImport}
                    handleClose={() => {
                        setLibraryImport(false);
                    }}
                />
            )}

            {!!addAreaFromTemplateModal && (
                <AddAreaFromTemplate
                    payload={addAreaFromTemplateModal}
                    handleClose={() => {
                        setAddAreaFromTemplateModal(false);
                    }}
                />
            )}

            {!!addAreaFromParentModal && (
                <AddAreaFromParent
                    document={document}
                    payload={addAreaFromParentModal}
                    handleClose={() => {
                        setAddAreaFromParentModal(false);
                    }}
                />
            )}
        </>
    );

    function getSections() {
        return document.sections
            .filter((section) => {
                // Hide deleted Sections when no active revision
                return !(document.activeRevisionId === null && section.deletedAt !== null);
            })
            .sort(HelperFunctions.dynamicSort('sortOrder'));
    }

    function getDocumentType() {
        if (document.type === Constants.documentType.default) {
            return t('document.navbar.main.titles.documentType.standard');
        } else if (document.type === Constants.documentType.model) {
            return t('document.navbar.main.titles.documentType.template');
        } else if (document.type === Constants.documentType.library) {
            return t('document.navbar.main.titles.documentType.library');
        } else if (document.type === Constants.documentType.terms_conditions) {
            return t('document.navbar.main.titles.documentType.terms');
        }

        return 'Document';
    }

    function toggleMoveModal(area) {
        setAreaToMove(area);
    }
}
