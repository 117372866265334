import { useTranslation } from 'react-i18next';
import { useGetUserGroups } from 'pages/publications_v2/hooks/publicationUsers';
import { Form, Formik } from 'formik';
import { Modal } from 'react-bootstrap';
import { Checkbox, FormFieldWithTitle } from 'pages/publications_v2/helpers/FieldHelper';
import { MultiSelect } from 'components/Select';
import { ModalFooter } from 'pages/global/FormModal';
import { useMemo } from 'react';
import _ from 'lodash';
import * as Yup from 'yup';

export function UserForm({ initialValues, onSubmit, onClose, defaultValue = null }) {
    const { t } = useTranslation();
    const userGroups = useGetUserGroups();

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
            {({ values, setFieldValue, handleBlur, isValid, isSubmitting, dirty }) => {
                const filteredOptions = useFilterUserGroups(userGroups, values.email);
                const userGroupsAreValid = validateUserGroups(userGroups, values.email, values.userGroups);

                return (
                    <Form>
                        <Modal.Body>
                            <FormFieldWithTitle
                                label={t('publications:accessControl.headers.name')}
                                name="name"
                                type="text"
                                props={{ required: true }}
                            />

                            <FormFieldWithTitle
                                label={t('publications:accessControl.labels.surname')}
                                name="surname"
                                type="text"
                                props={{ required: true }}
                            />

                            <FormFieldWithTitle
                                label={t('publications:accessControl.headers.company')}
                                name="company"
                                type="text"
                                props={{ required: true }}
                            />

                            <FormFieldWithTitle
                                label={t('global:labels.email')}
                                name="email"
                                type="email"
                                props={{ required: true }}
                            />

                            <FormFieldWithTitle
                                label={t('publications:accessControl.labels.comments')}
                                name="comments"
                                type="text"
                            />

                            <div className="d-flex justify align-items-start mb-3">
                                <Checkbox
                                    id="isNewsletterActive"
                                    name="isNewsletterActive"
                                    label={t('publications:accessControl.headers.newsletter')}
                                    checked={values.isNewsletterActive}
                                    onChange={(e) => setFieldValue('isNewsletterActive', e.target.checked)}
                                    onBlur={handleBlur}
                                />

                                <Checkbox
                                    id="isDocumentUpdatesActive"
                                    name="isDocumentUpdatesActive"
                                    label={t('publications:accessControl.labels.documentUpdates')}
                                    checked={values.isDocumentUpdatesActive}
                                    onChange={(e) => setFieldValue('isDocumentUpdatesActive', e.target.checked)}
                                    className="ml-4"
                                    onBlur={handleBlur}
                                />
                            </div>

                            <MultiSelect
                                label={t('publications:accessControl.userGroups')}
                                id="userGroups"
                                options={filteredOptions}
                                defaultValue={defaultValue}
                                handleChange={(selectedValues) => setFieldValue('userGroups', selectedValues ?? [])}
                                isDisabled={filteredOptions.length === 0}
                            />
                        </Modal.Body>

                        <ModalFooter
                            onHide={onClose}
                            isSubmitting={isSubmitting}
                            dirty={dirty}
                            isValid={isValid && userGroupsAreValid}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
}

const useFilterUserGroups = (userGroups, email) => {
    return useMemo(() => {
        const emailDomain = email.split('@')[1] || '';

        if (_.isEmpty(emailDomain)) {
            return [];
        }

        return userGroups
            .filter(
                (group) =>
                    group.allowedEmailDomains.length === 0 ||
                    group.allowedEmailDomains.map((domain) => domain.toLowerCase()).includes(emailDomain.toLowerCase())
            )
            .map((group) => ({
                label: group.name,
                value: group['@id'],
            }));
    }, [email, userGroups]);
};

const validateUserGroups = (userGroups, email, selectedOptions) => {
    const emailDomain = email.split('@')[1] || '';

    const groupsById = userGroups.reduce((acc, group) => {
        acc[group['@id']] = group;
        return acc;
    }, {});

    return selectedOptions.every((selectedOption) => {
        const group = groupsById[selectedOption.value];
        return (
            group &&
            (group.allowedEmailDomains.length === 0 ||
                group.allowedEmailDomains.map((domain) => domain.toLowerCase()).includes(emailDomain.toLowerCase()))
        );
    });
};

const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    surname: Yup.string().required(),
    company: Yup.string().required(),
    email: Yup.string().email('Invalid email').required(),
});
