import { useCurrentDocumentUserRole } from '../pages/documents_v2/RestrictedDocumentContent';

export function RestrictedDocument({ documentId, children }) {
    const currentDocumentUserRole = useCurrentDocumentUserRole(documentId);

    if (currentDocumentUserRole === undefined) {
        return null;
    }

    return <>{children}</>;
}
