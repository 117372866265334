import { Field, Form as FForm, Formik } from 'formik';
import * as Yup from 'yup';
import { generatePath, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormField, InputMultipleSelect, Switch } from 'pages/publications_v2/helpers/FieldHelper';
import { LoadingSpinner } from 'pages/global/Spinner';
import { PrimaryButton, WarningButton } from 'components/Buttons';
import Constants from '../../../../config/Constants';
import HelperFunctions from '../../../global/HelperFunctions';
import {
    useCreateAdminUserMutation,
    useResetAdminUserPasswordMutation,
    useUpdateAdminUserMutation,
} from 'features/security/authApi';
import { ADMIN_SETTING_ACCOUNT_PATH } from 'scenes/Admin';
import { useState } from 'react';

export function UserForm({ user = {}, account }) {
    const { t } = useTranslation('global');
    const { t: tUsers } = useTranslation('users');
    const history = useHistory();
    const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
    const availableUserRoles = useGetAvailableUserRoles();

    const [createAdminUser] = useCreateAdminUserMutation();
    const [updateAdminUser] = useUpdateAdminUserMutation();
    const [resetAdminUserPassword] = useResetAdminUserPasswordMutation();

    const isEditMode = user.hasOwnProperty('id');

    const handleSubmit = (values, { setSubmitting }) => {
        const body = {
            ...values,
            organisations: values.organisations.map((organisation) => organisation.id),
        };

        if (isEditMode === false) {
            // Create
            createAdminUser({ accountId: account.id, body }).then(() => {
                setSubmitting(false);

                history.push(
                    generatePath(ADMIN_SETTING_ACCOUNT_PATH, {
                        id: account.id,
                        view: 'users',
                    }),
                );
            });
        } else {
            // Update
            updateAdminUser({ accountId: account.id, id: user.id, body }).then(() => {
                setSubmitting(false);

                history.push(
                    generatePath(ADMIN_SETTING_ACCOUNT_PATH, {
                        id: account.id,
                        view: 'users',
                    }),
                );
            });
        }
    };

    const {
        email = '',
        roles = [],
        firstName = '',
        lastName = '',
        department = '',
        jobDescription = '',
        enabled = true,
        ssoEnabled = false,
        twoFactorEnabled = true,
        organisations = [],
    } = user;

    return (
        <div>
            <Formik
                initialValues={{
                    account: account.id,
                    email,
                    firstName,
                    lastName,
                    department: department || '',
                    jobDescription: jobDescription || '',
                    enabled,
                    ssoEnabled,
                    twoFactorEnabled,
                    roles: roles.filter((_role) =>
                        availableUserRoles.some((_availableRole) => _availableRole.role === _role),
                    ),
                    organisations: HelperFunctions.prepareDropdownData(organisations, 'name'),
                }}
                onSubmit={handleSubmit}
                enableReinitialize
                validationSchema={userFormSchema}
            >
                {({ values, dirty, isSubmitting, isValid, setFieldValue }) => (
                    <FForm autoComplete="off">
                        <div className="mb-3 form-group row">
                            <label htmlFor="organisations" className="form-label col-form-label col-sm-4">
                                Organisatie(s)*
                            </label>

                            <div className="col-sm-8">
                                <InputMultipleSelect
                                    name="organisations"
                                    options={HelperFunctions.prepareDropdownData(account.organisations, 'name')}
                                    onChange={(selectedOptions) => {
                                        setFieldValue('organisations', selectedOptions ?? []);
                                    }}
                                    value={values.organisations}
                                />
                            </div>
                        </div>

                        <FormField name="firstName" label={`${t('editUser.data.name')}*`} />
                        <FormField name="lastName" label={`${t('editUser.data.surName')}*`} />

                        <FormField name="email" label={`${t('editUser.loginDetails.email')}*`} />

                        <FormField name="department" label={t('editUser.data.section')} />
                        <FormField name="jobDescription" label={t('editUser.data.function')} />

                        <div className="font-weight-bolder text-color pt-2 mb-2">
                            {t('editUser.loginDetails.title')}
                        </div>

                        {account.isSsoEnabled && (
                            <Switch name="ssoEnabled" label="Single sign-on" wrapperClassName="mb-0" />
                        )}

                        <Switch
                            name="twoFactorEnabled"
                            label={t('editUser.loginDetails.twoStepSwitch')}
                            wrapperClassName="mb-0"
                        />

                        <Switch
                            name="enabled"
                            label={t('editUser.loginDetails.activeSwitch')}
                            wrapperClassName="mb-0"
                        />

                        {isEditMode && renderResetPasswordButton()}

                        <div className="font-weight-bolder text-color pt-3 mb-3">{t('editUser.loginDetails.role')}</div>

                        <UserRoles />

                        <div className="mb-4 mt-4">
                            <WarningButton type="submit" disabled={isSubmitting || !dirty || !isValid}>
                                {t('btn.save')}
                            </WarningButton>

                            <LoadingSpinner isSubmitting={isSubmitting} />
                        </div>
                    </FForm>
                )}
            </Formik>
        </div>
    );

    function renderResetPasswordButton() {
        return (
            <div className="mb-0 form-group row">
                <label className="form-label col-form-label col-sm-4">{tUsers('editUser.resetPassword')}</label>
                <div className="col-sm-8">
                    <div className="d-flex align-items-center pt-2">
                        <PrimaryButton
                            size="sm"
                            onClick={confirmPasswordReset}
                            disabled={passwordResetSuccess === true || user.enabled === false}
                        >
                            {tUsers('btn.sendViaEmail')}
                        </PrimaryButton>

                        {passwordResetSuccess && (
                            <div className="ml-2 text-success small">{tUsers('editUser.resetSuccessMessage')}</div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    function confirmPasswordReset() {
        HelperFunctions.confirmModal(
            tUsers('editUser.resetPasswordConfirm', {
                name: user.fullName,
            }),
            undefined,
            false,
            tUsers('editUser.resetPasswordConfirmLabel'),
        )
            .then(() => {
                resetPassword();
            })
            .catch(() => {});
    }

    function resetPassword() {
        resetAdminUserPassword({ accountId: account.id, id: user.id }).then(({ data }) => {
            if (data.status === 'ok') {
                setPasswordResetSuccess(true);
            }
        });
    }
}

export function UserRoles() {
    const availableUserRoles = useGetAvailableUserRoles();

    const availableUserRolesGrouped = Object.groupBy(availableUserRoles, ({ group }) => group);

    return (
        <>
            {Object.keys(availableUserRolesGrouped).map((group) => {
                const roles = availableUserRolesGrouped[group];

                if (roles.length === 0) {
                    return null;
                }

                return (
                    <div className="mb-3" key={`group-${group}`}>
                        <div className="font-weight-bold small">{group}</div>

                        <div>
                            {roles.map((_role) => (
                                <UserRole userRole={_role} key={`role-${_role.role}`} />
                            ))}
                        </div>
                    </div>
                );
            })}
        </>
    );
}

function UserRole({ userRole }) {
    const { role, label } = userRole;

    return (
        <div className="form-check mb-1 mt-2">
            <Field name="roles" type="checkbox" value={role} className="form-check-input" id={`role-${role}`} />
            <label className="form-check-label" htmlFor={`role-${role}`}>
                {label}
            </label>
        </div>
    );
}

function useGetAvailableUserRoles() {
    const { t: tUsers } = useTranslation('users');

    return [
        {
            role: Constants.userRoles.superAdmin,
            label: tUsers('newUser.roles.superAdmin'),
            module: '',
            group: 'Organisatie',
        },
        {
            role: Constants.userRoles.organisationAdmin,
            label: tUsers('newUser.roles.organizationAdmin'),
            module: '',
            group: 'Organisatie',
        },
        {
            role: Constants.userRoles.userManager,
            label: tUsers('newUser.roles.userManager'),
            module: Constants.modules.users,
            group: 'Organisatie',
        },
        {
            role: Constants.userRoles.documentManager,
            label: tUsers('newUser.roles.documentManager'),
            module: Constants.modules.documents,
            group: 'Documenten',
        },
        {
            role: Constants.userRoles.publicationManager,
            label: tUsers('newUser.roles.publicationsManager'),
            module: Constants.modules.publications,
            group: 'Publicaties',
        },
        {
            role: Constants.userRoles.publisher,
            label: tUsers('newUser.roles.publisher'),
            module: Constants.modules.publications,
            group: 'Publicaties',
        },
        {
            role: Constants.userRoles.publicationSpectator,
            label: tUsers('newUser.roles.publicationSpectator'),
            module: Constants.modules.publications,
            group: 'Publicaties',
        },
        {
            role: Constants.userRoles.reimbursementManager,
            label: tUsers('newUser.roles.reimbursementsManager'),
            module: Constants.modules.reimbursements,
            group: 'Vergoedingen',
        },
        {
            role: Constants.userRoles.mediaManager,
            label: tUsers('newUser.roles.mediaManager'),
            module: Constants.modules.documents,
            group: 'Algemeen',
        },
        {
            role: Constants.userRoles.tagManager,
            label: tUsers('newUser.roles.tagManager'),
            module: Constants.modules.tags,
            group: 'Algemeen',
        },
        {
            role: Constants.userRoles.labelManager,
            label: tUsers('newUser.roles.labelManager'),
            module: Constants.modules.labels,
            group: 'Algemeen',
        },
        {
            role: Constants.userRoles.operationListManager,
            label: tUsers('newUser.roles.changeListManager'),
            module: Constants.modules.operations_list,
            group: 'Operations',
        },
        {
            role: Constants.userRoles.operationListEditor,
            label: tUsers('newUser.roles.changeListEditor'),
            module: Constants.modules.operations_list,
            group: 'Operations',
        },
        {
            role: Constants.userRoles.operationListOnly,
            label: tUsers('newUser.roles.changeListUser'),
            module: Constants.modules.operations_list,
            group: 'Operations',
        },
        {
            role: Constants.userRoles.translationManager,
            label: tUsers('newUser.roles.translationsManager'),
            module: Constants.modules.translations,
            group: 'Vertalen',
        },
        {
            role: Constants.userRoles.templateManager,
            label: tUsers('newUser.roles.templateManager'),
            module: Constants.modules.documents,
            group: 'Documenten',
        },
        {
            role: Constants.userRoles.kioskManager,
            label: tUsers('newUser.roles.kioskManager'),
            module: Constants.modules.publications,
            group: 'Publicaties',
        },
        {
            role: Constants.userRoles.taskTeamUser,
            label: 'Task team user',
            module: Constants.modules.task_team,
            group: 'Taken',
        },
        {
            role: Constants.userRoles.taskTeamManager,
            label: 'Task team manager',
            module: Constants.modules.task_team,
            group: 'Taken',
        },
    ];
}

const userFormSchema = Yup.object().shape({
    organisations: Yup.array().min(1),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string().email().required(),
    plainPassword: Yup.string()
        .min(12)
        .matches(/(?=^.{12,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/),
    passwordConfirmation: Yup.string().when('plainPassword', {
        is: (val) => val && val.length > 0,
        then: Yup.string()
            .required()
            .oneOf([Yup.ref('plainPassword')], 'Both password need to be the same'),
    }),
});
