import { NewItemButton } from 'components/Buttons';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { PaginatedTable, TableProvider } from 'components/PaginatedTable';
import { ArrowDownShort } from 'react-bootstrap-icons';
import { DeleteButton, EditButton } from 'components/ActionButtons';
import HelperFunctions from 'pages/global/HelperFunctions';
import { useDeleteRejectionReasonMutation } from 'features/comments/commentApi';
import { useTranslation } from 'react-i18next';
import { entityTypes } from 'pages/translation/config/Constants';
import { useShowSettingsSidebar } from 'components/SettingsSidebar/hooks/useShowSettingsSidebar';
import { useGetRejectionReasons } from 'pages/task_team/hooks/useGetRejectionReasons';
import { useGetTeams } from 'pages/task_team/hooks/useGetTeams';
import { Badge } from 'react-bootstrap';

export function RejectionReasons() {
    const rejectionReasons = useGetRejectionReasons();
    const [, setAddRejectionReason] = useQueryParam('addRejectionReason', BooleanParam);

    return (
        <div>
            <div className="subheader">
                <h3>
                    Reden voor afwijzing <span className="text-muted ml-1">{rejectionReasons.length}</span>
                </h3>

                <div className="d-flex align-items-center ml-auto">
                    <NewItemButton size="sm" label="Reden toevoegen" onClick={() => setAddRejectionReason(true)} />
                </div>
            </div>

            <TableProvider>
                <PaginatedTable
                    items={rejectionReasons}
                    searchPlaceholder="Zoeken..."
                    searchProps={['name']}
                    colSpan={3}
                >
                    {({ items = [] }) => (
                        <>
                            <thead>
                                <tr>
                                    <th>
                                        Naam <ArrowDownShort />
                                    </th>
                                    <th>Teams</th>
                                    <th>Acties</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((rejectionReason) => (
                                    <RejectionReason
                                        rejectionReason={rejectionReason}
                                        key={`rejectionReason-${rejectionReason.id}`}
                                    />
                                ))}
                            </tbody>
                        </>
                    )}
                </PaginatedTable>
            </TableProvider>
        </div>
    );
}

function RejectionReason({ rejectionReason }) {
    const { t } = useTranslation();
    const [deleteRejectionReason] = useDeleteRejectionReasonMutation();
    const [showSettingsSidebar] = useShowSettingsSidebar();

    return (
        <tr>
            <td>{rejectionReason.name}</td>
            <td>
                {rejectionReason.teams.length > 0 ? <RejectionReasonTeams rejectionReason={rejectionReason} /> : <>-</>}
            </td>
            <td className="actions">
                <div>
                    <EditButton onClick={toggleSidebar} />
                    <DeleteButton onClick={handleDelete} />
                </div>
            </td>
        </tr>
    );

    function toggleSidebar() {
        showSettingsSidebar(rejectionReason, entityTypes.REJECTION_REASON);
    }

    function handleDelete() {
        HelperFunctions.confirmModal(
            `${rejectionReason.name} verwijderen?`,
            'danger',
            false,
            t('global:confirm.yesDelete'),
            t('global:btn.cancel'),
        )
            .then(() => {
                deleteRejectionReason(rejectionReason['@id']);
            })
            .catch(() => {});
    }
}

function RejectionReasonTeams({ rejectionReason }) {
    const teams = useGetTeams();

    return (
        <div>
            {teams
                .filter((team) => rejectionReason.teams.includes(team['@id']))
                .map((team, index) => (
                    <Badge pill variant="primary" key={`rejectionReason-${rejectionReason.id}-badge-${index}`}>
                        {team.name}
                    </Badge>
                ))}
        </div>
    );
}
