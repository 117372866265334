import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import DocumentApi from '../../api/DocumentApi';
import VariantHelper from '../../pages/global/VariantHelper';
import { normalize } from 'normalizr';
import { documentVariantEntity } from 'schemas';

const AreaAdapter = createEntityAdapter();
const DocumentVariantAdapter = createEntityAdapter();

export const loadDocumentVariantsHistory = createAsyncThunk(
    'documents/loadDocumentVariantsHistory',
    async ({ documentId, entity, entityId }) => {
        const results = await DocumentApi.getDocumentVariantsHistory(documentId, { entity, entityId });
        const normalized = normalize(VariantHelper.toArray(results), [documentVariantEntity]);

        return normalized.entities;
    }
);

export const loadTableTemplates = createAsyncThunk('documents/loadTableTemplates', () => {
    return DocumentApi.getTableTemplates();
});

const documentsSlice = createSlice({
    name: 'documents',
    initialState: {
        areas: AreaAdapter.getInitialState(),
        documents: {},
        documentVariants: DocumentVariantAdapter.getInitialState(),
        documentVariantsByDocument: DocumentVariantAdapter.getInitialState(),
        loadedOrganisation: 0,
        tableTemplates: false,
        blockSearchMatches: null,
        editArea: {
            editVariantsBlockId: null,
        },
        sidebarFilters: {
            showHeading: true,
            showEdits: true,
            showDeletedArticles: true,
            showDeletedChapters: true,
            showComments: true,
            showLabels: true,
            showNumbering: true,
            showArticlePrefix: true,
            showPageForcing: true,
            showTitleInDoc: true,
            showSkippedArticles: true,
        },
        expandedEntities: {},
    },
    reducers: {
        setEditVariantBlockId(state, action) {
            state.editArea.editVariantsBlockId = action.payload;
        },
        setFilterDoc: {
            reducer(state, action) {
                const { key, value } = action.payload;
                state.sidebarFilters[key] = value;
            },
        },
    },
    extraReducers: {
        ['security/resetState']: (state) => {
            Object.assign(state, documentsSlice.getInitialState());
        },
        [loadTableTemplates.fulfilled]: (state, action) => {
            state.tableTemplates = action.payload;
        },
        [loadDocumentVariantsHistory.fulfilled]: (state, action) => {
            DocumentVariantAdapter.upsertMany(state.documentVariants, action.payload.documentVariants ?? []);
        },
    },
});

export const { setFilterDoc, setEditVariantBlockId } = documentsSlice.actions;

export default documentsSlice.reducer;

export const { selectById: selectDocumentVariantById } = DocumentVariantAdapter.getSelectors(
    (state) => state.documentVariants
);
