import React, { useCallback, useContext, useMemo } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { NavBreadCrumbs } from '../../../Navbar';
import Tooltip from '../../../global/Tooltip';
import { X } from 'react-bootstrap-icons';
import {
    Category,
    Product,
    ProductLink,
    ReimbursementProduct,
    ReimbursementProductHistory,
} from '../../../../models/reimbursement.models';
import { Form as FForm, Formik, FormikHelpers, useFormikContext } from 'formik';
import * as Yup from 'yup';
import {
    useAddReimbursementProductMutation,
    useGetEntityHistoryQuery,
    useGetProductsQuery,
    useGetReimbursementProductCodesQuery,
    useGetReimbursementProductFieldsQuery,
    useUpdateReimbursementProductMutation,
} from '../../../../features/reimbursements/reimbursements';
import Spinner from '../../../global/Spinner';
import ProductHelper from '../../../global/ProductHelper';
import { Coverage, Description } from './ReimbursementProductForm';
import Constants from '../../../../config/Constants';
import LoadingSpinner from '../../../global/LoadingSpinner';
import ReimbursementProductCodes from './ReimbursementProductCodes';
import { SidebarProduct } from './SidebarProduct';
import { ProcessAllButtonsMemo } from './ProcessAllButtons';
import { ReimbursementBreadcrumbMemo } from './ReimbursementBreadcrumb';
import HelperFunctions from '../../../global/HelperFunctions';
import { generatePath, useHistory } from 'react-router-dom';
import { EDIT_REIMBURSEMENT_PRODUCT_PATH } from '../../../../scenes/Reimbursements';
import { HistoryContext } from './ReimbursementProduct';
import { HistorySelector } from './HistorySelector';
import RestrictedReimbursementContent from '../../RestrictedReimbursementContent';
import { useGetReimbursementProduct } from '../../../../features/reimbursements/reimbursementHooks';
import { useTranslation } from 'react-i18next';

const Form = ({
    reimbursementUri,
    productUri,
    selectedProductUri,
    categoryUri,
    children,
}: {
    reimbursementUri: string;
    productUri: string;
    selectedProductUri: string;
    categoryUri: string;
    children: React.ReactNode;
}) => {
    const [updateReimbursementProduct] = useUpdateReimbursementProductMutation();
    const [addReimbursementProduct] = useAddReimbursementProductMutation();

    const { reimbursementProduct, isLoading, isUninitialized } = useGetReimbursementProduct(
        categoryUri,
        reimbursementUri,
        productUri,
        selectedProductUri
    );

    const skipFields = reimbursementProduct['@id'] === undefined;

    const codesData = useGetReimbursementProductCodesQuery(reimbursementProduct['@id'], {
        selectFromResult: ({ data, isLoading, isUninitialized }) => ({
            reimbursementProductCodes: data ?? [],
            isLoading,
            isUninitialized,
        }),
        skip: skipFields,
    });

    const reimbursementProductCodes = useMemo(() => {
        if (skipFields) {
            return [];
        }

        return codesData?.reimbursementProductCodes ?? [];
    }, [skipFields, codesData]);

    const codesLoading = !skipFields && (codesData.isLoading || codesData.isUninitialized);

    const fieldsData = useGetReimbursementProductFieldsQuery(reimbursementProduct['@id'], {
        selectFromResult: ({ data, isLoading, isUninitialized }) => ({
            reimbursementProductFields: data ?? [],
            isLoading,
            isUninitialized,
        }),
        skip: skipFields,
    });

    const reimbursementProductFields = useMemo(() => {
        if (skipFields) {
            return [];
        }

        return fieldsData?.reimbursementProductFields ?? [];
    }, [skipFields, fieldsData]);

    const fieldsLoading = !skipFields && (fieldsData.isLoading || fieldsData.isUninitialized);

    if (isUninitialized || isLoading || codesLoading || fieldsLoading) {
        return <LoadingSpinner />;
    }

    const handleSubmit = (
        values: Partial<ReimbursementProduct>,
        helpers: FormikHelpers<Partial<ReimbursementProduct>>
    ) => {
        const formData = {
            ...values,
            reimbursementProductCodes: values.reimbursementProductCodes?.map((_item) => ({
                ..._item,
                id: _item['@id'] ?? null,
                scheduledForDeletion: _item?.markAsDeleted === true,
            })),
            reimbursementProductFields: values.reimbursementProductFields?.map((_item) => ({
                ..._item,
                id: _item['@id'] ?? null,
            })),
        };

        if (values.id === undefined) {
            addReimbursementProduct({
                ...formData,
            }).then(() => {
                helpers.setSubmitting(false);
            });
        } else {
            updateReimbursementProduct({
                uri: reimbursementProduct['@id'],
                ...formData,
            }).then(() => {
                helpers.setSubmitting(false);
            });
        }
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                ...reimbursementProduct,
                reimbursementProductCodes,
                reimbursementProductFields,
            }}
            validationSchema={ReimbursementProductSchema}
            onSubmit={handleSubmit}
        >
            {children}
        </Formik>
    );
};

const FormContent = ({
    product,
    parentProduct,
    category,
    reimbursementId,
}: {
    product: Product;
    parentProduct: Product;
    category: Category;
    reimbursementId: number;
}) => {
    const { values }: { values: Partial<ReimbursementProduct> } = useFormikContext();
    const { startGroupId, endGroupId } = useContext(HistoryContext);
    const { entityHistory }: { entityHistory?: ReimbursementProductHistory } = useGetEntityHistoryQuery(
        {
            entityId: values.id,
            entity: 'ReimbursementProduct',
            historyStartGroupId: startGroupId,
            historyEndGroupId: endGroupId,
        },
        {
            selectFromResult: ({ data }) => ({
                entityHistory: data ?? undefined,
            }),
            skip: values.id === undefined,
        }
    );

    const history = useMemo(() => {
        if (values.id === undefined) {
            return undefined;
        }

        return entityHistory;
    }, [values.id, entityHistory]);

    return (
        <>
            <ReimbursementBreadcrumbMemo
                reimbursementId={reimbursementId}
                categoryUri={category['@id']}
                product={product}
                parentProductName={parentProduct.name}
            />

            <div className="pt-4 px-3">
                <Container fluid>
                    <Row>
                        <Col>
                            <FForm autoComplete="off" id="editForm">
                                <Coverage
                                    reimbursementProduct={values}
                                    history={history}
                                    reimbursementId={reimbursementId}
                                />

                                <>
                                    <ReimbursementProductCodes
                                        categoryUri={category['@id']}
                                        history={history}
                                        reimbursementId={reimbursementId}
                                    />
                                    <Description
                                        category={category}
                                        history={history}
                                        reimbursementId={reimbursementId}
                                    />
                                </>
                            </FForm>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

const FormNav = ({
    categoryName,
    handleClose,
    children,
}: {
    categoryName?: string;
    handleClose: (dirty: boolean) => void;
    children: React.ReactNode;
}) => {
    const { dirty } = useFormikContext();
    const { t } = useTranslation('reimbursements');

    return (
        <nav
            className="bg-white border-bottom py-3 px-4 navbar navbar-expand navbar-light fixed-top"
            style={{ height: 64 }}
        >
            <div
                className="d-flex flex-wrap align-items-center text-color"
                style={{
                    fontSize: '1rem',
                }}
            >
                <NavBreadCrumbs pages={[{ title: t('breadcrumb') }, { title: categoryName }]} />
                <HistorySelector />
            </div>

            <div className="d-flex flex-shrink-0 align-items-center ml-auto">
                <div>{children}</div>

                <Tooltip tooltip={t('product.form.close') || ''} placement="bottom">
                    <Button variant="link" className="text-secondary p-0 ml-4" onClick={() => handleClose(dirty)}>
                        <X size={26} />
                    </Button>
                </Tooltip>
            </div>
        </nav>
    );
};

const FormSidebar = ({
    product,
    selectedProductId,
    categoryId,
    reimbursementId,
    parentProductId,
}: {
    product: Product;
    selectedProductId: number;
    categoryId: number;
    reimbursementId: number;
    parentProductId: number;
}) => {
    const history = useHistory();
    const { dirty } = useFormikContext();
    const isLinkedProduct = ProductHelper.isLinkedProduct(product);
    const selectedProductUris = isLinkedProduct
        ? product.extendedProducts.map((productLink: ProductLink) => productLink.baseProduct)
        : [product['@id']];

    const { products }: { products: Product[] } = useGetProductsQuery(categoryId, {
        selectFromResult: (result) => ({
            products: result.data
                ? result.data.filter((product: Product) => selectedProductUris.includes(product['@id']))
                : [],
        }),
    });
    const { t } = useTranslation('reimbursements');

    const handleClick = useCallback(
        (selectedProductId: number) => {
            const navPath = generatePath(EDIT_REIMBURSEMENT_PRODUCT_PATH, {
                categoryId,
                reimbursementId,
                parentProductId,
                productId: product.id,
                selectedProductId,
            });

            if (dirty) {
                HelperFunctions.confirmModal(t('product.form.unsavedChanges'), 'danger', false).then(() => {
                    history.push(navPath);
                });
            } else {
                history.push(navPath);
            }
        },
        [dirty]
    );

    return (
        <div className="py-4">
            <div className="d-flex align-items-center justify-content-between mb-2 px-4">
                <span className="text-uppercase small text-muted font-weight-light">
                    {t('product.form.reimbursement')}
                </span>
            </div>
            <div className="sidebar-nav flex-column nav">
                {products.map((_product: Product) => (
                    <SidebarProduct
                        product={_product}
                        selectedProductId={selectedProductId}
                        reimbursementId={reimbursementId}
                        productId={product.id}
                        handleClick={handleClick}
                        hasUnsavedChanges={dirty && selectedProductId === _product.id}
                        key={`r-${reimbursementId}-p-${_product.id}`}
                    />
                ))}
            </div>
        </div>
    );
};

const FormFooter = ({ handleClose }: { handleClose: (dirty: boolean) => void }) => {
    const { isSubmitting, isValid, dirty } = useFormikContext();
    const { t } = useTranslation('reimbursements');

    return (
        <div
            className="d-flex align-items-center px-4 bg-light"
            style={{
                height: 59,
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                borderTop: '2px solid #bbcad9',
            }}
        >
            <div className="d-flex align-items-center ml-auto">
                {isSubmitting && <Spinner />}
                {dirty && (
                    <div className="text-warning font-italic small mr-3">{t('product.form.thereAreUnsaved')}</div>
                )}
                <Button className="mx-2" variant="light" onClick={() => handleClose(dirty)}>
                    {t('product.form.close')}
                </Button>

                <RestrictedReimbursementContent
                    roles={[
                        Constants.reimbursementTeamRoles.editor,
                        Constants.reimbursementTeamRoles.finalEditor,
                        Constants.reimbursementTeamRoles.manager,
                        Constants.reimbursementTeamRoles.admin,
                    ]}
                >
                    <Button
                        variant="warning"
                        disabled={isSubmitting || !isValid || !dirty}
                        type="submit"
                        form="editForm"
                    >
                        {t('btn.save')}
                    </Button>
                </RestrictedReimbursementContent>
            </div>
        </div>
    );
};

const FormCheck = ({ reimbursementId }: { reimbursementId: number }) => {
    const { values, isSubmitting }: { values: Partial<ReimbursementProduct>; isSubmitting: boolean } =
        useFormikContext();

    return (
        <ProcessAllButtonsMemo
            reimbursementId={reimbursementId}
            reimbursementProductUri={values['@id']}
            isSubmitting={isSubmitting}
        />
    );
};

Form.FormContent = FormContent;
Form.FormNav = FormNav;
Form.FormSidebar = FormSidebar;
Form.FormFooter = FormFooter;
Form.FormCheck = FormCheck;

const ReimbursementProductSchema = Yup.object().shape({
    category: Yup.string().required(),
    coverage: Yup.string().required(),
});

export default Form;
