import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Field, Form as FForm, Formik } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchOperationsLists,
    patchOpList,
    selectOpListById,
} from '../../../features/operationsList/operationsListSlice';
import { useParams } from 'react-router-dom';
import Spinner from '../../global/Spinner';
import { useGetDocuments } from '../../documents_v2/hooks/useGetDocuments';
import { useTranslation } from 'react-i18next';
import { WarningButton } from '../../../components/Buttons';

export default function OpListPropertiesForm() {
    const { id } = useParams();
    const opList = useSelector((state) => selectOpListById(state.operations, id));
    const dispatch = useDispatch();
    const documents = useGetDocuments();
    const { t } = useTranslation('changelist');

    useEffect(() => {
        if (!opList) {
            dispatch(fetchOperationsLists());
        }
    }, [dispatch, opList]);

    if (!opList) {
        return null;
    }

    const handleSubmit = (values, { setSubmitting }) => {
        const formData = {
            ...values,
            documentIds: values.documentIds.map((_document) => _document.value),
        };

        dispatch(patchOpList({ uri: opList['@id'], formData })).then(() => {
            setSubmitting(false);
        });
    };

    const options = Object.values(documents).map((_document) => {
        return {
            label: _document.name,
            value: _document.id,
        };
    });

    const documentIds = opList?.documentIds ?? [];

    return (
        <Formik
            initialValues={{
                name: opList.name,
                documentIds: options.filter((_option) => documentIds.includes(_option.value)),
            }}
            validationSchema={EditOpListSchema}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({ setFieldValue, dirty, isSubmitting, handleBlur, isValid, touched, errors }) => (
                <FForm autoComplete="off">
                    <Form.Group>
                        <Form.Label htmlFor="name">{t('changelist.subnav.settings.opListProperties.name')}</Form.Label>
                        <Field
                            id="name"
                            name="name"
                            as={Form.Control}
                            isInvalid={touched['name'] && errors['name']}
                            isValid={touched['name'] && !errors['name']}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label htmlFor="documentIds">
                            {t('changelist.subnav.settings.opListProperties.documents')}
                        </Form.Label>
                        <Field
                            isMulti
                            as={Select}
                            id="documentIds"
                            name="documentIds"
                            options={options}
                            onBlur={handleBlur}
                            onChange={(selectedOptions) => {
                                setFieldValue('documentIds', selectedOptions ?? []);
                            }}
                        />
                    </Form.Group>
                    <WarningButton type="submit" disabled={isSubmitting || !dirty || !isValid}>
                        {t('btn.save')}
                    </WarningButton>
                    {isSubmitting && <Spinner />}
                </FForm>
            )}
        </Formik>
    );
}

const EditOpListSchema = Yup.object().shape({
    name: Yup.string().required(),
    documentIds: Yup.array().required(),
});
