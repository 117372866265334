import { ReactNode, useMemo, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useGetDocumentVariantsQuery } from '../../features/documents/documents';
import { DocumentVariant } from '../../models/documents.models';
import { useGetFolders, useGetLabels } from '../../features/metadata/metadataHooks';
import Constants from '../../config/Constants';
import { Label, LabelEntity, LabelFolder } from '../../models/labels.models';
import { InputMultipleSelect } from '../publications_v2/helpers/FieldHelper';
import cx from 'classnames';
import HelperFunctions from '../global/HelperFunctions';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

interface ActionValue {
    action: string;
    name: string;
    option?: FormLabel;
    removedValue?: FormLabel;
}

interface FormLabel extends Label {
    labelEntity?: Partial<LabelEntity>;
}

interface FormInterface {
    entityLabels: FormLabel[];
}

export function EditVariantFormContent({
    documentId,
    documentVariantIds,
    entityType,
    toggleLabel,
    children,
}: {
    documentId: number;
    documentVariantIds: number[];
    entityType: string;
    toggleLabel: (labels: FormLabel[] | null, actionValue: ActionValue) => void;
    children?: ReactNode;
}) {
    const { folders } = useGetFolders();
    const { t } = useTranslation('documents');

    const { documentVariants }: { documentVariants: DocumentVariant[] } = useGetDocumentVariantsQuery(documentId, {
        selectFromResult: ({ data }) => ({
            documentVariants: data
                ? data.filter(
                      (variant: DocumentVariant) => variant.parentId !== null && documentVariantIds.includes(variant.id)
                  )
                : [],
        }),
    });

    const allowedTypes = Object.keys(Constants.labelFolderEntities).filter((_entity) => {
        // @ts-ignore
        return Constants.labelFolderEntities[_entity].includes(entityType);
    });

    const filteredFolders = useMemo(() => {
        return folders.filter((folder) => {
            return folder.contentTypes.some((_type) => allowedTypes.includes(_type));
        });
    }, [allowedTypes, folders]);

    return (
        <div className="container-fluid">
            <Row noGutters={true}>
                <Col xs={3}>
                    <div className="pr-3">
                        <div className="small font-weight-bolder mb-4">
                            {t('document.navbar.main.settingsSidebar.labels.basicVariant')}
                        </div>
                        <div>{children}</div>
                    </div>
                </Col>
                <Col xs={9}>
                    <Container className="variant-container">
                        <div
                            className="row h-100"
                            style={{ display: 'block', overflowX: 'auto', whiteSpace: 'nowrap' }}
                        >
                            {documentVariants.map((variant) => (
                                <Variant
                                    variant={variant}
                                    folders={filteredFolders}
                                    toggleLabel={toggleLabel}
                                    key={`edit-variant-${variant.id}`}
                                />
                            ))}
                        </div>
                    </Container>
                </Col>
            </Row>
        </div>
    );
}

function Variant({
    variant,
    folders,
    toggleLabel,
}: {
    variant: DocumentVariant;
    folders: LabelFolder[];
    toggleLabel: (labels: FormLabel[] | null, actionValue: ActionValue) => void;
}) {
    return (
        <div className="col" style={{ display: 'inline-block', maxWidth: 320 }}>
            <div className="text-truncate small font-weight-bolder mb-4">{variant.name}</div>

            {folders.map((folder) => {
                return (
                    <VariantFolder
                        variantId={variant.id}
                        folder={folder}
                        toggleLabel={toggleLabel}
                        key={`variant-${variant.id}-folder-${folder.id}`}
                    />
                );
            })}
        </div>
    );
}

function VariantFolder({
    variantId,
    folder,
    toggleLabel,
}: {
    variantId: number;
    folder: LabelFolder;
    toggleLabel: (labels: FormLabel[] | null, actionValue: ActionValue) => void;
}) {
    const { labels } = useGetLabels();
    const folderUri = folder['@id'];
    const { values } = useFormikContext<FormInterface>();

    const labelOptions = useMemo(() => {
        return labels.length > 0
            ? HelperFunctions.prepareDropdownData(
                  labels.filter((label) => {
                      return label.folder === folderUri;
                  }),
                  'name',
                  '@id'
              )
            : [];
    }, [labels, folderUri]);

    const defaultLabels = useMemo(() => {
        if (values.entityLabels?.length > 0) {
            return values.entityLabels.filter(
                (label: FormLabel) =>
                    label.folder === folder['@id'] && label.labelEntity && label.labelEntity.variantId === variantId
            );
        }

        return [];
    }, [labelOptions, values.entityLabels]);

    const [hasOverride, setOverride] = useState(defaultLabels.length > 0);

    return (
        <div className="mb-3">
            <div className="d-flex justify-content-between align-items-center mb-2">
                <div className={cx({ 'text-muted': !hasOverride })}>{folder.name}</div>
                <div className="form-switch">
                    <Form.Switch
                        checked={hasOverride}
                        id={`variant-${variantId}-folder-${folder.id}-switch`}
                        onChange={() => {
                            if (hasOverride) {
                                const removeAction = {
                                    action: 'clear',
                                    name: `variant-${variantId}|folder-${folder.id}`,
                                } as ActionValue;

                                toggleLabel(null, removeAction);
                            }

                            setOverride(!hasOverride);
                        }}
                    />
                </div>
            </div>
            <LabelVariantSelect
                name={`variant-${variantId}|folder-${folder.id}`}
                defaultValue={defaultLabels}
                options={hasOverride ? labelOptions : []}
                toggleLabel={toggleLabel}
                placeholder={hasOverride ? undefined : ''}
                isDisabled={!hasOverride}
                variantId={variantId}
            />
        </div>
    );
}

function LabelVariantSelect({
    name,
    defaultValue,
    options,
    variantId,
    toggleLabel,
    placeholder = '',
    isDisabled = false,
}: {
    name: string;
    defaultValue: FormLabel[];
    options: FormLabel[];
    variantId?: number;
    toggleLabel: (labels: FormLabel[] | null, actionValue: ActionValue) => void;
    placeholder?: string;
    isDisabled?: boolean;
}) {
    return (
        <div>
            <InputMultipleSelect
                name={name}
                value={undefined}
                // @ts-ignore
                defaultValue={defaultValue}
                options={options}
                onChange={(selectedOptions: FormLabel[], action: ActionValue) => {
                    const selectedLabels = selectedOptions.map((option) => {
                        if (!option.labelEntity) {
                            return {
                                ...option,
                                labelEntity: {
                                    variantId,
                                } as Partial<LabelEntity>,
                            };
                        }

                        return option;
                    });

                    toggleLabel(selectedLabels, action);
                }}
                props={{
                    placeholder,
                    isDisabled,
                }}
            />
        </div>
    );
}
