import { Navbar } from 'react-bootstrap';
import { generatePath, Link, useRouteMatch } from 'react-router-dom';
import cx from 'classnames';
import {
    BASE_PATH,
    INDEX_FOLDER_PATH,
    INDEX_FOLDER_SELECTION_PATH,
    KIOSK_PATH,
    USER_GROUPS_PATH,
} from '../../../scenes/PublicationsV2';
import Constants, { Permissions } from '../../../config/Constants';
import RestrictedContent from '../../global/RestrictedContent';
import { useTranslation } from 'react-i18next';

export default function SubNav() {
    const isViewPath = useRouteMatch({ path: BASE_PATH, exact: true, strict: true });
    const isFolderPath = useRouteMatch({ path: INDEX_FOLDER_PATH, exact: true, strict: true });
    const isSelectionPath = useRouteMatch({ path: INDEX_FOLDER_SELECTION_PATH, exact: true, strict: true });
    const isUserGroupsPath = useRouteMatch({ path: USER_GROUPS_PATH, exact: true, strict: true });
    const isKioskPath = useRouteMatch({ path: KIOSK_PATH, exact: true, strict: true });
    const { t } = useTranslation('publications');


    return (
        <Navbar fixed="top" className="subnav bg-light border-bottom px-3 py-0">
            <div className="nav w-100">
                <div className="nav-item">
                    <Link
                        to={generatePath(BASE_PATH)}
                        className={cx('nav-link d-flex align-items-center', {
                            active: !isUserGroupsPath && (isViewPath || isFolderPath || isSelectionPath),
                        })}
                    >
                        {t('tabs.publications')}
                    </Link>
                </div>

                <RestrictedContent module={Constants.modules.publication_access_control}>
                    <div className="nav-item">
                        <Link
                            to={generatePath(USER_GROUPS_PATH, {
                                page: 'groups',
                            })}
                            className={cx('nav-link d-flex align-items-center', {
                                active: isUserGroupsPath,
                            })}
                        >
                            {t('tabs.accessControl')}
                        </Link>
                    </div>
                </RestrictedContent>

                <RestrictedContent permission={Permissions.Kiosk.Create}>
                    <div className="nav-item">
                        <Link
                            to={generatePath(KIOSK_PATH)}
                            className={cx('nav-link d-flex align-items-center', {
                                active: isKioskPath,
                            })}
                        >
                            {t('tabs.kiosk')}
                        </Link>

                    </div>
                </RestrictedContent>
            </div>
        </Navbar>
    );
}
