import { useState } from 'react';
import { Accordion, Button, Card, Table } from 'react-bootstrap';
import { InputCheck, InputField } from '../../../pages/publications_v2/helpers/FieldHelper';
import { useFormikContext } from 'formik';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import getTemplateParameters, { ParametersTranslation } from './TemplateConstants';
import { useSidebarContext } from '../hooks/useSidebarContext';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

export default function EditTemplateParameters({ document }) {
    const { entity, entityType } = useSidebarContext();

    let parameters =
        entityType === 'block' ? getTemplateParameters(entity.type, entity) : getTemplateParameters(entityType);

    // Extend parameters if the entityType is 'area' and entity.properties.isTemplate is true
    if (entityType === 'area' && entity?.properties?.isTemplate) {
        const templateAreaParameters = getTemplateParameters('templateArea', entity);
        parameters = {
            ...parameters,
            ...templateAreaParameters,
        };
    }

    return (
        <>
            {Object.keys(parameters).map((group) => (
                <ParametersTable
                    document={document}
                    group={group}
                    parameters={Object.keys(parameters[group])}
                    key={`group-${group}`}
                />
            ))}
        </>
    );
}

export function ParametersTable({ group, parameters, ButtonsComponentReplacement, namePrefix, document }) {
    const [isExpanded, setIsExpanded] = useState(false);
    const { t } = useTranslation('documents');

    const translatedGroup = t(`document.navbar.template.tabs.settings.templateParameters.groupNames.${group}`);

    return (
        <>
            <div className="uk-form-controls">
                <Accordion>
                    <Card style={{ backgroundColor: 'transparent', border: 'none' }}>
                        <Card.Header
                            style={{
                                backgroundColor: 'transparent',
                                border: 'none',
                            }}
                            className="pl-0"
                        >
                            <Accordion.Toggle
                                as={Button}
                                variant="dark"
                                style={{
                                    backgroundColor: 'transparent',
                                    color: 'black',
                                    outline: 'none',
                                    border: 'none',
                                }}
                                className="pl-0"
                                eventKey={`${group}-${parameters}`}
                                onClick={() => setIsExpanded(!isExpanded)}
                            >
                                <span className="uk-text-primary"> {translatedGroup} </span>
                                {!isExpanded ? <CaretDownFill /> : <CaretUpFill />}
                            </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse eventKey={`${group}-${parameters}`} key={`${group}-${parameters}`}>
                            <Card.Body className="p-0">
                                <Table borderless striped id="Settings-table" className="mb-0">
                                    <tbody className="font-weight-bold">
                                        {group === 'Algemeen' && ButtonsComponentReplacement ? (
                                            <ButtonsComponentReplacement />
                                        ) : (
                                            <SelectAllButtons parameters={parameters} group={group} />
                                        )}
                                        <ParameterRows
                                            document={document}
                                            group={group}
                                            parameters={parameters}
                                            namePrefix={namePrefix}
                                        />
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
        </>
    );
}

function ParameterRows({ group, parameters, namePrefix, document }) {
    return parameters.map((parameter) => {
        const parameterTranslation = ParametersTranslation(parameter);
        const name = namePrefix ? `${namePrefix}.${parameter}` : `settings.properties.templateParameters.${parameter}`;
        const hasCustomPrefixOptions = group === 'Instellingen' && parameter === 'prefixOptions';
        const isTextInput = parameter === 'maxNumberOfSections';
        const inputType = parameter === 'maxNumberOfSections' ? 'number' : 'text';
        const documentLevelRestrictions = document?.properties?.templateParameters;
        const { t } = useTranslation('documents');

        const canOnlyUseAsWhole = documentLevelRestrictions?.composition
            ? !documentLevelRestrictions?.composition?.includes('canOnlyUseAsWhole')
            : true;

        const isAffectByCanUseAsWhole =
            (group === 'Opties' && parameter === 'canAddFreeAreas') || parameter === 'canAddFreeBlocks';

        return (
            <tr key={`group-${group}-param-${parameter}`}>
                <td style={{ width: '50%' }}>
                    <div
                        className={cx('small font-weight-bold', {
                            'text-muted': isAffectByCanUseAsWhole && !canOnlyUseAsWhole,
                        })}
                    >
                        {parameterTranslation}
                    </div>
                    {hasCustomPrefixOptions && (
                        <div className="small text-muted">
                            {t('document.navbar.template.tabs.settings.templateParameters.comma')}
                        </div>
                    )}
                </td>
                <td style={{ width: '50%' }}>
                    {hasCustomPrefixOptions || isTextInput ? (
                        <InputField name={name} props={{ type: inputType }} />
                    ) : isAffectByCanUseAsWhole ? (
                        <InputCheck
                            name={name}
                            className="small text-muted"
                            value={false}
                            props={{ disabled: !canOnlyUseAsWhole }}
                        />
                    ) : (
                        <InputCheck name={name} />
                    )}
                </td>
            </tr>
        );
    });
}

function SelectAllButtons({ parameters, group }) {
    const { setFieldValue } = useFormikContext();
    const { t } = useTranslation('documents');

    const isAffectedByCanUseAsWhole = (parameter) => {
        return group === 'Opties' && (parameter === 'canAddFreeAreas' || parameter === 'canAddFreeBlocks');
    };

    const affectedParameters = parameters.filter((parameter) => !isAffectedByCanUseAsWhole(parameter));

    const handleSelectAll = () => {
        affectedParameters.forEach((parameter) => {
            if (parameter !== 'prefixOptions') {
                setFieldValue(`settings.properties.templateParameters.${parameter}`, true);
            }
        });
    };

    const handleDeselectAll = () => {
        affectedParameters.forEach((parameter) => {
            if (parameter === 'prefixOptions') {
                setFieldValue(`settings.properties.templateParameters.${parameter}`, '');
            } else {
                setFieldValue(`settings.properties.templateParameters.${parameter}`, false);
            }
        });
    };

    return (
        <tr>
            <td colSpan={2}>
                <div className="d-flex justify-content-center" style={{ marginLeft: '52px' }}>
                    <Button
                        className="outline-small-button mr-3"
                        variant="light"
                        onClick={handleSelectAll}
                        disabled={isAffectedByCanUseAsWhole(parameters[0])}
                    >
                        {t('document.navbar.template.tabs.settings.templateParameters.btn.selectAll')}
                    </Button>

                    <Button
                        className="outline-small-button"
                        variant="light"
                        onClick={handleDeselectAll}
                        disabled={isAffectedByCanUseAsWhole(parameters[0])}
                    >
                        {t('document.navbar.template.tabs.settings.templateParameters.btn.unselectAll')}
                    </Button>
                </div>
            </td>
        </tr>
    );
}
