import { ChatFill } from 'react-bootstrap-icons';
import { useGetParentCommentsQuery } from '../../../../features/comments/commentApi';
import { idToUri } from '../../../../pages/global/UriHelper';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { TagColor } from './TagColor';

export function CommentButton({ uri, parentUri, handleMetaClick, entity }) {
    const { t } = useTranslation('documents');
    const { comments } = useGetParentCommentsQuery(parentUri, {
        selectFromResult: ({ data }) => ({
            comments: data ? data.filter((comment) => comment.context === uri) : [],
        }),
    });

    const blockIds = entity.blockIds ?? [];
    const unresolvedComments = comments.filter((_comment) => !_comment.resolved);
    const hasUnresolvedComments = unresolvedComments.length > 0;

    return (
        <>
            {comments.length > 0 && (
                <div
                    className="position-absolute cursor-pointer"
                    style={{
                        top: -10,
                        right: -13,
                    }}
                    onClick={() => handleMetaClick('comments')}
                    data-uk-tooltip={t('document.navbar.main.editor.left.blocks.settingsSidebar.tootltips.comments')}
                >
                    <ChatFill
                        size={17}
                        className={cx({
                            'text-info': !hasUnresolvedComments,
                            'text-warning': hasUnresolvedComments,
                        })}
                    />
                    <span
                        className="position-absolute"
                        style={{
                            top: 2,
                            right: -32,
                            fontSize: 7,
                        }}
                    >
                        <TagColor uri={uri} comments={comments} />
                    </span>

                    {unresolvedComments.length > 0 && (
                        <span
                            className="position-absolute text-center"
                            style={{
                                top: 5,
                                right: 3,
                                bottom: 0,
                                left: 0,
                                fontSize: 9,
                                color: 'white',
                            }}
                        >
                            {unresolvedComments.length}
                        </span>
                    )}
                </div>
            )}

            {blockIds.length > 0 && <BlockBalloons blockIds={blockIds} parentUri={parentUri} />}
        </>
    );
}

function BlockBalloons({ blockIds, parentUri }) {
    const uris = blockIds.map((blockId) => idToUri(blockId, 'Block'));
    const { t } = useTranslation('documents');

    const { blockComments } = useGetParentCommentsQuery(parentUri, {
        selectFromResult: ({ data }) => ({
            blockComments: data ? data.filter((comment) => uris.includes(comment.context)) : [],
        }),
    });

    if (blockComments.length === 0) {
        return null;
    }

    const unresolvedComments = blockComments.filter((comment) => !comment.resolved);
    const hasUnresolvedComments = unresolvedComments.length > 0;

    return (
        <div
            className="position-absolute"
            style={{
                bottom: 2,
                right: -9,
            }}
            data-uk-tooltip={t('document.navbar.main.editor.left.blocks.settingsSidebar.tootltips.blockComment')}
        >
            <ChatFill
                size={12}
                className={cx({
                    'text-info': !hasUnresolvedComments,
                    'text-warning': hasUnresolvedComments,
                })}
            />
        </div>
    );
}
