import { useUserProfile } from 'hooks/useUserProfile';
import { UserForm } from './UserForm';
import Constants from '../../../config/Constants';
import { useGetUser } from 'hooks/useGetUser';
import { useTranslation } from 'react-i18next';

export function Profile() {
    const { t } = useTranslation('users');
    const userProfile = useUserProfile();
    const user = useGetUser(userProfile.userId);

    return (
        <div>
            <div className="subheader">
                <h3>{t('profile.myProfile')}</h3>
            </div>

            {user && <UserForm user={user} context={Constants.userFormContext.myProfile} />}
        </div>
    );
}
