import { createSelector, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const translationSlice = createSlice({
    name: 'translation',
    initialState: {
        sidebarFilters: {
            'showDisabledForTranslation': true,
            'variantId': 0,
            'showUntranslated': true,
            'showUnverified': true,
            'showVerified': true,
            'showComplete': true,
            'showIncomplete': true,
            'entity': {},
            'search': '',
            'reimbursement': null,
        },
        selectedEntities: {},
        expandedEntities: {},
    },
    reducers: {
        resetFilters: {
            reducer(state, action) {
                const initialState = translationSlice.getInitialState();
                state.sidebarFilters = initialState.sidebarFilters;
            },
        },
        setFilter: {
            reducer(state, action) {
                const { key, value } = action.payload;
                state.sidebarFilters[key] = value;
            },
        },
        setExpandedEntity: {
            reducer(state, action) {
                const { translationLanguage, activeKey, view = 'default' } = action.payload;
                _.set(state.expandedEntities, `[${translationLanguage}.${view}]`, activeKey);
            },
        },
    },
    extraReducers: {
        ['security/resetState']: (state) => {
            Object.assign(state, translationSlice.getInitialState());
        },
    },
});

export const { resetFilters, setFilter, setExpandedEntity } = translationSlice.actions;

export const getDefaultActiveKey = (translationLanguageId, view = 'default') =>
    createSelector([
            state => state.translation.expandedEntities,
        ],
        (expandedEntities) => (
            _.get(expandedEntities, `[${translationLanguageId}.${view}]`)
        ),
    );

export default translationSlice.reducer;
