import { useGetCommentsQuery } from 'features/comments/commentApi';

export function useGetEntityComments(uri) {
    return useGetCommentsQuery(uri, {
        selectFromResult: ({ data, isLoading }) => ({
            comments: data,
            isLoading,
        }),
    });
}
