import { Modal } from 'react-bootstrap';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { FormFieldWithTitle } from 'pages/publications_v2/helpers/FieldHelper';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { ModalFooter } from 'pages/global/FormModal';
import { useAddChecklistMutation, useUpdateChecklistMutation } from 'features/comments/commentApi';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { WysiwygEditor } from 'pages/task_team/helpers/FormHelper';

export function AddChecklistModal({ team }) {
    const [addChecklist, setAddChecklist] = useQueryParam('addChecklist', BooleanParam);
    const organisationId = useActiveOrganisation();

    if (!addChecklist) {
        return null;
    }

    return (
        <AddModalInner
            checklist={{
                organisationId,
                title: '',
                content: '',
                team: team['@id'],
                brands: [],
                topics: [],
                targetAudiences: [],
            }}
            close={close}
        />
    );

    function close() {
        setAddChecklist(undefined);
    }
}

export function AddModalInner({ checklist, close }) {
    const { t } = useTranslation();
    const [addChecklist] = useAddChecklistMutation();
    const [updateChecklist] = useUpdateChecklistMutation();

    return (
        <>
            <Modal show={true} onHide={close}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {checklist.hasOwnProperty('id') ? 'Checklist bewerken' : 'Checklist toevoegen'}
                    </Modal.Title>
                </Modal.Header>
                <Formik initialValues={checklist} onSubmit={handleSubmit} validationSchema={validationSchema}>
                    {({ isValid, isSubmitting, dirty }) => {
                        return (
                            <Form>
                                <Modal.Body>
                                    <FormFieldWithTitle
                                        label="Titel"
                                        name="title"
                                        props={{ required: true }}
                                        help="De titel van de uitklapper"
                                    />
                                    <WysiwygEditor label="Inhoud" name="content" required={true} />
                                </Modal.Body>

                                <ModalFooter
                                    onHide={close}
                                    isSubmitting={isSubmitting}
                                    dirty={dirty}
                                    isValid={isValid}
                                />
                            </Form>
                        );
                    }}
                </Formik>
            </Modal>
        </>
    );

    function handleSubmit(values) {
        if (values.hasOwnProperty('id')) {
            // Update
            updateChecklist({ uri: values['@id'], body: values }).then(() => {
                close();
            });
        } else {
            // Create
            addChecklist(values).then(() => {
                close();
            });
        }
    }
}

const validationSchema = Yup.object().shape({
    title: Yup.string().required(),
    content: Yup.string().required(),
});
