import { Dropdown, DropdownButton } from 'react-bootstrap';
import HelperFunctions from '../../../global/HelperFunctions';
import { useDispatch, useSelector } from 'react-redux';
import {
    batchDeletePublications,
    fetchPublicationGroups,
    selectAllPublications,
    setSelectedRows,
} from '../../../../features/publications/publicationsSlice';
import _ from 'lodash';
import { comparePublications } from '../../helpers/PublicationActions';
import { Permissions } from '../../../../config/Constants';
import RestrictedContent from '../../../global/RestrictedContent';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'ability/Can';
import { useDownloadPublicationsMutation } from 'features/publications/publicationApi';
import { saveAs } from 'file-saver';
import PublicationApi from 'api/PublicationApi';

export function SelectAllCheckbox({ rowIds = [], groupId }) {
    const allSelectedRows = useSelector((state) => state.publications.selectedRows);
    const dispatch = useDispatch();
    const selectedRows = allSelectedRows.filter((_row) => rowIds.includes(_row));
    const noneSelected = selectedRows.length === 0;
    const allSelected = rowIds.length > 0 && selectedRows.length >= rowIds.length;
    const indeterminate = !noneSelected && !allSelected;

    return (
        <div style={{ marginLeft: 2 }}>
            <div className="custom-control custom-checkbox">
                <input
                    className="custom-control-input"
                    id={`bulk-check-${groupId}`}
                    type="checkbox"
                    disabled={rowIds.length === 0}
                    checked={allSelected}
                    onChange={() => {
                        if (noneSelected || indeterminate) {
                            dispatch(setSelectedRows(_.uniq(_.concat(allSelectedRows, rowIds))));
                        } else {
                            dispatch(
                                setSelectedRows(allSelectedRows.filter((_row) => rowIds.includes(_row) === false))
                            );
                        }
                    }}
                    ref={(el) => el && (el.indeterminate = indeterminate)}
                />
                <label className="custom-control-label" htmlFor={`bulk-check-${groupId}`} />
            </div>
        </div>
    );
}

export function BulkActionsText() {
    const selectedRows = useSelector((state) => state.publications.selectedRows);
    const { t } = useTranslation('publications');

    if (selectedRows.length > 0) {
        return (
            <div className="mr-2 font-weight-bold small">
                <div className="text-color">
                    {selectedRows.length}
                    &nbsp;
                    {selectedRows.length > 1
                        ? t('publications.bulkActions.publicationsPlural')
                        : t('publications.bulkActions.publicationsSingle')}
                    &nbsp;
                    {t('publications.bulkActions.selected')}
                </div>
            </div>
        );
    }

    return null;
}

export function BulkActionsButton({
    setShowPublishModal,
    setShowDuplicateModal,
    setShowKioskBulkEditModal,
    setShowMovePublicationsModal,
}) {
    const allPublications = useSelector((state) => selectAllPublications(state.publications));
    const selectedRows = useSelector((state) => state.publications.selectedRows);
    const dispatch = useDispatch();
    const { t } = useTranslation('publications');
    const ability = useAbility(AbilityContext);

    const noneSelected = selectedRows.length === 0;

    return (
        <DropdownButton
            id="dropdown-bulk"
            title={t('publications.bulkActions.dropDown.title')}
            disabled={noneSelected}
            onSelect={handleAction}
        >
            <RestrictedContent permission={Permissions.Publication['Publish.All']}>
                <Dropdown.Item eventKey="publish">
                    <span className="text-success">{t('publications.bulkActions.dropDown.publish')}&hellip;</span>
                </Dropdown.Item>
                <Dropdown.Divider />
            </RestrictedContent>

            <RestrictedContent permission={Permissions.Publication['Write.All']}>
                <Dropdown.Item eventKey="duplicate" disabled={ability.cannot('create', 'Publication')}>
                    {t('publications.bulkActions.dropDown.duplicate')}&hellip;
                </Dropdown.Item>

                <RestrictedContent permission={Permissions.Kiosk['Write.All']}>
                    <KioskButton />
                </RestrictedContent>

                <Dropdown.Item eventKey="move" disabled={selectedRows.length < 2}>
                    {t('publications.bulkActions.dropDown.move')}
                </Dropdown.Item>

                <Dropdown.Item eventKey="compare" disabled={selectedRows.length !== 2}>
                    {t('publications.bulkActions.dropDown.compare')}
                </Dropdown.Item>
                <Dropdown.Item eventKey="download">{t('publications.bulkActions.dropDown.downloadPDF')}</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item eventKey="delete">
                    <span className="text-danger">{t('publications.bulkActions.dropDown.delete')}&hellip;</span>
                </Dropdown.Item>
            </RestrictedContent>
        </DropdownButton>
    );

    function handleAction(action) {
        if (action === 'publish') {
            setShowPublishModal({
                publications: selectedRows.map((_row) => '/api/publications/' + _row),
                isBatch: true,
            });

            return;
        }

        switch (action) {
            case 'move':
                setShowMovePublicationsModal(true);
                break;
            case 'kiosk_bulk_edit':
                setShowKioskBulkEditModal({
                    publications: allPublications.filter((publication) => selectedRows.includes(publication.id)),
                });

                break;

            case 'duplicate':
                setShowDuplicateModal({
                    publications: selectedRows.map((_row) => '/api/publications/' + _row),
                    folder: undefined,
                });

                break;

            case 'compare':
                comparePublications(selectedRows)
                    .then((response) => {
                        saveAs(response.data, response.headers['x-suggested-filename'] ?? 'export.xlsx');
                    })
                    .catch(async (error) => {
                        if (error.response.status === 400) {
                            const data = JSON.parse(await error.response.data.text());
                            HelperFunctions.alertModal(`Fout: ${data.error}`);
                        } else {
                            HelperFunctions.alertModal('Fout bij maken van vergelijking.');
                        }
                    });

                break;

            case 'delete':
                HelperFunctions.confirmModal(
                    `${t('publications.bulkActions.deleteModal.now')} ${selectedRows.length} ${
                        selectedRows.length > 1
                            ? t('publications.bulkActions.deleteModal.publicationsPlural')
                            : t('publications.bulkActions.deleteModal.publicationSingle')
                    } ${t('publications.bulkActions.deleteModal.delete')}`,
                    'danger',
                    false,
                    t('publications.bulkActions.btn.confirm'),
                    t('btn.cancel')
                )
                    .then(() => {
                        dispatch(batchDeletePublications({ formData: { publications: selectedRows } })).then(() => {
                            // Deselect everything
                            dispatch(setSelectedRows([]));

                            // Reload groups
                            dispatch(fetchPublicationGroups());
                        });
                    })
                    .catch(() => {});

                break;

            case 'download':
                PublicationApi.downloadPublications({publications: selectedRows}).then((response) => {
                    saveAs(response.data, 'publications.zip');
                })

                break;
        }
    }
}

function KioskButton() {
    const selectedRows = useSelector((state) => state.publications.selectedRows);
    const allPublications = useSelector((state) => selectAllPublications(state.publications));

    const canLinkToKiosk = useMemo(() => {
        const selectedPublications = allPublications.filter((publication) => selectedRows.includes(publication.id));
        const domainIds = _.uniq(selectedPublications.map((publication) => publication.domainId));

        return domainIds.length === 1;
    }, [allPublications, selectedRows]);

    return (
        <Dropdown.Item eventKey="kiosk_bulk_edit" disabled={!canLinkToKiosk}>
            Kiosk instellingen&hellip;
        </Dropdown.Item>
    );
}
