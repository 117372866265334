import { useParams } from 'react-router-dom';
import { Form as FForm, Formik } from 'formik';
import { Col, Row } from 'react-bootstrap';
import { LoadingSpinner } from '../../../global/Spinner';
import * as Yup from 'yup';
import { FormField } from '../../../publications_v2/helpers/FieldHelper';
import { useUpdateCategoryMutation } from '../../../../features/reimbursements/reimbursements';
import { useGetCategory } from '../../../../features/reimbursements/reimbursementHooks';
import { WarningButton } from '../../../../components/Buttons';
import { useTranslation } from 'react-i18next';

export default function Properties() {
    const { t } = useTranslation('reimbursements');

    return (
        <>
            <div className="subheader">
                <h3>{t('settings.properties.title')}</h3>
            </div>

            <Row>
                <Col xl={8}>
                    <PropertiesForm />
                </Col>
            </Row>
        </>
    );
}

function PropertiesForm() {
    const { categoryId } = useParams();
    const { category } = useGetCategory(parseInt(categoryId));
    const [updateCategory] = useUpdateCategoryMutation();
    const { t } = useTranslation('reimbursements');

    if (!category) {
        return null;
    }

    const handleSubmit = (values, { setSubmitting }) => {
        updateCategory({ uri: category['@id'], ...values }).then(() => {
            setSubmitting(false);
        });
    };

    return (
        <Formik
            initialValues={{
                name: category.name,
                description: category.description,
            }}
            validationSchema={CategoryPropertiesSchema}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({ dirty, isSubmitting, isValid }) => (
                <FForm autoComplete="off">
                    <FormField name="name" label={t('settings.properties.name')} />

                    <FormField name="description" label={t('settings.properties.description')} />

                    <WarningButton type="submit" disabled={isSubmitting || !dirty || !isValid}>
                        {t('btn.save')}
                    </WarningButton>
                    <LoadingSpinner isSubmitting={isSubmitting} />
                </FForm>
            )}
        </Formik>
    );
}

const CategoryPropertiesSchema = Yup.object().shape({
    name: Yup.string().required(),
});
