import Block from '../BlockParser';
import { useEffect, useState } from 'react';
import DocumentHelper from '../../../global/DocumentHelper';

export default function LeftSideBlocks({ area, areaBlocks, baseBlocks, leftSideProps }) {
    const [blockIdsInLayout, setBlockIdsInLayout] = useState(undefined);

    useEffect(() => {
        if (blockIdsInLayout === undefined && baseBlocks.length > 0) {
            setBlockIdsInLayout(DocumentHelper.getBlockIdsInLayout(baseBlocks));
        }
    }, [blockIdsInLayout, baseBlocks]);

    if (blockIdsInLayout === undefined) {
        return null;
    }

    return baseBlocks
        .filter((_block) => !blockIdsInLayout.includes(_block.key))
        .map((block) => {
            return (
                <Block
                    block={block}
                    areaBlocks={areaBlocks}
                    baseBlocks={baseBlocks}
                    {...leftSideProps}
                    key={area.id + '_' + block.key}
                />
            );
        });
}
