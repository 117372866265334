import { Field, useFormikContext } from 'formik';
import { Form } from 'react-bootstrap';
import { useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import { OpTeamEntryStatusSquare } from '../op_team_entry/OpTeamEntryStatus';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const maxLengthPerFilter = 5;

export function StatusCodes({ statusCodes, disabled = false }) {
    const [expanded, setExpanded] = useState(false);
    const expandable = statusCodes.length > maxLengthPerFilter;

    return (
        <div className="mb-3">
            <div className="font-weight-bold mb-1">Status</div>

            {/*NoTranslate class to prevent google translate from running on these DOM components, because google translate breaks the page when the ExpandableTrigger is clicked. See https://github.com/facebook/react/issues/11538*/}
            <div className="small notranslate">
                <StatusCodeFields statusCodes={statusCodes} disabled={disabled} />

                {expandable && <ExpandableTrigger expanded={expanded} setExpanded={setExpanded} />}

                {expanded && (
                    <StatusCodeFields
                        statusCodes={statusCodes}
                        disabled={disabled}
                        start={5}
                        end={statusCodes.length}
                    />
                )}
            </div>
        </div>
    );
}

function StatusCodeFields({ statusCodes, disabled = false, start = 0, end = 5 }) {
    return (
        <>
            {statusCodes.slice(start, end).map((statusCode) => {
                const statusColor = statusCode?.color ?? '#000';
                const statusLabel = statusCode?.label ?? 'Onbekend';

                return (
                    <div className="mb-2" key={`filter-status-${statusCode.slug}`}>
                        <Field
                            as={Form.Check}
                            type="checkbox"
                            label={
                                <>
                                    <OpTeamEntryStatusSquare
                                        statusColor={statusColor}
                                        statusLabel={statusLabel}
                                        marginLevel={1}
                                        fontSize={'0.65rem'}
                                    />
                                    {statusLabel}
                                </>
                            }
                            id={`filter-status.${statusCode.slug}`}
                            name={`filter-status.${statusCode.slug}`}
                            disabled={disabled}
                        />
                    </div>
                );
            })}
        </>
    );
}

export function Teams({ teams, disabled = false }) {
    const [expanded, setExpanded] = useState(false);
    const expandable = teams.length > maxLengthPerFilter;

    return (
        <div className="mb-3">
            <div className="font-weight-bold mb-1">Team</div>

            {/*NoTranslate class to prevent google translate from running on these DOM components, because google translate breaks the page when the ExpandableTrigger is clicked. See https://github.com/facebook/react/issues/11538*/}
            <div className="small notranslate">
                <TeamFields teams={teams} disabled={disabled} />

                {expandable && <ExpandableTrigger expanded={expanded} setExpanded={setExpanded} />}

                {expanded && <TeamFields teams={teams} start={5} end={teams.length} disabled={disabled} />}
            </div>
        </div>
    );
}

function TeamFields({ teams, disabled = false, start = 0, end = 5 }) {
    return (
        <>
            {teams.slice(start, end).map((team) => (
                <div className="mb-2" key={`filter-team-${team.id}`}>
                    <TeamField team={team} disabled={disabled} />
                </div>
            ))}
        </>
    );
}

function TeamField({ team, disabled = false }) {
    return (
        <Field
            as={Form.Check}
            type="checkbox"
            label={team.name}
            id={`filter-team.${team['@id']}`}
            name={`filter-team.${team['@id']}`}
            disabled={disabled}
        />
    );
}

export function Processors({ departmentUsers, disabled = false }) {
    const [expanded, setExpanded] = useState(false);
    const expandable = departmentUsers.length > maxLengthPerFilter;

    return (
        <div className="mb-3">
            <div className="font-weight-bold mb-1">Behandelaar</div>

            {/*NoTranslate class to prevent google translate from running on these DOM components, because google translate breaks the page when the ExpandableTrigger is clicked. See https://github.com/facebook/react/issues/11538*/}
            <div className="small notranslate">
                <ProcessorFields departmentUsers={departmentUsers} disabled={disabled} />

                {expandable && <ExpandableTrigger expanded={expanded} setExpanded={setExpanded} />}

                {expanded && (
                    <ProcessorFields
                        departmentUsers={departmentUsers}
                        disabled={disabled}
                        start={5}
                        end={departmentUsers.length}
                    />
                )}
            </div>
        </div>
    );
}

function ProcessorFields({ departmentUsers, disabled = false, start = 0, end = 5 }) {
    return (
        <>
            {departmentUsers.slice(start, end).map((user) => (
                <div className="mb-2" key={`filter-user-${user.id}`}>
                    <ProcessorField user={user} disabled={disabled} />
                </div>
            ))}
        </>
    );
}

function ProcessorField({ user, disabled = false }) {
    const { values } = useFormikContext();

    // Get array of IDs from the form values
    const selectedTeams = _.flow([
        Object.entries,
        (arr) => arr.filter(([key, value]) => value === true),
        (arr) => arr.map(([key]) => key),
    ])(values['filter-team']);

    // Check if user is in the selected team (if any selected)
    let userInTeam = true;

    if (selectedTeams.length > 0) {
        userInTeam = _.intersection(user.opTeams, selectedTeams).length > 0;
    }

    return (
        <Field
            as={Form.Check}
            type="checkbox"
            label={`${user?.securityUser?.firstName ?? ''} ${user?.securityUser?.lastName ?? ''}`}
            id={`filter-user.${user.userId}`}
            name={`filter-user.${user.userId}`}
            disabled={disabled || !userInTeam}
        />
    );
}

export function Labels({ labels, disabled = false }) {
    const [expanded, setExpanded] = useState(false);
    const expandable = labels.length > maxLengthPerFilter;

    return (
        <div className="mb-3">
            <div className="font-weight-bold mb-1">Zorgsoorten</div>

            {/*NoTranslate class to prevent google translate from running on these DOM components, because google translate breaks the page when the ExpandableTrigger is clicked. See https://github.com/facebook/react/issues/11538*/}
            <div className="small notranslate">
                <LabelFields labels={labels} disabled={disabled} />

                {expandable && <ExpandableTrigger expanded={expanded} setExpanded={setExpanded} />}

                {expanded && <LabelFields labels={labels} disabled={disabled} start={5} end={labels.length} />}
            </div>
        </div>
    );
}

function LabelFields({ labels, disabled = false, start = 0, end = 5 }) {
    return (
        <>
            {labels.slice(start, end).map((label) => (
                <div className="mb-2" key={`filter-label-${label.id}`}>
                    <Field
                        as={Form.Check}
                        type="checkbox"
                        label={label.name}
                        id={`filter-label-${label['@id']}`}
                        name={`filter-label.${label['@id']}`}
                        disabled={disabled}
                    />
                </div>
            ))}
        </>
    );
}

export function TypeOfEdit({ typeOfEdits, disabled = false }) {
    const [expanded, setExpanded] = useState(false);
    const expandable = Object.keys(typeOfEdits).length > maxLengthPerFilter;
    const { t } = useTranslation('changelist');

    return (
        <div className="mb-3">
            <div className="font-weight-bold mb-1">{t('changelist.subnav.settings.sidebar.typeOfChange')}</div>

            {/*NoTranslate class to prevent google translate from running on these DOM components, because google translate breaks the page when the ExpandableTrigger is clicked. See https://github.com/facebook/react/issues/11538*/}
            <div className="small notranslate">
                <TypeOfEditFields typeOfEdits={typeOfEdits} disabled={disabled} />

                {expandable && <ExpandableTrigger expanded={expanded} setExpanded={setExpanded} />}

                {expanded && (
                    <TypeOfEditFields
                        typeOfEdits={typeOfEdits}
                        disabled={disabled}
                        start={5}
                        end={Object.keys(typeOfEdits).length}
                    />
                )}
            </div>
        </div>
    );
}

function TypeOfEditFields({ typeOfEdits, disabled = false, start = 0, end = 5 }) {
    const { t } = useTranslation('changelist');

    return (
        <>
            {Object.keys(typeOfEdits)
                .slice(start, end)
                .map((_type) => (
                    <FilterField
                        id={`filter-type.${_type}`}
                        label={t('changelist.view.opEntry.typeOfEdits.' + _type)}
                        disabled={disabled}
                        name={`filter-type.${_type}`}
                        key={`filter-type-${_type}`}
                    />
                ))}
        </>
    );
}

function FilterField({ id, label, disabled = false, name }) {
    return (
        <div className="mb-2">
            <Field as={Form.Check} type="checkbox" label={label} id={id} name={name} disabled={disabled} />
        </div>
    );
}

export function ExpandableTrigger({ setExpanded, expanded = false }) {
    const { t } = useTranslation('changelist');

    return (
        <div
            className="text-primary link-underlined mb-2"
            onClick={() => {
                setExpanded(!expanded);
            }}
        >
            {expanded ? (
                <>
                    <ChevronUp className="mr-2" />
                    {t('changelist.subnav.settings.sidebar.btn.viewLess')}
                </>
            ) : (
                <>
                    <ChevronDown className="mr-2" />
                    {t('changelist.subnav.settings.sidebar.btn.viewMore')}
                </>
            )}
        </div>
    );
}

export const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
        return {
            ...obj,
            [item[key]]: false,
        };
    }, initialValue);
};
