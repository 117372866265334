import { getSelectedGroupId } from '../Index';
import { useDispatch, useSelector } from 'react-redux';
import {
    batchEditPublications,
    editPublications,
    selectPublicationGroupById,
    selectPublications,
} from '../../../../features/publications/publicationsSlice';
import LoadingSpinner from '../../../global/LoadingSpinner';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import cx from 'classnames';
import React, { useState } from 'react';
import { SelectAllCheckbox } from '../index/BulkActions';
import { RootPublications } from './RootPublications';
import GroupPublications from './Groups';
import _ from 'lodash';
import HelperFunctions from '../../../global/HelperFunctions';
import { Permissions } from '../../../../config/Constants';
import RestrictedContent from '../../../global/RestrictedContent';
import { Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default function PublicationsWrapper({
    setShowPublishModal,
    isLoading,
    setShowExportChangesModal,
    setShowDuplicateModal,
    handleMove,
}) {
    const allPublications = useSelector(selectPublications());
    const [isDragging, setDragging] = useState(false);
    const dispatch = useDispatch();

    const onDragEnd = (result) => {
        setDragging(false);
        const { destination, source } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        const sourceGroup = source.droppableId === 'main' ? null : source.droppableId;
        const destinationGroup = destination.droppableId === 'main' ? null : destination.droppableId;

        // Sort within group
        if (sourceGroup === destinationGroup) {
            let newPublications = _.cloneDeep(
                allPublications
                    .filter((_pub) => _pub.publicationGroup === destinationGroup)
                    .sort(HelperFunctions.dynamicSort('sortOrder'))
            );

            const [removed] = newPublications.splice(source.index, 1);
            newPublications.splice(destination.index, 0, removed);
            newPublications = newPublications.map((_pub, index) => {
                return {
                    ..._pub,
                    sortOrder: index,
                };
            });

            const changes = newPublications.map((_pub) => {
                return {
                    id: _pub.id,
                    changes: {
                        sortOrder: _pub.sortOrder,
                    },
                };
            });

            // Update local state first to prevent flickering
            dispatch(editPublications(changes));

            // Update APi
            dispatch(
                batchEditPublications({
                    formData: {
                        publications: changes,
                    },
                })
            );
        } else {
            // Remove from old group
            let oldPublications = _.cloneDeep(
                allPublications
                    .filter((_pub) => _pub.publicationGroup === sourceGroup)
                    .sort(HelperFunctions.dynamicSort('sortOrder'))
            );
            const [removed] = oldPublications.splice(source.index, 1);

            if (!removed) {
                return;
            }

            // Add to new group
            removed.publicationGroup = destinationGroup;
            let newPublications = _.cloneDeep(
                allPublications
                    .filter((_pub) => _pub.publicationGroup === destinationGroup)
                    .sort(HelperFunctions.dynamicSort('sortOrder'))
            );
            newPublications.splice(destination.index, 0, removed);

            newPublications = newPublications.map((_pub, index) => {
                return {
                    ..._pub,
                    sortOrder: index,
                };
            });

            const changes = newPublications.map((_pub) => {
                return {
                    id: _pub.id,
                    changes: {
                        publicationGroup: _pub.publicationGroup,
                        sortOrder: _pub.sortOrder,
                    },
                };
            });

            // Update local state first to prevent flickering
            dispatch(editPublications(changes));

            // Update APi
            dispatch(
                batchEditPublications({
                    formData: {
                        publications: changes,
                    },
                })
            );
        }
    };

    return (
        <DragDropContext onDragEnd={onDragEnd} onDragStart={() => setDragging(true)}>
            <div
                className={cx('data-table data-table-publications mt-3', {
                    'is-dragging': isDragging,
                })}
            >
                <PublicationsWrapperInner
                    setShowExportChangesModal={setShowExportChangesModal}
                    setShowPublishModal={setShowPublishModal}
                    isLoading={isLoading}
                    setShowDuplicateModal={setShowDuplicateModal}
                    handleMove={handleMove}
                />
            </div>
        </DragDropContext>
    );
}

function PublicationsWrapperInner({
    setShowPublishModal,
    setShowExportChangesModal,
    isLoading,
    setShowDuplicateModal,
    handleMove,
}) {
    const groupId = getSelectedGroupId(true);

    if (groupId === '0') {
        return (
            <RootPublications
                setShowPublishModal={setShowPublishModal}
                setShowExportChangesModal={setShowExportChangesModal}
                isLoading={isLoading}
                setShowDuplicateModal={setShowDuplicateModal}
                handleMove={handleMove}
            />
        );
    }

    return (
        <>
            <div className="dr-container ">
                <GroupTitle />
                <Publications
                    setShowPublishModal={setShowPublishModal}
                    setShowExportChangesModal={setShowExportChangesModal}
                    isLoading={isLoading}
                    setShowDuplicateModal={setShowDuplicateModal}
                />
            </div>
        </>
    );
}

function GroupTitle() {
    const groupId = getSelectedGroupId();
    const folder = useSelector((state) => selectPublicationGroupById(state.publications, groupId));

    if (folder === undefined) {
        return null;
    }

    return (
        <div
            className="data-table-group-header d-flex align-items-center"
            style={{
                fontSize: '14px',
                backgroundColor: '#f3f5f7',
                fontWeight: 500,
                padding: '0.55rem 0 0.55rem 1.2rem',
            }}
        >
            <div className="mr-2">{folder?.name ?? ''}</div>
        </div>
    );
}

function Publications({ setShowPublishModal, isLoading, setShowExportChangesModal, setShowDuplicateModal }) {
    const groupId = getSelectedGroupId(true);
    const groupIdAsNumber = getSelectedGroupId();
    const publications = useSelector(selectPublications(groupId === '0' ? null : groupId));
    const { t } = useTranslation('publications');

    if (isLoading && publications.length === 0) {
        return <LoadingSpinner />;
    }

    return (
        <div className="mb-4">
            <Droppable droppableId={groupId}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className={cx('data-table-group', 'data-table-group-level-1', {
                            'dragging-over': snapshot.isDraggingOver,
                        })}
                    >
                        <GroupHeader publications={publications} groupId={groupIdAsNumber} />

                        <GroupPublications
                            publications={publications}
                            setShowExportChangesModal={setShowExportChangesModal}
                            setShowPublishModal={setShowPublishModal}
                            setShowDuplicateModal={setShowDuplicateModal}
                        />

                        {!isLoading && publications.length === 0 && (
                            <div className="p-3">
                                <div
                                    className="small text-secondary"
                                    style={{ lineHeight: '21px', marginLeft: '33px' }}
                                >
                                    <span style={{ marginLeft: '30px' }}>{t('publications.noPublications')}</span>
                                </div>
                            </div>
                        )}

                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </div>
    );
}

export function GroupHeader({ publications = [], groupId }) {
    const rowIds = publications.map((_pub) => _pub.id);
    const { t } = useTranslation('publications');

    const hasMulti = publications.some((publication) => publication.multiVersions);

    return (
        <div className="d-flex align-items-center p-2">
            <RestrictedContent permission={Permissions.Publication['Write.All']}>
                <div style={{ width: 54 }}>
                    <div style={{ paddingLeft: 28, marginTop: 8 }}>
                        <SelectAllCheckbox rowIds={rowIds} groupId={groupId} />
                    </div>
                </div>
            </RestrictedContent>

            <Container fluid className="small font-weight-bolder pt-2">
                <Row>
                    <div
                        className={cx({
                            'col-md-5': !hasMulti,
                            'col-md-4': hasMulti,
                        })}
                    >
                        <div className="d-flex align-items-center pl-2">
                            <div className="text-muted">{t('publications.columnTitles.name')}</div>
                        </div>
                    </div>

                    <div className="col-md-2 text-muted">{t('publications.columnTitles.status')}</div>

                    {hasMulti && (
                        <div className="col-md-2 text-muted">
                            {t('publications.columnTitles.publishedVersions')}
                        </div>
                    )}

                    <div
                        className={cx('text-muted', {
                            'col-md-5': !hasMulti,
                            'col-md-4': hasMulti,
                        })}
                    >
                        {t('publications.columnTitles.document')}
                    </div>
                </Row>
            </Container>
        </div>
    );
}
