import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { useGetTaskUsersQuery } from 'features/comments/commentApi';
import { useGetOrganisationUsers } from 'hooks/useGetOrganisationUsers';
import HelperFunctions from 'pages/global/HelperFunctions';

export function useGetTaskUsers(role) {
    const organisationId = useActiveOrganisation();
    const organisationUsers = useGetOrganisationUsers(true);

    const selectTaskUsers = useMemo(() => {
        const emptyArray = [];

        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (res) => res.data,
            (res, organisationId) => organisationId,
            (data, organisationId) => {
                if (!data || organisationUsers.length === 0) {
                    return emptyArray;
                }

                return data
                    .filter((taskUser) => taskUser.organisationId === organisationId)
                    .map((taskUser) => {
                        const organisationUser = organisationUsers.find(
                            (organisationUser) => organisationUser.id === taskUser.userId,
                        );

                        return {
                            ...taskUser,
                            fullName: organisationUser?.fullName ?? '',
                        };
                    })
                    .sort(HelperFunctions.sortByString('fullName'));
            },
        );
    }, [organisationUsers]);

    const { taskUsers } = useGetTaskUsersQuery(role, {
        selectFromResult: (result) => ({
            taskUsers: selectTaskUsers(result, organisationId),
        }),
    });

    return taskUsers;
}
