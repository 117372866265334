import Tooltip from '../../global/Tooltip';
import { Button } from 'react-bootstrap';
import { InfoCircle, X } from 'react-bootstrap-icons';
import { EditContext } from './view/EditContextWrapper';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import {
    useGetDocumentVariantsQuery,
    useGetTranslationContainerQuery,
} from '../../../features/translations/translationApi';
import { filterDeep } from 'deepdash-es/standalone';
import _ from 'lodash';
import cx from 'classnames';
import { entityTypes, translationContainerTypes } from '../config/Constants';
import ReimbursementProductProperties from './edit/reimbursementContainer/ReimbursementProductProperties';
import TagItemProperties from './edit/documentContainer/TagItemProperties';
import { ReimbursementProductFieldProperties } from './edit/reimbursementContainer/ReimbursementProductFieldProperties';
import { useTranslation } from 'react-i18next';

export default function ContextSidebar() {
    const { selectedEntities, showSidebar } = useContext(EditContext);
    const selectedEntity = selectedEntities[0];
    const numberOfSelectedEntities = selectedEntities.length;
    const { t } = useTranslation('translations');

    return (
        <div
            style={{
                width: showSidebar ? 320 : 0,
                backgroundColor: '#fff',
                transition: 'width 300ms cubic-bezier(0.4,0.0,0.2,1)',
                borderLeft: '1px solid #e8ecf0',
            }}
        >
            <div className="d-flex flex-column align-items-stretch h-100">
                <>
                    <div className="d-flex align-items-center p-4">
                        <div
                            className="ml-2"
                            style={{
                                color: '#255478',
                                fontFamily: '"Poppins", sans-serif',
                                fontSize: 22,
                                lineHeight: '24px',
                                wordWrap: 'break-word',
                            }}
                        >
                            {numberOfSelectedEntities === 1 ? (
                                <>{selectedEntity.entity.title}</>
                            ) : (
                                <>{t('translation.navbar.dashboard.document.actions.contextSidebar.details')}</>
                            )}
                        </div>

                        <CloseButton />
                    </div>

                    <div
                        className="px-3"
                        style={{
                            borderBottom: '1px solid #e8ecf0',
                        }}
                    >
                        <div
                            className="text-color py-2 d-inline-block"
                            style={{
                                borderBottom: '2px solid #0073d5',
                                fontWeight: 500,
                                paddingBottom: 4,
                                paddingLeft: '2rem',
                                paddingRight: '2rem',
                            }}
                        >
                            {t('translation.navbar.dashboard.document.actions.contextSidebar.details')}
                        </div>
                    </div>

                    <div
                        className="flex-grow-1 overflow-auto pl-3 pr-4 pt-4 pb-3"
                        style={{
                            fontSize: 13,
                        }}
                    >
                        {numberOfSelectedEntities === 1 && <EntityProperties selectedEntity={selectedEntity} />}

                        {numberOfSelectedEntities === 0 && (
                            <div style={{ color: '#555555' }}>
                                {t('translation.navbar.dashboard.document.actions.contextSidebar.selectItem')}
                            </div>
                        )}

                        {numberOfSelectedEntities > 1 && (
                            <div style={{ color: '#555555' }}>
                                {t('translation.navbar.dashboard.document.actions.contextSidebar.multiple')}
                            </div>
                        )}
                    </div>
                </>
            </div>
        </div>
    );
}

export function ToggleSidebarButton() {
    const { showSidebar, setShowSidebar } = useContext(EditContext);
    const { t } = useTranslation('translations');

    return (
        <Tooltip
            tooltip={
                showSidebar
                    ? t('translation.navbar.dashboard.document.actions.info.hideDetails')
                    : t('translation.navbar.dashboard.document.actions.info.showDetails')
            }
            placement="bottom"
        >
            <Button
                variant="icon"
                className={cx('btn-icon-only p-1', {
                    'text-muted': !showSidebar,
                })}
                onClick={() => {
                    setShowSidebar(!showSidebar);
                }}
            >
                <InfoCircle size={20} />
            </Button>
        </Tooltip>
    );
}

function CloseButton() {
    const { setShowSidebar } = useContext(EditContext);
    const { t } = useTranslation('translations');

    return (
        <div className="ml-auto">
            <Tooltip tooltip={t('translation.navbar.dashboard.document.actions.tooltip.details')} placement="bottom">
                <Button
                    variant="icon"
                    className="btn-icon-only text-secondary p-1"
                    style={{ minWidth: 40 }}
                    onClick={() => {
                        setShowSidebar(false);
                    }}
                >
                    <X size={30} />
                </Button>
            </Tooltip>
        </div>
    );
}

function DocumentVariants({ entity }) {
    const { translationContainerId } = useParams();
    const { translationContainer } = useGetTranslationContainerQuery(translationContainerId, {
        selectFromResult: ({ data }) => ({
            translationContainer: data,
        }),
    });
    const { t } = useTranslation('translations');

    const validContainer =
        translationContainer !== undefined && translationContainer['@type'] === translationContainerTypes.DOCUMENT;

    const { document } = useGetDocumentVariantsQuery(translationContainer?.documentId, {
        selectFromResult: ({ data }) => ({
            document: data,
        }),
        skip: !validContainer,
    });

    if (!validContainer || document === undefined) {
        return null;
    }

    const filteredVariants = filterDeep(
        [document.documentVariants],
        ({ id, variantGroup, enabled }, key, parent) => {
            if (variantGroup === true || enabled === false) {
                return undefined;
            }

            return entity.documentVariants.includes(id) ? true : undefined;
        },
        {
            childrenPath: 'children',
        }
    );

    if (_.isEmpty(filteredVariants)) {
        return null;
    }

    const baseVariant = filteredVariants[0];

    return (
        <div>
            <div
                style={{
                    flex: '0 0 100px',
                    color: '#5f6368',
                }}
                className=""
                role="cell"
            >
                {t('translation.navbar.dashboard.document.actions.contextSidebar.variants')}
            </div>

            {baseVariant.children.map((_variant) => (
                <DocumentVariant variant={_variant} key={`variant-${_variant.id}`} />
            ))}
        </div>
    );
}

function DocumentVariant({ variant }) {
    const { name, children = [], prefix, variantGroup } = variant;
    const { t } = useTranslation('translations');

    return (
        <div>
            <div
                className={cx('mb-2', {
                    'font-weight-bold text-primary pt-2': variantGroup,
                })}
                style={{
                    padding: '2px 0',
                }}
            >
                {!_.isEmpty(prefix) && (
                    <span
                        style={{
                            fontSize: 11,
                        }}
                        className="text-muted text-uppercase font-weight-bold mr-1"
                    >
                        {prefix}
                    </span>
                )}

                {name === 'default'
                    ? t('translation.navbar.dashboard.document.actions.contextSidebar.basicVariants')
                    : name}
            </div>

            {children.length > 0 && (
                <div
                    className="mb-3"
                    style={{
                        borderLeft: '2px solid #e5e5e5',
                        paddingLeft: '1.125rem',
                    }}
                >
                    {children.map((_childVariant) => (
                        <DocumentVariant variant={_childVariant} key={`variant-${_childVariant.id}`} />
                    ))}
                </div>
            )}
        </div>
    );
}

function EntityProperties({ selectedEntity }) {
    const { entity, entityReference } = selectedEntity;
    const { entityType } = entityReference;

    return (
        <>
            {entity.hasOwnProperty('documentVariants') && <DocumentVariants entity={entity} />}

            {entityType === entityTypes.REIMBURSEMENT_PRODUCT && (
                <ReimbursementProductProperties selectedEntity={selectedEntity} />
            )}

            {entityType === entityTypes.REIMBURSEMENT_PRODUCT_FIELD && (
                <ReimbursementProductFieldProperties selectedEntity={selectedEntity} />
            )}

            {entityType === entityTypes.TAG_ITEM && <TagItemProperties selectedEntity={selectedEntity} />}
        </>
    );
}
