import cx from 'classnames';
import { PubPreviewsVersions } from './PubPreviewsVersions';

export function PublicationRowDetails({
    publication,
    setShowPublishModal,
    setShowExportChangesModal,
    setShowDuplicateModal,
}) {
    return (
        <div className="expanded-row-details">
            <div className="px-1">
                <PubPreviewsVersions
                    publication={publication}
                    setShowExportChangesModal={setShowExportChangesModal}
                    setShowPublishModal={setShowPublishModal}
                    setShowDuplicateModal={setShowDuplicateModal}
                />
            </div>
        </div>
    );
}

export function Action({ tooltip, children, disabled = false, handleClick, variant = 'btn-outline-primary' }) {
    const btnClass = cx('d-flex align-items-center btn btn-sm mr-1', variant);

    return (
        <button
            type="button"
            className={btnClass}
            data-uk-tooltip={tooltip}
            disabled={disabled}
            onClick={() => handleClick()}
        >
            {children}
        </button>
    );
}
