import EntityTranslation from 'pages/translation/views/translate/EntityTranslation';
import { entityTypes } from 'pages/translation/config/Constants';
import HelperFunctions from 'pages/global/HelperFunctions';
import MediaSelector, { getFileUri, isValidUri, MediaPreview } from 'pages/media_library/views/MediaSelector';
import { useContext } from 'react';
import { FormikContext } from 'formik';

export function FileBlock({ block, languageIso, handleTranslationMutation, translationContainer }) {
    return (
        <EntityTranslation
            content={block.baseContent}
            languageIso={languageIso}
            entityUri={`/api/${entityTypes.BLOCK}/${block.id}`}
            translationContainer={translationContainer}
            entityType={entityTypes.BLOCK}
            entityId={block.id}
            property="uri"
            entity={{
                ...block,
                title: 'Afbeelding',
            }}
            handleTranslationMutation={handleTranslationMutation}
        />
    );
}

export function RenderFileBlock({ content }) {
    const file = HelperFunctions.tryParseJSON(content, null);
    const fileUri = getFileUri(_.get(file, 'uri', ''));
    const validUri = isValidUri(fileUri);

    if (!validUri) {
        return <div className="text-muted">Geen vertaling aanwezig</div>;
    }

    return <MediaPreview uri={fileUri} readOnly={true} />;
}

export function EditFileTranslationContent({ content }) {
    const file = HelperFunctions.tryParseJSON(content, null);
    const { values, setFieldValue } = useContext(FormikContext);
    const { translation } = values;

    const translatedFile = HelperFunctions.tryParseJSON(translation, undefined);

    return (
        <div className="mb-4">
            <MediaSelector selectedValue={translatedFile?.uri} name="translation" onChange={handleChange} />
        </div>
    );

    function handleChange(newFile) {
        if (newFile) {
            setFieldValue(
                'translation',
                JSON.stringify({
                    ...file,
                    uri: newFile['@id'],
                }),
            );
        } else {
            setFieldValue('translation', '');
        }
    }
}
