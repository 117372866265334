import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteFile, patchFile, updateFile } from '../../../../features/mediaLibrary/mediaLibrarySlice';
import { Row } from 'react-bootstrap';
import { CheckCircleFill, CircleFill, PlusCircleFill, ReplyFill, TrashFill } from 'react-bootstrap-icons';
import HelperFunctions from '../../../global/HelperFunctions';
import DraggableItem from '../DraggableItem';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParam } from 'use-query-params';

export default function Files({ files, toggleSelection, selectedFiles, setSelectedFiles }) {
    return (
        <Row>
            <ul className={cx('dnd-list-grid', { 'has-selection': selectedFiles.length > 0 })}>
                {files.length > 0 &&
                    files.map((_file) => {
                        return (
                            <File
                                file={_file}
                                toggleSelection={toggleSelection}
                                selectedFiles={selectedFiles}
                                setSelectedFiles={setSelectedFiles}
                                key={`file-${_file.id}`}
                            />
                        );
                    })}
            </ul>
        </Row>
    );
}

function File({ file, toggleSelection, selectedFiles, setSelectedFiles }) {
    const selectedFolderId = useSelector((state) => state.mediaLibrary.selectedFolder);
    const isSelected = selectedFiles.includes(file['@id']);

    // Don't display as draggable when in folder
    if (selectedFolderId !== null) {
        return (
            <li className={cx({ selected: isSelected })}>
                <FileCard
                    file={file}
                    toggleSelection={toggleSelection}
                    setSelectedFiles={setSelectedFiles}
                    isSelected={isSelected}
                    numberOfSelectedFiles={selectedFiles.length}
                />
            </li>
        );
    }

    return (
        <DraggableItem uri={file['@id']} disabled={selectedFiles.length > 0} isSelected={isSelected}>
            <FileCard
                file={file}
                toggleSelection={toggleSelection}
                setSelectedFiles={setSelectedFiles}
                isSelected={isSelected}
                numberOfSelectedFiles={selectedFiles.length}
            />
        </DraggableItem>
    );
}

function FileCard({ file, toggleSelection, setSelectedFiles, isSelected, numberOfSelectedFiles }) {
    const { t } = useTranslation('media');
    const dispatch = useDispatch();
    const [view, setView] = useQueryParam('view', StringParam);

    const toggleFile = () => {
        if (isSelected) {
            toggleSelection(file['@id']);
        } else {
            setSelectedFiles([file['@id']]);
        }
    };

    const confirmFileDelete = (e) => {
        e.stopPropagation();

        HelperFunctions.confirmModal(file.displayName + ' verwijderen?', 'danger', false).then(() => {
            if (isSelected) {
                toggleSelection(file['@id']);
            }

            dispatch(deleteFile({ uri: file['@id'] }));
        });
    };

    const restoreFile = (e) => {
        e.stopPropagation();

        if (isSelected) {
            toggleSelection(file['@id']);
        }

        dispatch(
            updateFile({
                id: file['@id'],
                changes: {
                    deletedAt: null,
                    folder: null,
                },
            })
        );

        // Update API
        dispatch(
            patchFile({
                uri: file['@id'],
                formData: {
                    deletedAt: null,
                    folder: null,
                },
            })
        );
    };

    const openModal = (e) => {
        e.stopPropagation();
        setView(file['@id']);
    };

    return (
        <>
            <span
                onClick={(e) => {
                    e.stopPropagation();
                    toggleFile();
                }}
            >
                <span
                    className="file-header d-flex align-items-center justify-content-between position-absolute p-2"
                    style={{
                        left: 0,
                        right: 0,
                        zIndex: 150,
                    }}
                >
                    <button
                        data-uk-tooltip="Selecteren"
                        className="btn btn-link btn-media-select p-0"
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleSelection(file['@id']);
                        }}
                    >
                        {isSelected ? (
                            <CheckCircleFill size={18} />
                        ) : (
                            <CircleFill className="circle-bg text-white" size={18} />
                        )}
                    </button>

                    <button
                        className="btn btn-link btn-media-select p-0"
                        data-uk-tooltip={t('fileModal.tooltip')}
                        onClick={openModal}
                    >
                        <PlusCircleFill className="text-secondary" size={16} />
                    </button>

                    {file.deletedAt === null && numberOfSelectedFiles < 2 && (
                        <button
                            className="btn btn-link text-danger p-0"
                            data-uk-tooltip="Verwijderen"
                            onClick={confirmFileDelete}
                        >
                            <TrashFill size={16} />
                        </button>
                    )}
                    {file.deletedAt !== null && (
                        <button
                            className="btn btn-link text-primary p-0"
                            data-uk-tooltip="Terugzetten"
                            onClick={restoreFile}
                        >
                            <ReplyFill size={18} />
                        </button>
                    )}
                </span>

                <FileContent file={file} />
            </span>
        </>
    );
}

function FileContent({ file }) {
    return (
        <button
            className="file-content"
            style={{
                width: '100%',
                height: 200,
                backgroundColor: '#e8ecf0',
                backgroundImage: `url("${file.thumbnail}")`,
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                outline: 'none',
                border: 'none',
                borderRadius: 3,
                position: 'relative',
            }}
        >
            <span
                className=" text-left text-darkBlueAlt small"
                style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    backgroundColor: '#ffffff',
                    padding: '8px',
                    borderRadius: '0 0 3px 3px',
                    maxHeight: 'calc(100% - 100px)',
                    overflow: 'hidden',
                    lineHeight: '1.2',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    wordBreak: 'break-all', // Allow breaking at the letter level
                }}
            >
                {file.description && <span>{file.description}</span>}
                <div style={{ color: '#a0a2b3' }}>{file.displayName}</div>
            </span>
        </button>
    );
}

export function FileDraggingContent({ file }) {
    const getItemStyle = {
        width: 150,
        height: 200,
        backgroundImage: `url("${file.thumbnail}")`,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        transform: 'scale3d(0.75, 0.75, 1)',
    };

    return <button className="file-content img-thumbnail" style={getItemStyle} />;
}
