import { createSlice } from '@reduxjs/toolkit';

const appSlice = createSlice({
    name: 'app',
    initialState: {
        sidebarContext: null,
        sidebarEntityUpdate: undefined,
        blockEditModal: undefined,
        blockImprovementContext: {
            option1: 'B1',
            option2: 'formele',
        },
    },
    reducers: {
        set: {
            reducer(state, action) {
                const { key, value } = action.payload;
                state[key] = value;
            },
        },
    },
    extraReducers: {
        ['security/resetState']: (state) => {
            Object.assign(state, appSlice.getInitialState());
        },
    },
});

export const { set } = appSlice.actions;

export default appSlice.reducer;
