import { DocumentVariantSelect, FieldSelect } from '../../helpers/FieldHelper';
import HelperFunctions from '../../../global/HelperFunctions';
import _ from 'lodash';
import RestrictedContent from '../../../global/RestrictedContent';
import Constants from '../../../../config/Constants';
import SectionTranslation from './SectionTranslation';
import { useGetDocuments } from '../../../documents_v2/hooks/useGetDocuments';
import { useGetDocument } from '../../../documents_v2/hooks/useGetDocument';
import { PublicationProperty, templateProperties } from '../PublicationProperty';
import { useDocumentTemplate } from '../../hooks/useDocumentTemplate';
import { useTranslation } from 'react-i18next';
import { useGetBaseVariant } from '../../../documents_v2/hooks/useGetBaseVariant';

export default function BackCover({ index, contentItem }) {
    const documents = useGetDocuments();
    const document = useGetDocument(contentItem.documentId, false);
    const documentTemplate = useDocumentTemplate();
    const { t } = useTranslation('publications');

    const templateOptions =
        documentTemplate?.backCoverTemplates.map((_template) => {
            return {
                label: _template,
                value: _template,
            };
        }) ?? [];

    return (
        <>
            <FieldSelect
                name={`contentDecoded.${index}.backCoverTemplate`}
                label={`${t('publication.settings.content.summary.expand.backCover.template')}*`}
                options={templateOptions}
            />
            <FieldSelect
                label="Document*"
                name={`contentDecoded.${index}.documentId`}
                options={HelperFunctions.prepareDropdownData(documents)}
                props={{
                    placeholder: `${t('publication.settings.content.summary.expand.backCover.titleSelect')}...`,
                }}
            />

            <VariantSelect contentItem={contentItem} index={index} />

            {document && (
                <RestrictedContent module={Constants.modules.translations}>
                    <PublicationProperty property={templateProperties.backCover.translate}>
                        <SectionTranslation
                            contentItem={contentItem}
                            index={index}
                            document={document}
                            showReimbursements={false}
                        />
                    </PublicationProperty>
                </RestrictedContent>
            )}
        </>
    );
}

function VariantSelect({ index, contentItem }) {
    const { documentId } = contentItem;
    const baseVariant = useGetBaseVariant(documentId);
    const isLoading = !baseVariant;
    const { t } = useTranslation('publications');

    return (
        <DocumentVariantSelect
            name={`contentDecoded.${index}.variantId`}
            label="Variant*"
            baseVariant={baseVariant}
            props={{
                placeholder:
                    isLoading && _.isInteger(documentId)
                        ? `${t('publication.settings.content.summary.expand.backCover.busyLoading')}...`
                        : `${t('publication.settings.content.summary.expand.backCover.titleSelect')}...`,
                isDisabled: isLoading,
            }}
        />
    );
}
