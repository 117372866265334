import { Dropdown, Nav } from 'react-bootstrap';
import { CaretRightFill, CollectionFill, PlusCircle, ThreeDotsVertical, TrashFill } from 'react-bootstrap-icons';
import { generatePath, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import Constants, { Permissions } from '../../../../config/Constants';
import { useState } from 'react';
import { INDEX_PATH } from '../../../../scenes/DocumentsV2';
import _ from 'lodash';
import HelperFunctions from '../../../global/HelperFunctions';
import cx from 'classnames';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import RestrictedContent from '../../../global/RestrictedContent';
import DropdownMenu from '../../../global/DropdownMenu';
import { IconButton } from '../../../../components/Buttons';
import { RxDragHandleDots2 } from 'react-icons/rx';
import { useGetDocumentGroups } from '../../hooks/useGetDocumentGroups';
import { useGetDocuments } from '../../hooks/useGetDocuments';
import { useDeleteDocumentGroupMutation } from '../../../../features/documents/documents';
import { useTranslation } from 'react-i18next';

export default function Sidebar({ setShowModal, draggableType, handleMove }) {
    const { path } = useRouteMatch();
    const history = useHistory();
    const { folderId } = useParams();
    const rootLevelGroups = useGetDocumentGroups();
    const { t } = useTranslation('global');

    return (
        <div className="pt-4">
            <Nav className="folder-nav flex-column mb-3">
                <Nav.Link
                    active={folderId === undefined}
                    className="nav-dropdown-hover d-flex align-items-center"
                    onClick={() => {
                        history.push(generatePath(path));
                    }}
                >
                    <div className="font-weight-bolder">
                        <CollectionFill size={14} className="mr-2" />
                        {t('contentSidebar.all')}
                    </div>

                    <RestrictedContent permission={Permissions.DocumentFolder['Read.All']}>
                        <RootActions setShowModal={setShowModal} rootLevelGroups={rootLevelGroups} />
                    </RestrictedContent>
                </Nav.Link>
                <Nav.Link
                    className="d-flex justify-content-between"
                    active={folderId === Constants.folders.trash}
                    onClick={() => {
                        history.push(
                            generatePath(path, {
                                folder: Constants.folders.folder,
                                folderId: Constants.folders.trash,
                            })
                        );
                    }}
                >
                    <div className="font-weight-bolder">
                        <TrashFill size={14} className="mr-2" />
                        {t('contentSidebar.trash')}
                    </div>
                </Nav.Link>
            </Nav>

            <RootGroups
                groups={rootLevelGroups}
                setShowModal={setShowModal}
                draggableType={draggableType}
                handleMove={handleMove}
            />
        </div>
    );
}

function RootGroups({ groups = [], setShowModal, draggableType, handleMove }) {
    return (
        <>
            {groups.map((_group, index) => (
                <RootGroup
                    group={_group}
                    setShowModal={setShowModal}
                    draggableType={draggableType}
                    handleMove={handleMove}
                    index={index}
                    isLast={groups.length - 1 === index}
                    key={`sidebar-group-${_group.id}`}
                />
            ))}
        </>
    );
}

function RootGroup({ group, setShowModal, draggableType, handleMove, index, isLast }) {
    const childGroups = useGetDocumentGroups(group.id);
    const [showContents, setShowContents] = useState(false);
    const toggleContents = () => {
        setShowContents((prevState) => !prevState);
    };

    return (
        <>
            <div
                className="nav-dropdown-hover d-flex align-items-center mb-1 cursor-pointer"
                style={{
                    padding: '0.6rem 1.15rem 0.4rem 1.5rem',
                }}
                onClick={toggleContents}
            >
                <div className="font-weight-bolder d-flex justify-content-between">
                    <div className="sidebar-titles">{group.name}</div>
                    <CaretRightFill
                        className={cx('sidebar-icons ml-2', {
                            'rotate-90': showContents,
                        })}
                    />
                </div>

                <RestrictedContent permission={Permissions.DocumentFolder['Read.All']}>
                    <div className="ml-auto">
                        <GroupActions
                            setShowModal={setShowModal}
                            folder={group}
                            groups={childGroups}
                            handleMove={handleMove}
                            index={index}
                            isLast={isLast}
                        />
                    </div>
                </RestrictedContent>
            </div>
            {showContents && (
                <Droppable droppableId={`group-${group.id}`} isDropDisabled={draggableType === 'item'} type="group">
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            <Nav
                                className={cx('folder-nav droppable flex-column', {
                                    'dragging-over': snapshot.isDraggingOver,
                                })}
                            >
                                {childGroups.map((_childGroup, childIndex) => (
                                    <Group
                                        group={_childGroup}
                                        setShowModal={setShowModal}
                                        index={childIndex}
                                        handleMove={handleMove}
                                        isLast={childGroups.length - 1 === childIndex}
                                        key={`sidebar-group-${_childGroup.id}`}
                                    />
                                ))}

                                {provided.placeholder}
                            </Nav>
                        </div>
                    )}
                </Droppable>
            )}
        </>
    );
}

function Group({ group, setShowModal, index, handleMove, isLast }) {
    const { folderId } = useParams();
    const history = useHistory();
    const isActive = folderId === _.toString(group.id);
    const documents = useGetDocuments(group.id);

    return (
        <Draggable draggableId={`group-${group.id}`} index={index}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(provided.draggableProps.style)}
                >
                    <Nav.Link
                        as="div"
                        className={cx('d-flex align-items-center position-relative nav-dropdown-hover', {
                            'is-dragging': snapshot.isDragging,
                        })}
                        active={isActive}
                        onClick={() => {
                            if (isActive === false) {
                                history.push(
                                    generatePath(INDEX_PATH, {
                                        folder: Constants.folders.folder,
                                        folderId: group.id,
                                    })
                                );
                            }
                        }}
                    >
                        <RestrictedContent permission={Permissions.DocumentFolder['Read.All']}>
                            <RxDragHandleDots2 size={16} className="flex-shrink-0 text-color icon-grip" />
                        </RestrictedContent>

                        <div className="small">{group.name}</div>

                        <div className="d-flex align-items-center ml-auto">
                            <RestrictedContent permission={Permissions.DocumentFolder['Read.All']}>
                                <GroupActions
                                    setShowModal={setShowModal}
                                    folder={group}
                                    handleMove={handleMove}
                                    index={index}
                                    isLast={isLast}
                                />
                            </RestrictedContent>

                            <div className="text-center small ml-2" style={{ minWidth: 16 }}>
                                {documents.length}
                            </div>
                        </div>
                    </Nav.Link>
                </div>
            )}
        </Draggable>
    );
}

function RootActions({ setShowModal, rootLevelGroups = [] }) {
    const { t } = useTranslation('global');

    const getNewSortOrder = () => {
        if (rootLevelGroups.length === 0) {
            return 0;
        }

        return rootLevelGroups[rootLevelGroups.length - 1].sortOrder + 1;
    };

    return (
        <IconButton
            className="ml-auto"
            onClick={() => setShowModal({ sortOrder: getNewSortOrder() })}
            tooltip={`${t('contentSidebar.map.chaptersMap')}...`}
            icon={<PlusCircle size={14} />}
        />
    );
}

function GroupActions({ setShowModal, folder, groups = [], handleMove, index, isLast }) {
    const history = useHistory();
    const [dropdownIsOpen, setDropDownOpen] = useState(false);
    const { t } = useTranslation('global');

    const [deleteDocumentGroup] = useDeleteDocumentGroupMutation();

    const handleSelect = (eventKey) => {
        switch (eventKey) {
            case 'insert':
                setShowModal({
                    parent: folder.id,
                    sortOrder: groups.length > 0 ? groups[groups.length - 1].sortOrder + 1 : 0,
                });
                break;
            case 'edit':
                setShowModal(folder);
                break;
            case 'delete':
                HelperFunctions.confirmModal(
                    `${t('contentSidebar.map.confirmDelete', { name: folder.name })}`,
                    'danger',
                    false
                )
                    .then(() => {
                        deleteDocumentGroup(folder.id).then(() => {
                            history.push(generatePath(INDEX_PATH));
                        });
                    })
                    .catch(() => {});
                break;
            case 'moveUp':
                handleMove('up', folder, index);
                break;
            case 'moveDown':
                handleMove('down', folder, index);
                break;
        }
    };

    const canDelete = () => {
        return folder.documents.length === 0 && groups.length === 0;
    };

    return (
        <Dropdown
            className={cx('d-flex align-items-center cursor-pointer mr-1 font-weight-bolder', {
                'dropdown-open': dropdownIsOpen,
            })}
            onSelect={handleSelect}
            onToggle={(isOpen, event) => {
                event.stopPropagation();
                setDropDownOpen(isOpen);
            }}
        >
            <Dropdown.Toggle
                bsPrefix="docrev-dropdown-toggle"
                as={ThreeDotsVertical}
                size={16}
                id={`folder-${folder.id}-dropdown`}
                className="sidebar-icons"
            />

            <DropdownMenu>
                {folder.parent === null && (
                    <Dropdown.Item eventKey="insert">{t('contentSidebar.map.menu.subfolder')}&hellip;</Dropdown.Item>
                )}
                <Dropdown.Item eventKey="edit">{t('contentSidebar.map.menu.changeName')}</Dropdown.Item>
                <Dropdown.Item eventKey="moveUp" disabled={index === 0}>
                    {t('contentSidebar.map.menu.moveUp')}
                </Dropdown.Item>
                <Dropdown.Item eventKey="moveDown" disabled={isLast}>
                    {t('contentSidebar.map.menu.moveDown')}
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item eventKey="delete" disabled={!canDelete()}>
                    <span className={canDelete() ? 'text-danger' : 'text-muted'}>
                        {t('contentSidebar.map.menu.delete')}&hellip;
                    </span>
                </Dropdown.Item>
            </DropdownMenu>
        </Dropdown>
    );
}

const getItemStyle = (draggableStyle) => ({
    userSelect: 'none',
    ...draggableStyle,
});
