import { Form, Formik, useFormikContext } from 'formik';
import { Accordion, Button, Card, Table } from 'react-bootstrap';
import { useState } from 'react';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { SecondaryButton, WarningButton } from '../../../../../components/Buttons';
import { FormField, InputSelect } from '../../../../publications_v2/helpers/FieldHelper';
import { ParametersTable } from '../../../../../components/SettingsSidebar/views/EditTemplateParameters';
import getTemplateParameters from '../../../../../components/SettingsSidebar/views/TemplateConstants';
import { useParams } from 'react-router-dom';
import { useGetDocument } from '../../../hooks/useGetDocument';
import { useUpdateDocumentMutation } from '../../../../../features/documents/documents';
import { useTranslation } from 'react-i18next';

export default function TemplateParameters() {
    const { t } = useTranslation('documents');
    return (
        <>
            <div className="dr-container p-4">
                <div className="subheader">
                    <h3>{t('document.navbar.template.tabs.settings.title')}</h3>
                </div>

                <DocumentLevelTemplateParameters />
            </div>
        </>
    );
}

function DocumentLevelTemplateParameters() {
    const { t } = useTranslation('documents');
    const { documentId } = useParams();
    const document = useGetDocument(documentId);
    const entityParameters = getTemplateParameters('document', document);

    const [updateDocument] = useUpdateDocumentMutation();

    const handleSubmit = (values, { setSubmitting }) => {
        updateDocument({
            id: parseInt(documentId),
            uri: `/v2/documents/${document.id}`,
            body: values,
            method: 'PATCH',
        }).then(() => {
            setSubmitting(false);
        });
    };

    return (
        <>
            <Formik enableReinitialize initialValues={document} onSubmit={handleSubmit}>
                {({ isValid, isSubmitting, dirty, values }) => (
                    <Form className="uk-form  content-between align-items-center">
                        <div className="uk-width-850 d-flex justify-content-end py-2 px-0 ">
                            <div className="position-relative" style={{ top: -80 }}>
                                <SecondaryButton onClick={() => closeSidebar()}>{t('btn.cancel')}</SecondaryButton>

                                <WarningButton
                                    className="ml-2"
                                    disabled={!dirty || !isValid || isSubmitting}
                                    type="submit"
                                >
                                    {t('btn.save')}
                                </WarningButton>
                            </div>
                        </div>
                        <div className="uk-form-controls position-relative" style={{ top: -50, fontSize: '12px' }}>
                            {Object.keys(entityParameters).map((group) => (
                                <ParametersTable
                                    group={group}
                                    parameters={Object.keys(entityParameters[group])}
                                    key={`group-${group}`}
                                    ButtonsComponentReplacement={DocumentParametersDropDown}
                                    namePrefix={'properties.templateParameters'}
                                />
                            ))}
                            <TemplateTerminologyTable />
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
}

function DocumentParametersDropDown() {
    const { values } = useFormikContext();
    const { t } = useTranslation('documents');

    const compositionsOptions = [
        {
            label: t(
                'document.navbar.template.tabs.settings.templateParameters.documentParametersDropdown.onlyFromTemplate'
            ),
            value: 'canOnlyUseAsWhole',
        },
        {
            label: t(
                'document.navbar.template.tabs.settings.templateParameters.documentParametersDropdown.toBeUsedFreely'
            ),
            value: 'canBeFree',
        },
    ];

    const publicationOptions = [
        {
            label: t(
                'document.navbar.template.tabs.settings.templateParameters.documentParametersDropdown.publicationOptions.healthInsuranceCards'
            ),
            value: 'informationDocumentHealthInsuranceCards'
        },
        {
            label: t(
                'document.navbar.template.tabs.settings.templateParameters.documentParametersDropdown.publicationOptions.insuranceCards'
            ),
            value: 'informationDocumentInsuranceCards',
        }
    ];
    return (
        <>
            <tr>
                <td style={{ height: '50%', padding: '10px 0', verticalAlign: 'middle' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <label style={{ fontSize: '12px', marginLeft: '14px' }}>
                            {t(
                                'document.navbar.template.tabs.settings.templateParameters.documentParametersDropdown.compilation'
                            )}
                        </label>
                    </div>
                </td>
                <td>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <InputSelect
                            style={{ width: '100%', maxWidth: '300px' }}
                            options={compositionsOptions}
                            name="properties.templateParameters.composition"
                            value={values?.properties?.templateParameters}
                            onChange={(selectedOption) => {
                                setFieldValue('properties?.templateParameters?.composition', selectedOption.value);
                            }}
                        />
                    </div>
                </td>
            </tr>
            <tr>
                <td style={{ height: '50%', padding: '10px 0', verticalAlign: 'middle' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <label style={{ fontSize: '12px', marginLeft: '14px' }}>
                            {t(
                                'document.navbar.template.tabs.settings.templateParameters.documentParametersDropdown.publicationTemplate'
                            )}
                        </label>
                    </div>
                </td>
                <td>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <InputSelect
                            style={{ width: '100%', maxWidth: '300px' }}
                            options={publicationOptions}
                            name="properties.templateParameters.publication"
                            onChange={(selectedOption) => {
                                setFieldValue('properties?.templateParameters?.publication', selectedOption.value);
                            }}
                        />
                    </div>
                </td>
            </tr>
        </>
    );
}

function TemplateTerminologyTable() {
    const [isExpanded, setIsExpanded] = useState(false);
    const { t } = useTranslation('documents');

    return (
        <>
            <Accordion>
                <Card style={{ backgroundColor: 'transparent', border: 'none' }}>
                    <Card.Header
                        className="font-weight-bold pl-0"
                        style={{
                            backgroundColor: 'transparent',
                            border: 'none',
                        }}
                    >
                        <Accordion.Toggle
                            as={Button}
                            variant="dark"
                            style={{
                                backgroundColor: 'transparent',
                                color: 'black',
                                outline: 'none',
                                border: 'none',
                            }}
                            className="pl-0"
                            eventKey={`Terminologie`}
                            onClick={() => setIsExpanded(!isExpanded)}
                        >
                            <span className="uk-text-primary">
                                {t(
                                    'document.navbar.template.tabs.settings.templateParameters.templateTerminologyTable.terminology'
                                )}
                            </span>
                            {!isExpanded ? <CaretDownFill /> : <CaretUpFill />}
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={`Terminologie`} key={`Terminologie`}>
                        <div className="uk-form-controls">
                            <Table borderless striped style={{ width: '75%' }} className="mb-0">
                                <thead className="font-weight-bold" style={{ backgroundColor: '#ebeced' }}>
                                    <tr>
                                        <td style={{ width: '5%', textAlign: 'center' }}></td>
                                        <td style={{ width: '45%', textAlign: 'center' }}>
                                            {t(
                                                'document.navbar.template.tabs.settings.templateParameters.templateTerminologyTable.singular'
                                            )}
                                        </td>
                                        <td style={{ width: '45%', textAlign: 'center' }}>
                                            {t(
                                                'document.navbar.template.tabs.settings.templateParameters.templateTerminologyTable.plural'
                                            )}
                                        </td>
                                    </tr>
                                </thead>
                                <tbody className="dr-side-container">
                                    <tr>
                                        <td
                                            style={{
                                                verticalAlign: 'middle',
                                                width: '5%',
                                                textAlign: 'left',
                                                fontSize: '12px',
                                            }}
                                        >
                                            <label className="font-weight-bold">
                                                {t(
                                                    'document.navbar.template.tabs.settings.templateParameters.templateTerminologyTable.variant(s)'
                                                )}
                                            </label>
                                        </td>
                                        <td style={{ width: '45%', textAlign: 'center' }}>
                                            <FormField
                                                name={`properties.terminology.variant`}
                                                props={{
                                                    placeholder: t(
                                                        'document.navbar.template.tabs.settings.templateParameters.templateTerminologyTable.variant'
                                                    ),
                                                }}
                                            />
                                        </td>
                                        <td style={{ width: '45%', textAlign: 'center' }}>
                                            <FormField
                                                name={`properties.terminology.variants`}
                                                props={{
                                                    placeholder: t(
                                                        'document.navbar.template.tabs.settings.templateParameters.templateTerminologyTable.variants'
                                                    ),
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            style={{
                                                verticalAlign: 'middle',
                                                width: '5%',
                                                textAlign: 'left',
                                                fontSize: '12px',
                                            }}
                                        >
                                            <label className="font-weight-bold">
                                                {t(
                                                    'document.navbar.template.tabs.settings.templateParameters.templateTerminologyTable.chapter'
                                                )}
                                            </label>
                                        </td>
                                        <td style={{ width: '45%', textAlign: 'center' }}>
                                            <FormField
                                                name={`properties.terminology.chapter`}
                                                props={{
                                                    placeholder: t(
                                                        'document.navbar.template.tabs.settings.templateParameters.templateTerminologyTable.chapter'
                                                    ),
                                                }}
                                            />
                                        </td>
                                        <td style={{ width: '45%', textAlign: 'center' }}>
                                            <FormField
                                                name={`properties.terminology.chapters`}
                                                props={{
                                                    placeholder: t(
                                                        'document.navbar.template.tabs.settings.templateParameters.templateTerminologyTable.chapters'
                                                    ),
                                                }}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </>
    );
}
