import MainContentNav from '../../../Navbar';
import { BASE_PATH, VIEW_PATH } from '../../../../scenes/Reimbursements';
import Sidebar from './Sidebar';
import Propositions from './Propositions';
import SubNav from '../SubNav';
import LoadingSpinner from '../../../global/LoadingSpinner';
import { generatePath, useParams } from 'react-router-dom';
import { useGetCategory } from '../../../../features/reimbursements/reimbursementHooks';
import { useTranslation } from 'react-i18next';

export default function Index() {
    const params = useParams();
    const categoryId = parseInt(params.categoryId);
    const { category } = useGetCategory(categoryId);
    const { t } = useTranslation('reimbursements');

    if (category === undefined) {
        return <LoadingSpinner />;
    }

    return (
        <>
            <MainContentNav
                pages={[
                    { title: t('breadcrumb'), url: BASE_PATH },
                    {
                        title: category.name,
                        url: generatePath(VIEW_PATH, { categoryId }),
                    },
                    { title: t('proposition.title') },
                ]}
            />

            <SubNav />

            <div className="content-static-body has-subnav">
                <div className="d-flex align-items-stretch h-100">
                    <div className="content-sidebar overflow-auto">
                        <Sidebar categoryUri={category['@id']} />
                    </div>
                    <div className="flex-grow-1 overflow-auto">
                        <div className="py-2 px-4">
                            <Propositions categoryUri={category['@id']} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
