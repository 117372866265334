import { getTable, RenderTable } from '../../documents/misc/_Blocks/Table';
import HelperFunctions from '../../global/HelperFunctions';
import { useActiveOrganisation } from '../../../hooks/useActiveOrganisation';
import { useGetTableTemplatesQuery } from '../../../features/documents/documents';

export function TableBlock({ opEntryBlock, showChanges }) {
    const activeOrganisation = useActiveOrganisation();

    const { tableTemplates } = useGetTableTemplatesQuery(activeOrganisation, {
        selectFromResult: ({ data }) => ({
            tableTemplates: data ?? [],
        }),
    });

    const table = getTable(opEntryBlock, showChanges);
    const dataSet = table.data[0] ?? [];
    const template = HelperFunctions.getByValue(tableTemplates, 'id', parseInt(table.templateId));

    if (!template) {
        return null;
    }

    return (
        <RenderTable
            rows={template.rows}
            columns={template.columns}
            tableData={dataSet?.rows ?? []}
            template={template}
        />
    );
}
