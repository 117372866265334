import { DocumentVariantOption, MultiVariantSelect } from '../../../../components/Select';
import { Area, DocumentVariant } from '../../../../models/documents.models';
import { useContext, useMemo } from 'react';
import { VariantFilterContext } from '../../../documents/misc/AreaLayout';
import HelperFunctions from '../../../global/HelperFunctions';
import { useGetBaseVariant } from '../../hooks/useGetBaseVariant';
import { useTranslation } from 'react-i18next';

export function BlockVariantsHeader({ area }: { area: Area }) {
    const baseVariant: DocumentVariant | undefined = useGetBaseVariant();
    const { t } = useTranslation('documents');

    const { setSelectedVariants } = useContext(VariantFilterContext);

    const variants = useMemo(() => {
        if (baseVariant === undefined) {
            return [] as DocumentVariant[];
        }

        const variantsAsList: DocumentVariant[] = HelperFunctions.flatten([baseVariant]);

        return variantsAsList.filter((variant) => variant.variantGroup || area.documentVariants.includes(variant.id));
    }, [baseVariant]);

    const markVariantsLabel = t('document.navbar.main.editor.right.RightSideViewSwitch.markVariants');

    return (
        <MultiVariantSelect
            isClearable={true}
            label={markVariantsLabel}
            variants={variants}
            id="select-variants"
            handleChange={(newOptions: DocumentVariantOption[]) => {
                setSelectedVariants(newOptions.map((option) => option.value));
            }}
        />
    );
}
