import Constants from '../../../../../config/Constants';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

export function BlockName({ block }) {
    const { type } = block;

    // Block name
    let name = _.get(block, 'properties.name', '');

    if (type === Constants.blockTypes.linked) {
        name = _.get(block, 'targetProperties.name', '');
    }

    const showName = !_.isEmpty(name);

    // Block prefix
    const blockPrefix = _.get(block, 'properties.blockPrefix', '');
    const blockPrefixSetting = _.get(block, 'properties.blockPrefixSetting', 'manual');

    const showBlockPrefix = blockPrefixSetting === 'auto' || blockPrefix !== '';

    if (!showName && !showBlockPrefix) {
        return null;
    }

    return (
        <div
            className="block-name d-flex align-items-center bg-white small position-absolute px-2"
            style={{
                top: -10,
                left: 16,
                color: '#00abb4',
            }}
        >
            {showName && <div>{name}</div>}

            {showBlockPrefix && <BlockPrefix block={block} showName={showName} />}
        </div>
    );
}

function BlockPrefix({ block }) {
    const blockPrefix = _.get(block, 'properties.blockPrefix', '');
    const blockPrefixSetting = _.get(block, 'properties.blockPrefixSetting', 'manual');
    const blockPrefixType = _.get(block, 'properties.blockPrefixType', 'default');
    const { t } = useTranslation('documents');

    return (
        <div
            className="text-secondary"
            style={{
                fontSize: 11,
            }}
        >
            {t('document.navbar.main.editor.left.blocks.badges.numberingTitle')}:&nbsp;
            {blockPrefixSetting === 'manual' && (
                <>
                    {blockPrefix} {t('document.navbar.main.editor.left.blocks.badges.manual')}
                </>
            )}
            {blockPrefixSetting === 'auto' && (
                <>
                    {blockPrefixType === 'default' && (
                        <>{t('document.navbar.main.editor.left.blocks.badges.123automatic')}</>
                    )}
                    {blockPrefixType === 'small_caps' && (
                        <>{t('document.navbar.main.editor.left.blocks.badges.abcautomatic')}</>
                    )}
                    {blockPrefixType === 'uppercase' && (
                        <>{t('document.navbar.main.editor.left.blocks.badges.ABCautomatic')}</>
                    )}
                </>
            )}
        </div>
    );
}
