import { useGetTaskTeamAssignmentQuery } from 'features/comments/commentApi';

export function useGetTaskTeamAssignment(id) {
    const { assignment } = useGetTaskTeamAssignmentQuery(id, {
        selectFromResult: (result) => ({
            assignment: result.data,
        }),
    });

    return assignment;
}
