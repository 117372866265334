import Parser from 'html-react-parser';
import HelperFunctions from '../../global/HelperFunctions';
import cx from 'classnames';

export function TextBlock({ opEntryBlock, showRendered, document }) {
    let text = showRendered ? opEntryBlock.renderedDiffContent : opEntryBlock.latestContent;

    if (document && document.hasOwnProperty('sections')) {
        text = HelperFunctions.prepareRefs(text, document);
    }

    return (
        <div
            className={cx({
                ins:
                    showRendered === true && opEntryBlock.addedToVariant === true && text.startsWith('<ins>') === false,
            })}
        >
            {Parser(text)}
        </div>
    );
}
