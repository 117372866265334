import { useParams } from 'react-router-dom';
import MainContentNav from '../../../Navbar';
import { BASE_PATH } from '../../../../scenes/Translations';
import { useGetTranslationContainerQuery } from '../../../../features/translations/translationApi';
import LoadingSpinner from '../../../global/LoadingSpinner';
import SubNav from './SubNav';
import Dashboard from './Dashboard';
import Properties from './Properties';
import { useTranslation } from 'react-i18next';

export default function View() {
    const { translationContainerId, view = 'dashboard' } = useParams();
    const query = useGetTranslationContainerQuery(translationContainerId);
    const { isLoading, data = {} } = query;
    const { t } = useTranslation('translations');

    return (
        <>
            <MainContentNav
                pages={[
                    { title: t('translation.navbar.dashboard.document.breadcrumb'), url: BASE_PATH },
                    { title: data?.name },
                ]}
            />

            {isLoading && <LoadingSpinner />}

            {isLoading === false && <SubNav data={data} />}

            <div className="content-static-body" style={{ top: 118 }}>
                <RenderView view={view} />
            </div>
        </>
    );
}

function RenderView({ view }) {
    if (view === 'dashboard') {
        return <Dashboard />;
    }

    if (view === 'properties') {
        return <Properties />;
    }

    return <>View '{view}' is not supported.</>;
}
