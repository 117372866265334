import { useSelectedItems, useSelectedItemsDispatch } from 'components/PaginatedTable';

export function SelectAllCheckbox({ items = [], idProp = '@id' }) {
    const dispatch = useSelectedItemsDispatch();
    const { selectedItems = [] } = useSelectedItems();

    const numberOfItems = items.length;
    const indeterminate = selectedItems.length > 0 && selectedItems.length < numberOfItems;
    const selectAllChecked = numberOfItems > 0 && selectedItems.length === numberOfItems;

    return (
        <div className="custom-control custom-checkbox">
            <input
                className="custom-control-input"
                id={`bulk-check`}
                type="checkbox"
                checked={selectAllChecked}
                onChange={handleSelectAllChange}
                disabled={numberOfItems === 0}
                ref={(el) => el && (el.indeterminate = indeterminate)}
            />
            <label className="custom-control-label" htmlFor={`bulk-check`} />
        </div>
    );

    function handleSelectAllChange() {
        const selectAllChecked = items.length > 0 && selectedItems.length === items.length;

        if (selectAllChecked) {
            // Deselect all
            dispatch({
                type: 'select',
                items: [],
            })
        } else {
            // Select all
            dispatch({
                type: 'select',
                items: items.map((group) => group[idProp]),
            })
        }
    }
}
