import { useGetAccountRestrictions } from 'hooks/useGetAccountRestrictions';

/**
 * @param {string} restriction
 * @returns {boolean}
 */
export function useHasAccountRestriction(restriction) {
    const accountRestrictions = useGetAccountRestrictions();

    return accountRestrictions.includes(restriction);
}
