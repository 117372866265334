import { ExclamationTriangleFill } from 'react-bootstrap-icons';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { WarningButton } from 'components/Buttons';
import { LoadingSpinner } from 'pages/global/Spinner';

export function AreaNav() {
    const { t } = useTranslation('documents');
    const { dirty, isSubmitting } = useFormikContext();

    return (
        <>
            <LoadingSpinner isSubmitting={isSubmitting} />

            {dirty && !isSubmitting && (
                <>
                    <ExclamationTriangleFill className="text-warning mr-1" />
                    <div className="mr-3 small text-warning">
                        {t('document.navbar.main.editor.navbar.unsavedChanges')}
                    </div>
                </>
            )}

            <WarningButton type="submit" disabled={!dirty || isSubmitting}>
                {t('btn.save')}
            </WarningButton>

            <div
                style={{
                    borderRight: '1px solid #e5e5e5',
                    height: 32,
                    margin: '0 12px 0 18px',
                }}
            >
                &nbsp;
            </div>
        </>
    );
}
