import { LightOutlineButton } from 'components/Buttons';
import { RenderProperty } from 'pages/task_team/modals/view_task_modal/TaskProperties';
import { TaskAssignmentStatusBadge } from 'pages/task_team/views/checks/Reviews';
import { taskTeamAssignmentStatus } from 'pages/task_team/config/constants';
import { RejectionReason } from 'pages/task_team/modals/view_task_modal/TaskVersions';
import { useState } from 'react';
import { EditAssignment } from 'pages/task_team/modals/view_task_modal/EditAssignment';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

export function ViewAssignment({ assignment, canEdit = false }) {
    const { i18n } = useTranslation();
    const [editAssignment, setEditAssignment] = useState(false);

    if (editAssignment) {
        return <EditAssignment assignment={assignment} close={() => setEditAssignment(false)} />;
    }

    const reviewedAt = assignment.reviewedAt
        ? DateTime.fromISO(assignment.reviewedAt).setLocale(i18n.language).toLocaleString(DateTime.DATETIME_SHORT)
        : '-';

    return (
        <div className="card oke-card mb-4">
            <div className="card-header">Mijn beoordeling</div>

            <div className="card-body">
                <RenderProperty label="Beoordeling">
                    <TaskAssignmentStatusBadge assignment={assignment} />
                </RenderProperty>

                <RenderProperty label="Datum beoordeling">{reviewedAt}</RenderProperty>

                {assignment.status === taskTeamAssignmentStatus.STATUS_REJECTED && (
                    <RenderProperty label="Reden voor afkeuring">
                        {assignment.rejectionReasons.length > 0 ? <RejectionReason assignment={assignment} /> : <>-</>}
                    </RenderProperty>
                )}
            </div>

            <div className="card-footer d-flex flex-row-reverse">
                <LightOutlineButton disabled={!canEdit} onClick={() => setEditAssignment(true)}>
                    Beoordeling bewerken
                </LightOutlineButton>
            </div>
        </div>
    );
}
