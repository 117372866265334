import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import HelperFunctions from 'pages/global/HelperFunctions';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { useGetDepartmentsQuery } from 'features/comments/commentApi';
import { Department } from 'models/comments.models';

/**
 * @returns {Department[]}
 */
export function useGetDepartments() {
    const organisationId = useActiveOrganisation();

    const selectDepartments = useMemo(() => {
        const emptyArray = [];

        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (res) => res.data,
            (res, organisationId) => organisationId,
            (data, organisationId) =>
                data
                    ? data
                          .filter((department) => department.organisationId === organisationId)
                          .sort(HelperFunctions.dynamicSort('name'))
                    : emptyArray
        );
    }, []);

    const { departments } = useGetDepartmentsQuery(undefined, {
        selectFromResult: (result) => ({
            departments: selectDepartments(result, organisationId),
        }),
    });

    return departments;
}
