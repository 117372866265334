import Constants from '../../../../config/Constants';
import { documentApi, useGetAreaHistoryQuery, useUpdateAreaBlocksMutation } from 'features/documents/documents';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { AreaContext } from '../Area';
import { AreaLayoutNav, AreaLayoutPageLeft } from './AreaLayout';
import { FieldArray, Form as FForm, Formik, useFormikContext } from 'formik';
import { AreaBlocks } from './AreaBlocks';
import { AreaNav } from './AreaNav';
import { EditAreaLeftSideHeader } from 'pages/documents/misc/_EditArea/EditAreaLeftSideHeader';
import { useGetDocument } from '../../hooks/useGetDocument';
import { useGetAreaBlocks } from '../../hooks/useGetAreaBlocks';
import { AreaLayout, AreaLayoutPageRight } from 'pages/documents/misc/AreaLayout';
import { useGetVariant } from '../../hooks/useGetVariant';
import HelperFunctions from '../../../global/HelperFunctions';
import { formatData, parseBlockData } from 'pages/global/BlockLayoutHelper';
import LoadingSpinner from '../../../global/LoadingSpinner';
import { useEntityTemplateParameters } from '../../hooks/useEntityTemplateParameters';
import { LockedArea } from '../view/LockedArea';
import getTemplateParameters from '../../../../components/SettingsSidebar/views/TemplateConstants';
import { useTranslation } from 'react-i18next';
import { EditAreaRightSideContent } from 'pages/documents/misc/_EditArea/EditAreaRightSideContent';
import { EditAreaRightSideHeader } from 'pages/documents/misc/_EditArea/EditAreaRightSideHeader';
import { useGetBaseVariant } from '../../hooks/useGetBaseVariant';
import { useIsLoading } from 'pages/documents_v2/views/edit_area/useIsLoading';
import { useDispatch } from 'react-redux';
import { useGetAreaHistory } from 'pages/documents_v2/views/edit_area/hooks/useGetAreaHistory';

export function EditArea() {
    const document = useGetDocument();

    if (!document) {
        return (
            <div className="pt-5">
                <LoadingSpinner />
            </div>
        );
    }

    // @todo: show nice error message to the user
    if (document.currentUserRole === Constants.userDocumentRole.spectator) {
        return <div>not allowed</div>;
    }

    // @todo: show nice error message to the user
    if (document.status === Constants.documentStatus.reviewFinished) {
        return <div>review finished</div>;
    }

    return (
        <LockedArea action="edit">
            <EditAreaBlocks />
        </LockedArea>
    );
}

function EditAreaBlocks() {
    const areaData = useGetAreaBlocks();

    if (!areaData) {
        return (
            <div className="pt-5">
                <LoadingSpinner />
            </div>
        );
    }

    return <EditAreaBlocksForm areaData={areaData} />;
}

function EditAreaBlocksForm({ areaData }) {
    const [updateAreaBlocks] = useUpdateAreaBlocksMutation();
    const { activeVariantId } = useContext(AreaContext);
    const baseVariant = useGetBaseVariant();
    const dispatch = useDispatch();

    const { area } = areaData;

    const handleSubmit = (values, { setSubmitting }) => {
        const viewingBaseVariant = activeVariantId === baseVariant.id;

        if (viewingBaseVariant) {
            // Save everything to the base variant
            updateAreaBlocks({
                areaId: area.id,
                variantId: baseVariant.id,
                body: values.areaBlocks,
            }).then(() => {
                setSubmitting(false);
            });
        } else {
            // Save table block to the selected variant and the rest to the base variant
            const baseVariantFormData = values.areaBlocks.filter((block) => block.type !== Constants.blockTypes.table);
            const tableFormData = values.areaBlocks.filter((block) => block.type === Constants.blockTypes.table);

            const baseSave = new Promise((resolve) => {
                if (baseVariantFormData.length > 0) {
                    updateAreaBlocks({
                        areaId: area.id,
                        variantId: baseVariant.id,
                        body: baseVariantFormData,
                    }).then(() => {
                        resolve();
                    });
                } else {
                    resolve();
                }
            });

            const tableSave = new Promise((resolve) => {
                if (tableFormData.length > 0) {
                    updateAreaBlocks({
                        areaId: area.id,
                        variantId: activeVariantId,
                        body: tableFormData,
                    }).then(() => {
                        resolve();
                    });
                } else {
                    resolve();
                }
            });

            Promise.all([baseSave, tableSave]).then(() => {
                setSubmitting(false);

                // Invalidate tags to fix blocks of selected variant not being updated
                dispatch(
                    documentApi.util.invalidateTags([{ type: 'AreaVariant', id: `${area.id}-${activeVariantId}` }]),
                );
            });
        }
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{ ...areaData.area, areaBlocks: areaData.areaBlocks }}
            onSubmit={handleSubmit}
        >
            {() => (
                <FForm>
                    <FieldArray name="areaBlocks">
                        {(arrayHelpers) => <RenderedForm arrayHelpers={arrayHelpers} />}
                    </FieldArray>
                </FForm>
            )}
        </Formik>
    );
}

export const EditAreaContext = createContext(null);

function RenderedForm({ arrayHelpers }) {
    const [showRightSide, setShowRightSide] = useState(true);
    const { values, dirty, setFieldValue } = useFormikContext();
    const area = values;
    const document = useGetDocument();
    const restrictions = useEntityTemplateParameters(area, 'area', document);
    const { t } = useTranslation('documents');
    const isLoading = useIsLoading();

    const { areaBlocks } = values;

    return (
        <EditAreaContext.Provider value={{ addBlock, insertBlock, insertBlocks, generateKey }}>
            <div className="edit-area">
                <AreaLayout>
                    <AreaLayoutNav area={area}>
                        <AreaNav />
                    </AreaLayoutNav>

                    <AreaLayoutPageLeft area={area} showRightSide={showRightSide} dirty={dirty}>
                        <EditAreaLeftSideHeader
                            area={area}
                            toggleRightSide={() => setShowRightSide(!showRightSide)}
                            showRightSide={showRightSide}
                            changesDetected={dirty}
                            areaBlocks={areaBlocks}
                            canAddFreeBlocks={restrictions?.canAddFreeBlocks}
                        />

                        {isLoading ? (
                            <LoadingSpinner />
                        ) : (
                            <AreaBlocks
                                canAddFreeBlocks={restrictions?.canAddFreeBlocks}
                                canMoveBlocks={restrictions?.canMoveBlocks}
                            />
                        )}
                    </AreaLayoutPageLeft>

                    <RightSideContent area={area} showRightSide={showRightSide} />
                </AreaLayout>

                <div id="editor-toolbar">
                    <div id="editor-toolbar-placeholder"></div>
                </div>
            </div>
        </EditAreaContext.Provider>
    );

    function addBlock(type, documentVariants = [], properties = {}, addedContent = undefined) {
        let newContent = '';
        let newProperties = {};
        let blockType = type;

        switch (type) {
            case Constants.blockTypes.textExplanation:
                const newBlock1 = {
                    id: null,
                    key: generateKey(),
                    documentVariants,
                    latestContent: '',
                    properties: {
                        ...properties,
                        textLayout: 'default',
                    },
                    type: Constants.blockTypes.text,
                };
                const newBlock2 = {
                    id: null,
                    key: generateKey(),
                    documentVariants,
                    latestContent: '',
                    properties: {
                        ...properties,
                        textLayout: 'info',
                    },
                    type: Constants.blockTypes.text,
                };

                insertBlock(newBlock1);
                insertBlock(newBlock2);

                blockType = Constants.blockTypes.blockLayout;
                newContent = parseBlockData(formatData([[[{ id: newBlock1.key }]], [[{ id: newBlock2.key }]]]));
                newProperties = {
                    type: Constants.blockTypes.textExplanation,
                    fixedLayout: true,
                    showTableHeader: false,
                };

                break;
            case Constants.blockTypes.table:
            case Constants.blockTypes.reimbursement:
                const content = {
                    data: [],
                    ...properties,
                };
                newContent = JSON.stringify(content);
                break;
            case 'intro':
            case 'attention':
            case 'special':
            case 'info':
                blockType = Constants.blockTypes.text;
                newProperties = {
                    ...properties,
                    textLayout: type,
                };
                break;
            case Constants.blockTypes.text:
                newContent = addedContent ?? '';
                newProperties = {
                    ...properties,
                    textLayout: 'default',
                };
                break;
            case Constants.blockTypes.label:
                newProperties = {
                    ...properties,
                    name: t('document.navbar.main.editor.left.blocks.nameless'),
                    multiple: false,
                    allow_add: false,
                };
                break;
            case Constants.blockTypes.blockLayout:
                newProperties = {
                    type: 'default',
                    showTableHeader: true,
                };
                newContent = addedContent ?? `{\"rows\":2,\"columns\":2,\"data\":[[[],[]],[[],[]]]}`;
        }

        const templateParameters = getTemplateParameters(blockType, {});
        const justTheParameters = Object.assign({}, ...Object.values(templateParameters));

        const newBlockData = {
            id: null,
            key: generateKey(),
            documentVariants,
            latestContent: newContent,
            properties: { ...newProperties, templateParameters: justTheParameters },
            type: blockType,
        };

        insertBlock(newBlockData);

        return newBlockData;
    }

    function insertBlock(blockData) {
        const sortOrder = areaBlocks?.length > 0 ? Math.max(...areaBlocks.map((block) => block.sortOrder)) + 1 : 0;

        const newBlock = {
            ...blockData,
            sortOrder,
        };

        arrayHelpers.push(newBlock);
    }

    function insertBlocks(newAreaBlocks = []) {
        const maxSortOrder = areaBlocks?.length > 0 ? Math.max(...areaBlocks.map((block) => block.sortOrder)) + 1 : 0;

        const newBlocksWithSortOrder = newAreaBlocks.map((blockData, index) => ({
            ...blockData,
            sortOrder: maxSortOrder + index,
        }));

        const updatedAreaBlocks = [...areaBlocks, ...newBlocksWithSortOrder];

        setFieldValue(`areaBlocks`, updatedAreaBlocks);
    }

    function generateKey() {
        const randomKey = Math.floor(Math.random() * 9999) + 1;

        areaBlocks.forEach((block) => {
            if (block.key === randomKey) {
                return generateKey();
            }
        });

        return randomKey;
    }
}

export function RightSideContent({ area, showRightSide }) {
    const { activeVariantId } = useContext(AreaContext);
    const activeVariant = useGetVariant(activeVariantId);
    const { areaBlocks, baseBlocks } = useGetAreaBlocks();
    const document = useGetDocument(undefined, true);
    const [activeBlockUpdateGroup, setActiveBlockUpdateGroup] = useState(0);
    const isLoading = useIsLoading() || !document;
    const history = useGetAreaHistory(area.id);

    const switchVersionHistory = (startUpdateGroupId, blockUpdateGroupId) => {
        setActiveBlockUpdateGroup(blockUpdateGroupId);
    };

    const activeVersion = HelperFunctions.getByValue(history, 'endGroupId', activeBlockUpdateGroup);

    useEffect(() => {
        if (activeBlockUpdateGroup > 0) {
            if (!activeVersion) {
                setActiveBlockUpdateGroup(0);
            }
        }
    }, [activeBlockUpdateGroup, activeVersion]);

    const areaBaseBlocks = useMemo(() => {
        if (activeBlockUpdateGroup > 0) {
            const group = HelperFunctions.getByValue(history, 'endGroupId', activeBlockUpdateGroup);

            return group?.blocks ?? [];
        }

        return baseBlocks;
    }, [activeBlockUpdateGroup, baseBlocks, history]);

    const rightSideProps = {
        activeVariant,
        areaId: area.id,
        blockDisplayOptions: {
            markDeletedBlocks: true,
            canRestoreDeletedBlocks: false,
            text: {
                ...Constants.defaultBlockDisplayOptions.text,
                markChanges: true,
            },
            table: {
                ...Constants.defaultBlockDisplayOptions.table,
                markChanges: true,
            },
        },
        context: Constants.blockContext.editor,
        document,
        editorDisplaySection: Constants.editorDisplaySections.right,
        readOnly: true,
        showTableContent: true,
    };

    return (
        <AreaLayoutPageRight showRightSide={showRightSide}>
            <EditAreaRightSideHeader
                area={area}
                showRightSide={showRightSide}
                blockGroupHasHistory={history.length > 0}
                activeBlockUpdateGroup={activeBlockUpdateGroup}
                history={history}
                switchVersionHistory={switchVersionHistory}
            />

            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <div className="pt-4">
                    <EditAreaRightSideContent
                        showRightSide={showRightSide}
                        areaBlocks={areaBlocks}
                        baseBlocks={areaBaseBlocks}
                        rightSideProps={rightSideProps}
                        area={area}
                        activeVariantId={activeVariantId}
                    />
                </div>
            )}
        </AreaLayoutPageRight>
    );
}
