import { useGetTaskVersion } from 'pages/task_team/hooks/useGetTaskVersion';
import { useGetTaskVersionAttachments } from 'pages/task_team/hooks/useGetTaskVersionAttachments';
import { fileType, taskVersionStatus } from 'pages/task_team/config/constants';
import { useUpdateTaskVersionMutation } from 'features/comments/commentApi';
import { useMemo, useState } from 'react';
import { TaskAttachments } from 'pages/task_team/modals/add_check_modal/Step4Files';
import Parser from 'html-react-parser';
import { TaskFile } from 'pages/task_team/modals/view_task_modal/PreviewFile';
import { LightOutlineButton, LinkButton, WarningButton } from 'components/Buttons';
import { Form as FForm, Formik } from 'formik';
import Spinner from 'pages/global/Spinner';
import { useIsTaskOwner } from 'pages/task_team/hooks/permissions';
import { useVersionFilesAreValid } from 'pages/task_team/modals/view_task_modal/TaskReviews';
import { ExclamationTriangleFill } from 'react-bootstrap-icons';
import { Alert } from 'react-bootstrap';

export function TaskVersionFiles({ versionUri, task, canEdit = false }) {
    const [editMode, setEditMode] = useState(false);
    const version = useGetTaskVersion(versionUri);
    const isTaskOwner = useIsTaskOwner(task);
    const attachments = useGetTaskVersionAttachments(versionUri);
    const files = attachments.filter((attachment) => attachment.type === fileType.TYPE_ATTACHMENT);

    if (!version) {
        return null;
    }

    if (editMode) {
        return <EditTaskVersionFiles task={task} version={version} files={files} close={() => setEditMode(false)} />;
    }

    return (
        <div className="card oke-card oke-card-alt mb-4">
            <div className="card-header">Te beoordelen uiting</div>

            <div className="card-body">
                {isTaskOwner && version?.status === taskVersionStatus.STATUS_DRAFT && (
                    <>
                        <FileWarning version={version} />

                        {version.versionNumber > 1 && (
                            <NewFileWarning
                                task={task}
                                version={version}
                                files={files.map((file) => file.uri).sort()}
                            />
                        )}
                    </>
                )}

                <Attachments files={files} selectedVersion={version} />
            </div>

            {isTaskOwner && (
                <div className="card-footer d-flex flex-row-reverse">
                    <LightOutlineButton disabled={!canEdit} onClick={() => setEditMode(true)}>
                        Uiting bewerken
                    </LightOutlineButton>
                </div>
            )}
        </div>
    );
}

function FileWarning({ version }) {
    const versionFilesAreValid = useVersionFilesAreValid(version);

    return (
        <>
            {!versionFilesAreValid && (
                <div className="flex-center">
                    <ExclamationTriangleFill size={16} className="text-danger mr-2" />
                    Geen bestanden toegevoegd!
                </div>
            )}
        </>
    );
}

function NewFileWarning({ task, version, files }) {
    const previousVersionNumber = version.versionNumber - 1;

    const previousVersion = useMemo(() => {
        if (task && previousVersionNumber > 0) {
            return task.taskVersions.find((version) => version.versionNumber === previousVersionNumber);
        }

        return undefined;
    }, [previousVersionNumber, task]);

    const previousVersionReal = useGetTaskVersion(_.get(previousVersion, '@id'));
    const attachments = useGetTaskVersionAttachments(_.get(previousVersion, '@id'));

    const previousFiles = attachments
        .filter((attachment) => attachment.type === fileType.TYPE_ATTACHMENT)
        .map((file) => file.uri)
        .sort();

    const filesAreTheSame =
        previousFiles.length > 0 &&
        previousFiles.length === files.length &&
        JSON.stringify(previousFiles) === JSON.stringify(files);

    const contentIsTheSame = version.content === previousVersionReal?.content;

    if ((files.length > 0 && filesAreTheSame) || (files.length === 0 && contentIsTheSame)) {
        return <Alert variant="danger">De uiting is gelijk aan de vorige versie!</Alert>;
    }

    return null;
}

function Attachments({ files = [], selectedVersion }) {
    return (
        <div>
            {!_.isEmpty(selectedVersion.content) && selectedVersion.content.trim() !== '<p></p>' && (
                <div className="mb-4">
                    <div className="font-weight-bold small mb-2">Content</div>

                    <div className="border border-radius bg-light p-3 pb-0 mb-2">
                        <div className="content-attachment">{Parser(selectedVersion.content)}</div>
                    </div>
                </div>
            )}

            {files.length > 0 && (
                <div>
                    <div className="font-weight-bold small mb-2">Bestanden</div>

                    {files.map((file) => (
                        <div className="mb-3" key={`file-${file.id}`}>
                            <TaskFile file={file} />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

function EditTaskVersionFiles({ task, version, files, close }) {
    const versionContent = version?.content ?? '';
    const [updateTaskVersion] = useUpdateTaskVersionMutation();

    return (
        <Formik
            initialValues={{
                content: versionContent,
            }}
            onSubmit={handleSubmit}
            enableReinitialize={true}
        >
            {({ isValid, isSubmitting, dirty }) => (
                <FForm>
                    <div className="card oke-card oke-card-active mb-4">
                        <div className="card-header">Uiting</div>

                        <div className="card-body">
                            <TaskAttachments
                                task={task}
                                files={files}
                                taskVersionUri={version['@id']}
                                content={versionContent}
                            />
                        </div>

                        <div className="card-footer d-flex align-items-center justify-content-end">
                            {isSubmitting && <Spinner />}

                            <LinkButton disabled={isSubmitting} onClick={close}>
                                Annuleer
                            </LinkButton>

                            <WarningButton disabled={isSubmitting || !isValid || !dirty} className="ml-2" type="submit">
                                Opslaan
                            </WarningButton>
                        </div>
                    </div>
                </FForm>
            )}
        </Formik>
    );

    function handleSubmit(values, { setSubmitting }) {
        updateTaskVersion({
            uri: version['@id'],
            body: {
                content: values.content,
            },
        }).then(() => {
            setSubmitting(false);
            close();
        });
    }
}
