import { Modal } from 'react-bootstrap';
import { NumberParam, useQueryParam } from 'use-query-params';
import { Form as FForm, Formik } from 'formik';
import * as Yup from 'yup';
import { FormModal, ModalFooter } from '../../../global/FormModal';
import { useGetAdminAccountQuery, useUpdateAdminAccountMutation } from '../../../../features/security/authApi';
import { FormField, InputMultipleSelect } from '../../../publications_v2/helpers/FieldHelper';
import HelperFunctions from '../../../global/HelperFunctions';

export function EditAccountModal() {
    const [editAccount, setEditAccount] = useQueryParam('editAccount', NumberParam);

    const { account } = useGetAdminAccountQuery(editAccount, {
        selectFromResult: ({ data }) => ({
            account: data,
        }),
        skip: editAccount === undefined,
    });

    const hide = () => {
        setEditAccount(undefined);
    };

    return (
        <FormModal onHide={hide} show={editAccount !== undefined} title="Account bewerken">
            {editAccount !== undefined && account && account.id === editAccount && (
                <ModalContent account={account} hide={hide} />
            )}
        </FormModal>
    );
}

function ModalContent({ account, hide }) {
    const [updateAdminAccount] = useUpdateAdminAccountMutation();
    const sortedUsers = [...account.users].sort(HelperFunctions.sortByString('fullName'));
    const sortedManagers = [...account.managers].sort(HelperFunctions.sortByString('fullName'));

    const handleSubmit = (values, { setSubmitting }) => {
        const body = {
            name: values.name,
            managers: values.managers.map((user) => user.id),
        };

        updateAdminAccount({ id: account.id, body }).then(() => {
            setSubmitting(false);
            hide();
        });

        setSubmitting(false);
    };

    return (
        <Formik
            initialValues={{
                ...account,
                managers: HelperFunctions.prepareDropdownData(sortedManagers, 'fullName'),
            }}
            enableReinitialize={true}
            validationSchema={AccountSchema}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, isValid, dirty, setFieldValue, values }) => (
                <FForm autoComplete="off">
                    <Modal.Body>
                        <FormField label="Naam" name="name" props={{ required: true }} />

                        <div className="mb-3 form-group row">
                            <label htmlFor="managers" className="form-label col-form-label col-sm-4">
                                Managers
                            </label>

                            <div className="col-sm-8">
                                <InputMultipleSelect
                                    name="managers"
                                    options={HelperFunctions.prepareDropdownData(sortedUsers, 'fullName')}
                                    onChange={(selectedOptions) => {
                                        setFieldValue('managers', selectedOptions ?? []);
                                    }}
                                    value={values.managers}
                                />
                            </div>
                        </div>
                    </Modal.Body>

                    <ModalFooter isSubmitting={isSubmitting} isValid={isValid} dirty={dirty} onHide={hide} />
                </FForm>
            )}
        </Formik>
    );
}

const AccountSchema = Yup.object().shape({
    name: Yup.string().required(),
});
