import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import DocumentApi from '../../api/DocumentApi';
import { normalize } from 'normalizr';
import { areaEntity } from '../../schemas';
import _ from 'lodash';
import { views } from '../../pages/documents_v2/config/views';

const DocumentAdapter = createEntityAdapter();
const SectionAdapter = createEntityAdapter();
const AreaAdapter = createEntityAdapter();
const DocumentGroupAdapter = createEntityAdapter();
const PackageGroupAdapter = createEntityAdapter();
const ChangesAdapter = createEntityAdapter();

/**
 * Documents
 */

export const fetchArea = createAsyncThunk('documents_v2/fetchArea', async ({ id }) => {
    const data = await DocumentApi.getArea(id);
    const normalized = normalize(data ?? [], areaEntity);

    return normalized.entities;
});

const documentsSlice = createSlice({
    name: 'documents_v2',
    initialState: {
        documents: DocumentAdapter.getInitialState(),
        sections: SectionAdapter.getInitialState(),
        areas: AreaAdapter.getInitialState(),
        documentGroups: DocumentGroupAdapter.getInitialState(),
        packageGroups: PackageGroupAdapter.getInitialState(),
        changes: ChangesAdapter.getInitialState(),
        rightSideEditorView: views.CHANGES,
        blockPositions: {},
        loading: {
            fetchDocuments: false,
        },
        filters: {
            search: '',
        },
    },
    reducers: {
        setFilter: {
            reducer(state, action) {
                const { key, value } = action.payload;
                state.filters[key] = value;
            },
        },
        setRightSideEditorView: {
            reducer(state, action) {
                state.rightSideEditorView = action.payload;
            },
        },
        setBlockPosition: {
            reducer(state, action) {
                const { blockId, rect, view } = action.payload;
                const currentRect = state.blockPositions[blockId] ?? {};

                state.blockPositions[blockId] = {
                    ...currentRect,
                    [view]: rect,
                };
            },
        },
    },
    extraReducers: {
        ['security/resetState']: (state) => {
            Object.assign(state, documentsSlice.getInitialState());
        },
        [fetchArea.fulfilled]: (state, action) => {
            AreaAdapter.upsertMany(state.areas, action.payload.areas ?? []);
        },
    },
});

export const { setFilter, setRightSideEditorView, setBlockPosition } = documentsSlice.actions;

export default documentsSlice.reducer;

export const { selectById: selectAreaById } = AreaAdapter.getSelectors((state) => state.areas);

export const getBlockHeight = (blockId, view) =>
    createSelector([(state) => state.documents_v2.blockPositions], (blockPositions) => {
        return _.get(blockPositions, [blockId, view, 'height'], 0);
    });
