import { Col, Form, Modal, Row } from 'react-bootstrap';
import { Switch } from '../../../publications_v2/helpers/FieldHelper';
import { FormModal, ModalFooter } from '../../../global/FormModal';
import { Field, Form as FForm, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { VariantPackage } from '../../../../models/documents.models';
import { useGetCategory } from '../../../../features/reimbursements/reimbursementHooks';
import { useParams } from 'react-router-dom';
import { ReimbursementProductField } from '../../../../models/reimbursement.models';
import { useAddPropositionSummaryTokenMutation } from '../../../../features/publications/publicationApi';
import { PropositionSummaryToken } from '../../../../models/publications.models';
import getEnv from '../../../../config/Env';
import GroupByLabelSelect from './GroupByLabelSelect';
import LabelsSelect from './LabelsSelect';
import { useTranslation } from 'react-i18next';

interface FormInterface {
    variantPackageUri: string;
    categoryUri: string;
    showFieldNames: boolean;
    hideEmpty: boolean;
    addPrimaryAndSecondary: boolean;
    showOnlySecondary: boolean;
    fields: ReimbursementProductField[];
    labels: string[];
    groupByLabel: string;
}

const labelWidth = 8;
const formWidth = 4;

export default function GenerateSummaryModal({
    proposition,
    handleClose,
}: {
    proposition: VariantPackage;
    handleClose: () => void;
}) {
    const params: { categoryId: string } = useParams();
    const { category } = useGetCategory(parseInt(params.categoryId));
    const [addPropositionSummaryToken] = useAddPropositionSummaryTokenMutation();
    const { t } = useTranslation('reimbursements');

    const handleSubmit = (values: FormInterface, helpers: FormikHelpers<FormInterface>) => {
        addPropositionSummaryToken(values).then((response) => {
            handleClose();

            if ('data' in response) {
                const token: PropositionSummaryToken = response.data;
                window.open(`${getEnv('PUBLICATION_API_URL')}${token['@id']}`, '_blank', 'noreferrer');
            }
        });

        helpers.setSubmitting(false);
    };

    return (
        <FormModal show={true} onHide={handleClose} title={t('view.generateSummary.plan')}>
            <Formik
                enableReinitialize={true}
                initialValues={
                    {
                        variantPackageUri: proposition['@id'],
                        categoryUri: category ? category['@id'] : '',
                        showFieldNames: true,
                        hideEmpty: false,
                        addPrimaryAndSecondary: true,
                        showOnlySecondary: false,
                        fields: [],
                        labels: [],
                        groupByLabel: '',
                    } as FormInterface
                }
                validationSchema={GenerateSummarySchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, dirty, isValid }) => (
                    <FForm autoComplete="off" target="_blank">
                        <Modal.Body>
                            <Form.Group>
                                <div className="font-weight-bold">{t('view.generateSummary.proposition')}</div>
                                <Form.Control plaintext readOnly defaultValue={proposition.name} />
                            </Form.Group>

                            <div className="font-weight-bold mb-2">{t('view.generateSummary.displayOptions')}</div>

                            <Switch
                                name="showFieldNames"
                                label={t('view.generateSummary.showFieldNames')}
                                labelWidth={labelWidth}
                                formWidth={formWidth}
                                wrapperClassName="mb-0"
                            />

                            <Switch
                                name="addPrimaryAndSecondary"
                                label={t('view.generateSummary.addBase')}
                                labelWidth={labelWidth}
                                formWidth={formWidth}
                                wrapperClassName="mb-0"
                            />

                            <Form.Group>
                                <Form.Label className="col-form-label">{t('view.generateSummary.fields')}*</Form.Label>

                                {category?.reimbursementProductFields.map((field) => {
                                    return (
                                        <div className="form-check" key={`fields.${field.slug}`}>
                                            <Field
                                                type="checkbox"
                                                name="fields"
                                                value={field.slug}
                                                id={`fields.${field.slug}`}
                                                className="form-check-input"
                                            />
                                            <label htmlFor={`fields.${field.slug}`} className="form-check-label">
                                                {field.name}
                                            </label>
                                        </div>
                                    );
                                })}
                            </Form.Group>

                            <GroupByLabelSelect />

                            <div className="font-weight-bold pt-2 mb-2">{t('view.generateSummary.filterOptions')}</div>

                            <Switch
                                name="hideEmpty"
                                label={t('view.generateSummary.hideReimbursements')}
                                labelWidth={labelWidth}
                                formWidth={formWidth}
                                wrapperClassName="mb-0"
                            />

                            <Row>
                                <Col sm="8">
                                    <small className="form-text text-muted mt-n1 mb-2">
                                        {t('view.generateSummary.ifNoCoverage')}
                                    </small>
                                </Col>
                            </Row>

                            <Switch
                                name="showOnlySecondary"
                                label={t('view.generateSummary.showOnlyFieldsWithSUp')}
                                labelWidth={labelWidth}
                                formWidth={formWidth}
                                wrapperClassName="mb-0"
                            />

                            <Row className="mb-2">
                                <Col sm="8">
                                    <small className="form-text text-muted mt-n1 mb-2">
                                        {t('view.generateSummary.ifOnlyOne')}
                                    </small>
                                </Col>
                            </Row>

                            <LabelsSelect />
                        </Modal.Body>

                        <ModalFooter
                            btnSubmitText={t('view.generateSummary.createOverview') || ''}
                            dirty={dirty}
                            isSubmitting={isSubmitting}
                            isValid={isValid}
                            onHide={handleClose}
                        />
                    </FForm>
                )}
            </Formik>
        </FormModal>
    );
}

const GenerateSummarySchema = Yup.object().shape({
    fields: Yup.array().min(1),
});
