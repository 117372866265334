import { useDispatch, useSelector } from 'react-redux';
import {
    fetchOpEntryBlocksForOpEntry,
    fetchOpEntryVariants,
    selectOpEntryBlocksForOpEntry,
    selectOpVariantsFromOpEntry,
} from '../../features/operationsList/operationsListSlice';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import LoadingSpinner from '../global/LoadingSpinner';
import { useGetBaseVariant } from './hooks/useGetBaseVariant';
import { useTranslation } from 'react-i18next';

export default function OpEntryBlockVariantsSidebar({ context }) {
    const { opEntry, opEntryBlock } = context;
    const { t } = useTranslation('changelist');

    const entryBlocks = useSelector(selectOpEntryBlocksForOpEntry(opEntry));
    const entryVariants = useSelector(selectOpVariantsFromOpEntry(opEntry['@id']));
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (loading) {
            Promise.all([
                dispatch(fetchOpEntryBlocksForOpEntry({ opEntryId: opEntry.id })),
                dispatch(fetchOpEntryVariants({ id: opEntry.id })),
            ]).then(() => setLoading(false));
        }
    }, [dispatch, opEntry]);

    const baseVariantForDocument = useGetBaseVariant(opEntry.documentId);

    if (loading) {
        return (
            <div>
                <LoadingSpinner />
            </div>
        );
    }

    const documentVariantsToShow = resolveDocumentVariantsToShow(
        baseVariantForDocument,
        opEntry,
        entryBlocks,
        entryVariants,
        opEntryBlock
    );

    return (
        <div>
            <h4 className="pr-4">{t('changelist.variantSidebar.linkedVariants')}</h4>

            {documentVariantsToShow.map((documentVariant) => (
                <DocumentVariant documentVariant={documentVariant} key={documentVariant.id} />
            ))}

            {documentVariantsToShow.length <= 0 && (
                <div className="small text-muted">{t('changelist.variantSidebar.noVariants')}</div>
            )}
        </div>
    );
}

function DocumentVariant({ documentVariant, level = 0 }) {
    if (documentVariant.variantGroup) {
        return (
            <>
                <div className="text-primary font-weight-bold mb-2 pt-1">
                    {documentVariant.prefix && <span className="text-secondary pr-2">{documentVariant.prefix}</span>}
                    {documentVariant.name}
                </div>

                {documentVariant.children.length > 0 && (
                    <div className="pl-3">
                        {documentVariant.children.map((_variant) => (
                            <DocumentVariant documentVariant={_variant} level={level + 1} key={_variant.id} />
                        ))}
                    </div>
                )}
            </>
        );
    }

    // This way we can see if the variant has been removed/added this change
    let additionalStyling = '';
    if (documentVariant.insertHighlight) {
        additionalStyling = 'opList-linked-variants-ins';
    } else if (documentVariant.deleteHighlight) {
        additionalStyling = 'opList-linked-variants-del';
    } else if (documentVariant.notIncludedInOpEntry) {
        additionalStyling = 'opList-linked-variants-not-in-opEntry';
    }

    return (
        <div className={`small mb-2 ${additionalStyling}`}>
            {documentVariant.prefix && <span className="text-secondary pr-2">{documentVariant.prefix}</span>}
            {documentVariant.name}
        </div>
    );
}

function resolveDocumentVariantsToShow(baseVariantForDocument, opEntry, entryBlocks, entryVariants, opEntryBlock) {
    const entryVariantMap = {};

    // Add variants of opEntry to the array, include the info to highlight it or not.
    _.forEach(entryBlocks, (entryBlock) => {
        if (entryBlock.blockId !== opEntryBlock.blockId) {
            return null; // continue
        }

        const entryVariant = _.find(entryVariants, ['@id', entryBlock.opEntryVariant]);
        if (!entryVariant) {
            return null;
        }

        // For filtering we only need the id, but we want to add to the documentVariant if it needs to be highlighted or not.
        // This way we can see if the variant has been removed/added this change
        entryVariantMap[entryVariant.variantId] = {
            insertHighlight: entryBlock.insertHighlight ?? false,
            deleteHighlight: entryBlock.deleteHighlight ?? false,
        };
    });

    // Add any variants of the area to the array that have not been added yet.
    // Since these are not included in the opEntry they wont have any highlighting.
    _.forEach(opEntry?.areaVariantIds ?? [], (variantId) => {
        if (!_.has(entryVariantMap, variantId)) {
            entryVariantMap[variantId] = {
                notIncludedInOpEntry: true,
            };
        }
    });

    const documentVariants = _.cloneDeep(baseVariantForDocument.children);
    return filterOutVariants(documentVariants, entryVariantMap);
}

function filterOutVariants(documentVariants, allowedVariantsMap) {
    return _.chain(documentVariants)
        .filter((documentVariant) => filterVariant(documentVariant, allowedVariantsMap))
        .compact()
        .value();
}

function filterVariant(documentVariant, allowedVariantsMap) {
    if (documentVariant.variantGroup) {
        const filtered = _.chain(documentVariant.children)
            .filter((child) => filterVariant(child, allowedVariantsMap))
            .compact()
            .value();
        if (filtered.length <= 0) {
            return null;
        }

        documentVariant.children = filtered;
        return documentVariant;
    }

    if (!_.has(allowedVariantsMap, documentVariant.id)) {
        return;
    }

    // Add to the documentVariant if it needs to be highlighted or not.
    // This way we can see if the variant has been removed/added this change
    const variantConfig = allowedVariantsMap[documentVariant.id];
    documentVariant.insertHighlight = variantConfig.insertHighlight;
    documentVariant.deleteHighlight = variantConfig.deleteHighlight;
    documentVariant.notIncludedInOpEntry = variantConfig.notIncludedInOpEntry;

    return documentVariant;
}
