import { Navbar } from 'react-bootstrap';
import { Link, useParams, useRouteMatch, generatePath } from 'react-router-dom';
import { BoxArrowUp, Gear, GraphUp, ShieldCheck } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { selectOpListById } from '../../../features/operationsList/operationsListSlice';
import {
    EXPORT_PATH,
    REPORTS_PATH,
    SETTINGS_PATH,
    VIEW_ENTRY_PATH,
    VIEW_LABEL_PATH,
    VIEW_PATH,
} from '../../../scenes/OperationsList';
import { useTranslation } from 'react-i18next';

export default function SubNav() {
    const { id, selectedPage } = useParams();
    const opList = useSelector((state) => selectOpListById(state.operations, id));

    const isListPath = useRouteMatch({
        path: [VIEW_PATH, VIEW_ENTRY_PATH, VIEW_LABEL_PATH],
        exact: true,
    });
    const isReportPath = useRouteMatch({ path: REPORTS_PATH, exact: true });
    const isExportPath = useRouteMatch({ path: EXPORT_PATH, exact: true });
    const isSettingsPath = useRouteMatch({ path: SETTINGS_PATH, exact: false });

    const userIsAdmin = opList && opList.userIsAdmin;
    const userIsDepartmentManager = opList && opList.userIsTeamManager;
    const { t } = useTranslation('changelist');

    return (
        <Navbar fixed="top" className="subnav bg-light border-bottom px-3 py-0">
            <ul className="nav justify-content-end">
                <li className="nav-item">
                    <Link
                        to={generatePath(VIEW_PATH, {
                            id,
                            selectedPage,
                        })}
                        className={'nav-link' + (isListPath ? ' active' : '')}
                    >
                        <span className="mr-1" style={{ fontSize: '0.95rem' }}>
                            <ShieldCheck />
                        </span>{' '}
                        {t('changelist.subnav.list.title')}
                    </Link>
                </li>
                {userIsDepartmentManager && (
                    <li className="nav-item">
                        <Link
                            to={generatePath(REPORTS_PATH, {
                                id,
                            })}
                            className={'nav-link' + (isReportPath ? ' active' : '')}
                        >
                            <span className="mr-1" style={{ fontSize: '0.95rem' }}>
                                <GraphUp />
                            </span>{' '}
                            {t('changelist.subnav.report.title')}
                        </Link>
                    </li>
                )}
                {userIsDepartmentManager && (
                    <li className="nav-item">
                        <Link
                            to={generatePath(EXPORT_PATH, {
                                id,
                            })}
                            className={'nav-link' + (isExportPath ? ' active' : '')}
                        >
                            <span className="mr-1" style={{ fontSize: '0.95rem' }}>
                                <BoxArrowUp />
                            </span>{' '}
                            {t('changelist.subnav.export.title')}
                        </Link>
                    </li>
                )}
                {(userIsAdmin || userIsDepartmentManager) && (
                    <li className="nav-item">
                        <Link
                            to={generatePath(SETTINGS_PATH, {
                                id,
                                setting: userIsAdmin ? 'properties' : 'teams',
                            })}
                            className={'nav-link' + (isSettingsPath ? ' active' : '')}
                        >
                            <span className="mr-1" style={{ fontSize: '0.95rem' }}>
                                <Gear />
                            </span>{' '}
                            {t('changelist.subnav.settings.settings')}
                        </Link>
                    </li>
                )}
            </ul>
        </Navbar>
    );
}
