import {
    useGetCategoriesQuery,
    useGetReimbursementsQuery,
    useGetTranslationContainerEntityReferencesQuery,
    useGetTranslationKeysQuery,
} from '../../../../../features/translations/translationApi';
import { entityTypes, views } from '../../../config/Constants';
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { Card } from 'react-bootstrap';
import { MemoizedTranslationKey } from '../TranslationKeyCard';
import { isEnabledForTranslation } from '../../../helpers/TranslationKeyHelper';
import {
    EntityReference,
    ReimbursementContainer as ReimbursementContainerModel,
    TranslationContainer,
    TranslationKey,
    TranslationLanguage,
} from '../../../../../models/translation.models';
import { useAppSelector } from '../../../../../store';
import { SidebarFilters } from '../../../config/interfaces';
import { useParams } from 'react-router-dom';
import { ProductsContainer } from '../documentContainer/ProductsContainer';

export function ReimbursementContainer({
    reimbursementContainer,
    translationLanguage,
}: {
    reimbursementContainer: ReimbursementContainerModel;
    translationLanguage: TranslationLanguage;
}) {
    const { view = views.REIMBURSEMENTS }: { view: string } = useParams();

    if (view === views.REIMBURSEMENTS) {
        return (
            <ReimbursementsView
                reimbursementContainer={reimbursementContainer}
                languageIso={translationLanguage.languageIso}
            />
        );
    }

    if (view === views.PRODUCTS) {
        return (
            <ProductsContainer
                translationContainer={reimbursementContainer as TranslationContainer}
                translationLanguage={translationLanguage}
            />
        );
    }

    return <div>View {view} is not supported.</div>;
}

function ReimbursementsView({
    reimbursementContainer,
    languageIso,
}: {
    reimbursementContainer: ReimbursementContainerModel;
    languageIso: string;
}) {
    const { translationKeys } = useGetTranslationKeysQuery(
        {
            uri: reimbursementContainer['@id'],
            entityType: entityTypes.REIMBURSEMENT_PRODUCT_FIELD,
        },
        {
            selectFromResult: ({ data }) => ({
                translationKeys: data ?? [],
            }),
        }
    );

    return (
        <FilteredReimbursementContainer
            translationKeys={translationKeys}
            categoryId={reimbursementContainer.categoryId}
            reimbursementContainerUri={reimbursementContainer['@id']}
            languageIso={languageIso}
        />
    );
}

function FilteredReimbursementContainer({
    translationKeys,
    languageIso,
    categoryId,
    reimbursementContainerUri,
}: {
    translationKeys: TranslationKey[];
    languageIso: string;
    categoryId: number;
    reimbursementContainerUri: string;
}) {
    const { sidebarFilters }: { sidebarFilters: SidebarFilters } = useAppSelector((state) => state.translation);
    const { showDisabledForTranslation = true, reimbursement = null } = sidebarFilters;
    const reimbursementProductsToFilter = useReimbursementProductsToFilter(categoryId);

    const { allEntityReferences } = useGetTranslationContainerEntityReferencesQuery(
        {
            uri: reimbursementContainerUri,
        },
        {
            selectFromResult: ({ data }) => ({
                allEntityReferences: data ?? [],
            }),
            skip: reimbursement === null,
        }
    );

    const shouldApplyFilter = showDisabledForTranslation === false || reimbursement !== null;

    const filteredTranslationKeys = shouldApplyFilter
        ? translationKeys.filter((_translationKey) => {
              const { entityReferences } = _translationKey;

              // Reimbursement select filter
              if (reimbursement !== null && allEntityReferences.length > 0) {
                  const itemEntityReferences = entityReferences.map((uri) =>
                      allEntityReferences.find((ref) => ref['@id'] === uri)
                  );

                  return itemEntityReferences.some((_ref: EntityReference | undefined) => {
                      if (_ref === undefined) {
                          return false;
                      }

                      const parent = allEntityReferences.find((ref) => ref['@id'] === _ref.parent);

                      if (parent === undefined) {
                          return false;
                      }

                      return reimbursementProductsToFilter.includes(
                          `/api/reimbursement-api/api/reimbursement_products/${parent.entityId}`
                      );
                  });
              }

              // Hide item if not enabled for translation and filter option is turned off
              if (
                  showDisabledForTranslation === false &&
                  isEnabledForTranslation(_translationKey, languageIso) === false
              ) {
                  return false;
              }

              return true;
          })
        : translationKeys;

    return (
        <PaginatedReimbursementContainer
            translationKeys={filteredTranslationKeys}
            categoryId={categoryId}
            languageIso={languageIso}
        />
    );
}

function PaginatedReimbursementContainer({
    translationKeys,
    languageIso,
    itemsPerPage = 7,
    categoryId,
}: {
    translationKeys: TranslationKey[];
    languageIso: string;
    itemsPerPage?: number;
    categoryId: number;
}) {
    const [itemOffset, setItemOffset] = useState(0);
    const [currentItems, setCurrentItems] = useState([] as TranslationKey[]);
    const [pageCount, setPageCount] = useState(0);

    useEffect(() => {
        const newPageCount = Math.ceil(translationKeys.length / itemsPerPage);
        let tempOffset = itemOffset;

        if (newPageCount < pageCount) {
            tempOffset = 0;
        }

        // Fetch items from another resources.
        const endOffset = tempOffset + itemsPerPage;

        setCurrentItems(translationKeys.slice(tempOffset, endOffset));
        setPageCount(newPageCount);
        setItemOffset(tempOffset);
    }, [itemOffset, itemsPerPage, translationKeys]);

    const handlePageClick = (event: { selected: number }) => {
        const newOffset = (event.selected * itemsPerPage) % translationKeys.length;
        setItemOffset(newOffset);
    };

    return (
        <div>
            <ReimbursementContainerTitle
                categoryId={categoryId}
                itemLength={currentItems.length}
                itemOffset={itemOffset}
                totalLength={translationKeys.length}
            />

            <TranslationKeys
                items={currentItems}
                languageIso={languageIso}
                entityType={entityTypes.REIMBURSEMENT_PRODUCT_FIELD}
            />

            {translationKeys.length > itemsPerPage && (
                <div style={{ marginLeft: 50 }}>
                    <ReactPaginate
                        pageCount={pageCount}
                        forcePage={itemOffset > 0 ? itemOffset / itemsPerPage : 0}
                        onPageChange={handlePageClick}
                        previousLabel="‹"
                        nextLabel="›"
                        containerClassName="pagination"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        activeClassName="active"
                    />
                </div>
            )}
        </div>
    );
}

export function TranslationKeys({
    items,
    languageIso,
    entityType,
}: {
    items: TranslationKey[];
    languageIso: string;
    entityType: string;
}) {
    return (
        <div className="dr-accordion mb-4">
            {items.map((item, index) => (
                <Card className="mb-2" key={`translation-key-${index}`}>
                    <Card.Header className="d-flex align-items-center p-0">
                        <MemoizedTranslationKey
                            translationKeyUri={item['@id']}
                            languageIso={languageIso}
                            reimbursementContainerUri={item.translationContainer}
                            entityType={entityType}
                        />
                    </Card.Header>
                </Card>
            ))}
        </div>
    );
}

function ReimbursementContainerTitle({
    categoryId,
    itemLength,
    itemOffset,
    totalLength,
}: {
    categoryId: number;
    itemLength: number;
    itemOffset: number;
    totalLength: number;
}) {
    const { category } = useGetCategoriesQuery(undefined, {
        selectFromResult: ({ data }) => ({
            category: data ? data.find((_item) => _item.id === categoryId) : undefined,
        }),
    });

    if (category === undefined) {
        return null;
    }

    return (
        <div style={{ marginBottom: '2rem' }}>
            <h5 className="card-title">
                {category.name}

                <span className="small text-secondary ml-2">
                    {itemOffset + 1} - {itemOffset + itemLength} van {totalLength} onderdelen
                </span>
            </h5>
        </div>
    );
}

function useReimbursementProductsToFilter(categoryId: number) {
    const { sidebarFilters }: { sidebarFilters: SidebarFilters } = useAppSelector((state) => state.translation);

    const { reimbursement } = useGetReimbursementsQuery(categoryId, {
        selectFromResult: ({ data }) => ({
            reimbursement: data
                ? data.find((reimbursement) => reimbursement['@id'] === sidebarFilters.reimbursement)
                : undefined,
        }),
        skip: sidebarFilters.reimbursement === null,
    });

    return reimbursement?.reimbursementProducts ?? [];
}
