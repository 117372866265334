import Constants from '../../config/Constants';
import { useGetOpList } from 'pages/operations_list/hooks/useGetOpList';

export default function RestrictedOpListContent({ opListId, roles = [], children }) {
    const opList = useGetOpList(opListId);

    const hasPermissions = () => {
        if (opList.userIsAdmin) {
            return roles.includes(Constants.opListUserRoles.admin);
        }

        if (opList.userIsEditor) {
            return roles.includes(Constants.opListUserRoles.editor);
        }

        if (opList.userIsTeamManager) {
            return roles.includes(Constants.opListUserRoles.teamManager);
        }

        return false;
    };

    if (opList === undefined) {
        return null;
    }

    if (roles.length > 0 && !hasPermissions()) {
        return null;
    }

    return <>{children}</>;
}
