import { Form as FForm, Formik } from 'formik';
import * as yup from 'yup';
import {
    useAddPropositionMutation,
    useUpdatePropositionMutation,
} from '../../../../features/reimbursements/reimbursements';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { PROPOSITION_EDIT_PATH } from '../../../../scenes/Reimbursements';

export default function EditPublicationForm({ proposition, children }) {
    const [addProposition] = useAddPropositionMutation();
    const [updateProposition] = useUpdatePropositionMutation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { categoryId, folderId } = useParams();

    const handleSubmit = ((values, { setSubmitting }) => {
        if (proposition.hasOwnProperty('id')) {
            dispatch(
                updateProposition({
                    uri: proposition['@id'],
                    ...values,
                    variantPackageProducts: values.variantPackageProducts.map((_item, index) => ({
                        ..._item,
                        id: _item['@id'] ?? null,
                        sortOrder: index,
                    })),
                }).then(() => {
                    setSubmitting(false);
                }),
            );
        } else {
            dispatch(
                addProposition({
                    ...values,
                }).then(({ data }) => {
                    setSubmitting(false);

                    history.push(generatePath(PROPOSITION_EDIT_PATH, {
                        categoryId,
                        action: 'properties',
                        propositionId: data.id,
                        folderId,
                    }));
                }),
            );
        }
    });

    return (
        <Formik
            enableReinitialize
            initialValues={proposition}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            {() => {
                return (
                    <FForm autoComplete="off" id="editPropositionForm">
                        {children}
                    </FForm>
                );
            }}
        </Formik>
    );
}

const validationSchema = yup.object().shape({
    name: yup.string().required(),
    variantPackageProducts: yup.array()
        .of(yup.object().shape({
            primaryProductCode: yup.string().required(),
            primaryDescription: yup.string().required(),
            secondaryDescription: yup.string().when('secondaryProductCode', {
                is: (value) => !!value,
                then: yup.string().required(),
            }),
        })),
});
