import { TranslationContainer, TranslationKey, TranslationLanguage } from '../../../../../models/translation.models';
import { useGetTranslationKeysQuery } from '../../../../../features/translations/translationApi';
import { entityTypes } from '../../../config/Constants';
import { TranslationKeys } from '../reimbursementContainer/ReimbursementContainer';
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useAppSelector } from '../../../../../store';
import { isEnabledForTranslation } from '../../../helpers/TranslationKeyHelper';
import { useTranslation } from 'react-i18next';

export function ProductsContainer({
    translationContainer,
    translationLanguage,
}: {
    translationContainer: TranslationContainer;
    translationLanguage: TranslationLanguage;
}) {
    const { translationKeys } = useGetTranslationKeysQuery(
        {
            uri: translationContainer['@id'],
            entityType: entityTypes.VARIANT_PACKAGE,
        },
        {
            selectFromResult: ({ data }) => ({
                translationKeys: data ?? [],
            }),
        }
    );

    return (
        <FilteredProductsContainer translationKeys={translationKeys} languageIso={translationLanguage.languageIso} />
    );
}

function FilteredProductsContainer({
    translationKeys,
    languageIso,
}: {
    translationKeys: TranslationKey[];
    languageIso: string;
}) {
    const { showDisabledForTranslation } = useAppSelector<{ showDisabledForTranslation: boolean }>(
        (state) => state.translation.sidebarFilters
    );
    const shouldApplyFilter = !showDisabledForTranslation;

    const filteredTranslationKeys = shouldApplyFilter
        ? translationKeys.filter((item) => {
              // Hide item if not enabled for translation and filter option is turned off
              if (!showDisabledForTranslation && isEnabledForTranslation(item, languageIso) === false) {
                  return false;
              }

              return true;
          })
        : translationKeys;

    return <PaginatedProductsContainer translationKeys={filteredTranslationKeys} languageIso={languageIso} />;
}

function PaginatedProductsContainer({
    translationKeys,
    languageIso,
    itemsPerPage = 7,
}: {
    translationKeys: TranslationKey[];
    languageIso: string;
    itemsPerPage?: number;
}) {
    const [itemOffset, setItemOffset] = useState(0);
    const [currentItems, setCurrentItems] = useState<TranslationKey[]>([]);
    const [pageCount, setPageCount] = useState(0);

    useEffect(() => {
        const newPageCount = Math.ceil(translationKeys.length / itemsPerPage);
        let tempOffset = itemOffset;

        if (newPageCount < pageCount) {
            tempOffset = 0;
        }

        // Fetch items from another resources.
        const endOffset = tempOffset + itemsPerPage;

        setCurrentItems(translationKeys.slice(tempOffset, endOffset));
        setPageCount(newPageCount);
        setItemOffset(tempOffset);
    }, [itemOffset, itemsPerPage, translationKeys]);

    const handlePageClick = (selectedItem: { selected: number }) => {
        const newOffset = (selectedItem.selected * itemsPerPage) % translationKeys.length;
        setItemOffset(newOffset);
    };

    return (
        <div>
            <ProductsContainerTitle
                itemLength={currentItems.length}
                itemOffset={itemOffset}
                totalLength={translationKeys.length}
            />

            <TranslationKeys languageIso={languageIso} items={currentItems} entityType={entityTypes.VARIANT_PACKAGE} />

            {translationKeys.length > itemsPerPage && (
                <div style={{ marginLeft: 50 }}>
                    <ReactPaginate
                        pageCount={pageCount}
                        forcePage={itemOffset > 0 ? itemOffset / itemsPerPage : 0}
                        onPageChange={handlePageClick}
                        previousLabel="‹"
                        nextLabel="›"
                        containerClassName="pagination"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        activeClassName="active"
                    />
                </div>
            )}
        </div>
    );
}

function ProductsContainerTitle({
    itemLength,
    itemOffset,
    totalLength,
}: {
    itemLength: number;
    itemOffset: number;
    totalLength: number;
}) {
    const { t } = useTranslation('reimbursements');

    return (
        <div style={{ marginBottom: '2rem' }}>
            <h5 className="card-title">
                {t('proposition.editProposition.policies.title')}
                <span className="small text-secondary ml-2">
                    {itemOffset + 1} - {itemOffset + itemLength} {t('reimbursement.product.misc.of')} {totalLength}{' '}
                    {t('reimbursement.product.misc.parts')}
                </span>
            </h5>
        </div>
    );
}
