import { Modal } from 'react-bootstrap';
import { NumberParam, useQueryParam } from 'use-query-params';
import { FormModal, ModalFooter } from 'pages/global/FormModal';
import { useGetVariant } from '../../../hooks/useGetVariant';
import { Form as FForm, Formik } from 'formik';
import * as Yup from 'yup';
import { FieldSelect, FormField, SwitchWithTitle } from 'pages/publications_v2/helpers/FieldHelper';
import { useUpdateDocumentVariantsMutation } from 'features/documents/documents';
import { useParams } from 'react-router-dom';
import { useGetBaseVariant } from '../../../hooks/useGetBaseVariant';
import { useMemo } from 'react';
import { useGetDocument } from '../../../hooks/useGetDocument';
import { useGetTemplateCategory } from 'pages/documents_v2/hooks/useGetTemplateCategory';
import { useTranslation } from 'react-i18next';

export function VariantFormModal() {
    const { t } = useTranslation('documents');
    const [editVariant, setEditVariant] = useQueryParam('edit', NumberParam);
    const [parentId, setParentId] = useQueryParam('parentId', NumberParam);
    const baseVariant = useGetBaseVariant(undefined, true);
    const document = useGetDocument();

    const editMode = editVariant !== 0;

    const hide = () => {
        setEditVariant(undefined);
        setParentId(undefined);
    };

    return (
        <FormModal
            onHide={hide}
            show={editVariant !== undefined}
            title={
                editMode
                    ? t('document.navbar.variants.editVariantTitle')
                    : t('document.navbar.variants.addVariantTitle')
            }
        >
            {baseVariant && document && (
                <ModalContent baseVariant={baseVariant} document={document} editMode={editMode} hide={hide} />
            )}
        </FormModal>
    );
}

function ModalContent({ baseVariant, document, editMode, hide }) {
    const { t } = useTranslation('documents');
    const { t: tGlobal } = useTranslation('global');
    const { documentId } = useParams();
    const [editVariant, setEditVariant] = useQueryParam('edit', NumberParam);
    const [parentId] = useQueryParam('parentId', NumberParam);
    const variant = useGetVariant(editVariant);
    const [updateDocumentVariants] = useUpdateDocumentVariantsMutation();
    const templateCategory = useGetTemplateCategory(document);

    const showIpidFields = templateCategory?.slug === 'ipid-verbond';
    const ipidFields = showIpidFields
        ? {
              ipidProperties: variant?.ipidProperties ?? {
                  cardId: '',
                  cardVersion: '',
              },
          }
        : {};

    const variantOptions = useMemo(() => {
        const variants = variantAsList(baseVariant, [], 0, t('document.navbar.variants.basicVariant'));

        return variants.filter((variant) => variant.parentId === null || variant.variantGroup);
    }, [baseVariant]);

    return (
        <Formik
            initialValues={
                variant
                    ? {
                          id: variant.id,
                          name: variant.name,
                          parentId: variant.parentId,
                          prefix: variant.prefix ?? '',
                          type: variant.variantGroup ? 'group' : 'variant',
                          enabled: variant.enabled,
                          ...ipidFields,
                      }
                    : {
                          name: '',
                          parentId: parentId ?? baseVariant.id,
                          prefix: '',
                          type: '',
                          enabled: true,
                          ...ipidFields,
                      }
            }
            enableReinitialize={true}
            validationSchema={VariantSchema}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, isValid, dirty }) => (
                <FForm autoComplete="off">
                    <Modal.Body>
                        <FieldSelect
                            name="parentId"
                            options={variantOptions}
                            props={{ placeholder: tGlobal('field.selectPlaceholder'), required: true }}
                            label={t('document.navbar.variants.position')}
                        />

                        <FieldSelect
                            name="type"
                            options={[
                                {
                                    label: t('document.navbar.variants.select.variant'),
                                    value: 'variant',
                                },
                                {
                                    label: t('document.navbar.variants.select.group'),
                                    value: 'group',
                                },
                            ]}
                            props={{
                                placeholder: tGlobal('field.selectPlaceholder'),
                                required: true,
                                isDisabled: editMode,
                            }}
                            label={t('document.navbar.variants.select.type')}
                        />
                        <FormField label={t('document.navbar.variants.name')} name="name" props={{ required: true }} />

                        <FormField label={t('document.navbar.variants.select.code')} name="prefix" />

                        {editMode && (
                            <SwitchWithTitle label={t('document.navbar.variants.select.enabled')} name="enabled" />
                        )}

                        {showIpidFields && (
                            <>
                                <FormField
                                    label="Ipid card id"
                                    name="ipidProperties.cardId"
                                    props={{ type: 'number' }}
                                />
                                <FormField
                                    label="Ipid version"
                                    name="ipidProperties.cardVersion"
                                    props={{ type: 'number' }}
                                />
                            </>
                        )}
                    </Modal.Body>

                    <ModalFooter isSubmitting={isSubmitting} isValid={isValid} dirty={dirty} onHide={hide} />
                </FForm>
            )}
        </Formik>
    );

    function handleSubmit(values, { setSubmitting }) {
        const body = {
            ...values,
            variantGroup: values.type === 'group',
        };

        // The 'type' property is not supported in the API
        delete body.type;

        updateDocumentVariants({ id: parseInt(documentId), body }).then(() => {
            setSubmitting(false);
            hide();
        });

        setSubmitting(false);
    }
}

function variantAsList(variant, list = [], depth = 0, basicVariantName = 'Basisvariant') {
    let label = '';

    if (depth > 1) {
        for (let i = 0; i < depth - 1; i++) {
            label += '-- ';
        }
    }

    if (variant.prefix) {
        label += '(' + variant.prefix + ') ';
    }

    label += variant.parentId === null ? basicVariantName : variant.name;

    list.push({
        ...variant,
        name: variant.parentId === null ? basicVariantName : variant.name,
        label: label,
        value: variant.id,
        isDisabled: variant.parentId !== null && (!variant.variantGroup || !variant.enabled),
    });

    if (variant.children.length > 0) {
        variant.children.forEach((childVariant) => {
            variantAsList(childVariant, list, depth + 1);
        });
    }

    return list;
}

const VariantSchema = Yup.object().shape({
    type: Yup.string().required(),
    name: Yup.string().required(),
    parentId: Yup.number().required(),
});
