import { useFormikContext } from 'formik';
import { useCurrentOrganisation } from '../../../hooks/useCurrentOrganisation';

export function useDocumentTemplate() {
    const { values } = useFormikContext();
    const organisation = useCurrentOrganisation();

    return values.templateId
        ? organisation?.documentTemplates.find((template) => template.id === values.templateId)
        : undefined;
}
