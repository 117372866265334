import { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import mime from 'mime';

export function FileUploader({ onDrop, isDisabled = false, extensions = [] }) {
    const accept = getAcceptedFileTypes();

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        accept,
        onDrop,
    });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
            ...(isDisabled ? disabledStyle : {}),
        }),
        [isDragActive, isDragReject, isDragAccept, isDisabled],
    );

    return (
        <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <div>Klik hier of sleep bestanden naar dit vak</div>
        </div>
    );

    function getAcceptedFileTypes() {
        const accept = {};

        extensions.forEach((extension) => {
            const mimeType = mime.getType(extension);

            if (mimeType) {
                if (accept.hasOwnProperty(mimeType)) {
                    accept[mimeType].push(`.${extension}`);
                } else {
                    accept[mime.getType(extension)] = [`.${extension}`];
                }
            }
        });

        return accept;
    }
}

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
};

const activeStyle = {
    borderColor: '#2196f3',
};

const acceptStyle = {
    borderColor: '#00e676',
    backgroundColor: '#00e67614',
};

const rejectStyle = {
    borderColor: '#ff1744',
};

const disabledStyle = {
    opacity: 0.5,
};
