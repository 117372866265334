import { useFormikContext } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';
import { Switch } from '../helpers/FieldHelper';
import { useTranslation } from 'react-i18next';
import HelperFunctions from 'pages/global/HelperFunctions';
import Select from 'react-select';
import { useGetAvailableTags } from 'pages/comments/hooks/useGetAvailableTags';

export default function ExportChanges() {
    const { t } = useTranslation('publications');
    const { values, setFieldValue } = useFormikContext();
    const availableTags = useGetAvailableTags();

    const tags = values.properties?.tags ?? [];
    const tagValues = availableTags.filter((tag) => tags.includes(tag['@id']));

    return (
        <>
            <div className="p-4">
                <Switch
                    name="properties.exportComments"
                    label={t('publication.settings.content.exportChanges.showComments')}
                />

                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4} htmlFor="commentTags">
                        {t('publication.settings.content.exportChanges.filterByTags')}
                    </Form.Label>
                    <Col sm={8}>
                        <Select
                            options={HelperFunctions.prepareDropdownData(availableTags)}
                            value={HelperFunctions.prepareDropdownData(tagValues)}
                            defaultValue={tags}
                            onChange={handleChange}
                            isMulti={true}
                            placeholder={`${t('documents:document.navbar.main.sidebar.selectVariant.titleSelect')}...`}
                            className="docrev-react-select-container"
                            classNamePrefix="docrev-react-select"
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                            theme={(theme) => ({ ...theme, borderRadius: 4 })}
                        />
                    </Col>
                </Form.Group>
            </div>
        </>
    );

    function handleChange(selectedOptions) {
        setFieldValue(
            'properties.tags',
            selectedOptions.map((option) => option['@id']),
        );
    }
}
