import { useSelector } from 'react-redux';
import { useGetTranslationContainersQuery } from '../../features/translations/translationApi';

export default function RestrictedTranslationContent({ id, roles = [], children }) {
    const currentTranslationUser = useGetCurrentTranslationUser(id);

    if (currentTranslationUser === undefined) {
        return null;
    }

    const { role } = currentTranslationUser;

    const hasPermissions = (roles, role) => {
        return roles.includes(role);
    };

    if (!hasPermissions(roles, role)) {
        return null;
    }

    return <>{children}</>;
}

export function useGetCurrentTranslationUser(id) {
    const { userProfile } = useSelector(state => state.security);
    const { container } = useGetTranslationContainersQuery(undefined, {
        selectFromResult: ({ data }) => ({
            container: data?.find((_container) => _container.id === id),
        }),
        skip: id === undefined,
    });

    if (container === undefined) {
        return undefined;
    }

    const { translationUsers = [] } = container;

    return translationUsers.find((_item) => _item.userId === userProfile.userId);
}
