import HelperFunctions from '../pages/global/HelperFunctions';
import getEnv from "../config/Env";

const Language = {
    getLanguage() {
        const lang = localStorage.getItem('lang');
        if (lang && lang !== '') {
            return lang;
        } else {
            return getEnv('DEFAULT_LANGUAGE');
        }
    },
    getTranslation(key, group, section) {
        // const section = (group !== undefined && group === 'global') ? 'global' : HelperFunctions.getSectionFromUrl();
        if (section === undefined || section === '') {
            section = HelperFunctions.getSectionFromUrl();
        }

        if (group === undefined || group === '') {
            group = 'text';
        }

        const currentLanguage = this.getLanguage();
        let translations;
        try {
            translations = getLanguageFile(currentLanguage, section);
        } catch (e) {
            // no other place to look than global section, returning an error message
            return key;
        }

        let translation = getTranslationFromFile(translations, key, group);
        if (translation) {
            return translation;
        } else if (section !== 'global') {
            // translation not found in section file, trying global file
            translations = getLanguageFile(currentLanguage, 'global');
            translation = getTranslationFromFile(translations, key, group);
            if (translation) {
                return translation;
            } else {
                return key;
            }
        } else {
            return key;
        }
    },
};

function getLanguageFile(language, section) {
    return require('./' + language + '/' + section + '.json');
}

function getTranslationFromFile(translations, key, group) {
    if (translations.hasOwnProperty(group) && translations[group].hasOwnProperty(key)) {
        return translations[group][key];
    } else if (translations.hasOwnProperty(key)) {
        return translations[key];
    } else {
        return false;
    }
}

export default Language;
