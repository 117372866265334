import { resetFilters } from '../../../../features/translations/translationSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

export default function EmptyContentMessage() {
    const dispatch = useDispatch();
    const { t } = useTranslation('translations');

    const handleReset = (event) => {
        event.preventDefault();
        dispatch(resetFilters());
    };

    return (
        <div className="text-center text-secondary pt-3">
            <p>{t('etc.noTranslations')}</p>
            <p>
                {t('etc.selectAnother')}{' '}
                <a href="#" onClick={handleReset}>
                    {t('etc.resetFilter')}
                </a>
                .
            </p>
        </div>
    );
}

export function NoFilterOptionsSelected() {
    const dispatch = useDispatch();
    const { t } = useTranslation('translations');

    const handleReset = (event) => {
        event.preventDefault();
        dispatch(resetFilters());
    };

    return (
        <div className="text-center text-secondary pt-3">
            <p>{t('etc.noFilterSelected')}</p>
            <p>
                {t('etc.selectAFilter')}{' '}
                <a href="#" onClick={handleReset}>
                    {t('etc.resetFilterOptions')}
                </a>
                .
            </p>
        </div>
    );
}
