import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import { Settings } from 'luxon';
import { store } from './store';
import * as serviceWorker from './registerServiceWorker';
import './i18n';

UIkit.use(Icons);
Settings.defaultLocale = 'nl';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <Suspense fallback="">
            <App />
        </Suspense>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/2vJdu84
serviceWorker.unregister();
