import React from 'react';
import Language from '../../../../language/Language';
import Constants from '../../../../config/Constants';
import HelperFunctions from '../../../global/HelperFunctions';
import DocumentHelper from '../../../global/DocumentHelper';
import cx from 'classnames';
import { set } from '../../../../features/app/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setEditVariantBlockId } from '../../../../features/documents/documentsSlice';
import { useParams } from 'react-router-dom';
import ClonedBlocksButton from '../_EditArea/ClonedBlocksButton';
import RestrictedContent from '../../../global/RestrictedContent';
import { Link45deg, Magic } from 'react-bootstrap-icons';
import { useGetBaseVariant } from '../../../documents_v2/hooks/useGetBaseVariant';
import { useGetDocument } from '../../../documents_v2/hooks/useGetDocument';

const EditorToolbar = (props) => {
    const { editVariantsBlockId } = useSelector((state) => state.documents.editArea);
    const dispatch = useDispatch();
    const {
        activeVariantIsDetached,
        activeVariantId,
        baseVariantId,
        block,
        blockId,
        blockIsInLayout = false,
        document,
        documentVariants,
        editMode,
        editTableMode,
        moveBlock,
        showBlockLinks,
        showTableEditor,
        textLayout,
        toggleBlockLinks,
        toggleBlockSettings,
        toggleDeleteBlock,
        toggleTableEditMode,
        setBlockImprovementModal,
    } = props;

    const blockHasAllVariants = documentVariants.length <= block.documentVariants.length;
    const blockVariantsEditMode = editVariantsBlockId && editVariantsBlockId === block.id;

    const canDelete = (block) => {
        if (block.hasOwnProperty('linkedBlocks') && block.linkedBlocks.length > 0) {
            return false;
        }

        return !block.scheduledForDeletion;
    };

    const hasLinkedBlocks = (block) => {
        return block.hasOwnProperty('linkedBlocks') && block.linkedBlocks.length > 0;
    };

    const showLabels = () => {
        dispatch(
            set({
                key: 'sidebarContext',
                value: {
                    type: 'meta',
                    context: {
                        entityType: 'block',
                        entity: block,
                        documentId: document.id,
                    },
                },
            })
        );
    };

    const toggleEditVariantBlockId = () => {
        dispatch(setEditVariantBlockId(block.id));
    };

    const iconWrapperClasses = cx('block-edit-buttons uk-button-group uk-button-group-small mr-1', {
        'block-variants-edit-mode': blockVariantsEditMode,
    });

    return (
        <div className="editor-toolbar">
            <div className="block-edit-labels uk-button-group uk-button-group-small mr-1">
                <div className="mr-3">
                    {block.new && (
                        <ToolbarLabel
                            className="uk-background-success-light"
                            text={Language.getTranslation('blockIsNew')}
                            tooltip={Language.getTranslation('blockIsNewTooltip')}
                        />
                    )}
                    {!blockHasAllVariants && (
                        <ToolbarLabel
                            className="uk-background-success-light"
                            text={Language.getTranslation('customVariants')}
                        />
                    )}
                    {block.type === Constants.blockTypes.text && !editMode && hasLinkedBlocks(block) && (
                        <TextBlockLayout blockType={block.type} textLayout="linkedBlockWarning" />
                    )}
                    {block.type === Constants.blockTypes.text && !editMode && (
                        <TextBlockLayout blockType={block.type} textLayout={textLayout} />
                    )}
                    {block.type === Constants.blockTypes.file && !editMode && <ToolbarLabel text="Afbeelding" />}
                    {block.type === Constants.blockTypes.linked && !editMode && <LinkedBlockLabel block={block} />}
                    {block.type === Constants.blockTypes.label && !editMode && <ToolbarLabel text="Keuze opties" />}
                </div>
            </div>

            <div className={iconWrapperClasses}>
                {!block.deleted && (
                    <>
                        {block.type === Constants.blockTypes.compensationTable && showTableEditor && (
                            <ToolbarButton
                                icon={editTableMode ? 'check' : 'pencil'}
                                type="primary"
                                tooltip={
                                    editTableMode
                                        ? Language.getTranslation('save', 'buttons')
                                        : Language.getTranslation('edit', 'buttons')
                                }
                                handleClick={toggleTableEditMode}
                            />
                        )}
                        {(baseVariantId === activeVariantId || activeVariantIsDetached) && (
                            <>
                                {!block.scheduledForDeletion && !showBlockLinks && (
                                    <ToolbarButton
                                        icon={editMode ? 'close' : 'settings'}
                                        type="warning"
                                        handleClick={toggleBlockSettings}
                                        tooltip="Instellingen"
                                    />
                                )}
                                {!block.scheduledForDeletion && showBlockLinks && (
                                    <ToolbarButton icon="close" type="primary" handleClick={toggleBlockLinks} />
                                )}
                                {!editMode && !showBlockLinks && (
                                    <>
                                        {block.id && (
                                            <ToolbarButton
                                                className={blockVariantsEditMode ? 'block-variants-btn' : undefined}
                                                icon="album"
                                                type="warning"
                                                handleClick={toggleEditVariantBlockId}
                                                tooltip="Varianten toewijzen"
                                            />
                                        )}
                                        {hasLinkedBlocks(block) && (
                                            <ToolbarButton
                                                tooltip="Bekijk gekoppelde artikelen"
                                                icon="link"
                                                handleClick={toggleBlockLinks}
                                            />
                                        )}
                                        {!blockIsInLayout && (
                                            <ToolbarButton
                                                icon="arrow-up"
                                                handleClick={() => moveBlock(blockId, 'up')}
                                                tooltip="Verplaats omhoog"
                                            />
                                        )}
                                        {!blockIsInLayout && (
                                            <ToolbarButton
                                                icon="arrow-down"
                                                handleClick={() => moveBlock(blockId, 'down')}
                                                tooltip="Verplaats omlaag"
                                            />
                                        )}
                                        <RestrictedContent module={Constants.modules.labels}>
                                            {block.id && (
                                                <ToolbarButton
                                                    icon="bookmark"
                                                    handleClick={showLabels}
                                                    tooltip="Labels toewijzen"
                                                />
                                            )}
                                        </RestrictedContent>
                                        {block.type === Constants.blockTypes.text && (
                                            <button
                                                data-uk-tooltip="Verbeter tekst"
                                                className="uk-button uk-button-small uk-button-icon uk-cursor-pointer"
                                                onClick={() => setBlockImprovementModal(block)}
                                            >
                                                <span className="uk-icon">
                                                    <Magic />
                                                </span>
                                            </button>
                                        )}
                                        {block.id && block.type === Constants.blockTypes.text && (
                                            <ClonedBlocksButton blockId={block.id} document={document} />
                                        )}
                                        {canDelete(block) && (
                                            <ToolbarButton
                                                tooltip="Verwijderen"
                                                icon="trash"
                                                type="danger"
                                                handleClick={() => toggleDeleteBlock(blockId, true)}
                                            />
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

const emptyText = (
    <>
        <span className="linked-icon">
            <Link45deg size={24} />
        </span>
    </>
);

export const LinkedBlockLabel = ({ block }) => {
    if (!block.hasOwnProperty('latestContent') || block.latestContent === null || block.latestContent === '') {
        return <ToolbarLabel text={emptyText} />;
    }

    const data = HelperFunctions.tryParseJSON(block.latestContent);

    if (data === false || !data.hasOwnProperty('areaId')) {
        return <ToolbarLabel text={emptyText} />;
    }

    return <LinkedBlockLabelContent data={data} />;
};

function LinkedBlockLabelContent({ data }) {
    const { documentId } = useParams();
    const blockDocumentId = data.documentId ?? documentId;
    const document = useGetDocument(undefined, true);
    const baseVariant = useGetBaseVariant();

    if (document === undefined || baseVariant === undefined) {
        return <ToolbarLabel text={emptyText} />;
    }

    const area = HelperFunctions.findInDocument({ type: 'area', id: data.areaId }, document);

    if (area === undefined) {
        return <ToolbarLabel text={emptyText} />;
    }

    const prefix = DocumentHelper.getPrefix(area, baseVariant.id);
    let title = (prefix ? prefix + ': ' : '') + area.title;

    if (parseInt(documentId) !== parseInt(blockDocumentId)) {
        title = 'Bibliotheek > ' + title;
    }

    const tooltip = `Gekoppeld aan "${title}"`;

    return <ToolbarLabel text={emptyText} tooltip={tooltip} />;
}

function TextBlockLayout({ blockType, textLayout }) {
    let layout = {
        className: '',
        text: '',
    };
    if (blockType === Constants.blockTypes.text) {
        layout.className = {
            default: 'uk-background-theme-light',
            intro: 'uk-background-theme-light uk-text-bold',
            attention: 'uk-background-warning-light',
            info: 'uk-background-special',
            'more-info': 'uk-background-special',
            special: 'uk-background-special',
            linkedBlockWarning: 'uk-background-danger uk-text-white',
        }[textLayout];
        layout.text = {
            default: Language.getTranslation('textLayoutDefault', '', 'documents'),
            intro: Language.getTranslation('textLayoutIntro', '', 'documents'),
            attention: Language.getTranslation('textLayoutAttention', '', 'documents'),
            info: Language.getTranslation('textLayoutInfo', '', 'documents'),
            'more-info': Language.getTranslation('textLayoutMoreInfo', '', 'documents'),
            special: Language.getTranslation('textLayoutSpecial', '', 'documents'),
            linkedBlockWarning: (
                <>
                    <Link45deg /> Gedeeld blok
                </>
            ),
        }[textLayout];
    }

    return <ToolbarLabel className={layout.className} text={layout.text} />;
}

export const ToolbarButton = (props) => {
    const { handleClick, icon, tooltip, type, className } = props;
    const buttonClass = cx(className, 'uk-button uk-button-small uk-button-icon uk-cursor-pointer', {
        'uk-text-warning': type === 'warning',
        'uk-text-default': type === 'default',
        'uk-text-primary': type === 'primary',
        'uk-text-secondary': type === 'secondary',
        'uk-text-success': type === 'success',
        'uk-text-danger': type === 'danger',
    });

    return (
        <button className={buttonClass} data-uk-tooltip={tooltip} onClick={(e) => handleClick(e)}>
            <span data-uk-icon={'icon: ' + icon + '; ratio: 0.8'} />
        </button>
    );
};

export const ToolbarLabel = ({ className = 'uk-background-theme-light flex-shrink-0', text, tooltip }) => {
    return (
        <span className={'px-2 mr-1 uk-text-xsmall ' + className} data-uk-tooltip={tooltip}>
            {text}
        </span>
    );
};

export default EditorToolbar;
