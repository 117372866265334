import { Modal } from 'react-bootstrap';
import { Form as FForm, Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { FormModal, ModalFooter } from '../../global/FormModal';
import { FieldSelect, FormField } from '../../publications_v2/helpers/FieldHelper';
import HelperFunctions from '../../global/HelperFunctions';
import { useAddTranslationContainerMutation } from '../../../features/translations/translationApi';
import { translationContainerTypes } from '../config/Constants';
import { useGetDocuments } from '../../documents_v2/hooks/useGetDocuments';
import { useGetCategories } from '../../reimbursements/hooks/useGetCategories';
import { useTranslation } from 'react-i18next';

export default function NewTranslationModal({ showModal, handleClose }) {
    const activeOrganisation = useSelector((state) => state.security.activeOrganisation);
    const [addTranslationContainer] = useAddTranslationContainerMutation();
    const { t } = useTranslation('translations');

    if (!showModal) {
        return null;
    }

    const handleSubmit = (values) => {
        addTranslationContainer(values).then(() => {
            handleClose();
        });
    };

    return (
        <FormModal show={showModal} onHide={handleClose} title={t('newTranslationModal.title')}>
            <Formik
                initialValues={{
                    name: '',
                    type: '',
                    documentId: null,
                    categoryId: null,
                    organisationId: activeOrganisation,
                }}
                validationSchema={NewTranslationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, isValid, dirty, values }) => (
                    <FForm autoComplete="off">
                        <Modal.Body>
                            <FormField
                                name="name"
                                label={t('newTranslationModal.name')}
                                props={{
                                    placeholder: t('newTranslationModal.titleSelect'),
                                }}
                            />

                            <FieldSelect
                                name="type"
                                label={t('newTranslationModal.type')}
                                options={[
                                    {
                                        label: t('newTranslationModal.types.document'),
                                        value: translationContainerTypes.DOCUMENT,
                                    },
                                    {
                                        label: t('newTranslationModal.types.reimbursement'),
                                        value: translationContainerTypes.REIMBURSEMENT,
                                    },
                                ]}
                            />

                            {values.type === translationContainerTypes.DOCUMENT && <DocumentSelect />}

                            {values.type === translationContainerTypes.REIMBURSEMENT && <ReimbursementSelect />}
                        </Modal.Body>

                        <ModalFooter
                            dirty={dirty}
                            isValid={isValid}
                            isSubmitting={isSubmitting}
                            onHide={handleClose}
                            btnSubmitText={t('btn.save')}
                        />
                    </FForm>
                )}
            </Formik>
        </FormModal>
    );
}

function DocumentSelect() {
    const documents = useGetDocuments();
    const { t } = useTranslation('translations');

    return (
        <FieldSelect
            label="Document"
            name="documentId"
            options={HelperFunctions.prepareDropdownData(documents)}
            props={{
                placeholder: `${t('newTranslationModal.titleSelect1')}...`,
            }}
        />
    );
}

function ReimbursementSelect() {
    const categories = useGetCategories();
    const { t } = useTranslation('translations');

    return (
        <FieldSelect
            label="Vergoeding"
            name="categoryId"
            options={HelperFunctions.prepareDropdownData(categories)}
            props={{
                placeholder: `${t('newTranslationModal.titleSelect1')}...`,
            }}
        />
    );
}

const NewTranslationSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(255, 'Too Long!').required('Required'),
    type: Yup.string().min(2, 'Too Short!').required('Required'),
});
