import { useGetPublicationsQuery } from 'features/publications/publicationApi';
import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import HelperFunctions from 'pages/global/HelperFunctions';

export function useGetPublications() {
    const selectPublications = useMemo(() => {
        const emptyArray = [];

        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (res) => res.data,
            (data) => (data ? [...data].sort(HelperFunctions.sortByString('name')) : emptyArray)
        );
    }, []);

    const { publications } = useGetPublicationsQuery(undefined, {
        selectFromResult: (result) => ({
            publications: selectPublications(result),
        }),
    });

    return publications;
}
