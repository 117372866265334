import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { saveTokenData } from '../../api/AuthApi';
import { handleAuthenticationChange } from '../../features/security/securitySlice';
import { useDispatch } from 'react-redux';
import { useGetSsoTokenQuery } from '../../features/security/authApi';
import { BASE_PATH } from '../../scenes/DocumentsV2';

export default function SSO() {
    const { code } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const tokenResult = useGetSsoTokenQuery(code, {
        skip: !code,
    });

    useEffect(() => {
        if (tokenResult.status === 'rejected') {
            history.push('/');
            return;
        }

        if (!tokenResult.data) {
            return;
        }

        saveTokenData(tokenResult.data.token, tokenResult.data.refreshToken).then(() => {
            dispatch(handleAuthenticationChange());
            history.push(BASE_PATH);
        });
    }, [tokenResult]);

    return <div>Validating token, please wait...</div>;
}
