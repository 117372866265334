import { Form as FForm, Formik } from 'formik';
import * as yup from 'yup';
import {
    useAddCollectiveMutation,
    useAddPropositionMutation, useUpdateCollectiveMutation,
    useUpdatePropositionMutation,
} from '../../../../features/reimbursements/reimbursements';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { COLLECTIVES_EDIT_PATH, PROPOSITION_EDIT_PATH } from '../../../../scenes/Reimbursements';

export default function EditCollectiveForm({ proposition, children }) {
    const [addCollective] = useAddCollectiveMutation();
    const [updateCollective] = useUpdateCollectiveMutation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { categoryId, folderId } = useParams();

    const handleSubmit = ((values, { setSubmitting }) => {
        if (proposition.hasOwnProperty('id')) {
            dispatch(
                updateCollective({
                    uri: proposition['@id'],
                    ...values,
                }).then(() => {
                    setSubmitting(false);
                }),
            );
        } else {
            dispatch(
                addCollective({
                    ...values,
                }).then(({ data }) => {
                    setSubmitting(false);

                    history.push(generatePath(COLLECTIVES_EDIT_PATH, {
                        categoryId,
                        action: 'properties',
                        collectiveId: data.id,
                        folderId,
                    }));
                }),
            );
        }
    });

    return (
        <Formik
            enableReinitialize
            initialValues={proposition}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            {() => {
                return (
                    <FForm autoComplete="off" id="editCollectiveForm">
                        {children}
                    </FForm>
                );
            }}
        </Formik>
    );
}

const validationSchema = yup.object().shape({
    name: yup.string().required(),
});
