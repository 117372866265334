import { FieldSelect } from '../helpers/FieldHelper';
import HelperFunctions from '../../global/HelperFunctions';
import { useTranslation } from 'react-i18next';
import { useGetTranslationContainersQuery } from 'features/translations/translationApi';
import { translationContainerTypes } from '../../translation/config/Constants';
import { getLanguageName } from 'pages/translation/helpers/LanguageHelper';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';

export function XmlTranslation({ categoryId }) {
    const { t } = useTranslation('publications');
    const { values } = useFormikContext();
    const { xmlProperties } = values;

    const { translationContainers } = useGetTranslationContainersQuery(undefined, {
        selectFromResult: ({ data }) => ({
            translationContainers: data
                ? data.filter((container) => container['@type'] === translationContainerTypes.REIMBURSEMENT)
                : [],
        }),
    });

    const { reimbursementTranslation } = xmlProperties;
    const reimbursementContainer = _.isEmpty(reimbursementTranslation)
        ? undefined
        : translationContainers.find((_container) => _container.id === reimbursementTranslation);

    return (
        <div>
            <Form.Group>
                <FieldSelect
                    label={t('publication.settings.content.summary.expand.sectionTranslation.reimbursements')}
                    name={`xmlProperties.reimbursementTranslation`}
                    options={HelperFunctions.prepareDropdownData(
                        translationContainers.filter((_container) => _container.categoryId === categoryId)
                    )}
                    props={{
                        isClearable: true,
                        placeholder: `${t(
                            'publication.settings.content.summary.expand.sectionTranslation.titleSelect'
                        )}...`,
                    }}
                />
            </Form.Group>

            <Form.Group>
                <FieldSelect
                    label={t('publication.settings.content.summary.expand.sectionTranslation.reimbursementLanguage')}
                    name={`xmlProperties.reimbursementTranslationLanguage`}
                    options={HelperFunctions.prepareDropdownData(
                        reimbursementContainer
                            ? reimbursementContainer.translationLanguages.map((_language) => ({
                                  ..._language,
                                  name: getLanguageName(_language.languageIso),
                              }))
                            : []
                    )}
                    props={{
                        isDisabled: reimbursementContainer === undefined,
                        isClearable: true,
                        placeholder:
                            reimbursementContainer === undefined
                                ? ''
                                : `${t(
                                      'publication.settings.content.summary.expand.sectionTranslation.titleSelect'
                                  )}...`,
                    }}
                />
            </Form.Group>
        </div>
    );
}
