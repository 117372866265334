import { useGetProductsQuery } from '../../../../features/reimbursements/reimbursements';
import { VIEW_PATH } from '../../../../scenes/Reimbursements';
import React, { createContext, useState } from 'react';
import {
    Category,
    Product,
    ReimbursementProduct as ReimbursementProductModel,
} from '../../../../models/reimbursement.models';
import { generatePath, useHistory } from 'react-router-dom';
import LoadingSpinner from '../../../global/LoadingSpinner';
import { useGetCategory } from '../../../../features/reimbursements/reimbursementHooks';
import Form from './Form';
import HelperFunctions from '../../../global/HelperFunctions';
import Constants from '../../../../config/Constants';
import RestrictedReimbursementContent from '../../RestrictedReimbursementContent';
import { Download } from 'react-bootstrap-icons';
import { Button } from 'react-bootstrap';
import { LoadProductRowsModal } from '../modals/LoadProductRowsModal';
import { useFormikContext } from 'formik';
import { canAcceptChangesRoles } from '../../config/permissions';
import { useTranslation } from 'react-i18next';

interface HistoryContextInterface {
    startGroupId: number;
    setStartGroupId: (startGroupId: number) => void;
    endGroupId: number;
    setEndGroupId: (endGroupId: number) => void;
}

export const HistoryContext = createContext<HistoryContextInterface>({
    startGroupId: 0,
    endGroupId: 0,
} as HistoryContextInterface);

export default function ReimbursementProduct({
    categoryId,
    parentProductId,
    productId,
    reimbursementId,
    selectedProductId,
}: {
    categoryId: string;
    parentProductId: string;
    productId: string;
    reimbursementId: string;
    selectedProductId: string;
}) {
    const [startGroupId, setStartGroupId] = useState(0);
    const [endGroupId, setEndGroupId] = useState(0);

    const categoryIdAsInt = parseInt(categoryId);
    const productIdAsInt = parseInt(productId);
    const reimbursementIdAsInt = parseInt(reimbursementId);
    const parentProductIdAsInt = parseInt(parentProductId);
    const selectedProductIdAsInt = parseInt(selectedProductId);

    const { category } = useGetCategory(categoryIdAsInt);

    if (!category) {
        return <LoadingSpinner />;
    }

    return (
        <HistoryContext.Provider value={{ startGroupId, setStartGroupId, endGroupId, setEndGroupId }}>
            <ReimbursementProductInner
                category={category}
                reimbursementId={reimbursementIdAsInt}
                productId={productIdAsInt}
                selectedProductId={selectedProductIdAsInt}
                parentProductId={parentProductIdAsInt}
            />
        </HistoryContext.Provider>
    );
}

function ReimbursementProductInner({
    category,
    productId,
    reimbursementId,
    selectedProductId,
    parentProductId,
}: {
    category: Category;
    productId: number;
    reimbursementId: number;
    selectedProductId: number;
    parentProductId: number;
}) {
    const history = useHistory();
    const [showLoadProductRowsModal, setShowLoadProductRowsModal] = useState(false);
    const { t } = useTranslation('reimbursements');

    const {
        product,
        selectedProduct,
        parentProduct,
    }: { product: Product | undefined; selectedProduct: Product | undefined; parentProduct: Product | undefined } =
        useGetProductsQuery(category.id, {
            selectFromResult: (result) => ({
                product: result.data ? result.data.find((product: Product) => product.id === productId) : undefined,
                selectedProduct: result.data
                    ? result.data.find((product: Product) => product.id === selectedProductId)
                    : undefined,
                parentProduct: result.data
                    ? result.data.find((product: Product) => product.id === parentProductId)
                    : undefined,
            }),
        });

    if (!product || !selectedProduct || !parentProduct) {
        return <LoadingSpinner />;
    }

    const handleClose = (dirty = false) => {
        const closeUrl = generatePath(VIEW_PATH, {
            categoryId: category.id,
            reimbursementId,
            productId: parentProductId,
        });

        if (dirty) {
            HelperFunctions.confirmModal(t('product.form.unsavedChanges'), 'danger', false).then(() => {
                history.push(closeUrl);
            });
        } else {
            history.push(closeUrl);
        }
    };

    return (
        <Form
            reimbursementUri={`/api/reimbursement-api/api/reimbursements/${reimbursementId}`}
            productUri={product['@id']}
            selectedProductUri={selectedProduct['@id']}
            categoryUri={category['@id']}
        >
            <div>
                <Form.FormNav categoryName={category.name} handleClose={handleClose}>
                    <RestrictedReimbursementContent
                        categoryId={category.id}
                        roles={[
                            Constants.reimbursementTeamRoles.admin,
                            Constants.reimbursementTeamRoles.manager,
                            Constants.reimbursementTeamRoles.finalEditor,
                            Constants.reimbursementTeamRoles.editor,
                        ]}
                    >
                        <FormNavContent
                            categoryId={category.id}
                            onClick={() => setShowLoadProductRowsModal(true)}
                            reimbursementId={reimbursementId}
                        />
                    </RestrictedReimbursementContent>
                </Form.FormNav>
                <div className="content-static-body" style={{ top: 64, bottom: 59 }}>
                    <div className="d-flex align-items-stretch h-100">
                        <div className="content-sidebar overflow-auto bg-light">
                            {product && (
                                <Form.FormSidebar
                                    product={product}
                                    selectedProductId={selectedProductId}
                                    categoryId={category.id}
                                    reimbursementId={reimbursementId}
                                    parentProductId={parentProductId}
                                />
                            )}
                        </div>
                        <div className="flex-grow-1 position-relative">
                            <div className="d-flex h-100">
                                <div className="flex-grow-1 overflow-auto" style={{ marginTop: 50 }}>
                                    <Form.FormContent
                                        product={product}
                                        parentProduct={parentProduct}
                                        category={category}
                                        reimbursementId={reimbursementId}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Form.FormFooter handleClose={handleClose} />

                {showLoadProductRowsModal && (
                    <LoadProductRowsModal
                        currentProduct={product}
                        handleClose={() => setShowLoadProductRowsModal(false)}
                    />
                )}
            </div>
        </Form>
    );
}

function FormNavContent(props: { categoryId: number; onClick: () => void; reimbursementId: number }) {
    const { categoryId, onClick, reimbursementId } = props;
    const { values } = useFormikContext<ReimbursementProductModel>();
    const { t } = useTranslation('reimbursements');

    return (
        <div className="d-flex">
            <Button
                className="d-flex align-items-center"
                variant="light"
                size="sm"
                onClick={onClick}
                disabled={values.coverage === Constants.coverage['n/a']}
            >
                <Download className="mr-1" />
                {t('product.loading')}
            </Button>

            <RestrictedReimbursementContent categoryId={categoryId} roles={canAcceptChangesRoles}>
                <div className="d-flex align-items-center border-left pl-3 ml-3">
                    <Form.FormCheck reimbursementId={reimbursementId} />
                </div>
            </RestrictedReimbursementContent>
        </div>
    );
}
