import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Parser from "html-react-parser";

export default class InteractivePart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: props.selectedPart.block === props.blockId && props.selectedPart.part === props.partId,
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            selected: nextProps.selectedPart.block === this.props.blockId && nextProps.selectedPart.part === this.props.partId,
        });
    }

    selectPart = () => {
        this.props.interactive && this.props.selectPart();
    }

    render() {
        const {part} = this.props;
        const className = this.props.interactive ? 'part' + (this.state.selected ? ' selected' : '') : null;
        if (part.processed) {
            return (
                <Fragment>
                    {!part.accepted && part.oldText !== undefined && part.oldText !== '' && <Fragment>{Parser(part.oldText)}</Fragment>}
                    {part.accepted && part.newText !== undefined && part.newText !== '' && <Fragment>{Parser(part.newText)}</Fragment>}
                </Fragment>
            );
        } else {
            return (
                <span className={className} onClick={this.props.selectPart}>
                    {part.oldText !== undefined && part.oldText !== '' && <del>{Parser(part.oldText)}</del>}
                    {part.newText !== undefined && part.newText !== '' && <ins>{Parser(part.newText)}</ins>}
                </span>
            );
        }
    }
}

InteractivePart.propTypes = {
    blockId: PropTypes.number,
    interactive: PropTypes.bool,
    partId: PropTypes.number,
    part: PropTypes.object,
    selectPart: PropTypes.func.isRequired,
    selectedPart: PropTypes.object,
};
