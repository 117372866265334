import { PrimaryButton } from 'components/Buttons';
import HelperFunctions from 'pages/global/HelperFunctions';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useResetAdminUserPasswordMutation } from 'features/security/authApi';

export function ResetPasswordButton({ user, accountId, isPasswordReset = false }) {
    const { t: tUsers } = useTranslation('users');
    const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
    const [resetAdminUserPassword] = useResetAdminUserPasswordMutation();

    return (
        <PrimaryButton
            size="sm"
            onClick={confirmPasswordReset}
            disabled={passwordResetSuccess === true || user.enabled === false || isPasswordReset}
            tooltip="Verstuur nieuw wachtwoord via email"
        >
            {tUsers('btn.resetPassword')}
        </PrimaryButton>
    );

    function confirmPasswordReset() {
        HelperFunctions.confirmModal(
            tUsers('editUser.resetPasswordConfirm', {
                name: user.fullName,
            }),
            undefined,
            false,
            tUsers('editUser.resetPasswordConfirmLabel')
        )
            .then(() => {
                resetPassword();
            })
            .catch(() => {});
    }

    function resetPassword() {
        resetAdminUserPassword({ accountId, id: user.id }).then(({ data }) => {
            if (data?.status === 'ok') {
                setPasswordResetSuccess(true);
            }
        });
    }
}
