import HelperFunctions from '../../global/HelperFunctions';
import { Select } from 'components/Select';
import { useGetInsurerQuery, useGetInsurersQuery, useGetInsurerUsersQuery } from 'features/documents/ipidApi';
import { FieldArray, Form as FForm, Formik, useFormikContext } from 'formik';
import { IconButton, WarningButton } from 'components/Buttons';
import { LoadingSpinner } from '../../global/Spinner';
import { useTranslation } from 'react-i18next';
import { FormField, InputCheck, InputField } from '../../publications_v2/helpers/FieldHelper';
import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import cx from 'classnames';
import { PencilSquare, Star, StarFill, TrashFill } from 'react-bootstrap-icons';
import _ from 'lodash';
import { useCreateIpidImportMutation } from 'features/security/authApi';
import { ADMIN_SETTING_ACCOUNT_PATH } from 'scenes/Admin';
import { generatePath, useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import * as Yup from 'yup';

export function Import() {
    const { t } = useTranslation('global');
    const [createIpidImport] = useCreateIpidImportMutation();
    const history = useHistory();

    const handleSubmit = (values, { setSubmitting }) => {
        const body = {
            ...values,
            insurers: values.insurers.map((insurer) => ({
                ...insurer,
                users: insurer.users.filter((user) => user.checked),
            })),
        };

        createIpidImport(body).then(({ data }) => {
            setSubmitting(false);

            history.push(
                generatePath(ADMIN_SETTING_ACCOUNT_PATH, {
                    id: data.id,
                })
            );
        });
    };

    return (
        <div>
            <div className="subheader d-flex align-items-center justify-content-between pb-1 mb-3">
                <h3 className="mb-0">Account importeren</h3>
            </div>

            <Formik
                initialValues={{
                    accountName: '',
                    organisationName: '',
                    website: 'https://publicaties.docrevolution.nl/handleiding/verzekeringskaarten/',
                    insurers: [],
                }}
                onSubmit={handleSubmit}
                enableReinitialize
                validationSchema={importSchema}
            >
                {({ dirty, isSubmitting, isValid }) => (
                    <FForm autoComplete="off">
                        <FormField name="accountName" label="Naam account" props={{ required: true }} />
                        <FormField name="organisationName" label="Naam organisatie" props={{ required: true }} />
                        <FormField name="website" label="Documentation url" />
                        <Insurers />

                        <div className="mb-4 mt-4">
                            <WarningButton type="submit" disabled={isSubmitting || !dirty || !isValid}>
                                {t('btn.save')}
                            </WarningButton>

                            <LoadingSpinner isSubmitting={isSubmitting} />
                        </div>
                    </FForm>
                )}
            </Formik>
        </div>
    );
}

export function Insurers() {
    const { values } = useFormikContext();

    const { insurers } = useGetInsurersQuery(undefined, {
        selectFromResult: ({ data }) => ({
            insurers: data ? [...data].sort(HelperFunctions.sortByString('name')) : [],
        }),
    });

    return (
        <FieldArray name="insurers">
            {({ push, remove }) => (
                <>
                    <div className="mb-4">
                        <div className="font-weight-bold mb-2">Verzekeraars</div>
                        {insurers.length > 0 && <InsurerSelect insurers={insurers} push={push} />}
                    </div>

                    {values.insurers.map((insurer, index) => (
                        <Insurer insurer={insurer} index={index} remove={remove} key={`insurer-row-${index}`} />
                    ))}
                </>
            )}
        </FieldArray>
    );
}

function Insurer({ insurer, index, remove }) {
    const { values, setFieldValue } = useFormikContext();

    const { selectedInsurer } = useGetInsurerQuery(insurer.id, {
        selectFromResult: ({ data }) => ({
            selectedInsurer: data,
        }),
    });

    const { users } = useGetInsurerUsersQuery(insurer.id, {
        selectFromResult: ({ data }) => ({
            users: data,
        }),
    });

    useEffect(() => {
        if (selectedInsurer && users) {
            if (values.accountName === '') {
                setFieldValue('accountName', selectedInsurer.name);
            }

            if (values.organisationName === '') {
                setFieldValue('organisationName', selectedInsurer.name);
            }

            setFieldValue(`insurers.${index}.urlName`, selectedInsurer.urlName);

            if (users.length > 0) {
                setFieldValue(
                    `insurers.${index}.users`,
                    users.map((user) => {
                        const displayName = _.trim(user.displayName);
                        const lastLogin = DateTime.fromISO(user.lastLogin);

                        return {
                            ...user,
                            email: user.email.toLowerCase(),
                            displayName,
                            checked: lastLogin.isValid,
                            userManager: false,
                            ...splitName(displayName),
                        };
                    })
                );
            }
        }
    }, [selectedInsurer, users]);

    return (
        <div className="mb-4">
            <div className="d-flex align-items-center mb-3">
                <div>#{index + 1}:&nbsp;</div>
                <div className="font-weight-bold mr-2">{insurer.name}</div>
                <IconButton icon={<TrashFill className="text-danger" />} onClick={() => remove(index)} />
            </div>

            <FormField name={`insurers.${index}.urlName`} label="URL name" props={{ required: true }} />
            <div className="mb-2">Gebruikers</div>
            <Table size="sm">
                <thead>
                    <tr>
                        <th />
                        <th />
                        <th className="text-muted font-weight-bolder pb-2">Voornaam</th>
                        <th className="text-muted font-weight-bolder pb-2">Achternaam</th>
                        <th className="text-muted font-weight-bolder pb-2">Email</th>
                        <th className="text-muted font-weight-bolder pb-2">Laatste inlogdatum</th>
                    </tr>
                </thead>
                <tbody>
                    {insurer.users.map((user, userIndex) => (
                        <UserImport
                            user={user}
                            index={index}
                            userIndex={userIndex}
                            key={`insurer-${insurer.id}-row-${userIndex}`}
                        />
                    ))}
                </tbody>
            </Table>
        </div>
    );
}

function UserImport({ user, index, userIndex }) {
    const { setFieldValue } = useFormikContext();
    const lastLogin = DateTime.fromISO(user.lastLogin);
    const [editEmail, setEditEmail] = useState(false);

    return (
        <tr className={cx({ 'text-muted': !user.checked })}>
            <td className="align-middle">
                <InputCheck props={{ style: { marginTop: 5 } }} name={`insurers.${index}.users.${userIndex}.checked`} />
            </td>
            <td className="align-middle">
                <IconButton
                    icon={user.userManager ? <StarFill style={{ color: '#f1d613' }} /> : <Star />}
                    className="mr-2 flex-shrink-0"
                    disabled={!user.checked}
                    tooltip="User manager"
                    onClick={() => {
                        setFieldValue(`insurers.${index}.users.${userIndex}.userManager`, !user.userManager);
                    }}
                />
            </td>
            <td className="align-middle">{user.firstName}</td>
            <td className="align-middle">{user.lastName}</td>
            <td className="align-middle">
                {editEmail ? (
                    <InputField
                        name={`insurers.${index}.users.${userIndex}.email`}
                        props={{ onBlur: () => setEditEmail(false), autocomplete: 'off' }}
                    />
                ) : (
                    <div className="d-flex align-items-center">
                        <div className="mr-2">{user.email}</div>
                        <IconButton
                            tooltip="Bewerk email"
                            icon={<PencilSquare className="text-muted" />}
                            onClick={() => setEditEmail(true)}
                        />
                    </div>
                )}
            </td>
            <td className="align-middle">
                {lastLogin.isValid ? lastLogin.toLocaleString(DateTime.DATETIME_MED) : '-'}
            </td>
        </tr>
    );
}

function InsurerSelect({ insurers, push }) {
    const { values } = useFormikContext();

    const handleChange = (selectedValues = []) => {
        selectedValues.forEach((selectedInsurer) => {
            const existingRow = values.insurers.find((insurer) => insurer.id === selectedInsurer.id);

            if (!existingRow) {
                push({
                    ...selectedInsurer,
                    users: [],
                });
            }
        });
    };

    if (insurers.length === 0) {
        return null;
    }

    const options = HelperFunctions.prepareDropdownData(insurers);

    return (
        <Select isClearable isMulti id="insurerId" label="Verzekeraar" options={options} handleChange={handleChange} />
    );
}

const splitName = (name = '') => {
    const [firstName, ...lastName] = name.split(' ').filter(Boolean);
    return {
        firstName: firstName,
        lastName: lastName.join(' '),
    };
};

const importSchema = Yup.object().shape({
    accountName: Yup.string().required(),
    organisationName: Yup.string().required(),
    insurers: Yup.array()
        .of(
            Yup.object().shape({
                urlName: Yup.string().required(),
                id: Yup.number().required(),
            })
        )
        .min(1),
});
