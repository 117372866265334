import { generatePath, Link } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import cx from 'classnames';
import { USER_GROUPS_PATH } from 'scenes/PublicationsV2';
import { useTranslation } from 'react-i18next';

export default function Sidebar({ page }) {
    const { t } = useTranslation('publications');
    const availableSettings = [
        {
            id: 'groups',
            views: ['groups', 'view-group', 'view-publication-group'],
            name: t('accessControl.userGroups'),
        },
        {
            id: 'users',
            views: ['users', 'view-user'],
            name: t('accessControl.users'),
        },
    ];

    return (
        <div className="py-4 bg-lightBlueLight">
            <div className="d-flex align-items-center justify-content-between mb-2 px-4">
                <span className="font-weight-bold text-color">{t('tabs.accessControl')}</span>
            </div>
            <Nav className="sidebar-nav flex-column">
                {availableSettings.map((_setting, index) => (
                    <Link
                        to={generatePath(USER_GROUPS_PATH, {
                            page: _setting.id,
                        })}
                        className={cx('nav-link font-weight-normal', {
                            active: _setting.views.includes(page),
                        })}
                        key={`sidebar-item-${index}`}
                    >
                        {_setting.name}
                    </Link>
                ))}
            </Nav>
        </div>
    );
}
