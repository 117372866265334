import ReactLoadingSkeleton from 'react-loading-skeleton';

export function Skeleton(props) {
    return <ReactLoadingSkeleton {...props} />;
}

export function TableRowSkeleton({ rowCount = 3, cellCount = 3 }) {
    return (
        <>
            {[...Array(rowCount)].map((e, i) => {
                return <tr key={`table-row-skeleton-${i}`}>{renderCells()}</tr>;
            })}
        </>
    );

    function renderCells() {
        let elements = [];

        for (let i = 0; i < cellCount; i++) {
            elements.push(
                <td>
                    <Skeleton />
                </td>,
            );
        }

        return elements;
    }
}
