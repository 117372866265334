import { useParams } from 'react-router-dom';
import { translationApi } from '../../../../../features/translations/translationApi';
import EntityTranslation from '../EntityTranslation';
import { TranslationContext } from '../Translate';
import { useContext, useEffect } from 'react';
import _ from 'lodash';
import { setFilter } from '../../../../../features/translations/translationSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useGetDocument } from '../../../../documents_v2/hooks/useGetDocument';

export default function TranslateSection({ translationContainer, documentVariants, translationLanguage }) {
    const { entityId } = useParams();
    const { documentId } = translationContainer;
    const document = useGetDocument(documentId, true);

    if (document === undefined) {
        return null;
    }

    const { sections = [] } = document;
    const section = sections.find((_section) => _section.id === _.toInteger(entityId));

    if (section === undefined) {
        return null;
    }

    return (
        <SectionContent
            translationContainer={translationContainer}
            documentVariants={documentVariants}
            translationLanguage={translationLanguage}
            section={section}
        />
    );
}

function SectionContent({ section, translationContainer, documentVariants, translationLanguage }) {
    const { entityType, language } = useParams();
    const dispatch = useDispatch();
    const translationContext = useContext(TranslationContext);
    const { id, title } = section;
    const { t } = useTranslation('translations');

    useEffect(() => {
        if (translationContext.title === '') {
            translationContext.setTitle(title);
        }
    }, [translationContext.title]);

    useEffect(() => {
        dispatch(setFilter({ key: 'entity', value: section }));
    }, [dispatch]);

    const handleTranslationMutation = (data) => {
        dispatch(
            translationApi.endpoints.getTranslationContainerEntityReferences.initiate(
                {
                    uri: translationContainer['@id'],
                    entityType,
                },
                { subscribe: false, forceRefetch: true }
            )
        );
    };

    return (
        <div>
            <EntityTranslation
                content={title}
                languageIso={language}
                entityType="section"
                property="title"
                entityUri={`/api/section/${id}`}
                entityId={id}
                translationContainer={translationContainer}
                entity={{
                    title: t('translation.navbar.dashboard.translation.area.title'),
                }}
                handleTranslationMutation={handleTranslationMutation}
            />
        </div>
    );
}
