import { Modal } from 'react-bootstrap';
import { Form as FForm, Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { FormModal, ModalFooter } from '../../../global/FormModal';
import { InputField } from '../../../publications_v2/helpers/FieldHelper';
import { useAddCategoryMutation } from '../../../../features/reimbursements/reimbursements';
import { useTranslation } from 'react-i18next';

export default function NewCategoryModal({ showModal, handleClose }) {
    const [addCategory] = useAddCategoryMutation();
    const activeOrganisation = useSelector((state) => state.security.activeOrganisation);
    const { t } = useTranslation('reimbursements');

    const handleSubmit = (values, { setSubmitting }) => {
        addCategory({ ...values }).then(() => {
            setSubmitting(false);
            handleClose();
        });
    };

    return (
        <FormModal show={!!showModal} onHide={handleClose} title={t('main.newModal.title')}>
            <Formik
                initialValues={{
                    name: '',
                    organisationId: activeOrganisation,
                    fieldsMigrated: true,
                }}
                validationSchema={NewCategorySchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, dirty }) => (
                    <FForm autoComplete="off">
                        <Modal.Body>
                            <InputField
                                name="name"
                                label={t('main.newModal.name')}
                                props={{ placeholder: `${t('main.newModal.titleSelect')}...` }}
                            />
                        </Modal.Body>

                        <ModalFooter dirty={dirty} isSubmitting={isSubmitting} onHide={handleClose} />
                    </FForm>
                )}
            </Formik>
        </FormModal>
    );
}

const NewCategorySchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Required'),
});
