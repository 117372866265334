import React from 'react';
import { Field } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default function EmptyPage({ index }) {
    const { t } = useTranslation('publications');

    return (
        <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4} htmlFor={`contentDecoded.${index}.title`}>
                {t('publication.settings.content.summary.expand.emptyPage.title')}*
            </Form.Label>
            <Col sm={8}>
                <Field
                    id={`contentDecoded.${index}.title`}
                    name={`contentDecoded.${index}.title`}
                    as={Form.Control}
                    type="number"
                    min="1"
                />
            </Col>
        </Form.Group>
    );
}
