import { Modal } from 'react-bootstrap';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { Form as FForm, Formik } from 'formik';
import * as Yup from 'yup';
import { FormModal, ModalFooter } from '../../../global/FormModal';
import { useCreateAdminAccountMutation } from '../../../../features/security/authApi';
import { FormField } from '../../../publications_v2/helpers/FieldHelper';
import { generatePath, useHistory } from 'react-router-dom';
import { ADMIN_SETTING_ACCOUNT_PATH } from '../../../../scenes/Admin';

export function CreateAccountModal() {
    const [createAccount, setCreateAccount] = useQueryParam('createAccount', BooleanParam);

    const hide = () => {
        setCreateAccount(undefined);
    };

    return (
        <FormModal onHide={hide} show={createAccount === true} title="Account aanmaken">
            {createAccount && <ModalContent hide={hide} />}
        </FormModal>
    );
}

function ModalContent({ hide }) {
    const [createAdminAccount] = useCreateAdminAccountMutation();
    const history = useHistory();

    const handleSubmit = (values, { setSubmitting }) => {
        createAdminAccount(values).then(({ data }) => {
            setSubmitting(false);

            history.push(
                generatePath(ADMIN_SETTING_ACCOUNT_PATH, {
                    id: data.id,
                })
            );
        });

        setSubmitting(false);
    };

    return (
        <Formik
            initialValues={{
                name: '',
            }}
            enableReinitialize={true}
            validationSchema={AccountSchema}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, isValid, dirty }) => (
                <FForm autoComplete="off">
                    <Modal.Body>
                        <FormField label="Naam" name="name" props={{ required: true }} />
                    </Modal.Body>

                    <ModalFooter isSubmitting={isSubmitting} isValid={isValid} dirty={dirty} onHide={hide} />
                </FForm>
            )}
        </Formik>
    );
}

const AccountSchema = Yup.object().shape({
    name: Yup.string().required(),
});
