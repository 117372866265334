import { useState } from 'react';
import { CaretDownFill, CaretRightFill, CollectionFill, PlusCircle, ThreeDotsVertical } from 'react-bootstrap-icons';
import { Badge, Dropdown, Nav } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory, useParams, generatePath } from 'react-router-dom';
import HelperFunctions from '../global/HelperFunctions';
import cx from 'classnames';
import EditModal from './EditModal';
import { useDeleteLabelFolderMutation, useGetLabelFoldersQuery } from '../../features/metadata/metadata';
import { IconButton } from '../../components/Buttons';
import { useTranslation } from 'react-i18next';

export default function Sidebar({ editLabel }) {
    const [showModal, setShowModal] = useState(false);
    const { folderId = 'all' } = useParams();
    const history = useHistory();
    const { activeOrganisation } = useSelector((state) => state.security);
    const { t } = useTranslation('labels');

    const { folders = [] } = useGetLabelFoldersQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                folders: data ?? [],
            }),
        }
    );
    const [showContents, setShowContents] = useState(true);
    const toggleContents = () => {
        setShowContents((prevState) => !prevState);
    };
    return (
        <div className="py-4">
            <Nav className="folder-nav flex-column mb-3">
                <Nav.Link
                    active={folderId === 'all'}
                    className="nav-dropdown-hover d-flex align-items-center"
                    onClick={() => history.push(generatePath('/labels'))}
                >
                    <div className="font-weight-bolder">
                        <CollectionFill size={14} className="mr-2" />
                        {t('contentSidebar.all')}
                    </div>

                    <IconButton
                        className="ml-auto"
                        onClick={() => setShowModal(true)}
                        tooltip={`${t('contentSidebar.newFolder')}...`}
                        icon={<PlusCircle size={14} />}
                    />
                </Nav.Link>
            </Nav>

            <div
                className="nav-dropdown-hover d-flex align-items-center mb-1 "
                style={{
                    padding: '0.6rem 1.15rem 0.4rem 1.5rem',
                }}
                onClick={toggleContents}
            >
                <div className="font-weight-bolder cursor-pointer">
                    {t('contentSidebar.folders')}
                    <CaretRightFill
                        size={14}
                        className={cx('ml-2', {
                            'rotate-90': showContents,
                        })}
                    />
                </div>
            </div>
            {showContents && (
                <Nav className="folder-nav flex-column">
                    {folders.map((folder) => (
                        <Folder folder={folder} editLabel={editLabel} key={folder.id} />
                    ))}
                </Nav>
            )}
            <EditModal
                showModal={showModal}
                handleClose={() => setShowModal(false)}
                entity={{
                    '@type': 'LabelFolder',
                }}
            />
        </div>
    );
}

function Folder({ folder, editLabel }) {
    const { folderId = 'all' } = useParams();
    const history = useHistory();
    const { id, name, labels = [] } = folder;

    return (
        <Nav.Link
            as="div"
            className="d-flex align-items-center nav-dropdown-hover"
            active={folderId === id}
            onClick={() => {
                history.push(
                    generatePath('/labels/:folderId', {
                        folderId: id,
                    })
                );
            }}
        >
            <div className="small">{name}</div>

            <div className="d-flex align-items-center ml-auto">
                <GroupActions folder={folder} editLabel={editLabel} />

                <div className="text-center small ml-2" style={{ minWidth: 16 }}>
                    {labels.length}
                </div>
            </div>
        </Nav.Link>
    );
}

const GroupActions = ({ folder, editLabel }) => {
    const { id, name, labels = [] } = folder;
    const canDelete = labels.length === 0;
    const [deleteLabelFolder] = useDeleteLabelFolderMutation();
    const { t } = useTranslation('labels');

    const confirmDelete = () => {
        HelperFunctions.confirmModal(
            `${t('contentSidebar.dropdown.deleteModal.delete', { name: name })}`,
            'danger',
            false,
            t('contentSidebar.dropdown.deleteModal.btn.confirmDelete'),
            t('btn.cancel')
        ).then(() => {
            deleteLabelFolder(folder['@id']);
        });
    };

    return (
        <div className="d-flex align-items-center cursor-pointer mr-1 dropdown">
            <Dropdown>
                <Dropdown.Toggle
                    bsPrefix="docrev-dropdown-toggle"
                    as={ThreeDotsVertical}
                    size={16}
                    id={`folder-${id}-dropdown`}
                />

                <Dropdown.Menu align="right">
                    <Dropdown.Item onClick={() => editLabel(folder)}>
                        {t('contentSidebar.dropdown.edit')}&hellip;
                    </Dropdown.Item>

                    <Dropdown.Divider />
                    <Dropdown.Item disabled={!canDelete} onClick={confirmDelete}>
                        <span
                            className={cx({
                                'text-danger': canDelete,
                                'text-muted': !canDelete,
                            })}
                        >
                            {t('contentSidebar.dropdown.delete')}&hellip;
                        </span>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};
