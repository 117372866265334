import { useUserProfile } from 'hooks/useUserProfile';
import { useAbility } from 'ability/useAbility';
import { useGetTeamUsers } from 'pages/task_team/hooks/useGetTeamUsers';

/**
 * Returns the TeamUser of the current user for the given Team
 */
export function useGetTeamUser(teamId) {
    const teamUsers = useGetTeamUsers(teamId);
    const userProfile = useUserProfile();
    const ability = useAbility();

    const canManageTeams = ability.can('manage', 'TaskTeam.Teams');

    const teamUser = teamUsers.find((teamUser) => teamUser.userId === userProfile.userId);

    if (teamUser) {
        return teamUser;
    }

    if (canManageTeams) {
        // Return dummy TeamUser for permissions check
        return {
            '@type': 'TeamUser',
            permissions: {
                canAssignReviewer: true,
                canManageTeam: true,
                canChangeTaskOwner: true,
                canOverrideTaskStatus: true, // user has the management decision override
            },
        };
    }

    return undefined;
}
