import env from "@beam-australia/react-env"

const getEnv = (key) => {
    return env(key)
}

export function getBaseName() {
    if (window.location.pathname === '/app' || window.location.pathname.startsWith('/app/')) {
        return '/app';
    }

    return undefined;
}

export default getEnv
