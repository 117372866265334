import { RxDragHandleDots2 } from 'react-icons/rx';
import cx from 'classnames';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import Constants from '../../../../config/Constants';
import { DocumentAction } from './DocumentAction';
import { Badge, Container, Row } from 'react-bootstrap';
import { Bank, FileText, FileTextFill, GearWideConnected } from 'react-bootstrap-icons';
import _ from 'lodash';
import HelperFunctions from '../../../global/HelperFunctions';
import { documentTypes } from '../settings/Properties';
import { useTranslation } from 'react-i18next';
import { ActionLinkButton } from 'components/Buttons';
import { VIEW_PATH } from 'scenes/DocumentsV2';

export function DocumentRow({ document }) {
    const { folderId } = useParams();
    const isDragDisabled = folderId === undefined || folderId === Constants.folders.trash;
    const { t } = useTranslation('documents');
    return (
        <div className="d-flex align-items-center p-2">
            <RxDragHandleDots2
                size={22}
                className={cx('icon-grip flex-shrink-0 mr-2', {
                    'text-muted': isDragDisabled,
                })}
            />

            <div className="d-flex align-items-center flex-grow-1 border bg-white py-2 mr-2">
                <Container fluid>
                    <Row className="align-items-center">
                        <div className="col-7 col-lg-5 col-xxl-4">
                            <DocumentName document={document} />
                        </div>
                        <div className="col-2 d-none d-lg-block">
                            <DocumentStatus document={document} />
                        </div>
                        <div className="col-2 d-none d-xl-block col-xxl-1">
                            <DocumentRevision document={document} />
                        </div>
                        <div className="col-3 d-none d-xxl-block">
                            <DocumentRole document={document} />
                        </div>
                        <div className="col d-flex justify-content-end">
                            <DocumentAction document={document} />
                        </div>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

function DocumentName({ document }) {
    const history = useHistory();
    const { t } = useTranslation('documents');

    const documentType = documentTypes.find((_type) => _type.value === document.type);
    const tooltip = t(`documents:${documentType?.label}`) ?? t('documents.newDocument.type.standard');

    const Icon = () => {
        switch (document.type) {
            case Constants.documentType.library:
                return (
                    <Bank
                        size={18}
                        className="flex-shrink-0 text-dark-blue mr-2"
                        style={{ minWidth: 18 }}
                        data-uk-tooltip={tooltip}
                    />
                );
            case Constants.documentType.model:
                return (
                    <FileTextFill
                        size={18}
                        className="flex-shrink-0 text-dark-blue mr-2"
                        style={{ minWidth: 18 }}
                        data-uk-tooltip={tooltip}
                    />
                );
            default:
                return (
                    <FileText
                        size={18}
                        className="flex-shrink-0 text-dark-blue mr-2"
                        style={{ minWidth: 18 }}
                        data-uk-tooltip={tooltip}
                    />
                );
        }
    };

    return (
        <div className="d-flex align-items-center">
            <Icon />

            {document.deletedAt === null ? (
                <ActionLinkButton
                    onClick={() => {
                        history.push(
                            generatePath(VIEW_PATH, {
                                documentId: document.id,
                            })
                        );
                    }}
                    className="font-weight-bolder"
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {document.name}
                </ActionLinkButton>
            ) : (
                <div
                    className="font-weight-bolder"
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {document.name}
                </div>
            )}
        </div>
    );
}

function DocumentStatus({ document }) {
    const isDeleted = _.get(document, 'deletedAt', null) !== null;
    const cloneTaskId = _.get(document, 'cloneTaskId', null);
    const { t } = useTranslation('documents');

    if (isDeleted) {
        return <Badge variant="danger">{t('documents.badges.deleted')}</Badge>;
    }

    if (cloneTaskId !== null) {
        return (
            <Badge variant="warning">
                <GearWideConnected size={16} className="icon-spin mr-1" />
                {t('documents.badges.duplicating')}
            </Badge>
        );
    }

    const getBadgeVariant = () => {
        if (document.status === Constants.documentStatus.reviewInProgress) {
            return 'warning';
        }

        if (document.status === Constants.documentStatus.reviewFinished) {
            return 'success';
        }

        return 'secondary';
    };

    const getStatusTranslation = (status) => {
        switch (status) {
            case Constants.documentStatus.published:
                return t('documents.badges.published');
            case Constants.documentStatus.reviewInProgress:
                return t('documents.badges.reviewInProgress');
            case Constants.documentStatus.reviewFinished:
                return t('documents.badges.reviewFinished');
            case Constants.documentStatus.draft:
                return t('documents.badges.draft');
            case Constants.documentStatus.open:
                return t('documents.badges.open');
            case Constants.documentStatus.finished:
                return t('documents.badges.finished');
            case Constants.documentStatus.deleted:
                return t('documents.badges.deleted');
            default:
                return t('documents.badges.unknownStatus');
        }
    };

    return <Badge variant={getBadgeVariant()}>{getStatusTranslation(document.status)}</Badge>;
}

function DocumentRevision({ document }) {
    const isDeleted = _.get(document, 'deletedAt', null) !== null;
    const { activeRevisionId = null, revisions = [] } = document;
    const activeRevision = activeRevisionId ? HelperFunctions.getByValue(revisions, 'id', activeRevisionId) : undefined;
    const { t } = useTranslation('documents');

    if (isDeleted || !activeRevision) {
        return null;
    }

    return (
        <Badge variant="secondary">
            {activeRevision.revisionNumber} {t('documents.badges.from')} {revisions.length}
        </Badge>
    );
}

function DocumentRole({ document }) {
    const { t } = useTranslation('documents');
    const isDeleted = _.get(document, 'deletedAt', null) !== null;

    if (isDeleted) {
        return null;
    }

    return (
        <div className="font-weight-bolder">
            {t(`document.navbar.main.sidebar.team.roles.${document.currentUserRole}`)}
        </div>
    );
}
