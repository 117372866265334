import { useTranslation } from 'react-i18next';
import { useGetLabelFolders } from 'pages/labels/hooks/useGetLabelFolders';
import { useGetLabels } from 'pages/labels/hooks/useGetLabels';
import { InputMultipleSelect } from 'pages/publications_v2/helpers/FieldHelper';
import HelperFunctions from 'pages/global/HelperFunctions';
import { idToUri } from 'pages/global/UriHelper';
import { useGetLabelEntities } from 'pages/labels/hooks/useGetLabelEntities';
import { useMemo } from 'react';
import { useAddLabelEntitiesMutation, useDeleteLabelEntitiesMutation } from 'features/metadata/metadata';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { entityTypes } from 'pages/translation/config/Constants';
import { Badge } from 'react-bootstrap';

export function TaskLabels({ taskId, readOnly = false }) {
    const labelFolders = useGetLabelFolders(entityTypes.TASK);
    const { entityLabelEntities } = useGetLabelEntities(entityTypes.TASK, taskId);
    const organisationLabels = useGetLabels();

    const entityLabels = useMemo(() => {
        return HelperFunctions.prepareDropdownData(
            entityLabelEntities
                .filter((_item) => organisationLabels.some((label) => label['@id'] === _item.label))
                .map((_item) => ({
                    ...HelperFunctions.getByValue(organisationLabels, '@id', _item.label),
                    labelEntity: _item,
                }))
                .sort(HelperFunctions.dynamicSort('name')),
            'name',
            '@id',
        );
    }, [entityLabelEntities, organisationLabels]);

    if (labelFolders.length === 0) {
        return null;
    }

    return (
        <>
            {labelFolders.map((folder) => (
                <MetaFolder
                    readOnly={readOnly}
                    showName={labelFolders.length > 1}
                    taskId={taskId}
                    folder={folder}
                    entityLabels={entityLabels.filter((_label) => _label.folder === idToUri(folder.id, 'LabelFolder'))}
                    key={`meta-folder-${folder.id}`}
                />
            ))}
        </>
    );
}

function MetaFolder({ folder, entityLabels = [], taskId, showName = false, readOnly = false }) {
    const { id, name } = folder;
    const labels = useGetLabels(folder['@id']);
    const { t } = useTranslation('documents');
    const [addLabelEntities] = useAddLabelEntitiesMutation();
    const [deleteLabelEntities] = useDeleteLabelEntitiesMutation();
    const organisationId = useActiveOrganisation();

    return (
        <>
            {showName && <div className="mb-2">{name}</div>}

            {readOnly ? (
                <ReadOnlyLabels labels={entityLabels} />
            ) : (
                <div className="mb-3">
                    <InputMultipleSelect
                        name={`labels-${id}`}
                        options={HelperFunctions.prepareDropdownData(labels, 'name', '@id')}
                        onChange={(selectedOptions, action) => toggleLabel(selectedOptions, action)}
                        props={{
                            placeholder: `${t('document.navbar.main.settingsSidebar.labels.selectTitle')}...`,
                        }}
                        value={entityLabels}
                    />
                </div>
            )}
        </>
    );

    function toggleLabel(selectedOptions, actionValue) {
        const { action, removedValue, removedValues, option } = actionValue;

        if (action === 'select-option') {
            addLabelEntities([
                {
                    entityType: entityTypes.TASK,
                    entityId: `${taskId}`,
                    label: option['@id'],
                    organisationId,
                },
            ]);
        } else if (action === 'remove-value') {
            deleteLabelEntities([removedValue.labelEntity['@id']]);
        } else if (action === 'clear') {
            deleteLabelEntities(removedValues.map((removedValue) => removedValue.labelEntity['@id']));
        }
    }
}

function ReadOnlyLabels({ labels = [] }) {
    return (
        <div>
            {labels.map((label, index) => (
                <Badge variant="light" className="mr-1 mb-1" key={`label-${index}-${label.id}`}>
                    {label.name}
                </Badge>
            ))}
        </div>
    );
}
