import Constants from '../../config/Constants';
import { useCurrentOrganisation } from 'hooks/useCurrentOrganisation';

export function useOrganisationTags() {
    const currentOrganisation = useCurrentOrganisation();

    if (
        currentOrganisation &&
        currentOrganisation.hasOwnProperty('commentTags') &&
        currentOrganisation.commentTags.length > 0
    ) {
        return currentOrganisation.commentTags;
    }

    // Return default tags
    return Constants.comments.tags;
}
