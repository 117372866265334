import { generatePath, Link, useParams } from 'react-router-dom';
import cx from 'classnames';
import { Nav } from 'react-bootstrap';
import { SETTINGS_PATH } from 'scenes/TaskTeam';
import {
    AwardFill,
    Bullseye,
    ChatTextFill,
    CollectionFill,
    FileEarmarkCheckFill, PeopleFill,
    SuitcaseLgFill, XCircleFill,
} from 'react-bootstrap-icons';

export function SettingsSidebar() {
    const { page = 'departments' } = useParams();

    const availableSettings = [
        {
            id: 'departments',
            pages: ['departments'],
            name: 'Afdelingen',
            icon: <CollectionFill />,
        },
        {
            id: 'customer-journeys',
            pages: ['customer-journeys'],
            name: 'Klantreizen',
            icon: <SuitcaseLgFill />,
        },
        {
            id: 'task-types',
            pages: ['task-types'],
            name: 'Uiting soorten',
            icon: <FileEarmarkCheckFill />,
        },
        {
            id: 'brands',
            pages: ['brands'],
            name: 'Merken',
            icon: <AwardFill />,
        },
        {
            id: 'topics',
            pages: ['topics'],
            name: 'Onderwerpen',
            icon: <ChatTextFill />,
        },
        {
            id: 'target-audiences',
            pages: ['target-audiences'],
            name: 'Doelgroepen',
            icon: <Bullseye />,
        },
        {
            id: 'rejection-reasons',
            pages: ['rejection-reasons'],
            name: 'Reden voor afwijzing',
            icon: <XCircleFill />,
        },
        {
            id: 'users',
            pages: ['users'],
            name: 'Gebruikers',
            icon: <PeopleFill />,
        },
    ];

    return (
        <div className="py-4 bg-lightBlueLight">
            <div className="d-flex align-items-center justify-content-between mb-2 px-4">
                <div className="font-weight-bold">Instellingen</div>
            </div>

            <Nav className="sidebar-nav flex-column">
                {availableSettings.map((_setting, index) => (
                    <Link
                        to={generatePath(SETTINGS_PATH, {
                            page: _setting.id,
                        })}
                        className={cx('nav-link font-weight-normal', {
                            active: _setting.pages.includes(page),
                        })}
                        key={`sidebar-item-${index}`}
                        style={{ fontSize: '12px' }}
                    >
                        {_setting.icon}
                        {_setting.name}
                    </Link>
                ))}
            </Nav>
        </div>
    );
}
