import { Route } from 'react-router-dom';
import Index from '../pages/translation/views/Index';
import { ReactTitle } from '../components/ReactTitle';
import View from '../pages/translation/views/view/View';
import Edit from '../pages/translation/views/view/Edit';
import Translate from '../pages/translation/views/translate/Translate';

export const BASE_PATH = '/translations';
export const VIEW_PATH = `${BASE_PATH}/view/:translationContainerId/:view?/:detail?`;
export const EDIT_PATH = `${BASE_PATH}/edit/:translationContainerId/:translationLanguageId/:view?`;
export const TRANSLATE_PATH = `${BASE_PATH}/translate/:language/:translationContainerId/:entityType/:entityId/:translationKeyId?`;
import { useTranslation } from 'react-i18next';

const Translations = () => {
    const { t } = useTranslation('translations');

    return (
        <>
            <ReactTitle title={t('breadcrumb')} />

            <Route path={BASE_PATH} exact strict render={() => <Index />} />
            <Route path={VIEW_PATH} exact strict render={() => <View />} />
            <Route path={EDIT_PATH} exact strict render={() => <Edit />} />
            <Route path={TRANSLATE_PATH} exact strict render={() => <Translate />} />
        </>
    );
};

export default Translations;
