import { taskTeamAssignmentStatus } from 'pages/task_team/config/constants';
import { useGetTaskVersion } from 'pages/task_team/hooks/useGetTaskVersion';
import { useGetUserFullName } from 'hooks/useGetUserFullName';
import { getStatusCode } from 'pages/task_team/views/checks/Reviews';
import { useGetTeams } from 'pages/task_team/hooks/useGetTeams';

export function TaskProgress({ taskVersionUri }) {
    const version = useGetTaskVersion(taskVersionUri);
    const teams = useGetTeams();

    if (!version) {
        return null;
    }

    const { taskTeamAssignments = [] } = version;

    return (
        <ul className="avatar-group tasks-avatar-group">
            {taskTeamAssignments.map((taskTeamAssignment, index) => (
                <Avatar
                    taskTeamAssignment={taskTeamAssignment}
                    team={teams.find((team) => team['@id'] === taskTeamAssignment.team)}
                    zIndex={taskTeamAssignments.length - index}
                    key={`assignment-${taskTeamAssignment.id}-avatar-${index}`}
                />
            ))}
        </ul>
    );
}

function Avatar({ taskTeamAssignment, zIndex, team }) {
    const { assignedTo, status } = taskTeamAssignment;
    const fullName = useGetUserFullName(assignedTo, false);

    const url = getTeamAvatarUrl(team?.name, getColor(), getBackgroundColor());

    return (
        <li>
            <div>
                <span style={{ zIndex }}>
                    <span
                        data-uk-tooltip={getTooltip()}
                        role="img"
                        style={{
                            backgroundImage: `url(${url})`,
                        }}
                    />
                </span>
            </div>
        </li>
    );

    function getColor() {
        switch (status) {
            case taskTeamAssignmentStatus.STATUS_APPROVED:
                return '1e7e34'; // green
            case taskTeamAssignmentStatus.STATUS_REVIEW_IN_PROGRESS:
                return 'f57b00'; // orange
            case taskTeamAssignmentStatus.STATUS_REJECTED:
                return 'dc3545'; // red
            case taskTeamAssignmentStatus.STATUS_NO_REVIEW_NEEDED:
            default:
                return '255478'; // grey
        }
    }

    function getBackgroundColor() {
        switch (status) {
            case taskTeamAssignmentStatus.STATUS_APPROVED:
                return 'dff2e3'; // green
            case taskTeamAssignmentStatus.STATUS_REVIEW_IN_PROGRESS:
                return 'ffefdf'; // orange
            case taskTeamAssignmentStatus.STATUS_REJECTED:
                return 'fae1e3'; // red
            case taskTeamAssignmentStatus.STATUS_NO_REVIEW_NEEDED:
            default:
                return 'faf9fc'; // grey
        }
    }

    function getTooltip() {
        const teamName = team ? team.name : 'Onbekend team';

        if (status === taskTeamAssignmentStatus.STATUS_UNASSIGNED) {
            return `${teamName} - Niet toegewezen`;
        }

        const name = assignedTo ? fullName : 'Niet toegewezen';
        const statusCode = getStatusCode(status);

        return `${teamName} - ${name} - ${statusCode}`;
    }
}

export const getTeamAvatarUrl = (name = '?', color = '255478', backgroundColor = 'faf9fc') => {
    return new URL(
        `https://ui-avatars.com/api/?name=${name}&rounded=true&color=${color}&background=${backgroundColor}&bold=true&size=60&font-size=0.33`,
    ).toString();
};
