import React, { useEffect, useState } from 'react';
import { Badge, Dropdown, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import {
    fetchDepartment,
    fetchOperationsLists,
    fetchOpListDepartments,
    fetchOpListTeamsForDepartment,
    fetchOpListUsers,
    removeOpListUser,
    selectDepartmentUsers,
    selectOpListById,
    selectTeamsForDepartment,
} from '../../../features/operationsList/operationsListSlice';
import { useDispatch, useSelector } from 'react-redux';
import EditOpListUserModal from './EditOpListUserModal';
import HelperFunctions from '../../global/HelperFunctions';
import classNames from 'classnames';
import { CheckCircleFill, DashCircleFill, ThreeDotsVertical, XCircleFill } from 'react-bootstrap-icons';
import { selectDepartmentUserIsManagerOf } from '../../../features/operationsList/opListUserSlice';
import LoadingSpinner from '../../global/LoadingSpinner';
import _ from 'lodash';
import { useGetLabelFoldersQuery, useGetLabelsQuery } from '../../../features/metadata/metadata';
import { useTranslation } from 'react-i18next';
import { useGetOrganisationUsers } from 'hooks/useGetOrganisationUsers';

export default function OpListUsers({ showEditModal, setShowEditModal }) {
    const { id } = useParams();
    const dispatch = useDispatch();
    const opList = useSelector((state) => selectOpListById(state.operations, id));

    useEffect(() => {
        if (!opList) {
            dispatch(fetchOperationsLists());
        }
    }, [dispatch, opList]);

    if (!opList) {
        return null;
    }

    return <DepartmentListUsers opList={opList} showEditModal={showEditModal} setShowEditModal={setShowEditModal} />;
}

function DepartmentListUsers({ showEditModal, setShowEditModal, opList }) {
    const department = useSelector(selectDepartmentUserIsManagerOf(opList));
    const teams = useSelector(selectTeamsForDepartment(department));
    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        if (loading) {
            const options = { opListId: opList.id };

            Promise.all([dispatch(fetchOpListDepartments(options)), dispatch(fetchOpListUsers(options))]);
        }
    }, [dispatch, opList]);

    useEffect(() => {
        if (loading && department) {
            dispatch(fetchOpListTeamsForDepartment({ opDepartmentId: department.id })).then(() => setLoading(false));
        }
    }, [dispatch, department]);

    if (loading) {
        return <LoadingSpinner />;
    }

    if (!department) {
        return 'Geen toegang';
    }

    return (
        <>
            <Department
                opList={opList}
                department={department}
                teamsForDepartment={teams}
                setShowEditModal={setShowEditModal}
            />

            {showEditModal !== false && (
                <EditOpListUserModal
                    handleClose={(data) => {
                        setShowEditModal(false);

                        // reload data
                        if (data && data.hasOwnProperty('payload')) {
                            const opListUser = Object.values(data.payload.opListUsers)[0];
                            dispatch(fetchDepartment({ uri: opListUser.opDepartment }));
                        }
                    }}
                    showModal={true}
                    opList={opList}
                    opListUser={showEditModal}
                    opDepartment={department}
                    opTeams={teams}
                />
            )}
        </>
    );
}

function Department({ department, teamsForDepartment, setShowEditModal }) {
    const organisationUsers = useGetOrganisationUsers();
    const opListUsers = useSelector(selectDepartmentUsers(department, organisationUsers));
    const { t } = useTranslation('changelist');

    return (
        <>
            <h4 className="font-weight-normal text-primary mb-0 pt-3" style={{ fontSize: '1rem' }}>
                {department.name}
            </h4>

            {opListUsers.length > 0 && (
                <Table hover className="mb-4">
                    <thead>
                        <tr>
                            <th className="border-top-0" width="25%">
                                {t('changelist.task.opListUsers.name')}
                            </th>
                            <th className="border-top-0" width="25%">
                                {t('changelist.task.opListUsers.teams')}
                            </th>
                            <th className="border-top-0" width="30%">
                                {t('changelist.task.opListUsers.insuranceTypes')}
                            </th>
                            <th className="text-center border-top-0" width="10%">
                                {t('changelist.task.opListUsers.autoAssignment')}
                            </th>
                            <th className="border-top-0" width="10%" />
                        </tr>
                    </thead>
                    <tbody>
                        {opListUsers.map((_opListUser) => {
                            return (
                                <OpListUserRow
                                    opListUser={_opListUser}
                                    department={department}
                                    teamsForDepartment={teamsForDepartment}
                                    setShowEditModal={setShowEditModal}
                                    key={_opListUser.id}
                                />
                            );
                        })}
                    </tbody>
                </Table>
            )}

            {opListUsers.length === 0 && (
                <p className="pt-3 text-muted">{t('changelist.task.opListUsers.noMembers')}</p>
            )}
        </>
    );
}

function OpListUserRow({ setShowEditModal, opListUser, department, teamsForDepartment }) {
    const [isHover, setHover] = useState(false);
    const { autoAssignLabels, name, opTeams = [], labels = [] } = opListUser;
    const { t } = useTranslation('changelist');

    if (!opListUser) {
        return null;
    }

    return (
        <tr
            onClick={() => setShowEditModal(opListUser)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            key={opListUser.id}
        >
            <td>{name}</td>
            <td>
                <TeamsAsBadges teamUris={opTeams} teamsForDepartment={teamsForDepartment} />
            </td>

            {/* If the department has the splitPerLabel option disabled than the value of these fields are irrelevant */}
            {!department.splitPerLabel ? (
                <>
                    <td className="pl-4">
                        <DashCircleFill
                            className="text-muted ml-3"
                            uk-tooltip={t('changelist.task.opListUsers.tooltip.fieldNotApplicable')}
                        />
                    </td>
                    <td className="text-center">
                        <DashCircleFill
                            className="text-muted"
                            uk-tooltip={t('changelist.task.opListUsers.tooltip.fieldNotApplicable')}
                        />
                    </td>
                </>
            ) : (
                <>
                    <td>
                        <LabelsAsBadges labelUris={labels} />
                    </td>
                    <td className="text-center">
                        {autoAssignLabels ? (
                            <CheckCircleFill className="text-success" />
                        ) : (
                            <XCircleFill className="text-danger" />
                        )}
                    </td>
                </>
            )}

            <td>
                <Actions edit={setShowEditModal} opListUser={opListUser} isHover={isHover} />
            </td>
        </tr>
    );
}

function TeamsAsBadges({ teamUris, teamsForDepartment }) {
    const teams = _.chain(teamUris)
        .map((_uri) => _.find(teamsForDepartment, ['@id', _uri]))
        .filter(_.identity)
        .value();

    return teams.map((_team) => {
        return (
            <Badge variant="secondary" className="mr-1" key={_team.id}>
                {_team.name}
            </Badge>
        );
    });
}

export function LabelsAsBadges({ labelUris, folders = false }) {
    const { activeOrganisation } = useSelector((state) => state.security);

    const { allFolders = [] } = useGetLabelFoldersQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                allFolders: data ?? [],
            }),
        }
    );

    const { allLabels = [] } = useGetLabelsQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                allLabels: data ?? [],
            }),
        }
    );

    const labels = labelUris.map((_uri) => {
        return folders
            ? allFolders.find((_label) => _label['@id'] === _uri)
            : allLabels.find((_label) => _label['@id'] === _uri);
    });

    return labels
        .filter((_label) => _label)
        .map((_label) => {
            return (
                <Badge variant="secondary" className="mr-1" key={_label.id}>
                    {_label.name}
                </Badge>
            );
        });
}

function Actions({ opListUser, isHover, edit }) {
    const dispatch = useDispatch();
    const { t } = useTranslation('changelist');

    const confirmDelete = () => {
        HelperFunctions.confirmModal(
            t('changelist.task.opListUsers.removeMember'),
            'danger',
            false
        ).then(async () => {
            await dispatch(removeOpListUser(opListUser));
            dispatch(fetchDepartment({ uri: opListUser.opDepartment }));
        });
    };

    return (
        <div
            className={classNames('d-flex flex-column align-items-end', { invisible: !isHover })}
            onClick={(e) => e.stopPropagation()}
        >
            <Dropdown>
                <Dropdown.Toggle bsPrefix="docrev-dropdown-toggle" as="span" id="dropdown-basic">
                    <ThreeDotsVertical />
                </Dropdown.Toggle>

                <Dropdown.Menu alignRight align="right">
                    <Dropdown.Item onClick={() => edit(opListUser)}>
                        Bewerken
                    </Dropdown.Item>
                    <Dropdown.Item onClick={confirmDelete}>
                        <span className="text-danger">Verwijderen&hellip;</span>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}
