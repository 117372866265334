import { useGetReimbursementsQuery } from '../../../../../features/translations/translationApi';
import { ReimbursementFilterSelect } from '../../../forms/FieldHelper';

export default function ReimbursementSidebar({ reimbursementContainer }) {
    const { categoryId } = reimbursementContainer;
    const { reimbursements = [] } = useGetReimbursementsQuery(categoryId, {
        selectFromResult: ({ data }) => ({
            reimbursements: data,
        }),
    });

    if (reimbursements.length === 0) {
        return null;
    }

    const options = reimbursements
        .filter((reimbursement) => reimbursement.enabled)
        .map((reimbursement) => {
            return {
                ...reimbursement,
                label: (
                    <>
                        <span className="uk-text-muted mr-2">{reimbursement.code}</span>
                        <span>{reimbursement.name}</span>
                    </>
                ),
                value: reimbursement['@id'],
                prefix: reimbursement.code,
            };
        });

    return <ReimbursementFilterSelect options={options} />;
}
