import { useMemo } from 'react';
import { Form } from 'react-bootstrap';
import {
    FormFieldWithTitle,
    InputCheck,
    InputField,
    InputSelectWithTitle,
    Switch,
    SwitchWithTitle,
} from 'pages/publications_v2/helpers/FieldHelper';
import { useSidebarContext } from '../hooks/useSidebarContext';
import { useGetBaseVariant } from 'pages/documents_v2/hooks/useGetBaseVariant';
import { useFormikContext } from 'formik';
import HelperFunctions from '../../../pages/global/HelperFunctions';
import { entityTypes } from 'pages/translation/config/Constants';
import { useEntityTemplateParameters } from 'pages/documents_v2/hooks/useEntityTemplateParameters';
import { useGetDocument } from 'pages/documents_v2/hooks/useGetDocument';
import { Select } from '../../Select';
import { useTranslation } from 'react-i18next';
import Constants from '../../../config/Constants';
import { EditMetadata } from 'components/SettingsSidebar/views/EditMetadata';

export function EditChapter() {
    const { entity, entityType, documentId } = useSidebarContext();
    const document = useGetDocument(documentId);
    const restrictions = useEntityTemplateParameters(entity, entityType, document);
    const { t } = useTranslation('documents');

    if (entityType === entityTypes.SECTION) {
        return (
            <div className="form-container dr-side-container">
                <div className="form">
                    {restrictions?.canChangeTitle && (
                        <>
                            <div className="formfield">
                                {restrictions?.canModifyTitle ? (
                                    <FormFieldWithTitle
                                        label={t('document.navbar.main.settingsSidebar.chapter.edit.title')}
                                        name="settings.title"
                                    />
                                ) : (
                                    <FormFieldWithTitle
                                        label={t('document.navbar.main.settingsSidebar.chapter.edit.title')}
                                        name="settings.title"
                                        defaultValue={entity.title}
                                        disabled
                                    />
                                )}
                            </div>
                            <SwitchWithTitle
                                label={t('document.navbar.main.settingsSidebar.chapter.edit.autoNumbering')}
                                name="settings.exportProperties.useAutoNumbering"
                                formWidth="5"
                                labelWidth="6"
                                title={t('document.navbar.main.settingsSidebar.chapter.edit.viewInPublication')}
                                disabled={!restrictions?.canModifyTitle}
                                defaultValue={entity.exportProperties.useAutoNumbering}
                            />
                        </>
                    )}
                </div>
            </div>
        );
    }

    if (entityType === entityTypes.AREA) {
        return <EditArea document={document} restrictions={restrictions} />;
    }

    return null;
}

function EditArea({ document, restrictions }) {
    const { values } = useFormikContext();
    const { t } = useTranslation('documents');
    const isLinkedArea = values.settings.type === Constants.areaTypes.linkedArea;
    const useParentAreaTitle = values.settings.exportProperties.useParentAreaTitle ?? true;

    const canEditArticleTemplate = restrictions?.canEditArticleTemplate ?? true;

    const isTemplate = values.settings.properties.isTemplate === true;
    const titleIsReadOnly =
        (isLinkedArea && useParentAreaTitle === true) || (isTemplate && canEditArticleTemplate === false);
    const showEditSettings = !isTemplate || canEditArticleTemplate === true;

    return (
        <div className="form-container dr-side-container">
            <div className="form">
                {restrictions?.canModifyTitle && (
                    <div className="mb-3">
                        <Form.Group className="mb-2 label-title">
                            <div className="mb-2 text-color font-size-sm font-weight-bolder">
                                {t('document.navbar.main.settingsSidebar.chapter.edit.title')}
                            </div>

                            <div className="d-flex">
                                {isLinkedArea && (
                                    <div className="form-check flex-shrink-0 mr-2" key={`useParentAreaTitle`}>
                                        <InputCheck
                                            name="settings.exportProperties.useParentAreaTitle"
                                            props={{
                                                id: `useParentAreaTitle`,
                                                className: 'form-check-input',
                                            }}
                                        />
                                        <label className="form-check-label" htmlFor={`useParentAreaTitle`}>
                                            {t(
                                                'document.navbar.main.settingsSidebar.articles.edit.useTitleFromLibrary'
                                            )}
                                        </label>
                                    </div>
                                )}

                                <InputField name="settings.title" props={{ readOnly: titleIsReadOnly }} />
                            </div>
                        </Form.Group>
                    </div>
                )}

                {showEditSettings && (
                    <>
                        {restrictions?.canModifyHeadingLevel && (
                            <div className="mb-3">
                                <InputSelectWithTitle
                                    name="settings.exportProperties.headingLevel"
                                    options={[
                                        {
                                            label: 'H2',
                                            value: '2',
                                        },
                                        {
                                            label: 'H3',
                                            value: '3',
                                        },
                                        {
                                            label: 'H4',
                                            value: '4',
                                        },
                                        {
                                            label: 'H5',
                                            value: '5',
                                        },
                                    ]}
                                    title={t('document.navbar.main.settingsSidebar.articles.edit.heading')}
                                />
                            </div>
                        )}

                        {restrictions?.canModifyTitlePrefix && (
                            <TitlePrefix document={document} restrictions={restrictions} />
                        )}

                        {restrictions?.canSeeTitleInDocumentModification && (
                            <>
                                <SwitchWithTitle
                                    name="settings.exportProperties.showTitleInDocument"
                                    label={t('document.navbar.main.settingsSidebar.articles.edit.showTitle')}
                                    title={t('document.navbar.main.settingsSidebar.articles.edit.pubDisplayTitle')}
                                    formWidth="5"
                                    labelWidth="6"
                                />

                                <Switch
                                    name="settings.exportProperties.showTitleInIndex"
                                    label={t('document.navbar.main.settingsSidebar.articles.edit.showInTable')}
                                    formWidth="5"
                                    labelWidth="6"
                                    wrapperClassName="mb-2 mt-n1"
                                />
                            </>
                        )}

                        {restrictions?.canChangeAutomaticItemNumbering && (
                            <Switch
                                name="settings.exportProperties.useAutoNumbering"
                                label={t('document.navbar.main.settingsSidebar.articles.edit.autoNumbering')}
                                formWidth="5"
                                labelWidth="6"
                                wrapperClassName="mb-2 mt-n1"
                            />
                        )}

                        {restrictions?.canForceArticleModificationOnNewPage && (
                            <SwitchWithTitle
                                name="settings.exportProperties.forceNewPage"
                                label={t('document.navbar.main.settingsSidebar.articles.edit.forcePage')}
                                formWidth="5"
                                labelWidth="6"
                                title="Pdf"
                            />
                        )}

                        {restrictions?.canChangeCustomPosition && (
                            <>
                                <SwitchWithTitle
                                    name="settings.exportProperties.showAsCollapsible"
                                    label={t('document.navbar.main.settingsSidebar.articles.edit.collapsible')}
                                    formWidth="5"
                                    labelWidth="6"
                                    title="HTML"
                                />
                                <Switch
                                    name="settings.exportProperties.defaultCollapsed"
                                    label={t('document.navbar.main.settingsSidebar.articles.edit.collapsibleByDefault')}
                                    formWidth="5"
                                    labelWidth="6"
                                    wrapperClassName="mb-2 mt-n1"
                                />
                            </>
                        )}

                        {restrictions?.canChangeArticleTemplate && (
                            <SwitchWithTitle
                                name="settings.properties.isTemplate"
                                label={t('document.navbar.main.settingsSidebar.articles.edit.articleTemplate')}
                                formWidth="5"
                                labelWidth="6"
                                title="Template"
                            />
                        )}

                        <div className="pt-3">
                            <EditMetadata />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

function TitlePrefix({ document, restrictions }) {
    const { t } = useTranslation('documents');
    const { documentId } = useSidebarContext();
    const baseVariant = useGetBaseVariant(documentId);
    const { values, setFieldValue } = useFormikContext();
    const titlePrefixes = values.settings?.properties?.titlePrefixes ?? [];
    const prefixValue = useMemo(() => {
        if (baseVariant && titlePrefixes.length > 0) {
            const titlePrefix = HelperFunctions.getByValue(titlePrefixes, 'variantId', baseVariant.id);

            if (titlePrefix !== undefined && titlePrefix.hasOwnProperty('text')) {
                return titlePrefix.text;
            }
        }

        return '';
    }, [titlePrefixes, baseVariant]);

    const handleChange = (text) => {
        const newTitlePrefixes = HelperFunctions.upsert(
            titlePrefixes,
            {
                variantId: baseVariant.id,
                text,
            },
            'variantId'
        );

        setFieldValue('settings.properties.titlePrefixes', newTitlePrefixes);
    };

    if (document.modelId && !_.isEmpty(restrictions?.prefixOptions)) {
        return (
            <div className="mb-4">
                <label className="mb-2 font-weight-bolder label-title">
                    {t('document.navbar.main.settingsSidebar.articles.edit.prefix')}
                </label>

                <TitlePrefixWithOptions
                    prefixValue={prefixValue}
                    prefixOptions={restrictions.prefixOptions}
                    handleChange={handleChange}
                />
            </div>
        );
    }

    return (
        <div className="mb-4">
            <label className="mb-2 font-weight-bolder label-title">
                {t('document.navbar.main.settingsSidebar.articles.edit.prefix')}
            </label>
            <Form.Control value={prefixValue} name="titlePrefix" onChange={(e) => handleChange(e.target.value)} />
        </div>
    );
}

export function TitlePrefixWithOptions({ prefixValue, prefixOptions, handleChange }) {
    const options = HelperFunctions.csvToArray(prefixOptions);
    const formattedOptions = options[0].map((option) => ({ value: option, label: option }));

    return (
        <Select
            isMulti={false}
            isClearable={true}
            id="titlePrefixes"
            options={formattedOptions}
            defaultValue={
                prefixValue !== '' ? formattedOptions.find((option) => option.value === prefixValue) : undefined
            }
            handleChange={(selectedOption) => {
                handleChange(selectedOption?.value ?? '');
            }}
        />
    );
}
