import { Alert, Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import AuthCode from 'react-auth-code-input';
import React, { useEffect, useRef, useState } from 'react';
import { resetTokenData, saveTokenData } from 'api/AuthApi';
import { handleAuthenticationChange, resetState } from 'features/security/securitySlice';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../../language/LanguageSwitcher';
import {
    useRecoverTwoFactorMutation,
    useTwoFactorLoginMutation,
    useVerifyRecoveryTwoFactorMutation,
} from 'features/security/authApi';
import { useUserProfile } from 'hooks/useUserProfile';
import { Logo } from 'pages/global/Logo';

export default function TwoFactorLogin() {
    const { t } = useTranslation('global');
    const [twoFactorLogin] = useTwoFactorLoginMutation();
    const [verifyRecoveryTwoFactor] = useVerifyRecoveryTwoFactorMutation();
    const [recoverTwoFactor] = useRecoverTwoFactorMutation();
    const dispatch = useDispatch();
    const AuthInputRef = useRef(null);

    const [result, setResult] = useState('');
    const [isSubmitting, setSubmitting] = useState(false);
    const [errorCode, setErrorCode] = useState();

    const userProfile = useUserProfile();
    const { googleAuthQRCodeImageUrl = undefined, roles = [] } = userProfile;
    const recoveryMode = roles.includes('ROLE_FIRST_FACTOR_RECOVERY_MODE');

    const handleSubmit = () => {
        twoFactorLogin({ code: result })
            .then((response) => {
                if (response.hasOwnProperty('error')) {
                    if (response.error.status === 401) {
                        setErrorCode('invalidTwoFactorLogin');
                    } else {
                        setErrorCode(response.error.data.status);
                    }
                } else {
                    const { token, refreshToken } = response.data;

                    // Save in application
                    saveTokenData(token, refreshToken)
                        .then(() => {
                            dispatch(handleAuthenticationChange());
                        })
                        .catch(() => {
                            resetTokenData();
                            dispatch(resetState());
                        });
                }
            })
            .finally(() => {
                setSubmitting(false);
                AuthInputRef.current?.clear();
                AuthInputRef.current?.focus();
            });
    };

    const requestRecoveryCode = () => {
        setSubmitting(true);
        setErrorCode(undefined);

        recoverTwoFactor()
            .then((response) => {
                if (response.hasOwnProperty('error')) {
                    if (response.error.status === 401) {
                        setErrorCode('invalidCredentials');
                    } else {
                        setErrorCode(response.error.data.status);
                    }
                } else {
                    const { token } = response.data;

                    // Save in application
                    saveTokenData(token).then(() => {
                        dispatch(handleAuthenticationChange());
                    });
                }
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const verifyRecoveryCode = (code) => {
        setSubmitting(true);
        setErrorCode(undefined);

        verifyRecoveryTwoFactor(code)
            .then((response) => {
                if (response.hasOwnProperty('error')) {
                    if (response.error.status === 401) {
                        setErrorCode('invalidCredentials');
                    } else {
                        setErrorCode(response.error.data.status);
                    }
                } else {
                    const { token } = response.data;

                    // Save in application
                    saveTokenData(token).then(() => {
                        dispatch(handleAuthenticationChange());
                    });
                }
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    useEffect(() => {
        if (result.length === 6 && isSubmitting === false) {
            setSubmitting(true);
            handleSubmit();
        }
    }, [result]);

    return (
        <div>
            <div id="login-bg-image" />
            <Container className="page-login pt-5 pb-4">
                <Row className="justify-content-center">
                    <Col sm={10} md={9} lg={8} xl={6}>
                        <Card className="dr-container mt-3">
                            <Card.Body>
                                <div className="p-4">
                                    <div className="text-center mb-4">
                                        <Logo />
                                        <div className="text-color" style={{ fontSize: '1.15rem' }}>
                                            {t('authentication.twoFactorLogin.recovery.login')}
                                        </div>
                                    </div>
                                </div>

                                <div className="text-center">
                                    {!recoveryMode && (
                                        <div className="two-fa-code mb-5">
                                            <AuthCode
                                                onChange={(res) => setResult(res)}
                                                disabled={isSubmitting}
                                                ref={AuthInputRef}
                                            />
                                        </div>
                                    )}

                                    {!isSubmitting && errorCode !== undefined && (
                                        <div className="px-4">
                                            <Alert variant="danger" className="small mb-4">
                                                {errorCode === 'invalidTwoFactorLogin' && (
                                                    <>{t('authentication.twoFactorLogin.invalidTwoFactorLogin')}</>
                                                )}

                                                {errorCode === 'applicationUnavailable' && (
                                                    <>{t('authentication.twoFactorLogin.applicationUnavailable')}</>
                                                )}

                                                {errorCode === 'twoFactorRecoveryLastRequestedBeforeThreshold' && (
                                                    <>
                                                        {t(
                                                            'authentication.twoFactorLogin.twoFactorRecoveryLastRequestedBeforeThreshold'
                                                        )}
                                                    </>
                                                )}

                                                {errorCode === 'twoFactorRecoveryTokenInvalid' && (
                                                    <>
                                                        {t(
                                                            'authentication.twoFactorLogin.twoFactorRecoveryTokenInvalid'
                                                        )}
                                                    </>
                                                )}

                                                {errorCode === 'invalidCredentials' && (
                                                    <>{t('authentication.twoFactorLogin.invalidCredentials')}</>
                                                )}

                                                {errorCode === 'tooManyLoginAttempts' && (
                                                    <>{t('authentication.twoFactorLogin.tooManyLoginAttempts')}</>
                                                )}
                                            </Alert>
                                        </div>
                                    )}
                                    {!recoveryMode && (
                                        <div className="px-3">
                                            {!_.isEmpty(googleAuthQRCodeImageUrl) && (
                                                <div className="text-secondary small px-4">
                                                    <p>{t('authentication.twoFactorLogin.recovery.twoFactorUsage')}</p>
                                                    <p>
                                                        {t(
                                                            'authentication.twoFactorLogin.recovery.installAuthenticator'
                                                        )}
                                                    </p>
                                                    <img
                                                        src={googleAuthQRCodeImageUrl}
                                                        alt="Google Authenticator QrCode"
                                                    />
                                                </div>
                                            )}

                                            {_.isEmpty(googleAuthQRCodeImageUrl) && (
                                                <div className="text-secondary small px-4">
                                                    <p>{t('authentication.twoFactorLogin.recovery.instructions1')}</p>
                                                    <p>{t('authentication.twoFactorLogin.recovery.instructions2')}</p>

                                                    <Button variant="secondary" onClick={requestRecoveryCode}>
                                                        {t(
                                                            'authentication.twoFactorLogin.recovery.btn.sendCodeToEmail'
                                                        )}
                                                    </Button>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {recoveryMode && (
                                        <RecoveryMode
                                            requestRecoveryCode={requestRecoveryCode}
                                            verifyRecoveryCode={verifyRecoveryCode}
                                            isSubmitting={isSubmitting}
                                        />
                                    )}
                                </div>
                            </Card.Body>
                        </Card>

                        <div className="d-flex justify-content-center align-items-center pt-2">
                            <LanguageSwitcher />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

function RecoveryMode({ verifyRecoveryCode, requestRecoveryCode, isSubmitting }) {
    const [code, setCode] = useState('');
    const { t } = useTranslation('global');

    return (
        <div className="text-left px-4">
            <div className="mb-4">{t('authentication.twoFactorLogin.recovery.confirmedSendToEmail')}</div>

            <div>
                <Form.Group>
                    <Form.Control
                        required
                        placeholder={`${t('authentication.twoFactorLogin.recovery.recoveryCode')}...`}
                        onChange={({ target }) => {
                            setCode(target.value);
                        }}
                        value={code ?? ''}
                    />
                </Form.Group>

                <Button
                    variant="primary"
                    disabled={code === '' || isSubmitting}
                    className="mr-3"
                    onClick={() => verifyRecoveryCode(code)}
                >
                    {t('authentication.twoFactorLogin.recovery.btn.submit')}
                </Button>

                <Button variant="secondary" onClick={requestRecoveryCode} disabled={isSubmitting}>
                    {t('authentication.twoFactorLogin.recovery.btn.requestNew')}
                </Button>
            </div>
        </div>
    );
}
