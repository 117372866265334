import { OverlayTrigger, Tooltip as ReactBootstrapTooltip } from 'react-bootstrap';
import _ from 'lodash';

export default function Tooltip({ tooltip = '', placement = 'top', children }) {
    if (_.isEmpty(tooltip)) {
        return <>{children}</>;
    }

    return (
        <>
            <OverlayTrigger
                key={placement}
                placement={placement}
                overlay={<ReactBootstrapTooltip id={`tooltip-${placement}`}>{tooltip}</ReactBootstrapTooltip>}
            >
                {children}
            </OverlayTrigger>
        </>
    );
}
