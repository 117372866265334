import React, { useMemo } from 'react';
import { TinymceEditor } from '../../TinymceEditor';
import Parser from 'html-react-parser';
import isEqual from 'react-fast-compare';

// Wrap the component with React.memo to memoize its rendering
const TextBlock = React.memo(
    ({ block, index, readOnly = false, handleBlockChange, searchTerm, selectedSearchBlock  }) => {
        const blockContent = useMemo(() => {
            if (readOnly && block.hasOwnProperty('renderedDiffContent')) {
                return block.renderedDiffContent;
            }

            return block.latestContent || block.baseContent || '';
        }, [block]);

        if (readOnly) {
            return <div className="tiny-mce-content-body p-4">{Parser(blockContent)}</div>;
        }

        return (
            <TinymceEditor
                block={block}
                handleBlockChange={handleBlockChange}
                index={index}
                searchTerm={searchTerm}
                selectedSearchBlock={selectedSearchBlock}
            />
        );
    },
    isEqual
);

export default TextBlock;
