let ProductHelper;

export default ProductHelper = {
    isLinkedProduct(product) {
        const numberOfEnabledLinks = product.extendedProducts.length;

        return numberOfEnabledLinks > 0;
    },
    // Is p1 an extendedProduct of p2?
    isLinkedProductOf(p1, p2) {
        let extended = false;

        p2.extendedProducts.forEach(productLink => {
            if (productLink.baseProductId === p1.id) {
                extended = true;
            }
        });

        return extended;
    },
    productsAsList(arr) {
        const result = [];

        const cb = (e) => {
            result.push({
                value: e.code,
                label: <>
                    {e.productGroup === false && (
                        <span className="text-secondary mr-1">{e.code}</span>
                    )}
                    <span>{e.name}</span>
                </>,
                isDisabled: ProductHelper.isLinkedProduct(e),
                options: e.children.length > 0 ? ProductHelper.productsAsList(e.children) : [],
                codeAndName: e.productGroup === false ? e.code.concat(' ', e.name) : e.name,
            });
        };

        arr.forEach(cb);

        return result;
    },
    secondaryProductsAsList(arr) {
        const result = [];

        const cb = (e) => {
            result.push({
                value: e.code,
                label: e.name,
                options: e.children.length > 0 ? ProductHelper.secondaryProductsAsList(e.children) : undefined,
                codeAndName: e.productGroup === false ? e.code.concat(' ', e.name) : e.name,
                productGroup: e.productGroup,
            });
        };

        arr.forEach(cb);

        return result;
    }
};
