import { useGetDocumentsQuery } from '../../../features/documents/documents';
import { useSelector } from 'react-redux';
import Constants from '../../../config/Constants';
import HelperFunctions from '../../global/HelperFunctions';

const emptyArray = [];

export function useGetDocuments(folderId = undefined, search = '') {
    const { activeOrganisation } = useSelector((state) => state.security);

    const { documents } = useGetDocumentsQuery(
        {
            organisationId: activeOrganisation,
            deleted: folderId === Constants.folders.trash,
        },
        {
            selectFromResult: ({ data }) => ({
                documents: data ?? emptyArray,
            }),
        }
    );

    const sortedDocuments = getDocumentsFromFolder(documents, folderId);

    if (search !== '') {
        return filterSearch(sortedDocuments, search);
    }

    return sortedDocuments;
}

const getDocumentsFromFolder = (documents, folderId) => {
    if (folderId === undefined) {
        // Return all Documents
        return documents;
    }

    // Return deleted Documents
    if (folderId === Constants.folders.trash) {
        return documents
            .filter((document) => document.deletedAt !== null)
            .sort(HelperFunctions.dynamicSort('sortOrder'));
    }

    // Return top-level Documents
    if (folderId === null) {
        return documents
            .filter((document) => document.deletedAt === null && document.documentGroup === null)
            .sort(HelperFunctions.dynamicSort('sortOrder'));
    }

    // Return Documents from group/folder
    const folderIdAsInt = parseInt(folderId);

    return documents
        .filter((document) => document.deletedAt === null && document.documentGroup === folderIdAsInt)
        .sort(HelperFunctions.dynamicSort('sortOrder'));
};

const filterSearch = (documents, search) => {
    const searchValueClean = search.trim().toLowerCase();

    return documents.filter((document) => document.name.toLowerCase().includes(searchValueClean));
};
