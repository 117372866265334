import { EntityReference } from '../../../models/translation.models';
import { useGetReimbursementProductQuery } from '../../../features/translations/translationApi';
import { entityTypes } from '../config/Constants';

export function useGetEntity(entityReference: EntityReference | undefined, parentEntity: any | undefined): any | undefined {
    const { reimbursementProduct } = useGetReimbursementProductQuery(
        `/api/reimbursement-api/api/reimbursement_products/${entityReference?.entityId}`,
        {
            selectFromResult: ({ data }) => ({
                reimbursementProduct: data,
            }),
            skip:
                entityReference === undefined ||
                entityReference.entityType !== entityTypes.REIMBURSEMENT_PRODUCT ||
                parentEntity !== undefined,
        }
    );

    if (parentEntity !== undefined) {
        return parentEntity;
    }

    if (entityReference?.entityType === entityTypes.REIMBURSEMENT_PRODUCT) {
        return reimbursementProduct;
    }

    return undefined;
}
