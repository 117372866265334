import { useGetSelectedVersion } from 'pages/task_team/modals/view_task_modal/TaskVersions';
import { useGetTaskVersion } from 'pages/task_team/hooks/useGetTaskVersion';
import { useUserProfile } from 'hooks/useUserProfile';
import { TaskChecklist } from 'pages/task_team/views/checks/TaskChecklist';

export function TaskFaq({ task }) {
    const selectedVersion = useGetSelectedVersion(task);
    const version = useGetTaskVersion(selectedVersion ? selectedVersion['@id'] : undefined);
    const userProfile = useUserProfile();

    const assignment = version?.taskTeamAssignments.find(
        (taskTeamAssignment) => taskTeamAssignment.assignedTo && taskTeamAssignment.assignedTo === userProfile.userId,
    );

    return (
        <div className="p-4">
            <TaskChecklist
                task={{
                    ...task,
                    id: null,
                    '@id': null,
                    department: task.department['@id'],
                    activeVersion: task.activeVersion['@id'],
                    customerJourney: task.customerJourney['@id'],
                    taskType: task.taskType['@id'],
                    brands: task.brands.map((brand) => brand['@id']),
                    topics: task.topics.map((topic) => topic['@id']),
                    targetAudiences: task.targetAudiences.map((targetAudience) => targetAudience['@id']),
                    taskTeamAssignments: task.taskTeamAssignments.map(
                        (taskTeamAssignment) => taskTeamAssignment['@id'],
                    ),
                    taskVersions: task.taskVersions.map((taskVersion) => taskVersion['@id']),
                }}
                assignment={assignment}
            />
        </div>
    );
}
