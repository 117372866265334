import Language from "../../language/Language";

export default {
    translateTags(text) {
        return text.replace(/\[trans (.*?)]/gm, match => {
            const tagString = match.slice(7, -1)
            const params = {}

            tagString.split(' ').map(_param => _param.split('=')).forEach(_param => {
                params[_param[0]] = _param[1]
            })

            if (params.hasOwnProperty('key')) {
                return Language.getTranslation(params.key, '', 'global')
            }

            return text
        })
    },
}
