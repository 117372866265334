import { Badge, Modal, Nav } from 'react-bootstrap';
import { NumberParam, StringParam, useQueryParam, useQueryParams } from 'use-query-params';
import { IconButton, LightOutlineButton } from 'components/Buttons';
import { useGetTask } from 'pages/task_team/hooks/useGetTask';
import { ArrowRepeat } from 'react-bootstrap-icons';
import { commentsApi } from 'features/comments/commentApi';
import { useDispatch } from 'react-redux';
import { TaskFaq } from 'pages/task_team/modals/view_task_modal/TaskFaq';
import { TaskComments } from 'pages/task_team/modals/view_task_modal/TaskComments';
import { useState } from 'react';
import { useGetEntityComments } from 'pages/comments/hooks/useGetEntityComments';
import { TaskReviews } from 'pages/task_team/modals/view_task_modal/TaskReviews';
import { TaskProperties } from 'pages/task_team/modals/view_task_modal/TaskProperties';

export function ViewTaskModal() {
    const [query, setQuery] = useQueryParams({
        viewTask: StringParam,
        version: NumberParam,
        previewFile: StringParam,
        tab: StringParam,
    });

    const { viewTask } = query;

    if (!viewTask) {
        return null;
    }

    return <ViewTaskModalWrapper id={viewTask} close={close} />;

    function close() {
        setQuery({
            viewTask: undefined,
            version: undefined,
            previewFile: undefined,
            tab: undefined,
        });
    }
}

function ViewTaskModalWrapper({ id, close }) {
    const task = useGetTask(id);
    const dispatch = useDispatch();

    return (
        <>
            <Modal show={true} onHide={close} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>{renderTitle()}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <RenderTaskView task={task} />
                </Modal.Body>
                <Modal.Footer>
                    <LightOutlineButton onClick={close}>Sluiten</LightOutlineButton>
                </Modal.Footer>
            </Modal>
        </>
    );

    function renderTitle() {
        if (!task) {
            return null;
        }

        return (
            <>
                #{task.sequence}
                {task?.activeVersionNumber && <>-{task.activeVersionNumber}</>}
                &nbsp;{task.title}
                <IconButton
                    icon={<ArrowRepeat className="text-primary mr-0" />}
                    className="ml-3 mb-1"
                    tooltip="Refresh"
                    onClick={() => {
                        dispatch(
                            commentsApi.util.invalidateTags([
                                { type: 'Task', id: task['@id'] },
                                { type: 'File', id: 'LIST' },
                            ]),
                        );
                    }}
                />
            </>
        );
    }
}

function RenderTaskView({ task }) {
    const [tab = 'reviews'] = useQueryParam('tab', StringParam);
    const [selectedNav, setSelectedNav] = useState(tab);
    const ViewToRender = RenderView[selectedNav] ?? ViewNotFound;

    return (
        <div>
            <div className="d-flex border-bottom subnav pl-1">
                <Nav activeKey={selectedNav} onSelect={(eventKey) => setSelectedNav(eventKey)}>
                    <Nav.Item>
                        <Nav.Link eventKey="reviews">Uiting en beoordeling</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="properties">Aanvullende gegevens</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="faq">Instructies</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="comments">
                            <div className="d-flex align-items-center">
                                Opmerkingen
                                {task && <TaskCommentsCount taskUri={task['@id']} />}
                            </div>
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>

            <div className="dr-modal-content-light small">
                <ViewToRender task={task} />
            </div>
        </div>
    );
}

function TaskCommentsCount({ taskUri }) {
    const { comments = [] } = useGetEntityComments(taskUri);

    if (comments.length === 0) {
        return null;
    }

    return (
        <Badge variant="primary" className="small ml-2">
            {comments.length}
        </Badge>
    );
}

function ViewNotFound() {
    return <div>view not found</div>;
}

const RenderView = {
    reviews: TaskReviews,
    properties: TaskProperties,
    faq: TaskFaq,
    comments: TaskComments,
};
