import { Card, Col, Container, Row } from 'react-bootstrap';
import { Logo } from 'pages/global/Logo';
import { PrimaryButton } from 'components/Buttons';
import LanguageSwitcher from 'language/LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useUserPasswordResetMutation } from 'features/security/authApi';
import { Link, useParams } from 'react-router-dom';
import { Form as FForm, Formik } from 'formik';
import * as Yup from 'yup';
import { FormFieldWithTitle } from 'pages/publications_v2/helpers/FieldHelper';

export const RESET_PASSWORD_PATH = '/reset-password/:token';

export function ResetPassword() {
    const { token } = useParams();
    const { t } = useTranslation();
    const [userPasswordReset] = useUserPasswordResetMutation();

    const [resetSent, setResetSent] = useState(false);
    const [resetError, setResetError] = useState(false);
    const [resetErrorMsg, setResetErrorMsg] = useState('');

    return (
        <div>
            <div id="login-bg-image" />
            <Container className="page-login pt-5 pb-4">
                <Row className="justify-content-center">
                    <Col sm={10} md={8} lg={6} xl={5}>
                        <Card className="dr-container mt-3">
                            <Card.Body>
                                <div className="p-4">
                                    <div className="text-center mb-4">
                                        <Logo />
                                        <div className="text-color pb-2" style={{ fontSize: '1.15rem' }}>
                                            {t('global:login.reset.resetPassword')}
                                        </div>
                                    </div>

                                    {resetSent ? (
                                        <div>
                                            <div className="mb-3">
                                                {resetErrorMsg === 'token_expired' ? (
                                                    <>{t('global:authentication.errorMessage.resetTokenExpired')}</>
                                                ) : (
                                                    <>
                                                        {t(
                                                            resetError
                                                                ? 'global:login.reset.passwordResetError'
                                                                : 'global:login.reset.passwordResetSuccess'
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                            <Link to="/">{t('global:login.reset.backToLogin')}</Link>
                                        </div>
                                    ) : (
                                        <Formik
                                            initialValues={{
                                                token,
                                                plainPassword: '',
                                                passwordConfirmation: '',
                                            }}
                                            onSubmit={handleSubmit}
                                            enableReinitialize
                                            validationSchema={resetPasswordSchema}
                                        >
                                            {({ values, dirty, isSubmitting, isValid }) => (
                                                <FForm>
                                                    <FormFieldWithTitle
                                                        name="plainPassword"
                                                        label={t('global:editUser.loginDetails.password')}
                                                        help={t('global:editUser.loginDetails.passwordHelp')}
                                                        props={{
                                                            type: 'password',
                                                            autoComplete: 'new-password',
                                                        }}
                                                    />
                                                    <FormFieldWithTitle
                                                        name="passwordConfirmation"
                                                        label={t('global:editUser.loginDetails.passwordNew')}
                                                        props={{
                                                            type: 'password',
                                                            autoComplete: 'new-password',
                                                            disabled: values.plainPassword === '',
                                                        }}
                                                    />

                                                    <PrimaryButton
                                                        type="submit"
                                                        className="w-100 py-2"
                                                        disabled={!isValid || isSubmitting || !dirty}
                                                    >
                                                        {t('global:login.reset.savePasswordBtn')}
                                                    </PrimaryButton>
                                                </FForm>
                                            )}
                                        </Formik>
                                    )}
                                </div>
                            </Card.Body>
                        </Card>

                        <div className="d-flex justify-content-center align-items-center pt-2">
                            <LanguageSwitcher />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );

    function handleSubmit(values) {
        userPasswordReset(values).then((response) => {
            setResetSent(true);

            console.log(response);
            console.log(response?.error?.data?.msg);

            if (response?.error?.status === 400) {
                setResetError(true);
                setResetErrorMsg(response?.error?.data?.msg ?? '');
            }
        });
    }
}

const resetPasswordSchema = Yup.object().shape({
    token: Yup.string().required(),
    plainPassword: Yup.string()
        .min(12)
        .matches(/(?=^.{12,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/),
    passwordConfirmation: Yup.string().when('plainPassword', {
        is: (val) => val && val.length > 0,
        then: Yup.string()
            .required()
            .oneOf([Yup.ref('plainPassword')], 'Both password need to be the same'),
    }),
});
