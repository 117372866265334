import { StringParam, useQueryParam } from 'use-query-params';
import { IpidEuNewDocument } from 'components/Tours/IpidEuNewDocument';
import _ from 'lodash';
import { IpidEuNewPublication } from 'components/Tours/IpidEuNewPublication';

export function Tours() {
    const [startTour] = useQueryParam('startTour', StringParam);

    if (_.isEmpty(startTour)) {
        return null;
    }

    const ViewToRender = RenderViews[startTour] ?? DefaultView;

    return <ViewToRender />;
}

const RenderViews = {
    ipid_eu_new_document: IpidEuNewDocument,
    ipid_eu_new_publication: IpidEuNewPublication,
};

function DefaultView() {
    return null;
}

export const globalStyles = {
    options: {
        arrowColor: '#fff',
        backgroundColor: '#fff',
        beaconSize: 36,
        overlayColor: 'rgba(0, 0, 0, 0.5)',
        primaryColor: '#f04',
        spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
        textColor: '#333',
        width: undefined,
        zIndex: 1250,
    },
};
