import { useParams } from 'react-router-dom';
import { Form as FForm, Formik, useFormikContext } from 'formik';
import { Badge, Col, Form, Row } from 'react-bootstrap';
import { LoadingSpinner } from 'pages/global/Spinner';
import { InputField, InputSelect, InputSelectWithTitle, SwitchField } from 'pages/publications_v2/helpers/FieldHelper';
import Constants, { AccountRestrictions, Permissions } from '../../../../config/Constants';
import HelperFunctions from '../../../global/HelperFunctions';
import RestrictedContent from '../../../global/RestrictedContent';
import ChooseTemplateModal from '../edit/templates/ChooseTemplateModal';
import { useCurrentOrganisation } from 'hooks/useCurrentOrganisation';
import { useGetDocument } from '../../hooks/useGetDocument';
import { useUpdateDocumentMutation } from 'features/documents/documents';
import { useGetCategories } from 'pages/reimbursements/hooks/useGetCategories';
import { useGetDocuments } from '../../hooks/useGetDocuments';
import { WarningButton } from 'components/Buttons';
import { useGetTemplate } from '../../hooks/useGetTemplate';
import { useUserProfile } from 'hooks/useUserProfile';
import { useTranslation } from 'react-i18next';
import { useHasAccountRestriction } from 'hooks/useHasAccountRestriction';
import { useGetModelVersionsQuery } from 'features/templates/templatesApi';
import { useEffect } from 'react';
import _ from 'lodash';

export default function Properties() {
    const { t } = useTranslation('documents');
    const document = useGetDocument();

    if (!document) {
        return null;
    }

    return (
        <>
            <div className="dr-container p-4">
                <div className="subheader">
                    <h3>{t('document.navbar.settings.tabs.settings.title')}</h3>
                </div>

                <div className="dr-instellingen-container">
                    <Row>
                        <Col xl={9}>
                            <PropertiesForm document={document} />
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

function PropertiesForm({ document }) {
    const [updateDocument] = useUpdateDocumentMutation();
    const allDocuments = useGetDocuments();
    const { t } = useTranslation('documents');

    const libraryDocuments = allDocuments.filter((_document) => _document.type === Constants.documentType.library);
    const restrictions = useGetTemplate(document.modelId, document.modelSource)?.properties?.templateParameters;

    const handleSubmit = (values, { setSubmitting }) => {
        updateDocument({
            id: document.id,
            uri: `/v2/documents/${document.id}`,
            body: values,
            method: 'PATCH',
        }).then(() => {
            setSubmitting(false);
        });
    };

    return (
        <Formik
            initialValues={{
                name: document.name,
                version: document.version ?? '',
                type: document.type ?? Constants.documentType.default,
                reimbursementCategoryId: document.reimbursementCategoryId,
                documentLibrary: document.documentLibrary,
                modelId: document.modelId,
                modelSource: document.modelSource,
                templateCategoryId: document.templateCategoryId,
                properties: document.properties,
            }}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({ values, dirty, isSubmitting, isValid }) => (
                <FForm autoComplete="off">
                    <div className="mb-3 ">
                        <label className="mb-2 font-weight-bolder label-title" htmlFor="name">
                            {t('document.navbar.settings.tabs.settings.name')}
                        </label>
                        <InputField name="name" />
                    </div>
                    <div className="mb-3">
                        <label className="mb-2 font-weight-bolder label-title" htmlFor="version">
                            {t('document.navbar.settings.tabs.settings.version')}
                        </label>
                        <InputField name="version" />
                    </div>

                    <DocumentTypeSelect />
                    <TemplateCategorySelect />

                    {values.type !== Constants.documentType.model && (
                        <>
                            {(restrictions === undefined || restrictions.canLinkReimbursementStructure) && (
                                <RestrictedContent
                                    permission={Permissions.Reimbursements['Write.All']}
                                    module={Constants.modules.reimbursements}
                                >
                                    <ReimbursementCategorySelect
                                        documentType={values.type}
                                        restrictions={restrictions}
                                    />
                                </RestrictedContent>
                            )}

                            {!values.modelId && libraryDocuments.length > 0 && (
                                <div className="mb-3">
                                    <label className="mb-2 font-weight-bolder label-title">
                                        {t('document.navbar.settings.tabs.settings.library')}
                                    </label>
                                    <InputSelect
                                        name="documentLibrary"
                                        props={{
                                            isDisabled:
                                                values.type === Constants.documentType.library ||
                                                (restrictions && restrictions.canLinkLibrary),
                                            isClearable: true,
                                        }}
                                        options={HelperFunctions.prepareDropdownData(libraryDocuments)}
                                    />
                                </div>
                            )}

                            <ChooseTemplateModal />

                            {/* not implemented yet */}
                            {/* <ChooseTemplateImage label={'Selecteer afbeelding'} /> */}
                        </>
                    )}

                    <RestrictedContent module={Constants.modules.openai}>
                        <OpenAiSwitch
                            name="properties.allowAI"
                            label={t('document.navbar.settings.tabs.settings.allowOpenAi')}
                            title={t('document.navbar.settings.tabs.settings.textControl')}
                        />
                    </RestrictedContent>

                    <RestrictedContent module={Constants.modules['one-liner']}>
                        <OneLinerSwitch
                            name="properties.enableOneLiners"
                            label={'One-liners inschakelen voor dit document'}
                            title={'One-liners'}
                        />
                    </RestrictedContent>

                    <WarningButton type="submit" disabled={isSubmitting || !dirty || !isValid}>
                        {t('btn.save')}
                    </WarningButton>
                    <LoadingSpinner isSubmitting={isSubmitting} />
                </FForm>
            )}
        </Formik>
    );
}

function ReimbursementCategorySelect({ documentType, restrictions }) {
    const categories = useGetCategories();
    const { t } = useTranslation('documents');

    return (
        <div className="mb-3">
            <label className="mb-2 font-weight-bolder label-title">
                {t('document.navbar.settings.tabs.settings.reimbursement')}
            </label>
            <InputSelect
                name="reimbursementCategoryId"
                props={{
                    isDisabled:
                        documentType === Constants.documentType.library ||
                        categories.length === 0 ||
                        restrictions?.canLinkReimbursementStructure === false,
                    isClearable: true,
                }}
                options={HelperFunctions.prepareDropdownData(categories)}
            />
        </div>
    );
}

export function DocumentTypeSelect() {
    const { t } = useTranslation('documents');
    const { t: tGlobal } = useTranslation('global');
    const { documentId } = useParams();
    const userProfile = useUserProfile();
    const isTemplateManager = userProfile.roles.includes(Constants.userRoles.templateManager);

    const allowOnlyFromDocumentTemplate = useHasAccountRestriction(
        AccountRestrictions.document.allowOnlyFromDocumentTemplate,
    );

    const options = getOptions();

    return (
        <div className="mb-3">
            <InputSelectWithTitle
                name="type"
                options={options}
                props={{
                    isDisabled: !!documentId,
                    placeholder: `${t('document.navbar.settings.tabs.settings.titleSelect')}...`,
                    required: true,
                }}
                title={`${t('document.navbar.settings.tabs.settings.typeSelect')}`}
            />
        </div>
    );

    function getOptions() {
        return documentTypes
            .filter((documentType) => {
                if (!isTemplateManager && documentType.value === Constants.documentType.model) {
                    return false;
                }
                return true;
            })
            .map((documentType) => {
                const isDisabledByRestriction =
                    allowOnlyFromDocumentTemplate && documentType.value !== Constants.documentType.default;

                return {
                    ...documentType,
                    label: getOptionLabel(t(`documents:${documentType.label}`), isDisabledByRestriction),
                    isDisabled: isDisabledByRestriction,
                };
            });
    }

    function getOptionLabel(label, isDisabledByRestriction = false) {
        return (
            <div className="d-flex align-items-center">
                {label}
                {isDisabledByRestriction && (
                    <Badge variant="info" className="ml-3">
                        {tGlobal('labels.licenceRequired')}
                    </Badge>
                )}
            </div>
        );
    }
}

export function TemplateCategorySelect() {
    const currentOrganisation = useCurrentOrganisation();
    const { values } = useFormikContext();
    const { documentId } = useParams();
    const { t } = useTranslation('documents');

    const { modelVersions = [] } = useGetModelVersionsQuery(documentId, {
        selectFromResult: ({ data }) => ({
            modelVersions: data ?? [],
        }),
        skip: !documentId,
    });

    if (
        !currentOrganisation ||
        values.type !== Constants.documentType.model ||
        currentOrganisation.templateCategories?.length === 0
    ) {
        return null;
    }

    const filteredTemplateCategories = currentOrganisation?.templateCategories.filter(
        (templateCategory) => templateCategory.isOwner === true,
    );

    return (
        <RestrictedContent permission={Permissions.Templates['Create']}>
            <InputSelectWithTitle
                name="templateCategoryId"
                title={t('documents.newDocument.templateCategory')}
                props={{
                    isClearable: !documentId,
                    isDisabled: documentId !== undefined && modelVersions.length > 0,
                }}
                options={HelperFunctions.prepareDropdownData(filteredTemplateCategories)}
            />
        </RestrictedContent>
    );
}

function OpenAiSwitch({ label, title, name }) {
    const { t } = useTranslation('documents');

    return (
        <div className="mb-4">
            <div className="mb-1 font-weight-bolder label-title">{title}</div>

            <SwitchField label={label} name={name} />

            <Form.Text id={name} muted>
                {t('document.navbar.settings.tabs.settings.openAiTerms')}&nbsp;
                <a href="https://openai.com/policies" target="_blank">
                    openai.com/policies
                </a>
            </Form.Text>
        </div>
    );
}

function OneLinerSwitch({ label, title, name }) {
    const { values, setFieldValue } = useFormikContext();
    const { enableOneLiners = false, oneLiners } = values.properties;

    useEffect(() => {
        if (enableOneLiners === true && _.isEmpty(oneLiners)) {
            setFieldValue('properties.oneLiners', {
                exportFileName: 'export.csv',
                colors: '',
                defaultMetadata: '',
            });
        }
    }, [enableOneLiners, oneLiners]);

    return (
        <div className="mb-4">
            <div className="mb-1 font-weight-bolder label-title">{title}</div>

            <SwitchField label={label} name={name} />
        </div>
    );
}

// Not implemented yet
// export function ChooseTemplateImage() {
//     return (
//         <>
//             {/* <FileEarmarkBarGraphFill onClick={handleIconClick}> */}
//             <MediaSelector />
//             {/* </FileEarmarkBarGraphFill> */}
//         </>
//     );
// }

export const documentTypes = [
    {
        label: 'documents.newDocument.type.standard',
        value: Constants.documentType.default,
    },
    {
        label: 'documents.newDocument.type.termsAndConditions',
        value: Constants.documentType.terms_conditions,
    },
    {
        label: 'documents.newDocument.type.library',
        value: Constants.documentType.library,
    },
    {
        label: 'documents.newDocument.type.template',
        value: Constants.documentType.model,
    },
];
