import { useDispatch, useSelector } from 'react-redux';
import { createContext, useContext, useEffect, useState } from 'react';
import { fetchTasks, selectAllDepartmentTasksForOpList } from '../../../features/operationsList/taskSlice';
import { Accordion, AccordionContext, Button, Card } from 'react-bootstrap';
import { CaretDownFill, CaretUpFill, ExclamationCircle } from 'react-bootstrap-icons';
import _ from 'lodash';
import { isFieldRequired, TaskFields } from './Tasks/TaskFields';
import { TaskBulkEdit } from './Tasks/TaskBulkEdit';
import { useTranslation } from 'react-i18next';

export const TasksContext = createContext({
    selectedTasks: [],
    setSelectedTasks: () => {},
    taskIsSelected: () => {},
    toggleTask: () => {},
});

export default function OpTeamTasks({ opList, opEntryLabel }) {
    const [selectedTasks, setSelectedTasks] = useState([]);
    const dispatch = useDispatch();
    const tasks = useSelector(selectAllDepartmentTasksForOpList(opList));
    const availableTasks = tasks.filter((_task) => !_task.deletedAt && _task.fields.length > 0);

    useEffect(() => {
        dispatch(fetchTasks());
    }, []);

    if (availableTasks.length === 0) {
        return null;
    }

    const taskIsSelected = (uri) => {
        return selectedTasks.find((_task) => _task['@id'] === uri) !== undefined;
    };

    const toggleTask = (task) => {
        const isSelected = taskIsSelected(task['@id']);

        if (isSelected) {
            // Remove
            setSelectedTasks(selectedTasks.filter((_task) => _task['@id'] !== task['@id']));
        } else {
            // Add
            setSelectedTasks([...selectedTasks, task]);
        }
    };

    return (
        <TasksContext.Provider
            value={{
                selectedTasks,
                setSelectedTasks,
                taskIsSelected,
                toggleTask,
            }}
        >
            <TaskBulkEdit availableTasks={availableTasks} opEntryLabel={opEntryLabel} />

            <Accordion>
                {availableTasks.map((_task) => (
                    <Task opEntryLabel={opEntryLabel} task={_task} key={`task-${_task.id}`} />
                ))}
            </Accordion>
        </TasksContext.Provider>
    );
}

function Task({ opEntryLabel, task }) {
    const currentEventKey = useContext(AccordionContext);
    const isExpanded = task && currentEventKey === task.id;
    const { toggleTask, taskIsSelected } = useContext(TasksContext);
    const { t } = useTranslation('reimbursements');

    const notDeletedFields = task.fields.filter((field) => !field.deletedAt);

    return (
        <div className="mb-2">
            <Card style={{ marginBottom: -1 }}>
                <Card.Body className="op-list-row-title py-2 pl-3">
                    <div className="d-flex align-items-center">
                        <input
                            type="checkbox"
                            className="mr-2"
                            checked={taskIsSelected(task['@id'])}
                            onChange={() => {
                                toggleTask(task);
                            }}
                        />

                        {hasMissingFields(opEntryLabel, task) && (
                            <ExclamationCircle className="text-danger" data-uk-tooltip={t('etc.emptyFields')} />
                        )}

                        <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey={task.id}
                            className="prevent-toggle d-flex align-items-center text-body px-1 py-0"
                        >
                            <div className="mr-1">{task.label}</div>
                            {isExpanded ? <CaretUpFill size={10} /> : <CaretDownFill size={10} />}
                        </Accordion.Toggle>

                        <PrimaryQuestionAnswer fields={notDeletedFields} opEntryLabel={opEntryLabel} />
                    </div>
                </Card.Body>

                <Accordion.Collapse eventKey={task.id}>
                    <>
                        {isExpanded && (
                            <TaskFields opEntryLabel={opEntryLabel} notDeletedFields={notDeletedFields} task={task} />
                        )}
                    </>
                </Accordion.Collapse>
            </Card>
        </div>
    );
}

function PrimaryQuestionAnswer({ fields, opEntryLabel }) {
    const answer = primaryQuestionAnswer(fields, opEntryLabel);
    const color = primaryAnswerColor(fields, opEntryLabel);

    return (
        <div className="small ml-auto" style={{ color }}>
            {answer}
        </div>
    );
}

export const primaryQuestionAnswer = (notDeletedFields, opEntryLabel) => {
    const { fieldValues = {} } = opEntryLabel;
    const primaryQuestionField = notDeletedFields[0];

    if (!primaryQuestionField) {
        return '';
    }

    return fieldValues[primaryQuestionField['@id']] ?? primaryQuestionField.options[0];
};

export const primaryAnswerColor = (fields, opEntryLabel) => {
    const answer = primaryQuestionAnswer(fields, opEntryLabel);

    switch (answer) {
        case 'Te bepalen':
            return '#ff9d00';
        case 'Ja':
            return '#0073d5';
        default:
            return '#6c757d';
    }
};

export const hasMissingFields = (opEntryLabel, task) => {
    const notDeletedFields = task.fields.filter((field) => !field.deletedAt);
    const { fieldValues = {} } = opEntryLabel;

    return notDeletedFields.some((field) => {
        return isFieldRequired(field, fieldValues) && _.isEmpty(fieldValues[field['@id']]);
    });
};
