import { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

export function Dropzone({ isDisabled = false, onDropComplete }) {
    const onDrop = (acceptedFiles) => {
        onDropComplete(acceptedFiles[0]);
    };
    const { t } = useTranslation('documents');

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        maxFiles: 1,
        accept: {
            'text/csv': ['.csv'],
        },
        onDrop: onDrop,
    });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
            ...(isDisabled ? disabledStyle : {}),
        }),
        [isDragActive, isDragReject, isDragAccept, isDisabled]
    );

    return (
        <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <div>{t('document.navbar.tags.importModal.addFile')}</div>
        </div>
    );
}

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
};

const activeStyle = {
    borderColor: '#2196f3',
};

const acceptStyle = {
    borderColor: '#00e676',
    backgroundColor: '#00e67614',
};

const rejectStyle = {
    borderColor: '#ff1744',
};

const disabledStyle = {
    opacity: 0.5,
};
