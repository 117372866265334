import React, { Component } from 'react';
import Language from '../../../language/Language';
import HelperFunctions from '../../global/HelperFunctions';
import { Button } from 'uikit-react';
import { animateScroll as scroll } from 'react-scroll';
import LoadingSpinner from '../../global/LoadingSpinner';
import LoadingSavedIndicator from '../../global/LoadingSavedIndicator';
import ReimbursementApi from '../../../api/ReimbursementApi';
import { generatePath, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import RestrictedReimbursementContent from '../../reimbursements/RestrictedReimbursementContent';
import Constants from '../../../config/Constants';
import { BASE_PATH, VIEW_PATH } from '../../../scenes/Reimbursements';
import { reimbursementApi } from '../../../features/reimbursements/reimbursements';
import MainContentNav from '../../Navbar';
import SubNav from '../../reimbursements/views/SubNav';
import { useGetCategory } from '../../../features/reimbursements/reimbursementHooks';
import { Col, Container, Row } from 'react-bootstrap';
import { WarningButton } from '../../../components/Buttons';
import { useTranslation } from 'react-i18next';

export default function UpdateProductTypesAsFunction() {
    const params = useParams();
    const categoryId = parseInt(params.categoryId);
    const dispatch = useDispatch();
    const { category } = useGetCategory(categoryId);
    const { t } = useTranslation('reimbursements');

    if (category === undefined) {
        return <LoadingSpinner />;
    }
    const invalidateTags = (tags = []) => {
        dispatch(reimbursementApi.util.invalidateTags(tags));
    };

    return (
        <RestrictedReimbursementContent
            categoryId={categoryId}
            roles={[
                Constants.reimbursementTeamRoles.admin,
                Constants.reimbursementTeamRoles.manager,
                Constants.reimbursementTeamRoles.finalEditor,
            ]}
        >
            <MainContentNav
                pages={[
                    { title: t('breadcrumb'), url: BASE_PATH },
                    { title: category?.name, url: generatePath(VIEW_PATH, { categoryId }) },
                    { title: t('productGroup.title') },
                ]}
            />

            <SubNav />

            <div className="w-100">
                <div className="content-static-body has-subnav">
                    <div className="d-flex align-items-stretch h-100">
                        <div id="scroll-container" className="flex-grow-1 overflow-auto pt-5 pb-3">
                            <Container>
                                <Row>
                                    <Col>
                                        <UpdateProductTypes category={category} invalidateTags={invalidateTags} t={t} />
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </div>
            </div>
        </RestrictedReimbursementContent>
    );
}

class UpdateProductTypes extends Component {
    constructor(props) {
        super(props);

        this.categoryId = this.props.category.id;
        this.requiredFormFields = ['name', 'code'];
        this.state = {
            changesSaved: false,
            disableSubmitBtn: true,
            category: {
                name: '',
                productTypes: [],
            },
            formData: this.getFormData(),
            isLoading: true,
            saveInProgress: false,
        };
    }

    addProductType = (formData) => {
        if (formData.hasOwnProperty('id')) {
            delete formData.id;
        }

        return new Promise((resolve, reject) => {
            ReimbursementApi.createProductType(formData)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    cancelEdit = () => {
        this.setState({
            formData: this.getFormData(),
            disableSubmitBtn: true,
        });
    };

    cloneItem = (item) => {
        const newItem = {
            name: item.name + ' (' + Language.getTranslation('copy', 'text', 'products') + ')',
            code: item.code + '-' + Language.getTranslation('copy', 'text', 'products'),
            enabled: item.enabled,
            category: item.category,
            sortOrder: item.sortOrder + 1,
        };

        this.addProductType(newItem).then(() => {
            ReimbursementApi.getProductTypes(this.categoryId).then((data) => {
                this.setState({
                    category: {
                        ...this.state.category,
                        productTypes: data['hydra:member'],
                    },
                });
            });
        });
    };

    changesSaved = () => {
        this.setState(
            {
                changesSaved: true,
                disableSubmitBtn: true,
                formData: this.getFormData(),
                saveInProgress: false,
            },
            () => {
                HelperFunctions.changesSaved().then(() => {
                    this.setState({ changesSaved: false });
                });
            }
        );
    };

    componentDidMount() {
        ReimbursementApi.getProductTypes(this.categoryId)
            .then((data) => {
                const category = {
                    ...this.props.category,
                    productTypes: data['hydra:member'],
                };

                this.setState({
                    category,
                    isLoading: false,
                });
            })
            .catch((error) => {
                this.setState({
                    isLoading: false,
                });
            });
    }

    editProductType = (productType) => {
        scroll.scrollToBottom({
            containerId: 'scroll-container',
        });

        const formData = {
            id: productType.id,
            name: productType.name,
            code: productType.code === null ? '' : productType.code,
        };

        this.setState({
            formData,
            disableSubmitBtn: !HelperFunctions.formIsValid(formData, this.requiredFormFields),
        });
    };

    getFormData = () => {
        return {
            id: null,
            name: '',
            code: '',
            category: '/api/categories/' + this.categoryId,
        };
    };

    handleFormChange = (event) => {
        const target = event.target;

        // Update data
        const formData = this.state.formData;
        formData[target.name] = target.value;

        this.setState({
            formData,
            disableSubmitBtn: !HelperFunctions.formIsValid(formData, this.requiredFormFields),
        });
    };

    handleFormSubmit = (event) => {
        event.preventDefault();

        this.setState(
            {
                disableSubmitBtn: true,
                saveInProgress: true,
            },
            () => {
                const formData = this.state.formData;
                const t = this.props.t;
                if (formData.id === null) {
                    // Add new item
                    this.addProductType(formData)
                        .then((data) => {
                            // save successful
                            this.setState({
                                category: {
                                    ...this.state.category,
                                    productTypes: this.state.category.productTypes.concat([data]),
                                },
                            });

                            // Invalidate Tags
                            this.props.invalidateTags([{ type: 'ProductType', id: 'LIST' }]);

                            this.changesSaved();
                        })
                        .catch((exception) => {
                            if (
                                exception.errorCode === 422 &&
                                exception.data['hydra:description'] === 'UniqueCodeViolation'
                            ) {
                                this.setState({
                                    saveInProgress: false,
                                });

                                HelperFunctions.alertModal(t('productGroup.errorMessage.codeError'));
                            }
                        });
                } else {
                    // Update item
                    this.updateProductType(formData)
                        .then((data) => {
                            // save successful
                            const productTypes = this.state.category.productTypes.map((productType) => {
                                return productType.id === formData.id ? data : productType;
                            });

                            this.setState({
                                category: {
                                    ...this.state.category,
                                    productTypes: productTypes,
                                },
                            });

                            // Invalidate Tags
                            this.props.invalidateTags([{ type: 'ProductType', id: 'LIST' }]);

                            this.changesSaved();
                        })
                        .catch(({ response }) => {
                            if (
                                response.status === 422 &&
                                response.data['hydra:description'] === 'UniqueCodeViolation'
                            ) {
                                this.setState({
                                    disableSubmitBtn: false,
                                    saveInProgress: false,
                                });

                                HelperFunctions.alertModal(t('productGroup.errorMessage.codeError'));
                            }
                        });
                }
            }
        );
    };

    moveDown = (item) => {
        if (HelperFunctions.isLastInArray(this.state.category.productTypes, item) === false) {
            this.saveSortOrder(item.sortOrder + 1, item);
        }
    };

    moveUp = (item) => {
        // Only update the order when it's not the first
        if (item.sortOrder > 0) {
            this.saveSortOrder(item.sortOrder - 1, item);
        }
    };

    saveSortOrder = (newSortOrder, currentItem) => {
        const formData = {
            id: currentItem.id,
            sortOrder: newSortOrder,
        };

        this.updateProductType(formData).then(() => {
            ReimbursementApi.getProductTypes(this.categoryId).then((data) => {
                this.setState({
                    category: {
                        ...this.state.category,
                        productTypes: data['hydra:member'],
                    },
                });
            });
        });
    };

    toggleEnable = (productType) => {
        const updateProductType = {
            id: productType.id,
            enabled: !productType.enabled,
        };
        const t = this.props.t;
        this.updateProductType(updateProductType)
            .then((data) => {
                const productTypes = this.state.category.productTypes.map((productType) => {
                    return productType.id === updateProductType.id ? data : productType;
                });

                this.setState({
                    category: {
                        ...this.state.category,
                        productTypes: productTypes,
                    },
                });
            })
            .catch((exception) => {
                if (exception.errorCode === 422 && exception.data['hydra:description'] === 'UniqueCodeViolation') {
                    this.setState({
                        saveInProgress: false,
                    });

                    HelperFunctions.alertModal(t('productGroup.errorMessage.codeError'));
                }
            });
    };

    updateProductType = (formData) => {
        return new Promise((resolve, reject) => {
            ReimbursementApi.updateProductType(formData.id, formData)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    render() {
        if (this.state.isLoading) {
            return <LoadingSpinner />;
        }

        return (
            <div className="uk-position-relative uk-margin-medium-bottom">
                <div className="uk-margin-bottom">
                    {this.state.category.productTypes.length > 0 &&
                        this.state.category.productTypes.map((productType) => {
                            return (
                                <ProductType
                                    cloneItem={this.cloneItem}
                                    editProductType={this.editProductType}
                                    moveDown={this.moveDown}
                                    moveUp={this.moveUp}
                                    productType={productType}
                                    toggleEnable={this.toggleEnable}
                                    key={productType.id}
                                    t={this.props.t}
                                />
                            );
                        })}
                </div>

                <div className="uk-grid">
                    <div className="uk-width-4-5@l uk-width-1-2@xl">
                        <NewProduct
                            changesSaved={this.state.changesSaved}
                            cancelEdit={this.cancelEdit}
                            disableSubmitBtn={this.state.disableSubmitBtn}
                            formData={this.state.formData}
                            addProductType={this.addProductType}
                            handleFormChange={this.handleFormChange}
                            handleFormSubmit={this.handleFormSubmit}
                            saveInProgress={this.state.saveInProgress}
                            t={this.props.t}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

class ProductType extends Component {
    render() {
        const { cloneItem, editProductType, moveDown, moveUp, productType, toggleEnable, t } = this.props;

        return (
            <div className="uk-grid uk-background-hover-parent uk-margin-remove-top uk-margin-bottom">
                <div
                    className={
                        'uk-width-1-1 uk-background-theme-light-child' + (!productType.enabled ? ' uk-text-muted' : '')
                    }
                >
                    <div className="uk-float-left line-height-button">
                        {productType.code && (
                            <span className="uk-text-small uk-text-muted uk-padding-small-right">
                                {productType.code}
                            </span>
                        )}
                        {productType.name}
                        {!productType.enabled && <span className="uk-text-xsmall"> {t('productGroup.inactive')}</span>}
                    </div>

                    <div className="uk-float-right">
                        <Button color="primary" onClick={() => editProductType(productType)}>
                            {t('productGroup.btn.edit')}
                        </Button>
                        <Button color="primary" onClick={() => cloneItem(productType)} className="uk-margin-small-left">
                            {t('productGroup.btn.duplicate')}
                        </Button>
                        <Button
                            color={productType.enabled ? 'default' : 'primary'}
                            onClick={() => toggleEnable(productType)}
                            className="uk-margin-small-left uk-margin-right"
                        >
                            {!productType.enabled ? t('productGroup.btn.enable') : t('productGroup.btn.disable')}
                        </Button>
                        <button
                            className="uk-button-icon"
                            onClick={() => moveUp(productType)}
                            uk-icon="icon: arrow-up; ratio: 1.1"
                        />
                        <button
                            className="uk-button-icon"
                            onClick={() => moveDown(productType)}
                            uk-icon="icon: arrow-down; ratio: 1.1"
                        />
                    </div>
                </div>
            </div>
        );
    }
}

class NewProduct extends Component {
    render() {
        const { changesSaved, formData, disableSubmitBtn, saveInProgress, t } = this.props;
        const isEditMode = formData.id !== null;

        return (
            <div className="uk-padding-top">
                <form className="uk-form-horizontal" autoComplete="off" onSubmit={this.props.handleFormSubmit}>
                    <div className="uk-margin-remove line-height-button">
                        <div className="uk-text-primary uk-margin-right">{t('productGroup.form.title')}</div>

                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="name">
                                {t('productGroup.form.name')}*
                            </label>
                            <div className="uk-form-controls">
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    className="uk-input"
                                    value={formData.name}
                                    onChange={this.props.handleFormChange}
                                    disabled={false}
                                />
                            </div>
                        </div>
                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="code">
                                {t('productGroup.form.code')}*
                            </label>
                            <div className="uk-form-controls">
                                <input
                                    type="text"
                                    id="code"
                                    name="code"
                                    className="uk-input"
                                    value={formData.code}
                                    onChange={this.props.handleFormChange}
                                    disabled={false}
                                />
                            </div>
                        </div>
                        <WarningButton type="submit" disabled={saveInProgress || disableSubmitBtn}>
                            {t('btn.save')}
                        </WarningButton>
                        {isEditMode && (
                            <a className="uk-margin-left line-height-button" onClick={this.props.cancelEdit}>
                                {t('btn.cancel')}
                            </a>
                        )}
                        <LoadingSavedIndicator changesSaved={changesSaved} saveInProgress={saveInProgress} />
                    </div>
                </form>
            </div>
        );
    }
}
