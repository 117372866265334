import { useDispatch, useSelector } from 'react-redux';
import { fetchOpListById, selectOpListById } from 'features/operationsList/operationsListSlice';
import { useEffect, useState } from 'react';

export function useGetOpList(id) {
    const opList = useSelector((state) => selectOpListById(state.operations, id));
    const [loading, setLoading] = useState(opList === undefined);
    const dispatch = useDispatch();

    useEffect(() => {
        if (loading && opList === undefined) {
            dispatch(fetchOpListById({ id })).finally(() => setLoading(false));
        }
    }, [id, opList, loading]);

    return opList;
}
