import { entityTypes } from '../../config/Constants';
import { TableData } from './document/blocks/TableBlock';
import Parser from 'html-react-parser';
import { TranslationKey } from '../../../../models/translation.models';
import { RenderFileBlock } from './document/blocks/FileBlock';

export default function RenderTranslationContent({
    translationKey,
    content,
}: {
    translationKey?: TranslationKey;
    content: string | null;
}) {
    if (!translationKey) {
        return null;
    }

    const { entityType, property } = translationKey;

    if (entityType === entityTypes.BLOCK && property === 'data') {
        return <TableData content={content} translationKey={translationKey} />;
    }

    if (entityType === entityTypes.BLOCK && property === 'uri') {
        return <RenderFileBlock content={content} />;
    }

    return (
        <div className="rendered-content">
            {content && Parser(content.replaceAll(/\[(Tag |)(.*?)]/gm, (match, p1, p2) => `<mark>${p2}</mark>`))}
        </div>
    );
}
