import cx from 'classnames';
import CustomIcons from '../../../global/CustomIcons';

export default function CheckChangesHeader({ block, processBlockChanges }) {
    const { id, changes = [] } = block;

    if (changes.length === 0) {
        return null;
    }

    const hasChanges = changes.some(part => part.processed === false);
    const allAccepted = hasChanges === false && changes.every(part => part.accepted);

    return (
        <div className={cx('d-flex align-items-center check-changes-header', {
            'has-changes': hasChanges,
            'no-changes': !hasChanges,
            'accepted': !hasChanges && allAccepted,
            'rejected': !hasChanges && !allAccepted,
        })}>
            <button
                className="btn-accept uk-button uk-button-small uk-button-primary uk-margin-small-left"
                onClick={() => processBlockChanges(id, 'accept')}
                data-uk-tooltip="Wijziging accepteren"
            >
                <CustomIcons className="uk-margin-small-right" icon="acceptOne" />
                {(!hasChanges && allAccepted) ? 'Geaccepteerd!' : 'Accepteren'}
            </button>

            <button
                className="btn-reject uk-button uk-button-small uk-button-primary uk-margin-small-left"
                onClick={() => processBlockChanges(id, 'reject')}
                data-uk-tooltip="Wijziging afwijzen"
            >
                <CustomIcons className="uk-margin-small-right" icon="rejectOne" />
                {(!hasChanges && !allAccepted) ? 'Afgewezen!' : 'Afwijzen'}
            </button>
        </div>
    );
}
