import { useGetDocumentVariantPublicationsQuery } from 'features/publications/publicationApi';
import { useGetVariantIds } from 'pages/documents_v2/views/edit/variants/useGetDocumentVariantPublications';

export function useGetDocumentPublications() {
    const variantIds = useGetVariantIds();

    const { documentPublications, documentPublicationsIsLoading } = useGetDocumentVariantPublicationsQuery(variantIds, {
        selectFromResult: ({ data, isLoading, isFetching, isUninitialized }) => ({
            documentPublications: data ?? [],
            documentPublicationsIsLoading: isLoading || isFetching || isUninitialized,
        }),
        skip: variantIds.length === 0,
    });

    return { documentPublications, documentPublicationsIsLoading };
}
