import Constants from '../../config/Constants';
import { Button } from 'react-bootstrap';
import { Bookmarks } from 'react-bootstrap-icons';
import { set } from '../../features/app/appSlice';
import { useDispatch } from 'react-redux';
import RestrictedContent from '../global/RestrictedContent';
import { useTranslation } from 'react-i18next';

export default function AssignLabelButton({ context, size = 16 }) {
    const dispatch = useDispatch();
    const { t } = useTranslation('labels');

    const handleMetaClick = () => {
        dispatch(
            set({
                key: 'sidebarContext',
                value: {
                    type: 'sidebarSettings',
                    context: {
                        ...context,
                        landingTab: 'labels',
                    },
                },
            })
        );
    };

    return (
        <RestrictedContent module={Constants.modules.labels}>
            <Button
                variant="icon"
                className="flex-shrink-0 btn-icon-only btn-icon-light p-0 mx-1"
                onClick={(event) => {
                    event.stopPropagation();
                    handleMetaClick();
                }}
                data-uk-tooltip={t('assignLabels.tooltip.assignLabels')}
            >
                <Bookmarks size={size} />
            </Button>
        </RestrictedContent>
    );
}
