import { useGetDocumentVariantPublicationsQuery } from 'features/publications/publicationApi';
import { useGetBaseVariant } from 'pages/documents_v2/hooks/useGetBaseVariant';
import { reduceDeep } from 'deepdash-es/standalone';

export function useGetDocumentVariantPublications(variantId) {
    const variantIds = useGetVariantIds();

    const { publications } = useGetDocumentVariantPublicationsQuery(variantIds, {
        selectFromResult: ({ data }) => ({
            publications: data ? data.filter((publication) => publication.documentVariantIds.includes(variantId)) : [],
        }),
        skip: variantIds.length === 0,
    });

    return publications;
}

export function useGetVariantIds() {
    const baseVariant = useGetBaseVariant();

    if (!baseVariant) {
        return [];
    }

    const childVariants = getChildVariants(baseVariant);

    return childVariants ? childVariants.map((variant) => variant.id) : [];
}

export function getChildVariants(baseVariant) {
    if (!baseVariant) {
        return [];
    }

    const filtrate = reduceDeep(
        baseVariant.children,
        (acc, value) => {
            if (value.parentId === null) {
                return acc;
            }

            if (value.variantGroup === false) {
                if (acc) {
                    return [...acc, value];
                } else {
                    return [acc];
                }
            }

            return acc;
        },
        [],
        { childrenPath: 'children' }
    );

    return filtrate ?? [];
}
