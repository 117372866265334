import { useState } from 'react';
import { Nav } from 'react-bootstrap';
import MainContentNav from '../../Navbar';
import { generatePath, Link, useParams } from 'react-router-dom';
import { selectOpListById } from '../../../features/operationsList/operationsListSlice';
import { useSelector } from 'react-redux';
import SubNav from '../nav/SubNav';
import OpListPropertiesForm from '../forms/OpListProperties';
import Departments from '../department/Departments';
import OpListUsers from '../team/OpListUsers';
import Tasks from '../task/Tasks';
import Teams from '../team/Teams';
import StatusCodes from '../status_codes/StatusCodes';
import RestrictedOpListContent from '../RestrictedOpListContent';
import Constants from '../../../config/Constants';
import { BASE_PATH, SETTINGS_PATH, VIEW_PATH } from '../../../scenes/OperationsList';
import { useTranslation } from 'react-i18next';
import { InfoButton } from '../../../components/Buttons';

export default function Settings() {
    const { id, setting } = useParams();
    const opList = useSelector((state) => selectOpListById(state.operations, id));
    const { t } = useTranslation('changelist');

    return (
        <>
            <MainContentNav
                pages={[
                    { title: t('breadcrumb'), url: BASE_PATH },
                    {
                        title: opList?.name,
                        url: generatePath(VIEW_PATH, {
                            id: opList?.id,
                        }),
                    },
                    { title: t('changelist.subnav.settings.settings') },
                ]}
            />
            <SubNav />

            <div className="content-static-body has-subnav">
                <div className="d-flex align-items-stretch h-100">
                    <div className="content-sidebar overflow-auto bg-light">
                        <div className="py-4">
                            <div className="d-flex align-items-center justify-content-between mb-2 px-4">
                                <span className="text-uppercase small text-muted font-weight-light">
                                    {t('changelist.subnav.settings.settings')}
                                </span>
                            </div>

                            <Nav className="sidebar-nav flex-column">
                                <RestrictedOpListContent opListId={id} roles={[Constants.opListUserRoles.admin]}>
                                    <Link
                                        to={generatePath(SETTINGS_PATH, {
                                            id,
                                            setting: 'properties',
                                        })}
                                        className={'nav-link' + (setting === 'properties' ? ' active' : '')}
                                    >
                                        {t('changelist.subnav.settings.sidebar.properties')}
                                    </Link>
                                    <Link
                                        to={generatePath(SETTINGS_PATH, {
                                            id,
                                            setting: 'departments',
                                        })}
                                        className={'nav-link' + (setting === 'departments' ? ' active' : '')}
                                    >
                                        {t('changelist.subnav.settings.sidebar.department')}
                                    </Link>
                                </RestrictedOpListContent>

                                <RestrictedOpListContent opListId={id} roles={[Constants.opListUserRoles.teamManager]}>
                                    <Link
                                        to={generatePath(SETTINGS_PATH, {
                                            id,
                                            setting: 'teams',
                                        })}
                                        className={'nav-link' + (setting === 'teams' ? ' active' : '')}
                                    >
                                        Teams
                                    </Link>
                                    <Link
                                        to={generatePath(SETTINGS_PATH, {
                                            id,
                                            setting: 'teamMembers',
                                        })}
                                        className={'nav-link' + (setting === 'teamMembers' ? ' active' : '')}
                                    >
                                        {t('changelist.subnav.settings.sidebar.teamMembers')}
                                    </Link>
                                    <Link
                                        to={generatePath(SETTINGS_PATH, {
                                            id,
                                            setting: 'tasks',
                                        })}
                                        className={'nav-link' + (setting === 'tasks' ? ' active' : '')}
                                    >
                                        {t('changelist.subnav.settings.sidebar.tasks')}
                                    </Link>
                                    <Link
                                        to={generatePath(SETTINGS_PATH, {
                                            id,
                                            setting: 'statusCodes',
                                        })}
                                        className={'nav-link' + (setting === 'statusCodes' ? ' active' : '')}
                                    >
                                        Status codes
                                    </Link>
                                </RestrictedOpListContent>
                            </Nav>
                        </div>
                    </div>
                    <div className="flex-grow-1 overflow-auto">
                        <div id="content" className="px-5 pt-5 pb-3">
                            <Setting />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function Setting() {
    const { id, setting } = useParams();

    switch (setting) {
        case 'properties':
            return (
                <RestrictedOpListContent opListId={id} roles={[Constants.opListUserRoles.admin]}>
                    <Properties />
                </RestrictedOpListContent>
            );
        case 'departments':
            return (
                <RestrictedOpListContent opListId={id} roles={[Constants.opListUserRoles.admin]}>
                    <DepartmentSettings />
                </RestrictedOpListContent>
            );
        case 'teams':
            return (
                <RestrictedOpListContent opListId={id} roles={[Constants.opListUserRoles.teamManager]}>
                    <TeamSettings />
                </RestrictedOpListContent>
            );
        case 'teamMembers':
            return (
                <RestrictedOpListContent opListId={id} roles={[Constants.opListUserRoles.teamManager]}>
                    <OpListUsersSettings />
                </RestrictedOpListContent>
            );
        case 'tasks':
            return (
                <RestrictedOpListContent opListId={id} roles={[Constants.opListUserRoles.teamManager]}>
                    <TaskSettings />
                </RestrictedOpListContent>
            );
        case 'statusCodes':
            return (
                <RestrictedOpListContent opListId={id} roles={[Constants.opListUserRoles.teamManager]}>
                    <StatusCodeSettings />
                </RestrictedOpListContent>
            );
        default:
            return <>Setting "{setting}" is not supported.</>;
    }
}

function Properties() {
    const { t } = useTranslation('changelist');

    return (
        <>
            <div className="subheader">
                <h3>{t('changelist.subnav.settings.sidebar.properties')}</h3>
            </div>

            <OpListPropertiesForm />
        </>
    );
}

function DepartmentSettings() {
    const [showEditModal, setShowEditModal] = useState(false);
    const { t } = useTranslation('changelist');

    return (
        <>
            <div className="subheader d-flex">
                <h3>{t('changelist.subnav.settings.sidebar.department')}</h3>
                <InfoButton size="sm" className="ml-auto" onClick={() => setShowEditModal({})}>
                    {t('changelist.subnav.settings.sidebar.btn.newDepartment')}
                </InfoButton>
            </div>

            <Departments showEditModal={showEditModal} setShowEditModal={setShowEditModal} />
        </>
    );
}

function TeamSettings() {
    const [showEditModal, setShowEditModal] = useState(false);
    const { t } = useTranslation('changelist');

    return (
        <>
            <div className="subheader d-flex">
                <h3>{t('changelist.subnav.settings.sidebar.teams')}</h3>
                <InfoButton size="sm" className="ml-auto" onClick={() => setShowEditModal({})}>
                    {t('changelist.subnav.settings.sidebar.btn.newTeam')}
                </InfoButton>
            </div>

            <Teams showEditModal={showEditModal} setShowEditModal={setShowEditModal} />
        </>
    );
}

function OpListUsersSettings() {
    const [showEditModal, setShowEditModal] = useState(false);
    const { t } = useTranslation('changelist');

    return (
        <>
            <div className="subheader d-flex">
                <h3>{t('changelist.subnav.settings.sidebar.teamMembers')}</h3>
                <InfoButton size="sm" className="ml-auto" onClick={() => setShowEditModal({})}>
                    {t('changelist.subnav.settings.sidebar.btn.newMember')}
                </InfoButton>
            </div>

            <OpListUsers showEditModal={showEditModal} setShowEditModal={setShowEditModal} />
        </>
    );
}

function TaskSettings() {
    const [showEditModalTask, setShowEditModalTask] = useState(false);
    const { t } = useTranslation('changelist');

    return (
        <>
            <div className="subheader d-flex">
                <h3>{t('changelist.subnav.settings.sidebar.tasks')}</h3>
                <InfoButton size="sm" className="ml-auto" onClick={() => setShowEditModalTask({})}>
                    {t('changelist.subnav.settings.sidebar.btn.newTask')}
                </InfoButton>
            </div>

            <Tasks showEditModalTask={showEditModalTask} setShowEditModalTask={setShowEditModalTask} />
        </>
    );
}

function StatusCodeSettings() {
    return (
        <>
            {/* Header and add button are in component */}
            <StatusCodes />
        </>
    );
}
