import { useTranslation } from 'react-i18next';
import { useGetUserFullName } from 'hooks/useGetUserFullName';
import { DateTime } from 'luxon';
import { ExclamationTriangleFill, PencilSquare } from 'react-bootstrap-icons';
import { IconButton } from 'components/Buttons';
import { TaskLabels } from 'pages/task_team/modals/view_task_modal/TaskLabels';
import { useCanChangeTaskOwner, useCanEditTaskProperties, useIsTaskOwner } from 'pages/task_team/hooks/permissions';
import { StringParam, useQueryParam } from 'use-query-params';
import { taskVersionStatus } from 'pages/task_team/config/constants';
import { useState } from 'react';
import { EditProperties } from 'pages/task_team/modals/view_task_modal/EditProperties';
import { EditTags } from 'pages/task_team/modals/view_task_modal/EditTags';
import _ from 'lodash';
import { Skeleton } from 'components/Skeleton';
import cx from 'classnames';
import { EditReEvaluation } from 'pages/task_team/modals/view_task_modal/EditReEvaluation';

export function TaskProperties({ task }) {
    return (
        <div className="p-4">
            <div className="container">
                <RenderProperties task={task} />
            </div>
        </div>
    );
}

function RenderProperties({ task }) {
    const [, setChangeOwner] = useQueryParam('changeOwner', StringParam);
    const [editMode, setEditMode] = useState(false);
    const [editReEvaluation, setEditReEvaluation] = useState(false);
    const [editTags, setEditTags] = useState(false);
    const isTaskOwner = useIsTaskOwner(task);
    const canChangeTaskOwner = useCanChangeTaskOwner();
    const canEditTaskProperties = useCanEditTaskProperties(task);

    if (editMode) {
        return <EditProperties task={task} close={() => setEditMode(false)} />;
    }

    if (editReEvaluation) {
        return <EditReEvaluation task={task} close={() => setEditReEvaluation(false)} />;
    }

    if (editTags) {
        return <EditTags task={task} close={() => setEditTags(false)} />;
    }

    return (
        <div className="row">
            <div className="col">
                <SectionWrapper
                    title="Eigenaar"
                    showIcon={canChangeTaskOwner}
                    iconTooltip="Eigenaar aanpassen"
                    onIconClick={() => setChangeOwner(task?.id)}
                >
                    {task ? <TaskOwner task={task} /> : <SectionSkeleton count={2} />}
                </SectionWrapper>

                <SectionWrapper
                    title="Periodieke herbeoordeling"
                    showIcon={isTaskOwner}
                    disabled={!isTaskOwner}
                    iconTooltip="Herbeoordeling bewerken"
                    onIconClick={() => setEditReEvaluation(task?.id)}
                >
                    {task ? <TaskReEvaluation task={task} /> : <SectionSkeleton />}
                </SectionWrapper>

                <SectionWrapper
                    title="Tags"
                    showIcon={isTaskOwner}
                    disabled={task?.archived}
                    iconTooltip="Tags bewerken"
                    onIconClick={() => setEditTags(task?.id)}
                >
                    {task ? <TaskTags task={task} /> : <SectionSkeleton />}
                </SectionWrapper>
            </div>

            <div className="col">
                <SectionWrapper
                    title="Eigenschappen"
                    showIcon={false}
                    disabled={true}
                    iconTooltip="Eigenschappen bewerken"
                    onIconClick={() => setEditMode(task?.id)}
                >
                    {task ? <TaskDetails task={task} /> : <SectionSkeleton count={5} />}
                </SectionWrapper>
            </div>
        </div>
    );
}

function SectionWrapper({
    title = '',
    showIcon = true,
    disabled = false,
    iconTooltip = '',
    Icon = PencilSquare,
    onIconClick,
    children,
}) {
    return (
        <div className="mb-4">
            <div className="flex-center border-bottom mb-3 pb-1">
                <div className="font-weight-bold">{title}</div>

                {showIcon && (
                    <>
                        {disabled ? (
                            <Icon data-uk-tooltip="Bewerken nu niet mogelijk" size={14} className="text-muted ml-2" />
                        ) : (
                            <IconButton
                                className="ml-2"
                                tooltip={iconTooltip}
                                icon={<Icon size={14} />}
                                onClick={onIconClick}
                            />
                        )}
                    </>
                )}
            </div>

            <div>{children}</div>
        </div>
    );
}

function SectionSkeleton({ count = 1 }) {
    return (
        <>
            {[...Array(count)].map((e, i) => {
                return (
                    <div className="row mb-3" key={`skeleton-row-${i}`}>
                        <div className="col-5">
                            <div className="dr-label">
                                <Skeleton />
                            </div>
                        </div>
                        <div className="col-7">
                            <Skeleton />
                        </div>
                    </div>
                );
            })}
        </>
    );
}

function TaskOwner({ task }) {
    const createdByName = useGetUserFullName(task.createdBy, false);

    return (
        <>
            <RenderProperty label="Eigenaar">{createdByName}</RenderProperty>
        </>
    );
}

function TaskDetails({ task }) {
    const clientName = useGetUserFullName(task.client, false);

    const brandNames = task.brands.map((brand) => brand.name);
    const targetAudienceNames = task.targetAudiences.map((targetAudience) => targetAudience.name);
    const topicNames = task.topics.map((topic) => topic.name);

    const contentType =
        task.contentType === 'texual'
            ? 'Het betreft een correctie in een tarief, spelling & grammatica of het toevoegen van een paar woorden.'
            : 'Het betreft hier een andere wijziging.';

    return (
        <>
            <RenderProperty label="Naam">{task.title}</RenderProperty>
            <RenderProperty label="Gearchiveerd">{task.archived ? 'Ja' : 'Nee'}</RenderProperty>
            <RenderProperty label="Doel van uiting">{task.description}</RenderProperty>
            <RenderProperty label="Opdrachtgever">{clientName}</RenderProperty>
            <RenderProperty label="Type">{task.taskType?.name ?? '-'}</RenderProperty>
            <RenderProperty label="Uitdrukkelijke toestemming?">{task.explicitConsent ? 'Ja' : 'Nee'}</RenderProperty>
            <RenderProperty label="Onder embargo?">{task.confidential ? 'Ja' : 'Nee'}</RenderProperty>
            <RenderProperty label="Afdeling">{task.department?.name ?? '-'}</RenderProperty>
            <RenderProperty label="Merk(en)">{brandNames.join(', ')}</RenderProperty>
            <RenderProperty label="Doelgroep">{targetAudienceNames.join(', ')}</RenderProperty>
            <RenderProperty label="Onderwerp(en)">{topicNames.join(', ')}</RenderProperty>
            <RenderProperty label="Soort check">{contentType}</RenderProperty>
            <RenderProperty label="Klantreis">{task.customerJourney?.name ?? '-'}</RenderProperty>
        </>
    );
}

function TaskReEvaluation({ task }) {
    const { t, i18n } = useTranslation();

    const reEvaluationStartDate = _.isEmpty(task.reEvaluationStartDate)
        ? '-'
        : DateTime.fromISO(task.reEvaluationStartDate).setLocale(i18n.language).toLocaleString(DateTime.DATE_MED);

    return (
        <div>
            <RenderProperty label="Periodieke herbeoordeling">
                {task.reEvaluation
                    ? `Ja, iedere ${task.reEvaluationAmount === 1 ? 'maand' : task.reEvaluationAmount + ' maanden'}`
                    : 'Nee'}
            </RenderProperty>

            {task.reEvaluation && (
                <RenderProperty label="Datum volgende beoordeling">{reEvaluationStartDate}</RenderProperty>
            )}
        </div>
    );
}

function TaskTags({ task }) {
    return (
        <RenderProperty label="Tags">
            <TaskLabels taskId={task.id} readOnly={true} />
        </RenderProperty>
    );
}

export function RenderProperty({ label, children }) {
    return (
        <div className="row mb-3">
            <div className="col-5">
                <div className="dr-label">{label}</div>
            </div>
            <div className="col-7">{children}</div>
        </div>
    );
}

export function DeadlineWarningIcon({ task, version, className }) {
    const isTaskOwner = useIsTaskOwner(task);
    const showDeadlineWarning = showDeadlineWarningIcon();

    if (!showDeadlineWarning) {
        return null;
    }

    return (
        <ExclamationTriangleFill
            size={16}
            className={cx('text-danger', className)}
            data-uk-tooltip={'Deadline mag niet in het verleden liggen'}
        />
    );

    function showDeadlineWarningIcon() {
        // Show warning for draft and deadline < now
        if (version?.status === taskVersionStatus.STATUS_DRAFT && isTaskOwner) {
            if (!version.deadline) {
                // Deadline null/undefined
                return true;
            }

            const deadline = DateTime.fromISO(version.deadline);
            const now = DateTime.now();

            return deadline < now;
        }

        return false;
    }
}
