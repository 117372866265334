export default function PageTitle({
    marginBottom,
    marginTop,
    pageTitle,
    prefix,
    subTitle,
    subTitleColor,
    subTitleSize,
}) {
    let containerHtmlClass = 'uk-margin-large-top';
    if (marginTop !== undefined && marginTop === 'medium') {
        containerHtmlClass += ' uk-margin-medium-top';
    } else if (marginTop !== undefined && marginTop === 'none') {
        containerHtmlClass += ' uk-margin-remove-top';
    }

    if (marginBottom !== undefined && marginBottom === 'none') {
        containerHtmlClass += ' uk-margin-remove-bottom';
    } else {
        containerHtmlClass += ' uk-margin-medium-bottom';
    }
    const titleHtmlClass =
        'uk-margin-remove-top uk-text-capitalize-first' +
        (marginBottom === 'none' || (subTitle !== undefined && subTitle !== '') ? ' uk-margin-remove-bottom' : '');

    let subTitleHtmlClass = 'uk-margin-remove-bottom';
    if (subTitleSize !== undefined && subTitleSize === 'small') {
        subTitleHtmlClass += ' uk-margin-small-top uk-text-small';
    } else if (subTitleSize !== undefined && subTitleSize === 'normal') {
        subTitleHtmlClass += ' uk-margin-small-top';
    } else {
        subTitleHtmlClass += ' uk-margin-small-top uk-text-large';
    }
    if (subTitleColor === undefined || subTitleColor !== 'default') {
        subTitleHtmlClass += ' uk-text-muted';
    }

    return (
        <div className={containerHtmlClass}>
            <h1 className={titleHtmlClass}>
                {prefix !== undefined && <span className="uk-text-small">{prefix}</span>} {pageTitle}
            </h1>
            {subTitle && <p className={subTitleHtmlClass}>{subTitle}</p>}
        </div>
    );
}
