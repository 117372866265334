import { useDispatch, useSelector } from 'react-redux';
import { setFilter } from 'features/translations/translationSlice';
import { Form } from 'react-bootstrap';
import _, { debounce } from 'lodash';
import cx from 'classnames';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import Select from 'react-select';
import HelperFunctions from '../../global/HelperFunctions';
import { useParams } from 'react-router-dom';
import { filterVariants } from '../../documents/misc/_EditArea/EditAreaSidebar';
import { EditContext } from '../views/view/EditContextWrapper';
import { tinyMceOptions } from '../../documents/misc/_BlockParser/BasicEditor';
import { TranslationContext } from '../views/translate/Translate';
import CrossRefModal from '../../documents/misc/_BlockParser/CrossRefModal';
import { InputField } from '../../publications_v2/helpers/FieldHelper';
import { findDeep } from 'deepdash-es/standalone';
import { setFilterDoc } from 'features/documents/documentsSlice';
import { useTranslation } from 'react-i18next';
import { Editor } from 'components/Editor';

export const FilterField = ({ label, name }) => {
    const values = useSelector((state) => state.translation.sidebarFilters);
    const dispatch = useDispatch();
    const { resetVisibleEntities } = useContext(TranslationContext);

    function setFieldValue(key, value) {
        resetVisibleEntities();
        dispatch(setFilter({ key, value }));
    }

    return (
        <div className="mb-2">
            <Form.Check
                id={name}
                name={name}
                checked={_.get(values, name) ?? false}
                label={label}
                onChange={(event) => {
                    setFieldValue(name, event.target.checked);
                }}
            />
        </div>
    );
};

export const FilterFieldDoc = ({ label, name }) => {
    const values = useSelector((state) => state.documents.sidebarFilters);
    const dispatch = useDispatch();

    function setFieldValue(key, value) {
        dispatch(setFilterDoc({ key, value }));
    }

    return (
        <div className="mb-2">
            <Form.Check
                custom
                id={name}
                name={name}
                checked={_.get(values, name) ?? false}
                label={label}
                onChange={(event) => {
                    setFieldValue(name, event.target.checked);
                }}
            />
        </div>
    );
};

export const FilterSearch = ({ placeholder = '' }) => {
    const { search, setSearch } = useContext(EditContext);

    const handleChange = (event) => {
        setSearch(event.target.value);
    };

    const debouncedResults = useMemo(() => {
        return debounce(handleChange, 300);
    }, []);

    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    });

    return (
        <Form.Control
            type="search"
            className={cx('nav-form-search', {
                'has-content': search !== '',
            })}
            placeholder={placeholder}
            onChange={debouncedResults}
        />
    );
};

export const FilterVariantSelect = ({ name, baseVariant = {} }) => {
    const values = useSelector((state) => state.translation.sidebarFilters);
    const dispatch = useDispatch();
    const { entityType } = useParams();

    let documentVariants = baseVariant?.children ?? [];

    if (entityType === 'area' || entityType === 'section') {
        // Limit the list of variants based on the entity
        if (values.entity.hasOwnProperty('documentVariants')) {
            documentVariants = filterVariants(baseVariant.children, values.entity.documentVariants);
        }
    }

    const localVariants = [];
    const filtered = HelperFunctions.flatten(documentVariants);

    filtered
        .filter((_variant) => _variant.parentId === baseVariant.id || _variant.variantGroup)
        .forEach((_variant) => {
            if (_variant.variantGroup) {
                localVariants.push({
                    label: (
                        <>
                            {_variant.prefix && <span>{_variant.prefix}&nbsp;</span>}
                            <span>{_variant.name}</span>
                        </>
                    ),
                    options: filtered
                        .filter(
                            (_childVariant) =>
                                _childVariant.parentId === _variant.id && _childVariant.variantGroup === false,
                        )
                        .map((_childVariant) => {
                            return {
                                label: (
                                    <>
                                        &nbsp;&nbsp;&nbsp;
                                        {_childVariant.prefix && (
                                            <span className="text-secondary">{_childVariant.prefix}&nbsp;</span>
                                        )}
                                        <span>{_childVariant.name}</span>
                                    </>
                                ),
                                value: _childVariant.id,
                                variant: {
                                    prefix: _childVariant.prefix ?? '',
                                    name: _childVariant.name,
                                },
                            };
                        }),
                });
            } else {
                localVariants.push({
                    label: (
                        <>
                            {_variant.prefix && <span className="text-secondary">{_variant.prefix}&nbsp;</span>}
                            <span>{_variant.name}</span>
                        </>
                    ),
                    value: _variant.id,
                    variant: {
                        prefix: _variant.prefix ?? '',
                        name: _variant.name,
                    },
                });
            }
        });

    return (
        <div className="mt-3 mr-2">
            <VariantViewButtonsSelect
                variants={localVariants}
                activeVariantId={_.get(values, name)}
                switchVariantView={(option) => {
                    dispatch(setFilter({ key: name, value: option }));
                }}
            />
        </div>
    );
};

function VariantViewButtonsSelect({ variants = [], activeVariantId, switchVariantView }) {
    const { t } = useTranslation('translations');

    const filterOptions = ({ label, value, data }, input) => {
        if (_.isEmpty(input)) {
            return true;
        }

        const { variant } = data;
        const title = variant.prefix + variant.name;

        return title.toLowerCase().includes(input.toLowerCase());
    };

    let selectedOption = '';

    if (activeVariantId !== 0) {
        const option = findDeep(
            variants,
            (value) => {
                const option = value;

                if (option.hasOwnProperty('value') === false) {
                    return undefined;
                }

                return option.value === activeVariantId;
            },
            {
                childrenPath: 'options',
            },
        );

        if (option) {
            selectedOption = option.value;
        }
    }

    return (
        <Select
            isClearable
            styles={customStyles}
            options={variants}
            value={selectedOption}
            placeholder={`${t('translation.navbar.dashboard.document.titleSelect')}...`}
            onChange={(item) => {
                if (item !== null && item.value !== activeVariantId) {
                    switchVariantView(item.value);
                } else if (item === null) {
                    switchVariantView(0);
                }
            }}
            classNamePrefix="variant-view"
            filterOption={filterOptions}
            theme={(theme) => {
                return {
                    ...theme,
                    borderRadius: 0,
                };
            }}
        />
    );
}

export function ReimbursementFilterSelect({ options = [] }) {
    const dispatch = useDispatch();
    const values = useSelector((state) => state.translation.sidebarFilters);

    const handleChange = (option) => {
        dispatch(setFilter({ key: 'reimbursement', value: option }));
    };

    const filterOptions = ({ label, value, data }, input) => {
        if (_.isEmpty(input)) {
            return true;
        }

        const title = data.code + data.name;

        return title.toLowerCase().includes(input.toLowerCase());
    };

    return (
        <div className="mt-3 mr-2">
            <Select
                isClearable
                styles={customStyles}
                options={options}
                value={options.find((reimbursement) => reimbursement['@id'] === values.reimbursement) ?? null}
                placeholder="Selecteer vergoeding..."
                onChange={(item) => {
                    if (item !== null) {
                        handleChange(item['@id']);
                    } else {
                        handleChange(null);
                    }
                }}
                classNamePrefix="variant-view"
                filterOption={filterOptions}
                theme={(theme) => {
                    return {
                        ...theme,
                        borderRadius: 0,
                    };
                }}
            />
        </div>
    );
}

const customStyles = {
    groupHeading: (provided) => ({
        ...provided,
        color: '#3c7fcc',
        fontWeight: 'bold',
        paddingTop: 4,
        paddingBottom: 4,
    }),
};

export const HtmlInputField = ({ name, tags = [] }) => {
    const { setFieldValue, values } = useFormikContext();

    return (
        <HtmlEditor
            initialValue={values[name]}
            name={name}
            tags={tags.map((_tag) => ({
                name: _tag.replace(/&#61;/g, '='),
            }))}
            onEditorChange={(value) => {
                setFieldValue(name, value);
            }}
        />
    );
};

export function TextInputField({ name }) {
    return (
        <InputField
            name={name}
            props={{
                autoFocus: true,
            }}
        />
    );
}

export const HtmlEditor = ({ name, onEditorChange, initialValue = '', tags = [] }) => {
    const [options, setOptions] = useState(false);
    const [showCrossRefModal, setShowCrossRefModal] = useState(false);
    const editorRef = useRef(null);

    const toggleCrossRefModal = () => {
        setShowCrossRefModal(!showCrossRefModal);
    };

    useEffect(() => {
        if (options === false) {
            setOptions(tinyMceOptions(tags, true, toggleCrossRefModal));
        }
    });

    if (options === false) {
        return null;
    }

    return (
        <>
            <Editor
                id={'jsTinymceEditor' + name}
                init={options}
                value={initialValue}
                onEditorChange={onEditorChange}
                onInit={(event, editor) => {
                    editorRef.current = editor;
                }}
            />

            {showCrossRefModal && (
                <CrossRefModal editor={editorRef.current} toggleCrossRefModal={toggleCrossRefModal} />
            )}
        </>
    );
};
