import _ from 'lodash';

export function resolveTeamEntryLabelName(labelLut, opEntryLabel) {
    const labelIri = opEntryLabel.label;
    const label = _.get(labelLut, labelIri);
    if (!label) {
        return null;
    }

    // By default use the name of the label. If the label has been linked via the generalLabel
    // Prefix the label name by the name of the generalLabel. (e.g. ABC -> ALG-ABC)
    let labelName = label.name;
    if (opEntryLabel.linkedGeneralLabel) {
        const generalLabel = _.get(labelLut, opEntryLabel.linkedGeneralLabel)
        if (generalLabel) {
            labelName = `${generalLabel.name}-${labelName}`;
        }
    }

    return labelName;
}