import { Nav } from 'react-bootstrap';
import { generatePath, Link, useParams } from 'react-router-dom';
import cx from 'classnames';
import { VIEW_PATH } from '../../../../scenes/Translations';
import { useTranslation } from 'react-i18next';

export default function Sidebar() {
    const { translationContainerId, detail = 'general' } = useParams();
    const { t } = useTranslation('translations');

    const availableSettings = [
        {
            id: 'general',
            name: t('translation.navbar.settings.contentSidebar.properties.title'),
        },
        {
            id: 'team',
            name: t('translation.navbar.settings.contentSidebar.team.title'),
        },
        {
            id: 'languages',
            name: t('translation.navbar.settings.contentSidebar.languages.title'),
        },
    ];

    return (
        <div className="py-4">
            <div className="d-flex align-items-center justify-content-between mb-2 px-4">
                <span className="text-uppercase small text-muted font-weight-light">
                    {t('translation.navbar.settings.contentSidebar.title')}
                </span>
            </div>

            <Nav className="sidebar-nav flex-column">
                {availableSettings.map((_setting, index) => (
                    <Link
                        to={generatePath(VIEW_PATH, {
                            translationContainerId,
                            view: 'properties',
                            detail: _setting.id,
                        })}
                        className={cx('nav-link', {
                            active: detail === _setting.id,
                        })}
                        key={`sidebar-item-${index}`}
                    >
                        {_setting.name}
                    </Link>
                ))}
            </Nav>
        </div>
    );
}
