import { useCurrentOrganisation } from 'hooks/useCurrentOrganisation';
import _ from 'lodash';
import { STATUS_ACTIVE } from 'pages/account/views/Billing';

/**
 * @returns {'active'|'canceled'|'incomplete'|'incomplete_expired'|'past_due'|'paused'|'trialing'|'unpaid'}
 */
export function useGetSubscriptionStatus() {
    const organisation = useCurrentOrganisation();

    return _.get(organisation, 'account.subscriptionStatus', STATUS_ACTIVE);
}
