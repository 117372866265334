import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { StringParam, useQueryParam } from 'use-query-params';
import { selectFileById } from '../../../../features/mediaLibrary/mediaLibrarySlice';
import { useSelector } from 'react-redux';

export function FileModal() {
    const { t } = useTranslation('media');
    const [view, setView] = useQueryParam('view', StringParam);
    const file = useSelector((state) => selectFileById(state.mediaLibrary, view));

    const onClose = () => {
        setView(undefined);
    };

    if (!file) {
        return undefined;
    }

    return (
        <Modal show={true} onHide={onClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>{t('fileModal.title', { name: file.displayName })}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <img src={file.url} alt={file.displayName} />
            </Modal.Body>
        </Modal>
    );
}
