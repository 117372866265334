import Constants from '../../../config/Constants';

export const teamUserRoles = [
    {
        label: 'settings.team.roles.admin',
        value: Constants.reimbursementTeamRoles.admin,
    },
    {
        label: 'settings.team.roles.manager',
        value: Constants.reimbursementTeamRoles.manager,
    },
    {
        label: 'settings.team.roles.finalEditor',
        value: Constants.reimbursementTeamRoles.finalEditor,
    },
    {
        label: 'settings.team.roles.editor',
        value: Constants.reimbursementTeamRoles.editor,
    },
    {
        label: 'settings.team.roles.spectator',
        value: Constants.reimbursementTeamRoles.spectator,
    },
];
