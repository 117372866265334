import { useGetPublicationGroupsQuery } from '../../../features/publications/publicationApi';
import HelperFunctions from '../../global/HelperFunctions';
import { useActiveOrganisation } from '../../../hooks/useActiveOrganisation';

const emptyArray = [];

export function useGetPublicationGroups(returnAsNested = true) {
    const activeOrganisation = useActiveOrganisation();

    const { publicationGroups } = useGetPublicationGroupsQuery(undefined, {
        selectFromResult: ({ data }) => ({
            publicationGroups: data ?? emptyArray,
        }),
    });

    if (returnAsNested) {
        return publicationGroups
            .filter((_group) => _group.organisationId === activeOrganisation)
            .filter((_group) => _group.parent === null)
            .map((_group) => ({
                ..._group,
                children: _group.children.map((childUri) =>
                    publicationGroups.find((_item) => _item['@id'] === childUri)
                ),
            }))
            .sort(HelperFunctions.dynamicSort('sortOrder'));
    }

    return publicationGroups;
}
