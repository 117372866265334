import { Translation } from '../../../../models/translation.models';
import _ from 'lodash';
import striptags from 'striptags';
import React from 'react';
import { useGetTranslationsQuery } from '../../../../features/translations/translationApi';
import { Check2Circle, DashCircle, ExclamationCircle } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

function TranslationKeyCardTitle({
    title,
    translationKeyUri,
    languageIso,
    enabledForTranslation,
}: {
    title: string;
    translationKeyUri: string;
    languageIso: string;
    enabledForTranslation: boolean;
}) {
    return (
        <div>
            <div className="text-title mb-1" style={{ fontSize: 14 }}>
                {_.truncate(striptags(title), {
                    length: 75,
                })}
            </div>
            <div className="font-weight-normal small">
                <TranslationKeyStatus
                    translationKeyUri={translationKeyUri}
                    enabledForTranslation={enabledForTranslation}
                    languageIso={languageIso}
                />
            </div>
        </div>
    );
}

function TranslationKeyStatus({
    translationKeyUri,
    enabledForTranslation,
    languageIso,
}: {
    translationKeyUri: string;
    enabledForTranslation: boolean;
    languageIso: string;
}) {
    const { t } = useTranslation('translations');

    const {
        translation,
        isLoading,
        isUninitialized,
    }: { translation: Translation | undefined; isLoading: boolean; isUninitialized: boolean } = useGetTranslationsQuery(
        translationKeyUri,
        {
            selectFromResult: ({ data, isLoading, isUninitialized }) => {
                return {
                    translation: data?.find((_translation: Translation) => _translation.languageIso === languageIso),
                    isLoading,
                    isUninitialized,
                };
            },
            skip: !enabledForTranslation,
        }
    );

    if (!enabledForTranslation) {
        return (
            <div className="d-flex align-items-center text-secondary">
                <DashCircle className="mr-1" />
                <div>{t('translation.navbar.dashboard.document.status.excluded')}</div>
            </div>
        );
    }

    if (isLoading || isUninitialized) {
        return null;
    }

    if (translation === undefined || translation.unverified) {
        return (
            <div className="d-flex align-items-center">
                <div className="d-flex align-items-center text-danger">
                    <ExclamationCircle size={14} className="mr-1" />
                    <div>{t('translation.navbar.dashboard.document.status.notYetReady')}</div>
                </div>
            </div>
        );
    }

    return (
        <div className="d-flex align-items-center text-success">
            <Check2Circle size={14} className="mr-1" />
            <div>{t('translation.navbar.dashboard.document.status.translationComplete')}</div>
        </div>
    );
}

export const MemoizedTranslationKeyCardTitle = React.memo(TranslationKeyCardTitle);
