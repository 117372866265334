import { useSelector } from 'react-redux';
import { selectFiles, selectFolders } from '../../../../features/mediaLibrary/mediaLibrarySlice';
import Files from './Files';
import React from 'react';
import { Folder as FolderIcon, TrashFill } from 'react-bootstrap-icons';
import Droppable from '../Droppable';
import { useTranslation } from 'react-i18next';

export default function Folder({ folder, toggleSelection, setSelectedFiles, selectedFiles, isDragging }) {
    const childFolders = useSelector(selectFolders(folder['@id']));
    const files = useSelector(selectFiles(folder['@id']));
    const { t } = useTranslation('documents');

    // Root folder?
    if (folder.parentFolder === null) {
        if (childFolders.length === 0) {
            return null;
        }

        return (
            <>
                <div
                    className="text-darkBlue text-uppercase mb-3 mt-4 font-size-lg font-weight-bold"
                    style={{ fontSize: '1rem' }}
                >
                    {folder.name}
                </div>

                {childFolders.map((_childFolder) => (
                    <Folder
                        folder={_childFolder}
                        toggleSelection={toggleSelection}
                        selectedFiles={selectedFiles}
                        setSelectedFiles={setSelectedFiles}
                        isDragging={isDragging}
                        key={`media-folder-index-${_childFolder.id}`}
                    />
                ))}
            </>
        );
    }

    return (
        <>
            <FolderHeader name={folder.name} />

            <Droppable id={folder['@id']} isDragging={isDragging}>
                {files.length > 0 && (
                    <Files
                        files={files}
                        toggleSelection={toggleSelection}
                        selectedFiles={selectedFiles}
                        setSelectedFiles={setSelectedFiles}
                    />
                )}

                {files.length === 0 && isDragging === false && (
                    <div className="text-muted small">
                        {t('document.navbar.main.editor.left.blocks.types.fileBlock.noFiles')}
                    </div>
                )}
            </Droppable>
        </>
    );
}

export function FolderHeader({ name }) {
    return (
        <div className="d-flex align-items-center mb-3 mt-4" style={{ fontSize: '0.75rem' }}>
            {name === 'Prullenbak' ? (
                <TrashFill size={16} className="mr-2" />
            ) : (
                <FolderIcon size={16} className="mr-2" />
            )}
            <div className="font-weight-500">{name}</div>
        </div>
    );
}
