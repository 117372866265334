import React from 'react';
import HelperFunctions from '../../../global/HelperFunctions';
import { DateTime } from 'luxon';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useGetUserFullName } from '../../../../hooks/useGetUserFullName';

export default function AreaHistory({ activeBlockUpdateGroup, history = [], switchVersionHistory }) {
    const activeVersionIsMostRecent = activeBlockUpdateGroup === 0;
    const { t } = useTranslation('documents');
    let activeVersionPeriod = '';

    if (!activeVersionIsMostRecent) {
        const activeVersion = HelperFunctions.getByValue(history, 'endGroupId', activeBlockUpdateGroup);

        if (activeVersion) {
            const startDate = DateTime.fromSeconds(activeVersion.startDate).toLocaleString(DateTime.DATE_MED);
            const startTime = DateTime.fromSeconds(activeVersion.startDate).toLocaleString(DateTime.TIME_24_SIMPLE);

            const endDate = DateTime.fromSeconds(activeVersion.endDate).toLocaleString(DateTime.DATE_MED);
            const endTime = DateTime.fromSeconds(activeVersion.endDate).toLocaleString(DateTime.TIME_24_SIMPLE);

            activeVersionPeriod = startDate + ' ' + startTime;
            activeVersionPeriod +=
                startDate !== endDate || startTime !== endTime
                    ? ' ' + t('document.navbar.main.editor.right.RightSideViewSwitch.until')
                    : '';
            activeVersionPeriod += startDate !== endDate ? ' ' + endDate : '';
            activeVersionPeriod += startTime !== endTime ? ' ' + endTime : '';
        }
    }

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
        </a>
    ));

    return (
        <div className="pt-2">
            <div className="d-flex mb-1">
                <div className="mr-1">{t('document.navbar.main.editor.right.RightSideViewSwitch.history')}:</div>

                <Dropdown>
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-history">
                        {activeVersionIsMostRecent
                            ? t('document.navbar.main.editor.right.RightSideViewSwitch.currentVersion')
                            : activeVersionPeriod}
                        <span data-uk-icon="icon: triangle-down" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item
                            active={activeVersionIsMostRecent}
                            style={{
                                backgroundColor: activeVersionIsMostRecent ? '#c9dcf1' : undefined,
                            }}
                            onSelect={() => switchVersionHistory(0, 0)}
                        >
                            {t('document.navbar.main.editor.right.RightSideViewSwitch.currentVersion')}
                        </Dropdown.Item>

                        <Dropdown.Divider />

                        {history.map((version, index) => (
                            <HistoryItem
                                activeBlockUpdateGroup={activeBlockUpdateGroup}
                                version={version}
                                switchVersionHistory={switchVersionHistory}
                                key={`history-${index}`}
                            />
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            {!activeVersionIsMostRecent && (
                <ActiveVersion history={history} activeBlockUpdateGroup={activeBlockUpdateGroup} />
            )}
        </div>
    );
}

function HistoryItem({ activeBlockUpdateGroup, version, switchVersionHistory }) {
    const creator = useGetUserFullName(version.createdBy, false);
    const { t } = useTranslation('documents');

    // Start date
    const startDate = DateTime.fromSeconds(version.startDate).toLocaleString(DateTime.DATE_MED);
    const startTime = DateTime.fromSeconds(version.startDate).toLocaleString(DateTime.TIME_24_SIMPLE);

    // End date
    const endDate = DateTime.fromSeconds(version.endDate).toLocaleString(DateTime.DATE_MED);
    const endTime = DateTime.fromSeconds(version.endDate).toLocaleString(DateTime.TIME_24_SIMPLE);

    return (
        <Dropdown.Item
            active={activeBlockUpdateGroup === version.endGroupId}
            onSelect={() => switchVersionHistory(version.startGroupId, version.endGroupId)}
            style={{
                backgroundColor: activeBlockUpdateGroup === version.endGroupId ? '#c9dcf1' : undefined,
            }}
        >
            <span>
                {startDate} {startTime}
                {(startDate !== endDate || startTime !== endTime) &&
                    t('document.navbar.main.editor.right.RightSideViewSwitch.until')}
                {startDate !== endDate && endDate} {startTime !== endTime && endTime}
            </span>
            <br />
            <span className="uk-comment-meta small">
                {creator} {version?.numberOfEdits > 0 || version?.numberOfChecks > 0 ? ':' : ''}
                {version?.numberOfEdits > 0 && (
                    <>
                        &nbsp;
                        {version?.numberOfEdits}
                        &nbsp;
                        {t(
                            version?.numberOfEdits === 1
                                ? t('document.navbar.main.editor.right.RightSideViewSwitch.changeSingular')
                                : t('document.navbar.main.editor.right.RightSideViewSwitch.changesPlural')
                        )}
                        &nbsp;
                        {t('document.navbar.main.editor.right.RightSideViewSwitch.suggested')}
                        &nbsp;
                    </>
                )}
                {version.numberOfChecks > 0 && (
                    <>
                        &nbsp;
                        {version?.numberOfChecks}
                        &nbsp;
                        {t(
                            version?.numberOfChecks === 1
                                ? 'document.navbar.main.editor.right.RightSideViewSwitch.changeProcessedSingular'
                                : 'document.navbar.main.editor.right.RightSideViewSwitch.changeProcessedPlural'
                        )}
                        &nbsp;
                    </>
                )}
            </span>
        </Dropdown.Item>
    );
}

function ActiveVersion({ activeBlockUpdateGroup, history = [] }) {
    const activeVersion = HelperFunctions.getByValue(history, 'endGroupId', activeBlockUpdateGroup);
    const activeVersionUser = useGetUserFullName(activeVersion?.createdBy, false);
    const { t } = useTranslation('documents');

    return (
        <div className="small">
            {t('document.navbar.main.editor.right.RightSideViewSwitch.byUser')}:&nbsp;{activeVersionUser}, &nbsp;
            {activeVersion?.numberOfEdits}
            &nbsp;
            {t(
                activeVersion?.numberOfEdits === 1
                    ? t('document.navbar.main.editor.right.RightSideViewSwitch.changeSingular')
                    : t('document.navbar.main.editor.right.RightSideViewSwitch.changesPlural')
            )}
            &nbsp;
            {t('document.navbar.main.editor.right.RightSideViewSwitch.suggested')}
            ,&nbsp;
            {activeVersion?.numberOfChecks}
            &nbsp;
            {t(
                activeVersion?.numberOfChecks === 1
                    ? 'document.navbar.main.editor.right.RightSideViewSwitch.changeProcessedSingular'
                    : 'document.navbar.main.editor.right.RightSideViewSwitch.changeProcessedPlural'
            )}
            &nbsp;
        </div>
    );
}
