import { useDispatch } from 'react-redux';
import { Button, Card, Form } from 'react-bootstrap';
import { Field, Form as FForm, Formik, useFormikContext } from 'formik';
import { patchOpEntryLabel } from '../../../../features/operationsList/operationsListSlice';
import { LoadingSpinner } from '../../../global/Spinner';
import TaskHistory from '../../task/TaskHistory';
import _ from 'lodash';
import { WarningButton } from '../../../../components/Buttons';
import { useTranslation } from 'react-i18next';

export function TaskFields({ opEntryLabel, notDeletedFields, task }) {
    const initialValues = {};
    const dispatch = useDispatch();
    const { t } = useTranslation('changelist');

    if (notDeletedFields.length <= 0) {
        return null;
    }

    notDeletedFields.forEach((field) => {
        initialValues[field['@id']] = '';

        // PrimaryQuestion
        if (field.type === 'select') {
            initialValues[field['@id']] = field.options[0];
        }
    });

    return (
        <Card.Body>
            <Formik
                enableReinitialize
                initialValues={{
                    ...initialValues,
                    ...opEntryLabel.fieldValues,
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    await dispatch(
                        patchOpEntryLabel({
                            uri: opEntryLabel['@id'],
                            formData: {
                                fieldValues: {
                                    ...opEntryLabel.fieldValues,
                                    ...values,
                                },
                            },
                        })
                    );

                    setSubmitting(false);
                }}
            >
                {({ isSubmitting, dirty, values }) => {
                    return (
                        <FForm autoComplete="off">
                            {notDeletedFields.map((field) => {
                                const required = isFieldRequired(field, values);

                                return (
                                    <Form.Group key={field['@id']}>
                                        <Form.Label htmlFor={field['@id']}>
                                            {field.label}
                                            {required && <>&nbsp;*</>}
                                        </Form.Label>
                                        <TaskField field={field} isRequired={required} />
                                    </Form.Group>
                                );
                            })}

                            <WarningButton
                                type="submit"
                                disabled={isSubmitting || !formValid(notDeletedFields, opEntryLabel, values) || !dirty}
                            >
                                {t('btn.save')}
                            </WarningButton>
                            <LoadingSpinner isSubmitting={isSubmitting} />
                        </FForm>
                    );
                }}
            </Formik>

            <TaskHistory opEntryLabel={opEntryLabel} task={task} />
        </Card.Body>
    );
}

function TaskField({ field, isRequired = false }) {
    const { type, options } = field;
    const { values } = useFormikContext();

    const taskFieldValue = values[field['@id']];

    if (type === 'text') {
        return (
            <Field
                as={Form.Control}
                id={field['@id']}
                name={field['@id']}
                type="text"
                className={isRequired && _.isEmpty(taskFieldValue) ? 'form-control is-invalid' : 'form-control'}
            />
        );
    }

    if (type === 'textarea') {
        return (
            <Field
                as="textarea"
                className={isRequired && _.isEmpty(taskFieldValue) ? 'form-control is-invalid' : 'form-control'}
                id={field['@id']}
                name={field['@id']}
                rows={options.rows ?? 3}
            />
        );
    }

    if (type === 'select') {
        const selectOptions = options.map((key) => (
            <option value={key} key={`field_${field.id}_option_${key}`}>
                {key}
            </option>
        ));

        return (
            <Field as="select" className="form-control" id={field['@id']} name={field['@id']}>
                {selectOptions}
            </Field>
        );
    }

    return <div>Field type '{type}' is not supported</div>;
}

export const primaryQuestionAnswer = (notDeletedFields, opEntryLabel) => {
    const { fieldValues = {} } = opEntryLabel;
    const primaryQuestionField = notDeletedFields[0];

    if (!primaryQuestionField) {
        return '';
    }

    return fieldValues[primaryQuestionField['@id']] ?? primaryQuestionField.options[0];
};

const formValid = (notDeletedFields, opEntryLabel, values) => {
    const answer = primaryQuestionAnswer(notDeletedFields, opEntryLabel);

    // Primary question always needs an answer
    if (answer === '') {
        return false;
    }

    // Primary question has been answered.
    // Required restrictions are only for the status not for the actual form
    return true;
};

export const isFieldRequired = (field, fieldValues) => {
    const { requiredConditions = [] } = field;

    if (field.required === true && requiredConditions.length === 0) {
        return true;
    }

    if (requiredConditions.length > 0) {
        return requiredConditions.some((_condition) => {
            return fieldValues[_condition.field] === _condition.value;
        });
    }

    return false;
};
