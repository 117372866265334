import { ReactNode, useMemo } from 'react';
import { reimbursementApi } from '../../features/reimbursements/reimbursements';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { Category, TeamUser } from '../../models/reimbursement.models';
import { useAppSelector } from '../../store';
import { UserProfile } from '../../models/auth.models';

export default function RestrictedReimbursementContent({
    categoryId,
    roles = [],
    children,
}: {
    categoryId?: number;
    roles: string[];
    children: ReactNode;
}) {
    const params: { categoryId: string } = useParams();
    const id = categoryId ?? parseInt(params.categoryId);
    const currentTeamUser: TeamUser | undefined = useCurrentTeamUser(id);

    const hasPermissions = () => {
        return currentTeamUser && roles.includes(currentTeamUser.role);
    };

    if (_.isNil(id) || currentTeamUser === undefined || !hasPermissions()) {
        return null;
    }

    return <>{children}</>;
}

export function useCurrentRole(categoryId: number) {
    const currentTeamUser = useCurrentTeamUser(categoryId);

    return currentTeamUser?.role ?? '';
}

function useCurrentTeamUser(categoryId: number) {
    const { userProfile } = useAppSelector<{ userProfile: UserProfile | boolean }>((state) => state.security);

    const selectCategoryById = useMemo(() => {
        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (data: Category[] | undefined) => data,
            (data: Category[] | undefined, categoryId: number) => categoryId,
            (data: Category[] | undefined, categoryId: number) =>
                data ? data.find((category) => category.id === categoryId) : undefined
        );
    }, []);

    const { category }: { category: Category | undefined } = reimbursementApi.endpoints.getCategories.useQueryState(
        undefined,
        {
            selectFromResult: (result) => {
                return {
                    category: selectCategoryById(result?.data, categoryId),
                };
            },
        }
    );

    return category && typeof userProfile !== 'boolean'
        ? category.teamUsers.find((_teamUser) => _teamUser.userId === userProfile.userId)
        : undefined;
}
