import { Alert, Card, ProgressBar } from 'react-bootstrap';
import { Check } from 'react-bootstrap-icons';
import { generatePath, Link } from 'react-router-dom';
import { SETTINGS_PATH } from 'scenes/Account';
import { useGetSubscription } from 'pages/account/views/billing/useGetSubscription';
import { DateTime } from 'luxon';
import { Trans, useTranslation } from 'react-i18next';
import { STATUS_PAUSED, STATUS_TRIALING } from 'pages/account/views/Billing';
import { PaymentInformation } from 'pages/account/views/billing/PaymentInformation';
import getEnv from 'config/Env';
import { Branding } from 'pages/account/views/billing/Branding';
import { useCurrentOrganisation } from 'hooks/useCurrentOrganisation';
import _ from 'lodash';
import { useGetPublications } from 'pages/publications_v2/hooks/useGetPublications';

export function CurrentPlan() {
    return (
        <>
            <PaymentInformation />
            <CurrentPlanDetails />
            <Branding />
        </>
    );
}

function CurrentPlanDetails() {
    const metaTitle = getEnv('META_TITLE');
    const { t } = useTranslation('users');
    const { i18n } = useTranslation();
    const subscriptionResult = useGetSubscription();
    const { currentPlan, status, trial_end, default_payment_method } = subscriptionResult;
    const organisation = useCurrentOrganisation();

    const hasPlan = currentPlan && currentPlan.hasOwnProperty('id');
    const subscriptionName = _.get(organisation, 'account.subscriptionName', '');

    return (
        <div className="pt-4">
            <div className="subheader">
                <h3>{t('profile.billing.currentPlan')}</h3>
            </div>

            {status === STATUS_TRIALING && default_payment_method === null && (
                <Alert variant="danger">
                    {t('profile.billing.noPaymentWarning', {
                        product: metaTitle,
                    })}
                </Alert>
            )}

            {status === STATUS_PAUSED && <Alert variant="danger">{t('profile.billing.inactiveSubscription')}</Alert>}

            <Card>
                {hasPlan ? (
                    <Card.Header className="bg-light">
                        <div>{currentPlan.name}</div>
                        <div className="font-weight-normal text-muted small">{currentPlan.description}</div>
                    </Card.Header>
                ) : (
                    <Card.Header className="bg-light">
                        <div>{subscriptionName}</div>
                    </Card.Header>
                )}

                <ul className="list-group list-group-flush">
                    {hasPlan && currentPlan.features && (
                        <li className="list-group-item">
                            {currentPlan.features.map((feature, index) => (
                                <div className="d-flex align-items-center mb-1" key={`feature-item-${index}`}>
                                    <Check size={20} className="text-success mr-1" />
                                    {feature.name}
                                </div>
                            ))}
                        </li>
                    )}
                    {hasPlan && status === STATUS_TRIALING && default_payment_method === null && (
                        <li className="list-group-item">
                            <Alert variant="warning" className="mb-0">
                                <Trans
                                    ns="users"
                                    i18nKey="profile.billing.trialEnds"
                                    values={{
                                        date: DateTime.fromSeconds(trial_end).setLocale(i18n.language).toRelative(),
                                    }}
                                    components={{ bold: <strong /> }}
                                />
                            </Alert>
                        </li>
                    )}

                    <SeatsUsage />
                    <PublicationsUsage />

                    {hasPlan && (
                        <li className="list-group-item">
                            <div className="py-2">
                                <Link
                                    to={generatePath(SETTINGS_PATH, {
                                        view: 'billing',
                                        action: 'seats',
                                    })}
                                >
                                    {t('profile.billing.addSeat')}
                                </Link>
                            </div>
                        </li>
                    )}
                </ul>
            </Card>
        </div>
    );
}

function SeatsUsage() {
    const { t } = useTranslation();
    const organisation = useCurrentOrganisation();

    const maxSeats = _.get(organisation, 'account.seats', 5);
    const seatsInUse = _.get(organisation, 'account.seatsInUse', 1);

    return (
        <li className="list-group-item">
            <div className="py-2">
                <div className="d-flex justify-content-between mb-1">
                    <div className="font-weight-bold">{t('users:profile.billing.seats')}</div>
                    <div>
                        <Trans
                            i18nKey="users:profile.billing.numberOfSeatsUsed"
                            values={{
                                count: seatsInUse,
                                total: maxSeats,
                            }}
                            components={{ bold: <strong /> }}
                        />
                    </div>
                </div>

                <ProgressBar style={{ height: 6 }} now={seatsInUse} max={maxSeats} />

                <div className="small text-muted mt-1">
                    {t('users:profile.billing.numberOfSeats', {
                        count: seatsInUse,
                    })}
                </div>
            </div>
        </li>
    );
}

function PublicationsUsage() {
    const { t } = useTranslation();
    const organisation = useCurrentOrganisation();
    const allPublications = useGetPublications();

    const numberOfPublications = allPublications.length;
    const maxPublications = _.get(organisation, 'account.maxPublications', 5);

    return (
        <li className="list-group-item">
            <div className="py-2">
                <div className="d-flex justify-content-between mb-1">
                    <div className="font-weight-bold">{t('users:profile.billing.publications')}</div>
                    <div>
                        <Trans
                            i18nKey="users:profile.billing.numberOfPublicationsUsed"
                            values={{
                                count: numberOfPublications,
                                total: maxPublications,
                            }}
                            components={{ bold: <strong /> }}
                        />
                    </div>
                </div>

                <ProgressBar style={{ height: 6 }} now={numberOfPublications} max={maxPublications} />

                <div className="small text-muted mt-1">
                    {t('users:profile.billing.numberOfPublications', {
                        count: numberOfPublications,
                    })}
                </div>
            </div>
        </li>
    );
}
