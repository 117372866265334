import { Button } from 'react-bootstrap';
import React, { useState } from 'react';
import HelperFunctions from '../../../global/HelperFunctions';
import Constants from '../../../../config/Constants';
import { useParams } from 'react-router-dom';
import Language from '../../../../language/Language';
import LoadingSpinner from '../../../global/LoadingSpinner';
import { FieldArray, Form as FForm, Formik } from 'formik';
import { InputField } from '../../../publications_v2/helpers/FieldHelper';
import Spinner from '../../../global/Spinner';
import RestrictedDocumentContent from '../../RestrictedDocumentContent';
import { useGetDocument } from '../../hooks/useGetDocument';
import { useUpdateDocumentMutation } from '../../../../features/documents/documents';
import { TbPlaylistAdd } from 'react-icons/tb';
import { WarningButton } from '../../../../components/Buttons';
import { useTranslation } from 'react-i18next';

export default function Packages() {
    const { documentId } = useParams();
    const document = useGetDocument();

    return (
        <div className="content-static-body has-subnav">
            <div className="d-flex align-items-stretch h-100">
                <div className="overflow-auto w-100">
                    <div className="mt-5 ml-5 mr-5 mb-4 dr-container p-4">
                        <RestrictedDocumentContent
                            documentId={documentId}
                            roles={[Constants.userDocumentRole.documentManager, Constants.userDocumentRole.finalEditor]}
                        >
                            {document && <PackagesForm document={document} packageGroups={document.packageGroups} />}

                            {!document && <LoadingSpinner inline size="sm" />}
                        </RestrictedDocumentContent>
                    </div>
                </div>
            </div>
        </div>
    );
}

function PackagesForm({ document, packageGroups = [] }) {
    const [updateDocument] = useUpdateDocumentMutation();
    const { t } = useTranslation('documents');

    const handleSubmit = (values, { setSubmitting }) => {
        const formData = values.packageGroups.map((packageGroup, groupIndex) => {
            return {
                ...packageGroup,
                sortOrder: groupIndex,
                packages: packageGroup.packages.map((_package, index) => {
                    return {
                        ..._package,
                        sortOrder: index,
                    };
                }),
            };
        });

        updateDocument({
            id: document.id,
            uri: `/documents/${document.id}/packagegroup`,
            body: formData,
        }).then(() => {
            setSubmitting(false);
        });
    };

    return (
        <Formik
            initialValues={{
                packageGroups: packageGroups,
            }}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({ values, dirty, isSubmitting, isValid, setFieldValue }) => (
                <>
                    <FForm autoComplete="off">
                        <div className="mb-4">
                            <FieldArray name="packageGroups">
                                {({ remove, push }) => (
                                    <>
                                        {values.packageGroups.map((packageGroup, index) => {
                                            return (
                                                <PackageGroup
                                                    packageGroup={packageGroup}
                                                    userDocumentRole={document.currentUserRole}
                                                    removePackageGroup={remove}
                                                    index={index}
                                                    setFieldValue={setFieldValue}
                                                    key={`package-group-${packageGroup.id}`}
                                                />
                                            );
                                        })}

                                        <NewItemFormInline
                                            formClassName="uk-margin-medium-top"
                                            inputClassName="uk-width-1-5"
                                            labelTranslation={t('document.navbar.packages.newGroup')}
                                            requiredRoles={{
                                                not: [
                                                    Constants.userDocumentRole.editor,
                                                    Constants.userDocumentRole.spectator,
                                                ],
                                            }}
                                            role={document.currentUserRole}
                                            submitItem={(name) =>
                                                push({
                                                    name,
                                                    packages: [],
                                                })
                                            }
                                        />
                                    </>
                                )}
                            </FieldArray>
                        </div>

                        <WarningButton type="submit" disabled={isSubmitting || !dirty || !isValid}>
                            {t('btn.save')}
                        </WarningButton>
                        {isSubmitting && <Spinner />}
                    </FForm>
                </>
            )}
        </Formik>
    );
}

function PackageGroup({ packageGroup, userDocumentRole, index, setFieldValue, removePackageGroup }) {
    const toggleDeletePackageGroup = () => {
        setFieldValue(`packageGroups.${index}.deleted`, !packageGroup.deleted);
    };
    const { t } = useTranslation('documents');

    return (
        <div className="uk-grid">
            <div className="mt-1 line-height-button font-size-lg font-weight-bolder">
                {t('document.navbar.packages.group')}:
            </div>
            <div className="uk-width-expand">
                <FieldArray name={`packageGroups.${index}.packages`}>
                    {({ remove, push, move }) => (
                        <>
                            {!packageGroup.deleted && (
                                <>
                                    <div className="d-flex justify-content-between">
                                        <div className="mb-3 w-100">
                                            <InputField name={`packageGroups.${index}.name`} />
                                        </div>
                                        <button
                                            type="button"
                                            className="uk-button uk-text-danger mb-3 ml-1"
                                            onClick={toggleDeletePackageGroup}
                                            uk-icon="icon: trash; ratio: 0.9"
                                        />
                                    </div>
                                    <div className="ml-3 mb-3">
                                        {packageGroup.packages.map((packageItem, itemIndex) => {
                                            return (
                                                <Package
                                                    movePackageItem={(direction) => {
                                                        move(
                                                            itemIndex,
                                                            direction === 'up' ? itemIndex - 1 : itemIndex + 1
                                                        );
                                                    }}
                                                    packageItem={packageItem}
                                                    groupIndex={index}
                                                    itemIndex={itemIndex}
                                                    removePackage={remove}
                                                    isLast={itemIndex === packageGroup.packages.length - 1}
                                                    key={`group-${index}-package-${itemIndex}`}
                                                />
                                            );
                                        })}

                                        <NewItemFormInline
                                            inputClassName="uk-width-1-5"
                                            labelTranslation={t('document.navbar.packages.addPackage')}
                                            requiredRoles={{
                                                not: [
                                                    Constants.userDocumentRole.editor,
                                                    Constants.userDocumentRole.spectator,
                                                ],
                                            }}
                                            role={userDocumentRole}
                                            submitItem={(name) => {
                                                push({
                                                    name,
                                                });
                                            }}
                                        />
                                    </div>
                                </>
                            )}

                            {packageGroup.deleted && (
                                <div className="mt-1">
                                    <span className="uk-text-danger line-height-button mr-2">
                                        {t('document.navbar.packages.confirmDelete')}
                                    </span>
                                    <button
                                        type="button"
                                        className="uk-button uk-button-small uk-button-danger paketten-buttons"
                                        onClick={() => removePackageGroup(index)}
                                    >
                                        {t('document.navbar.packages.btn.confirmDelete')}
                                    </button>
                                    <button
                                        type="button"
                                        className="uk-button paketten-buttons uk-button-small uk-button-primary uk-margin-left"
                                        onClick={toggleDeletePackageGroup}
                                    >
                                        {t('document.navbar.packages.btn.restoreGroup')}
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                </FieldArray>
            </div>
        </div>
    );
}

function Package({ packageItem, movePackageItem, groupIndex, itemIndex, removePackage, isLast }) {
    const { t } = useTranslation('documents');

    return (
        <div className="d-flex mb-2">
            <InputField
                name={`packageGroups.${groupIndex}.packages.${itemIndex}.name`}
                props={{
                    maxLength: 100,
                    disabled: packageItem.deleted,
                }}
            />

            <button
                type="button"
                className="uk-button"
                onClick={() => movePackageItem('up')}
                data-uk-tooltip={t('document.navbar.packages.btn.badges.moveUp')}
                uk-icon="icon: arrow-up; ratio: 1.1"
                disabled={itemIndex === 0}
            />
            <button
                type="button"
                className="uk-button"
                onClick={() => movePackageItem('down')}
                data-uk-tooltip={t('document.navbar.packages.btn.badges.moveDown')}
                uk-icon="icon: arrow-down; ratio: 1.1"
                disabled={isLast}
            />
            <button
                type="button"
                className="uk-button uk-text-danger"
                onClick={() => removePackage(itemIndex)}
                uk-icon="icon: trash; ratio: 0.8"
            />
        </div>
    );
}

function NewItemFormInline(props) {
    const [title, setTitle] = useState('');
    const hasPermission = HelperFunctions.checkRoleOrPermission(props.role, props.requiredRoles);
    const [showForm, setShowForm] = useState(false);
    const { t } = useTranslation('documents');

    const handleFormChange = (e) => {
        setTitle(e.target.value);
    };

    const handleFormSubmit = () => {
        props.submitItem(title);
        setTitle('');
    };

    if (!hasPermission) {
        return null;
    }

    const formClassName = 'uk-form ' + (props.formClassName !== undefined ? props.formClassName : 'uk-margin-top');
    const inputClassName = 'uk-input ' + (props.inputClassName !== undefined ? props.inputClassName : '');

    return (
        <>
            <Button variant="btn outline-small-button p-1 mt-2" onClick={() => setShowForm(!showForm)}>
                <TbPlaylistAdd size={16} /> {props.labelTranslation}
            </Button>
            {showForm && (
                <div className={formClassName}>
                    <div className="uk-margin-remove line-height-button">
                        <span className="uk-text-normal uk-text-primary uk-margin-right">
                            {Language.getTranslation(props.labelTranslation)}:
                        </span>
                        <input type="text" className={inputClassName} value={title} onChange={handleFormChange} />
                        <button
                            type="button"
                            className="uk-button uk-button-default uk-margin-left"
                            onClick={handleFormSubmit}
                            disabled={title === ''}
                        >
                            <span data-uk-icon="icon: check" />
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}
