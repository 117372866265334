import { useFormikContext } from 'formik';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
    filterAvailableBlocks,
    formatData,
    getBlockData,
    getBlocksFromData,
    parseBlockContent,
    parseBlockData,
} from '../../../../../global/BlockLayoutHelper';
import { Button, Dropdown, Table } from 'react-bootstrap';
import { Droppable } from 'react-beautiful-dnd';
import {
    ArrowDownShort,
    ArrowUpShort,
    ChevronDown,
    ChevronUp,
    DistributeHorizontal,
    DistributeVertical,
    Plus,
    ThreeDotsVertical,
    Trash,
} from 'react-bootstrap-icons';
import { AreaContext } from '../../../Area';
import { EmptyTextBlock } from './EmptyTextBlock';
import Constants from '../../../../../../config/Constants';
import _, { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { Block } from '../Block';
import isEqual from 'react-fast-compare';
import { IconButton, InfoButton } from '../../../../../../components/Buttons';
import HelperFunctions from '../../../../../global/HelperFunctions';
import cx from 'classnames';
import { EditAreaContext } from 'pages/documents_v2/views/edit_area/EditArea';

export function BlockLayoutBlock({
    block,
    index,
    isDragging,
    readOnly,
    restrictions,
    itemsPerPage = 20,
    handleBlockChange,
    areaBlocks = undefined,
}) {
    const [itemOffset, setItemOffset] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedSearchBlock, setSelectedSearchBlock] = useState(undefined);
    const { values } = useFormikContext();

    const areaBlocksMemo = useMemo(() => {
        if (areaBlocks !== undefined) {
            return areaBlocks;
        }

        return values.areaBlocks;
    }, [areaBlocks, values.areaBlocks]);

    const blockContent = useMemo(() => {
        if (readOnly && block.hasOwnProperty('renderedDiffContent')) {
            return HelperFunctions.tryParseJSON(block.renderedDiffContent, {});
        }

        return filterAvailableBlocks(parseBlockContent(block.latestContent || block.baseContent), areaBlocksMemo);
    }, [block, areaBlocksMemo]);

    const rows = blockContent?.data ?? [];
    const pageCount = Math.ceil(rows.length / itemsPerPage);
    const columnsPerRow = rows.length > 0 ? rows[0].length : 2;

    const handlePageClick = useCallback(
        (event) => {
            const newOffset = event.selected * itemsPerPage;
            setItemOffset(newOffset);
        },
        [itemsPerPage]
    );

    const currentItems = useMemo(() => {
        const newPageCount = Math.ceil(rows.length / itemsPerPage);
        let tempOffset = itemOffset;

        if (newPageCount < pageCount) {
            tempOffset = 0;
        }

        const endOffset = tempOffset + itemsPerPage;
        return rows.slice(tempOffset, endOffset);
    }, [rows, itemsPerPage, itemOffset]);

    return (
        <div className="py-4 px-3">
            {rows.length >= itemsPerPage && (
                <LayoutBlockPagination
                    currentItemsLength={currentItems.length}
                    handlePageClick={handlePageClick}
                    itemOffset={itemOffset}
                    pageCount={pageCount}
                    totalItemsLength={rows.length}
                    itemsPerPage={itemsPerPage}
                    columnsPerRow={columnsPerRow}
                    setItemOffset={setItemOffset}
                    rows={rows}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    setSelectedSearchBlock={setSelectedSearchBlock}
                    readOnly={readOnly}
                    areaBlocks={areaBlocksMemo}
                />
            )}

            <MemoizedBlockLayout
                currentItems={currentItems}
                currentLayoutBlockContent={blockContent}
                block={block}
                index={index}
                isDropDisabled={isDragging}
                readOnly={readOnly}
                canChangeColumnsAndRows={restrictions.canChangeColumnsAndRows}
                setItemOffset={setItemOffset}
                itemsPerPage={itemsPerPage}
                itemOffset={itemOffset}
                handleBlockChange={handleBlockChange}
                searchTerm={searchTerm}
                selectedSearchBlock={selectedSearchBlock}
                areaBlocks={areaBlocksMemo}
            />
        </div>
    );
}

function BlockLayout({
    currentItems,
    currentLayoutBlockContent,
    block,
    index,
    isDropDisabled = false,
    readOnly,
    setItemOffset,
    itemsPerPage,
    itemOffset,
    canChangeColumnsAndRows = true,
    handleBlockChange,
    searchTerm,
    selectedSearchBlock,
    areaBlocks = [],
}) {
    const { setFieldValue } = useFormikContext();
    const [blockVariants, setBlockVariants] = useState(block.documentVariants);
    const [diffVariants, setDiffVariants] = useState({ added: [], removed: [] });
    const currentPage = Math.ceil((itemOffset + 1) / itemsPerPage);
    const startingIndex = (currentPage - 1) * itemsPerPage;
    const endingIndex = Math.min(startingIndex + itemsPerPage, currentLayoutBlockContent?.data.length);
    const currentPageRowLength = endingIndex - startingIndex;

    useEffect(() => {
        const added = _.difference(block.documentVariants, blockVariants);
        const removed = _.difference(blockVariants, block.documentVariants);

        if (added.length > 0 || removed.length > 0) {
            setDiffVariants({
                added,
                removed,
            });
        }

        setBlockVariants(block.documentVariants);
    }, [block.documentVariants]);

    useEffect(() => {
        const { added, removed } = diffVariants;

        if (!readOnly && (added.length > 0 || removed.length > 0)) {
            const data = getBlockData(block);
            const blocks = getBlocksFromData(data, areaBlocks);

            blocks.forEach((_block) => {
                const blockIndex = areaBlocks.findIndex((areaBlock) => areaBlock.key === _block.key);

                if (blockIndex >= 0) {
                    const childBlock = areaBlocks[blockIndex];
                    const layoutType = childBlock.properties.textLayout;

                    if (block.properties.type === 'textExplanation' && layoutType === 'default') {
                        // Mirror variants
                        setFieldValue(`areaBlocks.${blockIndex}.documentVariants`, block.documentVariants);
                    } else {
                        let newChildVariants = [...childBlock.documentVariants];

                        if (removed.length > 0) {
                            newChildVariants = _.pullAll(newChildVariants, removed);
                        }

                        if (added.length > 0) {
                            newChildVariants = _.union(newChildVariants, added);
                        }

                        setFieldValue(`areaBlocks.${blockIndex}.documentVariants`, newChildVariants);
                    }
                }
            });
        }
    }, [diffVariants]);

    useEffect(() => {
        if (
            ((block.deleted === true && readOnly) || (block.deleted === undefined && !readOnly)) &&
            block.properties.type === 'textExplanation'
        ) {
            const data = getBlockData(block);
            const blocks = getBlocksFromData(data, areaBlocks);

            blocks.forEach((_block) => {
                const blockIndex = areaBlocks.findIndex((areaBlock) => areaBlock.key === _block.key);

                if (blockIndex >= 0) {
                    setFieldValue(`values.areaBlocks.${blockIndex}.deleted`, block.deleted);
                }
            });
        }
    }, [block.deleted]);

    const addRow = useCallback(
        (rowIndex = undefined) => {
            const blockData = currentLayoutBlockContent.data;
            const indexToAdd = rowIndex !== undefined ? rowIndex + itemOffset : blockData.length;

            const columnCount = blockData[0].length;
            blockData.splice(indexToAdd, 0, Array(columnCount).fill([]));

            const newContent = formatData(blockData);

            if (rowIndex === undefined) {
                const newRowCount = blockData.length;
                const lastPage = Math.ceil(newRowCount / itemsPerPage) - 1;
                const newOffset = lastPage * itemsPerPage;
                setItemOffset(newOffset);
            }

            handleBlockChange(parseBlockData(newContent), index);
        },
        [currentLayoutBlockContent, index, itemOffset, itemsPerPage]
    );

    const addColumn = useCallback(() => {
        const newBlockData = currentLayoutBlockContent.data.map((_row) => {
            return [..._row, []];
        });
        const newContent = formatData(newBlockData);

        handleBlockChange(parseBlockData(newContent), index);
    }, [currentLayoutBlockContent, index]);

    const removeRow = useCallback(
        (rowIndex = undefined) => {
            const blockData = currentLayoutBlockContent.data;
            const indexToRemove =
                rowIndex !== undefined ? rowIndex + itemOffset : startingIndex + currentPageRowLength - 1;

            const newContent = formatData(blockData.filter((_item, _index) => _index !== indexToRemove));

            handleBlockChange(parseBlockData(newContent), index);
        },
        [currentLayoutBlockContent, index, itemOffset]
    );

    const removeColumn = useCallback(() => {
        const newBlockData = currentLayoutBlockContent.data.map((_row) => {
            const newRow = [..._row];
            newRow.pop();

            return newRow;
        });
        const newContent = formatData(newBlockData);

        handleBlockChange(parseBlockData(newContent), index);
    }, [currentLayoutBlockContent, index]);

    return (
        <MemoizedRenderBlockLayout
            block={block}
            addRow={addRow}
            addColumn={addColumn}
            removeRow={removeRow}
            removeColumn={removeColumn}
            isDropDisabled={isDropDisabled}
            readOnly={readOnly}
            areaBlocks={areaBlocks}
            currentLayoutBlockContent={currentLayoutBlockContent}
            blockLayout={currentItems}
            itemOffset={itemOffset}
            canChangeColumnsAndRows={canChangeColumnsAndRows}
            handleBlockChange={handleBlockChange}
            searchTerm={searchTerm}
            selectedSearchBlock={selectedSearchBlock}
            itemsPerPage={itemsPerPage}
            blockLayoutIndex={index}
        />
    );
}

const MemoizedBlockLayout = React.memo(BlockLayout, isEqual);

function RenderBlockLayout({
    block,
    addRow,
    addColumn,
    removeRow,
    removeColumn,
    isDropDisabled,
    readOnly,
    areaBlocks,
    currentLayoutBlockContent,
    blockLayout = [],
    itemOffset,
    canChangeColumnsAndRows = true,
    handleBlockChange,
    searchTerm,
    selectedSearchBlock,
    itemsPerPage,
    blockLayoutIndex,
}) {
    const { draggedItemKey } = useContext(AreaContext);
    const { properties } = block;
    const isDragging = !!draggedItemKey;

    const showTableHeader = properties?.showTableHeader ?? true;
    const highlightFirstColumn = properties?.highlightFirstColumn ?? false;
    const fixedLayout = properties?.fixedLayout ?? false;

    const columnWidth =
        blockLayout[0] && blockLayout[0].length > 0 ? Math.round(100 / blockLayout[0].length) + '%' : '100%';

    return (
        <>
            <div className="table-responsive">
                <Table bordered className="mb-2">
                    <tbody>
                        {blockLayout.map((el, ind) => (
                            <tr key={block.key + '-row-' + ind}>
                                {el.map((col, colIndex) => {
                                    const isTableHeader =
                                        (showTableHeader && ind === 0 && itemOffset === 0) ||
                                        (highlightFirstColumn && colIndex === 0);

                                    if (readOnly) {
                                        return (
                                            <td
                                                width={columnWidth}
                                                style={getListStyle(false, isTableHeader)}
                                                key={block.key + '-col-' + colIndex}
                                            >
                                                <div>
                                                    {col.map((item, index) => (
                                                        <MemoizedLayoutItem
                                                            itemId={item.id}
                                                            areaBlock={areaBlocks.find(
                                                                (_block) => _block.key === item.id
                                                            )}
                                                            areaBlocks={areaBlocks}
                                                            readOnly={true}
                                                            searchTerm={searchTerm}
                                                            selectedSearchBlock={selectedSearchBlock}
                                                            key={`layout-${item.id}-${index}`}
                                                        />
                                                    ))}
                                                </div>
                                            </td>
                                        );
                                    }

                                    return (
                                        <MemoizedDroppableLayoutItem
                                            areaBlocks={areaBlocks}
                                            block={block}
                                            col={col}
                                            isDropDisabled={fixedLayout || isDropDisabled}
                                            isDragging={isDragging}
                                            colIndex={colIndex}
                                            columnWidth={columnWidth}
                                            fixedLayout={fixedLayout}
                                            ind={ind}
                                            itemOffset={itemOffset}
                                            showTableHeader={isTableHeader}
                                            handleBlockChange={handleBlockChange}
                                            searchTerm={searchTerm}
                                            selectedSearchBlock={selectedSearchBlock}
                                            currentLayoutBlockContent={currentLayoutBlockContent}
                                            blockLayoutIndex={blockLayoutIndex}
                                            key={block.key + '-col-' + colIndex}
                                        />
                                    );
                                })}

                                {!fixedLayout && !readOnly && (
                                    <td style={{ minWidth: '45px' }}>
                                        <RowActions
                                            row={el}
                                            index={ind}
                                            removeRow={removeRow}
                                            addRow={addRow}
                                            canChangeColumnsAndRows={canChangeColumnsAndRows}
                                        />
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>

            {!fixedLayout && !readOnly && (
                <LayoutActions
                    addRow={addRow}
                    addColumn={addColumn}
                    removeRow={removeRow}
                    removeColumn={removeColumn}
                    blockLayout={blockLayout}
                    content={currentLayoutBlockContent}
                    canChangeColumnsAndRows={canChangeColumnsAndRows}
                    itemOffset={itemOffset}
                    itemsPerPage={itemsPerPage}
                />
            )}
        </>
    );
}

const MemoizedRenderBlockLayout = React.memo(RenderBlockLayout, isEqual);

function DroppableLayoutItem({
    block,
    fixedLayout,
    isDropDisabled,
    ind,
    itemOffset,
    colIndex,
    columnWidth,
    showTableHeader,
    isDragging,
    areaBlocks,
    col,
    handleBlockChange,
    searchTerm,
    selectedSearchBlock,
    currentLayoutBlockContent,
    blockLayoutIndex,
}) {
    const isTextExplanationBlock = block?.properties?.type === Constants.blockTypes.textExplanation;

    return (
        <Droppable
            type="LAYOUT_BLOCK"
            isDropDisabled={isDropDisabled}
            droppableId={`block|${block.key}|${ind + itemOffset}|${colIndex}`}
        >
            {(provided, snapshot) => (
                <td
                    width={columnWidth}
                    ref={provided.innerRef}
                    className="block-layout-droppable-cell position-relative px-2 py-3"
                    style={getListStyle(snapshot.isDraggingOver, showTableHeader, isDragging)}
                    {...provided.droppableProps}
                >
                    {col.map((item, index) => {
                        const blockKey = parseInt(item.id);
                        const blockIndex = areaBlocks?.findIndex((_block) => _block.key === blockKey);
                        const areaBlock = blockIndex < 0 ? undefined : areaBlocks[blockIndex];

                        return (
                            <MemoizedLayoutItem
                                areaBlock={areaBlock}
                                parentBlock={block}
                                index={index}
                                blockIndex={blockIndex}
                                fixedLayout={fixedLayout}
                                handleBlockChange={handleBlockChange}
                                searchTerm={searchTerm}
                                selectedSearchBlock={selectedSearchBlock}
                                key={`layout-${item.id}`}
                            />
                        );
                    })}
                    {provided.placeholder}

                    {!isTextExplanationBlock && (
                        <InsertBlockBtn
                            block={block}
                            rowIndex={ind + itemOffset}
                            colIndex={colIndex}
                            currentLayoutBlockContent={currentLayoutBlockContent}
                            index={blockLayoutIndex}
                            handleBlockChange={handleBlockChange}
                        />
                    )}
                </td>
            )}
        </Droppable>
    );
}

const MemoizedDroppableLayoutItem = React.memo(DroppableLayoutItem, isEqual);

function LayoutItem({
    areaBlock,
    parentBlock,
    index,
    blockIndex,
    fixedLayout,
    handleBlockChange,
    readOnly = false,
    searchTerm,
    selectedSearchBlock,
}) {
    if (!areaBlock) {
        return null;
    }

    const blockContent = areaBlock.latestContent || areaBlock.baseContent || '';

    if (readOnly && _.isEmpty(blockContent)) {
        return <EmptyTextBlock block={areaBlock} />;
    }

    return (
        <Block
            block={areaBlock}
            parentBlock={parentBlock}
            index={blockIndex}
            readOnly={readOnly}
            renderedIndex={index}
            isDraggable={!fixedLayout}
            showSettings={false}
            isInsideBlockLayout={true}
            handleBlockChange={handleBlockChange}
            searchTerm={searchTerm}
            selectedSearchBlock={selectedSearchBlock}
        />
    );
}

const MemoizedLayoutItem = React.memo(LayoutItem, isEqual);

function LayoutActions({
    addColumn,
    addRow,
    removeRow,
    removeColumn,
    blockLayout,
    content,
    canChangeColumnsAndRows = true,
}) {
    const { t } = useTranslation('documents');
    const lastRowHasBlocks =
        content.rows < 2 ||
        (blockLayout && blockLayout[blockLayout.length - 1]).some((_item) => _item && _item.length > 0);
    const canDeleteColumn =
        content.columns > 1 && content.data && content.data.every((_item) => _item[_item.length - 1].length === 0);

    return (
        <>
            {canChangeColumnsAndRows && (
                <>
                    <Button size="sm" variant="primary" className="mt-2 mr-1" onClick={() => addRow()}>
                        {t('document.navbar.main.editor.left.blocks.types.blockLayout.addRow')} <DistributeVertical />
                    </Button>
                    <Button size="sm" variant="primary" className="mt-2 mr-3" onClick={() => addColumn()}>
                        {t('document.navbar.main.editor.left.blocks.types.blockLayout.addColumn')}{' '}
                        <DistributeHorizontal />
                    </Button>
                    <Button
                        size="sm"
                        variant="danger"
                        className="mt-2 mr-1"
                        onClick={() => removeRow()}
                        disabled={lastRowHasBlocks}
                    >
                        {t('document.navbar.main.editor.left.blocks.types.blockLayout.removeRow')}{' '}
                        <DistributeVertical />
                    </Button>
                    <Button
                        size="sm"
                        variant="danger"
                        className="mt-2 mr-1"
                        onClick={() => removeColumn()}
                        disabled={!canDeleteColumn}
                    >
                        {t('document.navbar.main.editor.left.blocks.types.blockLayout.removeColumn')}
                        <DistributeHorizontal />
                    </Button>
                </>
            )}
        </>
    );
}

function RowActions({ row = [], index, removeRow, addRow, canChangeColumnsAndRows = true }) {
    const hasBlocks = row.some((_item) => _item.length > 0);
    const { t } = useTranslation('documents');

    if (!canChangeColumnsAndRows) {
        return null;
    }

    return (
        <Dropdown>
            <Dropdown.Toggle bsPrefix="docrev-dropdown-toggle" as="span" id={`dropdown-toggle-${index}`}>
                <ThreeDotsVertical size={18} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item onClick={() => addRow(index)}>
                    <div className="d-flex align-items-center">
                        <ArrowUpShort size={14} className="mr-1" />
                        {t('document.navbar.main.editor.left.blocks.types.blockLayout.menu.rowAbove')}
                    </div>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => addRow(index + 1)}>
                    <div className="d-flex align-items-center">
                        <ArrowDownShort size={14} className="mr-1" />
                        {t('document.navbar.main.editor.left.blocks.types.blockLayout.menu.rowBelow')}
                    </div>
                </Dropdown.Item>
                <Dropdown.Item disabled={hasBlocks} onClick={() => removeRow(index)}>
                    <div
                        className={cx('d-flex align-items-center', {
                            'text-danger': !hasBlocks,
                            'text-muted': hasBlocks,
                        })}
                    >
                        <Trash size={14} className="mr-1" />
                        {t('document.navbar.main.editor.left.blocks.types.blockLayout.menu.deleteRow')}
                    </div>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}

function LayoutBlockPaginationRender({
    itemOffset,
    currentItemsLength,
    totalItemsLength,
    pageCount,
    handlePageClick,
    itemsPerPage,
    columnsPerRow,
    setItemOffset,
    rows,
    setSearchTerm,
    searchTerm,
    readOnly,
    setSelectedSearchBlock,
    areaBlocks,
}) {
    return (
        <div className="d-flex flex-row-reverse align-items-center justify-content-between mb-4">
            <div className="d-flex align-items-center">
                <div className="small text-secondary mr-2">
                    {itemOffset + 1} - {itemOffset + currentItemsLength} van {totalItemsLength} onderdelen
                </div>

                <ReactPaginate
                    pageCount={pageCount}
                    forcePage={itemOffset > 0 ? itemOffset / itemsPerPage : 0}
                    onPageChange={handlePageClick}
                    previousLabel="‹"
                    nextLabel="›"
                    containerClassName="pagination mb-0"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    nextClassName="page-item"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    activeClassName="active"
                />
            </div>

            {!readOnly && (
                <>
                    <LayoutBlockSearch
                        rows={rows}
                        setSearchTerm={setSearchTerm}
                        setSelectedSearchBlock={setSelectedSearchBlock}
                        setItemOffset={setItemOffset}
                        itemsPerPage={itemsPerPage}
                        searchTerm={searchTerm}
                        areaBlocks={areaBlocks}
                    />
                </>
            )}
        </div>
    );
}

function LayoutBlockSearch({
    rows,
    setSearchTerm,
    setSelectedSearchBlock,
    itemsPerPage,
    setItemOffset,
    searchTerm,
    areaBlocks = [],
}) {
    const [searchResults, setSearchResults] = useState([]);
    const [searchResultIndex, setSearchResultIndex] = useState(0);
    const [inputValue, setInputValue] = useState('');
    const [matchingBlockIndexes, setMatchingBlockIndexes] = useState([]);

    const blockPositions = useMemo(() => {
        const positions = [];

        rows?.forEach((row, rowIndex) => {
            row.forEach((column, columnIndex) => {
                column.forEach((item) => {
                    if (item.id) {
                        positions.push({
                            block: areaBlocks?.find((block) => block.key === item.id),
                            rowIndex,
                            columnIndex,
                            page: Math.floor(rowIndex / itemsPerPage),
                        });
                    }
                });
            });
        });

        return positions;
    }, [rows, areaBlocks]);

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const debouncedResults = useMemo(() => {
        return debounce(handleChange, 300);
    }, []);

    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    });

    useEffect(() => {
        if (searchTerm !== inputValue) {
            handleSearch(searchTerm);
        }
    }, [searchTerm, inputValue]);

    const handleSearch = (input) => {
        const trimmedInputValue = input.trim();

        if (_.isEmpty(trimmedInputValue)) {
            handleClearSearch();
            return;
        }

        const searchValue = trimmedInputValue.toLowerCase();
        let results = [];
        let indexes = [];

        blockPositions.forEach((position, index) => {
            const latestContent = position.block?.latestContent ?? '';
            if (latestContent.toLowerCase().includes(searchValue)) {
                results.push(position.block);
                indexes.push(index);
            }
        });

        if (results.length > 0) {
            const firstOffset = blockPositions[indexes[0]].page * itemsPerPage;
            setItemOffset(firstOffset);
            setMatchingBlockIndexes(indexes);
            setSearchResults(results);
            setSelectedSearchBlock(results[0]);
        } else {
            setMatchingBlockIndexes([]);
            setSearchResults([]);
            setSelectedSearchBlock(undefined);
        }

        setSearchResultIndex(0);
        setInputValue(input);
    };
    const handleNextResult = () => {
        if (matchingBlockIndexes.length === 1) {
            const position = blockPositions[matchingBlockIndexes[0]];
            if (position) {
                const newOffset = position.page * itemsPerPage;
                setItemOffset(newOffset);
                setSelectedSearchBlock(searchResults[0]);
            }
            return;
        }

        const nextIndex = searchResultIndex + 1;
        if (nextIndex >= matchingBlockIndexes.length) {
            return;
        }

        const newMatchingBlockIndex = matchingBlockIndexes[nextIndex];

        const position = blockPositions[newMatchingBlockIndex];

        if (!position) {
            return;
        }

        const newOffset = position.page * itemsPerPage;
        setItemOffset(newOffset);
        setSearchResultIndex(nextIndex);
        setSelectedSearchBlock(searchResults[nextIndex]);
    };

    const handlePreviousResult = () => {
        if (searchResultIndex <= 0) {
            return;
        }

        const prevIndex = searchResultIndex - 1;

        const newMatchingBlockIndex = matchingBlockIndexes[prevIndex];

        const position = blockPositions[newMatchingBlockIndex];
        if (!position) {
            return;
        }

        const newOffset = position.page * itemsPerPage;

        setItemOffset(newOffset);
        setSearchResultIndex(prevIndex);
        setSelectedSearchBlock(searchResults[prevIndex]);
    };

    const handleClearSearch = () => {
        setSearchTerm('');
        setInputValue('');
        setSearchResultIndex(0);
        setSearchResults([]);
        setMatchingBlockIndexes([]);
        setSelectedSearchBlock(undefined);
    };

    return (
        <div className="search-container">
            <div className="d-flex align-items-center">
                <input
                    type="search"
                    onChange={debouncedResults}
                    placeholder="Zoeken in tabel layout"
                    className="form-control"
                    style={{ height: '38px' }}
                />

                {searchResults.length > 0 && (
                    <>
                        <div className="text-muted small ml-2 mr-2">
                            {searchResultIndex + 1}/{searchResults.length}
                        </div>

                        <IconButton
                            className="text-secondary mr-2"
                            onClick={handlePreviousResult}
                            tooltip="Vorige"
                            icon={<ChevronUp size={18} />}
                        />

                        <IconButton
                            className="text-secondary"
                            onClick={handleNextResult}
                            tooltip="Volgende"
                            icon={<ChevronDown size={18} />}
                        />
                    </>
                )}
            </div>
        </div>
    );
}

export const LayoutBlockPagination = React.memo(LayoutBlockPaginationRender);

function InsertBlockBtn({ block, currentLayoutBlockContent, rowIndex, colIndex, index, handleBlockChange }) {
    const { addBlock } = useContext(EditAreaContext);
    const { t } = useTranslation('documents');

    return (
        <InfoButton
            style={{ position: 'absolute', bottom: 2, left: '50%', transform: 'translate(-50%, 0)' }}
            size="sm"
            className="insert-block-btn p-1"
            tooltip={t('document.navbar.main.editor.left.blocks.tooltips.insertBlock')}
            onClick={() => insertBlock()}
        >
            <Plus size={16} />
        </InfoButton>
    );

    function insertBlock() {
        // Create new Block first
        const newBlock = addBlock(Constants.blockTypes.text, block.documentVariants);

        // Add it to the layout
        const currentData = currentLayoutBlockContent.data;

        // Append to end of array
        currentData[rowIndex][colIndex] = [
            ...currentData[rowIndex][colIndex],
            {
                id: newBlock.key,
            },
        ];

        const newContent = formatData(currentData);
        handleBlockChange(parseBlockData(newContent), index);
    }
}

const getListStyle = (isDraggingOver, isTableHeader, isDragging = false) => ({
    background: isDraggingOver ? '#00e67614' : isDragging ? undefined : isTableHeader ? '#f6f8fa' : undefined,
    height: 87,
    minWidth: 140,
    overflow: isDragging ? 'hidden' : undefined,
});
