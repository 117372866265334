import { Route, Switch } from 'react-router-dom';
import { Teams as TeamsPage } from 'pages/task_team/views/teams/Teams';
import { AddTeamModal } from 'pages/task_team/modals/AddTeamModal';
import { TEAMS_PATH, VIEW_TEAM_PATH } from 'scenes/TaskTeam';
import { Team } from 'pages/task_team/views/teams/Team';

export function Teams() {
    return (
        <>
            <Switch>
                <Route path={TEAMS_PATH} exact={true} strict={true} render={() => <TeamsPage />} />
                <Route path={VIEW_TEAM_PATH} exact={true} strict={true} render={() => <Team />} />
            </Switch>

            <AddTeamModal />
        </>
    );
}
