import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    createPublication,
    fetchPublication,
    patchPublication,
    selectPublicationById,
} from '../../../features/publications/publicationsSlice';
import { Form as FForm, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import _ from 'lodash';
import { EDIT_PATH } from '../../../scenes/PublicationsV2';
import { useActiveOrganisation } from '../../../hooks/useActiveOrganisation';
import { showError } from '../../../helpers/errorHelper';
import { publicationApi } from '../../../features/publications/publicationApi';

export default function EditPublication(props) {
    const { publicationId, action, groupId } = useParams();
    const publication = useSelector((state) => selectPublicationById(state.publications, publicationId));
    const activeOrganisation = useActiveOrganisation();
    const dispatch = useDispatch();
    const history = useHistory();
    const initialValues =
        publication === undefined
            ? {
                  organisationId: activeOrganisation,
                  publicationGroup: groupId && groupId !== '0' ? '/api/publication_groups/' + groupId : null,
                  name: '',
                  title: '',
                  titleExtra: '',
                  chaptersDocumentTitle: '',
                  footerDocumentTitle: '',
                  variantName: '',
                  description: '',
                  prefix: '',
                  versionNumber: '',
                  versionDate: '',
                  languageIso: '',
                  showChangesInPreview: true,
                  informTeamMembers: false,
                  properties: {
                      tags: [],
                      exportComments: false,
                  },
                  translation: {
                      translate: false,
                  },
                  contentDecoded: [],
                  html: false,
                  pdf: false,
                  printPdf: false,
                  htmlSlug: '',
                  htmlMeta: '',
                  documentIdLastEdit: null,
                  new: false,
                  pdfFileName: '',
                  pdfMetaTitle: '',
                  pdfMetaAuthor: '',
                  pdfMetaSubject: '',
                  pdfMetaKeywords: '',
                  pdfProtection: false,
                  pdfPassword: '',
                  pdfPermissionPassword: '',
                  pdfPermissions: [],
                  multiVersions: false,
                  pubGroupProperties: {
                      enableGroupProperties: false,
                      userGroupId: [],
                  },
                  publicationUserPasswords: [],
                  includeInKiosk: false,
                  kioskCategoryId: null,
              }
            : {
                  ...publication,
                  new: false,
              };

    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            validateOnMount
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
                const formData = {
                    ...values,
                };

                // Convert empty page title to string (required by backend)
                if (_.isArray(formData.contentDecoded)) {
                    formData.contentDecoded = formData.contentDecoded.map((contentSection) => {
                        if ('emptyPage' === contentSection.contentType && _.isInteger(contentSection.title)) {
                            contentSection.title += ''; // convert to string
                        }

                        return contentSection;
                    });
                }

                if (formData.hasOwnProperty('publicationVersions')) {
                    delete formData.publicationVersions;
                }

                if (values.hasOwnProperty('id')) {
                    // update
                    dispatch(patchPublication({ uri: publication['@id'], formData }))
                        .then(() => {
                            setSubmitting(false);

                            // Reload data
                            dispatch(fetchPublication({ id: publication.id }));

                            // Invalidate Tags
                            dispatch(publicationApi.util.invalidateTags([{ type: 'Publication', id: 'LIST' }]));
                        })
                        .catch((error) => {
                            showError(`${error}`);
                        });
                } else {
                    // create
                    dispatch(createPublication({ formData })).then(({ payload }) => {
                        const publication = Object.values(payload.publications).shift();

                        // Invalidate Tags
                        dispatch(publicationApi.util.invalidateTags([{ type: 'Publication', id: 'LIST' }]));

                        history.push(
                            generatePath(EDIT_PATH, {
                                publicationId: publication.id,
                                action: action,
                                groupId: publication.publicationGroup
                                    ? publication.publicationGroup.split('/').pop()
                                    : '0',
                            })
                        );
                    });
                }
            }}
        >
            {() => {
                return (
                    <FForm autoComplete="off" id="editPublicationForm">
                        {props.children}
                    </FForm>
                );
            }}
        </Formik>
    );
}

const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    title: Yup.string().required(),
    templateId: Yup.number().required(),
    domainId: Yup.number().required(),
    htmlSlug: Yup.string().nullable().when('html', {
        is: true,
        then: Yup.string().required(),
    }),
    prefix: Yup.string().nullable().when('multiVersions', {
        is: true,
        then: Yup.string().required(),
    }),
    pdfFileName: Yup.string().nullable().when('pdf', {
        is: true,
        then: Yup.string().required(),
    }),
    pdfPermissionPassword: Yup.string()
        .nullable()
        .when('pdfPermissions', {
            is: (value) => (value ? value.length > 0 : false),
            then: Yup.string().required(),
        }),
    content: Yup.array()
        .of(
            Yup.object().shape({
                contentType: Yup.string().required(),
                backCoverTemplate: Yup.string().when('contentType', {
                    is: 'backCover',
                    then: Yup.string().required(),
                    otherwise: Yup.string().nullable(),
                }),
                variantId: Yup.number().when('contentType', {
                    is: 'backCover',
                    then: Yup.number().required(),
                    otherwise: Yup.number().nullable(),
                }),
            })
        )
        .min(1),
});
