import { useState } from 'react';
import Constants from '../../config/Constants';
import { Col, Form, Modal } from 'react-bootstrap';
import Spinner from '../global/Spinner';
import getEnv from '../../config/Env';
import HelperFunctions from '../global/HelperFunctions';
import { SecondaryButton, WarningButton } from '../../components/Buttons';
import { useTranslation } from 'react-i18next';

export default function NewTagModal(props) {
    const { showModal = false } = props;
    const [tagName, setTagName] = useState('');
    const [isLoading, setLoading] = useState(false);
    const { t } = useTranslation('documents');

    const handleTagNameChange = (value) => {
        setTagName(HelperFunctions.removeSpaces(value));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        setLoading(true);

        props
            .handleSubmit({ name: tagName })
            .then(() => {
                setTagName('');
                setLoading(false);
                props.handleClose();
            })
            .catch(() => {
                getEnv('APP_ENV') === Constants.environments.dev && console.log('error adding tag');
                setLoading(false);
            });
    };

    return (
        <Modal show={showModal} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('document.navbar.tags.newModal.title')}</Modal.Title>
            </Modal.Header>

            <Form noValidate onSubmit={handleSubmit}>
                <Modal.Body>
                    <Form.Row>
                        <Col>
                            <Form.Control
                                onChange={(event) => handleTagNameChange(event.currentTarget.value)}
                                placeholder={t('document.navbar.tags.newModal.titleInput')}
                                value={tagName}
                            />
                        </Col>
                    </Form.Row>
                </Modal.Body>

                <Modal.Footer>
                    {isLoading && <Spinner />}
                    <SecondaryButton className="btn btn-secondary" onClick={props.handleClose}>
                        {t('btn.cancel')}
                    </SecondaryButton>
                    <WarningButton disabled={tagName.length < 2 || isLoading} type="submit">
                        {t('btn.save')}
                    </WarningButton>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
