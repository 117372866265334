import { useParams } from 'react-router-dom';
import { UserForm } from './UserForm';
import { useGetAdminUserQuery } from '../../../../features/security/authApi';

export function Edit({ account }) {
    const { entityId } = useParams();

    const { user } = useGetAdminUserQuery(entityId, {
        selectFromResult: ({ data }) => ({
            user: data,
        }),
    });

    if (!user || user.id !== entityId) {
        return null;
    }

    return (
        <div>
            <div className="subheader d-flex align-items-center justify-content-between pb-1 mb-3">
                <div className="d-flex align-items-baseline">
                    <h3 className="mb-0">{user.fullName}: bewerken</h3>
                </div>
            </div>

            <UserForm account={account} user={user} />
        </div>
    );
}
