import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import ReimbursementApi from '../../api/ReimbursementApi';

const categoriesAdapter = createEntityAdapter();

const reimbursementProductCodesAdapter = createEntityAdapter({
    selectId: (entity) => entity['@id'],
});
const reimbursementProductTypesAdapter = createEntityAdapter({
    selectId: (entity) => entity['@id'],
});
const reimbursementCodesAdapter = createEntityAdapter({
    selectId: (entity) => entity['@id'],
});
const reimbursementProductAdapter = createEntityAdapter({
    selectId: (entity) => entity['@id'],
});
const reimbursementProductFieldAdapter = createEntityAdapter({
    selectId: (entity) => entity['@id'],
});
const reimbursementAdapter = createEntityAdapter({
    selectId: (entity) => entity['@id'],
});

export const batchEditReimbursements = createAsyncThunk(
    'reimbursements/batchEditReimbursements',
    async ({ formData }) => {
        const { data } = await ReimbursementApi.batchEdit(formData);

        return data.reimbursements;
    }
);

export const slice = createSlice({
    name: 'reimbursements',
    initialState: {
        expandedItems: {},
        categories: categoriesAdapter.getInitialState(),
        reimbursementProductCodes: reimbursementProductCodesAdapter.getInitialState(),
        reimbursementProductTypes: reimbursementProductTypesAdapter.getInitialState(),
        reimbursementCodes: reimbursementCodesAdapter.getInitialState(),
        reimbursementProducts: reimbursementProductAdapter.getInitialState(),
        reimbursementProductFields: reimbursementProductFieldAdapter.getInitialState(),
        reimbursements: reimbursementAdapter.getInitialState(),
        selectedRows: [],
        editReimbursement: undefined,
        filters: {
            search: '',
            team: 'all',
            labels: [],
            show_open_changes: true,
            show_unchanged: true,
            hide_inactive: true,
        },
    },
    reducers: {
        setEditReimbursement: {
            reducer(state, action) {
                state.editReimbursement = action.payload;
            },
        },
        setFilter: {
            reducer(state, action) {
                const { key, value } = action.payload;
                state.filters[key] = value;
            },
        },
    },
    extraReducers: {
        ['security/resetState']: (state) => {
            Object.assign(state, slice.getInitialState());
        },
        [batchEditReimbursements.fulfilled]: (state, action) => {
            reimbursementAdapter.updateMany(state.reimbursements, action.payload ?? []);
        },
    },
});

export default slice.reducer;

export const { setEditReimbursement, setFilter } = slice.actions;
