import { Button, Col, FormControl } from 'react-bootstrap';
import RestrictedContent from '../../../global/RestrictedContent';
import { Permissions } from '../../../../config/Constants';
import cx from 'classnames';
import { useEffect, useMemo } from 'react';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { setFilter } from '../../../../features/translations/translationSlice';
import { useTranslation } from 'react-i18next';

export default function Filters({ setShowNewTranslationModal }) {
    const { t } = useTranslation('translations');

    const dispatch = useDispatch();
    const { search } = useSelector((state) => state.translation.sidebarFilters);

    function setStoreFilter(key, value) {
        dispatch(setFilter({ key, value }));
    }

    const handleChange = (event) => {
        setStoreFilter('search', event.target.value);
    };

    const debouncedResults = useMemo(() => {
        return debounce(handleChange, 300);
    }, []);

    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    });

    return (
        <div
            className="bg-bgLight px-4 py-3"
            style={{
                position: 'absolute',
                top: 73,
                left: 0,
                right: 0,
            }}
        >
            <div className="form-row align-items-center">
                <Col sm={3} md={5} lg={4} xl={3}>
                    <FormControl
                        type="search"
                        placeholder={`${t('searchTitle')}...`}
                        className={cx('nav-form-search mr-2', { 'has-content': search !== '' })}
                        aria-label={`${t('searchTitle')}...`}
                        onChange={debouncedResults}
                    />
                </Col>
                <RestrictedContent permission={Permissions.Translation.Create}>
                    <div className="col-auto ml-auto d-flex align-items-center">
                        <Button
                            variant="info"
                            className="ml-3"
                            style={{ marginRight: '25px', marginTop: '5px' }}
                            onClick={() => setShowNewTranslationModal(true)}
                        >
                            {t('btn.newTranslation')}
                        </Button>
                    </div>
                </RestrictedContent>
            </div>
        </div>
    );
}
