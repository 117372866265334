import { WarningButton } from 'components/Buttons';
import Spinner from 'pages/global/Spinner';
import { Field, useFormikContext } from 'formik';
import HelperFunctions from 'pages/global/HelperFunctions';
import { InputMultipleSelect } from 'pages/publications_v2/helpers/FieldHelper';
import { useEffect, useState } from 'react';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { useTranslation } from 'react-i18next';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import _ from 'lodash';
import cx from 'classnames';

export const prepareTaskFormData = (values) => {
    return {
        ...values,
        publicationDate: valueOrNull(values.publicationDate),
        customerJourney: valueOrNull(values.customerJourney),
        deadline: valueOrNull(values.deadline),
        startDate: valueOrNull(values.startDate),
        taskType: valueOrNull(values.taskType),
        reEvaluationStartDate: valueOrNull(values.reEvaluationStartDate),
    };
};

export const prepareTaskVersionFormData = (values) => {
    return {
        ...values,
        publicationDate: valueOrNull(values.publicationDate),
        deadline: valueOrNull(values.deadline),
        startDate: valueOrNull(values.startDate),
    };
};

function valueOrNull(value) {
    return value === '' ? null : value;
}

export function FormFooter() {
    const { isSubmitting, isValid, dirty } = useFormikContext();

    return (
        <div className="sidebar-form-group d-flex flex-row-reverse align-items-center">
            <WarningButton disabled={isSubmitting || !isValid || !dirty} type="submit">
                Opslaan
            </WarningButton>

            {isSubmitting && <Spinner />}
        </div>
    );
}

export function CheckBox({ name, label }) {
    return (
        <div className="form-group form-check">
            <Field name={name} id={name} className="form-check-input" type="checkbox" />
            <label className="form-check-label" htmlFor={name}>
                {label}
            </label>
        </div>
    );
}

export function Radio({ name, id, label, value }) {
    return (
        <div className="form-check mb-1">
            <Field name={name} id={id} value={value} className="form-check-input" type="radio" />
            <label className="form-check-label" htmlFor={id}>
                {label}
            </label>
        </div>
    );
}

export function TextArea({ name, props = {} }) {
    return <Field id={name} name={name} as="textarea" className="form-control" {...props} />;
}

export function MultipleSelect({ options = [], name, maxLimit, props = {} }) {
    const { values, setFieldValue } = useFormikContext();

    const formValues = _.get(values, name, []);
    const selectOptions = HelperFunctions.prepareDropdownData(options);
    const selectedOptions = selectOptions.filter((option) => formValues.includes(option['@id']));

    return (
        <InputMultipleSelect
            name={name}
            options={selectOptions}
            onChange={handleChange}
            value={selectedOptions}
            maxLimit={maxLimit}
            props={props}
        />
    );

    function handleChange(selection) {
        setFieldValue(
            name,
            selection.map((item) => item['@id']),
        );
    }
}

export function WysiwygEditor({
    label = '',
    name,
    required = false,
    readOnly = false,
    onBlur = () => {},
    focusOnInit = false,
    toolbar = 'default',
    configOverride = {},
}) {
    const { values, setFieldValue } = useFormikContext();
    const [editorState, setEditorState] = useState();
    const [editorRef, setEditorRef] = useState();
    const [hasFocus, setHasFocus] = useState(false);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const defaultContent = _.get(values, name) ?? '';
        const blocksFromHtml = htmlToDraft(defaultContent);
        const { contentBlocks, entityMap } = blocksFromHtml;

        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        setEditorState(EditorState.createWithContent(contentState));
    }, [name]);

    const currentContent = editorState ? editorState.getCurrentContent() : undefined;

    useEffect(() => {
        if (currentContent !== undefined) {
            let newContent = draftToHtml(convertToRaw(currentContent)).trim();

            // Empty <p>?
            if (newContent === '<p></p>') {
                newContent = '';
            }

            setFieldValue(name, newContent);
        }
    }, [currentContent]);

    const setEditorReference = (ref) => {
        if (ref && !editorRef) {
            setEditorRef(ref);

            if (focusOnInit) {
                ref.focus();
            }
        }
    };

    return (
        <div className="mb-3">
            {!_.isEmpty(label) && (
                <div className="mb-2 text-color font-size-sm font-weight-bolder label-title-label">
                    {label}
                    {required && <>*</>}
                </div>
            )}

            {editorState && (
                <Editor
                    toolbar={getToolbarConfig()}
                    stripPastedStyles={true}
                    readOnly={readOnly}
                    editorClassName={cx('docrev-editor', {
                        'has-focus': hasFocus,
                    })}
                    editorState={editorState}
                    editorRef={setEditorReference}
                    onEditorStateChange={(editorState) => setEditorState(editorState)}
                    onBlur={() => {
                        setHasFocus(false);
                        onBlur();
                    }}
                    onFocus={() => setHasFocus(true)}
                    localization={{
                        locale: i18n.language,
                    }}
                />
            )}
        </div>
    );

    function getToolbarConfig() {
        const config = toolbarConfig[toolbar];

        if (!_.isEmpty(configOverride)) {
            return _.assign(config, configOverride);
        }

        return config;
    }
}

const toolbarConfig = {
    default: {
        options: ['inline', 'list', 'link'],
        inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['bold', 'italic', 'underline'],
            bold: {
                // icon: bold,
                className: undefined,
            },
            italic: {
                // icon: italic,
                className: undefined,
            },
            underline: {
                // icon: underline,
                className: undefined,
            },
        },
        list: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['unordered', 'ordered'],
            unordered: {
                // icon: unordered,
                className: undefined,
            },
            ordered: {
                // icon: ordered,
                className: undefined,
            },
        },
        link: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            dropdownClassName: undefined,
            showOpenOptionOnHover: true,
            defaultTargetOption: '_self',
            options: ['link', 'unlink'],
            link: {
                // icon: link,
                className: undefined,
            },
            unlink: {
                // icon: unlink,
                className: undefined,
            },
            linkCallback: undefined,
        },
    },
    one_liner: {
        options: ['inline', 'link', 'colorPicker'],
        inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['bold', 'italic', 'underline'],
            bold: {
                // icon: bold,
                className: undefined,
            },
            italic: {
                // icon: italic,
                className: undefined,
            },
            underline: {
                // icon: underline,
                className: undefined,
            },
        },
        link: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            dropdownClassName: undefined,
            showOpenOptionOnHover: true,
            defaultTargetOption: '_self',
            options: ['link', 'unlink'],
            link: {
                // icon: link,
                className: undefined,
            },
            unlink: {
                // icon: unlink,
                className: undefined,
            },
            linkCallback: undefined,
        },
        colorPicker: {
            colors: ['#ff0000', '#008000', '#0000ff'],
        },
    },
};

export const yesNoOptions = [
    {
        label: 'Ja',
        value: '1',
    },
    {
        label: 'Nee',
        value: '0',
    },
];
