import Modal from '../../global/Modal';
import SelectExportDate from './export_changes/SelectExportDate';

export default function ExportChangesModal({ showModal, handleClose, payload }) {
    if (!showModal) {
        return null;
    }

    return <Modal opened={true} onRequestClose={handleClose} modalStyle="centeredAutoWidth">
        <SelectExportDate
            closeModal={handleClose}
            publication={payload.publication}
        />
    </Modal>;
}
