import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Field, Form as FForm, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { FormModal, ModalFooter } from '../../global/FormModal';
import { getLabelOptions } from '../team/EditOpListUserModal';
import { selectDepartmentUserIsManagerOf } from '../../../features/operationsList/opListUserSlice';
import Select from 'react-select';
import _ from 'lodash';
import { createOpListEntryLabel, patchOpEntry } from '../../../features/operationsList/operationsListSlice';
import { useGetLabelFoldersQuery, useGetLabelsQuery } from '../../../features/metadata/metadata';
import { useTranslation } from 'react-i18next';

export default function AddOpEntryLabelModal({ showModal = false, opList, baseEntryLabel, opEntry, handleClose }) {
    const department = useSelector(selectDepartmentUserIsManagerOf(opList));
    const { activeOrganisation } = useSelector((state) => state.security);
    const { t } = useTranslation('changelist');

    const dispatch = useDispatch();

    const { allFolders = [] } = useGetLabelFoldersQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                allFolders: data ?? [],
            }),
        }
    );

    const { allLabels = [] } = useGetLabelsQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                allLabels: data ?? [],
            }),
        }
    );

    const handleSubmit = (values, { setSubmitting }) => {
        const formData = _.pick(baseEntryLabel, ['opEntry', 'label', 'opList', 'opDepartment']);
        formData.status = opList.statusCodes[0]['key']; // set status to new
        formData.label = values.label.value;

        const opEntryFormData = {
            labelUris: [...opEntry.labelUris, formData.label],
        };

        // Create the new OpEntryLabel based on the old one
        return (
            dispatch(createOpListEntryLabel({ formData }))
                // Then update the labelUris of the opEntry so it includes the new label
                .then(() =>
                    dispatch(
                        patchOpEntry({
                            uri: opEntry['@id'],
                            formData: opEntryFormData,
                        })
                    )
                )
                .then(() => {
                    setSubmitting(false);
                    handleClose();
                })
        );
    };

    return (
        <FormModal
            show={showModal}
            onHide={() => handleClose()}
            title={t('changelist.view.addOpEntryLabel.addInsuranceType')}
        >
            <Formik
                initialValues={{
                    label: '',
                }}
                validationSchema={AddOpEntryLabelSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, touched, errors, setFieldValue, isValid, values, dirty }) => (
                    <FForm autoComplete="off">
                        <Modal.Body>
                            <Form.Group>
                                <Form.Label htmlFor="labels">
                                    {t('changelist.view.addOpEntryLabel.insuranceType')}
                                </Form.Label>
                                <Field
                                    id="labels"
                                    name="labels"
                                    as={Select}
                                    options={getLabelOptions(allLabels, allFolders, department, opEntry.labelUris)}
                                    onChange={(selectedOptions) => {
                                        setFieldValue('label', selectedOptions ?? []);
                                    }}
                                />
                            </Form.Group>
                        </Modal.Body>

                        <ModalFooter
                            isSubmitting={isSubmitting}
                            isValid={isValid}
                            dirty={dirty}
                            onHide={() => handleClose()}
                        />
                    </FForm>
                )}
            </Formik>
        </FormModal>
    );
}

const AddOpEntryLabelSchema = Yup.object().shape({
    label: Yup.object().required(),
});
