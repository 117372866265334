import { generatePath, useHistory, useParams } from 'react-router-dom';
import {
    useDeleteCollectiveMutation,
    useGetCollectivesQuery,
} from '../../../../features/reimbursements/reimbursements';
import { ExclamationTriangle, JournalRichtext, PencilSquare } from 'react-bootstrap-icons';
import AssignLabelButton from '../../../labels/AssignLabelButton';
import { Dropdown, SplitButton } from 'react-bootstrap';
import { COLLECTIVES_EDIT_PATH } from '../../../../scenes/Reimbursements';
import HelperFunctions from '../../../global/HelperFunctions';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import cx from 'classnames';
import { RxDragHandleDots2 } from 'react-icons/rx';
import { useTranslation } from 'react-i18next';

export function Collective({ categoryUri, collectiveId, collectiveGroupId, index }) {
    const history = useHistory();
    const [deleteCollective] = useDeleteCollectiveMutation();
    const { categoryId } = useParams();
    const { collective } = useGetCollectivesQuery(
        { uri: categoryUri },
        {
            selectFromResult: ({ data }) => ({
                collective: data.find((_item) => _item.id === collectiveId),
            }),
        }
    );
    const { id, name, description = '', code = '', variantPackages = [] } = collective;

    const canDelete = variantPackages.length === 0;
    const { t } = useTranslation('reimbursements');

    return (
        <Draggable draggableId={collective['@id']} index={index}>
            {(provided, snapshot) => (
                <div
                    className={cx('data-table-item-wrapper', {
                        'is-dragging': snapshot.isDragging,
                    })}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <div className="data-table-item">
                        <div className="data-table-item-header px-4 py-3">
                            <div className="d-flex align-items-center">
                                <RxDragHandleDots2 size={12} className="icon-grip text-muted" />

                                <div className="d-flex align-items-center mr-3">
                                    <JournalRichtext size={18} className="flex-shrink-0 text-dark-blue mr-2" />
                                    {code !== '' && <div className="text-secondary mr-2">{code}</div>}
                                </div>

                                <div className="d-flex flex-column" style={{ marginBlock: -3.5 }}>
                                    <div className="d-flex align-items-center">
                                        <div className="field-title mr-2">{name}</div>

                                        <AssignLabelButton
                                            context={{
                                                entity: {
                                                    ...collective,
                                                    title: collective.name,
                                                },
                                                entityType: 'collective',
                                            }}
                                        />
                                    </div>

                                    {description !== '' && (
                                        <div
                                            className="d-inline-block text-truncate text-secondary"
                                            style={{ fontSize: 11 }}
                                        >
                                            {description}
                                        </div>
                                    )}
                                </div>

                                <div className="ml-auto">
                                    <SplitButton
                                        id={`btn-edit-${id}`}
                                        variant="primary"
                                        title={
                                            <>
                                                <PencilSquare />
                                                <span>{t('collectivity.editCollectivity.properties.title')}</span>
                                            </>
                                        }
                                        onClick={() => {
                                            history.push(
                                                generatePath(COLLECTIVES_EDIT_PATH, {
                                                    categoryId,
                                                    action: 'properties',
                                                    folderId: collectiveGroupId,
                                                    collectiveId: id,
                                                })
                                            );
                                        }}
                                        alignRight={true}
                                    >
                                        <Dropdown.Item
                                            onClick={() => {
                                                if (canDelete) {
                                                    HelperFunctions.confirmModal(
                                                        `${
                                                            (t('collectivity.editCollectivity.deleteQuestion'),
                                                            { name: name })
                                                        }`,
                                                        'danger',
                                                        false,
                                                        t('collectivity.editCollectivity.btn.delete'),
                                                        t('btn.cancel')
                                                    ).then(() => {
                                                        deleteCollective(collective['@id']);
                                                    });
                                                } else {
                                                    HelperFunctions.alertModal(
                                                        t('collectivity.editCollectivity.cannotDelete', { name })
                                                    );
                                                }
                                            }}
                                        >
                                            <span className="text-danger">
                                                {!canDelete && <ExclamationTriangle className="mr-1" />}
                                                {t('collectivity.editCollectivity.properties.delete')}
                                            </span>
                                        </Dropdown.Item>
                                    </SplitButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Draggable>
    );
}
