import { PaginatedTable, TableProvider } from 'components/PaginatedTable';
import { ArrowDownShort } from 'react-bootstrap-icons';
import { DeleteButton, EditButton } from 'components/ActionButtons';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';
import { NewItemButton } from 'components/Buttons';
import { useDeleteChecklistMutation } from 'features/comments/commentApi';
import { useParams } from 'react-router-dom';
import HelperFunctions from 'pages/global/HelperFunctions';
import { useTranslation } from 'react-i18next';
import { AddChecklistModal } from 'pages/task_team/modals/AddChecklistModal';
import { useGetTeamChecklists } from 'pages/task_team/hooks/useGetTeamChecklists';
import _ from 'lodash';
import { EditChecklistModal } from 'pages/task_team/modals/EditChecklistModal';

export function Checklist({ team }) {
    const { id } = useParams();
    const checklists = useGetTeamChecklists(id);
    const [, setAddChecklist] = useQueryParam('addChecklist', BooleanParam);

    return (
        <TableProvider>
            <AddChecklistModal team={team} />
            <EditChecklistModal team={team} />

            <div className="subheader">
                <h3>
                    Checklist <span className="text-muted ml-1">{checklists.length}</span>
                </h3>

                <div className="d-flex align-items-center ml-auto">
                    <NewItemButton size="sm" label="Checklist toevoegen" onClick={() => setAddChecklist(true)} />
                </div>
            </div>

            <PaginatedTable
                items={checklists}
                searchPlaceholder="Zoeken..."
                searchProps={['title', 'content']}
                colSpan={3}
            >
                {({ items = [] }) => (
                    <>
                        <thead>
                            <tr>
                                <th>
                                    Titel <ArrowDownShort />
                                </th>
                                <th>Content</th>
                                <th>Acties</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((checklist) => (
                                <ChecklistRow checklist={checklist} key={`checklist-${checklist.id}`} />
                            ))}
                        </tbody>
                    </>
                )}
            </PaginatedTable>
        </TableProvider>
    );
}

function ChecklistRow({ checklist }) {
    const { t } = useTranslation();
    const [deleteChecklist] = useDeleteChecklistMutation();
    const [, setEditChecklist] = useQueryParam('editChecklist', StringParam);

    return (
        <tr>
            <td>{checklist.title}</td>
            <td>
                {_.truncate(checklist.content, {
                    length: 25,
                    separator: ' ',
                })}
            </td>
            <td className="actions">
                <div>
                    <EditButton onClick={() => setEditChecklist(checklist.id)} />
                    <DeleteButton onClick={handleDelete} />
                </div>
            </td>
        </tr>
    );

    function handleDelete() {
        HelperFunctions.confirmModal(
            `Deze regel verwijderen?`,
            'danger',
            false,
            t('global:confirm.yesDelete'),
            t('global:btn.cancel'),
        )
            .then(() => {
                deleteChecklist(checklist['@id']);
            })
            .catch(() => {});
    }
}
