import Constants, { Permissions } from '../../../../config/Constants';
import { Dropdown, SplitButton } from 'react-bootstrap';
import RestrictedContent from '../../../global/RestrictedContent';
import { useContext, useState } from 'react';
import { AddBlockModal } from '../../../documents_v2/modals/AddBlockModal';
import { AreaContext } from '../../../documents_v2/views/Area';
import { useGetDocument } from '../../../documents_v2/hooks/useGetDocument';
import { useGetBaseVariant } from '../../../documents_v2/hooks/useGetBaseVariant';
import getTemplateParameters from '../../../../components/SettingsSidebar/views/TemplateConstants';
import { useTranslation } from 'react-i18next';
import { useIsLoading } from 'pages/documents_v2/views/edit_area/useIsLoading';
import { EditAreaContext } from 'pages/documents_v2/views/edit_area/EditArea';

export default function AddBlockButton({ area, areaBlocks}) {
    const document = useGetDocument();
    const baseVariant = useGetBaseVariant();
    const { activeVariantId } = useContext(AreaContext);
    const { addBlock } = useContext(EditAreaContext);
    const isLoading = useIsLoading();

    const [showAddBlockModal, setShowAddBlockModal] = useState(false);
    const [defaultBlockType, setDefaultBlockType] = useState(Constants.blockTypes.text);
    const [defaultVariants, setDefaultVariants] = useState([]);
    const [defaultVariantOption, setDefaultVariantOption] = useState('all');

    const buttonDisabled = activeVariantId === 0 || isLoading;
    const { t } = useTranslation('documents');

    const setVariants = () => {
        if (activeVariantId === baseVariant.id) {
            setDefaultVariants([]);
            setDefaultVariantOption('all');
        } else {
            setDefaultVariants([activeVariantId]);
            setDefaultVariantOption('select');
        }
    };

    const handleClick = () => {
        setDefaultBlockType(Constants.blockTypes.text);
        setVariants();
        setShowAddBlockModal(true);
    };

    const onSubmit = (values) => {
        let properties = {};

        if (values.blockType === Constants.blockTypes.table) {
            properties.templateId = values.templateId;
        }

        const templateParameters = getTemplateParameters(values.blockType);
        const justTheParameters = Object.assign({}, ...Object.values(templateParameters));

        properties = { ...properties, templateParameters: justTheParameters };

        addBlock(values.blockType, values.variants, properties);

        // Close modal
        setShowAddBlockModal(false);
    };

    const handleSelect = (eventKey) => {
        setDefaultBlockType(eventKey);
        setVariants();
        setShowAddBlockModal(true);
    };

    const canAddLinkedBlock = document.type !== Constants.documentType.model && !document.modelId;

    return (
        <>
            <SplitButton
                id="btn-add-new-block"
                className="dropdown-absolute"
                variant="info"
                title={t('document.navbar.main.editor.left.subnav.addBlock.addBlock')}
                menuAlign="right"
                onSelect={handleSelect}
                onClick={handleClick}
                disabled={buttonDisabled}
            >
                <Dropdown.Item eventKey={Constants.blockTypes.text}>
                    {t('document.navbar.main.editor.left.subnav.addBlock.blocks.text')}
                </Dropdown.Item>
                <Dropdown.Item eventKey={Constants.blockTypes.textExplanation}>
                    {t('document.navbar.main.editor.left.subnav.addBlock.blocks.textWithExplanation')}
                </Dropdown.Item>
                <Dropdown.Item eventKey="intro">
                    {t('document.navbar.main.editor.left.subnav.addBlock.blocks.introtext')}
                </Dropdown.Item>
                <Dropdown.Item eventKey="attention">
                    {t('document.navbar.main.editor.left.subnav.addBlock.blocks.attention')}
                </Dropdown.Item>
                <Dropdown.Item eventKey="special">
                    {t('document.navbar.main.editor.left.subnav.addBlock.blocks.special')}
                </Dropdown.Item>
                <Dropdown.Item eventKey="info">
                    {t('document.navbar.main.editor.left.subnav.addBlock.blocks.info')}
                </Dropdown.Item>

                {document.type !== Constants.documentType.library && (
                    <>
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey={Constants.blockTypes.file}>
                            {t('document.navbar.main.editor.left.subnav.addBlock.blocks.image')}
                        </Dropdown.Item>
                        <Dropdown.Item eventKey={Constants.blockTypes.blockLayout}>
                            {t('document.navbar.main.editor.left.subnav.addBlock.blocks.tableLayout')}
                        </Dropdown.Item>
                        <Dropdown.Item eventKey={Constants.blockTypes.table}>
                            {t('document.navbar.main.editor.left.subnav.addBlock.blocks.table')}
                        </Dropdown.Item>

                        <RestrictedContent module={Constants.modules.labels}>
                            <Dropdown.Item eventKey={Constants.blockTypes.label}>
                                {t('document.navbar.main.editor.left.subnav.addBlock.blocks.options')}
                            </Dropdown.Item>
                        </RestrictedContent>

                        <RestrictedContent
                            permission={Permissions.Reimbursements['Write.All']}
                            module={Constants.modules.reimbursements}
                        >
                            <Dropdown.Item
                                eventKey={Constants.blockTypes.reimbursement}
                                disabled={document.reimbursementCategoryId === null}
                            >
                                {t('document.navbar.main.editor.left.subnav.addBlock.blocks.package')}
                            </Dropdown.Item>
                        </RestrictedContent>
                    </>
                )}

                {canAddLinkedBlock && (
                    <>
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey={Constants.blockTypes.linked}>
                            {t('document.navbar.main.editor.left.subnav.addBlock.blocks.linked')}
                        </Dropdown.Item>
                    </>
                )}
            </SplitButton>

            {showAddBlockModal && (
                <AddBlockModal
                    defaultBlockType={defaultBlockType}
                    hide={() => setShowAddBlockModal(false)}
                    onSubmit={onSubmit}
                    defaultVariants={defaultVariants}
                    defaultVariantOption={defaultVariantOption}
                    area={area}
                    areaBlocks={areaBlocks}
                />
            )}
        </>
    );
}
