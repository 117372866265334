import { LabelBlockContent } from '../../documents/misc/_Blocks/LabelBlock';
import HelperFunctions from '../../global/HelperFunctions';

export function LabelBlock({ opEntryBlock, showChanges }) {
    const content = showChanges ? opEntryBlock.renderedDiffContent : opEntryBlock.latestContent;

    return (
        <LabelBlockContent
            latestContent={HelperFunctions.tryParseJSON(content, {})}
            block={{
                properties: {
                    multiple: true,
                },
                key: opEntryBlock.blockId,
            }}
            markChanges={showChanges}
        />
    );
}
