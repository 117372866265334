import { useGetTaskTeamResultsMutation } from 'features/comments/commentApi';
import { useContext, useEffect, useState } from 'react';
import HelperFunctions from 'pages/global/HelperFunctions';
import { Accordion, Card } from 'react-bootstrap';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import cx from 'classnames';
import Parser from 'html-react-parser';
import { TranslatableSwitch } from 'pages/publications_v2/helpers/FieldHelper';

export function TaskChecklist({ task, assignment }) {
    const [getTaskTeamResults] = useGetTaskTeamResultsMutation();
    const [checklists, setChecklists] = useState([]);
    const [showAllItems, setShowAllItems] = useState(false);

    useEffect(() => {
        getTaskTeamResults(task).then(({ data }) => {
            if (data) {
                const { checklists = [] } = data;

                const sortedChecklists = [...checklists].sort(HelperFunctions.sortByString('title'));
                setChecklists(sortedChecklists);
            }
        });
    }, []);

    const showToggle = assignment !== undefined;
    const checklistsToShow = groupChecklists(getChecklists());

    return (
        <div>
            {showToggle && (
                <div className="mb-3" style={{ fontSize: 14 }}>
                    <TranslatableSwitch
                        label={showAllItems ? 'Toon alles' : 'Alleen van team'}
                        id="show-all"
                        name="show-all"
                        checked={showAllItems}
                        onChange={() => setShowAllItems(!showAllItems)}
                    />
                </div>
            )}
            <Checklists checklists={checklistsToShow} />
        </div>
    );

    function getChecklists() {
        if (!showToggle || showAllItems) {
            return checklists;
        }

        return checklists.filter((checklist) => checklist.team === assignment.team);
    }

    function groupChecklists(checklists) {
        return Object.groupBy(checklists, ({ title }) => title);
    }
}

function Checklists({ checklists = {} }) {
    return (
        <Accordion>
            <ChecklistItems checklists={checklists} />
        </Accordion>
    );
}

function ChecklistItems({ checklists = {} }) {
    const expandedKey = useContext(AccordionContext);

    return (
        <>
            {Object.keys(checklists).map((title, index) => {
                const checklist = checklists[title];

                return (
                    <Card key={`checklist-row-${index}`}>
                        <Accordion.Toggle
                            as={Card.Header}
                            className="d-flex align-items-center cursor-pointer"
                            eventKey={title}
                        >
                            <div className="mr-2">{title}</div>
                            {expandedKey === title ? <ChevronUp /> : <ChevronDown />}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={title}>
                            <Card.Body>
                                {checklist.map((checklistItem, index) => {
                                    const isLast = index >= checklist.length - 1;

                                    return (
                                        <div className={cx({ 'mb-3': !isLast })} key={`checklist-${checklistItem.id}`}>
                                            {Parser(checklistItem.content)}
                                        </div>
                                    );
                                })}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                );
            })}
        </>
    );
}
