import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormModal } from '../global/FormModal';
import Spinner from '../global/Spinner';
import CloneApi from '../../api/CloneApi';
import { reimbursementApi } from '../../features/reimbursements/reimbursements';
import { showError } from '../../helpers/errorHelper';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

export function CloneProductModal({ reloadProducts, handleClose, product = undefined }) {
    const dispatch = useDispatch();
    const [cloneInProgress, setCloneInProgress] = useState(false);
    const { t } = useTranslation('reimbursements');

    const onClose = () => {
        if (!cloneInProgress) {
            handleClose();
        }
    };

    const handleClone = async () => {
        setCloneInProgress(true);

        CloneApi.cloneEntity('products', product.id)
            .then(() => {
                // Invalidate Tags
                dispatch(
                    reimbursementApi.util.invalidateTags([
                        { type: 'Product', id: 'LIST' },
                        { type: 'Reimbursement', id: 'LIST' },
                    ])
                );

                reloadProducts().then(() => {
                    setCloneInProgress(false);
                    handleClose();
                });
            })
            .catch(() => {
                showError(t('policy.errorMessage.errorDuplicating'));
                setCloneInProgress(false);
                handleClose();
            });
    };

    return (
        <FormModal show={!!product} onHide={onClose} title={t('policy.duplicateModal.title')}>
            {product && (
                <>
                    <Modal.Body>
                        <>
                            <div>
                                {t('policy.duplicateModal.policy')} <span className="text-muted">{product.code}</span>{' '}
                                <span className="font-weight-bold">{product.name}</span>{' '}
                                {t('policy.duplicateModal.duplicate')}
                            </div>

                            {cloneInProgress && (
                                <div className="mt-3 text-danger">{t('policy.duplicateModal.note')}</div>
                            )}
                        </>
                    </Modal.Body>

                    <Modal.Footer>
                        {cloneInProgress && <Spinner />}

                        <Button type="button" variant="btn btn-secondary" disabled={cloneInProgress} onClick={onClose}>
                            {t('btn.cancel')}
                        </Button>
                        <Button
                            type="button"
                            variant="btn btn-warning "
                            disabled={cloneInProgress}
                            onClick={handleClone}
                        >
                            {cloneInProgress ? (
                                <>{t('policy.duplicateModal.busy')}&hellip;</>
                            ) : (
                                <>{t('policy.duplicateModal.duplicateNow')}</>
                            )}
                        </Button>
                    </Modal.Footer>
                </>
            )}
        </FormModal>
    );
}
