import {
    useGetPublicationGroup,
    useGetPublicationUsersForGroup,
    useGetPublicationUsersForPublication,
} from 'pages/publications_v2/hooks/publicationUsers';
import { PaginatedTable, TableProvider, useSelectedItems, useSelectedItemsDispatch } from 'components/PaginatedTable';
import { ArrowDownShort, CaretDownFill, CaretRightFill, ChevronRight, SendPlus } from 'react-bootstrap-icons';
import { Checkbox, TranslatableSwitch } from 'pages/publications_v2/helpers/FieldHelper';
import {
    publicationApi,
    useAddPublicationUserPasswordMutation,
    useEditPublicationUserPasswordMutation,
    usePostForBatchMutation,
    usePutForBatchMutation,
} from 'features/publications/publicationApi';
import HelperFunctions from 'pages/global/HelperFunctions';
import { GenericButton } from 'components/Buttons';
import { DateTime } from 'luxon';
import cx from 'classnames';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath, Link, useParams } from 'react-router-dom';
import { EDIT_PATH } from 'scenes/PublicationsV2';
import { parseInt } from 'lodash';
import { NumberParam, useQueryParam } from 'use-query-params';
import { ExpandedUserRow } from 'pages/publicationUsers/ViewUser';
import { useGetPublication } from 'pages/publications_v2/hooks/useGetPublication';
import { SelectAllCheckbox } from 'components/SelectAllCheckbox';

export function PublicationUsers() {
    const { id, publicationId } = useParams();
    const publicationIdAsInt = parseInt(publicationId);
    const publication = useGetPublication(publicationIdAsInt);
    const userPasswords = useGetPublicationUsersForPublication(publicationIdAsInt);
    const group = useGetPublicationGroup(parseInt(id));

    return (
        <div className="dr-container p-4">
            <div className="subheader justify-content-between">
                <h3>
                    <Link
                        className="text-muted"
                        to={generatePath(EDIT_PATH, {
                            publicationId,
                            action: 'password_protection',
                            groupId: id,
                        })}
                    >
                        {publication?.name}
                    </Link>
                    <ChevronRight className="text-muted mx-2" />
                    {group?.name}
                </h3>
            </div>

            <TableProvider>
                <RenderPublicationUsers group={group} userPasswords={userPasswords} />
            </TableProvider>
        </div>
    );
}

function RenderPublicationUsers({ group, userPasswords = [] }) {
    const { t } = useTranslation();
    const users = useGetPublicationUsersForGroup(group);
    const { selectedItems = [] } = useSelectedItems();

    return (
        <PaginatedTable
            items={users}
            searchPlaceholder={t('publications:accessControl.searchUserPlaceholder')}
            colSpan={5}
            actionsButton={<Actions />}
        >
            {({ items = [] }) => (
                <>
                    <thead>
                        <tr>
                            <th>
                                <SelectAllCheckbox items={items} />
                            </th>
                            <th>
                                {t('publications:accessControl.headers.name')} <ArrowDownShort />
                            </th>
                            <th>{t('publications:accessControl.headers.login')}</th>
                            <th>{t('global:btn.actions')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((publicationUser) => (
                            <UserRow
                                publicationUser={publicationUser}
                                userPassword={userPasswords.find(
                                    (userPassword) => userPassword.publicationUser['@id'] === publicationUser['@id']
                                )}
                                isSelected={selectedItems.includes(publicationUser['@id'])}
                                key={`user-item-${publicationUser.id}`}
                            />
                        ))}
                    </tbody>
                </>
            )}
        </PaginatedTable>
    );
}

function UserRow({ publicationUser, isSelected = false, userPassword }) {
    const dispatch = useSelectedItemsDispatch();
    const [expanded, setExpanded] = useQueryParam('expand', NumberParam);

    const isExpanded = expanded === publicationUser.id;

    return (
        <>
            <tr className={cx({ 'row-selected': isSelected, 'is-expanded': isExpanded })}>
                <td>
                    <Checkbox
                        id={`user-checkbox-${publicationUser.id}`}
                        checked={isSelected}
                        onChange={() => {
                            dispatch({
                                type: 'toggle',
                                id: publicationUser['@id'],
                            });
                        }}
                    />
                </td>
                <td>
                    <a
                        href="#"
                        className="d-flex align-items-center row-toggle"
                        onClick={(event) => {
                            event.preventDefault();
                            setExpanded(isExpanded ? undefined : publicationUser.id);
                        }}
                    >
                        {isExpanded ? <CaretDownFill /> : <CaretRightFill />}
                        <div className="ml-2">{publicationUser.fullName}</div>
                    </a>
                </td>
                <td>
                    <UserPublicationSwitch publicationUser={publicationUser} userPassword={userPassword} />
                </td>
                <td className="actions">
                    <div>
                        <SendLoginButton publicationUser={publicationUser} userPassword={userPassword} />
                    </div>
                </td>
            </tr>
            {isExpanded && <ExpandedUserRow userPassword={userPassword} colSpan={4} />}
        </>
    );
}

export function UserPublicationSwitch({ publicationUser, userPassword }) {
    const { publicationId } = useParams();
    const { t, i18n } = useTranslation();
    const [editPublicationUserPassword] = useEditPublicationUserPasswordMutation();
    const [addPublicationUserPassword] = useAddPublicationUserPasswordMutation();
    const organisationId = useActiveOrganisation();

    const passwordSent = userPassword && userPassword.enabled && userPassword.passwordCreatedAt;

    return (
        <>
            <TranslatableSwitch
                id={`user-${publicationUser.id}-enabled`}
                name={`user-${publicationUser.id}-enabled`}
                checked={userPassword && userPassword.enabled}
                onChange={handleEnabledChange}
                disabled={!userPassword}
            />
            {passwordSent && (
                <div className="extra-small mt-1">
                    {t('publications:accessControl.passwordSentAt', { date: getPasswordCreatedAt() })}
                </div>
            )}

            {userPassword === undefined && (
                <div className="extra-small text-warning mt-1">
                    {t('publications:accessControl.passwordNotYetSent')}
                </div>
            )}
        </>
    );

    function getPasswordCreatedAt() {
        const createdAt = DateTime.fromISO(userPassword.passwordCreatedAt).setLocale(i18n.language);
        return createdAt.toLocaleString(DateTime.DATETIME_MED);
    }

    function handleEnabledChange() {
        if (userPassword) {
            // edit
            editPublicationUserPassword({
                uri: userPassword['@id'],
                body: {
                    enabled: !userPassword.enabled,
                },
            });
        } else {
            // create
            addPublicationUserPassword({
                organisationId,
                publication: `/api/publications/${publicationId}`,
                publicationUser: publicationUser['@id'],
                enabled: true,
            });
        }
    }
}

export function SendLoginButton({ publicationUser, userPassword, isDisabled = false }) {
    const { publicationId } = useParams();
    const { t } = useTranslation();
    const [editPublicationUserPassword] = useEditPublicationUserPasswordMutation();
    const [addPublicationUserPassword] = useAddPublicationUserPasswordMutation();
    const organisationId = useActiveOrganisation();

    const userHasLogin = userPassword && userPassword.enabled === true;
    const userHasPassword = userHasLogin && userPassword.passwordCreatedAt !== null;

    return (
        <GenericButton
            className="d-flex align-items-center flex-shrink-0"
            variant="link"
            onClick={handlePasswordClick}
            disabled={(userPassword && !userPassword.enabled) || isDisabled}
        >
            <SendPlus className="flex-shrink-0 mr-2" size={16} />
            <div>{t('publications:accessControl.btn.sendLogin')}</div>
        </GenericButton>
    );

    function handlePasswordClick() {
        if (userHasPassword) {
            HelperFunctions.confirmModal(
                t('publications:accessControl.confirmSendNewPassword'),
                undefined,
                false,
                t('publications:accessControl.btn.yesSend'),
                t('global:btn.cancel')
            )
                .then(() => {
                    generatePasswordAction();
                })
                .catch(() => {});
        } else {
            generatePasswordAction();
        }
    }

    function generatePasswordAction() {
        if (userPassword) {
            // edit
            editPublicationUserPassword({
                uri: userPassword['@id'],
                body: {
                    enabled: true,
                    plainPassword: 'new',
                },
            });
        } else {
            // create
            addPublicationUserPassword({
                organisationId,
                publication: `/api/publications/${publicationId}`,
                publicationUser: publicationUser['@id'],
                enabled: true,
                plainPassword: 'new',
            });
        }
    }
}

function Actions() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { selectedItems = [] } = useSelectedItems();
    const selectedItemsDispatch = useSelectedItemsDispatch();
    const [putForBatch] = usePutForBatchMutation();
    const [postForBatch] = usePostForBatchMutation();
    const { publicationId } = useParams();
    const publicationIdAsInt = parseInt(publicationId);
    const userPasswords = useGetPublicationUsersForPublication(publicationIdAsInt);
    const organisationId = useActiveOrganisation();

    return (
        <div className="d-flex align-items-center">
            <DropdownButton
                id="actions-dropdown"
                title={t('publications:accessControl.actions')}
                variant="primary"
                disabled={selectedItems.length === 0}
                size="sm"
            >
                <Dropdown.Item onClick={sendLogins}>
                    {t('publications:accessControl.btn.sendLogin')}&hellip;
                </Dropdown.Item>
            </DropdownButton>

            <div className="text-muted small ml-2">
                {selectedItems.length > 0 && (
                    <>{t('publications:accessControl.numUsersSelected', { count: selectedItems.length })}</>
                )}
            </div>
        </div>
    );

    function sendLogins() {
        HelperFunctions.confirmModal(
            t('publications:accessControl.confirmSendNewPassword'),
            undefined,
            false,
            t('publications:accessControl.btn.yesSend'),
            t('global:btn.cancel')
        )
            .then(() => {
                new Promise(async (resolve) => {
                    const sendUserLogins = async () => {
                        for (const publicationUserUri of selectedItems) {
                            const userPassword = userPasswords.find(
                                (userPassword) => userPassword.publicationUser['@id'] === publicationUserUri
                            );

                            if (userPassword) {
                                // update
                                await putForBatch({
                                    uri: userPassword['@id'],
                                    body: {
                                        enabled: true,
                                        plainPassword: 'new',
                                    },
                                });
                            } else {
                                // create
                                await postForBatch({
                                    uri: '/api/publication_user_passwords',
                                    body: {
                                        organisationId,
                                        publication: `/api/publications/${publicationId}`,
                                        publicationUser: publicationUserUri,
                                        enabled: true,
                                    },
                                });
                            }
                        }
                    };

                    await sendUserLogins();
                    resolve();
                }).then(() => {
                    // Reload data
                    dispatch(publicationApi.util.invalidateTags([{ type: 'PublicationUserPassword', id: 'LIST' }]));

                    selectedItemsDispatch({
                        type: 'select',
                        items: [],
                    });
                });
            })
            .catch(() => {});
    }
}
