import { Form } from 'react-bootstrap';
import { Field, Form as FForm, Formik, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { selectDepartmentTeams, updateOpListFilter } from '../../../features/operationsList/operationsListSlice';
import {
    selectDepartmentLabels,
    selectDepartmentsOpListUsers,
    selectDepartmentUserBelongsTo,
} from '../../../features/operationsList/opListUserSlice';
import Constants from '../../../config/Constants';
import { convertArrayToObject, Labels, Processors, StatusCodes, Teams, TypeOfEdit } from '../forms/FilterFields';
import { useEffect, useMemo } from 'react';
import { debounce } from 'lodash';
import { useGetLabelsQuery } from '../../../features/metadata/metadata';
import { useTranslation } from 'react-i18next';
import { useGetOrganisationUsers } from 'hooks/useGetOrganisationUsers';

export default function OpTeamEntrySidebar({ opList, searchQuery, disabled }) {
    const dispatch = useDispatch();
    const { opListFilter } = useSelector((state) => state.operations);
    const organisationUsers = useGetOrganisationUsers();
    const departmentUsers = useSelector(selectDepartmentsOpListUsers(opList, organisationUsers));
    const teams = useSelector(selectDepartmentTeams(opList));
    const department = useSelector(selectDepartmentUserBelongsTo(opList));
    const { activeOrganisation } = useSelector((state) => state.security);

    const { organisationLabels = [] } = useGetLabelsQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                organisationLabels: data ?? [],
            }),
        }
    );

    const labels = useSelector(selectDepartmentLabels(opList, organisationLabels));

    const handleSubmit = (values) => {
        dispatch(updateOpListFilter({ opListId: opList.id, formData: values }));
    };

    const initialEditTypes = {};

    Object.keys(Constants.opEntry.typeOfEdits).map(function (key, index) {
        initialEditTypes[key] = false;
    });

    const userIsAdmin = opList && (opList.userIsAdmin || opList.userIsEditor);

    const initialValues = opListFilter[opList.id] ?? {
        'filter-2': false, // Gearchiveerde wijzigingen
        'filter-3': true, // Toegewezen aan anderen
        'filter-4': false, // Toon uitgebreide status informatie
        'filter-5': true, // Vervallen wijzigingen
        'filter-6': true, // Toegewezen aan mij
        'filter-7': true, // Niet toegewezen
        'filter-status': convertArrayToObject(department?.statusCodes ?? [], 'slug'),
        'filter-user': convertArrayToObject(departmentUsers, 'userId'),
        'filter-team': convertArrayToObject(teams, '@id'),
        'filter-label': convertArrayToObject(labels, '@id'),
        'filter-type': initialEditTypes,
        searchQuery: '',
    };

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
            {() => (
                <SidebarForm
                    teams={teams}
                    department={department}
                    departmentUsers={departmentUsers}
                    userIsAdmin={userIsAdmin}
                    disabled={disabled}
                    labels={labels}
                    searchQuery={searchQuery}
                />
            )}
        </Formik>
    );
}

function SidebarForm({ userIsAdmin, department, teams, departmentUsers, disabled, labels, searchQuery }) {
    const { submitForm, dirty, values, setFieldValue } = useFormikContext();
    const { t } = useTranslation('changelist');

    useEffect(() => {
        if (dirty) {
            submitForm();
        }
    }, [dirty]);

    const debouncedSearchQuery = useMemo(() => {
        return debounce((searchValue) => {
            setFieldValue('searchQuery', searchValue);
        }, 600);
    }, []);

    useEffect(() => {
        if (searchQuery !== values.searchQuery) {
            debouncedSearchQuery(searchQuery);
        }
    }, [searchQuery]);

    return (
        <div className="pt-4 pl-3 pr-2 pb-2">
            <FForm autoComplete="off">
                <div className="font-weight-bold  mb-1">{t('changelist.subnav.settings.settings')}</div>
                <div className="small mb-3">
                    <div className="mb-2">
                        <Field
                            as={Form.Check}
                            type="checkbox"
                            name="filter-4"
                            id="filter-4"
                            label={t('changelist.subnav.settings.sidebar.showExtended')}
                            disabled={disabled}
                        />
                    </div>
                </div>

                <div className="font-weight-bold mb-1">Filters</div>
                <div className="small mb-3">
                    {!userIsAdmin && (
                        <>
                            <div className="mb-2">
                                <Field
                                    as={Form.Check}
                                    type="checkbox"
                                    name="filter-6"
                                    id="filter-6"
                                    label={t('changelist.subnav.settings.sidebar.assignedToMe')}
                                    disabled={disabled}
                                />
                            </div>
                            <div className="mb-2">
                                <Field
                                    as={Form.Check}
                                    type="checkbox"
                                    name="filter-3"
                                    id="filter-3"
                                    label={t('changelist.subnav.settings.sidebar.assignedToOthers')}
                                    disabled={disabled}
                                />
                            </div>
                            <div className="mb-2">
                                <Field
                                    as={Form.Check}
                                    type="checkbox"
                                    name="filter-7"
                                    id="filter-7"
                                    label={t('changelist.subnav.settings.sidebar.notAssigned')}
                                    disabled={disabled}
                                />
                            </div>
                        </>
                    )}
                    <div className="mb-2">
                        <Field
                            as={Form.Check}
                            type="checkbox"
                            name="filter-5"
                            id="filter-5"
                            label={t('changelist.subnav.settings.sidebar.expired')}
                            disabled={disabled}
                        />
                    </div>
                    <div className="mb-2">
                        <Field
                            as={Form.Check}
                            type="checkbox"
                            name="filter-2"
                            id="filter-2"
                            label={t('changelist.subnav.settings.sidebar.archivedChanges')}
                            disabled={disabled}
                        />
                    </div>
                </div>

                {!userIsAdmin && <StatusCodes statusCodes={department?.statusCodes ?? []} disabled={disabled} />}

                {!userIsAdmin && <Teams teams={teams} disabled={disabled} />}

                {!userIsAdmin && <Processors departmentUsers={departmentUsers} disabled={disabled} />}

                {/* Only show label filter if the department splits entries by labels, else there is no point for these filters */}
                {(department?.splitPerLabel ?? false) && <Labels labels={labels} disabled={disabled} />}

                <TypeOfEdit typeOfEdits={Constants.opEntry.typeOfEdits} disabled={disabled} />
            </FForm>
        </div>
    );
}
