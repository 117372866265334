import { PaginatedTable, TableProvider } from 'components/PaginatedTable';
import { ArrowDownShort, CheckCircleFill, XCircleFill } from 'react-bootstrap-icons';
import { DeleteButton, EditButton } from 'components/ActionButtons';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { NewItemButton } from 'components/Buttons';
import { useShowSettingsSidebar } from 'components/SettingsSidebar/hooks/useShowSettingsSidebar';
import { useDeleteTeamUserMutation } from 'features/comments/commentApi';
import { useParams } from 'react-router-dom';
import { useGetTeamUsers } from 'pages/task_team/hooks/useGetTeamUsers';
import HelperFunctions from 'pages/global/HelperFunctions';
import { useTranslation } from 'react-i18next';
import { entityTypes } from 'pages/translation/config/Constants';
import { AddTeamUserModal } from 'pages/task_team/modals/AddTeamUserModal';
import cx from 'classnames';
import { Can } from 'ability/Can';
import { useGetTeamUser } from 'pages/task_team/hooks/useGetTeamUser';

export function TeamUsers({ team }) {
    const { id } = useParams();
    const [, setAddTeamUser] = useQueryParam('addTeamUser', BooleanParam);
    const teamUsers = useGetTeamUsers(id);
    const teamUser = useGetTeamUser(id);

    return (
        <TableProvider>
            <AddTeamUserModal team={team} />

            <div className="subheader">
                <h3>
                    Gebruikers <span className="text-muted ml-1">{teamUsers.length}</span>
                </h3>

                <Can I="manage" a={teamUser}>
                    <div className="d-flex align-items-center ml-auto">
                        <NewItemButton size="sm" label="Gebruiker toevoegen" onClick={() => setAddTeamUser(true)} />
                    </div>
                </Can>
            </div>

            <PaginatedTable
                items={teamUsers}
                searchPlaceholder="Zoeken..."
                searchProps={['user.fullName']}
                colSpan={5}
            >
                {({ items = [] }) => (
                    <>
                        <thead>
                            <tr>
                                <th>
                                    Naam <ArrowDownShort />
                                </th>
                                <th className="text-center">Indienen</th>
                                <th className="text-center">Beoordelen</th>
                                <Can I="manage" a={teamUser}>
                                    <th>Acties</th>
                                </Can>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((teamUser) => (
                                <TeamUser teamUser={teamUser} team={team} key={`teamUser-${teamUser.id}`} />
                            ))}
                        </tbody>
                    </>
                )}
            </PaginatedTable>
        </TableProvider>
    );
}

function TeamUser({ teamUser, team }) {
    const { t } = useTranslation();
    const [showSettingsSidebar] = useShowSettingsSidebar();
    const [deleteTeamUser] = useDeleteTeamUserMutation();
    const currentTeamUser = useGetTeamUser(team.id);

    const { user } = teamUser;

    return (
        <tr>
            <td className={cx({ 'text-muted': user.enabled === false })}>
                {user.fullName}
                {user.enabled === false && <span>&nbsp;(inactief)</span>}
            </td>
            <td className="text-center">
                {team.permissions.canCreateTask && teamUser.permissions.canCreateTask ? (
                    <CheckCircleFill className="text-success" />
                ) : (
                    <XCircleFill className="text-danger" />
                )}
            </td>
            <td className="text-center">
                {team.permissions.canReviewTask && teamUser.permissions.canReviewTask ? (
                    <CheckCircleFill className="text-success" />
                ) : (
                    <XCircleFill className="text-danger" />
                )}
            </td>
            <Can I="manage" a={currentTeamUser}>
                <td className="actions">
                    <div>
                        <EditButton onClick={toggleSidebar} />
                        <DeleteButton onClick={handleDelete} />
                    </div>
                </td>
            </Can>
        </tr>
    );

    function toggleSidebar() {
        showSettingsSidebar(
            {
                ...teamUser,
                team,
            },
            entityTypes.TEAM_USER
        );
    }

    function handleDelete() {
        HelperFunctions.confirmModal(
            `${user.fullName} uit het team "${team.name}" verwijderen?`,
            'danger',
            false,
            t('global:confirm.yesDelete'),
            t('global:btn.cancel')
        )
            .then(() => {
                deleteTeamUser(teamUser['@id']);
            })
            .catch(() => {});
    }
}
