import { Form, Formik, useFormikContext } from 'formik';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Spinner, { LoadingSpinner } from '../global/Spinner';
import HelperFunctions from '../global/HelperFunctions';
import RestrictedContent from '../global/RestrictedContent';
import Constants, { Permissions } from '../../config/Constants';
import _ from 'lodash';
import { InputMultipleSelect } from '../publications_v2/helpers/FieldHelper';
import {
    useAddLabelEntitiesMutation,
    useDeleteLabelEntitiesMutation,
    useGetLabelEntitiesQuery,
    useGetLabelFoldersQuery,
    useGetLabelsQuery,
} from '../../features/metadata/metadata';
import { idToUri } from '../global/UriHelper';
import { useContext, useState } from 'react';
import { SlideContext } from '../global/Sidebar';
import { EditVariantFormContent } from './EditVariantFormContent';
import { ChevronRight } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

export default function MetaSidebarContent({ context }) {
    const { entity, entityType, documentId = null } = context;
    const { t } = useTranslation('labels');

    return (
        <>
            <div className="p-4">
                <h4 className="pr-3">
                    {t('metaSidebar.labels')}
                    {entityType === 'area' && <span className="small text-muted pl-2">{t('metaSidebar.article')}</span>}
                </h4>
                {entity.hasOwnProperty('title') && <h5>&ldquo;{entity.title}&rdquo;</h5>}

                <RestrictedContent permission={Permissions.LabelEntity['Read.All']}>
                    <MetaForm entity={entity} entityType={entityType} documentId={documentId} />
                </RestrictedContent>
            </div>
        </>
    );
}

function MetaForm({ entity, entityType, documentId }) {
    const { activeOrganisation } = useSelector((state) => state.security);
    const [addLabelEntities] = useAddLabelEntitiesMutation();
    const [deleteLabelEntities] = useDeleteLabelEntitiesMutation();
    const hasVariants = entity.hasOwnProperty('documentVariants');
    const { setSize } = useContext(SlideContext);
    const [showVariants, setShowVariants] = useState(false);
    const { t } = useTranslation('labels');

    const { allLabels } = useGetLabelsQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                allLabels: data ?? [],
            }),
        }
    );

    const { entityLabelEntities, isLoading, isUninitialized } = useGetLabelEntitiesQuery(
        {
            entityType,
            entityId: entity.id,
            organisationId: activeOrganisation,
        },
        {
            selectFromResult: ({ data, isLoading, isUninitialized }) => ({
                entityLabelEntities: data ?? [],
                isLoading,
                isUninitialized,
            }),
        }
    );

    if (isLoading || isUninitialized || allLabels.length === 0) {
        return <Spinner />;
    }

    const entityLabels = entityLabelEntities
        .filter((_item) => allLabels.some((label) => label['@id'] === _item.label))
        .map((_item) => ({
            ...HelperFunctions.getByValue(allLabels, '@id', _item.label),
            labelEntity: _item,
        }))
        .sort(HelperFunctions.dynamicSort('name'));

    const hasVariantData = hasVariants
        ? entityLabelEntities.some((labelEntity) => labelEntity.variantId !== null)
        : false;

    const handleSubmit = async (values = [], { setSubmitting }) => {
        const { labels = [] } = values;
        let deleteDone = true;
        let addingDone = true;

        // Labels deleted
        const deletedLabelEntities = entityLabelEntities
            .filter((_labelEntity) => labels.find((_label) => _label.labelEntity.id === _labelEntity.id) === undefined)
            .map((_labelEntity) => _labelEntity['@id']);

        if (deletedLabelEntities.length > 0) {
            deleteDone = false;
        }

        // Labels added
        const addedLabels = labels.filter((_item) => _item.labelEntity.id === undefined);

        if (addedLabels.length > 0) {
            addingDone = false;
        }

        if (deletedLabelEntities.length > 0) {
            deleteLabelEntities(deletedLabelEntities).then(() => {
                deleteDone = true;

                if (addingDone) {
                    setSubmitting(false);
                }
            });
        }

        if (addedLabels.length > 0) {
            addLabelEntities(
                addedLabels.map((_item) => ({
                    entityType,
                    entityId: `${entity.id}`,
                    label: _item.value,
                    organisationId: activeOrganisation,
                    documentId,
                    variantId: _item?.labelEntity?.variantId ?? null,
                }))
            ).then(() => {
                addingDone = true;

                if (deleteDone) {
                    setSubmitting(false);
                }
            });
        }
    };

    const toggleLabel = ({ labels = [] }, label, setFieldValue, actionValue) => {
        const { action, removedValue, name } = actionValue;

        if (action === 'remove-value') {
            setFieldValue(
                'labels',
                labels.filter((_label) => _label.id !== removedValue.id)
            );
        } else if (action === 'clear') {
            if (name.startsWith('variant')) {
                const parts = name.split('|');

                // Extract variant id
                const variantId = parseInt(parts[0].substring(8));

                // Extract folder id
                const folderName = parts[1].substring(7);
                const folderId = folderName === 'root' ? null : idToUri(folderName, 'LabelFolder');

                setFieldValue(
                    'labels',
                    labels.filter((_label) => {
                        if (_label.labelEntity.variantId === variantId) {
                            return _label.folder !== folderId;
                        }

                        return true;
                    })
                );
            } else {
                const folderName = name.substring(7);
                const folderId = folderName === 'root' ? null : idToUri(folderName, 'LabelFolder');

                setFieldValue(
                    'labels',
                    labels.filter((_label) => {
                        if (_label.labelEntity.variantId === null) {
                            return _label.folder !== folderId;
                        }

                        return true;
                    })
                );
            }
        } else {
            setFieldValue('labels', _.unionWith(labels, label, _.isEqual).sort(HelperFunctions.dynamicSort('name')));
        }
    };

    const formContent = <PrimaryFormContent entityType={entityType} toggleLabel={toggleLabel} />;

    return (
        <Formik
            enableReinitialize
            initialValues={{
                labels: HelperFunctions.prepareDropdownData(entityLabels, 'name', '@id'),
            }}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, dirty }) => (
                <Form autoComplete="off">
                    {showVariants ? (
                        <EditVariantFormContent
                            documentId={documentId}
                            documentVariantIds={entity.documentVariants}
                            entityType={entityType}
                            toggleLabel={toggleLabel}
                        >
                            <>{formContent}</>
                        </EditVariantFormContent>
                    ) : (
                        <>{formContent}</>
                    )}

                    <RestrictedContent permission={Permissions.LabelEntity['Write.All']}>
                        <div className="d-flex align-items-center py-3">
                            <Button type="submit" disabled={isSubmitting || !dirty}>
                                {t('btn.save')}
                            </Button>
                            <LoadingSpinner isSubmitting={isSubmitting} />

                            {hasVariants && !showVariants && (
                                <Button
                                    disabled={entity.documentVariants.length < 2}
                                    variant={hasVariantData ? 'primary' : 'outline-secondary'}
                                    className="ml-auto"
                                    onClick={() => {
                                        setSize('lg');
                                        setShowVariants(true);
                                    }}
                                >
                                    {t('metaSidebar.btn.installPerVariant')}
                                    <ChevronRight className="ml-1" />
                                </Button>
                            )}
                        </div>
                    </RestrictedContent>
                </Form>
            )}
        </Formik>
    );
}

function PrimaryFormContent({ entityType, toggleLabel }) {
    const { activeOrganisation } = useSelector((state) => state.security);

    const { folders } = useGetLabelFoldersQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                folders: data ?? [],
            }),
        }
    );

    return (
        <>
            <RootLabels toggleLabel={toggleLabel} />

            {folders.map((_folder) => (
                <MetaFolder
                    folder={_folder}
                    toggleLabel={toggleLabel}
                    entityType={entityType}
                    key={`meta-folder-${_folder.id}`}
                />
            ))}
        </>
    );
}

function RootLabels({ toggleLabel }) {
    const { activeOrganisation } = useSelector((state) => state.security);
    const { setFieldValue, values } = useFormikContext();
    const { t } = useTranslation('labels');

    const { rootLabels } = useGetLabelsQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                rootLabels: data ? data.filter((label) => label.folder === null) : [],
            }),
        }
    );

    if (rootLabels.length === 0) {
        return null;
    }

    return (
        <div className="pt-3 mb-4">
            <InputMultipleSelect
                name="labels-root"
                options={HelperFunctions.prepareDropdownData(rootLabels, 'name', '@id')}
                onChange={(selectedOption, action) => {
                    toggleLabel(values, selectedOption, setFieldValue, action);
                }}
                props={{
                    placeholder: `${t('metaSidebar.btn.titleSelectLabels')}...`,
                }}
                defaultValue={values.labels.filter((_label) => _label.folder === null)}
            />
        </div>
    );
}

function MetaFolder({ folder, toggleLabel, entityType }) {
    const { id, name, contentTypes = [] } = folder;
    const { setFieldValue, values } = useFormikContext();
    const { activeOrganisation } = useSelector((state) => state.security);
    const { t } = useTranslation('labels');

    const { labels } = useGetLabelsQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                labels: data ? data.filter((label) => label.folder === folder['@id']) : [],
            }),
        }
    );

    if (labels.length === 0 || contentTypes.length === 0) {
        return null;
    }

    const allowedTypes = Object.keys(Constants.labelFolderEntities).filter((_entity) => {
        return Constants.labelFolderEntities[_entity].includes(entityType);
    });

    if (contentTypes.some((_type) => allowedTypes.includes(_type)) === false) {
        return null;
    }

    return (
        <>
            <div className="font-weight-bold mb-1">{name}</div>
            <div className="mb-3">
                <InputMultipleSelect
                    name={`labels-${id}`}
                    options={HelperFunctions.prepareDropdownData(labels, 'name', '@id')}
                    onChange={(selectedOptions, action) => {
                        const selectedLabels = selectedOptions
                            ? selectedOptions.map((option) => {
                                  if (!option.labelEntity) {
                                      return {
                                          ...option,
                                          labelEntity: {
                                              variantId: null,
                                          },
                                      };
                                  }

                                  return option;
                              })
                            : null;

                        toggleLabel(values, selectedLabels, setFieldValue, action);
                    }}
                    props={{
                        placeholder: `${t('metaSidebar.btn.titleSelectLabels')}...`,
                    }}
                    defaultValue={values.labels.filter(
                        (_label) =>
                            _label.folder === idToUri(id, 'LabelFolder') && _label?.labelEntity?.variantId === null
                    )}
                />
            </div>
        </>
    );
}
