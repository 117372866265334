import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFile } from '../../../features/mediaLibrary/mediaLibrarySlice';
import { FOLDER_TRASH } from './Folders';
import _ from 'lodash';
import HelperFunctions from '../../global/HelperFunctions';
import { useTranslation } from 'react-i18next';

export default function Dropzone({ isDisabled = false, onDropComplete }) {
    const { t } = useTranslation('documents');
    const dispatch = useDispatch();
    const activeOrganisation = useSelector((state) => state.security.activeOrganisation);
    const selectedFolderId = useSelector((state) => state.mediaLibrary.selectedFolder);

    const onDrop = (acceptedFiles) => {
        Promise.all(
            acceptedFiles.map((file) => {
                return dispatch(uploadFile({ file, activeOrganisation, folderId: selectedFolderId }));
            })
        ).then((values) => {
            const hasError = values.some((_payload) => {
                return _payload.type === 'mediaLibrary/uploadFile/rejected';
            });

            if (hasError) {
                HelperFunctions.alertModal(
                    t('document.navbar.main.editor.left.blocks.types.fileBlock.fileModal.error')
                );
            }

            if (_.isFunction(onDropComplete)) {
                onDropComplete(values);
            }
        });
    };

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg'],
        },
        onDrop: onDrop,
    });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
            ...(isDisabled ? disabledStyle : {}),
        }),
        [isDragActive, isDragReject, isDragAccept, isDisabled]
    );

    if (selectedFolderId === FOLDER_TRASH) {
        // Can't upload files into the trash
        return null;
    }

    return (
        <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <div>{t('document.navbar.main.editor.left.blocks.types.fileBlock.fileModal.dragFile')}</div>
        </div>
    );
}

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
};

const activeStyle = {
    borderColor: '#2196f3',
};

const acceptStyle = {
    borderColor: '#00e676',
    backgroundColor: '#00e67614',
};

const rejectStyle = {
    borderColor: '#ff1744',
};

const disabledStyle = {
    opacity: 0.5,
};
