import React, { Component, Fragment, useEffect, useMemo, useState } from 'react';
import Parser from 'html-react-parser';
import Language from '../../../../language/Language';
import Modal from '../../../global/Modal';
import CloseButton from '../../../global/CloseButton';
import HelperFunctions from '../../../global/HelperFunctions';
import Constants from '../../../../config/Constants';
import InteractivePart from '../_BlockParser/InteractivePart';
import cx from 'classnames';
import BasicEditor from '../_BlockParser/BasicEditor';
import { useDispatch, useSelector } from 'react-redux';
import { loadTableTemplates } from 'features/documents/documentsSlice';
import getEnv from '../../../../config/Env';
import { Form } from 'react-bootstrap';
import { useGetBaseVariant } from 'pages/documents_v2/hooks/useGetBaseVariant';
import { useGetDocumentTags } from 'pages/tags/hooks/useGetDocumentTags';

export function Table(props) {
    const baseVariant = useGetBaseVariant();
    const { activeVariant, block, blockDisplayOptions, document, selectedPart, selectPart, users, readOnly } = props;
    const { tableTemplates } = useSelector((state) => state.documents);
    const dispatch = useDispatch();
    const [showEditorModal, setShowEditorModal] = useState(false);
    const { packageGroups } = document;
    const { deleted = false } = block;

    useEffect(() => {
        if (tableTemplates === false) {
            dispatch(loadTableTemplates());
        }
    }, [dispatch, tableTemplates]);

    const table = useMemo(() => {
        let tableJson = block.latestContent;

        if (
            blockDisplayOptions.table.markChanges &&
            block.hasOwnProperty('renderedDiffContent') &&
            block.renderedDiffContent !== ''
        ) {
            tableJson = block.renderedDiffContent;
        }

        const table = HelperFunctions.tryParseJSON(tableJson);

        if (table !== false) {
            return table;
        }

        return {
            data: {},
            templateId: false,
            title: '',
        };
    }, [block]);

    const template = useMemo(() => {
        if (!table || !table.hasOwnProperty('templateId')) {
            return false;
        }

        const template = HelperFunctions.getByValue(tableTemplates, 'id', parseInt(table.templateId));

        if (!template) {
            return false;
        }

        if (template.hasOwnProperty('firstColumnTitle') && template.firstColumnTitle.length > 0) {
            return {
                ...template,
                firstColumnTitle: template.firstColumnTitle,
            };
        }

        return template;
    }, [table, tableTemplates]);

    if (tableTemplates === false || template === false) {
        return null;
    }

    const properties = {
        ...template?.properties,
        ...table?.properties,
    };

    const isEditable = () => {
        let editable = blockDisplayOptions.table.editable;

        if (
            deleted ||
            (activeVariant?.id !== baseVariant?.id && template?.properties?.differentCellValuePerVariant === false)
        ) {
            return false;
        }

        return editable;
    };

    let baseVariantOverride = false;
    let activeVariantId = activeVariant.id;
    const differentCellValuePerVariant = properties?.differentCellValuePerVariant ?? false;

    if (differentCellValuePerVariant === false) {
        if (activeVariantId !== baseVariant?.id) {
            baseVariantOverride = true;
        }

        activeVariantId = baseVariant?.id;
    }

    let tableDataSet = getTableDataSet(table, activeVariantId);

    // Fallback dataset (for new tables)
    if (tableDataSet === undefined) {
        tableDataSet = {
            variantId: activeVariantId,
        };
    }

    const packageGroupId = tableDataSet?.packageGroupId ?? undefined;
    const columns = getColumns(template, packageGroupId, packageGroups, activeVariant, document);
    const rows = prepareTags(template?.rows, 'title', activeVariant.id, document);

    const dataSet = prepareTableData(tableDataSet, rows, columns, blockDisplayOptions, block, selectedPart, selectPart);
    const dataIsValid = validateData(table, dataSet, template?.rows ?? [], columns);

    const editorClass = cx(
        'dr-block-table uk-padding-small uk-position-relative uk-background-muted uk-text-muted uk-text-center',
        {
            'uk-background-primary-extra-light-hover dr-block-table-left uk-text-primary-hover uk-cursor-pointer':
                isEditable(),
            'block-table-read-only': readOnly,
        },
    );

    return (
        <>
            <div className={editorClass} onClick={() => isEditable() && setShowEditorModal(true)}>
                {blockDisplayOptions.table.displayMode === 'placeholder' ? (
                    <>
                        <p className="uk-margin-remove">{table.title}</p>
                        <p className="uk-margin-remove uk-text-small uk-text-capitalize-first">
                            {Language.getTranslation('template')}: {dataIsValid.templateIsValid ? template.name : '-'}
                        </p>
                    </>
                ) : dataIsValid.colsAreValid && dataIsValid.rowsAreValid && dataIsValid.dataSetHasData ? (
                    <>
                        <RenderTable
                            columns={columns}
                            document={document}
                            enableTableEditor={false}
                            rows={rows}
                            tableData={dataSet.rows}
                            template={template}
                        />
                        {baseVariantOverride && (
                            <div className="font-weight-bold text-left pt-2">* tabeldata uit de basisvariant</div>
                        )}
                    </>
                ) : (
                    <>
                        <p className="uk-margin-remove">{table.title}</p>
                        <p className="uk-margin-remove uk-text-small">
                            {Language.getTranslation(
                                packageGroupId === undefined ? 'noPackageGroupSelected' : 'noResultsColumns',
                            )}
                        </p>
                    </>
                )}
            </div>
            {showEditorModal && (
                <EditorModal
                    activeVariant={activeVariant}
                    columns={columns}
                    dataIsValid={dataIsValid}
                    document={document}
                    enableTableEditor={blockDisplayOptions.table.editable}
                    handleTableDataChange={undefined}
                    packageGroupId={packageGroupId}
                    packageGroups={packageGroups}
                    rows={rows}
                    selectPackageGroup={(packageGroupId) => {
                        // Notify the parent components that the content has changed
                        const newContent = handleTableDataChange(
                            {
                                rows: dataSet.rows,
                            },
                            dataSet,
                            packageGroupId,
                            table,
                            activeVariantId,
                        );
                        props.handleBlockChange(newContent, false);
                    }}
                    handleFormSubmit={(formData) => {
                        if (formData === null) {
                            // Nothing to save
                            return;
                        }

                        // Notify the parent components that the content has changed
                        const newContent = handleTableDataChange(
                            {
                                rows: formData.tempDataSetRows,
                                properties: formData.properties,
                            },
                            dataSet,
                            packageGroupId,
                            table,
                            activeVariantId,
                        );
                        props.handleBlockChange(newContent, false);

                        // Close modal
                        setShowEditorModal(false);
                    }}
                    table={table}
                    tableData={dataSet.rows}
                    template={template}
                    toggleEditorModal={() => setShowEditorModal(false)}
                />
            )}
        </>
    );
}

export function getTable(block, markChanges = false) {
    let table;
    let tableJson = block.latestContent;

    if (markChanges && block.hasOwnProperty('renderedDiffContent') && block.renderedDiffContent !== '') {
        tableJson = block.renderedDiffContent;
    }

    table = HelperFunctions.tryParseJSON(tableJson);

    if (table !== false) {
        return table;
    }

    // Error parsing json
    getEnv('APP_ENV') === Constants.environments.dev && console.log('error parsing json');

    return {
        data: {},
        templateId: false,
        title: '',
    };
}

export function getTableDataSet(table, variantId) {
    // this.table.data is an array with datasets for all variants
    return HelperFunctions.getByValue(table.data, 'variantId', variantId);
}

export function validateData(table, dataSet, rows, columns) {
    let templateIsValid = true;
    let rowsAreValid = true;
    let colsAreValid = true;
    let tableHasData = true;
    let dataSetHasData = true;

    if (!table.templateId) {
        templateIsValid = false;
    }

    if (rows === undefined || rows.length < 1) {
        rowsAreValid = false;
    }
    if (columns === undefined || !columns || columns.length < 1) {
        colsAreValid = false;
    }

    if (table.data === undefined || table.data.length < 1) {
        tableHasData = false;
    }

    if (!dataSet.hasOwnProperty('rows')) {
        dataSetHasData = false;
    }

    return { colsAreValid, dataSetHasData, rowsAreValid, tableHasData, templateIsValid };
}

export function getColumns(template, packageGroupId, packageGroups = [], activeVariant, document) {
    if (!template?.columns) {
        // Probably loading not complete yet
        return false;
    }

    let columns = false;

    switch (template.columns) {
        case 'packages':
            // get columns from chosen set of packages
            if (packageGroupId) {
                const packageGroup = getPackageGroup(packageGroupId, packageGroups);
                if (packageGroup && packageGroup.hasOwnProperty('packages') && packageGroup.packages.length > 0) {
                    columns = packageGroup.packages;
                }
            }
            break;
        default:
            columns = template.columns.length > 0 ? template.columns : [];
            columns = prepareTags(columns, 'name', activeVariant.id, document);
            break;
    }

    return columns;
}

function getPackageGroup(packageGroupId, packageGroups = []) {
    if (!packageGroupId || packageGroups === undefined || packageGroups.length < 1) {
        return false;
    }

    const packageGroup = HelperFunctions.getByValue(packageGroups, 'id', packageGroupId);

    if (packageGroup !== undefined) {
        return packageGroup;
    }

    return false;
}

function prepareTags(items = [], key, variantId, document) {
    let itemsCopy = [];
    if (items.length > 0) {
        itemsCopy = items.map((item) => {
            const itemCopy = Object.assign({}, item);
            itemCopy[key] = HelperFunctions.prepareTags(itemCopy[key], document, variantId, true);
            return itemCopy;
        });
    }

    return itemsCopy;
}

export function prepareTableData(dataSet, templateRows, columns, blockDisplayOptions, block, selectedPart, selectPart) {
    let rows = [];

    if (dataSet !== undefined && dataSet.hasOwnProperty('rows') && dataSet.rows.length > 0) {
        rows = dataSet.rows;
    } else {
        // no data stored, preparing an empty set based on the template
        if (templateRows !== undefined && templateRows.length > 0) {
            rows = templateRows.map((templateRow) => {
                return {
                    id: templateRow.id,
                    cells: [],
                };
            });
        }
    }

    if (rows.length === 0) {
        return dataSet;
    }

    return {
        ...dataSet,
        rows: rows.map((row) => {
            // make sure every row has a cells array and each cell has an entityId and value
            let cells = row.hasOwnProperty('cells') || row.cells.length > 0 ? row.cells : [];

            // make sure there is a cell for each column
            if (columns && columns.length > 0) {
                columns.forEach((column) => {
                    let cellFound = false;
                    const newCells = cells.map((cell) => {
                        if (blockDisplayOptions.table.interactiveChanges) {
                            // prepare interactive content
                            let cellContent = cell.diffContent === '' ? cell.latestContent : cell.diffContent;
                            cell.latestContent = prepareInteractiveContent(
                                cellContent,
                                block.changes,
                                block.id,
                                selectedPart,
                                selectPart,
                            );
                            cell.useParser = false;
                        } else if (blockDisplayOptions.table.markChanges) {
                            cell.latestContent = cell.diffContent === '' ? cell.latestContent : cell.diffContent;
                            cell.useParser = true;
                        } else {
                            cell.useParser = true;
                        }

                        if (cell.entityId === column.id) {
                            cellFound = true;
                        }

                        return cell;
                    });

                    if (!cellFound) {
                        // no cell found for this column
                        const cell = {
                            entityId: column.id,
                            latestContent: '',
                            diffContent: '',
                        };
                        newCells.push(cell);
                    }

                    cells = newCells;
                });
            }

            row.cells = cells;
            return row;
        }),
    };
}

function prepareInteractiveContent(content, changes, blockId, selectedPart, selectPart) {
    if (typeof content !== 'string') {
        return content;
    }

    return Parser(content, {
        replace: (domNode) => {
            if (
                domNode.name === 'change' &&
                domNode.hasOwnProperty('attribs') &&
                domNode.attribs.hasOwnProperty('data-diff-id')
            ) {
                const domNodeId = parseInt(domNode.attribs['data-diff-id']);
                let part = {};
                changes.forEach((partItem) => {
                    if (partItem.diffId === domNodeId) {
                        part = partItem;
                    }
                });
                if (part !== undefined) {
                    return (
                        <InteractivePart
                            partId={part.id}
                            part={part}
                            blockId={blockId}
                            interactive={true}
                            selectedPart={selectedPart}
                            selectPart={() => selectPart(part.id, blockId)}
                        />
                    );
                } else {
                    return <Fragment />;
                }
            } else if (domNode.name === 'change') {
                return <Fragment />;
            }
        },
    });
}

export function handleTableDataChange(formData, currentDataSet, packageGroupId, table, activeVariantId) {
    const { properties, rows } = formData;
    const dataSet = {
        ...currentDataSet,
        packageGroupId: packageGroupId,
        rows,
    };
    let newData = [];
    let datasetFound = false;

    if (table.data !== undefined && table.data.length > 0) {
        // merge with existing data
        newData = table.data.map((dataItem) => {
            if (dataItem.variantId === activeVariantId) {
                datasetFound = true;
                return dataSet;
            } else {
                return dataItem;
            }
        });
    } else {
        // create array with the current dataSet as only item
        newData.push(dataSet);
        datasetFound = true;
    }

    if (!datasetFound) {
        newData.push(dataSet);
    }

    const newTable = {
        ...table,
        data: newData,
        properties,
    };

    return JSON.stringify(newTable);
}

export function EditorModal(props) {
    const { activeVariant, dataIsValid, packageGroupId, packageGroups, table, tableData, template } = props;
    const packageGroup = getPackageGroup(packageGroupId, packageGroups);
    const [editPackageGroup, setEditPackageGroup] = useState(false);
    const [formData, setFormData] = useState({
        tempDataSetRows: tableData,
        properties: {
            ...template.properties,
            ...table?.properties,
        },
        title: table?.title ?? '',
    });

    const handleCellChange = (rowId, entityId, value) => {
        const newDataSetRows = [...formData.tempDataSetRows];

        // Check if dataset has cells of rowId
        if (!newDataSetRows.some((_row) => _row.id === rowId)) {
            newDataSetRows.push({
                id: rowId,
                cells: [],
            });
        }

        setFormData({
            ...formData,
            tempDataSetRows: newDataSetRows.map((row) => {
                if (row.id === rowId && row.hasOwnProperty('cells')) {
                    let found = false;

                    row.cells = row.cells.map((cell) => {
                        if (cell.entityId === entityId) {
                            found = true;
                            cell.latestContent = value;
                        }
                        return cell;
                    });

                    // Cell not found, add it
                    if (!found) {
                        row.cells.push({
                            entityId: entityId,
                            latestContent: value,
                            diffContent: '',
                        });
                    }
                }
                return row;
            }),
        });
    };

    const handlePropertyChange = (propName, newValue) => {
        setFormData({
            ...formData,
            properties: {
                ...formData.properties,
                [propName]: newValue,
            },
        });
    };

    const handleTitleChange = (e) => {
        setFormData({
            ...formData,
            title: e.target.value,
        });
    };

    return (
        <Modal opened={true} modalStyle="centeredAutoWidth" onRequestClose={props.toggleEditorModal}>
            <div className="uk-width-968-fixed uk-padding-medium uk-position-relative">
                <CloseButton className="uk-float-right" close={props.toggleEditorModal} />
                <h2 className="uk-margin-top uk-margin-small-bottom">{Language.getTranslation('tableEditor')}</h2>
                <div className="d-flex align-items-center mb-1">
                    <div className="font-weight-bold mr-2">{Language.getTranslation('tableTitle')}:</div>
                    <input
                        type="text"
                        className="uk-input uk-form-width-large"
                        name="title"
                        value={formData.title}
                        onChange={handleTitleChange}
                    />
                </div>
                <strong>{Language.getTranslation('template')}:</strong> {template.name}
                <br />
                <strong>{Language.getTranslation('variant')}:</strong>{' '}
                {activeVariant.parentId !== null ? activeVariant.name : Language.getTranslation('baseVariant')}
                <br />
                {packageGroup && (
                    <>
                        <strong>{Language.getTranslation('packageGroup')}:</strong> {packageGroup.name}{' '}
                        {!editPackageGroup && (
                            <span
                                className="uk-link uk-margin-left"
                                onClick={() => setEditPackageGroup(!editPackageGroup)}
                            >
                                {Language.getTranslation('changeTablePackageGroup', 'buttons')}
                            </span>
                        )}
                    </>
                )}
                {template.columns === 'packages' && (
                    <div className="pt-2">
                        <Form.Group>
                            <input
                                type="checkbox"
                                className="uk-checkbox uk-checkbox-large mr-2"
                                name="differentCellValuePerVariant"
                                id="differentCellValuePerVariant"
                                checked={formData.properties.differentCellValuePerVariant}
                                onChange={() => {
                                    handlePropertyChange(
                                        'differentCellValuePerVariant',
                                        !formData.properties.differentCellValuePerVariant,
                                    );
                                }}
                            />
                            <label htmlFor="differentCellValuePerVariant">
                                Verschillende waarden per variant toestaan
                            </label>
                        </Form.Group>
                    </div>
                )}
                <div className="uk-margin-top">
                    {dataIsValid.rowsAreValid && dataIsValid.colsAreValid && !editPackageGroup ? (
                        <form
                            className="uk-form"
                            // onSubmit={(event) => {
                            //     event.preventDefault();
                            //     props.handleFormSubmit(formData);
                            // }}
                        >
                            <RenderTable
                                {...props}
                                handleCellChange={handleCellChange}
                                modal={true}
                                tableData={formData.tempDataSetRows} // Override tableData in modal with the formData
                            />
                            <div className="uk-margin-small-top uk-text-right">
                                <button
                                    type="button"
                                    className="uk-button uk-button-link uk-margin-left"
                                    onClick={props.toggleEditorModal}
                                >
                                    {Language.getTranslation('cancel', 'buttons')}
                                </button>
                                <button
                                    type="button"
                                    className="uk-button uk-button-primary ml-3"
                                    disabled={formData === null}
                                    onClick={() => props.handleFormSubmit(formData)}
                                >
                                    {Language.getTranslation('save', 'buttons')}
                                </button>
                            </div>
                        </form>
                    ) : template.columns === 'packages' ? (
                        (packageGroups !== undefined && packageGroups.length > 0) || editPackageGroup ? (
                            <SelectPackageGroup {...props} setEditPackageGroup={setEditPackageGroup} action="create" />
                        ) : (
                            <p>{Language.getTranslation('noResultsPackageGroups')}</p>
                        )
                    ) : (
                        <p>{Language.getTranslation('noResultsColumns')}</p>
                    )}
                </div>
            </div>
        </Modal>
    );
}

export const RenderTable = ({
    columns = [],
    enableTableEditor = false,
    handleCellChange,
    modal = false,
    rows = [],
    tableData,
    template,
    readOnly,
}) => {
    const tags = useGetDocumentTags();
    const hideRowTitles = template?.properties?.hideRowTitles ?? false;
    const firstColumnTitle = template?.firstColumnTitle ?? '';
    const vertical = template?.editorOptions?.displayVertical ?? false;
    const sortedRows = [...rows].sort(HelperFunctions.dynamicSort('sortOrder'));

    if (vertical) {
        return (
            <RenderVerticalTable
                columns={columns}
                tags={tags}
                enableTableEditor={enableTableEditor}
                handleCellChange={handleCellChange}
                modal={modal}
                tableData={tableData}
                template={template}
                readOnly={readOnly}
            />
        );
    }

    return (
        <div className={modal ? 'uk-overflow-auto max-height-650vh' : ''}>
            <table className="uk-table uk-table-small uk-table-striped uk-table-hover uk-width-1-1 uk-background-muted uk-text-left">
                <thead>
                    <tr>
                        {hideRowTitles === false && <th className="uk-width-1-4">{Parser(firstColumnTitle)}</th>}
                        {columns.map((column) => {
                            return <th key={column.id}>{Parser(column.name)}</th>;
                        })}
                    </tr>
                </thead>
                <tbody>
                    {sortedRows.map((row) => {
                        const rowData =
                            tableData === undefined ? [] : HelperFunctions.getByValue(tableData, 'id', row.id);
                        return (
                            <tr key={row.id}>
                                {hideRowTitles === false && row.hasOwnProperty('title') && (
                                    <td className="uk-text-uppercase uk-text-muted">
                                        <span
                                            className={
                                                row.hasOwnProperty('parentId') && row.parentId !== null
                                                    ? 'uk-padding-left'
                                                    : ''
                                            }
                                        >
                                            {Parser(row.title)}
                                        </span>
                                    </td>
                                )}
                                {columns.map((column) => {
                                    if (
                                        rowData !== undefined &&
                                        rowData.hasOwnProperty('cells') &&
                                        rowData.cells.length > 0
                                    ) {
                                        const cellData = HelperFunctions.getByValue(
                                            rowData.cells,
                                            'entityId',
                                            column.id,
                                        );
                                        if (cellData !== undefined) {
                                            return (
                                                <td key={column.id}>
                                                    <RenderTableCell
                                                        columnId={column.id}
                                                        data={cellData}
                                                        tags={tags}
                                                        enableTableEditor={enableTableEditor}
                                                        handleCellChange={handleCellChange}
                                                        rowId={row.id}
                                                    />
                                                </td>
                                            );
                                        } else {
                                            return <td key={column.id} />;
                                        }
                                    } else {
                                        return <td key={column.id} />;
                                    }
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

function RenderVerticalTable({
    columns = [],
    tags,
    enableTableEditor = false,
    handleCellChange,
    modal = false,
    tableData,
    template,
    readOnly,
}) {
    const hideRowTitles = template?.properties?.hideRowTitles ?? false;

    const wrapperClasses = cx({
        'uk-overflow-auto max-height-650vh': modal,
    });

    const tableCellClasses = cx('flex-table-cell', {
        'flex-table-cell-editor': enableTableEditor,
    });

    const columnCount = template.rows.length + 1;
    const headerWidth = (1 / columnCount) * 100;
    const cellWidth = 100 - headerWidth;

    return (
        <div className={wrapperClasses}>
            <div className="flex-table flex-table-striped flex-table-vertical">
                <div className="flex-table-body">
                    {columns.map((column) => {
                        return (
                            <div className="flex-table-row mb-3" key={column.id}>
                                {hideRowTitles && (
                                    <div className="flex-table-header" style={{ width: `${headerWidth}%` }}>
                                        {Parser(column.name)}
                                    </div>
                                )}
                                {template.rows.map((_templateRow) => {
                                    const rowData =
                                        tableData === undefined
                                            ? undefined
                                            : HelperFunctions.getByValue(tableData, 'id', _templateRow.id);
                                    const rowCells = rowData?.cells ?? undefined;
                                    let cellData = rowCells
                                        ? HelperFunctions.getByValue(rowCells, 'entityId', column.id)
                                        : undefined;

                                    if (!cellData) {
                                        cellData = {
                                            entityId: column.id,
                                            latestContent: '',
                                        };
                                    }

                                    return (
                                        <div
                                            className={tableCellClasses}
                                            style={{ width: `${cellWidth}%` }}
                                            key={`${column.id}-${_templateRow.id}`}
                                        >
                                            <RenderTableCell
                                                columnId={column.id}
                                                data={cellData}
                                                tags={tags}
                                                enableTableEditor={enableTableEditor}
                                                handleCellChange={handleCellChange}
                                                rowId={_templateRow.id}
                                                readOnly={readOnly}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

const RenderTableCell = (props) => {
    const { columnId, data, enableTableEditor, handleCellChange, rowId, readOnly, tags = [] } = props;

    if (enableTableEditor && !readOnly) {
        return (
            <BasicEditor
                id={'jsTinymceEditor' + rowId + columnId}
                name={'jsTinymceEditor' + rowId + columnId}
                onChange={(event) => handleCellChange(rowId, columnId, event.target.value)}
                tags={tags}
                value={data.latestContent}
            />
        );
    }

    if (data !== undefined) {
        const useParser = data.useParser ?? true;

        if (useParser) {
            let html = '';

            if (data.hasOwnProperty('renderedDiffContent') && data.renderedDiffContent !== null) {
                html = data.renderedDiffContent;
            } else {
                html = data.latestContent ?? '';
            }

            return <>{Parser(html)}</>;
        } else {
            return <>{data.latestContent}</>;
        }
    } else {
        return <div />;
    }
};

class SelectPackageGroup extends Component {
    constructor(props) {
        super(props);
        this.handleFormChange = this.handleFormChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);

        this.state = {
            disableSubmitButton: props.packageGroupId === undefined,
            packageGroupId: props.packageGroupId !== undefined ? props.packageGroupId : false,
        };
    }

    handleFormChange(e) {
        this.setState({
            disableSubmitButton: e.target.value === '',
            [e.target.name]: e.target.value,
        });
    }

    handleFormSubmit() {
        this.props.selectPackageGroup(parseInt(this.state.packageGroupId));
        this.props.setEditPackageGroup(false);
    }

    render() {
        return (
            <form className="uk-form">
                <p className="uk-text-primary uk-margin-small-bottom">
                    {Language.getTranslation('choosePackageGroup')}
                </p>
                <div data-uk-form-custom="target: > * > span:first-child">
                    <select name="packageGroupId" value={this.state.packageGroupId} onChange={this.handleFormChange}>
                        <option value="">{Language.getTranslation('selectPackageGroup')}</option>
                        {this.props.packageGroups.map((packageGroup) => {
                            return (
                                <option value={packageGroup.id} key={packageGroup.id}>
                                    {packageGroup.name}
                                </option>
                            );
                        })}
                    </select>
                    <button className="uk-button uk-button-primary" type="button" tabIndex="-1">
                        <span />
                        <span uk-icon="icon: triangle-down" />
                    </button>
                </div>
                <div className="uk-margin-top uk-text-right">
                    <button
                        type="button"
                        className="uk-button uk-button-primary"
                        disabled={this.state.disableSubmitButton}
                        onClick={() => this.handleFormSubmit()}
                    >
                        {Language.getTranslation(this.props.action === 'create' ? 'nextStep' : 'save', 'buttons')}
                    </button>
                </div>
            </form>
        );
    }
}
