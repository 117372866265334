import Block from '../BlockParser';
import HelperFunctions from '../../../global/HelperFunctions';
import { useMemo } from 'react';
import DocumentHelper from '../../../global/DocumentHelper';

export default function AreaBlocks({ area, areaBlocks, baseBlocks, activeVariantId, leftSideBlockProps = {} }) {
    const blockIdsInLayout = useMemo(() => {
        if (areaBlocks.length === 0) {
            return undefined;
        }

        return DocumentHelper.getBlockIdsInLayout(areaBlocks);
    }, [areaBlocks]);

    if (blockIdsInLayout === undefined) {
        return null;
    }

    return areaBlocks
        .filter((_block) => !blockIdsInLayout.includes(_block.key))
        .map((block) => (
            <Block
                areaBlocks={areaBlocks}
                baseBlocks={baseBlocks}
                baseBlock={HelperFunctions.getByValue(baseBlocks, 'id', block.id)}
                block={block}
                blockIdsInLayout={blockIdsInLayout}
                {...leftSideBlockProps}
                key={area.id + '_' + activeVariantId + '_' + block.id}
            />
        ));
}
