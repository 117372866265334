import { FormModal, ModalFooter } from '../../global/FormModal';
import { Button, Modal, ProgressBar } from 'react-bootstrap';
import { Form as FForm, Formik } from 'formik';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FieldSelect } from '../../publications_v2/helpers/FieldHelper';
import HelperFunctions from '../../global/HelperFunctions';
import * as Yup from 'yup';
import ReimbursementApi from '../../../api/ReimbursementApi';
import { CheckLg } from 'react-bootstrap-icons';
import { reimbursementApi } from '../../../features/reimbursements/reimbursements';
import { useGetDocuments } from '../../documents_v2/hooks/useGetDocuments';
import { useTranslation } from 'react-i18next';

export default function CrossRefMigrateModal({ category, hideModal }) {
    const allDocuments = useGetDocuments();
    const [isFormSubmitting, setFormSubmitting] = useState(false);
    const [isTaskDone, setTaskIsDone] = useState(false);
    const [progress, setProgress] = useState(0);
    const dispatch = useDispatch();
    const { t } = useTranslation('reimbursements');

    const handleSubmit = (values, { setSubmitting }) => {
        setFormSubmitting(true);

        ReimbursementApi.createCrossRefMigrationTask(values).then(({ data }) => {
            let timer = setInterval(myFunction, 2000);

            async function myFunction() {
                const task = await ReimbursementApi.get(data['@id'], true);

                if (task.status === 'done') {
                    clearInterval(timer);
                    setSubmitting(false);
                    setFormSubmitting(false);
                    setTaskIsDone(true);

                    // Invalidate cache
                    dispatch(
                        reimbursementApi.util.invalidateTags([
                            'Reimbursement',
                            'ReimbursementProduct',
                            'ReimbursementProductField',
                            'EntityHistory',
                            'History',
                            'Change',
                        ])
                    );

                    return;
                }

                setProgress(task.progress);
            }
        });
    };

    return (
        <FormModal
            show={true}
            title={category.name}
            onHide={() => {
                if (isFormSubmitting === false) {
                    hideModal();
                }
            }}
        >
            <Formik
                initialValues={{
                    category: category['@id'],
                    oldDocumentId: null,
                    newDocumentId: null,
                }}
                validationSchema={CrossRefMigrateSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, values, dirty, isValid }) => (
                    <FForm autoComplete="off">
                        <Modal.Body>
                            <p>{t('migrate.selectSource')}</p>
                            <FieldSelect
                                name="oldDocumentId"
                                label={t('migrate.source')}
                                options={HelperFunctions.prepareDropdownData(allDocuments)}
                                props={{
                                    isClearable: true,
                                    placeholder: 'Document',
                                    isDisabled: isSubmitting || isTaskDone,
                                }}
                            />
                            <FieldSelect
                                name="newDocumentId"
                                label={t('migrate.target')}
                                options={
                                    values.oldDocumentId
                                        ? HelperFunctions.prepareDropdownData(
                                              allDocuments.filter(
                                                  (_document) => _document.clonedFromId === values.oldDocumentId
                                              )
                                          )
                                        : []
                                }
                                props={{
                                    isClearable: true,
                                    placeholder: 'Document',
                                    isDisabled: isSubmitting || isTaskDone,
                                }}
                            />

                            {isTaskDone && (
                                <div className="text-success d-flex">
                                    <CheckLg size={16} className="mr-1" /> {t('migrate.completed')}
                                </div>
                            )}

                            {isFormSubmitting && <ProgressBar now={progress} label={`${progress}%`} />}
                        </Modal.Body>

                        {!isTaskDone ? (
                            <ModalFooter
                                dirty={dirty}
                                isValid={isValid}
                                isSubmitting={isSubmitting}
                                onHide={hideModal}
                                btnSubmitText={isSubmitting ? `${t('migrate.busy')}...` : t('migrate.start')}
                            />
                        ) : (
                            <Modal.Footer>
                                <Button
                                    variant="primary"
                                    onClick={() => {
                                        setTaskIsDone(false);
                                        hideModal();
                                    }}
                                >
                                    {t('btn.close')}
                                </Button>
                            </Modal.Footer>
                        )}
                    </FForm>
                )}
            </Formik>
        </FormModal>
    );
}

const CrossRefMigrateSchema = Yup.object().shape({
    category: Yup.string().required('Required'),
    oldDocumentId: Yup.number().required('Required'),
    newDocumentId: Yup.number().required('Required'),
});
