import React from "react";
import Parser from "html-react-parser";
import HelperFunctions from "../../global/HelperFunctions";

export function LinkedBlock({opEntryBlock, showRendered, document = null}) {
    let text = showRendered ? opEntryBlock.renderedDiffContent : opEntryBlock.latestContent;
    if (document) {
        text = HelperFunctions.prepareRefs(text, document);
    }

    return (
        <div>{Parser(text)}</div>
    )
}
