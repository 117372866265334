import { useSelector } from 'react-redux';
import { entityTypes } from '../../translation/config/Constants';

export function useBlockUpdate(blockKey) {
    const { sidebarEntityUpdate } = useSelector((state) => state.app);

    if (sidebarEntityUpdate) {
        const { entity, entityType } = sidebarEntityUpdate;

        if (entityType === entityTypes.BLOCK && entity.key === blockKey) {
            return sidebarEntityUpdate;
        }
    }

    return undefined;
}
