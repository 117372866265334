import React from 'react';
import HelperFunctions from '../../global/HelperFunctions';
import { ProductsTable, RenderedReimbursementProducts } from '../../documents/misc/_BlockParser/Reimbursement';
import { Alert, Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export function ReimbursementBlock({ opEntryBlock, showChanges, document = null, isBaseVariant = false }) {
    const data = (showChanges && opEntryBlock.renderedDiffContent !== null) ? opEntryBlock.renderedDiffContent : opEntryBlock.latestContent;
    const reimbursement = HelperFunctions.tryParseJSON(data);
    const { t } = useTranslation('changelist');

    if (!reimbursement) {
        return <Alert variant="danger">{t('changelist.blocks.reimbursementBlock.error')}</Alert>;
    }

    if (isBaseVariant) {
        return <ReimbursementVariantWarning />;
    }

    return (
        <Tabs className="mb-1" defaultActiveKey="result" id={`block-${opEntryBlock.id}`}>
            <Tab eventKey="av" title="AV">
                <div className="p-3">
                    <RenderedBlock reimbursement={reimbursement} eventKey="av" document={document} />
                </div>
            </Tab>
            <Tab eventKey="cav" title="CAV">
                <div className="p-3">
                    <RenderedBlock reimbursement={reimbursement} eventKey="cav" document={document} />
                </div>
            </Tab>
            <Tab eventKey="result" title={t('changelist.blocks.reimbursementBlock.result')}>
                <div className="p-3">
                    <RenderedBlock reimbursement={reimbursement} eventKey="result" document={document} />
                </div>
            </Tab>
        </Tabs>
    );
}

function RenderedBlock({ reimbursement, eventKey, document = null }) {
    if (eventKey === 'av' || eventKey === 'cav') {
        const reimbursementProducts = reimbursement.separateRenderedReimbursementProducts;

        if (eventKey === 'av') {
            return (
                <ProductsTable
                    name={reimbursementProducts?.name ?? ''}
                    products={reimbursementProducts?.products ?? []}
                    document={document}
                />
            );
        }

        if (eventKey === 'cav') {
            return (
                <ProductsTable
                    name={reimbursementProducts?.secondaryName ?? ''}
                    products={reimbursementProducts?.secondaryProducts ?? []}
                    document={document}
                />
            );
        }
    }

    return (
        <RenderedReimbursementProducts
            reimbursementProducts={reimbursement.renderedReimbursementProducts}
            document={document}
        />
    );
}

function ReimbursementVariantWarning() {
    const { t } = useTranslation('changelist');

    return (
        <Alert variant="warning">
            <p>
                <span className="font-weight-bold">{t('changelist.blocks.reimbursementBlock.reimbursement')}:</span>{' '}
                {t('changelist.blocks.reimbursementBlock.view')}
            </p>
        </Alert>
    );
}
