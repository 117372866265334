import HelperFunctions from '../../../global/HelperFunctions';
import DocumentHelper from '../../../global/DocumentHelper';
import { ChevronRight } from 'react-bootstrap-icons';
import { useGetDocument } from '../../../documents_v2/hooks/useGetDocument';
import { useGetBaseVariant } from '../../../documents_v2/hooks/useGetBaseVariant';
import Spinner from '../../../global/Spinner';
import { Table } from 'react-bootstrap';

export function LinkedBlocks({ block }) {
    const areaIds = [];
    const sectionIds = [];
    const sections = [];

    if (!block.hasOwnProperty('linkedBlocks')) {
        return null;
    }

    block.linkedBlocks.forEach((linkedBlock) => {
        if (!areaIds.includes(linkedBlock.sourceAreaId)) {
            areaIds.push(linkedBlock.sourceAreaId);
        }

        if (!sectionIds.includes(linkedBlock.sourceSectionId)) {
            sectionIds.push(linkedBlock.sourceSectionId);
            sections.push({
                sectionId: linkedBlock.sourceSectionId,
                documentId: linkedBlock.sourceDocumentId,
            });
        }
    });

    return (
        <>
            {sections.map((_section, index) => {
                return (
                    <LinkedBlockSection areaIds={areaIds} data={_section} key={`linked-block-${block.id}-${index}`} />
                );
            })}
        </>
    );
}

function LinkedBlockSection({ areaIds, data }) {
    const { sectionId, documentId } = data;
    const document = useGetDocument(documentId, true);
    const baseVariant = useGetBaseVariant(documentId);

    if (!document || !baseVariant) {
        return <Spinner />;
    }

    const section = HelperFunctions.findInDocument({ type: 'section', id: sectionId }, document);

    if (!section) {
        return null;
    }

    const areas = section.areas.filter((_area) => areaIds.includes(_area.id));

    if (!areas || areas.length === 0) {
        return null;
    }

    const sectionPrefix = DocumentHelper.getPrefix(section, baseVariant.id);

    return (
        <Table striped borderless>
            <thead>
                <tr>
                    <th>
                        {document.name} <ChevronRight />{' '}
                        {sectionPrefix && <span className="small mr-1">{sectionPrefix}</span>}
                        {section.title}
                    </th>
                </tr>
            </thead>
            <tbody>
                {areas.map((area) => {
                    const areaPrefix = DocumentHelper.getPrefix(area, baseVariant.id);

                    return (
                        <tr key={'area-row-' + area.id}>
                            <td>
                                {areaPrefix && <span className="small text-secondary mr-1">{areaPrefix}</span>}
                                {area.title}
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
}
