import { FieldSelect, Switch } from '../../helpers/FieldHelper';
import { Form } from 'react-bootstrap';
import HelperFunctions from '../../../global/HelperFunctions';
import { translationContainerTypes } from '../../../translation/config/Constants';
import { useGetTranslationContainersQuery } from '../../../../features/translations/translationApi';
import _ from 'lodash';
import Constants from '../../../../config/Constants';
import RestrictedContent from '../../../global/RestrictedContent';
import { getLanguageName } from '../../../translation/helpers/LanguageHelper';
import { useTranslation } from 'react-i18next';

export default function SectionTranslation({ index, contentItem, document, showReimbursements = true }) {
    const { documentId, translation = {} } = contentItem;
    const { translate = false, documentTranslation } = translation;
    const { documentLibrary = null } = document;
    const { t } = useTranslation('publications');

    const { translationContainers = [] } = useGetTranslationContainersQuery(undefined, {
        selectFromResult: ({ data }) => ({
            translationContainers: data,
        }),
        skip: !translate,
    });

    const documentContainer = _.isEmpty(documentTranslation)
        ? undefined
        : translationContainers.find((_container) => _container.id === documentTranslation);

    return (
        <div>
            <span className="font-weight-bolder font-size-sm text-color">
                {t('publication.settings.content.summary.expand.sectionTranslation.title')}
            </span>

            <Switch
                label={t('publication.settings.content.summary.expand.section.chapterTranslate')}
                name={`contentDecoded.${index}.translation.translate`}
            />

            {translate && (
                <>
                    <Form.Group>
                        <FieldSelect
                            label="Document"
                            name={`contentDecoded.${index}.translation.documentTranslation`}
                            options={HelperFunctions.prepareDropdownData(
                                translationContainers.filter(
                                    (_container) =>
                                        _container['@type'] === translationContainerTypes.DOCUMENT &&
                                        _container.documentId === documentId
                                )
                            )}
                            props={{
                                isClearable: true,
                                placeholder: `${t(
                                    'publication.settings.content.summary.expand.sectionTranslation.titleSelect'
                                )}...`,
                            }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <FieldSelect
                            label={t('publication.settings.content.summary.expand.sectionTranslation.documentLanguage')}
                            name={`contentDecoded.${index}.translation.documentTranslationLanguage`}
                            options={HelperFunctions.prepareDropdownData(
                                documentContainer
                                    ? documentContainer.translationLanguages.map((_language) => ({
                                          ..._language,
                                          name: getLanguageName(_language.languageIso),
                                      }))
                                    : []
                            )}
                            props={{
                                isDisabled: documentContainer === undefined,
                                isClearable: true,
                                placeholder:
                                    documentContainer === undefined
                                        ? ''
                                        : `${t(
                                              'publication.settings.content.summary.expand.sectionTranslation.titleSelect'
                                          )}...`,
                            }}
                        />
                    </Form.Group>

                    {documentLibrary !== null && (
                        <DocumentLibraryTranslation
                            index={index}
                            documentId={documentLibrary}
                            translationContainers={translationContainers}
                            translation={translation}
                        />
                    )}

                    {showReimbursements && (
                        <RestrictedContent module={Constants.modules.reimbursements}>
                            <SectionReimbursementTranslation
                                index={index}
                                translationContainers={translationContainers}
                                translation={translation}
                                document={document}
                            />
                        </RestrictedContent>
                    )}
                </>
            )}
        </div>
    );
}

function DocumentLibraryTranslation({ index, documentId, translationContainers = [], translation }) {
    const { documentLibraryTranslation } = translation;
    const documentContainer = _.isEmpty(documentLibraryTranslation)
        ? undefined
        : translationContainers.find((_container) => _container.id === documentLibraryTranslation);
    const { t } = useTranslation('publications');

    return (
        <>
            <Form.Group>
                <FieldSelect
                    label={t('publication.settings.content.summary.expand.sectionTranslation.library')}
                    name={`contentDecoded.${index}.translation.documentLibraryTranslation`}
                    options={HelperFunctions.prepareDropdownData(
                        translationContainers.filter(
                            (_container) =>
                                _container['@type'] === translationContainerTypes.DOCUMENT &&
                                _container.documentId === documentId
                        )
                    )}
                    props={{
                        isClearable: true,
                        placeholder: `${t(
                            'publication.settings.content.summary.expand.sectionTranslation.titleSelect'
                        )}...`,
                    }}
                />
            </Form.Group>
            <Form.Group>
                <FieldSelect
                    label={t('publication.settings.content.summary.expand.sectionTranslation.documentLanguage')}
                    name={`contentDecoded.${index}.translation.documentLibraryTranslationLanguage`}
                    options={HelperFunctions.prepareDropdownData(
                        documentContainer
                            ? documentContainer.translationLanguages.map((_language) => ({
                                  ..._language,
                                  name: getLanguageName(_language.languageIso),
                              }))
                            : []
                    )}
                    props={{
                        isDisabled: documentContainer === undefined,
                        isClearable: true,
                        placeholder:
                            documentContainer === undefined
                                ? ''
                                : `${t(
                                      'publication.settings.content.summary.expand.sectionTranslation.titleSelect'
                                  )}...`,
                    }}
                />
            </Form.Group>
        </>
    );
}

function SectionReimbursementTranslation({ index, translationContainers, translation, document }) {
    const { reimbursementCategoryId } = document;
    const { t } = useTranslation('publications');

    if (_.isNil(reimbursementCategoryId)) {
        return null;
    }

    const { reimbursementTranslation } = translation;
    const reimbursementContainer = _.isEmpty(reimbursementTranslation)
        ? undefined
        : translationContainers.find((_container) => _container.id === reimbursementTranslation);

    return (
        <div>
            <Form.Group>
                <FieldSelect
                    label={t('publication.settings.content.summary.expand.sectionTranslation.reimbursements')}
                    name={`contentDecoded.${index}.translation.reimbursementTranslation`}
                    options={HelperFunctions.prepareDropdownData(
                        translationContainers.filter(
                            (_container) =>
                                _container['@type'] === translationContainerTypes.REIMBURSEMENT &&
                                _container.categoryId === reimbursementCategoryId
                        )
                    )}
                    props={{
                        isClearable: true,
                        placeholder: `${t(
                            'publication.settings.content.summary.expand.sectionTranslation.titleSelect'
                        )}...`,
                    }}
                />
            </Form.Group>
            <Form.Group>
                <FieldSelect
                    label={t('publication.settings.content.summary.expand.sectionTranslation.reimbursementLanguage')}
                    name={`contentDecoded.${index}.translation.reimbursementTranslationLanguage`}
                    options={HelperFunctions.prepareDropdownData(
                        reimbursementContainer
                            ? reimbursementContainer.translationLanguages.map((_language) => ({
                                  ..._language,
                                  name: getLanguageName(_language.languageIso),
                              }))
                            : []
                    )}
                    props={{
                        isDisabled: reimbursementContainer === undefined,
                        isClearable: true,
                        placeholder:
                            reimbursementContainer === undefined
                                ? ''
                                : `${t(
                                      'publication.settings.content.summary.expand.sectionTranslation.titleSelect'
                                  )}...`,
                    }}
                />
            </Form.Group>
        </div>
    );
}
