import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { FOLDER_TRASH } from 'pages/media_library/views/Folders';
import { normalize } from 'normalizr';
import { fileEntity, folderEntity } from 'schemas';
import FileApi from '../../api/FileApi';

const MediaLibraryAdapter = createEntityAdapter({
    selectId: (entity) => entity['@id'],
    sortComparer: (a, b) => a.displayName.localeCompare(b.displayName),
});
const MediaLibraryFolderAdapter = createEntityAdapter({
    selectId: (entity) => entity['@id'],
    sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const uploadFile = createAsyncThunk(
    'mediaLibrary/uploadFile',
    async ({ file, activeOrganisation, folderId }) => {
        const { data } = await FileApi.uploadFile(file, activeOrganisation, folderId);
        const normalized = normalize(data, fileEntity);

        return normalized.entities;
    }
);

export const createFolder = createAsyncThunk('mediaLibrary/createFolder', async ({ formData }) => {
    const { data } = await FileApi.createFolder(formData);
    const normalized = normalize(data, folderEntity);

    return normalized.entities;
});

export const patchFolder = createAsyncThunk('mediaLibrary/patchFolder', async ({ uri, formData }) => {
    const { data } = await FileApi.patch(uri, formData);
    const normalized = normalize(data, folderEntity);

    return normalized.entities;
});

export const patchFile = createAsyncThunk('mediaLibrary/patchFile', async ({ uri, formData }) => {
    const { data } = await FileApi.patch(uri, formData);
    const normalized = normalize(data, fileEntity);

    return normalized.entities;
});

export const getFolders = createAsyncThunk('mediaLibrary/getFolders', async ({ organisationId }) => {
    const { data } = await FileApi.getFolders(organisationId);
    const normalized = normalize(data['hydra:member'], [folderEntity]);

    return normalized.entities;
});

export const getFiles = createAsyncThunk('mediaLibrary/getFiles', async ({ organisationId }) => {
    const { data } = await FileApi.getFiles(organisationId);
    const normalized = normalize(data['hydra:member'], [fileEntity]);

    return normalized.entities;
});

export const deleteFolder = createAsyncThunk('mediaLibrary/deleteFolder', async ({ uri }) => {
    await FileApi.delete(uri);

    return uri;
});

export const deleteFile = createAsyncThunk('mediaLibrary/deleteFile', async ({ uri }) => {
    await FileApi.delete(uri);

    return uri;
});

const mediaLibrarySlice = createSlice({
    name: 'mediaLibrary',
    initialState: {
        files: MediaLibraryAdapter.getInitialState(),
        folders: MediaLibraryFolderAdapter.getInitialState(),
        selectedFolder: null,
    },
    reducers: {
        ['security/resetState']: (state) => {
            Object.assign(state, mediaLibrarySlice.getInitialState());
        },
        setSelectedFolder: {
            reducer(state, { payload }) {
                state.selectedFolder = payload;
            },
        },
        updateFile: {
            reducer(state, { payload }) {
                MediaLibraryAdapter.updateOne(state.files, payload);
            },
        },
        updateFolder: {
            reducer(state, { payload }) {
                MediaLibraryFolderAdapter.updateOne(state.folders, payload);
            },
        },
        removeFolder: {
            reducer(state, { payload }) {
                MediaLibraryFolderAdapter.updateOne(state.folders, {
                    id: payload,
                    changes: {
                        deletedAt: 'now',
                    },
                });
            },
        },
    },
    extraReducers: {
        [uploadFile.fulfilled]: (state, action) => {
            MediaLibraryAdapter.upsertMany(state.files, action.payload.files);
        },
        [createFolder.fulfilled]: (state, action) => {
            MediaLibraryFolderAdapter.upsertMany(state.folders, action.payload.folders);
        },
        [patchFolder.fulfilled]: (state, action) => {
            MediaLibraryFolderAdapter.upsertMany(state.folders, action.payload.folders);
        },
        [patchFile.fulfilled]: (state, action) => {
            MediaLibraryAdapter.upsertMany(state.files, action.payload.files);
        },
        [getFolders.fulfilled]: (state, action) => {
            MediaLibraryFolderAdapter.upsertMany(state.folders, action.payload.folders ?? []);
        },
        [getFiles.fulfilled]: (state, action) => {
            MediaLibraryAdapter.upsertMany(state.files, action.payload.files ?? []);
        },
        [deleteFolder.fulfilled]: (state, action) => {
            MediaLibraryFolderAdapter.updateOne(state.folders, {
                id: action.payload,
                changes: {
                    deletedAt: 'now',
                },
            });
        },
        [deleteFile.fulfilled]: (state, action) => {
            MediaLibraryAdapter.updateOne(state.files, {
                id: action.payload,
                changes: {
                    deletedAt: 'now',
                },
            });
        },
    },
});

export const selectFiles = (folder) =>
    createSelector(
        [(state) => state.security.activeOrganisation, (state) => selectAllFiles(state.mediaLibrary)],
        (activeOrganisation, allFiles) => {
            if (folder === FOLDER_TRASH) {
                return allFiles.filter(
                    (_file) => _file.organisationId === activeOrganisation && _file.deletedAt !== null
                );
            }

            return allFiles.filter(
                (_file) =>
                    _file.organisationId === activeOrganisation && _file.deletedAt === null && _file.folder === folder
            );
        }
    );

export const countFiles = (folder) =>
    createSelector(
        [(state) => state.security.activeOrganisation, (state) => selectAllFiles(state.mediaLibrary)],
        (activeOrganisation, allFiles) => {
            if (folder === FOLDER_TRASH) {
                return allFiles.filter(
                    (_file) => _file.organisationId === activeOrganisation && _file.deletedAt !== null
                ).length;
            }

            return allFiles.filter(
                (_file) =>
                    _file.organisationId === activeOrganisation && _file.deletedAt === null && _file.folder === folder
            ).length;
        }
    );

export const selectFolders = (parent = null) =>
    createSelector(
        [(state) => state.security, (state) => selectAllFolders(state.mediaLibrary)],
        ({ activeOrganisation }, allFolders) => {
            return allFolders.filter(
                (_folder) =>
                    _folder.organisationId === activeOrganisation &&
                    _folder.parentFolder === parent &&
                    _folder.deletedAt === null
            );
        }
    );

export const selectFolderById = (id) =>
    createSelector(
        [(state) => state.security, (state) => selectAllFolders(state.mediaLibrary)],
        ({ activeOrganisation }, allFolders) => {
            return allFolders
                .filter(
                    (_folder) =>
                        _folder.organisationId === activeOrganisation && _folder.id === id && _folder.deletedAt === null
                )
                .shift();
        }
    );

export const { updateFile, updateFolder, removeFolder, setSelectedFolder } = mediaLibrarySlice.actions;

export const { selectAll: selectAllFiles, selectById: selectFileById } = MediaLibraryAdapter.getSelectors(
    (state) => state.files
);

const { selectAll: selectAllFolders } = MediaLibraryFolderAdapter.getSelectors((state) => state.folders);

export default mediaLibrarySlice.reducer;
