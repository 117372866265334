import { useGetDocumentTags } from '../../../tags/hooks/useGetDocumentTags';
import Constants from '../../../../config/Constants';
import { TagContentBody } from '../../../tags/Index';
import RestrictedDocumentContent from '../../RestrictedDocumentContent';
import { useParams } from 'react-router-dom';

export function Tags() {
    const { documentId } = useParams();
    const tags = useGetDocumentTags();

    return (
        <RestrictedDocumentContent documentId={documentId} roles={Object.values(Constants.userDocumentRole)}>
            <TagContentBody tags={tags} hasSubNav={true} />
        </RestrictedDocumentContent>
    );
}
