import { useGetLabelsQuery } from 'features/metadata/metadata';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';

const emptyArray = [];

export function useGetLabels(folder) {
    const activeOrganisation = useActiveOrganisation();

    const { organisationLabels } = useGetLabelsQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                organisationLabels: data ?? emptyArray,
            }),
        }
    );

    if (folder) {
        return organisationLabels.filter((label) => label.folder === folder);
    }

    return organisationLabels;
}
