import { useParams } from 'react-router-dom';
import { TranslationInner } from '../EntityTranslation';
import { entityTypes } from '../../../config/Constants';
import {
    translationApi,
    useGetCategoriesQuery,
    useGetEntityReference,
    useGetReimbursementProductFieldQuery,
    useGetTranslationKeyQuery,
} from '../../../../../features/translations/translationApi';
import { useContext, useEffect } from 'react';
import { TranslationContext } from '../Translate';
import _ from 'lodash';
import { ReimbursementContainer, TranslationLanguage } from '../../../../../models/translation.models';
import { useAppDispatch } from '../../../../../store';
import { TranslateVariantPackage } from '../document/VariantPackage';
import { useTranslation } from 'react-i18next';

export function ReimbursementTranslate({
    translationContainer,
    translationLanguage,
}: {
    translationContainer: ReimbursementContainer;
    translationLanguage: TranslationLanguage;
}) {
    const { entityType }: { entityType: string } = useParams();

    if (entityType === entityTypes.REIMBURSEMENT_PRODUCT_FIELD) {
        return (
            <TranslateReimbursementProductField
                translationContainer={translationContainer}
                translationLanguage={translationLanguage}
            />
        );
    }

    if (entityType === entityTypes.VARIANT_PACKAGE) {
        return (
            <TranslateVariantPackage
                translationContainer={translationContainer}
                translationLanguage={translationLanguage}
            />
        );
    }

    return <div>Entity with type {entityType} is not supported.</div>;
}

function TranslateReimbursementProductField({
    translationContainer,
    translationLanguage,
}: {
    translationContainer: ReimbursementContainer;
    translationLanguage: TranslationLanguage;
}) {
    const dispatch = useAppDispatch();
    const { setTitle, title }: { setTitle: (title: string) => void; title: string } = useContext(TranslationContext);
    const {
        entityType,
        entityId,
        translationKeyId,
    }: { entityType: string; entityId: string; translationKeyId: string } = useParams();
    const { t } = useTranslation('translations');

    const { languageIso } = translationLanguage;
    const { categoryId } = translationContainer;

    const { category } = useGetCategoriesQuery(undefined, {
        selectFromResult: ({ data }) => ({
            category: data ? data.find((_item) => _item.id === categoryId) : undefined,
        }),
    });

    const { translationKey } = useGetTranslationKeyQuery(translationKeyId, {
        selectFromResult: ({ data }) => ({
            translationKey: data,
        }),
    });

    const entityReference = useGetEntityReference(
        translationContainer['@id'],
        entityType,
        `/api/${entityType}/${entityId}`
    );

    const { reimbursementProductField } = useGetReimbursementProductFieldQuery(
        `/api/reimbursement-api/api/reimbursement_product_fields/${entityId}`,
        {
            selectFromResult: ({ data }) => ({
                reimbursementProductField: data,
            }),
        }
    );

    useEffect(() => {
        if (title === '' && category) {
            setTitle(category.name);
        }
    }, [title, category]);

    if (
        category === undefined ||
        translationKey === undefined ||
        entityReference === undefined ||
        reimbursementProductField === undefined
    ) {
        return null;
    }

    const handleTranslationMutation = () => {
        dispatch(
            translationApi.endpoints.getTranslationContainerEntityReferences.initiate(
                {
                    uri: translationContainer['@id'],
                    entityType: entityTypes.REIMBURSEMENT_PRODUCT_FIELD,
                },
                { subscribe: false, forceRefetch: true }
            )
        );
    };

    return (
        <TranslationInner
            isHtml
            entityReference={entityReference}
            translationKey={translationKey}
            content={_.get(reimbursementProductField, translationKey.property)}
            languageIso={languageIso}
            translationContainer={translationContainer}
            entity={{
                ...reimbursementProductField,
                title: t('translation.navbar.dashboard.translation.area.reimbursement'),
            }}
            handleTranslationMutation={handleTranslationMutation}
        />
    );
}
