import React from 'react';
import { Navbar } from 'react-bootstrap';
import { generatePath, Link, matchPath, useLocation, useParams } from 'react-router-dom';
import cx from 'classnames';
import Constants from '../../../config/Constants';
import RestrictedReimbursementContent, { useCurrentRole } from '../RestrictedReimbursementContent';
import {
    COLLECTIVES_PATH,
    EDIT_CODES_PATH,
    EDIT_PRODUCT_TYPES_PATH,
    EDIT_PRODUCTS_PATH,
    PROPOSITIONS_PATH,
    SETTINGS_PATH,
    VIEW_PATH,
} from '../../../scenes/Reimbursements';
import { Gear } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

export default function SubNav() {
    const { categoryId } = useParams();
    const { pathname } = useLocation();
    const currentUserRole = useCurrentRole(parseInt(categoryId));
    const { t } = useTranslation('reimbursements');

    const availableSettings = [
        {
            id: 'reimbursements',
            name: t('navbar.titles.reimbursement'),
            allowedRoles: [],
            url: generatePath(VIEW_PATH, { categoryId }),
            path: VIEW_PATH,
        },
        {
            id: 'products',
            name: t('navbar.titles.policyType'),
            allowedRoles: [Constants.reimbursementTeamRoles.admin],
            url: generatePath(EDIT_PRODUCTS_PATH, { categoryId }),
            path: EDIT_PRODUCTS_PATH,
        },
        {
            id: 'product_types',
            name: t('navbar.titles.productGroups'),
            allowedRoles: [Constants.reimbursementTeamRoles.admin],
            url: generatePath(EDIT_PRODUCT_TYPES_PATH, { categoryId }),
            path: EDIT_PRODUCT_TYPES_PATH,
        },
        {
            id: 'codes',
            name: t('navbar.titles.codes'),
            allowedRoles: [Constants.reimbursementTeamRoles.manager, Constants.reimbursementTeamRoles.admin],
            url: generatePath(EDIT_CODES_PATH, { categoryId }),
            path: EDIT_CODES_PATH,
        },
        {
            id: 'propositions',
            name: t('navbar.titles.propositions'),
            allowedRoles: [Constants.reimbursementTeamRoles.manager, Constants.reimbursementTeamRoles.admin],
            url: generatePath(PROPOSITIONS_PATH, { categoryId }),
            path: PROPOSITIONS_PATH,
        },
        {
            id: 'collectives',
            name: t('navbar.titles.collectivities'),
            allowedRoles: [Constants.reimbursementTeamRoles.manager, Constants.reimbursementTeamRoles.admin],
            url: generatePath(COLLECTIVES_PATH, { categoryId }),
            path: COLLECTIVES_PATH,
        },
    ];

    return (
        <Navbar fixed="top" className="subnav bg-white border-bottom px-3 py-0">
            <div className="nav w-100">
                {availableSettings
                    .filter((_setting) => {
                        if (_setting.allowedRoles.length === 0) {
                            return true;
                        }

                        return _setting.allowedRoles.includes(currentUserRole);
                    })
                    .map(({ id, name, url, path }) => (
                        <div className="nav-item" key={`nav-${id}`}>
                            <Link
                                to={url}
                                className={cx('nav-link', {
                                    active: matchPath(pathname, { path }),
                                })}
                            >
                                {name}
                            </Link>
                        </div>
                    ))}

                <RestrictedReimbursementContent roles={[Constants.reimbursementTeamRoles.admin]}>
                    <div className="nav-item ml-auto">
                        <Link
                            to={generatePath(SETTINGS_PATH, {
                                categoryId,
                                view: 'properties',
                            })}
                            className={cx('d-flex align-items-center nav-link', {
                                active: matchPath(pathname, { path: SETTINGS_PATH }),
                            })}
                            style={{ marginRight: '20px' }}
                        >
                            <Gear className="mr-1" size={14} /> {t('navbar.titles.settings')}
                        </Link>
                    </div>
                </RestrictedReimbursementContent>
            </div>
        </Navbar>
    );
}
