import { StringParam, useQueryParam } from 'use-query-params';
import React, { useCallback, useEffect, useState } from 'react';
import { PrimaryButton } from 'components/Buttons';
import { Form as FForm, Formik, useFormikContext } from 'formik';
import { DocRevSelectMemo, InputField } from '../publications_v2/helpers/FieldHelper';
import { useCreateNewAccountMutation, useValidateOnboardingOrganisationMutation } from 'features/security/authApi';
import { Alert, Form } from 'react-bootstrap';
import _ from 'lodash';
import * as Yup from 'yup';
import { useDebounce } from 'hooks/useDebounce';
import useCountries from 'use-countries';
import HelperFunctions from 'pages/global/HelperFunctions';
import { useGetStripeSubscription } from 'pages/welcome/hooks/useGetStripeSubscription';

export function CreateAccount() {
    const subscription = useGetStripeSubscription();

    if (!subscription) {
        return null;
    }

    return (
        <div id="setup-wizard">
            <NewOrderForm subscription={subscription} />
        </div>
    );
}

function NewOrderForm({ subscription }) {
    const [createNewAccount] = useCreateNewAccountMutation();
    const [errorMessage, setErrorMessage] = useState('');
    const [userId] = useQueryParam('userId', StringParam);

    if (errorMessage !== '') {
        return (
            <div id="msform" className="text-left">
                <Alert variant="danger">Error: {errorMessage}</Alert>
            </div>
        );
    }

    return (
        <Formik
            enableReinitialize
            initialValues={{
                sid: subscription.subscription.id,
                userId,
                templateLanguage: '',
                organisation: '',
                slug: '',
                terms_agree: false,
            }}
            onSubmit={handleSubmit}
            validationSchema={signupSchema}
        >
            <RenderForm availableLanguages={subscription.languages} />
        </Formik>
    );

    function handleSubmit(values, { setSubmitting }) {
        createNewAccount(values).then(({ data }) => {
            if (data?.status === 'ok') {
                window.location.replace(data.redirect);
            }
        });
    }
}

function RenderForm({ availableLanguages }) {
    const [validateOnboardingOrganisation] = useValidateOnboardingOrganisationMutation();
    const { values, isSubmitting, setFieldValue, isValid, dirty } = useFormikContext();
    const [organisationIsValid, setOrganisationIsValid] = useState(true);
    const [organisationUrlPreview, setOrganisationUrlPreview] = useState();

    const { organisation, slug, templateLanguage } = values;

    const handleOrganisationChange = useDebounce((organisation) => {
        setFieldValue('slug', HelperFunctions.slugify(organisation));
    }, 800);

    const handleSlugChange = useDebounce((slug, templateLanguage) => {
        validateOnboardingOrganisation({
            slug,
            templateLanguage,
        }).then(({ data }) => {
            const { slug, valid, url_preview } = data;
            setOrganisationIsValid(valid);
            setOrganisationUrlPreview(url_preview);

            if (slug !== values.slug) {
                setFieldValue('slug', slug);
            }
        });
    }, 500);

    useEffect(() => {
        // Debounce the slug change
        if (!_.isEmpty(slug) && !_.isEmpty(templateLanguage)) {
            handleSlugChange(slug, templateLanguage);
        } else {
            setOrganisationUrlPreview(undefined);
        }
    }, [slug, templateLanguage]);

    useEffect(() => {
        // Debounce the organisation change
        if (!_.isEmpty(organisation) && _.isEmpty(slug)) {
            handleOrganisationChange(organisation);
        }
    }, [organisation, slug]);

    return (
        <FForm id="msform">
            <h2 className="fs-title mb-4">IPID information</h2>

            <div className="form-card">
                <AccountForm
                    organisationIsValid={organisationIsValid}
                    organisationUrlPreview={organisationUrlPreview}
                    availableLanguages={availableLanguages}
                />
            </div>

            <div className="d-flex align-items-center justify-content-end pt-3">
                <PrimaryButton type="submit" className="action-button" disabled={!dirty || !isValid || isSubmitting}>
                    Finish
                </PrimaryButton>
            </div>
        </FForm>
    );
}

function AccountForm({ organisationIsValid, availableLanguages = [], organisationUrlPreview }) {
    const { setFieldValue } = useFormikContext();
    const { languages } = useCountries();
    const languageOptions = getLanguageOptions();

    return (
        <>
            <InputSelectWithTitle
                title="IPID Language"
                name="templateLanguage"
                options={languageOptions}
                props={{ required: true, placeholder: 'Select your language' }}
                help="This is the language of the country where you want to use your IPIDs"
            />

            <FormFieldWithTitle
                label="Organisation name"
                name="organisation"
                props={{
                    required: true,
                    placeholder: 'Organisation name',
                }}
            />

            <FormFieldWithTitle
                label="Publication url"
                name="slug"
                props={{
                    required: true,
                    placeholder: 'Url',
                    onChange: handleSlugChange,
                }}
                help="This will show in your IPID urls. See example below:"
            />

            {!organisationIsValid && <Alert variant="danger">We're sorry, but this name is already taken!</Alert>}

            {organisationIsValid && organisationUrlPreview && (
                <Alert variant="primary">Sample IPID url: {organisationUrlPreview}</Alert>
            )}
        </>
    );

    function handleSlugChange(event) {
        setFieldValue('slug', HelperFunctions.slugify(event.currentTarget.value));
    }

    function getLanguageOptions() {
        const options = languages
            .filter((language) => {
                return availableLanguages.includes(language.code);
            })
            .sort(HelperFunctions.sortByString('name'));

        return HelperFunctions.prepareDropdownData(options, 'name', 'code');
    }
}

export function FormFieldWithTitle({ label, name, as = undefined, props = {}, help = '' }) {
    return (
        <Form.Group className="mb-3">
            <label className="label-title-label" htmlFor={name}>
                {label}
            </label>
            <InputField name={name} as={as} props={props} />
            {help !== '' && (
                <Form.Text id={name} muted>
                    {help}
                </Form.Text>
            )}
        </Form.Group>
    );
}

function InputSelectWithTitle({ name, title, options = [], props = {}, size = '', defaultValue = '', help = '' }) {
    const { values, setFieldValue, errors, touched } = useFormikContext();
    const selectedValue = _.get(values, name) ?? defaultValue;

    const onChangeCallback = useCallback((selection) => {
        setFieldValue(name, selection?.value ?? '');
    }, []);

    return (
        <Form.Group className="mb-3">
            <label className="label-title-label" htmlFor={name}>
                {title}
            </label>
            <DocRevSelectMemo
                name={name}
                options={options}
                selectedValue={selectedValue}
                props={props}
                onChange={onChangeCallback}
                hasError={_.get(errors, name) && _.get(touched, name)}
                size={size}
            />
            {help !== '' && (
                <Form.Text id={name} muted>
                    {help}
                </Form.Text>
            )}
        </Form.Group>
    );
}

const signupSchema = Yup.object().shape({
    sid: Yup.string().required(),
    userId: Yup.string().required(),
    organisation: Yup.string().required(),
    slug: Yup.string().required(),
    templateLanguage: Yup.string().required(),
});
