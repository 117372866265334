import { Button } from 'react-bootstrap';
import { useState } from 'react';
import MediaSelectorModal from './media_selector/MediaSelectorModal';
import _ from 'lodash';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useGetFile } from 'pages/media_library/hooks/useGetFile';
import { Skeleton } from 'components/Skeleton';

export default function MediaSelector({ name, selectedValue, onChange = undefined, readOnly = false, className = '' }) {
    const { t } = useTranslation('documents');
    const [showModal, setShowModal] = useState(false);

    const uri = getFileUri(selectedValue);
    const hasMedia = isValidUri(uri);

    return (
        <>
            {hasMedia && (
                <MediaPreview
                    className={className}
                    uri={uri}
                    removeMedia={() => handleChange(null)}
                    showModal={() => {
                        if (readOnly === false) {
                            setShowModal(true);
                        }
                    }}
                    readOnly={readOnly}
                />
            )}

            {!hasMedia && (
                <div className="d-flex align-items-center h-100">
                    <Button onClick={() => setShowModal(true)} size="sm" disabled={readOnly}>
                        {t('document.navbar.main.editor.left.blocks.types.fileBlock.mainButton')}
                    </Button>
                    <input type="hidden" name={name} value={uri} />
                </div>
            )}

            {showModal && <MediaSelectorModal onHide={() => setShowModal(false)} onSubmit={handleSubmit} />}
        </>
    );

    function handleChange(value) {
        if (_.isFunction(onChange)) {
            onChange(value);
        }
    }

    function handleSubmit(value) {
        // Trigger callback function
        handleChange(value);

        // Close modal
        setShowModal(false);
    }
}

export function MediaPreview({ uri, removeMedia, showModal, readOnly, className }) {
    const { t } = useTranslation('documents');
    const file = useGetFile(uri);

    return (
        <div>
            {file ? (
                <div className={cx(className, 'media-selector-img m-0 mb-2')} onClick={showModal}>
                    <img
                        className="image"
                        src={file.thumbnailSquare}
                        alt={file.description}
                        data-uk-tooltip={file.displayName}
                    />
                </div>
            ) : (
                <Skeleton height={170} width={170} />
            )}

            {readOnly === false && (
                <Button variant="danger" size="sm" onClick={removeMedia}>
                    {t('document.navbar.main.editor.left.blocks.types.fileBlock.delete')}
                </Button>
            )}
        </div>
    );
}

export const getFileUri = (selectedValue) => {
    if (_.isString(selectedValue) && selectedValue.startsWith('/api/uploads')) {
        return selectedValue;
    }

    if (_.isObject(selectedValue) && _.has(selectedValue, 'fileId') && selectedValue.fileId !== null) {
        return '/api/uploads/' + selectedValue.fileId;
    }

    return '';
};

export const isValidUri = (uri) => {
    return uri.startsWith('/api/uploads');
};
