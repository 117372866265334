import { FilterDateRange, FilterSelect } from 'pages/task_team/helpers/FilterHelper';
import { useGetAvailableUsers } from 'pages/task_team/hooks/useGetAvailableUsers';
import { yesNoOptions } from 'pages/task_team/helpers/FormHelper';
import { useGetTaskStatusOptions } from 'pages/task_team/config/useGetTaskStatusOptions';
import { useCanViewConfidentialTasks } from 'pages/task_team/hooks/permissions';
import HelperFunctions from 'pages/global/HelperFunctions';
import { useGetCustomerJourneys } from 'pages/task_team/hooks/useGetCustomerJourneys';
import { useGetLabelFolders } from 'pages/labels/hooks/useGetLabelFolders';
import { entityTypes } from 'pages/translation/config/Constants';
import { useGetLabels } from 'pages/labels/hooks/useGetLabels';
import { useMemo } from 'react';

export const defaultFilterAllOptions = {
    archived: '0',
};

export function FilterAll() {
    const availableUsers = useGetAvailableUsers();
    const taskStatusOptions = useGetTaskStatusOptions();
    const canViewConfidentialTasks = useCanViewConfidentialTasks();
    const customerJourneys = useGetCustomerJourneys();

    return (
        <>
            <FilterSelect placeholder="Status..." label="Status" options={taskStatusOptions} name="status" />

            <FilterDateRange label="Deadline" name="deadline" />
            <FilterDateRange label="Datum ingediend" name="createdAt" />

            <FilterSelect placeholder="Eigenaar..." label="Eigenaar" options={availableUsers} name="createdBy" />

            <FilterSelect
                placeholder="Klantreis..."
                label="Klantreis"
                options={HelperFunctions.prepareDropdownData(customerJourneys, 'name', '@id')}
                name="customerJourney"
            />

            <FilterSelect
                defaultFilterOptions={defaultFilterAllOptions}
                placeholder="Gearchiveerd..."
                label="Gearchiveerd"
                options={yesNoOptions}
                name="archived"
                isClearable={false}
            />

            {canViewConfidentialTasks && (
                <FilterSelect
                    placeholder="Onder embargo..."
                    label="Onder embargo"
                    options={yesNoOptions}
                    name="confidential"
                />
            )}

            <FilterTags />
        </>
    );
}

function FilterTags() {
    const labelFolders = useGetLabelFolders(entityTypes.TASK);
    const allLabels = useGetLabels();

    const labels = useMemo(() => {
        if (labelFolders.length === 0 || allLabels.length === 0) {
            return [];
        }

        let labelUris = [];

        labelFolders.forEach((folder) => {
            labelUris = labelUris.concat(folder.labels);
        });

        return allLabels.filter((label) => labelUris.includes(label['@id'])).sort(HelperFunctions.sortByString('name'));
    }, [labelFolders, allLabels]);

    if (labels.length === 0) {
        return null;
    }

    return (
        <FilterSelect
            placeholder="Label..."
            label="Label"
            options={HelperFunctions.prepareDropdownData(labels, 'name', '@id')}
            name="label"
        />
    );
}
