import { BlockType } from '../BlockType';
import { useTranslation } from 'react-i18next';

export function EmptyTextBlock({ block }) {
    const { t } = useTranslation('documents');

    return (
        <div className="position-relative">
            <div className="editor-toolbar">
                <BlockType block={block} />
            </div>

            <div className="edit-area-block edit-area-block-type-text">
                <div className="edit-area-block-content bg-white">
                    <div className="tiny-mce-content-body p-4">
                        <p className="newTextBlockPlaceholder">
                            {t('document.navbar.main.editor.left.blocks.types.emptyTextBlock.text')}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
