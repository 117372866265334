import Constants from '../../../../config/Constants';
import RestrictedDocumentContent from '../../RestrictedDocumentContent';
import { useParams } from 'react-router-dom';
import { TemplateContentBody } from './templates/TemplatesTable';
import TemplateSideBar from './templates/TemplateSideBar';
import TemplateParameters from './templates/TemplateParameters';

export function Templates({ document }) {
    const { documentId } = useParams();

    return (
        <RestrictedDocumentContent documentId={documentId} roles={Object.values(Constants.userDocumentRole)}>
            <div className="content-static-body has-subnav">
                <div className="d-flex align-items-stretch h-100">
                    <div className="content-sidebar-document overflow-auto bg-light">
                        <TemplateSideBar />
                    </div>
                    <div className="flex-grow-1 overflow-auto">
                        <div id="content" className="px-5 pt-5 pb-3">
                            <Tabs document={document} />
                        </div>
                    </div>
                </div>
            </div>
        </RestrictedDocumentContent>
    );
}

function Tabs() {
    const { page = 'parameters' } = useParams();

    switch (page) {
        case 'parameters':
            return <TemplateParameters document={document} />;
        case 'versions':
            return <TemplateContentBody />;
    }
}
