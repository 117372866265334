import { useGetAccounts } from '../hooks/useGetAccounts';
import { ActionLinkButton, PrimaryButton } from 'components/Buttons';
import { ADMIN_SETTING_ACCOUNT_PATH } from 'scenes/Admin';
import { Badge, Table } from 'react-bootstrap';
import { generatePath, useHistory } from 'react-router-dom';
import { BooleanParam, useQueryParam } from 'use-query-params';

export function AccountsList() {
    const [createAccount, setCreateAccount] = useQueryParam('createAccount', BooleanParam);
    const accounts = useGetAccounts();

    return (
        <div>
            <div className="subheader d-flex align-items-center justify-content-between pb-1 mb-3">
                <h3 className="mb-0">Accounts</h3>

                <PrimaryButton variant="info" size="sm" className="mb-0" onClick={() => setCreateAccount(true)}>
                    Nieuw account
                </PrimaryButton>
            </div>

            <Table striped>
                <thead>
                    <tr>
                        <td className="font-weight-bold border-top-0">Naam</td>
                        <td className="font-weight-bold border-top-0">Slug</td>
                        <td className="font-weight-bold border-top-0">Organisaties</td>
                        <td className="font-weight-bold border-top-0">Gebruikers</td>
                    </tr>
                </thead>
                <tbody>
                    {accounts.map((account) => (
                        <Account account={account} key={`account-${account.id}`} />
                    ))}
                </tbody>
            </Table>
        </div>
    );
}

function Account({ account }) {
    const history = useHistory();

    return (
        <tr>
            <td className="align-middle">
                <ActionLinkButton
                    onClick={() => {
                        history.push(
                            generatePath(ADMIN_SETTING_ACCOUNT_PATH, {
                                id: account.id,
                            })
                        );
                    }}
                    className="font-weight-bolder"
                >
                    {account.name}
                </ActionLinkButton>
            </td>
            <td className="align-middle text-muted">{account.slug}</td>
            <td className="align-middle">
                <Badge variant="primary">{account.organisationCount}</Badge>
            </td>
            <td className="align-middle">
                <Badge variant="primary">{account.userCount}</Badge>
            </td>
        </tr>
    );
}
