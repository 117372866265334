import { FormModal } from '../../global/FormModal';
import { Button, Modal } from 'react-bootstrap';
import LoadingSpinner from '../../global/LoadingSpinner';
import Parser from 'html-react-parser';
import { useGetAreaQuery, useGetBlockCloneContentQuery } from '../../../features/documents/documents';
import { ChevronRight } from 'react-bootstrap-icons';
import { useGetDocuments } from '../hooks/useGetDocuments';
import { useTranslation } from 'react-i18next';

export function PreviousBlockVersionsModal({ block, handleClose }) {
    const { t } = useTranslation('documents');

    return (
        <FormModal
            show={true}
            onHide={handleClose}
            title={t('document.navbar.main.editor.left.blocks.modals.previousBlockVersionsModal.compare')}
            size="lg"
        >
            <>
                <Modal.Body>
                    <Block id={block.id} />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="btn btn-secondary" onClick={() => handleClose()}>
                        {t('btn.close')}
                    </Button>
                </Modal.Footer>
            </>
        </FormModal>
    );
}

function Block({ id }) {
    const { t } = useTranslation('documents');

    const { blocks } = useGetBlockCloneContentQuery(id, {
        selectFromResult: ({ data }) => ({
            blocks: data,
        }),
    });

    return (
        <div>
            {!blocks && <LoadingSpinner inline size="sm" />}

            {blocks && blocks.length === 0 && (
                <div>
                    <p>{t('document.navbar.main.editor.left.blocks.modals.previousBlockVersionsModal.identical')}</p>
                </div>
            )}

            {blocks && blocks.map((block, index) => <BlockContent data={block} key={`block-${id}-content-${index}`} />)}
        </div>
    );
}

function BlockContent({ data }) {
    const { areaId, oldContextId } = data;
    const documents = useGetDocuments();
    const document = documents.find((_document) => _document.id === parseInt(oldContextId));

    const { area } = useGetAreaQuery(areaId, {
        selectFromResult: ({ data }) => ({
            area: data,
        }),
    });

    if (!document && !area) {
        return null;
    }

    return (
        <div className="px-4 mb-4">
            <div className="d-flex mb-3">
                {document && <div className="font-weight-bold mr-1">{document.name}</div>}
                {document && area && <ChevronRight />}
                {area && <div className="text-secondary">{area.title}</div>}
            </div>

            <div className="read-only-content small bg-light dr-container p-3">{Parser(data.diffContent)}</div>
        </div>
    );
}
