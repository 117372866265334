import Tooltip from '../../../global/Tooltip';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { useGetUserFullName } from '../../../../hooks/useGetUserFullName';

export function PublicationStatusBadgeWithTooltip({ publishedBy, publishedAt, children }) {
    const { t, i18n } = useTranslation('publications');
    const creatorName = useGetUserFullName(publishedBy, false);

    const createdAt = DateTime.fromISO(publishedAt).setLocale(i18n.language).toLocaleString(DateTime.DATETIME_MED);
    const tooltip = t('publications.tooltip.publishedBy', { name: creatorName, date: createdAt });

    return <Tooltip tooltip={tooltip}>{children}</Tooltip>;
}
