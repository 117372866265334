import { useGetSubscription } from 'pages/account/views/billing/useGetSubscription';
import React, { useState } from 'react';
import { SecondaryButton, SuccessButton } from 'components/Buttons';
import { generatePath, useHistory } from 'react-router-dom';
import { SETTINGS_PATH } from 'scenes/Account';
import Spinner from 'pages/global/Spinner';
import { useAddExtraProductMutation } from 'features/security/authApi';
import { useUserProfile } from 'hooks/useUserProfile';
import { Trans, useTranslation } from 'react-i18next';

export function Seats() {
    const { t } = useTranslation('users');
    const { t: tGlobal } = useTranslation('global');
    const { subscription, extraSeatProduct, extraSeatPrice } = useGetSubscription();
    const [extraSeats, setExtraSeats] = useState(1);
    const [isSubmitting, setSubmitting] = useState(false);
    const history = useHistory();
    const [addExtraProduct] = useAddExtraProductMutation();
    const userProfile = useUserProfile();

    if (!extraSeatProduct || !extraSeatPrice) {
        return null;
    }

    const price = new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR',
    }).format(extraSeatPrice.unit_amount / 100);

    const newPrice = new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR',
    }).format((extraSeats * extraSeatPrice.unit_amount) / 100);

    return (
        <>
            <div className="subheader">
                <h3>{t('profile.billing.addSeatsTitle')}</h3>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-4">
                <div>
                    <input
                        type="number"
                        min={1}
                        className="form-control d-inline mr-2"
                        value={extraSeats}
                        onChange={(e) => setExtraSeats(e.currentTarget.value)}
                        style={{
                            maxWidth: 100,
                        }}
                    />
                    <span>{t('profile.billing.newSeatsAmount', { price })}</span>
                </div>
                <div>
                    <div className="font-weight-bold text-right">{newPrice}</div>
                </div>
            </div>

            {extraSeats > 0 && (
                <div className="text-muted small mb-4">
                    <Trans
                        ns="users"
                        i18nKey="profile.billing.numberOfSeatsNew"
                        values={{
                            newPrice,
                            extraSeats,
                            total: subscription.seats + extraSeats
                        }}
                        components={{ bold: <strong /> }}
                    />
                </div>
            )}

            <div className="d-flex align-items-center">
                <SuccessButton onClick={handleSubmit} className="mr-2" disabled={extraSeats <= 0 || isSubmitting}>
                    {t('profile.billing.addSeat')}
                </SuccessButton>
                <SecondaryButton
                    className="mr-1"
                    onClick={() =>
                        history.push(
                            generatePath(SETTINGS_PATH, {
                                view: 'billing',
                            })
                        )
                    }
                >
                    {tGlobal('btn.cancel')}
                </SecondaryButton>

                {isSubmitting && <Spinner />}
            </div>
        </>
    );

    function handleSubmit() {
        setSubmitting(true);

        addExtraProduct({
            accountId: userProfile.accountId,
            type: 'extra_seat_product',
            quantity: extraSeats,
        }).then(() => {
            setSubmitting(false);

            history.push(
                generatePath(SETTINGS_PATH, {
                    view: 'billing',
                })
            );
        });
    }
}
