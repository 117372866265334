import { useEffect, useMemo } from 'react';
import getEnv from '../config/Env';

export function ReactTitle({ title }: { title: string }) {
    const titlePrefix = useMemo(() => getEnv('META_TITLE'), []);

    useEffect(() => {
        document.title = title === '' ? titlePrefix : `${titlePrefix} - ${title}`;
    }, [title]);

    return null;
}
