import { Col, Form, Row } from 'react-bootstrap';
import { Field, useField, useFormikContext } from 'formik';
import _ from 'lodash';
import Select from 'react-select';
import MediaSelector from '../../media_library/views/MediaSelector';
import { findDeep } from 'deepdash-es/standalone';
import Creatable from 'react-select/creatable';
import cx from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import HelperFunctions from '../../global/HelperFunctions';
import { filterVariants } from '../../documents/misc/_EditArea/EditAreaSidebar';
import { VariantSelectRow } from '../../documents/misc/VariantViewButtons';
import FormCheckLabel from 'react-bootstrap/FormCheckLabel';
import FormCheck from 'react-bootstrap/FormCheck';

export const FormFieldWithTitle = ({ label, name, as = undefined, props = {}, help = '' }) => {
    const isRequired = props.hasOwnProperty('required') && props.required === true;
    let formLabel = label;

    if (isRequired && formLabel.endsWith('*') === false) {
        formLabel = formLabel + '*';
    }

    return (
        <div className="mb-3">
            <Form.Group className="mb-2 label-title">
                <label className="mb-2 text-color font-size-sm font-weight-bolder label-title-label" htmlFor={name}>
                    {formLabel}
                </label>
                <InputField name={name} as={as} props={props} />
                {help !== '' && (
                    <Form.Text id={name} muted>
                        {help}
                    </Form.Text>
                )}
            </Form.Group>
        </div>
    );
};

export const SwitchWithTitle = ({ label, title, name, help = '', labelWidth = 4, formWidth = 8 }) => {
    return (
        <>
            <div className="mb-2 font-weight-bolder label-title">{title}</div>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column sm={labelWidth} htmlFor={name}>
                    {label}
                </Form.Label>
                <Col sm={formWidth}>
                    <div className="col-form-label-switch">
                        <SwitchField name={name} />
                        {help !== '' && (
                            <Form.Text id={name} muted>
                                {help}
                            </Form.Text>
                        )}
                    </div>
                </Col>
            </Form.Group>
        </>
    );
};

export function TranslatableSwitch(props) {
    return (
        <Form.Switch {...props}>
            <FormCheck.Input {...props} />
            <TranslatableLabel label={props.label} />
        </Form.Switch>
    );
}

export function SwitchField({ label, name }) {
    const { values, setFieldValue } = useFormikContext();

    return (
        <TranslatableSwitch
            label={label}
            id={name}
            name={name}
            checked={_.get(values, name) ?? false}
            onChange={(event) => {
                setFieldValue(name, event.target.checked);
            }}
        />
    );
}

function TranslatableLabel({ label = '' }) {
    const { t } = useTranslation('global');

    return (
        <FormCheckLabel dataon={t('field.switchLabel.on')} dataoff={t('field.switchLabel.off')}>
            {label}
        </FormCheckLabel>
    );
}

export const InputSelectWithTitle = ({ name, title, options = [], props = {}, size = '', defaultValue = '' }) => {
    const { values, setFieldValue, errors, touched } = useFormikContext();
    const selectedValue = _.get(values, name) ?? defaultValue;

    const isRequired = props.hasOwnProperty('required') && props.required === true;
    let formLabel = title;

    if (isRequired && formLabel.endsWith('*') === false) {
        formLabel = formLabel + '*';
    }

    const onChangeCallback = useCallback((selection) => {
        setFieldValue(name, selection?.value ?? '');
    }, []);

    return (
        <div className="mb-3">
            <div className="mb-2 font-weight-bolder label-title">{formLabel}</div>
            <DocRevSelectMemo
                name={name}
                options={options}
                selectedValue={selectedValue}
                props={props}
                onChange={onChangeCallback}
                hasError={_.get(errors, name) && _.get(touched, name)}
                size={size}
            />
        </div>
    );
};

export const InputSelectWithTitleIcon = ({ name, title, options = [], props = {}, size = '', defaultValue = '' }) => {
    const { values, setFieldValue, errors, touched } = useFormikContext();
    const selectedValue = _.get(values, name) ?? defaultValue;

    const onChangeCallback = useCallback((selection) => {
        setFieldValue(name, selection?.value ?? '');
    }, []);

    return (
        <>
            <div className="mb-2">{title}</div>
            <DocRevSelectMemo
                name={name}
                options={options}
                selectedValue={selectedValue}
                props={props}
                onChange={onChangeCallback}
                hasError={_.get(errors, name) && _.get(touched, name)}
                size={size}
            />
        </>
    );
};

export const FormField = ({ label, name, as = undefined, props = {}, help = '', alertText = '' }) => {
    const isRequired = props.hasOwnProperty('required') && props.required === true;
    let formLabel = label;

    if (isRequired && formLabel.endsWith('*') === false) {
        formLabel = formLabel + '*';
    }

    return (
        <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4} htmlFor={name}>
                {formLabel}
            </Form.Label>
            <Col sm={8}>
                <InputField name={name} as={as} props={props} />
                {props.isInvalid && alertText !== '' && (
                    <div id={name} className="invalid-feedback">
                        {alertText}
                    </div>
                )}
                {help !== '' && (
                    <Form.Text id={name} muted>
                        {help}
                    </Form.Text>
                )}
            </Col>
        </Form.Group>
    );
};

export const InputField = ({ name, as = Form.Control, props = {} }) => {
    const { errors, touched } = useFormikContext();

    return (
        <DocRevInputFieldMemo
            name={name}
            as={as}
            isInvalid={_.get(errors, name) && _.get(touched, name)}
            props={props}
        />
    );
};

function DocRevInputField({ name, as = Form.Control, isInvalid, props = {} }) {
    return <Field id={name} name={name} as={as} isInvalid={isInvalid ?? props.isInvalid} {...props} />;
}

const DocRevInputFieldMemo = React.memo(DocRevInputField);

export const Textarea = ({ label, name, props = {}, help = '' }) => {
    const { errors, touched } = useFormikContext();

    const isRequired = props.hasOwnProperty('required') && props.required === true;
    let formLabel = label;

    if (isRequired && formLabel.endsWith('*') === false) {
        formLabel = formLabel + '*';
    }

    return (
        <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4} htmlFor={name}>
                {formLabel}
            </Form.Label>
            <Col sm={8}>
                <Field
                    id={name}
                    name={name}
                    as="textarea"
                    className={_.get(errors, name) && _.get(touched, name) ? 'form-control is-invalid' : 'form-control'}
                    {...props}
                />
                {help !== '' && (
                    <Form.Text id={name} muted>
                        {help}
                    </Form.Text>
                )}
            </Col>
        </Form.Group>
    );
};

export const TextareaWithTitle = ({ label, name, props = {}, help = '' }) => {
    const { errors, touched } = useFormikContext();

    const isRequired = props.hasOwnProperty('required') && props.required === true;
    let formLabel = label;

    if (isRequired && formLabel.endsWith('*') === false) {
        formLabel = formLabel + '*';
    }

    return (
        <Form.Group as={Row} className="mb-3 ml-0 mr-0 ">
            <div className="mb-2 text-color font-size-sm font-weight-bolder">{formLabel}</div>
            <Field
                id={name}
                name={name}
                as="textarea"
                className={_.get(errors, name) && _.get(touched, name) ? 'form-control is-invalid' : 'form-control'}
                {...props}
            />
            {help !== '' && (
                <Form.Text id={name} muted>
                    {help}
                </Form.Text>
            )}
        </Form.Group>
    );
};

export const Switch = ({
    label,
    name,
    help = '',
    props = {},
    labelWidth = 4,
    formWidth = 8,
    wrapperClassName = 'mb-3 mt-n1',
}) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(name);

    const isChecked = field.value ?? false;
    const handleToggle = () => {
        setFieldValue(name, !isChecked);
    };

    return (
        <Form.Group as={Row} className={wrapperClassName}>
            <Form.Label column sm={labelWidth} htmlFor={name}>
                {label}
            </Form.Label>
            <Col sm={formWidth}>
                <div className="col-form-label-switch">
                    <TranslatableSwitch id={name} name={name} checked={isChecked} onChange={handleToggle} {...props} />
                    {help !== '' && (
                        <Form.Text id={name} muted>
                            {help}
                        </Form.Text>
                    )}
                </div>
            </Col>
        </Form.Group>
    );
};

export const Checkbox = ({
    id = '',
    name = '',
    ref = null,
    checked = false,
    onChange = () => {},
    value = '',
    disabled = false,
    className = '',
    ...props
}) => {
    return (
        <Form.Check
            htmlFor={id}
            custom
            id={id}
            ref={ref}
            disabled={disabled}
            name={name}
            value={value}
            checked={checked}
            onChange={onChange}
            className={className}
            {...props}
        />
    );
};

export const FieldSelect = ({
    label,
    name,
    options = [],
    props = {},
    help = '',
    labelWidth = 4,
    formWidth = 8,
    emptyValue = '',
}) => {
    const { t } = useTranslation('global');
    const isRequired = props.hasOwnProperty('required') && props.required === true;
    let formLabel = label;

    if (isRequired && formLabel.endsWith('*') === false) {
        formLabel = formLabel + '*';
    }

    return (
        <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={labelWidth} htmlFor={name}>
                {formLabel}
            </Form.Label>
            <Col sm={formWidth}>
                <InputSelect
                    props={{
                        placeholder: `${t('field.FieldSelect')}...`,
                        ...props,
                    }}
                    name={name}
                    options={options}
                    emptyValue={emptyValue}
                />
                {help !== '' && (
                    <Form.Text id={name} muted>
                        {help}
                    </Form.Text>
                )}
            </Col>
        </Form.Group>
    );
};

export const InputSelect = ({ name, options = [], props = {}, size = '', defaultValue = '', emptyValue = '' }) => {
    const { t } = useTranslation();
    const { values, setFieldValue, errors, touched } = useFormikContext();
    const selectedValue = _.get(values, name) ?? defaultValue;

    const onChangeCallback = useCallback((selection) => {
        setFieldValue(name, selection?.value ?? emptyValue);
    }, []);

    const defaultProps = {
        placeholder: t('global:field.selectPlaceholder'),
    };

    return (
        <DocRevSelectMemo
            name={name}
            options={options}
            selectedValue={selectedValue}
            props={{
                ...defaultProps,
                ...props,
            }}
            onChange={onChangeCallback}
            hasError={_.get(errors, name) && _.get(touched, name)}
            size={size}
        />
    );
};

export const DocRevSelect = ({
    name,
    options = [],
    selectedValue = '',
    props = {},
    onChange,
    hasError = false,
    size = '',
}) => {
    let newSelectedOption = null;

    const selectedOption = useMemo(() => {
        if (selectedValue === '') {
            return '';
        }

        const option = findDeep(
            options,
            (value) => {
                const option = value;

                if (option.hasOwnProperty('value') === false) {
                    return undefined;
                }
                return option.value === selectedValue;
            },
            {
                childrenPath: 'options',
            },
        );

        if (option) {
            return option.value;
        }

        return '';
    }, [selectedValue, options]);

    if (props.isCrsf === true && selectedOption?.type === 'area') {
        newSelectedOption = {
            ...selectedOption,
            label: selectedOption.label.startsWith('- ') ? selectedOption.label.substring(2) : selectedOption.label,
        };
    }

    const finalOptionValue = props?.isCrsf && selectedOption?.type === 'area' ? newSelectedOption : selectedOption;

    return (
        <Select
            options={options}
            name={name}
            value={finalOptionValue}
            onChange={(selection, action) => onChange(selection, action)}
            className={cx('docrev-react-select-container', {
                'is-invalid': hasError,
                'docrev-react-select-small': size === 'sm',
            })}
            classNamePrefix="docrev-react-select"
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            theme={(theme) => {
                return {
                    ...theme,
                    spacing: {
                        ...theme.spacing,
                        menuGutter: 6,
                    },
                };
            }}
            {...props}
        />
    );
};

export function DocumentVariantSelect({
    baseVariant,
    availableVariants = [],
    name,
    label,
    defaultValue = '',
    labelWidth = 4,
    formWidth = 8,
    props = {},
    showBaseVariant = true,
}) {
    const { values, setFieldValue, errors, touched } = useFormikContext();
    const selectedValue = _.get(values, name) ?? defaultValue;
    const { t } = useTranslation('documents');

    const isRequired = props.hasOwnProperty('required') && props.required === true;
    let formLabel = label;

    if (isRequired && formLabel.endsWith('*') === false) {
        formLabel = formLabel + '*';
    }

    const options = useMemo(() => {
        const localVariants = [];

        if (!baseVariant) {
            return [];
        }

        const filtered = HelperFunctions.flatten(
            availableVariants.length > 0
                ? filterVariants(baseVariant.children, availableVariants)
                : baseVariant.children,
        );

        if (showBaseVariant) {
            localVariants.push({
                label: <VariantSelectRow name={t('document.navbar.main.editor.left.subnav.selectVariant')} />,
                value: baseVariant.id,
                variant: {
                    prefix: '',
                    name: t('document.navbar.main.editor.left.subnav.selectVariant'),
                },
            });
        }

        filtered
            .filter((_variant) => _variant.parentId === baseVariant.id || _variant.variantGroup)
            .forEach((_variant) => {
                if (_variant.variantGroup) {
                    localVariants.push({
                        label: <VariantSelectRow name={_variant.name} prefix={_variant.prefix} />,
                        options: filtered
                            .filter(
                                (_childVariant) =>
                                    _childVariant.parentId === _variant.id && _childVariant.variantGroup === false,
                            )
                            .map((_childVariant) => {
                                return {
                                    label: <VariantSelectRow name={_childVariant.name} prefix={_childVariant.prefix} />,
                                    value: _childVariant.id,
                                    variant: {
                                        prefix: _childVariant.prefix ?? '',
                                        name: _childVariant.name,
                                    },
                                };
                            }),
                    });
                } else {
                    localVariants.push({
                        label: <VariantSelectRow name={_variant.name} prefix={_variant.prefix} />,
                        value: _variant.id,
                        variant: {
                            prefix: _variant.prefix ?? '',
                            name: _variant.name,
                        },
                    });
                }
            });

        return localVariants;
    }, [baseVariant, availableVariants]);

    const onChangeCallback = useCallback((selection) => {
        setFieldValue(name, selection?.value ?? '');
    }, []);

    const filterOption = ({ label, value, data }, input) => {
        if (_.isEmpty(input)) {
            return true;
        }

        const { variant } = data;
        const title = variant.prefix + variant.name;

        return title.toLowerCase().includes(input.toLowerCase());
    };

    return (
        <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={labelWidth} htmlFor={name}>
                {formLabel}
            </Form.Label>
            <Col sm={formWidth}>
                <DocRevSelect
                    onChange={onChangeCallback}
                    selectedValue={selectedValue}
                    options={options}
                    name={name}
                    hasError={_.get(errors, name) && _.get(touched, name)}
                    props={{
                        ...props,
                        filterOption,
                    }}
                />
            </Col>
        </Form.Group>
    );
}

export const DocRevSelectMemo = React.memo(DocRevSelect);

export const InputMultipleSelect = ({
    name,
    options = [],
    props = {},
    onChange,
    hasError = false,
    creatable = false,
    defaultValue = '',
    value,
    maxLimit = 0,
}) => {
    const { t } = useTranslation();

    const defaultProps = {
        placeholder: t('global:field.selectPlaceholder'),
        formatCreateLabel: (inputValue) => `${t('global:field.createSelectLabel')} "`.concat(inputValue, '"'),
    };

    const newProps = {
        ...defaultProps,
        ...props,
    };

    if (creatable) {
        return (
            <Creatable
                isMulti
                options={options}
                value={value}
                defaultValue={defaultValue}
                name={name}
                onChange={(selection, action) => onChange(selection, action)}
                className={hasError ? 'docrev-react-select-container is-invalid' : 'docrev-react-select-container'}
                classNamePrefix="docrev-react-select"
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                theme={(theme) => {
                    return {
                        ...theme,
                        spacing: {
                            ...theme.spacing,
                            menuGutter: 6,
                        },
                    };
                }}
                {...newProps}
            />
        );
    }

    return (
        <Select
            isMulti
            isOptionDisabled={() => maxLimit > 0 && value.length >= maxLimit}
            options={options}
            value={value}
            name={name}
            defaultValue={defaultValue}
            onChange={(selection, action) => onChange(selection, action)}
            className={hasError ? 'docrev-react-select-container is-invalid' : 'docrev-react-select-container'}
            classNamePrefix="docrev-react-select"
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            theme={(theme) => {
                return {
                    ...theme,
                    spacing: {
                        ...theme.spacing,
                        menuGutter: 6,
                    },
                };
            }}
            {...newProps}
        />
    );
};

export const InputCreatableSelect = ({
    name,
    options = [],
    props = {},
    onChange,
    hasError = false,
    value,
    label = '',
}) => {
    const handleChange = (selection, action) => {
        const newValues = selection ? selection.map((item) => item.value) : [];
        onChange(newValues);
    };

    const formattedValue = value ? value.map((val) => ({ label: val, value: val })) : [];

    return (
        <div className="docrev-react-select-container">
            {label && (
                <label htmlFor={name} className="mb-2 text-color font-size-sm font-weight-bolder">
                    {label}
                </label>
            )}
            <Creatable
                isMulti
                options={options}
                value={value}
                defaultValue={formattedValue}
                name={name}
                onChange={handleChange}
                formatCreateLabel={(inputValue) => `Add domain: ${inputValue}`}
                className={hasError ? 'docrev-react-select-container is-invalid' : 'docrev-react-select-container'}
                classNamePrefix="docrev-react-select"
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                theme={(theme) => {
                    return {
                        ...theme,
                        spacing: {
                            ...theme.spacing,
                            menuGutter: 6,
                        },
                    };
                }}
                {...props}
            />
        </div>
    );
};

export const InputCheck = ({ name, props = {} }) => {
    return <Field name={name} type="checkbox" {...props} />;
};

export const MediaField = ({ label, name }) => {
    const { values, setFieldValue } = useFormikContext();
    const selectedValue = _.get(values, name) ?? '';

    return (
        <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4} htmlFor={name}>
                {label}
            </Form.Label>
            <Col sm={8}>
                <MediaSelector
                    name={name}
                    selectedValue={selectedValue}
                    onChange={(fileObject) => {
                        if (_.isObject(fileObject)) {
                            setFieldValue(name, {
                                fileId: fileObject.id,
                                url: fileObject.url,
                            });
                        } else {
                            setFieldValue(name, {
                                fileId: null,
                                url: null,
                            });
                        }
                    }}
                />
            </Col>
        </Form.Group>
    );
};
