import { getBaseName } from 'config/Env';
import { Editor as TinyEditor } from '@tinymce/tinymce-react';

export function Editor(props) {
    const baseDir = getBaseName();

    return (
        <TinyEditor
            tinymceScriptSrc={`${baseDir ?? ''}/tinymce/tinymce.min.js`}
            cloudChannel="7"
            licenseKey="gpl"
            {...props}
        />
    );
}
