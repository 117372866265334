import { useDispatch } from 'react-redux';
import HelperFunctions from '../../../global/HelperFunctions';
import { Button, Dropdown, SplitButton } from 'react-bootstrap';
import { generatePath, useHistory } from 'react-router-dom';
import Constants, { Permissions } from '../../../../config/Constants';
import _ from 'lodash';
import { useState } from 'react';
import { VIEW_PATH } from 'scenes/DocumentsV2';
import PublicationApi from '../../../../api/PublicationApi';
import { useCurrentDocumentUserRole } from '../../RestrictedDocumentContent';
import CloneApi from '../../../../api/CloneApi';
import { showError } from 'helpers/errorHelper';
import { documentApi, useDeleteDocumentMutation, useUpdateDocumentMutation } from 'features/documents/documents';
import { useTranslation } from 'react-i18next';
import RestrictedContent from '../../../global/RestrictedContent';
import { useAbility } from 'ability/useAbility';

export function DocumentAction({ document }) {
    const { t } = useTranslation('documents');
    const dispatch = useDispatch();
    const history = useHistory();
    const currentUserRole = useCurrentDocumentUserRole(document.id);
    const [isReviewLoading, setReviewLoading] = useState(false);
    const [updateDocument] = useUpdateDocumentMutation();
    const [deleteDocument] = useDeleteDocumentMutation();
    const ability = useAbility();

    const { id } = document;
    const isDeleted = _.get(document, 'deletedAt', null) !== null;
    const isDocumentManager = currentUserRole === Constants.userDocumentRole.documentManager;

    if (isDeleted) {
        return (
            <Button
                className="button flex-shrink-0"
                onClick={(event) => {
                    event.stopPropagation();

                    updateDocument({
                        id: document.id,
                        uri: `/documents/${document.id}/restore`,
                        body: {},
                    });
                }}
            >
                {t('documents.btn.restore')}
            </Button>
        );
    }

    const handleClick = () => {
        history.push(
            generatePath(VIEW_PATH, {
                documentId: id,
            })
        );
    };

    if (!isDocumentManager) {
        return (
            <Button className="button flex-shrink-0" onClick={handleClick}>
                {t('documents.btn.open')}
            </Button>
        );
    }

    const canDelete = () => {
        return new Promise(async (resolve, reject) => {
            PublicationApi.getPublicationsForDocument(id).then((publications) => {
                if (publications.length === 0) {
                    resolve();
                } else {
                    reject();
                }
            });
        });
    };

    const handleStartReview = () => {
        setReviewLoading(true);

        updateDocument({
            id: document.id,
            uri: `/documents/${document.id}/revisions/start`,
            body: {},
        }).then(() => {
            setReviewLoading(false);
        });
    };

    const handleEndReview = () => {
        setReviewLoading(true);

        updateDocument({
            id: document.id,
            uri: `/revisions/${document.activeRevisionId}/finish`,
            body: {},
        }).then(() => {
            setReviewLoading(false);
        });
    };

    const handleStartNextReview = () => {
        setReviewLoading(true);

        updateDocument({
            id: document.id,
            uri: `/documents/${document.id}/revisions/start/next`,
            body: {},
        }).then(() => {
            setReviewLoading(false);
        });
    };

    const canStartReview = document.hasOwnProperty('revisions') && document.revisions.length > 0;
    const canEndReview = document.activeRevisionId !== null && document.unprocessedEdits === 0;
    const canStartNextReview = document.canStartNextRevision === true;

    const handleDuplicate = () => {
        let confirmMessage = t('documents.documentAction.duplicate', { name: document.name });
        if (document.status === Constants.documentStatus.reviewInProgress) {
            confirmMessage = t('documents.documentAction.notAcceptedChanges', { name: document.name });
        }
        HelperFunctions.confirmModal(
            confirmMessage,
            undefined,
            false,
            t('documents.documentAction.confirmDuplication'),
            t('documents.documentAction.cancelDuplication')
        ).then(async () => {
            CloneApi.createCloneTask('documents', id)
                .then(() => {
                    // Reload Documents and Groups to get new status
                    dispatch(documentApi.util.invalidateTags([{ type: 'Document', id: 'LIST' }]));
                    dispatch(documentApi.util.invalidateTags([{ type: 'DocumentGroup', id: 'LIST' }]));
                })
                .catch(() => {
                    showError(t('documents.documentAction.errorDuplicating'));
                });
        });
    };

    const handleDelete = () => {
        canDelete()
            .then(() => {
                HelperFunctions.confirmModal(
                    `${t('documents.documentAction.delete', { file: document.name })}`,
                    'danger',
                    false,
                    t('documents.btn.confirmDelete'),
                    t('btn.cancel')
                ).then(() => {
                    deleteDocument(document.id);
                });
            })
            .catch(() => {
                HelperFunctions.alertModal(t('documents.documentAction.hasPublications'));
            });
    };

    const handleSelect = (eventKey) => {
        switch (eventKey) {
            case 'start_review':
                handleStartReview();
                break;
            case 'end_review':
                handleEndReview();
                break;
            case 'start_next_review':
                handleStartNextReview();
                break;
            case 'duplicate':
                handleDuplicate();
                break;
            case 'delete':
                handleDelete();
                break;
        }
    };

    return (
        <SplitButton
            id={`document-${id}-dropdown`}
            variant="primary"
            title={t('documents.btn.open')}
            className="flex-shrink-0"
            onClick={handleClick}
            onSelect={handleSelect}
        >
            {document.status === Constants.documentStatus.draft && (
                <Dropdown.Item eventKey="start_review" disabled={canStartReview === false || isReviewLoading}>
                    {t('documents.documentAction.dropdownItem.startReview')}
                </Dropdown.Item>
            )}
            {document.status === Constants.documentStatus.reviewInProgress && (
                <Dropdown.Item eventKey="end_review" disabled={canEndReview === false || isReviewLoading}>
                    {t('documents.documentAction.dropdownItem.endRevision')}
                </Dropdown.Item>
            )}
            {document.status === Constants.documentStatus.reviewFinished && (
                <Dropdown.Item eventKey="start_next_review" disabled={canStartNextReview === false || isReviewLoading}>
                    {t('documents.documentAction.dropdownItem.startNextRound')}
                </Dropdown.Item>
            )}

            <RestrictedContent permission={Permissions.Document.Create}>
                <Dropdown.Item eventKey="duplicate" disabled={ability.cannot('create', 'Document')}>
                    {t('documents.documentAction.dropdownItem.duplicate')}&hellip;
                </Dropdown.Item>

                <Dropdown.Divider />

                <Dropdown.Item
                    eventKey="delete"
                    disabled={document.status === Constants.documentStatus.reviewInProgress}
                >
                    <div className="text-danger">{t('documents.documentAction.dropdownItem.delete')}&hellip;</div>
                </Dropdown.Item>
            </RestrictedContent>
        </SplitButton>
    );
}
