import { Button, Col, Container, Row } from 'react-bootstrap';
import { PlusCircleFill } from 'react-bootstrap-icons';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { PROPOSITION_EDIT_PATH } from '../../../../scenes/Reimbursements';
import {
    reimbursementApi,
    useGetPropositionGroupsQuery,
    useGetPropositionsQuery,
    useUpdateBatchReimbursementsMutation,
} from '../../../../features/reimbursements/reimbursements';
import HelperFunctions from '../../../global/HelperFunctions';
import cx from 'classnames';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Proposition } from './Proposition';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const emptyArray = [];

export default function Propositions({ categoryUri }) {
    const { folderId } = useParams();
    const [getPropositions] = reimbursementApi.useLazyGetPropositionsQuery();
    const [updateBatchReimbursements] = useUpdateBatchReimbursementsMutation();
    const dispatch = useDispatch();

    const { propositionGroups } = useGetPropositionGroupsQuery(categoryUri, {
        selectFromResult: ({ data }) => ({
            propositionGroups: data
                ? data
                      .filter((_item) => {
                          if (folderId === undefined) {
                              return _item.parent === null;
                          }

                          return _item.id === parseInt(folderId);
                      })
                      .sort(HelperFunctions.dynamicSort('sortOrder'))
                : emptyArray,
        }),
    });

    const updateBatch = (newPropositions) => {
        return updateBatchReimbursements({
            uri: categoryUri,
            propositions: newPropositions.map((_proposition) => {
                return {
                    idAsInt: _proposition.id,
                    id: _proposition['@id'],
                    changes: {
                        sortOrder: _proposition.sortOrder,
                        propositionGroup: _proposition.propositionGroup,
                    },
                };
            }),
        });
    };

    const onDragEnd = (result) => {
        const { destination, source } = result;

        if (!destination) {
            // dropped outside the list
            return;
        }

        const sourceGroup = source.droppableId;
        const destinationGroup = destination.droppableId;

        // Sort within group
        if (sourceGroup === destinationGroup) {
            if (source.index === destination.index) {
                // Sort order not changed
                return;
            }

            getPropositions({ uri: categoryUri }, true).then(({ data }) => {
                const oldPropositions = data
                    .filter((proposition) => proposition.propositionGroup === sourceGroup)
                    .sort(HelperFunctions.dynamicSort('sortOrder'));

                const newPropositions = HelperFunctions.arrayMove(oldPropositions, source.index, destination.index).map(
                    (_proposition, index) => {
                        // Reset sort order
                        return {
                            ..._proposition,
                            sortOrder: index,
                        };
                    }
                );

                updateBatch(newPropositions);
            });

            return;
        }

        // Moved into new group
        getPropositions({ uri: categoryUri }, true).then(({ data }) => {
            // Remove from old group
            const newPropositions1 = data
                .filter((proposition) => proposition.propositionGroup === sourceGroup)
                .sort(HelperFunctions.dynamicSort('sortOrder'));
            const [removed] = newPropositions1.splice(source.index, 1);

            updateBatch(newPropositions1);

            // Add to new group
            const newProposition = {
                ...removed,
                propositionGroup: destinationGroup,
            };

            const oldPropositions2 = data
                .filter((proposition) => proposition.propositionGroup === destinationGroup)
                .sort(HelperFunctions.dynamicSort('sortOrder'));
            oldPropositions2.splice(destination.index, 0, newProposition);

            const newPropositions2 = oldPropositions2.map((_proposition, index) => {
                // Reset sort order
                return {
                    ..._proposition,
                    sortOrder: index,
                };
            });

            updateBatch(newPropositions2).then(() => {
                dispatch(
                    reimbursementApi.util.invalidateTags([
                        {
                            type: 'Proposition',
                            id: 'LIST',
                        },
                        { type: 'PropositionGroup', id: 'LIST' },
                    ])
                );
            });
        });
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <div className="data-table data-table-propositions mb-4">
                {propositionGroups.map((_group) => (
                    <PropositionGroup group={_group} key={`list-group-${_group.id}`} />
                ))}
            </div>
        </DragDropContext>
    );
}

function PropositionGroup({ group, level = 0 }) {
    const { folderId } = useParams();
    const { name, parent } = group;
    const { childGroups } = useGetPropositionGroupsQuery(group.category, {
        selectFromResult: ({ data }) => ({
            childGroups: data
                ? data.filter((_item) => _item.parent === group['@id']).sort(HelperFunctions.dynamicSort('sortOrder'))
                : [],
        }),
        skip: parent !== null,
    });

    return (
        <Droppable droppableId={group['@id']} isDropDisabled={parent === null}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={cx('data-table-group data-table-group-droppable', 'data-table-group-level-' + level, {
                        'dragging-over': snapshot.isDraggingOver && group.parent !== null,
                        'dr-container mb-3': level === 1,
                    })}
                >
                    <div
                        className={cx('data-table-group-header d-flex align-items-center', {
                            'font-weight-normal mb-3': folderId !== undefined,
                        })}
                    >
                        <div>{name}</div>

                        {parent !== null && <AddButton folderId={group.id} />}
                    </div>

                    {childGroups.map((_childGroup) => (
                        <PropositionGroup group={_childGroup} level={1} key={`list-group-${_childGroup.id}`} />
                    ))}

                    {parent !== null && (
                        <GroupPropositions
                            categoryUri={group.category}
                            propositionGroupUri={group['@id']}
                            propositionGroupId={group.id}
                            provided={provided}
                            level={level}
                        />
                    )}
                </div>
            )}
        </Droppable>
    );
}

function GroupPropositions({ categoryUri, propositionGroupUri, propositionGroupId, provided, level }) {
    const { categoryId } = useParams();
    const history = useHistory();
    const { t } = useTranslation('reimbursements');

    const { propositions, isUninitialized, isLoading } = useGetPropositionsQuery(
        { uri: categoryUri },
        {
            selectFromResult: ({ data, isUninitialized, isLoading }) => ({
                propositions: data
                    ? data
                          .filter((proposition) => proposition.propositionGroup === propositionGroupUri)
                          .sort(HelperFunctions.dynamicSort('sortOrder'))
                    : [],
                isUninitialized,
                isLoading,
            }),
        }
    );

    return (
        <div className={cx('mb-2', { 'dr-container': level === 0 })}>
            <GroupHeader />

            <div>
                {propositions.map((_item, index) => (
                    <Proposition
                        proposition={_item}
                        propositionGroupId={propositionGroupId}
                        index={index}
                        key={`proposition-${_item.id}`}
                    />
                ))}

                {provided.placeholder}
            </div>

            {!isUninitialized && !isLoading && propositions.length === 0 && (
                <div className="text-center small text-secondary p-4" style={{ borderTop: '1px solid #f1f1f1' }}>
                    <p>{t('proposition.noPropositions')}</p>

                    <Button
                        variant="outline-primary"
                        onClick={() => {
                            history.push(
                                generatePath(PROPOSITION_EDIT_PATH, {
                                    categoryId,
                                    action: 'properties',
                                    folderId: propositionGroupId,
                                })
                            );
                        }}
                    >
                        {t('proposition.btn.newProposition')}
                    </Button>
                </div>
            )}
        </div>
    );
}

function GroupHeader() {
    const { t } = useTranslation('reimbursements');

    return (
        <div className="small text-secondary py-3">
            <Container>
                <Row>
                    <Col>
                        <div className="pl-2 ">{t('proposition.name')}</div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

function AddButton({ folderId }) {
    const history = useHistory();
    const { categoryId } = useParams();
    const { t } = useTranslation('reimbursements');

    return (
        <Button
            className="small ml-auto d-flex align-items-center mr-2 outline-small-button"
            size="sm"
            onClick={() => {
                history.push(
                    generatePath(PROPOSITION_EDIT_PATH, {
                        categoryId,
                        action: 'properties',
                        folderId,
                    })
                );
            }}
        >
            <PlusCircleFill className="mr-1" />
            {t('proposition.btn.proposition')}
        </Button>
    );
}
