import { useGetPublicationVersionsQuery } from 'features/publications/publicationApi';

const emptyArray = [];

export function useGetPublicationVersions(
    publicationId,
    skip = false,
    autoRefresh = false,
) {
    const { publicationVersions } = useGetPublicationVersionsQuery(publicationId, {
        selectFromResult: ({ data }) => ({
            publicationVersions: data ? [...data].reverse() : emptyArray,
        }),
        skip: publicationId === undefined || skip === true,
        pollingInterval: autoRefresh ? 5000 : 0,
    });

    return publicationVersions;
}
