import { useGetTaskUsers } from 'pages/task_team/hooks/useGetTaskUsers';
import { useGetOrganisationUsers } from 'hooks/useGetOrganisationUsers';
import { DocRevSelect } from 'pages/publications_v2/helpers/FieldHelper';
import { useAddTaskUserMutation, useDeleteTaskUserMutation } from 'features/comments/commentApi';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { PaginatedTable, TableProvider } from 'components/PaginatedTable';
import { DeleteButton } from 'components/ActionButtons';

export function Users() {
    return (
        <div>
            <div className="subheader">
                <h3>Gebruikers</h3>
            </div>

            <div>
                <TaskUsers role="client" title="Opdrachtgevers" />
                <TaskUsers role="pre-approved" title="Afstemmen met" />
            </div>
        </div>
    );
}

function TaskUsers({ role, title }) {
    const taskUsers = useGetTaskUsers(role);

    return (
        <div className="mb-4">
            <div className="font-weight-bold">{title}</div>
            <div className="d-flex flex-row-reverse">
                <AddUser role={role} currentUsers={taskUsers} />
            </div>

            <TableProvider>
                <PaginatedTable items={taskUsers} renderSearch={false} colSpan={2}>
                    {({ items = [] }) => (
                        <>
                            <thead>
                                <tr>
                                    <th>Naam</th>
                                    <th>Acties</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((taskUser) => (
                                    <TaskUser taskUser={taskUser} key={`task-user-${taskUser.id}`} />
                                ))}
                            </tbody>
                        </>
                    )}
                </PaginatedTable>
            </TableProvider>
        </div>
    );
}

function TaskUser({ taskUser }) {
    const [deleteTaskUser] = useDeleteTaskUserMutation();

    return (
        <tr>
            <td>{taskUser?.fullName}</td>
            <td className="actions">
                <div>
                    <DeleteButton onClick={handleDelete} />
                </div>
            </td>
        </tr>
    );

    function handleDelete() {
        deleteTaskUser(taskUser['@id']);
    }
}

function AddUser({ role, currentUsers = [] }) {
    const users = useGetOrganisationUsers(true);
    const [addTaskUser] = useAddTaskUserMutation();
    const organisationId = useActiveOrganisation();

    const availableUsers = users
        .filter((_user) => currentUsers.some((currentUser) => currentUser.userId === _user.id) === false)
        .map((_user) => {
            return {
                label: _user.fullName,
                value: _user.id,
            };
        });

    return (
        <DocRevSelect
            name="newUser"
            options={availableUsers}
            props={{
                placeholder: 'Gebruiker toevoegen...',
            }}
            onChange={(newUser) => {
                addTaskUser({
                    organisationId,
                    userId: newUser.value,
                    role,
                });
            }}
        />
    );
}
