import { useGetUserQuery } from 'features/security/authApi';

export function useGetUser(userId) {
    const { user } = useGetUserQuery(userId, {
        selectFromResult: ({ data }) => ({
            user: data,
        }),
    });

    return user;
}
