import {
    useGetPublicationUsersQuery,
    useGetUserGroupsQuery,
    useGetUserPasswordActionsQuery,
    useGetPublicationUserPasswordsQuery,
    useGetUserPublicationPasswordsQuery,
} from 'features/publications/publicationApi';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import HelperFunctions from '../../global/HelperFunctions';
import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import { parseInt } from 'lodash';

const emptyArray = [];

export function useGetUserGroups() {
    const organisationId = useActiveOrganisation();

    const selectUserGroups = useMemo(() => {
        const emptyArray = [];

        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (res) => res.data,
            (res, organisationId) => organisationId,
            (data, organisationId) =>
                data
                    ? data
                          .filter((group) => group.organisationId === organisationId)
                          .sort(HelperFunctions.sortByString('name'))
                    : emptyArray
        );
    }, []);

    const { userGroups } = useGetUserGroupsQuery(undefined, {
        selectFromResult: (result) => ({
            userGroups: selectUserGroups(result, organisationId),
        }),
    });

    return userGroups;
}

export function useGetPublicationGroup(id) {
    const groups = useGetUserGroups();

    return groups.find((group) => group.id === id);
}

/**
 * @returns {*[]}
 */
export function useGetPublicationUsers() {
    const organisationId = useActiveOrganisation();

    const selectPublicationUsers = useMemo(() => {
        const emptyArray = [];

        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (res) => res.data,
            (res, organisationId) => organisationId,
            (data, organisationId) =>
                data
                    ? data
                          .filter((user) => user.organisationId === organisationId)
                          .map((user) => ({ ...user, fullName: `${user.name} ${user.surname}` }))
                          .sort(HelperFunctions.sortByString('fullName'))
                    : emptyArray
        );
    }, []);

    const { publicationUsers } = useGetPublicationUsersQuery(undefined, {
        selectFromResult: (result) => ({
            publicationUsers: selectPublicationUsers(result, organisationId),
        }),
    });

    return publicationUsers;
}

export function useGetPublicationUser(id) {
    const publicationUsers = useGetPublicationUsers();

    return publicationUsers.find((publicationUser) => publicationUser.id === id);
}

/**
 * @returns {*[]}
 */
export function useGetPublicationUsersForGroup(group) {
    const publicationUsers = useGetPublicationUsers();

    return publicationUsers.filter((publicationUser) => group.publicationUsers.includes(publicationUser['@id']));
}

export function useGetUserPasswordActions(uri) {
    const { userPasswordActions } = useGetUserPasswordActionsQuery(uri, {
        selectFromResult: ({ data }) => ({
            userPasswordActions: data ? [...data].reverse() : [],
        }),
        skip: uri === undefined,
    });

    return userPasswordActions ?? [];
}

export function useGetPublicationUsersForPublication(publicationId) {
    const selectPublicationUserPasswords = useMemo(() => {
        const emptyArray = [];

        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (res) => res.data,
            (data) =>
                data
                    ? data
                          .map((userPassword) => ({
                              ...userPassword,
                              fullName: `${userPassword.publicationUser.name} ${userPassword.publicationUser.surname}`,
                          }))
                          .sort(HelperFunctions.sortByString('fullName'))
                    : emptyArray
        );
    }, []);

    const { publicationUserPasswords } = useGetPublicationUserPasswordsQuery(publicationId, {
        selectFromResult: (result) => ({
            publicationUserPasswords: selectPublicationUserPasswords(result),
        }),
    });

    return publicationUserPasswords;
}

export function useGetUserPublicationPasswords(id) {
    const selectUserPublicationPasswords = useMemo(() => {
        const emptyArray = [];

        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (res) => res.data,
            (data) =>
                data
                    ? data
                          .map((userPassword) => ({
                              ...userPassword,
                              publicationName: userPassword.publication.name,
                          }))
                          .sort(HelperFunctions.sortByString('publicationName'))
                    : emptyArray
        );
    }, []);

    const { userPublicationPasswords } = useGetUserPublicationPasswordsQuery(id, {
        selectFromResult: (result) => ({
            userPublicationPasswords: selectUserPublicationPasswords(result),
        }),
    });

    return userPublicationPasswords;
}
