import { Accordion, AccordionContext, Card, Dropdown, useAccordionToggle } from 'react-bootstrap';
import { CaretDownFill } from 'react-bootstrap-icons';
import cx from 'classnames';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useContext } from 'react';
import { EditContext } from '../view/EditContextWrapper';
import EntityChildRow from './EntityChildRow';
import EntityOptions from './EntityOptions';
import { isEnabledForTranslation, isTranslationInProgress } from '../../helpers/EntityReferenceHelper';
import EntityCardTitle from './EntityCardTitle';
import { useTranslation } from 'react-i18next';

export default function EntityCard({
    entityReference = {},
    entity,
    languageIso,
    entityChildren = [],
    entityTitleField = 'title',
    entityTitle,
    translationContainerUri,
}) {
    const { toggleEntity, entityIsSelected, search } = useContext(EditContext);
    const { sidebarFilters } = useSelector((state) => state.translation);
    const currentEventKey = useContext(AccordionContext);

    const hasEntityReference = entityReference.hasOwnProperty('uri');
    const { entityId, entityType, uri } = entityReference;
    const {
        showDisabledForTranslation = true,
        showVerified = true,
        showComplete = true,
        showIncomplete = true,
    } = sidebarFilters;

    const enabledForTranslation = isEnabledForTranslation(entityReference, languageIso);
    const translationInProgress = isTranslationInProgress(entityReference, languageIso);

    const eventKey = `${entityType}-${entityId}`;
    const toggleCard = useAccordionToggle(eventKey);

    // Hide Card if not enabled for translation and filter option is turned off
    if (showDisabledForTranslation === false && enabledForTranslation === false) {
        return null;
    }

    const isExpanded = currentEventKey === eventKey;
    const hasChildren = entityChildren.length > 0;

    const title = entityTitle ?? _.get(entity, entityTitleField, '');

    // Hide Entity when no child Entities and search filter is active
    const searchResult = search === '' || title.toLowerCase().includes(search.trim().toLowerCase());

    if (
        (search !== '' && entityChildren.length === 0 && searchResult === false) ||
        (entityChildren.length === 0 && showVerified === false && translationInProgress === false) ||
        (entityChildren.length === 0 && showComplete === false && translationInProgress === false) ||
        (entityChildren.length === 0 &&
            showIncomplete === false &&
            translationInProgress === true &&
            enabledForTranslation === true)
    ) {
        return null;
    }

    return (
        <Card>
            <Card.Header className="d-flex align-items-center p-0">
                <div className="d-flex flex-shrink-0 align-items-center mr-3">
                    <input
                        type="checkbox"
                        className="mr-2"
                        checked={entityIsSelected(uri)}
                        onChange={() => {
                            toggleEntity(
                                {
                                    id: uri,
                                    entityReference,
                                    entity,
                                },
                                true
                            );
                        }}
                    />

                    {hasChildren && (
                        <EntityDropdown
                            entityId={entityId}
                            currentEventKey={currentEventKey}
                            entityChildren={entityChildren}
                        />
                    )}
                </div>

                <div
                    className={cx('dr-card-row d-flex w-100 align-items-center', {
                        selected: entityIsSelected(uri),
                    })}
                    style={{
                        padding: '0.85rem 1.25rem',
                    }}
                    onClick={(event) => {
                        if (!hasChildren) {
                            return;
                        }

                        const { target } = event;

                        if (
                            _.isString(target.className) &&
                            !target.className.includes('prevent-toggle') &&
                            !target.className.includes('btn')
                        ) {
                            toggleCard(event);
                        }
                    }}
                >
                    <div>
                        <EntityCardTitle
                            translationContainerUri={translationContainerUri}
                            entityType={entityType}
                            entityUri={uri}
                            title={title}
                            languageIso={languageIso}
                            hasChildren={hasChildren}
                            eventKey={eventKey}
                        />
                    </div>

                    {hasEntityReference && (
                        <EntityOptions
                            entityReference={entityReference}
                            languageIso={languageIso}
                            entityChildren={entityChildren}
                            showEntityChildrenStatus={!isExpanded && hasChildren}
                        />
                    )}
                </div>
            </Card.Header>

            {isExpanded && hasChildren && (
                <Accordion.Collapse eventKey={eventKey}>
                    <Card.Body>
                        <div>
                            {entityChildren.map((_child) => (
                                <EntityChildRow
                                    entity={_child.entity}
                                    entityReferenceUri={_child.entityReference['@id']}
                                    entityType={_child.entityReference.entityType}
                                    languageIso={languageIso}
                                    entityTitleField={entityTitleField}
                                    translationContainerUri={translationContainerUri}
                                    key={`${_child.entityReference.entityType}-${_child.entityReference.entityId}`}
                                />
                            ))}
                        </div>
                    </Card.Body>
                </Accordion.Collapse>
            )}
        </Card>
    );
}

function EntityDropdown({ entityId, currentEventKey, entityChildren = [] }) {
    const { entityIsSelected, setSelectedEntities, selectedEntities } = useContext(EditContext);
    const { t } = useTranslation('translations');

    return (
        <Dropdown className="dropdown-absolute">
            <Dropdown.Toggle
                as={CaretDownFill}
                id={`card-${entityId}-select-dropdown`}
                className="text-secondary cursor-pointer"
                size={12}
            />

            <Dropdown.Menu>
                <Dropdown.Item
                    disabled={entityChildren.length === 0}
                    eventKey={currentEventKey}
                    onSelect={() => {
                        const unselectedEntities = entityChildren
                            .filter(({ entityReference }) => !entityIsSelected(entityReference.uri))
                            .map(({ entity, entityReference }) => {
                                return {
                                    id: entity.id,
                                    entityReference,
                                    entity,
                                };
                            });

                        setSelectedEntities(selectedEntities.concat(unselectedEntities));
                    }}
                >
                    {t('etc.selectAllArticles')}
                </Dropdown.Item>
                <Dropdown.Item
                    disabled={selectedEntities.length === 0}
                    eventKey={currentEventKey}
                    onSelect={() => {
                        const _entities = entityChildren.map(({ entityReference }) => entityReference.uri);

                        setSelectedEntities(
                            selectedEntities.filter(({ entityReference }) => !_entities.includes(entityReference.uri))
                        );
                    }}
                >
                    {t('etc.selectNone')}
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}
