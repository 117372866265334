import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FileBinaryFill } from 'react-bootstrap-icons';
import cx from 'classnames';
import { set } from '../../../features/app/appSlice';
import { entityTypes } from '../../translation/config/Constants';
import { idToUri } from '../../global/UriHelper';
import { useTranslation } from 'react-i18next';

export function LinkedVariantsButton({ opEntry, opEntryBlock }) {
    const { sidebarContext } = useSelector((state) => state.app);
    const dispatch = useDispatch();
    const { t } = useTranslation('changelist');

    const iconClass = cx('uk-text-theme', {
        'text-primary':
            sidebarContext?.type === 'opListBlockVariants' && sidebarContext.context.opEntryBlockId === opEntryBlock.id,
    });

    const handleClick = () => {
        dispatch(
            set({
                key: 'sidebarContext',
                value: {
                    type: 'sidebarSettings',
                    context: {
                        entity: {
                            opEntry,
                            opEntryBlock,
                        },
                        entityType: entityTypes.OP_ENTRY_VARIANT,
                        uri: idToUri(opEntryBlock.blockId, 'Block'),
                        parentUri: idToUri(opEntry.documentId, 'Document'),
                        landingTab: 'opEntry_variants',
                    },
                    // context: {
                    //     opEntry,
                    //     opEntryBlock,
                    // },
                },
            })
        );
    };

    return (
        <div className="pt-1">
            <div onClick={handleClick}>
                <div className="uk-cursor-pointer position-relative">
                    <FileBinaryFill
                        uk-tooltip={t('changelist.view.tooltip.viewLinkedVariants')}
                        className={iconClass}
                        style={{ fontSize: '1.5rem' }}
                    />
                </div>
            </div>
        </div>
    );
}
