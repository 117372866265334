import { useGetUserGroups } from 'pages/publications_v2/hooks/publicationUsers';
import Constants from 'config/Constants';
import { PaginatedTable, TableProvider, useSelectedItems, useSelectedItemsDispatch } from 'components/PaginatedTable';
import { ArrowDownShort } from 'react-bootstrap-icons';
import { Checkbox, DocRevSelect } from 'pages/publications_v2/helpers/FieldHelper';
import HelperFunctions from 'pages/global/HelperFunctions';
import { DeleteButton } from 'components/ActionButtons';
import cx from 'classnames';
import { SelectAllCheckbox } from 'components/SelectAllCheckbox';
import { Badge, Dropdown, DropdownButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';
import { USER_GROUPS_PATH } from 'scenes/PublicationsV2';
import { useFormikContext } from 'formik';

export function PublicationUserGroups({ publication }) {
    const { t } = useTranslation();
    const isPublished = publication.id !== undefined && publication.status === Constants.publicationStatus.published;
    const userGroups = useGetUserGroups();

    const userGroupIds = publication.pubGroupProperties?.userGroupId ?? [];
    const publicationGroups =
        userGroupIds.length > 0 ? userGroups.filter((group) => userGroupIds.includes(group.id)) : [];

    return (
        <div>
            {!isPublished && (
                <div className="d-flex alert alert-info align-items-center">
                    {t('publications:accessControl.notPubllishedWarning')}
                </div>
            )}

            <AddNewGroup publication={publication} />

            <TableProvider>
                <RenderUserGroups publicationGroups={publicationGroups} actionsButton={<Actions />} />
            </TableProvider>
        </div>
    );
}

function Actions() {
    const { t } = useTranslation();
    const { selectedItems = [] } = useSelectedItems();
    const { values, setFieldValue } = useFormikContext();

    return (
        <div className="d-flex align-items-center">
            <DropdownButton
                id="actions-dropdown"
                title={t('publications:accessControl.actions')}
                variant="primary"
                disabled={selectedItems.length === 0}
                size="sm"
            >
                <Dropdown.Item onClick={deleteItems}>
                    <span className="text-danger">{t('global:btn.delete')}&hellip;</span>
                </Dropdown.Item>
            </DropdownButton>

            <div className="text-muted small ml-2">
                {selectedItems.length > 0 && (
                    <>{t('publications:accessControl.numGroupsSelected', { count: selectedItems.length })}</>
                )}
            </div>
        </div>
    );

    function deleteItems() {
        HelperFunctions.confirmModal(
            t('publications:accessControl.confirmGroupDeleteCount', { count: selectedItems.length }),
            'danger',
            false,
            t('global:confirm.yesDelete'),
            t('global:btn.cancel')
        )
            .then(() => {
                setFieldValue(
                    'pubGroupProperties.userGroupId',
                    values.pubGroupProperties.userGroupId.filter((id) => !selectedItems.includes(id))
                );
            })
            .catch(() => {});
    }
}

function RenderUserGroups({ publicationGroups = [], actionsButton }) {
    const { t } = useTranslation();
    const { selectedItems = [] } = useSelectedItems();

    return (
        <PaginatedTable
            items={publicationGroups}
            searchPlaceholder={t('publications:accessControl.searchGroupPlaceholder')}
            searchProps={['name']}
            colSpan={4}
            actionsButton={actionsButton}
        >
            {({ items = [] }) => (
                <>
                    <thead>
                        <tr>
                            <th>
                                <SelectAllCheckbox items={publicationGroups} idProp="id" />
                            </th>
                            <th>
                                {t('publications:accessControl.headers.name')} <ArrowDownShort />
                            </th>
                            <th>#&nbsp;{t('publications:accessControl.users')}</th>
                            <th>{t('global:btn.actions')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((userGroup) => (
                            <UserGroupRow
                                userGroup={userGroup}
                                isSelected={selectedItems.includes(userGroup.id)}
                                key={`user-item-${userGroup.id}`}
                            />
                        ))}
                    </tbody>
                </>
            )}
        </PaginatedTable>
    );
}

function UserGroupRow({ userGroup, isSelected = false }) {
    const { t } = useTranslation();
    const dispatch = useSelectedItemsDispatch();
    const { values, setFieldValue, dirty } = useFormikContext();
    const history = useHistory();

    return (
        <tr className={cx({ 'row-selected': isSelected })}>
            <td>
                <Checkbox
                    id={`group-checkbox-${userGroup.id}`}
                    checked={isSelected}
                    onChange={() => {
                        dispatch({
                            type: 'toggle',
                            id: userGroup.id,
                        });
                    }}
                />
            </td>
            <td>
                <a href="#" onClick={handleNameClick}>
                    {userGroup.name}
                </a>
            </td>
            <td>
                <Badge variant="primary">{userGroup.publicationUsers.length}</Badge>
            </td>
            <td className="actions">
                <div>
                    <DeleteButton onClick={handleUserGroupDelete} />
                </div>
            </td>
        </tr>
    );

    function handleNameClick(event) {
        event.preventDefault();

        if (dirty) {
            HelperFunctions.alertModal(t('publications:accessControl.saveChangesWarning'));
            return;
        }

        history.push(
            generatePath(USER_GROUPS_PATH, {
                page: 'view-publication-group',
                id: userGroup.id,
                publicationId: values.id,
            })
        );
    }

    function handleUserGroupDelete() {
        setFieldValue(
            'pubGroupProperties.userGroupId',
            values.pubGroupProperties.userGroupId.filter((id) => id !== userGroup.id)
        );
    }
}

function AddNewGroup() {
    const { t } = useTranslation();
    const userGroups = useGetUserGroups();
    const { values, setFieldValue } = useFormikContext();

    const userGroupIds = values.pubGroupProperties?.userGroupId ?? [];
    const options = HelperFunctions.prepareDropdownData(
        userGroups.filter((userGroup) => !userGroupIds.includes(userGroup.id))
    );

    return (
        <div className="mb-4">
            <label className="mb-2 font-weight-bolder label-title small">
                {t('publications:accessControl.userGroups')}
            </label>
            <DocRevSelect
                props={{
                    placeholder: t('publications:accessControl.addGroupPlaceholder'),
                }}
                name="add-new-group"
                options={options}
                selectedValue=""
                onChange={handleFormChange}
            />
        </div>
    );

    function handleFormChange(data) {
        setFieldValue('pubGroupProperties.userGroupId', [...userGroupIds, data.id]);
    }
}
