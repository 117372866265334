import { Alert, Col, Form, Modal, Row } from 'react-bootstrap';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { Form as FForm, Formik } from 'formik';
import * as Yup from 'yup';
import { FormModal, ModalFooter } from 'pages/global/FormModal';
import React, { useEffect, useState } from 'react';
import Constants from 'config/Constants';
import { FieldSelect, InputMultipleSelect } from 'pages/publications_v2/helpers/FieldHelper';
import { useTranslation } from 'react-i18next';
import HelperFunctions from 'pages/global/HelperFunctions';
import { useBatchAssignDocumentUsersMutation } from 'features/documents/documents';

export function LinkToDocumentsModal({ selectedUsers = [], account }) {
    const [showLinkToDocuments, setShowLinkToDocuments] = useQueryParam('linkToDocuments', BooleanParam);

    useEffect(() => {
        if (showLinkToDocuments && selectedUsers.length === 0) {
            hide();
        }
    }, [selectedUsers, showLinkToDocuments]);

    return (
        <FormModal onHide={hide} show={showLinkToDocuments} title="Gebruikers koppelen aan documenten">
            {showLinkToDocuments && selectedUsers.length > 0 && (
                <ModalContent selectedUsers={selectedUsers} account={account} hide={hide} />
            )}
        </FormModal>
    );

    function hide() {
        setShowLinkToDocuments(undefined);
    }
}

function ModalContent({ selectedUsers = [], account, hide }) {
    const { t } = useTranslation('documents');
    const [batchAssignDocumentUsers] = useBatchAssignDocumentUsersMutation();
    const [success, setSuccess] = useState(false);

    const roles = getRoles();
    const organisations = HelperFunctions.prepareDropdownData(account.organisations, 'name');
    const sortedUsers = account.users
        .filter((user) => selectedUsers.includes(user.id))
        .sort(HelperFunctions.sortByString('fullName'));

    return (
        <Formik
            initialValues={{
                userIds: selectedUsers,
                organisations: organisations,
                role: Constants.userDocumentRole.documentManager,
            }}
            enableReinitialize={true}
            validationSchema={LinkToDocumentsSchema}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, isValid, values, setFieldValue }) => (
                <FForm autoComplete="off">
                    <Modal.Body>
                        <div>
                            <FieldSelect label="Rol" name="role" options={roles} />

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm={4} htmlFor="organisations">
                                    Organisaties
                                </Form.Label>
                                <Col sm={8}>
                                    <InputMultipleSelect
                                        name="organisations"
                                        options={organisations}
                                        onChange={(selectedOptions) => {
                                            setFieldValue('organisations', selectedOptions ?? []);
                                        }}
                                        value={values.organisations}
                                    />
                                </Col>
                            </Form.Group>

                            {success && <Alert variant="success">Koppelen is geslaagd!</Alert>}

                            {isValid && (
                                <Alert variant="primary">
                                    Met deze actie koppel je de {selectedUsers.length > 1 ? 'gebruikers' : 'gebruiker'}{' '}
                                    {renderUsers()}
                                    aan alle documenten van de{' '}
                                    {values.organisations.length > 1 ? 'organisaties' : 'organisatie'}{' '}
                                    {renderOrganiations(values.organisations)} met als rol{' '}
                                    <strong>
                                        {roles.find((role) => role.value === values.role).label.toLowerCase()}
                                    </strong>
                                    .
                                </Alert>
                            )}
                        </div>
                    </Modal.Body>

                    <ModalFooter isSubmitting={isSubmitting} isValid={isValid} onHide={hide} />
                </FForm>
            )}
        </Formik>
    );

    function renderUsers() {
        return (
            <>
                {sortedUsers.map((user, index) => (
                    <span key={`user-list-${index}`}>
                        <strong>{user.fullName}</strong>
                        {sortedUsers.length > 1 && index < sortedUsers.length - 1 && <>,</>}{' '}
                    </span>
                ))}
            </>
        );
    }

    function renderOrganiations(selectedOrganisations = []) {
        return (
            <>
                {selectedOrganisations.map((organisation, index) => (
                    <span key={`organisation-list-${index}`}>
                        <strong>{organisation.label}</strong>
                        {selectedOrganisations.length > 1 && index < selectedOrganisations.length - 1 && <>, </>}
                    </span>
                ))}
            </>
        );
    }

    function handleSubmit(values, { setSubmitting, setFieldValue }) {
        setSuccess(false);

        const formData = {
            ...values,
            organisations: values.organisations.map((organisation) => organisation.id),
        };

        batchAssignDocumentUsers(formData).then(() => {
            setSuccess(true);
            setSubmitting(false);
            setFieldValue('organisations', []);
        });
    }

    function getRoles() {
        return [
            {
                label: t('document.navbar.settings.tabs.team.roles.manager'),
                value: Constants.userDocumentRole.documentManager,
            },
            {
                label: t('document.navbar.settings.tabs.team.roles.finalEditor'),
                value: Constants.userDocumentRole.finalEditor,
            },
            {
                label: t('document.navbar.settings.tabs.team.roles.editor'),
                value: Constants.userDocumentRole.editor,
            },
            {
                label: t('document.navbar.settings.tabs.team.roles.spectator'),
                value: Constants.userDocumentRole.spectator,
            },
        ];
    }
}

const LinkToDocumentsSchema = Yup.object().shape({
    role: Yup.string().required(),
    userIds: Yup.array().min(1),
    organisations: Yup.array().min(1),
});
