export type Team = {
    id: number;
    name: string;
    sortOrder: number;
};

export function useGetTeams(): Team[] {
    return [
        {
            id: 10,
            name: 'CM',
            sortOrder: 0,
        },
        {
            id: 11,
            name: 'PM',
            sortOrder: 1,
        },
    ];
}
