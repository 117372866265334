import React from 'react';
import {useDraggable} from "@dnd-kit/core";
import cx from "classnames";

export default function DraggableItem(props) {
    const {
        attributes,
        listeners,
        setNodeRef,
    } = useDraggable({
        id: props.uri, data: {type: 'file'}, disabled: props.disabled
    });

    return (
        <li className={cx({'selected': props.isSelected})} ref={setNodeRef} {...attributes} {...listeners}>
            {props.children}
        </li>
    )
}
