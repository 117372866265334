import DocumentSidebar from './documentContainer/DocumentSidebar';
import { FilterField } from '../../forms/FieldHelper';
import { Route, useParams } from 'react-router-dom';
import { EDIT_PATH, TRANSLATE_PATH } from '../../../../scenes/Translations';
import { translationContainerTypes, views } from '../../config/Constants';
import { resetFilters } from '../../../../features/translations/translationSlice';
import { useDispatch } from 'react-redux';
import ReimbursementSidebar from './reimbursementContainer/ReimbursementSidebar';
import { useTranslation } from 'react-i18next';

export default function TranslationContainerSidebar({ translationContainer }) {
    const dispatch = useDispatch();
    const { view } = useParams();
    const { t } = useTranslation('translations');

    const handleReset = (event) => {
        event.preventDefault();
        dispatch(resetFilters());
    };

    return (
        <>
            <div className="d-flex align-items-center mb-3">
                <div className="font-weight-bold">Filters</div>
                <a className="small text-secondary ml-auto mr-2" onClick={handleReset}>
                    Reset
                </a>
            </div>

            <div className="small">
                <FilterField
                    name="showDisabledForTranslation"
                    label={t('translation.navbar.dashboard.document.checkbox.excludeFromTranslation')}
                />

                {translationContainer['@type'] === translationContainerTypes.DOCUMENT && view !== views.PRODUCTS && (
                    <Route
                        path={EDIT_PATH}
                        exact
                        strict
                        render={() => (
                            <>
                                <FilterField
                                    name="showComplete"
                                    label={t('translation.navbar.dashboard.document.checkbox.completeTranslation')}
                                />

                                <FilterField
                                    name="showIncomplete"
                                    label={t('translation.navbar.dashboard.document.checkbox.notReady')}
                                />
                            </>
                        )}
                    />
                )}

                <Route
                    path={TRANSLATE_PATH}
                    exact
                    strict
                    render={() => (
                        <>
                            <FilterField
                                name="showUntranslated"
                                label={t('translation.navbar.dashboard.document.checkbox.showUntranslated')}
                            />

                            <FilterField
                                name="showUnverified"
                                label={t('translation.navbar.dashboard.document.checkbox.showUncontrolled')}
                            />

                            <FilterField
                                name="showVerified"
                                label={t('translation.navbar.dashboard.document.checkbox.showVerified')}
                            />
                        </>
                    )}
                />
            </div>

            {translationContainer['@type'] === translationContainerTypes.DOCUMENT && (
                <DocumentSidebar documentContainer={translationContainer} />
            )}

            {translationContainer['@type'] === translationContainerTypes.REIMBURSEMENT && view !== views.PRODUCTS && (
                <Route
                    path={EDIT_PATH}
                    exact
                    strict
                    render={() => (
                        <>
                            <ReimbursementSidebar reimbursementContainer={translationContainer} />
                        </>
                    )}
                />
            )}
        </>
    );
}
