import { useGetDocument } from '../../hooks/useGetDocument';
import LoadingSpinner from '../../../global/LoadingSpinner';
import { useGetAreaBlocks } from '../../hooks/useGetAreaBlocks';
import { AreaLayout, AreaLayoutNav } from '../../../documents/misc/AreaLayout';
import { generatePath, useHistory } from 'react-router-dom';
import { EDIT_AREA_PATH, VIEW_PATH } from '../../../../scenes/DocumentsV2';
import React, { useMemo, useState } from 'react';
import VariantViewButtons from '../../../documents/misc/VariantViewButtons';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import RestrictedContent from '../../../global/RestrictedContent';
import Constants, { Permissions } from '../../../../config/Constants';
import { AddToOpListBtn } from '../../../operations_list/edit_area/AddToOpList';
import { RightSideContent } from './EditArea';
import { Formik } from 'formik';
import DocumentHelper from '../../../global/DocumentHelper';
import { Block } from './blocks/Block';
import { EmptyAreaPlaceholder } from '../../../documents/misc/_EditArea/EmptyAreaPlaceholder';
import { AreaLayoutPageLeft } from './AreaLayout';
import queryString from 'query-string';
import { documentApi, useUnlockAreaMutation } from 'features/documents/documents';
import { useDispatch } from 'react-redux';

export function ViewArea() {
    const document = useGetDocument();
    const areaData = useGetAreaBlocks();

    if (!document || !areaData) {
        return <LoadingSpinner />;
    }

    return <ViewAreaInner areaData={areaData} document={document} />;
}

function ViewAreaInner({ areaData, document }) {
    const { area } = areaData;
    const [showRightSide, setShowRightSide] = useState(true);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{ ...areaData.area, areaBlocks: areaData.areaBlocks }}
            onSubmit={() => {}}
        >
            {() => (
                <AreaLayout>
                    <ViewAreaNav area={area} document={document} />

                    <ViewAreaLeft
                        showRightSide={showRightSide}
                        toggleRightSide={() => setShowRightSide(!showRightSide)}
                    />

                    <RightSideContent area={area} showRightSide={showRightSide} />
                </AreaLayout>
            )}
        </Formik>
    );
}

function ViewAreaNav({ area, document }) {
    const history = useHistory();
    const [unlockArea] = useUnlockAreaMutation();
    const dispatch = useDispatch();

    const unlock = () => {
        return new Promise((resolve) => {
            unlockArea(area.id).then(() => {
                resolve();
            });
        });
    };

    const invalidateTags = (type, id) => {
        dispatch(documentApi.util.invalidateTags([{ type, id }]));
    };

    const redirect = (url) => {
        unlock().then(() => {
            // Invalidate cache
            invalidateTags('Document', document.id);

            history.push(url);
        });
    };

    const redirectToArea = (id) => {
        redirect(
            generatePath(EDIT_AREA_PATH, {
                documentId: document.id,
                view: 'viewArea',
                areaId: id,
            })
        );
    };

    const handleClose = () => {
        const url = queryString.stringifyUrl({
            url: generatePath(VIEW_PATH, {
                documentId: document.id,
            }),
            query: { sid: area.sectionId },
        });

        redirect(url);
    };

    return (
        <AreaLayoutNav area={area} redirectToArea={redirectToArea} handleClose={handleClose}>
            {document?.activeRevisionId && (
                <RestrictedContent
                    permission={Permissions.OperationListEntry.Create}
                    module={Constants.modules.operations_list}
                >
                    <>
                        <AddToOpListBtn document={document} areaId={area.id} />
                        <div
                            style={{
                                borderRight: '1px solid #e5e5e5',
                                height: 32,
                                margin: '0 12px 0 4px',
                            }}
                        >
                            &nbsp;
                        </div>
                    </>
                </RestrictedContent>
            )}
        </AreaLayoutNav>
    );
}

function ViewAreaLeft({ showRightSide, toggleRightSide }) {
    const { area, areaBlocks, baseBlocks } = useGetAreaBlocks();
    const { t } = useTranslation('documents');

    const blocksToRender = useMemo(() => {
        const blockIdsInLayout = DocumentHelper.getBlockIdsInLayout(areaBlocks);

        if (blockIdsInLayout.length === 0) {
            return areaBlocks;
        }

        return areaBlocks.filter((block) => !blockIdsInLayout.includes(block.key));
    }, [areaBlocks]);

    return (
        <AreaLayoutPageLeft area={area} showRightSide={showRightSide}>
            <div className="mb-4" style={{ height: 110 }}>
                <div className="d-flex align-items-center">
                    <VariantViewButtons area={area} />

                    <div className="ml-auto">
                        <button
                            type="button"
                            className="uk-button uk-button-light ml-2"
                            data-uk-tooltip={
                                showRightSide
                                    ? t('document.navbar.main.editor.left.subnav.hideRightSide')
                                    : t('document.navbar.main.editor.left.subnav.showRightSide')
                            }
                            onClick={toggleRightSide}
                        >
                            {showRightSide ? <ChevronRight size={16} /> : <ChevronLeft size={16} />}
                        </button>
                    </div>
                </div>
            </div>

            <div style={{ padding: '2px 2px 2px 7px' }}>
                {blocksToRender.map((block, index) => (
                    <Block
                        isDraggable={false}
                        showSettings={false}
                        block={block}
                        renderedIndex={index}
                        index={areaBlocks.findIndex((_block) => _block.key === block.key)}
                        readOnly={true}
                        key={`block-${block.key}-index-${index}`}
                    />
                ))}

                {blocksToRender.length === 0 && <EmptyAreaPlaceholder showTextEditor={false} />}
            </div>
        </AreaLayoutPageLeft>
    );
}
