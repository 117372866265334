import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { Badge, Col, Container, Row } from 'react-bootstrap';
import {
    useActivateTemplateMutation,
    useDeleteTemplateMutation,
    useGetModelVersionsQuery,
} from '../../../../../features/templates/templatesApi';
import { CloudArrowUpFill } from 'react-bootstrap-icons';
import { CreateModelVersionButton } from './CreateModelVersionButton';
import HelperFunctions from '../../../../global/HelperFunctions';
import { IconButton } from '../../../../../components/Buttons';
import cx from 'classnames';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useGetUserFullName } from '../../../../../hooks/useGetUserFullName';

export function TemplateContentBody() {
    const { t } = useTranslation('documents');
    return (
        <div className="dr-container position-relative p-4">
            <div className="subheader">
                <h3>{t('document.navbar.template.tabs.versions.title')}</h3>
            </div>

            <TemplateTable />
        </div>
    );
}

function TemplateTable() {
    const { t } = useTranslation('documents');
    return (
        <>
            <div className="position-absolute" style={{ top: '0.6rem', right: '1.5rem' }}>
                <CreateModelVersionButton />
            </div>

            <div className="d-flex align-items-stretch h-100">
                <div className="overflow-auto w-100">
                    <div className="pt-3 pb-3">
                        <Container fluid>
                            <Row className="text-muted font-weight-bolder small border-bottom pb-1 mb-3">
                                <div className="col-2">
                                    {t('document.navbar.template.tabs.versions.columnTitles.number')}
                                </div>
                                <div className="col-2">
                                    {t('document.navbar.template.tabs.versions.columnTitles.status')}
                                </div>
                                <div className="col-3">
                                    {t('document.navbar.template.tabs.versions.columnTitles.createdBy')}
                                </div>
                                <div className="col-3">
                                    {t('document.navbar.template.tabs.versions.columnTitles.date')}
                                </div>
                                <div className="col-2">
                                    {t('document.navbar.template.tabs.versions.columnTitles.actions')}
                                </div>
                            </Row>

                            <ModelVersionRows />
                        </Container>
                    </div>
                </div>
            </div>
        </>
    );
}

function ModelVersionRows() {
    const { documentId } = useParams();
    const { t } = useTranslation('documents');
    const { modelVersions = [] } = useGetModelVersionsQuery(documentId, {
        selectFromResult: ({ data }) => ({
            modelVersions: data ? _.reverse([...data]) : undefined,
        }),
    });

    if (modelVersions.length === 0) {
        return (
            <Row>
                <Col className="text-secondary py-3 small text-center">
                    {t('document.navbar.template.tabs.versions.noVErsionsPublished')}
                </Col>
            </Row>
        );
    }

    return (
        <>
            {modelVersions.map((modelVersion) => (
                <ModelVersionRow modelVersion={modelVersion} key={`version-row-${modelVersion.id}`} />
            ))}
        </>
    );
}

function ModelVersionRow({ modelVersion }) {
    const creatorName = useGetUserFullName(modelVersion.createdBy);
    const createdAt = DateTime.fromISO(modelVersion.createdAt);
    const { t } = useTranslation('documents');

    return (
        <Row className="align-items-center py-2">
            <div
                className={cx('col-2', {
                    'font-weight-bolder': modelVersion.active,
                })}
            >
                #{modelVersion.version}
            </div>
            <div className="col-2">
                {modelVersion.active && (
                    <Badge variant="success">{t('document.navbar.template.tabs.versions.published')}</Badge>
                )}
            </div>
            <div className="col-3">{creatorName}</div>
            <div className="col-3">{createdAt.toLocaleString(DateTime.DATETIME_MED)}</div>
            <div className="col-2">
                <ModelVersionButtons modelVersion={modelVersion} />
            </div>
        </Row>
    );
}

function ModelVersionButtons({ modelVersion }) {
    const [deleteTemplate] = useDeleteTemplateMutation();
    const [activateTemplate] = useActivateTemplateMutation();
    const { t } = useTranslation('documents');

    const handleDeleteTemplate = () => {
        HelperFunctions.confirmModal(`Deze versie verwijderen?`, 'danger', false).then(() => {
            deleteTemplate(modelVersion.id);
        });
    };

    const handleActivateTemplate = () => {
        activateTemplate(modelVersion['@id']);
    };

    if (modelVersion.active) {
        return null;
    }

    return (
        <>
            <IconButton
                icon={<CloudArrowUpFill size={18} className="text-success mr-2" />}
                tooltip={t('document.navbar.template.tabs.versions.publishVersion')}
                onClick={() => {
                    handleActivateTemplate();
                }}
            />

            {/*<IconButton*/}
            {/*    icon={<TrashFill size={15} className="text-danger" />}*/}
            {/*    tooltip="Versie verwijderen"*/}
            {/*    onClick={() => {*/}
            {/*        handleDeleteTemplate();*/}
            {/*    }}*/}
            {/*/>*/}
        </>
    );
}
