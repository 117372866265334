import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Constants from '../../../../config/Constants';
import getEnv from '../../../../config/Env';
import LogApi from '../../../../api/LogApi';
import CloseButton from '../../../global/CloseButton';
import PageTitle from '../../../global/PageTitle';
import LoadingSpinner from '../../../global/LoadingSpinner';
import Modal from '../../../global/Modal';
import HelperFunctions from '../../../global/HelperFunctions';
import { TbPlaylistAdd } from 'react-icons/tb';
import { useTranslation } from 'react-i18next';

export default function AuditTrail() {
    const [isShowModal, setShowModal] = useState(false);
    const { t } = useTranslation('documents');

    return (
        <>
            <div className="dr-container p-4">
                <div className="subheader">
                    <h3>{t('document.navbar.settings.tabs.audit.title')}</h3>
                </div>
                <div className="dr-instellingen-container">
                    <Row>
                        <Col xl={8}>
                            <Button size="sm" className="outline-small-button" onClick={() => setShowModal(true)}>
                                <TbPlaylistAdd size={16} className="mr-2" />
                                {t('document.navbar.settings.tabs.audit.addAudit')}
                            </Button>

                            <Modal onRequestClose={() => setShowModal(false)} opened={isShowModal}>
                                <AuditTrailForm close={() => setShowModal(false)} isShowModal={isShowModal} />
                            </Modal>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

function AuditTrailForm({ close, isShowModal }) {
    const { t } = useTranslation('documents');
    const { documentId } = useParams();
    const [isLoading, setLoading] = useState(true);
    const [previewToken, setPreviewToken] = useState(null);

    const createPreviewLink = () => {
        return getEnv('LOG_API_URL') + '/audit/document/' + previewToken.token;
    };

    useEffect(() => {
        if (isShowModal) {
            LogApi.getAuditToken({ documentId })
                .then((previewToken) => {
                    setLoading(false);
                    setPreviewToken(previewToken);
                })
                .catch((context) => {
                    getEnv('APP_ENV') === Constants.environments.dev &&
                        console.log('error preview token from publisher api:', context);
                    HelperFunctions.alertModal(t('document.navbar.settings.tabs.audit.fail')).then(() => close());
                });
        }
    }, [isShowModal]);

    if (!isShowModal) {
        return null;
    }

    if (isLoading || previewToken === null) {
        return <LoadingSpinner />;
    }

    return (
        <div className="uk-padding-medium">
            <div className="uk-position-relative">
                <CloseButton
                    className="uk-position-top-right uk-position-fixed uk-position-z-index uk-margin-large-top uk-margin-large-right"
                    close={close}
                />
                <PageTitle pageTitle="Audit trail" marginTop="none" marginBottom="none" />
            </div>
            <iframe
                className="uk-width-1-1 uk-border uk-border-theme-light"
                src={createPreviewLink()}
                title={t('document.navbar.settings.tabs.audit.showTrail')}
                data-uk-height-viewport="offset-top: true; offset-bottom: 80px"
            />
        </div>
    );
}
