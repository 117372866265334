import { entityTypes } from '../config/Constants';

export const getTypeAsString = (property, entityType, entity) => {
    if (property === 'title' || property === 'name') {
        return 'Titel';
    }

    if (property === 'description') {
        return 'Omschrijving (volledig)';
    }

    if (property === 'descriptionShort') {
        return 'Omschrijving (kort)';
    }

    if (property === 'url') {
        return 'Link';
    }

    if (property === 'tooltip') {
        return 'Tooltip';
    }

    if (entityType === entityTypes.BLOCK && property === 'data') {
        return 'Tabel';
    }

    if (entityType === entityTypes.BLOCK && entity.type === 'file') {
        return 'Afbeelding';
    }

    // Return specific block type
    if (entityType === entityTypes.BLOCK && entity.hasOwnProperty('properties')) {
        const textLayout = entity.properties.textLayout;

        switch (textLayout) {
            case 'intro':
                return 'Introtekst';
            case 'attention':
                return 'Let op-tekst';
            case 'special':
                return 'Speciaal kader';
            case 'info':
                return 'Informatie kader';
            case 'more-info':
                return 'Meer informatie';
            default:
                return 'Tekst';
        }
    }

    return 'Tekst';
};
