import {
    AccordionContext,
    Badge,
    Col,
    Container,
    Dropdown,
    DropdownButton,
    Form,
    OverlayTrigger,
    Popover,
    Row,
} from 'react-bootstrap';
import React, { useContext, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import cx from 'classnames';
import Constants, { Permissions } from '../../../../config/Constants';
import {
    fetchPublication,
    fetchPublicationPreviewTokens,
    selectPreviewTokensByPublication,
} from 'features/publications/publicationsSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
    ArrowUpRightSquare,
    BoxArrowUpRight,
    CheckCircleFill,
    Clipboard,
    ClipboardCheck,
    CloudArrowDownFill,
    CloudArrowUpFill,
    FileEarmarkPdf,
    FileEarmarkPpt,
    FileEarmarkRichtext,
    Files,
    FiletypeXml,
    GearWideConnected,
    Intersect,
    Pencil,
    Trash3,
} from 'react-bootstrap-icons';
import getEnv from '../../../../config/Env';
import {
    compareVersions,
    dePublish,
    openPreview,
    publishVersion,
    remove,
    removeVersion,
} from '../../helpers/PublicationActions';
import { Action } from './PublicationRowDetails';
import LoadingSpinner from '../../../global/LoadingSpinner';
import { saveAs } from 'file-saver';
import RestrictedContent, { useHasPermission } from '../../../global/RestrictedContent';
import HelperFunctions from '../../../global/HelperFunctions';
import { canDeletePublication } from '../../helpers/PublicationHelper';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard/src';
import { PublicationStatusBadgeWithTooltip } from '../index2/PublicationStatusBadge';
import _ from 'lodash';
import { useUpdateTokenMutation } from 'features/publications/publicationApi';
import { IconButton } from 'components/Buttons';
import { useGetUserFullName } from 'hooks/useGetUserFullName';
import { useGetPublicationDomains } from 'pages/publications_v2/hooks/useGetPublicationDomains';
import { useAbility } from 'ability/useAbility';
import { useGetPublicationVersions } from 'pages/publications_v2/hooks/useGetPublicationVersions';

export function PubPreviewsVersions({
    publication,
    setShowPublishModal,
    setShowExportChangesModal,
    setShowDuplicateModal,
}) {
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(true);

    const publicationPreviewTokens = useSelector((state) =>
        selectPreviewTokensByPublication(state.publications, publication['@id']),
    );

    const currentEventKey = useContext(AccordionContext);
    const isExpanded = currentEventKey === `publication-${publication.id}`;

    const skipLoadingVersions = !isExpanded;
    const publicationVersions = useGetPublicationVersions(publication.id, skipLoadingVersions);

    useEffect(() => {
        if (isExpanded && isLoading) {
            const p1 = new Promise((resolve) => {
                dispatch(fetchPublicationPreviewTokens({ id: publication.id })).then(() => {
                    resolve();
                });
            });
            const p2 = new Promise((resolve) => {
                dispatch(fetchPublication({ id: publication.id })).then(() => {
                    resolve();
                });
            });

            Promise.all([p1, p2]).then(() => {
                setLoading(false);
            });
        }
    }, [isExpanded, isLoading]);

    useEffect(() => {
        // Reset 'loading' to refresh the list when expanding the item again.
        if (isExpanded === false && isLoading === false) {
            setLoading(true);
        }
    }, [isExpanded, isLoading]);

    const hasPendingPreviewTokens = isExpanded && hasPendingTokens(publicationPreviewTokens);

    useEffect(() => {
        const interval = setInterval(() => {
            if (hasPendingPreviewTokens) {
                // Refresh every 5 seconds when there are preview tokens
                dispatch(fetchPublicationPreviewTokens({ id: publication.id }));
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [dispatch, hasPendingPreviewTokens, publication.id]);

    const hasPendingVersions = isExpanded && hasPendingPublication(publicationVersions);

    useEffect(() => {
        const interval = setInterval(() => {
            if (hasPendingVersions) {
                // Refresh every 5 seconds when there are preview tokens.
                dispatch(fetchPublication({ id: publication.id }));
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [dispatch, hasPendingVersions, publication.id]);

    return (
        <>
            <div className="border-top mt-2"></div>

            {isExpanded && (
                <PubPreviewsVersionsInner
                    publication={publication}
                    hasPendingVersions={hasPendingVersions}
                    isLoading={isLoading}
                    setShowPublishModal={setShowPublishModal}
                    setShowExportChangesModal={setShowExportChangesModal}
                    setShowDuplicateModal={setShowDuplicateModal}
                />
            )}
        </>
    );
}

function PubPreviewsVersionsInner({
    publication,
    hasPendingVersions = false,
    isLoading,
    setShowPublishModal,
    setShowExportChangesModal,
    setShowDuplicateModal,
}) {
    const [selectedRows, setSelectedRows] = useState([]);
    const { t } = useTranslation('publications');

    const publicationVersions = useGetPublicationVersions(publication.id, false, hasPendingVersions);

    const publicationPreviewTokens = useSelector((state) =>
        selectPreviewTokensByPublication(state.publications, publication['@id']),
    );

    const pubRowStyle = {
        minWidth: '60px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };

    return (
        <>
            {publication.status === Constants.publicationStatus.published && publication.multiVersions && (
                <PublicationUrl publication={publication} publicationVersions={publicationVersions} />
            )}

            <div className="mt-3">
                <PublishActions
                    selectedRows={selectedRows}
                    setShowPublishModal={setShowPublishModal}
                    publication={publication}
                    publicationVersions={publicationVersions}
                    setShowExportChangesModal={setShowExportChangesModal}
                    setShowDuplicateModal={setShowDuplicateModal}
                />

                <div className="docrev-flex-table">
                    <Container fluid>
                        <Row className="docrev-flex-head d-flex justify-content-between">
                            <Col md={1}>Nr</Col>
                            <Col md={1}>
                                {t('publication.columnTitles.reference')}
                                {publication.multiVersions && <>- {t('publication.columnTitles.version')}</>}
                            </Col>
                            <Col md={1}>{t('publication.columnTitles.type')}</Col>
                            <Col md={1}>{t('publication.columnTitles.format')}</Col>
                            <Col md={2}>{t('publication.columnTitles.status')}</Col>
                            <Col md={2} className="d-none d-xxl-block">
                                {t('publication.columnTitles.user')}
                            </Col>
                            <Col md={1} className="d-none d-md-block d-xxl-none">
                                {t('publication.columnTitles.start')}
                            </Col>
                            <Col md={1} className="d-none d-md-block d-xxl-none">
                                {t('publication.columnTitles.end')}
                            </Col>
                            <Col md={1} className="d-block d-md-none d-xxl-block">
                                {t('publication.columnTitles.startDate')}
                            </Col>
                            <Col md={1} className="d-block d-md-none d-xxl-block">
                                {t('publication.columnTitles.endDate')}
                            </Col>
                            <RestrictedContent permission={Permissions.Publication['Publish.All']}>
                                <Col md={1}>{t('publication.columnTitles.actions')}</Col>
                            </RestrictedContent>
                        </Row>
                        <RestrictedContent permission={Permissions.Publication['Read.All']}>
                            {isLoading && publicationPreviewTokens.length === 0 && publicationVersions.length === 0 && (
                                <Row>
                                    <Col className="text-secondary">
                                        <LoadingSpinner size="sm" inline />
                                    </Col>
                                </Row>
                            )}

                            {!isLoading &&
                                publicationPreviewTokens.length === 0 &&
                                publicationVersions.length === 0 && (
                                    <Row>
                                        <Col className="text-secondary mt-2">
                                            {t('publication.columnTitles.noVersions')}
                                        </Col>
                                    </Row>
                                )}

                            {publicationPreviewTokens.length > 0 && (
                                <PublicationTokens
                                    publication={publication}
                                    publicationPreviewTokens={publicationPreviewTokens}
                                    pubRowStyle={pubRowStyle}
                                />
                            )}

                            {publicationVersions.length > 0 && (
                                <PublicationVersionRows
                                    publication={publication}
                                    publicationVersions={publicationVersions}
                                    setSelectedRows={setSelectedRows}
                                    selectedRows={selectedRows}
                                    pubRowStyle={pubRowStyle}
                                />
                            )}
                        </RestrictedContent>
                    </Container>
                </div>
            </div>
        </>
    );
}

function PublicationUrl({ publication, publicationVersions }) {
    const [copied, setCopied] = useState(false);
    const { t } = useTranslation('global');

    useEffect(() => {
        if (copied === true) {
            // Hide complete message after 2 seconds
            setTimeout(() => {
                setCopied(false);
            }, 2000);
        }
    }, [copied]);

    const primaryVersion = publicationVersions.find((version) => {
        return (
            version.primaryVersion &&
            version.status === Constants.publicationVersionStatus.published &&
            version.prefix === publication.prefix
        );
    });

    if (!primaryVersion) {
        return null;
    }
    return (
        <div className="mt-3">
            <div className="d-inline-flex align-items-center text-secondary bg-light border-radius small p-2">
                <div className="font-weight-bold">Url html:&nbsp;</div>
                <a href={primaryVersion.htmlUrl} target="_blank" className="text-body mr-2">
                    {primaryVersion.htmlUrl}
                </a>

                <CopyToClipboard text={primaryVersion.htmlUrl} onCopy={() => setCopied(true)}>
                    {copied ? (
                        <ClipboardCheck size={14} className="text-success" />
                    ) : (
                        <Clipboard data-uk-tooltip={t('btn.copyToClipboard')} size={14} />
                    )}
                </CopyToClipboard>
            </div>
        </div>
    );
}

function PublishActions({
    selectedRows,
    setShowPublishModal,
    publication,
    setShowExportChangesModal,
    publicationVersions,
    setShowDuplicateModal,
}) {
    const ability = useAbility();
    const [compareIsLoading, setCompareIsLoading] = useState(false);
    const dispatch = useDispatch();
    const hasScheduledVersions = hasPendingPublication(publicationVersions, [
        Constants.publicationVersionStatus.scheduledForPublishing,
    ]);

    const hasPublishPermission = useHasPermission(Permissions.Publication['Publish.All']);
    const hasEditPermission = useHasPermission(Permissions.Publication['Write.All']);
    const { t } = useTranslation('publications');

    return (
        <>
            <div className="d-flex mb-2">
                <Action
                    tooltip={t('publication.tooltip.publish')}
                    handleClick={() => setShowPublishModal({ publication })}
                    variant="btn-warning"
                    disabled={hasScheduledVersions || !hasPublishPermission}
                >
                    <CloudArrowUpFill size={18} className="d-none d-xl-block" />
                    <div>{t('publication.btn.publish')}&hellip;</div>
                </Action>
                <Action
                    tooltip={t('publication.tooltip.createHtml')}
                    disabled={publication.html === false}
                    handleClick={() => openPreview(dispatch, publication, 'html')}
                    variant="btn outline-small-button"
                >
                    <FileEarmarkRichtext size={18} className="d-none d-xl-block" />
                    <div>{t('publication.btn.createHtml')}</div>
                </Action>
                <Action
                    tooltip={t('publication.tooltip.createPdf')}
                    disabled={publication.pdf === false}
                    handleClick={() => openPreview(dispatch, publication, 'pdf')}
                    variant="btn outline-small-button"
                >
                    <FileEarmarkPdf size={18} className="d-none d-xl-block" />
                    <div>{t('publication.btn.createPdf')}</div>
                </Action>

                <DropdownButton title={t('publication.dropdown.title')} variant="btn outline-small-button btn-sm">
                    <Dropdown.Item
                        disabled={selectedRows.length !== 2 || compareIsLoading}
                        className={cx('text-muted', { disabled: selectedRows.length !== 2 || compareIsLoading })}
                        onClick={() => {
                            setCompareIsLoading(true);

                            compareVersions(selectedRows)
                                .then((response) => {
                                    saveAs(response.data, response.headers['x-suggested-filename'] ?? 'export.xlsx');
                                    setCompareIsLoading(false);
                                })
                                .catch(() => {
                                    setCompareIsLoading(false);
                                    HelperFunctions.alertModal(t('publication.dropdown.errorComparison'));
                                });
                        }}
                    >
                        <Intersect size={16} className="mr-2" />
                        {t('publication.dropdown.compare')}
                    </Dropdown.Item>
                    <Dropdown.Item
                        disabled={!publication.printPdf}
                        onClick={() => openPreview(dispatch, publication, 'print')}
                    >
                        <FileEarmarkPpt size={16} className="mr-2" />
                        {t('publication.dropdown.printPdf')}
                    </Dropdown.Item>
                    {publication?.xmlProperties?.enableXml === true && (
                        <Dropdown.Item onClick={() => openPreview(dispatch, publication, 'xml')}>
                            <FiletypeXml size={16} className="mr-2" />
                            {t('publication.dropdown.xml')}
                        </Dropdown.Item>
                    )}
                    <Dropdown.Item
                        onClick={() =>
                            setShowExportChangesModal({
                                publication,
                            })
                        }
                        disabled={!hasEditPermission}
                    >
                        <ArrowUpRightSquare size={16} className="mr-2" />
                        {t('publication.dropdown.changes')}&hellip;
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={() =>
                            setShowDuplicateModal({
                                publications: [publication['@id']],
                                folder: undefined,
                            })
                        }
                        disabled={!hasEditPermission || ability.cannot('create', 'Publication')}
                    >
                        <Files size={16} className="mr-2" />
                        {t('publication.dropdown.duplicate')}&hellip;
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                        disabled={!canDeletePublication(publication) || !hasEditPermission}
                        onClick={() => {
                            remove(dispatch, publication, t);
                        }}
                    >
                        <div className="d-flex align-items-center text-danger">
                            <Trash3 size={16} className="mr-2" />
                            {t('publication.dropdown.delete')}&hellip;
                        </div>
                    </Dropdown.Item>
                </DropdownButton>
            </div>
        </>
    );
}

function PublicationVersionRows({ publication, publicationVersions, setSelectedRows, selectedRows, pubRowStyle }) {
    return (
        <>
            {publicationVersions.map((_version, index) => (
                <PublicationVersionRow
                    publication={publication}
                    pubRowStyle={pubRowStyle}
                    version={_version}
                    index={publicationVersions.length - index}
                    toggleVersion={(id) => {
                        setSelectedRows(
                            selectedRows.includes(id) ? selectedRows.filter((i) => i !== id) : [...selectedRows, id],
                        );
                    }}
                    selectedRowCount={selectedRows.length}
                    rowIsSelected={selectedRows.includes(_version.id)}
                    key={`publication-${publication.id}-${_version.id}`}
                />
            ))}
        </>
    );
}

function PublicationTokens({ publication, publicationPreviewTokens, pubRowStyle }) {
    return (
        <>
            {publicationPreviewTokens.map((token, index) => {
                const number = publicationPreviewTokens.length - index;

                return (
                    <PreviewToken
                        publication={publication}
                        token={token}
                        pubRowStyle={pubRowStyle}
                        key={`publication-${publication.id}-preview-${token.id}`}
                        number={number}
                    />
                );
            })}
        </>
    );
}

function PreviewToken({ publication, token, pubRowStyle, number }) {
    const expiresAt = DateTime.fromISO(token.expiresAt);
    const createdAt = DateTime.fromISO(token.createdAt);
    const formattedCreatedAt = createdAt.toFormat('dd/MM/yyyy HH:mm');
    const formattedExpiresAt = expiresAt.toFormat('dd/MM/yyyy HH:mm');
    const creatorName = useGetUserFullName(token.createdBy, false);
    const { t } = useTranslation('publications');

    const showGear = hasPendingPublication(
        [token],
        [Constants.publicationPreviewTokenStatus.pending, Constants.publicationPreviewTokenStatus.processing],
    );

    function renderBadge(text) {
        if (_.isEmpty(token.token)) {
            return null;
        }

        return (
            <a
                className="d-inline-flex align-items-center badge badge-primary mr-1 pr-3"
                href={`${getEnv('PUBLICATION_API_URL')}/api/publication_preview_tokens/preview/${token.token}`}
                target="_blank"
                data-uk-tooltip={`${t('publication.tooltip.newWindow', { name: text })}`}
                rel="noreferrer"
            >
                <span className="mr-1">{text}</span>
                <BoxArrowUpRight className="flex-shrink-0" />
            </a>
        );
    }

    const statusClasses = cx('badge', {
        'badge-success': token.status === Constants.publicationPreviewTokenStatus.processed,
        'badge-secondary': token.status === Constants.publicationPreviewTokenStatus.pending,
        'badge-warning ': token.status === Constants.publicationPreviewTokenStatus.processing,
        'badge-danger': token.status === Constants.publicationPreviewTokenStatus.failed,
    });

    const statusTranslation = {
        [Constants.publicationPreviewTokenStatus.processed]: t('publication.columnData.badges.ready'),
        [Constants.publicationPreviewTokenStatus.pending]: t('publication.columnData.badges.inQueue'),
        [Constants.publicationPreviewTokenStatus.processing]: `${t('publication.columnData.badges.preparing')}...`,
        [Constants.publicationPreviewTokenStatus.failed]: t('publication.columnData.badges.failed'),
    };

    return (
        <Row className="docrev-flex-body d-flex justify-content-between">
            <Col md={1}>
                <div className="text-secondary">{number}</div>
            </Col>
            <Col md={1}>
                <div className="text-secondary">{publication.prefix}</div>
            </Col>
            <Col md={1}>
                {token.action === Constants.publicationToken.export_changes && (
                    <div style={pubRowStyle}>{t('publication.columnData.changes')}</div>
                )}
                {token.action === Constants.publicationToken.preview && (
                    <div style={pubRowStyle}>{t('publication.columnData.preview')}</div>
                )}
            </Col>
            <Col md={1} className="pr-0">
                <div className="d-flex align-items-center">
                    {token.type === 'html' && renderBadge('Html')}
                    {token.type === 'pdf' && renderBadge('Pdf')}
                    {token.type === 'print' && renderBadge('Print Pdf')}
                    {token.type === 'xml' && renderBadge('XML')}
                </div>
            </Col>
            <Col md={2}>
                <div className={statusClasses}>
                    {statusTranslation[token.status] ?? t('publication.columnData.unknown')}
                </div>
            </Col>
            <Col md={2} className="d-none d-xxl-block">
                <div>{creatorName}</div>
            </Col>
            <Col md={1}>
                <div style={pubRowStyle}>{formattedCreatedAt}</div>
            </Col>
            <Col md={1}>
                <div style={pubRowStyle}>{formattedExpiresAt}</div>
            </Col>
            <Col md={1}>{showGear && <GearWideConnected size={18} className="icon-spin" />}</Col>
        </Row>
    );
}

function PublicationVersionRow({
    version,
    publication,
    index,
    toggleVersion,
    rowIsSelected,
    selectedRowCount,
    pubRowStyle,
}) {
    const dispatch = useDispatch();
    const [updateToken] = useUpdateTokenMutation();
    const publicationDomains = useGetPublicationDomains();

    const initialStart = DateTime.fromISO(version.publicationStart).toFormat("yyyy-MM-dd'T'HH:mm");
    const [tempStart, setTempStart] = useState(initialStart);

    const tempEndTest = version.publicationEnd
        ? DateTime.fromISO(version.publicationEnd).toFormat("yyyy-MM-dd'T'HH:mm")
        : '';
    const [tempEnd, setTempEnd] = useState(tempEndTest);

    const hasWritePermission = useHasPermission(Permissions.Publication['Write.All']);
    const { t } = useTranslation('publications');
    const creatorName = useGetUserFullName(version.createdBy, false);
    const domain = publicationDomains.find((_domain) => _domain.id === version.domainId);

    const publicationStart = DateTime.fromISO(version.publicationStart ?? version.publishedAt);
    const formattedPublicationStart = publicationStart.isValid ? publicationStart.toFormat('dd/MM/yyyy HH:mm') : '-';

    const publicationEnd = version.publicationEnd ? DateTime.fromISO(version.publicationEnd) : undefined;
    const formattedPublicationEnd = publicationEnd ? publicationEnd.toFormat('dd/MM/yyyy HH:mm') : '-';

    const isArchived = version.status === Constants.publicationVersionStatus.published && !version.published;

    const pending = hasPendingPublication(
        [version],
        [
            Constants.publicationVersionStatus.processing_document,
            Constants.publicationVersionStatus.in_queue,
            Constants.publicationVersionStatus.readyToPublish,
        ],
    );

    const publicationId = publication?.id;
    const publicationVersionId = version?.id;

    const reloadData = () => {
        dispatch(fetchPublication({ id: publication.id }));
    };

    const handleUpdateStart = async () => {
        if (tempStart !== '') {
            const payload = {
                publicationId,
                publicationVersionId,
                action: 'publish',
                value: tempStart,
            };

            updateToken(payload).then(() => {
                reloadData();
            });
        }
    };

    const handleUpdateEnd = async () => {
        if (tempEnd !== '') {
            const payload = {
                publicationId,
                publicationVersionId,
                action: 'unpublish',
                value: tempEnd,
            };

            updateToken(payload).then(() => {
                reloadData();
            });
        }
    };

    const minDate = DateTime.now().toFormat("yyyy-MM-dd'T'HH:mm");

    const popoverStart = (
        <Popover id={`row-${version.id}-start`}>
            <Popover.Content>
                <div className="d-flex align-items-center">
                    <Form.Control
                        type="datetime-local"
                        name="publicationStart"
                        min={minDate}
                        value={tempStart}
                        onChange={(e) => setTempStart(e.target.value)}
                    />
                    <IconButton
                        className="ml-2"
                        onClick={handleUpdateStart}
                        tooltip="Opslaan"
                        icon={<CheckCircleFill className="text-success" size={20} />}
                    />
                </div>
            </Popover.Content>
        </Popover>
    );

    const popoverEnd = (
        <Popover id={`row-${version.id}-end`}>
            <Popover.Content>
                <div className="d-flex align-items-center">
                    <Form.Control
                        type="datetime-local"
                        name="publicationEnd"
                        min={minDate}
                        value={tempEnd}
                        onChange={(e) => setTempEnd(e.target.value)}
                    />
                    <IconButton
                        className="ml-2"
                        onClick={handleUpdateEnd}
                        tooltip="Opslaan"
                        icon={<CheckCircleFill className="text-success" size={20} />}
                    />
                </div>
            </Popover.Content>
        </Popover>
    );

    return (
        <Row className="docrev-flex-body justify-content-between pt-4">
            <Col md={1}>
                {hasWritePermission ? (
                    <div className="form-check">
                        <input
                            type="checkbox"
                            id={`version-check-${version.id}`}
                            className="form-check-input mr-2"
                            checked={rowIsSelected}
                            disabled={selectedRowCount >= 2 && !rowIsSelected}
                            onChange={() => toggleVersion(version.id)}
                            onClick={(event) => event.stopPropagation()}
                        />
                        {!publication.multiVersions && (
                            <label htmlFor={`version-check-${version.id}`} className="form-check-label">
                                {index}
                            </label>
                        )}
                    </div>
                ) : (
                    <>{!publication.multiVersions && <div>{index}</div>}</>
                )}
            </Col>
            <Col md={1}>
                <div>
                    {_.isEmpty(version.prefix) ? '-' : version.prefix}
                    {publication.multiVersions && <> - {version.counter}</>}
                </div>
            </Col>
            <Col md={1}>
                <div>{t('publication.columnData.publication')}</div>
            </Col>
            <Col md={1} className="pr-0">
                {version.status === Constants.publicationVersionStatus.published && (
                    <>
                        <VersionMedia
                            publication={publication}
                            version={version}
                            property="html"
                            publicationDomain={domain}
                            isArchived={isArchived}
                        />
                        <VersionMedia
                            publication={publication}
                            version={version}
                            property="pdf"
                            publicationDomain={domain}
                            isArchived={isArchived}
                        />
                        <VersionMedia
                            publication={publication}
                            version={version}
                            property="printPdf"
                            publicationDomain={domain}
                            isArchived={isArchived}
                        />
                        <VersionMedia
                            publication={publication}
                            version={version}
                            property="xml"
                            publicationDomain={domain}
                            isArchived={isArchived}
                        />
                    </>
                )}
            </Col>

            <Col md={2}>
                {version.published && (
                    <PublicationStatusBadgeWithTooltip
                        publishedBy={version.publishedBy}
                        publishedAt={version.publishedAt}
                    >
                        <Badge
                            variant="success"
                            style={{
                                cursor: 'help',
                            }}
                        >
                            {t('publication.columnData.badges.published')}
                        </Badge>
                    </PublicationStatusBadgeWithTooltip>
                )}
                {isArchived && <Badge variant="light">{t('publication.columnData.badges.archived')}</Badge>}
                {version.status === Constants.publicationVersionStatus.processing_document && (
                    <Badge variant="warning">{t('publication.columnData.badges.preparing')}</Badge>
                )}
                {version.status === Constants.publicationVersionStatus.readyToPublish && (
                    <Badge variant="warning">{t('publication.columnData.badges.publish')}</Badge>
                )}
                {version.status === Constants.publicationVersionStatus.scheduledForPublishing && (
                    <Badge variant="secondary">{t('publication.columnData.badges.plannedPublication')}</Badge>
                )}
                {version.status === Constants.publicationVersionStatus.in_queue && (
                    <Badge variant="warning">{t('publication.columnData.badges.inQueue')}</Badge>
                )}
                {version.status === Constants.publicationVersionStatus.processing_failed && (
                    <Badge variant="danger">{t('publication.columnData.badges.failed')}</Badge>
                )}
            </Col>
            <Col md={2} className="d-none d-xxl-block">
                <div>{creatorName}</div>
            </Col>
            <Col md={1}>
                <div className="pub-row">
                    {version.status === Constants.publicationVersionStatus.scheduledForPublishing ? (
                        <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={popoverStart}>
                            <div className="d-flex align-items-center cursor-pointer">
                                <div className="mr-1">{formattedPublicationStart}</div>
                                <Pencil className="flex-shrink-0" />
                            </div>
                        </OverlayTrigger>
                    ) : (
                        formattedPublicationStart
                    )}
                </div>
            </Col>

            <Col md={1}>
                <div className="pub-row">
                    {version.published ||
                    version.status === Constants.publicationVersionStatus.scheduledForPublishing ? (
                        <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={popoverEnd}>
                            <div className="d-flex align-items-center cursor-pointer">
                                <div className="mr-1">{formattedPublicationEnd}</div>
                                <Pencil className="flex-shrink-0" />
                            </div>
                        </OverlayTrigger>
                    ) : (
                        <>{formattedPublicationEnd}</>
                    )}
                </div>
            </Col>

            <Col md={1} className="pub-d-flex align-items-center">
                {renderPublicationVersionActions()}
            </Col>
        </Row>
    );

    function renderPublicationVersionActions() {
        if (isIpidArchivePdfUrl(version)) {
            return '-';
        }

        const allowedStatusCodes = [
            Constants.publicationVersionStatus.published,
            Constants.publicationVersionStatus.processing_failed,
            Constants.publicationVersionStatus.scheduledForPublishing,
        ];

        const showVersionPublishAction = version.published && !pending;
        const showVersionActions = !pending && !showVersionPublishAction && allowedStatusCodes.includes(version.status);

        return (
            <>
                {pending && <GearWideConnected size={18} className="icon-spin" />}

                <RestrictedContent permission={Permissions.Publication['Publish.All']}>
                    {showVersionPublishAction && (
                        <IconButton
                            icon={<CloudArrowDownFill size={18} className="text-danger" />}
                            tooltip={`${t('publication.columnData.actions.dePublish')}...`}
                            onClick={() => {
                                dePublish(dispatch, publication, version, t);
                            }}
                        />
                    )}

                    {showVersionActions && (
                        <>
                            <IconButton
                                icon={<CloudArrowUpFill size={18} className="text-success" />}
                                className="mr-1"
                                tooltip={`${t('publication.columnData.actions.publishVersion')}...`}
                                onClick={() => {
                                    publishVersion(dispatch, publication, version, index, t);
                                }}
                            />
                            <IconButton
                                icon={<Trash3 size={16} className="text-danger" />}
                                tooltip={`${t('publication.columnData.actions.remove')}...`}
                                onClick={() => {
                                    removeVersion(dispatch, publication, version, index, t);
                                }}
                            />
                        </>
                    )}
                </RestrictedContent>
            </>
        );
    }
}

function isIpidArchivePdfUrl(version) {
    const regex =
        /^(https:\/\/(www\.)?verzekeringskaarten(-test)?\.nl)\/assets\/downloads\/.*(\d{4}_\d{2}_\d{2}_).*(\.pdf)/gm;

    return version.pdfOnly && _.isString(version.pdfUrl) && version.pdfUrl.match(regex);
}

function VersionMedia({ property, version, publicationDomain, isArchived = false }) {
    const { t } = useTranslation('publications');

    if (property === 'html' && version.pdfOnly) {
        return null;
    }

    const url = (propName, protocol = 'https') => {
        const path = version[propName] ?? '';

        if (path === '') {
            return '';
        }

        if (path.startsWith('http')) {
            return path;
        }

        return `${protocol}://${publicationDomain.domain}/${path}`;
    };

    const linkUrl = url(property + (version.published ? 'Url' : 'ArchiveUrl'));

    if (linkUrl === '') {
        return null;
    }

    const result = property.replace(/([A-Z])/g, ' $1');
    const title = result.charAt(0).toUpperCase() + result.slice(1);

    return (
        <a
            href={linkUrl}
            className={cx('d-inline-flex align-items-center badge mr-1 mb-1 pr-3', {
                'badge-light': isArchived,
                'badge-primary': !isArchived,
            })}
            target="_blank"
            data-uk-tooltip={`${t('publication.tooltip.newWindow', { name: title })}`}
            rel="noreferrer"
        >
            <span className="mr-1">{title}</span>
            <BoxArrowUpRight className="flex-shrink-0" />
        </a>
    );
}

const hasPendingPublication = (
    versions,
    pendingStatusCodes = [
        Constants.publicationVersionStatus.processing_document,
        Constants.publicationVersionStatus.in_queue,
        Constants.publicationVersionStatus.scheduledForPublishing,
        Constants.publicationVersionStatus.scheduledForUnpublishing,
        Constants.publicationVersionStatus.readyToPublish,
    ],
) => {
    return versions.some((_version) => {
        return pendingStatusCodes.includes(_version.status);
    });
};

const hasPendingTokens = (tokens) => {
    const pendingStatusCodes = [
        Constants.publicationPreviewTokenStatus.pending,
        Constants.publicationPreviewTokenStatus.processing,
    ];

    return tokens.some((_token) => {
        return pendingStatusCodes.includes(_token.status);
    });
};
