import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import {
    translationApi,
    translationDocumentApi,
    translationReimbursementApi,
} from './features/translations/translationApi';
import { templatesApi } from './features/templates/templatesApi';
import { reimbursementApi } from './features/reimbursements/reimbursements';
import { metaApi } from './features/metadata/metadata';
import { documentApi } from './features/documents/documents';
import { ipidApi } from './features/documents/ipidApi';
import { errorLogger } from './errorLogger';
import { publicationApi } from './features/publications/publicationApi';
import { aiApi } from './features/openai/openAIApi';
import { commentsApi } from './features/comments/commentApi';
import { authApi } from './features/security/authApi';
import { mediaApi } from './features/mediaLibrary/mediaApi';

export const store = configureStore({
    reducer: rootReducer,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            templatesApi.middleware,
            translationApi.middleware,
            translationDocumentApi.middleware,
            translationReimbursementApi.middleware,
            reimbursementApi.middleware,
            metaApi.middleware,
            documentApi.middleware,
            ipidApi.middleware,
            publicationApi.middleware,
            aiApi.middleware,
            commentsApi.middleware,
            authApi.middleware,
            mediaApi.middleware,
            errorLogger
        ),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

type RootState = ReturnType<typeof store.getState>;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
