import { useCurrentOrganisation } from 'hooks/useCurrentOrganisation';
import _ from 'lodash';

/**
 * @returns {string[]}
 */
export function useGetAccountRestrictions() {
    const organisation = useCurrentOrganisation();

    return _.get(organisation, 'account.restrictions', []);
}
