import Constants from '../../../../config/Constants';
import { MouseEvent, useMemo } from 'react';
import HelperFunctions from '../../../global/HelperFunctions';
import { DocumentVariant } from '../../../../models/documents.models';
import { useGetBaseVariant } from '../../../documents_v2/hooks/useGetBaseVariant';
import { useTranslation } from 'react-i18next';

export function EmptyAreaPlaceholder({
    showTextEditor = true,
    addBlock,
}: {
    showTextEditor?: boolean;
    addBlock?: (blockType: string, variants: number[]) => void;
}) {
    const baseVariant: DocumentVariant | undefined = useGetBaseVariant();
    const { t } = useTranslation('documents');

    const variants: number[] = useMemo(() => {
        if (!baseVariant) {
            return [];
        }

        return HelperFunctions.flatten([baseVariant]).map((variant) => variant.id);
    }, [baseVariant]);

    const addNewBlock = (event: MouseEvent) => {
        event.preventDefault();

        if (addBlock) {
            addBlock(Constants.blockTypes.text, variants);
        }
    };

    return (
        <div className="py-4 text-secondary justify-content-left" style={{ border: '1px dashed #e5e5e5' }}>
            <div className="ml-4">
                {t('document.navbar.main.editor.left.blocks.noContent')}&nbsp;
                {showTextEditor && (
                    <span className="links">
                        <a href="#" onClick={(event) => addNewBlock(event)}>
                            {t('document.navbar.main.editor.left.blocks.addBlock')}
                        </a>
                        .
                    </span>
                )}
            </div>
        </div>
    );
}
