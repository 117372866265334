import { generatePath, Link, useParams } from 'react-router-dom';
import cx from 'classnames';
import { Badge, Nav } from 'react-bootstrap';
import { CHECKS_PATH } from 'scenes/TaskTeam';
import { ClipboardCheckFill, PeopleFill, PersonXFill, Search, Trash3Fill } from 'react-bootstrap-icons';
import { useHasCreateTaskPermission, useHasReviewTaskPermission } from 'pages/task_team/hooks/permissions';
import { useGetTaskTeamAssignments } from 'pages/task_team/hooks/useGetTaskTeamAssignments';
import { UNASSIGNED_FILTER_PARAM, useGetDefaultUnassignedFilterOptions } from 'pages/task_team/views/checks/Unassigned';
import { REVIEWS_FILTER_PARAM, useGetDefaultFilterOptions } from 'pages/task_team/views/checks/Reviews';
import { useGetMyTasksCount } from 'pages/task_team/hooks/useGetMyTasks';

export function ChecksSidebar() {
    const { page = 'all' } = useParams();
    const hasReviewTaskPermission = useHasReviewTaskPermission();
    const hasCreateTaskPermission = useHasCreateTaskPermission();
    const myTasksCount = useGetMyTasksCount();

    const availableSettings = getSettings();

    return (
        <div className="border border-radius py-4 mb-4">
            <div className="d-flex align-items-center justify-content-between mb-2 px-4">
                <div className="font-weight-bold">Uitingen</div>
            </div>

            <Nav className="sidebar-nav flex-column">
                {availableSettings.map((_setting, index) => (
                    <Link
                        to={generatePath(CHECKS_PATH, {
                            page: _setting.id,
                        })}
                        className={cx('nav-link font-weight-normal', {
                            active: _setting.pages.includes(page),
                        })}
                        key={`sidebar-item-${index}`}
                        style={{ fontSize: '12px' }}
                    >
                        {_setting.icon}
                        {_setting.name}
                    </Link>
                ))}

                {hasReviewTaskPermission && <Review page={page} />}
                {hasReviewTaskPermission && <UnAssigned page={page} />}
            </Nav>
        </div>
    );

    function getSettings() {
        let settings = [
            {
                id: 'all',
                pages: ['all'],
                name: 'Alle uitingen',
                icon: <Search />,
            },
        ];

        if (hasCreateTaskPermission || myTasksCount > 0) {
            settings.push(
                {
                    id: 'my',
                    pages: ['my'],
                    name: 'Mijn aanvragen',
                    icon: <PeopleFill />,
                },
                {
                    id: 'recycle-bin',
                    pages: ['recycle-bin'],
                    name: 'Prullenbak',
                    icon: <Trash3Fill />,
                },
            );
        }

        return settings;
    }
}

function Review({ page }) {
    const defaultFilterOptions = useGetDefaultFilterOptions();
    const { totalItems = 0 } = useGetTaskTeamAssignments(REVIEWS_FILTER_PARAM, defaultFilterOptions);

    return (
        <Link
            to={generatePath(CHECKS_PATH, {
                page: 'review',
            })}
            className={cx('nav-link font-weight-normal', {
                active: ['review'].includes(page),
            })}
            style={{ fontSize: '12px' }}
        >
            <ClipboardCheckFill />
            Te beoordelen
            {totalItems > 0 && (
                <Badge variant="danger" className="small ml-auto">
                    {totalItems}
                </Badge>
            )}
        </Link>
    );
}

function UnAssigned({ page }) {
    const defaultFilterOptions = useGetDefaultUnassignedFilterOptions();
    const { totalItems = 0 } = useGetTaskTeamAssignments(UNASSIGNED_FILTER_PARAM, defaultFilterOptions);

    return (
        <Link
            to={generatePath(CHECKS_PATH, {
                page: 'unassigned',
            })}
            className={cx('nav-link font-weight-normal', {
                active: ['unassigned'].includes(page),
            })}
            style={{ fontSize: '12px' }}
        >
            <PersonXFill />
            Niet toegewezen
            {totalItems > 0 && (
                <Badge variant="danger" className="small ml-auto">
                    {totalItems}
                </Badge>
            )}
        </Link>
    );
}
