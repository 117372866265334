import { useUserProfile } from './useUserProfile';
import { useTranslation } from 'react-i18next';
import { useGetOrganisationUsers } from 'hooks/useGetOrganisationUsers';

export function useGetUserFullName(userId, returnMe = true) {
    const { t } = useTranslation('global');
    const userProfile = useUserProfile();
    const organisationUsers = useGetOrganisationUsers();

    if (!userId) {
        return t('users.unknownUser');
    }

    const selectedUser = organisationUsers.find((user) => user.id === userId);

    if (!selectedUser) {
        return t('users.unknownUser');
    }

    if (returnMe && selectedUser.id === userProfile.userId) {
        return t('users.me');
    }

    return selectedUser.firstName + ' ' + selectedUser.lastName;
}
