import { useDispatch, useSelector } from 'react-redux';
import { patchOpEntry, selectOpEntryActions } from '../../../features/operationsList/operationsListSlice';
import { DateTime } from 'luxon';
import { Button, Card, Dropdown } from 'react-bootstrap';
import _ from 'lodash';
import { ThreeDotsVertical } from 'react-bootstrap-icons';
import { useState } from 'react';
import Constants from '../../../config/Constants';
import RestrictedOpListContent from '../RestrictedOpListContent';
import { useParams } from 'react-router-dom';
import { Field, Form as FForm, Formik } from 'formik';
import { WarningButton } from '../../../components/Buttons';
import { useTranslation } from 'react-i18next';
import { useGetUserFullName } from '../../../hooks/useGetUserFullName';
import { useGetOrganisationUser } from '../../../hooks/useGetOrganisationUser';

const actionsTranslation = {
    opEntry_created: 'changelist.view.opEntryHistory.created',
    opEntry_deleted: 'changelist.view.opEntryHistory.deleted',
    opEntry_added: 'changelist.view.opEntryHistory.added',
    opDepartment_status_finished_created: 'changelist.view.opEntryHistory.handled',
    opDepartment_status_finished_deleted: 'changelist.view.opEntryHistory.undone',
    opDepartment_status_archived_created: 'changelist.view.opEntryHistory.archived',
    opDepartment_status_archived_deleted: 'changelist.view.opEntryHistory.archiveUndone',
    opEntry_status_final: 'changelist.view.opEntryHistory.final',
    opEntry_status_not_final: 'changelist.view.opEntryHistory.notFinal',
    opTeamEntry_status_itsma_ingediend: 'changelist.view.opEntryHistory.statusUpdated',
    opTeamEntry_status_nieuw: 'changelist.view.opEntryHistory.statusUpdated',
    opTeamEntry_status_in_behandeling_zssp: 'changelist.view.opEntryHistory.statusUpdated',
    opTeamEntry_status_productmanagement: 'changelist.view.opEntryHistory.statusUpdated',
    opTeamEntry_status_afgerond: 'changelist.view.opEntryHistory.statusUpdated',
};

const actions = {
    opEntry_created: 'opEntry_created',
    opEntry_deleted: 'opEntry_deleted',
    opEntry_added: 'opEntry_added',
    opDepartment_status_finished_created: 'opDepartment_status_finished_created',
    opDepartment_status_finished_deleted: 'opDepartment_status_finished_deleted',
    opDepartment_status_archived_created: 'opDepartment_status_archived_created',
    opDepartment_status_archived_deleted: 'opDepartment_status_archived_deleted',
    opEntry_status_final: 'opEntry_status_final',
    opEntry_status_not_final: 'opEntry_status_not_final',
    opEntryLabelCreated: 'opEntryLabelCreated',
};

export function OpEntryCreatedHistory({ opEntry }) {
    const opEntryActions = useSelector(selectOpEntryActions(opEntry['@id']));
    const createdAction = opEntryActions.find((opEntryAction) => opEntryAction.name === actions.opEntry_created);
    const creator = useGetOrganisationUser(createdAction?.createdBy);
    const createdBy = useGetUserFullName(createdAction?.createdBy, false);
    const [isEditMode, setEditMode] = useState(false);
    const params = useParams();
    const { t } = useTranslation('changelist');

    if (!opEntry || !createdAction) {
        return null;
    }

    const created = DateTime.fromISO(createdAction.createdAt);

    return (
        <Card body>
            <div className="d-flex align-items-center">
                <div className="font-weight-bold">{t(actionsTranslation[createdAction.name])}</div>

                <RestrictedOpListContent
                    opListId={params.id}
                    roles={[Constants.opListUserRoles.admin, Constants.opListUserRoles.editor]}
                >
                    <div className="ml-1">
                        <Dropdown>
                            <Dropdown.Toggle
                                bsPrefix="docrev-dropdown-toggle dots"
                                as="span"
                                id="dropdown-basic"
                                data-uk-tooltip="Meer opties..."
                            >
                                <ThreeDotsVertical size={16} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => setEditMode(true)}>
                                    {t('changelist.view.opEntryHistory.btn.edit')}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </RestrictedOpListContent>
            </div>

            <div className="small text-muted mb-2">{created.toLocaleString(DateTime.DATETIME_MED)}</div>

            {isEditMode && <EditOpEntryExplanation opEntry={opEntry} setEditMode={setEditMode} />}

            {!isEditMode && opEntry.explanation && opEntry.explanation.length > 0 && (
                <div className="speech-bubble">{opEntry.explanation}</div>
            )}

            {!isEditMode && (
                <div className="small">
                    <span>{createdBy}</span>
                    {_.size(creator.jobDescription) > 0 && <span> &#8226; {creator.jobDescription}</span>}

                    <OpEntryEditsAndChecks opEntry={opEntry} />
                </div>
            )}
        </Card>
    );
}

function OpEntryEditsAndChecks({ opEntry }) {
    const { editUserIds = [], checkUserIds = [] } = opEntry;
    const { t } = useTranslation('changelist');

    if (editUserIds.length === 0 && checkUserIds.length === 0) {
        return null;
    }

    return (
        <div className="mt-3">
            {editUserIds.length > 0 && (
                <div className="mb-2">
                    <div className="font-weight-bold">{t('changelist.view.opEntryHistory.changesProposedBy')}:</div>

                    {editUserIds.map((editUserId, index) => {
                        const user = useGetUserFullName(editUserId, false);

                        return <div key={`opEntry-edit-user-${index}`}>{user}</div>;
                    })}
                </div>
            )}

            {checkUserIds.length > 0 && (
                <div>
                    <div className="font-weight-bold">{t('changelist.view.opEntryHistory.changesApprovedBy')}:</div>

                    {checkUserIds.map((checkUserId, index) => {
                        const user = useGetUserFullName(checkUserId, false);

                        return <div key={`opEntry-check-user-${index}`}>{user}</div>;
                    })}
                </div>
            )}
        </div>
    );
}

export function LastOpEntryEditBy({ opEntryLabel }) {
    const opEntryActions = useSelector(selectOpEntryActions(opEntryLabel.opEntry));
    const opEntryLabelActions = opEntryActions
        .filter((action) => action.opEntryLabel === opEntryLabel['@id'])
        .filter((action) => action.name !== actions.opEntryLabelCreated);

    if (opEntryLabelActions.length === 0) {
        return null;
    }

    return <ActionNameAndDate action={opEntryLabelActions[0]} />;
}

export function ActionNameAndDate({ action }) {
    const createdBy = useGetUserFullName(action.createdBy);
    const createdAt = DateTime.fromISO(action.createdAt);

    return (
        <>
            {createdBy}, {createdAt.toLocaleString(DateTime.DATETIME_MED)}
        </>
    );
}

function EditOpEntryExplanation({ opEntry, setEditMode }) {
    const dispatch = useDispatch();
    const { t } = useTranslation('changelist');

    const handleSubmit = (values) => {
        dispatch(patchOpEntry({ uri: opEntry['@id'], formData: values })).then(() => {
            setEditMode(false);
        });
    };

    return (
        <Formik
            initialValues={{
                explanation: opEntry.explanation,
            }}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, isValid, dirty, errors }) => (
                <FForm autoComplete="off">
                    <div>
                        <Field
                            id="explanation"
                            name="explanation"
                            as="textarea"
                            className={_.get(errors, name) ? 'form-control is-invalid' : 'form-control'}
                            rows={3}
                        />

                        <div className="mt-2">
                            <WarningButton disabled={isSubmitting || !isValid || !dirty} type="submit">
                                {t('btn.save')}
                            </WarningButton>
                            <Button variant="link" disabled={isSubmitting} onClick={() => setEditMode(false)}>
                                {t('btn.cancel')}
                            </Button>
                        </div>
                    </div>
                </FForm>
            )}
        </Formik>
    );
}
