import _ from 'lodash';
import Constants from '../../../../config/Constants';
import cx from 'classnames';

export default function BlockName({ block, editorDisplaySection }) {
    const { deleted = false, type } = block;

    if (editorDisplaySection === Constants.editorDisplaySections.right || deleted) {
        return null;
    }

    // Block name
    let name = _.get(block, 'properties.name', '');
    if (type === Constants.blockTypes.linked) {
        name = _.get(block, 'targetProperties.name', '');
    }

    const showName = !_.isEmpty(name);

    // Block prefix
    const blockPrefix = _.get(block, 'properties.blockPrefix', '');
    const blockPrefixSetting = _.get(block, 'properties.blockPrefixSetting', 'manual');

    const showBlockPrefix = blockPrefixSetting === 'auto' || blockPrefix !== '';

    if (!showName && !showBlockPrefix) {
        return null;
    }

    return (
        <div
            className="block-name d-flex align-items-baseline px-2"
            style={{
                position: 'absolute',
                backgroundColor: 'white',
                top: -10,
                left: 65,
                fontSize: 12,
                lineHeight: '20px',
                color: '#00abb4',
            }}
        >
            {showName && <div>{name}</div>}

            {showBlockPrefix && <BlockPrefix block={block} showName={showName} />}
        </div>
    );
}

function BlockPrefix({ block, showName }) {
    const blockPrefix = _.get(block, 'properties.blockPrefix', '');
    const blockPrefixSetting = _.get(block, 'properties.blockPrefixSetting', 'manual');
    const blockPrefixType = _.get(block, 'properties.blockPrefixType', 'default');

    return (
        <div
            className={cx('text-secondary', {
                'ml-2': showName,
            })}
            style={{
                fontSize: 11,
            }}
        >
            Nummering:&nbsp;
            {blockPrefixSetting === 'manual' && <>{blockPrefix} (handmatig)</>}
            {blockPrefixSetting === 'auto' && (
                <>
                    {blockPrefixType === 'default' && <>1/2/3 (automatisch)</>}
                    {blockPrefixType === 'small_caps' && <>a/b/c (automatisch)</>}
                    {blockPrefixType === 'uppercase' && <>A/B/C (automatisch)</>}
                </>
            )}
        </div>
    );
}
