import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useGetCollectiveQuery, useGetCollectivesQuery } from '../../../../features/reimbursements/reimbursements';
import { LoadingSpinner } from '../../../global/Spinner';
import MainContentNav from '../../../Navbar';
import { BASE_PATH, COLLECTIVES_PATH, VIEW_PATH } from '../../../../scenes/Reimbursements';
import { Button, Col, Container, Row } from 'react-bootstrap';
import React from 'react';
import { useFormikContext } from 'formik';
import HelperFunctions from '../../../global/HelperFunctions';
import { ExclamationTriangleFill } from 'react-bootstrap-icons';
import Properties from './edit/Properties';
import Sidebar from './edit/Sidebar';
import EditCollectiveForm from '../forms/EditCollectiveForm';
import { useGetCategory } from '../../../../features/reimbursements/reimbursementHooks';
import { idToUri } from '../../../global/UriHelper';
import { WarningButton } from '../../../../components/Buttons';
import { useTranslation } from 'react-i18next';

export default function Edit() {
    const params = useParams();
    const categoryId = parseInt(params.categoryId);
    const { collectiveId, folderId, action } = params;
    const { category } = useGetCategory(categoryId);
    const { t } = useTranslation('reimbursements');

    const { collectives } = useGetCollectivesQuery(
        { uri: idToUri(categoryId, 'Category') },
        {
            selectFromResult: ({ data }) => ({
                collectives: data
                    ? data
                          .filter((collective) => collective.collectiveGroup === idToUri(folderId, 'CollectiveGroup'))
                          .sort(HelperFunctions.dynamicSort('sortOrder'))
                    : [],
            }),
            skip: collectiveId !== undefined,
        }
    );

    const { collective } = useGetCollectiveQuery(collectiveId, {
        selectFromResult: ({ data }) => ({
            collective: data,
        }),
        skip: category === undefined || collectiveId === undefined,
    });

    if (category === undefined) {
        return null;
    }

    return (
        <>
            <MainContentNav
                pages={[
                    { title: t('breadcrumb'), url: BASE_PATH },
                    {
                        title: category.name,
                        url: generatePath(VIEW_PATH, { categoryId }),
                    },
                    {
                        title: t('collectivity.title'),
                        url: generatePath(COLLECTIVES_PATH, {
                            categoryId,
                            folderId,
                        }),
                    },
                    { title: collective === undefined ? t('collectivity.editCollectivity.title') : collective.name },
                ]}
            />

            {(collectiveId === undefined || collective !== undefined) && (
                <EditCollectiveForm
                    proposition={
                        collective ?? {
                            name: '',
                            description: '',
                            category: category && category.hasOwnProperty('@id') ? category['@id'] : '',
                            collectiveGroup: `/api/reimbursement-api/api/collective_groups/${folderId}`,
                            sortOrder: collectives.length > 0 ? collectives[collectives.length - 1].sortOrder + 1 : 0,
                        }
                    }
                >
                    <>
                        <div className="position-absolute" style={{ top: 73, right: 0, left: 0, bottom: 50 }}>
                            <div className="d-flex align-items-stretch h-100">
                                <div className="content-sidebar bg-light">
                                    <Sidebar />
                                </div>
                                <div className="flex-grow-1 overflow-auto">
                                    <Container>
                                        <Row className="justify-content-center">
                                            <Col md={10}>
                                                <div className="subheader d-flex mt-5">
                                                    <h3>{t('collectivity.editCollectivity.properties.title')}</h3>
                                                </div>
                                                <div className="mb-5">
                                                    <Action />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </div>
                        </div>

                        <FormActions />
                    </>
                </EditCollectiveForm>
            )}
        </>
    );
}

function Action() {
    const { action } = useParams();

    switch (action) {
        case 'properties':
            return <Properties />;
        default:
            return <>Not supported</>;
    }
}

function FormActions() {
    const history = useHistory();
    const { isSubmitting, dirty, isValid } = useFormikContext();
    const { categoryId, folderId } = useParams();
    const { t } = useTranslation('reimbursements');

    const close = () => {
        history.push(
            generatePath(COLLECTIVES_PATH, {
                categoryId,
                folderId,
            })
        );
    };

    const handleClose = () => {
        if (dirty === false) {
            // No changes in the form, just close the window
            close();
            return;
        }

        // Ask for confirmation first
        HelperFunctions.confirmModal(
            t('collectivity.editCollectivity.unsavedChanges'),
            'danger',
            false,
            t('collectivity.editCollectivity.btn.yesClose'),
            t('btn.cancel')
        ).then(() => {
            close();
        });
    };

    return (
        <div className="position-absolute bg-light border-top" style={{ height: 65, right: 0, left: 0, bottom: 0 }}>
            <div className="d-flex justify-content-end align-items-center pr-5" style={{ height: 65 }}>
                <div className="mr-3">
                    <LoadingSpinner isSubmitting={isSubmitting} />
                </div>

                {dirty && !isSubmitting && isValid && (
                    <>
                        <ExclamationTriangleFill className="text-warning mr-1" />
                        <div className="text-warning small mr-4">
                            {t('collectivity.editCollectivity.thereAreUnsavedChanges')}
                        </div>
                    </>
                )}
                {dirty && !isSubmitting && !isValid && (
                    <>
                        <ExclamationTriangleFill className="text-danger mr-1" />
                        <div className="text-danger small mr-4">{t('collectivity.editCollectivity.formErrors')}</div>
                    </>
                )}

                <Button variant="link" onClick={handleClose} disabled={isSubmitting}>
                    {t('btn.close')}
                </Button>
                <WarningButton type="submit" form="editCollectiveForm" disabled={isSubmitting || !isValid || !dirty}>
                    {t('btn.save')}
                </WarningButton>
            </div>
        </div>
    );
}

const actions = {
    properties: 'Eigenschappen',
};
