import { useGetDeletedTasks } from 'pages/task_team/hooks/useGetDeletedTasks';
import { PaginatedTable, TableProvider } from 'components/PaginatedTable';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { TaskStatusBadge } from 'pages/task_team/views/checks/Checks';
import { GenericButton } from 'components/Buttons';
import { ArrowCounterclockwise } from 'react-bootstrap-icons';
import { commentsApi, useUpdateTaskMutation } from 'features/comments/commentApi';
import { EmptyState, EmptyStateBody, EmptyStateHeader, EmptyStateIcon, RocketIcon } from 'components/EmptyState';
import { useDispatch } from 'react-redux';
import { PAGINATION_ITEMS_PER_PAGE } from 'scenes/TaskTeam';

export function RecycleBin() {
    return (
        <div>
            <div className="subheader">
                <h3>Prullenbak</h3>
            </div>

            <TasksTable />
        </div>
    );
}

function TasksTable() {
    const { items = [], totalItems = 0, isEmpty = false } = useGetDeletedTasks();

    return (
        <TableProvider>
            <PaginatedTable
                items={items}
                totalItems={totalItems}
                clientSidePagination={false}
                renderSearch={false}
                itemsPerPage={PAGINATION_ITEMS_PER_PAGE}
                colSpan={4}
            >
                {({ items = [] }) => (
                    <>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Titel</th>
                                <th>Status</th>
                                <th>Acties</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isEmpty ? (
                                <NoResults />
                            ) : (
                                <>
                                    {items.map((task) => (
                                        <TaskRow task={task} key={`task-${task.id}`} />
                                    ))}
                                </>
                            )}
                        </tbody>
                    </>
                )}
            </PaginatedTable>
        </TableProvider>
    );
}

function TaskRow({ task }) {
    const { t, i18n } = useTranslation();
    const [updateTask] = useUpdateTaskMutation();
    const dispatch = useDispatch();

    const deletedAtDateTime = DateTime.fromISO(task.deletedAt).setLocale(i18n.language);
    const deletedAtFull = deletedAtDateTime.toLocaleString(DateTime.DATE_FULL);

    return (
        <tr>
            <td>
                {task.sequence && (
                    <>
                        #{task.sequence}-{task.activeVersionNumber}
                    </>
                )}
            </td>
            <td>
                <div>{task.title}</div>
                <div className="text-muted">Verwijderd op {deletedAtFull}</div>
            </td>
            <td>
                <TaskStatusBadge task={task} />
            </td>
            <td className="actions">
                <div>
                    <GenericButton
                        className="d-flex align-items-center flex-shrink-0"
                        variant="link"
                        onClick={() => restoreAction()}
                    >
                        <ArrowCounterclockwise size={16} className="mr-1" />
                        <div>Herstellen</div>
                    </GenericButton>
                </div>
            </td>
        </tr>
    );

    function restoreAction() {
        updateTask({
            uri: task['@id'],
            body: {
                deletedAt: null,
                deletedBy: null,
            },
        }).then(() => {
            dispatch(
                commentsApi.util.invalidateTags([
                    { type: 'Task', id: 'LIST' },
                    { type: 'TaskTeamAssignment', id: 'LIST' },
                ]),
            );
        });
    }
}

function NoResults() {
    return (
        <tr>
            <td colSpan={4}>
                <EmptyState>
                    <EmptyStateHeader titleText={'Prullenbak is leeg'} icon={<EmptyStateIcon icon={RocketIcon} />} />
                    <EmptyStateBody>
                        Je hebt geen verwijderde aanvragen. Zodra een aanvraag verwijderd is wordt deze hier getoond.
                    </EmptyStateBody>
                </EmptyState>
            </td>
        </tr>
    );
}
