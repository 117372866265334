import { useGetTaskQuery } from 'features/comments/commentApi';

export function useGetTask(id) {
    const { task } = useGetTaskQuery(id, {
        selectFromResult: (result) => ({
            task: result.currentData,
        }),
        skip: id === undefined,
    });

    return task;
}
