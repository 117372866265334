import { useAddUserGroupMutation } from 'features/publications/publicationApi';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { GroupForm } from 'pages/publicationUsers/modals/GroupForm';
import * as Yup from 'yup';

export function AddGroupModal() {
    const [addGroup] = useQueryParam('addGroup', BooleanParam);

    if (!addGroup) {
        return null;
    }

    return <AddGroup />;
}

function AddGroup() {
    const { t } = useTranslation();
    const [, setAddGroup] = useQueryParam('addGroup', BooleanParam);
    const organisationId = useActiveOrganisation();
    const [addUserGroup] = useAddUserGroupMutation();

    const initialValues = {
        name: '',
        organisationId,
        publicationUsers: [],
        allowedEmailDomains: [],
    };

    return (
        <Modal show={true} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{ t('publications:accessControl.modal.addGroup') }</Modal.Title>
            </Modal.Header>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                <GroupForm handleClose={handleClose} />
            </Formik>
        </Modal>
    )

    function handleSubmit(values) {
        const formData = {
            ...values,
            publicationUsers: values.publicationUsers.map((option) => option.value),
            allowedEmailDomains: values.allowedEmailDomains.map((option) => option.value),
        };

        addUserGroup(formData).then(() => {
            handleClose();
        });
    }

    function handleClose() {
        setAddGroup(undefined);
    }
}

const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
});
