import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { useEffect, useState } from 'react';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';
import { globalStyles } from 'components/Tours';

export function IpidEuNewDocument() {
    const [stepIndex, setStepIndex] = useState(0);
    const [run, setRun] = useState(true);
    const [showNewDocumentModal, setShowNewDocumentModal] = useQueryParam('newDocument', BooleanParam);
    const [startTour, setStartTour] = useQueryParam('startTour', StringParam);

    useEffect(() => {
        console.log(showNewDocumentModal);

        if (showNewDocumentModal && stepIndex === 0) {
            setTimeout(() => {
                setStepIndex(1);
            }, 1000);
        } else if (!showNewDocumentModal && stepIndex > 0 && run === true) {
            resetState();
        }
    }, [showNewDocumentModal]);

    const steps = [
        {
            target: '.tour-btn-new-document',
            content: 'Start by creating a new document',
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: true,
            // hideFooter: true,
            spotlightClicks: true,
            showSkipButton: true,
        },
        {
            target: '.model-new-document .tour-new-document-field-name',
            content: 'Enter the name of the product',
            spotlightClicks: true,
            hideCloseButton: true,
            hideBackButton: true,
            isFixed: true,
            disableOverlayClose: true,
        },
        {
            target: '.model-new-document .btn-warning',
            content: 'Click here to create your first document',
            spotlightClicks: true,
            disableOverlayClose: true,
            hideCloseButton: true,
            hideFooter: false,
            styles: {
                buttonNext: {
                    display: 'none',
                },
            },
        },
        // {
        //     target: `#document-item-${entityId} .btn-primary`,
        //     content: "Well done, you've created your first document!",
        //     hideBackButton: true,
        //     hideCloseButton: true,
        //     disableOverlayClose: true,
        //     locale: {
        //         last: 'Open document',
        //         next: 'Open document',
        //     },
        // },
    ];

    return (
        <Joyride
            run={run}
            callback={handleJoyrideCallback}
            continuous={true}
            // scrollToFirstStep={true}
            disableCloseOnEsc={true}
            steps={steps}
            stepIndex={stepIndex}
            styles={globalStyles}
        />
    );

    function handleJoyrideCallback(data) {
        const { action, index, status, type } = data;

        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            // Need to set our running state to false, so we can restart if we click start again.
            resetState();
            return;
        }

        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

            if (index === 0 && action !== ACTIONS.CLOSE) {
                setShowNewDocumentModal(true);
                return;
            }

            console.log(nextStepIndex);
            setStepIndex(nextStepIndex);
        }

        // if (action === 'skip') {
        //     setStartTour(undefined);
        // }
        //
        // if (action === 'next') {
        //     if (stepIndex === 0) {
        //         setShowNewDocumentModal(true);
        //         setStepIndex(1);
        //
        //         return;
        //     }
        //
        //     setStepIndex(stepIndex + 1);
        // }
    }

    function resetState() {
        setRun(false);
        setStepIndex(0);
        setStartTour(undefined);
    }
}
