import React from 'react';
import LoadingSpinner from './LoadingSpinner';
import MoreInfoIcon from './MoreInfoIcon';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

const LoadingSavedIndicator = (props) => {
    const { changesSaved, float, saveInProgress } = props;
    const { t } = useTranslation('documents');

    if (changesSaved === false && saveInProgress === false) {
        return null;
    }

    const divClass = cx('line-height-button uk-display-inline', {
        'uk-margin-left': float === 'none',
        'uk-margin-left uk-float-left': float === 'left',
        'uk-margin-right uk-float-right': float === 'right',
    });

    return (
        <div className={divClass}>
            {changesSaved && (
                <MoreInfoIcon
                    margin="none"
                    valid={true}
                    validText={t('document.navbar.variants.linkChapters.changesSaved')}
                />
            )}
            {saveInProgress && <LoadingSpinner size="small" inline={true} />}
        </div>
    );
};

export default LoadingSavedIndicator;

LoadingSavedIndicator.propTypes = {
    changesSaved: PropTypes.bool,
    saveInProgress: PropTypes.bool,
    float: PropTypes.oneOf(['left', 'right', 'none']),
};
LoadingSavedIndicator.defaultProps = {
    changesSaved: false,
    saveInProgress: false,
    float: 'none',
};
