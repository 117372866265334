import { ReimbursementProduct as ReimbursementProductModel } from '../../../../models/reimbursement.models';
import Constants from '../../../../config/Constants';
import cx from 'classnames';
import { useGetReimbursementCoverage } from '../../hooks/useGetReimbursementCoverage';
import { useGetProduct } from '../../hooks/useGetProduct';

export function QuickCoverage({
    reimbursementId,
    categoryId,
    productUri,
    selectedProductUris,
}: {
    reimbursementId: number;
    categoryId: number;
    productUri: string;
    selectedProductUris: string[];
}) {
    const coverage = useGetReimbursementCoverage(reimbursementId, selectedProductUris, productUri);

    return (
        <div className="d-flex align-items-center">
            {coverage.map((coverageValue, index) => (
                <CoverageValue
                    reimbursementProduct={coverageValue}
                    categoryId={categoryId}
                    key={`${productUri}-coverage-${index}`}
                />
            ))}
        </div>
    );
}

function CoverageValue({
    reimbursementProduct,
    categoryId,
}: {
    reimbursementProduct: Partial<ReimbursementProductModel>;
    categoryId: number;
}) {
    const selectedProduct = useGetProduct(categoryId, reimbursementProduct.selectedProduct);

    // @ts-ignore
    const coverage = Constants.coverageTranslation[reimbursementProduct?.coverage ?? '1'];

    return (
        <div
            className={cx({
                'text-secondary': reimbursementProduct.coverage === Constants.coverage['n/a'],
            })}
            style={{ minWidth: 45 }}
            data-uk-tooltip={selectedProduct.name}
        >
            {coverage}
        </div>
    );
}
