import { ImportContext } from '../IpidImport';
import { useContext, useEffect, useMemo, useState } from 'react';
import Parser from 'html-react-parser';
import { ExclamationTriangleFill, PencilSquare } from 'react-bootstrap-icons';
import { IconButton } from '../../../../components/Buttons';
import { Form } from 'react-bootstrap';

export function UnmatchedBlocks() {
    const { formData } = useContext(ImportContext);
    const { areas = [], variants = [] } = formData;

    const blockIds = useMemo(() => {
        const blockIds = [];

        areas.forEach((area) => {
            area.blocks.forEach((block) => {
                block.eiopaIds.forEach((eiopaId) => {
                    if (blockIds.includes(eiopaId) === false) {
                        blockIds.push(eiopaId);
                    }
                });
            });
        });

        return blockIds;
    }, [variants, areas]);

    return (
        <>
            {variants.map((variant, index) => (
                <VariantBlocks
                    variantData={variant}
                    variantIndex={index}
                    blockIds={blockIds}
                    key={`unmatched-variant-${index}`}
                />
            ))}
        </>
    );
}

function VariantBlocks({ variantData, variantIndex, blockIds = [] }) {
    const { variant, blockData = [] } = variantData;
    const unmatchedBlocks = blockData.filter((block) => blockIds.includes(block.id) === false);

    if (unmatchedBlocks.length === 0) {
        return null;
    }

    return (
        <div className="pt-3 mb-4">
            <div className="d-flex align-items-center mb-2">
                <ExclamationTriangleFill className="text-danger mr-2" />
                <div className="font-weight-bold mr-2">
                    {variant[1]}: {variant[4]}
                </div>
                <div className="small">
                    {unmatchedBlocks.length} van {blockData.length} blokken niet gekoppeld
                </div>
            </div>
            <div className="small" style={{ marginLeft: 22 }}>
                {unmatchedBlocks.map((block) => (
                    <UnmatchedBlock block={block} variantId={parseInt(variant[2])} variantIndex={variantIndex} key={`unmatched-block-${block.id}`} />
                ))}
            </div>
        </div>
    );
}

function UnmatchedBlock({ block, variantId, variantIndex }) {
    const { editBlockTitle, findUnmatchedBlockArea } = useContext(ImportContext);
    const [editMode, setEditMode] = useState(false);
    const [inputValue, setInputValue] = useState(block.title);

    const handleSubmit = (e) => {
        e.preventDefault();
        handleTitleChange();
    };

    const originalTitle = useMemo(() => {
        return block.title
    }, [])

    const handleTitleChange = () => {
        editBlockTitle(variantIndex, block.id, inputValue)
        setEditMode(false);
    }

    useEffect(() => {
        if (block.title !== originalTitle) {
            findUnmatchedBlockArea(block, variantId);
        }
    }, [block.title]);

    return (
        <div className="mb-3">
            <div className="area-read-only bg-white border p-3">
                <div className="d-flex align-items-center font-weight-bold mb-2">
                    {editMode ? (
                        <form onSubmit={handleSubmit}>
                            <Form.Control
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                onBlur={() => handleTitleChange()}
                            />
                        </form>
                    ) : (
                        <>
                            <div className="mr-1">{block.title}</div>
                            <IconButton icon={<PencilSquare />} onClick={() => setEditMode(true)} />
                        </>
                    )}
                </div>

                {Parser(block.description)}
                {block.descriptionExtra !== '' && <div className="font-italic">{Parser(block.descriptionExtra)}</div>}
            </div>
            <div className="text-muted mt-1 ml-2">
                id: {block.id} / name: {block.name} / category: {block.categoryId} / type: {block.type}
            </div>
        </div>
    );
}
