import ApiConnector from "./ApiConnector";
import Constants from "../config/Constants";
import getEnv from "../config/Env";

const apiUrl = getEnv('LOG_API_URL');

const LogApi = {
    getAuditToken(formData) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiPut(apiUrl + '/audits/token', formData)
                .then(result => resolve(result))
                .catch(context => {
                    getEnv('APP_ENV') === Constants.environments.dev && console.log('unable to get audit token ', context);
                    reject(context);
                });
        });
    },
};

export default LogApi;