import React, { Fragment } from 'react';
import Constants from '../../../../config/Constants';
import Parser from 'html-react-parser';
import InteractivePart from '../_BlockParser/InteractivePart';
import HelperFunctions from '../../../global/HelperFunctions';
import cx from 'classnames';
import { useGetDocument } from 'pages/documents_v2/hooks/useGetDocument';

export default function TextBlock(props) {
    const { block, blockDisplayOptions, blockId, context, selectedPart } = props;
    const scheduledForDeletion = block.scheduledForDeletion;
    const deleted = block.deleted;
    const documentWithAreas = useGetDocument(undefined, true);

    if (
        context === Constants.blockContext.checkChanges &&
        blockDisplayOptions.text.interactiveChanges &&
        !scheduledForDeletion &&
        !deleted
    ) {
        // render review text changes on the left side
        let content = block.diffContent;
        if (content === undefined || content === null || content === '') {
            content = block.latestContent;
        }
        const changes = block.changes;

        if (content === undefined || content === '') {
            return null;
        }

        // start counting changes again at 1 for each block
        const interactiveContent = Parser(content, {
            replace: (domNode) => {
                if (
                    domNode.name === 'change' &&
                    domNode.hasOwnProperty('attribs') &&
                    domNode.attribs.hasOwnProperty('data-diff-id')
                ) {
                    const domNodeId = parseInt(domNode.attribs['data-diff-id']);
                    let part = HelperFunctions.getByValue(changes, 'diffId', domNodeId);

                    if (part !== undefined) {
                        part = {
                            ...part,
                            oldText: HelperFunctions.prepareRefs(part.oldText, documentWithAreas),
                            newText: HelperFunctions.prepareRefs(part.newText, documentWithAreas),
                        };
                        return (
                            <InteractivePart
                                partId={part.id}
                                part={part}
                                blockId={blockId}
                                interactive={true}
                                selectedPart={selectedPart}
                                selectPart={() => props.selectPart(part.id, blockId)}
                            />
                        );
                    } else {
                        return <Fragment />;
                    }
                } else if (domNode.name === 'change') {
                    return <Fragment />;
                }
            },
        });

        return <div className="area-read-only editor-right-side">{interactiveContent}</div>;
    }

    // render text on the right/read only side
    return (
        <ContentReadOnly
            block={block}
            document={documentWithAreas}
            activeVariant={props.activeVariant}
            baseBlock={props.baseBlock}
            blockDisplayOptions={blockDisplayOptions}
            context={context}
            skipped={props.skipped}
        />
    );
}

function ContentReadOnly({ activeVariant, baseBlock = {}, block, blockDisplayOptions, context, document, skipped }) {
    let content = '';
    const { deleted = false } = block;

    if (context === Constants.blockContext.checkChanges) {
        content = block.baseContent !== '' ? block.baseContent : block.latestContent;
    } else {
        let prepareTags = true;
        let text = block.latestContent;

        if (baseBlock && baseBlock.hasOwnProperty('latestContentRendered')) {
            // No need to prepare the Tags, only the [Ref] [Tooltip] and [~~ChangedValues~~]
            text = baseBlock.latestContentRendered;
            prepareTags = false;
        }

        // show the changes for the base variant only
        if (blockDisplayOptions.text.markChanges) {
            text = block.renderedDiffContent;
        }

        if (!blockDisplayOptions.text.markChanges && block.hasOwnProperty('latestContentRendered')) {
            text = block.latestContentRendered;
            prepareTags = false;
        }

        if (text === undefined) {
            text = '';
            prepareTags = false;
        }

        if (prepareTags === false && text !== '') {
            text = HelperFunctions.prepareTooltipTags(text);
            text = HelperFunctions.prepareTagChangedValues(text);
            text = HelperFunctions.prepareRefs(text, document);
        }

        content = prepareTags ? HelperFunctions.prepareTags(text, document, activeVariant) : text;
    }

    if (content) {
        return (
            <div
                className={cx('area-read-only editor-right-side', {
                    'uk-text-muted': skipped,
                    'is-deleted': deleted,
                })}
            >
                {Parser(content)}
            </div>
        );
    }

    return null;
}
