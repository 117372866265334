import { useGetSubscription } from 'pages/account/views/billing/useGetSubscription';
import { useCreateCustomerPortalSessionMutation } from 'features/security/authApi';
import { PrimaryButton } from 'components/Buttons';
import { useUserProfile } from 'hooks/useUserProfile';
import { useState } from 'react';
import Spinner from 'pages/global/Spinner';
import { Trans, useTranslation } from 'react-i18next';

export function PaymentInformation() {
    const { t } = useTranslation('users');
    const subscriptionResult = useGetSubscription();
    const userProfile = useUserProfile();
    const { currentPlan } = subscriptionResult;
    const [createCustomerPortalSession] = useCreateCustomerPortalSessionMutation();
    const [isSubmitting, setSubmitting] = useState(false);

    return (
        <>
            <div className="subheader">
                <h3>{t('profile.billingPlans')}</h3>
            </div>
            <div>{renderPaymentInformation()}</div>
        </>
    );

    function renderPaymentInformation() {
        if (!currentPlan || currentPlan.hasOwnProperty('id') === false) {
            return null;
        }

        return (
            <div>
                <div className="mb-2">
                    <Trans
                        ns="users"
                        i18nKey="profile.billing.editBillingSettings"
                        values={{
                            portal: t('profile.customerPortal'),
                        }}
                        components={{ bold: <strong /> }}
                    />
                </div>

                <div className="d-flex align-items-center">
                    <PrimaryButton
                        disabled={isSubmitting}
                        className="mr-1"
                        onClick={() => {
                            setSubmitting(true);

                            createCustomerPortalSession({
                                accountId: userProfile.accountId,
                                returnUrl: window.location.href,
                            }).then(({ data }) => {
                                if (data.hasOwnProperty('url')) {
                                    window.location.href = data.url;
                                }

                                setSubmitting(false);
                            });
                        }}
                    >
                        {t('profile.billing.openCustomerPortal')}
                    </PrimaryButton>

                    {isSubmitting && <Spinner />}
                </div>
            </div>
        );
    }
}
