import { useEffect, useState } from 'react';
import ReimbursementApi from '../../../../api/ReimbursementApi';

let isCalling = new Map();

export function useFetchBlockData(blockId, code, variantId, categoryId, changesFromTimestamp = undefined) {
    const [data, setData] = useState({
        loading: true,
        data: undefined,
    });

    const mapKey = `${code}-${variantId}-${categoryId}`;

    useEffect(() => {
        let isThisAPICalling = isCalling.get(mapKey);
        if (!isThisAPICalling) {
            isThisAPICalling = ReimbursementApi.getReimbursementBlockData(
                blockId,
                code,
                variantId,
                categoryId,
                'nl',
                false,
                true,
                changesFromTimestamp
            ).then((result) => result);

            isCalling.set(mapKey, isThisAPICalling);
            // The body can only be parsed once.
        }
        isThisAPICalling.then((data) => {
            isCalling.delete(mapKey);
            setData({
                loading: false,
                data,
            });
        });
    }, []);

    return data;
}
