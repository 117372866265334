import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { useGetOneLinersQuery } from 'features/metadata/metadata';

const emptyArray = [];

export function useGetOneLiners(areaId, skip = false) {
    const organisationId = useActiveOrganisation();

    const params = {
        areaId,
        organisationId,
    };

    const { oneLiners } = useGetOneLinersQuery(params, {
        selectFromResult: ({ data }) => ({
            oneLiners: data ?? emptyArray,
        }),
        skip,
    });

    return oneLiners;
}
