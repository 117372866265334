import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { useGetOneLinerCategoriesQuery } from 'features/metadata/metadata';

const emptyArray = [];

export function useGetCategories(documentId, groupsOnly = true) {
    const organisationId = useActiveOrganisation();

    const params = {
        documentId,
        organisationId,
        isGroup: groupsOnly ? true : undefined,
    };

    const { categories } = useGetOneLinerCategoriesQuery(params, {
        selectFromResult: ({ data }) => ({
            categories: data ?? emptyArray,
        }),
    });

    return categories;
}
