import { useGetLabelFoldersQuery } from 'features/metadata/metadata';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import Constants from '../../../config/Constants';

const emptyArray = [];

export function useGetLabelFolders(entityType) {
    const activeOrganisation = useActiveOrganisation();

    const { labelFolders } = useGetLabelFoldersQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                labelFolders: data ?? emptyArray,
            }),
        },
    );

    // Filter by entity type?
    if (entityType) {
        const allowedTypes = Object.keys(Constants.labelFolderEntities).filter((_entity) => {
            return Constants.labelFolderEntities[_entity].includes(entityType);
        });

        return labelFolders.filter((labelFolder) =>
            labelFolder.contentTypes.some((_type) => allowedTypes.includes(_type)),
        );
    }

    return labelFolders;
}
