import React, { Component } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import Modal from '../../global/Modal';
import Constants from '../../../config/Constants';
import CloseButton from '../../global/CloseButton';
import PageTitle from '../../global/PageTitle';
import LoadingSpinner from '../../global/LoadingSpinner';
import LogApi from '../../../api/LogApi';
import getEnv from '../../../config/Env';
import HelperFunctions from '../../global/HelperFunctions';
import { useTranslation } from 'react-i18next';

export default function AuditTrailModal(props) {
    const { t } = useTranslation('documents');
    const { documentId } = useParams();

    return (
        <Modal onRequestClose={props.close} opened={true} documentId={parseInt(documentId)}>
            <AuditTrail {...props} t={t} />
        </Modal>
    );
}

class AuditTrail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            previewToken: '',
            redirect: false,
        };

        this.previewLink = getEnv('LOG_API_URL') + '/audit/document/';
    }

    componentDidMount() {
        let formData = { documentId: this.props.documentId };
        let t = this.props.t;

        if (this.props.sectionId !== undefined) {
            formData.sectionId = this.props.sectionId;
        }

        if (formData.documentId === undefined) {
            HelperFunctions.alertModal(t('document.navbar.main.titles.couldNotGetAudit')).then(() =>
                this.props.close()
            );
        } else {
            LogApi.getAuditToken(formData)
                .then((previewToken) => {
                    this.setState({
                        isLoading: false,
                        previewToken,
                    });
                })
                .catch((context) => {
                    getEnv('APP_ENV') === Constants.environments.dev &&
                        console.log('error preview token from publisher api:', context);
                    HelperFunctions.alertModal(t('document.navbar.main.titles.couldNotGetAudit')).then(() =>
                        this.setState({ redirect: 'basePath' })
                    );
                });
        }
    }

    render() {
        if (this.state.redirect === 'basePath') {
            return <Redirect to="/documents" />;
        }

        if (!this.state.isLoading) {
            return (
                <div className="uk-padding-medium">
                    <div className="uk-position-relative">
                        <CloseButton
                            className="uk-position-top-right uk-position-fixed uk-position-z-index uk-margin-large-top uk-margin-large-right"
                            close={this.props.close}
                        />
                        <PageTitle pageTitle="Audit trail" marginTop="none" marginBottom="none" />
                    </div>
                    <iframe
                        className="uk-width-1-1 uk-border uk-border-theme-light"
                        src={this.previewLink + this.state.previewToken.token}
                        title="Dit frame toont de audit trail"
                        data-uk-height-viewport="offset-top: true; offset-bottom: 80px"
                    />
                </div>
            );
        } else {
            return <LoadingSpinner />;
        }
    }
}
