import HelperFunctions from '../../../../../global/HelperFunctions';
import _ from 'lodash';
import { useGetDocument } from '../../../../hooks/useGetDocument';
import { set } from '../../../../../../features/app/appSlice';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import { useGetReimbursementsQuery } from '../../../../../../features/reimbursements/reimbursements';
import { useTranslation } from 'react-i18next';

export function ReimbursementBlock({ block, index, readOnly = false, restrictions }) {
    const dispatch = useDispatch();

    const showEditModal = () => {
        dispatch(
            set({
                key: 'blockEditModal',
                value: {
                    eventKey: 'reimbursementBlockModal',
                    block,
                    blockIndex: index,
                },
            })
        );
    };

    return (
        <div
            onClick={() => {
                if (!readOnly || restrictions.canChangePackage) {
                    showEditModal();
                }
            }}
            className={cx({
                'uk-background-hover-parent cursor-pointer': !readOnly,
            })}
        >
            <div className="p-4 border-radius uk-background-muted uk-background-primary-extra-light-child uk-text-muted text-center">
                <ReimbursementContent block={block} readOnly={readOnly} />
            </div>
        </div>
    );
}

function ReimbursementContent({ block, readOnly }) {
    const latestContentData = readOnly
        ? HelperFunctions.tryParseJSON(block.baseContent)
        : HelperFunctions.tryParseJSON(block.latestContent);
    const { t } = useTranslation('documents');
    if (!latestContentData || !latestContentData.code) {
        return <div>{t('document.navbar.main.editor.left.blocks.types.reimbursementBlock.noCode')}</div>;
    }

    return (
        <>
            <div>{latestContentData.code}</div>
            <div>
                <RenderedReimbursementCode code={latestContentData.code} />
            </div>
        </>
    );
}

function RenderedReimbursementCode({ code }) {
    const document = useGetDocument();

    const { reimbursement } = useGetReimbursementsQuery(
        `/api/reimbursement-api/api/categories/${document.reimbursementCategoryId}`,
        {
            selectFromResult: ({ data }) => ({
                reimbursement: data ? data.find((reimbursement) => reimbursement.code === code) : undefined,
            }),
            skip: _.isEmpty(code),
        }
    );

    if (reimbursement === undefined || _.isEmpty(code)) {
        return null;
    }

    return <>{reimbursement.name}</>;
}
