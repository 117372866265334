import React, { useEffect, useState } from 'react';
import MainContentNav from '../Navbar';
import Sidebar from './Sidebar';
import { Badge, Dropdown, Table } from 'react-bootstrap';
import NewLabelModal from './NewLabelModal';
import { Bookmark, FolderFill, ThreeDotsVertical } from 'react-bootstrap-icons';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import MoveLabelModal from './MoveLabelModal';
import HelperFunctions from '../global/HelperFunctions';
import { useHistory, useParams, generatePath } from 'react-router-dom';
import EditModal from './EditModal';
import EditLabelProps from './EditLabelProps';
import {
    useDeleteLabelFolderMutation,
    useDeleteLabelMutation,
    useGetLabelFoldersQuery,
    useGetLabelsQuery,
} from '../../features/metadata/metadata';
import { idToUri } from '../global/UriHelper';
import { InfoButton } from '../../components/Buttons';
import { useTranslation } from 'react-i18next';

function Index() {
    const [showNewLabelModal, setShowNewLabelModal] = useState(false);
    const [moveLabel, setMoveLabel] = useState(null);
    const [editLabel, setEditLabel] = useState(null);
    const [editLabelProps, setEditLabelProps] = useState(null);
    const dispatch = useDispatch();
    const { folderId = 'all' } = useParams();
    const { t } = useTranslation('labels');

    useEffect(() => {
        setEditLabelProps(null);
    }, [dispatch, folderId]);

    return (
        <>
            <MainContentNav title="Labels" />

            <div className="content-static-body">
                <div className="d-flex align-items-stretch h-100">
                    <div className="content-sidebar overflow-auto">
                        <Sidebar editLabel={setEditLabel} />
                    </div>
                    <div
                        className="flex-grow-1 overflow-auto"
                        style={{ marginTop: 75, marginRight: '23px', marginLeft: '15px' }}
                    >
                        <div className="pt-4 pb-3 px-4">
                            <div className="dr-container">
                                <Labels
                                    editLabel={setEditLabel}
                                    editLabelProps={editLabelProps}
                                    setEditLabelProps={setEditLabelProps}
                                    moveLabel={setMoveLabel}
                                />
                            </div>
                        </div>
                    </div>

                    <EditLabelProps
                        closeSidebar={() => setEditLabelProps(null)}
                        label={editLabelProps}
                        setEditLabelProps={setEditLabelProps}
                    />
                </div>
            </div>

            <div
                className="d-flex align-items-center content-static-filters bg-bgLight px-4 py-3"
                style={{ marginRight: '25px', marginTop: '5px' }}
            >
                <InfoButton className="ml-auto" onClick={() => setShowNewLabelModal(true)}>
                    {t('btn.newLabel')}
                </InfoButton>
            </div>

            {showNewLabelModal && <NewLabelModal handleClose={() => setShowNewLabelModal(false)} showModal={true} />}

            {moveLabel && <MoveLabelModal handleClose={() => setMoveLabel(null)} showModal={true} label={moveLabel} />}

            {editLabel !== null && (
                <EditModal handleClose={() => setEditLabel(null)} showModal={true} entity={editLabel} />
            )}
        </>
    );
}

function Labels(props) {
    return (
        <div id="content" className="px-3 py-1">
            <LabelTable {...props} />
        </div>
    );
}

function LabelTable(props) {
    const { folderId = 'all' } = useParams();
    const { activeOrganisation } = useSelector((state) => state.security);
    const editMode = props.editLabelProps !== null;
    const { t } = useTranslation('labels');

    const { labels = [] } = useGetLabelsQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                labels: data
                    ? folderId === 'all'
                        ? data.filter((label) => label.folder === null)
                        : data.filter((label) => label.folder === idToUri(folderId, 'LabelFolder'))
                    : [],
            }),
        }
    );

    return (
        <div className="react-bootstrap-table">
            <Table hover>
                <thead>
                    <tr>
                        <th>
                            <span className="pl-2">{t('columnTitles.labelName')}</span>
                        </th>
                        <th width={editMode ? 130 : 180}>{t('columnTitles.created')}</th>
                        <th width="70" style={{ width: editMode ? '5%' : undefined }} />
                    </tr>
                </thead>
                <tbody>{folderId === 'all' && <FolderTable {...props} />}</tbody>
                <tbody>
                    {labels.map((row) => {
                        return <LabelRow {...props} row={row} key={row.id} />;
                    })}
                </tbody>
            </Table>
        </div>
    );
}

function FolderTable(props) {
    const { activeOrganisation } = useSelector((state) => state.security);
    const { folders = [] } = useGetLabelFoldersQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                folders: data ?? [],
            }),
        }
    );

    return folders.map((row) => <FolderRow {...props} row={row} key={row.id} />);
}

function FolderRow(props) {
    const { row } = props;
    const [isHover, setHover] = useState(false);
    const dateTime = DateTime.fromISO(row.createdAt);
    const history = useHistory();
    const [deleteLabelFolder] = useDeleteLabelFolderMutation();

    const remove = () => {
        deleteLabelFolder(row['@id']);
    };

    return (
        <tr onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            <td>
                <div
                    className="pl-2"
                    onClick={() => {
                        history.push(
                            generatePath('/labels/:folderId', {
                                folderId: row.id,
                            })
                        );
                    }}
                >
                    <FolderFill size={15} className="text-muted mr-2" />
                    {row.name}
                </div>
            </td>
            <td>
                <span className="text-muted small">{dateTime.toLocaleString(DateTime.DATETIME_MED)}</span>
            </td>
            <td>
                <ActionsFormatter {...props} isHover={isHover} remove={remove} />
            </td>
        </tr>
    );
}

function LabelRow(props) {
    const { editLabelProps, row, setEditLabelProps } = props;
    const [isHover, setHover] = useState(false);
    const dateTime = DateTime.fromISO(row.createdAt);
    const rowIsSelected = row.id === editLabelProps?.id;
    const [deleteLabel] = useDeleteLabelMutation();

    const remove = () => {
        deleteLabel(row['@id']);
    };

    const handleRowClick = () => {
        setEditLabelProps(rowIsSelected ? null : row);
    };

    return (
        <tr
            onClick={handleRowClick}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            key={row.id}
        >
            <td>
                <NameFormatter row={row} />
            </td>
            <td>
                <span className="text-muted small">{dateTime.toLocaleString(DateTime.DATETIME_MED)}</span>
            </td>
            <td>
                <ActionsFormatter {...props} isHover={isHover} row={row} remove={remove} />
            </td>
        </tr>
    );
}

function NameFormatter({ row }) {
    return (
        <div className="d-flex align-items-center pl-2">
            <Bookmark size={15} className="text-muted" style={{ minWidth: 15 }} />
            <span className="mx-2">{row.name}</span>
            {row.labelProps.length > 0 && <Badge variant="primary">{row.labelProps.length}</Badge>}
        </div>
    );
}

const ActionsFormatter = ({ row, isHover, remove, moveLabel, editLabel }) => {
    const { activeOrganisation } = useSelector((state) => state.security);
    const { t } = useTranslation('labels');

    const { labels = [] } = useGetLabelsQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                labels: data ? data.filter((label) => label.folder === idToUri(row.id, 'LabelFolder')) : [],
            }),
        }
    );

    const confirmDelete = () => {
        HelperFunctions.confirmModal(
            `${t('columnTitles.deleteModal.delete', { row: row.name })}`,
            'danger',
            false,
            t('columnTitles.deleteModal.btn.delete'),
            t('btn.cancel')
        )
            .then(() => {
                remove(row);
            })
            .catch(() => {});
    };

    const deleteDisabled = () => {
        return row['@type'] === 'LabelFolder' && labels.length > 0;
    };

    return (
        <div
            className={classNames('d-flex flex-column align-items-end', { invisible: !isHover })}
            onClick={(e) => e.stopPropagation()}
        >
            <Dropdown>
                <Dropdown.Toggle bsPrefix="docrev-dropdown-toggle" as="span" id="dropdown-basic">
                    <ThreeDotsVertical />
                </Dropdown.Toggle>

                <Dropdown.Menu align="right">
                    <Dropdown.Item onClick={() => editLabel(row)}>
                        {t('columnTitles.dropdown.editName')}&hellip;
                    </Dropdown.Item>
                    {row['@type'] === 'Label' && (
                        <Dropdown.Item onClick={() => moveLabel(row)}>
                            {t('columnTitles.dropdown.move')}&hellip;
                        </Dropdown.Item>
                    )}
                    <Dropdown.Divider />
                    <Dropdown.Item disabled={deleteDisabled()} onClick={confirmDelete}>
                        <span className={deleteDisabled() ? 'text-muted' : 'text-danger'}>
                            {t('columnTitles.dropdown.delete')}&hellip;
                        </span>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default Index;
