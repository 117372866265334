import { useUserProfile } from './useUserProfile';
import { useActiveOrganisation } from './useActiveOrganisation';

export function useGetOrganisationModules() {
    const userProfile = useUserProfile();
    const activeOrganisationId = useActiveOrganisation();

    if (userProfile.hasOwnProperty('organisations')) {
        const organisation = userProfile.organisations.find(
            (_organisation) => _organisation.id === activeOrganisationId
        );

        return organisation?.modules ?? [];
    }

    return [];
}
