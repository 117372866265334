import { useSidebarContext } from '../hooks/useSidebarContext';
import OpEntryBlockVariantsSidebar from '../../../pages/operations_list/OpEntryBlockVariantsSidebar';

export function OpEntryVariants() {
    const { entity } = useSidebarContext();

    return (
        <OpEntryBlockVariantsSidebar context={entity} />
    )
}
