import { Product, ProductTeam, Reimbursement } from '../../../../models/reimbursement.models';
import { useGetReimbursementsQuery } from '../../../../features/reimbursements/reimbursements';
import { BagPlusFill, ChevronRight } from 'react-bootstrap-icons';
import { memo } from 'react';
import { TeamAvatars } from '../category/TeamAvatars';

function ReimbursementBreadcrumb({
    reimbursementId,
    categoryUri,
    parentProductName,
    product,
}: {
    reimbursementId: number;
    categoryUri: string;
    parentProductName: string;
    product: Product;
}) {
    const { reimbursement }: { reimbursement: Reimbursement | undefined } = useGetReimbursementsQuery(categoryUri, {
        selectFromResult: ({ data }) => ({
            reimbursement: data
                ? data.find((reimbursement: Reimbursement) => reimbursement.id === reimbursementId)
                : undefined,
        }),
    });

    const productTeam =
        reimbursement?.productTeams?.find((_productTeam) => _productTeam.productId === product.id) ??
        ({
            productId: product.id,
            teams: [],
        } satisfies ProductTeam);

    return (
        <div className="position-absolute border-bottom" style={{ height: 50, top: 0, left: 0, right: 0 }}>
            <div className="d-flex px-4" style={{ height: 50 }}>
                <div className="d-flex align-items-center text-secondary" style={{ fontSize: 12 }}>
                    <BagPlusFill className="mr-2" size={14} />
                    <div>{reimbursement?.name}</div>
                    <ChevronRight className="text-muted mx-2" size={14} />
                    <div>{parentProductName}</div>
                    <ChevronRight className="text-muted mx-2" size={14} />
                    <div className="font-weight-bolder">
                        <span>{product.code}</span>&nbsp;
                        <span className="text-body">{product.name}</span>
                    </div>

                    {productTeam.teams.length > 0 && (
                        <div className="ml-3">
                            <TeamAvatars teams={productTeam.teams} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export const ReimbursementBreadcrumbMemo = memo(ReimbursementBreadcrumb);
