import { Modal } from 'react-bootstrap';
import { LightOutlineButton } from 'components/Buttons';
import { EmptyState, EmptyStateBody, EmptyStateHeader, EmptyStateIcon, RocketIcon } from 'components/EmptyState';

export function NoPermissionsModal({ title = '', close }) {
    return (
        <Modal show={true} onHide={close} size="xl">
            <Modal.Header className="align-items-center">
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <EmptyState>
                    <EmptyStateHeader
                        titleText={'Je hebt geen toestemming voor deze actie'}
                        icon={<EmptyStateIcon icon={RocketIcon} />}
                    />
                    <EmptyStateBody>
                        Vraag een beheerder om de juiste rechten te krijgen voor deze actie.
                    </EmptyStateBody>
                </EmptyState>
            </Modal.Body>
            <Modal.Footer>
                <LightOutlineButton onClick={close}>Sluiten</LightOutlineButton>
            </Modal.Footer>
        </Modal>
    );
}
