import { AvatarGroups } from '../../../../components/Avatars';
import { useGetTeams } from '../../hooks/useGetTeams';

export function TeamAvatars({ teams }: { teams: number[] }) {
    const allTeams = useGetTeams();

    if (teams.length === 0) {
        return null;
    }

    return <AvatarGroups data={allTeams.filter((team) => teams.includes(team.id))} />;
}
