import { NumberParam, useQueryParam } from 'use-query-params';
import { FormModal, ModalFooter } from '../../../../global/FormModal';
import { useGetVariant } from '../../../hooks/useGetVariant';
import { Form as FForm, Formik, useFormikContext } from 'formik';
import { useGetBaseVariant } from '../../../hooks/useGetBaseVariant';
import { useMemo } from 'react';
import { useGetDocument } from '../../../hooks/useGetDocument';
import { useTranslation } from 'react-i18next';
import DocumentHelper from '../../../../global/DocumentHelper';
import cx from 'classnames';
import HelperFunctions from '../../../../global/HelperFunctions';
import { useUpdateAreasMutation, useUpdateSectionsMutation } from '../../../../../features/documents/documents';
import _ from 'lodash';
import { Form, Table } from 'react-bootstrap';

export function LinkDocumentModal() {
    const [documentModal, setDocumentModal] = useQueryParam('documentModal', NumberParam);
    const baseVariant = useGetBaseVariant(undefined, true);
    const variant = useGetVariant(documentModal);

    const showModal = documentModal !== undefined;
    const showContent = showModal && baseVariant;

    const hide = () => {
        setDocumentModal(undefined);
    };

    return (
        <FormModal
            scrollable={true}
            size="xl"
            onHide={hide}
            show={documentModal !== undefined}
            title={variant ? `Hoofdstukken koppelen: ${variant.name}` : 'Hoofdstukken koppelen'}
        >
            {showContent && <ModalContent variant={variant} />}
        </FormModal>
    );
}

function ModalContent({ variant }) {
    const [documentModal, setDocumentModal] = useQueryParam('documentModal', NumberParam);
    const document = useGetDocument(undefined, true);
    const variantId = variant.id;
    const [updateAreas] = useUpdateAreasMutation();
    const [updateSections] = useUpdateSectionsMutation();

    const hide = () => {
        setDocumentModal(undefined);
    };

    const prepareAreas = (formData) => {
        const areas = [];

        document.sections.forEach((section) => {
            section.areas.forEach((area) => {
                let currentVariants = [...area.documentVariants];

                // Make sure to add it
                if (formData.variants.hasOwnProperty(section.id) && formData.variants[section.id].includes(area.id)) {
                    if (!currentVariants.includes(variant.id)) {
                        // Not yet added
                        currentVariants.push(variant.id);
                    }
                } else {
                    // Remove it
                    currentVariants = currentVariants.filter((currentVariantId) => {
                        return currentVariantId !== variant.id;
                    });
                }

                const variantsChanged = JSON.stringify(currentVariants) !== JSON.stringify(area.documentVariants);

                // Properties
                const areaFromForm = HelperFunctions.getByValue(formData.areas, 'id', area.id);
                const propertiesChanged =
                    JSON.stringify(areaFromForm.properties.titlePrefixes) !==
                    JSON.stringify(area.properties.titlePrefixes);

                if (variantsChanged || propertiesChanged) {
                    // content changed, save it
                    areas.push({
                        id: area.id,
                        documentVariants: currentVariants,
                        properties: areaFromForm.properties,
                        addBlocks: true,
                    });
                }
            });
        });

        return areas;
    };

    const prepareSections = (formData) => {
        const sections = [];

        document.sections.forEach((section) => {
            let currentVariants = [...section.documentVariants];

            // Make sure to add it
            if (formData.variants.hasOwnProperty(section.id)) {
                if (!currentVariants.includes(variant.id)) {
                    // Not yet added
                    currentVariants.push(variant.id);
                }
            } else {
                // Remove it
                currentVariants = currentVariants.filter((currentVariantId) => {
                    return currentVariantId !== variant.id;
                });
            }

            const variantsChanged = JSON.stringify(currentVariants) !== JSON.stringify(section.documentVariants);

            // Properties
            const sectionFromForm = HelperFunctions.getByValue(formData.sections, 'id', section.id);
            if (sectionFromForm.properties === undefined || sectionFromForm.properties === null) {
                sectionFromForm.properties = {
                    titlePrefixes: [],
                };
            }
            if (!sectionFromForm.properties.hasOwnProperty('titlePrefixes')) {
                sectionFromForm.properties.titlePrefixes = [];
            }

            // Check Section properties
            if (section.properties === undefined || section.properties === null) {
                section.properties = {
                    titlePrefixes: [],
                };
            }
            if (!section.properties.hasOwnProperty('titlePrefixes')) {
                section.properties.titlePrefixes = [];
            }

            const propertiesChanged =
                JSON.stringify(sectionFromForm.properties.titlePrefixes) !==
                JSON.stringify(section.properties.titlePrefixes);

            if (variantsChanged || propertiesChanged) {
                // content changed, save it
                sections.push({
                    addArea: false,
                    documentVariants: currentVariants,
                    properties: sectionFromForm.properties,
                    id: section.id,
                    title: section.title, //tmp
                });
            }
        });

        return sections;
    };

    const handleUpdateAreas = (formData) => {
        return new Promise((resolve) => {
            if (formData.length > 0) {
                updateAreas({ documentId: document.id, body: formData }).then((data) => {
                    resolve(data);
                });
            } else {
                resolve([]);
            }
        });
    };

    const handleUpdateSections = (formData) => {
        return new Promise((resolve) => {
            if (formData.length > 0) {
                updateSections({ documentId: document.id, body: formData }).then((data) => {
                    resolve(data);
                });
            } else {
                resolve([]);
            }
        });
    };

    const handleSubmit = (formData, { setSubmitting }) => {
        // Prepare the new data
        const sections = prepareSections(formData);
        const areas = prepareAreas(formData);

        // Save it
        Promise.all([handleUpdateSections(sections), handleUpdateAreas(areas)]).finally(() => {
            setSubmitting(false);
        });
    };

    const initialValues = useMemo(() => {
        const formData = {
            variants: {},
            sections: [],
            areas: [],
        };

        document?.sections.forEach((section) => {
            formData.sections.push(section);

            if (section.documentVariants.includes(variantId)) {
                formData.variants[section.id] = [];
            }

            section.areas.forEach((area) => {
                formData.areas.push(area);

                if (section.documentVariants.includes(variantId) && area.documentVariants.includes(variantId)) {
                    formData.variants[section.id].push(area.id);
                }
            });
        });

        return formData;
    }, [document, variantId]);

    if (!variant || !document) {
        return null;
    }

    return (
        <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={handleSubmit}>
            {({ values, isSubmitting, isValid, dirty }) => (
                <>
                    <FForm autoComplete="off" className="modal-body" id="link-document">
                        <div>
                            {values.sections.map((section) => (
                                <SectionTable
                                    section={section}
                                    variantId={variantId}
                                    key={`section-table-${section.id}`}
                                />
                            ))}
                        </div>
                    </FForm>

                    <ModalFooter
                        isSubmitting={isSubmitting}
                        isValid={isValid}
                        dirty={dirty}
                        onHide={hide}
                        form="link-document"
                    />
                </>
            )}
        </Formik>
    );
}

function SectionTable({ section, variantId }) {
    const { t } = useTranslation('documents');
    const { values, setFieldValue } = useFormikContext();

    const sectionSelected = values.variants.hasOwnProperty(section.id);

    const toggleSelection = (sectionId, areaId = undefined) => {
        const formData = {
            ...values.variants,
        };

        // Toggle Section
        if (areaId === undefined) {
            if (values.variants.hasOwnProperty(sectionId)) {
                // Remove it
                delete formData[sectionId];
            } else {
                // Add it
                formData[sectionId] = [];
            }
        }

        // Toggle Area
        if (areaId !== undefined) {
            if (formData[sectionId].includes(areaId)) {
                // Remove area from section
                formData[sectionId] = formData[sectionId].filter((id) => {
                    return id !== areaId;
                });
            } else {
                // Add area
                formData[sectionId].push(areaId);
            }
        }

        setFieldValue('variants', formData);
    };

    const addArea = (sectionId, areaIds = []) => {
        const formData = {
            ...values.variants,
        };

        areaIds.forEach((areaId) => {
            if (!formData[sectionId].includes(areaId)) {
                formData[sectionId].push(areaId);
            }
        });

        setFieldValue('variants', formData);
    };

    const removeArea = (sectionId, areaIds = []) => {
        const formData = {
            ...values.variants,
        };

        areaIds.forEach((areaId) => {
            if (formData[sectionId].includes(areaId)) {
                formData[sectionId] = formData[sectionId].filter((id) => {
                    return id !== areaId;
                });
            }
        });

        setFieldValue('variants', formData);
    };

    const selectAllAreas = () => {
        addArea(
            section.id,
            section.areas.map((area) => area.id)
        );
    };

    const deselectAllAreas = () => {
        removeArea(
            section.id,
            section.areas.map((area) => area.id)
        );
    };

    const handleTitlePrefixChange = (e, entity, props) => {
        let titlePrefixes = [];
        let found = false;

        if (entity.properties.hasOwnProperty('titlePrefixes') && Array.isArray(entity.properties.titlePrefixes)) {
            titlePrefixes = _.cloneDeep(entity.properties.titlePrefixes);
        }

        titlePrefixes.forEach((prefix, index) => {
            if (!found && prefix.variantId === variantId) {
                found = true;
                titlePrefixes[index].text = e.target.value;
            }
        });

        if (!found) {
            // Add it
            titlePrefixes.push({
                text: e.target.value,
                variantId,
            });
        }

        setFieldValue(
            props,
            values[props].map((_entity) => {
                if (_entity.id === entity.id) {
                    return {
                        ..._entity,
                        properties: {
                            ..._entity.properties,
                            titlePrefixes,
                        },
                    };
                }

                return _entity;
            })
        );
    };

    return (
        <Table striped>
            <thead>
                <tr>
                    <th className="border-top-0 w-50" />
                    <th className="border-top-0 w-25">
                        {t('document.navbar.variants.linkChapters.columnTitles.active')}
                    </th>
                    <th className="border-top-0 w-25">
                        {t('document.navbar.variants.linkChapters.columnTitles.prefix')}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className="align-middle font-weight-bold">{section.title}</td>
                    <td className="align-middle">
                        <div
                            className={cx('d-flex align-items-center small', {
                                'text-muted': !sectionSelected,
                            })}
                        >
                            <Form.Check checked={sectionSelected} onChange={() => toggleSelection(section.id)} />

                            <div style={{ paddingTop: 2 }}>
                                (
                                <a
                                    className={cx('btn-link', {
                                        disabled: !sectionSelected,
                                    })}
                                    onClick={() => selectAllAreas(section)}
                                >
                                    {t('document.navbar.variants.linkChapters.selectAll')}
                                </a>
                                |
                                <a
                                    className={cx('btn-link', {
                                        disabled: !sectionSelected,
                                    })}
                                    onClick={() => deselectAllAreas(section)}
                                >
                                    {t('document.navbar.variants.linkChapters.selectNone')}
                                </a>
                                )
                            </div>
                        </div>
                    </td>
                    <td className="align-middle">
                        <Form.Control
                            value={DocumentHelper.getPrefix(section, variantId) ?? ''}
                            onChange={(e) => handleTitlePrefixChange(e, section, 'sections')}
                        />
                    </td>
                </tr>
                {section.areas.map((area) => (
                    <AreaTable
                        area={HelperFunctions.getByValue(values.areas, 'id', area.id)}
                        toggleSelection={(areaId) => toggleSelection(section.id, areaId)}
                        sectionSelected={sectionSelected}
                        sectionId={section.id}
                        handleTitlePrefixChange={handleTitlePrefixChange}
                        variantId={variantId}
                        key={`area-table-${area.id}`}
                    />
                ))}
            </tbody>
        </Table>
    );
}

function AreaTable({ area, toggleSelection, sectionSelected, sectionId, handleTitlePrefixChange, variantId }) {
    const { values } = useFormikContext();

    const selectedAreas = sectionSelected ? values.variants[sectionId] : [];
    const areaSelected = selectedAreas.includes(area.id);

    return (
        <tr>
            <td className="align-middle">
                <span className="pl-2">{area.title}</span>
            </td>
            <td className="align-middle">
                <Form.Check checked={areaSelected} onChange={() => toggleSelection(area.id)} />
            </td>
            <td className="align-middle">
                <Form.Control
                    value={DocumentHelper.getPrefix(area, variantId) ?? ''}
                    onChange={(e) => handleTitlePrefixChange(e, area, 'areas')}
                />
            </td>
        </tr>
    );
}
