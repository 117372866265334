import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useGetPropositionQuery, useGetPropositionsQuery } from '../../../../features/reimbursements/reimbursements';
import { LoadingSpinner } from '../../../global/Spinner';
import MainContentNav from '../../../Navbar';
import { BASE_PATH, PROPOSITIONS_PATH, VIEW_PATH } from '../../../../scenes/Reimbursements';
import { useSelector } from 'react-redux';
import EditPublicationForm from '../forms/EditPropositionForm';
import { Button } from 'react-bootstrap';
import React from 'react';
import { useFormikContext } from 'formik';
import HelperFunctions from '../../../global/HelperFunctions';
import { ExclamationTriangleFill } from 'react-bootstrap-icons';
import Properties from './edit/Properties';
import Sidebar from './edit/Sidebar';
import Products from './edit/Products';
import Collectives from './edit/Collectives';
import constants from '../../../../config/Constants';
import { useGetCategory } from '../../../../features/reimbursements/reimbursementHooks';
import { idToUri } from '../../../global/UriHelper';
import { WarningButton } from '../../../../components/Buttons';
import { useTranslation } from 'react-i18next';

export default function EditProposition() {
    const params = useParams();
    const categoryId = parseInt(params.categoryId);
    const { propositionId, folderId } = params;
    const activeOrganisation = useSelector((state) => state.security.activeOrganisation);
    const { category } = useGetCategory(categoryId);
    const { t } = useTranslation('reimbursements');

    const { propositions } = useGetPropositionsQuery(
        { uri: idToUri(categoryId, 'Category') },
        {
            selectFromResult: ({ data }) => ({
                propositions: data
                    ? data
                          .filter(
                              (proposition) => proposition.propositionGroup === idToUri(folderId, 'PropositionGroup')
                          )
                          .sort(HelperFunctions.dynamicSort('sortOrder'))
                    : [],
            }),
            skip: propositionId !== undefined,
        }
    );

    const { proposition } = useGetPropositionQuery(propositionId, {
        selectFromResult: ({ data }) => ({
            proposition: data,
        }),
        skip: category === undefined || propositionId === undefined,
    });

    if (category === undefined) {
        return null;
    }

    return (
        <>
            <MainContentNav
                pages={[
                    { title: t('breadcrumb'), url: BASE_PATH },
                    {
                        title: category.name,
                        url: generatePath(VIEW_PATH, { categoryId }),
                    },
                    {
                        title: t('proposition.title'),
                        url: generatePath(PROPOSITIONS_PATH, {
                            categoryId,
                            folderId,
                        }),
                    },
                    { title: proposition === undefined ? t('proposition.editProposition.title') : proposition.name },
                ]}
            />

            {(propositionId === undefined || proposition !== undefined) && (
                <EditPublicationForm
                    proposition={
                        proposition ?? {
                            name: '',
                            description: '',
                            status: constants.variantPackageStatus.Ongewijzigd,
                            veranderingVergoeding: '',
                            secondaryName: '',
                            addPrimaryAndSecondary: true,
                            showAsSeparate: false,
                            hideFirstColumn: false,
                            variantPackageProducts: [],
                            collectives: [],
                            category: category && category.hasOwnProperty('@id') ? category['@id'] : '',
                            propositionGroup: `/api/reimbursement-api/api/proposition_groups/${folderId}`,
                            organisationId: activeOrganisation,
                            sortOrder:
                                propositions.length > 0 ? propositions[propositions.length - 1].sortOrder + 1 : 0,
                        }
                    }
                >
                    <>
                        <div className="position-absolute" style={{ top: 73, right: 0, left: 0, bottom: 50 }}>
                            <div className="d-flex align-items-stretch h-100">
                                <div className="content-sidebar bg-light">
                                    <Sidebar categoryUri={category['@id']} />
                                </div>
                                <div className="flex-grow-1 overflow-auto">
                                    <div id="content" className="px-5 pt-5 pb-3">
                                        <Action categoryId={categoryId} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <FormActions />
                    </>
                </EditPublicationForm>
            )}
        </>
    );
}

function Action({ categoryId }) {
    const { action } = useParams();

    switch (action) {
        case 'properties':
            return <Properties />;
        case 'products':
            return <Products categoryId={categoryId} />;
        case 'collectives':
            return <Collectives />;
        default:
            return <>Not supported</>;
    }
}

function FormActions() {
    const history = useHistory();
    const { isSubmitting, dirty, isValid } = useFormikContext();
    const { categoryId, folderId } = useParams();
    const { t } = useTranslation('reimbursements');

    const close = () => {
        history.push(
            generatePath(PROPOSITIONS_PATH, {
                categoryId,
                folderId,
            })
        );
    };

    const handleClose = () => {
        if (dirty === false) {
            // No changes in the form, just close the window
            close();
            return;
        }

        // Ask for confirmation first
        HelperFunctions.confirmModal(
            t('proposition.editProposition.unsavedChanges'),
            'danger',
            false,
            t('proposition.editProposition.btn.yes'),
            t('btn.cancel')
        ).then(() => {
            close();
        });
    };

    return (
        <div className="position-absolute bg-light border-top" style={{ height: 65, right: 0, left: 0, bottom: 0 }}>
            <div className="d-flex justify-content-end align-items-center pr-5" style={{ height: 65 }}>
                <div className="mr-3">
                    <LoadingSpinner isSubmitting={isSubmitting} />
                </div>

                {dirty && !isSubmitting && isValid && (
                    <>
                        <ExclamationTriangleFill className="text-warning mr-1" />
                        <div className="text-warning small mr-4">
                            {t('proposition.editProposition.thereAreUnsavedChanges')}
                        </div>
                    </>
                )}
                {dirty && !isSubmitting && !isValid && (
                    <>
                        <ExclamationTriangleFill className="text-danger mr-1" />
                        <div className="text-danger small mr-4"> {t('proposition.editProposition.formErrors')}</div>
                    </>
                )}

                <Button variant="link" onClick={handleClose} disabled={isSubmitting}>
                    {t('btn.close')}
                </Button>
                <WarningButton type="submit" form="editPropositionForm" disabled={isSubmitting || !isValid || !dirty}>
                    {t('btn.save')}
                </WarningButton>
            </div>
        </div>
    );
}

const actions = {
    properties: 'Eigenschappen',
    products: 'Polissoorten',
    collectives: 'Collectiviteiten',
};
