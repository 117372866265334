import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { FieldArray, Form as FForm, Formik } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import { LoadingSpinner } from '../../../global/Spinner';
import { InputSelect } from '../../../publications_v2/helpers/FieldHelper';
import Constants from '../../../../config/Constants';
import { XCircleFill } from 'react-bootstrap-icons';
import {
    useGetTranslationContainerQuery,
    useUpdateTranslationContainerMutation,
} from '../../../../features/translations/translationApi';
import { teamRoles } from '../../config/Constants';
import { useTranslation } from 'react-i18next';
import { useCurrentOrganisation } from '../../../../hooks/useCurrentOrganisation';
import HelperFunctions from '../../../global/HelperFunctions';
import { useUserProfile } from '../../../../hooks/useUserProfile';

export default function Team() {
    const { translationContainerId } = useParams();
    const query = useGetTranslationContainerQuery(translationContainerId);
    const { isLoading, data } = query;
    const { t } = useTranslation('translations');

    return (
        <>
            <div className="subheader">
                <h3>{t('translation.navbar.settings.contentSidebar.team.title')}</h3>
            </div>

            {isLoading === false && <TeamForm data={data} />}
        </>
    );
}

function TeamForm({ data }) {
    const [updateTranslationContainer] = useUpdateTranslationContainerMutation();
    const { t } = useTranslation('translations');

    const handleSubmit = (values, { setSubmitting }) => {
        updateTranslationContainer({
            uri: data['@id'],
            body: values,
        }).then(() => {
            setSubmitting(false);
        });
    };

    return (
        <Formik
            initialValues={{
                translationUsers:
                    data.translationUsers.map((_user) => {
                        return {
                            ..._user,
                            id: _user['@id'],
                        };
                    }) ?? [],
                newUser: '',
            }}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({ values, dirty, isSubmitting, isValid, setFieldValue }) => (
                <FForm autoComplete="off">
                    <div className="mb-4">
                        <FieldArray name="translationUsers">
                            {({ remove, push }) => (
                                <>
                                    <div className="mb-4" style={{ maxWidth: 320 }}>
                                        <AddUser values={values} push={push} setFieldValue={setFieldValue} />
                                    </div>

                                    <Row className="align-items-center mb-3 text-secondary font-weight-bolder">
                                        <Col md={6} lg={4} style={{ minWidth: 340 }}>
                                            {t('translation.navbar.settings.contentSidebar.team.name')}
                                        </Col>
                                        <Col md={6} lg={4} className="d-flex" style={{ minWidth: 300 }}>
                                            {t('translation.navbar.settings.contentSidebar.team.role')}
                                        </Col>
                                    </Row>

                                    {values.translationUsers.length > 0 &&
                                        values.translationUsers.map((_documentUser, index) => (
                                            <DocumentUser
                                                documentUser={_documentUser}
                                                remove={() => remove(index)}
                                                index={index}
                                                key={index}
                                            />
                                        ))}
                                </>
                            )}
                        </FieldArray>
                    </div>

                    <Button type="submit" disabled={isSubmitting || !dirty || !isValid}>
                        {t('btn.save')}
                    </Button>
                    <LoadingSpinner isSubmitting={isSubmitting} />
                </FForm>
            )}
        </Formik>
    );
}

function AddUser({ values, push, setFieldValue }) {
    const { newUser } = values;
    const { t } = useTranslation('translations');

    const currentOrganisation = useCurrentOrganisation();
    const users = currentOrganisation?.users ?? [];

    const availableUsers = users
        .filter((_user) => values.translationUsers.some((_documentUser) => _documentUser.userId === _user.id) === false)
        .map((_user) => {
            return {
                label: _user.firstName + ' ' + _user.lastName,
                value: _user.id,
            };
        })
        .sort(HelperFunctions.sortByString('label'));

    useEffect(() => {
        if (newUser !== '') {
            push({
                userId: newUser,
                role: Constants.userDocumentRole.editor,
            });
            setFieldValue('newUser', '');
        }
    }, [newUser]);

    return (
        <InputSelect
            name="newUser"
            options={availableUsers}
            props={{
                placeholder: t('translation.navbar.settings.contentSidebar.team.addMember'),
            }}
        />
    );
}

function DocumentUser({ documentUser, remove, index }) {
    const userProfile = useUserProfile();
    const currentOrganisation = useCurrentOrganisation();
    const users = currentOrganisation?.users ?? [];

    const user = users.find((_user) => _user.id === documentUser.userId);
    const { t } = useTranslation('translations');

    // If user is undefined it's probably deleted or inactive
    if (user === undefined) {
        return null;
    }

    const { jobDescription, firstName, lastName } = user;
    const roles = Object.values(Constants.translationContainerTeamRoles).map((_role) => ({
        label: t(teamRoles[_role]),
        value: _role,
    }));
    const isRemoveDisabled = documentUser.userId === userProfile.userId;

    return (
        <Row className="align-items-center mb-2">
            <Col md={6} lg={4} style={{ minWidth: 340 }}>
                <span className="font-weight-bold">
                    {firstName} {lastName}
                </span>
                {jobDescription && <>&nbsp;({jobDescription})</>}
            </Col>
            <Col md={6} lg={4} className="d-flex" style={{ minWidth: 300 }}>
                <div className="flex-grow-1" style={{ maxWidth: 220 }}>
                    <InputSelect name={`translationUsers.${index}.role`} options={roles} />
                </div>
                <Button
                    variant="icon"
                    size="sm"
                    className="btn-icon-only text-danger ml-2"
                    data-uk-tooltip={
                        isRemoveDisabled ? '' : t('translation.navbar.settings.contentSidebar.team.deleteMember')
                    }
                    onClick={remove}
                    disabled={isRemoveDisabled}
                >
                    <XCircleFill size={16} />
                </Button>
            </Col>
        </Row>
    );
}
