import { Product } from '../../../../models/reimbursement.models';
import cx from 'classnames';
import { EditCounterMemo } from '../category/ProductEdits';
import { memo, MouseEvent } from 'react';
import { useGetReimbursementProduct } from '../../../../features/reimbursements/reimbursementHooks';
import { idToUri } from '../../../global/UriHelper';

export function SidebarProduct({
    product,
    selectedProductId,
    reimbursementId,
    productId,
    handleClick,
    hasUnsavedChanges,
}: {
    product: Product;
    selectedProductId: number;
    reimbursementId: number;
    productId: number;
    handleClick: (selectedProductId: number) => void;
    hasUnsavedChanges: boolean;
}) {
    const { reimbursementProduct } = useGetReimbursementProduct(
        product.category,
        idToUri(reimbursementId, 'Reimbursement'),
        idToUri(productId, 'Product'),
        product['@id']
    );

    return (
        <ProductNavMemo
            productId={product.id}
            productCode={product.code}
            productName={product.name}
            hasUnsavedChanges={hasUnsavedChanges}
            edits={reimbursementProduct?.edits ?? 0}
            isActive={product.id === selectedProductId}
            handleClick={handleClick}
        />
    );
}

function ProductNav({
    productId,
    productCode,
    productName,
    hasUnsavedChanges,
    edits,
    isActive,
    handleClick,
}: {
    productId: number;
    productCode: string;
    productName: string;
    hasUnsavedChanges: boolean;
    isActive: boolean;
    edits: number;
    handleClick: (selectedProductId: number) => void;
}) {
    return (
        <a
            className={cx('d-flex align-items-center nav-link', { active: isActive })}
            onClick={(event: MouseEvent) => {
                event.preventDefault();
                handleClick(productId);
            }}
        >
            <div className="text-secondary mr-2">{productCode}</div>
            <div>
                {productName}
                {hasUnsavedChanges && <sup>*</sup>}
            </div>
            {edits > 0 ? (
                <div className="ml-auto">
                    <EditCounterMemo unprocessedEdits={edits} size="sm" />
                </div>
            ) : null}
        </a>
    );
}

const ProductNavMemo = memo(ProductNav);
