import { useGetOrganisationUsers } from 'hooks/useGetOrganisationUsers';
import HelperFunctions from 'pages/global/HelperFunctions';

export function useGetAvailableUsers() {
    const users = useGetOrganisationUsers();

    return HelperFunctions.prepareDropdownData(
        users.filter((user) => user.enabled),
        'fullName',
    );
}
