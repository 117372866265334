import { Modal } from 'react-bootstrap';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { FormFieldWithTitle } from 'pages/publications_v2/helpers/FieldHelper';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { ModalFooter } from 'pages/global/FormModal';
import { useAddDepartmentMutation, useAddTeamMutation } from 'features/comments/commentApi';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';

export function AddDepartmentModal() {
    const [addDepartment, setAddDepartment] = useQueryParam('addDepartment', BooleanParam);
    const organisationId = useActiveOrganisation();

    if (!addDepartment) {
        return null;
    }

    return (
        <AddModalInner
            department={{
                organisationId,
                name: '',
            }}
            close={close}
        />
    );

    function close() {
        setAddDepartment(undefined);
    }
}

export function AddModalInner({ department, close }) {
    const { t } = useTranslation();
    const [addDepartment] = useAddDepartmentMutation();

    return (
        <>
            <Modal show={true} onHide={close}>
                <Modal.Header closeButton>
                    <Modal.Title>Afdeling toevoegen</Modal.Title>
                </Modal.Header>
                <Formik initialValues={department} onSubmit={handleSubmit} validationSchema={validationSchema}>
                    {({ isValid, isSubmitting, dirty }) => {
                        return (
                            <Form>
                                <Modal.Body>
                                    <FormFieldWithTitle
                                        label="Name"
                                        name="name"
                                        props={{ required: true }}
                                    />
                                </Modal.Body>

                                <ModalFooter
                                    onHide={close}
                                    isSubmitting={isSubmitting}
                                    dirty={dirty}
                                    isValid={isValid}
                                />
                            </Form>
                        );
                    }}
                </Formik>
            </Modal>
        </>
    );

    function handleSubmit(values) {
        addDepartment(values).then(() => {
            close();
        });
    }
}

const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    // surname: Yup.string().required(),
    // company: Yup.string().required(),
    // email: Yup.string().email('Invalid email').required(),
});
