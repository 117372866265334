import { useGetOrganisationUsersQuery } from '../features/security/authApi';
import { User } from '../models/auth.models';
import HelperFunctions from '../pages/global/HelperFunctions';
import { useActiveOrganisation } from './useActiveOrganisation';
import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';

export function useGetOrganisationUsers(activeOnly = false): User[] {
    const activeOrganisation = useActiveOrganisation();

    const selectUsers = useMemo(() => {
        const emptyArray: User[] = [];

        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (res: { data?: User[] }) => res.data,
            (data: User[] | undefined) => {
                const users = data ? [...data].sort(HelperFunctions.sortByString('fullName')) : emptyArray;

                if (activeOnly) {
                    return users.filter((user) => user.enabled);
                }

                return users;
            },
        );
    }, []);

    const { users } = useGetOrganisationUsersQuery(activeOrganisation, {
        selectFromResult: (result) => ({
            users: selectUsers(result),
        }),
    });

    return users;
}
