import { createContext, useState } from 'react';
import { slide as Slide } from 'react-burger-menu';
import { useDispatch, useSelector } from 'react-redux';
import { set } from 'features/app/appSlice';
import MetaSidebarContent from '../labels/MetaSidebarContent';
import OpEntryBlockVariantsSidebar from '../operations_list/OpEntryBlockVariantsSidebar';
import { SettingsSidebar } from 'components/SettingsSidebar/SettingsSidebar';

export const SlideContext = createContext({
    size: 'sm',
});

export default function Sidebar() {
    const { sidebarContext } = useSelector((state) => state.app);
    const dispatch = useDispatch();
    const [size, setSize] = useState('sm');

    const onStateChange = (newState) => {
        if (newState.isOpen === false) {
            dispatch(set({ key: 'sidebarContext', value: null }));
            setSize('sm');
        }
    };

    return (
        <SlideContext.Provider value={{ size, setSize }}>
            <Slide
                right
                disableAutoFocus
                width={size === 'xs' ? 370 : size === 'sm' ? 700 : size === 'lg' ? '80%' : 1200}
                isOpen={sidebarContext !== null}
                customBurgerIcon={false}
                onStateChange={onStateChange}
                styles={{
                    bmMenuWrap: {
                        transition: 'all 0.3s ease 0s',
                    },
                    bmOverlay: {
                        background: 'rgba(0, 0, 0, 0)',
                        zIndex: 1100,
                    },
                    bmCrossButton: { top: '1.4rem', right: '1.25rem' },
                    bmCross: { height: 18, width: 2 },
                }}
            >
                {sidebarContext !== null && <SidebarContent context={sidebarContext} />}
            </Slide>
        </SlideContext.Provider>
    );
}

function SidebarContent({ context }) {
    if (context?.type === 'meta') {
        return <MetaSidebarContent {...context} />;
    } else if (context?.type === 'opListBlockVariants') {
        return <OpEntryBlockVariantsSidebar {...context} />;
    } else if (context?.type === 'sidebarSettings') {
        return <SettingsSidebar />;
    }

    return <>Unsupported context type</>;
}
