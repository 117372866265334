import { StringParam, useQueryParam } from 'use-query-params';
import { Modal } from 'react-bootstrap';
import { IconButton, LightOutlineButton, PrimaryButton } from 'components/Buttons';
import { useGetCategoryOneLiners } from 'pages/documents_v2/views/edit/one_liners/hooks/useGetCategoryOneLiners';
import { useGetCategory } from 'pages/documents_v2/views/edit/one_liners/hooks/useGetCategory';
import { PaginatedTable, TableProvider } from 'components/PaginatedTable';
import { TableRowSkeleton } from 'components/Skeleton';
import { EmptyState, EmptyStateBody, EmptyStateHeader, EmptyStateIcon, RocketIcon } from 'components/EmptyState';
import { generatePath } from 'react-router-dom';
import { useGetUserFullName } from 'hooks/useGetUserFullName';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import Parser from 'html-react-parser';
import _ from 'lodash';
import { useGetDocument } from 'pages/documents_v2/hooks/useGetDocument';
import HelperFunctions from 'pages/global/HelperFunctions';
import { EDIT_AREA_PATH } from 'scenes/DocumentsV2';
import { ArrowRepeat, CloudDownload } from 'react-bootstrap-icons';
import { exportCategory, metaApi } from 'features/metadata/metadata';
import { saveAs } from 'file-saver';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

export function ViewOneLinersModal() {
    const [viewCategory, setViewCategory] = useQueryParam('viewCategory', StringParam);

    if (!viewCategory) {
        return null;
    }

    return <ViewTaskModalWrapper close={close} id={viewCategory} />;

    function close() {
        setViewCategory(undefined);
    }
}

function ViewTaskModalWrapper({ id, close }) {
    const { t } = useTranslation();
    const category = useGetCategory(id);
    const [isExporting, setIsExporting] = useState(false);
    const document = useGetDocument();
    const dispatch = useDispatch();

    return (
        <>
            <Modal className="modal-fullscreen" show={true} onHide={close} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="flex-center">
                            One-liners:
                            {category && <>&nbsp;{category.name}</>}
                            <IconButton
                                icon={<ArrowRepeat className="text-primary mr-0" />}
                                className="ml-2"
                                tooltip="Refresh"
                                onClick={() => {
                                    dispatch(metaApi.util.invalidateTags([{ type: 'Category', id: category['@id'] }]));
                                }}
                            />
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <OneLinersTable uri={category ? category['@id'] : undefined} />
                </Modal.Body>
                <Modal.Footer>
                    <LightOutlineButton disabled={!category || !document || isExporting} onClick={handleDownload}>
                        <CloudDownload />
                        <div>{t('global:btn.export')}</div>
                    </LightOutlineButton>

                    <PrimaryButton className="ml-2" onClick={close}>
                        Sluiten
                    </PrimaryButton>
                </Modal.Footer>
            </Modal>
        </>
    );

    function handleDownload() {
        setIsExporting(true);

        exportCategory(category.id, { ...document.properties.oneLiners })
            .then((response) => {
                saveAs(response.data, decodeURI(response.headers['x-suggested-filename'] ?? 'export.csv'));
            })
            .catch(async (error) => {
                if (error.response.status === 400) {
                    const response = await error.response.data.text();
                    const responseObject = HelperFunctions.tryParseJSON(response);
                    HelperFunctions.alertModal(t('global:error.errorTitle', { title: responseObject.error }));
                } else {
                    HelperFunctions.alertModal(t('global:error.exportFailed'));
                }
            })
            .finally(() => {
                setIsExporting(false);
            });
    }
}

function OneLinersTable({ uri }) {
    const { oneLiners = [], totalItems = 0, isEmpty = false, isLoading = true } = useGetCategoryOneLiners(uri);
    const document = useGetDocument(undefined, true);

    const loading = isLoading || !document;

    return (
        <TableProvider>
            <PaginatedTable
                items={oneLiners}
                totalItems={totalItems}
                clientSidePagination={false}
                itemsPerPage={15}
                renderSearch={false}
                colSpan={3}
            >
                {({ items = [], colSpan }) => (
                    <>
                        <thead>
                            <tr>
                                <th width="25%">Gemaakt door</th>
                                <th width="25%">Artikel</th>
                                <th width="50%">Content</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading && <TableRowSkeleton cellCount={colSpan} />}

                            {document && (
                                <>
                                    {isEmpty ? (
                                        <EmptyTable colSpan={colSpan} />
                                    ) : (
                                        <>
                                            {items.map((oneLiner) => (
                                                <OneLinerRow
                                                    oneLiner={oneLiner}
                                                    documentId={document.id}
                                                    area={HelperFunctions.findInDocument(
                                                        {
                                                            type: 'area',
                                                            id: oneLiner.areaId,
                                                        },
                                                        document,
                                                    )}
                                                    key={`oneLiner-${oneLiner.id}`}
                                                />
                                            ))}
                                        </>
                                    )}
                                </>
                            )}
                        </tbody>
                    </>
                )}
            </PaginatedTable>
        </TableProvider>
    );
}

function OneLinerRow({ oneLiner, area, documentId }) {
    const { t, i18n } = useTranslation();
    const createdBy = useGetUserFullName(oneLiner.createdBy, false);
    const createdAt = DateTime.fromISO(oneLiner.createdAt).setLocale(i18n.language).toLocaleString(DateTime.DATE_MED);

    return (
        <tr>
            <td>
                <div>{createdBy}</div>
                <div className="text-muted">{createdAt}</div>
            </td>
            <td>
                {area ? (
                    <>
                        <a
                            href={generatePath(EDIT_AREA_PATH, {
                                documentId,
                                view: 'editOneLiners',
                                areaId: area.id,
                            })}
                        >
                            {area.title}
                        </a>
                    </>
                ) : (
                    '-'
                )}
            </td>
            <td>
                <div className="tiny-mce-content-body one-liner-table-content-preview">
                    {_.isEmpty(oneLiner.content) ? '-' : Parser(oneLiner.content)}
                </div>
            </td>
        </tr>
    );
}

function EmptyTable({ colSpan }) {
    return (
        <tr>
            <td colSpan={colSpan}>
                <EmptyState>
                    <EmptyStateHeader
                        titleText={'Geen one-liners gevonden'}
                        icon={<EmptyStateIcon icon={RocketIcon} />}
                    />
                    <EmptyStateBody>Er zijn nog geen one-liners aan deze categorie toegewezen.</EmptyStateBody>
                </EmptyState>
            </td>
        </tr>
    );
}
