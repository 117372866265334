import MainContentNav from '../../Navbar';
import { useDispatch, useSelector } from 'react-redux';
import {
    getFiles,
    patchFile,
    selectFileById,
    selectFiles,
    selectFolderById,
    selectFolders,
    setSelectedFolder,
    updateFile,
} from '../../../features/mediaLibrary/mediaLibrarySlice';
import React, { useEffect, useState } from 'react';
import Dropzone from './Dropzone';
import { Col, Container, Row } from 'react-bootstrap';
import Sidebar from './Sidebar';
import _ from 'lodash';
import Folders, { FOLDER_TRASH } from './Folders';
import EditFolderModal from '../modals/EditFolderModal';
import Files, { FileDraggingContent } from './index/Files';
import { Route } from 'react-router-dom';
import Folder, { FolderHeader } from './index/Folder';
import { DndContext, DragOverlay, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import Droppable, { TYPE_DROPPABLE } from './Droppable';
import { Permissions } from '../../../config/Constants';
import RestrictedContent from '../../global/RestrictedContent';
import { useTranslation } from 'react-i18next';
import {FileModal} from "./index/FileModal";
import {useActiveOrganisation} from "../../../hooks/useActiveOrganisation";

export default function Index() {
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();
    const activeOrganisation = useActiveOrganisation()

    useEffect(() => {
        dispatch(getFiles({ organisationId: activeOrganisation }));
    }, [activeOrganisation]);

    return (
        <>
            <MainContentNav title="Media" />

            <div className="content-static-body">
                <IndexInner setShowModal={setShowModal} />
            </div>

            <EditFolderModal showModal={showModal} handleClose={() => setShowModal(false)} />
            <FileModal />
        </>
    );
}

function IndexInner({ setShowModal }) {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [activeId, setActiveId] = useState(null);
    const dispatch = useDispatch();

    const isDragging = activeId !== null;

    const toggleSelection = (uri) => {
        setSelectedFiles(_.xor(selectedFiles, [uri]));
    };

    const onDragStart = ({ active }) => {
        setActiveId(active.id);
    };

    const onDragEnd = (event) => {
        setActiveId(null);

        const { active, over } = event;
        const type = over?.data?.current?.type;

        if (over?.id === undefined || type !== TYPE_DROPPABLE) {
            return;
        }

        const fileUri = active.id;
        const folderUri = over.id;
        const formData = {
            folder: folderUri === 'null' ? null : folderUri,
        };

        // Quickly update state
        dispatch(
            updateFile({
                id: fileUri,
                changes: formData,
            })
        );

        // Update API
        dispatch(
            patchFile({
                uri: fileUri,
                formData,
            })
        );
    };

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10,
            },
        })
    );

    return (
        <>
            <div className="d-flex align-items-stretch h-100">
                <div className="content-sidebar overflow-auto">
                    <Folders setShowModal={setShowModal} />
                </div>
                <div
                    id="content"
                    className="overflow-auto pt-3 px-3"
                    onClick={() => {
                        if (selectedFiles.length > 0) {
                            setSelectedFiles([]);
                        }
                    }}
                >
                    <Container>
                        <div className="dr-container p-4">
                            <RestrictedContent permission={Permissions.Media['Write.All']}>
                                <Row>
                                    <Col>
                                        <div className="my-3">
                                            <Dropzone isDisabled={isDragging} />
                                        </div>
                                    </Col>
                                </Row>
                            </RestrictedContent>

                            <Route path="/media-library" exact>
                                <DndContext onDragStart={onDragStart} onDragEnd={onDragEnd} sensors={sensors}>
                                    <RootFiles
                                        toggleSelection={toggleSelection}
                                        selectedFiles={selectedFiles}
                                        setSelectedFiles={setSelectedFiles}
                                        isDragging={isDragging}
                                    />

                                    <DragOverlay>{activeId ? <FileOverlay activeId={activeId} /> : null}</DragOverlay>
                                </DndContext>
                            </Route>

                            <Route
                                path="/media-library/folder/:folder"
                                render={(routeProps) => (
                                    <>
                                        {routeProps.match.params.folder === FOLDER_TRASH ? (
                                            <Trash
                                                toggleSelection={toggleSelection}
                                                selectedFiles={selectedFiles}
                                                setSelectedFiles={setSelectedFiles}
                                            />
                                        ) : (
                                            <FolderFiles
                                                {...routeProps}
                                                toggleSelection={toggleSelection}
                                                selectedFiles={selectedFiles}
                                                setSelectedFiles={setSelectedFiles}
                                            />
                                        )}
                                    </>
                                )}
                            />
                        </div>
                    </Container>
                </div>

                <Sidebar setSelectedFiles={setSelectedFiles} selectedFiles={selectedFiles} />
            </div>
        </>
    );
}

function RootFiles({ toggleSelection, selectedFiles, setSelectedFiles, isDragging }) {
    const files = useSelector(selectFiles(null));
    const rootFolders = useSelector(selectFolders(null));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setSelectedFolder(null));
        setSelectedFiles([]);
    }, [dispatch]);

    return (
        <>
            {files.length > 0 && (
                <Droppable id="null" isDragging={isDragging}>
                    <Files
                        files={files}
                        toggleSelection={toggleSelection}
                        selectedFiles={selectedFiles}
                        setSelectedFiles={setSelectedFiles}
                    />
                </Droppable>
            )}

            {rootFolders.map((_folder) => (
                <Folder
                    folder={_folder}
                    toggleSelection={toggleSelection}
                    selectedFiles={selectedFiles}
                    setSelectedFiles={setSelectedFiles}
                    isDragging={isDragging}
                    key={`media-folder-index-${_folder.id}`}
                />
            ))}
        </>
    );
}

function FileOverlay({ activeId }) {
    const file = useSelector((state) => selectFileById(state.mediaLibrary, activeId));

    if (!file) {
        return null;
    }

    return <FileDraggingContent file={file} />;
}

function FolderFiles({ toggleSelection, selectedFiles, setSelectedFiles, match }) {
    const dispatch = useDispatch();
    const { params } = match;
    const folderId = parseInt(params.folder);
    const folderEntity = useSelector(selectFolderById(folderId));

    useEffect(() => {
        dispatch(setSelectedFolder(folderId));
        setSelectedFiles([]);
    }, [folderId]);

    if (folderEntity === undefined) {
        return null;
    }

    return (
        <>
            <FolderHeader name={folderEntity.name} />

            <FolderFilesInner
                toggleSelection={toggleSelection}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                folder={folderEntity['@id']}
            />
        </>
    );
}

function Trash({ toggleSelection, selectedFiles, setSelectedFiles }) {
    const dispatch = useDispatch();
    const folderId = FOLDER_TRASH;

    const { t } = useTranslation('global');

    useEffect(() => {
        dispatch(setSelectedFolder(folderId));
    }, [folderId]);

    return (
        <>
            <FolderHeader name={t('contentSidebar.trash')} />
            <FolderFilesInner
                toggleSelection={toggleSelection}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                folder={folderId}
            />
        </>
    );
}

function FolderFilesInner({ toggleSelection, selectedFiles, setSelectedFiles, folder }) {
    const files = useSelector(selectFiles(folder));
    const { t } = useTranslation('global');

    if (files.length === 0) {
        return <div className="text-muted small mt-3">{t('contentSidebar.map.noResults')}</div>;
    }

    return (
        <Files
            files={files}
            toggleSelection={toggleSelection}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
        />
    );
}
