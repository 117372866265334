import React from 'react';
import Modal from '../../global/Modal';
import PageTitle from '../../global/PageTitle';
import getEnv from '../../../config/Env';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

export default function SelectExportSegmentModal({ showModal, handleClose, payload }) {
    const { t } = useTranslation('changelist');

    if (!showModal) {
        return null;
    }

    const { token } = payload;

    return (
        <Modal opened={true} onRequestClose={handleClose} modalStyle="centeredAutoWidth">
            <div className="uk-padding-medium" style={{ width: 500 }}>
                <div className="uk-position-relative">
                    <PageTitle
                        pageTitle={t('changelist.export.exportSegment.selectSegment')}
                        marginTop="none"
                        marginBottom="none"
                    />
                    <p>
                        {t('changelist.export.exportSegment.largeAmountData', { totalSegments: token.totalSegments })}:
                    </p>
                </div>

                <div className="d-flex align-items-center justify-content-center">
                    {_.times(token.totalSegments, (index) => (
                        <a
                            className="uk-button uk-button-primary uk-margin-small-right uk-margin-small-left"
                            href={`${getEnv('PUBLICATION_API_URL')}/api/op_list_export_tokens/preview/${
                                token.token
                            }?dataSegment=${index + 1}`}
                            target="_blank"
                            data-uk-tooltip={t('changelist.view.view')}
                            key={`token-${token.id}-segment-${index}`}
                        >
                            {index + 1}
                        </a>
                    ))}
                </div>

                <p className="uk-text-muted mt-2">{t('changelist.export.exportSegment.viewingPdf')}</p>

                <div className="uk-clearfix uk-padding-top uk-text-right">
                    <button
                        className="uk-button uk-button-default uk-margin-small-right uk-margin-small-left"
                        onClick={handleClose}
                    >
                        {t('btn.close')}
                    </button>
                </div>
            </div>
        </Modal>
    );
}
