import { useMemo, useState } from 'react';
import Spinner from '../../../../pages/global/Spinner';
import CommentTags from './CommentTags';
import cx from 'classnames';
import { WarningButton } from '../../../Buttons';
import { Form } from 'react-bootstrap';
import { useAddCommentMutation } from 'features/comments/commentApi';
import { useTranslation } from 'react-i18next';
import { useCurrentOrganisation } from 'hooks/useCurrentOrganisation';
import { useUserProfile } from 'hooks/useUserProfile';

export function NewComment({ uri, parentUri, allowTags = true, requireTagInComment = false }) {
    const { t } = useTranslation('documents');
    const [addComment] = useAddCommentMutation();
    const currentOrganisation = useCurrentOrganisation();
    const userProfile = useUserProfile();
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [text, setText] = useState('');
    const [tags, setTags] = useState([]);

    return (
        <>
            <div>
                <div className="d-flex">
                    <div className="mr-4 ml-4 mt-2">
                        <NavBarAvatar />
                    </div>
                    <div className="w-100 text-primary">
                        <textarea
                            className="form-control"
                            rows={2}
                            id="text"
                            name="text"
                            maxLength={500}
                            placeholder={t('document.navbar.main.settingsSidebar.articles.comments.titleCreateSelect')}
                            value={text}
                            onChange={handleFormChange}
                        />
                    </div>
                </div>

                <div className="mt-3 ml-4">
                    {allowTags && (
                        <div
                            className={cx({
                                'tags-invalid': text.length > 0 && requireTagInComment && tags.length === 0,
                            })}
                            style={{ marginLeft: '59px' }}
                        >
                            <CommentTags tags={tags} setTags={setTags} />
                        </div>
                    )}
                </div>

                <div className="mt-3 d-flex justify-content-end">
                    <WarningButton
                        disabled={!formIsValid() || saveInProgress}
                        onClick={handleFormSubmit}
                    >
                        {t('btn.save')}
                    </WarningButton>
                    {saveInProgress && <Spinner />}
                </div>
                <Form.Label className="font-weight-bold mt-4 mb-0">
                    {t('document.navbar.main.settingsSidebar.articles.comments.titleComments')}
                </Form.Label>
            </div>
        </>
    );

    function handleFormChange(e) {
        setText(e.target.value);
    }

    function handleFormSubmit() {
        addComment({
            context: uri,
            parentContext: parentUri,
            text: text,
            tags: tags.map((tag) => ({
                uri: tag['@id'],
            })),
            organisationId: currentOrganisation.id,
        }).then(() => {
            setText('');
            setTags([]);
            setSaveInProgress(false);
        });
    }

    function formIsValid() {
        if (text === '') {
            return false;
        }

        if (requireTagInComment && tags.length === 0) {
            return false;
        }

        return true;
    }

    function NavBarAvatar() {
        const initialsString = useMemo(() => {
            const name = userProfile.firstName + ' ' + userProfile.lastName;
            const creatorName = name.split(' '); // split the name into an array of names
            const initials = creatorName.slice(0, 2).map((name) => name.charAt(0).toUpperCase()); // get the first two initials

            return initials.join(''); // join the initials into a string
        }, [userProfile]);

        return (
            <div className="avatar">
                <span className="dropdown-toggle">{initialsString}</span>
            </div>
        );
    }
}
