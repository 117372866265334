import React, { useEffect } from 'react';
import { FieldArray, Form as FForm, Formik } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import { LoadingSpinner } from 'pages/global/Spinner';
import { InputSelect } from 'pages/publications_v2/helpers/FieldHelper';
import Constants from '../../../../config/Constants';
import { XCircleFill } from 'react-bootstrap-icons';
import { useGetDocument } from '../../hooks/useGetDocument';
import { WarningButton } from 'components/Buttons';
import { useUpdateDocumentMutation } from 'features/documents/documents';
import { useTranslation } from 'react-i18next';
import { useGetOrganisationUsers } from 'hooks/useGetOrganisationUsers';
import { useGetOrganisationUser } from 'hooks/useGetOrganisationUser';
import { useUserProfile } from 'hooks/useUserProfile';

export default function Team() {
    const { t } = useTranslation('documents');

    return (
        <>
            <div className="dr-container p-4">
                <div className="subheader">
                    <h3>{t('document.navbar.settings.tabs.team.title')}</h3>
                </div>

                <div className="dr-instellingen-container">
                    <TeamForm />
                </div>
            </div>
        </>
    );
}

function TeamForm() {
    const { t } = useTranslation('documents');
    const document = useGetDocument();
    const [updateDocument] = useUpdateDocumentMutation();

    if (!document) {
        return null;
    }

    const handleSubmit = (values, { setSubmitting }) => {
        updateDocument({
            id: document.id,
            uri: `/documents/${document.id}/team`,
            body: values.documentUsers,
        }).then(() => {
            setSubmitting(false);
        });
    };

    return (
        <Formik
            initialValues={{
                documentUsers: document.documentUsers ?? [],
                newUser: '',
            }}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({ values, dirty, isSubmitting, isValid, setFieldValue }) => (
                <FForm autoComplete="off">
                    <div className="mb-4">
                        <FieldArray name="documentUsers">
                            {({ remove, push }) => (
                                <>
                                    <div className="mb-3 ">
                                        <label className="mb-2 font-weight-bolder label-title">
                                            {t('document.navbar.settings.tabs.team.user')}
                                        </label>
                                        <AddUser values={values} push={push} setFieldValue={setFieldValue} />
                                    </div>
                                    <Row className="align-items-center mb-3 text-color font-weight-bold small">
                                        <Col md={3} style={{ minWidth: 300 }}>
                                            {t('document.navbar.settings.tabs.team.name')}
                                        </Col>
                                        <Col md={6} className="d-flex">
                                            {t('document.navbar.settings.tabs.team.role')}
                                        </Col>
                                    </Row>

                                    {values.documentUsers.length > 0 &&
                                        values.documentUsers.map((_documentUser, index) => (
                                            <DocumentUser
                                                documentUser={_documentUser}
                                                remove={() => remove(index)}
                                                index={index}
                                                key={index}
                                            />
                                        ))}
                                </>
                            )}
                        </FieldArray>
                    </div>

                    <WarningButton type="submit" disabled={isSubmitting || !dirty || !isValid}>
                        {t('btn.save')}
                    </WarningButton>
                    <LoadingSpinner isSubmitting={isSubmitting} />
                </FForm>
            )}
        </Formik>
    );
}

function AddUser({ values, push, setFieldValue }) {
    const { newUser } = values;
    const users = useGetOrganisationUsers(true);
    const { t } = useTranslation('documents');

    const availableUsers = users
        .filter((_user) => values.documentUsers.some((_documentUser) => _documentUser.userId === _user.id) === false)
        .map((_user) => {
            return {
                label: _user.fullName,
                value: _user.id,
            };
        });

    useEffect(() => {
        if (newUser !== '') {
            push({
                userId: newUser,
                role: Constants.userDocumentRole.editor,
            });
            setFieldValue('newUser', '');
        }
    }, [newUser]);

    return (
        <InputSelect
            name="newUser"
            options={availableUsers}
            props={{
                placeholder: t('document.navbar.settings.tabs.team.addSelect'),
            }}
        />
    );
}

function DocumentUser({ documentUser, remove, index }) {
    const userProfile = useUserProfile();
    const { t } = useTranslation('documents');
    const user = useGetOrganisationUser(documentUser.userId);

    // If user is undefined it's probably deleted or inactive
    if (user === undefined || user.enabled === false) {
        return null;
    }

    const { jobDescription, fullName } = user;
    const roles = [
        {
            label: t('document.navbar.settings.tabs.team.roles.manager'),
            value: Constants.userDocumentRole.documentManager,
        },
        {
            label: t('document.navbar.settings.tabs.team.roles.finalEditor'),
            value: Constants.userDocumentRole.finalEditor,
        },
        {
            label: t('document.navbar.settings.tabs.team.roles.editor'),
            value: Constants.userDocumentRole.editor,
        },
        {
            label: t('document.navbar.settings.tabs.team.roles.spectator'),
            value: Constants.userDocumentRole.spectator,
        },
    ];
    const isRemoveDisabled = documentUser.userId === userProfile.userId;

    return (
        <Row className="align-items-center mb-2">
            <Col md={3}>
                <div style={{ minWidth: 300 }}>
                    <div>{fullName}</div>
                    {jobDescription && <div className="text-muted small">{jobDescription}</div>}
                </div>
            </Col>
            <Col md={6} className="d-flex">
                <InputSelect name={`documentUsers.${index}.role`} options={roles} />
                <Button
                    variant="icon"
                    size="sm"
                    className="btn-icon-only text-danger ml-1"
                    data-uk-tooltip={isRemoveDisabled ? '' : t('document.navbar.settings.tabs.team.deleteBadge')}
                    onClick={remove}
                    disabled={isRemoveDisabled}
                >
                    <XCircleFill size={16} />
                </Button>
            </Col>
        </Row>
    );
}
