import { Product, ProductLink, ProductTeam } from '../../../../models/reimbursement.models';
import {
    reimbursementApi,
    useUpdateBatchReimbursementAcceptChangesMutation,
    useUpdateProductEditsMutation,
    useUpdateReimbursementMutation,
} from '../../../../features/reimbursements/reimbursements';
import { Button, Dropdown, SplitButton } from 'react-bootstrap';
import React, { useContext } from 'react';
import Constants from '../../../../config/Constants';
import CommentButton from '../../../comments/CommentButton';
import RestrictedContent from '../../../global/RestrictedContent';
import { Link45deg, PencilSquare } from 'react-bootstrap-icons';
import ProductHelper from '../../../global/ProductHelper';
import cx from 'classnames';
import { ProductEditsMemo } from './ProductEdits';
import { generatePath, useHistory } from 'react-router-dom';
import { EDIT_REIMBURSEMENT_PRODUCT_PATH } from '../../../../scenes/Reimbursements';
import _ from 'lodash';
import { ProductContext } from './ReimbursementProducts';
import { QuickCoverage } from './QuickCoverage';
import { idToUri } from '../../../global/UriHelper';
import RestrictedReimbursementContent from '../../RestrictedReimbursementContent';
import { canAcceptAllChangesRoles } from '../../config/permissions';
import { TeamAvatars } from './TeamAvatars';
import { IconButton } from '../../../../components/Buttons';
import { useTranslation } from 'react-i18next';

export default function ReimbursementProduct({
    productId,
    parentProductId,
    categoryId,
    reimbursementId,
    disabledProducts,
    setEditProductTeamModal,
    productTeams,
}: {
    productId: number;
    parentProductId: number;
    categoryId: number;
    reimbursementId: number;
    disabledProducts: string[];
    setEditProductTeamModal: () => void;
    productTeams: ProductTeam[];
}) {
    const history = useHistory();
    const [updateReimbursement] = useUpdateReimbursementMutation();
    const [updateBatchReimbursementAcceptChanges] = useUpdateBatchReimbursementAcceptChangesMutation();
    const [updateProductEdits] = useUpdateProductEditsMutation();
    const { selectedProducts, toggleProduct } = useContext(ProductContext);
    const { t } = useTranslation('reimbursements');

    const { product }: { product: Product } = reimbursementApi.endpoints.getProducts.useQueryState(categoryId, {
        selectFromResult: ({ data }) => ({
            product: data?.find((product: Product) => product.id === productId) ?? undefined,
        }),
    });

    const toggleEnabled = () => {
        updateReimbursement({
            uri: `/api/reimbursement-api/api/reimbursements/${reimbursementId}`,
            category: product.category,
            disabledProducts: _.xor(disabledProducts, [product['@id']]),
        });
    };

    const acceptChanges = (selectedProducts: string[]) => {
        updateBatchReimbursementAcceptChanges({
            uri: idToUri(reimbursementId, 'Reimbursement'),
            product: product['@id'],
            selectedProducts,
        });
    };

    const resetCounter = () => {
        updateProductEdits({
            reimbursementId,
            productId: product.id,
        });
    };

    const isDisabled = disabledProducts.includes(product['@id']) ?? false;
    const isLinkedProduct = ProductHelper.isLinkedProduct(product);
    const extendedProductUris = isLinkedProduct
        ? product.extendedProducts.map((productLink: ProductLink) => productLink.baseProduct)
        : [product['@id']];

    const { selectedProductUris }: { selectedProductUris: string[] } =
        reimbursementApi.endpoints.getProducts.useQueryState(categoryId, {
            selectFromResult: ({ data }) => {
                if (extendedProductUris.length === 1) {
                    return {
                        selectedProductUris: extendedProductUris,
                    };
                }

                return {
                    selectedProductUris:
                        data
                            ?.filter((product: Product) => extendedProductUris.includes(product['@id']))
                            .map((product: Product) => product['@id']) ?? [],
                };
            },
        });

    const productTeam =
        productTeams.find((_productTeam) => _productTeam.productId === productId) ??
        ({
            productId,
            teams: [],
        } satisfies ProductTeam);

    return (
        <tr>
            <td className="align-middle">
                <div className="d-flex align-items-center">
                    <RestrictedReimbursementContent
                        categoryId={categoryId}
                        roles={[
                            Constants.reimbursementTeamRoles.finalEditor,
                            Constants.reimbursementTeamRoles.manager,
                            Constants.reimbursementTeamRoles.admin,
                        ]}
                    >
                        <input
                            type="checkbox"
                            id={`product-check-${productId}`}
                            checked={selectedProducts.includes(product['@id'])}
                            onChange={() => {
                                if (toggleProduct) {
                                    toggleProduct(product['@id']);
                                }
                            }}
                            disabled={product.identicalTo !== null || isDisabled}
                            className="mt-1 mr-3"
                        />
                    </RestrictedReimbursementContent>

                    <RestrictedContent module={Constants.modules.comments}>
                        <CommentButton
                            entity={product}
                            entityType="reimbursementProduct"
                            uri={`/api/reimbursements/${reimbursementId}/products/${productId}`}
                            parentUri={`/api/reimbursements/${reimbursementId}`}
                        />
                    </RestrictedContent>
                </div>
            </td>
            <td className={cx('align-middle', { 'text-muted': isDisabled })}>{product.code}</td>
            <td className={cx('align-middle', { 'text-muted': isDisabled })}>
                <div className="d-flex align-items-center">
                    {isLinkedProduct && (
                        <Link45deg data-uk-tooltip={t('reimbursement.product.misc.supplemental')} className="mr-1" />
                    )}
                    <div>
                        {product.name}
                        {isDisabled && <> ({t('reimbursement.product.misc.inactive')})</>}
                    </div>
                </div>
            </td>
            <td className={cx('align-middle', { 'text-muted': isDisabled })}>
                <div className="d-flex align-items-center">
                    {productTeam.teams.length > 0 && (
                        <div className="mr-2">
                            <TeamAvatars teams={productTeam.teams} />
                        </div>
                    )}

                    <RestrictedReimbursementContent
                        categoryId={categoryId}
                        roles={[Constants.reimbursementTeamRoles.admin, Constants.reimbursementTeamRoles.manager]}
                    >
                        <IconButton
                            className="flex-shrink-0"
                            onClick={() => setEditProductTeamModal()}
                            icon={<PencilSquare className="text-secondary" size={12} />}
                        />
                    </RestrictedReimbursementContent>
                </div>
            </td>
            <td className="align-middle">
                {product.identicalTo && (
                    <IdenticalProduct
                        productUri={product.identicalTo}
                        categoryId={categoryId}
                        isDisabled={isDisabled}
                    />
                )}

                {!isDisabled && (
                    <>
                        {product.identicalTo === null && (
                            <QuickCoverage
                                reimbursementId={reimbursementId}
                                categoryId={categoryId}
                                productUri={product['@id']}
                                selectedProductUris={selectedProductUris}
                            />
                        )}
                    </>
                )}
            </td>
            <td align="right">
                <div className="d-flex align-items-center justify-content-end">
                    <RestrictedReimbursementContent
                        categoryId={categoryId}
                        roles={[
                            Constants.reimbursementTeamRoles.finalEditor,
                            Constants.reimbursementTeamRoles.manager,
                            Constants.reimbursementTeamRoles.admin,
                        ]}
                    >
                        {isDisabled ? (
                            <Button variant="success" size="sm" onClick={() => toggleEnabled()}>
                                {t('reimbursement.product.dropdown.activate')}
                            </Button>
                        ) : (
                            <>
                                <ProductEditsMemo
                                    reimbursementId={reimbursementId}
                                    product={product}
                                    categoryId={categoryId}
                                />

                                <SplitButton
                                    id={`btn-edit-${productId}`}
                                    className="ml-3"
                                    variant="primary"
                                    size="sm"
                                    disabled={product.identicalTo !== null}
                                    title={t('reimbursement.product.dropdown.open')}
                                    onClick={() => {
                                        history.push(
                                            generatePath(EDIT_REIMBURSEMENT_PRODUCT_PATH, {
                                                categoryId,
                                                reimbursementId,
                                                parentProductId,
                                                productId,
                                                selectedProductId: _.last(selectedProductUris[0].split('/')) ?? '',
                                            })
                                        );
                                    }}
                                    alignRight={true}
                                >
                                    {product.identicalTo === null && (
                                        <>
                                            <RestrictedReimbursementContent
                                                categoryId={categoryId}
                                                roles={canAcceptAllChangesRoles}
                                            >
                                                <Dropdown.Item onClick={() => acceptChanges(selectedProductUris)}>
                                                    {t('reimbursement.product.dropdown.approve')}
                                                </Dropdown.Item>
                                            </RestrictedReimbursementContent>
                                            <Dropdown.Item onClick={() => resetCounter()}>
                                                {t('reimbursement.product.dropdown.resetCounter')}
                                            </Dropdown.Item>

                                            <RestrictedReimbursementContent
                                                categoryId={categoryId}
                                                roles={[
                                                    Constants.reimbursementTeamRoles.admin,
                                                    Constants.reimbursementTeamRoles.manager,
                                                ]}
                                            >
                                                <Dropdown.Item onClick={() => setEditProductTeamModal()}>
                                                    {t('reimbursement.product.dropdown.editDepartment')}
                                                </Dropdown.Item>
                                            </RestrictedReimbursementContent>
                                        </>
                                    )}
                                    <Dropdown.Item onClick={() => toggleEnabled()}>
                                        {t('reimbursement.product.dropdown.deactivate')}
                                    </Dropdown.Item>
                                </SplitButton>
                            </>
                        )}
                    </RestrictedReimbursementContent>

                    <RestrictedReimbursementContent
                        categoryId={categoryId}
                        roles={[Constants.reimbursementTeamRoles.spectator, Constants.reimbursementTeamRoles.editor]}
                    >
                        <ProductEditsMemo reimbursementId={reimbursementId} product={product} categoryId={categoryId} />

                        <Button
                            className="d-flex align-items-center ml-3"
                            size="sm"
                            disabled={product.identicalTo !== null || isDisabled}
                            onClick={() => {
                                history.push(
                                    generatePath(EDIT_REIMBURSEMENT_PRODUCT_PATH, {
                                        categoryId,
                                        reimbursementId,
                                        parentProductId,
                                        productId,
                                        selectedProductId: _.last(selectedProductUris[0].split('/')) ?? '',
                                    })
                                );
                            }}
                        >
                            <PencilSquare className="mr-1" />
                            {t('reimbursement.product.dropdown.open')}
                        </Button>
                    </RestrictedReimbursementContent>
                </div>
            </td>
        </tr>
    );
}

function IdenticalProduct({
    productUri,
    categoryId,
    isDisabled,
}: {
    productUri: string;
    categoryId: number;
    isDisabled: boolean;
}) {
    const { product }: { product: Product | undefined } = reimbursementApi.endpoints.getProducts.useQueryState(
        categoryId,
        {
            selectFromResult: ({ data }) => ({
                product: data?.find((_item: Product) => _item['@id'] === productUri) ?? undefined,
            }),
        }
    );
    const { t } = useTranslation('reimbursements');

    return (
        <span
            className={cx('small text-secondary font-italic', {
                'text-muted': isDisabled,
            })}
        >
            {t('reimbursement.product.misc.identical', {
                identicalCode: product?.code,
                identicalName: product?.name,
            })}
        </span>
    );
}
