import { Form } from 'react-bootstrap';
import { InputSelect } from '../../../publications_v2/helpers/FieldHelper';
import HelperFunctions from '../../../global/HelperFunctions';
import { useGetLabelFoldersQuery } from '../../../../features/metadata/metadata';
import { useAppSelector } from '../../../../store';
import { LabelFolder } from '../../../../models/labels.models';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const allowedTypes = ['reimbursements', 'reimbursements_filter'];

export default function GroupByLabelSelect() {
    const { activeOrganisation } = useAppSelector((state) => state.security);
    const { t } = useTranslation('reimbursements');

    const { labelFolders }: { labelFolders: LabelFolder[] } = useGetLabelFoldersQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                labelFolders: data
                    ? data.filter((folder: LabelFolder) => _.intersection(folder.contentTypes, allowedTypes).length > 0)
                    : [],
            }),
        }
    );

    return (
        <Form.Group>
            <Form.Label htmlFor="groupByLabel">{t('view.generateSummary.group')}</Form.Label>

            <InputSelect
                name="groupByLabel"
                options={HelperFunctions.prepareDropdownData(labelFolders, 'name', '@id')}
                props={{
                    placeholder: `${t('view.generateSummary.titleSelect')}...`,
                    isDisabled: labelFolders.length === 0,
                    isClearable: true,
                }}
            />
        </Form.Group>
    );
}
