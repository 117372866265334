import { Pencil } from 'react-bootstrap-icons';
import { Product } from '../../../../models/reimbursement.models';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetReimbursement } from '../../hooks/useGetReimbursement';
import { useUpdateReimbursementEditsMutation } from '../../../../features/reimbursements/reimbursements';

function ProductEdits({
    categoryId,
    reimbursementId,
    product,
}: {
    categoryId: number;
    reimbursementId: number;
    product: Product;
}) {
    const reimbursement = useGetReimbursement(reimbursementId);

    if (reimbursement === undefined) {
        return null;
    }

    let unprocessedEdits = 0;

    if (product.productGroup) {
        product.children.forEach((childProduct) => {
            const productEdit = reimbursement.productEdits.find(
                (productEdit: any) => productEdit.productId === childProduct.id,
            );

            if (productEdit && productEdit.unprocessedEdits > 0) {
                unprocessedEdits += productEdit.unprocessedEdits;
            }
        });
    } else {
        const productEdit = reimbursement.productEdits.find((productEdit: any) => productEdit.productId === product.id);

        if (productEdit) {
            unprocessedEdits = productEdit.unprocessedEdits;
        }
    }

    if (unprocessedEdits === 0) {
        return null;
    }

    return <EditCounterMemo unprocessedEdits={unprocessedEdits} reimbursementId={reimbursementId} />;
}

function EditCounter({
    unprocessedEdits,
    size,
    reimbursementId,
}: {
    unprocessedEdits: number;
    size?: string;
    reimbursementId?: number;
}) {
    const { t } = useTranslation('reimbursements');
    const [updateReimbursementEdits] = useUpdateReimbursementEditsMutation();

    if (unprocessedEdits <= 0) {
        return null;
    }

    const fontSize = size === 'sm' ? 12 : 14;

    return (
        <div
            data-uk-tooltip={t('etc.numberUprocessed')}
            className="d-flex align-items-center flex-shrink-0 cursor-pointer text-danger font-weight-normal"
            onClick={(event) => {
                event.stopPropagation();

                // Reset counter
                if (reimbursementId) {
                    updateReimbursementEdits({ reimbursementId });
                }
            }}
            style={{
                fontSize,
            }}
        >
            <Pencil size={fontSize} className={size === 'sm' ? 'mr-1' : 'mr-2'} />
            {unprocessedEdits}
        </div>
    );
}

export const ProductEditsMemo = memo(ProductEdits);
export const EditCounterMemo = memo(EditCounter);
