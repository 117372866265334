import {
    useAddTranslationMutation,
    useUpdateTranslationMutation,
} from 'features/translations/translationApi';
import { Form, Formik } from 'formik';
import { Button } from 'react-bootstrap';
import { LoadingSpinner } from 'pages/global/Spinner';
import { HtmlInputField, TextInputField } from '../../forms/FieldHelper';
import _ from 'lodash';
import { EditTableTranslationContent } from './document/blocks/TableBlock';
import HelperFunctions from '../../../global/HelperFunctions';
import { useSelector } from 'react-redux';
import Constants from '../../../../config/Constants';
import RestrictedTranslationContent from '../../RestrictedTranslationContent';
import React, { useState } from 'react';
import { entityTypes, translationContainerTypes } from '../../config/Constants';
import Modal from '../../../global/Modal';
import TagList from '../../../documents/misc/_ReviewDocument/TagList';
import { WarningButton } from 'components/Buttons';
import { useTranslation } from 'react-i18next';
import { EditFileTranslationContent } from 'pages/translation/views/translate/document/blocks/FileBlock';

export default function EditTranslation({
    translation = {},
    close,
    languageIso,
    translationKey,
    isHtml = false,
    handleTranslationMutation,
    content,
    translationContainer,
}) {
    const [addTranslation] = useAddTranslationMutation();
    const [updateTranslation] = useUpdateTranslationMutation();
    const { unverified = true } = translation;
    const { variantId } = useSelector((state) => state.translation.sidebarFilters);
    const { t } = useTranslation('translations');

    let translationValue = translation?.translation ?? '';
    const { entityType, property, translationPerVariant = false } = translationKey;

    if (entityType === 'block' && property === 'data') {
        translationValue = HelperFunctions.tryParseJSON(translationValue, {
            title: '',
            data: [],
        });
    }

    return (
        <Formik
            initialValues={{
                translation: translationValue,
            }}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
                let translationValue = values.translation;

                if (_.isObject(translationValue)) {
                    translationValue = JSON.stringify(translationValue);
                }

                // Process cross-references, replacing '=' with '&#61;'
                translationValue = translationValue.replaceAll(/\[(Ref )(.*?)]/gm, (p1) => p1.replace(/=/g, '&#61;'));

                if (translation.hasOwnProperty('id')) {
                    // Update
                    updateTranslation({
                        uri: translation['@id'],
                        body: {
                            translation: translationValue,
                            contentSource: content,
                            contentHashChanged: false,
                        },
                    }).then(({ data }) => {
                        setSubmitting(false);

                        if (_.isFunction(handleTranslationMutation)) {
                            handleTranslationMutation(data);
                        }
                    });
                } else {
                    // Create
                    addTranslation({
                        translation: translationValue,
                        contentSource: content,
                        languageIso,
                        translationKey: translationKey['@id'],
                        documentVariantId: translationPerVariant ? variantId : undefined,
                    }).then(({ data }) => {
                        setSubmitting(false);

                        if (_.isFunction(handleTranslationMutation)) {
                            handleTranslationMutation(data);
                        }
                    });
                }
            }}
        >
            {({ dirty, isSubmitting }) => (
                <Form autoComplete="off">
                    <EditTranslationContent
                        isHtml={isHtml}
                        translationKey={translationKey}
                        content={content}
                        translationContainer={translationContainer}
                    />

                    <div className="mt-3">
                        <WarningButton type="submit" disabled={!dirty} className="mr-2">
                            {t('btn.save')}
                        </WarningButton>

                        <RestrictedTranslationContent
                            id={translationContainer.id}
                            roles={[
                                Constants.translationContainerTeamRoles.manager,
                                Constants.translationContainerTeamRoles.finalEditor,
                            ]}
                        >
                            <Button
                                type="submit"
                                variant={unverified ? 'success' : 'danger'}
                                disabled={!translation.hasOwnProperty('id') || isSubmitting}
                                onClick={(event) => {
                                    event.preventDefault();

                                    updateTranslation({
                                        uri: translation['@id'],
                                        body: {
                                            unverified: !unverified,
                                            contentHashChanged: false,
                                        },
                                    }).then(({ data }) => {
                                        if (_.isFunction(handleTranslationMutation)) {
                                            handleTranslationMutation(data);
                                        }

                                        close();
                                    });
                                }}
                            >
                                {unverified
                                    ? t('translation.navbar.dashboard.translation.btn.approve')
                                    : t('translation.navbar.dashboard.translation.btn.reject')}
                            </Button>
                        </RestrictedTranslationContent>

                        <Button variant="link" onClick={close}>
                            {t('btn.close')}
                        </Button>

                        <LoadingSpinner isSubmitting={isSubmitting} />
                    </div>
                </Form>
            )}
        </Formik>
    );
}

function EditTranslationContent({ isHtml, translationKey, content, translationContainer }) {
    const { entityType, property } = translationKey;

    if (entityType === entityTypes.BLOCK && property === 'data') {
        return (
            <EditTableTranslationContent
                translationKey={translationKey}
                content={content}
                translationContainer={translationContainer}
            />
        );
    }

    if (entityType === entityTypes.BLOCK && property === 'uri') {
        return <EditFileTranslationContent content={content} />;
    }

    return (
        <>
            {isHtml ? (
                <HtmlInputField
                    name="translation"
                    tags={[...content.matchAll(/\[(Tag |)(.*?)]/gm)].map((_match) => _match[2])}
                />
            ) : (
                <div className="d-flex align-items-center">
                    <TextInputField name="translation" />

                    {translationContainer['@type'] === translationContainerTypes.DOCUMENT && (
                        <InsertTagModal documentId={translationContainer.documentId} />
                    )}
                </div>
            )}
        </>
    );
}

function InsertTagModal({ documentId }) {
    const [showTagModal, setShowTagModal] = useState(false);
    const { t } = useTranslation('translations');

    return (
        <>
            <div className="uk-link ml-2" style={{ minWidth: 90 }} onClick={() => setShowTagModal(true)}>
                {t('translation.navbar.dashboard.translation.insertTag')}
            </div>

            {showTagModal && (
                <Modal modalStyle="centeredAutoWidth" opened={true}>
                    <TagList close={() => setShowTagModal(false)} documentId={documentId} />
                </Modal>
            )}
        </>
    );
}
