import { useGetAdminAccountsQuery } from '../../../features/security/authApi';

export function useGetAccounts() {
    const { accounts } = useGetAdminAccountsQuery(undefined, {
        selectFromResult: ({ data }) => ({
            accounts: data ?? [],
        }),
    });

    return accounts;
}
