import { useGetTaskVersion } from 'pages/task_team/hooks/useGetTaskVersion';
import { useGetTaskVersionAttachments } from 'pages/task_team/hooks/useGetTaskVersionAttachments';
import { fileType } from 'pages/task_team/config/constants';
import { TaskFile } from 'pages/task_team/modals/view_task_modal/PreviewFile';
import { useGetOrganisationUsers } from 'hooks/useGetOrganisationUsers';
import { Badge } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { LightOutlineButton, LinkButton, WarningButton } from 'components/Buttons';
import { useUpdateTaskVersionMutation } from 'features/comments/commentApi';
import { Form as FForm, Formik } from 'formik';
import Spinner from 'pages/global/Spinner';
import * as Yup from 'yup';
import { MultipleSelect } from 'pages/task_team/helpers/FormHelper';
import { TaskFileAttachments } from 'pages/task_team/views/checks/TaskFileAttachments';
import { Dropzone } from 'pages/task_team/helpers/Dropzone';
import { useGetTaskUsers } from 'pages/task_team/hooks/useGetTaskUsers';
import { useIsTaskOwner } from 'pages/task_team/hooks/permissions';

export function TaskVersionEvidence({ versionUri, task, canEdit = false }) {
    const [editEvidence, setEditEvidence] = useState(false);
    const version = useGetTaskVersion(versionUri);
    const isTaskOwner = useIsTaskOwner(task);

    useEffect(() => {
        setEditEvidence(false);
    }, [versionUri]);

    if (!version) {
        return null;
    }

    if (editEvidence) {
        return <EditEvidence version={version} task={task} close={() => setEditEvidence(false)} />;
    }

    return (
        <div className="card oke-card mb-4">
            <div className="card-header">Bewijs van afstemming</div>

            <div className="card-body">
                <ViewEvidence version={version} />
            </div>

            {isTaskOwner && (
                <div className="card-footer d-flex flex-row-reverse">
                    <LightOutlineButton disabled={!canEdit} onClick={() => setEditEvidence(true)}>
                        Bewijs bewerken
                    </LightOutlineButton>
                </div>
            )}
        </div>
    );
}

function ViewEvidence({ version }) {
    const attachments = useGetTaskVersionAttachments(version['@id']);
    const files = attachments.filter((attachment) => attachment.type === fileType.TYPE_EVIDENCE);

    return (
        <>
            <div className="mb-3">
                <div className="font-weight-bold small mb-2">Afgestemd met</div>
                <PreApprovedBy preApprovedBy={version.preApprovedBy} />
            </div>

            {files.length > 0 && (
                <div>
                    <div className="font-weight-bold small mb-2">Bestand(en)</div>

                    {files.map((file) => (
                        <div className="mb-3" key={`file-${file.id}`}>
                            <TaskFile file={file} />
                        </div>
                    ))}
                </div>
            )}
        </>
    );
}

function PreApprovedBy({ preApprovedBy = [] }) {
    const organisationUsers = useGetOrganisationUsers();
    const approvedByUsers = organisationUsers.filter((user) => preApprovedBy.includes(user.id));

    if (approvedByUsers.length === 0) {
        return <>-</>;
    }

    return (
        <>
            {approvedByUsers.map((user, index) => (
                <Badge variant="primary" className="mr-1 mb-1" key={`user-${index}-${user.id}`}>
                    {user.fullName}
                </Badge>
            ))}
        </>
    );
}

function EditEvidence({ version, task, close }) {
    const [updateTaskVersion] = useUpdateTaskVersionMutation();
    const taskUsers = useGetTaskUsers('pre-approved');
    const attachments = useGetTaskVersionAttachments(version['@id']);
    const files = attachments.filter((attachment) => attachment.type === fileType.TYPE_EVIDENCE);

    const userOptions = taskUsers.map((taskUser) => ({
        ...taskUser,
        name: taskUser.fullName,
        '@id': taskUser.userId,
    }));

    return (
        <Formik
            initialValues={{
                preApprovedBy: version.preApprovedBy,
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            {({ isValid, isSubmitting, dirty }) => {
                return (
                    <FForm>
                        <div className="card oke-card oke-card-active mb-4">
                            <div className="card-header">Bewijs van afstemming</div>

                            <div className="card-body">
                                <div className="sidebar-form">
                                    <div className="mb-3">
                                        <div className="font-weight-bold small mb-2">Afgestemd met</div>
                                        <MultipleSelect options={userOptions} name="preApprovedBy" />
                                    </div>

                                    <div className="mb-3">
                                        <div className="font-weight-bold small mb-2">Bestand(en)</div>
                                        <Dropzone task={task} taskVersionUri={version['@id']} type="evidence" />
                                    </div>

                                    <TaskFileAttachments files={files} canDelete={true} />
                                </div>
                            </div>

                            <div className="card-footer d-flex align-items-center justify-content-end">
                                {isSubmitting && <Spinner />}

                                <LinkButton disabled={isSubmitting} onClick={close}>
                                    Annuleer
                                </LinkButton>

                                <WarningButton
                                    disabled={isSubmitting || !isValid || !dirty}
                                    className="ml-2"
                                    type="submit"
                                >
                                    Opslaan
                                </WarningButton>
                            </div>
                        </div>
                    </FForm>
                );
            }}
        </Formik>
    );

    function handleSubmit(values) {
        updateTaskVersion({ uri: version['@id'], body: values }).then(() => {
            close();
        });
    }
}

const validationSchema = Yup.object().shape({
    preApprovedBy: Yup.array(),
});
