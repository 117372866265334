import { Permissions } from 'config/Constants';
import RestrictedContent from 'pages/global/RestrictedContent';
import { CurrentPlan } from 'pages/account/views/billing/CurrentPlan';
import { useParams } from 'react-router-dom';
import { Seats } from 'pages/account/views/billing/Seats';

export const STATUS_ACTIVE = 'active';
export const STATUS_CANCELED = 'canceled';
export const STATUS_INCOMPLETE = 'incomplete';
export const STATUS_INCOMPLETE_EXPIRED = 'incomplete_expired';
export const STATUS_PAST_DUE = 'past_due';
export const STATUS_PAUSED = 'paused';
export const STATUS_TRIALING = 'trialing';
export const STATUS_UNPAID = 'unpaid';

export function Billing() {
    const { action = 'index' } = useParams();
    const ViewToRender = RenderViews[action] ?? DefaultView;

    return (
        <RestrictedContent permission={Permissions.Account['Billing.Manage']}>
            <ViewToRender />
        </RestrictedContent>
    );
}

function DefaultView() {
    return <div>no view</div>;
}

const RenderViews = {
    index: CurrentPlan,
    seats: Seats,
};
