import { useGetTeamUsersQuery } from 'features/comments/commentApi';
import { useGetOrganisationUsers } from 'hooks/useGetOrganisationUsers';
import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import HelperFunctions from 'pages/global/HelperFunctions';

export function useGetTeamUsers(id) {
    const organisationUsers = useGetOrganisationUsers();

    const selectTeamUsers = useMemo(() => {
        const emptyArray = [];

        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (res) => res.data,
            (res, organisationUsers) => organisationUsers,
            (data, organisationUsers) => {
                if (data) {
                    return data
                        .reduce((acc, currentValue) => {
                            const user = organisationUsers.find((_user) => _user.id === currentValue.userId);

                            if (user) {
                                acc.push({
                                    ...currentValue,
                                    user,
                                    fullName: user.fullName,
                                });
                            }

                            return acc;
                        }, [])
                        .sort(HelperFunctions.sortByString('fullName'));
                }

                return emptyArray;
            },
        );
    }, []);

    const { teamUsers } = useGetTeamUsersQuery(id, {
        selectFromResult: (result) => ({
            teamUsers: selectTeamUsers(result, organisationUsers),
        }),
        skip: id === undefined,
    });

    return teamUsers;
}
