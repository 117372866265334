import { useAddTemplateMutation } from '../../../../../features/templates/templatesApi';
import { useGetBaseVariant } from '../../../hooks/useGetBaseVariant';
import { SuccessButton } from '../../../../../components/Buttons';
import { useParams } from 'react-router-dom';
import { useCurrentOrganisation } from '../../../../../hooks/useCurrentOrganisation';
import { useTranslation } from 'react-i18next';

export function CreateModelVersionButton() {
    const [addTemplate] = useAddTemplateMutation();
    const { documentId } = useParams();
    const baseVariant = useGetBaseVariant();
    const currentOrganisation = useCurrentOrganisation();
    const { t } = useTranslation('documents');

    const handleClick = () => {
        addTemplate({
            documentId: parseInt(documentId),
            documentVariantId: baseVariant.id,
            organisationId: currentOrganisation.id,
        });
    };

    return (
        <SuccessButton onClick={handleClick}>{t('document.navbar.template.tabs.versions.btn.publish')}</SuccessButton>
    );
}
