export function CategoryPublications({ publications = [], categoryId }) {
    return (
        <div className="pt-3">
            <div className="mb-1">Publicaties:</div>

            {publications.map((publication) => (
                <div key={`cat-${categoryId}-pub-${publication.id}`} className="small mb-1">
                    {publication.name}
                </div>
            ))}

            {publications.length === 0 && (
                <div className="text-muted small">Er zijn geen publicaties gekoppeld aan deze categorie.</div>
            )}
        </div>
    );
}
