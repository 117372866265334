import { PaginatedTable, TableProvider } from 'components/PaginatedTable';
import { ArrowUpShort, CircleFill } from 'react-bootstrap-icons';
import { useGetTaskTeamAssignments } from 'pages/task_team/hooks/useGetTaskTeamAssignments';
import { useGetUserFullName } from 'hooks/useGetUserFullName';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParams } from 'use-query-params';
import { useUserProfile } from 'hooks/useUserProfile';
import cx from 'classnames';
import { TaskDeadline } from 'pages/task_team/views/checks/Checks';
import { taskStatus, taskTeamAssignmentStatus } from 'pages/task_team/config/constants';
import {
    EmptyState,
    EmptyStateActions,
    EmptyStateBody,
    EmptyStateHeader,
    EmptyStateIcon,
    RocketIcon,
} from 'components/EmptyState';
import { PrimaryLinkButton } from 'components/Buttons';
import { generatePath } from 'react-router-dom';
import { CHECKS_PATH, PAGINATION_ITEMS_PER_PAGE } from 'scenes/TaskTeam';
import { Badge } from 'react-bootstrap';

export const REVIEWS_FILTER_PARAM = 'review_filters';

export function Reviews() {
    const defaultFilterOptions = useGetDefaultFilterOptions();
    const {
        items = [],
        totalItems = 0,
        isEmpty = false,
    } = useGetTaskTeamAssignments(REVIEWS_FILTER_PARAM, defaultFilterOptions);

    return (
        <div>
            <div className="subheader">
                <h3>Te beoordelen</h3>
            </div>

            <TableProvider>
                <PaginatedTable
                    items={items}
                    totalItems={totalItems}
                    clientSidePagination={false}
                    itemsPerPage={PAGINATION_ITEMS_PER_PAGE}
                    searchPlaceholder="Zoek op titel of nummer..."
                    colSpan={6}
                    pageParamPrefix={REVIEWS_FILTER_PARAM}
                    searchParamPrefix={REVIEWS_FILTER_PARAM}
                >
                    {({ items = [] }) => (
                        <>
                            <thead>
                                <tr>
                                    <th>
                                        # <ArrowUpShort />
                                    </th>
                                    <th>Titel</th>
                                    <th>Toegewezen aan</th>
                                    <th>Team</th>
                                    <th>Status</th>
                                    <th>Deadline</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isEmpty ? (
                                    <NoReviews />
                                ) : (
                                    <>
                                        {items.map((assignment) => (
                                            <AssignmentRow
                                                assignment={assignment}
                                                key={`assignment-${assignment.id}`}
                                            />
                                        ))}
                                    </>
                                )}
                            </tbody>
                        </>
                    )}
                </PaginatedTable>
            </TableProvider>
        </div>
    );
}

function NoReviews() {
    return (
        <tr>
            <td colSpan={6}>
                <EmptyState>
                    <EmptyStateHeader titleText={'Niks te beoordelen'} icon={<EmptyStateIcon icon={RocketIcon} />} />
                    <EmptyStateBody>
                        Zodra er uitingen zijn die jij moet beoordelen verschijnen die op deze pagina.
                    </EmptyStateBody>
                    <EmptyStateActions>
                        <PrimaryLinkButton
                            to={generatePath(CHECKS_PATH, {
                                page: 'all',
                            })}
                        >
                            Naar alle uitingen
                        </PrimaryLinkButton>
                    </EmptyStateActions>
                </EmptyState>
            </td>
        </tr>
    );
}

function AssignmentRow({ assignment }) {
    const { t, i18n } = useTranslation();
    const [, setQuery] = useQueryParams({
        viewTask: StringParam,
        tab: StringParam,
    });
    const { task, team, taskVersion } = assignment;
    const createdByName = useGetUserFullName(task.createdBy, false);
    const createdAt = DateTime.fromISO(task.createdAt).setLocale(i18n.language).toLocaleString(DateTime.DATE_MED);

    return (
        <tr>
            <td>
                #{task.sequence}-{taskVersion.versionNumber}
            </td>
            <td>
                <div className="d-flex align-items-center">
                    {task.status === taskStatus.STATUS_PAUSED && (
                        <Badge variant="primary" className="small mb-0">
                            Gepauzeerd
                        </Badge>
                    )}

                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();

                            setQuery({
                                viewTask: task.id,
                            });
                        }}
                    >
                        {task.title}
                    </a>
                </div>
                <div>
                    {createdByName}
                    <span className="text-muted">&nbsp;&bull; {createdAt}</span>
                </div>
            </td>
            <td>{assignment.assignedTo ? <AssignedToUser userId={assignment.assignedTo} /> : '-'}</td>
            <td>{team.name}</td>
            <td>
                <TaskAssignmentStatusBadge assignment={assignment} />
            </td>
            <td>
                {[
                    taskTeamAssignmentStatus.STATUS_UNASSIGNED,
                    taskTeamAssignmentStatus.STATUS_REVIEW_IN_PROGRESS,
                ].includes(assignment.status) && <TaskDeadline task={task} />}
            </td>
        </tr>
    );
}

function AssignedToUser({ userId }) {
    const createdByName = useGetUserFullName(userId);

    return <>{createdByName}</>;
}

export function TaskAssignmentStatusBadge({ assignment }) {
    const { i18n } = useTranslation();

    return (
        <div className={cx('d-flex align-items-center', getStatusVariant())}>
            <CircleFill size={10} className="mr-2" />
            <span data-uk-tooltip={getReviewedTooltip()}>{getStatusCode(assignment.status)}</span>
        </div>
    );

    function getReviewedTooltip() {
        if (!assignment.reviewedAt) {
            return undefined;
        }

        const reviewedAt = DateTime.fromISO(assignment.reviewedAt)
            .setLocale(i18n.language)
            .toLocaleString(DateTime.DATE_FULL);

        return `Beoordeeld op ${reviewedAt}`;
    }

    function getStatusVariant() {
        switch (assignment.status) {
            case taskTeamAssignmentStatus.STATUS_REVIEW_IN_PROGRESS:
                return 'text-warning';
            case taskTeamAssignmentStatus.STATUS_APPROVED:
                return 'text-success';
            case taskTeamAssignmentStatus.STATUS_REJECTED:
                return 'text-danger';
            case taskTeamAssignmentStatus.STATUS_UNASSIGNED:
                return 'text-danger';
            case taskTeamAssignmentStatus.STATUS_NO_REVIEW_NEEDED:
                return 'text-muted';
        }
    }
}

export function getStatusCode(status) {
    switch (status) {
        case taskTeamAssignmentStatus.STATUS_REVIEW_IN_PROGRESS:
            return 'Te beoordelen';
        case taskTeamAssignmentStatus.STATUS_APPROVED:
            return 'Goedgekeurd';
        case taskTeamAssignmentStatus.STATUS_REJECTED:
            return 'Afgekeurd';
        case taskTeamAssignmentStatus.STATUS_UNASSIGNED:
            return 'Niet toegewezen';
        case taskTeamAssignmentStatus.STATUS_NO_REVIEW_NEEDED:
            return 'Geen beoordeling nodig';
    }
}

export function useGetDefaultFilterOptions() {
    const userProfile = useUserProfile();

    return {
        status: taskStatus.STATUS_REVIEW_IN_PROGRESS,
        assignedTo: userProfile.userId,
    };
}
