import { useGetTeams } from 'pages/task_team/hooks/useGetTeams';
import { PaginatedTable, TableProvider } from 'components/PaginatedTable';
import { ArrowDownShort, CheckCircleFill, XCircleFill } from 'react-bootstrap-icons';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { NewItemButton } from 'components/Buttons';
import { generatePath, Link } from 'react-router-dom';
import { VIEW_TEAM_PATH } from 'scenes/TaskTeam';
import { ReactTitle } from 'components/ReactTitle';
import { Badge } from 'react-bootstrap';
import { Can } from 'ability/Can';
import { getTeamAvatarUrl } from 'pages/task_team/views/checks/TaskProgress';

export function Teams() {
    const teams = useGetTeams(true);
    const [, setAddTeam] = useQueryParam('addTeam', BooleanParam);

    return (
        <>
            <ReactTitle title="Teams" />

            <div className="overflow-auto w-100">
                <div className="mt-5 mx-5 d-flex">
                    <div className="w-100">
                        <div className="dr-container p-5 mb-4">
                            <TableProvider>
                                <div className="subheader">
                                    <h3>Teams</h3>

                                    <Can I="manage" a="TaskTeam.Teams">
                                        <div className="d-flex align-items-center ml-auto">
                                            <NewItemButton
                                                size="sm"
                                                label="Nieuw team"
                                                onClick={() => setAddTeam(true)}
                                            />
                                        </div>
                                    </Can>
                                </div>

                                <PaginatedTable
                                    items={teams}
                                    searchPlaceholder="Zoeken..."
                                    searchProps={['name', 'email']}
                                    colSpan={5}
                                >
                                    {({ items = [] }) => (
                                        <>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        Naam <ArrowDownShort />
                                                    </th>
                                                    <th>Team mailbox</th>
                                                    <th className="text-center"># Gebruikers</th>
                                                    <th className="text-center">Mag indienen</th>
                                                    <th className="text-center">Mag beoordelen</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {items.map((team) => (
                                                    <Team team={team} key={`team-${team.id}`} />
                                                ))}
                                            </tbody>
                                        </>
                                    )}
                                </PaginatedTable>
                            </TableProvider>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function Team({ team }) {
    const url = getTeamAvatarUrl(team.name);

    return (
        <tr>
            <td>
                <div className="flex-center">
                    <img src={url} width={30} height={30} alt={team.name} className="mr-2" />

                    <Link
                        to={generatePath(VIEW_TEAM_PATH, {
                            id: team.id,
                            page: 'users',
                        })}
                    >
                        {team.name}
                    </Link>
                </div>
            </td>
            <td className="align-middle">{team.email}</td>
            <td className="text-center align-middle">
                <Badge variant="primary" className="m-0">
                    {team.numberOfTeamUsers}
                </Badge>
            </td>
            <td className="text-center">
                {team.permissions.canCreateTask ? (
                    <CheckCircleFill className="text-success" />
                ) : (
                    <XCircleFill className="text-danger" />
                )}
            </td>
            <td className="text-center">
                {team.permissions.canReviewTask ? (
                    <CheckCircleFill className="text-success" />
                ) : (
                    <XCircleFill className="text-danger" />
                )}
            </td>
        </tr>
    );
}
