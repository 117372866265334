import { useEntityTemplateParameters } from '../../../documents_v2/hooks/useEntityTemplateParameters';
import { useGetDocument } from '../../../documents_v2/hooks/useGetDocument';
import { entityTypes } from '../../../translation/config/Constants';
import VariantViewButtons from '../VariantViewButtons';
import AddBlockButton from '../_Blocks/AddBlockButton';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

export function EditAreaLeftSideHeader({
    area,
    toggleRightSide,
    showRightSide,
    changesDetected = false,
    areaBlocks,
    canAddFreeBlocks = true,
}) {
    const document = useGetDocument();
    const documentLevelRestrictions = useEntityTemplateParameters(document, entityTypes.DOCUMENT, document);
    const canOnlyUseAsWhole = documentLevelRestrictions?.composition
        ? !documentLevelRestrictions?.composition?.includes('canOnlyUseAsWhole')
        : true;
    const { t } = useTranslation('documents');

    return (
        <div style={{ height: 110 }}>
            <div className="d-flex align-items-center">
                <VariantViewButtons area={area} changesDetected={changesDetected} />
                <div className="ml-auto">
                    {canAddFreeBlocks && canOnlyUseAsWhole && (
                        <AddBlockButton area={area} areaBlocks={areaBlocks} />
                    )}

                    <button
                        type="button"
                        className="uk-button uk-button-light ml-2"
                        data-uk-tooltip={
                            showRightSide
                                ? t('document.navbar.main.editor.left.subnav.hideRightSide')
                                : t('document.navbar.main.editor.left.subnav.showRightSide')
                        }
                        onClick={toggleRightSide}
                    >
                        {showRightSide ? <ChevronRight size={16} /> : <ChevronLeft size={16} />}
                    </button>
                </div>
            </div>
        </div>
    );
}
