import React, { useEffect, useState } from 'react';
import { Spinner as SpinnerBS } from 'react-bootstrap';
import { Check2 } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

export default function Spinner({ className = 'mx-2' }) {
    return (
        <SpinnerBS animation="border" as="span" role="status" size="sm" variant="primary" className={className}>
            <span className="sr-only">Loading...</span>
        </SpinnerBS>
    );
}

export function LoadingSpinner({ isSubmitting = false }) {
    const [showSaved, setShowSaved] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);

    useEffect(() => {
        if (isSubmitting && showSpinner === false) {
            // Starting save, show loading spinner
            setShowSpinner(true);
        } else if (isSubmitting === false && showSpinner) {
            // Save is done, show complete message
            setShowSpinner(false);
            setShowSaved(true);

            // Hide complete message after 2 seconds
            setTimeout(() => {
                setShowSaved(false);
            }, 2000);
        }
    }, [isSubmitting]);

    if (showSaved) {
        return <SaveComplete />;
    }

    if (isSubmitting && showSpinner) {
        return <Spinner />;
    }

    return null;
}

function SaveComplete() {
    const { t } = useTranslation('documents');
    return (
        <span className="small text-success mx-2">
            <Check2 size={20} />
            {t('document.navbar.main.editor.changesSaved')}
        </span>
    );
}
