import { useGetTranslationContainerEntityReferencesQuery } from '../../../../../features/translations/translationApi';
import LoadingSpinner from '../../../../global/LoadingSpinner';
import { Accordion } from 'react-bootstrap';
import { getDefaultActiveKey, setExpandedEntity } from '../../../../../features/translations/translationSlice';
import { useDispatch, useSelector } from 'react-redux';
import Section from './document/Section';
import { useParams } from 'react-router-dom';
import { views } from '../../../config/Constants';
import TagsContainer from './TagsContainer';
import EmptyContentMessage from '../EmptyContentMessage';
import { ProductsContainer } from './ProductsContainer';
import { useGetDocument } from '../../../../documents_v2/hooks/useGetDocument';

export default function DocumentContainer({ documentContainer, translationLanguage }) {
    const { view } = useParams();
    const currentView = view ?? views.DOCUMENT;

    if (currentView === views.DOCUMENT) {
        return (
            <DocumentContainerInner documentContainer={documentContainer} translationLanguage={translationLanguage} />
        );
    }

    if (currentView === views.TAGS) {
        return <TagsContainer documentContainer={documentContainer} translationLanguage={translationLanguage} />;
    }

    if (currentView === views.PRODUCTS) {
        return <ProductsContainer translationContainer={documentContainer} translationLanguage={translationLanguage} />;
    }

    return <div>View '{currentView}' is not supported.</div>;
}

function DocumentContainerInner({ documentContainer, translationLanguage }) {
    const dispatch = useDispatch();
    const defaultActiveKey = useSelector(getDefaultActiveKey(translationLanguage['@id']));

    const { documentId } = documentContainer;
    const document = useGetDocument(documentId, true);

    const { areaEntityReferences = [], isLoading } = useGetTranslationContainerEntityReferencesQuery(
        {
            uri: documentContainer['@id'],
            entityType: 'area',
        },
        {
            selectFromResult: ({ data, isLoading }) => ({
                areaEntityReferences: data,
                isLoading,
            }),
        }
    );

    const { sidebarFilters } = useSelector((state) => state.translation);
    const { variantId } = sidebarFilters;

    if (document === undefined || isLoading) {
        return <LoadingSpinner />;
    }

    const { name = '' } = document;

    const sections =
        variantId > 0
            ? document.sections.filter((_section) => {
                  return _section.documentVariants.includes(variantId);
              })
            : document.sections;

    return (
        <>
            <div style={{ marginBottom: '2rem' }}>
                <h5 className="card-title">{name}</h5>
            </div>

            <div>
                <Accordion
                    className="dr-accordion"
                    defaultActiveKey={defaultActiveKey}
                    onSelect={(activeKey) => {
                        dispatch(
                            setExpandedEntity({
                                translationLanguage: translationLanguage['@id'],
                                activeKey,
                            })
                        );
                    }}
                >
                    {sections.map((_section) => (
                        <Section
                            translationContainer={documentContainer}
                            section={_section}
                            languageIso={translationLanguage.languageIso}
                            areaEntityReferences={areaEntityReferences}
                            key={`section-${_section.id}`}
                        />
                    ))}

                    {sections.length === 0 && <EmptyContentMessage />}
                </Accordion>
            </div>
        </>
    );
}
