import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import HelperFunctions from 'pages/global/HelperFunctions';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { useGetTargetAudiencesQuery } from 'features/comments/commentApi';

/**
 * @returns {TargetAudience[]}
 */
export function useGetTargetAudiences() {
    const organisationId = useActiveOrganisation();

    const selectTargetAudiences = useMemo(() => {
        const emptyArray = [];

        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (res) => res.data,
            (res, organisationId) => organisationId,
            (data, organisationId) =>
                data
                    ? data
                          .filter((targetAudience) => targetAudience.organisationId === organisationId)
                          .sort(HelperFunctions.dynamicSort('name'))
                    : emptyArray
        );
    }, []);

    const { targetAudiences } = useGetTargetAudiencesQuery(undefined, {
        selectFromResult: (result) => ({
            targetAudiences: selectTargetAudiences(result, organisationId),
        }),
    });

    return targetAudiences;
}
