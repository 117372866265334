import { StringParam, useQueryParam } from 'use-query-params';
import { IconButton } from 'components/Buttons';
import { CloudDownload } from 'react-bootstrap-icons';
import FileApi from 'api/FileApi';
import { saveAs } from 'file-saver';
import { FileIcon } from 'pages/task_team/views/checks/TaskFileAttachments';
import { useEffect, useState } from 'react';
import { DocViewer } from 'pages/task_team/modals/view_task_modal/file_viewer/DocViewer';
import 'pages/task_team/modals/view_task_modal/file_viewer/file-viewer.scss';
import { ImageViewer } from 'pages/task_team/modals/view_task_modal/file_viewer/ImageViewer';
import { PdfViewer } from 'pages/task_team/modals/view_task_modal/file_viewer/PdfViewer';
import { MailViewer } from 'pages/task_team/modals/view_task_modal/file_viewer/MailViewer';
import { VideoViewer } from 'pages/task_team/modals/view_task_modal/file_viewer/VideoViewer';
import { ExcelViewer } from 'pages/task_team/modals/view_task_modal/file_viewer/ExcelViewer';

export function RenderFile({ file }) {
    const [data, setData] = useState();

    useEffect(() => {
        FileApi.getPrivateFile(file.uri, 'arraybuffer', true)
            .then((response) => {
                if (response.data) {
                    setData(response.data);
                }
            })
            .catch(() => {});
    }, []);

    if (!data) {
        return null;
    }

    return (
        <div className="pg-viewer-wrapper">
            <div className="pg-viewer" id="pg-viewer">
                {renderFileType()}
            </div>
        </div>
    );

    function renderFileType() {
        switch (file.mime) {
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return <DocViewer data={data} />;
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            case 'application/vnd.ms-excel':
                return <ExcelViewer data={data} />;
            case 'image/png':
            case 'image/jpeg':
            case 'image/gif':
            case 'image/tiff':
                return <ImageViewer data={data} />;
            case 'application/pdf':
                return <PdfViewer data={data} />;
            case 'application/vnd.ms-outlook':
            case 'message/rfc822':
                return <MailViewer data={data} />;
            case 'video/mp4':
            case 'audio/mpeg':
            case 'video/quicktime':
            case 'video/x-flv':
                return <VideoViewer data={data} />;
            default:
                return <div>Mime "{file.mime}" is not supported.</div>;
        }
    }
}

export function TaskFile({ file }) {
    const [, setPreviewFileId] = useQueryParam('previewFile', StringParam);

    return (
        <div className="d-flex align-items-center">
            <FileIcon mime={file.mime} />

            <a href="#" className="mr-2" onClick={() => setPreviewFileId(file.id)}>
                {file.fileName}
            </a>

            <DownloadFileIcon file={file} />
        </div>
    );
}

export function DownloadFileIcon({ file }) {
    return <IconButton tooltip={'Download bestand'} icon={<CloudDownload size={16} />} onClick={downloadFile} />;

    function downloadFile() {
        FileApi.getPrivateFile(file.uri)
            .then((response) => {
                saveAs(response.data, response.headers['x-suggested-filename'] ?? file.fileName);
            })
            .catch(() => {});
    }
}
