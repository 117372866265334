import { Form as FForm, Formik } from 'formik';
import * as Yup from 'yup';
import { SecondaryButton, WarningButton } from 'components/Buttons';
import Spinner from 'pages/global/Spinner';
import { useUpdateTaskMutation } from 'features/comments/commentApi';
import { prepareTaskFormData } from 'pages/task_team/helpers/FormHelper';
import { VersionEvaluation } from 'pages/task_team/modals/add_check_modal/Step5Finish';

export function EditReEvaluation({ task, close }) {
    const [updateTask] = useUpdateTaskMutation();

    return (
        <Formik
            initialValues={{
                startDate: task.startDate,
                reEvaluation: task.reEvaluation,
                reEvaluationAmount: task.reEvaluationAmount,
                reEvaluationStartDate: task.reEvaluationStartDate,
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            {({ isValid, isSubmitting, dirty, values }) => {
                return (
                    <FForm>
                        <div className="border-bottom mb-3 pb-1">
                            <div className="font-weight-bold">Periodieke herbeoordeling</div>
                        </div>

                        <div className="sidebar-form">
                            <VersionEvaluation active={true} />

                            <div className="d-flex align-items-center justify-content-end mt-4">
                                {isSubmitting && <Spinner />}

                                <SecondaryButton disabled={isSubmitting} onClick={close}>
                                    Annuleer
                                </SecondaryButton>

                                <WarningButton
                                    disabled={isSubmitting || !isValid || !dirty}
                                    className="ml-2"
                                    type="submit"
                                >
                                    Opslaan
                                </WarningButton>
                            </div>
                        </div>
                    </FForm>
                );
            }}
        </Formik>
    );

    function handleSubmit(values) {
        updateTask({ uri: task['@id'], body: prepareTaskFormData(values) }).then(() => {
            close();
        });
    }
}

const validationSchema = Yup.object().shape({
    reEvaluationAmount: Yup.number().when('reEvaluation', {
        is: (val) => val === true,
        then: (s) => s.required(),
        otherwise: (s) => s,
    }),
    reEvaluationStartDate: Yup.string().when('reEvaluation', {
        is: (val) => val === true,
        then: (s) => s.required(),
        otherwise: (s) => s,
    }),
});
