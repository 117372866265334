import { useGetCategoriesQuery } from 'features/reimbursements/reimbursements';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';

const emptyArray = [];

export function useGetCategories() {
    const activeOrganisation = useActiveOrganisation();

    const { categories } = useGetCategoriesQuery(undefined, {
        selectFromResult: ({ data }) => ({
            categories: data ? data.filter((_item) => _item.organisationId === activeOrganisation) : emptyArray,
        }),
    });

    return categories;
}
