import { useGetTeams } from 'pages/task_team/hooks/useGetTeams';
import HelperFunctions from 'pages/global/HelperFunctions';
import { FilterSelect } from 'pages/task_team/helpers/FilterHelper';
import { useGetAvailableUsers } from 'pages/task_team/hooks/useGetAvailableUsers';
import { UNASSIGNED_FILTER_PARAM } from 'pages/task_team/views/checks/Unassigned';

export function FilterUnassigned() {
    const teams = useGetTeams();
    const availableUsers = useGetAvailableUsers();

    return (
        <>
            <FilterSelect
                placeholder="Team"
                label="Team..."
                options={HelperFunctions.prepareDropdownData(teams, 'name')}
                name="team"
                filterParamName={UNASSIGNED_FILTER_PARAM}
            />

            <FilterSelect
                placeholder="Eigenaar"
                label="Eigenaar..."
                options={availableUsers}
                name="task.createdBy"
                filterParamName={UNASSIGNED_FILTER_PARAM}
            />
        </>
    );
}
