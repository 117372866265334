import { useEffect, useState } from 'react';
import _ from 'lodash';
import PostalMime from 'postal-mime';

export function MailViewer({ data }) {
    const [msg, setMsg] = useState();

    useEffect(() => {
        PostalMime.parse(data).then((msg) => {
            setMsg(msg);
        });
    }, []);

    if (!msg || _.isEmpty(msg.html)) {
        return null;
    }

    const toAsString = msg.to.map((to) => recipientToString(to));

    return (
        <div id="mail">
            <table className="table table-sm table-borderless">
                <tbody>
                    <tr>
                        <td className="p-2">Onderwerp:</td>
                        <td className="p-2">{msg.subject}</td>
                    </tr>
                    <tr>
                        <td className="p-2">Van:</td>
                        <td className="p-2">{recipientToString(msg.from)}</td>
                    </tr>
                    <tr>
                        <td className="p-2">Aan:</td>
                        <td className="p-2">{toAsString.join(',')}</td>
                    </tr>
                </tbody>
            </table>
            <iframe srcDoc={msg.html}></iframe>
        </div>
    );

    function recipientToString(recipient) {
        if (_.isEmpty(recipient.name)) {
            return recipient.address;
        }

        return `${recipient.name} <${recipient.address}>`;
    }
}
