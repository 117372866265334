import React, { useState } from 'react';
import { useGetDocument } from '../../hooks/useGetDocument';
import { useGetTemplate } from '../../hooks/useGetTemplate';
import { Button, Dropdown, Modal, Table } from 'react-bootstrap';
import { TbPlaylistAdd } from 'react-icons/tb';
import { useGetBaseVariant } from '../../hooks/useGetBaseVariant';
import { useUpdateSectionsMutation } from 'features/documents/documents';
import { Form as FForm, Formik, useFormikContext } from 'formik';
import { PrimaryButton } from 'components/Buttons';
import HelperFunctions from '../../../global/HelperFunctions';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { FormModal } from 'pages/global/FormModal';
import Spinner from 'pages/global/Spinner';
import cx from 'classnames';

export function NewItemFromTemplate() {
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedSection, setSelectedSection] = useState(null);

    const { t } = useTranslation('documents');
    const document = useGetDocument();
    const template = useGetTemplate(document?.modelId, document?.modelSource);
    const templateTerminology = template?.properties?.terminology;
    const chapterTerm = templateTerminology?.chapter
        ? templateTerminology.chapter
        : t('document.navbar.main.titles.template.chapter');

    const showModal = () => {
        setModalVisible(true);
    };

    const hideModal = () => {
        setModalVisible(false);
        setSelectedSection(null);
    };

    const handleSectionSelect = (section) => {
        setSelectedSection(section);
    };

    return (
        <>
            <Dropdown onSelect={showModal}>
                <Dropdown.Toggle
                    variant="btn-outline-secondary"
                    className="btn btn-btn outline-small-button p-1"
                    id="dropdown-section"
                >
                    <TbPlaylistAdd size={16} className="mr-1" />
                    {t('document.navbar.main.titles.template.addChapter', { term: chapterTerm })}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <span className="ml-3 font-weight-bold">
                        {t('document.navbar.main.titles.template.select', { term: chapterTerm })}
                    </span>
                    {template?.sections &&
                        Object.values(template?.sections).map((section) => (
                            <Dropdown.Item key={section.id} onClick={() => handleSectionSelect(section)}>
                                {section.title}
                            </Dropdown.Item>
                        ))}
                </Dropdown.Menu>
            </Dropdown>

            {modalVisible && template?.sections && (
                <SelectVariantsForm
                    document={document}
                    modalVisible={modalVisible}
                    hideModal={hideModal}
                    selectedSection={selectedSection}
                    template={template}
                />
            )}
        </>
    );
}

function SelectVariantsForm({ document, modalVisible, hideModal, selectedSection, template }) {
    const [updateSections] = useUpdateSectionsMutation();
    const { t } = useTranslation('documents');
    const { t: tGlobal } = useTranslation('global');
    const documentBaseVariant = useGetBaseVariant(undefined, true);

    const templateTerminology = template?.properties?.terminology;
    const variantTerm = templateTerminology.variants
        ? templateTerminology.variants
        : t('document.navbar.main.titles.template.variants');

    const initialValues = getInitialValues();
    const hasVariants = documentBaseVariant.children.length > 0;

    return (
        <FormModal
            scrollable
            size="xl"
            onHide={hideModal}
            show={modalVisible}
            title={`${selectedSection.title}: ${t('document.navbar.main.titles.template.select2')} ${variantTerm}`}
        >
            <Formik
                enableReinitialize
                onSubmit={handleSubmit}
                initialValues={initialValues}
                validationSchema={NewItemSchema}
            >
                {({ isValid, isSubmitting, dirty, setFieldValue, values }) => (
                    <>
                        <FForm autoComplete="off" className="modal-body" id="add-section-form">
                            {renderFormContent()}
                        </FForm>

                        {renderModalFooter(isSubmitting, hideModal, isValid, dirty, setFieldValue, values)}
                    </>
                )}
            </Formik>
        </FormModal>
    );

    function renderFormContent() {
        return (
            <div style={{ minHeight: 200 }}>
                {hasVariants ? (
                    <TemplateSectionsVariantsSelectionTable areas={selectedSection.areas} />
                ) : (
                    <div className="text-danger">
                        {t('document.navbar.main.titles.template.createYourItemsFirstWarning', {
                            items: variantTerm,
                        })}
                    </div>
                )}
            </div>
        );
    }

    function renderModalFooter(isSubmitting = false, onHide, isValid = true, dirty = true, setFieldValue, values) {
        const documentVariantsList = HelperFunctions.variantAsList(documentBaseVariant);
        const variantIds = documentVariantsList
            .filter((variant) => !variant.variantGroup)
            .map((variant) => `${variant.id}`);

        const allSelected = values.areas.every(
            (area) => area.properties.isTemplate === true || area.documentVariants.length === variantIds.length
        );

        return (
            <Modal.Footer>
                <div className="d-flex align-items-center justify-content-around flex-grow-1">
                    <PrimaryButton
                        onClick={() => {
                            selectedSection.areas.forEach((area, index) => {
                                if (area.properties.isTemplate !== true) {
                                    setFieldValue(`areas.${index}.documentVariants`, variantIds);
                                }
                            });
                        }}
                        disabled={allSelected}
                    >
                        {t('document.navbar.main.titles.template.selectAll')}
                    </PrimaryButton>

                    <div className="ml-auto">
                        <div className="d-flex align-items-center">
                            {isSubmitting && <Spinner />}

                            <Button variant="btn btn-secondary" disabled={isSubmitting} onClick={onHide}>
                                {tGlobal('btn.cancel')}
                            </Button>
                            <Button
                                variant="btn btn-warning ml-2"
                                disabled={isSubmitting || !isValid || !dirty}
                                type="submit"
                                form="add-section-form"
                            >
                                {tGlobal('btn.save')}
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal.Footer>
        );
    }

    function handleSubmit(values, { setSubmitting }) {
        const filteredAreas = values.areas.map((area) => ({
            ...area,
            documentVariants: area.documentVariants.map((variantId) => parseInt(variantId)),
        }));

        //we make sure that the baseVariant is there to keep the functionality going
        const documentVariants = [documentBaseVariant.id];
        filteredAreas.forEach((area) => {
            area.documentVariants.forEach((variantId) => {
                if (!documentVariants.includes(variantId)) {
                    documentVariants.push(variantId);
                }
            });
        });

        const formData = {
            ...values,
            documentVariants,
            areas: filteredAreas,
            modelId: document.modelId,
            sourceSlug: document.modelSource,
        };

        updateSections({ documentId: document.id, body: [formData] }).then(() => {
            setSubmitting(false);
            hideModal();
        });
    }

    function getInitialValues() {
        return {
            title: selectedSection.title,
            documentId: document.id,
            documentVariants: [documentBaseVariant.id],
            exportProperties: Array.isArray(selectedSection.exportProperties)
                ? [...selectedSection.exportProperties]
                : { ...selectedSection.exportProperties },
            properties: {
                ...selectedSection.properties,
                templateParameters: selectedSection.properties.templateParameters,
                parentId: selectedSection.id,
            },
            areas: selectedSection.areas.map((area) => ({
                title: area.title,
                exportProperties: Array.isArray(area.exportProperties)
                    ? [...area.exportProperties]
                    : { ...area.exportProperties },
                properties: {
                    ...area.properties,
                    templateParameters: area.properties.templateParameters,
                    parentId: area.id,
                },
                sortOrder: area.sortOrder,
                documentVariants: [documentBaseVariant.id],
            })),
        };
    }
}

function TemplateSectionsVariantsSelectionTable({ areas }) {
    const { documentId } = useParams();
    const documentBaseVariant = useGetBaseVariant(parseInt(documentId), true);
    const documentVariantsList = HelperFunctions.variantAsList(documentBaseVariant);
    const { values, setFieldValue } = useFormikContext();

    const variantIds = documentVariantsList
        .filter((variant) => !variant.variantGroup)
        .map((variant) => `${variant.id}`);

    const allVariantsCount = variantIds.length;

    return (
        <Table borderless responsive>
            <tbody>
                {renderTableHeader()}

                <SelectionTableRows
                    areas={areas}
                    documentVariantsList={documentVariantsList}
                    documentBaseVariant={documentBaseVariant}
                />
            </tbody>
        </Table>
    );

    function renderTableHeader() {
        return (
            <>
                <tr>
                    <td />
                    {areas?.map((area, index) => (
                        <td
                            className={cx('font-weight-bold small py-2 pl-2 pr-1 text-center', {
                                'text-muted': area.properties.isTemplate === true,
                            })}
                            style={{ minWidth: 105 }}
                            key={`area-0-row-${index}`}
                        >
                            {area.title}
                        </td>
                    ))}
                </tr>
                <tr>
                    <td />
                    {areas?.map((area, index) => (
                        <td className="pt-2" key={`area-1-row-${index}`}>
                            {renderAreaCheckbox(index, area.properties.isTemplate === true)}
                        </td>
                    ))}
                </tr>
            </>
        );
    }

    function renderAreaCheckbox(index, isTemplate = false) {
        const areaFromForm = values.areas[index];
        const checked = areaFromForm.documentVariants.length === allVariantsCount;
        const indeterminate =
            areaFromForm.documentVariants.length > 1 && areaFromForm.documentVariants.length < allVariantsCount;

        return (
            <div className="d-flex justify-content-center">
                <input
                    type="checkbox"
                    disabled={isTemplate}
                    checked={checked}
                    onChange={handleChange}
                    ref={(el) => el && (el.indeterminate = indeterminate)}
                />
            </div>
        );

        function handleChange() {
            if (checked) {
                // Select none
                setFieldValue(`areas.${index}.documentVariants`, [documentBaseVariant.id]);
            } else {
                // Select all
                setFieldValue(`areas.${index}.documentVariants`, variantIds);
            }
        }
    }
}

export function SelectionTableRows({ areas, documentVariantsList, documentBaseVariant, nameField = undefined }) {
    const renderRows = (variants) => {
        return variants.map((variant) => {
            const childOfBaseVariants = variants
                .filter((v) => v.parentId === documentBaseVariant.id && !variants.some((a) => a.parentId === v.id))
                .map((v) => v.id);

            const GroupVariantsIds = variants
                .filter((v) => v.parentId === documentBaseVariant.id && !childOfBaseVariants.includes(v.id))
                .map((v) => v.id);

            const childVariantsWithNonBaseParents = variants.filter(
                (v) =>
                    v.id !== documentBaseVariant.id &&
                    v.parentId !== documentBaseVariant.id &&
                    variants.some((a) => a.parentId === v.id)
            );

            const parentIds = childVariantsWithNonBaseParents.map((v) => v.parentId);
            const parentChildsIds = childVariantsWithNonBaseParents.map((v) => v.id);

            const normalChildVariants = variants
                .filter(
                    (v) =>
                        v.parentId === variant.id &&
                        v.parentId !== documentBaseVariant.id &&
                        !parentChildsIds.includes(v.id) &&
                        !childOfBaseVariants.includes(v.id) &&
                        !parentIds.includes(v.id)
                )
                .map((v) => v.id);

            const childVariantsIds = [...normalChildVariants];
            const parentVariantIds = [...GroupVariantsIds, ...parentIds, ...parentChildsIds];

            if (parentVariantIds.includes(variant.id) || variant.parentId === null) {
                return (
                    <React.Fragment key={variant.id}>
                        {variant.id === documentBaseVariant.id && childOfBaseVariants.length === 0 ? null : (
                            <SelectionTableParentRows variant={variant} areas={areas} />
                        )}

                        {variant.id === documentBaseVariant.id
                            ? childOfBaseVariants.map((childVariantId) => {
                                  const childVariant = variants.find((variant) => variant.id === childVariantId);
                                  return (
                                      <SelectionTableChildRows
                                          key={childVariantId}
                                          variant={childVariant}
                                          areas={areas}
                                          nameField={nameField}
                                      />
                                  );
                              })
                            : childVariantsIds.map((childVariantId) => {
                                  const childVariant = variants.find((variant) => variant.id === childVariantId);
                                  return (
                                      <SelectionTableChildRows
                                          key={childVariantId}
                                          variant={childVariant}
                                          areas={areas}
                                          nameField={nameField}
                                      />
                                  );
                              })}
                    </React.Fragment>
                );
            }
            return null;
        });
    };
    return <>{renderRows(documentVariantsList)}</>;
}

function SelectionTableParentRows({ variant, areas = [] }) {
    return (
        <tr>
            <td className="bg-lightBlueLight font-weight-bold small py-2" colSpan={areas.length + 1}>
                {variant.prefix && <span className="text-muted">{variant.prefix}&nbsp;</span>}
                {variant.name}
            </td>
        </tr>
    );
}

function SelectionTableChildRows({ variant, areas, nameField = undefined }) {
    const { values, setFieldValue } = useFormikContext();

    const handleChangeCheckbox = (variantId, index) => {
        const currentValue = nameField ? values.documentVariants : _.get(values, `areas.${index}.documentVariants`, []);
        const updatedValue = currentValue.includes(_.toString(variantId))
            ? currentValue.filter((v) => v !== _.toString(variantId))
            : [...currentValue, _.toString(variantId)];

        if (nameField) {
            setFieldValue('documentVariants', updatedValue);
        } else {
            setFieldValue(`areas.${index}.documentVariants`, updatedValue);
        }
    };

    return (
        <tr key={variant.id}>
            <td className="small">
                <div className="pl-2">
                    {variant.prefix && <span className="text-muted">{variant.prefix}&nbsp;</span>}
                    {variant.name}
                </div>
            </td>
            {areas.map((area, index) => (
                <td className="align-middle py-2" key={`variant-${variant.id}-area-${index}`}>
                    <div className="d-flex justify-content-center">
                        <input
                            type="checkbox"
                            disabled={area.properties.isTemplate === true}
                            checked={
                                nameField
                                    ? values.documentVariants.includes(_.toString(variant.id))
                                    : _.get(values, `areas.${index}.documentVariants`, []).includes(
                                          _.toString(variant.id)
                                      )
                            }
                            onChange={() => handleChangeCheckbox(variant.id, index)}
                        />
                    </div>
                </td>
            ))}
        </tr>
    );
}

const NewItemSchema = Yup.object().shape({
    areas: Yup.array().min(1).required(),
});
