import Constants from '../../../../config/Constants';
import BasicEditor from '../../../documents/misc/_BlockParser/BasicEditor';
import { Col, Form, Row } from 'react-bootstrap';
import { Field, useFormikContext } from 'formik';
import _ from 'lodash';
import { DocRevSelect, Switch, Textarea } from '../../helpers/FieldHelper';
import { documentApi } from '../../../../features/documents/documents';
import { useEffect, useState } from 'react';
import { PublicationProperty, templateProperties } from '../PublicationProperty';
import { useTranslation } from 'react-i18next';

export default function TOC({ contentItem, index }) {
    const { values, setFieldValue } = useFormikContext();
    const [getDocument] = documentApi.useLazyGetDocumentQuery();
    const [getDocumentVariants] = documentApi.useLazyGetDocumentVariantsQuery();
    const { t } = useTranslation('publications');

    const [sectionSelectOptions, setSectionSelectOptions] = useState([]);

    const contentDecoded = values.contentDecoded;

    useEffect(() => {
        Promise.all(
            contentDecoded
                .filter((_content) => _content.contentType === Constants.publication.contentTypes.section)
                .map((_content) => {
                    return new Promise(async (resolve) => {
                        const documentData = await getDocument({ id: _content.documentId }, true);
                        const document = documentData.data;

                        const variantData = await getDocumentVariants(_content.documentId, true);
                        const documentVariants = variantData.data;

                        const section = _.find(document.sections, ['id', _content.sectionId]);
                        const variant = _.find(documentVariants, ['id', _content.variantId]);

                        if (section === undefined || variant === undefined) {
                            resolve({
                                label: t('publication.settings.content.summary.expand.toc.unknownChapter'),
                                value: _content.id,
                            });
                        } else {
                            const variantName =
                                variant.parentId === null
                                    ? t('publication.settings.content.summary.expand.toc.basicVariant')
                                    : variant.name;
                            resolve({
                                label: section.title + ' (' + variantName + ')',
                                value: _content.id,
                            });
                        }
                    });
                }),
        ).then((results) => {
            setSectionSelectOptions(results);
        });
    }, [contentDecoded]);

    return (
        <>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4} htmlFor={`contentDecoded.${index}.title`}>
                    {t('publication.settings.content.summary.expand.toc.title')}*
                </Form.Label>
                <Col sm={8}>
                    <Field
                        id={`contentDecoded.${index}.title`}
                        name={`contentDecoded.${index}.title`}
                        as={Form.Control}
                    />
                </Col>
            </Form.Group>

            <PublicationProperty property={templateProperties.toc.section}>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4} htmlFor={`contentDecoded.${index}.tableOfContentsSectionId`}>
                        {t('publication.settings.content.summary.expand.toc.chapter')}
                    </Form.Label>
                    <Col sm={8}>
                        <DocRevSelect
                            options={sectionSelectOptions}
                            name={`contentDecoded.${index}.tableOfContentsSectionId`}
                            selectedValue={contentItem.tableOfContentsSectionId}
                            onChange={(selection) =>
                                setFieldValue(`contentDecoded.${index}.tableOfContentsSectionId`, selection?.value ?? 0)
                            }
                            props={{
                                isClearable: true,
                            }}
                        />
                        <Form.Text muted>{t('publication.settings.content.summary.expand.toc.leaveBlank')}</Form.Text>
                    </Col>
                </Form.Group>
            </PublicationProperty>

            <PublicationProperty property={templateProperties.toc.description}>
                <Form.Group as={Row} className="mb-0">
                    <Form.Label column sm={4} htmlFor={`contentDecoded.${index}.description`}>
                        {t('publication.settings.content.summary.expand.toc.description')}
                    </Form.Label>
                    <Col sm={8}>
                        <BasicEditor
                            name={`contentDecoded.${index}.description`}
                            id={`contentDecoded.${index}.description`}
                            onChange={(e) => {
                                setFieldValue(`contentDecoded.${index}.description`, e.target.value);
                            }}
                            value={contentItem?.description ?? ''}
                            showRef={false}
                        />
                    </Col>
                </Form.Group>
            </PublicationProperty>

            <PublicationProperty property={templateProperties.toc.showInChapters}>
                <Switch
                    label={t('publication.settings.content.summary.expand.section.displayOnOverview')}
                    name={`contentDecoded.${index}.properties.tocShowInChapters`}
                />
            </PublicationProperty>

            <PublicationProperty property={templateProperties.toc.tableOfContentsDescription}>
                {contentItem?.properties?.tocShowInChapters === true && (
                    <Textarea
                        label={t('publication.settings.content.summary.expand.section.additionalDescription')}
                        name={`contentDecoded.${index}.tableOfContentsDescription`}
                        props={{
                            rows: 2,
                        }}
                    />
                )}
            </PublicationProperty>
        </>
    );
}
