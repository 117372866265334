import * as ReactIconsIo from 'react-icons/io';
import * as ReactIconsFa from 'react-icons/fa';
import * as ReactIconsMd from 'react-icons/md';
import * as RemixIcons from 'react-icons/ri';
import * as BoxIcons from 'react-icons/bi';
import * as IcoMoonIcons from 'react-icons/im';
import * as GrommentIcons from 'react-icons/gr';
import * as ReactIconsIo5 from 'react-icons/io5';
import * as BootstrapIcons from 'react-icons/bs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose } from 'react-icons/ai';
import { ChromePicker } from 'react-color';


const IconRenderer = ({ 
    areaIcons, 
    onRemoveIcon, 
    iconColor, 
    handleIconColorChange, 
    showColorPicker, 
    setShowColorPicker, 
    isInsideTileRow = false, 
    showIconNames = false 
}) => {
       const iconLibraries = {
        Gr: GrommentIcons,
        Im: IcoMoonIcons,
        Bs: BootstrapIcons,
        Fa: ReactIconsFa,
        Md: ReactIconsMd,
        Ri: RemixIcons,
        Bi: BoxIcons,
    };
    const { t } = useTranslation('documents');
    const [selectedIconIndex, setSelectedIconIndex] = useState(0);


    return (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {areaIcons?.map((areaIcon, index) => {
                const { icon, color } = areaIcon;

                let IconComponent = null;

                if (icon.startsWith('IoIos')) {
                    IconComponent = ReactIconsIo[icon];
                } else if (icon.startsWith('Io')) {
                    IconComponent = ReactIconsIo5[icon];
                } else {
                    const libraryName = icon.substring(0, icon.indexOf('icon') + 3);
                    IconComponent = iconLibraries[libraryName][icon];
                }

                if (!IconComponent) {
                    return null;
                }

                return (
                    <React.Fragment key={index}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div
                                onClick={() => {
                                    setSelectedIconIndex(index);
                                }}
                            >
                                <IconComponent
                                    color={areaIcon.color || iconColor}
                                    size={isInsideTileRow ? 18 : 36}
                                    onClick={() => {
                                        setShowColorPicker(!showColorPicker);
                                    }}
                                />
                                {showIconNames && <span>{icon}</span>}
                                {showColorPicker && selectedIconIndex === index && (
                                    <div className="position-absolute" style={{ right: '1.5rem' }}>
                                        <ChromePicker
                                            color={areaIcon.color || iconColor}
                                            onChange={(color) => handleIconColorChange(color, index)}
                                            disableAlpha={true}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        {!isInsideTileRow &&
                            <AiOutlineClose
                                className="mr-2 ml-1 outline-small-button"
                                onClick={(event) => {
                                    event.preventDefault();
                                    onRemoveIcon(index);
                                }}
                                tooltip={t('document.navbar.main.settingsSidebar.articles.edit.deleteIcon')}
                            />}
                    </React.Fragment>
                );
            })}
        </div>
    );
};

export default IconRenderer;
