import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import IconRenderer from './IconRenderer';
import { iconLibraries } from './iconLibraries';
import { InputSelectWithTitleIcon } from '../../../../pages/publications_v2/helpers/FieldHelper';

const IconSearch = ({ handleIconColorChange, handleAddIcon, areaIcons: initialAreaIcons, handleDeleteIcon }) => {
    const [areaIcons, setAreaIcons] = useState(initialAreaIcons);
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [matchedIcons, setMatchedIcons] = useState([]);
    const [selectedLibrary, setSelectedLibrary] = useState('react-icons/fa');
    const [iconColor, setIconColor] = useState('#255478');
    const [searchInput, setSearchInput] = useState('');
    const { t } = useTranslation('documents');

    const handleIconFilterBySearch = () => {
        const selectedLibraryIcons = iconLibraries[selectedLibrary].icons;
        let matchedIconsList;

        if (searchInput === '') {
            matchedIconsList = Object.keys(selectedLibraryIcons);
        } else {
            matchedIconsList = Object.keys(selectedLibraryIcons).filter((iconName) =>
                iconName.toLowerCase().includes(searchInput.toLowerCase())
            );
        }

        setMatchedIcons(matchedIconsList);
    };

    useEffect(() => {
        handleIconFilterBySearch();
    }, [searchInput, selectedLibrary]);

    useEffect(() => {
        setAreaIcons(initialAreaIcons);
    }, [initialAreaIcons]);


    const handleIconSelect = (iconName, iconColor) => {
        const iconExists = initialAreaIcons.find((areaIcon) => areaIcon.icon === iconName);

        if (iconExists) {
            handleDeleteIcon(initialAreaIcons.findIndex(areaIcon => areaIcon.icon === iconName));
        } else {
            if (initialAreaIcons.length < 3) {
                handleAddIcon({ icon: iconName, color: iconColor });
            }
        }
    };

    const renderAllIcons = () => {
        const selectedLibraryIcons = iconLibraries[selectedLibrary].icons;
        return (
            <div className="icon-grid-wrapper">
                <div className="icon-grid">
                    {matchedIcons.map((iconName) => {
                        const IconComponent = selectedLibraryIcons[iconName];
                        return IconComponent ? (
                            <div
                                key={iconName}
                                onClick={() => handleIconSelect(iconName, iconColor)}
                            >
                                <IconComponent 
                                    size={24} 
                                    color={areaIcons?.some(areaIcon => areaIcon.icon === iconName) ? "#B2EC2B" : iconColor} 
                                />
                            </div>
                        ) : null;
                    })}
                </div>
            </div>
        );
    };
    

    useEffect(() => {
        const selectedLibraryIcons = iconLibraries[selectedLibrary].icons;
        setMatchedIcons(Object.keys(selectedLibraryIcons));
    }, [selectedLibrary]);

    return (
        <div className="form">
            <div className="mb-3 mt-2">
                <InputSelectWithTitleIcon
                    name="library"
                    title={t('document.navbar.main.settingsSidebar.articles.edit.selectLibrary')}
                    options={Object.keys(iconLibraries).map((libraryKey) => ({
                        label: iconLibraries[libraryKey].library,
                        value: libraryKey,
                    }))}
                    defaultValue={selectedLibrary}
                    props={{
                        onChange: (selection) => {
                            setSelectedLibrary(selection?.value ?? '')
                            setSearchInput('')
                        },
                    }}
                />
            </div>
            <div className="mb-3">
                <div>
                    {areaIcons && (
                        <IconRenderer
                            areaIcons={areaIcons}
                            onRemoveIcon={handleDeleteIcon}
                            iconColor={iconColor}
                            handleIconColorChange={handleIconColorChange}
                            showColorPicker={showColorPicker}
                            setShowColorPicker={setShowColorPicker}
                            showIconNames={true}
                        />
                    )}

                </div>

                <div className="mb-3 mt-3">
                    <Form.Control
                        type="text"
                        placeholder="Search icons"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                    />
                </div>

                {renderAllIcons()}


            </div>
        </div>
    );
};

export default IconSearch;
