import { Route, Switch } from 'react-router-dom';
import { FORGOT_PASSWORD_PATH, ForgotPassword } from 'pages/authentication/ForgotPassword';
import { Login } from 'pages/authentication/Login';
import { RESET_PASSWORD_PATH, ResetPassword } from 'pages/authentication/ResetPassword';

export function LoginRouter() {
    return (
        <Switch>
            <Route exact strict path={FORGOT_PASSWORD_PATH}>
                <ForgotPassword />
            </Route>
            <Route exact strict path={RESET_PASSWORD_PATH}>
                <ResetPassword />
            </Route>
            <Route path="/">
                <Login />
            </Route>
        </Switch>
    );
}
