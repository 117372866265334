import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    fetchOperationsLists,
    fetchOpListDepartments,
    fetchOpListUsers,
    selectOpListById,
} from '../../../features/operationsList/operationsListSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchTask,
    fetchTasks,
    removeField,
    removeTask,
    selectAllDepartmentTasksForOpList,
} from '../../../features/operationsList/taskSlice';
import { Button, Table } from 'react-bootstrap';
import { CheckCircleFill, PencilFill, PlusCircleFill, TrashFill } from 'react-bootstrap-icons';
import EditTaskModal from './EditTaskModal';
import EditFieldModal from './EditFieldModal';
import Constants from '../../../config/Constants';
import LoadingSpinner from '../../global/LoadingSpinner';
import HelperFunctions from '../../global/HelperFunctions';
import { selectDepartmentUserBelongsTo } from '../../../features/operationsList/opListUserSlice';
import { useTranslation } from 'react-i18next';

export default function Tasks({ showEditModalTask, setShowEditModalTask }) {
    const { id } = useParams();
    const dispatch = useDispatch();
    const opList = useSelector((state) => selectOpListById(state.operations, id));
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (loading) {
            const options = { opListId: id };
            Promise.all([
                dispatch(fetchOperationsLists()),
                dispatch(fetchOpListUsers(options)),
                dispatch(fetchOpListDepartments(options)),
            ]).then(() => setLoading(false));
        }
    }, [dispatch, loading, setLoading]);

    if (loading) {
        return <LoadingSpinner />;
    }

    return (
        <TaskList opList={opList} showEditModalTask={showEditModalTask} setShowEditModalTask={setShowEditModalTask} />
    );
}

function TaskList({ opList, showEditModalTask, setShowEditModalTask }) {
    const dispatch = useDispatch();
    const { t } = useTranslation('changelist');

    const department = useSelector(selectDepartmentUserBelongsTo(opList));
    const tasks = useSelector(selectAllDepartmentTasksForOpList(opList));
    const [showEditModalFieldData, setShowEditModalFieldData] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (loading) {
            dispatch(fetchTasks()).then(() => setLoading(false));
        }
    }, [dispatch, loading, setLoading]);

    if (loading) {
        return <LoadingSpinner />;
    }

    // Filter out deleted tasks
    const filteredTasks = tasks.filter((task) => !task.deletedAt);

    return (
        <>
            {filteredTasks.map((task) => {
                return (
                    <Task
                        task={task}
                        setShowEditModalTask={setShowEditModalTask}
                        setShowEditModalFieldData={setShowEditModalFieldData}
                        key={task.id}
                    />
                );
            })}
            {filteredTasks.length === 0 && <p className="pt-3 text-muted">{t('changelist.task.tasks.noTasks')}</p>}

            {showEditModalTask !== false && (
                <EditTaskModal
                    showModal={true}
                    opList={opList}
                    department={department}
                    task={showEditModalTask}
                    handleClose={() => setShowEditModalTask(false)}
                />
            )}

            {showEditModalFieldData !== false && (
                <EditFieldModal
                    showModal={true}
                    task={showEditModalFieldData[0]}
                    field={showEditModalFieldData[1]}
                    handleClose={(updatedField) => {
                        // Modal has closed without submitting successfully
                        if (!updatedField) {
                            setShowEditModalFieldData(false);
                            return;
                        }

                        dispatch(fetchTask({ uri: updatedField.payload.task })).then(() =>
                            setShowEditModalFieldData(false)
                        );
                    }}
                />
            )}
        </>
    );
}

function Task({ task, setShowEditModalTask, setShowEditModalFieldData }) {
    const dispatch = useDispatch();
    const { t } = useTranslation('changelist');

    function deleteTask(task) {
        HelperFunctions.confirmModal(
            t('changelist.task.tasks.confirmDeleteTask'),
            'danger',
            false,
            t('changelist.task.tasks.btn.yes'),
            t('btn.cancel')
        ).then(() => dispatch(removeTask(task['@id'])));
    }

    const fields = task.fields.filter((field) => !field.deletedAt);

    return (
        <>
            <div className="op-list-settings-task-header pt-3">
                <h4 className="font-weight-normal text-primary mb-0">{task.label}</h4>
                <PencilFill className="ml-2 text-muted" onClick={() => setShowEditModalTask(task)} />
                <TrashFill className="ml-2 text-muted" onClick={() => deleteTask(task)} />
            </div>

            <div className="mb-4">
                {fields.length > 0 && (
                    <Table hover className="mb-2">
                        <thead>
                            <tr>
                                <th className="border-top-0" width="45%">
                                    {t('changelist.task.tasks.fieldName')}
                                </th>
                                <th className="border-top-0" width="20%">
                                    {t('changelist.task.tasks.type')}
                                </th>
                                <th className="border-top-0" width="15%">
                                    {t('changelist.task.tasks.required')}
                                </th>
                                <th className="text-center border-top-0" width="15%">
                                    {t('changelist.task.tasks.primaryQuestion')}
                                </th>
                                <th className="border-top-0" style={{ minWidth: 65 }} />
                            </tr>
                        </thead>
                        <tbody>
                            {fields.map((field, index) => {
                                return (
                                    <FieldRow
                                        setShowEditModalFieldData={setShowEditModalFieldData}
                                        task={task}
                                        field={field}
                                        index={index}
                                        key={`field-row-${field.id}`}
                                    />
                                );
                            })}
                        </tbody>
                    </Table>
                )}

                {fields.length === 0 && <p className="pt-3 text-muted">{t('changelist.task.tasks.noFieldsAdded')}</p>}

                <div className="op-list-settings-new-field" onClick={() => setShowEditModalFieldData([task, {}])}>
                    <a className="text-success">
                        <PlusCircleFill />
                        <span className="ml-2">{t('changelist.task.tasks.newField')}</span>
                    </a>
                </div>
            </div>
        </>
    );
}

function FieldRow({ setShowEditModalFieldData, task, field, index }) {
    const { t } = useTranslation('changelist');

    return (
        <tr onClick={() => setShowEditModalFieldData([task, field])}>
            <td className="align-middle">{field.label}</td>
            <td className="align-middle">
                {Constants.opTaskFieldTypes[field.type] ?? t('changelist.task.tasks.unknownType')}
            </td>
            <td className="align-middle">
                {field.required ? t('changelist.task.tasks.yes') : t('changelist.task.tasks.no')}
            </td>
            <td className="align-middle text-center">{index === 0 && <CheckCircleFill className="text-success" />}</td>
            <td className="align-middle">
                <FieldActions task={task} field={field} index={index} />
            </td>
        </tr>
    );
}

const FieldActions = ({ task, field, index }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('changelist');

    function confirmDelete() {
        HelperFunctions.confirmModal(
            t('changelist.task.tasks.removeField'),
            'danger',
            false,
            t('btn.save'),
            t('btn.cancel')
        )
            .then(() => dispatch(removeField(field['@id'])))
            .then(() => dispatch(fetchTask({ uri: task['@id'] })));
    }

    return (
        <Button
            variant="icon"
            disabled={index === 0}
            className="btn-icon-only text-danger"
            data-uk-tooltip={t('changelist.task.tasks.btn.deleteField')}
            onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                confirmDelete();
            }}
        >
            <TrashFill />
        </Button>
    );
};
