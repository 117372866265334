import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Area } from 'pages/documents_v2/views/view/Area';
import AddAreaForm from '../index/AddAreaForm';
import React, { useEffect, useRef, useState } from 'react';
import SectionTitleRow from '../../../documents/misc/_ReviewDocument/SectionTitleRow';
import AuditTrailModal from '../../../documents/misc/AuditTrailModal';
import { useEntityTemplateParameters } from '../../hooks/useEntityTemplateParameters';
import cx from 'classnames';
import { entityTypes } from 'pages/translation/config/Constants';
import { useTranslation } from 'react-i18next';
import { NumberParam, useQueryParam } from 'use-query-params';
import { useInView } from 'react-intersection-observer';
import { useGetAreas } from 'pages/documents_v2/hooks/useGetAreas';
import { useGetAreasQuery, useUpdateAreaSortOrderMutation } from 'features/documents/documents';
import LoadingSpinner from 'pages/global/LoadingSpinner';
import HelperFunctions from 'pages/global/HelperFunctions';
import { useGetDocument } from 'pages/documents_v2/hooks/useGetDocument';

export function Section({
    section,
    toggleMoveModal,
    setLibraryImport,
    setAddAreaFromTemplateModal,
    setAddAreaFromParentModal,
    expandByDefault = false,
}) {
    const { t } = useTranslation('documents');

    if (!section.displayInEditMode) {
        return (
            <h4 className="uk-margin-remove uk-text-muted uk-font-weight-600">
                {section.title}
                <span className="uk-text-small font-weight-bold">
                    {t('document.navbar.main.titles.subTitles.unWorkable')}
                </span>
            </h4>
        );
    }

    return (
        <ScrollableSection sectionId={section.id}>
            <SectionContent
                section={section}
                expandByDefault={expandByDefault}
                toggleMoveModal={toggleMoveModal}
                setLibraryImport={setLibraryImport}
                setAddAreaFromTemplateModal={setAddAreaFromTemplateModal}
                setAddAreaFromParentModal={setAddAreaFromParentModal}
            />
        </ScrollableSection>
    );
}

function ScrollableSection({ sectionId, children }) {
    const [sid] = useQueryParam('sid', NumberParam);
    const [didScroll, setDidScroll] = useState(false);
    const innerRef = useRef();

    const { ref, inView } = useInView({
        threshold: 0,
    });

    useEffect(() => {
        if (sectionId !== sid) {
            return;
        }

        if (!inView && !didScroll) {
            if (innerRef.current) {
                innerRef.current.scrollIntoView({ behavior: 'smooth' });
                setDidScroll(true);
            }
        } else {
            setDidScroll(true);
        }
    }, [sectionId, sid, inView, innerRef.current]);

    return (
        <div className="mb-4" ref={ref}>
            <div ref={innerRef}>{children}</div>
        </div>
    );
}

function SectionContent({
    section,
    expandByDefault,
    toggleMoveModal,
    setLibraryImport,
    setAddAreaFromTemplateModal,
    setAddAreaFromParentModal,
}) {
    const document = useGetDocument();
    const [showAuditTrail, setShowAuditTrail] = useState(false);

    const [sid, setSid] = useQueryParam('sid', NumberParam);
    const [aid, setAid] = useQueryParam('aid', NumberParam);

    const expanded = sid === section.id;

    const documentLevelRestrictions = useEntityTemplateParameters(document, entityTypes.DOCUMENT, document);
    const restrictions = useEntityTemplateParameters(section, entityTypes.SECTION, document);

    const canOnlyUseAsWhole = documentLevelRestrictions?.composition
        ? !documentLevelRestrictions?.composition?.includes('canOnlyUseAsWhole')
        : true;

    useEffect(() => {
        if (expandByDefault) {
            setSid(section.id);
        }
    }, []);

    return (
        <>
            <div
                className="section-row-hover cursor-pointer"
                onClick={() => toggleSectionRow()}
            >
                <SectionTitleRow
                    section={section}
                    expanded={expanded}
                    toggleAuditTrail={toggleAuditTrail}
                />
            </div>

            {expanded && (
                <div className="uk-animation-fade uk-animation-fast">
                    <SectionAreas section={section} toggleMoveModal={toggleMoveModal} />

                    {restrictions.canAddFreeAreas && canOnlyUseAsWhole && (
                        <AddAreaForm
                            section={section}
                            setLibraryImport={setLibraryImport}
                            setAddAreaFromTemplateModal={setAddAreaFromTemplateModal}
                            setAddAreaFromParentModal={setAddAreaFromParentModal}
                        />
                    )}
                </div>
            )}

            {showAuditTrail && <AuditTrailModal close={closeAuditTrail} sectionId={section.id} />}
        </>
    );

    function toggleSectionRow() {
        if (sid === section.id) {
            setSid(undefined);
            setAid(undefined);
        } else {
            setSid(section.id);
        }
    }

    function toggleAuditTrail() {
        setShowAuditTrail(true);
    }

    function closeAuditTrail() {
        setShowAuditTrail(false);
    }
}

function SectionAreas({ section, toggleMoveModal }) {
    const document = useGetDocument();
    const [updateAreaSortOrder] = useUpdateAreaSortOrderMutation();
    const { t } = useTranslation('documents');
    const areas = useGetAreas(section.id);
    const { isLoading, isFetching } = useGetAreasQuery(section.id);
    const restrictions = useEntityTemplateParameters(section, entityTypes.SECTION, document);

    if ((isLoading === true || isFetching === true) && areas.length === 0) {
        return (
            <div className="ml-2 mb-3">
                <LoadingSpinner inline={true} size="sm" />
            </div>
        );
    }

    if (isLoading === false && isFetching === false && areas.length === 0) {
        return (
            <div className="text-muted small ml-3 mb-4">{t('document.navbar.main.titles.subTitles.noArticles')}</div>
        );
    }

    return (
        <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
            <Droppable
                droppableId={`droppable-section-${section.id}`}
                isDropDisabled={restrictions?.canMoveArticles === false}
            >
                {(provided, snapshot) => (
                    <div
                        className={cx('section-areas pt-2 mb-3', {
                            'is-dragging-over': snapshot.isDraggingOver,
                        })}
                        style={{ marginLeft: -28 }}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {areas.map((area, index) => (
                            <Area
                                area={area}
                                canMoveArticles={restrictions?.canMoveArticles}
                                index={index}
                                document={document}
                                toggleMoveModal={toggleMoveModal}
                                key={`area-${area.id}`}
                            />
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );

    function onDragEnd(result) {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const areasReordered = HelperFunctions.arrayMove(areas, result.source.index, result.destination.index)
            .map((area, index) => {
                return {
                    ...area,
                    oldSortOrder: area.sortOrder,
                    sortOrder: index,
                };
            })
            .filter((area) => area.sortOrder !== area.oldSortOrder);

        updateAreaSortOrder({
            sectionId: section.id,
            body: areasReordered.map((area) => ({
                id: area.id,
                sortOrder: area.sortOrder,
                section: area.sectionId,
            })),
        });
    }
}
