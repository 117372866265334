import { useState } from 'react';
import PageTitle from '../../../global/PageTitle';
import { openExportChanges } from '../../helpers/PublicationActions';
import { useDispatch } from 'react-redux';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

export default function SelectExportDate({ closeModal, publication }) {
    const [changesSinceDate, setChangesSinceDate] = useState('');
    const [changesSinceTime, setChangesSinceTime] = useState('');
    const dispatch = useDispatch();
    const { t } = useTranslation('publications');

    const onDateChange = (event) => {
        setChangesSinceDate(event.target.value);
    };

    const onTimeChange = (event) => {
        setChangesSinceTime(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        // Resolve timestamp and set it.
        const dateString = `${changesSinceDate} ${changesSinceTime}`;
        const changesSinceTimestamp = DateTime.fromFormat(dateString, 'yyyy-MM-dd hh:mm').toSeconds();

        openExportChanges(dispatch, publication, changesSinceTimestamp).then(() => {
            closeModal();
        });
    };

    return (
        // MinWidth is so loading animation does not overlap with close icon
        <div className="uk-padding-medium" style={{ minWidth: 500 }}>
            <form name="changesSinceForm" onSubmit={handleSubmit}>
                <div className="uk-position-relative">
                    <PageTitle
                        pageTitle={t('publication.dropdown.changesModal.title')}
                        marginTop="none"
                        marginBottom="none"
                    />
                </div>
                <div className="uk-margin">
                    <label className="uk-form-label">{t('publication.dropdown.changesModal.whatDateMessage')}</label>
                    <div className="uk-form-controls">
                        <div>
                            <input
                                required
                                type="date"
                                className="uk-input uk-form-width-medium"
                                name="startDate"
                                id="startDate"
                                value={changesSinceDate}
                                placeholder="jjjj-mm-dd"
                                onChange={onDateChange}
                                max={DateTime.now().toFormat('yyyy-MM-dd')}
                            />
                        </div>
                        <div>
                            <input
                                type="time"
                                className="uk-input uk-form-width-medium uk-margin-xsmall-top"
                                name="startTime"
                                id="startTime"
                                value={changesSinceTime}
                                onChange={onTimeChange}
                                placeholder="HH:mm"
                                required
                                pattern="[0-9]{2}:[0-9]{2}"
                            />
                        </div>
                    </div>
                </div>

                <div className="uk-clearfix uk-padding-top uk-text-right">
                    <button className="btn btn-secondary" onClick={closeModal}>
                        {t('btn.cancel')}
                    </button>

                    <button
                        className="uk-button uk-button-primary uk-margin-small-right uk-margin-small-left"
                        type="submit"
                        disabled={_.isEmpty(changesSinceDate) || _.isEmpty(changesSinceTime)}
                    >
                        {t('publication.dropdown.changesModal.btn.createDocument')}
                    </button>
                </div>
            </form>
        </div>
    );
}
