import { InputField } from 'pages/publications_v2/helpers/FieldHelper';
import { FormGroup } from 'components/SettingsSidebar/helpers/FormHelpers';

export function Topic() {
    return (
        <div className="sidebar-form">
            <FormGroup label="Naam *" htmlFor="name" help="De naam van het onderwerp.">
                <InputField
                    name="settings.name"
                    props={{
                        maxLength: 255,
                        required: true,
                        id: 'name',
                        placeholder: 'Naam van het onderwerp',
                    }}
                />
            </FormGroup>
        </div>
    );
}
