import { useDispatch, useSelector } from 'react-redux';
import HelperFunctions from '../global/HelperFunctions';
import { set } from '../../features/app/appSlice';
import TagHelper from '../global/TagHelper';
import { ChatDotsFill, ChatFill, CircleFill } from 'react-bootstrap-icons';
import cx from 'classnames';
import { useGetLabelFoldersQuery, useGetLabelsQuery } from '../../features/metadata/metadata';
import { useOrganisationTags } from './CommentTags';
import { useGetParentCommentsQuery } from '../../features/comments/commentApi';
import { useSidebarContext } from '../../components/SettingsSidebar/hooks/useSidebarContext';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

export default function CommentButton({
    uri,
    parentUri,
    entity,
    entityType,
    documentId = undefined,
    filterByLabels = [],
    until = undefined,
}) {
    const dispatch = useDispatch();
    const { activeOrganisation } = useSelector((state) => state.security);
    const organisationTags = useOrganisationTags();
    const sidebarContext = useSidebarContext();
    const { t } = useTranslation('reimbursements');

    const { comments } = useGetParentCommentsQuery(parentUri, {
        selectFromResult: ({ data }) => {
            let comments = data ? data.filter((comment) => comment.context === uri) : [];

            if (filterByLabels.length > 0) {
                comments = comments.filter((comment) =>
                    comment.tags.some((_labelUri) => filterByLabels.includes(_labelUri.uri))
                );
            }

            // If until is given, filter out any comments created AFTER the until timestamp
            if (until !== undefined) {
                comments = comments.filter((comment) => DateTime.fromISO(comment.createdAt).toMillis() < until);
            }

            return {
                comments,
            };
        },
    });

    const { labelFolders = [] } = useGetLabelFoldersQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                labelFolders: data
                    ? data.filter((folder) => folder.contentTypes.includes('comments')).map((folder) => folder['@id'])
                    : [],
            }),
            skip: comments.length === 0,
        }
    );

    const { availableTags = [] } = useGetLabelsQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                availableTags: data ? data.filter((label) => labelFolders.includes(label.folder)) : organisationTags,
            }),
            skip: comments.length === 0 || labelFolders.length === 0,
        }
    );

    const handleClick = () => {
        if (uri === undefined) {
            return;
        }

        dispatch(
            set({
                key: 'sidebarContext',
                value: {
                    type: 'sidebarSettings',
                    context: { entity, entityType, documentId, landingTab: 'comments', uri, parentUri },
                },
            })
        );
    };

    const isAlert = comments.length > 0 ? getAlertStatus(comments, availableTags) : false;
    const hasUnresolvedComments = comments.some((_comment) => !_comment.resolved);
    const iconWarningStyle = {
        fontSize: 10,
        position: 'absolute',
        top: -1,
        left: 13,
        filter: 'drop-shadow(0 1px 1px rgba(0, 0, 0, .25))',
    };
    const entitySelected = sidebarContext?.uri === uri;
    const iconClass = cx('uk-text-theme', {
        'text-highlighted': hasUnresolvedComments,
    });

    if (entitySelected) {
        return (
            <div className="position-relative">
                <ChatDotsFill className={iconClass} size={18} />
                {isAlert && <CircleFill className="text-danger" style={iconWarningStyle} />}
            </div>
        );
    }

    return (
        <div
            style={{
                opacity: uri === undefined ? '0.5' : undefined,
            }}
            className={cx('position-relative', {
                'cursor-pointer': uri !== undefined,
            })}
            onClick={() => handleClick()}
        >
            <ChatFill
                uk-tooltip={
                    uri === undefined
                        ? undefined
                        : comments.length > 0
                        ? t('reimbursement.product.comment.commentsPosted')
                        : t('reimbursement.product.comment.noComment')
                }
                className={iconClass}
                size={18}
            />
            {isAlert && <CircleFill className="text-danger" style={iconWarningStyle} />}
        </div>
    );
}

const getAlertStatus = (comments = [], availableTags) => {
    return comments
        .filter((_comment) => !_comment.resolved)
        .some((_comment) => {
            const tags = _comment?.tags ?? [];

            return tags.some((_tag) => {
                let color = '';

                if (_tag.hasOwnProperty('uri')) {
                    const tagData = HelperFunctions.getByValue(availableTags, '@id', _tag.uri);
                    color = TagHelper.getTagColor(tagData) ?? '';
                } else {
                    const tagData = HelperFunctions.getByValue(availableTags, 'id', _tag.id);
                    color = tagData?.color ?? '';
                }

                return color === 'alert';
            });
        });
};
