import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { useGetTaskTeamAssignmentsQuery } from 'features/comments/commentApi';
import { JsonParam, NumberParam, StringParam, useQueryParam } from 'use-query-params';
import _ from 'lodash';
import HelperFunctions from 'pages/global/HelperFunctions';

export function useGetTaskTeamAssignments(filterParamName, defaultFilterOptions = {}) {
    const [search] = useQueryParam(filterParamName + 'search', StringParam);
    const [page = 1] = useQueryParam(filterParamName + 'page', NumberParam);
    const organisationId = useActiveOrganisation();
    const [filterOptions = {}] = useQueryParam(filterParamName, JsonParam);

    const combinedFilterOptions = {
        ...defaultFilterOptions,
        ...filterOptions,
    };

    let params = {
        page,
        organisationId,
        'order[id]': 'desc',
    };

    if (search) {
        params['or[task.title]'] = search;
        params['or[task.sequence]'] = search;
    }

    if (!_.isEmpty(combinedFilterOptions)) {
        Object.keys(combinedFilterOptions).forEach((filterKey) => {
            const filterValue = combinedFilterOptions[filterKey];

            if (!_.isEmpty(filterValue)) {
                params[`and[${filterKey}]`] = filterValue.trim();
            }
        });
    }

    // Filter deletedAt
    params['exists[task.deletedAt]'] = false;

    return useGetTaskTeamAssignmentsQuery(
        { params },
        {
            selectFromResult: (result) => HelperFunctions.queryResult(result),
        },
    );
}
