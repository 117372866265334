import { useState } from 'react';
import CommentTags from './CommentTags';
import { DateTime } from 'luxon';
import Parser from 'html-react-parser';
import cx from 'classnames';
import { SecondaryButton, WarningButton } from '../../../Buttons';
import { useUpdateCommentMutation } from 'features/comments/commentApi';
import { useTranslation } from 'react-i18next';
import { useGetCommentTags } from 'pages/comments/hooks/useGetCommentTags';
import HelperFunctions from 'pages/global/HelperFunctions';
import { useUserProfile } from 'hooks/useUserProfile';

export function EditComment({
    cancelEdit,
    comment,
    isDocumentManager,
    textClass,
    allowTags = true,
    requireTagInComment = false,
}) {
    const { t } = useTranslation('documents');
    const userProfile = useUserProfile();
    const commentTags = useGetCommentTags(comment);
    const [updateComment] = useUpdateCommentMutation();
    const [text, setText] = useState(comment.text);
    const [tags, setTags] = useState(HelperFunctions.prepareDropdownData(commentTags));
    const [saveInProgress, setSaveInProgress] = useState(false);

    const isReply = comment.parent !== null;
    const isCreator = comment.createdBy === userProfile.userId;

    return (
        <div className="mt-3" onClick={(e) => e.stopPropagation()}>
            <div>
                {/* If only documentManager (and not creator of the comment) then you'll only be able to edit the tag.
            Not the content of the comment*/}
                {!isCreator && isDocumentManager ? (
                    <span className={textClass}>{Parser(comment.text)}</span>
                ) : (
                    <div className="mb-3">
                        <textarea
                            className="form-control"
                            rows={2}
                            id="text"
                            name="text"
                            maxLength={500}
                            onChange={handleFormChange}
                            value={text}
                            autoFocus={true}
                        />
                    </div>
                )}

                {!isReply && allowTags && (
                    <div
                        className={cx({ 'tags-invalid': text.length > 0 && requireTagInComment && tags.length === 0 })}
                    >
                        <CommentTags tags={tags} setTags={setTags} />
                    </div>
                )}

                <div className="mt-3 d-flex justify-content-end">
                    <SecondaryButton disabled={saveInProgress} className="mr-2" onClick={cancelEdit}>
                        {t('document.navbar.main.settingsSidebar.articles.comments.comment.edit.cancel')}
                    </SecondaryButton>
                    <WarningButton disabled={!formIsValid() || saveInProgress} onClick={() => handleFormSubmit()}>
                        {t('document.navbar.main.settingsSidebar.articles.comments.comment.edit.save')}
                    </WarningButton>
                </div>
            </div>
        </div>
    );

    function handleFormChange(e) {
        setText(e.target.value);
    }

    function handleFormSubmit() {
        const textEdit = comment.text !== text;
        const tagEdit = JSON.stringify(comment.tags) !== JSON.stringify(tags);

        if (!textEdit && !tagEdit) {
            // No changes
            cancelEdit();
            return;
        }

        setSaveInProgress(true);

        updateComment({
            uri: comment['@id'],
            body: {
                text: text,
                tags: tags.map((tag) => ({
                    uri: tag['@id'],
                })),
                updatedAt: DateTime.local().toISO(),
            },
        }).then(() => {
            setSaveInProgress(false);
            cancelEdit();
        });
    }

    function formIsValid() {
        if (text === '') {
            return false;
        }

        if (requireTagInComment && tags.length === 0) {
            return false;
        }

        return true;
    }
}
