import { Dropdown } from 'react-bootstrap';

export default function DropdownMenu({ align = 'left', children }) {
    return (
        <Dropdown.Menu
            align={align}
            popperConfig={{
                strategy: 'fixed',
            }}
        >
            {children}
        </Dropdown.Menu>
    );
}
