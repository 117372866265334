import React, { useEffect, useState } from 'react';
import { Dropdown, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import {
    fetchOperationsLists,
    fetchOpListDepartments,
    fetchOpListTeamsForDepartment,
    removeOpTeam,
    selectOpListById,
    selectTeamsForDepartment,
} from '../../../features/operationsList/operationsListSlice';
import { useDispatch, useSelector } from 'react-redux';
import HelperFunctions from '../../global/HelperFunctions';
import classNames from 'classnames';
import { ThreeDotsVertical } from 'react-bootstrap-icons';
import { selectDepartmentUserIsManagerOf } from '../../../features/operationsList/opListUserSlice';
import LoadingSpinner from '../../global/LoadingSpinner';
import EditTeamModal from './EditTeamModal';
import { useTranslation } from 'react-i18next';

export default function Teams({ showEditModal, setShowEditModal }) {
    const { id } = useParams();
    const dispatch = useDispatch();
    const opList = useSelector((state) => selectOpListById(state.operations, id));

    useEffect(() => {
        if (!opList) {
            dispatch(fetchOperationsLists());
        }
    }, [dispatch, opList]);

    if (!opList) {
        return null;
    }

    return <TeamsOverview opList={opList} showEditModal={showEditModal} setShowEditModal={setShowEditModal} />;
}

function TeamsOverview({ opList, showEditModal, setShowEditModal }) {
    const department = useSelector(selectDepartmentUserIsManagerOf(opList));
    const teams = useSelector(selectTeamsForDepartment(department));
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation('changelist');

    const dispatch = useDispatch();

    useEffect(() => {
        if (loading && !department) {
            dispatch(fetchOpListDepartments({ opListId: opList.id }));
        }
    }, [dispatch, opList]);

    useEffect(() => {
        if (loading && department) {
            dispatch(fetchOpListTeamsForDepartment({ opDepartmentId: department.id })).then(() => setLoading(false));
        }
    }, [dispatch, department]);

    if (loading) {
        return <LoadingSpinner />;
    }

    if (!department) {
        return `${t('changelist.export.export.noAccess')}`;
    }

    return (
        <>
            <TeamRows opList={opList} department={department} teams={teams} setShowEditModal={setShowEditModal} />

            {showEditModal !== false && (
                <EditTeamModal
                    showModal={true}
                    handleClose={() => setShowEditModal(false)}
                    opList={opList}
                    department={department}
                    team={showEditModal}
                />
            )}
        </>
    );
}

function TeamRows({ teams, setShowEditModal }) {
    if (!teams) {
        return null;
    }
    const { t } = useTranslation('changelist');

    const nonDeletedTeams = teams.filter((team) => !team.deletedAt);

    return (
        <>
            {nonDeletedTeams.length > 0 && (
                <Table hover className="mb-4">
                    <thead>
                        <tr>
                            <th className="border-top-0" width="90%">
                                {t('changelist.teams.name')}
                            </th>
                            <th className="border-top-0" width="10%" />
                        </tr>
                    </thead>
                    <tbody>
                        {nonDeletedTeams.map((team) => {
                            return <TeamRow team={team} setShowEditModal={setShowEditModal} key={team.id} />;
                        })}
                    </tbody>
                </Table>
            )}

            {nonDeletedTeams.length === 0 && <p className="pt-3 text-muted">{t('changelist.teams.noTeams')}</p>}
        </>
    );
}

function TeamRow({ team, setShowEditModal }) {
    const [isHover, setHover] = useState(false);

    return (
        <tr
            onClick={() => setShowEditModal(team)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            key={team.id}
        >
            <td>{team.name}</td>
            <td>
                <Actions edit={setShowEditModal} team={team} isHover={isHover} />
            </td>
        </tr>
    );
}

const Actions = ({ team, isHover, edit }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('changelist');

    const confirmDelete = () => {
        HelperFunctions.confirmModal(
            t('changelist.teams.deleteTeam'),
            'danger',
            false,
            t('changelist.teams.btn.yesDelete'),
            t('btn.cancel')
        ).then(() => dispatch(removeOpTeam({ team })));
    };

    return (
        <div
            className={classNames('d-flex flex-column align-items-end', { invisible: !isHover })}
            onClick={(e) => e.stopPropagation()}
        >
            <Dropdown>
                <Dropdown.Toggle bsPrefix="docrev-dropdown-toggle" as="span" id="dropdown-basic">
                    <ThreeDotsVertical />
                </Dropdown.Toggle>

                <Dropdown.Menu alignRight align="right">
                    <Dropdown.Item onClick={() => edit(team)}>{t('changelist.teams.btn.edit')}</Dropdown.Item>
                    <Dropdown.Item className="text-danger" onClick={confirmDelete}>
                        {t('changelist.teams.btn.delete')}
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};
