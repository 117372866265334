import React, { useContext, useEffect, useMemo, useState } from 'react';
import './SettingsSidebar';
import { useDispatch, useSelector } from 'react-redux';
import { set } from 'features/app/appSlice';
import { Navbar } from 'react-bootstrap';
import { EditLabels } from './views/EditLabels';
import { EditComments } from './views/EditComments';
import { EditVariants } from './views/EditVariants';
import { Form, Formik } from 'formik';
import { SecondaryButton, WarningButton } from '../Buttons';
import { useGetDocument } from 'pages/documents_v2/hooks/useGetDocument';
import {
    useAddLabelEntitiesMutation,
    useDeleteLabelEntitiesMutation,
    useGetLabelEntitiesQuery, useUpdateOneLinerCategoryMutation, useUpdateOneLinerMutation,
} from 'features/metadata/metadata';
import { useGetLabels } from 'pages/labels/hooks/useGetLabels';
import HelperFunctions from '../../pages/global/HelperFunctions';
import EditTemplateParameters from './views/EditTemplateParameters';
import { useSidebarContext } from './hooks/useSidebarContext';
import { entityTypes } from 'pages/translation/config/Constants';
import Spinner from '../../pages/global/Spinner';
import _ from 'lodash';
import { SlideContext } from 'pages/global/Sidebar';
import { EditSettings } from './views/EditSettings';
import { useHasModuleEnabled } from 'pages/global/RestrictedContent';
import Constants from '../../config/Constants';
import cx from 'classnames';
import { OpEntryVariants } from './views/OpEntryVariants';
import { fileOptions } from 'pages/documents_v2/views/edit_area/blocks/block_types/FileBlock';
import { useUpdateAreaMutation, useUpdateSectionsMutation } from 'features/documents/documents';
import { useEntityTemplateParameters } from 'pages/documents_v2/hooks/useEntityTemplateParameters';
import BlockType from './views/BlockTypes';
import { useTranslation } from 'react-i18next';
import EditAreaIcon from './views/EditAreaIcon';
import {
    useUpdateBrandMutation,
    useUpdateCustomerJourneyMutation,
    useUpdateDepartmentMutation, useUpdateRejectionReasonMutation,
    useUpdateTargetAudienceMutation,
    useUpdateTaskTypeMutation,
    useUpdateTeamMutation,
    useUpdateTeamUserMutation,
    useUpdateTopicMutation,
} from 'features/comments/commentApi';
import { Brands } from 'components/SettingsSidebar/views/Tasks/Brands';

export function SettingsSidebar() {
    const { t } = useTranslation('documents');
    const [addLabelEntities] = useAddLabelEntitiesMutation();
    const [deleteLabelEntities] = useDeleteLabelEntitiesMutation();
    const [updateArea] = useUpdateAreaMutation();
    const [updateSections] = useUpdateSectionsMutation();
    const [updateTeam] = useUpdateTeamMutation();
    const [updateTeamUser] = useUpdateTeamUserMutation();
    const [updateDepartment] = useUpdateDepartmentMutation();
    const [updateCustomerJourney] = useUpdateCustomerJourneyMutation();
    const [updateTaskType] = useUpdateTaskTypeMutation();
    const [updateBrand] = useUpdateBrandMutation();
    const [updateTopic] = useUpdateTopicMutation();
    const [updateTargetAudience] = useUpdateTargetAudienceMutation();
    const [updateRejection] = useUpdateRejectionReasonMutation();
    const [updateOneLinerCategory] = useUpdateOneLinerCategoryMutation();
    const [updateOneLiner] = useUpdateOneLinerMutation();

    const dispatch = useDispatch();
    const { entity, entityType, documentId, landingTab } = useSidebarContext();
    const { activeOrganisation } = useSelector((state) => state.security);
    const organisationLabels = useGetLabels();
    const [activeTab, setActiveTab] = useState(landingTab);
    const { size, setSize } = useContext(SlideContext);
    const hasCommentsModuleEnabled = useHasModuleEnabled(Constants.modules.comments);
    const document = useGetDocument(documentId);
    const restrictions = useEntityTemplateParameters(entity, entityType, document);
    const [localEntity, setLocalEntity] = useState(entity);
    const userDocumentRole = document?.currentUserRole;
    const userIsSpectator = userDocumentRole === Constants.userDocumentRole.spectator;

    useEffect(() => {
        if (activeTab === 'variants' && entityType === entityTypes.AREA) {
            if (size === 'sm') {
                setSize('lg');
            }

            return;
        }

        if (activeTab !== 'labels' && size === 'lg') {
            setSize('sm');
        }
    }, [activeTab]);

    const { entityLabelEntities } = useGetLabelEntitiesQuery(
        {
            entityType,
            entityId: entity.id,
            organisationId: activeOrganisation,
        },
        {
            selectFromResult: ({ data }) => ({
                entityLabelEntities: data ?? [],
            }),
        },
    );
    const entityLabels = useMemo(() => {
        return HelperFunctions.prepareDropdownData(
            entityLabelEntities
                .filter((_item) => organisationLabels.some((label) => label['@id'] === _item.label))
                .map((_item) => ({
                    ...HelperFunctions.getByValue(organisationLabels, '@id', _item.label),
                    labelEntity: _item,
                }))
                .sort(HelperFunctions.dynamicSort('name')),
            'name',
            '@id',
        );
    }, [entityLabelEntities, organisationLabels]);

    const handleTabChange = (tabId) => {
        setActiveTab(tabId);
    };

    const allViews = [
        {
            id: 'settings',
            name: t('document.navbar.main.settingsSidebar.titles.navbar.settings'),
            entityTypes: [
                entityTypes.SECTION,
                entityTypes.AREA,
                entityTypes.BLOCK,
                entityTypes.TEAM,
                entityTypes.TEAM_USER,
                entityTypes.DEPARTMENT,
                entityTypes.CUSTOMER_JOURNEY,
                entityTypes.TASK_TYPE,
                entityTypes.BRAND,
                entityTypes.TOPIC,
                entityTypes.TARGET_AUDIENCE,
                entityTypes.REJECTION_REASON,
                entityTypes.ONE_LINER_CATEGORY,
                entityTypes.ONE_LINER,
            ],
            hasPermission: !userIsSpectator && (restrictions === undefined || restrictions.canSeeSettings),
        },
        {
            id: 'brands',
            name: 'Merk, onderwerp en doelgroep',
            entityTypes: [entityTypes.TEAM],
            hasPermission: true,
        },
        {
            id: 'checklist',
            name: 'Checklist',
            entityTypes: [entityTypes.TEAM],
            hasPermission: true,
        },
        {
            id: 'variants',
            name: t('document.navbar.main.settingsSidebar.titles.navbar.variants'),
            entityTypes: [entityTypes.SECTION, entityTypes.AREA, entityTypes.BLOCK],
            hasPermission: !userIsSpectator && (restrictions === undefined || restrictions.canSeeVariantsSettings),
        },
        {
            id: 'opEntry_variants',
            name: t('document.navbar.main.settingsSidebar.titles.navbar.variants'),
            entityTypes: [entityTypes.OP_ENTRY_VARIANT],
            hasPermission: true,
        },
        {
            id: 'template',
            name: t('document.navbar.main.settingsSidebar.titles.navbar.template'),
            entityTypes: [entityTypes.SECTION, entityTypes.AREA, entityTypes.BLOCK],
            hasPermission: !userIsSpectator && document?.type === 'template',
        },
        {
            id: 'labels',
            name: t('document.navbar.main.settingsSidebar.titles.navbar.labels'),
            entityTypes: [
                entityTypes.SECTION,
                entityTypes.AREA,
                entityTypes.BLOCK,
                entityTypes.REIMBURSEMENT,
                entityTypes.VARIANT_PACKAGE,
                entityTypes.COLLECTIVE,
                entityTypes.PRODUCT,
                entityTypes.PUBLICATION,
            ],
            hasPermission: !userIsSpectator && (restrictions === undefined || restrictions.canSeeLabelsSettings),
            disabled: !entity.id,
        },
        {
            id: 'comments',
            name: t('document.navbar.main.settingsSidebar.titles.navbar.comments'),
            entityTypes: [
                entityTypes.AREA,
                entityTypes.BLOCK,
                entityTypes.OP_ENTRY,
                entityTypes.OP_ENTRY_VARIANT,
                entityTypes.OP_ENTRY_LABEL,
                entityTypes.REIMBURSEMENT_PRODUCT,
            ],
            hasPermission: hasCommentsModuleEnabled,
            disabled: !entity.id,
        },
        {
            id: 'areaIcon',
            name: t('document.navbar.main.settingsSidebar.articles.edit.selectIcon'),
            entityTypes: [entityTypes.AREA],
            hasPermission: !userIsSpectator && (restrictions === undefined || restrictions.canModifyIcon),
            disabled: !entity.id,
        },
    ];

    const viewsWithComponents = useMemo(() => {
        return allViews.filter((view) => {
            // Check if view applies to the entity type
            if (!view.entityTypes.includes(entityType)) {
                return false;
            }
            // Check if permissions resolve to true
            return view.hasPermission;
        });
    }, [allViews, entityType, userIsSpectator]);

    useEffect(() => {
        if (activeTab === undefined) {
            // Set the active tab to the first one available, else show empty message
            setActiveTab(viewsWithComponents.length > 0 ? viewsWithComponents[0].id : 'empty');
        } else {
            // Exception handling
            if (viewsWithComponents.length === 0) {
                setActiveTab('empty');
            } else {
                // Check if activeTab is available
                const views = viewsWithComponents.map((view) => view.id);

                if (views.includes(activeTab) === false) {
                    // Set to the first tab available
                    setActiveTab(viewsWithComponents[0].id);
                }
            }
        }
    }, [viewsWithComponents, activeTab]);

    const closeSidebar = () => {
        dispatch(set({ key: 'sidebarContext', value: null }));
    };

    const updateSettings = (values) => {
        return new Promise((resolve, reject) => {
            switch (entityType) {
                case entityTypes.AREA:
                    updateArea({ areaId: values.id, body: values }).then(({ data }) => {
                        resolve(data);
                    });

                    break;

                case entityTypes.SECTION:
                    updateSections({
                        documentId,
                        body: [
                            {
                                ...values,
                                addArea: false,
                            },
                        ],
                    }).then(({ data }) => {
                        resolve(data[0]);
                    });

                    break;

                case entityTypes.BLOCK:
                    //Add the endpoint here
                    break;

                case entityTypes.TEAM:
                    updateTeam({ uri: values['@id'], body: values }).then(({ data }) => {
                        resolve(data);
                    });
                    break;

                case entityTypes.TEAM_USER:
                    updateTeamUser({ uri: values['@id'], body: values }).then(({ data }) => {
                        resolve(data);
                    });
                    break;

                case entityTypes.DEPARTMENT:
                    updateDepartment({ uri: values['@id'], body: values }).then(({ data }) => {
                        resolve(data);
                    });
                    break;

                case entityTypes.CUSTOMER_JOURNEY:
                    updateCustomerJourney({ uri: values['@id'], body: values }).then(({ data }) => {
                        resolve(data);
                    });
                    break;

                case entityTypes.TASK_TYPE:
                    updateTaskType({ uri: values['@id'], body: values }).then(({ data }) => {
                        resolve(data);
                    });
                    break;

                case entityTypes.BRAND:
                    updateBrand({ uri: values['@id'], body: values }).then(({ data }) => {
                        resolve(data);
                    });
                    break;

                case entityTypes.TOPIC:
                    updateTopic({ uri: values['@id'], body: values }).then(({ data }) => {
                        resolve(data);
                    });
                    break;

                case entityTypes.TARGET_AUDIENCE:
                    updateTargetAudience({ uri: values['@id'], body: values }).then(({ data }) => {
                        resolve(data);
                    });
                    break;

                case entityTypes.REJECTION_REASON:
                    updateRejection({ uri: values['@id'], body: values }).then(({ data }) => {
                        resolve(data);
                    });
                    break;

                case entityTypes.ONE_LINER_CATEGORY:
                    updateOneLinerCategory({ uri: values['@id'], body: values }).then(({ data }) => {
                        resolve(data);
                    });
                    break;

                case entityTypes.ONE_LINER:
                    updateOneLiner({ uri: values['@id'], body: values }).then(({ data }) => {
                        resolve(data);
                    });
                    break;

                default:
                    resolve();
            }
        });
    };

    const updateEntityLabels = (values) => {
        return new Promise((resolve, reject) => {
            const handleDelete = (values) => {
                return new Promise((resolve, reject) => {
                    const deletedLabelEntities = entityLabelEntities
                        .filter(
                            (_labelEntity) =>
                                values.find((_label) => _label.labelEntity.id === _labelEntity.id) === undefined,
                        )
                        .map((_labelEntity) => _labelEntity['@id']);

                    if (deletedLabelEntities.length === 0) {
                        resolve([]);
                        return;
                    }

                    deleteLabelEntities(deletedLabelEntities).then(() => {
                        resolve([]);
                    });
                });
            };

            const handleCreate = (values) => {
                return new Promise((resolve, reject) => {
                    const addedLabels = values.filter((_item) => _item.labelEntity.id === undefined);

                    if (addedLabels.length === 0) {
                        resolve([]);
                        return;
                    }

                    addLabelEntities(
                        addedLabels.map((_item) => ({
                            entityType,
                            entityId: `${entity.id}`,
                            label: _item.value,
                            organisationId: activeOrganisation,
                            documentId,
                            variantId: _item?.labelEntity?.variantId ?? null,
                        })),
                    ).then(() => {
                        resolve([]);
                    });
                });
            };

            Promise.allSettled([handleDelete(values), handleCreate(values)]).then((results) => {
                resolve(results);
            });
        });
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        // When entity is block, update store
        if (entityType === entityTypes.BLOCK) {
            // Check if we need to save the entity labels
            if (_.isEqual(values['entityLabels'], entityLabels) === false) {
                updateEntityLabels(values['entityLabels']);
            }

            // Dispatch action to update the store
            dispatch(
                set({
                    key: 'sidebarEntityUpdate',
                    value: {
                        values,
                        entity,
                        entityType,
                    },
                }),
            );

            setLocalEntity(values.settings);
            setSubmitting(false);

            return;
        }

        // Save changes immediately
        const promises = Object.keys(values).map((key) => {
            return new Promise((resolve, reject) => {
                switch (key) {
                    case 'settings':
                        // Check if the objects differ
                        if (_.isEqual(values[key], localEntity)) {
                            resolve(values[key]);
                            break;
                        }

                        updateSettings(values[key]).then((result) => {
                            if (result) {
                                setLocalEntity(result);
                            }

                            resolve(result);
                        });

                        break;
                    case 'entityLabels':
                        // Check if the objects differ
                        if (_.isEqual(values[key], entityLabels)) {
                            resolve(values[key]);
                            break;
                        }

                        updateEntityLabels(values[key]).then((result) => {
                            resolve(result);
                        });

                        break;
                }
            });
        });

        Promise.allSettled(promises).then(() => {
            setSubmitting(false);
        });
    };

    const prepareEntity = (_entity) => {
        if (_entity.type === Constants.blockTypes.file) {
            const options = fileOptions(_entity);
            const properties = _entity.properties ?? {};

            return {
                ..._entity,
                properties: {
                    ...properties,
                    options,
                },
            };
        }

        if (_.isArray(_entity.properties) && _entity.properties.length === 0) {
            return {
                ..._entity,
                properties: {},
            };
        }

        return _entity;
    };

    const initialValues = {
        settings: prepareEntity(localEntity),
        entityLabels,
    };

    return (
        <div className="position-relative h-100">
            <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
                {({ isValid, isSubmitting, dirty, values }) => (
                    <Form>
                        <div className="fixed-top" style={{ position: 'sticky', zIndex: 'initial' }}>
                            <div className="sidebar-top d-flex justify-content-between border-bottom align-items-center">
                                <EntityTitle />
                                <div className="d-flex align-items-center sidebar-top-buttons">
                                    {activeTab === 'comments' ? (
                                        <SecondaryButton onClick={() => closeSidebar()}>
                                            {t('btn.cancel')}
                                        </SecondaryButton>
                                    ) : (
                                        <>
                                            {isSubmitting && <Spinner />}
                                            <SecondaryButton onClick={() => closeSidebar()}>
                                                {t('btn.cancel')}
                                            </SecondaryButton>
                                            <WarningButton
                                                className="ml-2"
                                                disabled={!dirty || !isValid || isSubmitting}
                                                type="submit"
                                            >
                                                {t('btn.save')}
                                            </WarningButton>
                                        </>
                                    )}
                                </div>
                            </div>

                            <Navbar align="left" className="bg-white border-bottom px-0 py-0">
                                <Navbar.Collapse className="ml-2">
                                    <ul className="navbar-nav mr-auto ml-2">
                                        {viewsWithComponents.map((view) => (
                                            <li
                                                key={view.id}
                                                className={cx('nav-item', {
                                                    active: activeTab === view.id,
                                                })}
                                            >
                                                <a
                                                    href="#"
                                                    className={cx('nav-link mr-3', {
                                                        disabled: view.disabled,
                                                    })}
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        handleTabChange(view.id);
                                                    }}
                                                >
                                                    {view.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </Navbar.Collapse>
                            </Navbar>
                        </div>

                        <div
                            className="position-absolute overflow-auto"
                            style={{ top: 129, right: 0, bottom: 0, left: 0 }}
                        >
                            <div className="p-4">
                                <SidebarContent document={document} activeTab={activeTab} />
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

function EntityTitle() {
    const { entity, entityType } = useSidebarContext();
    const { t } = useTranslation('documents');

    return (
        <div
            className="sidebar-top-item-name"
            style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '370px',
            }}
        >
            {entityType === entityTypes.SECTION && (
                <>{t('document.navbar.main.settingsSidebar.titles.chapterTitle')}: </>
            )}
            {(entityType === entityTypes.AREA || entityType === entityTypes.OP_ENTRY) && (
                <>{t('document.navbar.main.settingsSidebar.titles.articleTitle')}: </>
            )}
            {entityType === entityTypes.BLOCK && <BlockType />}
            {entityType === entityTypes.TEAM && <>Team: {entity.name}</>}
            {entityType === entityTypes.TEAM_USER && <>Gebruiker: {entity.user.fullName}</>}
            {entityType === entityTypes.DEPARTMENT && <>Afdeling: {entity.name}</>}
            {entityType === entityTypes.CUSTOMER_JOURNEY && <>Klantreis: {entity.name}</>}
            {entityType === entityTypes.TASK_TYPE && <>Soort uiting: {entity.name}</>}
            {entityType === entityTypes.BRAND && <>Merk: {entity.name}</>}
            {entityType === entityTypes.TOPIC && <>Onderwerp: {entity.name}</>}
            {entityType === entityTypes.TARGET_AUDIENCE && <>Doelgroep: {entity.name}</>}
            {entityType === entityTypes.REJECTION_REASON && <>Reden: {entity.name}</>}

            <>{entity.title}</>
        </div>
    );
}

function SidebarContent({ activeTab, document }) {
    switch (activeTab) {
        case 'settings':
            return <EditSettings />;
        case 'variants':
            return <EditVariants />;
        case 'opEntry_variants':
            return <OpEntryVariants />;
        case 'template':
            return <EditTemplateParameters document={document} />;
        case 'labels':
            return <EditLabels />;
        case 'comments':
            return <EditComments />;
        case 'areaIcon':
            return <EditAreaIcon />;
        case 'brands':
            return <Brands />;
        case 'empty':
            return <NoSettingsMessage />;
        default:
            return null;
    }
}

function NoSettingsMessage() {
    const { t } = useTranslation('documents');
    return <div>{t('document.navbar.main.settingsSidebar.titles.noSettings')}</div>;
}
