import { FieldArray, useFormikContext } from 'formik';
import { Accordion, AccordionContext, Button, Card, Dropdown, SplitButton } from 'react-bootstrap';
import { useContext } from 'react';
import Constants from '../../../config/Constants';
import EmptyPage from './content/EmptyPage';
import { ArrowDown, ArrowUp, ChevronRight, PencilSquare, Trash3 } from 'react-bootstrap-icons';
import TOC from './content/TOC';
import { generateRandomId } from '../helpers/FormHelper';
import Section from './content/Section';
import _ from 'lodash';
import BackCover from './content/BackCover';
import AddContentSectionModal from '../modals/AddContentSectionModal';
import { useGetDocument } from '../../documents_v2/hooks/useGetDocument';
import { useGetDocumentVariants } from '../../documents_v2/hooks/useGetDocumentVariants';
import { useGetBaseVariant } from '../../documents_v2/hooks/useGetBaseVariant';
import { PublicationProperty, templateProperties, useIsPropertySupported } from './PublicationProperty';
import { PrimaryButton } from '../../../components/Buttons';
import { useDocumentTemplate } from '../hooks/useDocumentTemplate';
import { useTranslation } from 'react-i18next';

export default function Compose({ showAddContentSectionModal, setAddContentSectionModal }) {
    const { values, setFieldValue } = useFormikContext();
    const { contentDecoded = [] } = values;

    return (
        <>
            <FieldArray name="contentDecoded">
                {({ push, remove, move }) => {
                    return (
                        <>
                            <Accordion defaultActiveKey="card-0">
                                {contentDecoded.length > 0 &&
                                    contentDecoded.map((contentItem, index) => {
                                        return (
                                            <Card className="dr-container mb-3" key={`content-card-${contentItem.id}`}>
                                                <ContentItemHeader
                                                    index={index}
                                                    remove={remove}
                                                    move={move}
                                                    values={values}
                                                    contentItem={contentItem}
                                                    setAddContentSectionModal={setAddContentSectionModal}
                                                />
                                                <Accordion.Collapse eventKey={`card-${contentItem.id}`}>
                                                    <Card.Body>
                                                        <ContentItem contentItem={contentItem} index={index} />
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        );
                                    })}
                            </Accordion>

                            {!!showAddContentSectionModal && (
                                <AddContentSectionModal
                                    parentValues={values}
                                    setFieldValue={setFieldValue}
                                    handleClose={() => setAddContentSectionModal(false)}
                                    push={push}
                                    payload={showAddContentSectionModal}
                                />
                            )}
                        </>
                    );
                }}
            </FieldArray>
        </>
    );
}

function ContentItemHeader({ contentItem, index, move, remove, values, setAddContentSectionModal }) {
    const { t } = useTranslation('publications');

    const currentEventKey = useContext(AccordionContext);
    const isExpanded = currentEventKey === `card-${contentItem.id}`;
    const hasDocument = !_.isNil(contentItem.documentId) && contentItem.documentId > 0;

    const contentOptions = [
        {
            value: Constants.publication.contentTypes.toc,
            label: t('publication.settings.content.summary.expand.tableOfContentsPdf'),
        },
        {
            value: Constants.publication.contentTypes.backCover,
            label: t('publication.settings.content.summary.expand.backCoverPdf'),
        },
        {
            value: Constants.publication.contentTypes.emptyPage,
            label: t('publication.settings.content.summary.expand.blankPagePdf'),
        },
    ];
    const contentOption = contentOptions.find((_option) => _option.value === contentItem.contentType);
    const title = contentOption?.label ?? '';

    return (
        <Accordion.Toggle as={Card.Header} eventKey={`card-${contentItem.id}`} className="d-flex cursor-pointer">
            <div className="d-flex flex-fill align-items-center">
                <ChevronRight
                    className="chevron mr-3"
                    style={{
                        transform: isExpanded ? 'rotate(90deg)' : undefined,
                        transition: 'all .15s ease-out',
                    }}
                />

                {contentItem.contentType === Constants.publication.contentTypes.section ? (
                    <SectionTitle contentItem={contentItem} />
                ) : (
                    <div>
                        <div>{title}</div>

                        {hasDocument && <ContentItemSubHeader contentItem={contentItem} />}
                    </div>
                )}
            </div>

            <div className="ml-auto compose-actions d-flex align-items-center flex-row-reverse">
                <Button
                    variant="link"
                    size="sm"
                    className="text-danger px-1 ml-2"
                    onClick={(event) => {
                        event.stopPropagation();
                        remove(index);
                    }}
                    data-uk-tooltip={t('publication.settings.content.summary.expand.btn.deleteChapter')}
                >
                    <Trash3 size={16} />
                </Button>
                <Button
                    variant="link"
                    size="sm"
                    className="text-dark px-1 d-none d-xl-inline-block"
                    disabled={index >= values.contentDecoded.length - 1}
                    onClick={(event) => {
                        event.stopPropagation();
                        move(index, index + 1);
                    }}
                    data-uk-tooltip={t('publication.settings.content.summary.expand.btn.moveDown')}
                >
                    <ArrowDown size={16} />
                </Button>
                <Button
                    variant="link"
                    size="sm"
                    className="text-dark px-1 ml-2 d-none d-xl-inline-block"
                    disabled={index === 0}
                    onClick={(event) => {
                        event.stopPropagation();
                        move(index, index - 1);
                    }}
                    data-uk-tooltip={t('publication.settings.content.summary.expand.btn.moveUp')}
                >
                    <ArrowUp size={16} />
                </Button>
                {contentItem.contentType === Constants.publication.contentTypes.section && (
                    <Button
                        variant="link"
                        size="sm"
                        className="text-dark px-1"
                        onClick={(event) => {
                            event.stopPropagation();
                            setAddContentSectionModal({
                                contentItem,
                                index,
                            });
                        }}
                        data-uk-tooltip={t('publication.settings.content.summary.expand.btn.editChapter')}
                    >
                        <PencilSquare size={16} />
                    </Button>
                )}
            </div>
        </Accordion.Toggle>
    );
}

function SectionTitle({ contentItem }) {
    const document = useGetDocument(contentItem.documentId, false);
    const sections = document?.sections ?? [];
    const section = _.find(sections, ['id', contentItem.sectionId]);
    const { t } = useTranslation('publications');
    const title = _.get(section, 'title', t('publication.settings.content.summary.addChapterModal.chapter'));

    return (
        <div>
            <div data-uk-tooltip={title.length > 80 ? title : undefined}>{_.truncate(title, { length: 80 })}</div>

            <ContentItemSubHeader contentItem={contentItem} document={document} />
        </div>
    );
}

function ContentItemSubHeader({ contentItem, document }) {
    const showDocumentTitle = !_.isNil(contentItem.documentId) && contentItem.documentId > 0;
    const showVariantTitle = !_.isNil(contentItem.variantId) && contentItem.variantId > 0;

    return (
        <>
            {(showDocumentTitle || showVariantTitle) && (
                <div className="font-weight-normal text-color" style={{ fontSize: '12px' }}>
                    {showDocumentTitle && (
                        <>
                            <SectionDocumentTitle name={document?.name} />

                            {showVariantTitle && <span>&nbsp;&nbsp;</span>}
                        </>
                    )}

                    {showVariantTitle && <SectionVariantTitle variantId={contentItem.variantId} document={document} />}
                </div>
            )}
        </>
    );
}

function SectionDocumentTitle({ name }) {
    if (name === undefined) {
        return null;
    }

    return (
        <span>
            Document: <span className="text-body">{name}</span>
        </span>
    );
}

function SectionVariantTitle({ variantId, document = {} }) {
    const documentVariants = useGetDocumentVariants(document?.id);
    const variant = documentVariants.find((item) => item.id === variantId);
    const { t } = useTranslation('publications');

    if (variant === undefined) {
        return null;
    }

    return (
        <span>
            Variant:{' '}
            <span className="text-body">
                {variant.parentId === null
                    ? t('publication.settings.content.summary.expand.basicVariant')
                    : variant.name}
            </span>
        </span>
    );
}

function ContentItem({ contentItem, index }) {
    const currentEventKey = useContext(AccordionContext);
    const isExpanded = currentEventKey === `card-${contentItem.id}`;

    // Until we find a good way to improvement formik performance, just don't render it for now...
    if (!isExpanded) {
        return null;
    }

    switch (contentItem.contentType) {
        case Constants.publication.contentTypes.section:
            return <Section index={index} contentItem={contentItem} />;
        case Constants.publication.contentTypes.toc:
            return <TOC index={index} contentItem={contentItem} />;
        case Constants.publication.contentTypes.emptyPage:
            return <EmptyPage index={index} />;
        case Constants.publication.contentTypes.backCover:
            return <BackCover index={index} contentItem={contentItem} />;
        default:
            return null;
    }
}

export function AddSectionButton({ setAddContentSectionModal }) {
    const { values, setFieldValue } = useFormikContext();
    const { contentDecoded, documentIdLastEdit, pdf, printPdf } = values;
    const { t } = useTranslation('publications');

    const pdfEnabled = useIsPropertySupported(templateProperties.layout.pdf);
    const printPdfEnabled = useIsPropertySupported(templateProperties.layout.printPdf);
    const showAsSplitButton = pdfEnabled || printPdfEnabled;

    const document = useGetDocument(documentIdLastEdit, false);
    const baseVariant = useGetBaseVariant(documentIdLastEdit);
    const documentTemplate = useDocumentTemplate();

    const hasBackCoverTemplates = documentTemplate?.backCoverTemplates?.length > 0 ?? false;

    const push = (newSection) => {
        setFieldValue('contentDecoded', [...contentDecoded, ...[newSection]]);
    };

    const addEmptyPage = () => {
        return {
            id: generateRandomId(contentDecoded),
            title: '1',
            properties: {},
            contentType: Constants.publication.contentTypes.emptyPage,
        };
    };

    const addToc = () => {
        return {
            id: generateRandomId(contentDecoded),
            title: t('publication.settings.content.summary.content'),
            properties: {},
            contentType: Constants.publication.contentTypes.toc,
        };
    };

    const addBackCover = () => {
        return {
            id: generateRandomId(contentDecoded),
            title: '',
            properties: {},
            contentType: Constants.publication.contentTypes.backCover,
            documentId: document?.id ?? null,
            variantId: baseVariant?.id ?? null,
        };
    };

    const handleSelect = (eventKey) => {
        switch (eventKey) {
            case Constants.publication.contentTypes.toc:
                push(addToc());
                break;
            case Constants.publication.contentTypes.emptyPage:
                push(addEmptyPage());
                break;
            case Constants.publication.contentTypes.backCover:
                push(addBackCover());
                break;
            default:
                break;
        }
    };

    if (showAsSplitButton) {
        return (
            <SplitButton
                className="ml-auto"
                id="btn-add-section"
                title={t('publication.settings.content.summary.btn.addChapter')}
                variant="primary"
                size="sm"
                menuAlign="right"
                onClick={() => setAddContentSectionModal({})}
                onSelect={handleSelect}
            >
                <PublicationProperty property={templateProperties.layout.pdf}>
                    <>
                        <Dropdown.Header>PDF</Dropdown.Header>
                        <Dropdown.Item eventKey={Constants.publication.contentTypes.toc} disabled={!pdf}>
                            {t('publication.settings.content.summary.dropdown.tableOfContents')}
                        </Dropdown.Item>

                        {hasBackCoverTemplates && (
                            <Dropdown.Item eventKey={Constants.publication.contentTypes.backCover} disabled={!pdf}>
                                {t('publication.settings.content.summary.dropdown.backCover')}
                            </Dropdown.Item>
                        )}
                    </>
                </PublicationProperty>

                <PublicationProperty property={templateProperties.layout.printPdf}>
                    <>
                        <Dropdown.Divider />
                        <Dropdown.Header>{t('publication.settings.content.summary.dropdown.printPdf')}</Dropdown.Header>
                        <Dropdown.Item eventKey={Constants.publication.contentTypes.emptyPage} disabled={!printPdf}>
                            {t('publication.settings.content.summary.dropdown.emptyPage')}
                        </Dropdown.Item>
                    </>
                </PublicationProperty>
            </SplitButton>
        );
    }

    return (
        <PrimaryButton className="ml-auto" size="sm" onClick={() => setAddContentSectionModal({})}>
            {t('publication.settings.content.summary.btn.addChapter')}
        </PrimaryButton>
    );
}
