import _ from 'lodash';
import Parser from 'html-react-parser';
import { Card } from 'react-bootstrap';
import { getTypeAsString } from '../../../helpers/EntityHelper';
import { findAll } from 'highlight-words-core';
import React, { useContext } from 'react';
import { EditContext } from '../../view/EditContextWrapper';
import { useTranslation } from 'react-i18next';

export default function TagItemProperties({ selectedEntity }) {
    const { search } = useContext(EditContext);
    const { entity, translationKey } = selectedEntity;
    const { property, entityType, entityReferences = [] } = translationKey;
    const { t } = useTranslation('reimbursements');

    let title = _.get(entity, property, '') ?? '';

    if (search !== '') {
        const chunks = findAll({
            searchWords: [search],
            textToHighlight: title,
        });

        title = chunks
            .map((chunk) => {
                const { end, highlight, start } = chunk;
                const text = title.substr(start, end - start);
                if (highlight) {
                    return `<mark>${text}</mark>`;
                } else {
                    return text;
                }
            })
            .join('');
    }

    return (
        <>
            <div className="mb-4" role="table">
                <div
                    style={{
                        lineHeight: '20px',
                    }}
                    className="d-flex"
                    role="row"
                >
                    <div
                        style={{
                            flex: '0 0 100px',
                            color: '#5f6368',
                        }}
                        className=""
                        role="cell"
                    >
                        Type
                    </div>
                    <div
                        style={{
                            flex: '1 1 auto',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                        className=""
                        role="cell"
                    >
                        {getTypeAsString(property, entityType)}
                    </div>
                </div>
            </div>
            <Card className="mb-3">
                <Card.Body>
                    <div className="dr-translation-item p-0">{Parser(title)}</div>
                </Card.Body>
            </Card>
        </>
    );
}
