import { useDispatch, useSelector } from 'react-redux';
import { patchFile, selectFileById } from '../../../features/mediaLibrary/mediaLibrarySlice';
import { Form as FForm, Formik } from 'formik';
import { Button, Form } from 'react-bootstrap';
import { InputField } from '../../publications_v2/helpers/FieldHelper';
import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export default function EditFileProperties({ uri }) {
    const dispatch = useDispatch();
    const file = useSelector((state) => selectFileById(state.mediaLibrary, uri));
    const { t } = useTranslation('documents');

    if (!file) {
        return null;
    }

    return (
        <Formik
            enableReinitialize
            initialValues={{
                displayName: file.displayName,
                description: file.description ?? '',
            }}
            validationSchema={EditFileSchema}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);

                dispatch(
                    patchFile({
                        uri,
                        formData: values,
                    })
                ).finally(() => {
                    setSubmitting(false);
                });
            }}
        >
            {({ dirty, isSubmitting, isValid }) => (
                <FForm autoComplete="off">
                    <Form.Group>
                        <Form.Label htmlFor="displayName">
                            {t('document.navbar.main.editor.left.blocks.types.fileBlock.name')}*
                        </Form.Label>
                        <InputField name="displayName" />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label htmlFor="description">
                            {t('document.navbar.main.editor.left.blocks.types.fileBlock.description')}
                        </Form.Label>
                        <InputField name="description" />
                    </Form.Group>

                    <Button variant="primary" type="submit" disabled={!dirty || isSubmitting || !isValid}>
                        {t('btn.save')}
                    </Button>
                </FForm>
            )}
        </Formik>
    );
}

const EditFileSchema = Yup.object().shape({
    displayName: Yup.string().min(3).required('Required'),
});
