import { Button, Col, Form, Row } from 'react-bootstrap';
import { FieldArray, useFormikContext } from 'formik';
import { InputSelect } from '../../publications_v2/helpers/FieldHelper';
import { Plus, TrashFill } from 'react-bootstrap-icons';
import { Condition, Field } from '../../../models/operations_list.models';
import { useTranslation } from 'react-i18next';

interface FormValues {
    requiredConditions: Condition[];
}

export default function ConditionalFields({ fields }: { fields: Field[] }) {
    const { values }: { values: FormValues } = useFormikContext();
    const { t } = useTranslation('changelist');

    return (
        <FieldArray name="requiredConditions">
            {({ remove, push }) => (
                <Form.Group>
                    <Form.Label htmlFor="required">{t('changelist.task.conditionRequired')}</Form.Label>
                    <div className="mb-1">
                        {values.requiredConditions.map((condition, index) => (
                            <div key={`condition-${index}`}>
                                {index > 0 && (
                                    <div className="small text-secondary py-1">{t('changelist.task.or')}</div>
                                )}

                                <ConditionalField condition={condition} index={index} fields={fields} remove={remove} />
                            </div>
                        ))}
                    </div>
                    <div>
                        <Button variant="link" size="sm" className="px-0" onClick={() => push({})}>
                            <Plus /> {t('changelist.task.addRule')}
                        </Button>
                    </div>
                </Form.Group>
            )}
        </FieldArray>
    );
}

function ConditionalField({
    condition,
    index,
    fields,
    remove,
}: {
    condition: Condition;
    index: number;
    fields: Field[];
    remove: (index: number) => void;
}) {
    const selectedField = condition.field ? fields.find((_field) => _field['@id'] === condition.field) : undefined;
    const { t } = useTranslation('changelist');

    return (
        <Row className="align-items-center" noGutters>
            <Col sm={7}>
                <InputSelect
                    name={`requiredConditions.${index}.field`}
                    options={fields.map((_field) => ({
                        value: _field['@id'],
                        label: _field.label,
                    }))}
                    props={{
                        placeholder: `${t('changelist.task.task')}...`,
                        className: 'mr-2',
                    }}
                />
            </Col>
            <Col sm={4}>
                <InputSelect
                    name={`requiredConditions.${index}.value`}
                    options={
                        selectedField
                            ? selectedField.options.map((_option) => ({
                                  value: _option,
                                  label: _option,
                              }))
                            : []
                    }
                    props={{
                        placeholder: `${t('changelist.task.option')}...`,
                        isDisabled: selectedField === undefined,
                        className: 'mr-2',
                    }}
                />
            </Col>
            <Col>
                <Button
                    variant="link"
                    size="sm"
                    className="text-danger px-1"
                    onClick={(event) => {
                        event.stopPropagation();
                        remove(index);
                    }}
                >
                    <TrashFill size={16} />
                </Button>
            </Col>
        </Row>
    );
}
