import { InputField } from 'pages/publications_v2/helpers/FieldHelper';
import { CheckBox, FormGroup } from 'components/SettingsSidebar/helpers/FormHelpers';

export function Team() {
    return (
        <div className="sidebar-form">
            <FormGroup label="Naam *" htmlFor="name" help="De naam van het team.">
                <InputField
                    name="settings.name"
                    props={{
                        maxLength: 255,
                        required: true,
                        id: 'name',
                        placeholder: 'Naam van het team',
                    }}
                />
            </FormGroup>

            <FormGroup
                label="Team mailbox"
                htmlFor="email"
                help="Vul het mailadres in waar de notificaties voor het team naar gestuurd moeten worden."
            >
                <InputField
                    name="settings.properties.email"
                    props={{
                        type: 'email',
                        maxLength: 255,
                        id: 'email',
                        placeholder: 'Team mailbox',
                    }}
                />
            </FormGroup>

            <FormGroup
                label="Notificaties"
                help="Verstuur een notificatie (e-mail) naar de team mailbox of naar individuele gebruikers."
            >
                <CheckBox
                    name="settings.properties.emailNewTasksToTeam"
                    label="Team mailbox"
                    help="Verstuur e-mail naar de team mailbox"
                />
                <CheckBox
                    name="settings.properties.emailNewTasksToUser"
                    label="Gebruiker"
                    help="Verstuur e-mail naar de gebruiker als een uiting is toegewezen"
                />
            </FormGroup>

            <FormGroup
                label="Rechten"
                help="Met deze rechten kan worden aangegeven of de gebruikers uit deze groep nieuwe uitingen mogen indienen en/of beoordelen."
            >
                <CheckBox
                    name="settings.permissions.canCreateTask"
                    label="Mag indienen"
                    help="Gebruikers uit dit team mogen uitingen indienen"
                />
                <CheckBox
                    name="settings.permissions.canReviewTask"
                    label="Mag beoordelen"
                    help="Gebruikers uit dit team mogen uitingen beoordelen"
                />
            </FormGroup>
        </div>
    );
}
