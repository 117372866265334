import { useState } from 'react';
import { Checkbox } from 'pages/publications_v2/helpers/FieldHelper';
import { useGetCategories } from 'pages/documents_v2/views/edit/one_liners/hooks/useGetCategories';
import { useSidebarContext } from 'components/SettingsSidebar/hooks/useSidebarContext';
import { CaretDownFill } from 'react-bootstrap-icons';
import cx from 'classnames';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { EditMetadata } from 'components/SettingsSidebar/views/EditMetadata';
import { CategoryMetadata } from 'pages/documents_v2/views/edit/one_liners/OneLiners';

export function OneLiner() {
    const { entity } = useSidebarContext();
    const categories = useGetCategories(entity.documentId, false);

    const categoryGroups = categories.filter((category) => category.isGroup);

    return (
        <>
            <div className="dr-side-container pt-4 px-3 mb-4">
                <div className="font-weight-bold mb-3">Categorie</div>

                {categoryGroups.map((category) => (
                    <CategoryGroup
                        category={category}
                        childCategories={categories.filter(
                            (childCategory) => childCategory.parent && childCategory.parent === category['@id'],
                        )}
                        key={`settings-category-${category.id}`}
                    />
                ))}
            </div>

            {/*<EditMetadata />*/}
        </>
    );
}

function CategoryGroup({ category, childCategories = [] }) {
    const { values, setFieldValue } = useFormikContext();
    const [expanded, setExpanded] = useState(values.settings.categories.length > 0);

    const allChecked =
        childCategories.length > 0 &&
        childCategories.every((childCategory) => values.settings.categories.includes(childCategory['@id']));

    const isIndeterminate =
        childCategories.length > 0 &&
        !allChecked &&
        childCategories.some((childCategory) => values.settings.categories.includes(childCategory['@id']));

    return (
        <div className="mb-2">
            <div className="d-flex align-items-center pt-2 pb-3">
                <div className="custom-control custom-checkbox">
                    <input
                        className="custom-control-input"
                        id={`category-${category.id}`}
                        type="checkbox"
                        checked={allChecked}
                        onChange={handleChange}
                        ref={(el) => el && (el.indeterminate = isIndeterminate)}
                    />
                    <label className="custom-control-label" htmlFor={`category-${category.id}`} />
                </div>

                <div className="d-flex align-items-center cursor-pointer" onClick={() => setExpanded(!expanded)}>
                    <div className="font-weight-bold">{category.name}</div>
                    <CaretDownFill
                        size={16}
                        className={cx('ml-2', {
                            'rotate-180': !expanded,
                        })}
                    />
                </div>
            </div>

            {expanded && (
                <div className="pl-4">
                    {childCategories.map((childCategory) => (
                        <ChildCategory category={childCategory} key={`settings-category-${childCategory.id}`} />
                    ))}
                </div>
            )}
        </div>
    );

    function handleChange() {
        const childUris = childCategories.map((childCategory) => childCategory['@id']);

        if (allChecked) {
            // Remove all children
            setFieldValue(
                'settings.categories',
                values.settings.categories.filter((v) => !childUris.includes(v)),
            );
        } else {
            // Select all children
            const newCategories = [...values.settings.categories, ...childUris];
            setFieldValue('settings.categories', _.uniq(newCategories));
        }
    }
}

function ChildCategory({ category }) {
    const { values, setFieldValue } = useFormikContext();
    const checked = values.settings.categories.includes(category['@id']);

    return (
        <div className="mb-2">
            <div className="d-flex align-items-center">
                <Checkbox
                    onChange={handleChange}
                    value={category['@id']}
                    checked={checked}
                    id={`category-${category.id}`}
                    name="categories"
                    label={renderCategoryName()}
                />
            </div>
        </div>
    );

    function renderCategoryName() {
        return (
            <>
                <span className="small font-weight-500">{category.name}</span>
                <CategoryMetadata category={category} />
            </>
        );
    }

    function handleChange() {
        setFieldValue('settings.categories', _.xor(values.settings.categories, [category['@id']]));
    }
}
