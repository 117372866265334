import { FormField } from '../../../../publications_v2/helpers/FieldHelper';
import { useTranslation } from 'react-i18next';

export default function Properties() {
    const { t } = useTranslation('reimbursements');

    return (
        <>
            <FormField label={`${t('collectivity.editCollectivity.properties.name')}*`} name="name" />

            <FormField label={`${t('collectivity.editCollectivity.properties.code')}*`} name="code" />

            <FormField label={t('collectivity.editCollectivity.properties.description')} name="description" />
        </>
    );
}
