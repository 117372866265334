import { useGetStripeProduct } from 'pages/welcome/hooks/useGetStripeProduct';
import { Formik, useFormikContext, Form as FForm } from 'formik';
import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { FormFieldWithTitle } from 'pages/welcome/CreateAccount';
import { PrimaryButton } from 'components/Buttons';
import * as Yup from 'yup';
import _ from 'lodash';
import { useDebounce } from 'hooks/useDebounce';
import { useCreateNewSubscriptionMutation, useValidateOnboardingEmailMutation } from 'features/security/authApi';
import HelperFunctions from 'pages/global/HelperFunctions';
import { DateTime } from 'luxon';
import { InputCheck } from 'pages/publications_v2/helpers/FieldHelper';
import { useHistory } from 'react-router-dom';
import { WELCOME_CREATE_ACCOUNT } from 'pages/welcome/config/Constants';

export function SignUp() {
    const stripeProduct = useGetStripeProduct();
    const [createNewSubscription] = useCreateNewSubscriptionMutation();
    const history = useHistory();

    if (!stripeProduct) {
        return null;
    }

    const { product, price } = stripeProduct;

    return (
        <div id="setup-wizard">
            <Formik
                enableReinitialize
                initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                    plainPassword: '',
                    passwordConfirmation: '',
                    terms_agree: false,
                    priceId: price.id,
                    productId: product.id,
                }}
                onSubmit={handleSubmit}
                validationSchema={signupSchema}
            >
                <FForm>
                    <RenderSignUpForm />
                </FForm>
            </Formik>
        </div>
    );

    function handleSubmit(values) {
        createNewSubscription(values).then(({ data }) => {
            if (data?.status === 'ok') {
                history.push(
                    `${WELCOME_CREATE_ACCOUNT}?sid=${data.data.subscriptionId}&userId=${data.data.userId}&pid=${product.id}`,
                );
            }
        });
    }
}

function RenderSignUpForm() {
    const { values, isSubmitting, dirty, isValid } = useFormikContext();
    const [emailIsValid, setEmailIsValid] = useState(true);
    const [validateOnboardingEmail] = useValidateOnboardingEmailMutation();

    const { email } = values;

    const trialEndDate = DateTime.now().plus({ days: 30 }).toLocaleString(DateTime.DATE_MED);

    const handleEmailChange = useDebounce((email) => {
        validateOnboardingEmail({
            email,
        }).then(({ data }) => {
            const { email } = data;
            setEmailIsValid(email);
        });
    }, 500);

    useEffect(() => {
        // Debounce the email change
        if (!_.isEmpty(email) && HelperFunctions.emailIsValid(email)) {
            handleEmailChange(email);
        }
    }, [email]);

    return (
        <div id="msform" className="text-left">
            <h2 className="fs-title mb-4">Create your account and start for free</h2>

            <div>
                <FormFieldWithTitle
                    label="First name"
                    name="firstName"
                    props={{ required: true, placeholder: 'Your first name', autoComplete: 'given-name' }}
                />

                <FormFieldWithTitle
                    label="Last name"
                    name="lastName"
                    props={{ required: true, placeholder: 'Your last name', autoComplete: 'family-name' }}
                />

                <FormFieldWithTitle
                    label="Email"
                    name="email"
                    props={{
                        required: true,
                        placeholder: 'Your email',
                        type: 'email',
                        autoComplete: 'email',
                    }}
                    help="This is the email adress you use to log in"
                />

                {!emailIsValid && <Alert variant="danger">Email already registered!</Alert>}

                <FormFieldWithTitle
                    label="Password"
                    name="plainPassword"
                    props={{
                        required: true,
                        placeholder: 'Password',
                        type: 'password',
                        autoComplete: 'new-password',
                        pattern: '(?=^.{8,}$)(?=.*\\d)(?=.*[A-Z])(?=.*[a-z]).*$',
                    }}
                    help="Minimum of 8 characters, 1 digit and 1 uppercase"
                />

                <FormFieldWithTitle
                    label="Confirm password"
                    name="passwordConfirmation"
                    props={{
                        required: true,
                        placeholder: 'Confirm password',
                        type: 'password',
                        autoComplete: 'new-password',
                        pattern: '(?=^.{8,}$)(?=.*\\d)(?=.*[A-Z])(?=.*[a-z]).*$',
                    }}
                />

                <TermsForm />

                <div className="pt-3 mb-3">
                    <PrimaryButton
                        type="submit"
                        className="action-button d-block w-100"
                        disabled={isSubmitting || !emailIsValid || !dirty || !isValid}
                    >
                        Start trial
                    </PrimaryButton>
                </div>

                <div className="text-center text-muted small px-3">
                    <div>Your trial will automatically end on {trialEndDate}.</div>
                </div>
            </div>
        </div>
    );
}

function TermsForm() {
    return (
        <div>
            <div className="form-check align-items-baseline">
                <InputCheck name="terms_agree" props={{ className: 'form-check-input mt-1', id: 'termsAgree' }} />
                <label className="form-check-label" htmlFor="termsAgree">
                    I agree to the DocRevolution IPIDcreator{' '}
                    <a href="https://legal.ipidcreator.eu/ipidcreator-terms-and-conditions" target="_blank">
                        Terms and Conditions
                    </a>{' '}
                    and the DocRevolution IPIDcreator{' '}
                    <a href="https://legal.ipidcreator.eu/docrevolution-ipid-creator-terms-of-service" target="_blank">
                        Terms of service
                    </a>
                    .
                </label>
            </div>
        </div>
    );
}

const passwordPattern = /(?=^.{8,}$)(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).*$/;

const signupSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    plainPassword: Yup.string().min(8).matches(passwordPattern),
    passwordConfirmation: Yup.string().when('plainPassword', {
        is: (val) => val && val.length > 0,
        then: Yup.string()
            .required()
            .oneOf([Yup.ref('plainPassword')], 'Both password need to be the same'),
    }),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    terms_agree: Yup.bool().required().oneOf([true]),
});
