import { useParams } from 'react-router-dom';
import { useGetAreaBlocksQuery } from '../../../features/documents/documents';
import { useContext } from 'react';
import { AreaContext } from '../views/Area';

export function useGetAreaBlocks() {
    const { areaId } = useParams();
    const { activeVariantId } = useContext(AreaContext);

    const { areaData } = useGetAreaBlocksQuery(
        { areaId: parseInt(areaId), variantId: activeVariantId },
        {
            selectFromResult: ({ data }) => ({
                areaData: data,
            }),
            skip: activeVariantId === 0,
        }
    );

    return areaData;
}
