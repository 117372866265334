import { useGetAdminOrganisationQuery } from '../../../../features/security/authApi';
import { useParams } from 'react-router-dom';
import { OrganisationForm } from './OrganisationForm';

export function Edit({ account }) {
    const { entityId } = useParams();

    const { organisation } = useGetAdminOrganisationQuery(parseInt(entityId), {
        selectFromResult: ({ data }) => ({
            organisation: data,
        }),
    });

    if (!organisation) {
        return null;
    }

    return (
        <div>
            <div className="subheader d-flex align-items-center justify-content-between pb-1 mb-3">
                <div className="d-flex align-items-baseline">
                    <h3 className="mb-0">{organisation.name}: bewerken</h3>
                </div>
            </div>

            <OrganisationForm organisation={organisation} account={account} />
        </div>
    );
}
