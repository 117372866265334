import { useEffect, useRef, useState } from 'react';
import { FormModal, ModalFooter } from '../../../global/FormModal';
import { Form as FForm, Formik, useFormikContext } from 'formik';
import { Badge, Col, Form, Modal, Row } from 'react-bootstrap';
import {
    DocRevSelect,
    FieldSelect,
    FormField,
    InputMultipleSelect,
    Switch,
} from '../../../publications_v2/helpers/FieldHelper';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import HelperFunctions from '../../../global/HelperFunctions';
import Constants from '../../../../config/Constants';
import _ from 'lodash';
import cx from 'classnames';
import CheckChangesHeader from './CheckChangesHeader';
import { useGetLabelFoldersQuery, useGetLabelsQuery } from '../../../../features/metadata/metadata';
import { idToUri } from '../../../global/UriHelper';

export default function LabelBlock({
    block,
    handleBlockChange,
    editorDisplaySection,
    markChanges,
    readOnly = true,
    context,
    processBlockChanges,
}) {
    const blockIsDeleted = _.get(block, 'deleted', false) || _.get(block, 'scheduledForDeletion', false);
    let jsonContent = block.latestContent;

    // Override readonly when block is deleted
    if (blockIsDeleted) {
        readOnly = true;
    }

    // Override jsonContent when showing changes
    if (readOnly && markChanges && !_.isEmpty(block.diffContent)) {
        jsonContent = block.diffContent;
    }

    // Override jsonContent when in checkChanges
    if (context === Constants.blockContext.checkChanges) {
        const hasChanges = block.changes?.some((part) => part.processed === false);

        if (hasChanges) {
            // We still have changes, so show the diff content with the changes
            jsonContent = block.diffContent;
            markChanges = true;
        } else {
            const allAccepted = block.changes?.every((part) => part.accepted === true);

            if (allAccepted) {
                // Show the accepted content
                jsonContent = block.latestContent;
            } else {
                // Show the old (previous) content
                jsonContent = block.baseContent;
            }
        }
    }

    const latestContent = HelperFunctions.tryParseJSON(jsonContent, {
        labelFolder: '',
        options: [],
    });

    if (
        context === Constants.blockContext.editor &&
        editorDisplaySection === Constants.editorDisplaySections.left &&
        readOnly === false
    ) {
        return (
            <EditableBlock latestContent={latestContent} block={block} handleBlockChange={handleBlockChange}>
                <LabelBlockContent block={block} latestContent={latestContent} />
            </EditableBlock>
        );
    }

    return (
        <div
            className={cx(`label-block block-context-${editorDisplaySection}-${context} label-block-read-only`, {
                'is-deleted': blockIsDeleted,
            })}
        >
            {context === Constants.blockContext.checkChanges &&
                blockIsDeleted === false &&
                editorDisplaySection === Constants.editorDisplaySections.left && (
                    <CheckChangesHeader block={block} processBlockChanges={processBlockChanges} />
                )}

            <LabelBlockContent block={block} latestContent={latestContent} markChanges={markChanges} />
        </div>
    );
}

function EditableBlock({ block, latestContent, handleBlockChange, children }) {
    const [showOptions, setShowOptions] = useState(false);

    return (
        <>
            <div
                className="area-text-block area-text-block-has-name area-text-block-no-editor keuze-opties p-0"
                onClick={() => {
                    setShowOptions(true);
                }}
            >
                <div className="mce-content-body">{children}</div>
            </div>

            <OptionsModal
                block={block}
                latestContent={latestContent}
                show={showOptions}
                handleClose={() => {
                    setShowOptions(false);
                }}
                handleBlockChange={handleBlockChange}
            />
        </>
    );
}

export function LabelBlockContent({ block, latestContent, markChanges = false }) {
    const { options = [], changes = {} } = latestContent;
    const { properties } = block;
    const { multiple = false } = properties;

    return (
        <div className="label-block-content">
            {multiple ? (
                <>
                    {options.map((_option, index) => {
                        let variant = 'primary';

                        if (markChanges && changes.hasOwnProperty(_option.id)) {
                            const changeType = changes[_option.id];
                            variant = changeType === 'a' ? 'success ' : 'danger';
                        }

                        return (
                            <Badge
                                className="mr-1 label-block-text-color"
                                variant="primary"
                                key={`block-${block.key}-option-${index}`}
                            >
                                {_option.label}
                            </Badge>
                        );
                    })}
                </>
            ) : (
                <SingleItemContent latestContent={latestContent} markChanges={markChanges} />
            )}
        </div>
    );
}

function SingleItemContent({ latestContent, markChanges = false }) {
    const { options = [], changes = {} } = latestContent;

    // Option was modified
    if (markChanges && options.length === 2 && Object.keys(changes).length === 2) {
        const optionOne = options[0];
        const optionTwo = options[1];

        let oldLabel = optionOne.label;
        let newLabel = optionTwo.label;

        // optionOne is added, so reverse the labels
        if (changes[optionOne.id] === 'a') {
            oldLabel = optionTwo.label;
            newLabel = optionOne.label;
        }

        return (
            <Badge variant="warning">
                <del>{oldLabel}</del>
                <ins>{newLabel}</ins>
            </Badge>
        );
    }

    // Show placeholder?
    if (options.length !== 1) {
        return <div className="text-warning small">Nog geen optie geselecteerd.</div>;
    }

    const option = options[0];
    const { id, label } = option;

    // Option was added/removed
    if (markChanges && changes.hasOwnProperty(id)) {
        const changeType = changes[id];
        const variant = changeType === 'a' ? 'success' : 'danger';

        return <Badge variant={variant}>{label}</Badge>;
    }

    return <>{label}</>;
}

function OptionsModal({ block, handleClose, show, handleBlockChange, latestContent = {} }) {
    const [isInit, setInit] = useState(false);
    const { activeOrganisation } = useSelector((state) => state.security);

    const { folders = [] } = useGetLabelFoldersQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                folders: data ?? [],
            }),
        }
    );

    useEffect(() => {
        if (show && !isInit) {
            setInit(true);
        }
    }, [show]);

    if (isInit === false) {
        return null;
    }

    const { labelFolder, options } = latestContent;
    const { properties } = block;
    const { multiple = false, allow_add = false, name = '' } = properties;

    // Filter folders
    const allowedTypes = Object.keys(Constants.labelFolderEntities).filter((_entity) => {
        return Constants.labelFolderEntities[_entity].includes('labelBlock');
    });

    const availableFolder = folders.filter((_folder) =>
        _folder.contentTypes.some((_type) => allowedTypes.includes(_type))
    );

    const handleSubmit = (values, { setSubmitting }) => {
        const latestContent = JSON.stringify({
            labelFolder: values.labelFolder,
            options: values.options,
        });

        const newProperties = {
            name: values.name,
            multiple: values.multiple,
            allow_add: values.allow_add,
        };

        handleBlockChange(latestContent, newProperties);

        setSubmitting(false);
        handleClose();
    };

    return (
        <FormModal show={show} onHide={handleClose} title="Keuze opties">
            <Formik
                initialValues={{
                    name,
                    multiple,
                    allow_add,
                    labelFolder,
                    options,
                }}
                validationSchema={LabelOptionsSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, isValid, dirty, values }) => (
                    <FForm autoComplete="off">
                        <Modal.Body>
                            <FormField name="name" label="Naam" props={{ placeholder: 'Kies een naam...' }} />

                            <FieldSelect
                                name="labelFolder"
                                label="Labelmap"
                                help="Let op: kan na het opslaan niet meer aangepast worden."
                                options={HelperFunctions.prepareDropdownData(availableFolder)}
                                props={{
                                    isDisabled: values.labelFolder,
                                }}
                            />

                            <Switch
                                name="multiple"
                                label="Meerdere opties"
                                help="Let op: kan na het opslaan niet meer aangepast worden."
                                props={{
                                    disabled: values.labelFolder,
                                }}
                            />

                            {values.multiple && (
                                <Switch
                                    name="allow_add"
                                    label="Mag toevoegen"
                                    props={{
                                        disabled: values.labelFolder,
                                    }}
                                />
                            )}

                            {values.labelFolder !== '' && <LabelBlockOptions name="options" />}
                        </Modal.Body>

                        <ModalFooter isValid={isValid} dirty={dirty} isSubmitting={isSubmitting} onHide={handleClose} />
                    </FForm>
                )}
            </Formik>
        </FormModal>
    );
}

function LabelBlockOptions({ name }) {
    const { setFieldValue, initialValues, values } = useFormikContext();
    const { activeOrganisation } = useSelector((state) => state.security);

    const { labels = [] } = useGetLabelsQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                labels: data ? data.filter((label) => label.folder === idToUri(values.labelFolder, 'LabelFolder')) : [],
            }),
        }
    );

    const { multiple, allow_add, options = [], labelFolder } = values;
    const selectInputRef = useRef();

    useEffect(() => {
        if (labelFolder !== '' && labelFolder !== initialValues.labelFolder) {
            setFieldValue(name, []);
        }
    }, [labelFolder]);

    useEffect(() => {
        if (!multiple && options.length > 1) {
            setFieldValue(name, []);
        }
    }, [multiple]);

    return (
        <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4} htmlFor={name}>
                Waarde
            </Form.Label>
            <Col sm={8}>
                {multiple ? (
                    <div
                        onPaste={(event) => {
                            const pastedText = event.nativeEvent.clipboardData.getData('text');
                            const items = pastedText
                                .split(',')
                                .map((_item) => _item.trim())
                                .filter((_item) => options.find((_option) => _option.label === _item) === undefined);

                            if (items.length > 0) {
                                setFieldValue(
                                    name,
                                    options.concat(
                                        items
                                            .filter((_item) => {
                                                return (
                                                    allow_add ||
                                                    labels.find((_label) => _label.name === _item) !== undefined
                                                );
                                            })
                                            .map((_item) => {
                                                const existingLabel = labels.find((_label) => _label.name === _item);

                                                return {
                                                    id: existingLabel?.id ?? undefined,
                                                    value: existingLabel?.id ?? _item,
                                                    label: existingLabel?.name ?? _item,
                                                };
                                            })
                                    )
                                );

                                selectInputRef.current.select.blur();
                            }
                        }}
                    >
                        <InputMultipleSelect
                            props={{
                                ref: selectInputRef,
                            }}
                            name="options"
                            value={options}
                            defaultValue={options}
                            creatable={allow_add}
                            options={HelperFunctions.prepareDropdownData(labels)}
                            onChange={(selection) => {
                                setFieldValue(
                                    name,
                                    selection.map((_selection) => ({
                                        id: _selection.id,
                                        value: _selection.value,
                                        label: _selection.label,
                                    }))
                                );
                            }}
                        />
                    </div>
                ) : (
                    <DocRevSelect
                        name="options"
                        options={HelperFunctions.prepareDropdownData(labels)}
                        selectedValue={options.length === 1 ? options[0]['value'] : ''}
                        onChange={(selection) => {
                            setFieldValue(name, [
                                {
                                    id: selection.id,
                                    value: selection.value,
                                    label: selection.label,
                                },
                            ]);
                        }}
                    />
                )}
            </Col>
        </Form.Group>
    );
}

const LabelOptionsSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Required'),
    labelFolder: Yup.string().min(2, 'Too Short!').required(),
});
