import { useState } from 'react';
import HelperFunctions from '../../../global/HelperFunctions';
import Constants from '../../../../config/Constants';
import { Dropdown, Form, SplitButton } from 'react-bootstrap';
import _ from 'lodash';
import { TbPlaylistAdd } from 'react-icons/tb';
import { LightOutlineButton, PrimaryButton, SecondaryButton } from 'components/Buttons';
import { useEntityTemplateParameters } from '../../hooks/useEntityTemplateParameters';
import { entityTypes } from 'pages/translation/config/Constants';
import { useTranslation } from 'react-i18next';
import { useAddAreaMutation } from 'features/documents/documents';
import { useGetDocument } from 'pages/documents_v2/hooks/useGetDocument';

export default function AddAreaForm({
    section,
    setLibraryImport,
    setAddAreaFromTemplateModal,
    setAddAreaFromParentModal,
}) {
    const { t } = useTranslation('documents');
    const document = useGetDocument(undefined, true);
    const documentLevelRestrictions = useEntityTemplateParameters(document, entityTypes.DOCUMENT, document);
    const [addArea] = useAddAreaMutation();
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [title, setTitle] = useState('');
    const [showForm, setShowForm] = useState(false);

    if (!document) {
        return null;
    }

    const hasPermission = HelperFunctions.checkRoleOrPermission(document.currentUserRole, {
        not: [Constants.userDocumentRole.spectator],
    });

    const canOnlyUseAsWhole = documentLevelRestrictions?.composition
        ? !documentLevelRestrictions?.composition?.includes('canOnlyUseAsWhole')
        : true;
    const canBeFree = documentLevelRestrictions?.composition
        ? !documentLevelRestrictions?.composition?.includes('canBeFree')
        : true;

    if (
        !hasPermission ||
        document.status === Constants.documentStatus.reviewFinished ||
        section.scheduledForDeletion === true
    ) {
        return null;
    }

    const handleFormChange = (e) => {
        setTitle(e.target.value);
    };

    const addNewArea = () => {
        if (title === '') {
            return;
        }

        setSaveInProgress(true);

        const newArea = {
            currentUserStatus: { status: Constants.areaUserStatus.unstarted, skipped: false },
            exportProperties: {
                showTitleInDocument: true,
                showTitleInIndex: true,
            },
            sectionId: section.id,
            title,
        };

        addArea({ sectionId: section.id, body: newArea }).then(() => {
            // save successful, clearing form
            setTitle('');
            setSaveInProgress(false);
        });
    };

    // Check if there's a template area
    const { sections = [] } = document;
    const documentHasTemplateArea = sections.some((_section) => {
        const { areas = [] } = _section;

        return areas.some((_area) => _area.deletedAt === null && _.get(_area.properties, 'isTemplate', false));
    });

    return (
        <>
            <div className="pl-3">
                {showForm ? (
                    <Form
                        autoComplete="off"
                        inline
                        onSubmit={(e) => {
                            e.preventDefault();
                            addNewArea();
                            setShowForm(false);
                        }}
                    >
                        <Form.Control
                            autoFocus={true}
                            placeholder={`${t('documents:document.navbar.main.titles.addAreaPlaceholder')}...`}
                            id="areaTitleInput"
                            value={title}
                            onChange={handleFormChange}
                            disabled={saveInProgress}
                            className="mr-2"
                        />

                        {canOnlyUseAsWhole ? (
                            <SplitButton
                                id="add-area"
                                title={t('btn.save')}
                                size="sm"
                                disabled={saveInProgress}
                                menuAlign="right"
                                onClick={addNewArea}
                            >
                                <Dropdown.Item
                                    onClick={() => handleSetLibrary(document.id, section.id)}
                                >
                                    {t('document.navbar.main.titles.splitMenu.linkArea')}&hellip;
                                </Dropdown.Item>
                                {/*<Dropdown.Item*/}
                                {/*    disabled={document.documentLibrary === null}*/}
                                {/*    onClick={() => handleSetLibrary(document.documentLibrary, section.id)}*/}
                                {/*>*/}
                                {/*    {t('document.navbar.main.titles.splitMenu.addFromLibrary')}*/}
                                {/*</Dropdown.Item>*/}
                                <Dropdown.Item
                                    disabled={!documentHasTemplateArea}
                                    onClick={() =>
                                        setAddAreaFromTemplateModal({
                                            documentId: document.id,
                                            sectionId: section.id,
                                        })
                                    }
                                >
                                    {t('document.navbar.main.titles.splitMenu.addBasedOnTemplate')}&hellip;
                                </Dropdown.Item>
                                {document.modelId !== null && (
                                    <Dropdown.Item
                                        disabled={canBeFree}
                                        onClick={() =>
                                            setAddAreaFromParentModal({
                                                documentId: document.id,
                                                sectionId: section.id,
                                            })
                                        }
                                    >
                                        {t('document.navbar.main.titles.splitMenu.addArticleBasedOnTemplate')}&hellip;
                                    </Dropdown.Item>
                                )}
                            </SplitButton>
                        ) : (
                            <PrimaryButton disabled={saveInProgress} size="sm" onClick={addNewArea}>
                                {t('btn.save')}
                            </PrimaryButton>
                        )}

                        <SecondaryButton
                            size="sm"
                            className="ml-2"
                            onClick={() => {
                                setTitle('');
                                setShowForm(false);
                            }}
                        >
                            {t('global:btn.cancel')}
                        </SecondaryButton>
                    </Form>
                ) : (
                    <div className="pt-2">
                        <LightOutlineButton size="sm" onClick={() => setShowForm(!showForm)}>
                            <TbPlaylistAdd />
                            {t('document.navbar.main.titles.addArticle')}
                        </LightOutlineButton>
                    </div>
                )}
            </div>
        </>
    );

    function handleSetLibrary(documentId, sectionId) {
        setLibraryImport({
            documentId: documentId,
            sectionId: sectionId,
        });
    }
}
