import { Col, Form, Modal } from 'react-bootstrap';
import { FormModal, ModalFooter } from '../../global/FormModal';
import { useDropzone } from 'react-dropzone';
import { useMemo, useState } from 'react';
import { getLanguageName } from '../helpers/LanguageHelper';
import { useTranslation } from 'react-i18next';
import {
    useImportXliffFileMutation,
    useProcessImportXliffContentMutation,
} from '../../../features/translations/translationApi';
import Parser from 'html-react-parser';
import { diffWords } from 'diff';
import striptags from 'striptags';
import { ExclamationTriangleFill } from 'react-bootstrap-icons';

export function UploadXliffModal({ translationLanguage, hide }) {
    const { t } = useTranslation('translations');
    const { languageIso } = translationLanguage;
    const [uploadedFile, setUploadedFile] = useState(null);
    const [isSubmitting, setSubmitting] = useState(false);
    const [previewData, setPreviewData] = useState([]);

    const [importXliffFile] = useImportXliffFileMutation();
    const [processImportXliffContent] = useProcessImportXliffContentMutation();

    const handleClose = () => {
        hide();
    };

    const generatePreview = (file) => {
        setSubmitting(true);

        const formData = new FormData();
        formData.append('file', file);

        importXliffFile({
            uri: translationLanguage['@id'],
            body: formData,
        })
            .then((response) => {
                setSubmitting(false);
                setUploadedFile(file);
                setPreviewData(response?.data ?? []);
            })
            .catch(() => {
                setSubmitting(false);
                setUploadedFile(null);
                setPreviewData([]);
            });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmitting(true);

        if (uploadedFile !== null) {
            processImportXliffContent({
                uri: translationLanguage['@id'],
                body: previewData,
            }).then(() => {
                setSubmitting(true);
                handleClose();
            });
        }
    };

    return (
        <FormModal show={true} onHide={handleClose} title={t('etc.importXLIFF')} size="xl">
            <form autoComplete="off" onSubmit={handleSubmit}>
                <Modal.Body>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>
                                <strong>{t('etc.language')}</strong>
                            </Form.Label>
                            <Form.Control
                                className="pt-0"
                                plaintext
                                readOnly
                                defaultValue={getLanguageName(languageIso)}
                            />
                        </Form.Group>
                    </Form.Row>

                    {uploadedFile ? (
                        <ImportPreview previewData={previewData} uploadedFile={uploadedFile} />
                    ) : (
                        <FileUpload generatePreview={generatePreview} />
                    )}
                </Modal.Body>

                <ModalFooter
                    btnSubmitText={t('etc.import') || ''}
                    isSubmitting={isSubmitting}
                    onHide={handleClose}
                    isValid={previewData.length > 0}
                />
            </form>
        </FormModal>
    );
}

function FileUpload({ generatePreview }) {
    const onDrop = (acceptedFiles) => {
        if (acceptedFiles.length === 1) {
            generatePreview(acceptedFiles[0]);
        }
    };

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        accept: {
            'application/xhtml+xml': ['.xlf'],
        },
        onDrop,
        maxFiles: 1,
    });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );
    const { t } = useTranslation('translations');

    return (
        <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <div>{t('etc.dragXLF')}</div>
        </div>
    );
}

function ImportPreview({ uploadedFile, previewData = [] }) {
    const { t } = useTranslation('translations');

    return (
        <>
            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label>
                        <strong>{t('etc.importTitle')}</strong>
                    </Form.Label>
                    <Form.Control className="pt-0" plaintext readOnly defaultValue={uploadedFile.name} />
                </Form.Group>
            </Form.Row>

            <div>
                <table className="table small">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th width="30%">Bron</th>
                            <th width="30%">Huidige vertaling</th>
                            <th width="30%">Nieuwe vertaling</th>
                        </tr>
                    </thead>
                    <tbody>
                        {previewData.map((row, index) => (
                            <PreviewRow row={row} index={index} key={`preview-row-${index}`} />
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

function PreviewRow({ row, index }) {
    const { source = '', currentTarget = '', newTarget = '', enabledForTranslation = false } = row;

    const diffText = useMemo(() => {
        let text = '';
        const diff = diffWords(striptags(currentTarget), striptags(newTarget));

        diff.forEach((part) => {
            if (part.added) {
                text = text + `<ins>${part.value}</ins>`;
            } else if (part.removed) {
                text = text + `<del>${part.value}</del>`;
            } else {
                text = text + part.value;
            }
        });

        return `<p>${text}</p>`;
    }, [currentTarget, newTarget]);

    return (
        <tr>
            <td>#{index + 1}</td>
            <td>
                {!enabledForTranslation && (
                    <div className="d-flex align-items-center text-danger font-weight-bold mb-1">
                        <ExclamationTriangleFill className="mr-1" />
                        Uitgesloten van vertaling!
                    </div>
                )}
                <div className="rendered-content text-break">{Parser(source)}</div>
            </td>
            <td>
                <div className="rendered-content text-break">{Parser(currentTarget)}</div>
            </td>
            <td>
                <div className="rendered-content text-break">{Parser(diffText)}</div>
            </td>
        </tr>
    );
}

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
};

const activeStyle = {
    borderColor: '#2196f3',
};

const acceptStyle = {
    borderColor: '#00e676',
    backgroundColor: '#00e67614',
};

const rejectStyle = {
    borderColor: '#ff1744',
};
