import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import {
    departmentEntity,
    opEntryActionEntity,
    opEntryAreaEntity,
    opEntryBlockEntity,
    opEntryEntity,
    opEntryLabelEntity,
    opEntryVariantEntity,
    opListEntity,
    opListUserEntity,
    reportsDataEntity,
    teamEntity,
} from 'schemas';
import OperationsListApi from '../../api/OperationsListApi';
import HelperFunctions from '../../pages/global/HelperFunctions';
import _ from 'lodash';
import { selectUserDepartments } from './opListUserSlice';

const defaultFilters = { 'filter-1': true, 'filter-3': true, 'filter-5': true, 'filter-6': true, 'filter-7': true };

const OpListAdapter = createEntityAdapter({
    sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const DepartmentAdapter = createEntityAdapter({
    selectId: (entity) => entity['@id'],
    sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const ReportsDataAdapter = createEntityAdapter({
    selectId: (entity) => entity['departmentId'],
});

const selectUUIDOptions = { selectId: (entity) => entity['@id'] };
const OpListUserAdapter = createEntityAdapter(selectUUIDOptions);
const OpListTeamAdapter = createEntityAdapter(selectUUIDOptions);
const OpListEntryAdapter = createEntityAdapter(selectUUIDOptions);
const OpEntryLabelAdapter = createEntityAdapter(selectUUIDOptions);
const OpEntryVariantAdapter = createEntityAdapter();
const OpEntryBlockAdapter = createEntityAdapter(selectUUIDOptions);
const OpEntryAreaAdapter = createEntityAdapter(selectUUIDOptions);
const OpEntryActionAdapter = createEntityAdapter(selectUUIDOptions);
const OpListSummaryAdapter = createEntityAdapter();

export const fetchOperationsLists = createAsyncThunk('operationsList/fetchOperationsLists', async () => {
    const results = await OperationsListApi.getOperationsLists();
    const normalized = normalize(results['hydra:member'], [opListEntity]);

    return normalized.entities;
});

export const fetchOpListById = createAsyncThunk('operationsList/fetchOpList', async ({ id }) => {
    const results = await OperationsListApi.get(`/api/operations-api/api/op_lists/${id}`);
    const normalized = normalize(results, opListEntity);

    return normalized.entities;
});

export const fetchOpEntryLabelId = createAsyncThunk('operationsList/fetchOpEntryLabel', async ({ id }) => {
    const results = await OperationsListApi.get(`/api/operations-api/api/op_entry_labels/${id}`);
    const normalized = normalize(results, opEntryLabelEntity);

    return normalized.entities;
});

export const fetchOpEntryId = createAsyncThunk('operationsList/fetchOpEntryId', async ({ id }) => {
    const results = await OperationsListApi.get(`/api/operations-api/api/op_entries/${id}`);
    const normalized = normalize(results, opEntryEntity);

    return normalized.entities;
});

export const fetchDepartment = createAsyncThunk('operationsList/fetchDepartment', async ({ uri }) => {
    const results = await OperationsListApi.get(uri);
    const normalized = normalize(results, departmentEntity);

    return normalized.entities;
});

export const fetchBulkEntryLabels = createAsyncThunk(
    'operationsList/fetchPaginatedEntryLabels',
    async ({ opListId, filterOptions = defaultFilters, page = 1, targetEntity = 'OpEntryLabel' }) => {
        const results = await OperationsListApi.getOpListBulkEntryLabels(opListId, filterOptions, page, targetEntity);
        const data = _.get(results.data, 'hydra:member.0', {});

        const normalizedTeamEntries = normalize(data['results']['opEntryLabels'], [opEntryLabelEntity]);
        const normalizedEntries = normalize(data['results']['opEntries'], [opEntryEntity]);

        return _.assign({}, normalizedTeamEntries.entities, normalizedEntries.entities, {
            pagination: data.pagination,
        });
    }
);

export const fetchOpEntryLabelsForOpEntry = createAsyncThunk(
    'operationsList/fetchOpEntryLabelsForOpEntry',
    async ({ opListId, opEntryId }) => {
        const results = await OperationsListApi.getOpEntryLabelsForOpEntry(opListId, opEntryId);
        const normalized = normalize(results['hydra:member'], [opEntryLabelEntity]);

        return normalized.entities;
    }
);

export const fetchOpListUsers = createAsyncThunk('operationsList/fetchOpListUsers', async ({ opListId }) => {
    const results = await OperationsListApi.getOpListUsers(opListId);
    const normalized = normalize(results['hydra:member'], [opListUserEntity]);

    return normalized.entities;
});

export const fetchOpListTeams = createAsyncThunk('operationsList/fetchOpListTeams', async ({ opListId }) => {
    const results = await OperationsListApi.getOpListTeamsForOpList(opListId);
    const normalized = normalize(results['hydra:member'], [teamEntity]);

    return normalized.entities;
});

export const fetchOpListTeamsForDepartment = createAsyncThunk(
    'operationsList/fetchOpListTeamsForDepartment',
    async ({ opDepartmentId }) => {
        const results = await OperationsListApi.getOpListTeamsForDepartment(opDepartmentId);
        const normalized = normalize(results['hydra:member'], [teamEntity]);

        return normalized.entities;
    }
);

export const fetchOpListDepartments = createAsyncThunk(
    'operationsList/fetchOpListDepartments',
    async ({ opListId }) => {
        const results = await OperationsListApi.getOpListDepartments(opListId);
        const normalized = normalize(results['hydra:member'], [departmentEntity]);

        return normalized.entities;
    }
);

export const fetchOpListSummary = createAsyncThunk(
    'operationsList/fetchOpListSummary',
    async ({ opListId, filterOptions = defaultFilters }) => {
        const { data } = await OperationsListApi.getOpListSummary(opListId, filterOptions);
        const summary = _.get(data, 'hydra:member.0', {});

        return {
            id: opListId,
            ...summary,
        };
    }
);

export const addOpList = createAsyncThunk('operationsList/addOpList', async (formData) => {
    const results = await OperationsListApi.addOpList(formData);
    const normalized = normalize(results, opListEntity);

    return normalized.entities;
});

export const removeOpTeam = createAsyncThunk('operationsList/removeOpTeam', async ({ team }) => {
    await OperationsListApi.remove(team['@id']);

    return team;
});

export const addDepartment = createAsyncThunk('operationsList/addDepartment', async (formData) => {
    const results = await OperationsListApi.addDepartment(formData);
    const normalized = normalize(results, departmentEntity);

    return normalized.entities;
});

export const addOpListUser = createAsyncThunk('operationsList/addOpListUser', async (formData) => {
    const results = await OperationsListApi.addUser(formData);
    const normalized = normalize(results, opListUserEntity);

    return normalized.entities;
});

export const addOpTeam = createAsyncThunk('operationsList/addOpTeam', async (formData) => {
    const results = await OperationsListApi.addOpTeam(formData);
    const normalized = normalize(results, teamEntity);

    return normalized.entities;
});

export const patchDepartment = createAsyncThunk('operationsList/patchDepartment', async ({ uri, formData }) => {
    const results = await OperationsListApi.patch(uri, formData);
    const normalized = normalize(results, departmentEntity);

    return normalized.entities;
});

export const patchOpTeam = createAsyncThunk('operationsList/patchOpTeam', async ({ uri, formData }) => {
    const results = await OperationsListApi.patch(uri, formData);
    const normalized = normalize(results, teamEntity);

    return normalized.entities;
});

export const patchOpList = createAsyncThunk('operationsList/patchOpList', async ({ uri, formData }) => {
    const results = await OperationsListApi.patch(uri, formData);
    const normalized = normalize(results, opListEntity);

    return normalized.entities;
});

export const patchOpListUser = createAsyncThunk('operationsList/patchOpListUser', async ({ uri, formData }) => {
    const results = await OperationsListApi.patch(uri, formData);
    const normalized = normalize(results, opListUserEntity);

    return normalized.entities;
});

export const patchOpEntryLabel = createAsyncThunk('operationsList/patchOpEntryLabel', async ({ uri, formData }) => {
    const results = await OperationsListApi.patch(uri, formData);
    const normalized = normalize(results, opEntryLabelEntity);

    return normalized.entities;
});

export const removeOpList = createAsyncThunk('operationsList/removeOpList', async (entity) => {
    await OperationsListApi.remove(entity['@id']);
    return entity;
});

export const removeDepartment = createAsyncThunk('operationsList/removeDepartment', async (entity) => {
    await OperationsListApi.remove(entity['@id']);
    return entity;
});

export const removeOpListUser = createAsyncThunk('operationsList/removeOpListUser', async (entity) => {
    await OperationsListApi.remove(entity['@id']);
    return entity;
});

export const fetchOpEntryVariants = createAsyncThunk('operationsList/fetchOpEntryVariants', async ({ id }) => {
    const results = await OperationsListApi.getOpEntryVariants(id);
    const normalized = normalize(results['hydra:member'], [opEntryVariantEntity]);

    return normalized.entities;
});

export const fetchOpEntryActions = createAsyncThunk('operationsList/fetchOpEntryActions', async ({ id }) => {
    const results = await OperationsListApi.getOpEntryActions(id);
    const normalized = normalize(results['hydra:member'], [opEntryActionEntity]);

    return normalized.entities;
});

export const fetchOpEntryVariantBlocks = createAsyncThunk(
    'operationsList/fetchOpEntryVariantBlocks',
    async ({ opEntryVariantId }) => {
        const results = await OperationsListApi.getOpEntryVariantBlocks(opEntryVariantId);
        const normalized = normalize(results['hydra:member'], [opEntryBlockEntity]);

        return normalized.entities;
    }
);

export const fetchOpEntryBlocksForOpEntry = createAsyncThunk(
    'operationsList/fetchOpEntryBlocksForOpEntry',
    async ({ opEntryId }) => {
        const results = await OperationsListApi.getOpEntryBlocksForOpEntry(opEntryId);
        const normalized = normalize(results['hydra:member'], [opEntryBlockEntity]);

        return normalized.entities;
    }
);

export const fetchOpEntryAreasForOpEntry = createAsyncThunk(
    'operationsList/fetchOpEntryAreasForOpEntry',
    async ({ opEntryId }) => {
        const results = await OperationsListApi.getOpEntryAreasForOpEntry(opEntryId);
        const normalized = normalize(results['hydra:member'], [opEntryAreaEntity]);

        return normalized.entities;
    }
);

export const createOpListEntry = createAsyncThunk('operationsList/createOpListEntry', async ({ formData }) => {
    const results = await OperationsListApi.addOpEntry(formData);
    const normalized = normalize(results, opEntryEntity);

    return normalized.entities;
});

export const patchOpEntry = createAsyncThunk('operationsList/patchOpEntry', async ({ uri, formData }) => {
    const results = await OperationsListApi.patch(uri, formData);
    const normalized = normalize(results, opEntryEntity);

    return normalized.entities;
});

export const cancelOpEntry = createAsyncThunk('operationsList/cancelOpEntry', async ({ id, formData }) => {
    const results = await OperationsListApi.cancelOpEntry(id, formData);
    const normalized = normalize(results, opEntryEntity);

    return normalized.entities;
});

export const createOpListEntryLabel = createAsyncThunk(
    'operationsList/createOpListEntryLabel',
    async ({ formData }) => {
        const results = await OperationsListApi.addOpEntryLabel(formData);
        const normalized = normalize(results, opEntryLabelEntity);

        return normalized.entities;
    }
);

export const fetchDepartmentReportData = createAsyncThunk(
    'operationsList/fetchDepartmentReportData',
    async ({ departmentId, filters }) => {
        // Format filters
        const filterStatus = filters['filter-status'] ?? {};
        const filterLabel = filters['filter-label'] ?? {};

        const formattedFilters = {
            ...filters,
            'filter-status': Object.keys(filterStatus).filter((key) => !!filterStatus[key]),
            'filter-label': Object.keys(filterLabel).filter((key) => !!filterLabel[key]),
        };

        const results = await OperationsListApi.getDepartmentReportData(departmentId, formattedFilters);
        const normalized = normalize(results['hydra:member'], [reportsDataEntity]);

        return normalized.entities;
    }
);

export const fetchEligibleDepartmentUsers = createAsyncThunk(
    'operationsList/fetchEligibleDepartmentUsers',
    async ({ opListId, departmentId, allowManagers }) => {
        const results = await OperationsListApi.getEligibleDepartmentUsers(opListId, departmentId, allowManagers);
        return results['hydra:member'];
    }
);

const operationsListSlice = createSlice({
    name: 'operations',
    initialState: {
        opLists: OpListAdapter.getInitialState(),
        departments: DepartmentAdapter.getInitialState(),
        users: OpListUserAdapter.getInitialState(),
        teams: OpListTeamAdapter.getInitialState(),
        opEntries: OpListEntryAdapter.getInitialState(),
        opEntryLabels: OpEntryLabelAdapter.getInitialState(),
        opEntryBlocks: OpEntryBlockAdapter.getInitialState(),
        opEntryAreas: OpEntryAreaAdapter.getInitialState(),
        opEntryVariants: OpEntryVariantAdapter.getInitialState(),
        opEntryActions: OpEntryActionAdapter.getInitialState(),
        filters: {
            label: '',
            typeOfEdit: '',
            documentVariant: '',
        },
        opListPagination: null,
        opListPaginatedPage: null,
        opListFilter: {},
        opListReportsFilter: {},
        opListSummaries: OpListSummaryAdapter.getInitialState(),
        reportsData: ReportsDataAdapter.getInitialState(),
        eligibleDepartmentUsers: [],
    },
    reducers: {
        setFilter: {
            reducer(state, action) {
                state.opListPagination = null;
            },
        },
        clearPagination: {
            reducer(state, action) {
                const { key, value } = action.payload;
                state.filters[key] = value;
            },
        },
        updateEntity: {
            reducer(state, action) {
                const { entity } = action.payload;

                switch (entity['@type']) {
                    case 'OpEntry':
                        OpListEntryAdapter.upsertOne(state.opEntries, entity);
                        break;
                    // case 'OpTeamEntry':
                    //     OpTeamEntryAdapter.upsertOne(state.opTeamEntries, entity)
                    //     break
                }
            },
        },
        updateOpListFilter: {
            reducer(state, action) {
                const { opListId, formData } = action.payload;

                state.opListFilter[opListId] = formData;
            },
        },
        updateOpListReportsFilter: {
            reducer(state, action) {
                const { opListId, formData } = action.payload;

                state.opListReportsFilter[opListId] = formData;
            },
        },
    },
    extraReducers: {
        ['security/resetState']: (state) => {
            Object.assign(state, operationsListSlice.getInitialState());
        },
        [addDepartment.fulfilled]: (state, action) => {
            DepartmentAdapter.addMany(state.departments, action.payload.departments ?? []);
        },
        [addOpList.fulfilled]: (state, action) => {
            OpListAdapter.addMany(state.opLists, action.payload.operationLists ?? []);
        },
        [addOpListUser.fulfilled]: (state, action) => {
            OpListUserAdapter.addMany(state.users, action.payload.opListUsers ?? []);
        },
        [addOpTeam.fulfilled]: (state, action) => {
            OpListTeamAdapter.addMany(state.teams, action.payload.teams ?? []);
        },
        [fetchOperationsLists.fulfilled]: (state, action) => {
            OpListAdapter.upsertMany(state.opLists, filterDeleted(action, 'payload.operationLists'));
        },
        [fetchOpListById.fulfilled]: (state, action) => {
            OpListAdapter.upsertMany(state.opLists, action.payload.operationLists);
        },
        [fetchOpListUsers.fulfilled]: (state, action) => {
            OpListUserAdapter.upsertMany(state.users, action.payload.opListUsers ?? []);
        },
        [fetchOpListTeams.fulfilled]: (state, action) => {
            OpListTeamAdapter.upsertMany(state.teams, filterDeleted(action, 'payload.teams'));
        },
        [fetchOpListTeamsForDepartment.fulfilled]: (state, action) => {
            OpListTeamAdapter.upsertMany(state.teams, filterDeleted(action, 'payload.teams'));
        },
        [fetchOpListDepartments.fulfilled]: (state, action) => {
            DepartmentAdapter.upsertMany(state.departments, action.payload.departments ?? []);
        },
        [fetchOpEntryLabelsForOpEntry.fulfilled]: (state, action) => {
            OpEntryLabelAdapter.upsertMany(state.opEntryLabels, action.payload.opEntryLabels ?? []);
        },
        [fetchBulkEntryLabels.fulfilled]: (state, action) => {
            const opTeamEntries = action.payload.opEntryLabels ?? [];
            const opEntries = action.payload.opEntries ?? [];

            OpEntryLabelAdapter.upsertMany(state.opEntryLabels, opTeamEntries);
            OpListEntryAdapter.upsertMany(state.opEntries, opEntries);

            state.opListPagination = action.payload.pagination;
            state.opListPaginatedPage = {
                opEntryLabels: _.keys(opTeamEntries),
                opEntries: _.keys(opEntries),
            };
        },
        [fetchOpListSummary.fulfilled]: (state, action) => {
            OpListSummaryAdapter.upsertOne(state.opListSummaries, action.payload);
        },
        [fetchOpEntryLabelId.fulfilled]: (state, action) => {
            OpEntryLabelAdapter.upsertMany(state.opEntryLabels, action.payload.opEntryLabels ?? []);
        },
        [fetchOpEntryId.fulfilled]: (state, action) => {
            OpListEntryAdapter.upsertMany(state.opEntries, action.payload.opEntries ?? []);
        },
        [patchOpEntryLabel.fulfilled]: (state, action) => {
            OpEntryLabelAdapter.upsertMany(state.opEntryLabels, action.payload.opEntryLabels ?? []);
        },
        [cancelOpEntry.fulfilled]: (state, action) => {
            OpEntryLabelAdapter.upsertMany(state.opEntryLabels, action.payload.opEntryLabels ?? []);
        },
        [fetchOpEntryVariantBlocks.fulfilled]: (state, action) => {
            OpEntryBlockAdapter.upsertMany(state.opEntryBlocks, action.payload.opEntryBlocks ?? []);
        },
        [fetchOpEntryBlocksForOpEntry.fulfilled]: (state, action) => {
            OpEntryBlockAdapter.upsertMany(state.opEntryBlocks, action.payload.opEntryBlocks ?? []);
        },
        [fetchOpEntryAreasForOpEntry.fulfilled]: (state, action) => {
            OpEntryAreaAdapter.upsertMany(state.opEntryAreas, action.payload.opEntryAreas ?? []);
        },
        [fetchOpEntryVariants.fulfilled]: (state, action) => {
            OpEntryVariantAdapter.upsertMany(state.opEntryVariants, action.payload.opEntryVariants ?? []);
        },
        [fetchOpEntryActions.fulfilled]: (state, action) => {
            OpEntryActionAdapter.upsertMany(state.opEntryActions, action.payload.opEntryActions ?? []);
        },
        [createOpListEntry.fulfilled]: (state, action) => {
            OpListEntryAdapter.addMany(state.opEntries, action.payload.opEntries ?? []);
        },
        [createOpListEntryLabel.fulfilled]: (state, action) => {
            OpEntryLabelAdapter.addMany(state.opEntryLabels, action.payload.opEntryLabels ?? []);
        },
        [fetchDepartment.fulfilled]: (state, action) => {
            DepartmentAdapter.upsertMany(state.departments, filterDeleted(action, 'payload.departments'));
        },
        [fetchDepartmentReportData.fulfilled]: (state, action) => {
            ReportsDataAdapter.upsertMany(state.reportsData, action.payload.reportsData ?? []);
        },
        [patchDepartment.fulfilled]: (state, action) => {
            DepartmentAdapter.upsertMany(state.departments, action.payload.departments ?? []);
        },
        [patchOpTeam.fulfilled]: (state, action) => {
            OpListTeamAdapter.upsertMany(state.teams, action.payload.teams ?? []);
        },
        [patchOpList.fulfilled]: (state, action) => {
            OpListAdapter.upsertMany(state.opLists, action.payload.operationLists ?? []);
        },
        [patchOpEntry.fulfilled]: (state, action) => {
            OpListEntryAdapter.upsertMany(state.opEntries, action.payload.opEntries ?? []);
        },
        [patchOpListUser.fulfilled]: (state, action) => {
            OpListUserAdapter.upsertMany(state.users, action.payload.opListUsers ?? []);
        },
        [removeOpList.fulfilled]: (state, action) => {
            OpListAdapter.removeOne(state.opLists, action.payload['id']);
        },
        [removeDepartment.fulfilled]: (state, action) => {
            DepartmentAdapter.removeOne(state.departments, action.payload['@id']);
        },
        [removeOpTeam.fulfilled]: (state, action) => {
            OpListTeamAdapter.removeOne(state.teams, action.payload['@id']);
        },
        [removeOpListUser.fulfilled]: (state, action) => {
            OpListUserAdapter.removeOne(state.users, action.payload['@id']);
        },
        [fetchEligibleDepartmentUsers.fulfilled]: (state, action) => {
            // TODO quick fix, because we need to retrieve new ones each time
            state.eligibleDepartmentUsers = action.payload;
        },
    },
});

export const { updateEntity, setFilter, updateOpListFilter, updateOpListReportsFilter } = operationsListSlice.actions;

function filterDeleted(action, path) {
    const entities = _.get(action, path, []);
    return _.filter(entities, (list) => !list.deletedAt);
}

export default operationsListSlice.reducer;

export const { selectById: selectOpListById, selectAll: selectAllOpLists } = OpListAdapter.getSelectors(
    (state) => state.opLists
);

export const { selectById: selectOpEntryLabelById } = OpEntryLabelAdapter.getSelectors((state) => state.opEntryLabels);

export const { selectById: selectDepartmentById } = DepartmentAdapter.getSelectors((state) => state.departments);

export const { selectEntities: selectOpListUserEntities, selectAll: selectAllOpListUsers } =
    OpListUserAdapter.getSelectors((state) => state.users);

export const { selectById: selectOpEntryById } = OpListEntryAdapter.getSelectors((state) => state.opEntries);

export const { selectById: selectOpEntryVariantById } = OpEntryVariantAdapter.getSelectors(
    (state) => state.opEntryVariants
);

export const { selectById: selectOpEntryAreaByUri } = OpEntryAreaAdapter.getSelectors((state) => state.opEntryAreas);

export const selectDepartmentsFromOpList = (opListUri) =>
    createSelector(
        [
            (state) => state.operations.departments.ids.map((id) => state.operations.departments.entities[id]), // this is the same as selectAllDepartments
        ],
        (departments) => departments.filter((_item) => !_item.deleted && _item.opList === opListUri)
    );

export const selectOpVariantsFromOpEntry = (opEntryUri) =>
    createSelector(
        [
            (state) => selectOpEntryById(state.operations, opEntryUri),
            (state) => state.operations.opEntryVariants.ids.map((id) => state.operations.opEntryVariants.entities[id]), // this is the same as selectAllOpVariants
            (state) => state.documents.documentVariants.entities, // this is the same as selectAllDocumentVariantEntities
        ],
        (opEntry, opEntryVariants, documentVariants) => {
            if (!opEntry) {
                return [];
            }

            return opEntryVariants
                .filter((_opEntryVariant) => _opEntryVariant.opEntry === opEntry['@id'])
                .map((_opEntryVariant) => {
                    return {
                        ..._opEntryVariant,
                        sortOrder: documentVariants[_opEntryVariant.variantId]?.sortOrder ?? undefined,
                        parentId: documentVariants[_opEntryVariant.variantId]?.parentId ?? undefined,
                    };
                });
        }
    );

export const selectOpEntryBlocksFromOpVariant = (opEntryVariantId) =>
    createSelector(
        [
            (state) => selectOpEntryVariantById(state.operations, opEntryVariantId),
            (state) => state.operations.opEntryBlocks.ids.map((id) => state.operations.opEntryBlocks.entities[id]), // this is the same as selectAll
        ],
        (opEntryVariant, opEntryBlocks) => {
            return opEntryBlocks
                .filter((_opEntryBlock) => _opEntryBlock.opEntryVariant === opEntryVariant['@id'])
                .sort(HelperFunctions.dynamicSort('sortOrder'));
        }
    );

export const selectOpEntryBlocksForOpEntry = (opEntry) =>
    createSelector(
        [
            (state) => state.operations.opEntryBlocks.ids.map((id) => state.operations.opEntryBlocks.entities[id]), // this is the same as selectAll
        ],
        (opEntryBlocks) => {
            return opEntryBlocks.filter((_opEntryBlock) => _opEntryBlock.opEntry === opEntry['@id']);
        }
    );

export const selectOpEntryActions = (uri) =>
    createSelector(
        [
            (state) => state.operations.opEntryActions.ids.map((id) => state.operations.opEntryActions.entities[id]), // this is the same as selectAllDepartments
        ],
        (opEntryActions) => {
            return opEntryActions
                .filter((_item) => _item.opEntry === uri)
                .sort(HelperFunctions.dynamicSort('createdAt', -1));
        }
    );

export const selectCurrentOpListPageTeamEntries = () =>
    createSelector(
        [(state) => state.operations.opListPaginatedPage, (state) => state.operations.opEntryLabels.entities],
        (paginatedPage, allEntryLabels) => {
            const pageEntryLabelIds = _.get(paginatedPage, 'opEntryLabels');
            if (!pageEntryLabelIds) {
                return [];
            }

            return pageEntryLabelIds.map((pageEntryLabelId) => allEntryLabels[pageEntryLabelId]);
        }
    );

export const selectOpEntryLabelsByOpEntry = (opEntryUri) =>
    createSelector(
        [
            (state) => state.operations.opEntryLabels.ids.map((id) => state.operations.opEntryLabels.entities[id]), // this is the same as selectAllLabels
        ],
        (opEntryLabels) => {
            if (!opEntryLabels) {
                return [];
            }

            return opEntryLabels.filter((opEntryLabel) => opEntryLabel.opEntry === opEntryUri);
        }
    );

export const selectTeamsForDepartment = (department = {}) =>
    createSelector(
        [(state) => state.operations.teams.ids.map((id) => state.operations.teams.entities[id]), () => department],
        (teams, department) => {
            if (!teams || !department) {
                return [];
            }

            return teams.filter((team) => team.opDepartment === department['@id'] && !team.deletedAt);
        }
    );

export const selectDepartmentTeams = (opList) =>
    createSelector(
        [
            selectUserDepartments(opList),
            (state) => state.operations.teams.ids.map((id) => state.operations.teams.entities[id]),
        ],
        (departments, teams) => {
            if (departments.length === 0 || teams.length === 0) {
                return [];
            }

            return teams.filter((team) => {
                return departments.find((department) => team.opDepartment === department['@id']) && !team.deletedAt;
            });
        }
    );

export const selectDepartmentUsers = (department, organisationUsers = []) =>
    createSelector(
        [
            (state) => selectOpListUserEntities(state.operations),
            () => organisationUsers
        ],
        (opListUsers = [], users = []) => {
            const userIds = users.map((_user) => _user.id);

            return department.opListUsers
                .filter((_opListUserId) => opListUsers.hasOwnProperty(_opListUserId))
                .map((_opListUserId) => opListUsers[_opListUserId])
                .filter((_opListUser) => userIds.includes(_opListUser.userId))
                .map((_opListUser) => {
                    const user = users.find((_user) => _user.id === _opListUser.userId)

                    return {
                        ..._opListUser,
                        user,
                        name: `${user.fullName}`,
                    };
                })
                .sort(HelperFunctions.sortByString('name'));
        }
    );

export const getAllOpLists = () =>
    createSelector(
        [(state) => state.security.activeOrganisation, (state) => selectAllOpLists(state.operations)],
        (activeOrganisation, opLists = []) => {
            return opLists.filter((_opList) => _opList.organisationId === activeOrganisation);
        }
    );

export const { selectById: selectReportsDataByDepartmentId } = ReportsDataAdapter.getSelectors(
    (state) => state.reportsData
);

export const { selectById: selectOpListSummary } = OpListSummaryAdapter.getSelectors((state) => state.opListSummaries);

export const selectAllEligibleDepartmentUsers = (state) => state.eligibleDepartmentUsers;
