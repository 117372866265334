import { useTranslation } from 'react-i18next';
import { TranslatableSwitch } from 'pages/publications_v2/helpers/FieldHelper';
import { Col, Form, Row } from 'react-bootstrap';
import React from 'react';
import { STATUS_ACTIVE } from 'pages/account/views/Billing';
import { useCurrentOrganisation } from 'hooks/useCurrentOrganisation';
import { useUpdateOrganisationMutation } from 'features/security/authApi';
import { useGetSubscriptionStatus } from 'hooks/useGetSubscriptionStatus';

export function Branding() {
    const { t } = useTranslation();
    const organisation = useCurrentOrganisation();
    const [updateOrganisation] = useUpdateOrganisationMutation();

    const status = useGetSubscriptionStatus();
    const hasActiveSubscription = status === STATUS_ACTIVE;

    return (
        <div className="pt-4">
            <div className="subheader">
                <h3>{t('users:profile.billing.branding')}</h3>
            </div>

            <Form.Group as={Row}>
                <Form.Label column sm={3} htmlFor={name}>
                    {t('users:profile.billing.showBranding')}
                </Form.Label>
                <Col sm={9}>
                    <div className="col-form-label-switch">
                        <TranslatableSwitch
                            id="showBranding"
                            name="showBranding"
                            checked={organisation.showBranding}
                            disabled={!hasActiveSubscription}
                            onChange={handleChange}
                        />
                        <Form.Text id="showBranding" muted>
                            {t(
                                hasActiveSubscription
                                    ? 'users:profile.billing.brandingSwitchHelp'
                                    : 'users:profile.billing.brandingSwitchWarning'
                            )}
                        </Form.Text>
                    </div>
                </Col>
            </Form.Group>
        </div>
    );

    function handleChange() {
        updateOrganisation({
            id: organisation.id,
            body: {
                showBranding: !organisation.showBranding,
            },
        });
    }
}
