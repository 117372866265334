import { useParams } from 'react-router-dom';
import Sidebar from '../settings/Sidebar';
import General from '../settings/General';
import Team from '../settings/Team';
import Language from '../settings/Language';
import Constants from '../../../../config/Constants';
import RestrictedTranslationContent from '../../RestrictedTranslationContent';

export default function Properties() {
    const { translationContainerId } = useParams();

    return (
        <RestrictedTranslationContent
            id={translationContainerId}
            roles={[
                Constants.translationContainerTeamRoles.manager,
            ]}
        >
            <div className="d-flex align-items-stretch h-100">
                <div className="content-sidebar overflow-auto bg-light">
                    <Sidebar />
                </div>
                <div className="flex-grow-1 overflow-auto">
                    <div id="content" className="px-5 pt-5 pb-3">
                        <Setting />
                    </div>
                </div>
            </div>
        </RestrictedTranslationContent>
    );
}

function Setting() {
    const { detail = 'general' } = useParams();

    switch (detail) {
        case 'general':
            return <General />;
        case 'team':
            return <Team />;
        case 'languages':
            return <Language />;
    }

    return (
        <div>
            Setting '{view}' is not supported.
        </div>
    );
}
