import React, { useEffect, useState } from 'react';
import Constants, { Permissions } from '../../config/Constants';
import { generatePath, Link, useHistory } from 'react-router-dom';
import HelperFunctions from '../global/HelperFunctions';
import { Container, Dropdown, Row, SplitButton } from 'react-bootstrap';
import RestrictedContent from '../global/RestrictedContent';
import { useDispatch } from 'react-redux';
import RestrictedReimbursementContent, { useCurrentRole } from './RestrictedReimbursementContent';
import CloneApi from '../../api/CloneApi';
import { ArrowRepeat, GearWideConnected, Pencil, PeopleFill } from 'react-bootstrap-icons';
import CrossRefMigrateModal from './views/CrossRefMigrateModal';
import {
    COLLECTIVES_PATH,
    EDIT_CODES_PATH,
    EDIT_PRODUCT_TYPES_PATH,
    EDIT_PRODUCTS_PATH,
    PROPOSITIONS_PATH,
    SETTINGS_PATH,
    VIEW_PATH,
} from 'scenes/Reimbursements';
import {
    reimbursementApi,
    useMigrateCategoryFieldsMutation,
    useUpdateCategoryEditsMutation,
} from 'features/reimbursements/reimbursements';
import NewCategoryModal from './views/modals/NewCategoryModal';
import { useGetCategory } from 'features/reimbursements/reimbursementHooks';
import { IconButton, InfoButton, WarningButton } from 'components/Buttons';
import MainContentNav from '../Navbar';
import { useGetCategories } from './hooks/useGetCategories';
import { useTranslation } from 'react-i18next';
import { useGetUserFullName } from 'hooks/useGetUserFullName';
import cx from 'classnames';

export default function Index() {
    const dispatch = useDispatch();
    const [showNewCategoryModal, setShowNewCategoryModal] = useState(false);
    const categories = useGetCategories();
    const { t } = useTranslation('reimbursements');

    const hasDuplicatingCategories = categories.filter((_category) => _category.cloneTaskId !== null).length > 0;

    useEffect(() => {
        const interval = setInterval(() => {
            if (hasDuplicatingCategories) {
                // Refresh every 5 seconds when there are duplicating categories
                dispatch({
                    type: `${reimbursementApi.reducerPath}/invalidateTags`,
                    payload: [
                        {
                            type: 'Category',
                            id: 'LIST',
                        },
                    ],
                });
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [dispatch, hasDuplicatingCategories]);

    return (
        <>
            <MainContentNav title={t('breadcrumb')} />

            <div className="content-static-body">
                <div className="d-flex align-items-stretch h-100">
                    <div className="flex-grow-1 overflow-auto" style={{ marginTop: 68 }}>
                        <div className="pt-4 pb-3 px-4">
                            <Container>
                                {categories.map((category) => (
                                    <Category id={category.id} key={category.id} />
                                ))}
                            </Container>

                            {showNewCategoryModal && (
                                <NewCategoryModal
                                    showModal={showNewCategoryModal}
                                    handleClose={() => setShowNewCategoryModal(false)}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="bg-bgLight px-4 py-3"
                style={{
                    position: 'absolute',
                    top: 73,
                    left: 0,
                    right: 0,
                }}
            >
                <div className="d-flex align-items-center">
                    <MainContentNavButtons setShowNewCategoryModal={setShowNewCategoryModal} />
                </div>
            </div>
        </>
    );
}

function Category({ id }) {
    const { category } = useGetCategory(id);
    const { t } = useTranslation('reimbursements');
    const { cloneTaskId } = category;
    const creatorName = useGetUserFullName(category.createdBy);
    const dispatch = useDispatch();
    const [showCrossRefMigrateModal, setShowCrossRefMigrateModal] = useState(false);
    const currentRole = useCurrentRole(id);
    const [updateCategoryEdits] = useUpdateCategoryEditsMutation();
    const [isUpdating, setIsUpdating] = useState(false);

    const handleDuplicate = (event) => {
        event.preventDefault();

        HelperFunctions.confirmModal(
            `${category.name} ${t('main.category.duplicateNow')}`,
            undefined,
            false,
            t('main.category.yesDuplicate'),
        ).then(async () => {
            await CloneApi.createCloneTask('categories', category.id);

            // Reload Categories to get new status
            dispatch({
                type: `${reimbursementApi.reducerPath}/invalidateTags`,
                payload: [
                    {
                        type: 'Category',
                        id: 'LIST',
                    },
                ],
            });
        });
    };

    const handleCrossRefMigrate = (event) => {
        event.preventDefault();

        setShowCrossRefMigrateModal(true);
    };

    let roleValue;

    if (currentRole === 'spectator') {
        roleValue = t('settings.team.roles.spectator');
    } else if (currentRole === 'editor') {
        roleValue = t('settings.team.roles.editor');
    } else if (currentRole === 'finalEditor') {
        roleValue = t('settings.team.roles.finalEditor');
    } else if (currentRole === 'manager') {
        roleValue = t('settings.team.roles.manager');
    } else if (currentRole === 'admin') {
        roleValue = t('settings.team.roles.admin');
    }

    return (
        <Row className="dr-container mb-3">
            <div className="col-5">
                <div className="py-3 pl-2">
                    <div className="font-weight-bolder mb-1">{category.name}</div>

                    <div className="small">
                        {category.description && <div className="text-secondary">{category.description}</div>}
                        {creatorName && (
                            <div>
                                {t('main.category.createdBy')} {creatorName}
                            </div>
                        )}
                    </div>

                    {cloneTaskId !== null && (
                        <div className="d-flex align-items-center mt-3">
                            <GearWideConnected size={18} className="icon-spin text-warning mr-1" />
                            <div className="text-warning small">{t('main.category.busyDuplicating')}</div>
                        </div>
                    )}
                </div>
            </div>
            <div className="col-4">
                <div className="py-3">
                    <div className="d-flex">
                        <InfoBlock
                            title={t('main.category.progress')}
                            icon={<Pencil className="text-secondary mr-2" size={14} />}
                            subTitle={t('main.category.proposedChanges')}
                            value={category.unprocessedEdits}
                            onRefresh={refreshCategory}
                            isUpdating={isUpdating}
                        />
                        <InfoBlock
                            icon={<PeopleFill className="text-secondary mr-2" size={14} />}
                            title={t('main.category.teamMembers')}
                            value={category.teamUsers.length}
                        />
                        <InfoBlock title={t('main.category.yourRole')} value={roleValue} />
                    </div>
                </div>
            </div>
            <div className="col-3">
                <div className="d-flex justify-content-end py-3 pr-2">
                    <RestrictedReimbursementContent
                        categoryId={category.id}
                        roles={[Constants.reimbursementTeamRoles.manager, Constants.reimbursementTeamRoles.admin]}
                    >
                        {category.fieldsMigrated ? (
                            <ReimbursementMenu
                                category={category}
                                handleCrossRefMigrate={handleCrossRefMigrate}
                                handleDuplicate={handleDuplicate}
                            />
                        ) : (
                            <MigrateCategory category={category} />
                        )}
                    </RestrictedReimbursementContent>

                    {category.fieldsMigrated && (
                        <RestrictedReimbursementContent
                            categoryId={category.id}
                            roles={[
                                Constants.reimbursementTeamRoles.spectator,
                                Constants.reimbursementTeamRoles.editor,
                                Constants.reimbursementTeamRoles.finalEditor,
                            ]}
                        >
                            <Link
                                to={generatePath(VIEW_PATH, {
                                    categoryId: category.id,
                                })}
                                className="uk-button uk-button-primary"
                            >
                                {t('main.category.openReimbursements')}
                            </Link>
                        </RestrictedReimbursementContent>
                    )}
                </div>
            </div>

            {showCrossRefMigrateModal && (
                <CrossRefMigrateModal category={category} hideModal={() => setShowCrossRefMigrateModal(false)} />
            )}
        </Row>
    );

    function refreshCategory() {
        setIsUpdating(true);

        updateCategoryEdits(category['@id']).then(() => {
            setIsUpdating(false);
        });
    }
}

function ReimbursementMenu({ category, handleDuplicate, handleCrossRefMigrate }) {
    const { t } = useTranslation('reimbursements');
    const history = useHistory();

    return (
        <SplitButton
            id={`category-${category.id}-options-btn`}
            variant="primary"
            title={t('main.dorpdown.openReimbursement')}
            onClick={() => {
                history.push(
                    generatePath(VIEW_PATH, {
                        categoryId: category.id,
                    }),
                );
            }}
            alignRight={true}
        >
            <RestrictedReimbursementContent categoryId={category.id} roles={[Constants.reimbursementTeamRoles.admin]}>
                <Dropdown.Item
                    as={Link}
                    to={generatePath(SETTINGS_PATH, {
                        categoryId: category.id,
                        view: 'properties',
                    })}
                >
                    {t('main.dorpdown.properties')}
                </Dropdown.Item>
            </RestrictedReimbursementContent>

            <RestrictedReimbursementContent
                categoryId={category.id}
                roles={[Constants.reimbursementTeamRoles.admin, Constants.reimbursementTeamRoles.manager]}
            >
                <Dropdown.Item
                    as={Link}
                    to={generatePath(EDIT_PRODUCTS_PATH, {
                        categoryId: category.id,
                    })}
                >
                    {t('main.dorpdown.policies')}
                </Dropdown.Item>
                <Dropdown.Item
                    as={Link}
                    to={generatePath(EDIT_CODES_PATH, {
                        categoryId: category.id,
                    })}
                >
                    {t('main.dorpdown.reimbursementCodes')}
                </Dropdown.Item>
                <Dropdown.Item
                    as={Link}
                    to={generatePath(PROPOSITIONS_PATH, {
                        categoryId: category.id,
                    })}
                >
                    {t('main.dorpdown.propositions')}
                </Dropdown.Item>
                <Dropdown.Item
                    as={Link}
                    to={generatePath(COLLECTIVES_PATH, {
                        categoryId: category.id,
                    })}
                >
                    {t('main.dorpdown.collectivities')}
                </Dropdown.Item>
            </RestrictedReimbursementContent>

            <RestrictedReimbursementContent categoryId={category.id} roles={[Constants.reimbursementTeamRoles.admin]}>
                <Dropdown.Item
                    as={Link}
                    to={generatePath(EDIT_PRODUCT_TYPES_PATH, {
                        categoryId: category.id,
                    })}
                >
                    {t('main.dorpdown.productGroups')}
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={handleDuplicate}>{t('main.dorpdown.duplicate')}&hellip;</Dropdown.Item>
                <Dropdown.Item onClick={handleCrossRefMigrate}>
                    {t('main.dorpdown.migrateCrossRef')}&hellip;
                </Dropdown.Item>
            </RestrictedReimbursementContent>
        </SplitButton>
    );
}

function MigrateCategory({ category }) {
    const [isMigrating, setMigrating] = useState(false);
    const [migrateCategoryFields] = useMigrateCategoryFieldsMutation();
    const { t } = useTranslation('reimbursements');

    const startMigration = () => {
        setMigrating(true);

        migrateCategoryFields(category['@id']).then((result) => {
            if ('error' in result) {
                HelperFunctions.alertModal(t('main.error.migrationError'));
            }

            setMigrating(false);
        });
    };

    return (
        <WarningButton
            onClick={() => {
                HelperFunctions.confirmModal(
                    t('main.messages.startMigration'),
                    undefined,
                    false,
                    t('main.btn.startMigration'),
                ).then(() => {
                    startMigration();
                });
            }}
            disabled={isMigrating}
        >
            {isMigrating ? `${t('main.messages.busyMigrating')}...` : `${t('main.messages.migratingContent')}...`}
        </WarningButton>
    );
}

function InfoBlock({ icon, subTitle, title, value, onRefresh, isUpdating = false }) {
    return (
        <div className="px-3">
            {title && <p className="text-uppercase mb-2">{title}</p>}

            <div className="d-flex align-items-center mb-1">
                {icon && <>{icon}</>}
                <div
                    className={cx({
                        'text-body': !isUpdating,
                        'text-muted': isUpdating,
                    })}
                    style={{ fontSize: '1rem' }}
                >
                    {value}
                </div>

                {onRefresh && (
                    <IconButton
                        tooltip="Refresh"
                        icon={
                            <ArrowRepeat
                                className={cx({
                                    'icon-spin': isUpdating,
                                })}
                                size={14}
                            />
                        }
                        className="text-secondary ml-2"
                        style={{ marginBottom: 3 }}
                        onClick={onRefresh}
                        disabled={isUpdating}
                    />
                )}
            </div>

            {subTitle && <span className="small">{subTitle}</span>}
        </div>
    );
}

const MainContentNavButtons = ({ setShowNewCategoryModal }) => {
    const { t } = useTranslation('reimbursements');
    return (
        <RestrictedContent permission={Permissions.ReimbursementCategory.Create}>
            <div className=" ml-auto d-flex align-items-center" style={{ marginRight: '25px', marginTop: '8px' }}>
                <InfoButton className="ml-auto" onClick={() => setShowNewCategoryModal(true)}>
                    {t('main.btn.newReimbursement')}
                </InfoButton>
            </div>
        </RestrictedContent>
    );
};
