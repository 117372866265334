import { useParams } from 'react-router-dom';
import { useGetDocumentVariantsQuery } from '../../../../../features/translations/translationApi';
import TranslateArea from './Area';
import TranslateSection from './Section';
import { entityTypes } from '../../../config/Constants';
import TranslateTag from './Tag';
import { TranslateVariantPackage } from './VariantPackage';

export default function DocumentTranslate({ translationContainer, translationLanguage }) {
    const { entityType } = useParams();
    const { documentId } = translationContainer;
    const getDocumentVariantsQuery = useGetDocumentVariantsQuery(documentId);
    const documentVariants = getDocumentVariantsQuery.data;

    if (getDocumentVariantsQuery.isLoading) {
        return null;
    }

    if (entityType === entityTypes.AREA) {
        return (
            <TranslateArea
                translationContainer={translationContainer}
                documentVariants={documentVariants}
            />
        );
    }

    if (entityType === entityTypes.SECTION) {
        return (
            <TranslateSection
                translationContainer={translationContainer}
                documentVariants={documentVariants}
                translationLanguage={translationLanguage}
            />
        );
    }

    if (entityType === entityTypes.TAG) {
        return (
            <TranslateTag
                translationContainer={translationContainer}
                translationLanguage={translationLanguage}
            />
        );
    }

    if (entityType === entityTypes.VARIANT_PACKAGE) {
        return (
            <TranslateVariantPackage
                translationContainer={translationContainer}
                translationLanguage={translationLanguage}
            />
        );
    }

    return null;
}
