import RestrictedDocumentContent from '../../../RestrictedDocumentContent';
import Constants, { Permissions } from '../../../../../config/Constants';
import { useParams } from 'react-router-dom';
import { useGetDocument } from '../../../hooks/useGetDocument';
import { useGetBaseVariant } from '../../../hooks/useGetBaseVariant';
import LoadingSpinner from '../../../../global/LoadingSpinner';
import { ActionLinkButton, GenericButton, InfoButton } from 'components/Buttons';
import { Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BooleanParam, NumberParam, useQueryParam } from 'use-query-params';
import { VariantFormModal } from './VariantFormModal';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import cx from 'classnames';
import { RxDragHandleDots2 } from 'react-icons/rx';
import { findDeep } from 'deepdash-es/standalone';
import HelperFunctions from '../../../../global/HelperFunctions';
import { useState } from 'react';
import { useUpdateDocumentVariantsMutation } from 'features/documents/documents';
import Spinner from '../../../../global/Spinner';
import { CaretDownFill, CaretRightFill } from 'react-bootstrap-icons';
import { LinkDocumentModal } from './LinkDocumentModal';
import RestrictedContent from '../../../../global/RestrictedContent';
import { LinkProductModal } from './LinkProductModal';
import { PublicationsModal } from './PublicationsModal';
import { VariantPublicationsModal } from './VariantPublicationsModal';
import { VariantActions } from './VariantActions';
import { GroupActions } from './GroupActions';
import _ from 'lodash';
import { useGetDocumentVariantPublications } from 'pages/documents_v2/views/edit/variants/useGetDocumentVariantPublications';

export function Variants() {
    const { t } = useTranslation('documents');
    const document = useGetDocument();
    const { documentId } = useParams();
    const [editVariant, setEditVariant] = useQueryParam('edit', NumberParam);
    const [isSubmitting, setSubmitting] = useState(false);
    const [createPublication, setCreatePublication] = useQueryParam('createPublication', BooleanParam);

    const showPublicationButton = document?.modelId;

    return (
        <div className="content-static-body has-subnav">
            <div className="d-flex align-items-stretch h-100">
                <div className="overflow-auto w-100">
                    <div className="mt-5 ml-5 mr-3 mr-xxl-5 dr-container p-4 mb-4">
                        <div className="subheader d-flex justify-content-between pb-2">
                            <h3 className="mb-0">
                                {t('document.navbar.title.variants')}
                                {isSubmitting && <Spinner />}
                            </h3>

                            <div>
                                {showPublicationButton && (
                                    <RestrictedContent permission={Permissions.Publication.Create}>
                                        <GenericButton
                                            variant="outline-primary"
                                            className="mr-2 mb-0"
                                            size="sm"
                                            onClick={() => setCreatePublication(true)}
                                        >
                                            {t('document.navbar.variants.btn.createPublication')}
                                        </GenericButton>
                                    </RestrictedContent>
                                )}

                                <RestrictedDocumentContent
                                    documentId={documentId}
                                    roles={[
                                        Constants.userDocumentRole.finalEditor,
                                        Constants.userDocumentRole.documentManager,
                                    ]}
                                >
                                    <InfoButton size="sm" onClick={() => setEditVariant(0)} className="mb-0">
                                        {t('document.navbar.variants.btn.newVariant')}
                                    </InfoButton>
                                </RestrictedDocumentContent>
                            </div>
                        </div>

                        <RestrictedDocumentContent
                            documentId={documentId}
                            roles={[Constants.userDocumentRole.finalEditor, Constants.userDocumentRole.documentManager]}
                        >
                            <>
                                <DocumentVariants isSubmitting={isSubmitting} setSubmitting={setSubmitting} />
                                <VariantFormModal />
                                <LinkDocumentModal />
                                <LinkProductModal />
                                <PublicationsModal />
                                <VariantPublicationsModal />
                            </>
                        </RestrictedDocumentContent>
                    </div>
                </div>
            </div>
        </div>
    );
}

function DocumentVariants({ isSubmitting, setSubmitting }) {
    const { t } = useTranslation('documents');
    const document = useGetDocument();
    const baseVariant = useGetBaseVariant();
    const [isDragging, setIsDragging] = useState(false);
    const [updateDocumentVariants] = useUpdateDocumentVariantsMutation();

    const findVariant = (variantId) => {
        const result = findDeep(
            [baseVariant],
            (value) => {
                return value.id === variantId;
            },
            {
                childrenPath: 'children',
            }
        );

        return result?.value;
    };

    const onDragStart = () => {
        setIsDragging(true);
    };

    const moveGroup = (variant, index, direction) => {
        if (direction === 'up' && index === 0) {
            return;
        }

        const parent = findVariant(variant.parentId);

        handleMove(variant, parent, index, direction === 'up' ? index - 1 : index + 1);
    };

    const handleMove = (variant, destinationGroup, fromIndex, toIndex) => {
        setSubmitting(true);

        // const destinationVariants = destinationGroup.children.filter((variant) => !variant.variantGroup);
        let newDestinationVariants = [];

        if (variant.parentId === destinationGroup.id) {
            // Re-order is same group
            newDestinationVariants = HelperFunctions.arrayMove(destinationGroup.children, fromIndex, toIndex);
        } else {
            // Move to new group
            variant.parentId = destinationGroup.id;
            newDestinationVariants = HelperFunctions.arrayInsert(destinationGroup.children, variant, toIndex);
        }

        if (newDestinationVariants.length === 0) {
            // Something went wrong
            setSubmitting(false);
            return;
        }

        const changedVariants = [];

        newDestinationVariants.forEach((_variant, index) => {
            if (_variant.sortOrder !== index) {
                changedVariants.push({
                    id: _variant.id,
                    parentId: _variant.parentId,
                    sortOrder: index,
                });
            }
        });

        if (changedVariants.length === 0) {
            setSubmitting(false);
            return;
        }

        updateDocumentVariants({
            id: document.id,
            body: changedVariants,
        }).then(() => {
            setSubmitting(false);
        });
    };

    const onDragEnd = (result) => {
        setIsDragging(false);
        const { source, destination, draggableId } = result;

        if (
            !source ||
            !destination ||
            (source.droppableId === destination.droppableId && source.index === destination.index)
        ) {
            return;
        }

        // The dragged variant
        const draggedId = draggableId.split('|');
        const draggedVariant = {
            ...findVariant(parseInt(draggedId[0])),
        };

        // The destination group and variant
        const destinationGroup = findVariant(parseInt(destination.droppableId));

        handleMove(draggedVariant, destinationGroup, source.index, destination.index);
    };

    if (!document || !baseVariant) {
        return (
            <div>
                <LoadingSpinner inline size="sm" />
            </div>
        );
    }

    return (
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
            <div
                className={cx('dr-variants', {
                    'variant-is-dragging': isDragging,
                })}
            >
                <div className="d-flex align-items-center py-2 pr-2 mb-1">
                    {t('document.navbar.variants.basicVariant')}
                </div>

                <ChildVariants
                    variant={baseVariant}
                    isSubmitting={isSubmitting}
                    setSubmitting={setSubmitting}
                    moveGroup={moveGroup}
                />
            </div>
        </DragDropContext>
    );
}

function VariantGroup({ variant, isSubmitting, setSubmitting, moveGroup, index = 0, isLast }) {
    const [isExpanded, setExpanded] = useState(true);

    return (
        <div className="dr-variant-border dr-variant-group">
            <Draggable isDragDisabled={true} draggableId={`${variant.id}|${variant.parentId}`} index={index}>
                {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                        <div className="toggle-on-hover d-flex align-items-center py-2 pr-2" style={{ paddingLeft: 3 }}>
                            <div
                                className="d-flex align-items-center cursor-pointer"
                                onClick={() => setExpanded(!isExpanded)}
                            >
                                {isExpanded ? (
                                    <CaretDownFill size={12} className="mr-1" />
                                ) : (
                                    <CaretRightFill size={12} className="mr-1" />
                                )}

                                {variant.prefix && (
                                    <div className="small text-muted mr-2" style={{ paddingTop: 2 }}>
                                        {variant.prefix}
                                    </div>
                                )}
                                <div className="font-weight-bold">{variant.name}</div>
                            </div>

                            <GroupActions variant={variant} moveGroup={moveGroup} index={index} isLast={isLast} />

                            <div className="ml-auto" style={{ marginRight: 1 }}>
                                <VariantActions
                                    variant={variant}
                                    isSubmitting={isSubmitting}
                                    setSubmitting={setSubmitting}
                                />
                            </div>
                        </div>

                        {isExpanded && (
                            <ChildVariants
                                variant={variant}
                                isSubmitting={isSubmitting}
                                setSubmitting={setSubmitting}
                                moveGroup={moveGroup}
                            />
                        )}
                    </div>
                )}
            </Draggable>
        </div>
    );
}

function ChildVariants({ variant, isSubmitting, setSubmitting, moveGroup }) {
    const { t } = useTranslation('documents');

    return (
        <div
            className={cx('position-relative pl-2 pb-2 mb-2', {
                'ml-n1': variant.parentId === null,
            })}
        >
            <Droppable droppableId={`${variant.id}`} type={`TYPE-${variant.id}`}>
                {(provided, snapshot) => (
                    <div
                        className={cx('variant-droppable', {
                            'is-dragging-over': snapshot.isDraggingOver,
                        })}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {variant.children.map((childVariant, index) => (
                            <div className="dr-child-variant-wrapper pl-3" key={`variant-${childVariant.id}`}>
                                {childVariant.variantGroup ? (
                                    <VariantGroup
                                        variant={childVariant}
                                        isSubmitting={isSubmitting}
                                        setSubmitting={setSubmitting}
                                        moveGroup={moveGroup}
                                        index={index}
                                        isLast={index + 1 === variant.children.length}
                                    />
                                ) : (
                                    <Variant
                                        variant={childVariant}
                                        index={index}
                                        isSubmitting={isSubmitting}
                                        setSubmitting={setSubmitting}
                                    />
                                )}
                            </div>
                        ))}

                        {variant.children.length === 0 && (
                            <div className="text-muted bg-light small font-italic p-3 mr-2">
                                {t('document.navbar.variants.emptyGroup')}
                            </div>
                        )}

                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </div>
    );
}

function Variant({ variant, index, isSubmitting, setSubmitting }) {
    const [editVariant, setEditVariant] = useQueryParam('edit', NumberParam);
    const { t } = useTranslation('documents');

    return (
        <div className="dr-variant-border">
            <Draggable isDragDisabled={isSubmitting} draggableId={`${variant.id}|${variant.parentId}`} index={index}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={cx('dr-variant py-1', {
                            'is-dragging': snapshot.isDragging,
                        })}
                    >
                        <div className="toggle-on-hover d-flex align-items-center flex-grow-1 bg-white py-2 pl-1 pr-2 border">
                            <div {...provided.dragHandleProps}>
                                <RxDragHandleDots2
                                    className="drag-handle flex-shrink-0 text-color toggle-visible"
                                    size={14}
                                />
                            </div>

                            {variant.prefix && (
                                <div className="small text-muted mr-2" style={{ paddingTop: 2 }}>
                                    {variant.prefix}
                                </div>
                            )}

                            <ActionLinkButton disabled={!variant.enabled} onClick={() => setEditVariant(variant.id)}>
                                {variant.name}
                            </ActionLinkButton>

                            {!variant.enabled && (
                                <div className="text-muted small">
                                    &nbsp;{t('document.navbar.variants.message.inactive')}
                                </div>
                            )}

                            <ExtraVariantInfo variant={variant} />

                            <div className="ml-auto">
                                <VariantActions
                                    variant={variant}
                                    isSubmitting={isSubmitting}
                                    setSubmitting={setSubmitting}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </Draggable>
        </div>
    );
}

function ExtraVariantInfo({ variant }) {
    const { t } = useTranslation('documents');
    const [variantPublications, setVariantPublications] = useQueryParam('variantPublications', NumberParam);
    const baseVariant = useGetBaseVariant();

    const ipidCardId = _.get(variant, 'ipidProperties.cardId');
    const ipidCardVersion = _.get(variant, 'ipidProperties.cardVersion');

    const publicationsForVariant = useGetDocumentVariantPublications(variant.id, baseVariant);

    return (
        <>
            {publicationsForVariant.length > 0 && (
                <Badge
                    variant="special"
                    className="cursor-pointer ml-2"
                    onClick={() => setVariantPublications(variant.id)}
                >
                    {t('document.navbar.variants.variantPublications', { count: publicationsForVariant.length })}
                </Badge>
            )}

            {!!ipidCardId && (
                <div className="ml-2 text-muted font-italic small">
                    ipid kaart {ipidCardId}
                    {ipidCardVersion && <>, versie {ipidCardVersion}</>}
                </div>
            )}
        </>
    );
}
