import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import OperationsListApi from '../../api/OperationsListApi';
import { normalize } from 'normalizr';
import { taskEntity } from 'schemas';
import _ from 'lodash';
import { selectDepartmentUserBelongsTo } from './opListUserSlice';

const TaskAdapter = createEntityAdapter({
    selectId: (entity) => entity['@id'],
});

export const fetchDepartmentTasks = createAsyncThunk(
    'tasks/fetchDepartmentTasks',
    async ({ opListId, opDepartmentId }) => {
        const results = await OperationsListApi.getDepartmentTasks(opListId, opDepartmentId);
        const normalized = normalize(results['hydra:member'], [taskEntity]);

        return normalized.entities;
    }
);
export const fetchTasks = createAsyncThunk('tasks/fetchTasks', async () => {
    const results = await OperationsListApi.getTasks();
    const normalized = normalize(results['hydra:member'], [taskEntity]);

    return normalized.entities;
});
export const fetchTask = createAsyncThunk('tasks/fetchTask', async ({ uri }) => {
    const results = await OperationsListApi.get(uri);
    const normalized = normalize(results, taskEntity);

    return normalized.entities;
});
export const addTask = createAsyncThunk('tasks/addTask', async (formData) => {
    const results = await OperationsListApi.addTask(formData);
    const normalized = normalize(results, taskEntity);

    return normalized.entities;
});
export const patchTask = createAsyncThunk('tasks/patchTask', async ({ uri, formData }) => {
    const results = await OperationsListApi.patch(uri, formData);
    const normalized = normalize(results, taskEntity);

    return normalized.entities;
});
export const removeTask = createAsyncThunk('tasks/removeTask', async (uri) => {
    await OperationsListApi.remove(uri);
    return uri;
});

export const addField = createAsyncThunk('tasks/addField', (formData) => {
    return OperationsListApi.addField(formData);
});
export const patchField = createAsyncThunk('tasks/patchField', ({ uri, formData }) => {
    return OperationsListApi.patch(uri, formData);
});
export const removeField = createAsyncThunk('tasks/removeField', (uri) => {
    return OperationsListApi.remove(uri);
});

const taskSlice = createSlice({
    name: 'tasks',
    initialState: {
        tasks: TaskAdapter.getInitialState(),
    },
    extraReducers: {
        ['security/resetState']: (state) => {
            Object.assign(state, taskSlice.getInitialState());
        },
        [fetchDepartmentTasks.fulfilled]: (state, action) => {
            TaskAdapter.upsertMany(state.tasks, action.payload.tasks ?? []);
        },
        [fetchTasks.fulfilled]: (state, action) => {
            TaskAdapter.upsertMany(state.tasks, action.payload.tasks ?? []);
        },
        [fetchTask.fulfilled]: (state, action) => {
            TaskAdapter.upsertMany(state.tasks, action.payload.tasks ?? []);
        },
        [addTask.fulfilled]: (state, action) => {
            TaskAdapter.upsertMany(state.tasks, action.payload.tasks ?? []);
        },
        [patchTask.fulfilled]: (state, action) => {
            TaskAdapter.upsertMany(state.tasks, action.payload.tasks ?? []);
        },
        [removeTask.fulfilled]: (state, action) => {
            TaskAdapter.removeOne(state.tasks, action.payload);
        },
    },
});

const { selectAll: selectAllTasks } = TaskAdapter.getSelectors((state) => state.tasks);

export const selectAllDepartmentTasksForOpList = (opList) => {
    return createSelector(
        [() => opList, selectDepartmentUserBelongsTo(opList), (state) => selectAllTasks(state.tasks)],
        (opList, department, tasks) => {
            if (!opList || !department) {
                return [];
            }

            return _.filter(tasks, (task) => task.opList === opList['@id'] && task.opDepartment === department['@id']);
        }
    );
};

export default taskSlice.reducer;
