import { AreaBlock, DocumentVariant } from '../../../../models/documents.models';
import { useAppDispatch, useAppSelector } from '../../../../store';
import HelperFunctions from '../../../global/HelperFunctions';
import { useContext, useEffect, useMemo } from 'react';
import { getBlockHeight, setBlockPosition } from '../../../../features/documents/documentsV2Slice';
import { VariantFilterContext } from '../../../documents/misc/AreaLayout';
import classNames from 'classnames';
import DocumentHelper from '../../../global/DocumentHelper';
import { useHeight } from '../../../../hooks/useHeight';
import { useGetBaseVariant } from '../../hooks/useGetBaseVariant';

export function BlockVariants({ blocks }: { blocks: AreaBlock[] }) {
    const baseVariant: DocumentVariant | undefined = useGetBaseVariant();

    const variants: DocumentVariant[] = useMemo(() => {
        if (baseVariant === undefined) {
            return [];
        }

        const variantsAsList: DocumentVariant[] = HelperFunctions.variantAsList(baseVariant);

        return variantsAsList.filter((variant) => !variant.variantGroup && variant.parentId !== null);
    }, [baseVariant]);

    const blockIdsInLayout: number[] = useMemo(() => {
        return DocumentHelper.getBlockIdsInLayout(blocks);
    }, [blocks]);

    return (
        <div>
            {blocks
                .filter((block) => !blockIdsInLayout.includes(block.key))
                .sort(HelperFunctions.dynamicSort('sortOrder'))
                .map((block, index) => (
                    <Block
                        blockId={block.key}
                        variants={variants.filter((variant) => block.documentVariants.includes(variant.id))}
                        key={`block-${block.key}-variants-index-${index}`}
                    />
                ))}
        </div>
    );
}

function Block({ blockId, variants }: { blockId: number; variants: DocumentVariant[] }) {
    const leftHeight = useAppSelector<number>(getBlockHeight(blockId, 'left'));
    const dispatch = useAppDispatch();
    const [divHeight, ref] = useHeight<HTMLDivElement>();

    useEffect(() => {
        dispatch(
            setBlockPosition({
                blockId,
                view: 'right',
                rect: {
                    height: divHeight,
                },
            })
        );
    }, [divHeight]);

    return (
        <div style={{ marginBottom: '2.5rem' }}>
            <div style={{ minHeight: leftHeight > divHeight ? leftHeight : undefined }}>
                <div className="border-top pt-3 px-1" ref={ref}>
                    <div className="d-flex flex-wrap">
                        {variants.map((variant) => (
                            <Variant variant={variant} key={`block-${blockId}-variant-${variant.id}`} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

function Variant({ variant }: { variant: DocumentVariant }) {
    const { selectedVariants }: { selectedVariants: number[] } = useContext(VariantFilterContext);

    return (
        <div
            className={classNames('label mr-2 mb-2', {
                active: selectedVariants.includes(variant.id),
                disabled: selectedVariants.length > 0 && !selectedVariants.includes(variant.id),
            })}
        >
            {variant.prefix !== null && <div className="label-prefix">{variant.prefix}</div>}
            <div className="label-title">{variant.name}</div>
        </div>
    );
}
