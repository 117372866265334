import { ArrayParam, useQueryParam, withDefault } from 'use-query-params';
import _ from 'lodash';
import Constants from 'config/Constants';

const SelectedBlocksParam = withDefault(ArrayParam, []);

export function SelectForCompareBlock({ block, children }) {
    const [selectedBlocks, setSelectedBlocks] = useQueryParam('selectedBlocks', SelectedBlocksParam);

    const blockKey = block.key;
    const selectedBlockIds = selectedBlocks.map((id) => parseInt(id));
    const isChecked = selectedBlockIds.includes(blockKey);

    return (
        <div className="d-flex">
            <div className="flex-shrink-0">
                <input
                    type="checkbox"
                    className="mt-2 mx-2"
                    disabled={!canSelectBlock(block) || (selectedBlockIds.length > 1 && !isChecked)}
                    checked={isChecked}
                    onChange={() => handleCheckboxChange()}
                />
            </div>

            <div className="flex-grow-1">{children}</div>
        </div>
    );

    function handleCheckboxChange() {
        setSelectedBlocks(_.xor(selectedBlockIds, [blockKey]));
    }
}

function canSelectBlock(block) {
    // Normal text blocks
    if (block.type === Constants.blockTypes.text) {
        return true;
    }

    // Special type of layout block
    if (
        block.type === Constants.blockTypes.blockLayout &&
        _.get(block, 'properties.type', '') === Constants.blockTypes.textExplanation
    ) {
        return true;
    }

    return false;
}
