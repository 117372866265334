export function AvatarGroups({ data = [] }) {
    return (
        <>
            <ul className="avatar-group">
                {data.map((avatarData, index) => (
                    <Avatar name={avatarData.name} zIndex={data.length - index} key={`avatar-${index}`} />
                ))}
            </ul>
        </>
    );
}

function Avatar({ name, zIndex }) {
    const url = new URL(`https://ui-avatars.com/api/?name=${name}`);

    return (
        <li>
            <div>
                <span style={{ zIndex }}>
                    <span
                        role="img"
                        style={{
                            backgroundImage: `url(${url.toString()})`,
                            borderRadius: '50%',
                        }}
                    />
                </span>
            </div>
        </li>
    );
}
