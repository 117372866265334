import { EntityReferenceStatus } from './EntityReferenceStatus';
import React, { useContext } from 'react';
import { CaretUpFill } from 'react-bootstrap-icons';
import { AccordionContext } from 'react-bootstrap';
import { EditContext } from '../view/EditContextWrapper';
import Highlighter from 'react-highlight-words';
import _ from 'lodash';
import { entityTypes } from '../../config/Constants';

function EntityCardTitle({
    translationContainerUri,
    entityType,
    entityUri,
    title,
    languageIso,
    hasChildren = false,
    eventKey = '',
}: {
    translationContainerUri: string;
    entityType: string;
    entityUri: string;
    title: string;
    languageIso: string;
    hasChildren?: boolean;
    eventKey?: string;
}) {
    return (
        <>
            <div className="text-title mb-1" style={{ fontSize: entityType === entityTypes.SECTION ? 16 : 14 }}>
                <EntityTitle title={title} />
                {hasChildren && <EntityTitleCaret eventKey={eventKey} />}
            </div>
            <div className="font-weight-normal small">
                <EntityReferenceStatus
                    translationContainerUri={translationContainerUri}
                    entityType={entityType}
                    entityUri={entityUri}
                    languageIso={languageIso}
                />
            </div>
        </>
    );
}

function EntityTitle({ title }: { title: string }) {
    const { search } = useContext(EditContext);

    if (search.length > 0) {
        return (
            <Highlighter
                highlightClassName="highlight-search"
                searchWords={[search]}
                autoEscape={true}
                textToHighlight={title}
            />
        );
    }

    return <>{_.truncate(title, { length: 110 })}</>;
}

function EntityTitleCaret({ eventKey }: { eventKey: string }) {
    const currentEventKey = useContext(AccordionContext);
    const isExpanded = currentEventKey === eventKey;

    return (
        <CaretUpFill
            size={10}
            className="chevron text-secondary ml-2"
            style={{
                transition: 'all .15s ease-out',
                transform: isExpanded ? 'rotate(180deg)' : undefined,
                pointerEvents: 'none',
            }}
        />
    );
}

export default React.memo(EntityCardTitle);
