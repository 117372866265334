import { useUserProfile } from 'hooks/useUserProfile';
import {
    useCreateSsoConfigMutation,
    useDeleteSsoConfigMutation,
    useGetSsoConfigQuery,
    useUpdateSsoConfigMutation,
} from 'features/security/authApi';
import { Form as FForm, Formik } from 'formik';
import { FieldSelect, FormField, Textarea } from '../../publications_v2/helpers/FieldHelper';
import { DangerButton, WarningButton } from 'components/Buttons';
import HelperFunctions from '../../global/HelperFunctions';
import * as Yup from 'yup';
import { Alert, Badge, Col, Form, Row } from 'react-bootstrap';
import { CheckCircleFill, QuestionCircleFill } from 'react-bootstrap-icons';
import getEnv from '../../../config/Env';
import { LoadingSpinner } from '../../global/Spinner';
import { Permissions } from 'config/Constants';
import RestrictedContent from '../../global/RestrictedContent';

export const TYPE_AZURE = 'azure';
export const TYPE_AZURE_SAML = 'azure_saml';

export function Sso() {
    const userProfile = useUserProfile();
    const ssoApiUrl = getEnv('SSO_API_BASE_URL');
    const [deleteSsoConfig] = useDeleteSsoConfigMutation();
    const [updateSsoConfig] = useUpdateSsoConfigMutation();
    const [createSsoConfig] = useCreateSsoConfigMutation();

    const { ssoConfig } = useGetSsoConfigQuery(userProfile.accountId, {
        selectFromResult: ({ data }) => ({
            ssoConfig: data ?? {},
        }),
    });

    const {
        id,
        ssoType = TYPE_AZURE,
        enabled = false,
        clientId = '',
        clientSecret = '',
        entityId = '',
        signInUrl = '',
        logoutUrl = '',
        cert = '',
        slug = '',
    } = ssoConfig;
    const isEditMode = id !== undefined;

    return (
        <RestrictedContent permission={Permissions.SsoConfig.Create}>
            <div>
                <div className="subheader">
                    <h3>Single Sign-On</h3>
                </div>

                {renderRedirectUrls()}

                <Formik
                    initialValues={{
                        ssoType,
                        clientId,
                        clientSecret,
                        entityId,
                        signInUrl,
                        logoutUrl,
                        cert,
                    }}
                    validationSchema={formSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {({ dirty, isSubmitting, isValid, setSubmitting, values }) => (
                        <FForm autoComplete="off">
                            <FieldSelect
                                name="ssoType"
                                label="Type"
                                options={[
                                    {
                                        label: 'Azure',
                                        value: TYPE_AZURE,
                                    },
                                    {
                                        label: 'Azure SAML',
                                        value: TYPE_AZURE_SAML,
                                    },
                                ]}
                                props={{ isDisabled: isEditMode, required: true }}
                            />

                            {values.ssoType === TYPE_AZURE && (
                                <>
                                    <FormField
                                        name="clientId"
                                        label="Application (client) ID"
                                        props={{
                                            disabled: isEditMode,
                                            autoComplete: 'off',
                                            'data-1p-ignore': '',
                                            required: true,
                                        }}
                                    />
                                    <FormField
                                        name="clientSecret"
                                        label="Client secret"
                                        props={{
                                            disabled: isEditMode,
                                            autoComplete: 'off',
                                            'data-1p-ignore': '',
                                            required: true,
                                        }}
                                    />
                                </>
                            )}

                            {values.ssoType === TYPE_AZURE_SAML && (
                                <>
                                    <FormField
                                        name="entityId"
                                        label="Application (entity) ID"
                                        props={{
                                            disabled: isEditMode,
                                            autoComplete: 'off',
                                            'data-1p-ignore': '',
                                            required: true,
                                        }}
                                    />
                                    <FormField
                                        name="signInUrl"
                                        label="Sign In url"
                                        props={{
                                            disabled: isEditMode,
                                            autoComplete: 'off',
                                            'data-1p-ignore': '',
                                            required: true,
                                        }}
                                    />
                                    <FormField
                                        name="logoutUrl"
                                        label="Logout url"
                                        props={{
                                            disabled: isEditMode,
                                            autoComplete: 'off',
                                            'data-1p-ignore': '',
                                            required: true,
                                        }}
                                    />
                                    <Textarea
                                        name="cert"
                                        label="Certificate"
                                        props={{
                                            disabled: isEditMode,
                                            autoComplete: 'off',
                                            'data-1p-ignore': '',
                                            required: true,
                                            rows: 5,
                                        }}
                                    />
                                </>
                            )}

                            {isEditMode && (
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={4}>
                                        Status
                                    </Form.Label>
                                    <Col sm={8}>
                                        <div className="form-control-plaintext">
                                            {enabled ? (
                                                <Badge variant="success" className="d-inline-flex align-items-center">
                                                    <CheckCircleFill className="mr-1" />
                                                    Actief
                                                </Badge>
                                            ) : (
                                                <div className="d-flex align-items-center">
                                                    <Badge
                                                        variant="warning"
                                                        className="d-inline-flex align-items-center mr-2"
                                                    >
                                                        <QuestionCircleFill className="mr-1" />
                                                        Inactief
                                                    </Badge>

                                                    <a
                                                        href={`${ssoApiUrl}/config-redirect/${slug}?email=${userProfile.email}`}
                                                        className="small"
                                                    >
                                                        activeren
                                                    </a>
                                                </div>
                                            )}
                                        </div>
                                    </Col>
                                </Form.Group>
                            )}

                            <div className="d-flex align-items-center pt-4">
                                <WarningButton type="submit" disabled={isSubmitting || !isValid || !dirty}>
                                    Opslaan
                                </WarningButton>

                                {isEditMode && (
                                    <DangerButton className="ml-2" onClick={() => handleDelete(setSubmitting)}>
                                        Verwijderen&hellip;
                                    </DangerButton>
                                )}

                                <LoadingSpinner isSubmitting={isSubmitting} />
                            </div>
                        </FForm>
                    )}
                </Formik>
            </div>
        </RestrictedContent>
    );

    function renderRedirectUrls() {
        if (slug === '') {
            return null;
        }

        return (
            <Alert variant="primary">
                {ssoType === TYPE_AZURE && <>Redirect URI: {`${ssoApiUrl}/connect/${slug}/check`}</>}
                {ssoType === TYPE_AZURE_SAML && (
                    <>
                        SAML metadata XML:{' '}
                        <a
                            href={`${ssoApiUrl}/saml/metadata?idp=${slug}`}
                            target="_blank"
                        >{`${ssoApiUrl}/saml/metadata?idp=${slug}`}</a>
                    </>
                )}
            </Alert>
        );
    }

    function handleSubmit(values, { setSubmitting }) {
        if (isEditMode) {
            updateSsoConfig({ accountId: userProfile.accountId, id: ssoConfig.id, body: values }).then(() => {
                setSubmitting(false);
            });
        } else {
            createSsoConfig({ accountId: userProfile.accountId, body: values }).then(() => {
                setSubmitting(false);
            });
        }
    }

    function handleDelete(setSubmitting) {
        HelperFunctions.confirmModal('Instellingen verwijderen?', 'danger', false)
            .then(() => {
                setSubmitting(true);
                deleteSsoConfig({ accountId: userProfile.accountId, id: ssoConfig.id }).then(() => {
                    setSubmitting(false);
                });
            })
            .catch(() => {});
    }
}

const formSchema = Yup.object().shape({
    ssoType: Yup.string().required(),
    clientId: Yup.string().when('ssoType', {
        is: TYPE_AZURE,
        then: Yup.string().required(),
    }),
    clientSecret: Yup.string().when('ssoType', {
        is: TYPE_AZURE,
        then: Yup.string().required(),
    }),
    entityId: Yup.string().when('ssoType', {
        is: TYPE_AZURE_SAML,
        then: Yup.string().required(),
    }),
    signInUrl: Yup.string().when('ssoType', {
        is: TYPE_AZURE_SAML,
        then: Yup.string().required(),
    }),
    logoutUrl: Yup.string().when('ssoType', {
        is: TYPE_AZURE_SAML,
        then: Yup.string().required(),
    }),
    cert: Yup.string().when('ssoType', {
        is: TYPE_AZURE_SAML,
        then: Yup.string().required(),
    }),
});
