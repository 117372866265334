import { NewItemFromTemplate } from 'pages/documents_v2/views/view/NewItemFromTemplate';
import { useEntityTemplateParameters } from 'pages/documents_v2/hooks/useEntityTemplateParameters';
import { entityTypes } from 'pages/translation/config/Constants';
import HelperFunctions from 'pages/global/HelperFunctions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'react-bootstrap';
import { TbPlaylistAdd } from 'react-icons/tb';
import _ from 'lodash';
import { useUpdateSectionsMutation } from 'features/documents/documents';
import { NumberParam, useQueryParam } from 'use-query-params';
import { LightOutlineButton, PrimaryButton, SecondaryButton } from 'components/Buttons';
import Spinner from 'pages/global/Spinner';

export function AddNewItem({ document, requiredRoles }) {
    const [updateSections] = useUpdateSectionsMutation();
    const [sid, setSid] = useQueryParam('sid', NumberParam);
    const restrictions = useEntityTemplateParameters(document, entityTypes.DOCUMENT, document);

    const canOnlyUseAsWhole = restrictions?.composition
        ? !restrictions?.composition?.includes('canOnlyUseAsWhole')
        : true;

    const role = document.currentUserRole;
    const documentIsChild = !!document.modelId;

    // Is the number of sections limited? Zero means unlimited
    const maxNumberOfSections = _.get(restrictions, 'maxNumberOfSections', 0);

    if (maxNumberOfSections > 0) {
        const numberOfSections = _.get(document, 'sections', []).filter((section) => section.deletedAt === null).length;

        if (numberOfSections >= maxNumberOfSections) {
            return null;
        }
    }

    if (documentIsChild) {
        return (
            <div className="pl-3">
                {canOnlyUseAsWhole ? (
                    <div className="mb-3">
                        <NewItemFormInline requiredRoles={requiredRoles} role={role} submitItem={submitItem} />
                    </div>
                ) : (
                    <NewItemFromTemplate />
                )}
            </div>
        );
    }

    return <NewItemFormInline formClassName="mt-4" requiredRoles={requiredRoles} role={role} submitItem={submitItem} />;

    function submitItem(sectionTitle) {
        return new Promise((resolve) => {
            let newSortOrder = 1;

            if (document.sections.length > 0) {
                const lastSection = document.sections.slice(-1)[0];
                newSortOrder = lastSection.sortOrder + 1;
            }

            const newSection = {
                displayInEditMode: true,
                exportProperties: {
                    showTitleInDocument: true,
                    showTitleInIndex: true,
                },
                sortOrder: newSortOrder,
                title: sectionTitle,
                documentVersionId: document.currentDocumentVersion.id,
            };

            if (document.activeRevisionId) {
                newSection.revisionId = document.activeRevisionId;
            }

            updateSections({
                documentId: document.id,
                body: [newSection],
            }).then(({ data }) => {
                const newSection = data[0];

                // Expand new section
                setSid(newSection.id);

                resolve(newSection);
            });
        });
    }
}

function NewItemFormInline(props) {
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [title, setTitle] = useState('');
    const [showForm, setShowForm] = useState(false);
    const hasPermission = HelperFunctions.checkRoleOrPermission(props.role, props.requiredRoles);
    const { t } = useTranslation('documents');

    const handleFormSubmit = (e) => {
        e.preventDefault();

        if (title === '') {
            return;
        }

        setSaveInProgress(true);

        props
            .submitItem(title)
            .then(() => {
                // save successful, clearing form
                setTitle('');
                setSaveInProgress(false);
                setShowForm(false);
            })
            .catch((error) => {
                // error while saving, returning form as it was
                setSaveInProgress(false);
            });
    };

    if (!hasPermission) {
        return null;
    }

    return (
        <div className="border-top mt-2 pt-4">
            {showForm ? (
                <form className="form-inline" onSubmit={handleFormSubmit}>
                    <Form.Control
                        placeholder={`${t('documents:document.navbar.main.titles.addChapterPlaceholder')}...`}
                        autoFocus={true}
                        onChange={(e) => setTitle(e.target.value)}
                        name="add-title-name"
                        id="add-title-name"
                        className="mr-2"
                    />
                    <PrimaryButton
                        className="mr-2"
                        onClick={handleFormSubmit}
                        disabled={saveInProgress || title === ''}
                    >
                        {t('global:btn.save')}
                    </PrimaryButton>
                    <SecondaryButton
                        onClick={() => {
                            setTitle('');
                            setShowForm(false);
                        }}
                    >
                        {t('global:btn.cancel')}
                    </SecondaryButton>
                    {saveInProgress && <Spinner/>}
                </form>
            ) : (
                <div className="pt-2">
                    <LightOutlineButton size="sm" onClick={() => setShowForm(!showForm)}>
                        <TbPlaylistAdd/>
                        {t('document.navbar.main.titles.addChapter')}
                    </LightOutlineButton>
                </div>
            )}
        </div>
    );
}
