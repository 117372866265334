import { AbilityBuilder, createMongoAbility } from '@casl/ability';
import { AccountRestrictions, Permissions } from 'config/Constants';
import _ from 'lodash';
import { STATUS_ACTIVE, STATUS_TRIALING } from 'pages/account/views/Billing';

const abilityBuilder = new AbilityBuilder(createMongoAbility);

export default function defineAbilityFor(user, organisation) {
    const { can, cannot, rules } = abilityBuilder;
    const { permissions = [] } = user;

    const activeSubscription = hasActiveSubscription(organisation);

    const accountRestrictions = _.get(organisation, 'account.restrictions', []);
    const allowOnlyFromDocumentTemplate = accountRestrictions.includes(
        AccountRestrictions.document.allowOnlyFromDocumentTemplate
    );

    /**
     * Documents
     */
    if (activeSubscription && permissions.includes(Permissions.Document.Create)) {
        can('create', 'Document');
    }

    /**
     * Users
     */
    if (activeSubscription && permissions.includes(Permissions.User['Write.All'])) {
        can('create', 'User');
    }

    if (isSeatsLimitReached(organisation)) {
        cannot('create', 'User');
    }

    /**
     * Publications
     */
    if (activeSubscription && permissions.includes(Permissions.Publication.Create)) {
        can('create', 'Publication');
    }

    if (activeSubscription && permissions.includes(Permissions.Publication['Write.All'])) {
        can('edit', 'Publication');
    }

    /**
     * Ipid import
     */
    if (permissions.includes(Permissions.Templates.Create) && allowOnlyFromDocumentTemplate === false) {
        can('create', 'IpidImport');
    }

    /**
     * Task team
     */
    if (permissions.includes(Permissions.TaskTeam.Manage.Settings)) {
        can('manage', 'TaskTeam.Settings');
    }

    if (permissions.includes(Permissions.TaskTeam.Manage.Teams)) {
        can('manage', 'TaskTeam.Teams');
        can('manage', 'TeamUser');
    }

    can('manage', 'TeamUser', { 'permissions.canManageTeam': true });

    return createMongoAbility(rules, {
        detectSubjectType: (object) => object['@type'],
    });
}

export function updateAbilityForDocuments(ability, numberOfDocuments, user, organisation) {
    const { can, cannot, rules } = abilityBuilder;
    const { permissions = [] } = user;

    const limitReached = isDocumentLimitReached(numberOfDocuments, organisation);
    const activeSubscription = hasActiveSubscription(organisation);

    if (activeSubscription && limitReached === false && permissions.includes(Permissions.Document.Create)) {
        can('create', 'Document');
    } else {
        cannot('create', 'Document');
    }

    ability.update(rules);
}

export function updateAbilityForPublications(ability, numberOfPublications, user, organisation) {
    const { can, cannot, rules } = abilityBuilder;
    const { permissions = [] } = user;

    const limitReached = isPublicationLimitReached(numberOfPublications, organisation);
    const activeSubscription = hasActiveSubscription(organisation);

    if (activeSubscription && limitReached === false && permissions.includes(Permissions.Publication.Create)) {
        can('create', 'Publication');
    } else {
        cannot('create', 'Publication');
    }

    ability.update(rules);
}

export function isDocumentLimitReached(numberOfDocuments, organisation) {
    const maxDocuments = _.get(organisation, 'account.maxDocuments', -1);

    return maxDocuments > 0 && numberOfDocuments >= maxDocuments;
}

export function isPublicationLimitReached(numberOfPublications, organisation) {
    const maxPublications = _.get(organisation, 'account.maxPublications', -1);

    return maxPublications > 0 && numberOfPublications >= maxPublications;
}

export function isSeatsLimitReached(organisation) {
    return _.get(organisation, 'account.canAddNewUser', true) === false;
}

export function hasActiveSubscription(organisation) {
    const status = _.get(organisation, 'account.subscriptionStatus', STATUS_ACTIVE);

    return [STATUS_ACTIVE, STATUS_TRIALING].includes(status);
}
