import { Button, Form, Modal } from 'react-bootstrap';
import { Field, FieldArray, Form as FForm, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { FormModal, ModalFooter } from '../../global/FormModal';
import Select from 'react-select';
import _ from 'lodash';
import Constants from '../../../config/Constants';
import { PlusCircleFill, TrashFill } from 'react-bootstrap-icons';
import { addField, patchField } from '../../../features/operationsList/taskSlice';
import ConditionalFields from './ConditionalFiels';
import { useTranslation } from 'react-i18next';

export default function EditFieldModal({ showModal = false, task, field, handleClose }) {
    const editMode = _.has(field, 'id');
    const isPrimaryQuestion = _.filter(task.fields, (field) => !field.deletedAt).length <= 0;
    const dispatch = useDispatch();
    const { t } = useTranslation('changelist');

    const handleSubmit = (values, { setSubmitting }) => {
        const formData = {
            ...values,
            type: values.type.value,
        };

        // Clear options if type is not select. Options are only meant as select options (for now at least)
        if ('select' !== formData.type) {
            formData.options = [];
        }

        return Promise.resolve()
            .then(() => {
                // Patch
                if (editMode) {
                    return dispatch(patchField({ uri: field['@id'], formData }));
                }

                // Create
                return dispatch(addField(formData));
            })
            .then((data) => {
                setSubmitting(false);
                handleClose(data);
            });
    };

    const typeOptions = _.map(Constants.opTaskFieldTypes, (label, type) => {
        return {
            value: type,
            label: label,
        };
    });

    return (
        <FormModal
            show={showModal}
            onHide={() => handleClose()}
            title={editMode ? t('changelist.task.editModal.editField') : t('changelist.task.editModal.createField')}
        >
            {isPrimaryQuestion && (
                <h4
                    className="uk-background-primary pb-2 pt-2 op-list-new-field-primary-banner"
                    uk-tooltip={t('changelist.task.editModal.tooltip.primary')}
                >
                    {t('changelist.task.editModal.editingPrimary')}
                </h4>
            )}
            <Formik
                initialValues={{
                    isPrimaryQuestion,
                    task: task['@id'],
                    label: _.get(field, 'label', ''),
                    type: editMode
                        ? {
                              value: field.type,
                              label:
                                  Constants.opTaskFieldTypes[field.type] ?? t('changelist.task.editModal.unknownType'),
                          }
                        : isPrimaryQuestion
                        ? {
                              value: 'select',
                              label: Constants.opTaskFieldTypes.select,
                          }
                        : '',
                    options: _.get(field, 'options', []),
                    // If this is a primary question is should be true
                    required: _.get(field, 'required', isPrimaryQuestion),
                    requiredConditions: _.get(field, 'requiredConditions', []),
                }}
                validationSchema={EditFieldSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, touched, errors, setFieldValue, isValid, values, dirty }) => (
                    <FForm autoComplete="off">
                        <Modal.Body>
                            <Form.Group>
                                <Form.Label htmlFor="label">{t('changelist.task.editModal.question')}</Form.Label>
                                <Field
                                    id="label"
                                    name="label"
                                    as={Form.Control}
                                    isInvalid={touched['label'] && errors['label']}
                                    isValid={touched['label'] && !errors['label']}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label htmlFor="type">{t('changelist.task.editModal.type')}</Form.Label>
                                <Field
                                    id="type"
                                    name="type"
                                    as={Select}
                                    isDisabled={isPrimaryQuestion}
                                    options={typeOptions}
                                    onChange={(selectedType) => setFieldValue('type', selectedType)}
                                />
                            </Form.Group>
                            {values.type.value === 'select' && (
                                <Form.Group>
                                    <Form.Label htmlFor="options">
                                        {t('changelist.task.editModal.selectOptions')}
                                    </Form.Label>
                                    <FieldArray id="options" name="options">
                                        {({ insert, remove, push }) => (
                                            <div>
                                                {values.options.length > 0 &&
                                                    values.options.map((meta, index) => (
                                                        <Form.Group key={index}>
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <Field
                                                                    id={`options[${index}]`}
                                                                    name={`options[${index}]`}
                                                                    as={Form.Control}
                                                                    isInvalid={
                                                                        _.get(touched, ['options', index]) &&
                                                                        _.get(errors, ['options', index], false)
                                                                    }
                                                                    isValid={
                                                                        _.get(touched, ['options', index]) &&
                                                                        !_.get(errors, ['options', index], false)
                                                                    }
                                                                    placeholder={t('changelist.task.editModal.value')}
                                                                />
                                                                <Button
                                                                    variant="link"
                                                                    size="sm"
                                                                    className="text-muted px-1"
                                                                    onClick={() => remove(index)}
                                                                >
                                                                    <TrashFill size={14} />
                                                                </Button>
                                                            </div>
                                                        </Form.Group>
                                                    ))}
                                                <div className="pt-3">
                                                    <Button
                                                        variant="link"
                                                        className="px-1"
                                                        size="sm"
                                                        onClick={() => push('')}
                                                    >
                                                        <PlusCircleFill size={20} />
                                                    </Button>
                                                    <span className="text-muted small pl-1 pt-1">
                                                        {t('changelist.task.editModal.addOption')}
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </FieldArray>
                                </Form.Group>
                            )}

                            {/* Primary question is always required */}
                            {!isPrimaryQuestion && (
                                <Form.Group>
                                    <Form.Label htmlFor="required">
                                        {t('changelist.task.editModal.required')}
                                    </Form.Label>
                                    <Form.Check
                                        type="switch"
                                        id="required"
                                        name="required"
                                        checked={values.required}
                                        onChange={() => setFieldValue('required', !values.required)}
                                    />
                                </Form.Group>
                            )}

                            {!isPrimaryQuestion &&
                                values.required &&
                                (values.type.value === 'text' || values.type.value === 'textarea') && (
                                    <ConditionalFields
                                        fields={task.fields.filter((_field) => _field.type === 'select')}
                                    />
                                )}
                        </Modal.Body>

                        <ModalFooter
                            isSubmitting={isSubmitting}
                            isValid={isValid}
                            dirty={dirty}
                            onHide={() => handleClose()}
                        />
                    </FForm>
                )}
            </Formik>
        </FormModal>
    );
}

const EditFieldSchema = Yup.object().shape({
    isPrimaryQuestion: Yup.bool(),
    task: Yup.string().required(),
    label: Yup.string().required(),
    type: Yup.object().required(),
    options: Yup.array()
        .required()
        .when('type', {
            is: (type) => type && type.value === 'select',
            then: Yup.array().of(Yup.string().required()).min(1).required(),
        }),
    required: Yup.bool().required(),
    requiredConditions: Yup.array().of(
        Yup.object().shape({
            field: Yup.string().required(),
            value: Yup.string().required(),
        })
    ),
});
