import { Dropdown, DropdownButton } from 'react-bootstrap';
import HelperFunctions from '../global/HelperFunctions';
import { useGetPublicationUsers } from '../publications_v2/hooks/publicationUsers';
import { useDeletePublicationUserMutation } from 'features/publications/publicationApi';
import { useTranslation } from 'react-i18next';
import { PaginatedTable, TableProvider, useSelectedItems, useSelectedItemsDispatch } from 'components/PaginatedTable';
import { UserRow } from 'pages/publicationUsers/ViewGroup';
import { SelectAllCheckbox } from 'components/SelectAllCheckbox';
import { LightOutlineButton, NewItemButton, PrimaryButton } from 'components/Buttons';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { ArrowDownShort, CloudDownload, PersonPlus } from 'react-bootstrap-icons';
import { saveAs } from 'file-saver';
import PublicationApi from 'api/PublicationApi';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';

export default function PublicationUsers() {
    return (
        <TableProvider>
            <RenderUsers />
        </TableProvider>
    );
}

function RenderUsers() {
    const { t } = useTranslation();
    const [, setAddUser] = useQueryParam('addUser', BooleanParam);
    const organisationId = useActiveOrganisation();

    return (
        <>
            <div className="dr-container p-4">
                <div className="subheader">
                    <h3>{t('publications:accessControl.users')}</h3>

                    <div className="d-flex align-items-center ml-auto">
                        <LightOutlineButton onClick={handleDownload}>
                            <CloudDownload />
                            <div>{t('global:btn.export')}</div>
                        </LightOutlineButton>
                        <NewItemButton
                            icon={<PersonPlus />}
                            label={t('publications:accessControl.newUserBtn')}
                            onClick={() => setAddUser(true)}
                        />
                    </div>
                </div>

                <UsersList />
            </div>
        </>
    );

    function handleDownload() {
        PublicationApi.exportPublicationUsers({ organisationId })
            .then((response) => {
                saveAs(response.data, response.headers['x-suggested-filename'] ?? 'export.xlsx');
            })
            .catch(async (error) => {
                if (error.response.status === 400) {
                    const response = await error.response.data.text();
                    const responseObject = HelperFunctions.tryParseJSON(response);
                    HelperFunctions.alertModal(t('global:error.errorTitle', { title: responseObject.error }));
                } else {
                    HelperFunctions.alertModal(t('global:error.exportFailed'));
                }
            });
    }
}

function UsersList() {
    const { t } = useTranslation();
    const publicationUsers = useGetPublicationUsers();
    const { selectedItems = [] } = useSelectedItems();

    return (
        <PaginatedTable
            items={publicationUsers}
            searchPlaceholder={t('publications:accessControl.searchUserPlaceholder')}
            searchProps={['fullName', 'email']}
            colSpan={6}
            actionsButton={<Actions />}
        >
            {({ items = [] }) => (
                <>
                    <thead>
                        <tr>
                            <th>
                                <SelectAllCheckbox items={items} />
                            </th>
                            <th>
                                {t('publications:accessControl.headers.name')} <ArrowDownShort />
                            </th>
                            <th>{t('publications:accessControl.headers.company')}</th>
                            <th>{t('publications:accessControl.headers.newsletter')}</th>
                            <th>{t('publications:accessControl.headers.updates')}</th>
                            <th>{t('global:btn.actions')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((user) => (
                            <UserRow
                                user={user}
                                isSelected={selectedItems.includes(user['@id'])}
                                key={`user-item-${user.id}`}
                            />
                        ))}
                    </tbody>
                </>
            )}
        </PaginatedTable>
    );
}

function Actions() {
    const { t } = useTranslation();
    const [deletePublicationUser] = useDeletePublicationUserMutation();
    const { selectedItems = [] } = useSelectedItems();
    const dispatch = useSelectedItemsDispatch();

    return (
        <div className="d-flex align-items-center">
            <DropdownButton
                id="actions-dropdown"
                title={t('publications:accessControl.actions')}
                variant="primary"
                disabled={selectedItems.length === 0}
                size="sm"
            >
                <Dropdown.Item onClick={deleteCheckedUsers}>
                    <span className="text-danger">{t('global:btn.delete')}&hellip;</span>
                </Dropdown.Item>
            </DropdownButton>

            <div className="text-muted small ml-2">
                {selectedItems.length > 0 && (
                    <>{t('publications:accessControl.numUsersSelected', { count: selectedItems.length })}</>
                )}
            </div>
        </div>
    );

    function deleteCheckedUsers() {
        HelperFunctions.confirmModal(
            t('publications:accessControl.confirmUserDeleteCount', { count: selectedItems.length }),
            'danger',
            false,
            t('global:confirm.yesDelete'),
            t('global:btn.cancel')
        )
            .then(() => {
                Promise.all(selectedItems.map((userId) => deletePublicationUser(userId))).then(() => {
                    dispatch({
                        type: 'select',
                        items: [],
                    });
                });
            })
            .catch(() => {});
    }
}
