import { Pencil } from 'react-bootstrap-icons';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteFile, patchFile, updateFile } from '../../../features/mediaLibrary/mediaLibrarySlice';
import { Button } from 'react-bootstrap';
import cx from 'classnames';
import HelperFunctions from '../../global/HelperFunctions';
import { FOLDER_TRASH } from './Folders';
import EditFileProperties from '../forms/EditFileProperties';
import { useTranslation } from 'react-i18next';

export default function Sidebar({ selectedFiles, setSelectedFiles }) {
    const { t } = useTranslation('global');
    const dispatch = useDispatch();
    const selectedFolderId = useSelector((state) => state.mediaLibrary.selectedFolder);
    const isTrashFolder = selectedFolderId === FOLDER_TRASH;

    const classes = cx('border-left', {
        'is-closed': selectedFiles.length === 0,
    });

    const confirmRemoveFile = () => {
        HelperFunctions.confirmModal(`${t('contentSidebar.map.menu.deleteSelected')}...`, 'danger', false).then(() => {
            selectedFiles.forEach((uri) => {
                dispatch(deleteFile({ uri }));
            });
            setSelectedFiles([]);
        });
    };

    const restoreSelectedFiles = () => {
        selectedFiles.forEach((uri) => {
            dispatch(
                updateFile({
                    id: uri,
                    changes: {
                        deletedAt: null,
                        folder: null,
                    },
                })
            );

            // Update API
            dispatch(
                patchFile({
                    uri: uri,
                    formData: {
                        deletedAt: null,
                        folder: null,
                    },
                })
            );

            setSelectedFiles([]);
        });
    };

    return (
        <div id="sidebar" className={classes}>
            <div className="p-4">
                <div className="d-flex align-items-start justify-content-between mb-4">
                    <h4>
                        <Pencil /> {t('contentSidebar.map.menu.edit')}
                    </h4>
                    <button type="button" className="close" onClick={() => setSelectedFiles([])}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                {isTrashFolder === false && selectedFiles.length === 1 && (
                    <div>
                        <EditFileProperties uri={selectedFiles[0]} />
                    </div>
                )}

                {selectedFiles.length > 1 && (
                    <div>
                        <p>{t('contentSidebar.map.menu.multiFiles')}</p>

                        {isTrashFolder === false && (
                            <Button variant="danger" onClick={() => confirmRemoveFile()}>
                                {t('contentSidebar.map.btn.delete')}
                            </Button>
                        )}
                    </div>
                )}

                {isTrashFolder && (
                    <Button variant="primary" onClick={() => restoreSelectedFiles()}>
                        {t('contentSidebar.map.btn.restore')}
                    </Button>
                )}
            </div>
        </div>
    );
}
