import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Form as FForm, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { FormModal, ModalFooter } from '../../global/FormModal';
import {
    batchDuplicatePublications,
    duplicatePublicationGroup,
    fetchPublicationGroups,
    fetchPublications,
    selectPublicationGroups,
    selectPublications,
    selectRootLevelPublicationGroups,
    setSelectedRows,
} from '../../../features/publications/publicationsSlice';
import { FieldSelect } from '../helpers/FieldHelper';
import { getSelectedGroupId } from '../views/Index';
import { generatePath, useHistory } from 'react-router-dom';
import { INDEX_PATH } from '../../../scenes/PublicationsV2';
import Constants from '../../../config/Constants';
import HelperFunctions from '../../global/HelperFunctions';
import { useGetDocuments } from '../../documents_v2/hooks/useGetDocuments';
import { useTranslation } from 'react-i18next';
import { publicationApi } from 'features/publications/publicationApi';

export default function DuplicateModal({ showModal, handleClose, payload }) {
    const dispatch = useDispatch();
    const { publications = [], folder = undefined } = payload;
    const groups = useSelector(selectPublicationGroups());
    const rootLevelGroups = useSelector(selectRootLevelPublicationGroups());
    const groupId = getSelectedGroupId(true);
    const history = useHistory();
    const { t } = useTranslation('publications');

    if (!showModal) {
        return null;
    }

    const isDuplicatingFolder = folder !== undefined;

    const groupOptions = rootLevelGroups
        .filter((_group) => _group.id !== null)
        .map((_rootGroup) => {
            return {
                label: _rootGroup.name,
                options: groups
                    .filter((_group) => _group.parent === _rootGroup['@id'])
                    .map((_group) => {
                        return {
                            label: _group.name,
                            value: _group['@id'],
                        };
                    }),
            };
        });

    const handleSubmit = (values) => {
        if (isDuplicatingFolder) {
            // Duplicate Folder
            dispatch(
                duplicatePublicationGroup({
                    id: folder.id,
                    formData: values,
                })
            ).then(({ payload }) => {
                const newGroup = Object.values(payload.publicationGroups)[0];

                // Reload data
                dispatch(fetchPublicationGroups());
                dispatch(fetchPublications());
                handleClose();

                // Navigate to new folder
                history.push(
                    generatePath(INDEX_PATH, {
                        folder: Constants.folders.folder,
                        folderId: newGroup.id,
                    })
                );
            });
        } else {
            // Duplicate Publications
            dispatch(
                batchDuplicatePublications({
                    formData: values,
                })
            ).then(() => {
                // Deselect everything
                dispatch(setSelectedRows([]));
                handleClose();

                // Reload data
                dispatch(fetchPublicationGroups());
                dispatch(fetchPublications());

                // Invalidate Tags
                dispatch(publicationApi.util.invalidateTags([{ type: 'Publication', id: 'LIST' }]));
            });
        }
    };

    return (
        <FormModal show={showModal} onHide={handleClose} title={t('publications.bulkActions.duplicateModal.title')}>
            <Formik
                initialValues={{
                    targetFolder: isDuplicatingFolder ? folder['@id'] : groupId === '0' ? null : groupId,
                    targetDocument: null,
                    publications,
                }}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, isValid }) => (
                    <FForm autoComplete="off">
                        <Modal.Body>
                            <p className="mb-4">
                                {folder === undefined ? (
                                    <>
                                        {t('publications.bulkActions.duplicateModal.now')} {publications.length}&nbsp;
                                        {publications.length > 1
                                            ? t('publications.bulkActions.duplicateModal.publications')
                                            : t('publications.bulkActions.duplicateModal.publicationSingle')}
                                        &nbsp;
                                        {t('publications.bulkActions.duplicateModal.duplicate')}
                                    </>
                                ) : (
                                    <>
                                        {folder.name}&nbsp;{t('publications.bulkActions.duplicateModal.duplicatesAll')}
                                    </>
                                )}
                            </p>

                            {folder === undefined && (
                                <Form.Group>
                                    <FieldSelect
                                        label={t('publications.bulkActions.duplicateModal.targe')}
                                        name="targetFolder"
                                        options={groupOptions}
                                        props={{
                                            placeholder: `${t(
                                                'publications.bulkActions.duplicateModal.titleSelect'
                                            )}...`,
                                            isClearable: true,
                                        }}
                                        help={t('publications.bulkActions.duplicateModal.leaveBlank')}
                                    />
                                </Form.Group>
                            )}

                            <TargetDocumentSelect publicationIds={publications} />
                        </Modal.Body>

                        <ModalFooter
                            isValid={isValid}
                            isSubmitting={isSubmitting}
                            onHide={handleClose}
                            btnSubmitText={t('publications.bulkActions.duplicateModal.btn.duplicate')}
                        />
                    </FForm>
                )}
            </Formik>
        </FormModal>
    );
}

function TargetDocumentSelect({ publicationIds = [] }) {
    const documents = useGetDocuments();
    const { t } = useTranslation('publications');
    const allPublications = useSelector(selectPublications()).filter((_publication) =>
        publicationIds.includes(_publication['@id'])
    );

    const onlyHaveOneDocument = allPublications.every((_publication) => {
        return _publication.documentIds.length === 1;
    });

    if (onlyHaveOneDocument === false) {
        return null;
    }

    const uniqueDocumentIds = allPublications
        .map((_publication) => _publication.documentIds[0])
        .filter((v, i, a) => a.indexOf(v) === i);

    if (uniqueDocumentIds.length !== 1) {
        return null;
    }

    const documentClones = documents.filter((_document) => _document.clonedFromId === uniqueDocumentIds[0]);

    if (documentClones.length === 0) {
        return null;
    }

    return (
        <Form.Group>
            <FieldSelect
                label={t('publications.bulkActions.duplicateModal.targetDocument')}
                name="targetDocument"
                options={HelperFunctions.prepareDropdownData(documentClones)}
                props={{
                    placeholder: `${t('publications.bulkActions.duplicateModal.titleSelectDocument')}...`,
                    isClearable: true,
                }}
                help={t('publications.bulkActions.duplicateModal.helpMessage')}
            />
        </Form.Group>
    );
}
