import { useMemo } from 'react';
import { DateTime } from 'luxon';
import { FileFont, FileTextFill, Globe } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import HelperFunctions from '../global/HelperFunctions';

export function TagList({ selectedItem = [], tags = [], handleTagSelect }) {
    const { t } = useTranslation('documents');

    const sortedTags = useMemo(() => {
        return [...tags].sort(HelperFunctions.sortByString('name'));
    }, [tags]);

    const handleSelect = (row) => {
        handleTagSelect(row, !selectedItem.includes(row.id));
    };

    return (
        <div className="react-bootstrap-table">
            <table className="table table-hover mb-3">
                <thead>
                    <tr>
                        <th>{t('document.navbar.tags.columns.tagName')}</th>
                        <th>{t('document.navbar.tags.columns.tagValue')}</th>
                        <th>{t('document.navbar.tags.columns.tagLink')}</th>
                        <th>{t('document.navbar.tags.columns.tagCreated')}</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedTags.map((tag) => (
                        <TagRow tag={tag} handleSelect={() => handleSelect(tag)} key={`tag-row-${tag.id}`} />
                    ))}

                    {sortedTags.length === 0 && (
                        <tr>
                            <td colSpan="4" className="text-center">
                                {t('document.navbar.tags.columns.noTags')}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

function TagRow({ tag, handleSelect }) {
    return (
        <tr onClick={handleSelect}>
            <td>
                <TagName tag={tag} />
            </td>
            <td>
                <div className="font-weight-light">
                    <span>{tag.tagValue?.text}</span>
                </div>
            </td>
            <td>
                <span className="font-weight-light">
                    <span>{tag.tagValue?.url}</span>
                </span>
            </td>
            <td>
                <div className="small">
                    {tag.createdAt > 0 && (
                        <span className="text-muted">
                            {DateTime.fromSeconds(tag.createdAt).toLocaleString(DateTime.DATE_MED)}
                        </span>
                    )}
                </div>
            </td>
        </tr>
    );
}

function TagName({ tag }) {
    return (
        <div>
            <div className="d-flex align-items-center">
                {tag.documentId && tag.isOverride === false ? (
                    tag.parentId ? (
                        <FileFont className="mr-2 flex-shrink-0" />
                    ) : (
                        <FileTextFill className="mr-2 flex-shrink-0" />
                    )
                ) : (
                    <Globe className="mr-2 flex-shrink-0" />
                )}
                <div className="font-weight-bold">[{tag.name}]</div>
            </div>
            {tag.description && (
                <div className="small font-weight-light pt-1" style={{ marginLeft: 24 }}>
                    {tag.description}
                </div>
            )}
        </div>
    );
}
