import React, { useEffect, useState } from 'react';
import { Col, Form, Modal } from 'react-bootstrap';
import { Field, Form as FForm, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { FormModal, ModalFooter } from '../../global/FormModal';
import {
    addDepartment,
    fetchEligibleDepartmentUsers,
    patchDepartment,
    selectAllEligibleDepartmentUsers,
} from '../../../features/operationsList/operationsListSlice';
import Select from 'react-select';
import { formatAsOptions, formatAsOptionsMapFn, formatLabelAsOption } from '../../global/LabelHelper';
import LoadingSpinner from '../../global/LoadingSpinner';
import _ from 'lodash';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import { useGetLabelFoldersQuery, useGetLabelsQuery } from '../../../features/metadata/metadata';
import { useTranslation } from 'react-i18next';

export default function EditDepartmentModal(props) {
    const { showModal = false, department, opList } = props;
    const dispatch = useDispatch();
    const { activeOrganisation } = useSelector((state) => state.security);
    const { t } = useTranslation('changelist');

    // TODO this is rather slow
    const eligibleUsers = useSelector((state) => selectAllEligibleDepartmentUsers(state.operations));
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (loading) {
            dispatch(
                fetchEligibleDepartmentUsers({
                    opListId: opList.id,
                    departmentId: _.get(department, 'id'),
                    allowManagers: true,
                })
            ).then(() => setLoading(false));
        }
    }, [dispatch, opList, department, loading, setLoading]);

    // Folders
    const {
        folders = [],
        folderOptions = [],
        commentFolders = [],
    } = useGetLabelFoldersQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                folders: data ?? [],
                folderOptions: data ? formatAsOptions(data) : [],
                commentFolders: data
                    ? data.filter((folder) => folder.contentTypes.includes('comments')).map((folder) => folder['@id'])
                    : [],
            }),
        }
    );

    // organisationLabels
    const {
        allLabels = [],
        commentLabels = [],
        commentLabelOptions = [],
    } = useGetLabelsQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                allLabels: data ?? [],
                commentLabels: data ? data.filter((label) => commentFolders.includes(label.folder)) : [],
                commentLabelOptions: data
                    ? formatAsOptions(data.filter((label) => commentFolders.includes(label.folder)))
                    : [],
            }),
        }
    );

    if (loading) {
        return <LoadingSpinner />;
    }

    const handleSubmit = (values, { setSubmitting }) => {
        const formData = {
            ...values,
            labels: values.labels.map((_item) => _item.value),
            generalLabel: _.get(values, 'generalLabel.value', null),
            commentLabels: values.commentLabels.map((_item) => _item.value),
            managers: values.managers.map((_category) => _category.value),
        };

        if (department.hasOwnProperty('id')) {
            // Patch
            dispatch(patchDepartment({ uri: department['@id'], formData })).then(() => {
                setSubmitting(false);
                props.handleClose();
            });
        } else {
            // Start the department off with statusCodes new and finished.
            formData.statusCodes = [
                {
                    label: t('changelist.department.editDepartment.new'),
                    slug: 'nieuw',
                    color: '#ccc',
                    required: true,
                    sortOrder: 0,
                },
                {
                    label: t('changelist.department.editDepartment.completed'),
                    slug: 'afgerond',
                    color: '#7be262',
                    required: true,
                    sortOrder: 1,
                },
            ];

            // Create
            dispatch(addDepartment(formData)).then(() => {
                setSubmitting(false);
                props.handleClose();
            });
        }
    };

    const users = eligibleUsers.map((_user) => {
        return {
            value: _user.id,
            label: `${_user.firstName} ${_user.lastName}`,
        };
    });

    // Init the selected generalLabel option (if any)
    let generalLabelInitVal = null;
    if (department && department.generalLabel) {
        // In the formik render cycle we check if the selected generalLabel belongs to one of the selected folders.
        // If not it's unset, so we don't need to check that here.
        const generalLabelObject = _.find(allLabels, ['@id', department.generalLabel]);
        if (generalLabelObject) {
            generalLabelInitVal = formatLabelAsOption(generalLabelObject);
        }
    }

    return (
        <FormModal
            show={showModal}
            onHide={props.handleClose}
            title={t('changelist.department.editDepartment.editDepartment')}
            size="lg"
        >
            <Formik
                initialValues={{
                    name: department?.name ?? '',
                    code: department?.code ?? '',
                    opList: opList['@id'],
                    organisationId: activeOrganisation,
                    labels: department.hasOwnProperty('labels')
                        ? department.labels
                              .map((_uri) => folders.find((_label) => _label['@id'] === _uri))
                              .map((_item) => {
                                  if (!_item) {
                                      return false;
                                  }

                                  return {
                                      value: _item['@id'],
                                      label: _item.name,
                                  };
                              })
                        : [],
                    generalLabel: generalLabelInitVal,
                    commentLabels: department.hasOwnProperty('commentLabels')
                        ? department.commentLabels
                              .map((_uri) => commentLabels.find((_label) => _label['@id'] === _uri))
                              .map((_item) => {
                                  if (!_item) {
                                      return false;
                                  }

                                  return {
                                      value: _item['@id'],
                                      label: _item.name,
                                  };
                              })
                        : [],
                    splitPerLabel: department?.splitPerLabel ?? true,
                    managers: department.hasOwnProperty('managers')
                        ? department.managers.map((_userId) => {
                              const user = eligibleUsers.find((_user) => _user.id === _userId);

                              if (!user) {
                                  return false;
                              }

                              return {
                                  value: user.id,
                                  label: `${user.firstName} ${user.lastName}`,
                              };
                          })
                        : [],
                }}
                validationSchema={EditDepartmentSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, touched, errors, setFieldValue, isValid, dirty, values }) => {
                    // The generalLabel can only be picked from the labels that belong to the selected folders
                    const selectedFolders = _.map(values.labels, 'value');
                    const generalLabelOptions = _.filter(allLabels, (label) => {
                        return _.includes(selectedFolders, label.folder);
                    }).map(formatAsOptionsMapFn());

                    // If the selected generalLabel belongs to a folder that is no longer selected, unset the selected generalLabel
                    if (_.has(values, 'generalLabel.value')) {
                        const generalLabelOptionValues = _.map(generalLabelOptions, 'value');
                        if (!_.includes(generalLabelOptionValues, values.generalLabel.value)) {
                            setFieldValue('generalLabel', null);
                        }
                    }

                    // Bootstrap's single select has no way to clear the value after one's been selected. So add this dummy value instead.
                    if (generalLabelOptions.length > 0) {
                        generalLabelOptions.unshift({
                            value: null,
                            label: t('changelist.department.editDepartment.noValue'),
                        });
                    }

                    return (
                        <FForm autoComplete="off">
                            <Modal.Body>
                                <Form.Row className="mb-3">
                                    <Col md={3}>
                                        <Form.Label htmlFor="code">
                                            {t('changelist.department.editDepartment.code')}*
                                        </Form.Label>
                                        <Field
                                            id="code"
                                            name="code"
                                            as={Form.Control}
                                            isInvalid={touched['code'] && errors['code']}
                                            isValid={touched['code'] && !errors['code']}
                                            maxLength={2}
                                        />
                                    </Col>
                                </Form.Row>
                                <Form.Group>
                                    <Form.Label htmlFor="name">
                                        {t('changelist.department.editDepartment.name')}*
                                    </Form.Label>
                                    <Field
                                        id="name"
                                        name="name"
                                        as={Form.Control}
                                        isInvalid={touched['name'] && errors['name']}
                                        isValid={touched['name'] && !errors['name']}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label htmlFor="managers">
                                        {t('changelist.department.editDepartment.admins')}
                                    </Form.Label>
                                    <Field
                                        isMulti
                                        id="managers"
                                        name="managers"
                                        as={Select}
                                        options={users}
                                        onChange={(selectedOptions) => {
                                            setFieldValue('managers', selectedOptions ?? []);
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label htmlFor="splitPerLabel">
                                        {t('changelist.department.editDepartment.splitChange')} &nbsp;
                                        <QuestionCircleFill
                                            uk-tooltip={t('changelist.department.editDepartment.tooltip.optionOn')}
                                            size={20}
                                        />
                                    </Form.Label>
                                    <Field
                                        as={Form.Check}
                                        type="checkbox"
                                        name="splitPerLabel"
                                        id="splitPerLabel"
                                        checked={values.splitPerLabel}
                                        onChange={() => {
                                            const newVal = !values.splitPerLabel;
                                            setFieldValue('splitPerLabel', newVal);
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label htmlFor="labels">
                                        {t('changelist.department.editDepartment.insuranceType')}
                                    </Form.Label>
                                    <Field
                                        isMulti
                                        id="labels"
                                        name="labels"
                                        as={Select}
                                        options={folderOptions}
                                        onChange={(selectedOptions) => {
                                            setFieldValue('labels', selectedOptions ?? []);
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label htmlFor="generalLabel">
                                        {t('changelist.department.editDepartment.genaralType')} &nbsp;
                                        <QuestionCircleFill
                                            uk-tooltip={t('changelist.department.editDepartment.tooltip.whenItem')}
                                            size={20}
                                        />
                                    </Form.Label>
                                    <Field
                                        isDisabled={generalLabelOptions.length < 1}
                                        id="generalLabel"
                                        name="generalLabel"
                                        as={Select}
                                        options={generalLabelOptions}
                                        onChange={(selectedOption) => {
                                            if (!_.get(selectedOption, 'value')) {
                                                setFieldValue('generalLabel', null);
                                            }

                                            setFieldValue('generalLabel', selectedOption);
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label htmlFor="commentLabels">
                                        {t('changelist.department.editDepartment.commentsLabels')}
                                    </Form.Label>
                                    <Field
                                        isMulti
                                        id="commentLabels"
                                        name="commentLabels"
                                        as={Select}
                                        options={commentLabelOptions}
                                        onChange={(selectedOptions) => {
                                            setFieldValue('commentLabels', selectedOptions ?? []);
                                        }}
                                    />
                                    <Form.Text id="autoAssignLabels" muted>
                                        {t('changelist.department.editDepartment.restricted')}
                                    </Form.Text>
                                </Form.Group>
                            </Modal.Body>

                            <ModalFooter
                                isValid={isValid}
                                dirty={dirty}
                                isSubmitting={isSubmitting}
                                onHide={props.handleClose}
                            />
                        </FForm>
                    );
                }}
            </Formik>
        </FormModal>
    );
}

const EditDepartmentSchema = Yup.object().shape({
    name: Yup.string().required(),
    code: Yup.string().required().min(2).max(2),
    opList: Yup.string().required(),
    organisationId: Yup.number().required(),
});
