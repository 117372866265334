import { useGetTaskAttachmentsQuery } from 'features/comments/commentApi';

const emptyArray = [];

export function useGetTaskAttachments(uri) {
    const { attachments } = useGetTaskAttachmentsQuery(uri, {
        selectFromResult: (result) => {
            return {
                attachments: result?.data ?? emptyArray,
            };
        },
        skip: uri === undefined,
    });

    return attachments;
}
