import { useEffect } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Form as FForm, Formik } from 'formik';
import * as Yup from 'yup';
import { FormModal, ModalFooter } from '../../global/FormModal';
import { FieldSelect } from '../helpers/FieldHelper';
import HelperFunctions from '../../global/HelperFunctions';
import { generateRandomId } from '../helpers/FormHelper';
import Constants from '../../../config/Constants';
import _ from 'lodash';
import { useGetDocuments } from '../../documents_v2/hooks/useGetDocuments';
import { useTranslation } from 'react-i18next';
import { useGetDocument } from '../../documents_v2/hooks/useGetDocument';
import { useGetBaseVariant } from '../../documents_v2/hooks/useGetBaseVariant';

export default function AddContentSectionModal({ parentValues, handleClose, setFieldValue, push, payload = {} }) {
    const documents = useGetDocuments();
    const { contentItem = {}, index } = payload;
    const { t } = useTranslation('publications');

    const handleSubmit = (values) => {
        setFieldValue('documentIdLastEdit', values.documentId);

        if (contentItem.hasOwnProperty('id')) {
            // Update
            const newContentDecoded = _.clone(parentValues.contentDecoded);

            newContentDecoded[index] = {
                ...newContentDecoded[index],
                ...values,
            };

            setFieldValue('contentDecoded', newContentDecoded);
        } else {
            // Insert
            push({
                id: generateRandomId(values.contentDecoded),
                sectionId: values.sectionId,
                documentId: values.documentId,
                title: '',
                prefix: '',
                properties: {
                    includeInIndex: true,
                    showTitleInDocument: true,
                    capitalizeTags: true,
                    showInChapters: true,
                    sequentialNumbering: false,
                },
                style: 'default',
                type: 'default',
                contentType: Constants.publication.contentTypes.section,
                variantId: values.variantId,
                sectionLayout: Constants.publication.defaultSectionLayout,
            });
        }

        handleClose();
    };

    return (
        <FormModal show onHide={handleClose} title={t('publication.settings.content.summary.addChapterModal.title')}>
            <Formik
                initialValues={{
                    documentId: contentItem.hasOwnProperty('id')
                        ? _.get(contentItem, 'documentId', undefined)
                        : parentValues.documentIdLastEdit,
                    sectionId: _.get(contentItem, 'sectionId', undefined),
                    variantId: _.get(contentItem, 'variantId', undefined),
                }}
                validationSchema={AddContentSectionSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, dirty, values, setFieldValue, isValid, initialValues }) => (
                    <FForm autoComplete="off">
                        <Modal.Body>
                            <Form.Group>
                                <FieldSelect
                                    label="Document"
                                    name="documentId"
                                    options={HelperFunctions.prepareDropdownData(documents)}
                                    props={{
                                        placeholder: `${t(
                                            'publication.settings.content.summary.addChapterModal.titleSelect'
                                        )}...`,
                                    }}
                                />
                            </Form.Group>

                            <SectionSelect
                                values={values}
                                setFieldValue={setFieldValue}
                                initialValues={initialValues}
                            />
                        </Modal.Body>

                        <ModalFooter
                            isValid={isValid}
                            dirty={dirty}
                            isSubmitting={isSubmitting}
                            onHide={handleClose}
                            btnSubmitText={
                                contentItem.hasOwnProperty('id')
                                    ? t('btn.save')
                                    : t('publication.settings.content.summary.addChapterModal.btn.add')
                            }
                        />
                    </FForm>
                )}
            </Formik>
        </FormModal>
    );
}

function SectionSelect({ values, setFieldValue, initialValues }) {
    const { documentId, sectionId } = values;
    const { t } = useTranslation('publications');

    const document = useGetDocument(documentId, false);
    const baseVariant = useGetBaseVariant(documentId);

    const isLoading = document === undefined || document.hasOwnProperty('sections') === false;

    useEffect(() => {
        if (_.isInteger(documentId)) {
            if (
                initialValues.documentId !== documentId ||
                (initialValues.sectionId !== undefined && sectionId !== initialValues.sectionId)
            ) {
                setFieldValue('sectionId', undefined);
                setFieldValue('variantId', undefined);
            }
        }
    }, [documentId]);

    useEffect(() => {
        if (sectionId !== undefined) {
            setFieldValue('variantId', baseVariant.id);
        }
    }, [sectionId]);

    return (
        <Form.Group>
            <FieldSelect
                label={t('publication.settings.content.summary.addChapterModal.chapter')}
                name="sectionId"
                options={HelperFunctions.prepareDropdownData(document?.sections ?? [], 'title')}
                props={{
                    placeholder:
                        isLoading && _.isInteger(documentId)
                            ? `${t('publication.settings.content.summary.addChapterModal.busyLoading')}...`
                            : t('publication.settings.content.summary.addChapterModal.titleSelect'),
                    isDisabled: isLoading,
                }}
            />
        </Form.Group>
    );
}

const AddContentSectionSchema = Yup.object().shape({
    documentId: Yup.number().required(),
    sectionId: Yup.number().required(),
    variantId: Yup.number().required(),
});
