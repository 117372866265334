import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import 'draft-js/dist/Draft.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Dropzone } from 'pages/task_team/helpers/Dropzone';
import { TaskFileAttachments } from 'pages/task_team/views/checks/TaskFileAttachments';
import { useGetTaskAttachments } from 'pages/task_team/hooks/useGetTaskAttachments';
import { fileType } from 'pages/task_team/config/constants';
import { WysiwygEditor } from 'pages/task_team/helpers/FormHelper';
import _ from 'lodash';

export function Step4Files({ active = false }) {
    if (!active) {
        return null;
    }

    return <RenderStepFiles />;
}

function RenderStepFiles() {
    const { values, setFieldValue } = useFormikContext();
    const attachments = useGetTaskAttachments(values['@id']);
    const files = attachments.filter((attachment) => attachment.type === fileType.TYPE_ATTACHMENT);

    const { fileCount } = values;

    useEffect(() => {
        if (files.length !== fileCount) {
            setFieldValue('fileCount', files.length);
        }
    }, [files.length, fileCount]);

    return <TaskAttachments task={values} files={files} content={values.content} />;
}

export function TaskAttachments({ task, files = [], taskVersionUri = null, content }) {
    const filesAreReadOnly = _.isEmpty(content) === false;
    const contentIsReadOnly = files.length > 0;

    return (
        <div>
            <FileAttachments task={task} taskVersionUri={taskVersionUri} isDisabled={filesAreReadOnly} />

            <div className="mb-4">
                <TaskFileAttachments files={files} canDelete={true} />
            </div>

            <div className="mb-3" style={{ opacity: contentIsReadOnly ? 0.5 : undefined }}>
                <div className="font-weight-bold mb-2">Of kopieer en plak tekst naar onderstaand vak</div>
                <WysiwygEditor name="content" readOnly={contentIsReadOnly} />
            </div>
        </div>
    );
}

function FileAttachments({ task, taskVersionUri = null, isDisabled }) {
    return (
        <div className="mb-3">
            <div className="font-weight-bold mb-2" style={{ opacity: isDisabled ? 0.5 : undefined }}>
                Bestand(en)
            </div>
            <Dropzone task={task} taskVersionUri={taskVersionUri} isDisabled={isDisabled} />
        </div>
    );
}
