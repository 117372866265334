import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import HelperFunctions from 'pages/global/HelperFunctions';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { useGetTaskTypesQuery } from 'features/comments/commentApi';

/**
 * @returns {TaskType[]}
 */
export function useGetTaskTypes() {
    const organisationId = useActiveOrganisation();

    const selectTaskTypes = useMemo(() => {
        const emptyArray = [];

        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (res) => res.data,
            (res, organisationId) => organisationId,
            (data, organisationId) =>
                data
                    ? data
                          .filter((taskType) => taskType.organisationId === organisationId)
                          .sort(HelperFunctions.dynamicSort('name'))
                    : emptyArray
        );
    }, []);

    const { taskTypes } = useGetTaskTypesQuery(undefined, {
        selectFromResult: (result) => ({
            taskTypes: selectTaskTypes(result, organisationId),
        }),
    });

    return taskTypes;
}
