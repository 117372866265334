import { useEffect } from 'react';
import getEnv, { getBaseName } from 'config/Env';

export function Favicon() {
    const metaTitle = getEnv('META_TITLE');
    const baseName = _.defaultTo(getBaseName(), '');

    useEffect(() => {
        const url = `${baseName}/images/favicon/${metaTitle.toLowerCase()}/`;
        const link = document.querySelector("link[rel~='icon']");

        if (link) {
            link.href = url + 'favicon.ico';
        }

        const link2 = document.querySelector("link[rel~='icon'][sizes~='32x32']");

        if (link2) {
            link2.href = url + 'favicon-32x32.png';
        }
    }, [metaTitle, baseName]);

    return null;
}
