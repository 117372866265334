import React, { createContext, useMemo, useState } from 'react';
import { useGetProductsQuery } from '../../../../features/reimbursements/reimbursements';
import LoadingSpinner from '../../../global/LoadingSpinner';
import { Product, ProductTeam, Reimbursement } from '../../../../models/reimbursement.models';
import { ChevronRight } from 'react-bootstrap-icons';
import ReimbursementProduct from './ReimbursementProduct';
import cx from 'classnames';
import { ProductEditsMemo } from './ProductEdits';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { VIEW_PATH } from '../../../../scenes/Reimbursements';
import { useAppSelector } from '../../../../store';
import { ProductSearchActions } from './ProductSearchActions';
import _ from 'lodash';
import { ReimbursementProductTeamModal } from '../modals/ReimbursementProductTeamModal';
import { CategoryFilters } from '../../config/params';
import { useTranslation } from 'react-i18next';
import { useGetProducts } from '../../hooks/useGetProducts';
import { useGetReimbursement } from '../../hooks/useGetReimbursement';

function ReimbursementProducts({
    reimbursementId,
    categoryId,
    categoryUri,
}: {
    reimbursementId: number;
    categoryId: number;
    categoryUri: string;
}) {
    const { products, isLoading, isUninitialized } = useGetProducts(categoryId, categoryUri);

    if (isLoading || isUninitialized) {
        return <LoadingSpinner />;
    }

    return (
        <div className="dr-container p-3">
            <div className="accordion">
                {products.map((product: Product) => (
                    <RenderedProduct
                        reimbursementId={reimbursementId}
                        product={product}
                        categoryId={categoryId}
                        key={`product-${product.id}`}
                    />
                ))}
            </div>
        </div>
    );
}

function RenderedProduct({
    reimbursementId,
    product,
    categoryId,
}: {
    reimbursementId: number;
    product: Product;
    categoryId: number;
}) {
    const params: { productId: string | undefined } = useParams();
    const expanded = params.productId === `${product.id}`;
    const history = useHistory();

    return (
        <div>
            <div>
                <div
                    className="cursor-pointer pt-3 pb-2"
                    onClick={() => {
                        handleClick();
                    }}
                >
                    <div className="d-flex align-items-center">
                        <ChevronRight
                            size={14}
                            className="mr-3"
                            style={{
                                minWidth: 14,
                                transform: expanded ? 'rotate(90deg)' : undefined,
                                transition: 'all .15s ease-out',
                            }}
                        />
                        <div className="text-secondary mr-3" style={{ minWidth: 40 }}>
                            {product.code}
                        </div>
                        <div className="font-weight-bold mr-3">{product.name}</div>

                        <ProductEditsMemo reimbursementId={reimbursementId} product={product} categoryId={categoryId} />
                    </div>
                </div>
                <ProductCollapseMemo
                    productId={product.id}
                    categoryId={categoryId}
                    reimbursementId={reimbursementId}
                    expanded={expanded}
                />
            </div>
        </div>
    );

    function handleClick() {
        history.push(
            generatePath(VIEW_PATH, {
                categoryId,
                reimbursementId,
                productId: expanded ? undefined : product.id,
            }),
        );
    }
}

function ProductCollapse({
    expanded,
    reimbursementId,
    productId,
    categoryId,
}: {
    expanded: boolean;
    reimbursementId: number;
    productId: number;
    categoryId: number;
}) {
    return (
        <div className={cx('collapse', { show: expanded })}>
            <div className="py-3 px-4">
                {expanded && (
                    <ChildProductsMemo
                        reimbursementId={reimbursementId}
                        productId={productId}
                        categoryId={categoryId}
                    />
                )}
            </div>
        </div>
    );
}

const ProductCollapseMemo = React.memo(ProductCollapse);

interface ProductContextInterface {
    selectedProducts: string[];
    toggleProduct?: (uri: string) => void;
    clearSelection?: () => void;
}

export const ProductContext = createContext<ProductContextInterface>({
    selectedProducts: [],
});

function ChildProducts({
    reimbursementId,
    productId,
    categoryId,
}: {
    reimbursementId: number;
    productId: number;
    categoryId: number;
}) {
    const [search, setSearch] = useState('');
    const [selectedProducts, setSelectedProducts] = useState([] as string[]);
    const [editProductTeamModal, setEditProductTeamModal] = useState<Product | undefined>(undefined);
    const filters: CategoryFilters = useAppSelector((state) => state.reimbursements.filters);
    const { t } = useTranslation('reimbursements');

    const toggleProduct = (uri: string) => {
        setSelectedProducts(_.xor(selectedProducts, [uri]));
    };

    const clearSelection = () => {
        setSelectedProducts([]);
    };

    const reimbursement: Reimbursement = useGetReimbursement(reimbursementId);
    const { disabledProducts = [], productEdits = [], productTeams = [] } = reimbursement ?? {};

    const { childProducts }: { product: Product; childProducts: Product[] } = useGetProductsQuery(categoryId, {
        selectFromResult: (result) => {
            const product: Product = result.data.find((product: Product) => product.id === productId);

            return {
                ...result,
                product,
                childProducts: product.children.filter((childProduct) => childProduct.enabled),
            };
        },
    });

    const filteredProducts = useMemo(() => {
        return childProducts
            .filter((product: Product) => {
                // "Hide inactive" filter
                if (filters.hide_inactive) {
                    return !disabledProducts.includes(product['@id']);
                }

                return true;
            })
            .filter((product: Product) => {
                // "Show open changes" filter
                if (!filters.show_open_changes || !filters.show_unchanged) {
                    const productEdit = productEdits.find((productEdit) => productEdit.productId === product.id);

                    // No edits, but filter hides products without edits
                    if (!filters.show_unchanged && !productEdit) {
                        return false;
                    }

                    if (!productEdit) {
                        return true;
                    }

                    if (!filters.show_open_changes) {
                        return productEdit.unprocessedEdits === 0;
                    } else {
                        return productEdit.unprocessedEdits > 0;
                    }
                }

                return true;
            })
            .filter((product: Product) => {
                // Search filter
                if (search === '') {
                    return true;
                }

                const searchValueClean = search.trim().toLowerCase();

                if (product.code.toLowerCase().includes(searchValueClean)) {
                    return true;
                }

                return product.name.toLowerCase().includes(searchValueClean);
            })
            .filter((product: Product) => {
                // Team filter
                if (filters.team === 'all') {
                    return true;
                }

                const productTeam =
                    reimbursement?.productTeams.find((_productTeam) => _productTeam.productId === product.id) ??
                    ({
                        productId,
                        teams: [],
                    } satisfies ProductTeam);

                if (filters.team === 'none') {
                    return productTeam.teams.length === 0;
                }

                return productTeam.teams.includes(parseInt(filters.team));
            });
    }, [search, filters, childProducts, productEdits]);

    return (
        <ProductContext.Provider value={{ selectedProducts, toggleProduct, clearSelection }}>
            <div>
                <ProductSearchActions
                    search={search}
                    setSearch={setSearch}
                    reimbursementId={reimbursementId}
                    categoryId={categoryId}
                />
                <div className="table-responsive">
                    <table className="table table-hover table-sm">
                        <thead>
                            <tr>
                                <th>&nbsp;</th>
                                <th>CODE</th>
                                <th>{t('reimbursement.columnTitles.name')}</th>
                                <th>{t('reimbursement.columnTitles.department')}</th>
                                <th>{t('reimbursement.columnTitles.reimbursement')}</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredProducts.map((product) => (
                                <ReimbursementProduct
                                    categoryId={categoryId}
                                    parentProductId={productId}
                                    productId={product.id}
                                    reimbursementId={reimbursementId}
                                    disabledProducts={disabledProducts}
                                    productTeams={productTeams}
                                    setEditProductTeamModal={() => setEditProductTeamModal(product)}
                                    key={`product-${product.id}`}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>

                {editProductTeamModal && reimbursement && (
                    <ReimbursementProductTeamModal
                        reimbursement={reimbursement}
                        product={editProductTeamModal}
                        handleClose={() => {
                            setEditProductTeamModal(undefined);
                        }}
                    />
                )}
            </div>
        </ProductContext.Provider>
    );
}

export const ReimbursementProductsMemo = React.memo(ReimbursementProducts);
const ChildProductsMemo = React.memo(ChildProducts);
