import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import React from 'react';
import cx from 'classnames';
import { PlusLg } from 'react-bootstrap-icons';

// Normal buttons

export function PrimaryButton({ children, ...props }) {
    return (
        <GenericButton variant="primary" {...props}>
            {children}
        </GenericButton>
    );
}

export function SecondaryButton({ children, ...props }) {
    return (
        <GenericButton variant="secondary" {...props}>
            {children}
        </GenericButton>
    );
}

export function SuccessButton({ children, ...props }) {
    return (
        <GenericButton variant="success" {...props}>
            {children}
        </GenericButton>
    );
}

export function InfoButton({ children, ...props }) {
    return (
        <GenericButton variant="info" {...props}>
            {children}
        </GenericButton>
    );
}

export function WarningButton({ children, ...props }) {
    return (
        <GenericButton variant="warning" {...props}>
            {children}
        </GenericButton>
    );
}

export function DangerButton({ children, ...props }) {
    return (
        <GenericButton variant="danger" {...props}>
            {children}
        </GenericButton>
    );
}

export function LinkButton({ children, ...props }) {
    return (
        <GenericButton variant="link" {...props}>
            {children}
        </GenericButton>
    );
}

export function GenericButton({ children, tooltip, ...props }) {
    return (
        <Button {...props} data-uk-tooltip={tooltip}>
            {children}
        </Button>
    );
}

export function NewItemButton({ icon = <PlusLg />, label, ...props }) {
    return (
        <InfoButton className="d-flex align-items-center" {...props}>
            {icon}
            <div>{label}</div>
        </InfoButton>
    );
}

// Button as Link

export function PrimaryLinkButton({ to = '', children, ...props }) {
    return (
        <PrimaryButton as={Link} to={to} {...props}>
            {children}
        </PrimaryButton>
    );
}

export function SuccessLinkButton({ children, ...props }) {
    return (
        <GenericButton variant="success" as={Link} {...props}>
            {children}
        </GenericButton>
    );
}

export function InfoLinkButton({ children, ...props }) {
    return (
        <GenericButton variant="info" as={Link} {...props}>
            {children}
        </GenericButton>
    );
}

export function ActionLinkButton({ children, ...props }) {
    return (
        <GenericButton variant="action" {...props}>
            {children}
        </GenericButton>
    );
}

// Icon buttons

export function IconButton({ icon, className, ...props }) {
    return (
        <GenericButton className={cx('text-body p-0', className)} variant="link" {...props}>
            {icon}
        </GenericButton>
    );
}

// Outline button

export function LightOutlineButton({ className, children, ...props }) {
    return (
        <GenericButton className={cx('d-flex align-items-center', className)} variant="outline-light" {...props}>
            {children}
        </GenericButton>
    );
}
