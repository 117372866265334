import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import HelperFunctions from 'pages/global/HelperFunctions';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { useGetBrandsQuery } from 'features/comments/commentApi';

/**
 * @returns {Brand[]}
 */
export function useGetBrands() {
    const organisationId = useActiveOrganisation();

    const selectBrands = useMemo(() => {
        const emptyArray = [];

        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (res) => res.data,
            (res, organisationId) => organisationId,
            (data, organisationId) =>
                data
                    ? data
                          .filter((brand) => brand.organisationId === organisationId)
                          .sort(HelperFunctions.dynamicSort('name'))
                    : emptyArray
        );
    }, []);

    const { brands } = useGetBrandsQuery(undefined, {
        selectFromResult: (result) => ({
            brands: selectBrands(result, organisationId),
        }),
    });

    return brands;
}
