import { Navbar } from 'react-bootstrap';
import { generatePath, Link, useParams, useRouteMatch } from 'react-router-dom';
import { EDIT_PATH, VIEW_PATH } from '../../../scenes/DocumentsV2';
import cx from 'classnames';
import Constants from '../../../config/Constants';
import RestrictedContent from '../../global/RestrictedContent';
import { useGetDocument } from '../hooks/useGetDocument';
import { useEntityTemplateParameters } from '../hooks/useEntityTemplateParameters';
import { entityTypes } from '../../translation/config/Constants';
import { useTranslation } from 'react-i18next';

export const availableSettings = [
    {
        id: 'variants',
        name: 'document.navbar.title.variants',
        allowedRoles: [Constants.userDocumentRole.documentManager, Constants.userDocumentRole.finalEditor],
        module: Constants.modules.documents,
    },
    {
        id: 'one-liners',
        name: 'One-liners',
        allowedRoles: [
            Constants.userDocumentRole.documentManager,
            Constants.userDocumentRole.finalEditor,
            Constants.userDocumentRole.editor,
            Constants.userDocumentRole.spectator,
        ],
        module: Constants.modules['one-liner'],
    },
    {
        id: 'tags',
        name: 'document.navbar.title.tags',
        allowedRoles: [
            Constants.userDocumentRole.documentManager,
            Constants.userDocumentRole.finalEditor,
            Constants.userDocumentRole.editor,
        ],
        module: Constants.modules.tags,
    },
    {
        id: 'packages',
        name: 'document.navbar.title.packages',
        allowedRoles: [Constants.userDocumentRole.documentManager, Constants.userDocumentRole.finalEditor],
        module: Constants.modules.packages,
    },
    {
        id: 'find_replace',
        name: 'document.navbar.title.search',
        allowedRoles: [
            Constants.userDocumentRole.documentManager,
            Constants.userDocumentRole.finalEditor,
            Constants.userDocumentRole.editor,
        ],
        module: Constants.modules.documents,
    },
    {
        id: 'models',
        name: 'document.navbar.title.template',
        allowedRoles: [Constants.userDocumentRole.documentManager, Constants.userDocumentRole.finalEditor],
        module: Constants.modules.documents,
    },
    {
        id: 'settings',
        name: 'document.navbar.title.settings',
        allowedRoles: [Constants.userDocumentRole.documentManager],
        module: Constants.modules.documents,
    },
];

export function SubNav() {
    const { documentId, view } = useParams();
    const isViewPath = useRouteMatch({ path: VIEW_PATH, exact: true });
    const document = useGetDocument();
    const { t } = useTranslation('documents');
    const restrictions = useEntityTemplateParameters(document, entityTypes.DOCUMENT, document);

    return (
        <Navbar fixed="top" className="subnav bg-light border-bottom px-3 py-0">
            <div className="nav w-100">
                <div className="nav-item">
                    <Link
                        to={generatePath(VIEW_PATH, {
                            documentId: documentId,
                        })}
                        className={cx('nav-link d-flex align-items-center', {
                            active: isViewPath,
                        })}
                    >
                        {t('document.navbar.main.title')}
                    </Link>
                </div>

                {document && (
                    <>
                        {availableSettings
                            .filter((_setting) => {
                                if (_setting.allowedRoles.length === 0) {
                                    return true;
                                }
                                if (_setting.id === 'models' && document.type !== Constants.documentType.model) {
                                    return false;
                                }
                                if (_setting.id === 'variants' && document.type === Constants.documentType.library) {
                                    return false;
                                }
                                if (_setting.id === 'variants' && document.type === Constants.documentType.model) {
                                    return false;
                                }
                                if (_setting.id === 'one-liners' && document.properties?.enableOneLiners !== true) {
                                    return false;
                                }
                                if (
                                    _setting.id === 'find_replace' &&
                                    restrictions &&
                                    !restrictions.canUserSeeFindAndReplace
                                ) {
                                    return false;
                                }
                                if (_setting.id === 'tags' && restrictions && !restrictions.canViewDocTagsTab) {
                                    return false;
                                }

                                return _setting.allowedRoles.includes(document.currentUserRole);
                            })
                            .map((_setting) => {
                                const Icon = _setting.icon ?? undefined;

                                return (
                                    <RestrictedContent module={_setting.module} key={`document-nav-${_setting.id}`}>
                                        <div className={_setting.className ?? 'nav-item'}>
                                            <Link
                                                to={generatePath(EDIT_PATH, {
                                                    documentId: documentId,
                                                    view: _setting.id,
                                                })}
                                                className={cx('nav-link d-flex align-items-center', {
                                                    active: view === _setting.id,
                                                })}
                                            >
                                                {Icon && <Icon className="mr-1" />}
                                                {t(_setting.name)}
                                            </Link>
                                        </div>
                                    </RestrictedContent>
                                );
                            })}
                    </>
                )}
            </div>
        </Navbar>
    );
}
