import { Button, Col, Form } from 'react-bootstrap';
import { useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import cx from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { setEditReimbursement, setFilter } from '../../../../features/reimbursements/reimbursementSlice';
import { Category, Reimbursement } from '../../../../models/reimbursement.models';
import Constants from '../../../../config/Constants';
import RestrictedReimbursementContent from '../../RestrictedReimbursementContent';
import { useTranslation } from 'react-i18next';

export default function CategoryFilters({ category }: { category?: Category }) {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('reimbursements');

    return (
        <div className="content-static-filters bg-bgLight px-4 py-3 has-subnav">
            <Form.Row style={{ minHeight: 42 }}>
                <Col sm={3} md={5} lg={4} xl={4}>
                    <FilterSearch placeholder={`${t('reimbursement.searchTitle')}...`} />
                </Col>

                <div className="col-auto d-flex align-items-center ml-auto col">
                    {category && (
                        <RestrictedReimbursementContent
                            categoryId={category.id}
                            roles={[Constants.reimbursementTeamRoles.manager, Constants.reimbursementTeamRoles.admin]}
                        >
                            <Button
                                className="mr-4"
                                variant="info"
                                onClick={() => {
                                    const newReimbursement: Partial<Reimbursement> = {
                                        category: category['@id'],
                                        reimbursementGroup: false,
                                    };
                                    dispatch(setEditReimbursement(newReimbursement));
                                }}
                            >
                                {t('reimbursement.btn.newReimbursement')}
                            </Button>
                        </RestrictedReimbursementContent>
                    )}
                </div>
            </Form.Row>
        </div>
    );
}

const FilterSearch = ({ placeholder = '' }: { placeholder: string }) => {
    const dispatch = useAppDispatch();
    const { search }: { search: string } = useAppSelector((state) => state.reimbursements.filters);
    const [searchQuery, setSearchQuery] = useState(search);

    const handleChange = (value: string) => {
        dispatch(setFilter({ key: 'search', value }));
    };

    const debouncedResults = useMemo(() => {
        return debounce(handleChange, 300);
    }, []);

    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    });

    useEffect(() => {
        if (searchQuery !== search) {
            debouncedResults(searchQuery);
        }
    }, [searchQuery]);

    return (
        <Form.Control
            type="search"
            value={searchQuery}
            className={cx('nav-form-search', {
                'has-content': searchQuery !== '',
            })}
            placeholder={placeholder}
            onChange={(event) => setSearchQuery(event.target.value)}
        />
    );
};
