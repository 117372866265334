import { useGetOneLinerCategoryQuery } from 'features/metadata/metadata';

export function useGetCategory(categoryId) {
    const { category } = useGetOneLinerCategoryQuery(categoryId, {
        selectFromResult: ({ data }) => ({
            category: data,
        }),
    });

    return category;
}
