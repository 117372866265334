import React from 'react';
import { CircleFill } from 'react-bootstrap-icons';
import { useOrganisationTags } from './CommentTags';
import { useGetLabelsQuery } from '../../../../features/metadata/metadata';
import { useSelector } from 'react-redux';
import HelperFunctions from '../../../../pages/global/HelperFunctions';
import TagHelper from '../../../../pages/global/TagHelper';

export function TagColor({ comments }) {
    const { activeOrganisation } = useSelector((state) => state.security);

    const organisationTags = useOrganisationTags();
    const { commentLabels = [], availableTags = [] } = useGetLabelsQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                commentLabels: data ?? [],
                availableTags: organisationTags,
            }),
        }
    );

    const uniqueColors = new Set();
    const colorComponents = comments.reduce((accumulator, comment) => {
        const commentTags = comment.tags;

        if (commentTags.length > 0) {
            commentTags.forEach((tagData) => {
                let tag;

                if (tagData.hasOwnProperty('uri')) {
                    tag = HelperFunctions.getByValue(commentLabels, '@id', tagData.uri);
                } else {
                    // Old style
                    tag = HelperFunctions.getByValue(availableTags, 'id', tagData.id);
                }

                if (tag !== undefined) {
                    const color = TagHelper.getTagColor(tag);

                    if (color !== 'default' && !uniqueColors.has(color)) {
                        uniqueColors.add(color);
                        accumulator.push(
                            <CircleFill key={color} style={{ marginRight: '2px' }} className={`tag-icon-${color}`} />
                        );
                    }
                }
            });
        }

        return accumulator;
    }, []);

    const colorComponentsWrapper = (
        <div className={`tag-color-wrapper ${colorComponents.length === 1 ? 'single-color' : ''}`}>
            {colorComponents}
        </div>
    );

    return <div>{colorComponentsWrapper}</div>;
}
