import { Field, Form as FForm, Formik } from 'formik';
import { LoadingSpinner } from '../../global/Spinner';
import { FormField, Switch } from '../../publications_v2/helpers/FieldHelper';
import * as Yup from 'yup';
import Constants from '../../../config/Constants';
import { InfoCircle } from 'react-bootstrap-icons';
import { generatePath, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { WarningButton } from 'components/Buttons';
import { useCreateUserMutation, useUpdateUserMutation } from 'features/security/authApi';
import { Alert, Button } from 'react-bootstrap';
import { SETTINGS_PATH } from 'scenes/Account';
import { useCurrentOrganisation } from 'hooks/useCurrentOrganisation';
import { useUserProfile } from 'hooks/useUserProfile';
import { useGetOrganisationModules } from 'hooks/useGetOrganisationModules';
import { isSeatsLimitReached } from 'ability/Ability';
import { useAbility } from 'ability/useAbility';

export function UserForm({ context, user = {} }) {
    const history = useHistory();
    const userProfile = useUserProfile();
    const organisation = useCurrentOrganisation();
    const modules = useGetOrganisationModules();

    const [updateUser] = useUpdateUserMutation();
    const [createUser] = useCreateUserMutation();

    const ability = useAbility();
    const canAddNewUser = ability.can('create', 'User');

    const isCurrentUser = context === Constants.userFormContext.myProfile;
    const isSuperAdmin = userProfile.roles.includes(Constants.userRoles.superAdmin);
    const isOrganisationAdmin = userProfile.roles.includes(Constants.userRoles.organisationAdmin);
    const seatsLimitReached = isSeatsLimitReached(organisation);

    const { t } = useTranslation('global');
    const { t: tUsers } = useTranslation('users');

    const availableUserRoles = getAvailableUserRoles();
    const availableUserRolesGrouped = Object.groupBy(availableUserRoles, ({ group }) => group);

    const {
        email = '',
        roles = [],
        firstName = '',
        lastName = '',
        department = '',
        jobDescription = '',
        enabled = true,
        ssoEnabled = false,
        twoFactorEnabled = true,
    } = user;

    const rolesConflict =
        (roles.includes(Constants.userRoles.superAdmin) && !isSuperAdmin) ||
        (roles.includes(Constants.userRoles.organisationAdmin) && !isOrganisationAdmin);

    const canEditRoles = rolesConflict === false;
    const isFormDisabled = context === Constants.userFormContext.create && !canAddNewUser;

    if (!organisation) {
        return null;
    }

    return (
        <div>
            <Formik
                initialValues={{
                    email,
                    firstName,
                    lastName,
                    department: department || '',
                    jobDescription: jobDescription || '',
                    enabled,
                    ssoEnabled,
                    twoFactorEnabled,
                    roles: roles.filter((_role) =>
                        availableUserRoles.some((_availableRole) => _availableRole.role === _role)
                    ),
                    plainPassword: '',
                    passwordConfirmation: '',
                    sendCredentialsToUser: true,
                }}
                onSubmit={handleSubmit}
                enableReinitialize
                validationSchema={userFormSchema}
            >
                {({ values, dirty, isSubmitting, isValid }) => (
                    <FForm autoComplete="off">
                        {context === Constants.userFormContext.create && seatsLimitReached && (
                            <Alert variant="danger">
                                No more seats left for the current subscription. Please upgrade your plan and add more
                                seats.
                            </Alert>
                        )}

                        <FormField name="firstName" label={`${t('editUser.data.name')}*`} />
                        <FormField name="lastName" label={`${t('editUser.data.surName')}*`} />

                        <FormField
                            name="email"
                            label={`${t('editUser.loginDetails.email')}*`}
                            props={{
                                disabled: context === Constants.userFormContext.myProfile,
                            }}
                        />

                        <FormField name="department" label={t('editUser.data.section')} />
                        <FormField name="jobDescription" label={t('editUser.data.function')} />

                        <div className="font-weight-bolder text-color pt-2 mb-2">
                            {t('editUser.loginDetails.title')}
                        </div>

                        {organisation.ssoEnabled && (
                            <Switch name="ssoEnabled" label="Single sign-on" wrapperClassName="mb-0" />
                        )}

                        <Switch
                            name="twoFactorEnabled"
                            label={t('editUser.loginDetails.twoStepSwitch')}
                            wrapperClassName="mb-0"
                            props={{
                                disabled: twoFactorEnabled === true,
                            }}
                        />

                        {isCurrentUser === false && (
                            <Switch
                                name="enabled"
                                label={t('editUser.loginDetails.activeSwitch')}
                                wrapperClassName="mb-0"
                                props={{
                                    disabled: enabled === false && canAddNewUser === false,
                                }}
                            />
                        )}

                        {isCurrentUser && !values.ssoEnabled && (
                            <>
                                <div className="text-primary small d-flex align-items-center mb-3 pt-3">
                                    <InfoCircle size={14} className="mr-1" />
                                    {t('editUser.loginDetails.info')}
                                </div>
                                <FormField
                                    name="plainPassword"
                                    label={t('editUser.loginDetails.password')}
                                    help={t('editUser.loginDetails.passwordHelp')}
                                    props={{
                                        type: 'password',
                                        autoComplete: 'new-password',
                                    }}
                                />
                                <FormField
                                    name="passwordConfirmation"
                                    label={t('editUser.loginDetails.passwordNew')}
                                    props={{
                                        type: 'password',
                                        autoComplete: 'new-password',
                                        disabled: values.plainPassword === '',
                                    }}
                                />
                            </>
                        )}

                        {context === Constants.userFormContext.create && (
                            <Switch
                                name="sendCredentialsToUser"
                                label={t('editUser.loginDetails.sendCredentialsToUser')}
                                wrapperClassName="mb-0"
                            />
                        )}

                        {!isCurrentUser && (
                            <>
                                <div className="font-weight-bolder text-color pt-3 mb-3">
                                    {t('editUser.loginDetails.role')}
                                </div>

                                {Object.keys(availableUserRolesGrouped).map((group) => {
                                    const roles = availableUserRolesGrouped[group].filter(
                                        (_role) =>
                                            _role.canAssignRole &&
                                            (_role.module === '' || modules.includes(_role.module))
                                    );

                                    if (roles.length === 0) {
                                        return null;
                                    }

                                    return (
                                        <div className="mb-3" key={`group-${group}`}>
                                            <div className="font-weight-bold small">{group}</div>

                                            <div>
                                                {roles.map((_role) => (
                                                    <UserRole
                                                        userRole={_role}
                                                        canEditRoles={canEditRoles}
                                                        key={`role-${_role.role}`}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    );
                                })}
                            </>
                        )}

                        <div className="mb-4 mt-4">
                            <WarningButton
                                type="submit"
                                disabled={isSubmitting || !dirty || !isValid || isFormDisabled}
                            >
                                {t('btn.save')}
                            </WarningButton>

                            {context === Constants.userFormContext.update && (
                                <Button
                                    variant="btn btn-link"
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        history.push(
                                            generatePath(SETTINGS_PATH, {
                                                view: 'users',
                                            })
                                        );
                                    }}
                                >
                                    {t('btn.close')}
                                </Button>
                            )}

                            <LoadingSpinner isSubmitting={isSubmitting} />
                        </div>
                    </FForm>
                )}
            </Formik>
        </div>
    );

    function handleSubmit(values, { setSubmitting, setFieldValue }) {
        const formData = { ...values };
        delete formData.passwordConfirmation;

        if (context === Constants.userFormContext.myProfile) {
            delete formData.roles;
            delete formData.email;
            delete formData.enabled;
        }

        if (canEditRoles === false) {
            delete formData.roles;
        }

        if (user.hasOwnProperty('id')) {
            // Update
            updateUser({ id: user.id, organisationId: organisation.id, body: formData }).then(() => {
                setSubmitting(false);
                setFieldValue('plainPassword', '');
                setFieldValue('passwordConfirmation', '');
            });
        } else {
            // Create
            createUser({ organisationId: organisation.id, body: formData }).then(() => {
                setSubmitting(false);
                history.push(
                    generatePath(SETTINGS_PATH, {
                        view: 'users',
                    })
                );
            });
        }
    }

    function getAvailableUserRoles() {
        return [
            {
                role: Constants.userRoles.superAdmin,
                label: tUsers('newUser.roles.superAdmin'),
                module: '',
                canAssignRole: isSuperAdmin,
                group: 'Organisatie',
            },
            {
                role: Constants.userRoles.organisationAdmin,
                label: tUsers('newUser.roles.organizationAdmin'),
                module: '',
                canAssignRole: isSuperAdmin || isOrganisationAdmin,
                group: 'Organisatie',
            },
            {
                role: Constants.userRoles.billingManager,
                label: tUsers('newUser.roles.billingManager'),
                module: '',
                canAssignRole: isSuperAdmin || isOrganisationAdmin,
                group: 'Organisatie',
            },
            {
                role: Constants.userRoles.userManager,
                label: tUsers('newUser.roles.userManager'),
                module: Constants.modules.users,
                canAssignRole: true,
                group: 'Organisatie',
            },
            {
                role: Constants.userRoles.documentManager,
                label: tUsers('newUser.roles.documentManager'),
                module: Constants.modules.documents,
                canAssignRole: true,
                group: 'Documenten',
            },
            {
                role: Constants.userRoles.publicationManager,
                label: tUsers('newUser.roles.publicationsManager'),
                module: Constants.modules.publications,
                canAssignRole: true,
                group: 'Publicaties',
            },
            {
                role: Constants.userRoles.publisher,
                label: tUsers('newUser.roles.publisher'),
                module: Constants.modules.publications,
                canAssignRole: true,
                group: 'Publicaties',
            },
            {
                role: Constants.userRoles.publicationSpectator,
                label: tUsers('newUser.roles.publicationSpectator'),
                module: Constants.modules.publications,
                canAssignRole: true,
                group: 'Publicaties',
            },
            {
                role: Constants.userRoles.reimbursementManager,
                label: tUsers('newUser.roles.reimbursementsManager'),
                module: Constants.modules.reimbursements,
                canAssignRole: true,
                group: 'Vergoedingen',
            },
            {
                role: Constants.userRoles.mediaManager,
                label: tUsers('newUser.roles.mediaManager'),
                module: Constants.modules.documents,
                canAssignRole: true,
                group: 'Algemeen',
            },
            {
                role: Constants.userRoles.tagManager,
                label: tUsers('newUser.roles.tagManager'),
                module: Constants.modules.tags,
                canAssignRole: true,
                group: 'Algemeen',
            },
            {
                role: Constants.userRoles.labelManager,
                label: tUsers('newUser.roles.labelManager'),
                module: Constants.modules.labels,
                canAssignRole: isSuperAdmin || isOrganisationAdmin,
                group: 'Algemeen',
            },
            {
                role: Constants.userRoles.operationListManager,
                label: tUsers('newUser.roles.changeListManager'),
                module: Constants.modules.operations_list,
                canAssignRole: true,
                group: 'Operations',
            },
            {
                role: Constants.userRoles.operationListEditor,
                label: tUsers('newUser.roles.changeListEditor'),
                module: Constants.modules.operations_list,
                canAssignRole: true,
                group: 'Operations',
            },
            {
                role: Constants.userRoles.operationListOnly,
                label: tUsers('newUser.roles.changeListUser'),
                module: Constants.modules.operations_list,
                canAssignRole: true,
                group: 'Operations',
            },
            {
                role: Constants.userRoles.translationManager,
                label: tUsers('newUser.roles.translationsManager'),
                module: Constants.modules.translations,
                canAssignRole: true,
                group: 'Vertalen',
            },
            {
                role: Constants.userRoles.templateManager,
                label: tUsers('newUser.roles.templateManager'),
                module: Constants.modules.documents,
                canAssignRole: isSuperAdmin || isOrganisationAdmin,
                group: 'Documenten',
            },
            {
                role: Constants.userRoles.kioskManager,
                label: tUsers('newUser.roles.kioskManager'),
                module: Constants.modules.publications,
                canAssignRole: isSuperAdmin || isOrganisationAdmin,
                group: 'Publicaties',
            },
        ];
    }
}

function UserRole({ userRole, canEditRoles = true }) {
    const { role, label } = userRole;

    return (
        <div className="form-check mb-1 mt-2">
            <Field
                name="roles"
                type="checkbox"
                value={role}
                className="form-check-input"
                id={`role-${role}`}
                disabled={canEditRoles === false}
            />
            <label className="form-check-label" htmlFor={`role-${role}`}>
                {label}
            </label>
        </div>
    );
}

const userFormSchema = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string().email().required(),
    plainPassword: Yup.string()
        .min(12)
        .matches(/(?=^.{12,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/),
    passwordConfirmation: Yup.string().when('plainPassword', {
        is: (val) => val && val.length > 0,
        then: Yup.string()
            .required()
            .oneOf([Yup.ref('plainPassword')], 'Both password need to be the same'),
    }),
});
