import {
    CardImage,
    EnvelopeAt,
    FileEarmarkExcel,
    FileEarmarkPdf,
    FileEarmarkText,
    FileEarmarkWord,
    X,
    ZoomIn,
} from 'react-bootstrap-icons';
import { IconButton } from 'components/Buttons';
import { useDeleteAttachmentMutation } from 'features/comments/commentApi';
import { saveAs } from 'file-saver';
import FileApi from 'api/FileApi';
import { useEffect, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

export function TaskFileAttachments({ files = [], canDelete = false, renderImages = false, showTitle = true }) {
    if (files.length === 0) {
        return null;
    }

    return (
        <div>
            {showTitle && <div className="font-weight-bold mb-2">Toegevoegde bestanden</div>}

            <ListAttachments attachments={files} canDelete={canDelete} renderImages={renderImages} />
        </div>
    );
}

export function ListAttachments({
    attachments = [],
    canDelete = false,
    renderImages = false,
    showPreviewButton = false,
}) {
    const [, setPreviewFileId] = useQueryParam('previewFile', StringParam);
    const [deleteAttachment] = useDeleteAttachmentMutation();

    if (attachments.length === 0) {
        return null;
    }

    return (
        <>
            {attachments.map((attachment) => (
                <div key={`attachment-${attachment.id}`}>
                    {renderImages && isImage(attachment.mime) && <RenderImage file={attachment} />}
                    {/*{renderImages && <RenderFile file={attachment} />}*/}

                    <div className="d-flex align-items-center mb-2">
                        <FileIcon mime={attachment.mime} />

                        <a href="#" onClick={() => downloadFile(attachment)}>
                            {attachment.fileName}
                        </a>

                        {canDelete && (
                            <IconButton
                                tooltip="Bestand verwijderen"
                                className="ml-2"
                                icon={<X size={18} className="text-danger" />}
                                onClick={() => handleDelete(attachment)}
                            />
                        )}

                        {showPreviewButton && (
                            <IconButton
                                tooltip="Voorvertoning"
                                className="ml-2"
                                icon={<ZoomIn size={18} />}
                                onClick={() => setPreviewFileId(attachment.id)}
                            />
                        )}
                    </div>
                </div>
            ))}
        </>
    );

    function isImage(mime) {
        return ['image/png', 'image/jpg', 'image/jpeg'].includes(mime);
    }

    function downloadFile(file) {
        FileApi.getPrivateFile(file.uri)
            .then((response) => {
                saveAs(response.data, response.headers['x-suggested-filename'] ?? file.fileName);
            })
            .catch(() => {});
    }

    function handleDelete(file) {
        deleteAttachment(file['@id']);
    }
}

export function FileIcon({mime}) {
    switch (mime) {
        case 'application/vnd.ms-outlook':
        case 'message/rfc822':
            return <EnvelopeAt size={16} className="text-muted mr-2" />;

        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'application/msword':
            return <FileEarmarkWord size={16} className="text-muted mr-2" />;

        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'application/vnd.ms-excel':
            return <FileEarmarkExcel size={16} className="text-muted mr-2" />;

        case 'application/pdf':
            return <FileEarmarkPdf size={16} className="text-muted mr-2" />;

        case 'image/png':
        case 'image/jpg':
        case 'image/jpeg':
            return <CardImage size={16} className="text-muted mr-2" />;

        default:
            return <FileEarmarkText size={16} className="text-muted mr-2" />;
    }
}

function RenderImage({ file }) {
    const [src, setSrc] = useState();

    useEffect(() => {
        FileApi.getPrivateFile(file.uri)
            .then((response) => {
                setSrc(URL.createObjectURL(response.data));
            })
            .catch(() => {
                console.log('error loading image');
            });
    }, []);

    if (!src) {
        return null;
    }

    return <img src={src} className="img-fluid" />;
}
