import React, { useEffect, useState } from 'react';
import { resetTokenData, saveTokenData } from 'api/AuthApi';
import { handleAuthenticationChange, resetState } from 'features/security/securitySlice';
import { useDispatch } from 'react-redux';
import getEnv from '../../config/Env';
import { Alert, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { Microsoft } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../../language/LanguageSwitcher';
import { useGetUserSsoStatusMutation, useUserLoginMutation } from 'features/security/authApi';
import { PrimaryButton } from 'components/Buttons';
import { StringParam, useQueryParam } from 'use-query-params';
import { TYPE_AZURE, TYPE_AZURE_SAML } from 'pages/account/views/Sso';
import _ from 'lodash';
import { Logo } from 'pages/global/Logo';
import { Link } from 'react-router-dom';
import { FORGOT_PASSWORD_PATH } from 'pages/authentication/ForgotPassword';

export function Login() {
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [formError, setFormError] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState('');
    const [ssoLoginButton, setSsoLoginButton] = useState(undefined);

    const userEmail = localStorage.getItem('userEmail') ?? '';
    const [username, setUsername] = useState(userEmail);
    const [password, setPassword] = useState('');

    const dispatch = useDispatch();
    const ssoName = getEnv('SSO_NAME');
    const ssoApiUrl = getEnv('SSO_API_BASE_URL');
    const { t } = useTranslation('global');
    const [getUserSsoStatus] = useGetUserSsoStatusMutation();
    const [userLogin] = useUserLoginMutation();
    const [ssoError] = useQueryParam('ssoError', StringParam);

    useEffect(() => {
        if (userEmail !== '') {
            getUserSsoStatus(username).then(({ data }) => {
                if (data) {
                    const { ssoEnabled = false } = data;

                    if (ssoEnabled) {
                        setSsoLoginButton(data);
                    }
                }
            });
        }
    }, [userEmail]);

    return (
        <div>
            <div id="login-bg-image" />
            <Container className="page-login pt-5 pb-4">
                <Row className="justify-content-center">
                    <Col sm={10} md={8} lg={6} xl={5}>
                        <Card className="dr-container mt-3">
                            <Card.Body>
                                <div className="p-4">
                                    <div className="text-center mb-4">
                                        <Logo />
                                        <div className="text-color pb-2" style={{ fontSize: '1.15rem' }}>
                                            {t('login.authentication.loginHeader')}
                                        </div>
                                    </div>

                                    {ssoName && <OldSsoLoginButton name={ssoName} />}

                                    {!ssoName && ssoLoginButton && (
                                        <SsoLoginButton ssoStatus={ssoLoginButton} userEmail={userEmail} />
                                    )}

                                    <Form className="login-form" onSubmit={handleFormSubmit}>
                                        {formError && (
                                            <Alert variant="danger" className="small mb-4">
                                                {formErrorMessage}
                                            </Alert>
                                        )}

                                        {ssoError && (
                                            <Alert variant="danger" className="small mb-4">
                                                {ssoError}
                                            </Alert>
                                        )}

                                        <Form.Group controlId="username">
                                            <Form.Label>{t('login.authentication.email')}</Form.Label>
                                            <Form.Control
                                                required
                                                disabled={isLoading}
                                                autoComplete="username"
                                                autoFocus={true}
                                                type="email"
                                                placeholder={t('login.authentication.emailPlaceholder')}
                                                name="username"
                                                value={username}
                                                onChange={(e) => setUsername(_.trim(e.currentTarget.value))}
                                            />
                                        </Form.Group>
                                        {showPassword && (
                                            <Form.Group controlId="password">
                                                <Form.Label>{t('login.authentication.password')}</Form.Label>
                                                <Form.Control
                                                    required
                                                    disabled={isLoading}
                                                    autoComplete="current-password"
                                                    autoFocus={true}
                                                    type="password"
                                                    name="password"
                                                    placeholder={t('login.authentication.passwordPlaceholder')}
                                                    value={password}
                                                    onChange={(e) => setPassword(_.trim(e.currentTarget.value))}
                                                />
                                            </Form.Group>
                                        )}
                                        {showPassword ? (
                                            <PrimaryButton type="submit" className="w-100 py-2" disabled={isLoading}>
                                                {t('login.authentication.btn.login')}
                                            </PrimaryButton>
                                        ) : (
                                            <PrimaryButton type="submit" className="w-100 py-2" disabled={isLoading}>
                                                {t('login.authentication.btn.next')}
                                            </PrimaryButton>
                                        )}
                                    </Form>

                                    <div className="mt-4">
                                        <Link to={FORGOT_PASSWORD_PATH}>{t('global:login.reset.forgotPassword')}</Link>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>

                        <div className="d-flex justify-content-center align-items-center pt-2">
                            <LanguageSwitcher />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );

    function handleFormSubmit(e) {
        e.preventDefault();
        setLoading(true);
        setFormError(false);
        setFormErrorMessage('');

        if (showPassword) {
            // Try to login
            new Promise((resolve, reject) => {
                userLogin({ username: username, password: password }).then((response) => {
                    if (response.hasOwnProperty('error')) {
                        reject(response.error.data.error);
                    } else {
                        resolve(response.data);
                    }
                });
            })
                .then((data) => {
                    const { token, refreshToken } = data;

                    // Save in application
                    saveTokenData(token, refreshToken)
                        .then(() => {
                            // Login OK
                            dispatch(handleAuthenticationChange());
                        })
                        .catch(() => {
                            resetTokenData();
                            dispatch(resetState());
                        });
                })
                .catch((error) => {
                    let errorMessage = t('authentication.errorMessage.applicationUnavailable');
                    setFormError(true);

                    if (error === 'Invalid credentials.') {
                        errorMessage = t('authentication.errorMessage.invalidLogin');
                    } else if (error === 'Too many authentication failures.') {
                        errorMessage = t('authentication.errorMessage.tooManyRequests');
                    }

                    setFormErrorMessage(errorMessage);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            getUserSsoStatus(username).then(({ data }) => {
                const { ssoEnabled = false, slug } = data;

                if (ssoEnabled) {
                    // Redirect to endpoint with the config id
                    window.location.replace(`${ssoApiUrl}/config-redirect/${slug}?email=${username}`);
                } else {
                    setLoading(false);
                    setShowPassword(true);
                }
            });
        }
    }
}

function OldSsoLoginButton({ name }) {
    const { t } = useTranslation('global');
    const authApiUrl = getEnv('AUTH_API_URL');

    return (
        <div className="pt-1 mb-2">
            <a
                href={`${authApiUrl}/sso/connect/${name}`}
                className="btn btn-outline-primary d-flex justify-content-center align-items-center w-100 py-2"
            >
                <Microsoft className="mr-2" /> {t('login.authentication.loginSSO')}
            </a>

            <div className="or-seperator mt-5">
                <strong>{t('login.authentication.or')}</strong>
            </div>
        </div>
    );
}

function SsoLoginButton({ ssoStatus, userEmail }) {
    const { t } = useTranslation('global');
    const ssoApiUrl = getEnv('SSO_API_BASE_URL');
    const { slug, ssoType } = ssoStatus;

    return (
        <div className="pt-1 mb-2">
            <a
                href={`${ssoApiUrl}/config-redirect/${slug}?email=${userEmail}`}
                className="btn btn-outline-primary d-flex justify-content-center align-items-center w-100 py-2"
            >
                {(ssoType === TYPE_AZURE || ssoType === TYPE_AZURE_SAML) && (
                    <>
                        <Microsoft className="mr-2" /> {t('login.authentication.loginWith', { name: 'Microsoft' })}
                    </>
                )}
            </a>

            <div className="or-seperator mt-5">
                <strong>{t('login.authentication.or')}</strong>
            </div>
        </div>
    );
}
