import { Modal } from 'react-bootstrap';
import { Field, Form as FForm, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { FormModal, ModalFooter } from '../../global/FormModal';
import { batchPublish, publish } from '../helpers/PublicationActions';
import { setSelectedRows } from 'features/publications/publicationsSlice';
import { InputCheck } from '../helpers/FieldHelper';
import { useEffect } from 'react';
import Constants from '../../../config/Constants';
import { useTranslation } from 'react-i18next';
import HelperFunctions from '../../global/HelperFunctions';
import { NumberParam, useQueryParam } from 'use-query-params';
import { publicationApi } from 'features/publications/publicationApi';

export default function PublishModal({ showModal, handleClose, payload }) {
    const dispatch = useDispatch();
    const { publication, publications = [], isBatch = false } = payload;
    const { t } = useTranslation('publications');

    if (!showModal) {
        return null;
    }

    const handleSubmit = (values, { setSubmitting }) => {
        const formData = {
            ...values,
            scheduledAt: values.scheduledAt === '' ? null : values.scheduledAt,
            publicationEnd: values.publicationEnd === '' ? null : values.publicationEnd,
        };

        if (values.isBatch) {
            batchPublish(dispatch, formData).then(() => {
                // Deselect everything
                dispatch(setSelectedRows([]));
                dispatch(publicationApi.util.invalidateTags([{ type: 'PublicationVersion' }]));
                handleClose();
            });
        } else {
            publish(dispatch, publication, formData).then((result) => {
                // Reload versions when done
                dispatch(publicationApi.util.invalidateTags([{ type: 'Publication', id: publication['@id'] }]));

                handleClose();

                if (
                    result.type === 'publications/publishPublication/rejected' &&
                    result.error.code === 'ERR_BAD_REQUEST'
                ) {
                    HelperFunctions.alertModal('Kan niet publiceren: er is al een publicatie met dezelfde url.');
                }
            });
        }
    };

    return (
        <FormModal
            show={showModal}
            onHide={handleClose}
            title={
                t('publications.bulkActions.publishModal.title') +
                ' ' +
                (isBatch ? `selectie (${publications.length})` : publication.name)
            }
        >
            <Formik
                initialValues={{
                    action: Constants.publicationToken.action_publish,
                    publication: isBatch ? '' : publication['@id'],
                    publications: publications,
                    publicationStartValue: 'now',
                    scheduledAt: '',
                    publicationEndValue: false,
                    publicationEnd: '',
                    isBatch,
                }}
                validationSchema={PublishSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, isValid, values, setFieldValue }) => (
                    <FForm autoComplete="off">
                        <Modal.Body>
                            <FormContent payload={payload} values={values} setFieldValue={setFieldValue} />
                        </Modal.Body>

                        <ModalFooter
                            isValid={isValid}
                            isSubmitting={isSubmitting}
                            onHide={handleClose}
                            btnSubmitText={t('publications.bulkActions.publishModal.btn.publish')}
                        />
                    </FForm>
                )}
            </Formik>
        </FormModal>
    );
}

function FormContent({ payload, values, setFieldValue }) {
    const { publication, isBatch = false } = payload;
    const { publicationStartValue, publicationEndValue } = values;
    const { t } = useTranslation('publications');
    const [showPublishModal, setShowPublishModal] = useQueryParam('showPublishModal', NumberParam);

    useEffect(() => {
        if (!showPublishModal && !isBatch) {
            setShowPublishModal(publication.id);
        }
    }, [showPublishModal]);

    useEffect(() => {
        if (publicationStartValue === 'now') {
            setFieldValue('scheduledAt', '');
        }
    }, [publicationStartValue]);

    useEffect(() => {
        if (publicationEndValue === false) {
            setFieldValue('publicationEnd', '');
        }
    }, [publicationEndValue]);

    return (
        <div className="text-darkBlueAlt">
            <div className="font-weight-bold mb-2">{t('publications.bulkActions.publishModal.startEndDate')}</div>
            <div className="font-weight-bold mb-3 font-size-sm">
                {t('publications.bulkActions.publishModal.publicationStartDate')}
            </div>
            <div className="mb-3">
                {isBatch
                    ? t('publications.bulkActions.publishModal.specificDateMultiple')
                    : t('publications.bulkActions.publishModal.specificDate', { name: publication.name })}
            </div>

            <div className="mb-3">
                <div className="mb-2">
                    <div className="form-check">
                        <Field
                            className="form-check-input"
                            type="radio"
                            id="publicationStartNow"
                            name="publicationStartValue"
                            value="now"
                        />
                        <label htmlFor="publicationStartNow" className="form-check-label">
                            {t('publications.bulkActions.publishModal.publishNow')}
                        </label>
                    </div>
                </div>
                <div className="form-check">
                    <Field
                        className="form-check-input"
                        type="radio"
                        id="publicationStartCustom"
                        name="publicationStartValue"
                        value="custom"
                    />
                    <label htmlFor="publicationStartCustom" className="form-check-label mr-3">
                        {t('publications.bulkActions.publishModal.otherDate')}
                    </label>

                    <Field type="datetime-local" name="scheduledAt" disabled={publicationStartValue !== 'custom'} />
                </div>
            </div>

            <div>
                <div className="font-weight-bold mb-3 font-size-sm">
                    {t('publications.bulkActions.publishModal.endDate')}
                </div>
                <div className="mb-3">
                    {isBatch
                        ? t('publications.bulkActions.publishModal.specificDepubDateMultiple')
                        : t('publications.bulkActions.publishModal.specificDepubDate', { name: publication.name })}
                </div>

                <div className="d-flex align-items-center">
                    <div className="mr-3">
                        <InputCheck name="publicationEndValue" />
                    </div>

                    <Field type="datetime-local" name="publicationEnd" disabled={publicationEndValue === false} />
                </div>
            </div>
        </div>
    );
}

const PublishSchema = Yup.object().shape({
    publication: Yup.string(),
    scheduledAt: Yup.string().nullable().when('publicationStartValue', {
        is: 'custom',
        then: Yup.string().required(),
    }),
    publicationEnd: Yup.string().nullable().when('publicationEndValue', {
        is: true,
        then: Yup.string().required(),
    }),
});
