import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import HelperFunctions from 'pages/global/HelperFunctions';
import { useGetTeamChecklistsQuery } from 'features/comments/commentApi';

/**
 * @returns {Checklist[]}
 */
export function useGetTeamChecklists(teamId) {
    const selectChecklists = useMemo(() => {
        const emptyArray = [];

        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (res) => res.data,
            (data) => (data ? [...data].sort(HelperFunctions.dynamicSort('title')) : emptyArray)
        );
    }, []);

    const { checklists } = useGetTeamChecklistsQuery(teamId, {
        selectFromResult: (result) => ({
            checklists: selectChecklists(result),
        }),
    });

    return checklists;
}
