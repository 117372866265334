import { FormModal, ModalFooter } from '../../../global/FormModal';
import { Modal } from 'react-bootstrap';
import { Form as FForm, Formik } from 'formik';
import * as Yup from 'yup';
import { Reimbursement } from '../../../../models/reimbursement.models';
import { idToUri } from '../../../global/UriHelper';
import { FieldSelect } from '../../../publications_v2/helpers/FieldHelper';
import { useTranslation } from 'react-i18next';
import { useGetReimbursements } from '../../../reimbursements/hooks/useGetReimbursements';

interface FormInterface {
    id?: number;
    code: string;
}

export function EditPackageReimbursementModal({
    showModal = false,
    handleClose,
    handleFormSubmit,
    categoryId,
    formData,
}: {
    showModal: boolean;
    handleClose: () => void;
    handleFormSubmit: (code: string) => void;
    categoryId: number;
    formData: FormInterface;
}) {
    const categoryUri = idToUri(categoryId, 'Category');
    const { reimbursements } = useGetReimbursements(categoryUri);
    const { t } = useTranslation('documents');

    const reimbursementOptions = reimbursements
        .filter((reimbursement: Reimbursement) => reimbursement.enabled)
        .map((reimbursement: Reimbursement) => ({
            ...reimbursement,
            label: `${reimbursement.code} - ${reimbursement.name}`,
            value: reimbursement.code,
        }));

    const handleSubmit = (values: FormInterface) => {
        handleFormSubmit(values.code);
    };

    return (
        <FormModal
            show={showModal}
            onHide={handleClose}
            title={
                formData.id === undefined
                    ? t('document.navbar.main.editor.left.blocks.modals.editPackageReimbursementModal.addPackage')
                    : t('document.navbar.main.editor.left.blocks.modals.editPackageReimbursementModal.editPackage')
            }
        >
            <Formik initialValues={formData} validationSchema={EditPackageReimbursementSchema} onSubmit={handleSubmit}>
                {({ isSubmitting, dirty, isValid }) => (
                    <FForm autoComplete="off">
                        <Modal.Body>
                            <FieldSelect
                                label={`${t(
                                    'document.navbar.main.editor.left.blocks.modals.editPackageReimbursementModal.reimbursement',
                                )}*`}
                                name="code"
                                options={reimbursementOptions}
                                props={{
                                    placeholder: `${t(
                                        'document.navbar.main.editor.left.blocks.modals.editPackageReimbursementModal.titleSelect',
                                    )}...`,
                                }}
                            />
                        </Modal.Body>

                        <ModalFooter dirty={dirty} isValid={isValid} isSubmitting={isSubmitting} onHide={handleClose} />
                    </FForm>
                )}
            </Formik>
        </FormModal>
    );
}

const EditPackageReimbursementSchema = Yup.object().shape({
    code: Yup.string().required('Required'),
});
