import MainContentNav from '../../Navbar';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import Sidebar from './edit/Sidebar';
import Layout from '../forms/Layout';
import Compose, { AddSectionButton } from '../forms/Compose';
import { Alert, Button } from 'react-bootstrap';
import Properties from '../forms/Properties';
import { useFormikContext } from 'formik';
import { fetchPublication, selectPublicationById } from 'features/publications/publicationsSlice';
import EditPublication from '../forms/EditPublication';
import _ from 'lodash';
import { ExclamationTriangleFill } from 'react-bootstrap-icons';
import HelperFunctions from '../../global/HelperFunctions';
import { BASE_PATH, INDEX_PATH } from 'scenes/PublicationsV2';
import Preview from '../forms/Preview';
import ExportChanges from '../forms/ExportChanges';
import { LoadingSpinner } from '../../global/Spinner';
import { ReimbursementXml } from '../forms/ReimbursementXml';
import { WarningButton } from 'components/Buttons';
import { useTranslation } from 'react-i18next';
import PublicationGroups from '../forms/PubGroupProperties';
import { Kiosk } from '../forms/Kiosk';
import { Labels } from 'pages/publications_v2/forms/Labels';
import { useGetPublications } from 'pages/publications_v2/hooks/useGetPublications';
import { isPublicationLimitReached, updateAbilityForPublications } from 'ability/Ability';
import { useCurrentOrganisation } from 'hooks/useCurrentOrganisation';
import { useAbility } from 'ability/useAbility';
import { useUserProfile } from 'hooks/useUserProfile';

export default function Edit() {
    const { action, publicationId } = useParams();
    const dispatch = useDispatch();
    const publication = useSelector((state) => selectPublicationById(state.publications, publicationId));
    const [isLoading, setLoading] = useState(true);
    const [showAddContentSectionModal, setAddContentSectionModal] = useState(false);
    const { t } = useTranslation('publications');
    const currentOrganisation = useCurrentOrganisation();
    const ability = useAbility();
    const userProfile = useUserProfile();

    const editMode = publicationId > 0;

    const allPublications = useGetPublications();
    const numberOfPublications = allPublications.length;
    const limitReached = isPublicationLimitReached(numberOfPublications, currentOrganisation);

    useEffect(() => {
        updateAbilityForPublications(ability, numberOfPublications, userProfile, currentOrganisation);
    }, [numberOfPublications, currentOrganisation, userProfile]);

    useEffect(() => {
        if (editMode) {
            // Edit Publication
            dispatch(fetchPublication({ id: publicationId })).then(() => {
                setLoading(false);
            });
        } else {
            // New Publication
            setLoading(false);
        }
    }, [dispatch, publicationId, editMode]);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    const actions = {
        compose: t('publication.settings.content.summary.title'),
        layout: t('publication.settings.content.layout.title'),
        properties: t('publication.settings.content.features.title'),
        preview: t('publication.settings.content.example.title'),
        export_changes: t('publication.settings.content.exportChanges.title'),
        language: t('publication.settings.content.language'),
        reimbursement_xml: t('publication.settings.content.reimbursementXml'),
        password_protection: t('publication.settings.contentSidebar.password_protection'),
        kiosk: t('publication.settings.contentSidebar.kiosk'),
        labels: t('publication.settings.contentSidebar.labels'),
    };

    return (
        <>
            <MainContentNav
                pages={[
                    { title: t('breadcrumb'), url: BASE_PATH },
                    { title: publication?.name ?? t('newPublication') },
                    { title: actions[action] },
                ]}
            />

            <EditPublication>
                <>
                    <div className="position-absolute" style={{ top: 73, right: 0, left: 0, bottom: 65 }}>
                        <div className="d-flex align-items-stretch h-100 bg-light">
                            <div className="content-sidebar-document overflow-auto">
                                <Sidebar />
                            </div>
                            <div className="flex-grow-1 overflow-auto px-5">
                                <div className="dr-container p-4" style={{ marginTop: '48px', marginBottom: '47px' }}>
                                    <div className="subheader d-flex mb-4 pb-2">
                                        <h3>{actions[action]}</h3>
                                        {action === 'compose' && (
                                            <AddSectionButton setAddContentSectionModal={setAddContentSectionModal} />
                                        )}
                                    </div>

                                    {editMode === false && limitReached && (
                                        <Alert variant="danger">
                                            Limit reached for current subscription. Please upgrade your plan to allow
                                            for more publications.
                                        </Alert>
                                    )}

                                    <div className="mb-5">
                                        <Action
                                            showAddContentSectionModal={showAddContentSectionModal}
                                            setAddContentSectionModal={setAddContentSectionModal}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FormActions editMode={editMode} />
                </>
            </EditPublication>
        </>
    );
}

function Action({ showAddContentSectionModal, setAddContentSectionModal }) {
    const { action } = useParams();

    switch (action) {
        case 'properties':
            return <Properties />;
        case 'compose':
            return (
                <Compose
                    showAddContentSectionModal={showAddContentSectionModal}
                    setAddContentSectionModal={setAddContentSectionModal}
                />
            );
        case 'layout':
            return <Layout />;
        case 'preview':
            return <Preview />;
        case 'export_changes':
            return <ExportChanges />;
        case 'password_protection':
            return <PublicationGroups />;
        case 'reimbursement_xml':
            return <ReimbursementXml />;
        case 'kiosk':
            return <Kiosk />;
        case 'labels':
            return <Labels />;
        default:
            return <>Not supported</>;
    }
}

function FormActions({editMode}) {
    const history = useHistory();
    const { isSubmitting, dirty, errors, isValid } = useFormikContext();
    const reallyIsValid = formIsValid(errors, isValid);
    const { groupId, publicationId } = useParams();
    const { t } = useTranslation('publications');
    const ability = useAbility();

    const canSubmitForm = ability.can(editMode ? 'edit' : 'create', 'Publication');

    return (
        <div className="position-absolute bg-white border-top" style={{ height: 65, right: 0, left: 0, bottom: 0 }}>
            <div className="d-flex justify-content-end align-items-center pr-5" style={{ height: 65 }}>
                <div className="mr-3">
                    <LoadingSpinner isSubmitting={isSubmitting} />
                </div>

                {dirty && !isSubmitting && reallyIsValid && (
                    <>
                        <ExclamationTriangleFill className="text-warning mr-1" />
                        <div className="text-warning small mr-4">
                            {t('publication.settings.content.unsavedChangesV2')}
                        </div>
                    </>
                )}
                {dirty && !reallyIsValid && (
                    <>
                        <ExclamationTriangleFill className="text-danger mr-1" />
                        <div className="text-danger small mr-4"> {t('publication.settings.content.formErrors')}</div>
                    </>
                )}

                <Button className="mr-2" variant="secondary" onClick={handleClose} disabled={isSubmitting}>
                    {t('btn.close')}
                </Button>
                <WarningButton
                    type="submit"
                    form="editPublicationForm"
                    disabled={isSubmitting || !reallyIsValid || !dirty || !canSubmitForm}
                >
                    {t('btn.save')}
                </WarningButton>
            </div>
        </div>
    );

    function close() {
        history.push(
            generatePath(INDEX_PATH, {
                publicationId: publicationId,
                folder: groupId && groupId !== '0' ? 'folder' : 'list',
                folderId: groupId && groupId !== '0' ? groupId : '0',
            })
        );
    }

    function handleClose() {
        if (dirty === false) {
            // No changes in the form, just close the window
            close();
            return;
        }

        // Ask for confirmation first
        HelperFunctions.confirmModal(
            t('publication.settings.content.unsavedChanges'),
            'danger',
            false,
            t('publication.settings.content.btn.confirmClose'),
            t('btn.cancel')
        ).then(() => {
            close();
        });
    }
}

const formIsValid = (errors, isValid) => {
    if (!isValid) {
        return false;
    }

    const requiredFields = ['name', 'title', 'templateId', 'domainId', 'pdfPermissionPassword'];

    const fieldsMissing = requiredFields.some((_field) => errors.hasOwnProperty(_field));

    if (fieldsMissing) {
        return false;
    }

    if (errors.hasOwnProperty('content') && _.isObject(errors.content)) {
        const realErrors = { ...errors };
        const contentErrors = errors.content.filter((_content) => {
            return _content;
        });

        if (contentErrors.length === 0) {
            delete realErrors.content;
        }

        return _.isEmpty(realErrors);
    }

    return true;
};
