import { useGetOrganisationTags } from "../../tags/hooks/useGetOrganisationTags";
import { useGetDocument } from "./useGetDocument";
import { useGetTemplate } from "./useGetTemplate";

export function useGetTemplateTagsCombined(tags) {
    const document = useGetDocument() || undefined;
    const template = useGetTemplate(document?.modelId, document?.modelSource) || undefined;
    const templateTags = template?.tags || [];
    const combinedTags = Array.from(
        new Map([...tags, ...templateTags].map((tag) => [tag.id, tag])).values()
    );

    return combinedTags;
}
