import { useGetAdminOrganisationQuery } from 'features/security/authApi';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { PrimaryButton } from 'components/Buttons';
import { ADMIN_SETTING_ACCOUNT_PATH } from 'scenes/Admin';
import { Badge, Form } from 'react-bootstrap';
import { CheckCircleFill, XCircleFill } from 'react-bootstrap-icons';
import HelperFunctions from '../../../global/HelperFunctions';

export function View({ account }) {
    const { entityId } = useParams();
    const history = useHistory();

    const { organisation } = useGetAdminOrganisationQuery(parseInt(entityId), {
        selectFromResult: ({ data }) => ({
            organisation: data,
        }),
    });

    if (!organisation) {
        return null;
    }

    return (
        <div>
            <div className="subheader d-flex align-items-center justify-content-between pb-1 mb-3">
                <h3 className="mb-0">{organisation.name}</h3>

                <PrimaryButton
                    size="sm"
                    className="mb-0"
                    onClick={() => {
                        history.push(
                            generatePath(ADMIN_SETTING_ACCOUNT_PATH, {
                                id: account.id,
                                view: 'organisations',
                                action: 'edit',
                                entityId,
                            })
                        );
                    }}
                >
                    Bewerken
                </PrimaryButton>
            </div>

            <div className="form-group row mb-0">
                <Form.Label htmlFor="name" className="col-sm-4 col-form-label">
                    Naam
                </Form.Label>
                <div className="col-sm-8">
                    <Form.Control readOnly plaintext id="name" value={organisation.name} />
                </div>
            </div>

            <div className="form-group row mb-0">
                <Form.Label htmlFor="aiRateLimit" className="col-sm-4 col-form-label">
                    AI rate limit
                </Form.Label>
                <div className="col-sm-8">
                    <Form.Control readOnly plaintext id="aiRateLimit" value={organisation.aiRateLimit} />
                </div>
            </div>
            <div className="form-group row mb-0">
                <Form.Label htmlFor="aiUsageCount" className="col-sm-4 col-form-label">
                    AI usage count
                </Form.Label>
                <div className="col-sm-8">
                    <Form.Control readOnly plaintext id="aiUsageCount" value={organisation.aiUsageCount} />
                </div>
            </div>
            <div className="form-group row mb-0">
                <Form.Label htmlFor="website" className="col-sm-4 col-form-label">
                    Documentation url
                </Form.Label>
                <div className="col-sm-8">
                    <Form.Control readOnly plaintext id="website" value={organisation.website} />
                </div>
            </div>

            <div className="form-group row mb-0">
                <Form.Label htmlFor="enabled" className="col-sm-4 col-form-label">
                    Ingeschakeld
                </Form.Label>
                <div className="col-sm-8 pt-2">
                    {organisation.enabled ? (
                        <Badge variant="success" className="d-inline-flex align-items-center">
                            <CheckCircleFill className="flex-shrink-0 mr-1" />
                            Ja
                        </Badge>
                    ) : (
                        <Badge variant="danger" className="d-inline-flex align-items-center">
                            <XCircleFill className="flex-shrink-0 mr-1" />
                            Nee
                        </Badge>
                    )}
                </div>
            </div>

            <div className="form-group row mb-0">
                <Form.Label htmlFor="ssoEnabled" className="col-sm-4 col-form-label">
                    SSO enabled
                </Form.Label>
                <div className="col-sm-8 pt-2">
                    {organisation.ssoEnabled ? (
                        <Badge variant="success" className="d-inline-flex align-items-center">
                            <CheckCircleFill className="flex-shrink-0 mr-1" />
                            Ja
                        </Badge>
                    ) : (
                        <Badge variant="danger" className="d-inline-flex align-items-center">
                            <XCircleFill className="flex-shrink-0 mr-1" />
                            Nee
                        </Badge>
                    )}
                </div>
            </div>

            <div className="form-group row mb-0">
                <Form.Label htmlFor="requireTagInComment" className="col-sm-4 col-form-label">
                    Require tag in comments
                </Form.Label>
                <div className="col-sm-8 pt-2">
                    {organisation.requireTagInComment ? (
                        <Badge variant="success" className="d-inline-flex align-items-center">
                            <CheckCircleFill className="flex-shrink-0 mr-1" />
                            Ja
                        </Badge>
                    ) : (
                        <Badge variant="danger" className="d-inline-flex align-items-center">
                            <XCircleFill className="flex-shrink-0 mr-1" />
                            Nee
                        </Badge>
                    )}
                </div>
            </div>

            <div className="form-group row mb-0">
                <Form.Label className="col-sm-4 col-form-label">Users</Form.Label>
                <div className="col-sm-8">
                    <EntityBadgeList
                        organisation={organisation}
                        propertyName="users"
                        labelProp="fullName"
                        sort={true}
                    />
                </div>
            </div>

            <div className="form-group row mb-0">
                <Form.Label className="col-sm-4 col-form-label">Modules</Form.Label>
                <div className="col-sm-8">
                    <EntityBadgeList organisation={organisation} propertyName="modules" labelProp="name" />
                </div>
            </div>

            <div className="form-group row mb-0">
                <Form.Label className="col-sm-4 col-form-label">Domeinen</Form.Label>
                <div className="col-sm-8">
                    <EntityBadgeList organisation={organisation} propertyName="publicationDomains" labelProp="domain" />
                </div>
            </div>

            <div className="form-group row mb-0">
                <Form.Label className="col-sm-4 col-form-label">Template bronnen</Form.Label>
                <div className="col-sm-8">
                    <ArrayBadgeList organisation={organisation} propertyName="templateSources" />
                </div>
            </div>
            <div className="form-group row mb-0">
                <Form.Label className="col-sm-4 col-form-label">Template categorien</Form.Label>
                <div className="col-sm-8">
                    <EntityBadgeList organisation={organisation} propertyName="templateCategories" labelProp="name" />
                </div>
            </div>

            <div className="form-group row mb-0">
                <Form.Label htmlFor="enabled" className="col-sm-4 col-form-label">
                    Ipid insurer
                </Form.Label>
                <div className="col-sm-8 pt-2">
                    {organisation.ipidImport ? (
                        <Badge variant="success" className="d-inline-flex align-items-center">
                            <CheckCircleFill className="flex-shrink-0 mr-1" />
                            Ja
                        </Badge>
                    ) : (
                        <Badge variant="danger" className="d-inline-flex align-items-center">
                            <XCircleFill className="flex-shrink-0 mr-1" />
                            Nee
                        </Badge>
                    )}
                </div>
            </div>

            {organisation.ipidImport && (
                <div className="form-group row mb-0">
                    <Form.Label htmlFor="insurerIds" className="col-sm-4 col-form-label">
                        Insurer ID(s)
                    </Form.Label>
                    <div className="col-sm-8">
                        <Form.Control readOnly plaintext id="insurerIds" value={organisation.insurerIds.join(', ')} />
                    </div>
                </div>
            )}
        </div>
    );
}

function EntityBadgeList({ organisation, propertyName, labelProp, sort = false }) {
    let entities = organisation[propertyName] ?? [];

    if (sort) {
        entities = [...entities].sort(HelperFunctions.sortByString(labelProp));
    }

    return (
        <div className="form-control-plaintext">
            {entities.map((entity) => (
                <Badge variant="primary" className="mr-1 mb-1" key={`entity-${propertyName}-${entity.id}`}>
                    {entity[labelProp]}
                </Badge>
            ))}

            {entities.length === 0 && <>-</>}
        </div>
    );
}

function ArrayBadgeList({ organisation, propertyName }) {
    const items = organisation[propertyName] ?? [];

    return (
        <div className="form-control-plaintext">
            {items.map((item, index) => (
                <Badge variant="primary" className="mr-1 mb-1" key={`array-${propertyName}-${index}`}>
                    {item}
                </Badge>
            ))}

            {items.length === 0 && <>-</>}
        </div>
    );
}
