import { useGetDocument } from '../../documents_v2/hooks/useGetDocument';
import { useGetPropositionsQuery } from '../../../features/reimbursements/reimbursements';

const emptyArray = [];

export function useGetPropositions() {
    const document = useGetDocument();
    const categoryId = document?.reimbursementCategoryId;

    const { propositions } = useGetPropositionsQuery(
        {
            uri: '/api/reimbursement-api/api',
            params: { 'category.id': categoryId },
        },
        {
            selectFromResult: ({ data }) => ({
                propositions: data ?? emptyArray,
            }),
            skip: categoryId === null,
        }
    );

    return propositions;
}
