import _ from 'lodash';
import HelperFunctions from './HelperFunctions';

const EditorHelper = {
    createTagElement(tag, title) {
        return `<span class="refs-button" data-replace="${_.trim(tag, '[]')}">
<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-shuffle mr-1" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M0 3.5A.5.5 0 0 1 .5 3H1c2.202 0 3.827 1.24 4.874 2.418.49.552.865 1.102 1.126 1.532.26-.43.636-.98 1.126-1.532C9.173 4.24 10.798 3 13 3v1c-1.798 0-3.173 1.01-4.126 2.082A9.6 9.6 0 0 0 7.556 8a9.6 9.6 0 0 0 1.317 1.918C9.828 10.99 11.204 12 13 12v1c-2.202 0-3.827-1.24-4.874-2.418A10.6 10.6 0 0 1 7 9.05c-.26.43-.636.98-1.126 1.532C4.827 11.76 3.202 13 1 13H.5a.5.5 0 0 1 0-1H1c1.798 0 3.173-1.01 4.126-2.082A9.6 9.6 0 0 0 6.444 8a9.6 9.6 0 0 0-1.317-1.918C4.172 5.01 2.796 4 1 4H.5a.5.5 0 0 1-.5-.5"/>
  <path d="M13 5.466V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192m0 9v-3.932a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192"/>
</svg>${title}</span>`;
    },
    generateTagMenu(tags, editor) {
        if (!tags || tags.length === 0) {
            return [];
        }

        let menu = [];

        tags.filter((tag) => {
            return (
                tag.hasOwnProperty('name') === false ||
                tag.hasOwnProperty('deletedAt') === false ||
                tag.deletedAt === null
            );
        })
            .sort(HelperFunctions.sortByString('name'))
            .forEach((tag) => {
                menu.push({
                    type: 'menuitem',
                    text: tag.name,
                    onAction: function () {
                        insertTag(tag, editor);
                    },
                });
            });

        return menu;
    },
    insertCrossReference(object, type, editor, document) {
        const tag = `[Ref id&#61;${object.id} type&#61;${type} document&#61;${document.id}]`;
        editor.insertContent(tag);
    },
    createCrossReferenceTag(entityId, entityType, documentId) {
        return `[Ref id&#61;${entityId} type&#61;${entityType} document&#61;${documentId}]`;
    },
    parseCrossReferenceTag(tagValue) {
        const regexp = /\[Ref id&#61;(.*) type&#61;(.*) document&#61;(.*)\]/g;
        const match = regexp.exec(tagValue);

        return _.slice(match, '1');
    },
};

const insertTag = (tag, editor) => {
    const newTag = `[${tag.name}]`;
    editor.insertContent(newTag);
};

export default EditorHelper;
