import { IconButton } from '../../../../../components/Buttons';
import { NumberParam, useQueryParam } from 'use-query-params';
import { ArrowDown, ArrowUp, PlusCircle } from 'react-bootstrap-icons';
import { Button } from 'react-bootstrap';

export function GroupActions({ variant, moveGroup, index, isLast }) {
    const [editVariant, setEditVariant] = useQueryParam('edit', NumberParam);
    const [parentId, setParentId] = useQueryParam('parentId', NumberParam);

    const moveUp = () => {
        moveGroup(variant, index, 'up');
    };

    const moveDown = () => {
        moveGroup(variant, index, 'down');
    };

    const addVariant = () => {
        setEditVariant(0);
        setParentId(variant.id);
    };

    return (
        <>
            <IconButton
                icon={<ArrowUp size={12} />}
                className="ml-2 toggle-visible"
                tooltip="Omhoog verplaatsen"
                onClick={moveUp}
                disabled={index === 0}
            />
            <IconButton
                icon={<ArrowDown size={12} />}
                tooltip="Omlaag verplaatsen"
                onClick={moveDown}
                disabled={isLast}
                className="toggle-visible mr-1"
            />
            <Button
                variant="icon"
                className="btn btn-icon-light toggle-visible px-1 py-0"
                data-uk-tooltip="Variant toevoegen"
                onClick={addVariant}
            >
                <PlusCircle size={12} />
            </Button>
        </>
    );
}
