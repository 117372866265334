import { useDispatch } from 'react-redux';
import { set } from 'features/app/appSlice';

export function useShowSettingsSidebar() {
    const dispatch = useDispatch();

    const showSettingsSidebar = (entity, entityType, landingTab = 'settings') => {
        dispatch(
            set({
                key: 'sidebarContext',
                value: {
                    type: 'sidebarSettings',
                    context: {
                        entity,
                        entityType,
                        landingTab,
                    },
                },
            })
        );
    };

    return [showSettingsSidebar];
}
