import { useGetReimbursementsQuery } from 'features/reimbursements/reimbursements';
import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import HelperFunctions from 'pages/global/HelperFunctions';

export function useGetReimbursements(categoryUri) {
    const sortReimbursements = useMemo(() => {
        const emptyArray = [];

        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (data) => data,
            (data) => (data ? [...data].sort(HelperFunctions.dynamicSort('sortOrder')) : emptyArray),
        );
    }, []);

    return useGetReimbursementsQuery(categoryUri, {
        selectFromResult: (result) => ({
            ...result,
            reimbursements: sortReimbursements(result?.data),
        }),
    });
}
