import Constants from '../../../../../../config/Constants';
import TextBlock from './TextBlock';
import TableBlock from './TableBlock';
import BlockLayout from './BlockLayout';
import { FileBlock } from 'pages/translation/views/translate/document/blocks/FileBlock';

export default function Block({ areaData, block, languageIso, handleTranslationMutation, translationContainer }) {
    const { type } = block;
    const blockProps = { block, languageIso, handleTranslationMutation, translationContainer };

    switch (type) {
        case Constants.blockTypes.text:
            return (
                <TextBlock
                    {...blockProps}
                />
            );

        case Constants.blockTypes.table:
            return (
                <TableBlock
                    {...blockProps}
                />
            );

        case Constants.blockTypes.blockLayout:
            return (
                <BlockLayout
                    {...blockProps}
                    areaData={areaData}
                />
            );

        case Constants.blockTypes.file:
            return (
                <FileBlock
                    {...blockProps}
                />
            );
    }

    return null;
}
