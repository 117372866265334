import { getAuthToken, getRefreshToken, saveTokenData } from '../api/AuthApi';
import { Mutex } from 'async-mutex';
import { BaseQueryApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import getEnv from '../config/Env';
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import _ from 'lodash';

const authApiUrl = getEnv('AUTH_API_URL');
const mutex = new Mutex();

interface TokenData {
    refreshToken: string;
    token: string;
}

function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
    return typeof error === 'object' && error != null && 'status' in error;
}

async function refreshToken(api: BaseQueryApi, extraOptions: any): Promise<TokenData | undefined> {
    const refreshResult = await fetchBaseQuery({
        baseUrl: authApiUrl,
    })(
        {
            url: '/token/refresh',
            method: 'POST',
            body: {
                refresh_token: getRefreshToken(),
            },
        },
        api,
        extraOptions
    );

    return refreshResult?.data as TokenData;
}

const baseQueryWithReauth = async (
    args: string | FetchArgs,
    api: BaseQueryApi,
    extraOptions: any,
    query: BaseQueryFn
) => {
    // wait until the mutex is available without locking it
    await mutex.waitForUnlock();

    let result = await query(args, api, extraOptions);

    if (result.error && isFetchBaseQueryError(result.error)) {
        const errorStatus = result.error.status;
        const requestUrl = _.get(result, 'meta.request.url', '')
        const isLoginUrl = requestUrl.endsWith('/auth-api/login') || requestUrl.endsWith('/auth-api/2fa-login');

        // Only try to re-auth when the return code is 401 (Unauthorized)
        if (errorStatus === 401 && !isLoginUrl) {
            // checking whether the mutex is locked
            if (!mutex.isLocked()) {
                const release = await mutex.acquire();
                try {
                    const refreshResult = await refreshToken(api, extraOptions);

                    if (refreshResult) {
                        // Store (refresh) token in storage
                        await saveTokenData(refreshResult.token, refreshResult.refreshToken);

                        // retry the initial query
                        result = await query(args, api, extraOptions);
                    } else {
                        console.log('refresh failed');
                    }
                } finally {
                    // release must be called once the mutex should be released again.
                    release();
                }
            } else {
                // wait until the mutex is available without locking it
                await mutex.waitForUnlock();
                result = await query(args, api, extraOptions);
            }
        }
    }

    return result;
};

function prepareHeaders(headers: Headers): Headers {
    const token = getAuthToken();

    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
        headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
}

function providesList<R extends { id: string | number }[], T extends string>(
    resultsWithIds: R | undefined,
    tagType: T,
    idProperty: string = '@id'
) {
    return resultsWithIds
        ? [{ type: tagType, id: 'LIST' }, ...resultsWithIds.map((_item: any) => ({ type: tagType, id: _item[idProperty] }))]
        : [{ type: tagType, id: 'LIST' }];
}

export { baseQueryWithReauth, prepareHeaders, providesList };
