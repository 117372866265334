import { useContext, useMemo } from 'react';
import { ImportContext } from '../IpidImport';
import { AreaBlock } from './AreaBlock';
import { Form } from 'react-bootstrap';
import _ from 'lodash';
import { getBlockIdsInLayout } from './Helpers';

export function VariantAreas({ area }) {
    const blocksToRender = useMemo(() => {
        const blockIdsInLayout = getBlockIdsInLayout(area.blocks);

        if (blockIdsInLayout.length === 0) {
            return area.blocks;
        }

        return area.blocks.filter((block) => !blockIdsInLayout.includes(block.key));
    }, [area.blocks]);

    return (
        <>
            {blocksToRender.map((block) => (
                <VariantBlock block={block} area={area} key={`variant-block-${block.key}`} />
            ))}
        </>
    );
}

function VariantBlock({ block, area }) {
    const { formData } = useContext(ImportContext);
    const { variants = [] } = formData;

    return (
        <div className="mr-3" style={{ width: 360, minWidth: 360 }}>
            <div className="d-flex flex-wrap align-items-center">
                {variants.map(({ variant }, index) => (
                    <VariantSelect
                        areaId={area.key}
                        blockKey={block.key}
                        variant={variant}
                        checked={block.variantIds.includes(parseInt(variant[2]))}
                        key={`block-${block.key}-variant-${index}`}
                    />
                ))}
            </div>

            <AreaBlock block={block} area={area} readOnly={false} key={`area-${area.key}-block-${block.key}`} />

            <div className="small text-muted">type: {block.eiopaType}</div>
        </div>
    );
}

function VariantSelect({ areaId, blockKey, variant, checked }) {
    const { toggleBlockVariant } = useContext(ImportContext);

    return (
        <Form.Check
            id={`block-${blockKey}-check-${variant[2]}`}
            type="checkbox"
            label={_.truncate(variant[4], {
                length: 20,
            })}
            className="mr-2 mb-1"
            onChange={() => {
                toggleBlockVariant(parseInt(variant[2]), areaId, blockKey);
            }}
            checked={checked}
        />
    );
}
