import { useContext } from 'react';
import { DateTime } from 'luxon';
import { useDispatch } from 'react-redux';
import { Accordion, AccordionContext, Badge, Card, Dropdown, Row, SplitButton } from 'react-bootstrap';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import Constants from '../../../config/Constants';
import { patchOpEntryLabel } from '../../../features/operationsList/operationsListSlice';
import OpVariant from './OpVariant';
import cx from 'classnames';
import { generatePath, useHistory } from 'react-router-dom';
import { OpTeamEntryStatus, OpTeamResponsible } from '../op_team_entry/OpTeamEntryStatus';
import _ from 'lodash';
import { primaryAnswerColor, primaryQuestionAnswer } from '../op_team_entry/OpTeamTasks';
import HelperFunctions from '../../global/HelperFunctions';
import LoadingSpinner from '../../global/LoadingSpinner';
import { resolveTeamEntryLabelName } from '../op_team_entry/OpTeamEntryLabelName';
import { OpEntryPath } from './OpEntry';
import { VIEW_LABEL_PATH } from '../../../scenes/OperationsList';
import { useSelectedPage } from './View';
import { useTranslation } from 'react-i18next';

function OpEntryLabel({
    labelLut,
    opEntry,
    opList,
    opEntryLabel,
    tasks,
    forceExpanded = false,
    setAddEntryLabelModalData,
    loadPage,
}) {
    const currentEventKey = useContext(AccordionContext);
    const isExpanded = currentEventKey === opEntryLabel.id || forceExpanded === true;
    const created = DateTime.fromISO(opEntry.createdAt);
    const cardBodyClasses = cx('d-flex align-items-center op-list-row-title', {
        'card-deleted': opEntry.deleted,
    });
    const labelName = resolveTeamEntryLabelName(labelLut, opEntryLabel);

    return (
        <div className="mb-3">
            <Card className={opEntry.cancelled ? 'op-list-entry-card-cancelled' : ''}>
                {forceExpanded ? (
                    <>
                        <Card.Body
                            className={cx('d-flex align-items-center', {
                                'card-deleted': opEntry.deleted,
                            })}
                        >
                            <OpEntryLabelRow
                                opEntry={opEntry}
                                opEntryLabel={opEntryLabel}
                                opList={opList}
                                loadPage={loadPage}
                                created={created}
                                isExpanded={isExpanded}
                                labelName={labelName}
                                setAddEntryLabelModalData={setAddEntryLabelModalData}
                            />
                        </Card.Body>
                        <div className="border-top">
                            <OpEntryLabelListExpanded
                                opEntry={opEntry}
                                opEntryLabel={opEntryLabel}
                                opList={opList}
                                tasks={tasks}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <Accordion.Toggle as={Card.Body} className={cardBodyClasses} eventKey={opEntryLabel.id}>
                            <OpEntryLabelRow
                                opEntry={opEntry}
                                opEntryLabel={opEntryLabel}
                                opList={opList}
                                loadPage={loadPage}
                                created={created}
                                isExpanded={isExpanded}
                                labelName={labelName}
                                setAddEntryLabelModalData={setAddEntryLabelModalData}
                            />
                        </Accordion.Toggle>

                        <Accordion.Collapse
                            eventKey={opEntryLabel.id}
                            className={(forceExpanded ? 'show ' : '') + 'border-top'}
                        >
                            <>
                                {isExpanded && (
                                    <OpEntryLabelListExpanded
                                        opEntry={opEntry}
                                        opEntryLabel={opEntryLabel}
                                        opList={opList}
                                        tasks={tasks}
                                    />
                                )}
                            </>
                        </Accordion.Collapse>
                    </>
                )}
            </Card>
        </div>
    );
}

function OpEntryLabelRow({
    opEntry,
    labelName,
    created,
    isExpanded,
    opList,
    opEntryLabel,
    setAddEntryLabelModalData,
    loadPage,
}) {
    const { t } = useTranslation('changelist');

    return (
        <>
            <div style={{ minWidth: 140 }} className="d-flex align-items-center mr-2">
                <div>
                    #{opEntry.entryNumber}
                    {null !== labelName && '-'}
                </div>
                {null !== labelName && <div className="text-uppercase small text-secondary">{labelName}</div>}
            </div>

            <div className="small mr-4">
                <div className="text-muted">{created.toLocaleString(DateTime.DATETIME_MED)}</div>
            </div>

            <div className="font-weight-bold mr-3">
                {opEntry.areaTitle}&nbsp;{isExpanded ? <CaretUpFill /> : <CaretDownFill />}
            </div>

            <div>
                {opEntry.typesOfEdit.map((typeOfEdit, key) => (
                    <Badge
                        variant="light"
                        style={{ fontSize: '0.7rem' }}
                        className="mr-1"
                        key={`opEntry-${opEntry.id}-typeOfEdit-${key}`}
                    >
                        {t('changelist.view.opEntry.typeOfEdits.' + typeOfEdit)}
                    </Badge>
                ))}
            </div>

            <div className="d-flex align-items-center ml-auto">
                <OpTeamEntryStatus opList={opList} opEntry={opEntry} opEntryLabel={opEntryLabel} />
                {resolveActions(opList, opEntry, opEntryLabel, setAddEntryLabelModalData, loadPage)}
            </div>
        </>
    );
}

function resolveActions(opList, opEntry, opEntryLabel, setAddEntryLabelModalData, loadPage) {
    const { t } = useTranslation('changelist');

    switch (opEntry.status) {
        case 'processed':
            return (
                <OpTeamEntryDropdown
                    opList={opList}
                    opEntry={opEntry}
                    opEntryLabel={opEntryLabel}
                    setAddEntryLabelModalData={setAddEntryLabelModalData}
                    loadPage={loadPage}
                />
            );
        case 'failed':
            return (
                <div className="text-danger mr-2" style={{ minWidth: '9em' }}>
                    {t('changelist.view.opEntryLabel.failed')}
                </div>
            );
        case 'pending':
        case 'processing':
        default:
            return (
                <div className="d-flex align-items-end text-success" style={{ flexWrap: 'nowrap' }}>
                    <div className="mr-2">{t('changelist.view.opEntryLabel.processing')}</div>
                    <span style={{ marginBottom: -3 }}>
                        <LoadingSpinner successTheme={true} size="sm" inline={true} />
                    </span>
                </div>
            );
    }
}

function OpEntryLabelListExpanded({ opEntry, opEntryLabel, opList, tasks }) {
    const { areaPath = [] } = opEntry;
    const { t } = useTranslation('changelist');

    return (
        <Card.Body>
            <Row>
                {areaPath.length > 0 && (
                    <div className="w-100 mb-4 px-2">
                        <div className="text-secondary pb-2">{t('changelist.view.opEntryLabel.location')}</div>

                        <OpEntryPath opEntry={opEntry} />
                    </div>
                )}

                <div className="w-100 op-entry-label-processor-container pl-2 mb-4">
                    <div className="text-secondary pb-2">{t('changelist.view.opEntryLabel.handlers')}</div>
                    <OpTeamResponsible opList={opList} opEntryLabel={opEntryLabel} />
                </div>

                <div className="op-entry-label-tasks-container">
                    <div className="text-secondary mx-2 pb-2">{t('changelist.view.opEntryLabel.tasks')}</div>

                    <div className="d-flex flex-wrap">
                        {tasks.map((task, index) => (
                            <OpTeamTaskStatus
                                opEntryLabel={opEntryLabel}
                                task={task}
                                key={`${opEntryLabel.id}-task-${index}`}
                            />
                        ))}
                    </div>
                </div>
            </Row>
        </Card.Body>
    );
}

function OpTeamTaskStatus({ opEntryLabel, task }) {
    if (!task) {
        return null;
    }
    const notDeletedFields = task.fields.filter((field) => !field.deletedAt);
    const color = primaryAnswerColor(notDeletedFields, opEntryLabel);

    return (
        <div className={`op-entry-label-task mx-2`} style={{ backgroundColor: color + '45' }}>
            {task.label}: {primaryQuestionAnswer(notDeletedFields, opEntryLabel)}
        </div>
    );
}

function OpTeamEntryDropdown({ opList, opEntry, opEntryLabel, setAddEntryLabelModalData, loadPage }) {
    const dispatch = useDispatch();
    const selectedPage = useSelectedPage();
    const history = useHistory();
    const { t } = useTranslation('changelist');

    // The teamEntry does have a label which means that the opEntry was split by labels
    const entryIsSplitByLabel = !!opEntryLabel.label;

    const handleSelect = (eventKey) => {
        switch (eventKey) {
            case 'archive':
                HelperFunctions.confirmModal(
                    t('changelist.view.opEntryLabel.archiveChange'),
                    'danger',
                    false,
                    t('changelist.view.opEntryLabel.btn.yesDelete'),
                    t('btn.cancel')
                ).then(() => {
                    dispatch(
                        patchOpEntryLabel({
                            uri: opEntryLabel['@id'],
                            formData: {
                                archived: true,
                            },
                        })
                    ).then(() => loadPage());
                });
                break;
            case 'undoArchive':
                dispatch(
                    patchOpEntryLabel({
                        uri: opEntryLabel['@id'],
                        formData: {
                            archived: false,
                        },
                    })
                ).then(() => loadPage());
                break;
            case 'cancel':
                HelperFunctions.confirmModal(
                    t('changelist.view.opEntryLabel.archiveChange'),
                    'danger',
                    false,
                    t('changelist.view.opEntryLabel.btn.yes'),
                    t('btn.cancel')
                )
                    .then(() => {
                        dispatch(
                            patchOpEntryLabel({
                                uri: opEntryLabel['@id'],
                                formData: {
                                    archived: false,
                                    cancelled: true,
                                },
                            })
                        );
                    })
                    .then(() => loadPage());
                break;
            case 'undoCancel':
                dispatch(
                    patchOpEntryLabel({
                        uri: opEntryLabel['@id'],
                        formData: {
                            cancelled: false,
                        },
                    })
                ).then(() => loadPage());
                break;
            case 'addOpEntryLabel':
                setAddEntryLabelModalData([opEntryLabel, opEntry, loadPage]);
                break;
        }
    };
    const viewPath = generatePath(VIEW_LABEL_PATH, {
        id: opList.id,
        opEntryLabelId: opEntryLabel.id,
        selectedPage,
    });

    return (
        <div
            onClick={(event) => {
                event.stopPropagation();
            }}
        >
            <SplitButton
                menuAlign="right"
                id={'entry-' + opEntryLabel.id + '-dropdown'}
                title="Behandelen"
                onSelect={handleSelect}
                onClick={(e) => {
                    e.preventDefault();
                    history.push(viewPath);
                }}
            >
                {/*Cannot add 'zorgsoort' if entry is not split by label*/}
                {opList.userIsTeamManager && entryIsSplitByLabel && (
                    <Dropdown.Item eventKey="addOpEntryLabel" disabled={opEntry.cancelled}>
                        {t('changelist.view.opEntryLabel.addInsuranceType')}
                    </Dropdown.Item>
                )}
                {!opEntryLabel.archived && (
                    <Dropdown.Item
                        eventKey="archive"
                        disabled={opEntryLabel.status !== Constants.opEntryLabel.status.afgerond}
                    >
                        {t('changelist.view.opEntryLabel.archive')}
                    </Dropdown.Item>
                )}
                {opEntryLabel.archived && (
                    <Dropdown.Item eventKey="undoArchive">
                        {t('changelist.view.opEntryLabel.undoArchiving')}
                    </Dropdown.Item>
                )}
            </SplitButton>
        </div>
    );
}

const showGroup = (documentVariant, opEntryVariants) => {
    return documentVariant.children.some((_variant) => {
        if (_variant.variantGroup) {
            return showGroup(_variant, opEntryVariants);
        }

        const opEntryVariant = _.find(opEntryVariants, (_opEntryVariant) => {
            return _opEntryVariant.variantId === _variant.id;
        });

        // Only show if variant is in the variants op this opEntry, and if that opEntry actually has block to show.
        return opEntryVariant && !_.isEmpty(opEntryVariant.opEntryBlocks);
    });
};

function DocumentVariant({ documentVariant, opEntryVariants, opEntry, opList }) {
    if (documentVariant.variantGroup) {
        if (!showGroup(documentVariant, opEntryVariants)) {
            return null;
        }

        return (
            <div className="mb-3">
                <div className="text-primary font-weight-bold mb-2">{documentVariant.name}</div>

                {documentVariant.children.map((_variant) => (
                    <DocumentVariant
                        documentVariant={_variant}
                        opEntryVariants={opEntryVariants}
                        opEntry={opEntry}
                        opList={opList}
                        key={_variant.id}
                    />
                ))}
            </div>
        );
    }

    return (
        <div className="mb-3">
            {opEntryVariants
                .filter((_opEntryVariant) => _opEntryVariant.variantId === documentVariant.id)
                .map((_opEntryVariant) => {
                    return (
                        <OpVariant
                            opEntryVariant={_opEntryVariant}
                            opEntry={opEntry}
                            opList={opList}
                            key={_opEntryVariant.id}
                        />
                    );
                })}
        </div>
    );
}

export default OpEntryLabel;
