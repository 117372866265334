import { useContext, useEffect, useMemo, useState } from 'react';
import { ImportContext } from '../IpidImport';
import { ipidApi } from 'features/documents/ipidApi';
import { AreaBlock } from './AreaBlock';
import HelperFunctions from '../../../global/HelperFunctions';
import { VariantAreas } from './VariantAreas';
import { SecondaryButton, WarningButton } from 'components/Buttons';
import { IPID_IMPORT_PATH } from 'scenes/DocumentsV2';
import { generatePath, Redirect, useHistory } from 'react-router-dom';
import IconRenderer from '../../../../components/SettingsSidebar/views/AreaIcons/IconRenderer';
import {
    areaHasCustomPrefix,
    cleanDescription,
    cleanTitle,
    generateKey,
    getAreaBlocks,
    getBlockIdsInLayout,
    getCategoryId,
    getChoiceVariantIds,
    getParentArea,
    removeInvisibleSpaces,
} from './Helpers';
import { BooleanParam, useQueryParam, withDefault } from 'use-query-params';
import _ from 'lodash';
import { Form } from 'react-bootstrap';
import { UnmatchedBlocks } from './UnmatchedBlocks';

const SkipParam = withDefault(BooleanParam, false);

export function Card() {
    const { formData } = useContext(ImportContext);
    const { importedVariants = [], document } = formData;
    const [skip] = useQueryParam('skip', SkipParam);

    if (!document) {
        return (
            <Redirect
                to={{
                    pathname: generatePath(IPID_IMPORT_PATH, {
                        view: 'start',
                    }),
                }}
            />
        );
    }

    return (
        <>
            <div className="content-static-body" style={{ bottom: 65 }}>
                <div className="d-flex align-items-stretch h-100">
                    <div className="flex-grow-1 overflow-auto">
                        <div className="p-4">
                            {importedVariants.length > 0 ? (
                                <CardWrapper document={document} importedVariants={importedVariants} skip={skip} />
                            ) : (
                                <div>no data...</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <CardFooter />
        </>
    );
}

function CardWrapper({ document, importedVariants, skip = false }) {
    const { formData, setFormData } = useContext(ImportContext);
    const [isLoading, setLoading] = useState(!skip);
    const [getBlockData] = ipidApi.useLazyGetBlockDataQuery();

    useEffect(() => {
        if (skip) {
            return;
        }

        const getBlockDataPromise = Promise.all(
            importedVariants.map((variant) => {
                return new Promise((resolve) => {
                    getBlockData({
                        id: variant[2],
                        version: variant[3],
                    }).then((result) => {
                        const blockData = result.data
                            ? result.data
                                  // Block type 3 means hidden so we never need it
                                  .filter((block) => block.type !== 3)
                                  .map((block) => {
                                      return {
                                          ...block,
                                          description: cleanDescription(block.description),
                                          descriptionExtra: cleanDescription(block.descriptionExtra),
                                          title: cleanTitle(block.title),
                                      };
                                  })
                            : [];

                        resolve({
                            variant,
                            blockData,
                        });
                    });
                });
            })
        );

        const findLabels = (areaId, labels, labelEntities, areas = []) => {
            const entityLabelEntities = labelEntities
                .filter((labelEntity) => parseInt(labelEntity.entityId) === areaId)
                .map((labelEntity) => labelEntity.label);

            const areaLabel =
                entityLabelEntities.length === 1
                    ? labels.find((label) => label['@id'] === entityLabelEntities[0])
                    : undefined;

            let parentLabel;
            const parentArea = getParentArea(areaId, areas);

            if (parentArea) {
                const parentEntityLabelEntities = labelEntities
                    .filter((labelEntity) => parseInt(labelEntity.entityId) === parentArea.id)
                    .map((labelEntity) => labelEntity.label);

                parentLabel =
                    parentEntityLabelEntities.length === 1
                        ? labels.find((label) => label['@id'] === parentEntityLabelEntities[0])
                        : undefined;
            }

            return [areaLabel, parentLabel];
        };

        const getFreeFormLabel = (categoryId) => {
            if (categoryId === 2) {
                return {
                    names: ['vrijerubriek', 'vrijerubriek-verzekerd', 'speciaal'],
                    types: [0, 1],
                };
            }
            if (categoryId === 3) {
                return {
                    names: ['vrijerubriek', 'vrijerubriek-niet-verzekerd'],
                    types: [4],
                };
            }
            if (categoryId === 4) {
                return {
                    names: ['vrijerubriek', 'vrijerubriek-beperkingen'],
                    types: [4],
                };
            }
        };

        const getFreeFormBlocks = (categoryId, freeFormLabel, variants = []) => {
            const data = [];

            variants.forEach((variant) => {
                const variantBlocks = variant.blockData ?? [];

                const filteredVariantBlocks = variantBlocks.filter(
                    (block) =>
                        block.categoryId === categoryId &&
                        freeFormLabel.names.includes(block.name) &&
                        freeFormLabel.types.includes(block.type)
                );

                if (filteredVariantBlocks.length > 0) {
                    data.push(
                        ...filteredVariantBlocks.map((block) => ({
                            ...block,
                            variantId: parseInt(variant.variant[2]),
                        }))
                    );
                }
            });

            return data.sort(HelperFunctions.dynamicSort('position'));
        };

        Promise.all([getBlockDataPromise]).then((data) => {
            const [variantsWithData] = data;
            const section = document.sections[0];
            const areas = [];

            section?.areas.forEach((area) => {
                if (area.deletedAt !== null) {
                    return;
                }

                const [areaLabel, parentLabel] = findLabels(
                    area.id,
                    document.labels,
                    document.labelEntities,
                    section?.areas ?? []
                );

                if (areaLabel === undefined) {
                    return;
                }

                const categoryId = getCategoryId(areaLabel.name, parentLabel?.name);

                if (area.properties.isTemplate) {
                    // Find all freeform areas
                    const freeFormLabel = getFreeFormLabel(categoryId);

                    if (freeFormLabel) {
                        const freeFormBlocks = getFreeFormBlocks(categoryId, freeFormLabel, variantsWithData);

                        if (freeFormBlocks.length > 0) {
                            // Create unique array from the (lowercase) block title
                            const uniqueFreeFormBlocks = [
                                ...new Map(freeFormBlocks.map((item) => [item.title.toLowerCase(), item])).values(),
                            ];

                            uniqueFreeFormBlocks.forEach((uniqueFreeFormBlock) => {
                                const freeFormArea = {
                                    key: generateKey(areas),
                                    label: areaLabel,
                                    parentLabel,
                                    parentArea: getParentArea(area.id, areas),
                                    categoryId,
                                    title: removeInvisibleSpaces(uniqueFreeFormBlock.title),
                                    freeForm: true,
                                    freeFormLabel,
                                    sortOrder: area.sortOrder,
                                    areaBlocks: area.blocks.map((block) => ({
                                        ...block,
                                        key: block.id,
                                    })),
                                    exportProperties: area.exportProperties,
                                    properties: {
                                        ...area.properties,
                                        parentId: area.id,
                                        isTemplate: false,
                                    },
                                };

                                const blocks = getAreaBlocks(freeFormArea, variantsWithData);

                                areas.push({
                                    ...freeFormArea,
                                    blocks,
                                    choiceVariantIds: getChoiceVariantIds(variantsWithData, blocks),
                                });
                            });
                        }
                    }
                }

                const areaBlocks = areaLabel.name === 'over-deze-kaart' ? [area.blocks[0]] : area.blocks;

                const newArea = {
                    key: generateKey(areas),
                    label: areaLabel,
                    parentLabel,
                    parentArea: getParentArea(area.id, areas),
                    categoryId,
                    title: removeInvisibleSpaces(area.title),
                    freeForm: false,
                    sortOrder: area.sortOrder,
                    areaBlocks: areaBlocks.map((block) => ({
                        ...block,
                        key: block.id,
                    })),
                    exportProperties: area.exportProperties,
                    properties: {
                        ...area.properties,
                        parentId: area.id,
                    },
                };

                const blocks = getAreaBlocks(newArea, variantsWithData);

                areas.push({
                    ...newArea,
                    blocks,
                    choiceVariantIds: getChoiceVariantIds(variantsWithData, blocks),
                });
            });

            setFormData({
                ...formData,
                labels: document.labels,
                labelEntities: document.labelEntities,
                // document,
                variants: variantsWithData,
                areas: areas.map((area, index) => ({
                    ...area,
                    sortOrder: index,
                })),
            });

            setLoading(false);
        });
    }, [skip]);

    if (isLoading) {
        return <div>loading...</div>;
    }

    return <CardInner />;
}

function CardInner() {
    const { formData } = useContext(ImportContext);
    const { document, areas = [] } = formData;

    const filteredAreas = areas.filter((area) => area.properties.isTemplate !== true);

    return (
        <div>
            <div className="font-weight-bold mb-4 pt-2" style={{ fontSize: '1.25rem' }}>
                {document.name}
            </div>

            <UnmatchedBlocks />

            {filteredAreas.map((area, index) => (
                <Area area={area} key={`area-${area.key}-index-${index}`} />
            ))}
        </div>
    );
}

function Area({ area }) {
    const { areaBlocks, categoryId, label, parentLabel } = area;

    const blocksToRender = useMemo(() => {
        const blockIdsInLayout = getBlockIdsInLayout(areaBlocks);

        if (blockIdsInLayout.length === 0) {
            return areaBlocks;
        }

        return areaBlocks.filter((block) => !blockIdsInLayout.includes(block.id));
    }, [areaBlocks]);

    const Icons = area.exportProperties?.areaIcon || [];

    return (
        <>
            {area.exportProperties.headingLevel === '2' && (
                <div className="d-flex align-items-center">
                    <div className="text-muted mr-2 flex-shrink-0">
                        <IconRenderer isInsideTileRow={true} className="text-muted" areaIcons={Icons} />
                    </div>
                    <div className="font-weight-bold mb-2 pt-2" style={{ fontSize: '1.25rem' }}>
                        {area.title}
                    </div>
                </div>
            )}

            <div className="dr-container mb-4 pb-3 pt-4 px-4">
                <div>
                    <div className="d-flex align-items-center">
                        {Icons.length > 0 && (
                            <div className="text-muted mr-1 flex-shrink-0">
                                <IconRenderer isInsideTileRow={true} className="text-muted" areaIcons={Icons} />
                            </div>
                        )}
                        <div className="font-weight-bold">
                            {area.freeForm && <>Vrije rubriek: </>}
                            {area.title}
                        </div>
                    </div>

                    <div className="small text-muted mb-4">
                        <span>{label.name}</span>
                        {parentLabel && <span className="ml-1">({parentLabel.name})</span>}
                        <span className="ml-1">
                            / category_id: {categoryId ?? <span className="text-danger">onbekend</span>}
                        </span>
                    </div>

                    {categoryId && blocksToRender.length > 0 && <AreaBlocks area={area} blocks={blocksToRender} />}
                </div>
            </div>
        </>
    );
}

function AreaBlocks({ area, blocks }) {
    const showChoiceBlocks = areaHasCustomPrefix(area);

    return (
        <div className="d-flex pb-3" style={{ overflowX: 'auto' }}>
            <div className="mr-4" style={{ width: 300, minWidth: 300 }}>
                <div className="d-flex align-items-end small font-weight-bold mb-2" style={{ height: 42 }}>
                    Modeltekst
                </div>
                <div>
                    {blocks.map((block) => (
                        <AreaBlock block={block} area={area} key={`area-${area.key}-parent-block-${block.key}`} />
                    ))}
                </div>

                {showChoiceBlocks && <ChoiceBlock area={area} />}
            </div>

            <VariantAreas area={area} />
        </div>
    );
}

function ChoiceBlock({ area }) {
    const { formData } = useContext(ImportContext);
    const { variants = [] } = formData;

    return (
        <div className="mt-3">
            <div className="small font-weight-bold mb-1">Prefix - Keuze</div>
            <div className="d-flex flex-wrap align-items-center">
                {variants.map(({ variant }, index) => (
                    <ChoiceVariantSelect
                        area={area}
                        variant={variant}
                        checked={area.choiceVariantIds.includes(parseInt(variant[2]))}
                        key={`area-${area.key}-prefix-${index}`}
                    />
                ))}
            </div>
        </div>
    );
}

function ChoiceVariantSelect({ area, variant, checked = false }) {
    const { toggleAreaChoiceVariant } = useContext(ImportContext);
    const variantId = parseInt(variant[2]);

    return (
        <Form.Check
            id={`area-${area.key}-choice-${variant[2]}`}
            type="checkbox"
            label={_.truncate(variant[4], {
                length: 20,
            })}
            className="mr-2 mb-1"
            onChange={() => {
                toggleAreaChoiceVariant(variantId, area.key);
            }}
            checked={checked}
        />
    );
}

function CardFooter() {
    const history = useHistory();

    return (
        <div className="bg-white position-absolute border-top" style={{ right: 0, bottom: 0, left: 0, height: 65 }}>
            <div className="d-flex align-items-center justify-content-end pr-5" style={{ height: 65 }}>
                <SecondaryButton
                    className="mr-2"
                    onClick={() => {
                        HelperFunctions.confirmModal(
                            'Dit venster sluiten? Wijzigingen kunnen verloren gaan.',
                            undefined,
                            false
                        )
                            .then(() => {
                                history.push(
                                    generatePath(IPID_IMPORT_PATH, {
                                        view: 'start',
                                    })
                                );
                            })
                            .catch(() => {});
                    }}
                >
                    Vorige
                </SecondaryButton>
                <WarningButton
                    onClick={() => {
                        history.push(
                            generatePath(IPID_IMPORT_PATH, {
                                view: 'preview',
                            })
                        );
                    }}
                >
                    Volgende
                </WarningButton>
            </div>
        </div>
    );
}
