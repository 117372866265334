import { useFormikContext } from 'formik';
import IconSearch from './AreaIcons/IconSearch';

export default function EditAreaIcon() {
    const { values, setFieldValue } = useFormikContext();
    const areaIcons = values.settings?.exportProperties?.areaIcon || [];

    const handleDeleteIcon = (selectedIconIndex) => {
        const newAreaIcons = [...areaIcons];
        newAreaIcons.splice(selectedIconIndex, 1);
        setFieldValue('settings.exportProperties.areaIcon', newAreaIcons);
    };

    const handleAddIcon = (selectedIcon) => {
        const newAreaIcons = [...areaIcons, selectedIcon];

        setFieldValue('settings.exportProperties.areaIcon', newAreaIcons);
    };

    const handleIconColorChange = (color, index) => {
        const newAreaIcons = [...areaIcons];
        newAreaIcons[index] = { ...newAreaIcons[index], color: color.hex };
        setFieldValue('settings.exportProperties.areaIcon', newAreaIcons);
    };
    


    return (<>
        <div>
            <IconSearch handleIconColorChange={handleIconColorChange} handleAddIcon={handleAddIcon} areaIcons={areaIcons} handleDeleteIcon={handleDeleteIcon} />
        </div>
    </>)
}
