import { FieldArray, useFormikContext } from 'formik';
import { GenericButton, IconButton, LightOutlineButton } from 'components/Buttons';
import { InputField } from 'pages/publications_v2/helpers/FieldHelper';
import { PencilSquare, Plus, Star, StarFill, Trash3 } from 'react-bootstrap-icons';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function EditMetadata({ allowEmptyValues = false, showMetadataForm = false }) {
    const { t } = useTranslation();
    const { values } = useFormikContext();
    const { settings } = values;
    const { metadata = [] } = settings;
    const [showForm, setShowForm] = useState(showMetadataForm);

    return (
        <div>
            <div className="d-flex align-items-end justify-content-between border-bottom pb-2 mb-3">
                <div className="font-weight-bolder">{t('global:settingsSidebar.metadata.title')}</div>

                <LightOutlineButton size="sm" onClick={() => setShowForm(true)} disabled={showForm}>
                    <PencilSquare size={12} />
                    {t('global:settingsSidebar.metadata.btnEdit')}
                </LightOutlineButton>
            </div>

            {showForm ? (
                <FieldArray name="settings.metadata">
                    {({ remove, push }) => (
                        <MetadataForm
                            allowEmptyValues={allowEmptyValues}
                            metadata={metadata}
                            remove={remove}
                            push={() => push({ key: '', value: '' })}
                        />
                    )}
                </FieldArray>
            ) : (
                <div>
                    {metadata.map((item, index) => (
                        <MetadataRow keyName={item.key} value={item.value} key={`meta-row-${index}`} />
                    ))}

                    {metadata.length === 0 && (
                        <div className="text-muted">{t('global:settingsSidebar.metadata.noMetadata')}</div>
                    )}
                </div>
            )}
        </div>
    );
}

function MetadataForm({ allowEmptyValues = false, metadata = [], remove, push }) {
    const { t } = useTranslation();

    useEffect(() => {
        if (metadata.length === 0) {
            push();
        }
    }, []);

    return (
        <div>
            <div className="mb-3">
                {metadata.map((item, index) => (
                    <MetadataFormRow
                        required={!allowEmptyValues}
                        item={item}
                        index={index}
                        remove={() => remove(index)}
                        key={`meta-row-${index}`}
                    />
                ))}
            </div>

            <LightOutlineButton size="sm" onClick={push}>
                <Plus />
                {t('global:settingsSidebar.metadata.btnAddItem')}
            </LightOutlineButton>
        </div>
    );
}

function MetadataFormRow({ item, index, remove, required = true }) {
    const { t } = useTranslation();
    const { setFieldValue } = useFormikContext();
    const showInTitleRow = item.properties?.showInTitleRow === '1';

    return (
        <div className="row align-items-center no-gutters mb-2">
            <div className="col-5">
                <div className="d-flex align-items-center">
                    <GenericButton
                        variant="bookmark"
                        size="sm"
                        className={cx('flex-shrink-0', { active: showInTitleRow })}
                        onClick={toggleShowInTitleRow}
                        tooltip={t('global:settingsSidebar.metadata.bookmarkTooltip')}
                    >
                        {showInTitleRow ? <StarFill /> : <Star />}
                    </GenericButton>
                    <div className="ml-3 mr-2">
                        <InputField
                            name={`settings.metadata[${index}].key`}
                            props={{
                                placeholder: t('global:settingsSidebar.metadata.keyPlaceholder'),
                                maxLength: 40,
                                required: true,
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="col-7">
                <div className="d-flex align-items-center">
                    <div className="mr-3">
                        <InputField
                            name={`settings.metadata[${index}].value`}
                            props={{
                                placeholder: t('global:settingsSidebar.metadata.valuePlaceholder'),
                                maxLength: 500,
                                required,
                            }}
                        />
                    </div>
                    <IconButton size="sm" icon={<Trash3 size={14} className="text-danger" />} onClick={remove} />
                </div>
            </div>
        </div>
    );

    function toggleShowInTitleRow() {
        setFieldValue(`settings.metadata[${index}].properties.showInTitleRow`, showInTitleRow ? '0' : '1');
    }
}

function MetadataRow({ keyName, value }) {
    return (
        <div className="row mb-1">
            <div className="col-4 text-muted">{keyName}</div>
            <div className="col-8 text-body">{value}</div>
        </div>
    );
}
