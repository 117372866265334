import { Route, Switch } from 'react-router-dom';
import { ReactTitle } from '../components/ReactTitle';
import { Admin } from '../pages/admin/Admin';
import RestrictedContent from '../pages/global/RestrictedContent';
import Constants, { Permissions } from '../config/Constants';
import { Account } from '../pages/admin/Account';
import { useUserProfile } from '../hooks/useUserProfile';

export const ADMIN_BASE_PATH = '/admin';
export const ADMIN_SETTINGS_PATH = `${ADMIN_BASE_PATH}/:view/:action?`;
export const ADMIN_SETTING_ACCOUNT_PATH = `${ADMIN_BASE_PATH}/accounts/view/:id/:view?/:action?/:entityId?`;

const AdminScene = () => {
    const userProfile = useUserProfile();
    const isSuperAdmin = userProfile.roles.includes(Constants.userRoles.superAdmin);

    // Extra check
    if (!isSuperAdmin) {
        return null;
    }

    return (
        <RestrictedContent permission={Permissions.Account.Create}>
            <ReactTitle title="Admin" />

            <Switch>
                <Route path={ADMIN_SETTING_ACCOUNT_PATH} exact={true} render={() => <Account />} />
                <Route path={ADMIN_SETTINGS_PATH} render={() => <Admin />} />
            </Switch>
        </RestrictedContent>
    );
};

export { AdminScene };
