import EntityTranslation from '../../EntityTranslation';
import { entityTypes } from '../../../../config/Constants';
import { TranslationContainer } from '../../../../../../models/translation.models';
import { BaseTextBlock } from '../../../../../../models/documents.models';
import { useTranslation } from 'react-i18next';

export default function TextBlock({
    block,
    languageIso,
    handleTranslationMutation,
    translationContainer,
}: {
    block: BaseTextBlock;
    languageIso: string;
    handleTranslationMutation: () => void;
    translationContainer: TranslationContainer;
}) {
    const { t } = useTranslation('translations');

    return (
        <EntityTranslation
            isHtml={true}
            content={block.baseContent}
            languageIso={languageIso}
            entityUri={`/api/${entityTypes.BLOCK}/${block.id}`}
            translationContainer={translationContainer}
            entityType={entityTypes.BLOCK}
            entityId={block.id}
            property="content"
            entity={{
                ...block,
                title: t('translation.navbar.dashboard.translation.textBlock.text'),
            }}
            handleTranslationMutation={handleTranslationMutation}
        />
    );
}
