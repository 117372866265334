import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import LoadingSpinner from 'pages/global/LoadingSpinner';
import Constants from 'config/Constants';
import { useGetDocument } from 'pages/documents_v2/hooks/useGetDocument';
import { documentApi, useUpdateLinkedAreasMutation } from 'features/documents/documents';
import { useTranslation } from 'react-i18next';
import { DocumentSelect } from 'pages/documents_v2/modals/LinkedBlockModal';
import { PrimaryButton, SecondaryButton } from 'components/Buttons';

export function LibraryImportModal({ handleClose, libraryImportData }) {
    const { sectionId } = libraryImportData;
    const [selectedDocumentId, setSelectedDocumentId] = useState();
    const [selectedArea, setSelectedArea] = useState();
    const dispatch = useDispatch();
    const { t } = useTranslation('documents');
    const [updateLinkedAreas] = useUpdateLinkedAreasMutation();

    return (
        <Modal show={libraryImportData !== false} onHide={close}>
            <Modal.Header closeButton>
                <Modal.Title>{t('document.navbar.main.reviewDocument.importLibrary.linkArticle')}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <DocumentSelect onDocumentSelect={onDocumentSelect} />

                {selectedDocumentId && (
                    <SelectArea selectedDocumentId={selectedDocumentId} handleChange={handleChange} />
                )}
            </Modal.Body>

            <Modal.Footer>
                <SecondaryButton onClick={close}>{t('btn.cancel')}</SecondaryButton>
                <PrimaryButton disabled={!selectedArea} onClick={handleSubmit}>
                    {t('document.navbar.main.reviewDocument.importLibrary.btn.addArticle')}
                </PrimaryButton>
            </Modal.Footer>
        </Modal>
    );

    function onDocumentSelect(newDocumentId) {
        if (newDocumentId !== selectedDocumentId) {
            setSelectedDocumentId(newDocumentId);
            setSelectedArea(undefined);
        }
    }

    function close() {
        setSelectedArea(false);
        handleClose();
    }

    function handleChange(selectedOption) {
        setSelectedArea(selectedOption.value);
    }

    function handleSubmit() {
        updateLinkedAreas([
            {
                linkedTo: selectedArea,
                section: sectionId,
            },
        ]).then(() => {
            // Invalidate cache
            dispatch(documentApi.util.invalidateTags([{ type: 'Section', id: sectionId }]));

            close();
        });
    }
}

function SelectArea({ selectedDocumentId, handleChange }) {
    const { t } = useTranslation('documents');
    const document = useGetDocument(selectedDocumentId, true);

    if (!document) {
        return <LoadingSpinner size="small" inline={true} />;
    }

    const sections =
        document?.sections.filter((_item) => _item.deletedAt === null && _item.scheduledForDeletion === false) ?? [];
    const options =
        sections.map((section) => {
            return {
                label: section.title,
                options: section.areas
                    .filter((_item) => _item.deletedAt === null && _item.scheduledForDeletion === false)
                    .map((area) => {
                        return {
                            value: area.id,
                            label: area.title,
                            isDisabled: area.type === Constants.areaTypes.linkedArea,
                        };
                    }),
            };
        }) ?? [];

    return (
        <>
            <label>{t('document.navbar.main.reviewDocument.importLibrary.selectArticleToLink')}:</label>
            <Select options={options} onChange={handleChange} />
        </>
    );
}
