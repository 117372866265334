import { useGetStripeProductQuery } from 'features/security/authApi';
import { StringParam, useQueryParam } from 'use-query-params';

export function useGetStripeProduct() {
    const [pid] = useQueryParam('pid', StringParam);
    const [priceId] = useQueryParam('priceId', StringParam);

    const { product } = useGetStripeProductQuery(
        { pid, priceId },
        {
            selectFromResult: ({ data }) => ({
                product: data,
            }),
            skip: !priceId || !pid,
        },
    );

    return product;
}
