import { Pencil, PlusCircleFill, TrashFill } from 'react-bootstrap-icons';
import cx from 'classnames';
import { Field, FieldArray, Form as FForm, Formik } from 'formik';
import { Button, Form } from 'react-bootstrap';
import Spinner from '../global/Spinner';
import { useUpdateLabelMutation } from '../../features/metadata/metadata';
import { useTranslation } from 'react-i18next';

export default function EditLabelProps({ closeSidebar, label, setEditLabelProps }) {
    const classes = cx('border-left', {
        'is-closed': !label,
    });
    const { t } = useTranslation('labels');

    return (
        <div id="sidebar" className={classes}>
            <div className="p-4">
                <div className="d-flex align-items-start justify-content-between mb-4">
                    <h4>
                        <Pencil /> {t('editLabel.edit')}
                    </h4>
                    <button type="button" className="close" onClick={closeSidebar}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                {label && (
                    <LabelPropForm
                        label={label}
                        setEditLabelProps={setEditLabelProps}
                        closeSidebar={closeSidebar}
                        key={label.id}
                    />
                )}
            </div>
        </div>
    );
}

function LabelPropForm({ label, setEditLabelProps, closeSidebar }) {
    const [updateLabel] = useUpdateLabelMutation();
    const { t } = useTranslation('labels');

    const handleFormSubmit = (values, { setSubmitting }) => {
        updateLabel({
            uri: label['@id'],
            labelProps: values.labelProps.map((_item) => {
                if (_item.hasOwnProperty('@id')) {
                    return {
                        ..._item,
                        id: _item['@id'],
                    };
                }

                return _item;
            }),
        }).then(({ data }) => {
            setSubmitting(false);

            if (data) {
                setEditLabelProps(data);
            }
        });
    };

    return (
        <Formik initialValues={label} onSubmit={handleFormSubmit} enableReinitialize>
            {({ values, isSubmitting, dirty }) => (
                <FForm autoComplete="off">
                    <Form.Group>
                        <FieldArray name="labelProps">
                            {({ remove, push }) => (
                                <div>
                                    {values.labelProps.length > 0 &&
                                        values.labelProps.map((meta, index) => (
                                            <Form.Group key={index}>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <Form.Label htmlFor={`labelProps.${index}.name`} className="mb-0">
                                                        {t('editLabel.keyLabel')}:
                                                    </Form.Label>
                                                    <Button
                                                        variant="link"
                                                        size="sm"
                                                        className="text-muted px-1"
                                                        onClick={() => remove(index)}
                                                    >
                                                        <TrashFill size={14} />
                                                    </Button>
                                                </div>
                                                <Field
                                                    as={Form.Control}
                                                    name={`labelProps.${index}.name`}
                                                    placeholder="Key"
                                                    type="text"
                                                    maxLength={100}
                                                />
                                                <Field
                                                    as={Form.Control}
                                                    name={`labelProps.${index}.value`}
                                                    placeholder="Value"
                                                    type="text"
                                                />
                                            </Form.Group>
                                        ))}

                                    <div className="pt-3">
                                        <Button
                                            variant="link"
                                            className="px-1"
                                            size="sm"
                                            onClick={() => push({ name: '', value: '' })}
                                        >
                                            <PlusCircleFill size={20} />
                                        </Button>
                                        <span className="text-muted small pl-1 pt-1">{t('editLabel.addKeyLabel')}</span>
                                    </div>
                                </div>
                            )}
                        </FieldArray>
                    </Form.Group>

                    <Form.Group>
                        <Button type="submit" disabled={isSubmitting || !dirty}>
                            {t('btn.save')}
                        </Button>

                        <Button variant="link" onClick={() => closeSidebar()}>
                            {t('btn.close')}
                        </Button>

                        {isSubmitting && <Spinner />}
                    </Form.Group>
                </FForm>
            )}
        </Formik>
    );
}
