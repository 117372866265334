import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Field, Form as FForm, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { FormModal, ModalFooter } from '../../global/FormModal';
import _ from 'lodash';
import { addTask, patchTask } from '../../../features/operationsList/taskSlice';
import { useTranslation } from 'react-i18next';

export default function EditTaskModal({ showModal = false, opList, department, task, handleClose }) {
    const editMode = _.has(task, 'id');
    const dispatch = useDispatch();
    const { t } = useTranslation('changelist');

    const handleSubmit = (values, { setSubmitting }) => {
        const formData = {
            ...values,
            opDepartment: department['@id'],
        };

        return Promise.resolve()
            .then(() => {
                // Patch
                if (editMode) {
                    return dispatch(patchTask({ uri: task['@id'], formData }));
                }

                // Create
                return dispatch(addTask(formData));
            })
            .then((data) => {
                setSubmitting(false);
                handleClose(data);
            });
    };

    return (
        <FormModal
            show={showModal}
            onHide={() => handleClose()}
            title={editMode ? t('changelist.task.editModal.editTask') : t('changelist.task.editModal.createTask')}
        >
            <Formik
                initialValues={{
                    label: _.get(task, 'label', ''),
                    opList: opList['@id'],
                }}
                validationSchema={EditTaskSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, touched, errors, setFieldValue, isValid, values, dirty }) => (
                    <FForm autoComplete="off">
                        <Modal.Body>
                            <Form.Group>
                                <Form.Label htmlFor="name">{t('changelist.task.editModal.name')}*</Form.Label>
                                <Field
                                    id="label"
                                    name="label"
                                    as={Form.Control}
                                    isInvalid={touched['label'] && errors['label']}
                                    isValid={touched['label'] && !errors['label']}
                                />
                            </Form.Group>
                        </Modal.Body>

                        <ModalFooter
                            isSubmitting={isSubmitting}
                            isValid={isValid}
                            dirty={dirty}
                            onHide={() => handleClose()}
                        />
                    </FForm>
                )}
            </Formik>
        </FormModal>
    );
}

const EditTaskSchema = Yup.object().shape({
    label: Yup.string().required(),
    opList: Yup.string().required(),
});
