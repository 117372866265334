import { useEditUserGroupMutation } from 'features/publications/publicationApi';
import { useTranslation } from 'react-i18next';
import { useGetPublicationGroup, useGetPublicationUsers } from 'pages/publications_v2/hooks/publicationUsers';
import { Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import { NumberParam, useQueryParam } from 'use-query-params';
import { GroupForm } from 'pages/publicationUsers/modals/GroupForm';
import * as Yup from 'yup';

export function EditGroupModal() {
    const [egid] = useQueryParam('egid', NumberParam);

    if (!egid) {
        return null;
    }

    return <EditGroup id={egid} />;
}

function EditGroup({ id }) {
    const { t } = useTranslation();
    const group = useGetPublicationGroup(id);
    const [, setEditGroupId] = useQueryParam('egid', NumberParam);
    const [editUserGroup] = useEditUserGroupMutation();
    const publicationUsers = useGetPublicationUsers();

    if (!group) {
        return null;
    }

    const publicationUserOptions = publicationUsers.map((user) => ({
        label: `${user.name} ${user.surname}`,
        email: user.email,
        value: user['@id'],
    }));

    const defaultpublicationUserOptions = publicationUserOptions.filter((option) => {
        return group.publicationUsers?.includes(option.value);
    });

    const initialValues = {
        name: group.name || '',
        publicationUsers: defaultpublicationUserOptions,
        allowedEmailDomains:
            group.allowedEmailDomains?.map((domain) => ({
                label: domain,
                value: domain,
            })) || [],
    };

    return (
        <Modal show={true} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('publications:accessControl.modal.editGrp')}</Modal.Title>
            </Modal.Header>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                <GroupForm handleClose={handleClose} />
            </Formik>
        </Modal>
    );

    function handleSubmit(values) {
        const formData = {
            ...values,
            publicationUsers: values.publicationUsers.map((option) => option.value),
            allowedEmailDomains: values.allowedEmailDomains.map((option) => option.value),
        };

        editUserGroup({ uri: group['@id'], body: formData })
            .then(() => {
                handleClose();
            })
            .catch((error) => {
                console.error('Error updating group:', error);
            });
    }

    function handleClose() {
        setEditGroupId(undefined);
    }
}

const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
});
