import { useGetReimbursementProductsQuery } from 'features/reimbursements/reimbursements';
import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import Constants from 'config/Constants';
import { idToUri } from 'pages/global/UriHelper';

export function useGetReimbursementCoverage(id, selectedProductUris = [], productUri) {
    const reimbursementUri = idToUri(id, 'Reimbursement');

    const selectCoverage = useMemo(() => {
        const emptyArray = [];

        return createSelector(
            (data) => data,
            (data) => {
                return data
                    ? selectedProductUris.map((uri) => {
                          const reimbursementProduct = data.find(
                              (reimbursementProduct) =>
                                  reimbursementProduct.product === productUri &&
                                  reimbursementProduct.selectedProduct === uri,
                          );

                          if (reimbursementProduct) {
                              return reimbursementProduct;
                          }

                          // Return dummy object
                          const dummy = {
                              coverage: Constants.coverage['n/a'],
                              product: productUri,
                              selectedProduct: uri,
                              reimbursement: reimbursementUri,
                          };

                          return dummy;
                      })
                    : emptyArray;
            },
        );
    }, []);

    const { coverage } = useGetReimbursementProductsQuery(reimbursementUri, {
        selectFromResult: (result) => {
            return {
                coverage: selectCoverage(result.data),
            };
        },
    });

    return coverage;
}
