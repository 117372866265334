import Constants from '../../../../../config/Constants';
import {
    BoxArrowUpRight,
    ClockHistory,
    ExclamationTriangleFill,
    FileEarmarkBreak,
    Grid,
    Link45deg,
    Magic,
    TagsFill,
    Trash,
    UiChecksGrid,
} from 'react-bootstrap-icons';
import { set } from '../../../../../features/app/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import RestrictedContent from '../../../../global/RestrictedContent';
import { useGetDocument } from '../../../hooks/useGetDocument';
import _ from 'lodash';
import { useGetLabelEntitiesQuery } from '../../../../../features/metadata/metadata';
import { useParams } from 'react-router-dom';
import { entityTypes } from '../../../../translation/config/Constants';
import { idToUri } from '../../../../global/UriHelper';
import { useEntityTemplateParameters } from '../../../hooks/useEntityTemplateParameters';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAreaBlocks } from '../../../hooks/useGetAreaBlocks';
import isEqual from 'react-fast-compare';
import { useFormikContext } from 'formik';
import { getBlockData, getBlocksFromData } from '../../../../global/BlockLayoutHelper';

const BlockActions = memo(({ block, parentBlock, index, isInsideBlockLayout }) => {
    const { t } = useTranslation('documents');
    const dispatch = useDispatch();
    const { values, setFieldValue } = useFormikContext();
    const document = useGetDocument();
    const restrictions = useEntityTemplateParameters(block, 'block', document);
    const { activeOrganisation } = useSelector((state) => state.security);
    const { documentId, areaId } = useParams();
    const areaData = useGetAreaBlocks();

    const documentVariantsFromArea = areaData?.area?.documentVariants ?? [];
    const hasVariants = block.documentVariants.length > 1;
    const areaIsTemplate = areaData?.area?.properties?.isTemplate ?? false;
    const documentIsTemplate = document.type === Constants.documentType.model;
    const documentIsLibrary = document.type === Constants.documentType.library;

    const blockHasAllVariants = useMemo(() => {
        return documentVariantsFromArea.length <= block.documentVariants.length;
    }, [block.documentVariants, documentVariantsFromArea]);

    const showPreviousVersions =
        block.id && _.isNumber(document.clonedFromId) && block.type === Constants.blockTypes.text;

    const { entityLabelEntities } = useGetLabelEntitiesQuery(
        {
            entityType: entityTypes.BLOCK,
            organisationId: activeOrganisation,
            documentId: parseInt(documentId),
        },
        {
            selectFromResult: ({ data }) => ({
                entityLabelEntities: data
                    ? data.filter((labelEntity) => parseInt(labelEntity.entityId) === block.id)
                    : [],
            }),
        }
    );

    const hasLinkedBlocks = block.hasOwnProperty('linkedBlocks') && block.linkedBlocks.length > 0;
    const hasLabels = entityLabelEntities.length > 0;
    const forceBreak = useMemo(() => {
        if (block) {
            const hasPropertiesForceNewPage =
                block.properties.forceNewPage &&
                block.properties.forceNewPage === true;

            const hasPropertiesForceNewPageVariants =
                block.properties.forceNewPageVariants !== undefined &&
                block.properties.forceNewPageVariants.length > 0;

            return hasPropertiesForceNewPage || hasPropertiesForceNewPageVariants;
        }
        return false
    }, [block]);

    const canDeleteBlock = restrictions?.canDeleteBlock && !hasLinkedBlocks;

    const showVariantsIcon = useMemo(() => {
        if (!isInsideBlockLayout || !parentBlock || !blockHasAllVariants) {
            return false;
        }

        if (parentBlock.properties.type === 'textExplanation' && block.properties.textLayout === 'default') {
            return false;
        }

        return true;
    }, [isInsideBlockLayout, parentBlock, block]);

    const showVariantsActionIcon = useMemo(() => {
        if (
            parentBlock &&
            parentBlock.properties.type === 'textExplanation' &&
            block.properties.textLayout === 'default'
        ) {
            return false;
        }

        return true;
    }, [isInsideBlockLayout, parentBlock, block]);

    const handleSettingsIconClick = (landingTab) => {
        dispatch(
            set({
                key: 'sidebarContext',
                value: {
                    type: 'sidebarSettings',
                    context: {
                        entity: block,
                        entityType: entityTypes.BLOCK,
                        documentId: parseInt(documentId),
                        areaId: parseInt(areaId),
                        uri: idToUri(block.id, entityTypes.BLOCK),
                        parentUri: idToUri(parseInt(documentId), entityTypes.DOCUMENT),
                        landingTab,
                    },
                },
            })
        );
    };

    const handleDeleteIconClick = (e) => {
        e.preventDefault();

        if (block.id) {
            if (block.type === Constants.blockTypes.blockLayout) {
                // Also delete child blocks if block is blockLayout
                const data = getBlockData(block);
                const childBlocks = getBlocksFromData(data, values.areaBlocks);
                const blockIds = childBlocks.map((_block) => _block.key);
                values.areaBlocks.forEach((block, blockIndex) => {
                    if (blockIds.includes(block.key)) {
                        setFieldValue(`areaBlocks.${blockIndex}.deleted`, true);
                    }
                });

                setFieldValue(`areaBlocks.${index}.deleted`, true);
            } else {
                setFieldValue(`areaBlocks.${index}.deleted`, true);
            }
        } else {
            // Block is new, just delete it
            if (block.type === Constants.blockTypes.blockLayout) {
                // Also delete child blocks if block is blockLayout
                const data = getBlockData(block);
                const childBlocks = getBlocksFromData(data, values.areaBlocks);
                const blockIds = childBlocks.map((_block) => _block.key);

                setFieldValue(
                    'areaBlocks',
                    values.areaBlocks.filter((_block) => _block.key !== block.key && !blockIds.includes(_block.key))
                );
            } else {
                setFieldValue(
                    'areaBlocks',
                    values.areaBlocks.filter((_block) => _block.key !== block.key)
                );
            }
        }
    };

    return (
        <div className="block-edit-buttons uk-button-group uk-button-group-small pr-1">
            {hasLinkedBlocks && (
                <button
                    type="button"
                    data-uk-tooltip={t('document.navbar.main.editor.left.blocks.tooltips.viewLinkedArticles')}
                    className="uk-button uk-button-small uk-button-icon uk-cursor-pointer"
                    onClick={(e) => {
                        e.preventDefault();

                        dispatch(
                            set({
                                key: 'blockEditModal',
                                value: {
                                    eventKey: 'showLinkedBlockModal',
                                    block,
                                    blockIndex: index,
                                },
                            })
                        );
                    }}
                >
                    <span className="uk-icon text-warning">
                        <BoxArrowUpRight size={15} />
                    </span>
                </button>
            )}

            {showVariantsIcon && (
                <button
                    type="button"
                    data-uk-tooltip={t('document.navbar.main.editor.left.blocks.tooltips.variants')}
                    className="uk-button uk-button-small uk-button-icon uk-cursor-pointer btn-edit-variants"
                    onClick={() => handleSettingsIconClick('variants')}
                >
                    <span className="uk-icon text-danger">
                        <UiChecksGrid size={15} />
                    </span>
                </button>
            )}

            {forceBreak && (
                <button
                    type="button"
                    data-uk-tooltip={t('document.navbar.main.editor.left.blocks.tooltips.forcedBlock')}
                    className="uk-button uk-button-small uk-button-icon uk-cursor-pointer"
                    onClick={() => handleSettingsIconClick()}
                >
                    <span className="uk-icon">
                        <FileEarmarkBreak size={15} />
                    </span>
                </button>
            )}

            {hasLabels && (
                <button
                    type="button"
                    data-uk-tooltip={t('document.navbar.main.editor.left.blocks.tooltips.labels')}
                    className="uk-button uk-button-small uk-button-icon uk-cursor-pointer"
                    onClick={() => handleSettingsIconClick('labels')}
                >
                    <span className="uk-icon">
                        <TagsFill size={15} />
                    </span>
                </button>
            )}

            {block.type === Constants.blockTypes.linked && (
                <button
                    type="button"
                    data-uk-tooltip={t('document.navbar.main.editor.left.blocks.tooltips.editLinkedBlock')}
                    className="uk-button uk-button-small uk-button-icon uk-cursor-pointer"
                    onClick={(e) => {
                        e.preventDefault();

                        dispatch(
                            set({
                                key: 'blockEditModal',
                                value: {
                                    eventKey: 'linkedBlockModal',
                                    block,
                                    blockIndex: index,
                                },
                            })
                        );
                    }}
                >
                    <span className="linked-icon-2">
                        <Link45deg size={24} />
                    </span>
                </button>
            )}

            {showPreviousVersions && (
                <button
                    type="button"
                    data-uk-tooltip={t('document.navbar.main.editor.left.blocks.tooltips.blockFromPreviewsVersion')}
                    className="uk-button uk-button-small uk-button-icon uk-cursor-pointer"
                    onClick={(e) => {
                        e.preventDefault();

                        dispatch(
                            set({
                                key: 'blockEditModal',
                                value: {
                                    eventKey: 'previousBlockVersions',
                                    block,
                                    blockIndex: index,
                                },
                            })
                        );
                    }}
                >
                    <span className="uk-icon">
                        <ClockHistory size={15} />
                    </span>
                </button>
            )}

            {document.properties.allowAI && (
                <RestrictedContent module={Constants.modules.openai}>
                    {block.type === Constants.blockTypes.text && (
                        <button
                            type="button"
                            data-uk-tooltip={t('document.navbar.main.editor.left.blocks.tooltips.improveText')}
                            className="uk-button uk-button-small uk-button-icon uk-cursor-pointer"
                            onClick={(e) => {
                                e.preventDefault();

                                dispatch(
                                    set({
                                        key: 'blockEditModal',
                                        value: {
                                            eventKey: 'blockImprovementModal',
                                            block,
                                            blockIndex: index,
                                        },
                                    })
                                );
                            }}
                        >
                            <span className="uk-icon">
                                <Magic size={15} />
                            </span>
                        </button>
                    )}
                </RestrictedContent>
            )}

            {showVariantsActionIcon && !hasVariants && !areaIsTemplate && !documentIsTemplate && !documentIsLibrary && (
                <button
                    type="button"
                    data-uk-tooltip={t('document.navbar.main.editor.left.blocks.tooltips.noVariants')}
                    className="uk-button uk-button-small uk-button-icon uk-cursor-pointer btn-edit-variants btn-edit-variants-warning btn-edit-variants-warning-icon"
                    onClick={() => handleSettingsIconClick('variants')}
                >
                    <span className="uk-icon">
                        <Grid size={15} />
                    </span>

                    <ExclamationTriangleFill size={16} className="triangle-icon" />
                </button>
            )}

            {showVariantsActionIcon && !blockHasAllVariants && hasVariants && !areaIsTemplate && !documentIsTemplate && (
                <button
                    type="button"
                    data-uk-tooltip={t('document.navbar.main.editor.left.blocks.tooltips.customVariants')}
                    className="uk-button uk-button-small uk-button-icon uk-cursor-pointer btn-edit-variants btn-edit-variants-warning"
                    onClick={() => handleSettingsIconClick('variants')}
                >
                    <span className="uk-icon">
                        <UiChecksGrid size={15} />
                    </span>
                </button>
            )}

            {canDeleteBlock && (
                <button
                    type="button"
                    data-uk-tooltip={t('document.navbar.main.editor.left.blocks.tooltips.delete')}
                    className="uk-button uk-button-small uk-button-icon uk-cursor-pointer btn-delete-block"
                    onClick={handleDeleteIconClick}
                >
                    <span className="uk-icon text-danger">
                        <Trash size={15} />
                    </span>
                </button>
            )}
        </div>
    );
}, isEqual);

export default BlockActions;
