import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import HelperFunctions from 'pages/global/HelperFunctions';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { useGetRejectionReasonsQuery } from 'features/comments/commentApi';

/**
 * @returns {RejectionReason[]}
 */
export function useGetRejectionReasons(teamUri) {
    const organisationId = useActiveOrganisation();

    const selectResults = useMemo(() => {
        const emptyArray = [];

        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (res) => res.data,
            (res, organisationId) => organisationId,
            (data, organisationId) =>
                data
                    ? data
                          .filter((item) => {
                              if (item.organisationId !== organisationId) {
                                  return false;
                              }

                              // Filter on team uri?
                              if (!teamUri) {
                                  return true;
                              }

                              return item.teams.includes(teamUri);
                          })
                          .sort(HelperFunctions.dynamicSort('name'))
                    : emptyArray,
        );
    }, []);

    const { rejectionReasons } = useGetRejectionReasonsQuery(undefined, {
        selectFromResult: (result) => ({
            rejectionReasons: selectResults(result, organisationId),
        }),
    });

    return rejectionReasons;
}
