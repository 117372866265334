import { useGetProductsQuery } from 'features/reimbursements/reimbursements';
import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import { Product } from 'models/reimbursement.models';

export function useGetProducts(categoryId: number, categoryUri: string) {
    const selectTopLevelProducts = useMemo(() => {
        const emptyArray: Product[] = [];

        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (data: Product[] | undefined) => data,
            (data: Product[] | undefined, categoryUri: string) => categoryUri,
            (data, categoryUri) =>
                data
                    ? data
                          .filter((product: Product) => product.enabled && product.category === categoryUri)
                          .filter((product: Product) => product.parent === null)
                    : emptyArray,
        );
    }, []);

    return useGetProductsQuery(categoryId, {
        selectFromResult: (result) => ({
            ...result,
            products: selectTopLevelProducts(result.data, categoryUri),
        }),
    });
}
