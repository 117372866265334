import { Form, Modal } from 'react-bootstrap';
import { Field, Form as FForm, Formik, FormikContext } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { FormModal, ModalFooter } from '../global/FormModal';
import { InputMultipleSelect } from '../publications_v2/helpers/FieldHelper';
import Constants from '../../config/Constants';
import { useContext } from 'react';
import {
    useAddLabelFolderMutation,
    useUpdateLabelFolderMutation,
    useUpdateLabelMutation,
} from '../../features/metadata/metadata';
import { useTranslation } from 'react-i18next';

export default function EditModal(props) {
    const { entity, showModal = false } = props;
    const isEditMode = entity.hasOwnProperty('id');
    const { activeOrganisation } = useSelector((state) => state.security);
    const [updateLabel] = useUpdateLabelMutation();
    const [addLabelFolder] = useAddLabelFolderMutation();
    const [updateLabelFolder] = useUpdateLabelFolderMutation();
    const { t } = useTranslation('labels');

    const handleSubmit = (values, { setSubmitting }) => {
        if (entity['@type'] === 'LabelFolder') {
            if (isEditMode) {
                updateLabelFolder({ uri: entity['@id'], ...values }).then(() => {
                    setSubmitting(false);
                    props.handleClose();
                });
            } else {
                addLabelFolder(values).then(() => {
                    setSubmitting(false);
                    props.handleClose();
                });
            }
        } else if (entity['@type'] === 'Label') {
            updateLabel({ uri: entity['@id'], ...values }).then(() => {
                setSubmitting(false);
                props.handleClose();
            });
        }
    };

    return (
        <FormModal
            show={showModal}
            onHide={props.handleClose}
            title={isEditMode ? t('editModal.editName') : t('editModal.createNewFolder')}
        >
            <Formik
                initialValues={{
                    name: entity?.name ?? '',
                    contentTypes: entity?.contentTypes ?? [],
                    organisationId: entity?.organisationId ?? activeOrganisation,
                }}
                validationSchema={EditLabelSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched, isValid, dirty }) => (
                    <FForm autoComplete="off">
                        <Modal.Body>
                            <Form.Group>
                                <Field
                                    as={Form.Control}
                                    name="name"
                                    placeholder={t('editModal.labelName')}
                                    isInvalid={errors.name && touched.name}
                                    isValid={!errors.name && touched.name}
                                />
                            </Form.Group>

                            {entity['@type'] === 'LabelFolder' && <ContentTypes />}
                        </Modal.Body>

                        <ModalFooter
                            isSubmitting={isSubmitting}
                            onHide={props.handleClose}
                            isValid={isValid}
                            dirty={dirty}
                        />
                    </FForm>
                )}
            </Formik>
        </FormModal>
    );
}

function ContentTypes() {
    const { setFieldValue, values } = useContext(FormikContext);
    const { t } = useTranslation('labels');
    const contentTypes = Object.keys(Constants.labelFolderContentTypes).map((_key) => {
        return {
            value: _key,
            label: Constants.labelFolderContentTypes[_key],
        };
    });

    return (
        <Form.Group controlId="contentTypes">
            <Form.Label>{t('editModal.applications')}</Form.Label>
            <InputMultipleSelect
                name="contentTypes"
                options={contentTypes}
                defaultValue={contentTypes.filter((_type) => values.contentTypes.includes(_type.value))}
                props={{
                    placeholder: `${t('editModal.titleSelect')}...`,
                }}
                onChange={(selection) => {
                    setFieldValue(
                        'contentTypes',
                        selection.map((_item) => _item.value)
                    );
                }}
            />
        </Form.Group>
    );
}

const EditLabelSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Required'),
});
