import { useDeleteTeamRuleMutation, useUpdateTeamMutation } from 'features/comments/commentApi';
import { Form as FForm, Formik } from 'formik';
import HelperFunctions from 'pages/global/HelperFunctions';
import { useGetTeamRules } from 'pages/task_team/hooks/useGetTeamRules';
import { NewItemButton } from 'components/Buttons';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';
import { AddTeamRuleModal } from 'pages/task_team/modals/AddTeamRuleModal';
import { PaginatedTable, TableProvider } from 'components/PaginatedTable';
import { Badge } from 'react-bootstrap';
import { DeleteButton, EditButton } from 'components/ActionButtons';
import { useTranslation } from 'react-i18next';
import { EditTeamRuleModal } from 'pages/task_team/modals/EditTeamRuleModal';

export function TeamRules({ team }) {
    const teamRules = useGetTeamRules(team.id);
    const [updateTeam] = useUpdateTeamMutation();
    const [, setAddTeamRule] = useQueryParam('addTeamRule', BooleanParam);

    return (
        <TableProvider>
            <AddTeamRuleModal team={team} />
            <EditTeamRuleModal team={team} />

            <div className="subheader">
                <h3>Onderwerp/doelgroepen</h3>

                <div className="d-flex align-items-center ml-auto">
                    <NewItemButton size="sm" label="Regel toevoegen" onClick={() => setAddTeamRule(true)} />
                </div>
            </div>

            <PaginatedTable items={teamRules} renderSearch={false} colSpan={4}>
                {({ items = [] }) => (
                    <>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Onderwerp</th>
                                <th>Doelgroep(en)</th>
                                <th>Acties</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((teamRule, index) => (
                                <TeamRuleRow teamRule={teamRule} index={index} key={`teamRule-${teamRule.id}`} />
                            ))}
                        </tbody>
                    </>
                )}
            </PaginatedTable>

            <div>
                <Formik enableReinitialize initialValues={team} onSubmit={handleSubmit}>
                    <FForm>
                        <div className="sidebar-form"></div>
                    </FForm>
                </Formik>
            </div>
        </TableProvider>
    );

    function handleSubmit(values, { setSubmitting }) {
        updateTeam({ uri: team['@id'], body: values }).then(() => {
            setSubmitting(false);
        });
    }
}

function TeamRuleRow({ teamRule, index }) {
    const { t } = useTranslation();
    const [, setEditTeamRule] = useQueryParam('editTeamRule', StringParam);
    const [deleteTeamRule] = useDeleteTeamRuleMutation()

    return (
        <tr>
            <td>#{index + 1}</td>
            <td>
                <Badge pill variant="primary">
                    {teamRule.topic.name}
                </Badge>
            </td>
            <td>
                {teamRule.targetAudiences.map((badge, index) => (
                    <Badge pill variant="warning" key={`teamRule-${teamRule.id}-badge-${index}`}>
                        {badge.name}
                    </Badge>
                ))}
            </td>
            <td className="actions">
                <div>
                    <EditButton onClick={() => setEditTeamRule(teamRule.id)} />
                    <DeleteButton onClick={handleDelete} />
                </div>
            </td>
        </tr>
    );

    function handleDelete() {
        HelperFunctions.confirmModal(
            `Regel #${index+1} verwijderen?`,
            'danger',
            false,
            t('global:confirm.yesDelete'),
            t('global:btn.cancel'),
        )
            .then(() => {
                deleteTeamRule(teamRule['@id']);
            })
            .catch(() => {});
    }
}
