import { useGetTaskVersion } from 'pages/task_team/hooks/useGetTaskVersion';
import { useGetTeams } from 'pages/task_team/hooks/useGetTeams';
import { TaskAssignmentStatusBadge } from 'pages/task_team/views/checks/Reviews';
import { useGetUserFullName } from 'hooks/useGetUserFullName';
import { GenericButton } from 'components/Buttons';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';
import { useGetRejectionReasons } from 'pages/task_team/hooks/useGetRejectionReasons';
import { Alert, Badge } from 'react-bootstrap';
import { PersonAdd, PersonCircle, PersonX } from 'react-bootstrap-icons';
import { taskStatus, taskTeamAssignmentStatus, taskVersionStatus } from 'pages/task_team/config/constants';
import { useCanAssignReviewerPermission } from 'pages/task_team/hooks/permissions';
import { useGetTeamUser } from 'pages/task_team/hooks/useGetTeamUser';
import { useGetManagementDecision } from 'pages/task_team/hooks/useGetManagementDecision';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { TaskFile } from 'pages/task_team/modals/view_task_modal/PreviewFile';
import { useUserProfile } from 'hooks/useUserProfile';
import cx from 'classnames';
import { useUpdateTaskTeamAssignmentMutation } from 'features/comments/commentApi';
import { ViewAssignment } from 'pages/task_team/modals/view_task_modal/ViewAssignment';

export function TaskVersions({ task, selectedVersion }) {
    const userProfile = useUserProfile();
    const version = useGetTaskVersion(_.get(selectedVersion, '@id'));
    const canAssignReviewerPermission = useCanAssignReviewerPermission();

    if (!version) {
        return null;
    }

    const { taskTeamAssignments = [], managementDecision } = version;
    const assignment = taskTeamAssignments.find(
        (taskTeamAssignment) => taskTeamAssignment.assignedTo && taskTeamAssignment.assignedTo === userProfile.userId,
    );

    return (
        <>
            {assignment && (
                <ViewAssignment
                    canEdit={task.status === taskStatus.STATUS_REVIEW_IN_PROGRESS}
                    assignment={assignment}
                />
            )}

            {managementDecision && <ManagementDecision uri={managementDecision} />}

            {version.status !== taskVersionStatus.STATUS_DRAFT && (
                <div className="card oke-card mb-4">
                    <div className="card-header">Beoordelingen</div>
                    <div className="card-body pb-0 pt-2 px-2">
                        <div className="table-responsive">
                            <table className="table dr-table">
                                <thead>
                                    <tr>
                                        <th>Beoordelaar</th>
                                        <th>Status</th>
                                        <th>Reden afwijzing</th>
                                        {canAssignReviewerPermission && <th>Acties</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {taskTeamAssignments.map((_assignment) => (
                                        <AssignmentRow
                                            task={task}
                                            assignment={_assignment}
                                            showActions={canAssignReviewerPermission}
                                            key={`task-row-${_assignment.id}`}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

function ManagementDecision({ uri }) {
    const managementDecision = useGetManagementDecision(uri);

    if (!managementDecision) {
        return null;
    }

    return <ManagementDecisionContent managementDecision={managementDecision} />;
}

function ManagementDecisionContent({ managementDecision }) {
    const { t, i18n } = useTranslation();
    const createdByName = useGetUserFullName(managementDecision.createdBy, false);

    const createdAt = DateTime.fromISO(managementDecision.createdAt)
        .setLocale(i18n.language)
        .toLocaleString(DateTime.DATETIME_SHORT);

    return (
        <div className="card oke-card oke-card-danger mb-4">
            <div className="card-header">Goedgekeurd d.m.v. directiebesluit</div>

            <div className="card-body">
                <Alert variant="secondary">
                    Uiting is goedgekeurd d.m.v. directiebesluit door <strong>{createdByName}</strong> op{' '}
                    <strong>{createdAt}</strong>.
                </Alert>

                {_.isEmpty(managementDecision.description) === false && (
                    <div className="mb-3">
                        <div className="font-weight-bold mb-2">Toelichting</div>
                        <div>{managementDecision.description}</div>
                    </div>
                )}

                <div className="mb-3">
                    <div className="font-weight-bold mb-2">Bestand(en)</div>

                    {managementDecision.files.map((file) => (
                        <div className="mb-3" key={`file-${file.id}`}>
                            <TaskFile file={file} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

function AssignmentRow({ task, assignment, showActions = false }) {
    const { i18n } = useTranslation();
    const userProfile = useUserProfile();
    const teams = useGetTeams();
    const assignedTeam = teams.find((team) => team['@id'] === assignment.team);
    const teamUser = useGetTeamUser(assignedTeam?.id);
    const [, setAssignUser] = useQueryParam('assignTask', StringParam);
    const [updateTaskTeamAssignment] = useUpdateTaskTeamAssignmentMutation();

    const canAssignReviewer =
        (task.confidential === false || teamUser?.permissions?.canViewConfidentialTasks === true) &&
        teamUser?.permissions?.canAssignReviewer === true &&
        [taskTeamAssignmentStatus.STATUS_UNASSIGNED, taskTeamAssignmentStatus.STATUS_REVIEW_IN_PROGRESS].includes(
            assignment.status,
        );

    const reviewedAt =
        [taskTeamAssignmentStatus.STATUS_APPROVED, taskTeamAssignmentStatus.STATUS_REJECTED].includes(
            assignment.status,
        ) && assignment.reviewedAt
            ? DateTime.fromISO(assignment.reviewedAt).setLocale(i18n.language).toLocaleString(DateTime.DATETIME_SHORT)
            : undefined;

    const isAssignedToCurrentUser = assignment.assignedTo === userProfile.userId;

    return (
        <tr className={cx({ 'bg-light': isAssignedToCurrentUser })}>
            <td>
                <div className="flex-center font-weight-bold">
                    {isAssignedToCurrentUser && (
                        <PersonCircle data-uk-tooltip={'Toegewezen aan mij'} size={14} className="mr-1" />
                    )}
                    {assignment.assignedTo ? <AssignedToUser userId={assignment.assignedTo} /> : '-'}
                </div>
                <div className="text-muted">{assignedTeam?.name ?? '-'}</div>
            </td>
            <td>
                <TaskAssignmentStatusBadge assignment={assignment} />
                {reviewedAt && <div className="text-muted">Beoordeeld op {reviewedAt}</div>}
            </td>
            <td>
                {assignment.status === taskTeamAssignmentStatus.STATUS_REJECTED &&
                assignment.rejectionReasons.length > 0 ? (
                    <RejectionReason assignment={assignment} />
                ) : (
                    <>-</>
                )}
            </td>
            {showActions && (
                <td className="actions">
                    <div>
                        <GenericButton
                            className="d-flex align-items-center flex-shrink-0"
                            variant="link"
                            onClick={() => setAssignUser(assignment.id)}
                            disabled={!canAssignReviewer}
                        >
                            <PersonAdd className="flex-shrink-0 mr-2" size={16} />
                            <div>Toewijzen</div>
                        </GenericButton>

                        {assignment.status === taskTeamAssignmentStatus.STATUS_REVIEW_IN_PROGRESS && (
                            <GenericButton
                                className="d-flex align-items-center flex-shrink-0 hover-danger"
                                variant="link"
                                onClick={removeAssignment}
                                disabled={!canAssignReviewer}
                            >
                                <PersonX className="flex-shrink-0 mr-2" size={16} />
                                <div>Verwijderen</div>
                            </GenericButton>
                        )}
                    </div>
                </td>
            )}
        </tr>
    );

    function removeAssignment() {
        updateTaskTeamAssignment({
            uri: assignment['@id'],
            body: {
                ...assignment,
                assignedAt: null,
                assignedTo: null,
                reviewedAt: null,
                rejectionReasons: [],
                status: taskTeamAssignmentStatus.STATUS_UNASSIGNED,
            },
        });
    }
}

function AssignedToUser({ userId }) {
    const createdByName = useGetUserFullName(userId, false);

    return <>{createdByName}</>;
}

export function RejectionReason({ assignment }) {
    const rejectionReasons = useGetRejectionReasons();
    const reasons = rejectionReasons.filter((reason) => assignment.rejectionReasons.includes(reason['@id']));

    return (
        <>
            {reasons.map((reason) => (
                <Badge
                    style={{ fontSize: 10 }}
                    variant="primary"
                    className="mr-1 mb-1"
                    key={`assignment-${assignment.id}-reason-${reason.id}`}
                >
                    {reason.name}
                </Badge>
            ))}
        </>
    );
}

export function useGetSelectedVersion(task) {
    const [selectedVersion = task?.activeVersionNumber] = useQueryParam('version', NumberParam);

    return task ? task.taskVersions.find((version) => version.versionNumber === selectedVersion) : undefined;
}
