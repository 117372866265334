import React, {Component, Fragment} from 'react';

import DocumentApi from "../../../../api/DocumentApi";
import LoadingSpinner from "../../../global/LoadingSpinner";
import Language from "../../../../language/Language";
import CloseButton from "../../../global/CloseButton";

export default class TagList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            tags: [],
        };
    }

    componentWillMount() {
        DocumentApi.getDocumentTags(this.props.documentId).then(tags => {
            let activeTags = [];
            tags.forEach(tag => {
                if (tag.deletedAt === null) {
                    activeTags.push(tag);
                }
            });

            this.setState({
                isLoading: false,
                tags: activeTags,
            });
        }).catch(() => {
            // do nothing
        });
    }

    render() {
        return (
            <div className="uk-width-xxlarge uk-padding-medium-horizontal uk-padding-vertical">
                <CloseButton className="uk-position-top-right uk-margin-top uk-margin-right" close={this.props.close}/>
                {!this.state.isLoading ? (
                    <Fragment>
                        <h2 className="uk-margin-remove-top uk-margin-small-bottom">Tags</h2>
                        {this.state.tags.length > 0 ? (
                            <Fragment>
                                <p className="uk-margin-medium-bottom">Kopieer de tag en plak deze tussen [ ] in de tekst</p>
                                <div className="uk-height-xlarge uk-overflow-auto">
                                    {this.state.tags.map(tag => {
                                        return <p className="uk-margin-small-bottom uk-text-primary" key={`tag-${tag.id}`}>[{tag.name}]</p>
                                    })}
                                </div>
                            </Fragment>
                        ) : (
                            <p className="uk-margin-top">{Language.getTranslation('noResultsTags')}</p>
                        )}
                    </Fragment>
                ) : (
                    <LoadingSpinner />
                )}
            </div>
        );
    }
}
