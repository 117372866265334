import Constants from '../../../config/Constants';
import { useSidebarContext } from '../hooks/useSidebarContext';

export default function BlockType() {
    const { entity } = useSidebarContext();
    const { properties = {} } = entity;
    const { textLayout = 'default' } = properties;

    const blockLayoutNames = {
        default: 'Tekst',
        intro: 'Introtekst',
        attention: 'Let op-tekst',
        info: 'Informatie kader',
        special: 'Speciaal kader',
    };
    const text =
        entity.type === Constants.blockTypes.text ? blockLayoutNames[textLayout] : Constants.blockTypes[entity.type];

    if (entity.type === Constants.blockTypes.text) {
        return <div>{text}</div>;
    }

    if (entity.type === Constants.blockTypes.blockLayout) {
        return <div>Tabel Layout</div>;
    }

    if (entity.type === Constants.blockTypes.file) {
        return <div>Afbeelding</div>;
    }

    if (entity.type === Constants.blockTypes.label) {
        return <div>Keuze opties</div>;
    }

    if (entity.type === Constants.blockTypes.linked) {
        return <div>Gekoppeld blok</div>;
    }

    if (entity.type === Constants.blockTypes.reimbursement) {
        return <div>Pakketvergoeding</div>;
    }

    if (entity.type === Constants.blockTypes.table) {
        return <div>Tabel</div>;
    }
}
