import { Component } from 'react';
import Parser from 'html-react-parser';
import CustomIcons from '../../global/CustomIcons';
import HelperFunctions from '../../global/HelperFunctions';
import { LinkedBlockLabel, ToolbarLabel } from './_BlockParser/EditorToolbar';
import Constants from '../../../config/Constants';
import { Table } from './_Blocks/Table';
import Reimbursement from './_BlockParser/Reimbursement';
import TextBlock from './_Blocks/TextBlock';
import LinkedBlock from './_Blocks/LinkedBlock';
import FileBlock from './_Blocks/FileBlock';
import _ from 'lodash';
import LabelBlock from './_Blocks/LabelBlock';
import { BlockWrapper } from './_Blocks/BlockWrapper';
import { BlockHeightWrapper } from './_Blocks/BlockHeightWrapper';
import { TextExplanation } from './_Blocks/TextExplanation';
import BlockLayout from './_BlockParser/BlockLayout';
import { Translation } from 'react-i18next';

export default class BlockContainer extends Component {
    constructor(props) {
        super(props);
        this.setFormData = this.setFormData.bind(this);
        this.toggleBlockSettings = this.toggleBlockSettings.bind(this);
        this.toggleTableEditMode = this.toggleTableEditMode.bind(this);
        this.toggleToolbar = this.toggleToolbar.bind(this);
        const { block, document, documentVariants } = props;
        const blockPrefix = _.get(block, 'properties.blockPrefix', '');
        const textLayout = _.get(block, 'properties.textLayout', 'default');
        const blockDisplayOptions = _.get(props, 'blockDisplayOptions', Constants.defaultBlockDisplayOptions);

        this.state = {
            blockPrefix,
            changesSaved: false,
            documentVariants: documentVariants
                ? documentVariants.map((variantId) => {
                      return HelperFunctions.getByValue(document.documentVariants, 'id', variantId);
                  })
                : [],
            editMode: false,
            editTableMode: false,
            formData: {},
            showBlockLinks: false,
            showToolbar:
                blockDisplayOptions.showBlockToolbar !== undefined ? blockDisplayOptions.showBlockToolbar : true,
            textLayout,
        };

        this.blockId = block.key !== undefined && block.key !== null ? block.key : block.id;
    }

    changesSaved = () => {
        this.setState(
            {
                changesSaved: true,
            },
            () => {
                HelperFunctions.changesSaved().then(() => {
                    this.setState({ changesSaved: false });
                });
            }
        );
    };

    componentDidMount() {
        this.setFormData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.activeVariantId !== prevProps.activeVariantId) {
            // switching variant, turn edit mode off
            this.setState({ editMode: false });
        }
    }

    setFormData = () => {
        const { block } = this.props;
        const blockPrefix = _.get(block, 'properties.blockPrefix', '');
        const blockPrefixSetting = _.get(block, 'properties.blockPrefixSetting', 'manual');
        const blockPrefixType = _.get(block, 'properties.blockPrefixType', 'default');
        const name = _.get(block, 'properties.name', '');
        const textLayout = _.get(block, 'properties.textLayout', 'default');
        const forceNewPage = _.get(block, 'properties.forceNewPage', false);
        const forceNewPageVariants = _.get(block, 'properties.forceNewPageVariants', []);

        this.setState({
            formData: {
                blockPrefix,
                blockPrefixSetting,
                blockPrefixType,
                documentVariants: block.documentVariants,
                name,
                textLayout,
                forceNewPage,
                forceNewPageVariants,
            },
        });
    };

    toggleBlockLinks = () => {
        this.setState({ showBlockLinks: !this.state.showBlockLinks });
    };

    toggleBlockSettings(e) {
        e.stopPropagation();

        // Call setFormData() when showing the edit form bc the state may have updates from other components
        if (!this.state.editMode) {
            this.setFormData();
        }

        this.setState({ editMode: !this.state.editMode });
    }

    toggleToolbar(newValue) {
        this.setState({ showToolbar: newValue });
    }

    toggleTableEditMode() {
        this.setState({ editTableMode: !this.state.editTableMode });
    }

    render() {
        const block = this.props.block;

        // Hide the block on the right side if it's new and has no base content yet
        if (
            this.props.context === Constants.blockContext.checkChanges &&
            this.props.editorDisplaySection === Constants.editorDisplaySections.right &&
            block.type !== Constants.blockTypes.blockLayout &&
            block.new &&
            block.baseContent === ''
        ) {
            return null;
        }

        // Hide the block on the right side if the deletion has been accepted but the save button has not been clicked
        if (this.props.context === Constants.blockContext.checkChanges && block.deleted && !block.deletionAccepted) {
            return null;
        }

        const blockDisplayOptions = this.props.blockDisplayOptions
            ? this.props.blockDisplayOptions
            : Constants.defaultBlockDisplayOptions;
        const markBlockDeleted = blockDisplayOptions.markDeletedBlocks && block.deleted;
        const markBlockScheduledForkDeletion = blockDisplayOptions.markDeletedBlocks && block.scheduledForDeletion;

        if (this.props.context !== Constants.blockContext.editor) {
            return (
                <BlockHeightWrapper
                    blockId={this.blockId}
                    editorDisplaySection={this.props.editorDisplaySection}
                    markAsDeleted={
                        blockDisplayOptions.markDeletedBlocks && (markBlockDeleted || markBlockScheduledForkDeletion)
                    }
                >
                    <>
                        {markBlockDeleted && (
                            <div className="py-2 uk-background-danger-light uk-text-danger text-center small">
                                <Translation ns="documents">
                                    {(t) => <>{t('document.navbar.main.editor.left.blocks.blockDeleted')}</>}
                                </Translation>
                            </div>
                        )}

                        {markBlockScheduledForkDeletion && (
                            <div className="py-2 uk-background-danger-light uk-text-danger text-center small">
                                <Translation ns="documents">
                                    {(t) => (
                                        <>{t('document.navbar.main.editor.left.blocks.badges.markedForDeletion')}</>
                                    )}
                                </Translation>

                                {this.props.context === Constants.blockContext.checkChanges &&
                                    blockDisplayOptions.text.interactiveChanges && (
                                        <>
                                            <button
                                                className="uk-button uk-button-small uk-button-primary uk-margin-small-left"
                                                onClick={() => this.props.processDeletedBlock(this.blockId, 'accept')}
                                            >
                                                <CustomIcons className="uk-margin-small-right" icon="acceptOne" />

                                                <Translation ns="documents">
                                                    {(t) => (
                                                        <>
                                                            {t(
                                                                'document.navbar.main.editor.left.blocks.acceptDeletion'
                                                            )}
                                                        </>
                                                    )}
                                                </Translation>
                                            </button>
                                            <button
                                                className="uk-button uk-button-small uk-button-primary uk-margin-small-left"
                                                onClick={() => this.props.processDeletedBlock(this.blockId, 'reject')}
                                            >
                                                <CustomIcons className="uk-margin-small-right" icon="rejectOne" />
                                                <Translation ns="documents">
                                                    {(t) => (
                                                        <>
                                                            {t(
                                                                'document.navbar.main.editor.left.blocks.rejectDeletion'
                                                            )}
                                                        </>
                                                    )}
                                                </Translation>
                                            </button>
                                        </>
                                    )}
                            </div>
                        )}

                        <Block
                            {...this.props}
                            blockDisplayOptions={blockDisplayOptions}
                            blockId={this.blockId}
                            blockPrefix={this.state.blockPrefix}
                            toggleToolbar={this.toggleToolbar}
                        />

                        {this.props.context === Constants.blockContext.checkChanges && (
                            <div className="editor-toolbar">
                                <div className="uk-button-group uk-button-group-small uk-margin-small-right align-items-center">
                                    {block.new && (
                                        <Translation ns="documents">
                                            {(t) => (
                                                <span
                                                    className="mr-2 p-1 badge badge-success"
                                                    data-uk-tooltip={t(
                                                        'document.navbar.main.editor.left.blocks.tooltips.blockInNew'
                                                    )}
                                                >
                                                    {t('document.navbar.main.editor.left.blocks.badges.newBlock')}
                                                </span>
                                            )}
                                        </Translation>
                                    )}

                                    {block.type === Constants.blockTypes.linked && <LinkedBlockLabel block={block} />}

                                    {block.type === Constants.blockTypes.label && <ToolbarLabel text="Keuze opties" />}
                                </div>
                            </div>
                        )}
                    </>
                </BlockHeightWrapper>
            );
        }

        return (
            <BlockWrapper
                blockId={this.blockId}
                state={this.state}
                props={this.props}
                toggleBlockLinks={this.toggleBlockLinks}
                toggleBlockSettings={this.toggleBlockSettings}
                toggleTableEditMode={this.toggleTableEditMode}
                toggleToolbar={this.toggleToolbar}
            />
        );
    }
}

export const Block = (props) => {
    const { block, blockId, handleBlockChange } = props;

    switch (block.type) {
        case Constants.blockTypes.text:
            return <TextBlock {...props} />;
        case Constants.blockTypes.table:
            return (
                <Table
                    {...props}
                    handleBlockChange={(content, ignoreChanges) => handleBlockChange(blockId, content, ignoreChanges)}
                />
            );
        case Constants.blockTypes.reimbursement:
            return (
                <Reimbursement
                    {...props}
                    handleBlockChange={(content, ignoreChanges) => handleBlockChange(blockId, content, ignoreChanges)}
                />
            );
        case Constants.blockTypes.linked:
            return (
                <LinkedBlock
                    {...props}
                    handleBlockChange={(content, ignoreChanges) => handleBlockChange(blockId, content, ignoreChanges)}
                />
            );
        case Constants.blockTypes.blockLayout:
            return <BlockLayout {...props} />;
        case Constants.blockTypes.file:
            return (
                <FileBlock
                    {...props}
                    handleBlockChange={(content, ignoreChanges) => handleBlockChange(blockId, content, ignoreChanges)}
                />
            );
        case Constants.blockTypes.label:
            return (
                <LabelBlock
                    block={block}
                    context={props.context}
                    readOnly={props.readOnly}
                    showTextEditor={props.showTextEditor}
                    editorDisplaySection={props.editorDisplaySection}
                    markChanges={props.blockDisplayOptions.text.markChanges}
                    processBlockChanges={props.processBlockChanges}
                    handleBlockChange={(content, properties) => handleBlockChange(blockId, content, false, properties)}
                />
            );
        case Constants.blockTypes.textExplanation:
            return <TextExplanation block={block} />;
    }

    return <Placeholder blockId={blockId} {...props} />;
};

const Placeholder = (props) => {
    const { block } = props;
    return (
        <div className="uk-margin-top uk-padding-small uk-position-relative uk-background-muted uk-text-muted uk-text-center">
            {block.type === Constants.blockTypes.table ? (
                Parser(block.latestContent)
            ) : (
                <p className="uk-margin-remove">{block.latestContent}</p>
            )}
        </div>
    );
};
