export const filterVariants = (variants = [], enabledVariants = []) => {
    return variants
        .filter((_variant) => {
            // VariantGroup is empty
            if (_variant.variantGroup && variantCount(_variant, enabledVariants) === 0) {
                return false;
            }

            // The Variant itself can be disabled
            if (!_variant.enabled) {
                return false;
            }

            // The Variant is included in the parent entity
            const isEnabled = enabledVariants.includes(_variant.id);

            if (!_variant.variantGroup && !isEnabled) {
                return false;
            }

            return true;
        })
        .map((_variant) => {
            if (_variant.children.length > 0) {
                return {
                    ..._variant,
                    children: filterVariants(_variant.children, enabledVariants),
                };
            }

            return {
                ..._variant,
            };
        });
};

const variantCount = (variant, enabledVariants = [], count = 0) => {
    if (variant.enabled && !variant.variantGroup) {
        if (enabledVariants.includes(variant.id)) {
            count++;
        }
    }

    if (variant.children.length > 0) {
        for (let i = 0; i < variant.children.length; i++) {
            const childVariant = variant.children[i];

            if (childVariant.enabled) {
                if (childVariant.children.length > 0) {
                    count += variantCount(childVariant, enabledVariants, count);
                } else {
                    if (enabledVariants.includes(childVariant.id)) {
                        count++;
                    }
                }
            }
        }
    }

    return count;
};
