import { useContext, useEffect } from 'react';
import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, AccordionContext, Badge, Card, Col, Dropdown, Row, SplitButton } from 'react-bootstrap';
import { CaretDownFill, CaretUpFill, ChevronRight } from 'react-bootstrap-icons';
import Constants from '../../../config/Constants';
import {
    cancelOpEntry,
    fetchOpEntryVariants,
    patchOpEntry,
    selectDepartmentsFromOpList,
    selectOpVariantsFromOpEntry,
} from 'features/operationsList/operationsListSlice';
import OpVariant from './OpVariant';
import cx from 'classnames';
import { OpListCommentButton } from './CommentButton';
import RestrictedContent from '../../global/RestrictedContent';
import { resolveOpListVariants } from '../utils/resolveOpListVariantsByDocument';
import { generatePath, useHistory } from 'react-router-dom';
import { OpTeamEntryStatus } from '../op_team_entry/OpTeamEntryStatus';
import _ from 'lodash';
import HelperFunctions from '../../global/HelperFunctions';
import LoadingSpinner from '../../global/LoadingSpinner';
import { resolveTeamEntryLabelName } from '../op_team_entry/OpTeamEntryLabelName';
import { VIEW_ENTRY_PATH } from 'scenes/OperationsList';
import { useSelectedPage } from './View';
import { useGetBaseVariant } from '../hooks/useGetBaseVariant';
import { entityTypes } from '../../translation/config/Constants';
import { idToUri } from '../../global/UriHelper';
import { useTranslation } from 'react-i18next';
import { TitleMetadata } from 'pages/operations_list/view/AreaMetadata';

function OpEntry({ opEntry, opList, opEntryLabels, labelLut, loadPage, forceExpanded = false }) {
    const departments = useSelector(selectDepartmentsFromOpList(_.get(opList, '@id', '')));
    const currentEventKey = useContext(AccordionContext);
    const { t } = useTranslation('changelist');

    if (!opEntry || !departments) {
        return null;
    }

    const isExpanded = currentEventKey === opEntry.id || forceExpanded === true;
    const created = DateTime.fromISO(opEntry.createdAt);
    const cardBodyClasses = cx('d-flex align-items-center op-list-row-title', {
        'card-deleted': opEntry.deleted,
    });

    const entryLabelsByDepartment = _.groupBy(opEntryLabels, 'opDepartment');

    return (
        <div className="mb-3">
            <Card className={opEntry.cancelled ? 'op-list-entry-card-cancelled' : ''}>
                <Accordion.Toggle as={Card.Body} className={cardBodyClasses} eventKey={opEntry.id}>
                    <div className="mr-2">#{opEntry.entryNumber}</div>

                    <div className="text-muted small mr-4">{created.toLocaleString(DateTime.DATETIME_MED)}</div>

                    <div className="font-weight-bold mr-3">
                        {opEntry.areaTitle}&nbsp;{isExpanded ? <CaretUpFill /> : <CaretDownFill />}
                    </div>

                    <div className="text-muted small mr-4">{renderLabelsOfEntry(opEntry, opEntryLabels, labelLut)}</div>

                    <div>
                        {opEntry.typesOfEdit.map((typeOfEdit, key) => (
                            <Badge
                                variant="light"
                                style={{ fontSize: '0.7rem' }}
                                className="mr-1"
                                key={`opEntry-${opEntry.id}-typeOfEdit-${key}`}
                            >
                                {t('changelist.view.opEntry.typeOfEdits.' + typeOfEdit)}
                            </Badge>
                        ))}
                    </div>

                    <div className="d-flex align-items-center ml-auto">
                        {/* If there is only a single entry, show the status here directly */}
                        {opEntry.archived && (
                            <div className="text-warning mr-2">{t('changelist.view.opEntry.archived')}</div>
                        )}
                        {opEntry.cancelled && (
                            <div className="text-danger mr-2">{t('changelist.view.opEntry.expired')}</div>
                        )}
                        {resolveActions(opList, opEntry, loadPage)}
                    </div>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey={opEntry.id} className={(forceExpanded ? 'show ' : '') + 'border-top'}>
                    <>
                        {isExpanded && (
                            <OpEntryListExpanded
                                departments={departments}
                                entryLabelsByDepartment={entryLabelsByDepartment}
                                labelLut={labelLut}
                                opList={opList}
                                opEntry={opEntry}
                            />
                        )}
                    </>
                </Accordion.Collapse>
            </Card>
        </div>
    );
}

function renderLabelsOfEntry(opEntry, opEntryLabels = [], labelLut) {
    const usedLabels = [];

    opEntryLabels.forEach((entryLabel) => {
        const labelName = resolveTeamEntryLabelName(labelLut, entryLabel);
        if (labelName && usedLabels.includes(labelName) === false) {
            usedLabels.push(labelName);
        }
    });

    return usedLabels.join('/');
}

function resolveActions(opList, opEntry, loadPage) {
    const { t } = useTranslation('changelist');

    switch (opEntry.status) {
        case 'processed':
            return <ActionsDropdown opList={opList} opEntry={opEntry} loadPage={loadPage} />;
        case 'failed':
            return (
                <div className="text-danger mr-2" style={{ minWidth: '9em' }}>
                    {t('changelist.view.opEntry.processingFailed')}
                </div>
            );
        case 'pending':
        case 'processing':
        default:
            return (
                <div
                    className="d-flex align-items-end text-success"
                    style={{ flexWrap: 'nowrap', whiteSpace: 'nowrap' }}
                >
                    <div className="mr-2">{t('changelist.view.opEntry.inProcessing')}</div>
                    <span style={{ marginBottom: -3 }}>
                        <LoadingSpinner successTheme={true} size="sm" inline={true} />
                    </span>
                </div>
            );
    }
}

function OpEntryListExpanded({ departments, entryLabelsByDepartment, labelLut, opList, opEntry }) {
    const { areaPath = [] } = opEntry;

    return (
        <Card.Body>
            {areaPath.length > 0 && (
                <div className="text-secondary mb-4">
                    <OpEntryPath opEntry={opEntry} />
                </div>
            )}

            {Object.keys(entryLabelsByDepartment).map((departmentUri, index) => {
                const department = _.find(departments, ['@id', departmentUri]);
                let entryLabels = entryLabelsByDepartment[departmentUri];

                if (!department) {
                    return null;
                }

                return (
                    <Row className="mb-2" key={departmentUri}>
                        <Col xs={3}>
                            <p className="uk-text-primary mb-0">{department.name}</p>
                        </Col>
                        <Col xs={9}>
                            <div className="d-flex align-items-center flex-wrap">
                                {entryLabels.map((entryLabel) => {
                                    const labelName = resolveTeamEntryLabelName(labelLut, entryLabel);

                                    return (
                                        <div
                                            className="d-flex ml-4 pb-1"
                                            style={{ alignItems: 'flex-start' }}
                                            key={entryLabel.id}
                                        >
                                            {/*Ignore archived since we're on the adminView, the archivation is for that specific department */}
                                            <OpTeamEntryStatus
                                                opList={opList}
                                                opEntryLabel={entryLabel}
                                                marginLevel={2}
                                                ignoreArchivedAndCancelled={true}
                                            />
                                            <span>{labelName}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </Col>
                    </Row>
                );
            })}
        </Card.Body>
    );
}

function ActionsDropdown({ opList, opEntry, loadPage }) {
    const dispatch = useDispatch();
    const selectedPage = useSelectedPage();
    const history = useHistory();
    const { t } = useTranslation('changelist');

    const handleSelect = (eventKey) => {
        switch (eventKey) {
            case 'archive':
                HelperFunctions.confirmModal(
                    t('changelist.view.opEntry.archiveChange'),
                    'danger',
                    false,
                    t('changelist.view.opEntry.btn.yesArchive'),
                    t('btn.cancel')
                ),
                    false.then(() => {
                        dispatch(
                            patchOpEntry({
                                uri: opEntry['@id'],
                                formData: {
                                    archived: true,
                                },
                            })
                        ).then(() => loadPage());
                    });
                break;
            case 'undoArchive':
                dispatch(
                    patchOpEntry({
                        uri: opEntry['@id'],
                        formData: {
                            archived: false,
                        },
                    })
                ).then(() => loadPage());
                break;
            case 'cancel':
                HelperFunctions.confirmModal(
                    t('changelist.view.opEntry.lapseChange'),
                    'danger',
                    false,
                    t('changelist.view.opEntry.btn.yes'),
                    t('btn.cancel')
                ).then(() => {
                    dispatch(
                        cancelOpEntry({
                            id: opEntry.id,
                            formData: {
                                cancel: true,
                            },
                        })
                    );
                });
                break;
            case 'undoCancel':
                dispatch(
                    cancelOpEntry({
                        id: opEntry.id,
                        formData: {
                            cancel: false,
                        },
                    })
                );
                break;
        }
    };

    const mayCancel = opList.userIsAdmin || opList.userIsEditor;
    const viewPath = generatePath(VIEW_ENTRY_PATH, {
        id: opList.id,
        opEntryId: opEntry.id,
        selectedPage,
    });

    return (
        <div
            onClick={(event) => {
                event.stopPropagation();
            }}
        >
            <SplitButton
                menuAlign="right"
                id={'entry-' + opEntry.id + '-dropdown'}
                title={t('changelist.view.view')}
                onSelect={handleSelect}
                onClick={(e) => {
                    e.preventDefault();
                    history.push(viewPath);
                }}
            >
                {!opEntry.archived && (
                    <Dropdown.Item eventKey="archive">{t('changelist.view.opEntry.archive')}</Dropdown.Item>
                )}
                {opEntry.archived && (
                    <Dropdown.Item eventKey="undoArchive">{t('changelist.view.opEntry.undoArchive')}</Dropdown.Item>
                )}

                {mayCancel && (
                    <>
                        {!opEntry.cancelled && (
                            <Dropdown.Item eventKey="cancel">{t('changelist.view.opEntry.dropChange')}</Dropdown.Item>
                        )}
                        {opEntry.cancelled && (
                            <Dropdown.Item eventKey="undoCancel">
                                {t('changelist.view.opEntry.undoExpiration')}
                            </Dropdown.Item>
                        )}
                    </>
                )}
            </SplitButton>
        </div>
    );
}

export function OpEntryExpanded({ opEntry, opList }) {
    const dispatch = useDispatch();
    const opVariants = useSelector(selectOpVariantsFromOpEntry(opEntry['@id']));
    const baseVariantForDocument = useGetBaseVariant(opEntry.documentId);
    const { documentVariant } = useSelector((state) => state.operations.filters);

    const opEntryId = opEntry.id;

    useEffect(() => {
        dispatch(fetchOpEntryVariants({ id: opEntryId }));
    }, [dispatch, opEntryId]);

    if (baseVariantForDocument === undefined) {
        return null;
    }

    const opEntryVariants = opVariants
        ? opVariants.filter((_variant) => (documentVariant ? _variant.variantId === documentVariant : true))
        : [];

    return (
        <Card.Body className="d-flex">
            <div style={{ minWidth: 60 }} className="pt-1">
                <RestrictedContent module={Constants.modules.comments}>
                    <OpListCommentButton
                        opList={opList}
                        entity={{
                            id: opEntry.areaId,
                            title: opEntry.areaTitle,
                            documentId: opEntry.documentId,
                        }}
                        entityType={entityTypes.OP_ENTRY}
                        uri={idToUri(opEntry.areaId, 'Area')}
                        parentUri={idToUri(opEntry.documentId, 'Document')}
                        // We want a snapshot of the comments up until this opList entry was created
                        until={DateTime.fromISO(opEntry.createdAt).toMillis()}
                    />
                </RestrictedContent>
            </div>
            <div className="flex-grow-1">
                <OpEntryTitle opEntry={opEntry} />

                <Accordion>
                    {resolveOpListVariants(baseVariantForDocument).map((_variant) => (
                        <DocumentVariant
                            documentVariant={_variant}
                            opEntry={opEntry}
                            opEntryVariants={opEntryVariants}
                            opList={opList}
                            key={_variant.id}
                        />
                    ))}
                </Accordion>
            </div>
        </Card.Body>
    );
}

function OpEntryTitle({ opEntry }) {
    const { areaTitle } = opEntry;

    return (
        <div className="mb-4 pb-3">
            <div className="d-flex align-items-center mb-1">
                <div
                    className="text-primary"
                    style={{
                        fontSize: '1.15rem',
                        lineHeight: '1.25',
                    }}
                >
                    {areaTitle}
                </div>

                <TitleMetadata areaId={opEntry.areaId} />
            </div>

            <div className="text-secondary">
                <OpEntryPath opEntry={opEntry} />
            </div>
        </div>
    );
}

export function OpEntryPath({ opEntry }) {
    const { areaPath = [], areaTitle } = opEntry;

    if (areaPath.length === 0) {
        return null;
    }

    return (
        <div className="d-flex flex-wrap align-items-center small">
            {areaPath.map((pathName, index) => (
                <div className="d-flex align-items-center" key={`opEntry-path-${index}`}>
                    {pathName}
                    <ChevronRight className="text-secondary mx-1" size={11} />
                </div>
            ))}

            <div className="font-weight-bold">{areaTitle}</div>
        </div>
    );
}

const showGroup = (documentVariant, opEntryVariants) => {
    return documentVariant.children.some((_variant) => {
        if (_variant.variantGroup) {
            return showGroup(_variant, opEntryVariants);
        }

        const opEntryVariant = _.find(opEntryVariants, (_opEntryVariant) => {
            return _opEntryVariant.variantId === _variant.id;
        });

        // Only show if variant is in the variants op this opEntry, and if that opEntry actually has block to show.
        return opEntryVariant && !_.isEmpty(opEntryVariant.opEntryBlocks);
    });
};

function DocumentVariant({ documentVariant, opEntryVariants, opEntry, opList }) {
    if (documentVariant.variantGroup) {
        if (!showGroup(documentVariant, opEntryVariants)) {
            return null;
        }

        return (
            <div className="mb-3">
                <div className="text-primary font-weight-bold mb-2">{documentVariant.name}</div>

                {documentVariant.children.map((_variant) => (
                    <DocumentVariant
                        documentVariant={_variant}
                        opEntryVariants={opEntryVariants}
                        opEntry={opEntry}
                        opList={opList}
                        key={_variant.id}
                    />
                ))}
            </div>
        );
    }

    return (
        <div className="mb-3">
            {opEntryVariants
                .filter((_opEntryVariant) => _opEntryVariant.variantId === documentVariant.id)
                .map((_opEntryVariant) => {
                    return (
                        <OpVariant
                            opEntryVariant={_opEntryVariant}
                            opEntry={opEntry}
                            opList={opList}
                            key={_opEntryVariant.id}
                        />
                    );
                })}
        </div>
    );
}

export default OpEntry;
