import { useGetStripeSubscriptionQuery } from 'features/security/authApi';
import { StringParam, useQueryParam } from 'use-query-params';

export function useGetStripeSubscription() {
    const [sid] = useQueryParam('sid', StringParam);
    const [userId] = useQueryParam('userId', StringParam);

    const { subscription } = useGetStripeSubscriptionQuery(
        { sid, userId },
        {
            selectFromResult: ({ data }) => ({
                subscription: data,
            }),
            skip: !sid || !userId,
        },
    );

    return subscription;
}
