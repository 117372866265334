import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useGetUser } from './useGetUser';

export default function useAdjustLanguage() {
    const { i18n } = useTranslation();
    const { userProfile } = useSelector((state) => state.security);
    const user = useGetUser(userProfile.userId);
    const userLanguagePreference = user?.preferences?.language ?? 'nl';
    const localLanguage = localStorage.getItem('i18nextLng');

    useEffect(() => {
        if (userLanguagePreference && userLanguagePreference !== localLanguage) {
            i18n.changeLanguage(userLanguagePreference);
            localStorage.setItem('i18nextLng', userLanguagePreference);
        }
    }, [user, userLanguagePreference, i18n]);
}
