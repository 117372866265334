import { EntityReference, TranslationKey } from '../../../models/translation.models';
import { useGetTranslationContainerEntityReferencesQuery } from '../../../features/translations/translationApi';
import { createSelector } from '@reduxjs/toolkit';
import { useMemo } from 'react';

const selectEntityReferenceById = (entityReferenceId: string | undefined) =>
    createSelector([(result: { data?: EntityReference[] }) => result.data], (data: EntityReference[] | undefined) => {
        return entityReferenceId
            ? data?.find((d: EntityReference) => d['@id'] === entityReferenceId) ?? undefined
            : undefined;
    });

export function useGetEntityReference(translationKey: TranslationKey): EntityReference | undefined {
    const { entityReferences, translationContainer, entityType } = translationKey;
    const uri = entityReferences.length > 0 ? entityReferences[0] : undefined;

    const selectEntityReferenceMemoized = useMemo(() => {
        return selectEntityReferenceById(uri);
    }, [translationContainer, uri]);

    const { entityReference }: { entityReference: EntityReference | undefined } =
        useGetTranslationContainerEntityReferencesQuery(
            {
                uri: translationContainer,
                entityType,
            },
            {
                selectFromResult: (result) => ({
                    entityReference: selectEntityReferenceMemoized(result),
                }),
                skip: entityReferences.length === 0,
            }
        );

    return entityReference;
}
