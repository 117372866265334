import { useSelector } from 'react-redux';
import { selectFolders } from '../../../../features/mediaLibrary/mediaLibrarySlice';
import { Nav } from 'react-bootstrap';
import { CollectionFill, FolderFill } from 'react-bootstrap-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Folders({ selectedFolder, setSelectedFolder }) {
    const { t } = useTranslation('documents');
    const rootFolders = useSelector(selectFolders(null));

    return (
        <>
            <Nav className="folder-nav flex-column mb-3">
                <Nav.Link
                    className="d-flex justify-content-between pr-3 pl-3"
                    active={selectedFolder === null}
                    onClick={() => {
                        setSelectedFolder(null);
                    }}
                    style={{
                        marginLeft: '9px',
                    }}
                >
                    <div className="font-weight-bolder">
                        <CollectionFill size={14} className="mr-2" />
                        {t('document.navbar.main.editor.left.blocks.types.fileBlock.fileModal.all')}
                    </div>
                </Nav.Link>
            </Nav>

            {rootFolders.map((_rootFolder) => (
                <RootFolder
                    folder={_rootFolder}
                    selectedFolder={selectedFolder}
                    setSelectedFolder={setSelectedFolder}
                    key={`root-folder-${_rootFolder.id}`}
                />
            ))}
        </>
    );
}

function RootFolder({ folder, selectedFolder, setSelectedFolder }) {
    const childFolders = useSelector(selectFolders(folder['@id']));

    return (
        <div className="mb-3">
            <div className="d-flex align-items-center mb-2 pl-2 pr-2">
                <span className="text-uppercase small text-muted font-weight-light">{folder.name}</span>
            </div>

            <Nav className="folder-nav flex-column">
                <div>
                    {childFolders.map((_folder) => (
                        <Folder
                            folder={_folder}
                            selectedFolder={selectedFolder}
                            setSelectedFolder={setSelectedFolder}
                            key={`media-folders-${_folder.id}`}
                        />
                    ))}
                </div>
            </Nav>
        </div>
    );
}

function Folder({ folder, selectedFolder, setSelectedFolder }) {
    const isActive = selectedFolder === folder.id;

    return (
        <Nav.Link
            as="div"
            className="d-flex align-items-center cursor-pointer pl-3"
            active={isActive}
            onClick={() => {
                setSelectedFolder(folder.id);
            }}
        >
            <div>
                <FolderFill size={14} className="mr-2" />
                {folder.name}
            </div>
        </Nav.Link>
    );
}
