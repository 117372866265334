import { PencilSquare, Trash3 } from 'react-bootstrap-icons';
import { GenericButton } from 'components/Buttons';
import { useTranslation } from 'react-i18next';

export function EditButton({ onClick }) {
    const { t } = useTranslation();

    return (
        <GenericButton className="d-flex align-items-center flex-shrink-0" variant="link" onClick={onClick}>
            <PencilSquare className="flex-shrink-0 mr-2" size={16} />
            <div>{t('global:btn.edit')}</div>
        </GenericButton>
    );
}

export function DeleteButton({ onClick }) {
    const { t } = useTranslation();

    return (
        <GenericButton
            className="d-flex align-items-center hover-danger flex-shrink-0"
            variant="link"
            onClick={onClick}
        >
            <Trash3 className="flex-shrink-0 mr-2" size={16} />
            <div>{t('global:btn.delete')}</div>
        </GenericButton>
    );
}
