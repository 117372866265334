import ReactSelect from 'react-select';
import React, { CSSProperties, ReactNode, useMemo } from 'react';
import { DocumentVariant } from '../models/documents.models';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

export function Select({
    label,
    id,
    options = [],
    isMulti = false,
    isClearable = false,
    handleChange,
    defaultValue = undefined,
    filterOption,
    isDisabled = false,
}: {
    label?: string;
    id: string;
    options: readonly object[];
    isMulti: any;
    isClearable?: boolean;
    handleChange: (newValue: any) => void;
    defaultValue?: any;
    filterOption?: (candidate: { label: string; value: string; data: any }, input: string) => boolean;
    isDisabled?: boolean;
}) {
    const style: { [key: string]: CSSProperties } = {
        wrapper: {
            border: '1px solid #ccc',
            borderRadius: 4,
        },
        label: {
            fontSize: 13,
            lineHeight: '40px',
            color: '#6c757d',
        },
    };
    const { t } = useTranslation('documents');
    return (
        <div style={style.wrapper}>
            <div className="d-flex align-items-center">
                {label && (
                    <label htmlFor={id} className="font-weight-bold flex-shrink-0 mb-0 ml-3 mr-2" style={style.label}>
                        {label}
                    </label>
                )}
                <ReactSelect
                    inputId={id}
                    placeholder={`${t('document.navbar.main.sidebar.selectVariant.titleSelect')}...`}
                    className="flex-grow-1"
                    isMulti={isMulti}
                    isClearable={isClearable}
                    isDisabled={isDisabled}
                    defaultValue={defaultValue}
                    options={options}
                    onChange={handleChange}
                    filterOption={filterOption}
                    menuPortalTarget={document.body}
                    noOptionsMessage={(obj: { inputValue: string }) => <>Geen opties</>}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderWidth: 0,
                            boxShadow: state.isFocused ? '0px 0 3px 2px #225a8a21' : undefined,
                            minHeight: 40,
                        }),
                        menuPortal: (baseStyles) => ({ ...baseStyles, zIndex: 1300 }),
                        option: (baseStyles, state) => ({
                            ...baseStyles,
                            fontSize: 13,
                        }),
                        multiValue: (baseStyles) => ({
                            ...baseStyles,
                            color: '#003763',
                            backgroundColor: '#eaf1fc',
                        }),
                        multiValueLabel: (baseStyles) => ({
                            ...baseStyles,
                            color: '#003763',
                            fontWeight: 500,
                            fontSize: '75%',
                        }),
                        placeholder: (baseStyles) => ({
                            ...baseStyles,
                            color: '#b8b8b8',
                            fontSize: 13,
                            lineHeight: '16px',
                        }),
                    }}
                    theme={(theme) => ({ ...theme, borderRadius: 4 })}
                />
            </div>
        </div>
    );
}

export function MultiSelect({
    label,
    id,
    options = [],
    handleChange,
    defaultValue = undefined,
    filterOption,
    isDisabled = false,
    isClearable = false,
}: {
    label?: string;
    id: string;
    options: readonly object[];
    handleChange: (newValue: any) => void;
    defaultValue?: any;
    filterOption?: (candidate: { label: string; value: string; data: any }, input: string) => boolean;
    isDisabled?: boolean;
    isClearable?: boolean;
}) {
    return (
        <Select
            id={id}
            label={label}
            handleChange={handleChange}
            filterOption={filterOption}
            options={options}
            isMulti={true}
            defaultValue={defaultValue}
            isDisabled={isDisabled}
            isClearable={isClearable}
        />
    );
}

export interface DocumentVariantOption {
    readonly label: ReactNode;
    readonly value?: number;
    readonly options?: DocumentVariantOption[];
    readonly variant?: DocumentVariantOptionValue;
}

interface DocumentVariantOptionValue {
    readonly prefix: string;
    readonly name: string;
}

export function MultiVariantSelect({
    label,
    id,
    variants,
    handleChange,
    defaultVariants,
    isClearable = false,
}: {
    label?: string;
    id: string;
    variants: DocumentVariant[];
    handleChange: (newValue: any) => void;
    defaultVariants?: number[];
    isClearable?: boolean;
}) {
    const { options, allOptions }: { options: DocumentVariantOption[]; allOptions: DocumentVariantOption[] } =
        useMemo(() => {
            const localVariants = [] as DocumentVariantOption[];
            const allOptions = [] as DocumentVariantOption[];
            const baseVariant = variants.find((variant) => variant.parentId === null);

            if (baseVariant === undefined) {
                return {
                    options: [],
                    allOptions: [],
                };
            }

            variants
                .filter((_variant) => _variant.parentId === baseVariant.id || _variant.variantGroup)
                .forEach((_variant) => {
                    if (_variant.variantGroup) {
                        localVariants.push({
                            label: (
                                <>
                                    {_variant.prefix && <span className="text-secondary">{_variant.prefix}&nbsp;</span>}
                                    <span className="text-primary">{_variant.name}</span>
                                </>
                            ),
                            options: variants
                                .filter(
                                    (_childVariant) =>
                                        _childVariant.parentId === _variant.id && !_childVariant.variantGroup,
                                )
                                .map((_childVariant) => {
                                    const childOption = {
                                        label: <VariantLabel variant={_childVariant} />,
                                        value: _childVariant.id,
                                        variant: {
                                            prefix: _childVariant.prefix ?? '',
                                            name: _childVariant.name,
                                        } as DocumentVariantOptionValue,
                                    };
                                    allOptions.push(childOption);

                                    return childOption;
                                }),
                        } as DocumentVariantOption);
                    } else {
                        const childOption = {
                            label: <VariantLabel variant={_variant} />,
                            value: _variant.id,
                            variant: {
                                prefix: _variant.prefix ?? '',
                                name: _variant.name,
                            } as DocumentVariantOptionValue,
                        };

                        allOptions.push(childOption);
                        localVariants.push(childOption);
                    }
                });

            return { options: localVariants, allOptions: allOptions };
        }, [variants]);

    const filterOption = (candidate: { label: string; value: string; data: DocumentVariantOption }, input: string) => {
        if (_.isEmpty(input)) {
            return true;
        }

        const { variant } = candidate.data;

        if (variant === undefined) {
            return false;
        }

        const title = variant.prefix + ' ' + variant.name;

        return title.toLowerCase().includes(input.toLowerCase());
    };

    return (
        <MultiSelect
            label={label}
            id={id}
            options={options}
            handleChange={handleChange}
            isClearable={isClearable}
            defaultValue={
                defaultVariants
                    ? allOptions.filter(
                          (variantOption) => variantOption.value && defaultVariants.includes(variantOption.value),
                      )
                    : undefined
            }
            filterOption={filterOption}
        />
    );
}

function VariantLabel({ variant }: { variant: DocumentVariant }) {
    return <LabelWithPrefix name={variant.name} prefix={variant.prefix} />;
}

export function LabelWithPrefix({ name, prefix }: { name: string; prefix?: string | null }) {
    return (
        <>
            {prefix && <span className="text-secondary mr-1">{prefix}</span>}
            <span>{name}</span>
        </>
    );
}
