import { Badge, Form } from 'react-bootstrap';
import { NumberParam, useQueryParam } from 'use-query-params';
import { PrimaryButton } from '../../../../components/Buttons';
import HelperFunctions from '../../../global/HelperFunctions';

export function AccountItem({ account }) {
    const [editAccount, setEditAccount] = useQueryParam('editAccount', NumberParam);

    return (
        <div>
            <div className="subheader d-flex align-items-center justify-content-between pb-1 mb-3">
                <h3 className="mb-0">{account.name}</h3>

                <PrimaryButton size="sm" className="mb-0" onClick={() => setEditAccount(account.id)}>
                    Bewerken
                </PrimaryButton>
            </div>

            <div className="form-group row mb-0">
                <Form.Label htmlFor="name" className="col-sm-4 col-form-label">
                    Naam
                </Form.Label>
                <div className="col-sm-8">
                    <Form.Control readOnly plaintext id="name" value={account.name} />
                </div>
            </div>

            <div className="form-group row mb-0">
                <Form.Label className="col-sm-4 col-form-label">Managers</Form.Label>
                <div className="col-sm-8">
                    <UserList account={account} propertyName="managers" />
                </div>
            </div>

            <div className="form-group row mb-0">
                <Form.Label className="col-sm-4 col-form-label">Gebruikers</Form.Label>
                <div className="col-sm-8">
                    <UserList account={account} propertyName="users" />
                </div>
            </div>
        </div>
    );
}

function UserList({ account, propertyName }) {
    const users = account[propertyName] ?? [];
    const sortedUsers = [...users].sort(HelperFunctions.sortByString('fullName'));

    return (
        <div className="form-control-plaintext">
            {sortedUsers.map((user) => (
                <Badge variant="primary" className="mr-1 mb-1" key={`user-${propertyName}-${user.id}`}>
                    {user.fullName}
                </Badge>
            ))}

            {users.length === 0 && <>-</>}
        </div>
    );
}
