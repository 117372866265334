import { Nav } from 'react-bootstrap';
import { generatePath, Link, useParams } from 'react-router-dom';
import { SETTINGS_PATH } from 'scenes/Account';
import cx from 'classnames';
import RestrictedContent from '../../global/RestrictedContent';
import Constants, { Permissions } from '../../../config/Constants';
import { CreditCard, People, Person, ShieldLock } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

export function Sidebar() {
    const { view = 'profile' } = useParams();
    const { t } = useTranslation('users');

    return (
        <div className="content-sidebar-document overflow-auto bg-light">
            <div className="py-4 bg-lightBlueLight">
                <div className="d-flex align-items-center justify-content-between mb-2 px-4">
                    <span className="text-color font-weight-bold">{t('profile.settings')}</span>
                </div>

                <Nav className="sidebar-nav flex-column">
                    <Link
                        to={generatePath(SETTINGS_PATH, {
                            view: 'profile',
                        })}
                        className={cx('nav-link', {
                            active: view === 'profile',
                        })}
                    >
                        <Person size={16} />
                        {t('profile.myProfile')}
                    </Link>

                    <RestrictedContent permission={Permissions.Account['Billing.Manage']}>
                        <Link
                            to={generatePath(SETTINGS_PATH, {
                                view: 'billing',
                            })}
                            className={cx('nav-link', {
                                active: view === 'billing',
                            })}
                        >
                            <CreditCard size={16} />
                            {t('profile.billingPlans')}
                        </Link>
                    </RestrictedContent>

                    <RestrictedContent module={Constants.modules.users} permission={Permissions.User['Write.All']}>
                        <Link
                            to={generatePath(SETTINGS_PATH, {
                                view: 'users',
                            })}
                            className={cx('nav-link', {
                                active: view === 'users',
                            })}
                        >
                            <People size={16} />
                            {t('profile.users')}
                        </Link>
                    </RestrictedContent>

                    <RestrictedContent
                        module={Constants.modules.single_sign_on}
                        permission={Permissions.SsoConfig.Create}
                    >
                        <Link
                            to={generatePath(SETTINGS_PATH, {
                                view: 'sso',
                            })}
                            className={cx('nav-link', {
                                active: view === 'sso',
                            })}
                        >
                            <ShieldLock size={16} />
                            {t('profile.sso')}
                        </Link>
                    </RestrictedContent>
                </Nav>
            </div>
        </div>
    );
}
