import { useEffect, useState } from 'react';
import DocumentHelper from '../../../../global/DocumentHelper';
import Block from './blocks/Block';

export function AreaBlocks({ areaData, translationContainer, languageIso, handleTranslationMutation }) {
    const [blockIdsInLayout, setBlockIdsInLayout] = useState(undefined);
    const { areaBlocks = [], baseBlocks = [] } = areaData;

    useEffect(() => {
        if (blockIdsInLayout === undefined && baseBlocks.length > 0) {
            setBlockIdsInLayout(DocumentHelper.getBlockIdsInLayout(baseBlocks));
        }
    }, [blockIdsInLayout, baseBlocks]);

    if (blockIdsInLayout === undefined) {
        return null;
    }

    return (
        <>
            {baseBlocks
                .filter((_block) => !blockIdsInLayout.includes(_block.id))
                .map((_block) => {
                    const areaBlock = areaBlocks.find((_areaBlock) => _areaBlock.id === _block.id);
                    const documentVariants = areaBlock?.documentVariants ?? [];

                    return (
                        <Block
                            areaData={areaData}
                            block={{
                                ..._block,
                                documentVariants,
                            }}
                            languageIso={languageIso}
                            translationContainer={translationContainer}
                            handleTranslationMutation={handleTranslationMutation}
                            key={`translation-${_block.id}`}
                        />
                    );
                })}
        </>
    );
}
