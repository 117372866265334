import { decodeToken } from 'react-jwt';

let token = '';

export function getActiveOrganisation() {
    return parseInt(localStorage.getItem('activeOrganisation') ?? 0);
}

export function setActiveOrganisation(organisationId) {
    localStorage.setItem('activeOrganisation', organisationId);
}

export function getProfile() {
    return decodeToken(getAuthToken());
}

export function getAuthToken() {
    return token;
}

function setAuthToken(authToken) {
    token = authToken;
}

export function saveTokenData(authToken, refreshToken) {
    return new Promise((resolve) => {
        setAuthToken(authToken);
        setRefreshToken(refreshToken);

        resolve(true);
    });
}

export function resetTokenData() {
    clearStorage();
    setAuthToken('');
}

function clearStorage() {
    const userEmail = localStorage.getItem('userEmail');

    localStorage.clear();

    if (userEmail) {
        localStorage.setItem('userEmail', userEmail);
    }
}

// Retrieves the refresh token from local storage
export function getRefreshToken() {
    return localStorage.getItem('refreshToken');
}

function setRefreshToken(refreshToken) {
    // Saves refresh token to local storage
    if (refreshToken) {
        localStorage.setItem('refreshToken', refreshToken);
    }
}
