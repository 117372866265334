import { FieldArray, Form as FForm, Formik } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import { LoadingSpinner } from 'pages/global/Spinner';
import { FormField, InputField } from 'pages/publications_v2/helpers/FieldHelper';
import { ArrowRepeat, PlusCircle, XCircleFill } from 'react-bootstrap-icons';
import { DateTime } from 'luxon';
import Constants from '../../../../config/Constants';
import cx from 'classnames';
import * as yup from 'yup';
import { useGetDocument } from '../../hooks/useGetDocument';
import { LightOutlineButton, WarningButton } from 'components/Buttons';
import { useUpdateDocumentMutation } from 'features/documents/documents';
import { useTranslation } from 'react-i18next';

export default function Revisions() {
    const { t } = useTranslation('documents');

    return (
        <>
            <div className="dr-container p-4">
                <div className="subheader">
                    <h3>{t('document.navbar.settings.tabs.planning.title')}</h3>
                </div>
                <div className="dr-instellingen-container">
                    <RevisionsForm />
                </div>
            </div>
        </>
    );
}

function RevisionsForm() {
    const { t } = useTranslation('documents');
    const document = useGetDocument();
    const [updateDocument] = useUpdateDocumentMutation();

    if (!document) {
        return null;
    }

    const handleSubmit = (values, { setSubmitting }) => {
        const formData = {
            deadline: DateTime.fromISO(values.deadline).toSeconds(),
            revisions: values.revisions.map((_revision, index) => {
                return {
                    ..._revision,
                    dateStart: DateTime.fromISO(_revision.dateStartUniversal).toSeconds(),
                    dateEnd: DateTime.fromISO(_revision.dateEndUniversal).toSeconds(),
                    id: index + 1, // BC fix
                    revisionId: _revision.id, // BC fix
                    revisionNumber: index + 1,
                };
            }),
        };

        updateDocument({
            id: document.id,
            uri: `/documents/${document.id}`,
            body: formData,
            method: 'PATCH',
        }).then(() => {
            setSubmitting(false);
        });
    };

    return (
        <Formik
            initialValues={{
                deadline: document.deadline ? DateTime.fromSeconds(document.deadline).toISODate() : '',
                revisions:
                    document.revisions.map((_revision) => {
                        return {
                            ..._revision,
                            dateStartUniversal: DateTime.fromSeconds(_revision.dateStart).toISODate(),
                            dateEndUniversal: DateTime.fromSeconds(_revision.dateEnd).toISODate(),
                        };
                    }) ?? [],
            }}
            onSubmit={handleSubmit}
            validationSchema={RevisionsSchema}
            enableReinitialize
        >
            {({ values, dirty, isSubmitting, isValid }) => {
                const lastRevision = values.revisions.at(-1);

                return (
                    <FForm autoComplete="off">
                        <Row>
                            <Col md={8}>
                                <div className="font-weight-bold">
                                    <FormField
                                        name="deadline"
                                        label={
                                            <label className="mb-2 font-weight-bolder label-title">
                                                {t('document.navbar.settings.tabs.planning.deadline')}
                                            </label>
                                        }
                                        props={{
                                            type: 'date',
                                            min: lastRevision?.dateEndUniversal ?? undefined,
                                            style: { maxWidth: 180, marginLeft: 18 },
                                        }}
                                    />
                                </div>
                                <label className="mb-2 font-weight-bolder label-title">
                                    {t('document.navbar.settings.tabs.planning.rounds')}
                                </label>
                            </Col>
                        </Row>

                        <FieldArray name="revisions">
                            {({ remove, push }) => (
                                <>
                                    {values.revisions.length > 0 &&
                                        values.revisions.map((_revision, index) => (
                                            <Revision
                                                revision={_revision}
                                                revisions={values.revisions}
                                                remove={() => remove(index)}
                                                index={index}
                                                key={index}
                                            />
                                        ))}

                                    <div className="mt-4 mb-3">
                                        <LightOutlineButton
                                            size="sm"
                                            onClick={() => {
                                                push({
                                                    dateStartUniversal: '',
                                                    dateEndUniversal: '',
                                                });
                                            }}
                                        >
                                            <PlusCircle size={14} />
                                            {t('document.navbar.settings.tabs.planning.addRound')}
                                        </LightOutlineButton>
                                    </div>
                                </>
                            )}
                        </FieldArray>

                        <WarningButton type="submit" disabled={isSubmitting || !dirty || !isValid}>
                            {t('btn.save')}
                        </WarningButton>
                        <LoadingSpinner isSubmitting={isSubmitting} />
                    </FForm>
                );
            }}
        </Formik>
    );
}

function Revision({ revision, revisions, remove, index }) {
    const previousRevision = index > 0 ? revisions[index - 1] ?? undefined : undefined;
    const { status = Constants.revisionStatus.draft } = revision;
    const canEdit = status === Constants.revisionStatus.draft;
    const { t } = useTranslation('documents');

    return (
        <Row className="align-items-center mb-2">
            <Col md={3}>
                <div className={cx({ 'font-weight-base': status === Constants.revisionStatus.inProgress })}>
                    {status === Constants.revisionStatus.inProgress && (
                        <ArrowRepeat
                            size={18}
                            className="mr-1"
                            data-uk-tooltip="Ronde is actief"
                            style={{ transform: 'rotate(45deg)' }}
                        />
                    )}
                    {t('document.navbar.settings.tabs.planning.round')} {index + 1}
                </div>
            </Col>
            <Col md={9} className="d-flex align-items-center">
                <InputField
                    name={`revisions.${index}.dateStartUniversal`}
                    props={{
                        type: 'date',
                        value: revision.dateStartUniversal,
                        min: previousRevision ? previousRevision.dateEndUniversal : undefined,
                        style: { width: 160 },
                        disabled: canEdit === false,
                        required: true,
                    }}
                />

                <span className="mx-2">t/m</span>

                <InputField
                    name={`revisions.${index}.dateEndUniversal`}
                    props={{
                        type: 'date',
                        value: revision.dateEndUniversal,
                        min: revision.dateStartUniversal,
                        style: { width: 160 },
                        disabled: canEdit === false,
                        required: true,
                    }}
                />

                <Button
                    variant="icon"
                    size="sm"
                    className="text-danger ml-2 btn-icon-only"
                    data-uk-tooltip="Ronde verwijderen"
                    onClick={remove}
                    disabled={canEdit === false}
                >
                    <XCircleFill size={16} style={{ minWidth: 16 }} />
                </Button>
            </Col>
        </Row>
    );
}

const RevisionsSchema = yup.object().shape({
    revisions: yup.array().of(
        yup.object().shape({
            dateStartUniversal: yup.string().required(),
            dateEndUniversal: yup.string().required(),
        })
    ),
});
