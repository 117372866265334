import HelperFunctions from "./HelperFunctions";
import deepcopy from "deepcopy";

const VariantHelper = {
    getVariant(variantId, document) {
        const baseVariant = document.baseVariant;

        if (variantId === baseVariant.id) {
            const variant = deepcopy(baseVariant);
            delete variant.children;

            return variant;
        }

        const variant = deepcopy(HelperFunctions.getByValueRecursive(baseVariant.children, variantId));
        delete variant.children;

        return variant;
    },
    hydrateVariants(arr, document) {
        return arr.map(id => {
            return this.getVariant(id, document);
        });
    },
    numberOfEnabledVariants(variant, count, enabledVariants) {
        if (variant.enabled && !variant.variantGroup) {
            if (enabledVariants !== undefined && enabledVariants.includes(variant.id)) {
                count++;
            }
        }

        if (variant.children.length > 0) {
            for (let i = 0; i < variant.children.length; i++) {
                const childVariant = variant.children[i];

                if (childVariant.enabled) {
                    if (childVariant.children.length > 0) {
                        count += this.numberOfEnabledVariants(childVariant, count, enabledVariants);
                    } else {
                        if (enabledVariants !== undefined && enabledVariants.includes(childVariant.id)) {
                            count++;
                        }
                    }
                }
            }
        }

        return count;
    },
    toArray(variant, arr = [], keepChildren = true, keepGroups = true) {
        if ((keepGroups && variant.variantGroup) || !variant.variantGroup) {
            arr.push(variant);
        }

        variant.children.forEach(childVariant => {
            return this.toArray(childVariant, arr, keepChildren, keepGroups);
        });

        if (!keepChildren) {
            delete variant.children;
        }

        return arr;
    }
}

export default VariantHelper;
