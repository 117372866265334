import { documentApi, useDeleteDocumentVariantMutation } from 'features/documents/documents';
import { useGetDocument } from '../../../hooks/useGetDocument';
import CloneApi from '../../../../../api/CloneApi';
import { showError } from 'helpers/errorHelper';
import HelperFunctions from '../../../../global/HelperFunctions';
import RestrictedContent from '../../../../global/RestrictedContent';
import Constants, { Permissions } from '../../../../../config/Constants';
import { useTranslation } from 'react-i18next';
import { NumberParam, useQueryParam } from 'use-query-params';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Dropdown, SplitButton } from 'react-bootstrap';
import { useGetPropositions } from 'pages/reimbursements/hooks/useGetPropositions';

export function VariantActions({ variant, isSubmitting, setSubmitting }) {
    const { t } = useTranslation('documents');
    const { t: tGlobal } = useTranslation('global');
    const [editVariant, setEditVariant] = useQueryParam('edit', NumberParam);
    const [documentModal, setDocumentModal] = useQueryParam('documentModal', NumberParam);
    const [productModal, setProductModal] = useQueryParam('productModal', NumberParam);
    const [categoryId, setCategoryId] = useQueryParam('categoryId', NumberParam);
    const [deleteDocumentVariant] = useDeleteDocumentVariantMutation();
    const { documentId } = useParams();
    const dispatch = useDispatch();
    const document = useGetDocument();

    const handleSelect = (eventKey) => {
        switch (eventKey) {
            case 'toggleDocumentModal':
                setDocumentModal(variant.id);
                return;
            case 'toggleLinkProductModal':
                setProductModal(variant.id);
                setCategoryId(document.reimbursementCategoryId);
                return;
            case 'clone':
                setSubmitting(true);

                CloneApi.cloneEntity('document_variants', variant.id)
                    .then(() => {
                        // Invalidate cache
                        dispatch(
                            documentApi.util.invalidateTags([
                                {
                                    type: 'Document',
                                    id: parseInt(documentId),
                                },
                                {
                                    type: 'DocumentVariant',
                                },
                            ])
                        );
                    })
                    .catch(() => {
                        showError('Fout bij dupliceren. Probeer het later nog eens.');
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });

                return;
            case 'delete':
                HelperFunctions.confirmModal(
                    `${t('document.navbar.variants.message.delete', { variant: variant.name })}`,
                    'danger',
                    false,
                    t('document.navbar.variants.message.confirmDeletion'),
                    t('btn.cancel')
                )
                    .then(() => {
                        deleteDocumentVariant({
                            documentId: parseInt(documentId),
                            variantId: variant.id,
                        });
                    })
                    .catch(() => {});

                return;
        }
    };

    return (
        <SplitButton
            id={`edit-variant-dropdown-${variant.id}`}
            title={tGlobal('btn.edit')}
            size="sm"
            onClick={() => setEditVariant(variant.id)}
            onSelect={handleSelect}
            disabled={isSubmitting}
        >
            {!variant.variantGroup && (
                <>
                    <Dropdown.Item eventKey="toggleDocumentModal" disabled={!variant.enabled}>
                        {t('document.navbar.variants.btn.notGroup.linkChapter')}&hellip;
                    </Dropdown.Item>

                    {document.reimbursementCategoryId && (
                        <RestrictedContent
                            permission={Permissions.Reimbursements['Write.All']}
                            module={Constants.modules.reimbursements}
                        >
                            <ToggleLinkItem variant={variant} />
                        </RestrictedContent>
                    )}

                    <Dropdown.Item eventKey="clone" disabled={!variant.enabled}>
                        {t('document.navbar.variants.btn.notGroup.duplicateChapter')}
                    </Dropdown.Item>
                    <Dropdown.Divider />
                </>
            )}
            <Dropdown.Item eventKey="delete" disabled={variant.variantGroup && variant.children.length > 0}>
                <span className="text-danger">{t('document.navbar.variants.btn.split.delete')}&hellip;</span>
            </Dropdown.Item>
        </SplitButton>
    );
}

function ToggleLinkItem({ variant }) {
    const { t } = useTranslation('documents');
    const propositions = useGetPropositions();
    const proposition = propositions.find((_item) => _item.documentVariantIds.includes(variant.id));

    return (
        <Dropdown.Item eventKey="toggleLinkProductModal" disabled={!variant.enabled || proposition !== undefined}>
            {t('document.navbar.variants.select.policy')}&hellip;
        </Dropdown.Item>
    );
}
