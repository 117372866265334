import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Accordion, AccordionContext, Badge, Card, Col, Form, Row} from 'react-bootstrap';
import { CaretDownFill, CaretUpFill, CircleFill } from 'react-bootstrap-icons';
import {
    fetchOpEntryAreasForOpEntry,
    fetchOpEntryVariantBlocks,
    selectOpEntryAreaByUri,
    selectOpEntryBlocksFromOpVariant,
} from '../../../features/operationsList/operationsListSlice';
import OpEntryBlock from './OpEntryBlock';
import RestrictedContent from '../../global/RestrictedContent';
import Constants from '../../../config/Constants';
import { OpListCommentButton } from './CommentButton';
import cx from 'classnames';
import Language from '../../../language/Language';
import { LinkedVariantsButton } from './LinkedVariantsButton';
import { DateTime } from 'luxon';
import HelperFunctions from '../../global/HelperFunctions';
import Parser from 'html-react-parser';
import LoadingSpinner from '../../global/LoadingSpinner';
import { useGetVariant } from '../hooks/useGetBaseVariant';
import { idToUri } from '../../global/UriHelper';
import { entityTypes } from '../../translation/config/Constants';
import { useGetDocument } from '../../documents_v2/hooks/useGetDocument';
import { useTranslation } from 'react-i18next';

export default function OpVariant({ opEntryVariant, opEntry, opList }) {
    const documentVariant = useGetVariant(opEntry.documentId, opEntryVariant.variantId);
    const currentEventKey = useContext(AccordionContext);
    const expanded = currentEventKey === opEntryVariant.id;
    const [showChanges, setShowChanges] = useState(true);
    const { t } = useTranslation('changelist');

    if (!documentVariant || documentVariant.variantGroup) {
        return null;
    }

    const isBaseVariant = documentVariant.parentId === null;

    return (
        <Card style={{ marginBottom: -1 }}>
            <Accordion.Toggle
                as={Card.Body}
                className="d-flex align-items-center op-list-row-title py-3"
                eventKey={opEntryVariant.id}
            >
                {documentVariant.prefix && <div className="text-muted mr-2">{documentVariant.prefix}</div>}

                <div className="font-weight-bold">
                    <span className="mr-1">
                        {isBaseVariant ? Language.getTranslation('baseVariant') : documentVariant.name}
                    </span>
                    {expanded ? <CaretUpFill /> : <CaretDownFill />}
                    {opEntryVariant.removedFromVariant && (
                        <span className="text-danger small ml-1">{t('changelist.view.opVariants.disconnected')}</span>
                    )}
                </div>

                {!expanded && <OpEntryVariantChanges opEntryVariant={opEntryVariant} />}

                {expanded && opEntryVariant.hasChanges && (
                    <div
                        className="ml-auto"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Form.Check
                            type="switch"
                            id="showChanges"
                            className="pt-0"
                            name="showChanges"
                            checked={showChanges}
                            label={t('changelist.view.opVariants.highlightChanges')}
                            onChange={(e) => {
                                setShowChanges(!showChanges);
                            }}
                        />
                    </div>
                )}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={opEntryVariant.id}>
                <>
                    {expanded && (
                        <OpVariantBlocks
                            isBaseVariant={isBaseVariant}
                            opEntryVariant={opEntryVariant}
                            showChanges={showChanges}
                            opEntry={opEntry}
                            opList={opList}
                        />
                    )}
                </>
            </Accordion.Collapse>
        </Card>
    );
}

function OpEntryVariantChanges({ opEntryVariant }) {
    const { hasChanges = false, hasSecondaryProducts = false } = opEntryVariant;
    const { t } = useTranslation('changelist');

    return (
        <div
            className="d-flex flex-shrink-0 align-items-center ml-auto"
        >
            {hasSecondaryProducts && (
                <Badge
                    variant="warning"
                    className="mr-2"
                    title={t('changelist.view.tooltip.opVariants.hasReimbursements')}
                >
                    CAV
                </Badge>
            )}

            <CircleFill
                className={cx({
                    'text-success': hasChanges,
                    'text-secondary': !hasChanges,
                })}
                size={8}
                title={
                    hasChanges
                        ? t('changelist.view.tooltip.opVariants.hasChanges')
                        : t('changelist.view.tooltip.opVariants.hasNoChanges')
                }
            />
        </div>
    );
}

function OpVariantBlocks({ isBaseVariant, opEntryVariant, showChanges, opEntry, opList }) {
    const dispatch = useDispatch();
    const opEntryVariantId = opEntryVariant.id;
    const opEntryBlocks = useSelector(selectOpEntryBlocksFromOpVariant(opEntryVariantId));
    const opEntryArea = useSelector((state) => selectOpEntryAreaByUri(state.operations, opEntryVariant.opEntryArea));
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);

        const promises = [dispatch(fetchOpEntryVariantBlocks({ opEntryVariantId }))];

        if (opEntryVariant.opEntryArea && !opEntryArea) {
            promises.push(dispatch(fetchOpEntryAreasForOpEntry({ opEntryId: opEntry.id })));
        }

        Promise.all(promises).then(() => setLoading(false));
    }, [dispatch, opEntryVariantId]);

    if (loading) {
        return <LoadingSpinner />;
    }

    return (
        <Card.Body className="border-top opVariant-content">
            <div className="opVariant-content-inner">
                {opEntryVariant.opEntryArea && (
                    <OpVariantArea
                        isBaseVariant={isBaseVariant}
                        opEntryVariant={opEntryVariant}
                        opEntryArea={opEntryArea}
                        showChanges={showChanges}
                        opEntry={opEntry}
                        key={opEntryVariant.opEntryArea}
                    />
                )}
                {opEntryBlocks.map((_opEntryBlock) => (
                    <OpVariantBlock
                        isBaseVariant={isBaseVariant}
                        opEntryVariant={opEntryVariant}
                        opEntryBlock={_opEntryBlock}
                        showChanges={showChanges}
                        opEntry={opEntry}
                        opList={opList}
                        key={_opEntryBlock.id}
                    />
                ))}
            </div>
        </Card.Body>
    );
}

function OpVariantBlock({ isBaseVariant, opEntryVariant, opEntryBlock, showChanges, opEntry, opList }) {
    let showInsertHighlight = opEntryBlock.insertHighlight;
    if (null === opEntryBlock.insertHighlight) {
        // since insertHighlight is a new field, we fallback to the legacy way of determining if a block should be highlighted if it is not present.
        showInsertHighlight = (opEntryBlock.addedToVariant && !opEntryVariant.removedFromVariant) || opEntry.areaIsNew;
    }

    let showDeleteHighlight = opEntryBlock.deleteHighlight;
    if (null === opEntryBlock.deleteHighlight) {
        // since deleteHighlight is a new field, we fallback to the legacy way of determining if a block should be highlighted if it is not present.
        showDeleteHighlight = opEntryBlock.removedFromVariant || opEntryVariant.removedFromVariant;
    }

    // Deleted but changes should not be shown, omit the entire block
    if (!showChanges && showDeleteHighlight) {
        return null;
    }

    const blockClassName = cx(`opList-block-${opEntryBlock.type}`, {
        'opList-block-added': showChanges && showInsertHighlight,
        'opList-block-removed': showChanges && showDeleteHighlight,
        'opList-block-show-changes': showChanges,
    });

    return (
        <Row className="opList-block-container">
            <div className="text-center" style={{ display: 'flex' }}>
                {isBaseVariant && <LinkedVariantsButton opEntry={opEntry} opEntryBlock={opEntryBlock} />}

                <RestrictedContent module={Constants.modules.comments}>
                    <div className="pl-2">
                        <OpListCommentButton
                            opList={opList}
                            entity={{
                                opEntry,
                                opEntryBlock,
                            }}
                            entityType={entityTypes.OP_ENTRY_VARIANT}
                            uri={idToUri(opEntryBlock.blockId, 'Block')}
                            parentUri={idToUri(opEntry.documentId, 'Document')}
                            // We want a snapshot of the comments up until this opList entry was created
                            until={DateTime.fromISO(opEntry.createdAt).toMillis()}
                        />
                    </div>
                </RestrictedContent>
            </div>
            <Col>
                <div className={blockClassName}>
                    <OpEntryBlock
                        isBaseVariant={isBaseVariant}
                        opEntryBlock={opEntryBlock}
                        documentId={opEntry.documentId}
                        showRendered={showChanges && !opEntryVariant.removedFromVariant}
                    />
                </div>
            </Col>
        </Row>
    );
}

function OpVariantArea({ isBaseVariant, opEntryVariant, opEntryArea, showChanges, opEntry }) {
    if (!opEntryArea) {
        return null;
    }

    return (
        <OpVariantAreaTitle
            isBaseVariant={isBaseVariant}
            opEntryVariant={opEntryVariant}
            opEntryArea={opEntryArea}
            showChanges={showChanges}
            opEntry={opEntry}
        />
    );
}

function OpVariantAreaTitle({ isBaseVariant, opEntryVariant, opEntryArea, showChanges, opEntry }) {
    let showInsertHighlight = opEntryArea.insertHighlight;
    let showDeleteHighlight = opEntryArea.deleteHighlight;
    const { t } = useTranslation('changelist');

    // Deleted but changes should not be shown, omit the entire component
    if (!showChanges && showDeleteHighlight) {
        return null;
    }

    const blockClassName = cx(`opList-block-text`, {
        'opList-block-added': showChanges && showInsertHighlight,
        'opList-block-removed': showChanges && showDeleteHighlight,
    });

    const marginRight = isBaseVariant ? 15 : -7.5;

    return (
        <Row className="opList-block-container">
            <div className="text-center" style={{ display: 'flex' }}>
                <div className="badge text-uppercase badge-success" style={{ marginTop: 3, marginRight: marginRight }}>
                    {t('changelist.view.tooltip.opVariants.title')}
                </div>
            </div>
            <Col>
                <div className={blockClassName}>
                    <AreaTitleRenderer
                        opEntryArea={opEntryArea}
                        documentId={opEntry.documentId}
                        showRendered={showChanges && !opEntryVariant.removedFromVariant}
                    />
                </div>
            </Col>
        </Row>
    );
}

function AreaTitleRenderer({ opEntryArea, showRendered, documentId }) {
    const document = useGetDocument(documentId);

    let text = showRendered ? opEntryArea.renderedDiffTitle : opEntryArea.latestTitle;
    if (document) {
        text = HelperFunctions.prepareRefs(text, document);
    }

    return <div>{Parser(text)}</div>;
}
