import { Col, Form, Modal, Row } from 'react-bootstrap';
import { InputMultipleSelect } from '../../../publications_v2/helpers/FieldHelper';
import { FormModal, ModalFooter } from '../../../global/FormModal';
import { Form as FForm, Formik, FormikHelpers } from 'formik';
import { Product, ProductTeam, Reimbursement } from '../../../../models/reimbursement.models';
import { useUpdateReimbursementMutation } from '../../../../features/reimbursements/reimbursements';
import { Team, useGetTeams } from '../../hooks/useGetTeams';
import HelperFunctions from '../../../global/HelperFunctions';
import { useTranslation } from 'react-i18next';

interface ActionValue {
    action: string;
    name: string;
    option?: Team;
    removedValue?: Team;
    removedValues?: Team[];
}

export function ReimbursementProductTeamModal({
    reimbursement,
    product,
    handleClose,
}: {
    reimbursement: Reimbursement;
    product: Product;
    handleClose: () => void;
}) {
    const teams = useGetTeams();
    const { t } = useTranslation('reimbursements');

    const productTeam =
        reimbursement?.productTeams?.find((_productTeam) => _productTeam.productId === product.id) ??
        ({
            productId: product.id,
            teams: [],
        } satisfies ProductTeam);

    const [updateReimbursement] = useUpdateReimbursementMutation();

    const handleSubmit = (values: ProductTeam, helpers: FormikHelpers<ProductTeam>) => {
        const formValues = [...reimbursement.productTeams];
        const index = reimbursement.productTeams.findIndex((_productTeam) => _productTeam.productId === product.id);

        if (index >= 0) {
            // Update
            formValues[index] = values;
        } else {
            // Insert
            formValues.push(values);
        }

        updateReimbursement({
            uri: reimbursement['@id'],
            category: reimbursement.category,
            productTeams: formValues,
        }).then(() => {
            helpers.setSubmitting(false);
            handleClose();
        });
    };

    return (
        <FormModal show={true} onHide={handleClose} title={t('reimbursement.product.tooltip.editDepartment')}>
            <Formik enableReinitialize={true} initialValues={productTeam} onSubmit={handleSubmit}>
                {({ isSubmitting, dirty, isValid, setFieldValue }) => (
                    <FForm autoComplete="off">
                        <Modal.Body>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm={4} htmlFor="teams">
                                    {t('reimbursement.editModal.departments')}
                                </Form.Label>
                                <Col sm={8}>
                                    <InputMultipleSelect
                                        name="teams"
                                        value={undefined}
                                        // @ts-ignore
                                        defaultValue={HelperFunctions.prepareDropdownData(
                                            teams.filter((team) => productTeam.teams.includes(team.id))
                                        )}
                                        options={HelperFunctions.prepareDropdownData(teams)}
                                        onChange={(selectedOptions: Team[], action: ActionValue) => {
                                            setFieldValue(
                                                'teams',
                                                selectedOptions.map((team) => team.id)
                                            );
                                        }}
                                        props={{
                                            placeholder: `${t('reimbursement.editModal.titleSelect')}...`,
                                        }}
                                    />
                                </Col>
                            </Form.Group>
                        </Modal.Body>

                        <ModalFooter dirty={dirty} isSubmitting={isSubmitting} isValid={isValid} onHide={handleClose} />
                    </FForm>
                )}
            </Formik>
        </FormModal>
    );
}
