import { useGetManagementDecisionQuery } from 'features/comments/commentApi';

export function useGetManagementDecision(uri) {
    const { managementDecision } = useGetManagementDecisionQuery(uri, {
        selectFromResult: (result) => ({
            managementDecision: result.currentData,
        }),
        skip: uri === undefined,
    });

    return managementDecision;
}
