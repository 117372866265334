import { useGetDocumentVariantsQuery } from 'features/documents/documents';
import { useParams } from 'react-router-dom';
import { filterDeep } from 'deepdash-es/standalone';

export function useGetBaseVariant(id = undefined, enabledOnly = false) {
    const { documentId } = useParams();
    const idAsNumber = id ?? parseInt(documentId);

    const { baseVariant } = useGetDocumentVariantsQuery(idAsNumber, {
        selectFromResult: ({ data }) => ({
            baseVariant: data ? data.find((variant) => variant.parentId === null) : undefined,
        }),
        skip: !idAsNumber,
    });

    if (baseVariant && enabledOnly) {
        return filterDeep(baseVariant, (value) => value.enabled, {
            childrenPath: 'children',
        });
    }

    return baseVariant;
}
