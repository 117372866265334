import { UserForm } from './UserForm';
import Constants from '../../../config/Constants';
import { useTranslation } from 'react-i18next';

export function CreateUser() {
    const { t } = useTranslation('users');

    return (
        <div>
            <div className="subheader">
                <h3>{t('newUser.title')}</h3>
            </div>

            <UserForm context={Constants.userFormContext.create} />
        </div>
    );
}
