import { useAppDispatch, useAppSelector } from '../../../../store';
import { Label, LabelFolder } from '../../../../models/labels.models';
import { Form } from 'react-bootstrap';
import _ from 'lodash';
import { setFilter } from '../../../../features/reimbursements/reimbursementSlice';
import { useGetLabelFoldersQuery, useGetLabelsQuery } from '../../../../features/metadata/metadata';
import { useGetTeams } from '../../hooks/useGetTeams';
import { CategoryFilters } from '../../config/params';
import { useTranslation } from 'react-i18next';

export default function Sidebar() {
    return (
        <div className="content-sidebar overflow-auto bg-light">
            <div className="pt-4 pl-3 pr-2 pb-2">
                <GeneralFilters />
                <TeamFilter />
                <LabelFolders />
            </div>
        </div>
    );
}

function GeneralFilters() {
    const dispatch = useAppDispatch();
    const filters: CategoryFilters = useAppSelector((state) => state.reimbursements.filters);
    const { t } = useTranslation('reimbursements');

    return (
        <div>
            <div className="mb-3">
                <div className="font-weight-bold mb-1">{t('navbar.titles.settings')}</div>
                <div className="small">
                    <div className="mb-2">
                        <Form.Check
                            name="show_open_changes"
                            id="show_open_changes"
                            label={t('reimbursement.sidebar.showChanges')}
                            checked={filters.show_open_changes}
                            onChange={() => {
                                dispatch(setFilter({ key: 'show_open_changes', value: !filters.show_open_changes }));
                            }}
                        />
                    </div>
                    <div className="mb-2">
                        <Form.Check
                            name="show_unchanged"
                            id="show_unchanged"
                            label={t('reimbursement.sidebar.showUnmodified')}
                            checked={filters.show_unchanged}
                            onChange={() => {
                                dispatch(setFilter({ key: 'show_unchanged', value: !filters.show_unchanged }));
                            }}
                        />
                    </div>
                    <div className="mb-2">
                        <Form.Check
                            name="hide_inactive"
                            id="hide_inactive"
                            label={t('reimbursement.sidebar.hideItems')}
                            checked={filters.hide_inactive}
                            onChange={() => {
                                dispatch(setFilter({ key: 'hide_inactive', value: !filters.hide_inactive }));
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

function TeamFilter() {
    const teams = useGetTeams();
    const { t } = useTranslation('reimbursements');

    return (
        <div>
            <div className="mb-3">
                <div className="font-weight-bold mb-1">{t('reimbursement.sidebar.departments')} (teams)</div>

                <div className="small">
                    <RenderRadio name="team" id="all" label={t('reimbursement.sidebar.all')} />

                    {teams.map((team) => {
                        return (
                            <RenderRadio
                                name="team"
                                id={`${team.id}`}
                                label={team.name}
                                key={`filter-team-${team.id}`}
                            />
                        );
                    })}

                    <RenderRadio name="team" id="none" label={t('reimbursement.sidebar.none')} />
                </div>
            </div>
        </div>
    );
}

function LabelFolders() {
    const { activeOrganisation } = useAppSelector((state) => state.security);

    const { labelFolders }: { labelFolders: LabelFolder[] } = useGetLabelFoldersQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                labelFolders: data
                    ? data.filter((folder: LabelFolder) => folder.contentTypes.includes('reimbursements_filter'))
                    : [],
            }),
        }
    );

    return (
        <>
            {labelFolders.map((folder: LabelFolder) => (
                <RenderLabelFolder folder={folder} key={`sidebar-folder-${folder.id}`} />
            ))}
        </>
    );
}

function RenderLabelFolder({ folder }: { folder: LabelFolder }) {
    const { activeOrganisation } = useAppSelector((state) => state.security);

    const { labels }: { labels: Label[] } = useGetLabelsQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                labels: data ? data.filter((label: Label) => label.folder === folder['@id']) : [],
            }),
        }
    );

    return (
        <div className="mb-3">
            <div className="font-weight-bold mb-1">{folder.name}</div>
            <div className="small">
                {labels.map((label: Label) => (
                    <RenderLabel label={label} key={`sidebar-label-${label.id}`} />
                ))}
            </div>
        </div>
    );
}

function RenderLabel({ label }: { label: Label }) {
    const labels: string[] = useAppSelector((state) => state.reimbursements.filters.labels);
    const dispatch = useAppDispatch();

    return (
        <div className="mb-2">
            <Form.Check
                name={label.id}
                id={label['@id']}
                label={label.name}
                checked={labels.includes(label['@id'])}
                onChange={() => {
                    dispatch(setFilter({ key: 'labels', value: _.xor(labels, [label['@id']]) }));
                }}
            />
        </div>
    );
}

function RenderRadio({ name, id, label }: { name: string; id: string; label: string }) {
    const dispatch = useAppDispatch();
    const { team } = useAppSelector((state) => state.reimbursements.filters);

    return (
        <div className="mb-2">
            <Form.Check
                name={name}
                id={id}
                label={label}
                type="radio"
                checked={team === id}
                onChange={() => {
                    dispatch(setFilter({ key: name, value: id }));
                }}
            />
        </div>
    );
}
