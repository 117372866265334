import Parser from 'html-react-parser';
import Constants from '../../../../config/Constants';
import { parseBlockContent } from '../../../global/BlockLayoutHelper';
import { useMemo } from 'react';
import { Table } from 'react-bootstrap';
import { HtmlEditor } from './HtmlEditor';

const BlockViews = {
    [Constants.blockTypes.text]: TextBlock,
    [Constants.blockTypes.blockLayout]: BlockLayout,
};

export function AreaBlock({ block, area, readOnly = true }) {
    const BlockView = BlockViews[block.type] ?? DefaultBlockView;

    return <BlockView block={block} area={area} readOnly={readOnly} />;
}

function TextBlock({ block, area, readOnly }) {
    if (block === undefined) {
        return null;
    }

    if (readOnly) {
        return <div className="area-read-only bg-white border p-3">{Parser(block.baseContent ?? '')}</div>;
    }

    return (
        <div className="bg-white border p-3 mb-1">
            <HtmlEditor
                value={block.baseContent}
                area={area}
                parentId={block.properties.parentId}
                parentBlock={area.areaBlocks.find((areaBlock) => areaBlock.id === block.properties.parentId)}
                areaKey={area.key}
                id={block.key}
            />
        </div>
    );
}

function BlockLayout({ block, area, readOnly }) {
    const areaBlocks = readOnly ? area.areaBlocks : area.blocks;

    const blockContent = useMemo(() => {
        return parseBlockContent(block.baseContent);
    }, [block, areaBlocks]);

    const rows = blockContent?.data ?? [];

    return (
        <Table bordered className="mb-2">
            <tbody>
                {rows.map((el, ind) => (
                    <tr key={block.key + '-row-' + ind}>
                        {el.map((col, colIndex) => {
                            return (
                                <td key={block.id + '-col-' + colIndex}>
                                    <div className="area-read-only p-0">
                                        {col.map((item, index) => (
                                            <TextBlock
                                                block={areaBlocks.find((_block) => _block.key === item.id)}
                                                area={area}
                                                readOnly={readOnly}
                                                key={`layout-${item.id}-${index}`}
                                            />
                                        ))}
                                    </div>
                                </td>
                            );
                        })}
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}

function DefaultBlockView() {
    return <div>block not supported</div>;
}
