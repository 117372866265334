import { useGetDocumentTagQuery } from 'features/documents/documents';

export function useGetDocumentTagData(documentId, id, skip = false) {
    return useGetDocumentTagQuery(
        { documentId, id },
        {
            selectFromResult: (result) => ({
                ...result,
                tagData: result.currentData,
                isLoading: result.isUninitialized || result.isFetching,
            }),
            skip,
        },
    );
}
