import { Product } from '../Update';
import cx from 'classnames';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import HelperFunctions from '../../global/HelperFunctions';

export default function SortableProducts({
    category,
    cloneProduct,
    currentLevel,
    editProduct,
    editProductId,
    moveDown,
    moveUp,
    toggleEnable,
    parent,
    isDragging,
    setProductToClone,
}) {
    const childProducts = category.products
        .filter((product) => {
            return product.hasOwnProperty('parent') && product.parent === parent['@id'];
        })
        .sort(HelperFunctions.dynamicSort('sortOrder'));

    return (
        <div className="mt-2 mb-3">
            <Droppable droppableId={parent['@id']}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className={cx('data-table-group-droppable', {
                            'is-dragging': isDragging,
                            'dragging-over': snapshot.isDraggingOver,
                        })}
                    >
                        {childProducts.map((childProduct, childIndex) => {
                            return (
                                <Draggable
                                    draggableId={`${childProduct.id}`}
                                    index={childIndex}
                                    key={`product-${childProduct.id}`}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            className={cx('data-table-item-wrapper mb-2', {
                                                'is-dragging': snapshot.isDragging,
                                            })}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                        >
                                            <Product
                                                category={category}
                                                cloneProduct={cloneProduct}
                                                currentLevel={currentLevel + 1}
                                                editProductId={editProductId}
                                                index={childIndex}
                                                isLast={childIndex === childProducts.length - 1}
                                                product={childProduct}
                                                editProduct={editProduct}
                                                moveDown={moveDown}
                                                moveUp={moveUp}
                                                toggleEnable={toggleEnable}
                                                provided={provided}
                                                isDragging={isDragging}
                                                setProductToClone={setProductToClone}
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            );
                        })}

                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </div>
    );
}
