import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import MainContentNav from '../../../Navbar';
import { generatePath, useParams } from 'react-router-dom';
import {
    fetchDepartmentReportData,
    fetchOpListDepartments,
    fetchOpListUsers,
    selectOpListById,
    selectReportsDataByDepartmentId,
    updateOpListReportsFilter,
} from '../../../../features/operationsList/operationsListSlice';
import { useDispatch, useSelector } from 'react-redux';
import SubNav from '../../nav/SubNav';
import _ from 'lodash';
import LoadingSpinner from '../../../global/LoadingSpinner';
import { Field, Form as FForm, Formik, useFormikContext } from 'formik';
import ReportsGraphs from './Graphs';
import { selectDepartmentUserIsManagerOf } from '../../../../features/operationsList/opListUserSlice';
import { convertArrayToObject, Labels, StatusCodes } from '../../forms/FilterFields';
import { BASE_PATH, VIEW_PATH } from '../../../../scenes/OperationsList';
import { useGetLabelsQuery } from '../../../../features/metadata/metadata';
import { useTranslation } from 'react-i18next';

export default function Reports() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const opList = useSelector((state) => selectOpListById(state.operations, id));
    const selectedDepartment = useSelector(selectDepartmentUserIsManagerOf(opList)); // only managers are able to see report

    const { opListReportsFilter } = useSelector((state) => state.operations);
    const [loading, setLoading] = useState(1);
    const { t } = useTranslation('changelist');

    const filters = _.get(opListReportsFilter, _.get(opList, 'id'), {});
    const selectedDepartmentId = _.get(selectedDepartment, 'id');
    const reportsData = useSelector((state) => selectReportsDataByDepartmentId(state.operations, selectedDepartmentId));

    useEffect(() => {
        if (1 === loading) {
            Promise.all([
                dispatch(fetchOpListUsers({ opListId: id })),
                dispatch(fetchOpListDepartments({ opListId: id })),
            ]).then(() => setLoading(2));
        }
    }, [dispatch, loading, id]);

    useEffect(() => {
        if (2 === loading) {
            dispatch(
                fetchDepartmentReportData({
                    departmentId: selectedDepartment.id,
                    filters,
                })
            ).then(() => setLoading(false));
        }
    }, [dispatch, loading]);

    if (!selectedDepartment) {
        return 'Geen toegang';
    }

    return (
        <>
            <MainContentNav
                pages={[
                    { title: t('breadcrumb'), url: BASE_PATH },
                    {
                        title: opList?.name,
                        url: generatePath(VIEW_PATH, {
                            id: opList?.id,
                        }),
                    },
                    { title: t('changelist.view.report.titleReport') },
                ]}
            />
            <SubNav />

            <div className="content-static-body has-subnav">
                <div className="d-flex align-items-stretch h-100">
                    <div className="content-sidebar overflow-auto bg-light">
                        {selectedDepartment && (
                            <ReportsSideBar
                                opList={opList}
                                filters={filters}
                                selectedDepartment={selectedDepartment}
                                setLoading={setLoading}
                            />
                        )}
                    </div>
                    <div className="flex-grow-1 overflow-auto">
                        <div id="content" className="px-4 pt-4">
                            <ReportsContent
                                selectedDepartment={selectedDepartment}
                                reportsData={reportsData}
                                filters={filters}
                                loading={loading}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function ReportsSideBar({ opList, filters, selectedDepartment, setLoading }) {
    const dispatch = useDispatch();
    const { activeOrganisation } = useSelector((state) => state.security);

    const { allLabels = [] } = useGetLabelsQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                allLabels: data ?? [],
            }),
        }
    );

    const handleSubmit = (values) => {
        const formData = {
            opListId: opList.id,
            formData: {
                ...values,
            },
        };

        dispatch(updateOpListReportsFilter(formData));

        // re retrieve reportData
        setLoading(2);
    };

    const selectedLabelOptions = getLabels(allLabels, selectedDepartment);

    const initialValues = {
        ...filters,
    };

    return (
        <div className="pt-4 pl-4 pr-4 pb-2">
            <Formik
                initialValues={
                    !_.isEmpty(filters)
                        ? initialValues
                        : {
                              showTable: true,
                              showBar: true,
                              'filter-2': false,
                              'filter-5': true,
                              'filter-status': convertArrayToObject(selectedDepartment?.statusCodes ?? [], 'slug'),
                              'filter-label': convertArrayToObject(selectedLabelOptions ?? [], '@id'),
                          }
                }
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {() => <SidebarForm allLabels={allLabels} selectedDepartment={selectedDepartment} />}
            </Formik>
        </div>
    );
}

function SidebarForm({ selectedDepartment, allLabels }) {
    const { submitForm, dirty } = useFormikContext();
    const { t } = useTranslation('changelist');

    useEffect(() => {
        if (dirty) {
            submitForm();
        }
    }, [dirty]);

    return (
        <FForm autoComplete="off">
            <div className="mb-3">
                <div className="font-weight-bold mb-1">{t('changelist.view.report.options')}</div>

                <div className="small">
                    <div className="mb-2">
                        <Field
                            as={Form.Check}
                            type="checkbox"
                            name="showTable"
                            id="showTable"
                            label={t('changelist.view.report.showLabel')}
                        />
                    </div>
                    {selectedDepartment && selectedDepartment.splitPerLabel && (
                        <div className="mb-2">
                            <Field
                                as={Form.Check}
                                type="checkbox"
                                name="showBar"
                                id="showBar"
                                label={t('changelist.view.report.showGraph')}
                            />
                        </div>
                    )}
                </div>
            </div>

            <div className="font-weight-bold mb-1">Filters</div>
            <div className="small mb-3">
                <div className="mb-2">
                    <Field
                        as={Form.Check}
                        type="checkbox"
                        name="filter-5"
                        id="filter-5"
                        label={t('changelist.view.report.expiredChanges')}
                    />
                </div>
                <div className="mb-2">
                    <Field
                        as={Form.Check}
                        type="checkbox"
                        name="filter-2"
                        id="filter-2"
                        label={t('changelist.view.report.archivedChanges')}
                    />
                </div>
            </div>

            {selectedDepartment && <StatusCodes statusCodes={selectedDepartment?.statusCodes ?? []} />}

            {/* Only show label filter if the department splits entries by labels, else there is no point for these filters */}
            {selectedDepartment && allLabels && selectedDepartment.splitPerLabel && (
                <Labels labels={getLabels(allLabels, selectedDepartment)} />
            )}
        </FForm>
    );
}

function ReportsContent({ selectedDepartment, reportsData, filters, loading }) {
    const { t } = useTranslation('changelist');

    if (loading) {
        return (
            <div id="content" className="px-4 pt-4">
                <LoadingSpinner />
            </div>
        );
    }

    return (
        <div id="content" className="px-4 pt-4">
            <Row>
                <Col className="pt-5" style={{ paddingTop: 0, marginTop: -50 }}>
                    <h3 className="text-primary mb-1">{t('changelist.view.report.titleReport')}</h3>
                    <p className="text-primary">
                        {t('changelist.view.report.reportsFor')} "{selectedDepartment?.name ?? ''}"
                    </p>
                </Col>
            </Row>

            <ReportsGraphs selectedDepartment={selectedDepartment} reportsData={reportsData} filters={filters} />
        </div>
    );
}

const getLabels = (allLabels, selectedDepartment) => {
    if (!allLabels || !selectedDepartment) {
        return [];
    }

    return allLabels.filter((label) => label.folder && selectedDepartment.labels.includes(label.folder));
};
