import MediaSelector, { getFileUri, isValidUri } from '../../../media_library/views/MediaSelector';
import HelperFunctions from '../../../global/HelperFunctions';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FieldSelect, FormField, Switch } from '../../../publications_v2/helpers/FieldHelper';
import React, { Fragment, useEffect } from 'react';
import Constants from '../../../../config/Constants';
import cx from 'classnames';
import Parser from 'html-react-parser';
import CheckChangesHeader from './CheckChangesHeader';

export default function FileBlock({
    block,
    handleBlockChange,
    editorDisplaySection,
    context,
    processBlockChanges,
    readOnly,
}) {
    const file = HelperFunctions.tryParseJSON(block.latestContent, null);
    const fileUri = getFileUri(_.get(file, 'uri', ''));
    const hasMedia = isValidUri(fileUri);

    const blockIsDeleted = _.get(block, 'deleted', false) || _.get(block, 'scheduledForDeletion', false);
    const checkChanges = context === Constants.blockContext.checkChanges;
    readOnly = readOnly || blockIsDeleted || editorDisplaySection === 'right' || checkChanges;

    let localChanges = [];

    if (checkChanges && !blockIsDeleted) {
        const newFile = HelperFunctions.tryParseJSON(block.renderedDiffContent, null);
        localChanges = newFile?.changes ?? [];
    }

    return (
        <div
            className={cx(`file-block block-context-${editorDisplaySection}-${context}`, {
                'area-text-block p-0': blockIsDeleted === false,
            })}
        >
            {checkChanges && !blockIsDeleted && editorDisplaySection === 'left' && (
                <CheckChangesHeader block={block} processBlockChanges={processBlockChanges} />
            )}

            <div className="d-flex flex-row p-3">
                <FileMedia
                    file={file}
                    readOnly={readOnly}
                    handleBlockChange={handleBlockChange}
                    checkChanges={checkChanges}
                    localChanges={localChanges}
                    block={block}
                />

                {hasMedia && (
                    <FileOptions file={file} block={block} checkChanges={checkChanges} localChanges={localChanges} />
                )}
            </div>
        </div>
    );
}

function FileMedia({ file, readOnly, handleBlockChange, checkChanges, localChanges, block }) {
    let fileUri = getFileUri(_.get(file, 'uri', ''));
    let changeProcessed = true;
    let showPrimary = true;

    if (checkChanges) {
        const hasChanges = block.changes?.some((part) => part.processed === false);

        if (block.new && hasChanges && fileUri === '') {
            showPrimary = false;
        }
    }

    if (checkChanges && localChanges.hasOwnProperty('uri')) {
        const part = HelperFunctions?.getByValue(block.changes, 'diffId', localChanges.uri[0][0]);

        if (part) {
            fileUri = localChanges.uri[2];
            changeProcessed = false;
        }
    }

    return (
        <>
            {showPrimary && (
                <MediaSelector
                    className={
                        checkChanges && localChanges.hasOwnProperty('uri') && changeProcessed === false
                            ? 'bg-deleted'
                            : ''
                    }
                    selectedValue={fileUri}
                    readOnly={readOnly}
                    onChange={(file) => {
                        const description = _.get(file, 'description', null);

                        const latestContent = JSON.stringify({
                            uri: _.get(file, '@id', ''),
                            options: {
                                align: FILE_OPTIONS_DEFAULT_ALIGN,
                                description: description ?? '',
                                alt: '',
                                url: '',
                                customSize: false,
                                width: '',
                                height: '',
                            },
                        });

                        handleBlockChange(latestContent);
                    }}
                />
            )}

            {checkChanges && localChanges.hasOwnProperty('uri') && changeProcessed === false && (
                <div className="ml-2">
                    <MediaSelector className="bg-insert" selectedValue={localChanges.uri[3]} readOnly />
                </div>
            )}
        </>
    );
}

function FileOptions({ file, block, checkChanges, localChanges }) {
    const options = getFileBlockOptions(file, block.properties.options);
    const { align, description, alt, url, customSize } = options;
    const labelMinWidth = 170;
    const hasChanges = _.get(block, 'changes', []).some((part) => part.processed === false);

    const alignContent = () => {
        if (!checkChanges || !localChanges.hasOwnProperty('align')) {
            return <>{Constants.fileBlock.alignOptions[align]}</>;
        }

        // Find Change part
        let part = HelperFunctions.getByValue(block.changes, 'diffId', localChanges.align[0][0]);

        if (checkChanges && part === undefined) {
            // Already accepted
            return <>{Constants.fileBlock.alignOptions[align]}</>;
        }

        const oldValue = Constants.fileBlock.alignOptions[localChanges.align[2]];
        const newValue = Constants.fileBlock.alignOptions[localChanges.align[3]];

        // Processed?
        if (part && part.processed) {
            if (part.accepted) {
                return <>{newValue}</>;
            }

            return <>{oldValue}</>;
        }

        return <>{Parser(`<span class="diffmod"><del>${oldValue}</del><ins>${newValue}</ins></span>`)}</>;
    };

    const parseChanges = (domNode) => {
        if (
            domNode.name === 'change' &&
            domNode.hasOwnProperty('attribs') &&
            domNode.attribs.hasOwnProperty('data-diff-id')
        ) {
            const domNodeId = parseInt(domNode.attribs['data-diff-id']);
            let part = HelperFunctions.getByValue(block.changes, 'diffId', domNodeId);

            if (part !== undefined) {
                if (part.processed) {
                    return (
                        <>
                            {!part.accepted && part.oldText !== undefined && part.oldText !== '' && (
                                <>{Parser(part.oldText)}</>
                            )}
                            {part.accepted && part.newText !== undefined && part.newText !== '' && (
                                <>{Parser(part.newText)}</>
                            )}
                        </>
                    );
                }

                return (
                    <span>
                        {part.oldText !== undefined && part.oldText !== '' && <del>{Parser(part.oldText)}</del>}
                        {part.newText !== undefined && part.newText !== '' && <ins>{Parser(part.newText)}</ins>}
                    </span>
                );
            } else {
                return <Fragment />;
            }
        } else if (domNode.name === 'change') {
            return <Fragment />;
        }
    };

    const diffContent = (option) => {
        if (!checkChanges || !localChanges.hasOwnProperty(option) || !hasChanges) {
            return <>{options[option]}</>;
        }

        return <>{Parser(localChanges[option][1], { replace: parseChanges })}</>;
    };

    const customSizeContent = () => {
        if (!checkChanges || !localChanges.hasOwnProperty('customSize')) {
            return <>{customSize ? 'Ja' : 'Nee'}</>;
        }

        // Find Change part
        let part = HelperFunctions.getByValue(block.changes, 'diffId', localChanges.customSize[0][0]);

        if (checkChanges && part === undefined) {
            // Already accepted
            return <>{customSize ? 'Ja' : 'Nee'}</>;
        }

        const oldValue = localChanges.customSize[2] ? 'Ja' : 'Nee';
        const newValue = localChanges.customSize[3] ? 'Ja' : 'Nee';

        // Processed?
        if (part && part.processed) {
            if (part.accepted) {
                return <>{newValue}</>;
            }

            return <>{oldValue}</>;
        }

        return <>{Parser(`<span class="diffmod"><del>${oldValue}</del><ins>${newValue}</ins></span>`)}</>;
    };

    return (
        <>
            <div className="ml-3 mt-1">
                <div className="mb-1">
                    <div className="d-inline-block text-secondary" style={{ minWidth: labelMinWidth }}>
                        Uitlijning
                    </div>
                    {alignContent()}
                </div>

                {_.isEmpty(description) === false && (
                    <div className="mb-1">
                        <div className="d-inline-block text-secondary" style={{ minWidth: labelMinWidth }}>
                            Omschrijving
                        </div>
                        {diffContent('description')}
                    </div>
                )}

                {_.isEmpty(alt) === false && (
                    <div className="mb-1">
                        <div className="d-inline-block text-secondary" style={{ minWidth: labelMinWidth }}>
                            Alt-tekst
                        </div>
                        {diffContent('alt')}
                    </div>
                )}

                {_.isEmpty(url) === false && (
                    <div className="mb-1">
                        <div className="d-inline-block text-secondary" style={{ minWidth: labelMinWidth }}>
                            URL
                        </div>
                        {diffContent('url')}
                    </div>
                )}

                <div>
                    <div className="d-inline-block text-secondary" style={{ minWidth: labelMinWidth }}>
                        Aangepaste afmetingen
                    </div>
                    {customSizeContent()}
                    {customSize && (
                        <span className="small">
                            &nbsp;(
                            <span className="text-secondary">
                                {diffContent('width')}/{diffContent('height')}
                            </span>{' '}
                            pixels)
                        </span>
                    )}
                </div>
            </div>
        </>
    );
}

export function FileBlockOptions({ handleFormChange, block }) {
    if (block.id === null) {
        return null;
    }

    const file = HelperFunctions.tryParseJSON(block.latestContent, null);

    if (block.properties.hasOwnProperty('options')) {
        file.options = block.properties.options;
    }

    return (
        <div className="ml-4">
            <Formik
                validationSchema={validationSchema}
                initialValues={{
                    align: file.options.align,
                    description: file.options.description,
                    alt: file.options.alt,
                    url: file.options.url,
                    customSize: file.options.customSize,
                    width: file.options.width,
                    height: file.options.height,
                }}
                enableReinitialize
                onSubmit={(values, { setSubmitting }) => {
                    console.log(values);
                }}
            >
                {({ values }) => (
                    <FileBlockOptionsForm block={block} handleFormChange={handleFormChange} values={values} />
                )}
            </Formik>
        </div>
    );
}

const FILE_OPTIONS_DEFAULT_ALIGN = 'left';

function FileBlockOptionsForm({ handleFormChange, block, values }) {
    useEffect(() => {
        const event = {
            target: {
                name: 'options',
                value: { ...values },
            },
        };

        handleFormChange(event);
    }, [values]);

    return (
        <>
            <FieldSelect
                name="align"
                label="Uitlijning"
                options={[
                    { label: 'Links', value: 'left' },
                    { label: 'Midden', value: 'center' },
                    { label: 'Rechts', value: 'right' },
                ]}
            />

            <FormField name="description" label="Onderschrift" />

            <FormField name="alt" label="Alt-tekst" />

            <FormField
                name="url"
                label="URL"
                props={{
                    placeholder: 'https://',
                }}
            />

            <Switch name="customSize" label="Aangepaste afmetingen" />

            {values.customSize === true && (
                <>
                    <FormField
                        name="width"
                        label="Breedte"
                        help="Breedte in pixels"
                        props={{
                            type: 'number',
                        }}
                    />

                    <FormField
                        name="height"
                        label="Hoogte"
                        help="Hoogte in pixels"
                        props={{
                            type: 'number',
                        }}
                    />
                </>
            )}
        </>
    );
}

const getFileBlockOptions = (file, customOptions) => {
    return _.defaults(customOptions, file?.options ?? {}, {
        align: 'left',
        description: '',
        alt: '',
        url: '',
        customSize: false,
        width: '',
        height: '',
    });
};

const validationSchema = Yup.object().shape({
    align: Yup.string(),
});
