import MainContentNav from '../../Navbar';
import SubNav from './SubNav';
import { PublicationDomains } from './kiosk/PublicationDomains';
import HelperFunctions from '../../global/HelperFunctions';
import { useGetPublicationDomains } from 'pages/publications_v2/hooks/useGetPublicationDomains';

export function Kiosk() {
    const publicationDomains = useGetPublicationDomains();

    const filteredDomains = publicationDomains
        .filter((domain) => domain.enableKiosk)
        .sort(HelperFunctions.sortByString('kioskName'));

    return (
        <>
            <MainContentNav title="Kiosk" />
            <SubNav />

            <div className="content-static-body has-subnav">
                <div className="d-flex align-items-stretch h-100">
                    <div className="flex-grow-1 overflow-auto">
                        {filteredDomains.length > 0 && (
                            <div id="content" className="px-5 pt-5 pb-3">
                                <PublicationDomains domains={filteredDomains} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
