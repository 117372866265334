import { Modal } from 'react-bootstrap';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { FormFieldWithTitle } from 'pages/publications_v2/helpers/FieldHelper';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { ModalFooter } from 'pages/global/FormModal';
import { useAddRejectionReasonMutation } from 'features/comments/commentApi';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';

export function AddRejectionReasonModal() {
    const [addRejectionReason, setAddRejectionReason] = useQueryParam('addRejectionReason', BooleanParam);
    const organisationId = useActiveOrganisation();

    if (!addRejectionReason) {
        return null;
    }

    return (
        <AddModalInner
            rejectionReason={{
                organisationId,
                name: '',
            }}
            close={close}
        />
    );

    function close() {
        setAddRejectionReason(undefined);
    }
}

export function AddModalInner({ rejectionReason, close }) {
    const { t } = useTranslation();
    const [addRejectionReason] = useAddRejectionReasonMutation();

    return (
        <>
            <Modal show={true} onHide={close}>
                <Modal.Header closeButton>
                    <Modal.Title>Reden toevoegen</Modal.Title>
                </Modal.Header>
                <Formik initialValues={rejectionReason} onSubmit={handleSubmit} validationSchema={validationSchema}>
                    {({ isValid, isSubmitting, dirty }) => {
                        return (
                            <Form>
                                <Modal.Body>
                                    <FormFieldWithTitle label="Name" name="name" props={{ required: true }} />
                                </Modal.Body>

                                <ModalFooter
                                    onHide={close}
                                    isSubmitting={isSubmitting}
                                    dirty={dirty}
                                    isValid={isValid}
                                />
                            </Form>
                        );
                    }}
                </Formik>
            </Modal>
        </>
    );

    function handleSubmit(values) {
        addRejectionReason(values).then(() => {
            close();
        });
    }
}

const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
});
