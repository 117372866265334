import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Language from '../../language/Language';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

const CloseButton = (props) => {
    const { t } = useTranslation('documents');

    const className = cx('uk-cursor-pointer uk-text-primary', {
        [props.className]: props.className !== undefined,
    });
    const Tag = props.type === 'link' ? Link : 'div';
    const tooltip =
        props.tooltipTranslation !== undefined
            ? props.tooltipTranslation
            : t('document.navbar.variants.linkChapters.btn.closeModal');

    return (
        <Tag
            to={props.to}
            className={className}
            onClick={props.close !== undefined ? props.close : null}
            data-uk-tooltip={tooltip}
        >
            <span data-uk-icon="icon: close; ratio: 1.6" />
        </Tag>
    );
};

export default CloseButton;

CloseButton.propTypes = {
    className: PropTypes.string,
    close: PropTypes.func,
    to: PropTypes.string,
    tooltipTranslation: PropTypes.string,
    type: PropTypes.string,
};
