import { SecondaryButton, WarningButton } from '../../../../components/Buttons';
import { IPID_IMPORT_PATH, VIEW_PATH } from '../../../../scenes/DocumentsV2';
import { generatePath, useHistory } from 'react-router-dom';
import { ImportContext } from '../IpidImport';
import { useContext, useState } from 'react';
import IconRenderer from '../../../../components/SettingsSidebar/views/AreaIcons/IconRenderer';
import Spinner from '../../../global/Spinner';
import {
    documentApi,
    useAddDocumentMutation,
    useSyncTagsMutation,
    useUpdateDocumentMutation,
    useUpdateDocumentVariantsMutation,
    useUpdateSectionsMutation,
    useUpdateTagMutation,
} from '../../../../features/documents/documents';
import { useActiveOrganisation } from '../../../../hooks/useActiveOrganisation';
import Constants from '../../../../config/Constants';
import { useCurrentOrganisation } from '../../../../hooks/useCurrentOrganisation';
import { StarFill } from 'react-bootstrap-icons';
import _ from 'lodash';
import queryString from 'query-string';
import { areaHasCustomPrefix, getBlockIdsInLayout, getBlockLayoutBlockIds } from './Helpers';

export function Preview() {
    const history = useHistory();
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [addDocument] = useAddDocumentMutation();
    const activeOrganisation = useActiveOrganisation();
    const { formData } = useContext(ImportContext);
    const { areas = [], document, importedVariants, documentTemplateId, documentTemplateSource } = formData;

    const currentOrganisation = useCurrentOrganisation();
    const [updateDocument] = useUpdateDocumentMutation();
    const [getDocumentVariants] = documentApi.useLazyGetDocumentVariantsQuery();
    const [getDocumentTag] = documentApi.useLazyGetDocumentTagQuery();
    const [updateDocumentVariants] = useUpdateDocumentVariantsMutation();
    const [updateSections] = useUpdateSectionsMutation();
    const [syncTags] = useSyncTagsMutation();
    const [updateTag] = useUpdateTagMutation();

    const documentVariantGroups = [...new Set(importedVariants.map((variant) => variant[1]))];
    const section = document?.sections[0];

    const handleSave = async () => {
        setSaveInProgress(true);

        const addDocumentResult = await addDocument({
            organisationId: activeOrganisation,
            body: {
                name: document.name,
                modelId: documentTemplateId,
                modelSource: documentTemplateSource,
                type: Constants.documentType.default,
            },
        });

        const newDocument = addDocumentResult.data;
        const documentId = newDocument.id;

        // Add all users as team member
        await updateDocument({
            id: documentId,
            uri: `/documents/${documentId}/team`,
            body: currentOrganisation.users.map((user) => ({
                role: Constants.userDocumentRole.documentManager,
                userId: user.id,
            })),
        });

        // Create DocumentVariants
        getDocumentVariants(documentId).then((result) => {
            const variants = result.data ?? [];
            const baseVariant = variants.find((variant) => variant.parentId === null);

            Promise.all(
                documentVariantGroups.map((group) => {
                    return new Promise(async (resolve) => {
                        // Create the DocumentVariant group
                        const groupName = group.trim();
                        const variantResult = await updateDocumentVariants({
                            id: documentId,
                            body: {
                                name: groupName,
                                prefix: '',
                                parentId: baseVariant.id,
                                variantGroup: true,
                            },
                        });

                        // Insert variants into group
                        const createdVariantParentGroup = variantResult.data.documentVariants.children.find(
                            (variant) => variant.name === groupName
                        );
                        const filteredVariants = importedVariants.filter((_variant) => _variant[1] === groupName);
                        const createdVariants = [];

                        for (const _variant of filteredVariants) {
                            const name = _variant[4].trim();

                            // Create DocumentVariant
                            const result = await updateDocumentVariants({
                                id: documentId,
                                body: {
                                    name,
                                    prefix: '',
                                    parentId: createdVariantParentGroup.id,
                                    variantGroup: false,
                                    ipidProperties: {
                                        cardId: parseInt(_variant[2]),
                                        cardVersion: parseInt(_variant[3]),
                                    }
                                },
                            });

                            const createdVariantGroup = result.data.documentVariants.children.find(
                                (variant) => variant.name === groupName
                            );
                            const documentVariant = createdVariantGroup.children.find(
                                (variant) => variant.name === name
                            );

                            createdVariants.push({
                                ..._variant,
                                documentVariant,
                            });
                        }

                        resolve(createdVariants);
                    });
                })
            )
                .then((results) => {
                    // format variants
                    const createdVariants = [];

                    results.forEach((variants) => {
                        variants.forEach((variant) => {
                            createdVariants.push(variant);
                        });
                    });

                    return createdVariants;
                })
                .then(async (variants) => {
                    // create section/areas
                    const variantIds = [baseVariant.id].concat(
                        variants.map((variantData) => variantData.documentVariant.id)
                    );

                    const createdSection = await updateSections({
                        documentId,
                        body: [
                            {
                                title: section.title,
                                documentId,
                                documentVariants: variantIds,
                                exportProperties: section.exportProperties,
                                modelId: newDocument.modelId,
                                sourceSlug: newDocument.modelSource,
                                properties: {
                                    ...section.properties,
                                    parentId: section.id,
                                },
                                areas: areas.map((area, index) => {
                                    const variantIds = [baseVariant.id];
                                    const blockIdsInLayout = getBlockIdsInLayout(area.blocks);
                                    let blocksToInsert = [];

                                    if (blockIdsInLayout.length === 0) {
                                        // No BlockLayout, remove Blocks not linked to a variant
                                        blocksToInsert = area.blocks.filter((block) => block.variantIds.length > 0);
                                    } else {
                                        // Has BlockLayout
                                        area.blocks
                                            .filter(
                                                (block) =>
                                                    block.type === Constants.blockTypes.blockLayout &&
                                                    block.variantIds.length > 0
                                            )
                                            .forEach((layoutBlock) => {
                                                blocksToInsert.push(layoutBlock);
                                                const blockIds = getBlockLayoutBlockIds(layoutBlock);

                                                // Set the variantIds to the child blocks
                                                area.blocks.forEach((block) => {
                                                    if (blockIds.includes(block.key)) {
                                                        blocksToInsert.push({
                                                            ...block,
                                                            variantIds: layoutBlock.variantIds,
                                                        });
                                                    }
                                                });
                                            });
                                    }

                                    blocksToInsert.forEach((block) => {
                                        block.variantIds.forEach((variantId) => {
                                            const variantData = variants.find(
                                                (_variant) => parseInt(_variant[2]) === variantId
                                            );

                                            if (variantData && !variantIds.includes(variantData.documentVariant.id)) {
                                                variantIds.push(variantData.documentVariant.id);
                                            }
                                        });
                                    });

                                    const showChoiceBlocks = areaHasCustomPrefix(area);
                                    let properties = Object.assign({}, area.properties);

                                    if (showChoiceBlocks && area.choiceVariantIds.length > 0) {
                                        properties.titlePrefixes = area.choiceVariantIds.map((variantId) => {
                                            const variantData = variants.find(
                                                (_variant) => parseInt(_variant[2]) === variantId
                                            );

                                            return {
                                                text: 'Keuze:',
                                                variantId: variantData.documentVariant.id,
                                            };
                                        });
                                    }

                                    return {
                                        title: area.title,
                                        properties,
                                        exportProperties: area.exportProperties,
                                        documentVariants: variantIds,
                                        sortOrder: index,
                                        blocksToInsert: blocksToInsert.map((block) => {
                                            const variantIds = [baseVariant.id];

                                            block.variantIds.forEach((variantId) => {
                                                const variantData = variants.find(
                                                    (_variant) => parseInt(_variant[2]) === variantId
                                                );

                                                if (
                                                    variantData &&
                                                    !variantIds.includes(variantData.documentVariant.id)
                                                ) {
                                                    variantIds.push(variantData.documentVariant.id);
                                                }
                                            });

                                            return {
                                                ...block,
                                                content: block.baseContent,
                                                documentVariants: variantIds,
                                            };
                                        }),
                                    };
                                }),
                            },
                        ],
                    });

                    return {
                        variants,
                        createdSection: createdSection.data[0],
                    };
                })
                .then(async ({ variants, createdSection }) => {
                    // Process tags
                    const tagsResult = await syncTags({ documentId, body: document.tags ?? [] });

                    if (tagsResult && tagsResult.data) {
                        const tags = tagsResult.data;
                        const polisvoorwaardenLink = tags.find((tag) => tag.name === 'PolisvoorwaardenLink');

                        if (polisvoorwaardenLink) {
                            const tagResult = await getDocumentTag({ documentId, id: polisvoorwaardenLink.id });
                            const tag = tagResult.data;

                            const newTagItems = variants.map((variant) => {
                                return {
                                    crossReference: null,
                                    documentVariant: variant.documentVariant.id,
                                    text: 'polisvoorwaarden',
                                    tooltip: null,
                                    url: variant[6],
                                };
                            });

                            await updateTag({
                                organisationId: activeOrganisation,
                                id: tag.id,
                                body: {
                                    ...tag,
                                    tagItems: tag.tagItems.concat(newTagItems),
                                },
                            });
                        }
                    }
                })
                .then(() => {
                    // setSaveInProgress(false);
                    // Redirect to the new Document
                    history.push(
                        generatePath(VIEW_PATH, {
                            documentId,
                        })
                    );
                });
        });
    };

    if (!document) {
        history.push(
            generatePath(IPID_IMPORT_PATH, {
                view: 'card',
            })
        );

        return null;
    }

    return (
        <>
            <div className="content-static-body" style={{ bottom: 65 }}>
                <div className="d-flex align-items-stretch h-100">
                    <div className="flex-grow-1 overflow-auto">
                        <div className="p-4">
                            <PreviewWrapper documentVariantGroups={documentVariantGroups} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-white position-absolute border-top" style={{ right: 0, bottom: 0, left: 0, height: 65 }}>
                <div className="d-flex align-items-center justify-content-end pr-5" style={{ height: 65 }}>
                    {saveInProgress && <Spinner />}
                    <SecondaryButton
                        disabled={saveInProgress}
                        className="mr-2"
                        onClick={() => {
                            const url = queryString.stringifyUrl({
                                url: generatePath(IPID_IMPORT_PATH, {
                                    view: 'card',
                                }),
                                query: { skip: '1' },
                            });

                            history.push(url);
                        }}
                    >
                        Vorige
                    </SecondaryButton>
                    <WarningButton disabled={saveInProgress} onClick={handleSave}>
                        Opslaan
                    </WarningButton>
                </div>
            </div>
        </>
    );
}

function PreviewWrapper({ documentVariantGroups }) {
    const { formData } = useContext(ImportContext);
    const { areas = [], document } = formData;

    const section = document?.sections[0];

    return (
        <div className="dr-container p-5 mb-2">
            <Variants documentVariantGroups={documentVariantGroups} />

            <div className="mb-4">Document naam: {document.name}</div>

            <div className="d-flex align-items-center mb-3">
                <div className="uk-text-primary mr-2">{section.title}</div>
                <div className="uk-label uk-text-xsmall uk-background-muted uk-text-muted">
                    <span>H1</span>
                </div>
            </div>

            {areas.map((area) => (
                <Area area={area} key={`area-${area.key}`} />
            ))}
        </div>
    );
}

function Variants({ documentVariantGroups }) {
    return (
        <div className="mb-5">
            <div className="uk-text-primary mb-3">Varianten</div>
            <div className="ml-4">
                <div className="mb-3">Basisvariant</div>

                {documentVariantGroups.map((group, index) => (
                    <VariantGroup name={group} key={`variant-${index}`} />
                ))}
            </div>
        </div>
    );
}

function VariantGroup({ name }) {
    const { formData } = useContext(ImportContext);
    const { importedVariants = [] } = formData;

    const filteredVariants = importedVariants.filter((variant) => variant[1] === name);

    return (
        <div className="mb-3">
            <div className="font-weight-bold mb-2">{name} (groep)</div>

            {filteredVariants.map((variant, index) => (
                <div className="mb-2" key={`variant-${name}-${index}`}>{`-- ${variant[4]}`}</div>
            ))}
        </div>
    );
}

function Area({ area }) {
    const isTemplate = _.get(area.properties, 'isTemplate', false);
    const Icons = area.exportProperties?.areaIcon || [];

    return (
        <div
            className="uk-border flex-grow-1 uk-border-theme-light mb-2"
            style={{
                marginLeft: parseInt(area.exportProperties.headingLevel) * 20 - 40,
            }}
        >
            <div className="d-flex align-items-center px-2 py-2 uk-background-theme-light-hover uk-cursor-pointer">
                <div className="d-flex align-items-center">
                    {isTemplate && <StarFill className="uk-text-warning mr-1" size={15} />}

                    <div className="text-muted mr-2 flex-shrink-0">
                        <IconRenderer isInsideTileRow={true} className="text-muted" areaIcons={Icons} />
                    </div>
                    <div className="font-weight-bold mr-2">
                        {area.freeForm && <>Vrije rubriek: </>}
                        {area.title}
                    </div>
                    <div className="uk-label uk-text-xsmall uk-background-muted uk-text-muted">
                        <span>{`H${area.exportProperties.headingLevel}`}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}
