import { Form } from 'react-bootstrap';
import { InputMultipleSelect } from '../../../publications_v2/helpers/FieldHelper';
import { useFormikContext } from 'formik';
import { ReimbursementProductField } from '../../../../models/reimbursement.models';
import HelperFunctions from '../../../global/HelperFunctions';
import { useGetLabelFoldersQuery, useGetLabelsQuery } from '../../../../features/metadata/metadata';
import { useAppSelector } from '../../../../store';
import { Label, LabelFolder } from '../../../../models/labels.models';
import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface FormInterface {
    variantPackageUri: string;
    categoryUri: string;
    showFieldNames: boolean;
    hideEmpty: boolean;
    addPrimaryAndSecondary: boolean;
    showOnlySecondary: boolean;
    fields: ReimbursementProductField[];
    labels: string[];
    groupByLabel: string;
}

const allowedTypes = ['reimbursements', 'reimbursements_filter'];

export default function LabelsSelect() {
    const { setFieldValue } = useFormikContext<FormInterface>();
    const { activeOrganisation } = useAppSelector((state) => state.security);
    const { t } = useTranslation('reimbursements');

    const { labelFolders }: { labelFolders: LabelFolder[] } = useGetLabelFoldersQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                labelFolders: data
                    ? data.filter((folder: LabelFolder) => _.intersection(folder.contentTypes, allowedTypes).length > 0)
                    : [],
            }),
        }
    );

    const { allLabels }: { allLabels: Label[] } = useGetLabelsQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                allLabels: data ?? [],
            }),
        }
    );

    const options = useMemo(() => {
        const optionsData = labelFolders?.map((folder: LabelFolder) => {
            const options = folder.labels
                .flatMap((uri: string) => allLabels.find((label) => label['@id'] === uri))
                .filter((label: Label | undefined): label is Label => label !== undefined);

            return {
                ...folder,
                options: HelperFunctions.prepareDropdownData(options),
            };
        });

        return HelperFunctions.prepareDropdownData(optionsData ?? []);
    }, [allLabels, labelFolders]);

    return (
        <Form.Group>
            <Form.Label htmlFor="labels">Labels</Form.Label>

            <InputMultipleSelect
                name="labels"
                options={options}
                onChange={(selection: Label[] | null) =>
                    setFieldValue('labels', selection?.map((label) => label['@id']) ?? [])
                }
                value={undefined}
                props={{
                    placeholder: `${t('view.generateSummary.titleSelect')}...`,
                }}
            />
            <Form.Text muted>{t('view.generateSummary.showReimbursementsWithLabels')}</Form.Text>
        </Form.Group>
    );
}
