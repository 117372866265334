import { Form as FForm, Formik } from 'formik';
import * as Yup from 'yup';
import { generatePath, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormField, InputMultipleSelect, Switch } from 'pages/publications_v2/helpers/FieldHelper';
import { LoadingSpinner } from 'pages/global/Spinner';
import { WarningButton } from 'components/Buttons';
import HelperFunctions from '../../../global/HelperFunctions';
import { useCreateAdminOrganisationMutation, useUpdateAdminOrganisationMutation } from 'features/security/authApi';
import { ADMIN_SETTING_ACCOUNT_PATH } from 'scenes/Admin';
import _ from 'lodash';

export function OrganisationForm({ organisation = {}, account }) {
    const { t } = useTranslation('global');
    const history = useHistory();
    const [updateAdminOrganisation] = useUpdateAdminOrganisationMutation();
    const [createAdminOrganisation] = useCreateAdminOrganisationMutation();

    const handleSubmit = (values, { setSubmitting }) => {
        const { insurerIds = '' } = values;

        const body = {
            ...values,
            users: values.users.map((user) => user.id),
            templateSources: values.templateSources.map((source) => source.value),
            insurerIds:
                insurerIds !== '' ? values.insurerIds.split(',').map((insurerId) => _.toInteger(insurerId.trim())) : [],
        };

        if (organisation.hasOwnProperty('id')) {
            // Update
            updateAdminOrganisation({
                accountId: account.id,
                id: organisation.id,
                body,
            }).then(() => {
                setSubmitting(false);

                history.push(
                    generatePath(ADMIN_SETTING_ACCOUNT_PATH, {
                        id: account.id,
                        view: 'organisations',
                        action: 'view',
                        entityId: organisation.id,
                    })
                );
            });
        } else {
            createAdminOrganisation({
                accountId: account.id,
                body,
            }).then(({ data }) => {
                setSubmitting(false);

                history.push(
                    generatePath(ADMIN_SETTING_ACCOUNT_PATH, {
                        id: account.id,
                        view: 'organisations',
                        action: 'view',
                        entityId: data.id,
                    })
                );
            });
        }

        setSubmitting(false);
    };

    const availableTemplateSources = [
        {
            label: 'MyTest',
            value: 'my-test',
        },
        {
            label: 'My',
            value: 'my',
        },
        {
            label: 'ONVZ',
            value: 'onvz',
        },
        {
            label: 'AchmeaZorg',
            value: 'achmeazorg',
        },
        {
            label: 'AchmeaSI',
            value: 'achmeasi',
        },
        {
            label: 'Menzis',
            value: 'menzis',
        },
    ];

    const {
        name = '',
        website = '',
        users = [],
        templateSources = [],
        aiRateLimit = 100,
        aiUsageCount = 0,
        ssoEnabled = false,
        requireTagInComment = false,
        enabled = true,
        ipidImport = true,
        insurerIds = [],
    } = organisation;

    return (
        <div>
            <Formik
                initialValues={{
                    account: account.id,
                    name,
                    website,
                    users: HelperFunctions.prepareDropdownData(users, 'fullName'),
                    templateSources: availableTemplateSources.filter((source) =>
                        templateSources.includes(source.value)
                    ),
                    aiRateLimit,
                    aiUsageCount,
                    ssoEnabled,
                    requireTagInComment,
                    enabled,
                    ipidImport,
                    insurerIds: insurerIds.join(','),
                }}
                onSubmit={handleSubmit}
                enableReinitialize
                validationSchema={organisationFormSchema}
            >
                {({ values, dirty, isSubmitting, isValid, setFieldValue }) => (
                    <FForm autoComplete="off">
                        <Switch name="enabled" label="Ingeschakeld" wrapperClassName="mb-2" />

                        <FormField name="name" label="Naam" props={{ required: true }} />
                        <FormField name="aiRateLimit" label="AI rate limit" />
                        <FormField name="aiUsageCount" label="AI usage count" help="Set to -1 for unlimited usage" />
                        <FormField
                            name="website"
                            label="Documentation url"
                            help="If set, a link to the docs will be shown"
                        />

                        <Switch name="ssoEnabled" label="SSO enabled" wrapperClassName="mb-0" />
                        <Switch name="requireTagInComment" label="Require tag in comments" wrapperClassName="mb-0" />

                        <div className="mb-3 form-group row">
                            <label htmlFor="users" className="form-label col-form-label col-sm-4">
                                Gebruikers
                            </label>

                            <div className="col-sm-8">
                                <InputMultipleSelect
                                    name="users"
                                    options={HelperFunctions.prepareDropdownData(account.users, 'fullName')}
                                    onChange={(selectedOptions) => {
                                        setFieldValue('users', selectedOptions ?? []);
                                    }}
                                    value={values.users}
                                />
                            </div>
                        </div>

                        <div className="mb-3 form-group row">
                            <label htmlFor="templateSources" className="form-label col-form-label col-sm-4">
                                Template bronnen
                            </label>

                            <div className="col-sm-8">
                                <InputMultipleSelect
                                    name="templateSources"
                                    options={availableTemplateSources}
                                    onChange={(selectedOptions) => {
                                        setFieldValue('templateSources', selectedOptions ?? []);
                                    }}
                                    value={values.templateSources}
                                />
                            </div>
                        </div>

                        <div className="font-weight-bolder text-color pt-2 mb-1">Ipid</div>
                        <Switch name="ipidImport" label="Ipid insurer" wrapperClassName="mb-0" />
                        <FormField
                            name="insurerIds"
                            label="Insurer ID(s)"
                            help="CSV of insurer ids: 4,18,50"
                            props={{
                                disabled: !values.ipidImport,
                            }}
                        />

                        <div className="mb-4 mt-4">
                            <WarningButton type="submit" disabled={isSubmitting || !dirty || !isValid}>
                                {t('btn.save')}
                            </WarningButton>

                            <LoadingSpinner isSubmitting={isSubmitting} />
                        </div>
                    </FForm>
                )}
            </Formik>
        </div>
    );
}

const organisationFormSchema = Yup.object().shape({
    name: Yup.string().required(),
});
