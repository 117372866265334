import { Form as FForm, Formik } from 'formik';
import * as Yup from 'yup';
import { LightOutlineButton, LinkButton, WarningButton } from 'components/Buttons';
import Spinner from 'pages/global/Spinner';
import { useUpdateTaskTeamAssignmentMutation } from 'features/comments/commentApi';
import { InputMultipleSelect, InputSelect } from 'pages/publications_v2/helpers/FieldHelper';
import HelperFunctions from 'pages/global/HelperFunctions';
import { FormGroup } from 'components/SettingsSidebar/helpers/FormHelpers';
import { taskTeamAssignmentStatus } from 'pages/task_team/config/constants';
import { useGetRejectionReasons } from 'pages/task_team/hooks/useGetRejectionReasons';
import { useState } from 'react';

export function EditAssignment({ assignment, close }) {
    const [updateTaskTeamAssignment] = useUpdateTaskTeamAssignmentMutation();
    const rejectionReasons = useGetRejectionReasons(assignment.team);
    const [showConfirm, setShowConfirm] = useState(false);

    const rejectionReasonsOptions = HelperFunctions.prepareDropdownData(rejectionReasons, 'name', '@id');
    const defaultRejectionReason = rejectionReasonsOptions.filter((option) =>
        assignment.rejectionReasons.includes(option.value),
    );

    return (
        <Formik
            initialValues={{
                status: assignment.status,
                rejectionReasons: assignment.rejectionReasons ?? [],
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            {({ isValid, isSubmitting, dirty, values, setFieldValue }) => {
                return (
                    <FForm>
                        <div className="card oke-card oke-card-active mb-4">
                            <div className="card-header">Mijn beoordeling</div>

                            <div className="card-body">
                                <div className="sidebar-form">
                                    <FormGroup label="Status*" htmlFor="status">
                                        <InputSelect name="status" options={getStatusOptions()} />
                                    </FormGroup>

                                    {values.status === taskTeamAssignmentStatus.STATUS_REJECTED && (
                                        <FormGroup label="Reden voor afkeuring*" htmlFor="rejectionReasons">
                                            <InputMultipleSelect
                                                name="rejectionReasons"
                                                options={rejectionReasonsOptions}
                                                defaultValue={defaultRejectionReason}
                                                onChange={(newValues) => {
                                                    setFieldValue(
                                                        'rejectionReasons',
                                                        newValues.map((item) => item.value),
                                                    );
                                                }}
                                            />
                                        </FormGroup>
                                    )}
                                </div>
                            </div>

                            <div className="card-footer d-flex align-items-center justify-content-end">
                                {isSubmitting && <Spinner />}

                                {showConfirm ? (
                                    <>
                                        <LightOutlineButton onClick={() => setShowConfirm(false)}>
                                            Annuleer
                                        </LightOutlineButton>

                                        <WarningButton className="ml-2" type="submit">
                                            Opslaan bevestigen
                                        </WarningButton>
                                    </>
                                ) : (
                                    <>
                                        <LinkButton disabled={isSubmitting} onClick={close}>
                                            Sluiten
                                        </LinkButton>

                                        <WarningButton
                                            disabled={isSubmitting || !isValid || !dirty}
                                            className="ml-2"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                setShowConfirm(true);
                                            }}
                                        >
                                            Beoordeling opslaan&hellip;
                                        </WarningButton>
                                    </>
                                )}
                            </div>
                        </div>
                    </FForm>
                );
            }}
        </Formik>
    );

    function handleSubmit(values) {
        updateTaskTeamAssignment({
            uri: assignment['@id'],
            body: {
                ...values,
            },
        }).then(() => {
            close();
        });
    }

    function getStatusOptions() {
        return [
            {
                value: taskTeamAssignmentStatus.STATUS_REVIEW_IN_PROGRESS,
                label: 'Te beoordelen',
            },
            {
                value: taskTeamAssignmentStatus.STATUS_APPROVED,
                label: 'Goedgekeurd',
            },
            {
                value: taskTeamAssignmentStatus.STATUS_REJECTED,
                label: 'Afgekeurd',
            },
        ];
    }
}

const validationSchema = Yup.object().shape({
    status: Yup.string().required(),
    rejectionReasons: Yup.array().when('status', {
        is: (val) => val === taskTeamAssignmentStatus.STATUS_REJECTED,
        then: (s) => s.min(1),
        otherwise: (s) => s,
    }),
});
