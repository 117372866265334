import { useFormikContext } from 'formik';
import { useGetCategories } from 'pages/documents_v2/views/edit/one_liners/hooks/useGetCategories';
import cx from 'classnames';
import { getBlockHeight } from 'features/documents/documentsV2Slice';
import { useSelector } from 'react-redux';
import { useHeight } from 'hooks/useHeight';
import { MultiSelect } from 'components/Select';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import { VariantFilterContext } from 'pages/documents/misc/AreaLayout';
import { CategoryMetadata } from 'pages/documents_v2/views/edit/one_liners/OneLiners';
import _ from 'lodash';

export function OneLinerCategories() {
    const { values } = useFormikContext();
    const { oneLiners = [] } = values;

    return (
        <div>
            <CategorySelect />

            <div>
                {oneLiners.map((oneLiner, index) => (
                    <OneLiner oneLiner={oneLiner} index={index} key={`one-liner-${oneLiner.id}-view-category`} />
                ))}
            </div>
        </div>
    );
}

function OneLiner({ oneLiner, index }) {
    const leftHeight = useSelector(getBlockHeight(`one_liner_${index}`, 'left'));
    const [divHeight, ref] = useHeight();

    return (
        <div style={{ marginBottom: '2.5rem' }}>
            <div style={{ minHeight: leftHeight > divHeight ? leftHeight : undefined }}>
                <div className="border-top pt-3 px-1" ref={ref}>
                    <div className="d-flex flex-wrap">
                        <RenderOneLinerCategories oneLiner={oneLiner} />
                    </div>
                </div>
            </div>
        </div>
    );
}

function RenderOneLinerCategories({ oneLiner }) {
    const categories = useGetCategories(oneLiner.documentId, false);
    const selectedCategories = categories.filter((category) => oneLiner.categories.includes(category['@id']));
    const { selectedVariants } = useContext(VariantFilterContext);

    if (selectedCategories.length === 0) {
        return null;
    }

    return (
        <>
            {selectedCategories.map((category) => (
                <div
                    className={cx('label mr-2 mb-2', {
                        active: selectedVariants.includes(category['@id']),
                        disabled: selectedVariants.length > 0 && !selectedVariants.includes(category['@id']),
                    })}
                    key={`one-liner-${oneLiner.id}-category-${category.id}`}
                >
                    <div className="label-title">
                        {category.name}
                        <CategoryMetadata category={category} />
                    </div>
                </div>
            ))}
        </>
    );
}

function CategorySelect() {
    const { documentId } = useParams();
    const categories = useGetCategories(parseInt(documentId), false);
    const { setSelectedVariants } = useContext(VariantFilterContext);

    return (
        <div style={{ height: 110 }}>
            <MultiSelect
                label="Markeer categorieën"
                id="select-one-liner-categories"
                options={getOptions()}
                handleChange={handleChange}
                isClearable={true}
                filterOption={filterOptions}
            />
        </div>
    );

    function getOptions() {
        const categoryGroups = categories.filter((category) => category.isGroup);

        return categoryGroups.map((group) => ({
            label: group.name,
            options: categories
                .filter((childCategory) => childCategory.parent && childCategory.parent === group['@id'])
                .map((childCategory) => ({
                    label: renderCategoryLabel(childCategory),
                    value: childCategory['@id'],
                    category: childCategory,
                })),
        }));
    }

    function renderCategoryLabel(category) {
        return (
            <>
                <span>{category.name}</span>
                <CategoryMetadata category={category} showTooltip={false} />
            </>
        );
    }

    function handleChange(newOptions) {
        setSelectedVariants(newOptions.map((option) => option.value));
    }

    function filterOptions({ data }, input) {
        if (_.isEmpty(input)) {
            return true;
        }

        const { category } = data;
        const inputClean = input.toLowerCase();

        // Check category name first
        if (category.name.toLowerCase().includes(inputClean)) {
            return true;
        }

        // Check metadata values
        if (category.metadata.length === 0) {
            return false;
        }

        return category.metadata.some((meta) => meta.value.toLowerCase().includes(inputClean));
    }
}
