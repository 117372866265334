import { useGetTeamsQuery } from 'features/comments/commentApi';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';

const emptyArray = [];

export function useGetTeams(myTeams = false) {
    const organisationId = useActiveOrganisation();

    const params = {
        organisationId,
        'order[name]': 'ASC',
    };

    const { teams } = useGetTeamsQuery(
        { myTeams, params },
        {
            selectFromResult: ({ data }) => ({
                teams: data ?? emptyArray,
            }),
        },
    );

    return teams;
}
