import HelperFunctions from "./HelperFunctions";

let TagHelper;

export default TagHelper = {
    getTagColor(tag) {
        if (tag && tag.hasOwnProperty('color')) {
            return tag.color
        }

        if (tag && tag.hasOwnProperty('labelProps')) {
            const colorProp = HelperFunctions.getByValue(tag.labelProps, 'name', 'color')

            if (colorProp) {
                return colorProp.value
            }
        }

        return 'default'
    },
}
