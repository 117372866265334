export const taskStatus = {
    STATUS_DRAFT: 'draft',
    STATUS_APPROVED: 'approved',
    STATUS_REJECTED: 'rejected',
    STATUS_NO_REVIEW_NEEDED: 'no_review_needed',
    STATUS_REVIEW_IN_PROGRESS: 'review_in_progress',
    STATUS_PAUSED: 'paused',
    STATUS_WITDRAWN: 'withdrawn',
};

export const taskVersionStatus = {
    STATUS_DRAFT: 'draft',
    STATUS_APPROVED: 'approved',
    STATUS_REJECTED: 'rejected',
    STATUS_REVIEW_IN_PROGRESS: 'review_in_progress',
    STATUS_WITDRAWN: 'withdrawn',
};

export const taskTeamAssignmentStatus = {
    STATUS_UNASSIGNED: 'unassigned',
    STATUS_APPROVED: 'approved',
    STATUS_REJECTED: 'rejected',
    STATUS_REVIEW_IN_PROGRESS: 'review_in_progress',
    STATUS_NO_REVIEW_NEEDED: 'no_review_needed',
};

export const fileType = {
    TYPE_EVIDENCE: 'evidence',
    TYPE_MANAGEMENT_DECISION: 'management_decision',
    TYPE_ATTACHMENT: 'attachment',
};
