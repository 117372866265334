export function Notification({ count }: { count: number }) {
    return (
        <div
            style={{
                minWidth: '1.6em',
                height: '1.6em',
                borderRadius: '0.8em',
                backgroundColor: '#db2d36',
                fontSize: '0.8em',
                color: 'white',
            }}
            className="d-flex justify-content-center align-items-center"
        >
            {count}
        </div>
    );
}
