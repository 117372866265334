import { Card } from 'react-bootstrap';
import LoadingSpinner from '../../global/LoadingSpinner';
import { useSelector } from 'react-redux';
import { selectOpListSummary } from '../../../features/operationsList/operationsListSlice';
import { useTranslation } from 'react-i18next';

export default function OpListSummary({ id }) {
    const summary = useSelector((state) => selectOpListSummary(state.operations, id));

    return (
        <Card className="mb-3 op-list-summary">
            <Card.Body>{!summary ? <LoadingSpinner /> : <OpListSummaryContent summary={summary} />}</Card.Body>
        </Card>
    );
}

function OpListSummaryContent({ summary }) {
    const { t } = useTranslation('changelist');
    return (
        <>
            <div className="d-flex mb-2">
                <div>{t('changelist.view.opListSummary.open')}</div>
                <div className="ml-auto">{summary.active ?? 0}</div>
            </div>

            <div className="d-flex mb-2">
                <div>{t('changelist.view.opListSummary.assignedToMe')}</div>
                <div className="ml-auto">{summary.assignedToMe ?? 0}</div>
            </div>

            <div className="d-flex">
                <div>{t('changelist.view.opListSummary.notYetAssigned')}</div>
                <div className="ml-auto">{summary.unassigned ?? 0}</div>
            </div>
        </>
    );
}
