import Constants from 'config/Constants';
import { CaretRightFill, GearFill, Icon1Circle, Link as LinkIcon, StarFill } from 'react-bootstrap-icons';
import cx from 'classnames/bind';
import DocumentHelper from 'pages/global/DocumentHelper';
import _ from 'lodash';
import { Badge } from 'react-bootstrap';
import { MdFormatListNumbered } from 'react-icons/md';
import { BiTrashAlt } from 'react-icons/bi';
import { ImEyeBlocked, ImPageBreak } from 'react-icons/im';
import { FaTags } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useGetLabelEntitiesQuery } from 'features/metadata/metadata';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { set } from 'features/app/appSlice';
import { entityTypes } from 'pages/translation/config/Constants';
import { idToUri } from 'pages/global/UriHelper';
import IconRenderer from 'components/SettingsSidebar/views/AreaIcons/IconRenderer';
import { useTranslation } from 'react-i18next';
import { useGetEntityFromModelVersion } from 'pages/documents_v2/hooks/useEntityTemplateParameters';
import { useGetBaseVariant } from 'pages/documents_v2/hooks/useGetBaseVariant';
import RestrictedContent from 'pages/global/RestrictedContent';
import { useGetSectionOneLiners } from 'pages/documents_v2/views/edit/one_liners/hooks/useGetSectionOneLiners';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { EDIT_AREA_PATH } from 'scenes/DocumentsV2';

export function AreaTitleRow({ area, document, expanded }) {
    const { currentUserStatus } = area;
    const isLinkedArea = area.type === Constants.areaTypes.linkedArea;
    const isTemplate = _.get(area.properties, 'isTemplate', false);
    const isDeleted = area.deletedAt !== null;
    const Icons = area.exportProperties?.areaIcon || [];
    const hasVariants = area.documentVariants.length > 1;
    const isSkipped = area.currentUserStatus.skipped;
    const documentIsTemplate = document.type === 'template' || document.type === 'library';
    const { t } = useTranslation('documents');

    return (
        <div
            className={cx('d-flex align-items-center flex-grow-1', {
                'uk-text-muted': currentUserStatus.skipped,
            })}
        >
            {isLinkedArea && <LinkIcon className="text-muted mr-1" size={20} data-uk-tooltip="Gekoppeld artikel" />}
            {isTemplate && <StarFill className="uk-text-warning mr-1" size={15} />}
            <div
                className={cx(' text-muted mr-2 flex-shrink-0', {
                    'opacity-4': !hasVariants && !documentIsTemplate,
                })}
            >
                <IconRenderer isInsideTileRow={true} className="text-muted" areaIcons={Icons} />
            </div>
            <div
                className={cx('d-flex align-items-center', {
                    'text-muted': isLinkedArea,
                    'uk-text-warning': isTemplate,
                    'font-weight-bold': area.exportProperties.headingLevel === '2',
                    'opacity-4': !hasVariants && !isTemplate && !documentIsTemplate,
                })}
                data-uk-tooltip={isTemplate ? 'Artikeltemplate' : undefined}
            >
                <AreaTitle area={area} document={document} />
            </div>
            {!isDeleted ? (
                <>
                    {area.scheduledForDeletion ? (
                        <Badge variant="secondary" size="6px" className="ml-2 pr-1 p-1">
                            <BiTrashAlt className="text-color mr-1" size={15} />
                            {t('document.navbar.main.badges.scheduledForDeletion')}
                        </Badge>
                    ) : (
                        <>
                            {area.new && (
                                <Badge variant="warning" size="6px" className="ml-2 p-1">
                                    {t('document.navbar.main.badges.new')}
                                </Badge>
                            )}
                            {area.titleEdited && (
                                <Badge variant="warning" size="6px" className="ml-2 p-1">
                                    {t('document.navbar.main.badges.titleEdited')}
                                </Badge>
                            )}
                            {!hasVariants && !isSkipped && !isTemplate && !documentIsTemplate && (
                                <Badge variant="warning" size="6px" className="ml-2 p-1">
                                    {t('document.navbar.main.badges.noVariants')}
                                </Badge>
                            )}
                        </>
                    )}
                    {!area.scheduledForDeletion && <AreaInformation area={area} expanded={expanded} />}
                </>
            ) : (
                <Badge variant="danger" size="6px" className="ml-2 p-1">
                    {t('document.navbar.main.badges.removed')}
                </Badge>
            )}
        </div>
    );
}

function AreaTitle({ area, document }) {
    const entityFromModelVersion = useGetEntityFromModelVersion(document, area, entityTypes.AREA);
    const { sidebarFilters } = useSelector((state) => state.documents);
    const { showArticlePrefix } = sidebarFilters;
    const baseVariant = useGetBaseVariant();

    const titlePrefix = DocumentHelper.getPrefix(area, baseVariant.id);

    return (
        <>
            {titlePrefix && showArticlePrefix && <div className="mr-2 uk-text-xsmall">{titlePrefix}</div>}
            {area.title}

            {showArticlePrefix && <AreaTitleMetadata area={area} />}

            {entityFromModelVersion &&
                entityFromModelVersion.properties.templateParameters.canModifyTitle === true &&
                entityFromModelVersion.title !== area.title && (
                    <div className="text-muted small text-uppercase" style={{ paddingTop: 2 }}>
                        &nbsp;({entityFromModelVersion.title})
                    </div>
                )}
        </>
    );
}

export function AreaTitleMetadata({ area }) {
    const metadataForTitle = getMetadata();

    if (metadataForTitle.length === 0) {
        return null;
    }

    return (
        <div className="ml-1">
            {metadataForTitle.map((item, index) => (
                <div className="badge badge-meta" key={`area-${area.id}-meta-${index}`}>
                    {item.value}
                </div>
            ))}
        </div>
    );

    function getMetadata() {
        const metadata = _.get(area, 'metadata', []);

        return metadata.filter((item) => item.properties?.showInTitleRow === '1');
    }
}

function AreaInformation({ area, expanded }) {
    const { t } = useTranslation('documents');
    const { documentId } = useParams();
    const dispatch = useDispatch();
    const { sidebarFilters } = useSelector((state) => state.documents);

    const { showHeading, showLabels, showNumbering, showPageForcing, showTitleInDoc } = sidebarFilters;

    const autoNumbering = area.exportProperties.useAutoNumbering ?? false;
    const headingLevel = area.exportProperties.headingLevel;
    const forceBreak = area.exportProperties.forceNewPage ?? false;
    const showTitle = area.exportProperties.showTitleInDocument ?? false;
    const isDeleted = area.deletedAt !== null;

    return (
        <>
            {!isDeleted && (
                <CaretRightFill
                    className={cx('flex-shrink-0 ml-3', {
                        'rotate-90': expanded,
                    })}
                    size={12}
                />
            )}
            {showHeading && (
                <div className="uk-label ml-2 mr-1 uk-text-xsmall uk-background-muted uk-text-muted">
                    <span>{'H' + headingLevel}</span>
                </div>
            )}
            {showNumbering && (
                <SettingOverrideIcon
                    enabled={autoNumbering}
                    tooltip={t('document.navbar.main.tooltips.numbering')}
                    icon="numbering"
                    size={20}
                    handleSettingsIconClick={handleSettingsIconClick}
                />
            )}
            {showTitleInDoc && (
                <SettingOverrideIcon
                    enabled={!showTitle}
                    tooltip={t('document.navbar.main.tooltips.showTitle')}
                    icon="showTitleInDoc"
                    size={20}
                    handleSettingsIconClick={handleSettingsIconClick}
                />
            )}
            {showLabels && (
                <AreaHasLabelsIcon
                    areaId={area.id}
                    documentId={parseInt(documentId)}
                    handleSettingsIconClick={handleSettingsIconClick}
                />
            )}
            {showPageForcing && (
                <SettingOverrideIcon
                    enabled={forceBreak}
                    tooltip={t('document.navbar.main.tooltips.forcePage')}
                    icon="pageBreak"
                    size={20}
                    handleSettingsIconClick={handleSettingsIconClick}
                />
            )}

            <RestrictedContent module={Constants.modules['one-liner']}>
                <AreaHasOneLinersIcon areaId={area.id} documentId={parseInt(documentId)} sectionId={area.sectionId} />
            </RestrictedContent>
        </>
    );

    function handleSettingsIconClick(icon) {
        dispatch(
            set({
                key: 'sidebarContext',
                value: {
                    type: 'sidebarSettings',
                    context: {
                        entity: area,
                        entityType: entityTypes.AREA,
                        documentId: parseInt(documentId),
                        uri: idToUri(area.id, entityTypes.AREA),
                        parentUri: idToUri(parseInt(documentId), entityTypes.DOCUMENT),
                        landingTab: icon === 'labels' ? 'labels' : undefined,
                    },
                },
            }),
        );
    }
}

const emptyArray = [];

function AreaHasLabelsIcon({ areaId, documentId, handleSettingsIconClick }) {
    const { t } = useTranslation('documents');
    const activeOrganisation = useActiveOrganisation();

    const { entityLabelEntities } = useGetLabelEntitiesQuery(
        {
            entityType: 'area',
            organisationId: activeOrganisation,
            documentId,
        },
        {
            selectFromResult: ({ data }) => ({
                entityLabelEntities: data ? data : emptyArray,
            }),
        },
    );

    const areaHasLabels = entityLabelEntities.some((labelEntity) => parseInt(labelEntity.entityId) === areaId);

    return (
        <SettingOverrideIcon
            enabled={areaHasLabels}
            tooltip={t('document.navbar.main.tooltips.hasLabels')}
            icon="labels"
            size={20}
            handleSettingsIconClick={handleSettingsIconClick}
        />
    );
}

function AreaHasOneLinersIcon({ areaId, documentId, sectionId }) {
    const history = useHistory();
    const oneLiners = useGetSectionOneLiners(documentId, sectionId);
    const areaHasOneLiner = oneLiners.some((oneLiner) => oneLiner.areaId === areaId);

    if (!areaHasOneLiner) {
        return null;
    }

    return (
        <SettingOverrideIcon
            tooltip={'Het artikel heeft one-liners'}
            enabled={true}
            icon="oneLiners"
            size={16}
            handleSettingsIconClick={handleSettingsIconClick}
        />
    );

    function handleSettingsIconClick() {
        history.push(
            generatePath(EDIT_AREA_PATH, {
                documentId,
                view: 'editOneLiners',
                areaId,
            }),
        );
    }
}

export function SettingOverrideIcon({
    tooltip = '',
    enabled = false,
    icon = 'gear',
    size = 14,
    handleSettingsIconClick = () => {},
}) {
    if (!enabled) {
        return null;
    }

    const icons = {
        gear: GearFill,
        numbering: MdFormatListNumbered,
        labels: FaTags,
        pageBreak: ImPageBreak,
        showTitleInDoc: ImEyeBlocked,
        oneLiners: Icon1Circle,
    };

    const IconComponent = icons[icon];

    return (
        <IconComponent
            data-uk-tooltip={tooltip}
            className={cx('flex-shrink-0 ml-2 uk-text-xsmall', {
                'uk-text-theme-extra-light': !enabled,
                'bg-transparent-orange text-warning': icon === 'numbering' || icon === 'showTitleInDoc', // add this class for the 'numbering' icon
                'text-color bg-transparent-blue': icon === 'labels' || icon === 'pageBreak',
            })}
            size={size}
            onClick={(e) => {
                e.stopPropagation();
                handleSettingsIconClick(icon);
            }}
        />
    );
}
