import { useSelector } from 'react-redux';
import { selectFiles, selectFolderById, selectFolders } from '../../../../features/mediaLibrary/mediaLibrarySlice';
import React from 'react';
import cx from 'classnames';
import _ from 'lodash';

export default function Files({ selectedFolder, selectedMedia, setSelectedMedia }) {
    if (selectedFolder === null) {
        return <AllFiles selectedMedia={selectedMedia} setSelectedMedia={setSelectedMedia} />;
    }

    return (
        <FolderFiles
            selectedFolder={selectedFolder}
            selectedMedia={selectedMedia}
            setSelectedMedia={setSelectedMedia}
        />
    );
}

function AllFiles({ selectedMedia, setSelectedMedia }) {
    const rootFolders = useSelector(selectFolders(null));
    const files = useSelector(selectFiles(null));

    return (
        <>
            {files.length > 0 && (
                <FilesInner folderUri={null} selectedMedia={selectedMedia} setSelectedMedia={setSelectedMedia} />
            )}

            {rootFolders.map((_folder) => (
                <RootFolder
                    folder={_folder}
                    selectedMedia={selectedMedia}
                    setSelectedMedia={setSelectedMedia}
                    key={`media-folder-index-${_folder.id}`}
                />
            ))}
        </>
    );
}

function RootFolder({ folder, selectedMedia, setSelectedMedia }) {
    const childFolders = useSelector(selectFolders(folder['@id']));

    return (
        <div>
            <div className="text-secondary text-uppercase mb-2 mt-3">{folder.name}</div>

            {childFolders.map((_childFolder) => (
                <div className="mb-3" key={`media-folder-index-${_childFolder.id}`}>
                    <div className="font-weight-bold mb-2">{_childFolder.name}</div>

                    <FolderFiles
                        selectedFolder={_childFolder['@id']}
                        selectedMedia={selectedMedia}
                        setSelectedMedia={setSelectedMedia}
                    />
                </div>
            ))}
        </div>
    );
}

function FolderFiles({ selectedFolder, selectedMedia, setSelectedMedia }) {
    const folder = useSelector(selectFolderById(selectedFolder));

    return (
        <FilesInner
            folderUri={folder !== undefined ? folder['@id'] : selectedFolder}
            selectedMedia={selectedMedia}
            setSelectedMedia={setSelectedMedia}
        />
    );
}

function FilesInner({ folderUri, setSelectedMedia, selectedMedia }) {
    const files = useSelector(selectFiles(folderUri));

    if (folderUri === undefined) {
        return null;
    }

    return (
        <div>
            {files.length === 0 && <div className="text-muted small mt-3">Geen bestanden gevonden in deze map.</div>}

            <div className="d-flex flex-wrap">
                {files.map((_file) => (
                    <File
                        file={_file}
                        selectedMedia={selectedMedia}
                        setSelectedMedia={setSelectedMedia}
                        key={`media-files-${_file.id}`}
                    />
                ))}
            </div>
        </div>
    );
}

function File({ file, setSelectedMedia, selectedMedia }) {
    const isSelected = file['@id'] === _.get(selectedMedia, '@id');
    const toggleMedia = () => {
        setSelectedMedia(isSelected ? null : file);
    };

    return (
        <div
            className={cx('media-selector-img-wrapper', {
                active: isSelected,
            })}
            style={{ position: 'relative' }}
        >
            <div
                className="media-selector-img"
                onClick={toggleMedia}
                style={{
                    width: '100%',
                    height: 200,
                }}
            >
                <img
                    className="image"
                    src={file.thumbnailSquare}
                    alt={file.description}
                    data-uk-tooltip={file.description ?? ''}
                />
                <div
                    className="text-left text-muted small mt-1 px-1"
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        padding: '8px',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                        overflow: 'hidden',
                        wordBreak: 'break-all',
                    }}
                >
                    <span className="text-darkBlueAlt">{file.description && <span>{file.description}</span>}</span>
                    <div style={{ color: '#a0a2b3' }}>{file.displayName}</div>
                </div>
            </div>
        </div>
    );
}
