import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use({
    type: 'backend',
    read: (language, namespace, callback) => {
        import(`./language/locales/${language}/${namespace}.json`)
            .then((resources) => {
                callback(null, resources);
            })
            .catch((error) => {
                callback(error, null);
            });
    },
})
    .use(LanguageDetector)
    .use(initReactI18next)

    .init({
        debug: false,
        fallbackLng: 'nl',
        lng: localStorage.getItem('i18nextLng') || 'nl',
        ns: [
            'changelist',
            'documents',
            'global',
            'labels',
            'publications',
            'reimbursements',
            'translations',
            'users',
            'media',
        ],
        defaultNS: 'global',
    });

export default i18n;
