export const views = {
    CHANGES: 'changes',
    VARIANTS: 'variants',
    BLOCK_COMPARE: 'block_compare',
    ONE_LINER_CATEGORIES: 'one_liner_categories',
    TEMPLATE: 'template',
    PREVIEW: 'preview',
};

export const actions = {
    EDIT: 'editArea',
    EDIT_NEW: 'editAreaNew',
    CHECK: 'checkArea',
    VIEW: 'viewArea',
    EDIT_ONE_LINERS: 'editOneLiners',
};
