import { Modal, Nav } from 'react-bootstrap';
import { BooleanParam, NumberParam, StringParam, useQueryParam, useQueryParams } from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { Form as FForm, Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import cx from 'classnames';
import Spinner from 'pages/global/Spinner';
import { ActionLinkButton, LightOutlineButton, WarningButton } from 'components/Buttons';
import { Step1General } from 'pages/task_team/modals/add_check_modal/Step1General';
import { Step2Properties } from 'pages/task_team/modals/add_check_modal/Step2Properties';
import { Step3Result } from 'pages/task_team/modals/add_check_modal/Step3Result';
import { ArrowLeftShort, CheckCircleFill, QuestionCircleFill } from 'react-bootstrap-icons';
import { Step4Files } from 'pages/task_team/modals/add_check_modal/Step4Files';
import { Step5Finish } from 'pages/task_team/modals/add_check_modal/Step5Finish';
import {
    commentsApi,
    useAddTaskMutation,
    useSubmitTaskMutation,
    useUpdateTaskMutation,
} from 'features/comments/commentApi';
import { prepareTaskFormData } from 'pages/task_team/helpers/FormHelper';
import { useCallback, useEffect, useState } from 'react';
import { useGetTask } from 'pages/task_team/hooks/useGetTask';
import HelperFunctions from 'pages/global/HelperFunctions';
import { taskStatus } from 'pages/task_team/config/constants';
import { NoPermissionsModal } from 'pages/task_team/modals/NoPermissionsModal';
import { useHasCreateTaskPermission } from 'pages/task_team/hooks/permissions';
import { DateTime } from 'luxon';
import { Step6Submit } from 'pages/task_team/modals/add_check_modal/Step6Submit';

export function AddCheckModal() {
    const [taskId] = useQueryParam('tid', StringParam);
    const [addCheck] = useQueryParam('addCheck', BooleanParam);

    if (!addCheck) {
        return null;
    }

    return <AddCheckModalWrapper taskId={taskId} />;
}

function AddCheckModalWrapper({ taskId }) {
    const [steps] = useState(generateSteps());
    const [, setQuery] = useQueryParams({ tid: StringParam, addCheck: BooleanParam, step: NumberParam });
    const hasCreateTaskPermission = useHasCreateTaskPermission();
    const task = useGetTask(taskId);

    const close = useCallback(() => {
        setQuery({
            tid: undefined,
            addCheck: undefined,
        });
    }, []);

    if (!hasCreateTaskPermission) {
        return <NoPermissionsModal title="Nieuwe Oké-check" close={close} />;
    }

    return (
        <Modal show={true} onHide={close} size="xl" backdrop="static" keyboard={false}>
            {(!taskId || task) && <AddModalInner steps={steps} initialValues={getInitialValues(task)} close={close} />}
        </Modal>
    );
}

function AddModalInner({ steps = [], initialValues, close }) {
    const { t } = useTranslation();
    const [submitTask] = useSubmitTaskMutation();
    const [currentIndex, setCurrentIndex] = useState(0);

    const step = steps[currentIndex];

    const handleNavSelect = useCallback((newIndex) => {
        setCurrentIndex(newIndex);
    }, []);

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            validationSchema={getStepSchema(currentIndex, steps)}
        >
            <FForm>
                <ModalHeader />
                <RenderNav steps={steps} activeKey={step.id} handleNavSelect={handleNavSelect} />
                <RenderFormSteps step={step} currentIndex={currentIndex} close={close} goBack={goBack} />
            </FForm>
        </Formik>
    );

    function goNext() {
        setCurrentIndex((oldIndex) => oldIndex + 1);
    }

    function goBack() {
        setCurrentIndex((oldIndex) => oldIndex - 1);
    }

    function handleSubmit(values, { setSubmitting }) {
        if (step.last) {
            // Save
            submitTask({ uri: values['@id'], body: prepareTaskFormData(values) }).then(() => {
                close();
            });
        } else {
            goNext();
            setSubmitting(false);
        }
    }
}

function ModalHeader() {
    const { values, dirty, initialValues } = useFormikContext();
    const isDraft = values.hasOwnProperty('id');

    return (
        <Modal.Header className="align-items-center">
            <Modal.Title>
                Nieuwe OKÉ-check
                {isDraft && <> - {initialValues.title}</>}
            </Modal.Title>

            {isDraft && !dirty && (
                <div className="flex-center text-success small">
                    <CheckCircleFill className="mr-1" />
                    concept opgeslagen
                </div>
            )}
            {isDraft && dirty && (
                <div className="flex-center text-muted small">
                    <QuestionCircleFill className="mr-1" />
                    wijzigingen nog niet opgeslagen
                </div>
            )}
        </Modal.Header>
    );
}

function RenderFormSteps({ step, currentIndex, close, goBack }) {
    const { values, dirty } = useFormikContext();
    const organisationId = useActiveOrganisation();
    const [addTask] = useAddTaskMutation();
    const [updateTask] = useUpdateTaskMutation();
    const [getTask] = commentsApi.useLazyGetTaskQuery();
    const [, setTaskId] = useQueryParam('tid', StringParam);

    const isDraft = values.hasOwnProperty('id');

    useEffect(() => {
        if (currentIndex > 0 || isDraft) {
            if (isDraft === false) {
                createDraft();
            } else {
                if (dirty) {
                    updateDraft();
                }
            }
        }
    }, [currentIndex]);

    // useEffect(() => {
    //     let timeout;
    //
    //     if (isDraft && dirty === true) {
    //         // Auto-save draft after 3 seconds
    //         timeout = setTimeout(() => {
    //             updateDraft();
    //         }, 3000);
    //     } else {
    //         clearTimeout(timeout);
    //     }
    //
    //     return () => clearTimeout(timeout);
    // }, [dirty]);

    return (
        <>
            <Modal.Body>
                <div className="p-3">
                    {steps.map((step, index) => (
                        <RenderStep active={currentIndex === index} step={step} key={`new-check-step-${index}`} />
                    ))}
                </div>
            </Modal.Body>

            <FooterButtons step={step} currentIndex={currentIndex} onHide={saveAndClose} goBack={goBack} />
        </>
    );

    function saveAndClose() {
        if (isDraft && dirty) {
            updateDraft();
        }

        close();
    }

    function createDraft() {
        const formData = {
            ...prepareTaskFormData(values),
            organisationId,
        };

        addTask(formData).then(({ data }) => {
            getTask(data.id).then(() => {
                setTaskId(data.id);
            });
        });
    }

    function updateDraft() {
        updateTask({ uri: values['@id'], body: prepareTaskFormData(values) });
    }
}

function RenderStep({ active = false, step }) {
    const StepComponent = step.component;

    return (
        <div className={cx({ 'd-none': !active })}>
            <StepComponent active={active} step={step} />
        </div>
    );
}

function RenderNav({ steps = [], activeKey, handleNavSelect }) {
    return (
        <div className="px-3 py-1">
            <Nav fill variant="tabs" defaultActiveKey="general" onSelect={handleSelect} activeKey={activeKey}>
                {steps.map((step, index) => (
                    <Nav.Item key={`new-check-nav_step-${index}`}>
                        <RenderStepNav steps={steps} step={step} index={index} />
                    </Nav.Item>
                ))}
            </Nav>
        </div>
    );

    function handleSelect(eventKey) {
        const selectedStepIndex = steps.findIndex((step) => step.id === eventKey);

        if (selectedStepIndex >= 0) {
            handleNavSelect(selectedStepIndex);
        }
    }
}

function RenderStepNav({ steps = [], step, index }) {
    const [isValid, setIsValid] = useState(index === 0);
    const { values } = useFormikContext();

    useEffect(() => {
        // validate
        async function validateStep() {
            const validationSchemas = getPreviousStepSchemas(index, steps);

            if (validationSchemas.length > 0) {
                try {
                    for (const validationSchemasKey in validationSchemas) {
                        const validationSchema = validationSchemas[validationSchemasKey];
                        await validationSchema().validate(values);
                        setIsValid(true);
                    }
                } catch (err) {
                    setIsValid(false);
                }
            }
        }

        if (index > 0) {
            validateStep();
        }
    }, [index, values]);

    return (
        <Nav.Link eventKey={step.id} disabled={!isValid} className="px-2">
            {step.title}
        </Nav.Link>
    );
}

function FooterButtons({ step, currentIndex, onHide, goBack }) {
    const { t } = useTranslation();
    const [updateTask] = useUpdateTaskMutation();
    const { isSubmitting, values, isValid, validateForm } = useFormikContext();

    const nextStep = step.last ? undefined : steps[currentIndex + 1];
    const abort = values.numberOfRecipients === 'one' || values.contentType === 'textual';
    const showCloseButton = currentIndex > 0 && abort;

    useEffect(() => {
        validateForm();
    }, [currentIndex, validateForm]);

    return (
        <Modal.Footer>
            {!showCloseButton && (
                <LightOutlineButton disabled={isSubmitting} onClick={onHide} className="mr-auto">
                    Sluiten
                </LightOutlineButton>
            )}

            {isSubmitting && <Spinner />}

            <ActionLinkButton
                disabled={isSubmitting || currentIndex === 0}
                onClick={goBack}
                className="d-flex align-items-center mr-3"
            >
                <ArrowLeftShort className="mr-1" /> Vorige
            </ActionLinkButton>

            {showCloseButton ? (
                <WarningButton onClick={saveTaskForReport}>Sluiten</WarningButton>
            ) : (
                <WarningButton disabled={isSubmitting || !isValid} type="submit">
                    {step.last ? 'OKÉ-check indienen' : `Volgende stap: ${nextStep.title}`}
                </WarningButton>
            )}
        </Modal.Footer>
    );

    function saveTaskForReport() {
        updateTask({
            uri: values['@id'],
            body: {
                ...prepareTaskFormData(values),
                status: taskStatus.STATUS_NO_REVIEW_NEEDED,
            },
        });

        onHide();
    }
}

const steps = [
    {
        id: 'general',
        title: 'Algemeen',
        last: false,
        component: Step1General,
        validationSchema: generalSchema,
    },
    {
        id: 'properties',
        title: 'Gegevens',
        last: false,
        component: Step2Properties,
        validationSchema: propertiesSchema,
        previousValidationSchemas: [generalSchema],
    },
    {
        id: 'result',
        title: 'Uitslag & checklist',
        last: false,
        component: Step3Result,
        validationSchema: propertiesSchema,
        previousValidationSchemas: [generalSchema, propertiesSchema],
    },
    {
        id: 'files',
        title: 'Bestanden toevoegen',
        last: false,
        component: Step4Files,
        validationSchema: filesSchema,
        previousValidationSchemas: [generalSchema, propertiesSchema],
    },
    {
        id: 'finish',
        title: 'Evidence & herbeoordelen',
        last: false,
        component: Step5Finish,
        validationSchema: evidenceSchema,
        previousValidationSchemas: [generalSchema, propertiesSchema, filesSchema],
    },
    {
        id: 'submit',
        title: 'OKÉ-check indienen',
        last: true,
        component: Step6Submit,
        validationSchema: evidenceSchema,
        previousValidationSchemas: [generalSchema, propertiesSchema, filesSchema, evidenceSchema],
    },
];

function generalSchema() {
    return Yup.object({
        title: Yup.string().required(),
        client: Yup.string().required(),
        department: Yup.string().required(),
        numberOfRecipients: Yup.string().required(),
        contentType: Yup.string().required(),
    });
}

function propertiesSchema() {
    return Yup.object({
        topics: Yup.array().min(1),
        targetAudiences: Yup.array().min(1),
        brands: Yup.array().min(1),
        startDate: Yup.string().required(),
        deadline: Yup.string().required(),
        description: Yup.string().required(),
        taskType: Yup.string().required(),
        customerJourney: Yup.string().required(),
    });
}

export function filesSchema() {
    return Yup.object().shape(
        {
            content: Yup.string().when('fileCount', {
                is: 0,
                then: Yup.string().required(),
                otherwise: Yup.string(),
            }),
            fileCount: Yup.number().when('content', {
                is: '',
                then: Yup.number().min(1),
                otherwise: Yup.number(),
            }),
        },
        ['content', 'fileCount'],
    );
}

function evidenceSchema() {
    return Yup.object({
        preApprovedBy: Yup.array(),
        reEvaluationAmount: Yup.number().when('reEvaluation', {
            is: (val) => val === true,
            then: (s) => s.required(),
            otherwise: (s) => s,
        }),
        reEvaluationStartDate: Yup.string().when('reEvaluation', {
            is: (val) => val === true,
            then: (s) => s.required(),
            otherwise: (s) => s,
        }),
    });
}

function generateSteps() {
    return steps;
}

function getStepSchema(currentIndex, steps) {
    return steps[currentIndex].validationSchema;
}

function getPreviousStepSchemas(currentIndex, steps) {
    return steps[currentIndex].previousValidationSchemas;
}

function getInitialValues(task) {
    if (task) {
        return {
            ...task,
            department: task.department ? task.department['@id'] : '',
            customerJourney: task.customerJourney ? task.customerJourney['@id'] : '',
            taskType: task.taskType ? task.taskType['@id'] : '',
            deadline: task.deadline ? HelperFunctions.formatTimestampForInput(task.deadline) : '',
            startDate: task.startDate ? HelperFunctions.formatTimestampForInput(task.startDate) : '',
            publicationDate: task.publicationDate ? HelperFunctions.formatTimestampForInput(task.publicationDate) : '',
            brands: task.brands ? task.brands.map((brand) => brand['@id']) : [],
            topics: task.topics ? task.topics.map((topic) => topic['@id']) : [],
            targetAudiences: task.targetAudiences
                ? task.targetAudiences.map((targetAudience) => targetAudience['@id'])
                : [],
            taskTeamAssignments: task.taskTeamAssignments
                ? task.taskTeamAssignments.map((taskTeamAssignment) => taskTeamAssignment['@id'])
                : [],
            taskVersions: task.taskVersions ? task.taskVersions.map((taskVersion) => taskVersion['@id']) : [],
            reEvaluationStartDate: task.reEvaluationStartDate
                ? HelperFunctions.formatTimestampForInput(task.reEvaluationStartDate)
                : '',
            fileCount: 0, // dummy property
        };
    }

    return {
        title: '',
        client: '',
        contentType: '',
        explicitConsent: false,
        confidential: false,
        department: '',
        customerJourney: '',
        numberOfRecipients: '',
        topics: [],
        targetAudiences: [],
        brands: [],
        fileCount: 0, // dummy property
        startDate: DateTime.now().toISO(),
        deadline: '',
        publicationDate: '',
        description: '',
        taskType: '',
        preApprovedBy: [],
        reEvaluation: false,
        reEvaluationAmount: 0,
        reEvaluationStartDate: '',
        content: '',
    };
}
