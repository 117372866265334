import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col, Container, Dropdown, Row, SplitButton } from 'react-bootstrap';
import { fetchOperationsLists, removeOpList } from '../../features/operationsList/operationsListSlice';
import MainContentNav from '../Navbar';
import NewOpListModal from './view/NewOpListModal';
import { Building, GearWideConnected, PeopleFill } from 'react-bootstrap-icons';
import { generatePath, useHistory } from 'react-router-dom';
import HelperFunctions from '../global/HelperFunctions';
import RestrictedContent from '../global/RestrictedContent';
import { Permissions } from '../../config/Constants';
import CloneApi from '../../api/CloneApi';
import { VIEW_PATH } from '../../scenes/OperationsList';
import { useGetOpLists } from './hooks/useGetOpLists';
import { useTranslation } from 'react-i18next';
import { useGetUserFullName } from '../../hooks/useGetUserFullName';

export default function Index() {
    const dispatch = useDispatch();
    const opLists = useGetOpLists();
    const [showNewOpListModal, setShowNewOpListModal] = useState(false);
    const hasDuplicatingOpLists = opLists.filter((_opList) => _opList.cloneTaskId !== null).length > 0;
    const { t } = useTranslation('changelist');

    const history = useHistory();

    useEffect(() => {
        if (hasDuplicatingOpLists) {
            const interval = setInterval(() => {
                // Refresh every 2 seconds when there are duplicating lists
                dispatch(fetchOperationsLists());
            }, 2000);

            return () => clearInterval(interval);
        }
    }, [dispatch, hasDuplicatingOpLists]);

    return (
        <>
            <MainContentNav title={t('breadcrumb')}>
                <RestrictedContent permission={Permissions.OperationList['Write.All']}>
                    <Button variant="info" className="mr-3" onClick={() => setShowNewOpListModal(true)}>
                        {t('btn.newChangeList')}
                    </Button>
                </RestrictedContent>
            </MainContentNav>

            <div className="bg-light w-100 px-5" style={{ paddingTop: 73 }}>
                <Container>
                    <Row>
                        <Col className="pt-5">
                            {opLists.map((_opList) => (
                                <OpList opList={_opList} key={_opList.id} />
                            ))}
                        </Col>
                    </Row>
                </Container>
            </div>

            <NewOpListModal
                handleClose={(newOplist) => {
                    if (newOplist) {
                        history.push(
                            generatePath(VIEW_PATH, {
                                id: newOplist.id,
                            })
                        );
                    } else {
                        setShowNewOpListModal(false);
                    }
                }}
                showModal={showNewOpListModal}
            />
        </>
    );
}

function OpList({ opList }) {
    const { cloneTaskId = null } = opList;
    const creator = useGetUserFullName(opList.createdBy);
    const history = useHistory();
    const { t } = useTranslation('changelist');

    const dispatch = useDispatch();

    const handleClick = () => {
        history.push(
            generatePath(VIEW_PATH, {
                id: opList.id,
            })
        );
    };

    const duplicateOpList = () => {
        HelperFunctions.confirmModal(
            t('btn.dropdown.duplicate', { name: opList.name }),
            undefined,
            false,
            t('btn.dropdown.yesDuplicate'),
            t('btn.dropdown.cancel')
        ).then(() => {
            CloneApi.createCloneTask('op_lists', opList.id).then(() => {
                // Reload Lists to get new status
                dispatch(fetchOperationsLists());
            });
        });
    };

    const deleteOpList = () => {
        HelperFunctions.confirmModal(
            t('btn.dropdown.delete', { name: opList.name }),
            'danger',
            false,
            t('btn.dropdown.yesDelete'),
            t('btn.dropdown.cancel')
        ).then(() => dispatch(removeOpList(opList)));
    };

    return (
        <Row className="dr-container mb-3">
            <Col md={6}>
                <div className="py-3 pl-2">
                    <div className="font-weight-bolder mb-1">{opList.name}</div>
                    <div className="small">{t('changelist.createdBy', { creator: creator })}</div>

                    {cloneTaskId !== null && (
                        <div className="d-flex align-items-center mt-3">
                            <GearWideConnected size={18} className="icon-spin text-warning mr-1" />
                            <div className="text-warning small">{t('changelist.busyDuplicating')}</div>
                        </div>
                    )}
                </div>
            </Col>
            <Col md={4}>
                <div className="py-3">
                    <div className="d-flex">
                        <div className="px-3">
                            <div className="text-uppercase mb-2">{t('changelist.departments')}</div>
                            <div className="d-flex align-items-center mb-1">
                                <Building size={18} className="text-muted mr-2" />
                                <div className="text-body" style={{ fontSize: '1rem' }}>
                                    {opList.departments.length}
                                </div>
                            </div>
                        </div>

                        <div className="px-3">
                            <div className="text-uppercase mb-2">{t('changelist.teamMembers')}</div>
                            <div className="d-flex align-items-center mb-1">
                                <PeopleFill size={18} className="text-muted mr-2" />
                                <div className="text-body" style={{ fontSize: '1rem' }}>
                                    {opList.opListUsers.length}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
            <Col>
                <div className="pt-4 pb-3">
                    {/* Only admin is allowed to delete the opList */}
                    {opList.userIsAdmin ? (
                        <SplitButton
                            id={`opList-btn-${opList.id}`}
                            title={t('changelist.btn.open')}
                            onClick={handleClick}
                            menuAlign="right"
                        >
                            <Dropdown.Item onClick={duplicateOpList}>{t('changelist.duplicate')}&hellip;</Dropdown.Item>
                            <Dropdown.Item onClick={deleteOpList}>
                                <span className="text-danger">{t('changelist.delete')}&hellip;</span>
                            </Dropdown.Item>
                        </SplitButton>
                    ) : (
                        <Button onClick={handleClick}>{t('changelist.btn.open')}</Button>
                    )}
                </div>
            </Col>
        </Row>
    );
}
