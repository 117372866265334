import { PrimaryButton, SecondaryButton } from 'components/Buttons';
import { LOGOUT_PATH } from 'App';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Spinner from 'pages/global/Spinner';
import { useUserProfile } from 'hooks/useUserProfile';
import { useCreateCustomerPortalSessionMutation } from 'features/security/authApi';
import React, { useState } from 'react';
import { Permissions } from 'config/Constants';
import RestrictedContent from 'pages/global/RestrictedContent';
import { Modal } from 'react-bootstrap';

export const ACCOUNT_CANCELLED_PATH = '/account-cancelled';

export function AccountCancelled() {
    const history = useHistory();
    const { t } = useTranslation('global');

    const userProfile = useUserProfile();
    const [createCustomerPortalSession] = useCreateCustomerPortalSessionMutation();
    const [isSubmitting, setSubmitting] = useState(false);

    return (
        <Modal show={true} size="lg">
            <div className="modal-body">
                <div className="mb-4">This account is no longer active.</div>

                <div className="d-flex align-items-center">
                    <RestrictedContent permission={Permissions.Account['Billing.Manage']}>
                        <PrimaryButton
                            disabled={isSubmitting}
                            className="mr-2"
                            onClick={() => {
                                setSubmitting(true);

                                createCustomerPortalSession({
                                    accountId: userProfile.accountId,
                                    returnUrl: window.location.href,
                                }).then(({ data }) => {
                                    if (data.hasOwnProperty('url')) {
                                        window.location.href = data.url;
                                    }

                                    setSubmitting(false);
                                });
                            }}
                        >
                            Open Customer Portal
                        </PrimaryButton>
                    </RestrictedContent>

                    <SecondaryButton
                        className="mr-2"
                        onClick={() => {
                            history.push(LOGOUT_PATH);
                        }}
                    >
                        {t('avatar.logout')}
                    </SecondaryButton>

                    {isSubmitting && <Spinner />}
                </div>
            </div>
        </Modal>
    );
}
