import { Button, Col, Form, Row } from 'react-bootstrap';
import cx from 'classnames';
import { ArrowDownCircleFill, ChevronRight } from 'react-bootstrap-icons';
import { useGetDocument } from '../../documents_v2/hooks/useGetDocument';
import EditorHelper from '../../global/EditorHelper';
import { findDeep } from 'deepdash-es/standalone';
import { DocRevSelect } from '../../publications_v2/helpers/FieldHelper';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

export function TagRow({
    item = undefined,
    variant,
    isOverride,
    handleFormChange,
    defaultItem,
    documentId,
    toggleVariantItem,
    copyItem,
    baseVariantId,
}) {
    const { variantGroup } = variant;
    const itemHasOverride = isOverride && item !== undefined;
    const { t } = useTranslation('documents');

    return (
        <Row
            className={cx('no-gutters', {
                'mb-2': !variantGroup,
                'mb-3': variantGroup,
            })}
        >
            <Col sm={variantGroup ? undefined : 2}>
                <div
                    className={cx('d-flex align-items-center', {
                        'pt-1': !variantGroup,
                    })}
                >
                    {isOverride && !variantGroup ? (
                        <div className="d-flex align-items-center">
                            <Form.Check
                                name={`override-${variant.id}`}
                                id={`override-${variant.id}`}
                                checked={itemHasOverride}
                                onChange={(event) => toggleVariantItem(event.target.checked, variant)}
                            />
                            <Form.Label htmlFor={`override-${variant.id}`} className="mb-0">
                                {variant.prefix && (
                                    <span
                                        className={cx('small', {
                                            'text-muted': !itemHasOverride,
                                            'text-color': itemHasOverride,
                                        })}
                                    >
                                        {variant.prefix}&nbsp;
                                    </span>
                                )}
                                <span
                                    className={cx({
                                        'text-muted': !itemHasOverride,
                                    })}
                                >
                                    {variant.parentId === null
                                        ? t('document.navbar.tags.editModal.modal.basicVariant')
                                        : variant.name}
                                </span>
                            </Form.Label>
                        </div>
                    ) : (
                        <>
                            <div
                                className={cx({
                                    'font-weight-bold text-color': variantGroup,
                                    'pt-1': !variantGroup,
                                })}
                            >
                                {variant.prefix && <span className="text-color small">{variant.prefix}&nbsp;</span>}
                                {variant.parentId === null
                                    ? t('document.navbar.tags.editModal.modal.basicVariant')
                                    : variant.name}
                            </div>
                        </>
                    )}

                    {variant.id === baseVariantId && (item !== undefined || isOverride === false) && (
                        <Button
                            variant="link"
                            className="ml-2 p-0"
                            onClick={() => copyItem(item)}
                            data-uk-tooltip={t('document.navbar.tags.editModal.modal.copyBasic')}
                        >
                            <ArrowDownCircleFill />
                        </Button>
                    )}
                </div>
            </Col>

            {!variantGroup && (
                <>
                    <Col className="pr-3">
                        <Form.Control
                            as="textarea"
                            id={`text-${variant.id}`}
                            rows={2}
                            value={isOverride === false || itemHasOverride ? item?.text ?? '' : defaultItem?.text ?? ''}
                            readOnly={isOverride === true && itemHasOverride === false}
                            onChange={(e) => handleFormChange(e.target.value, 'text', variant.id)}
                        />
                    </Col>
                    <Col className="pr-3">
                        <Form.Control
                            as="textarea"
                            id={`url-${variant.id}`}
                            rows={2}
                            type="url"
                            placeholder="https://"
                            value={isOverride === false || itemHasOverride ? item?.url ?? '' : defaultItem?.url ?? ''}
                            readOnly={isOverride === true && itemHasOverride === false}
                            onChange={(e) => handleFormChange(e.target.value, 'url', variant.id)}
                        />
                    </Col>
                    <Col className="pr-3">
                        <Form.Control
                            as="textarea"
                            id={`tooltip-${variant.id}`}
                            rows={2}
                            type="text"
                            value={
                                isOverride === false || itemHasOverride
                                    ? item?.tooltip ?? ''
                                    : defaultItem?.tooltip ?? ''
                            }
                            readOnly={isOverride === true && itemHasOverride === false}
                            onChange={(e) => handleFormChange(e.target.value, 'tooltip', variant.id)}
                        />
                    </Col>
                    <Col>
                        <TagRowCrossRef
                            handleFormChange={handleFormChange}
                            documentId={documentId}
                            variantId={variant.id}
                            item={item}
                            readOnly={isOverride === true && itemHasOverride === false}
                        />
                    </Col>
                </>
            )}
        </Row>
    );
}

function TagRowCrossRef({ handleFormChange, documentId, variantId, item, readOnly }) {
    const document = useGetDocument(undefined, true);
    const sections = _.get(document, 'sections', []);

    const getSelectedEntity = () => {
        // item already has crossReference
        if (item && item.crossReference) {
            const [entityIdString, entityType] = EditorHelper.parseCrossReferenceTag(item.crossReference);
            const entityId = parseInt(entityIdString);

            if (entityType === 'section') {
                return [sections.find((_section) => _section.deletedAt === null && _section.id === entityId)];
            }

            if (entityType === 'area') {
                const selectedArea = findDeep(
                    sections,
                    (value) => {
                        return value.deletedAt === null && value.id === entityId;
                    },
                    {
                        childrenPath: 'areas',
                    }
                );

                if (selectedArea) {
                    return [selectedArea.value, selectedArea.parent];
                }
            }
        }

        return [];
    };

    const [selectedEntity, selectedEntityParent] = getSelectedEntity();

    return (
        <>
            <DocRevSelect
                name={`variant-${variantId}-crossRef-entity`}
                selectedValue={selectedEntity?.id}
                options={sections
                    .filter((_section) => _section.deletedAt === null)
                    .map((_section) => ({
                        label: _section.title,
                        options: [
                            {
                                label: _section.title,
                                value: _section.id,
                                type: 'section',
                            },
                        ].concat(
                            _section.areas
                                .filter((_area) => _area.deletedAt === null)
                                .map((_area) => ({
                                    label: '- ' + _area.title,
                                    value: _area.id,
                                    type: 'area',
                                }))
                        ),
                    }))}
                onChange={(selection) => {
                    if (selection) {
                        handleFormChange(
                            EditorHelper.createCrossReferenceTag(selection.value, selection.type, documentId),
                            'crossReference',
                            variantId
                        );
                    } else {
                        handleFormChange(null, 'crossReference', variantId);
                    }
                }}
                props={{
                    isClearable: true,
                    placeholder: '',
                    isDisabled: readOnly,
                    isCrsf: true,
                }}
            />

            {selectedEntity && selectedEntityParent && selectedEntityParent.title && (
                <div className="form-text text-muted pl-1" style={{ fontSize: 11 }}>
                    {selectedEntityParent.title}
                    <ChevronRight size={12} style={{ margin: '0 2px' }} />
                    {selectedEntity.title}
                </div>
            )}
        </>
    );
}
