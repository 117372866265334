import { TeamSidebar } from 'pages/task_team/views/teams/TeamSidebar';
import { TeamUsers } from 'pages/task_team/views/teams/TeamUsers';
import { Settings } from 'pages/task_team/views/teams/Settings';
import { useParams } from 'react-router-dom';
import { useGetTeam } from 'pages/task_team/hooks/useGetTeam';
import { ReactTitle } from 'components/ReactTitle';
import { TeamRules } from 'pages/task_team/views/teams/TeamRules';
import { Checklist } from 'pages/task_team/views/teams/Checklist';

const RenderPage = {
    users: TeamUsers,
    rules: TeamRules,
    checklist: Checklist,
    settings: Settings,
};

export function Team() {
    const { id, page } = useParams();
    const team = useGetTeam(id);
    const PageToRender = RenderPage[page] ?? PageNotFound;

    return (
        <>
            {team && <ReactTitle title={`Teams - ${team.name}`} />}

            <div className="content-sidebar-document overflow-auto bg-light">
                <TeamSidebar team={team} />
            </div>

            <div className="overflow-auto w-100">
                <div className="mt-5 mx-5 d-flex">
                    <div className="w-100">
                        <div className="dr-container p-5 mb-4">{team && <PageToRender team={team} />}</div>
                    </div>
                </div>
            </div>
        </>
    );
}

function PageNotFound() {
    return <div>Page not found</div>;
}
