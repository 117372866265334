import { useTranslation } from 'react-i18next';
import { ExclamationTriangleFill, Grid, Trash } from 'react-bootstrap-icons';
import SettingsSidebarButton from 'components/SettingsSidebar/SettingsSidebarButton';
import { entityTypes } from 'pages/translation/config/Constants';
import Parser from 'html-react-parser';
import { useState } from 'react';
import cx from 'classnames';
import { WysiwygEditor } from 'pages/task_team/helpers/FormHelper';
import { BlockHeightWrapper } from 'pages/documents_v2/views/edit_area/blocks/BlockHeightWrapper';
import { useGetDocument } from 'pages/documents_v2/hooks/useGetDocument';
import HelperFunctions from 'pages/global/HelperFunctions';
import _ from 'lodash';

export function AreaOneLiner({ oneLiner, index, remove }) {
    if (oneLiner.deleted === true) {
        return null;
    }

    return (
        <div className="edit-area-block edit-area-block-type-text d-flex align-items-start">
            <BlockHeightWrapper blockId={`one_liner_${index}`} editorDisplaySection="left">
                <>
                    <Toolbar oneLiner={oneLiner} remove={remove} />

                    <div className="edit-area-block-content bg-white">
                        <EditableOneLiner oneLiner={oneLiner} index={index} />
                    </div>
                </>
            </BlockHeightWrapper>

            <SettingsIcon oneLiner={oneLiner} />
        </div>
    );
}

function EditableOneLiner({ oneLiner, index }) {
    const document = useGetDocument();
    const { t } = useTranslation('documents');
    const [showEditor, setShowEditor] = useState(false);

    return (
        <div
            className={cx('editor-wrapper', {
                active: showEditor,
                'cursor-pointer': !showEditor,
            })}
            onClick={() => setShowEditor(true)}
        >
            {showEditor ? (
                <div>
                    <WysiwygEditor
                        name={`oneLiners[${index}].content`}
                        focusOnInit={true}
                        onBlur={() => setShowEditor(false)}
                        toolbar="one_liner"
                        configOverride={getConfigOverride()}
                    />
                </div>
            ) : (
                <div className="tiny-mce-content-body">{Parser(getContent())}</div>
            )}
        </div>
    );

    function getContent() {
        if (_.isEmpty(oneLiner.content)) {
            return `<p class='newTextBlockPlaceholder'>${t(
                'document.navbar.main.editor.left.blocks.editBlockText',
            )}...</p>`;
        }

        return oneLiner.content;
    }

    function getConfigOverride() {
        if (_.isEmpty(document.properties.oneLiners.colors)) {
            return undefined;
        }

        return {
            colorPicker: {
                colors: HelperFunctions.csvToArray(document.properties.oneLiners.colors)[0],
            },
        };
    }
}

function Toolbar({ oneLiner, remove }) {
    const { t } = useTranslation('documents');

    return (
        <div className="editor-toolbar">
            <div className="block-edit-labels mr-1 uk-button-group uk-button-group-small uk-margin-small-right px-2 align-items-center">
                <LinkedCategoriesIcon oneLiner={oneLiner} />
                <span className="px-2 mr-1 uk-text-xsmall uk-background-theme-light">One-liner</span>
            </div>

            <div className="block-edit-buttons uk-button-group uk-button-group-small pr-1">
                <LinkedCategoriesIcon oneLiner={oneLiner} />
                <button
                    type="button"
                    data-uk-tooltip={t('document.navbar.main.editor.left.blocks.tooltips.delete')}
                    className="uk-button uk-button-small uk-button-icon uk-cursor-pointer btn-delete-block"
                    onClick={remove}
                >
                    <span className="uk-icon text-danger">
                        <Trash size={15} />
                    </span>
                </button>
            </div>
        </div>
    );
}

function SettingsIcon({ oneLiner }) {
    if (oneLiner.hasOwnProperty('id') === false) {
        return false;
    }

    return (
        <div className="hide-when-dragging flex-shrink-0 pt-2 pl-1">
            <SettingsSidebarButton
                context={{
                    entity: oneLiner,
                    entityType: entityTypes.ONE_LINER,
                }}
            />
        </div>
    );
}

function LinkedCategoriesIcon({ oneLiner }) {
    if (oneLiner.categories.length > 0) {
        return null;
    }

    return (
        <button
            className="uk-button uk-button-small uk-button-icon uk-cursor-pointer btn-edit-variants btn-edit-variants-warning btn-edit-variants-warning-icon"
            data-uk-tooltip={'Geen categorie&euml;n'}
            type="button"
            onClick={(e) => {
                e.preventDefault();
            }}
        >
            <span className="uk-icon">
                <Grid size={15} />
            </span>

            <ExclamationTriangleFill size={16} className="triangle-icon" />
        </button>
    );
}
