import { useGetDepartments } from 'pages/task_team/hooks/useGetDepartments';
import { FormGroup } from 'components/SettingsSidebar/helpers/FormHelpers';
import { InputSelect } from 'pages/publications_v2/helpers/FieldHelper';
import HelperFunctions from 'pages/global/HelperFunctions';
import { Radio } from 'pages/task_team/helpers/FormHelper';
import { useGetTaskUsers } from 'pages/task_team/hooks/useGetTaskUsers';
import { useCallback, useState } from 'react';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { commentsApi } from 'features/comments/commentApi';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { TaskStatusBadge } from 'pages/task_team/views/checks/Checks';
import { useGetUserFullName } from 'hooks/useGetUserFullName';
import { useIsTaskOwner } from 'pages/task_team/hooks/permissions';
import { LightOutlineButton } from 'components/Buttons';
import { ArrowRightCircle } from 'react-bootstrap-icons';
import { generatePath, useHistory } from 'react-router-dom';
import { ALL_CHECKS_PATH } from 'scenes/TaskTeam';

export function Step1General() {
    const [selectedTask, setSelectedTask] = useState();
    const departments = useGetDepartments();
    const users = useGetTaskUsers('client');

    return (
        <div className="sidebar-form">
            <TaskTitle selectedTask={selectedTask} setSelectedTask={setSelectedTask} />

            {selectedTask && selectedTask.hasOwnProperty('@id') ? (
                <ExistingTask task={selectedTask} />
            ) : (
                <>
                    <FormGroup label="Opdrachtgever*" htmlFor="client" help="Wie is de opdrachtgever van deze uiting?">
                        <InputSelect
                            name="client"
                            options={HelperFunctions.prepareDropdownData(users, 'fullName', 'userId')}
                            props={{
                                placeholder: 'Selecteer opdrachtgever...',
                            }}
                        />
                    </FormGroup>

                    <FormGroup label="Afdeling*" htmlFor="department" help="">
                        <InputSelect
                            name="department"
                            options={HelperFunctions.prepareDropdownData(departments, 'name', '@id')}
                            props={{
                                placeholder: 'Selecteer afdeling...',
                            }}
                        />
                    </FormGroup>

                    <FormGroup
                        label="Aantal ontvangers*"
                        htmlFor="numberOfRecipients"
                        help="Hoeveel mensen lezen de uiting of krijgen de uiting te zien?"
                    >
                        <Radio name="numberOfRecipients" id="numberOfRecipientsOne" value="one" label="Eén ontvanger" />
                        <Radio
                            name="numberOfRecipients"
                            id="numberOfRecipientsMultiple"
                            value="multiple"
                            label="Meerdere ontvangers"
                        />
                    </FormGroup>

                    <FormGroup label="Soort OKÉ-check*" htmlFor="contentType" help="">
                        <Radio
                            name="contentType"
                            id="contentTypeTextual"
                            value="textual"
                            label="Het gaat over een correctie in een tarief, spelling & grammatica of het toevoegen van een paar woorden."
                        />
                        <Radio
                            name="contentType"
                            id="contentTypeOther"
                            value="other"
                            label="Het betreft hier een andere wijziging."
                        />
                    </FormGroup>
                </>
            )}
        </div>
    );
}

function TaskTitle({ selectedTask, setSelectedTask }) {
    const organisationId = useActiveOrganisation();
    const [getTasks] = commentsApi.useLazyGetTasksQuery();
    const { values, setFieldValue } = useFormikContext();

    const taskTitleOption = {
        value: values.title,
        label: values.title,
    };

    const debouncedResults = useCallback(_.debounce(findTasks, 1000), []);

    return (
        <FormGroup label="Naam uiting*" htmlFor="title" help="De naam van de uiting">
            <AsyncCreatableSelect
                isClearable
                isDisabled={values.hasOwnProperty('id')}
                cacheOptions
                defaultOptions={getDefaultOptions()}
                createOptionPosition="first"
                formatCreateLabel={(inputValue) => `Gebruik "${inputValue}"`}
                loadOptions={promiseOptions}
                value={selectedTask ?? taskTitleOption}
                onChange={(newValue) => {
                    if (newValue) {
                        if (newValue.hasOwnProperty('id')) {
                            // Existing Task selected
                            setFieldValue('title', '');
                            setSelectedTask(newValue);
                        } else {
                            // New task title
                            setFieldValue('title', newValue.value);
                            setSelectedTask(undefined);
                        }
                    } else {
                        // Cleared
                        setFieldValue('title', '');
                        setSelectedTask(undefined);
                    }
                }}
            />
        </FormGroup>
    );

    function getDefaultOptions() {
        if (values.hasOwnProperty('id')) {
            return [taskTitleOption];
        }

        return [];
    }

    function findTasks(inputValue, callBack) {
        if (_.isEmpty(inputValue) || inputValue.length < 3) {
            callBack([]);
            return;
        }

        getTasks(
            {
                myTasks: false,
                params: {
                    organisationId,
                    title: inputValue,
                },
            },
            true,
        ).then(({ data }) => {
            if (data && data.hasOwnProperty('hydra:member')) {
                const tasks = data['hydra:member'] ?? [];
                callBack(formatResults(tasks));
            }
        });
    }

    function promiseOptions(inputValue, callback) {
        debouncedResults(inputValue, callback);
    }

    function formatResults(tasks = []) {
        return tasks.map((task) => ({
            ...task,
            value: task['@id'],
            label: (
                <div>
                    <div className="mb-1" style={{ fontSize: 14 }}>
                        <span className="text-muted">
                            #{task.sequence}-{task.activeVersionNumber}
                        </span>
                        <span className="font-weight-bold ml-2">{task.title}</span>
                    </div>
                    <div style={{ fontSize: 12 }}>
                        <TaskStatusBadge task={task} />
                    </div>
                </div>
            ),
        }));
    }
}

function ExistingTask({ task }) {
    const createdByName = useGetUserFullName(task.createdBy, false);
    const isTaskOwner = useIsTaskOwner(task);
    const history = useHistory();

    return (
        <div className="pt-3">
            <table className="table">
                <thead>
                    <tr>
                        <th className="py-2">Nr.</th>
                        <th className="py-2">Naam</th>
                        <th className="py-2">Eigenaar</th>
                        <th className="py-2">Status</th>
                        <th className="py-2">Acties</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="align-middle">
                            #{task.sequence}-{task.activeVersionNumber}
                        </td>
                        <td className="align-middle">{task.title}</td>
                        <td className="align-middle">{createdByName}</td>
                        <td className="align-middle">
                            <TaskStatusBadge task={task} />
                        </td>
                        <td>
                            {isTaskOwner && (
                                <LightOutlineButton
                                    size="sm"
                                    onClick={() => {
                                        history.push(`${generatePath(ALL_CHECKS_PATH)}?viewTask=${task.id}`);
                                    }}
                                >
                                    <ArrowRightCircle size={14} />
                                    Bekijken
                                </LightOutlineButton>
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
