import { useGetReimbursementQuery } from 'features/reimbursements/reimbursements';

export function useGetReimbursement(id) {
    const { reimbursement } = useGetReimbursementQuery(id, {
        selectFromResult: ({ data }) => ({
            reimbursement: data,
        }),
    });

    return reimbursement;
}
