import { TablePagination } from 'components/TablePagination';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';
import { SearchInput } from 'components/SearchInput';
import { createContext, useContext, useMemo, useReducer } from 'react';
import _ from 'lodash';

const TableContext = createContext(null);
const TableDispatchContext = createContext(null);

const defaultValues = {
    selectedItems: [],
};

export function TableProvider({ children }) {
    const [values, dispatch] = useReducer(tasksReducer, defaultValues);

    return (
        <TableContext.Provider value={values}>
            <TableDispatchContext.Provider value={dispatch}>{children}</TableDispatchContext.Provider>
        </TableContext.Provider>
    );
}

export function useSelectedItems() {
    return useContext(TableContext);
}

export function useSelectedItemsDispatch() {
    return useContext(TableDispatchContext);
}

export function PaginatedTable({
    items = [],
    renderSearch = true,
    clientSidePagination = true,
    searchPlaceholder = '',
    searchProps = ['name'],
    totalItems,
    itemsPerPage = 50,
    colSpan,
    actionsButton = undefined,
    pageParamPrefix = '',
    searchParamPrefix = '',
    children,
}) {
    const [page = 1, setPage] = useQueryParam(pageParamPrefix + 'page', NumberParam);
    const [search = '', setSearch] = useQueryParam(searchParamPrefix + 'search', StringParam);

    const filteredItems = useMemo(() => {
        if (clientSidePagination === false) {
            return items;
        }

        const searchValueClean = search.trim().toLowerCase();

        if (searchValueClean === '' || searchProps.length === 0) {
            return items;
        }

        // Apply search filter
        return items.filter((item) => {
            return searchProps.some((prop) => _.get(item, prop, '').toLowerCase().includes(searchValueClean));
        });
    }, [items, search]);

    const numberOfItems = totalItems ?? filteredItems.length;

    const itemOffset = ((page - 1) * itemsPerPage) % numberOfItems;
    const endOffset = itemOffset + itemsPerPage;

    const currentItems = clientSidePagination ? filteredItems.slice(itemOffset, endOffset) : filteredItems;
    const pageCount = Math.ceil(numberOfItems / itemsPerPage);

    return (
        <>
            <div className="d-flex align-items-center mb-2">
                <div className="mr-auto">{actionsButton}</div>

                {renderSearch && (
                    <SearchInput
                        placeholder={searchPlaceholder}
                        onChange={(value) => {
                            if (page > 1) {
                                setPage(undefined);
                            }
                            setSearch(_.isEmpty(value) ? undefined : value);
                        }}
                    />
                )}
            </div>

            <table className="table dr-table">
                {children({ items: currentItems, search, colSpan })}

                {currentItems.length > 0 && (
                    <TablePagination
                        itemOffset={itemOffset}
                        itemsLength={numberOfItems}
                        pageCount={pageCount}
                        onPageChange={onPageChange}
                        itemsPerPage={itemsPerPage}
                        colSpan={colSpan}
                    />
                )}
            </table>
        </>
    );

    function onPageChange(event) {
        setPage(event.selected + 1);
    }
}

function tasksReducer(values, action) {
    switch (action.type) {
        case 'toggle': {
            return {
                ...values,
                selectedItems: _.xor(values.selectedItems, [action.id]),
            };
        }
        case 'select': {
            return {
                ...values,
                selectedItems: action.items,
            };
        }
        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
}
