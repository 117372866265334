import { useGetTaskVersionQuery } from 'features/comments/commentApi';

/**
 * @typedef { import('models/comments.models').TaskVersion } TaskVersion
 *
 * @param {string} uri
 * @returns {(TaskVersion|undefined)}
 */
export function useGetTaskVersion(uri) {
    const { version } = useGetTaskVersionQuery(uri, {
        selectFromResult: (result) => ({
            version: result.currentData,
        }),
        skip: uri === undefined,
    });

    return version;
}
