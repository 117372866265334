import { useContext } from 'react';
import { Accordion, AccordionContext, Card } from 'react-bootstrap';
import { OpTeamEntryStatus } from '../op_team_entry/OpTeamEntryStatus';
import _ from 'lodash';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { resolveTeamEntryLabelName } from '../op_team_entry/OpTeamEntryLabelName';

export default function DepartmentAccordion({ opList, entryLabelsByDepartment, departments, departmentUri, labelLut }) {
    const currentEventKey = useContext(AccordionContext);

    const department = _.find(departments, ['@id', departmentUri]);
    const entryLabels = entryLabelsByDepartment[departmentUri];

    if (!department) {
        return null;
    }
    const isNotSplitByLabels = entryLabels.length === 1 && !_.get(entryLabels, '0.label');
    const canExpand = !isNotSplitByLabels; // No reason to expand if not split by labels, as theres only one entryLabel anyway

    const expanded = canExpand && currentEventKey === department.id;

    return (
        <>
            <Card style={{ marginBottom: 10 }}>
                <Accordion.Toggle as={Card.Body} className="op-list-row-title py-2" eventKey={department.id}>
                    <div className={`d-flex`}>
                        <div className="font-weight-bold mr-3">
                            {department.name}&nbsp;{canExpand && <>{expanded ? <CaretUpFill /> : <CaretDownFill />}</>}
                        </div>

                        <div className="ml-auto">
                            {/* If not split by labels, there's only one entryLabel, so just show it's status here directly. */}
                            {isNotSplitByLabels ? (
                                <OpTeamEntryStatus
                                    opList={opList}
                                    opEntryLabel={entryLabels[0]}
                                    ignoreArchivedAndCancelled={true}
                                    marginLevel={0}
                                />
                            ) : (
                                <span className="small">{entryLabels.length}</span>
                            )}
                        </div>
                    </div>
                </Accordion.Toggle>

                {canExpand && (
                    <Accordion.Collapse eventKey={department.id}>
                        <div className="py-2">
                            {expanded && (
                                <DepartmentEntryLabels opList={opList} entryLabels={entryLabels} labelLut={labelLut} />
                            )}
                        </div>
                    </Accordion.Collapse>
                )}
            </Card>
        </>
    );
}

function DepartmentEntryLabels({ opList, entryLabels, labelLut }) {
    return entryLabels.map((entryLabel, key) => {
        const labelName = resolveTeamEntryLabelName(labelLut, entryLabel);

        return (
            <div
                className="d-flex mr-auto ml-4 pb-1"
                style={{ alignItems: 'flex-start' }}
                key={`label-${entryLabel.id}-${key}`}
            >
                {/*Ignore archived since we're on the adminView, the archivation is for that specific department */}
                <OpTeamEntryStatus opList={opList} opEntryLabel={entryLabel} ignoreArchivedAndCancelled={true} />
                <span>{labelName}</span>
            </div>
        );
    });
}
