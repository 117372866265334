import { DocRevSelect } from 'pages/publications_v2/helpers/FieldHelper';
import { JsonParam, useQueryParam } from 'use-query-params';
import _ from 'lodash';
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/DateRangePicker/styles/index.css';
import { DateTime } from 'luxon';

export function FilterSelect({
    label,
    name,
    options = [],
    placeholder = '',
    defaultFilterOptions = {},
    filterParamName = 'filters',
    isClearable = true,
}) {
    const [filterOptions, setFilterOptions] = useQueryParam(filterParamName, JsonParam);

    const combinedFilterOptions = {
        ...defaultFilterOptions,
        ...filterOptions,
    };

    return (
        <div className="mb-3">
            <div className="small font-weight-bold mb-1">{label}</div>
            <DocRevSelect
                props={{
                    isClearable,
                    placeholder,
                }}
                name={name}
                options={options}
                selectedValue={_.get(combinedFilterOptions, name, '')}
                onChange={(selection) => {
                    setFilterOptions({
                        ...combinedFilterOptions,
                        [name]: selection?.value ?? '',
                    });
                }}
            />
        </div>
    );
}

export function FilterDateRange({ label, name }) {
    const [dateFilters, setDateFilters] = useQueryParam('dateFilters', JsonParam);

    const nameAfter = `${name}[after]`;
    const valueAfter = _.get(dateFilters, nameAfter, '');

    const nameBefore = `${name}[before]`;
    const valueBefore = _.get(dateFilters, nameBefore, '');

    let inputValue = undefined;

    if (valueAfter !== '' && valueBefore !== '') {
        inputValue = [new Date(valueAfter), new Date(valueBefore)];
    }

    return (
        <div className="mb-3">
            <div className="small font-weight-bold mb-1">{label}</div>
            <DateRangePicker defaultValue={inputValue} showOneCalendar={true} format="dd-MM-yyyy" onChange={onChange} />
        </div>
    );

    function onChange(values) {
        if (values) {
            const [after, before] = values;

            const afterDatetime = DateTime.fromJSDate(after);
            const beforeDatetime = DateTime.fromJSDate(before);

            setDateFilters({
                ...dateFilters,
                [nameAfter]: afterDatetime.toFormat('yyyy-MM-dd'),
                [nameBefore]: beforeDatetime.toFormat('yyyy-MM-dd'),
            });
        } else {
            setDateFilters({
                ...dateFilters,
                [nameAfter]: undefined,
                [nameBefore]: undefined,
            });
        }
    }
}
