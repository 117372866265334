import { useAppSelector } from '../../../../../../store';
import { useGetTableTemplatesQuery } from '../../../../../../features/documents/documents';
import {
    EditorModal,
    getColumns,
    getTableDataSet,
    handleTableDataChange,
    prepareTableData,
    RenderTable,
    validateData,
} from '../../../../../documents/misc/_Blocks/Table';
import HelperFunctions from '../../../../../global/HelperFunctions';
import React, { useContext, useState } from 'react';
import { AreaContext } from '../../../Area';
import { useGetVariant } from '../../../../hooks/useGetVariant';
import { useGetDocument } from '../../../../hooks/useGetDocument';
import { useGetBaseVariant } from '../../../../hooks/useGetBaseVariant';
import { useFormikContext } from 'formik';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

export function TableBlock({ block, index, restrictions, readOnly }) {
    const { activeOrganisation } = useAppSelector((state) => state.security);
    const { setFieldValue } = useFormikContext();
    const areaContext = useContext(AreaContext);
    const activeVariant = useGetVariant(areaContext.activeVariantId);
    const document = useGetDocument();
    const baseVariant = useGetBaseVariant();
    const [showEditorModal, setShowEditorModal] = useState(false);
    const isReadOnly = readOnly === true || restrictions?.canChangeContent === false;
    const { t } = useTranslation('documents');

    const { tableTemplates } = useGetTableTemplatesQuery(activeOrganisation, {
        selectFromResult: ({ data }) => ({
            tableTemplates: data ?? [],
        }),
    });
    const blockContent = block.latestContent || block.baseContent || '';
    const table = HelperFunctions.tryParseJSON(blockContent, {
        data: {},
        templateId: false,
        title: '',
    });

    const template = HelperFunctions.getByValue(tableTemplates, 'id', parseInt(table.templateId));

    if (template === undefined) {
        return null;
    }

    const properties = {
        ...template?.properties,
        ...table?.properties,
    };

    let baseVariantOverride = false;
    let activeVariantId = areaContext.activeVariantId;
    const differentCellValuePerVariant = properties?.differentCellValuePerVariant ?? false;

    if (differentCellValuePerVariant === false) {
        if (activeVariantId !== baseVariant.id) {
            baseVariantOverride = true;
        }

        activeVariantId = baseVariant.id;
    }

    let tableDataSet = getTableDataSet(table, activeVariantId);

    // Fallback dataset (for new tables)
    if (tableDataSet === undefined) {
        tableDataSet = {
            variantId: activeVariantId,
        };
    }

    const packageGroupId = tableDataSet?.packageGroupId ?? undefined;
    const columns = getColumns(template, packageGroupId, document.packageGroups, activeVariant, document);
    const rows = template?.rows;

    const dataSet = prepareTableData(
        tableDataSet,
        rows,
        columns,
        {
            table: {
                interactiveChanges: false,
                markChanges: false,
            },
        },
        block
    );
    const dataIsValid = validateData(table, dataSet, template?.rows ?? [], columns);

    return (
        <>
            <div
                className={cx('uk-background-muted border-radius p-3', {
                    'cursor-pointer': !isReadOnly,
                })}
                onClick={() => {
                    if (!isReadOnly) {
                        setShowEditorModal(true);
                    }
                }}
            >
                {dataIsValid.colsAreValid && dataIsValid.rowsAreValid && dataIsValid.dataSetHasData ? (
                    <>
                        <RenderTable
                            columns={columns}
                            document={document}
                            enableTableEditor={false}
                            rows={rows}
                            tableData={dataSet.rows}
                            template={template}
                        />

                        {baseVariantOverride && (
                            <div className="font-weight-bold text-left pt-2">
                                *&nbsp;{t('document.navbar.main.editor.left.blocks.types.tableBlock.tableData')}
                            </div>
                        )}
                    </>
                ) : (
                    <div className="text-secondary text-center py-2">
                        {packageGroupId === undefined
                            ? t('document.navbar.main.editor.left.blocks.types.tableBlock.noPackageGroupSelected')
                            : t('document.navbar.main.editor.left.blocks.types.tableBlock.noColumnsAreSet')}
                    </div>
                )}
            </div>

            {showEditorModal && (
                <EditorModal
                    activeVariant={activeVariant}
                    columns={columns}
                    dataIsValid={dataIsValid}
                    document={document}
                    enableTableEditor={true}
                    packageGroupId={packageGroupId}
                    packageGroups={document.packageGroups}
                    rows={rows}
                    selectPackageGroup={(packageGroupId) => {
                        const newContent = handleTableDataChange(
                            {
                                rows: dataSet.rows,
                            },
                            dataSet,
                            packageGroupId,
                            table,
                            activeVariantId
                        );

                        setFieldValue(`areaBlocks.${index}.latestContent`, newContent);
                    }}
                    handleFormSubmit={(formData) => {
                        if (formData === null) {
                            // Nothing to save
                            return;
                        }

                        let tableData = table;

                        if (formData.title && formData.title !== table.title) {
                            tableData = {
                                ...table,
                                title: formData.title,
                            };
                        }

                        const newContent = handleTableDataChange(
                            {
                                rows: formData.tempDataSetRows,
                                properties: formData.properties,
                            },
                            dataSet,
                            packageGroupId,
                            tableData,
                            activeVariantId
                        );

                        setFieldValue(`areaBlocks.${index}.latestContent`, newContent);

                        // Close modal
                        setShowEditorModal(false);
                    }}
                    table={table}
                    tableData={dataSet.rows}
                    template={template}
                    toggleEditorModal={() => setShowEditorModal(false)}
                />
            )}
        </>
    );
}
