import { useGetDocument } from 'pages/documents_v2/hooks/useGetDocument';
import { useGetCategories } from 'pages/documents_v2/views/edit/one_liners/hooks/useGetCategories';
import { useCallback, useState } from 'react';
import { CaretDownFill, CaretRightFill } from 'react-bootstrap-icons';
import { useGetChildCategories } from 'pages/documents_v2/views/edit/one_liners/hooks/useGetChildCategories';
import { AddCategoryForm } from 'pages/documents_v2/views/edit/one_liners/AddCategoryForm';
import { ActionLinkButton } from 'components/Buttons';
import { useTranslation } from 'react-i18next';
import { Dropdown, SplitButton } from 'react-bootstrap';
import { useShowSettingsSidebar } from 'components/SettingsSidebar/hooks/useShowSettingsSidebar';
import { entityTypes } from 'pages/translation/config/Constants';
import _ from 'lodash';
import HelperFunctions from 'pages/global/HelperFunctions';
import { exportCategory, useDeleteOneLinerCategoryMutation } from 'features/metadata/metadata';
import { StringParam, useQueryParam } from 'use-query-params';
import { ViewOneLinersModal } from 'pages/documents_v2/views/edit/one_liners/ViewOneLinersModal';
import { saveAs } from 'file-saver';

export function OneLiners() {
    const document = useGetDocument();

    if (!document || document.properties?.enableOneLiners !== true) {
        return null;
    }

    return (
        <div className="content-static-body has-subnav">
            <div className="d-flex align-items-stretch h-100">
                <div className="overflow-auto w-100">
                    <div className="mt-5 ml-5 mr-3 mr-xxl-5 dr-container p-4 mb-4">
                        <div className="subheader d-flex justify-content-between pb-2">
                            <h3 className="mb-0">One-liners</h3>
                        </div>

                        <Categories documentId={document.id} />
                        <ViewOneLinersModal />
                    </div>
                </div>
            </div>
        </div>
    );
}

function Categories({ documentId }) {
    const categories = useGetCategories(documentId);
    const [expandedCategoryId, setExpandedCategoryId] = useState();

    const handleExpanded = useCallback((categoryId) => {
        setExpandedCategoryId((expandedCategoryId) => (expandedCategoryId === categoryId ? undefined : categoryId));
    }, []);

    return (
        <div className="dr-variants">
            <div className="position-relative mb-4">
                <div className="variant-droppable">
                    {categories.map((category) => (
                        <CategoryGroup
                            isExpanded={expandedCategoryId === category.id}
                            handleExpanded={handleExpanded}
                            category={category}
                            key={`category-${category.id}`}
                        />
                    ))}
                </div>
            </div>

            <AddCategoryForm />
        </div>
    );
}

function CategoryGroup({ category, isExpanded = false, handleExpanded }) {
    const { t } = useTranslation();
    const childCategories = useGetChildCategories(category.id, isExpanded === false);

    return (
        <div className="dr-variant-group">
            <div className="toggle-on-hover d-flex align-items-center py-2 pr-2" style={{ paddingLeft: 3 }}>
                <div className="d-flex align-items-center cursor-pointer" onClick={() => handleExpanded(category.id)}>
                    {isExpanded ? (
                        <CaretDownFill size={12} className="mr-1" />
                    ) : (
                        <CaretRightFill size={12} className="mr-1" />
                    )}

                    <div className="font-weight-bold">{category.name}</div>
                </div>

                <div className="ml-auto" style={{ marginRight: 1 }}>
                    <CategoryEditButton category={category} />
                </div>
            </div>

            {isExpanded && (
                <div className="pb-3">
                    <div className="position-relative pl-2 pb-2 mb-2">
                        <div className="variant-droppable">
                            {childCategories.map((category) => (
                                <Category category={category} key={`category-${category.id}`} />
                            ))}
                        </div>
                    </div>

                    <div className="ml-4" style={{ paddingLeft: 3 }}>
                        <AddCategoryForm isGroup={false} parent={category['@id']} />
                    </div>
                </div>
            )}
        </div>
    );
}

function Category({ category }) {
    const [, setViewCategory] = useQueryParam('viewCategory', StringParam);

    return (
        <div className="dr-child-variant-wrapper pl-3">
            <div className="dr-variant-border">
                <div className="dr-variant py-1">
                    <div className="toggle-on-hover d-flex align-items-center flex-grow-1 bg-white py-2 pl-3 pr-2 border">
                        <ActionLinkButton onClick={handleClick}>{category.name}</ActionLinkButton>
                        <CategoryMetadata category={category} />

                        <div className="ml-auto">
                            <CategoryEditButton category={category} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    function handleClick() {
        setViewCategory(category.id);
    }
}

function CategoryEditButton({ category }) {
    const { t } = useTranslation();
    const [showSettingsSidebar] = useShowSettingsSidebar();
    const [deleteOneLinerCategory] = useDeleteOneLinerCategoryMutation();
    const [, setViewCategory] = useQueryParam('viewCategory', StringParam);
    const document = useGetDocument();

    return (
        <SplitButton
            id={`edit-category-dropdown-${category.id}`}
            title={t('global:btn.edit')}
            size="sm"
            onClick={handleEditClick}
            onSelect={handleSelect}
        >
            {!category.isGroup && (
                <>
                    <Dropdown.Item eventKey="view">Bekijk one-liners&hellip;</Dropdown.Item>
                </>
            )}

            <Dropdown.Item eventKey="export">Exporteren&hellip;</Dropdown.Item>
            <Dropdown.Divider />

            <Dropdown.Item eventKey="delete">
                <span className="text-danger">Verwijderen&hellip;</span>
            </Dropdown.Item>
        </SplitButton>
    );

    function handleEditClick() {
        showSettingsSidebar(category, entityTypes.ONE_LINER_CATEGORY);
    }

    function handleSelect(eventKey) {
        switch (eventKey) {
            case 'view':
                setViewCategory(category.id);
                return;

            case 'export':
                exportCategory(category.id, { ...document.properties.oneLiners })
                    .then((response) => {
                        saveAs(response.data, decodeURI(response.headers['x-suggested-filename'] ?? 'export.csv'));
                    })
                    .catch(async (error) => {
                        if (error.response.status === 400) {
                            const response = await error.response.data.text();
                            const responseObject = HelperFunctions.tryParseJSON(response);
                            HelperFunctions.alertModal(t('global:error.errorTitle', { title: responseObject.error }));
                        } else {
                            HelperFunctions.alertModal(t('global:error.exportFailed'));
                        }
                    });
                return;

            case 'delete':
                HelperFunctions.confirmModal(
                    `${category.name} verwijderen? Dit kan niet ongedaan gemaakt worden.`,
                    'danger',
                    false,
                    t('global:confirm.yesDelete'),
                    t('global:btn.cancel'),
                )
                    .then(() => {
                        deleteOneLinerCategory(category['@id']);
                    })
                    .catch(() => {});
                return;
        }
    }
}

export function CategoryMetadata({ category, showTooltip = true }) {
    const metadataForTitle = getMetadata();

    if (metadataForTitle.length === 0) {
        return null;
    }

    return (
        <>
            {metadataForTitle.map((item, index) => (
                <span
                    className="small text-muted font-weight-bold ml-2"
                    data-uk-tooltip={showTooltip ? item.key : undefined}
                    key={`category-${category.id}-meta-${index}`}
                >
                    {item.value}
                </span>
            ))}
        </>
    );

    function getMetadata() {
        const metadata = _.get(category, 'metadata', []);

        return metadata.filter((item) => item.properties?.showInTitleRow === '1');
    }
}
