import { Switch } from '../helpers/FieldHelper';
import { useTranslation } from 'react-i18next';

export default function Preview() {
    const { t } = useTranslation('publications');

    return (
        <>
            <div className="p-4">
                <Switch
                    name="showChangesInPreview"
                    label={t('publication.settings.content.example.showUnacceptedChanges')}
                />
            </div>
        </>
    );
}
