import { useFormikContext } from 'formik';
import { Switch } from '../helpers/FieldHelper';
import { useTranslation } from 'react-i18next';
import { PublicationUserGroups } from 'pages/publications_v2/forms/users/PublicationUserGroups';

export default function PublicationGroups() {
    const { t } = useTranslation();
    const { values } = useFormikContext();

    return (
        <div className="p-4">
            <Switch
                name="pubGroupProperties.enableGroupProperties"
                label={t('publications:accessControl.enableGroupProperties')}
                props={{ disabled: values.html === false }}
            />

            {values.html === false && (
                <div className="d-flex alert alert-info align-items-center">
                    {t('publications:accessControl.enableHtmlWarning')}
                </div>
            )}

            {values.pubGroupProperties.enableGroupProperties && values.html === true && (
                <PublicationUserGroups publication={values} />
            )}
        </div>
    );
}
