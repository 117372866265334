import { FieldSelect, FormField, InputMultipleSelect, Switch } from '../helpers/FieldHelper';
import { useFormikContext } from 'formik';
import { Publication } from '../../../models/publications.models';
import HelperFunctions from '../../global/HelperFunctions';
import { useAppSelector } from '../../../store';
import {
    useGetCategoriesQuery,
    useGetProductsQuery,
    useGetPropositionGroupsQuery,
} from '../../../features/reimbursements/reimbursements';
import { Category, Product, PropositionGroup } from '../../../models/reimbursement.models';
import { idToUri } from '../../global/UriHelper';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { XmlTranslation } from './XmlTranslation';

export function ReimbursementXml() {
    const { values } = useFormikContext<Publication>();
    const { xmlProperties } = values;
    const { t } = useTranslation('publications');

    return (
        <div className="p-4">
            <Switch name="xmlProperties.enableXml" label={t('publication.settings.content.reimbursements.xmlExport')} />

            {xmlProperties?.enableXml && (
                <div>
                    <TypeSelect />

                    <FormField
                        label={`${t('publication.settings.content.reimbursements.labelName')}*`}
                        name="xmlProperties.name"
                        props={{ required: true }}
                    />
                    <FormField
                        label={`${t('publication.settings.content.reimbursements.year')}*`}
                        name="xmlProperties.year"
                        props={{ type: 'number', min: '2000', max: '9999', step: '1', required: true }}
                    />
                    <FormField
                        label={`${t('publication.settings.content.reimbursements.idPrefix')}*`}
                        name="xmlProperties.idPrefix"
                        props={{ type: 'number', min: '10', max: '99', step: '1', required: true }}
                    />
                    <FormField
                        label={t('publication.settings.content.reimbursements.labelPrefix')}
                        name="xmlProperties.labelPrefix"
                    />

                    <CurrencyFormatSelect />
                    <FormOfAddressSelect />

                    <span className="font-weight-bolder text-color font-size-sm">
                        {t('publication.settings.content.reimbursements.reimbursements')}
                    </span>

                    <CategorySelect />
                    {xmlProperties.categoryId && (
                        <>
                            <PropositionSelect categoryId={xmlProperties.categoryId} />
                            <BaseProductSelect categoryId={xmlProperties.categoryId} />
                            <ProductSelect categoryId={xmlProperties.categoryId} />
                        </>
                    )}

                    <span className="font-weight-bolder text-color font-size-sm">
                        {t('publication.settings.content.summary.expand.sectionTranslation.title')}
                    </span>

                    <Switch
                        label={t('publication.settings.content.summary.expand.section.reimbursementsTranslate')}
                        name={`xmlProperties.translate`}
                    />

                    {xmlProperties.categoryId && xmlProperties.translate && (
                        <XmlTranslation categoryId={xmlProperties.categoryId} />
                    )}
                </div>
            )}
        </div>
    );
}

function CategorySelect() {
    const activeOrganisation = useAppSelector((state) => state.security.activeOrganisation);
    const { t } = useTranslation('publications');
    const { categories } = useGetCategoriesQuery(undefined, {
        selectFromResult: ({ data }) => ({
            categories: data ? data.filter((_item: Category) => _item.organisationId === activeOrganisation) : [],
        }),
    });

    return (
        <FieldSelect
            label={`${t('publication.settings.content.reimbursements.structure')}*`}
            name={`xmlProperties.categoryId`}
            options={HelperFunctions.prepareDropdownData(categories)}
            props={{
                placeholder: `${t('publication.settings.content.reimbursements.titleSelect')}...`,
            }}
        />
    );
}

function TypeSelect() {
    const { t } = useTranslation('publications');

    return (
        <FieldSelect
            label={`${t('publication.settings.content.reimbursements.type')}*`}
            name={`xmlProperties.type`}
            options={[
                { label: t('publication.settings.content.reimbursements.type_default'), value: 'default' },
                { label: 'Zoë', value: 'zoe' },
            ]}
            props={{
                placeholder: `${t('publication.settings.content.reimbursements.titleSelect')}...`,
            }}
        />
    );
}

function CurrencyFormatSelect() {
    return (
        <FieldSelect
            label="Bedrag weergave*"
            name={`xmlProperties.currencyFormat`}
            options={[
                { label: '€ 100,-', value: 'with_trailing_comma' },
                { label: '€ 100', value: 'no_trailing_comma' },
            ]}
        />
    );
}

function FormOfAddressSelect() {
    return (
        <FieldSelect
            label="Aanspreekvorm*"
            name={`xmlProperties.formOfAddress`}
            options={[
                { label: 'U (formeel)', value: 'formal' },
                { label: 'Jij (informeel)', value: 'informal' },
            ]}
        />
    );
}

function PropositionSelect({ categoryId }: { categoryId: number }) {
    const { values, setFieldValue } = useFormikContext<Publication>();
    const { t } = useTranslation('publications');

    const {
        propositionGroups,
        allPropositionGroups,
    }: { propositionGroups: PropositionGroup[]; allPropositionGroups: PropositionGroup[] } =
        useGetPropositionGroupsQuery(idToUri(categoryId, 'Category'), {
            selectFromResult: ({ data }) => ({
                propositionGroups: HelperFunctions.prepareDropdownData(
                    data
                        ?.filter((group: PropositionGroup) => group.parent === null)
                        ?.map((group: PropositionGroup) => ({
                            ...group,
                            options: HelperFunctions.prepareDropdownData(
                                group.children.map((childId) =>
                                    data.find((group: PropositionGroup) => group['@id'] === childId)
                                )
                            ),
                        })) ?? []
                ),
                allPropositionGroups: HelperFunctions.prepareDropdownData(data ?? []),
            }),
        });

    return (
        <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4} htmlFor="propositionGroups">
                {t('publication.settings.content.reimbursements.propositions')}
            </Form.Label>
            <Col sm={8}>
                <InputMultipleSelect
                    name="propositionGroups"
                    options={propositionGroups}
                    onChange={(selection: PropositionGroup[]) =>
                        setFieldValue(
                            'xmlProperties.propositionGroups',
                            selection.map((group) => group.id)
                        )
                    }
                    value={allPropositionGroups.filter((group) =>
                        values.xmlProperties?.propositionGroups?.includes(group.id)
                    )}
                    props={{
                        placeholder: t('publication.settings.content.reimbursements.titleSelect'),
                    }}
                />
            </Col>
        </Form.Group>
    );
}

function BaseProductSelect({ categoryId }: { categoryId: number }) {
    const { t } = useTranslation('publications');
    const { products }: { products: Product[] } = useGetProductsQuery(categoryId, {
        selectFromResult: ({ data }) => ({
            products: HelperFunctions.prepareDropdownData(
                data
                    ?.filter((product: Product) => product.parent === null)
                    .map((product: Product) => ({
                        ...product,
                        options: HelperFunctions.prepareDropdownData(
                            product.children.filter((childProduct: Product) => childProduct.description !== '')
                        ),
                    })) ?? []
            ),
        }),
    });

    return (
        <FieldSelect
            label={`${t('publication.settings.content.reimbursements.policyType')}*`}
            name={`xmlProperties.baseProductId`}
            options={HelperFunctions.prepareOptionValues(products, 'name', 'code')}
            props={{
                placeholder: t('publication.settings.content.reimbursements.titleSelect'),
            }}
        />
    );
}

function ProductSelect({ categoryId }: { categoryId: number }) {
    const { values, setFieldValue } = useFormikContext<Publication>();
    const { t } = useTranslation('publications');

    const { products, allProducts }: { products: Product[]; allProducts: Product[] } = useGetProductsQuery(categoryId, {
        selectFromResult: ({ data }) => ({
            products: HelperFunctions.prepareDropdownData(
                data
                    ?.filter((product: Product) => product.parent === null)
                    .map((product: Product) => ({
                        ...product,
                        options: HelperFunctions.prepareDropdownData(
                            product.children.filter((childProduct: Product) => childProduct.description !== '')
                        ),
                    })) ?? []
            ),
            allProducts: HelperFunctions.prepareOptionValues(
                HelperFunctions.prepareDropdownData(data ?? []),
                'name',
                'code'
            ),
        }),
    });

    return (
        <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4} htmlFor="products">
                {t('publication.settings.content.reimbursements.policyTypeAV')}
            </Form.Label>
            <Col sm={8}>
                <InputMultipleSelect
                    name="products"
                    options={HelperFunctions.prepareOptionValues(products, 'name', 'code')}
                    onChange={(selection: Product[]) =>
                        setFieldValue(
                            'xmlProperties.products',
                            selection.map((product) => product.id)
                        )
                    }
                    value={allProducts.filter((product) => values.xmlProperties?.products?.includes(product.id))}
                    props={{
                        placeholder: t('publication.settings.content.reimbursements.titleSelect'),
                    }}
                />
                <Form.Text id="products" muted>
                    {t('publication.settings.content.reimbursements.descriptionOnlyPolicy')}
                </Form.Text>
            </Col>
        </Form.Group>
    );
}
