import { useSelector } from 'react-redux';
import {
    selectPublicationGroups,
    selectPublications,
    selectRootLevelPublicationGroups,
} from '../../../../features/publications/publicationsSlice';
import { Button, Collapse } from 'react-bootstrap';
import React, { useState } from 'react';
import { ArrowDown, ArrowUp } from 'react-bootstrap-icons';
import cx from 'classnames';
import { Droppable } from 'react-beautiful-dnd';
import GroupPublications from './Groups';
import LoadingSpinner from '../../../global/LoadingSpinner';
import { GroupHeader } from './Publications';
import { AiFillCaretRight } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';

export function RootPublications({
    setShowPublishModal,
    setShowExportChangesModal,
    isLoading,
    setShowDuplicateModal,
    handleMove,
}) {
    const rootLevelGroups = useSelector(selectRootLevelPublicationGroups());
    const publications = useSelector(selectPublications(null));
    // rootLevelGroups contains a dummy group without id for Publications without group
    const rootLevelGroupsWithoutDummy = rootLevelGroups.filter((_group) => _group.id !== null);

    return (
        <>
            {isLoading && rootLevelGroups.length === 1 && publications.length === 0 && (
                <>
                    <LoadingSpinner />
                </>
            )}

            {publications.length > 0 && (
                <Droppable isDropDisabled droppableId="main">
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className={cx('data-table-group data-table-group-droppable dr-container', {
                                'dragging-over': snapshot.isDraggingOver,
                            })}
                        >
                            <div className="bg-white mb-2 pb-1">
                                <GroupHeader publications={publications} groupId="root" />

                                <GroupPublications
                                    publications={publications}
                                    setShowExportChangesModal={setShowExportChangesModal}
                                    setShowPublishModal={setShowPublishModal}
                                    setShowDuplicateModal={setShowDuplicateModal}
                                />

                                {provided.placeholder}
                            </div>
                        </div>
                    )}
                </Droppable>
            )}

            {rootLevelGroupsWithoutDummy.map((_group, index) => (
                <RootLevelGroup
                    group={_group}
                    setShowPublishModal={setShowPublishModal}
                    setShowExportChangesModal={setShowExportChangesModal}
                    level={0}
                    isLoading={isLoading}
                    setShowDuplicateModal={setShowDuplicateModal}
                    handleMove={handleMove}
                    index={index}
                    isLast={rootLevelGroupsWithoutDummy.length - 1 === index}
                    key={`group-${_group.id}`}
                />
            ))}
        </>
    );
}

function RootLevelGroup({
    group,
    setShowExportChangesModal,
    setShowPublishModal,
    isLoading,
    level,
    setShowDuplicateModal,
    handleMove,
    index,
    isLast,
}) {
    const groups = useSelector(selectPublicationGroups(group['@id']));
    const [open, setOpen] = useState(true);
    const publications = useSelector(selectPublications(group['@id']));
    const { search } = useSelector((state) => state.publications.filters);
    const { t } = useTranslation('publications');

    if (search !== '' && publications.length === 0 && level > 0) {
        return null;
    }

    const toggleGroup = () => {
        setOpen(!open);
    };

    return (
        <Droppable droppableId={group['@id']} isDropDisabled={level === 0}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={cx('data-table-group', 'data-table-group-level-' + level, {
                        'dragging-over': snapshot.isDraggingOver && group.parent !== null,
                        'data-table-group-expanded': open,
                        'dr-container mb-4 pb-3': level === 1,
                    })}
                >
                    <div className="data-table-group-header d-flex align-items-center">
                        <div className="cursor-pointer mr-2" onClick={toggleGroup}>
                            {group.name}
                        </div>
                        {level === 0 && (
                            <>
                                <AiFillCaretRight size={14} className="cursor-pointer chevron" onClick={toggleGroup} />
                                <Button
                                    variant="icon"
                                    size="sm"
                                    className="text-secondary btn-icon-only ml-2 px-1 py-0"
                                    data-uk-tooltip={t('publications.tooltip.moveUp')}
                                    onClick={() => handleMove('up', group, index)}
                                >
                                    <ArrowUp size={12} />
                                </Button>
                                <Button
                                    variant="icon"
                                    size="sm"
                                    className="text-secondary btn-icon-only px-1 py-0"
                                    data-uk-tooltip={t('publications.tooltip.moveDown')}
                                    onClick={() => {
                                        if (!isLast) handleMove('down', group, index);
                                    }}
                                >
                                    <ArrowDown size={12} />
                                </Button>
                            </>
                        )}
                    </div>

                    <Collapse in={open}>
                        <div>
                            {groups.length > 0 && (
                                <div>
                                    {groups.map((_group, groupIndex) => (
                                        <RootLevelGroup
                                            group={_group}
                                            setShowPublishModal={setShowPublishModal}
                                            setShowExportChangesModal={setShowExportChangesModal}
                                            isLoading={isLoading}
                                            level={level + 1}
                                            setShowDuplicateModal={setShowDuplicateModal}
                                            index={groupIndex}
                                            handleMove={handleMove}
                                            isLast={groups.length - 1 === groupIndex}
                                            key={`group-${_group.id}`}
                                        />
                                    ))}
                                </div>
                            )}

                            <div className="bg-white">
                                {level > 0 && (
                                    <>
                                        <GroupHeader publications={publications} groupId={group.id} />

                                        {isLoading && publications.length === 0 && (
                                            <div className="p-3">
                                                <LoadingSpinner inline size="sm" />
                                            </div>
                                        )}

                                        {!isLoading && publications.length === 0 && (
                                            <div className="p-3">
                                                <div
                                                    className="small text-secondary"
                                                    style={{ lineHeight: '21px', marginLeft: '33px' }}
                                                >
                                                    <span style={{ marginLeft: '30px' }}>
                                                        {t('publications.noPublications')}
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}

                                {publications.length > 0 && (
                                    <GroupPublications
                                        publications={publications}
                                        setShowExportChangesModal={setShowExportChangesModal}
                                        setShowPublishModal={setShowPublishModal}
                                        setShowDuplicateModal={setShowDuplicateModal}
                                    />
                                )}

                                {open && publications.length > 0 && <>{provided.placeholder}</>}
                            </div>
                        </div>
                    </Collapse>
                </div>
            )}
        </Droppable>
    );
}
