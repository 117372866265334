import { useGetFileQuery } from 'features/comments/commentApi';

export function useGetFile(fileId) {
    const { file } = useGetFileQuery(fileId, {
        selectFromResult: (result) => ({
            file: result.currentData,
        }),
    });

    return file;
}
