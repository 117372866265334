import { ActionLinkButton, PrimaryButton } from 'components/Buttons';
import { ADMIN_SETTING_ACCOUNT_PATH } from 'scenes/Admin';
import { Badge, Table } from 'react-bootstrap';
import { generatePath, useHistory } from 'react-router-dom';
import { CheckCircleFill, XCircleFill } from 'react-bootstrap-icons';
import HelperFunctions from '../../../global/HelperFunctions';

export function List({ account }) {
    const history = useHistory();

    const sortedOrganisations = [...(account?.organisations ?? [])].sort(HelperFunctions.sortByString('name'));

    return (
        <div>
            <div className="subheader d-flex align-items-center justify-content-between pb-1 mb-3">
                <div className="d-flex align-items-baseline">
                    <h3 className="mb-0">{account.name}: organisaties</h3>
                </div>

                <PrimaryButton
                    variant="info"
                    size="sm"
                    className="mb-0"
                    onClick={() => {
                        history.push(
                            generatePath(ADMIN_SETTING_ACCOUNT_PATH, {
                                id: account.id,
                                view: 'organisations',
                                action: 'create',
                            })
                        );
                    }}
                >
                    Nieuwe organisatie
                </PrimaryButton>
            </div>

            <Table striped>
                <thead>
                    <tr>
                        <td className="font-weight-bold border-top-0">Naam</td>
                        <td className="font-weight-bold border-top-0">Gebruikers</td>
                        <td className="font-weight-bold border-top-0">SSO enabled</td>
                        <td className="font-weight-bold border-top-0">Ingeschakeld</td>
                        <td className="font-weight-bold border-top-0">Ipid insurer</td>
                    </tr>
                </thead>
                <tbody>
                    {sortedOrganisations.map((organisation) => (
                        <Organisation
                            organisation={organisation}
                            accountId={account.id}
                            key={`organisation-${organisation.id}`}
                        />
                    ))}
                </tbody>
            </Table>
        </div>
    );
}

function Organisation({ organisation, accountId }) {
    const history = useHistory();

    return (
        <tr>
            <td className="align-middle">
                <ActionLinkButton
                    onClick={() => {
                        history.push(
                            generatePath(ADMIN_SETTING_ACCOUNT_PATH, {
                                id: accountId,
                                view: 'organisations',
                                action: 'view',
                                entityId: organisation.id,
                            })
                        );
                    }}
                    className="font-weight-bolder"
                >
                    {organisation.name}
                </ActionLinkButton>
            </td>
            <td className="align-middle">
                <Badge variant="primary">{organisation.userCount}</Badge>
            </td>
            <td className="align-middle">
                {organisation.ssoEnabled ? (
                    <Badge variant="success" className="d-inline-flex align-items-center">
                        <CheckCircleFill className="flex-shrink-0 mr-1" />
                        Ja
                    </Badge>
                ) : (
                    <Badge variant="danger" className="d-inline-flex align-items-center">
                        <XCircleFill className="flex-shrink-0 mr-1" />
                        Nee
                    </Badge>
                )}
            </td>
            <td className="align-middle">
                {organisation.enabled ? (
                    <Badge variant="success" className="d-inline-flex align-items-center">
                        <CheckCircleFill className="flex-shrink-0 mr-1" />
                        Ja
                    </Badge>
                ) : (
                    <Badge variant="danger" className="d-inline-flex align-items-center">
                        <XCircleFill className="flex-shrink-0 mr-1" />
                        Nee
                    </Badge>
                )}
            </td>
            <td className="align-middle">
                {organisation.ipidImport ? (
                    <Badge variant="success" className="d-inline-flex align-items-center">
                        <CheckCircleFill className="flex-shrink-0 mr-1" />
                        Ja
                    </Badge>
                ) : (
                    <Badge variant="danger" className="d-inline-flex align-items-center">
                        <XCircleFill className="flex-shrink-0 mr-1" />
                        Nee
                    </Badge>
                )}
            </td>
        </tr>
    );
}
