import { Button, Modal } from 'react-bootstrap';
import Spinner from './Spinner';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function FormModal({
    onHide,
    show = false,
    title = '',
    size,
    keyboard = true,
    backdrop = true,
    dialogClassName,
    scrollable = false,
    children,
}: {
    onHide: () => void;
    show: boolean;
    title: string;
    size?: 'sm' | 'lg' | 'xl';
    keyboard?: boolean;
    backdrop?: boolean;
    scrollable?: boolean;
    dialogClassName?: string;
    children: React.ReactNode;
}) {
    return (
        <Modal
            show={show}
            onHide={onHide}
            size={size}
            keyboard={keyboard}
            backdrop={backdrop}
            scrollable={scrollable}
            dialogClassName={dialogClassName}
        >
            {/* 
            // @ts-ignore */}
            <Modal.Header closeButton={true}>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            {children}
        </Modal>
    );
}

export function ModalFooter({
    btnCancelText = 'btn.cancel',
    btnSubmitText = 'btn.save',
    isSubmitting = false,
    onHide,
    isValid = true,
    dirty = true,
    form,
}: {
    btnCancelText?: string;
    btnSubmitText?: string;
    isSubmitting?: boolean;
    onHide: () => void;
    isValid?: boolean;
    dirty?: boolean;
    form?: string;
}) {
    const { t } = useTranslation('global');
    const cancelText = t(btnCancelText) || btnCancelText;
    const submitText = t(btnSubmitText) || btnSubmitText;

    return (
        <Modal.Footer>
            {isSubmitting && <Spinner />}

            <Button variant="btn btn-secondary" disabled={isSubmitting} onClick={onHide}>
                {cancelText}
            </Button>
            <Button variant="btn btn-warning" disabled={isSubmitting || !isValid || !dirty} type="submit" form={form}>
                {submitText}
            </Button>
        </Modal.Footer>
    );
}
