import ApiConnector from "./ApiConnector";
import Constants from "../config/Constants";
import getEnv from "../config/Env";
import baseAPI from "./ApiConnector_v2";
import queryString from 'query-string';

const baseUrl = getEnv('FILE_API_URL');
const apiUrl = baseUrl + '/api';

const FileApi = {
    createFolder(formData) {
        return baseAPI.post(apiUrl + '/folders', formData)
    },
    getFolders(organisationId) {
        return baseAPI.get(apiUrl + '/folders?organisationId=' + organisationId)
    },
    get(uri) {
        return baseAPI.get(baseUrl + uri)
    },
    getFiles(organisationId) {
        return baseAPI.get(apiUrl + '/uploads?organisationId=' + organisationId)
    },
    getPrivateFile(uri, responseType = 'blob', forViewer = false) {
        return baseAPI.get(baseUrl + uri + '/render?' + queryString.stringify({forViewer}, { arrayFormat: 'bracket' }), {
            responseType,
        });
    },
    patch(uri, formData) {
        return baseAPI.patch(baseUrl + uri, formData)
    },
    delete(uri) {
        return baseAPI.delete(baseUrl + uri)
    },
    deleteFile(id) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiDelete(apiUrl + '/uploads/' + id)
                .then(result => resolve(result))
                .catch(context => {
                    getEnv('APP_ENV') === Constants.environments.dev && console.log('unable to delete file ', context);
                    reject(context);
                });
        });
    },
    uploadFile(file, organisationId, folderId) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('organisationId', organisationId);
        formData.append('folder', folderId);

        return baseAPI.post(apiUrl + '/uploads', formData)
    },
};

export default FileApi;
