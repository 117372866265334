import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Button, Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import LoadingSpinner from '../../../global/LoadingSpinner';
import { ToolbarButton } from '../_BlockParser/EditorToolbar';
import DocumentApi from '../../../../api/DocumentApi';
import Parser from 'html-react-parser';
import { fetchArea, selectAreaById } from '../../../../features/documents/documentsV2Slice';
import { useGetDocument } from '../../../documents_v2/hooks/useGetDocument';

export default function ClonedBlocksButton({ blockId, document }) {
    const [showModal, setShowModal] = useState(false);

    // If the Current document is not a clone, hide button
    if (_.isNumber(document.clonedFromId) === false) {
        return null;
    }

    return (
        <>
            <ToolbarButton icon="history" handleClick={() => setShowModal(true)} tooltip="Blokken uit vorige versies" />

            {showModal && <BlocksModal onHide={() => setShowModal(false)} blockId={blockId} />}
        </>
    );
}

function BlocksModal({ onHide, blockId }) {
    return (
        <Modal show onHide={onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Vergelijk het huidige blok met het originele document</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Block id={blockId} />
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" type="button" onClick={onHide}>
                    Sluiten
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function Block({ id }) {
    const [isLoading, setLoading] = useState(true);
    const [blocks, setBlocks] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const { data } = await DocumentApi.getBlockCloneContent(id);

            setLoading(false);
            setBlocks(data);
        }

        if (isLoading) {
            fetchData();
        }
    }, [id]);

    return (
        <div>
            {isLoading && <LoadingSpinner inline size="sm" />}

            {isLoading === false && blocks.length === 0 && (
                <div>
                    <p>Het huidige blok is identiek aan het originele document.</p>
                </div>
            )}

            {blocks.map((block, index) => (
                <BlockContent data={block} key={`block-${id}-content-${index}`} />
            ))}
        </div>
    );
}

function BlockContent({ data }) {
    const { areaId, oldContextId } = data;
    const area = useSelector((state) => selectAreaById(state.documents_v2, areaId));
    const dispatch = useDispatch();
    const document = useGetDocument(oldContextId);

    useEffect(() => {
        if (area === undefined) {
            dispatch(fetchArea({ id: areaId }));
        }
    }, [areaId]);

    return (
        <div className="px-4 mb-4">
            <div className="d-flex mb-3">
                {document && <div className="font-weight-bold mr-1">{document.name}</div>}
                {area && <div className="text-secondary">{area.title}</div>}
            </div>

            <div className="read-only-content small bg-light dr-container p-3">{Parser(data.diffContent)}</div>
        </div>
    );
}
