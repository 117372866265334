import { useEffect } from 'react';
import { getAuthToken, resetTokenData } from 'api/AuthApi';
import { resetState } from 'features/security/securitySlice';
import { useDispatch } from 'react-redux';
import {
    translationApi,
    translationDocumentApi,
    translationReimbursementApi,
} from 'features/translations/translationApi';
import { reimbursementApi } from 'features/reimbursements/reimbursements';
import { documentApi } from 'features/documents/documents';
import { templatesApi } from 'features/templates/templatesApi';
import { commentsApi } from 'features/comments/commentApi';
import { publicationApi } from 'features/publications/publicationApi';
import { metaApi } from 'features/metadata/metadata';
import { aiApi } from 'features/openai/openAIApi';
import { authApi, useLogoutMutation } from 'features/security/authApi';
import { useHistory } from 'react-router-dom';

export default function Logout() {
    const dispatch = useDispatch();
    const authToken = getAuthToken();
    const history = useHistory();

    const [logout] = useLogoutMutation();

    function actionCreator() {
        return (dispatch) => {
            dispatch(resetState());
            dispatch(documentApi.util.resetApiState());
            dispatch(translationApi.util.resetApiState());
            dispatch(translationDocumentApi.util.resetApiState());
            dispatch(translationReimbursementApi.util.resetApiState());
            dispatch(reimbursementApi.util.resetApiState());
            dispatch(templatesApi.util.resetApiState());
            dispatch(commentsApi.util.resetApiState());
            dispatch(publicationApi.util.resetApiState());
            dispatch(metaApi.util.resetApiState());
            dispatch(aiApi.util.resetApiState());
            dispatch(authApi.util.resetApiState());

            return Promise.resolve();
        };
    }

    useEffect(() => {
        if (!_.isEmpty(authToken)) {
            logout().finally(() => {
                dispatch(actionCreator()).then(() => {
                    resetTokenData();
                    history.push('/');
                });
            });
        } else {
            history.push('/');
        }
    }, [authToken]);

    return null;
}
