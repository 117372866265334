import { Col, Form, Modal, Row } from 'react-bootstrap';
import { FormField, InputMultipleSelect, Switch } from '../../../publications_v2/helpers/FieldHelper';
import { FormModal, ModalFooter } from '../../../global/FormModal';
import { Form as FForm, Formik, FormikHelpers } from 'formik';
import { Reimbursement } from '../../../../models/reimbursement.models';
import { useState } from 'react';
import { ExclamationCircle, QuestionCircle } from 'react-bootstrap-icons';
import Constants from '../../../../config/Constants';
import RestrictedReimbursementContent from '../../RestrictedReimbursementContent';
import * as Yup from 'yup';
import {
    useAddReimbursementMutation,
    useUpdateReimbursementMutation,
} from '../../../../features/reimbursements/reimbursements';
import { Team, useGetTeams } from '../../hooks/useGetTeams';
import HelperFunctions from '../../../global/HelperFunctions';
import { useTranslation } from 'react-i18next';

interface ActionValue {
    action: string;
    name: string;
    option?: Team;
    removedValue?: Team;
    removedValues?: Team[];
}

export default function ReimbursementModal({
    reimbursement,
    handleClose,
}: {
    reimbursement: Partial<Reimbursement>;
    handleClose: () => void;
}) {
    const isEditMode = reimbursement.id !== undefined;
    const [enableCodeEdit, setEnableCodeEdit] = useState(!isEditMode);
    const [updateReimbursement] = useUpdateReimbursementMutation();
    const [addReimbursement] = useAddReimbursementMutation();
    const { t } = useTranslation('reimbursements');

    const teams = useGetTeams();

    const handleSubmit = (values: Partial<Reimbursement>, helpers: FormikHelpers<Partial<Reimbursement>>) => {
        if (isEditMode) {
            updateReimbursement({
                uri: reimbursement['@id'],
                category: reimbursement.category,
                ...values,
            }).then(() => {
                helpers.setSubmitting(false);
                handleClose();
            });
        } else {
            addReimbursement({
                ...values,
            }).then(() => {
                helpers.setSubmitting(false);
                handleClose();
            });
        }
    };

    return (
        <FormModal show={true} onHide={handleClose} title={t('reimbursement.editModal.title')}>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    name: reimbursement?.name ?? '',
                    code: reimbursement?.code ?? '',
                    description: reimbursement?.description ?? '',
                    enabled: reimbursement?.enabled ?? true,
                    category: reimbursement?.category ?? '',
                    reimbursementGroup: reimbursement?.reimbursementGroup ?? false,
                    teams: reimbursement?.teams ?? [],
                }}
                validationSchema={ReimbursementSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, dirty, values, isValid, setFieldValue }) => (
                    <FForm autoComplete="off">
                        <Modal.Body>
                            <FormField name="name" label={`${t('reimbursement.editModal.name')}*`} />

                            {enableCodeEdit ? (
                                <FormField name="code" label="Code*" />
                            ) : (
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={4} htmlFor="code">
                                        Code*
                                    </Form.Label>
                                    <Col sm={8}>
                                        <RestrictedReimbursementContent
                                            roles={[
                                                Constants.reimbursementTeamRoles.manager,
                                                Constants.reimbursementTeamRoles.admin,
                                            ]}
                                        >
                                            <div className="form-control-plaintext">
                                                <span className="mr-2">{values.code}</span>
                                                <EnableCodeEdit setEnableCodeEdit={setEnableCodeEdit} />
                                            </div>
                                        </RestrictedReimbursementContent>

                                        <RestrictedReimbursementContent
                                            roles={[
                                                Constants.reimbursementTeamRoles.finalEditor,
                                                Constants.reimbursementTeamRoles.editor,
                                            ]}
                                        >
                                            <div className="form-control-plaintext d-flex align-items-center">
                                                <div className="text-muted mr-2">{values.code}</div>
                                                <QuestionCircle
                                                    className="text-primary"
                                                    data-uk-tooltip={t('reimbursement.editModal.tooltip.onlyAdmin')}
                                                />
                                            </div>
                                        </RestrictedReimbursementContent>
                                    </Col>
                                </Form.Group>
                            )}

                            <FormField name="description" label={t('reimbursement.editModal.description')} />

                            <Switch name="enabled" label={t('reimbursement.editModal.active')} />

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm={4} htmlFor="teams">
                                    {t('reimbursement.editModal.departments')}
                                </Form.Label>
                                <Col sm={8}>
                                    <InputMultipleSelect
                                        name="teams"
                                        value={undefined}
                                        // @ts-ignore
                                        defaultValue={HelperFunctions.prepareDropdownData(
                                            teams.filter((team) => reimbursement.teams?.includes(team.id))
                                        )}
                                        options={HelperFunctions.prepareDropdownData(teams)}
                                        onChange={(selectedOptions: Team[], action: ActionValue) => {
                                            setFieldValue(
                                                'teams',
                                                selectedOptions.map((team) => team.id)
                                            );
                                        }}
                                        props={{
                                            placeholder: `${t('reimbursement.editModal.titleSelect')}...`,
                                        }}
                                    />
                                </Col>
                            </Form.Group>
                        </Modal.Body>

                        <ModalFooter dirty={dirty} isSubmitting={isSubmitting} isValid={isValid} onHide={handleClose} />
                    </FForm>
                )}
            </Formik>
        </FormModal>
    );
}

function EnableCodeEdit({ setEnableCodeEdit }: { setEnableCodeEdit: (value: boolean) => void }) {
    const { t } = useTranslation('reimbursements');

    return (
        <>
            <span className="text-primary small cursor-pointer mr-1" onClick={() => setEnableCodeEdit(true)}>
                {t('reimbursement.editModal.editCode')}
            </span>
            <ExclamationCircle
                className="text-primary"
                data-uk-tooltip={t('reimbursement.editModal.tooltip.lostCodes')}
            />
        </>
    );
}

const ReimbursementSchema = Yup.object().shape({
    name: Yup.string().required(),
    code: Yup.string().required(),
    category: Yup.string().required(),
});
