import getEnv from '../../config/Env';
import { baseQueryWithReauth, prepareHeaders, providesList } from '../api';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = getEnv('FILE_API_URL');

const mediaApiQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders,
});

export const mediaApi = createApi({
    reducerPath: 'mediaApi',
    baseQuery: (args, api, extraOptions) => baseQueryWithReauth(args, api, extraOptions, mediaApiQuery),
    tagTypes: ['Upload', 'PrivateUpload'],
    endpoints: (builder) => ({
        getFile: builder.query({
            query: (uri) => ({
                url: uri,
            }),
            providesTags: (result) => ([{ type: 'Upload', id: result['@id'] }]),
        }),
        getUploads: builder.query({
            query: (organisationId) => ({
                url: '/api/uploads',
                params: {
                    organisationId,
                },
            }),
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result) => providesList(result, 'Upload', 'id'),
        }),
        uploadFile: builder.mutation({
            query: ({ file, organisationId, folderId, url }) => {
                let body = {};

                if (file) {
                    body = new FormData();
                    formData.append('file', file);
                    formData.append('organisationId', organisationId);
                    formData.append('folder', folderId);
                }

                if (!file && url) {
                    body = {
                        url,
                        organisationId,
                        folderId,
                    };
                }

                return {
                    url: '/api/uploads',
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: (result, error, arg) => [{ type: 'Upload', id: 'LIST' }],
        }),
        uploadPrivateFile: builder.mutation({
            query: ({ file, organisationId }) => {
                const body = new FormData();
                body.append('file', file);
                body.append('organisationId', organisationId);

                return {
                    url: '/api/private_uploads',
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: () => [{ type: 'PrivateUpload', id: 'LIST' }],
        }),
    }),
});

export const { useGetFileQuery, useGetUploadsQuery, useUploadFileMutation, useUploadPrivateFileMutation } = mediaApi;
