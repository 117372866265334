import { Form, Formik } from 'formik';
import {
    usePostOrganisationKioskCategoryMutation,
    useUpdateKioskCategoryMutation,
} from '../../../../features/security/authApi';
import { FormField } from '../../helpers/FieldHelper';
import { Modal } from 'react-bootstrap';
import { ModalFooter } from '../../../global/FormModal';
import { useActiveOrganisation } from '../../../../hooks/useActiveOrganisation';
import { useTranslation } from 'react-i18next';

export function CreateOrUpdateKioskCategory({ mode, selectedKioskCategory, setShowModal }) {
    const { t } = useTranslation('publications');
    const activeOrganisation = useActiveOrganisation();
    const [postOrganisationKioskCategory] = usePostOrganisationKioskCategoryMutation();
    const [updateOrganisationKioskCategory] = useUpdateKioskCategoryMutation();

    const handleSubmit = async (values) => {
        try {
            if (mode === 'create') {
                await postOrganisationKioskCategory(values);
            } else {
                await updateOrganisationKioskCategory({
                    kioskCategoryId: selectedKioskCategory.id,
                    activeOrganisation,
                    body: values,
                });
            }

            setShowModal(false);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <Formik initialValues={selectedKioskCategory} onSubmit={handleSubmit}>
            {({ isSubmitting, isValid }) => (
                <Form>
                    <Modal.Body>
                        <FormField
                            label={`${t('kiosk.label_name')}*`}
                            name="categoryName"
                            props={{ placeholder: t('kiosk.label_name_placeholder') }}
                        />
                    </Modal.Body>
                    <ModalFooter isSubmitting={isSubmitting} isValid={isValid} onHide={setShowModal} />
                </Form>
            )}
        </Formik>
    );
}
