export const formatAsOptions = (items = [], idField = '@id', labelField = 'name') => {
    return items.map(formatAsOptionsMapFn(idField, labelField))
}

export const formatAsOptionsMapFn = (idField = '@id', labelField = 'name') => {
    return (_item) => formatLabelAsOption(_item, idField, labelField)
}

export const formatLabelAsOption = (_item, idField = '@id', labelField = 'name') => {
    return {
        value: _item[idField],
        label: _item[labelField],
    }
}
