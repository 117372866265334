import Constants from '../../../../config/Constants';
import BaseBlocks from './BaseBlocks';
import { views } from 'pages/documents_v2/config/views';
import { BlockVariants } from 'pages/documents_v2/views/edit/BlockVariants';
import { useSelector } from 'react-redux';
import { Preview } from './Preview';
import { useGetDocument } from 'pages/documents_v2/hooks/useGetDocument';
import { BlockCompare } from 'pages/documents_v2/views/edit_area/BlockCompare';

export function EditAreaRightSideContent({
    showRightSide = true,
    areaBlocks,
    baseBlocks,
    rightSideProps,
    area,
    activeVariantId,
}) {
    const { status } = useGetDocument();
    const rightSideEditorView = useSelector((state) => state.documents_v2.rightSideEditorView);

    if (!showRightSide) {
        return null;
    }

    return (
        <>
            {rightSideEditorView === views.PREVIEW && <Preview />}

            {rightSideEditorView === views.VARIANTS && <BlockVariants blocks={areaBlocks} />}

            {rightSideEditorView === views.BLOCK_COMPARE && <BlockCompare />}

            {rightSideEditorView === views.CHANGES && (
                <>
                    {status !== Constants.documentStatus.draft && (
                        <BaseBlocks
                            baseBlocks={baseBlocks}
                            rightSideProps={rightSideProps}
                            area={area}
                            activeVariantId={activeVariantId}
                        />
                    )}
                </>
            )}
        </>
    );
}
