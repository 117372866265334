import { useEffect, useState } from 'react';

export function VideoViewer({ data }) {
    const [video, setVideo] = useState();

    useEffect(() => {
        const blob = new Blob([data]);
        setVideo(URL.createObjectURL(blob));
    }, []);

    if (!video) {
        return null;
    }

    return (
        <div id="video">
            <video controls={true} autoPlay={true} src={video} />
        </div>
    );
}
