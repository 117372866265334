import React from 'react';
import PropTypes from 'prop-types';
import Language from "../../language/Language";

const LoadingSpinner = props => {
    let height = '';
    let size = '';
    if (props.viewportHeight) {
        height = ' uk-height-viewport';
    }
    if (props.size) {
        switch (props.size) {
            case 'lg':
            case 'large':
                size = 'large';
                break;
            case 'sm':
            case 'small':
                size = 'small';
                break;
            default:
                size = 'default';
                break;
        }
    }
    let theme = '';
    if (props.successTheme) {
        theme = ' spinner-success'
    }

    return <div className={(props.inline ? 'uk-display-inline-block' : 'uk-width-1-1 uk-margin-large-top uk-margin-large-bottom uk-flex uk-flex-center uk-flex-middle') + height}>
        <div className={"uk-text-center" + (props.inline ? ' uk-display-inline-block' : '')}>
            {props.loadingMessage !== undefined && <p className="uk-text-large uk-text-muted">{Language.getTranslation(props.loadingMessage, 'messages')}</p>}
            <div className={"spinner spinner-size-" + size + theme}>
                <span className="rect1" />
                <span className="rect2" />
                <span className="rect3" />
                <span className="rect4" />
                <span className="rect5" />
                <span className="rect6" />
                <span className="rect7" />
            </div>
        </div>
    </div>
};

export default LoadingSpinner;

LoadingSpinner.propTypes = {
    inline: PropTypes.bool,
    loadingMessage: PropTypes.string,
    size: PropTypes.string,
    viewportHeight: PropTypes.bool,
};