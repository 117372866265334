import _ from 'lodash';

export const isEnabledForTranslation = (entityReference, language) => {
    const { enabledForTranslation } = entityReference;

    return _.get(enabledForTranslation, language, false);
};

export const isTranslationInProgress = (entityReference, language) => {
    const { translationStatus } = entityReference;

    const status = _.get(translationStatus, language, {});
    const untranslated = _.get(status, 'untranslated', 0);
    const unverified = _.get(status, 'unverified', 0);

    return untranslated > 0 || unverified > 0;
};

export const getStatus = (entityReference, property, language) => {
    const { translationStatus } = entityReference;

    const status = _.get(translationStatus, language, {});

    return _.get(status, property, 0);
};
