import { useState } from 'react';
import cx from 'classnames';
import { BasicEditorInner } from '../../../documents/misc/_BlockParser/BasicEditor';

export default function BasicEditor({
    showRef = true,
    tags = [],
    name,
    onChange,
    value,
    id,
    placeholder = '',
    forceMinHeight = false,
    showEditor = false,
    setShowEditor,
}) {
    const [editorLoaded, setEditorLoaded] = useState(false);

    const wrapperClassName = cx('area-text-block basic-editor-text-block', {
        'area-text-block-has-editor': showEditor && editorLoaded,
        'area-text-block-no-editor': !showEditor || !editorLoaded,
        'area-text-block-min-height': forceMinHeight,
    });

    return (
        <div
            className={wrapperClassName}
            onClick={() => {
                if (!showEditor) {
                    setShowEditor(true);
                }
            }}
        >
            <BasicEditorInner
                value={value}
                name={name}
                id={id}
                onChange={onChange}
                showRef={showRef}
                tags={tags}
                showEditor={showEditor}
                editorLoaded={editorLoaded}
                setEditorLoaded={setEditorLoaded}
                setShowEditor={setShowEditor}
                placeholder={placeholder}
            />
        </div>
    );
}
