import axios from 'axios';
import { getAuthToken, getProfile, getRefreshToken, saveTokenData } from './AuthApi';
import getEnv from '../config/Env';
import { Mutex } from 'async-mutex';

const axiosApiInstance = axios.create();
const authApiUrl = getEnv('AUTH_API_URL');

const baseAPI = {
    get(uri, params = {}) {
        return axiosApiInstance.get(uri, params);
    },
    post(uri, formData = {}, params = {}) {
        return axiosApiInstance.post(uri, formData, params);
    },
    patch(uri, formData) {
        const patch = {
            'headers': {
                'Content-Type': 'application/merge-patch+json',
            },
        };

        return axiosApiInstance.patch(uri, formData, patch);
    },
    put(uri, formData) {
        return axiosApiInstance.put(uri, formData);
    },
    delete(uri, params = {}) {
        return axiosApiInstance.delete(uri, params);
    },
};

const mutex = new Mutex();

axiosApiInstance.interceptors.request.use(
    async (config) => {
        const { method, url } = config;

        if (getAuthToken() === '') {
            // No token to set
            return config;
        }

        await mutex.waitForUnlock();

        if (method === 'post') {
            // If token is about to expire, refresh it to prevent token timeout errors when getting data from the api
            const { exp } = getProfile();
            const secondsUntilExpiration = exp - Math.floor(Date.now() / 1000);
            const isLogoutUrl = url.endsWith('/logouts');

            // < 5 minutes until expiration
            if (secondsUntilExpiration < 300 && !!isLogoutUrl) {
                if (!mutex.isLocked()) {
                    const release = await mutex.acquire();
                    try {
                        const newToken = await refreshToken();
                        config.headers['Authorization'] = `Bearer ${newToken}`;
                    } finally {
                        release();
                    }
                } else {
                    await mutex.waitForUnlock();
                    config.headers['Authorization'] = `Bearer ${getAuthToken()}`;
                }
            } else {
                config.headers['Authorization'] = `Bearer ${getAuthToken()}`;
            }
        } else {
            config.headers['Authorization'] = `Bearer ${getAuthToken()}`;
        }

        return config;
    },
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        const originalRequest = error.config;
        const is2faLoginUrl = error.config.url.endsWith('/2fa-login');

        if (error.response?.status === 401 && !originalRequest._retry && !is2faLoginUrl) {
            originalRequest._retry = true;

            const newToken = await refreshToken();
            originalRequest.headers['Authorization'] = 'Bearer ' + newToken;

            return axiosApiInstance(originalRequest);
        }

        return Promise.reject(error);
    });

const refreshToken = async () => {
    const axiosApiInstance = axios.create();

    const response = await axiosApiInstance.post(authApiUrl + '/token/refresh', {
        refresh_token: getRefreshToken(),
    });
    const { data } = response;

    // Save in application
    saveTokenData(data.token, data.refreshToken);

    return data.token;
};

export default baseAPI;
