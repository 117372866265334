import { Modal } from 'react-bootstrap';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { Form as FForm, Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { FormModal, ModalFooter } from 'pages/global/FormModal';
import { Dropzone } from 'pages/tags/Dropzone';
import HelperFunctions from 'pages/global/HelperFunctions';
import { InputMultipleSelect } from 'pages/publications_v2/helpers/FieldHelper';
import { read, utils } from 'xlsx';
import { UserRoles } from 'pages/admin/views/users/UserForm';
import { useImportAccountUsersMutation } from 'features/security/authApi';

export function ImportUsersModal({ account }) {
    const [showImportModal, setShowImportModal] = useQueryParam('import', BooleanParam);

    const hide = () => {
        setShowImportModal(undefined);
    };

    return (
        <FormModal onHide={hide} show={showImportModal === true} title="Gebruikers importeren">
            {showImportModal && <ModalContent account={account} hide={hide} />}
        </FormModal>
    );
}

function ModalContent({ account, hide }) {
    const [importAccountUsers] = useImportAccountUsersMutation();

    return (
        <Formik
            initialValues={{
                users: [],
                organisations: [],
                roles: [],
            }}
            enableReinitialize={true}
            validationSchema={ImportSchema}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, isValid, dirty }) => (
                <FForm autoComplete="off">
                    <Modal.Body>
                        <FormContent account={account} />
                    </Modal.Body>

                    <ModalFooter isSubmitting={isSubmitting} isValid={isValid} dirty={dirty} onHide={hide} />
                </FForm>
            )}
        </Formik>
    );

    function handleSubmit(values) {
        importAccountUsers({ id: account.id, body: values }).then(() => {
            hide();
        });
    }
}

function FormContent({ account }) {
    const { values, setFieldValue } = useFormikContext();

    return (
        <div>
            <Dropzone onDropComplete={handleImport} />

            <div className="mt-3 mb-3 form-group row">
                <label htmlFor="users" className="form-label col-form-label col-sm-4">
                    Aantal gebruikers
                </label>
                <div className="col-sm-8">
                    <input
                        type="text"
                        readOnly
                        className="form-control-plaintext"
                        id="users"
                        value={values.users.length}
                    />
                </div>
            </div>

            <div className="mt-3 mb-3 form-group row">
                <label htmlFor="organisations" className="form-label col-form-label col-sm-4">
                    Organisaties
                </label>

                <div className="col-sm-8">
                    <InputMultipleSelect
                        name="organisations"
                        options={HelperFunctions.prepareDropdownData(account.organisations, 'name')}
                        onChange={(selectedOptions) => {
                            setFieldValue('organisations', selectedOptions ?? []);
                        }}
                        value={values.organisations}
                    />
                </div>
            </div>

            <div className="mt-3 mb-3 form-group row">
                <label htmlFor="roles" className="form-label col-form-label col-sm-4">
                    Standaard rollen
                </label>

                <div className="col-sm-8">
                    <UserRoles />
                </div>
            </div>
        </div>
    );

    function handleImport(file) {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);

        reader.onload = (event) => {
            const wb = read(event.target.result, { raw: true });
            const sheets = wb.SheetNames;

            if (sheets.length) {
                const rows = utils.sheet_to_json(wb.Sheets[sheets[0]], {
                    raw: true,
                    rawNumbers: true,
                });

                setFieldValue('users', rows);
            }
        };
    }
}

const ImportSchema = Yup.object().shape({
    organisations: Yup.array().min(1),
    users: Yup.array().min(1),
});
