import { Modal } from 'react-bootstrap';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { FormFieldWithTitle, InputSelect, InputSelectWithTitle } from 'pages/publications_v2/helpers/FieldHelper';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { ModalFooter } from 'pages/global/FormModal';
import {
    useAddChecklistMutation,
    useAddTeamRuleMutation,
    useUpdateChecklistMutation,
    useUpdateTeamRuleMutation,
} from 'features/comments/commentApi';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { MultipleSelect, WysiwygEditor } from 'pages/task_team/helpers/FormHelper';
import { useGetTargetAudiences } from 'pages/task_team/hooks/useGetTargetAudiences';
import { useGetTopics } from 'pages/task_team/hooks/useGetTopics';
import HelperFunctions from 'pages/global/HelperFunctions';
import { FormGroup } from 'components/SettingsSidebar/helpers/FormHelpers';

export function AddTeamRuleModal({ team }) {
    const [addTeamRule, setAddTeamRule] = useQueryParam('addTeamRule', BooleanParam);
    const organisationId = useActiveOrganisation();

    if (!addTeamRule) {
        return null;
    }

    return (
        <AddModalInner
            initialValues={{
                organisationId,
                team: team['@id'],
                topic: '',
                targetAudiences: [],
            }}
            close={close}
        />
    );

    function close() {
        setAddTeamRule(undefined);
    }
}

export function AddModalInner({ initialValues, close }) {
    const { t } = useTranslation();
    const [addTeamRule] = useAddTeamRuleMutation();
    const [updateTeamRule] = useUpdateTeamRuleMutation();
    const targetAudiences = useGetTargetAudiences();
    const topics = useGetTopics();

    return (
        <>
            <Modal show={true} onHide={close}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {initialValues.hasOwnProperty('id') ? 'Regel bewerken' : 'Regel toevoegen'}
                    </Modal.Title>
                </Modal.Header>
                <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                    {({ isValid, isSubmitting, dirty }) => {
                        return (
                            <Form>
                                <Modal.Body>
                                    <InputSelectWithTitle
                                        name="topic"
                                        options={HelperFunctions.prepareDropdownData(topics, 'name', '@id')}
                                        title="Onderwerp"
                                        props={{
                                            required: true,
                                        }}
                                    />

                                    <div className="mb-3">
                                        <div className="mb-2 font-weight-bolder label-title">Doelgroep(en)*</div>
                                        <div>
                                            <MultipleSelect
                                                options={targetAudiences}
                                                name="targetAudiences"
                                                props={{
                                                    placeholder: 'Selecteer doelgroep(en)...',
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Modal.Body>

                                <ModalFooter
                                    onHide={close}
                                    isSubmitting={isSubmitting}
                                    dirty={dirty}
                                    isValid={isValid}
                                />
                            </Form>
                        );
                    }}
                </Formik>
            </Modal>
        </>
    );

    function handleSubmit(values) {
        if (values.hasOwnProperty('id')) {
            // Update
            updateTeamRule({ uri: values['@id'], body: values }).then(() => {
                close();
            });
        } else {
            // Create
            addTeamRule(values).then(() => {
                close();
            });
        }
    }
}

const validationSchema = Yup.object().shape({
    topic: Yup.string().required(),
    targetAudiences: Yup.array().min(1),
});
