import { Change } from '../../../../models/reimbursement.models';
import {
    useGetReimbursementProductChangesQuery,
    useUpdateReimbursementChangesMutation,
} from '../../../../features/reimbursements/reimbursements';
import { Button } from 'react-bootstrap';
import { CheckCircleFill, XCircleFill } from 'react-bootstrap-icons';
import React, { useContext, useMemo } from 'react';
import { HistoryContext } from './ReimbursementProduct';
import { useTranslation } from 'react-i18next';

function ProcessAllButtons({
    reimbursementProductUri,
    reimbursementId,
    isSubmitting,
}: {
    reimbursementProductUri: string | undefined;
    reimbursementId: number;
    isSubmitting: boolean;
}) {
    const [updateReimbursementChanges] = useUpdateReimbursementChangesMutation();
    const { endGroupId } = useContext(HistoryContext);
    const activeVersionIsMostRecent = endGroupId === 0;

    const skipChanges = reimbursementProductUri === undefined || !activeVersionIsMostRecent;

    const { productChanges }: { productChanges: Change[] } = useGetReimbursementProductChangesQuery(reimbursementProductUri, {
        selectFromResult: ({ data }) => ({
            productChanges: data?.changes ?? [],
        }),
        skip: skipChanges,
    });

    const changes = useMemo(() => {
        if (skipChanges) {
            return [];
        }

        return productChanges;
    }, [skipChanges, productChanges]);

    const processChanges = (accept: boolean) => {
        const processedChanges = changes.map((_change) => ({
            ..._change,
            accepted: accept,
            processed: true,
        }));

        updateReimbursementChanges({ id: reimbursementId, ...processedChanges });
    };

    const hasChanges = changes.length > 0;
    const { t } = useTranslation('reimbursements');

    return (
        <>
            <Button
                className="d-flex align-items-center"
                disabled={!hasChanges || isSubmitting}
                variant="success"
                size="sm"
                data-uk-tooltip={hasChanges ? t('product.processAllButtons.accept') : undefined}
                onClick={() => processChanges(true)}
            >
                <CheckCircleFill className="mr-1" />
                {t('product.processAllButtons.acceptAll')}
            </Button>
            <Button
                className="d-flex align-items-center ml-2"
                disabled={!hasChanges || isSubmitting}
                variant="danger"
                size="sm"
                data-uk-tooltip={hasChanges ? t('product.processAllButtons.reject') : undefined}
                onClick={() => processChanges(false)}
            >
                <XCircleFill className="mr-1" />
                {t('product.processAllButtons.rejectAll')}
            </Button>
        </>
    );
}

export const ProcessAllButtonsMemo = React.memo(ProcessAllButtons);
