import React, { useRef } from 'react';
import { Row, Table } from 'react-bootstrap';
import { OpTeamEntryStatusSquare } from '../../op_team_entry/OpTeamEntryStatus';
import _ from 'lodash';
import { Bar } from 'react-chartjs-2';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { useTranslation } from 'react-i18next';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function Graphs({ selectedDepartment, reportsData, filters }) {
    const splitPerLabel = selectedDepartment?.splitPerLabel ?? true;
    const { t } = useTranslation('changelist');

    // Never show barChart if not splitting per label (there won't be anything to show if we remove the labels)
    const showBar = splitPerLabel ? _.get(filters, 'showBar', true) : false;
    const showTable = _.get(filters, 'showTable', true);

    const total = _.get(reportsData, 'values.total', 0);
    if (total <= 0) {
        return t('changelist.view.report.insufficientData');
    }

    // We only want to show the noLabel column/bar if there are actually entries with values.
    const showNoLabel = _.get(reportsData, ['values', 'labelTotals', ''], 0) > 0;

    return (
        <>
            {showTable && (
                <Row>
                    <TableReport reportsData={reportsData} splitPerLabel={splitPerLabel} showNoLabel={showNoLabel} />
                </Row>
            )}
            {showBar && (
                <Row className="op-list-report-graph-container">
                    <div className="op-list-report-graph-wrapper">
                        <BarChartReport reportsData={reportsData} showNoLabel={showNoLabel} />
                    </div>
                </Row>
            )}
        </>
    );
}

function TableReport({ reportsData, splitPerLabel, showNoLabel }) {
    const tableRef = useRef();
    const { t } = useTranslation('changelist');

    // If department not splitPerLabel, then only the totals per status are shown
    return (
        <div className="react-bootstrap-table" ref={tableRef} id="report-table">
            <Table hover className="op-list-report-table">
                <thead>
                    <tr>
                        <th key="spacing-header" className="spacing-header" />
                        {splitPerLabel &&
                            Object.values(reportsData.labels).map((labelName) => {
                                // If empty label (for entries without labels) and we want to show it format name to 'geen label'
                                if (null === labelName) {
                                    if (!showNoLabel) {
                                        return null;
                                    }
                                    labelName = t('changelist.view.report.noLabel');
                                }

                                return <th key={`${labelName}-header`}>{labelName}</th>;
                            })}
                        <th key="total-header">{t('changelist.view.report.total')}</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(reportsData.values.statusMapping).map((status) => {
                        const statusValue = reportsData.values.statusMapping[status];
                        const statusCodeInfo = reportsData.statusCodes[status] ?? {
                            label: t('changelist.view.report.unknown'),
                            color: '#000',
                        };

                        return (
                            <tr key={`${status}-row`}>
                                <td key={`${status}-label-row`}>
                                    <OpTeamEntryStatusSquare
                                        statusLabel={statusCodeInfo.label}
                                        statusColor={statusCodeInfo.color}
                                    />
                                    {statusCodeInfo.label}
                                </td>
                                {splitPerLabel &&
                                    Object.values(reportsData.labels).map((labelName) => {
                                        if (null === labelName && !showNoLabel) {
                                            return null;
                                        }

                                        return (
                                            <td key={`${status}-${labelName}-row`}>
                                                {statusValue.labelMapping[labelName ?? ''] ?? 0}
                                            </td>
                                        );
                                    })}
                                <td key={`${status}-total-row`}>{statusValue.total}</td>
                            </tr>
                        );
                    })}
                    <tr key="total-row">
                        <td key="total-total-label-row">{t('changelist.view.report.total1')}</td>
                        {splitPerLabel &&
                            Object.values(reportsData.labels).map((labelName) => {
                                if (null === labelName && !showNoLabel) {
                                    return null;
                                }

                                return (
                                    <td key={`total-${labelName}-row`}>
                                        {reportsData.values.labelTotals[labelName ?? ''] ?? 0}
                                    </td>
                                );
                            })}
                        <td key="total-complete-total-row">{reportsData.values.total}</td>
                    </tr>
                </tbody>
            </Table>
        </div>
    );
}

function BarChartReport({ reportsData, showNoLabel }) {
    const barChartOptions = {
        plugins: {
            legend: {
                position: 'bottom',
            },
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
                ticks: {
                    precision: 0,
                },
            },
        },
    };

    const barChartData = resolveBarChartData(reportsData, showNoLabel);

    return <Bar pointStyle="star" data={barChartData} options={barChartOptions} />;
}

function resolveBarChartData(reportsData, showNoLabel) {
    const { t } = useTranslation('changelist');

    return {
        labels: Object.values(reportsData.labels)
            .map((labelName) => {
                // If empty label (for entries without labels) and we want to show it format name to 'geen label'
                if (null === labelName && showNoLabel) {
                    labelName = t('changelist.view.report.noLabel');
                }

                return labelName;
            })
            .filter((label) => null !== label),
        datasets: Object.keys(reportsData.values.statusMapping).map((status) => {
            const statusValue = reportsData.values.statusMapping[status];
            const statusCodeInfo = reportsData.statusCodes[status] ?? {
                label: t('changelist.view.report.unknown'),
                color: '#000',
            };

            return {
                label: statusCodeInfo.label,
                backgroundColor: statusCodeInfo.color,
                data: Object.values(
                    _.filter(statusValue.labelMapping, (val, label) => {
                        // Only include the data for entries without label if we want to showNoLabel
                        if ('' === label && !showNoLabel) {
                            return false;
                        }
                        return true;
                    })
                ),
            };
        }),
    };
}
