import { useGetDocumentVariantsQuery } from '../../../features/documents/documents';

export function useGetBaseVariant(documentId) {
    const { baseVariant } = useGetDocumentVariantsQuery(documentId, {
        selectFromResult: ({ data }) => ({
            baseVariant: data ? data.find((variant) => variant.parentId === null) : undefined,
        }),
    });

    return baseVariant;
}

export function useGetVariant(documentId, variantId) {
    const { variant } = useGetDocumentVariantsQuery(documentId, {
        selectFromResult: ({ data }) => ({
            variant: data ? data.find((variant) => variant.id === variantId) : undefined,
        }),
    });

    return variant;
}
