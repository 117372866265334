import { Alert, Modal } from 'react-bootstrap';
import { StringParam, useQueryParam } from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { useGetTask } from 'pages/task_team/hooks/useGetTask';
import { FieldArray, Form, Formik, useFormikContext } from 'formik';
import { ModalFooter } from 'pages/global/FormModal';
import { useGetUserFullName } from 'hooks/useGetUserFullName';
import { IconButton } from 'components/Buttons';
import { FileEarmarkText, X } from 'react-bootstrap-icons';
import * as Yup from 'yup';
import { FileUploader } from 'pages/task_team/helpers/FileUploader';
import { TextArea } from 'pages/task_team/helpers/FormHelper';
import { commentsApi, useAddManagementDecisionMutation } from 'features/comments/commentApi';
import { useUploadPrivateFileMutation } from 'features/mediaLibrary/mediaApi';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { useDispatch } from 'react-redux';
import { fileType } from 'pages/task_team/config/constants';

export function ApproveTaskModal() {
    const [approveTaskId, setApproveTask] = useQueryParam('approve', StringParam);

    if (!approveTaskId) {
        return null;
    }

    return <ViewTaskModalWrapper id={approveTaskId} close={close} />;

    function close() {
        setApproveTask(undefined);
    }
}

function ViewTaskModalWrapper({ id, close }) {
    const { t } = useTranslation();
    const task = useGetTask(id);
    const [addManagementDecision] = useAddManagementDecisionMutation();
    const [uploadPrivateFile] = useUploadPrivateFileMutation();
    const organisationId = useActiveOrganisation();
    const dispatch = useDispatch();

    return (
        <>
            <Modal show={true} onHide={close} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Goedkeuren d.m.v. directiebesluit</Modal.Title>
                </Modal.Header>

                {task && (
                    <Formik
                        initialValues={{
                            description: '',
                            task: task['@id'],
                            taskVersion: task.activeVersion ? task.activeVersion['@id'] : '',
                            attachments: [],
                        }}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                        {({ isValid, isSubmitting, dirty }) => {
                            return (
                                <Form>
                                    <Modal.Body>{task && <TaskModalBody task={task} />}</Modal.Body>

                                    <ModalFooter
                                        onHide={close}
                                        isSubmitting={isSubmitting}
                                        dirty={dirty}
                                        isValid={isValid}
                                        btnSubmitText={'Uiting goedkeuren'}
                                    />
                                </Form>
                            );
                        }}
                    </Formik>
                )}
            </Modal>
        </>
    );

    function handleSubmit(formData) {
        uploadFiles(formData.attachments).then((uploadedFiles) => {
            addManagementDecision({
                ...formData,
                files: uploadedFiles.map((upload) => {
                    return {
                        organisationId,
                        task: task['@id'],
                        uri: upload['@id'],
                        fileName: upload.fileName,
                        mime: upload.mime,
                        type: fileType.TYPE_MANAGEMENT_DECISION,
                    };
                }),
            }).then(() => {
                // Invalidate cache
                dispatch(
                    commentsApi.util.invalidateTags([
                        {
                            type: 'Task',
                            id: task['@id'],
                        },
                    ]),
                );

                close();
            });
        });
    }

    function uploadFiles(files) {
        return Promise.all(
            files.map((file) => {
                return new Promise((resolve) => {
                    uploadPrivateFile({
                        file,
                        organisationId,
                    }).then((result) => {
                        resolve(result?.data);
                    });
                });
            }),
        );
    }
}

function TaskModalBody({ task }) {
    const { values, setFieldValue } = useFormikContext();
    const clientName = useGetUserFullName(task.client, false);
    const createdByName = useGetUserFullName(task.createdBy, false);

    return (
        <div>
            <Alert variant="secondary" className="mb-4">
                <strong>Let op!</strong> Is jouw uiting door de directeur van jouw bedrijfsonderdeel na afkeuring in de
                OKÉ-check alsnog goedgekeurd? Zorg dan dat je deze goedkeuring via mail bevestigd krijgt. Download de
                email als .msg bestand en sleep deze in het vak hieronder als bewijs van de goedkeuring.
            </Alert>

            <div className="dr-modal-content-light small px-4">
                <div className="mb-4">
                    <RenderProperty label="Naam">{task.title}</RenderProperty>
                    <RenderProperty label="Eigenaar">{createdByName}</RenderProperty>
                    <RenderProperty label="Opdrachtgever">{clientName}</RenderProperty>
                    <RenderProperty label="Doel van uiting">{task.description}</RenderProperty>
                </div>

                <div className="mb-3">
                    <div className="font-weight-bold mb-2">Bestand(en)</div>
                    <FileUploader onDrop={onDrop} extensions={['msg', 'eml']} />
                </div>

                <FieldArray
                    name="attachments"
                    render={(arrayHelpers) => (
                        <div>
                            {values.attachments && values.attachments.length > 0 && (
                                <>
                                    <div className="font-weight-bold mb-2">Toegevoegde bestanden</div>
                                    {values.attachments.map((file, index) => (
                                        <Attachment
                                            file={file}
                                            arrayHelpers={arrayHelpers}
                                            index={index}
                                            key={`file-item-${index}`}
                                        />
                                    ))}
                                </>
                            )}
                        </div>
                    )}
                />

                <div className="mt-4">
                    <TextArea
                        name="description"
                        props={{
                            placeholder: 'Toelichting...',
                        }}
                    />
                </div>
            </div>
        </div>
    );

    function onDrop(files) {
        setFieldValue('attachments', [...values.attachments].concat(...files));
    }
}

function RenderProperty({ label, children }) {
    return (
        <div className="row mb-3">
            <div className="col-5">
                <div className="dr-label">{label}</div>
            </div>
            <div className="col-7">{children}</div>
        </div>
    );
}

function Attachment({ file, arrayHelpers, index }) {
    return (
        <div className="d-flex align-items-center mb-2">
            <FileEarmarkText size={16} className="mr-1" />
            <span>{file.name}</span>

            <IconButton
                tooltip="Bestand verwijderen"
                className="ml-2"
                icon={<X size={18} className="text-danger mr-0" />}
                onClick={() => arrayHelpers.remove(index)}
            />
        </div>
    );
}

const validationSchema = Yup.object().shape({
    task: Yup.string().required(),
    taskVersion: Yup.string().required(),
    attachments: Yup.array().min(1).required(),
});
