import { ExclamationTriangleFill } from 'react-bootstrap-icons';
import { useParams } from 'react-router-dom';
import { useGetDocument } from '../../hooks/useGetDocument';
import { useFormikContext } from 'formik';
import { actions } from '../../config/views';
import { WarningButton } from '../../../../components/Buttons';
import { LoadingSpinner } from '../../../global/Spinner';
import RestrictedContent from '../../../global/RestrictedContent';
import Constants, { Permissions } from '../../../../config/Constants';
import { AddToOpListBtn } from '../../../operations_list/edit_area/AddToOpList';
import { useTranslation } from 'react-i18next';
import { useIsLoading } from 'pages/documents_v2/views/edit_area/useIsLoading';

export function AreaNav() {
    const { view, areaId } = useParams();
    const document = useGetDocument();
    const { values, dirty, isSubmitting } = useFormikContext();
    const { t } = useTranslation('documents');
    const isLoading = useIsLoading();
    const isLibraryDocument = document.type === Constants.documentType.library;
    const isTemplateDocument = document.type === Constants.documentType.model;
    const isTemplateArticle = values.properties.isTemplate;


    return (
        <>
            <LoadingSpinner isSubmitting={isSubmitting} />

            {values && values.anyBlockHasNoVariants && !isLibraryDocument && !isTemplateDocument && !isTemplateArticle &&
                <>
                    <ExclamationTriangleFill className="text-danger mr-1" />
                    <div className="mr-3 small text-danger">
                        {t('document.navbar.main.editor.navbar.warningNoVariants')}
                    </div>
                </>
            }


            {dirty && !isSubmitting && (
                <>
                    <ExclamationTriangleFill className="text-warning mr-1" />
                    <div className="mr-3 small text-warning">
                        {t('document.navbar.main.editor.navbar.unsavedChanges')}
                    </div>
                </>
            )}

            {document?.activeRevisionId && view === actions.EDIT && (
                <RestrictedContent
                    permission={Permissions.OperationListEntry.Create}
                    module={Constants.modules.operations_list}
                >
                    <AddToOpListBtn document={document} areaId={parseInt(areaId)} />
                </RestrictedContent>
            )}


            {view !== actions.VIEW && (
                <>
                    <WarningButton type="submit" disabled={!dirty || isSubmitting || isLoading}>
                        {t('btn.save')}
                    </WarningButton>

                    <div
                        style={{
                            borderRight: '1px solid #e5e5e5',
                            height: 32,
                            margin: '0 12px 0 18px',
                        }}
                    >
                        &nbsp;
                    </div>
                </>
            )}
        </>
    );
}
