import { ReactTitle } from 'components/ReactTitle';
import { SettingsSidebar } from 'pages/task_team/views/settings/SettingsSidebar';
import { Departments } from 'pages/task_team/views/settings/Departments';
import { useParams } from 'react-router-dom';
import { AddDepartmentModal } from 'pages/task_team/modals/AddDepartmentModal';
import { CustomerJourneys } from 'pages/task_team/views/settings/CustomerJourneys';
import { AddCustomerJourneyModal } from 'pages/task_team/modals/AddCustomerJourneyModal';
import { AddTaskTypeModal } from 'pages/task_team/modals/AddTaskTypeModal';
import { TaskTypes } from 'pages/task_team/views/settings/TaskTypes';
import { Brands } from 'pages/task_team/views/settings/Brands';
import { AddBrandModal } from 'pages/task_team/modals/AddBrandModal';
import { Topics } from 'pages/task_team/views/settings/Topics';
import { AddTopicModal } from 'pages/task_team/modals/AddTopicModal';
import { TargetAudiences } from 'pages/task_team/views/settings/TargetAudiences';
import { AddTargetAudienceModal } from 'pages/task_team/modals/AddTargetAudienceModal';
import { RejectionReasons } from 'pages/task_team/views/settings/RejectionReasons';
import { AddRejectionReasonModal } from 'pages/task_team/modals/AddRejectionReasonModal';
import { Users } from 'pages/task_team/views/settings/Users';

const RenderPage = {
    departments: Departments,
    'customer-journeys': CustomerJourneys,
    'task-types': TaskTypes,
    brands: Brands,
    topics: Topics,
    'target-audiences': TargetAudiences,
    'rejection-reasons': RejectionReasons,
    'users': Users,
};

export function Settings() {
    const { page = 'departments' } = useParams();
    const PageToRender = RenderPage[page] ?? PageNotFound;

    return (
        <>
            <ReactTitle title="Instellingen" />

            <div className="content-sidebar-document overflow-auto bg-light">
                <SettingsSidebar />
            </div>

            <div className="overflow-auto w-100">
                <div className="mt-5 mx-5 d-flex">
                    <div className="w-100">
                        <div className="dr-container p-5 mb-4">
                            <PageToRender />
                        </div>
                    </div>
                </div>
            </div>

            <AddDepartmentModal />
            <AddCustomerJourneyModal />
            <AddTaskTypeModal />
            <AddBrandModal />
            <AddTopicModal />
            <AddTargetAudienceModal />
            <AddRejectionReasonModal />
        </>
    );
}

function PageNotFound() {
    return <div>Page not found</div>;
}
