import { NewItemButton } from 'components/Buttons';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { PaginatedTable, TableProvider } from 'components/PaginatedTable';
import { ArrowDownShort } from 'react-bootstrap-icons';
import { DeleteButton, EditButton } from 'components/ActionButtons';
import HelperFunctions from 'pages/global/HelperFunctions';
import { useDeleteBrandMutation } from 'features/comments/commentApi';
import { useTranslation } from 'react-i18next';
import { entityTypes } from 'pages/translation/config/Constants';
import { useShowSettingsSidebar } from 'components/SettingsSidebar/hooks/useShowSettingsSidebar';
import { useGetBrands } from 'pages/task_team/hooks/useGetBrands';

export function Brands() {
    const brands = useGetBrands();
    const [, setAddBrand] = useQueryParam('addBrand', BooleanParam);

    return (
        <div>
            <div className="subheader">
                <h3>
                    Merken <span className="text-muted ml-1">{brands.length}</span>
                </h3>

                <div className="d-flex align-items-center ml-auto">
                    <NewItemButton size="sm" label="Merk toevoegen" onClick={() => setAddBrand(true)} />
                </div>
            </div>

            <TableProvider>
                <PaginatedTable
                    items={brands}
                    searchPlaceholder="Zoeken..."
                    searchProps={['name']}
                    colSpan={2}
                >
                    {({ items = [] }) => (
                        <>
                            <thead>
                                <tr>
                                    <th>
                                        Naam <ArrowDownShort />
                                    </th>
                                    <th>Acties</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((brand) => (
                                    <Brand brand={brand} key={`brand-${brand.id}`} />
                                ))}
                            </tbody>
                        </>
                    )}
                </PaginatedTable>
            </TableProvider>
        </div>
    );
}

function Brand({ brand }) {
    const { t } = useTranslation();
    const [deleteBrand] = useDeleteBrandMutation();
    const [showSettingsSidebar] = useShowSettingsSidebar();

    return (
        <tr>
            <td>{brand.name}</td>
            <td className="actions">
                <div>
                    <EditButton onClick={toggleSidebar} />
                    <DeleteButton onClick={handleDelete} />
                </div>
            </td>
        </tr>
    );

    function toggleSidebar() {
        showSettingsSidebar(brand, entityTypes.BRAND);
    }

    function handleDelete() {
        HelperFunctions.confirmModal(
            `${brand.name} verwijderen?`,
            'danger',
            false,
            t('global:confirm.yesDelete'),
            t('global:btn.cancel'),
        )
            .then(() => {
                deleteBrand(brand['@id']);
            })
            .catch(() => {});
    }
}
