import cx from 'classnames';
import { useDocumentTemplate } from '../hooks/useDocumentTemplate';

const showDebug = false;

export function PublicationProperty({ property, children }) {
    const isSupported = useIsPropertySupported(property);

    if (!isSupported && !showDebug) {
        return null;
    }

    if (showDebug) {
        return (
            <div
                className={cx('position-relative border px-2 pt-2 mb-3', {
                    'border-danger': !isSupported,
                    'border-success': isSupported,
                })}
            >
                <div className="position-absolute small px-1 bg-white" style={{ top: -10, right: 8 }}>
                    {property}
                </div>

                {children}
            </div>
        );
    }

    return <>{children}</>;
}

export function useIsPropertySupported(property) {
    const documentTemplate = useDocumentTemplate();

    if (!documentTemplate) {
        return false;
    }

    const { supportedProperties = [] } = documentTemplate;

    return supportedProperties.includes(property);
}

export const templateProperties = {
    properties: {
        titleExtra: 'properties.titleExtra',
        variantName: 'properties.variantName',
        description: 'properties.description',
        chaptersDocumentTitle: 'properties.chaptersDocumentTitle',
        footerDocumentTitle: 'properties.footerDocumentTitle',
        versionNumber: 'properties.versionNumber',
        versionDate: 'properties.versionDate',
    },
    layout: {
        multiVersions: 'layout.multiVersions',
        languageIso: 'layout.languageIso',
        logo: 'layout.logo',
        html: 'layout.html',
        htmlMetaFooter: 'layout.htmlMetaFooter',
        coverImage: 'layout.coverImage',
        htmlCollapsible: 'layout.htmlCollapsible',
        pdf: 'layout.pdf',
        pdfCoverImage: 'layout.pdfCoverImage',
        pdfBackCoverImage: 'layout.pdfBackCoverImage',
        pdfProtection: 'layout.pdfProtection',
        printPdf: 'layout.printPdf',
        printPdfCoverImage: 'layout.printPdfCoverImage',
        printPdfBackCoverImage: 'layout.printPdfBackCoverImage',
    },
    section: {
        showTitleInDocument: 'section.showTitleInDocument',
        title: 'section.title',
        showInChapters: 'section.showInChapters',
        showArticlesInChapters: 'section.showArticlesInChapters',
        tableOfContentsDescription: 'section.tableOfContentsDescription',
        tocForceNewPage: 'section.tocForceNewPage',
        translate: 'section.translate',
        capitalizeTags: 'section.capitalizeTags',
        numberingSettings: 'section.numberingSettings',
    },
    toc: {
        section: 'toc.section',
        description: 'toc.description',
        showInChapters: 'toc.showInChapters',
        tableOfContentsDescription: 'toc.tableOfContentsDescription',
    },
    backCover: {
        translate: 'backCover.translate',
    },
    xmlProperties: {
        enableXml: 'xmlProperties.enableXml',
    },
};
