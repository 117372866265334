import getTemplateParameters from '../../../components/SettingsSidebar/views/TemplateConstants';
import _ from 'lodash';
import { useGetTemplate } from './useGetTemplate';
import { findDeep } from 'deepdash-es/standalone';
import { entityTypes } from '../../translation/config/Constants';

export function useEntityTemplateParameters(entity, entityType, document) {
    const modelVersion = useGetTemplate(document?.modelId, document?.modelSource);

    if (entity === undefined || document === undefined) {
        return undefined;
    }

    if (!document || document.modelId === null) {
        return getDefaultPermissions(entityType, entity);
    }

    if (modelVersion) {
        return getPermissionsFromModelVersion(modelVersion, entity, entityType);
    }

    return getPermissionsFromEntity(entity, entityType);
}

function getDefaultPermissions(entityType, entity) {
    const templateParameters =
        entityType === entityTypes.BLOCK
            ? getTemplateParameters(entity.type, entity)
            : getTemplateParameters(entityType, entity);
    const permissions = {};

    Object.keys(templateParameters).forEach((group) => {
        Object.keys(templateParameters[group]).forEach((param) => {
            permissions[param] = templateParameters[group][param];
        });
    });

    return permissions;
}

function getPermissionsFromEntity(entity, entityType) {
    let templateParameters =
        entityType === entityTypes.BLOCK
            ? getTemplateParameters(entity.type, entity)
            : getTemplateParameters(entityType, entity);

    if (entityType === 'area' && entity?.properties?.isTemplate) {
        const templateAreaParameters = getTemplateParameters('templateArea', entity);
        templateParameters = {
            ...templateAreaParameters,
            ...templateParameters,
        };
    }

    const permissions = {};

    Object.keys(templateParameters).forEach((group) => {
        Object.keys(templateParameters[group]).forEach((param) => {
            permissions[param] = _.get(
                entity,
                `properties.templateParameters.${param}`,
                templateParameters[group][param]
            );
        });
    });

    return permissions;
}

export function useGetEntityFromModelVersion(document, entity, entityType) {
    const modelVersion = useGetTemplate(document?.modelId, document?.modelSource);

    if (!modelVersion) {
        return undefined;
    }

    return getEntityFromModelVersion(modelVersion, entity, entityType);
}

function getEntityFromModelVersion(modelVersion, entity, entityType) {
    const entityFromModelVersionResult = findDeep(
        _.isArray(modelVersion.sections) ? modelVersion.sections : Object.values(modelVersion.sections),
        (value) => {
            const modelVersionEntity = value;

            if (
                (entityType === entityTypes.BLOCK &&
                    modelVersionEntity.id === entity.properties.parentId &&
                    modelVersionEntity.type === entity.type) ||
                (modelVersionEntity.id === entity.properties.parentId && modelVersionEntity.type === entityType)
            ) {
                return true;
            }

            return undefined;
        },
        {
            childrenPath: ['areas', 'blocks'],
        }
    );

    if (entityFromModelVersionResult?.value) {
        return entityFromModelVersionResult.value;
    }

    return undefined;
}

function getPermissionsFromModelVersion(modelVersion, entity, entityType) {
    if (entityType === entityTypes.DOCUMENT) {
        return _.defaults({...modelVersion.properties.templateParameters}, {
            maxNumberOfSections: 1,
        });
    }

    const entityFromModelVersion = getEntityFromModelVersion(modelVersion, entity, entityType);

    if (entityFromModelVersion) {
        return getPermissionsFromEntity(entityFromModelVersion, entityType);
    }

    return getDefaultPermissions(entityType, entity);
}
