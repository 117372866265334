import _ from 'lodash';
import Tooltip from '../../../global/Tooltip';
import { PencilFill } from 'react-bootstrap-icons';
import { isEnabledForTranslation } from '../../helpers/EntityReferenceHelper';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

export default function EntityStatus({ entityReference, languageIso, tooltipPlacement }) {
    const { translationStatus } = entityReference;
    const status = _.get(translationStatus, languageIso, {});
    const { unverified = 0, translated = 0, untranslated = 0 } = status;

    return (
        <StatusPencil
            unverified={unverified + untranslated}
            translated={translated}
            tooltipPlacement={tooltipPlacement}
        />
    );
}

export function EntityChildrenStatus({ entityReference, entityChildren = [], languageIso, tooltipPlacement = 'top' }) {
    const { translationStatus } = entityReference;
    const enabledForTranslation = isEnabledForTranslation(entityReference, languageIso);

    let totalTranslated = 0;
    let totalUnverified = 0;
    let totalUntranslated = 0;

    entityChildren.forEach(({ entityReference }) => {
        const childEnabledForTranslation = isEnabledForTranslation(entityReference, languageIso);

        if (childEnabledForTranslation) {
            const { translationStatus } = entityReference;
            const status = _.get(translationStatus, languageIso, {});
            const { unverified = 0, translated = 0, untranslated = 0 } = status;

            totalTranslated = totalTranslated + translated;
            totalUnverified = totalUnverified + unverified;
            totalUntranslated = totalUntranslated + untranslated;
        }
    });

    if (enabledForTranslation) {
        const status = _.get(translationStatus, languageIso, {});
        const { unverified = 0, translated = 0, untranslated = 0 } = status;

        totalTranslated = totalTranslated + translated;
        totalUnverified = totalUnverified + unverified;
        totalUntranslated = totalUntranslated + untranslated;
    }

    return (
        <StatusPencil
            unverified={totalUnverified + totalUntranslated}
            translated={totalTranslated}
            tooltipPlacement={tooltipPlacement}
        />
    );
}

export function StatusPencil({ unverified, translated, tooltipPlacement = 'top' }) {
    const { t } = useTranslation('translations');

    return (
        <Tooltip
            tooltip={t('translation.navbar.dashboard.document.actions.tooltip.numberForApproval')}
            placement={tooltipPlacement}
        >
            <div
                className={cx('d-flex align-items-center small', {
                    'text-danger': unverified > 0,
                    'text-success': unverified === 0,
                })}
                style={{
                    fontSize: 14,
                }}
            >
                <PencilFill size={14} className="mr-2" />
                {translated}
                <span style={{ margin: '0 2px' }}>/</span>
                {translated + unverified}
            </div>
        </Tooltip>
    );
}
