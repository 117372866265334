import rocket from 'images/empty/rocket.svg';

export function EmptyState({ children }) {
    return (
        <div className="empty-state-wrapper text-center py-4">
            <div className="empty-state-wrapper-inner">{children}</div>
        </div>
    );
}

export function EmptyStateHeader({ titleText = '', icon }) {
    return (
        <div className="empty-state-header">
            {icon}
            <div className="empty-state-header-text">{titleText}</div>
        </div>
    );
}

export function EmptyStateIcon({ icon: Icon }) {
    return (
        <div>
            <Icon className="empty-state-icon" />
        </div>
    );
}

export function EmptyStateBody({ children }) {
    return <div className="empty-state-body">{children}</div>;
}

export function EmptyStateFooter({ children }) {
    return <div>{children}</div>;
}

export function EmptyStateActions({ children }) {
    return <div className="empty-state-actions">{children}</div>;
}

export function RocketIcon() {
    return <img src={rocket} className="empty-state-icon empty-state-icon-rocket" alt="" />;
}
