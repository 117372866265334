import { useGetOrganisationQuery } from 'features/security/authApi';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';

export function useCurrentOrganisation() {
    const activeOrganisation = useActiveOrganisation();

    const { organisation } = useGetOrganisationQuery(activeOrganisation, {
        selectFromResult: ({ data }) => ({
            organisation: data,
        }),
    });

    return organisation;
}
