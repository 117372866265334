import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import HelperFunctions from 'pages/global/HelperFunctions';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { useGetTopicsQuery } from 'features/comments/commentApi';

/**
 * @returns {Topic[]}
 */
export function useGetTopics() {
    const organisationId = useActiveOrganisation();

    const selectTopics = useMemo(() => {
        const emptyArray = [];

        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (res) => res.data,
            (res, organisationId) => organisationId,
            (data, organisationId) =>
                data
                    ? data
                          .filter((topic) => topic.organisationId === organisationId)
                          .sort(HelperFunctions.dynamicSort('name'))
                    : emptyArray
        );
    }, []);

    const { topics } = useGetTopicsQuery(undefined, {
        selectFromResult: (result) => ({
            topics: selectTopics(result, organisationId),
        }),
    });

    return topics;
}
