import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';

export function TablePagination({ pageCount, itemOffset = 0, itemsPerPage, onPageChange, itemsLength, colSpan }) {
    const { t } = useTranslation();

    return (
        <tfoot>
            <tr>
                <td colSpan={colSpan}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            {itemsLength > 0 && (
                                <>
                                    {t('global:pagination.numberOfItemsShowing', {
                                        start: itemOffset + 1,
                                        end: Math.min(itemOffset + itemsPerPage, itemsLength),
                                        total: itemsLength,
                                    })}
                                </>
                            )}
                        </div>

                        <ReactPaginate
                            pageCount={pageCount}
                            forcePage={itemOffset > 0 ? itemOffset / itemsPerPage : 0}
                            onPageChange={onPageChange}
                            previousLabel="‹"
                            nextLabel="›"
                            containerClassName="pagination mb-0"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            nextClassName="page-item"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            activeClassName="active"
                        />
                    </div>
                </td>
            </tr>
        </tfoot>
    );
}
