import { ActionLinkButton, LightOutlineButton, PrimaryButton } from 'components/Buttons';
import { ADMIN_SETTING_ACCOUNT_PATH } from 'scenes/Admin';
import { Badge, Dropdown, Table } from 'react-bootstrap';
import { generatePath, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CheckCircleFill, DashCircleFill, QuestionCircleFill, Upload, XCircleFill } from 'react-bootstrap-icons';
import HelperFunctions from '../../../global/HelperFunctions';
import { ResetPasswordButton } from 'pages/admin/views/users/ResetPasswordButton';
import { SendUsernameButton } from 'pages/admin/views/users/SendUsernameButton';
import { Checkbox } from 'pages/publications_v2/helpers/FieldHelper';
import { useState } from 'react';
import _ from 'lodash';
import { useResetAdminUserPasswordMutation, useSendUserAdminUsernameMutation } from 'features/security/authApi';
import Spinner from 'pages/global/Spinner';
import { LinkToDocumentsModal } from 'pages/admin/views/modals/LinkToDocumentsModal';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { ImportUsersModal } from 'pages/admin/views/modals/ImportUsersModal';

export function List({ account }) {
    const { t } = useTranslation('users');
    const history = useHistory();
    const [, setShowLinkToDocuments] = useQueryParam('linkToDocuments', BooleanParam);
    const [, setShowImportModal] = useQueryParam('import', BooleanParam);

    const [selectedUsers, setSelectedUsers] = useState([]);
    const [resetUserIds, setResetUserIds] = useState([]);
    const [resetPasswordUserIds, setResetPasswordUserIds] = useState([]);
    const [bulkInProgress, setBulkInProgress] = useState(false);

    const [sendUserAdminUsername] = useSendUserAdminUsernameMutation();
    const [resetAdminUserPassword] = useResetAdminUserPasswordMutation();

    const sortedUsers = [...(account?.users ?? [])].sort(HelperFunctions.sortByString('fullName'));
    const allSelected = selectedUsers.length === sortedUsers.length;
    const noneSelected = selectedUsers.length === 0;
    const indeterminate = !noneSelected && !allSelected;

    return (
        <div>
            <div className="subheader d-flex align-items-center justify-content-between pb-1 mb-3">
                <div className="d-flex align-items-baseline">
                    <h3 className="mb-0">{account.name}: gebruikers</h3>
                </div>

                <div className="d-flex align-items-center">
                    {renderBulkActionsBtn()}
                    {renderImportBtn()}
                    {renderAddUserBtn()}
                </div>
            </div>

            <Table striped>
                <thead>
                    <tr>
                        <td className="border-top-0">{renderSelectAllCheckbox()}</td>
                        <td className="font-weight-bold border-top-0">{t('columnTitles.name')}</td>
                        <td className="font-weight-bold border-top-0">{t('columnTitles.actions')}</td>
                        <td className="font-weight-bold border-top-0">
                            <span data-uk-tooltip="Single Sign-On">SSO</span>
                        </td>
                        <td className="font-weight-bold border-top-0">
                            <span data-uk-tooltip="Twee-factor-authenticatie">2FA</span>
                        </td>
                        <td className="font-weight-bold border-top-0">{t('columnTitles.status')}</td>
                    </tr>
                </thead>
                <tbody>
                    {sortedUsers.map((user) => (
                        <User
                            user={user}
                            accountId={account.id}
                            isSelected={selectedUsers.includes(user.id)}
                            handleUserSelect={() => handleUserSelect(user.id)}
                            isUsernameReset={resetUserIds.includes(user.id)}
                            isPasswordReset={resetPasswordUserIds.includes(user.id)}
                            key={`user-${user.id}`}
                        />
                    ))}
                </tbody>
            </Table>

            <LinkToDocumentsModal selectedUsers={selectedUsers} account={account} />
            <ImportUsersModal account={account} />
        </div>
    );

    function renderBulkActionsBtn() {
        return (
            <>
                {bulkInProgress && <Spinner />}

                <Dropdown onSelect={handleBulkSelect}>
                    <Dropdown.Toggle
                        variant="primary"
                        disabled={noneSelected || bulkInProgress}
                        size="sm"
                        className="mb-0 mr-2"
                        id="dropdown-bulk"
                    >
                        Acties
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item eventKey="send-username">Verstuur gebruikersnaam&hellip;</Dropdown.Item>
                        <Dropdown.Item eventKey="reset-password">Reset wachtwoord&hellip;</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey="link-to-documents">Koppel aan documenten&hellip;</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </>
        );
    }

    function renderImportBtn() {
        return (
            <LightOutlineButton size="sm" onClick={() => setShowImportModal(true)}>
                <Upload size={12} />
                Importeren&hellip;
            </LightOutlineButton>
        );
    }

    function handleBulkSelect(eventKey, event) {
        event.preventDefault();

        switch (eventKey) {
            case 'send-username':
                confirmSendUsernames();
                break;
            case 'reset-password':
                confirmResetPasswords();
                break;
            case 'link-to-documents':
                setShowLinkToDocuments(true);
                break;
        }
    }

    function confirmSendUsernames() {
        HelperFunctions.confirmModal(
            'Wil je nu de gebruikersnaam van de geselecteerde gebruiker(s) verzenden?',
            undefined,
            false,
            'Ja, verstuur',
        )
            .then(async () => {
                setBulkInProgress(true);
                const resetIds = [];

                for (const userIndex in selectedUsers) {
                    await sendUsername(selectedUsers[userIndex]);
                    resetIds.push(selectedUsers[userIndex]);
                    await sleep(150);
                }

                setBulkInProgress(false);
                setResetUserIds(resetIds);
            })
            .catch(() => {});
    }

    function confirmResetPasswords() {
        HelperFunctions.confirmModal(
            'Wil je nu de wachtwoorden van de geselecteerde gebruiker(s) resetten en nieuwe via email versturen?',
            undefined,
            false,
            'Ja, reset',
        )
            .then(async () => {
                setBulkInProgress(true);
                const resetPasswordIds = [];

                for (const userIndex in selectedUsers) {
                    await resetPassword(selectedUsers[userIndex]);
                    resetPasswordIds.push(selectedUsers[userIndex]);
                    await sleep(150);
                }

                setBulkInProgress(false);
                setResetPasswordUserIds(resetPasswordIds);
            })
            .catch(() => {});
    }

    function sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    function sendUsername(userId) {
        return new Promise((resolve) => {
            sendUserAdminUsername({ accountId: account.id, id: userId }).then(({ data }) => {
                if (data?.status === 'ok') {
                    resolve('ok');
                }
            });
        });
    }

    function resetPassword(userId) {
        return new Promise((resolve) => {
            resetAdminUserPassword({ accountId: account.id, id: userId }).then(({ data }) => {
                if (data?.status === 'ok') {
                    resolve('ok');
                }
            });
        });
    }

    function renderAddUserBtn() {
        return (
            <PrimaryButton
                variant="info"
                size="sm"
                className="mb-0"
                onClick={() => {
                    history.push(
                        generatePath(ADMIN_SETTING_ACCOUNT_PATH, {
                            id: account.id,
                            view: 'users',
                            action: 'create',
                        }),
                    );
                }}
            >
                Nieuwe gebruiker
            </PrimaryButton>
        );
    }

    function renderSelectAllCheckbox() {
        return (
            <div className="custom-control custom-checkbox">
                <input
                    className="custom-control-input"
                    id="bulk-check-users"
                    type="checkbox"
                    disabled={sortedUsers.length === 0}
                    checked={allSelected}
                    onChange={() => {
                        if (noneSelected || indeterminate) {
                            setSelectedUsers(sortedUsers.map((user) => user.id));
                        } else {
                            setSelectedUsers([]);
                        }
                    }}
                    ref={(el) => el && (el.indeterminate = indeterminate)}
                />
                <label className="custom-control-label" htmlFor="bulk-check-users" />
            </div>
        );
    }

    function handleUserSelect(userId) {
        setSelectedUsers(_.xor(selectedUsers, [userId]));
    }
}

function User({
    user,
    accountId,
    isSelected = false,
    handleUserSelect,
    isUsernameReset = false,
    isPasswordReset = false,
}) {
    const { t } = useTranslation('users');
    const history = useHistory();

    return (
        <tr>
            <td className="align-middle">
                <Checkbox
                    id={`user-check-${user.id}`}
                    name={`user-check-${user.id}`}
                    checked={isSelected}
                    onChange={handleUserSelect}
                />
            </td>
            <td className="align-middle">
                <ActionLinkButton
                    onClick={() => {
                        history.push(
                            generatePath(ADMIN_SETTING_ACCOUNT_PATH, {
                                id: accountId,
                                view: 'users',
                                action: 'edit',
                                entityId: user.id,
                            }),
                        );
                    }}
                    className="font-weight-bolder"
                >
                    {user.fullName}
                </ActionLinkButton>
            </td>
            <td className="align-middle">
                <SendUsernameButton
                    user={user}
                    accountId={accountId}
                    isUsernameReset={isUsernameReset}
                    className="mr-2"
                />
                <ResetPasswordButton user={user} accountId={accountId} isPasswordReset={isPasswordReset} />
            </td>
            <td className="align-middle">
                {user.ssoEnabled ? (
                    <Badge variant="success" className="d-inline-flex align-items-center">
                        <CheckCircleFill className="flex-shrink-0 mr-1" />
                        Ja
                    </Badge>
                ) : (
                    <Badge variant="danger" className="d-inline-flex align-items-center">
                        <XCircleFill className="flex-shrink-0 mr-1" />
                        Nee
                    </Badge>
                )}
            </td>
            <td className="align-middle">
                {user.ssoEnabled ? (
                    <Badge variant="light" className="d-inline-flex align-items-center">
                        <DashCircleFill className="flex-shrink-0 mr-1" />
                        n.v.t.
                    </Badge>
                ) : (
                    <>
                        {user.twoFactorEnabled ? (
                            <Badge variant="success" className="d-inline-flex align-items-center">
                                <CheckCircleFill className="flex-shrink-0 mr-1" />
                                Ja
                            </Badge>
                        ) : (
                            <Badge variant="danger" className="d-inline-flex align-items-center">
                                <XCircleFill className="flex-shrink-0 mr-1" />
                                Nee
                            </Badge>
                        )}
                    </>
                )}
            </td>
            <td className="align-middle">
                {user.enabled ? (
                    <Badge variant="success" className="d-inline-flex align-items-center">
                        <CheckCircleFill className="flex-shrink-0 mr-1" />
                        {t('status.active')}
                    </Badge>
                ) : (
                    <Badge variant="warning" className="d-inline-flex align-items-center">
                        <QuestionCircleFill className="flex-shrink-0 mr-1" />
                        {t('status.inactive')}
                    </Badge>
                )}
            </td>
        </tr>
    );
}
