import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { ReactTitle } from 'components/ReactTitle';
import Index from '../pages/tags/Index';
import { useTranslation } from 'react-i18next';

export const BASE_PATH = '/tags';

const Tags = () => {
    let { path } = useRouteMatch();
    const { t } = useTranslation('global');

    return (
        <>
            <ReactTitle title={t('mainSidebar.globalTags')} />

            <Switch>
                <Route path={`${path}`} exact strict render={() => <Index />} />
                <Route path={`${path}/`} exact strict render={() => <Redirect to="/tags" />} />
                <Route path={`${path}/update`} exact strict render={() => <Redirect to="/tags" />} />
                <Route path={`${path}/update/`} exact strict render={() => <Redirect to="/tags" />} />
            </Switch>
        </>
    );
};

export default Tags;
