import { Modal } from 'react-bootstrap';
import { Form as FForm, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { FormModal, ModalFooter } from '../../../global/FormModal';
import {
    useAddCollectiveGroupMutation,
    useUpdateCollectiveGroupMutation,
} from '../../../../features/reimbursements/reimbursements';
import { InputField } from '../../../publications_v2/helpers/FieldHelper';
import { useTranslation } from 'react-i18next';

export default function EditCollectiveGroupModal({ formData, handleClose }) {
    const dispatch = useDispatch();
    const [addCollectiveGroup] = useAddCollectiveGroupMutation();
    const [updateCollectiveGroup] = useUpdateCollectiveGroupMutation();
    const { t } = useTranslation('reimbursements');

    const handleSubmit = (values, { setSubmitting }) => {
        if (formData.hasOwnProperty('id')) {
            dispatch(
                updateCollectiveGroup({
                    uri: formData['@id'],
                    ...values,
                }).then(() => {
                    setSubmitting(false);
                    handleClose();
                })
            );
        } else {
            dispatch(
                addCollectiveGroup({
                    ...values,
                }).then(() => {
                    setSubmitting(false);
                    handleClose();
                })
            );
        }
    };

    return (
        <FormModal
            show={true}
            onHide={handleClose}
            title={
                formData.hasOwnProperty('id')
                    ? t('view.editCollectiveGroup.editFolder')
                    : t('view.editCollectiveGroup.addNewFolder')
            }
        >
            <Formik initialValues={formData} validationSchema={NewFolderSchema} onSubmit={handleSubmit}>
                {({ isSubmitting, dirty }) => (
                    <FForm autoComplete="off">
                        <Modal.Body>
                            <InputField
                                name="name"
                                label={t('view.editCollectiveGroup.name')}
                                props={{
                                    placeholder: t('view.editCollectiveGroup.folderName'),
                                }}
                            />
                        </Modal.Body>

                        <ModalFooter dirty={dirty} isSubmitting={isSubmitting} onHide={handleClose} />
                    </FForm>
                )}
            </Formik>
        </FormModal>
    );
}

const NewFolderSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Required'),
});
