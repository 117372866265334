import { isRejectedWithValue } from '@reduxjs/toolkit';
import { showError } from './helpers/errorHelper';
import _ from 'lodash';

export const errorLogger = (api) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        if (action.payload.status === 400 || action.payload.status === 401) {
            return;
        }

        const message = _.get(action.payload, 'data.error.exception.0.message', '');

        if (message !== 'AreaLockedByUser') {
            showError(
                'Fout bij het ophalen van data. Neem contact op met de helpdesk indien het probleem zich blijft voordoen.'
            );
        }
    }

    return next(action);
};
