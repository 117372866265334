import { FormField, InputField, InputSelect, Switch } from 'pages/publications_v2/helpers/FieldHelper';
import { FieldArray, FormikContext } from 'formik';
import React, { useCallback, useContext } from 'react';
import { ArrowDown, ArrowUp, PlusCircle, Trash } from 'react-bootstrap-icons';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useGetProductsQuery } from 'features/reimbursements/reimbursements';
import ProductHelper from '../../../../global/ProductHelper';
import HelperFunctions from '../../../../global/HelperFunctions';
import _ from 'lodash';
import { filterDeep, mapValuesDeep } from 'deepdash-es/standalone';
import { createFilter } from 'react-select';
import { useGetCategory } from 'features/reimbursements/reimbursementHooks';
import { useTranslation } from 'react-i18next';
import { LightOutlineButton } from 'components/Buttons';

export default function Products({ categoryId }) {
    const { category } = useGetCategory(categoryId);
    const { t } = useTranslation('reimbursements');

    if (category === undefined) {
        return null;
    }

    return (
        <>
            <div className="subheader">
                <h3>{t('proposition.editProposition.policies.title')}</h3>
            </div>

            <Row>
                <Col xs={8}>
                    <FormField label={t('proposition.editProposition.policies.policyTitle')} name="name" />

                    <Switch name="addPrimaryAndSecondary" label={t('proposition.editProposition.policies.addBase')} />

                    <Switch name="showAsSeparate" label={t('proposition.editProposition.policies.showBasic')} />

                    <Switch name="hideFirstColumn" label={t('proposition.editProposition.policies.hidePolicy')} />

                    <SecondaryName />
                </Col>
            </Row>

            <FieldArray name="variantPackageProducts">
                {({ remove, push, move }) => (
                    <VariantPackageProducts remove={remove} push={push} move={move} categoryId={categoryId} />
                )}
            </FieldArray>
        </>
    );
}

function VariantPackageProducts({ remove, push, move, categoryId }) {
    const { values } = useContext(FormikContext);
    const { t } = useTranslation('reimbursements');

    const variantPackageProducts = values?.variantPackageProducts ?? [];

    const removeCallback = useCallback((index) => {
        remove(index);
    }, []);

    const moveUpCallback = useCallback((index) => {
        move(index, index - 1);
    }, []);

    const moveDownCallback = useCallback((index) => {
        move(index, index + 1);
    }, []);

    return (
        <>
            <div className="mb-4">
                <LightOutlineButton
                    size="sm"
                    onClick={() => {
                        push({
                            primaryProductCode: '',
                            primaryProductCustomName: '',
                            secondaryProductCode: '',
                            secondaryProductCustomName: '',
                            primaryDescription: '',
                            secondaryDescription: '',
                        });
                    }}
                >
                    <PlusCircle size={14} />
                    <div>{t('proposition.editProposition.policies.addRow')}</div>
                </LightOutlineButton>

                <Form.Row className="text-muted small text-uppercase pt-4 mb-2">
                    <Col xs={2}>{t('proposition.editProposition.policies.policyBase')}</Col>
                    <Col xs={2}>{t('proposition.editProposition.policies.customName')}</Col>
                    <Col xs={2}>{t('proposition.editProposition.policies.description')}</Col>
                    <Col xs={2}>{t('proposition.editProposition.policies.addition')}</Col>
                    <Col xs={2}>{t('proposition.editProposition.policies.customName')}</Col>
                    <Col xs={1}>{t('proposition.editProposition.policies.description')}</Col>
                </Form.Row>

                {variantPackageProducts.map((_item, index) => (
                    <PropositionProductMemo
                        product={_item}
                        remove={removeCallback}
                        index={index}
                        moveUp={moveUpCallback}
                        moveDown={moveDownCallback}
                        isLast={index >= variantPackageProducts.length - 1}
                        categoryId={categoryId}
                        key={`product-${index}`}
                    />
                ))}
            </div>
        </>
    );
}

function PropositionProduct({ product, remove, index, moveDown, moveUp, isLast, categoryId }) {
    const { t } = useTranslation('reimbursements');

    const { products, isUninitialized } = useGetProductsQuery(categoryId, {
        selectFromResult: ({ data, isUninitialized }) => ({
            products: data ?? [],
            isUninitialized,
        }),
    });

    if (isUninitialized || products.length === 0) {
        return null;
    }

    return (
        <Form.Row className="mb-2">
            <PrimaryProductMemo categoryId={categoryId} index={index} primaryProductCode={product.primaryProductCode} />

            <SecondaryProductMemo
                index={index}
                primaryProductCode={product.primaryProductCode}
                secondaryProductCode={product.secondaryProductCode}
                categoryId={categoryId}
            />

            <Col xs={1}>
                <div className="d-flex align-items-center h-100">
                    <Button
                        variant="icon"
                        className="btn-icon-only text-secondary p-1"
                        disabled={index === 0}
                        data-uk-tooltip={index > 0 ? t('proposition.editProposition.policies.btn.moveUp') : undefined}
                        onClick={() => moveUp(index)}
                    >
                        <ArrowUp size={14} />
                    </Button>

                    <Button
                        variant="icon"
                        className="btn-icon-only text-secondary p-1"
                        disabled={isLast}
                        data-uk-tooltip={isLast ? undefined : t('proposition.editProposition.policies.btn.moveDown')}
                        onClick={() => moveDown(index)}
                    >
                        <ArrowDown size={14} />
                    </Button>

                    <Button
                        variant="icon"
                        className="btn-icon-only text-danger p-1 ml-1"
                        data-uk-tooltip={t('proposition.editProposition.policies.btn.deleteRow')}
                        onClick={() => remove(index)}
                    >
                        <Trash size={14} />
                    </Button>
                </div>
            </Col>
        </Form.Row>
    );
}

function PrimaryProduct({ index, categoryId, primaryProductCode }) {
    const { category } = useGetCategory(categoryId);
    const { t } = useTranslation('reimbursements');

    const { products } = useGetProductsQuery(categoryId, {
        selectFromResult: ({ data }) => ({
            products: data ? [...data].sort(HelperFunctions.dynamicSort('sortOrder')) : [],
        }),
    });

    const allProducts = ProductHelper.productsAsList(
        products.filter((_item) => _item.productGroup === true || _item.parent === null)
    );

    return (
        <>
            <Col xs={2}>
                <InputSelect
                    name={`variantPackageProducts.${index}.primaryProductCode`}
                    options={allProducts}
                    size="sm"
                    props={{
                        placeholder: `${t('proposition.editProposition.policies.policyType')}...`,
                        filterOption: createFilter({
                            stringify: ({ data }) => data.codeAndName,
                        }),
                    }}
                />
            </Col>
            <Col xs={2}>
                <InputField
                    name={`variantPackageProducts.${index}.primaryProductCustomName`}
                    props={{
                        disabled: primaryProductCode === '',
                        placeholder: t('proposition.editProposition.policies.customName'),
                    }}
                />
            </Col>
            <Col xs={2}>
                <InputSelect
                    name={`variantPackageProducts.${index}.primaryDescription`}
                    options={HelperFunctions.prepareDropdownData(category.reimbursementProductFields, 'name', 'slug')}
                    size="sm"
                    props={{
                        placeholder: t('proposition.editProposition.policies.description'),
                    }}
                />
            </Col>
        </>
    );
}

function SecondaryProduct({ index, primaryProductCode, secondaryProductCode, categoryId }) {
    const { category } = useGetCategory(categoryId);
    const { t } = useTranslation('reimbursements');

    const { products } = useGetProductsQuery(categoryId, {
        selectFromResult: ({ data }) => ({
            products: data ? [...data].sort(HelperFunctions.dynamicSort('sortOrder')) : [],
        }),
    });

    const primaryProduct = _.isEmpty(primaryProductCode)
        ? {}
        : HelperFunctions.getByValue(products, 'code', primaryProductCode) ?? {};
    const { productLinks = [] } = primaryProduct;

    const availableSecondaryProducts = productLinks
        .filter((productLink) => {
            const secondaryProduct = HelperFunctions.getByValue(products, 'id', productLink.extendedProductId);

            return secondaryProduct && secondaryProduct.enabled;
        })
        .map((productLink) => HelperFunctions.getByValue(products, 'id', productLink.extendedProductId));
    const hasSecondaryProducts = availableSecondaryProducts.length > 0;
    const secondaryProductsAsList = hasSecondaryProducts
        ? ProductHelper.secondaryProductsAsList(
              products.filter((_item) => _item.productGroup === true || _item.parent === null),
              false
          )
        : [];

    const secondaryProducts =
        secondaryProductsAsList.length > 0
            ? filterDeep(
                  secondaryProductsAsList,
                  (value, key, parent) => {
                      const product = value;

                      if (_.isArray(product.options) && product.options.length > 0) {
                          return undefined;
                      }

                      return availableSecondaryProducts.find((_item) => _item.code === product.value) !== undefined;
                  },
                  {
                      childrenPath: 'options',
                  }
              )
            : [];

    return (
        <>
            <Col xs={2}>
                <InputSelect
                    name={`variantPackageProducts.${index}.secondaryProductCode`}
                    options={
                        secondaryProducts.length > 0
                            ? mapValuesDeep(
                                  secondaryProducts,
                                  (value) => {
                                      if (value.productGroup) {
                                          return value;
                                      }

                                      return {
                                          ...value,
                                          label: (
                                              <>
                                                  <span className="text-secondary mr-1">{value.value}</span>
                                                  <span>{value.label}</span>
                                              </>
                                          ),
                                      };
                                  },
                                  {
                                      childrenPath: 'options',
                                  }
                              )
                            : []
                    }
                    size="sm"
                    props={{
                        isDisabled: primaryProductCode === '' || !hasSecondaryProducts,
                        isClearable: true,
                        placeholder: `${t('proposition.editProposition.policies.policyType')}...`,
                        filterOption: createFilter({
                            stringify: ({ data }) => data.codeAndName,
                        }),
                    }}
                />
            </Col>
            <Col xs={2}>
                <InputField
                    name={`variantPackageProducts.${index}.secondaryProductCustomName`}
                    props={{
                        disabled: secondaryProductCode === '' || !hasSecondaryProducts,
                        placeholder: t('proposition.editProposition.policies.customName'),
                    }}
                />
            </Col>
            <Col xs={1}>
                <InputSelect
                    name={`variantPackageProducts.${index}.secondaryDescription`}
                    options={
                        secondaryProductCode === '' || !hasSecondaryProducts
                            ? []
                            : HelperFunctions.prepareDropdownData(category.reimbursementProductFields, 'name', 'slug')
                    }
                    size="sm"
                    props={{
                        isDisabled: secondaryProductCode === '' || !hasSecondaryProducts,
                        isClearable: true,
                        placeholder: t('proposition.editProposition.policies.description'),
                    }}
                />
            </Col>
        </>
    );
}

function SecondaryName() {
    const { values } = useContext(FormikContext);
    const showAsSeparate = values?.showAsSeparate ?? false;

    return <SecondaryNameFieldMemo disabled={!showAsSeparate} />;
}

function SecondaryNameField({ disabled = false }) {
    const { t } = useTranslation('reimbursements');

    return (
        <FormField
            label={t('proposition.editProposition.policies.titlePolicySupplement')}
            name="secondaryName"
            props={{
                disabled,
            }}
        />
    );
}

const PropositionProductMemo = React.memo(PropositionProduct);
const SecondaryProductMemo = React.memo(SecondaryProduct);
const PrimaryProductMemo = React.memo(PrimaryProduct);
const SecondaryNameFieldMemo = React.memo(SecondaryNameField);
