import { generatePath, useParams } from 'react-router-dom';
import MainContentNav from '../Navbar';
import { BASE_PATH, VIEW_PATH } from '../../scenes/Reimbursements';
import LoadingSpinner from '../global/LoadingSpinner';
import Sidebar from './views/settings/Sidebar';
import Properties from './views/settings/Properties';
import Constants from '../../config/Constants';
import RestrictedReimbursementContent from './RestrictedReimbursementContent';
import Fields from './views/settings/Fields';
import SubNav from './views/SubNav';
import { ReviewTeam } from './views/settings/ReviewTeam';
import { useGetCategory } from '../../features/reimbursements/reimbursementHooks';
import { useTranslation } from 'react-i18next';

export default function Settings() {
    const { categoryId } = useParams();
    const { category } = useGetCategory(parseInt(categoryId));
    const { t } = useTranslation('reimbursements');

    if (category === undefined) {
        return <LoadingSpinner />;
    }

    return (
        <>
            <MainContentNav
                pages={[
                    { title: t('breadcrumb'), url: BASE_PATH },
                    {
                        title: category?.name,
                        url: generatePath(VIEW_PATH, { categoryId }),
                    },
                    { title: t('navbar.titles.settings') },
                ]}
            />

            <SubNav />

            <div className="content-static-body has-subnav">
                <div className="d-flex align-items-stretch h-100">
                    <div className="content-sidebar overflow-auto bg-light">
                        <Sidebar />
                    </div>
                    <div className="flex-grow-1 overflow-auto">
                        <div id="content" className="px-5 pt-5 pb-3">
                            <RestrictedReimbursementContent
                                categoryId={category.id}
                                roles={[Constants.reimbursementTeamRoles.admin]}
                            >
                                <Setting />
                            </RestrictedReimbursementContent>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function Setting() {
    const { view } = useParams();

    switch (view) {
        case 'properties':
            return <Properties />;
        case 'team':
            return <ReviewTeam />;
        case 'fields':
            return <Fields />;
    }

    return <div>Setting '{view}' is not supported.</div>;
}
