import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { PrimaryButton, WarningButton } from 'components/Buttons';
import getEnv from 'config/Env';
import { useUpdateUserSeenBannersMutation } from 'features/security/authApi';
import { useUserProfile } from 'hooks/useUserProfile';
import { useGetBanners } from 'hooks/useGetBanners';
import { useTranslation } from 'react-i18next';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import cx from 'classnames';
import { useLocation } from 'react-router-dom';
import { BASE_PATH as DOCUMENTS_BASE_PATH } from 'scenes/DocumentsV2';
import { ALL_CHECKS_PATH } from 'scenes/TaskTeam';

const LOCATIONS_TO_RENDER = [DOCUMENTS_BASE_PATH, ALL_CHECKS_PATH];

export function BannerPopup() {
    const { pathname } = useLocation();

    if (LOCATIONS_TO_RENDER.includes(pathname)) {
        return <RenderBannerPopup />;
    }

    return null;
}

function RenderBannerPopup() {
    const [show, setShow] = useState(true);
    const banners = useGetBanners();

    if (!banners || banners.length === 0) {
        return null;
    }

    return (
        <Modal show={show} onHide={handleClose} size="lg" centered id="modal-banners">
            <Modal.Body>
                <Banner banners={banners} handleClose={handleClose} />
            </Modal.Body>
        </Modal>
    );

    function handleClose() {
        setShow(false);
    }
}

function Banner({ banners = [], handleClose }) {
    const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
    const { t } = useTranslation('documents');
    const activeOrganisation = useActiveOrganisation();
    const userProfile = useUserProfile();
    const [updateUserSeenBanners] = useUpdateUserSeenBannersMutation();

    const seenBanners = banners.map((bannerFile) => bannerFile.bannerId) ?? [];
    const currentBanner = banners[currentBannerIndex];

    useEffect(() => {
        if (banners.length <= 1) {
            return;
        }

        const interval = setInterval(() => {
            setCurrentBannerIndex((prevIndex) => (prevIndex + 1) % banners.length);
        }, 7000);

        return () => {
            clearInterval(interval);
        };
    }, [banners]);

    useEffect(() => {
        return () => {
            updateUserSeenBanners({
                userId: userProfile.userId,
                organisationId: activeOrganisation,
                body: seenBanners,
            });
        };
    }, [banners]);

    if (!currentBanner) {
        return null;
    }

    return (
        <div className="popup-container">
            <div className="banner-container">
                <BannerImageFile file={currentBanner.file} />

                {banners.length > 1 && (
                    <ul className="slick-dots">
                        {banners.map((_, index) => (
                            <li
                                key={index}
                                className={cx('dot', {
                                    active: index === currentBannerIndex,
                                })}
                                onClick={() => setCurrentBannerIndex(index)}
                            />
                        ))}
                    </ul>
                )}
            </div>
            <div className="flex-center button-container">
                <div className="small mr-3">
                    {currentBannerIndex + 1}/{banners.length}
                </div>
                <PrimaryButton size="sm" onClick={handleClose}>
                    {t('documents.banners.skip')}
                </PrimaryButton>
                {currentBanner.url && (
                    <WarningButton size="sm" className="ml-2" onClick={handleReadMore}>
                        {t('documents.banners.readMore')}
                    </WarningButton>
                )}
            </div>
        </div>
    );

    function handleReadMore() {
        window.open(currentBanner.url, currentBanner.target);
    }
}

function BannerImageFile({ file }) {
    const bannerImageUrl = getEnv('BANNERS_URL');
    const imageUrl = bannerImageUrl + file;

    return <img src={imageUrl} className="banner-image" alt="Banner" />;
}
