import { Alert } from 'react-bootstrap';
import Constants from '../../../config/Constants';
import { TextBlock } from '../blocks/TextBlock';
import { TableBlock } from '../blocks/TableBlock';
import { ReimbursementBlock } from '../blocks/ReimbursementBlock';
import { LinkedBlock } from '../blocks/LinkedBlock';
import { LabelBlock } from '../blocks/LabelBlock';
import { useGetDocument } from '../../documents_v2/hooks/useGetDocument';
import { Skeleton } from 'components/Skeleton';

function OpEntryBlock({ opEntryBlock, showRendered, documentId, isBaseVariant }) {
    const document = useGetDocument(documentId, true);

    if (!document) {
        return (
            <Skeleton count={3} />
        );
    }

    switch (opEntryBlock.type) {
        case Constants.blockTypes.text:
            return (
                <TextBlock
                    isBaseVariant={isBaseVariant}
                    opEntryBlock={opEntryBlock}
                    showRendered={showRendered}
                    document={document}
                />
            );
        case Constants.blockTypes.table:
            // Document doesnt need to be given to tableBlock,
            // underlying logic seems to have it's own way to render cross-references.
            return <TableBlock isBaseVariant={isBaseVariant} opEntryBlock={opEntryBlock} showChanges={showRendered} />;
        case Constants.blockTypes.reimbursement:
            return (
                <ReimbursementBlock
                    isBaseVariant={isBaseVariant}
                    opEntryBlock={opEntryBlock}
                    showChanges={showRendered}
                    document={document}
                />
            );
        case Constants.blockTypes.linked:
            return (
                <LinkedBlock
                    isBaseVariant={isBaseVariant}
                    opEntryBlock={opEntryBlock}
                    showChanges={showRendered}
                    document={document}
                />
            );
        case Constants.blockTypes.label:
            return <LabelBlock opEntryBlock={opEntryBlock} showChanges={showRendered} />;
    }

    return <Alert variant="danger">Blok type {opEntryBlock.type} wordt niet ondersteund</Alert>;
}

export default OpEntryBlock;
