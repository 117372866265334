import { PrimaryButton } from 'components/Buttons';
import HelperFunctions from 'pages/global/HelperFunctions';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useSendUserAdminUsernameMutation } from 'features/security/authApi';

export function SendUsernameButton({ user, accountId, className, isUsernameReset = false }) {
    const { t: tUsers } = useTranslation('users');
    const [sendUsernameSuccess, setUsernameSendSuccess] = useState(false);
    const [sendUserAdminUsername] = useSendUserAdminUsernameMutation();

    return (
        <PrimaryButton
            size="sm"
            onClick={confirmSendUsername}
            disabled={sendUsernameSuccess === true || user.enabled === false || isUsernameReset}
            className={className}
            tooltip="Verstuur gebruikersnaam via email"
        >
            {tUsers('btn.sendUsername')}
        </PrimaryButton>
    );

    function confirmSendUsername() {
        HelperFunctions.confirmModal(
            tUsers('editUser.sendUsernameConfirm', {
                name: user.fullName,
            }),
            undefined,
            false,
            tUsers('editUser.sendUsernameConfirmLabel')
        )
            .then(() => {
                sendUsername();
            })
            .catch(() => {});
    }

    function sendUsername() {
        sendUserAdminUsername({ accountId, id: user.id }).then(({ data }) => {
            if (data?.status === 'ok') {
                setUsernameSendSuccess(true);
            }
        });
    }
}
