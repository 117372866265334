import { Nav } from 'react-bootstrap';
import { generatePath, Link, useParams } from 'react-router-dom';
import cx from 'classnames';
import { ADMIN_SETTING_ACCOUNT_PATH, ADMIN_SETTINGS_PATH } from '../../../../scenes/Admin';
import { ChevronLeft } from 'react-bootstrap-icons';

export function Sidebar() {
    const { id, view = 'item' } = useParams();

    return (
        <div className="content-sidebar-document overflow-auto bg-light">
            <div className="py-4 bg-lightBlueLight">
                <div className="d-flex align-items-center justify-content-between mb-2 px-4">
                    <div className="text-color font-weight-bold">Instellingen</div>

                    <Link
                        className="d-flex align-items-center text-muted small"
                        to={generatePath(ADMIN_SETTINGS_PATH, {
                            view: 'accounts',
                        })}
                        data-uk-tooltip="Terug naar overzicht"
                    >
                        <ChevronLeft />
                        <div>terug</div>
                    </Link>
                </div>

                <Nav className="sidebar-nav flex-column">
                    <Link
                        to={generatePath(ADMIN_SETTING_ACCOUNT_PATH, {
                            id,
                        })}
                        className={cx('nav-link font-weight-normal', {
                            active: view === 'item',
                        })}
                        style={{ fontSize: '12px' }}
                    >
                        Account
                    </Link>
                    <Link
                        to={generatePath(ADMIN_SETTING_ACCOUNT_PATH, {
                            id,
                            view: 'users',
                        })}
                        className={cx('nav-link font-weight-normal', {
                            active: view === 'users',
                        })}
                        style={{ fontSize: '12px' }}
                    >
                        Gebruikers
                    </Link>
                    <Link
                        to={generatePath(ADMIN_SETTING_ACCOUNT_PATH, {
                            id,
                            view: 'organisations',
                        })}
                        className={cx('nav-link font-weight-normal', {
                            active: view === 'organisations',
                        })}
                        style={{ fontSize: '12px' }}
                    >
                        Organisaties
                    </Link>
                    <Link
                        to={generatePath(ADMIN_SETTING_ACCOUNT_PATH, {
                            id,
                            view: 'import',
                        })}
                        className={cx('nav-link font-weight-normal', {
                            active: view === 'import',
                        })}
                        style={{ fontSize: '12px' }}
                    >
                        Importeren
                    </Link>
                </Nav>
            </div>
        </div>
    );
}
