import { UserForm } from './UserForm';

export function Create({ account }) {
    return (
        <div>
            <div className="subheader d-flex align-items-center justify-content-between pb-1 mb-3">
                <div className="d-flex align-items-baseline">
                    <h3 className="mb-0">{account.name}: nieuwe gebruiker</h3>
                </div>
            </div>

            <UserForm account={account} />
        </div>
    );
}
