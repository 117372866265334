import { History, ReimbursementProduct } from '../../../../models/reimbursement.models';
import { useFormikContext } from 'formik';
import React, { memo, useCallback, useContext } from 'react';
import { useGetHistoryQuery } from '../../../../features/reimbursements/reimbursements';
import { Dropdown } from 'react-bootstrap';
import { DateTime } from 'luxon';
import { HistoryContext } from './ReimbursementProduct';
import { ChevronRight } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useGetUserFullName } from '../../../../hooks/useGetUserFullName';

export function HistorySelector() {
    const { values }: { values: Partial<ReimbursementProduct>; isSubmitting: boolean } = useFormikContext();
    const reimbursementProductId = values.id;

    if (reimbursementProductId === undefined) {
        return null;
    }

    return <HistorySelectorInnerMemo reimbursementProductId={reimbursementProductId} />;
}

function HistorySelectorInner({ reimbursementProductId }: { reimbursementProductId: number }) {
    const { setStartGroupId, endGroupId, setEndGroupId } = useContext(HistoryContext);
    const activeVersionIsMostRecent = endGroupId === 0;
    const { t } = useTranslation('reimbursements');

    const { history }: { history: History[] } = useGetHistoryQuery(
        [['ReimbursementProduct[]', reimbursementProductId]],
        {
            selectFromResult: ({ data }) => ({
                history: data ?? [],
            }),
        }
    );

    const switchVersionHistory = useCallback((startId: number, endId: number) => {
        setStartGroupId(startId);
        setEndGroupId(endId);
    }, []);

    const activeVersionPeriod = () => {
        if (!activeVersionIsMostRecent) {
            const activeVersion = history.find((item) => item.endGroupId === endGroupId);

            if (activeVersion) {
                return versionPeriodAsString(activeVersion);
            }
        }

        return t('product.historySelector.currentVersion');
    };

    return (
        <>
            <ChevronRight className="text-secondary mx-2" size={12} />
            <Dropdown className="history-dropdown">
                <Dropdown.Toggle as="div" className="history-toggle">
                    {activeVersionPeriod()}{' '}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item active={activeVersionIsMostRecent} onSelect={() => switchVersionHistory(0, 0)}>
                        {t('product.historySelector.currentVersion')}
                    </Dropdown.Item>

                    <Dropdown.Divider />

                    {history.map((item: History, index: number) => (
                        <HistoryItemMemo
                            isActive={item.endGroupId === endGroupId}
                            history={item}
                            switchVersionHistory={switchVersionHistory}
                            key={`history-item-${index}`}
                        />
                    ))}
                </Dropdown.Menu>
            </Dropdown>
            {!activeVersionIsMostRecent && (
                <HistoryInfo history={history.find((item) => item.endGroupId === endGroupId)} />
            )}
        </>
    );
}

function HistoryItem({
    isActive,
    history,
    switchVersionHistory,
}: {
    isActive: boolean;
    history: History;
    switchVersionHistory: (startId: number, endId: number) => void;
}) {
    const creator: string = useGetUserFullName(history.createdBy, false);
    const { t } = useTranslation('reimbursements');

    return (
        <Dropdown.Item
            active={isActive}
            onSelect={() => switchVersionHistory(history.startGroupId, history.endGroupId)}
        >
            <div>{versionPeriodAsString(history)}</div>
            <div className="text-muted small">
                {creator} {history.numberOfEdits > 0 || history.numberOfChecks > 0 ? ':' : ''}
                {history.numberOfEdits > 0 && (
                    <>
                        {' '}
                        {history.numberOfEdits}{' '}
                        {history.numberOfEdits === 1
                            ? t('product.historySelector.change')
                            : t('product.historySelector.changes')}{' '}
                        {t('product.historySelector.suggested')}
                    </>
                )}
                {history.numberOfChecks > 0 && (
                    <>
                        {' '}
                        {history.numberOfChecks}{' '}
                        {history.numberOfChecks === 1
                            ? t('product.historySelector.changeAccepted')
                            : t('product.historySelector.changesAccepted')}
                    </>
                )}
            </div>
        </Dropdown.Item>
    );
}

function HistoryInfo({ history }: { history: History | undefined }) {
    const creator: string = useGetUserFullName(history?.createdBy, false);
    const { t } = useTranslation('reimbursements');

    if (history === undefined) {
        return null;
    }

    return (
        <div className="text-secondary ml-3" style={{ fontSize: 11 }}>
            <span className="font-weight-bolder">{creator}</span>
            {history.numberOfEdits > 0 && (
                <div>
                    {history.numberOfEdits}{' '}
                    {history.numberOfEdits === 1
                        ? t('product.historySelector.change')
                        : t('product.historySelector.changes')}{' '}
                    {t('product.historySelector.suggested')}
                </div>
            )}
            {history.numberOfChecks > 0 && (
                <div>
                    {history.numberOfChecks}{' '}
                    {history.numberOfChecks === 1
                        ? t('product.historySelector.change')
                        : t('product.historySelector.change')}{' '}
                    {t('product.historySelector.accepted')}
                </div>
            )}
        </div>
    );
}

const versionPeriodAsString = (version: History) => {
    const startDate = DateTime.fromSeconds(version.startDate).toLocaleString(DateTime.DATE_MED);
    const startTime = DateTime.fromSeconds(version.startDate).toLocaleString(DateTime.TIME_24_SIMPLE);

    const endDate = DateTime.fromSeconds(version.endDate).toLocaleString(DateTime.DATE_MED);
    const endTime = DateTime.fromSeconds(version.endDate).toLocaleString(DateTime.TIME_24_SIMPLE);

    let activeVersionPeriod = startDate + ' ' + startTime;
    activeVersionPeriod += startDate !== endDate || startTime !== endTime ? ' ' + 't/m' : '';
    activeVersionPeriod += startDate !== endDate ? ' ' + endDate : '';
    activeVersionPeriod += startTime !== endTime ? ' ' + endTime : '';

    return activeVersionPeriod;
};

const HistorySelectorInnerMemo = memo(HistorySelectorInner);
const HistoryItemMemo = memo(HistoryItem);
