import { useGetOneLinerChildCategoriesQuery } from 'features/metadata/metadata';

const emptyArray = [];

export function useGetChildCategories(categoryId, skip = false) {
    const { categories } = useGetOneLinerChildCategoriesQuery(categoryId, {
        selectFromResult: ({ data }) => ({
            categories: data ?? emptyArray,
        }),
        skip,
    });

    return categories;
}
