import Constants from '../../../config/Constants';

export const canAcceptChangesRoles = [
    Constants.reimbursementTeamRoles.finalEditor,
    Constants.reimbursementTeamRoles.manager,
    Constants.reimbursementTeamRoles.admin,
];

export const canAcceptAllChangesRoles = [
    Constants.reimbursementTeamRoles.admin,
];
