import { Nav } from 'react-bootstrap';
import { generatePath, Link, useParams } from 'react-router-dom';
import cx from 'classnames';
import { ADMIN_SETTINGS_PATH } from '../../../scenes/Admin';

export function Sidebar() {
    const { view } = useParams();

    return (
        <div className="content-sidebar-document overflow-auto bg-light">
            <div className="py-4 bg-lightBlueLight">
                <div className="d-flex align-items-center justify-content-between mb-2 px-4">
                    <span className="text-color font-weight-bold">Systeembeheer</span>
                </div>

                <Nav className="sidebar-nav flex-column">
                    <Link
                        to={generatePath(ADMIN_SETTINGS_PATH, {
                            view: 'accounts',
                        })}
                        className={cx('nav-link font-weight-normal', {
                            active: view === 'accounts',
                        })}
                        style={{ fontSize: '12px' }}
                    >
                        Accounts
                    </Link>
                    <Link
                        to={generatePath(ADMIN_SETTINGS_PATH, {
                            view: 'import',
                        })}
                        className={cx('nav-link font-weight-normal', {
                            active: view === 'import',
                        })}
                        style={{ fontSize: '12px' }}
                    >
                        Importeren
                    </Link>
                </Nav>
            </div>
        </div>
    );
}
