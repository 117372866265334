import { useParams } from 'react-router-dom';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { useAddOneLinerCategoryMutation } from 'features/metadata/metadata';
import { useState } from 'react';
import { Form as FForm, Formik } from 'formik';
import { InputField } from 'pages/publications_v2/helpers/FieldHelper';
import { LightOutlineButton, SecondaryButton, WarningButton } from 'components/Buttons';
import Spinner from 'pages/global/Spinner';
import { TbPlaylistAdd } from 'react-icons/tb';
import * as Yup from 'yup';
import HelperFunctions from 'pages/global/HelperFunctions';
import { useGetDocument } from 'pages/documents_v2/hooks/useGetDocument';
import _ from 'lodash';

export function AddCategoryForm({ isGroup = true, parent = null }) {
    const document = useGetDocument();
    const { documentId } = useParams();
    const organisationId = useActiveOrganisation();
    const [addOneLinerCategory] = useAddOneLinerCategoryMutation();
    const [showForm, setShowForm] = useState(false);

    const defaultMetadata = _.isEmpty(document?.properties.oneLiners.defaultMetadata)
        ? []
        : HelperFunctions.csvToArray(document?.properties.oneLiners.defaultMetadata)[0];

    if (showForm) {
        return (
            <Formik
                initialValues={{
                    isGroup,
                    name: '',
                    documentId: parseInt(documentId),
                    organisationId,
                    parent,
                    metadata: isGroup
                        ? []
                        : defaultMetadata.map((metadataKey) => ({
                              key: metadataKey,
                              value: '',
                              properties: {
                                  showInTitleRow: '1',
                              },
                          })),
                }}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {({ isValid, isSubmitting, dirty }) => (
                    <FForm className="form-inline">
                        <InputField
                            name="name"
                            props={{
                                autoFocus: true,
                            }}
                        />
                        <WarningButton className="mx-2" disabled={isSubmitting || !isValid || !dirty} type="submit">
                            Opslaan
                        </WarningButton>
                        <SecondaryButton onClick={() => setShowForm(false)}>Annuleer</SecondaryButton>
                        {isSubmitting && <Spinner />}
                    </FForm>
                )}
            </Formik>
        );
    }

    return (
        <div>
            <LightOutlineButton size="sm" onClick={() => setShowForm(!showForm)}>
                <TbPlaylistAdd />
                Nieuwe categorie
            </LightOutlineButton>
        </div>
    );

    function handleSubmit(values) {
        addOneLinerCategory(values).then(() => {
            setShowForm(false);
        });
    }
}

const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    documentId: Yup.number().required(),
    organisationId: Yup.number().required(),
    isGroup: Yup.bool().required(),
    parent: Yup.string().when('isGroup', {
        is: (val) => val === false,
        then: (s) => s.required(),
        otherwise: (s) => s.nullable(),
    }),
});
