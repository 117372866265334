import { Badge, Table } from 'react-bootstrap';
import HelperFunctions from '../../global/HelperFunctions';
import { useTranslation } from 'react-i18next';
import { InfoButton, PrimaryButton } from 'components/Buttons';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { SETTINGS_PATH } from 'scenes/Account';
import { EditUser } from './EditUser';
import { useUserProfile } from 'hooks/useUserProfile';
import { CreateUser } from './CreateUser';
import { CheckCircleFill, DashCircleFill, QuestionCircleFill, XCircleFill } from 'react-bootstrap-icons';
import { useGetOrganisationUsers } from 'hooks/useGetOrganisationUsers';
import { useCurrentOrganisation } from 'hooks/useCurrentOrganisation';
import { ResetPasswordButton } from 'pages/account/views/ResetPasswordButton';
import Constants, { Permissions } from 'config/Constants';
import RestrictedContent from 'pages/global/RestrictedContent';

export function Users() {
    return (
        <RestrictedContent module={Constants.modules.users} permission={Permissions.User['Write.All']}>
            <RenderUsers />
        </RestrictedContent>
    );
}

function RenderUsers() {
    const { t } = useTranslation('users');
    const organisation = useCurrentOrganisation();
    const users = useGetOrganisationUsers();
    const { action = 'index' } = useParams();
    const history = useHistory();

    const { ssoEnabled = false } = organisation ?? {};

    if (action === 'edit') {
        return <EditUser />;
    }

    if (action === 'create') {
        return <CreateUser />;
    }

    return (
        <div>
            <div className="subheader d-flex align-items-center justify-content-between pb-1 mb-3">
                <h3 className="mb-0">{t('profile.users')}</h3>

                <InfoButton
                    onClick={() => {
                        history.push(
                            generatePath(SETTINGS_PATH, {
                                view: 'users',
                                action: 'create',
                            })
                        );
                    }}
                >
                    {t('profile.addUser')}
                </InfoButton>
            </div>

            <Table striped>
                <thead>
                    <tr>
                        <td className="font-weight-bold">{t('columnTitles.name')}</td>
                        <td className="font-weight-bold">{t('columnTitles.jobDescription')}</td>
                        <td className="font-weight-bold">{t('columnTitles.actions')}</td>
                        {ssoEnabled && (
                            <td className="font-weight-bold">
                                <span data-uk-tooltip="Single Sign-On">SSO</span>
                            </td>
                        )}
                        <td className="font-weight-bold">
                            <span data-uk-tooltip="Twee-factor-authenticatie">2FA</span>
                        </td>
                        <td className="font-weight-bold">{t('columnTitles.status')}</td>
                        <td />
                    </tr>
                </thead>
                <tbody>
                    {users.map((user) => (
                        <User user={user} ssoEnabled={ssoEnabled} key={`user-${user.id}`} />
                    ))}
                </tbody>
            </Table>
        </div>
    );
}

function User({ user, ssoEnabled }) {
    const { t } = useTranslation('users');
    const { t: tGlobal } = useTranslation('global');
    const lastLogin = user.lastLoginTimestamp ? HelperFunctions.getLocalDateTime(user.lastLoginTimestamp) : '-';
    const history = useHistory();
    const userProfile = useUserProfile();

    return (
        <tr>
            <td className="align-middle">
                <div>{user.fullName}</div>
                <div className="text-muted small">
                    {t('columnTitles.lastLogIn')}: {lastLogin}
                </div>
            </td>
            <td className="align-middle">{user.jobDescription}</td>
            <td className="align-middle">
                {userProfile.userId !== user.id && (
                    <ResetPasswordButton user={user} />
                )}
            </td>
            {ssoEnabled && (
                <td className="align-middle">
                    {user.ssoEnabled ? (
                        <Badge variant="success" className="d-inline-flex align-items-center">
                            <CheckCircleFill className="flex-shrink-0 mr-1" />
                            {tGlobal('yes')}
                        </Badge>
                    ) : (
                        <Badge variant="danger" className="d-inline-flex align-items-center">
                            <XCircleFill className="flex-shrink-0 mr-1" />
                            {tGlobal('no')}
                        </Badge>
                    )}
                </td>
            )}
            <td className="align-middle">
                {user.ssoEnabled ? (
                    <Badge variant="light" className="d-inline-flex align-items-center">
                        <DashCircleFill className="flex-shrink-0 mr-1" />
                        {tGlobal('n/a')}
                    </Badge>
                ) : (
                    <>
                        {user.twoFactorEnabled ? (
                            <Badge variant="success" className="d-inline-flex align-items-center">
                                <CheckCircleFill className="flex-shrink-0 mr-1" />
                                {tGlobal('yes')}
                            </Badge>
                        ) : (
                            <Badge variant="danger" className="d-inline-flex align-items-center">
                                <XCircleFill className="flex-shrink-0 mr-1" />
                                {tGlobal('no')}
                            </Badge>
                        )}
                    </>
                )}
            </td>
            <td className="align-middle">
                {user.enabled ? (
                    <Badge variant="success" className="d-inline-flex align-items-center">
                        <CheckCircleFill className="flex-shrink-0 mr-1" />
                        {t('status.active')}
                    </Badge>
                ) : (
                    <Badge variant="warning" className="d-inline-flex align-items-center">
                        <QuestionCircleFill className="flex-shrink-0 mr-1" />
                        {t('status.inactive')}
                    </Badge>
                )}
            </td>
            <td className="align-middle">
                <PrimaryButton
                    disabled={user.id === userProfile.userId}
                    onClick={() => {
                        const url = generatePath(SETTINGS_PATH, {
                            view: 'users',
                            action: 'edit',
                            id: user.id,
                        });

                        history.push(url);
                    }}
                >
                    {tGlobal('btn.edit')}
                </PrimaryButton>
            </td>
        </tr>
    );
}
