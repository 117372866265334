import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import { Logo } from 'pages/global/Logo';
import _ from 'lodash';
import { PrimaryButton } from 'components/Buttons';
import LanguageSwitcher from 'language/LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useUserPasswordRequestMutation } from 'features/security/authApi';
import { Link } from 'react-router-dom';

export const FORGOT_PASSWORD_PATH = '/forgot-password';

export function ForgotPassword() {
    const { t } = useTranslation();
    const userEmail = localStorage.getItem('userEmail') ?? '';
    const [userPasswordRequest] = useUserPasswordRequestMutation();

    const [username, setUsername] = useState(userEmail);
    const [isLoading, setIsLoading] = useState(false);
    const [requestSent, setRequestSent] = useState(false);

    return (
        <div>
            <div id="login-bg-image" />
            <Container className="page-login pt-5 pb-4">
                <Row className="justify-content-center">
                    <Col sm={10} md={8} lg={6} xl={5}>
                        <Card className="dr-container mt-3">
                            <Card.Body>
                                <div className="p-4">
                                    <div className="text-center mb-4">
                                        <Logo />
                                        <div className="text-color pb-2" style={{ fontSize: '1.15rem' }}>
                                            {t('global:login.reset.forgotPassword')}
                                        </div>
                                    </div>

                                    {requestSent ? (
                                        <div>
                                            <div className="mb-3">{t('global:login.reset.requestSent')}</div>

                                            <Link to="/">{t('global:login.reset.backToLogin')}</Link>
                                        </div>
                                    ) : (
                                        <Form className="login-form" onSubmit={handleFormSubmit}>
                                            <Form.Group controlId="username">
                                                <Form.Label
                                                    className="font-weight-normal"
                                                    style={{ textTransform: 'none' }}
                                                >
                                                    {t('global:login.reset.sendCodeTo')}
                                                </Form.Label>
                                                <Form.Control
                                                    required
                                                    disabled={isLoading}
                                                    autoComplete="username"
                                                    autoFocus={true}
                                                    type="email"
                                                    placeholder={t('global:login.authentication.emailPlaceholder')}
                                                    name="username"
                                                    value={username}
                                                    onChange={(e) => setUsername(_.trim(e.currentTarget.value))}
                                                />
                                            </Form.Group>

                                            <PrimaryButton type="submit" className="w-100 py-2" disabled={isLoading}>
                                                {t('global:login.reset.sendCodeBtn')}
                                            </PrimaryButton>
                                        </Form>
                                    )}
                                </div>
                            </Card.Body>
                        </Card>

                        <div className="d-flex justify-content-center align-items-center pt-2">
                            <LanguageSwitcher />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );

    function handleFormSubmit(e) {
        e.preventDefault();

        setIsLoading(true);

        userPasswordRequest({
            email: username,
        }).then(() => {
            setRequestSent(true);
        });
    }
}
