import React, { useState } from 'react';
import { CaretRightFill, CollectionFill, PlusCircle, ThreeDotsVertical } from 'react-bootstrap-icons';
import { Button, Dropdown, Nav } from 'react-bootstrap';
import { generatePath, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    deletePublicationGroup,
    selectPublicationGroups,
    selectPublications,
    selectRootLevelPublicationGroups,
} from '../../../../features/publications/publicationsSlice';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import HelperFunctions from '../../../global/HelperFunctions';
import { getSelectedGroupId } from '../Index';
import cx from 'classnames';
import Constants, { Permissions } from '../../../../config/Constants';
import RestrictedContent, { useHasPermission } from '../../../global/RestrictedContent';
import DropdownMenu from '../../../global/DropdownMenu';
import { BASE_PATH, INDEX_PATH } from '../../../../scenes/PublicationsV2';
import { RxDragHandleDots2 } from 'react-icons/rx';
import { useTranslation } from 'react-i18next';

export default function Sidebar({ setShowModal, setShowDuplicateModal, onGroupDragEnd, handleMove }) {
    const history = useHistory();
    const rootLevelGroups = useSelector(selectRootLevelPublicationGroups());
    const groupId = getSelectedGroupId();
    const selectedGroupUri = getSelectedGroupId(true);
    const [isDragging, setDragging] = useState(false);
    const { t } = useTranslation('global');

    const onDragEnd = (result) => {
        setDragging(false);
        onGroupDragEnd(result);
    };

    return (
        <div className="pt-4">
            <Nav className="folder-nav folder-nav-publications flex-column mb-3">
                <Nav.Link
                    className="d-flex align-items-center"
                    active={groupId === '0'}
                    onClick={() => {
                        history.push(generatePath(BASE_PATH));
                    }}
                >
                    <div className="font-weight-bolder">
                        <CollectionFill size={14} className="mr-2" />
                        {t('contentSidebar.all')}
                    </div>

                    <RestrictedContent permission={Permissions.PublicationFolder['Write.All']}>
                        <Button
                            variant="link"
                            className="ml-auto text-body p-0"
                            onClick={() =>
                                setShowModal({
                                    rootLevel: true,
                                    sortOrder: rootLevelGroups[rootLevelGroups.length - 1].sortOrder + 1,
                                })
                            }
                            data-uk-tooltip={`${t('contentSidebar.map.chaptersMap')}...`}
                        >
                            <PlusCircle size={14} />
                        </Button>
                    </RestrictedContent>
                </Nav.Link>
            </Nav>

            <DragDropContext onDragEnd={onDragEnd} onDragStart={() => setDragging(true)}>
                {rootLevelGroups.map((_rootGroup, index) => {
                    const childGroups = _rootGroup.children ?? [];

                    return (
                        <RootGroup
                            group={_rootGroup}
                            setShowModal={setShowModal}
                            isDragging={isDragging}
                            setShowDuplicateModal={setShowDuplicateModal}
                            handleMove={handleMove}
                            index={index - 1}
                            isLast={rootLevelGroups.length - 1 === index}
                            initialShowContents={selectedGroupUri !== '0' ? childGroups.includes(selectedGroupUri) : false}
                            key={`root-group-${_rootGroup.id}`}
                        />
                    )
                })}
            </DragDropContext>
        </div>
    );
}

function RootGroup({
    group,
    setShowModal,
    isDragging,
    setShowDuplicateModal,
    handleMove,
    index,
    isLast,
    initialShowContents = false,
}) {
    const groups = useSelector(selectPublicationGroups(group['@id']));
    const [showContents, setShowContents] = useState(initialShowContents);

    const toggleContents = () => {
        setShowContents((prevState) => !prevState);
    };

    if (group.id === null && groups.length === 0) {
        return null;
    }

    return (
        <div className="mb-3">
            <div
                className="nav-dropdown-hover d-flex align-items-center mb-1"
                style={{
                    padding: '0.6rem 1.15rem 0.4rem 1.5rem',
                }}
                onClick={toggleContents}
            >
                <div className="font-weight-bolder d-flex justify-content-between cursor-pointer">
                    <div className="sidebar-titles">{group.name}</div>
                    <CaretRightFill
                        size={14}
                        className={cx('sidebar-icons ml-2', {
                            'rotate-90': showContents,
                        })}
                    />
                </div>

                {group.id !== null && (
                    <RestrictedContent permission={Permissions.PublicationFolder['Write.All']}>
                        <div className="ml-auto flex-shrink-0 mr-1">
                            <GroupActions
                                setShowModal={setShowModal}
                                setShowDuplicateModal={setShowDuplicateModal}
                                folder={group}
                                groups={groups}
                                handleMove={handleMove}
                                index={index}
                                isLast={isLast}
                            >
                                <DropDownToggle folderId={group.id} />
                            </GroupActions>
                        </div>
                    </RestrictedContent>
                )}
            </div>

            {showContents && (
                <Droppable droppableId={group['@id'] === null ? 'default' : group['@id']}>
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={{
                                minHeight: 36,
                            }}
                            {...provided.droppableProps}
                        >
                            <Nav
                                className={cx('folder-nav folder-nav-publications flex-column', {
                                    'dragging-over': snapshot.isDraggingOver,
                                    'is-dragging': isDragging,
                                })}
                            >
                                {groups.map((group, childIndex) => (
                                    <Folder
                                        index={childIndex}
                                        folder={group}
                                        setShowModal={setShowModal}
                                        setShowDuplicateModal={setShowDuplicateModal}
                                        handleMove={handleMove}
                                        isLast={groups.length - 1 === childIndex}
                                        key={`sidebar-group-${group.id}`}
                                    />
                                ))}
                                {provided.placeholder}
                            </Nav>
                        </div>
                    )}
                </Droppable>
            )}
        </div>
    );
}

function Folder({ folder, index, setShowModal, setShowDuplicateModal, handleMove, isLast }) {
    return (
        <>
            <FolderInner
                folder={folder}
                index={index}
                setShowModal={setShowModal}
                setShowDuplicateModal={setShowDuplicateModal}
                handleMove={handleMove}
                isLast={isLast}
            />
        </>
    );
}

function FolderInner({ folder, index, setShowModal, setShowDuplicateModal, handleMove, isLast }) {
    const hasReadWritePermission = useHasPermission(Permissions.Publication['Write.All']);
    const history = useHistory();
    const groupId = getSelectedGroupId();
    const isActive = groupId === folder.id;
    const publications = useSelector(selectPublications(folder['@id']));

    return (
        <Draggable draggableId={folder['@id']} index={index} isDragDisabled={hasReadWritePermission === false}>
            {(provided, snapshot) => {
                return (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <Nav.Link
                            as="div"
                            className={cx('d-flex align-items-center position-relative nav-dropdown-hover', {
                                'is-dragging': snapshot.isDragging,
                            })}
                            active={isActive}
                            onClick={() => {
                                if (isActive === false) {
                                    history.push(
                                        generatePath(INDEX_PATH, {
                                            folder: Constants.folders.folder,
                                            folderId: folder.id,
                                        })
                                    );
                                }
                            }}
                        >
                            <RestrictedContent permission={Permissions.PublicationFolder['Write.All']}>
                                <RxDragHandleDots2 size={16} className="flex-shrink-0 text-color icon-grip" />
                            </RestrictedContent>

                            <div className="small">{folder.name}</div>

                            <div className="d-flex align-items-center ml-auto">
                                <RestrictedContent permission={Permissions.PublicationFolder['Write.All']}>
                                    <GroupActions
                                        setShowModal={setShowModal}
                                        setShowDuplicateModal={setShowDuplicateModal}
                                        folder={folder}
                                        handleMove={handleMove}
                                        isLast={isLast}
                                        index={index}
                                    >
                                        <DropDownToggle folderId={folder.id} />
                                    </GroupActions>
                                </RestrictedContent>

                                <div className="text-center small" style={{ minWidth: 16 }}>
                                    {publications?.length ?? 0}
                                </div>
                            </div>
                        </Nav.Link>
                    </div>
                );
            }}
        </Draggable>
    );
}

function GroupActions({
    setShowModal,
    folder,
    groups = [],
    setShowDuplicateModal,
    children,
    handleMove,
    isLast,
    index,
}) {
    const dispatch = useDispatch();
    const [dropdownIsOpen, setDropDownOpen] = useState(false);
    const history = useHistory();
    const showMoveItems = typeof handleMove === 'function';
    const { t } = useTranslation('global');

    const handleSelect = (eventKey) => {
        switch (eventKey) {
            case 'insert':
                setShowModal({
                    parent: folder['@id'],
                    sortOrder: groups.length > 0 ? groups[groups.length - 1].sortOrder + 1 : 0,
                });
                break;
            case 'edit':
                setShowModal(folder);
                break;
            case 'duplicate':
                setShowDuplicateModal({
                    publications: folder.publications,
                    folder,
                });
                break;
            case 'delete':
                HelperFunctions.confirmModal(
                    `${t('contentSidebar.map.confirmDelete', { name: folder.name })}`,
                    'danger',
                    false,
                    t('contentSidebar.map.yesDelete'),
                    t('btn.cancel')
                )
                    .then(() => {
                        dispatch(deletePublicationGroup({ id: folder.id, uri: folder['@id'] })).then(() => {
                            // Redirect to main page
                            history.push(generatePath(BASE_PATH));
                        });
                    })
                    .catch(() => {});
                break;
            case 'moveUp':
                handleMove('up', folder, index);
                break;
            case 'moveDown':
                handleMove('down', folder, index);
                break;
        }
    };

    const canDelete = () => {
        return folder.publications.length === 0 && groups.length === 0;
    };

    const canDuplicate = () => {
        return folder.publications.length > 0;
    };

    return (
        <Dropdown
            className={cx('d-flex align-items-center cursor-pointer', {
                'dropdown-open': dropdownIsOpen,
            })}
            onSelect={handleSelect}
            onToggle={(isOpen, event) => {
                event.stopPropagation();
                setDropDownOpen(isOpen);
            }}
        >
            {children}

            <DropdownMenu>
                {folder.rootLevel && (
                    <Dropdown.Item eventKey="insert">{t('contentSidebar.map.menu.subfolder')}&hellip;</Dropdown.Item>
                )}

                <Dropdown.Item eventKey="edit">{t('contentSidebar.map.menu.changeName')}</Dropdown.Item>

                {folder.rootLevel === false && (
                    <Dropdown.Item eventKey="duplicate" disabled={!canDuplicate()}>
                        {t('contentSidebar.map.menu.duplicate')}&hellip;
                    </Dropdown.Item>
                )}

                {showMoveItems && (
                    <>
                        <Dropdown.Item eventKey="moveUp" disabled={index === 0}>
                            {t('contentSidebar.map.menu.moveUp')}
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="moveDown" disabled={isLast}>
                            {t('contentSidebar.map.menu.moveDown')}
                        </Dropdown.Item>
                    </>
                )}

                <Dropdown.Divider />

                <Dropdown.Item eventKey="delete" disabled={!canDelete()}>
                    <span className={canDelete() ? 'text-danger' : 'text-muted'}>
                        {t('contentSidebar.map.menu.delete')}&hellip;
                    </span>
                </Dropdown.Item>
            </DropdownMenu>
        </Dropdown>
    );
}

function DropDownToggle({ folderId }) {
    return (
        <Dropdown.Toggle
            bsPrefix="docrev-dropdown-toggle"
            as={ThreeDotsVertical}
            size={16}
            id={`folder-${folderId}-dropdown`}
        />
    );
}
