import { useGetProductsQuery } from 'features/reimbursements/reimbursements';
import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';

export function useGetProduct(categoryId, productUri) {
    const selectProduct = useMemo(() => {
        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (data) => data,
            (data, productUri) => productUri,
            (data, productUri) => (data ? data.find((product) => product['@id'] === productUri) : undefined),
        );
    }, []);

    const { product } = useGetProductsQuery(categoryId, {
        selectFromResult: ({ data }) => ({
            product: selectProduct(data, productUri),
        }),
    });

    return product;
}
