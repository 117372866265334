import * as ReactIconsIo from 'react-icons/io';
import * as ReactIconsFa from 'react-icons/fa';
import * as ReactIconsMd from 'react-icons/md';
import * as RemixIcons from 'react-icons/ri';
import * as BoxIcons from 'react-icons/bi';
import * as IcoMoonIcons from 'react-icons/im';
import * as GrommentIcons from 'react-icons/gr';
import * as ReactIconsIo5 from 'react-icons/io5';
import * as BootstrapIcons from 'react-icons/bs';

export const iconLibraries = {
    'react-icons/fa': {
        library: 'React Icons (FontAwesome)',
        icons: ReactIconsFa,
    },
    'react-icons/bs': {
        library: 'React Icons (Bootstrap)',
        icons: BootstrapIcons,
    },
    'react-icons/md': {
        library: 'React Icons (Material Design)',
        icons: ReactIconsMd,
    },
    'react-icons': {
        library: 'React Icons (Ionicons 4)',
        icons: ReactIconsIo,
    },
    'react-icons/io5': {
        library: 'React Icons (Ionicons 5)',
        icons: ReactIconsIo5,
    },
    'react-icons/ri': {
        library: 'React Icons (Remix)',
        icons: RemixIcons,
    },
    'react-icons/bi': {
        library: 'React Icons (Boxicons)',
        icons: BoxIcons,
    },
    'react-icons/im': {
        library: 'React Icons (IcoMoon)',
        icons: IcoMoonIcons,
    },
    'react-icons/gr': {
        library: 'React Icons (Grommet)',
        icons: GrommentIcons,
    },
};