import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Field, Form as FForm, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { FormModal, ModalFooter } from '../../global/FormModal';
import {
    addOpListUser,
    fetchEligibleDepartmentUsers,
    patchOpListUser,
    selectAllEligibleDepartmentUsers,
    selectAllOpListUsers,
} from '../../../features/operationsList/operationsListSlice';
import Select from 'react-select';
import LoadingSpinner from '../../global/LoadingSpinner';
import _ from 'lodash';
import { useGetLabelFoldersQuery, useGetLabelsQuery } from '../../../features/metadata/metadata';
import { useTranslation } from 'react-i18next';

export default function EditOpListUserModal({
    showModal = false,
    opList,
    opListUser,
    opDepartment,
    opTeams,
    handleClose,
}) {
    const dispatch = useDispatch();
    const editMode = !!opListUser.id;
    const allOpListUsers = useSelector((state) => selectAllOpListUsers(state.operations));
    const { activeOrganisation } = useSelector((state) => state.security);
    const { t } = useTranslation('changelist');

    const { allFolders = [] } = useGetLabelFoldersQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                allFolders: data ?? [],
            }),
        }
    );

    const { allLabels = [] } = useGetLabelsQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                allLabels: data ?? [],
            }),
        }
    );

    const eligibleUsers = useSelector((state) => selectAllEligibleDepartmentUsers(state.operations));
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (loading) {
            dispatch(
                fetchEligibleDepartmentUsers({
                    opListId: opList.id,
                    departmentId: opDepartment.id,
                    allowManagers: false,
                })
            ).then(() => setLoading(false));
        }
    }, [dispatch, opList, opDepartment]);

    if (loading) {
        return <LoadingSpinner />;
    }

    const handleSubmit = (values, { setSubmitting }) => {
        const formData = {
            ...values,
            opDepartment: opDepartment['@id'],
            labels: values.labels.map((_item) => _item.value),
            userId: values.userId.value,
            opTeams: values.opTeams.map((_team) => _team.value),
        };

        if (opListUser.hasOwnProperty('id')) {
            // Patch
            dispatch(patchOpListUser({ uri: opListUser['@id'], formData })).then((data) => {
                setSubmitting(false);
                handleClose(data);
            });
        } else {
            // Create
            dispatch(addOpListUser(formData)).then((data) => {
                setSubmitting(false);
                handleClose(data);
            });
        }
    };

    const teamOptions = opTeams.map((team) => {
        return {
            label: team.name,
            value: team['@id'],
        };
    });

    return (
        <FormModal
            show={showModal}
            onHide={() => handleClose()}
            title={t('changelist.task.opListUserModal.editTeamMember')}
        >
            <Formik
                initialValues={{
                    // If no previous value, set default to true if department splits entries per label and if not set default to false
                    autoAssignLabels: opListUser?.autoAssignLabels ?? opDepartment.splitPerLabel,
                    opList: opList['@id'],
                    labels: opListUser.hasOwnProperty('labels')
                        ? opListUser.labels
                              .map((_uri) => allLabels.find((_label) => _label['@id'] === _uri))
                              .map((_item) => {
                                  return {
                                      value: _item['@id'],
                                      label: _item.name,
                                  };
                              })
                        : [],
                    userId: opListUser?.userId ?? '',
                    opTeams: _.get(opListUser, 'opTeams')
                        ? _.filter(teamOptions, (teamOption) => {
                              return opListUser.opTeams.includes(teamOption.value);
                          })
                        : [],
                }}
                validationSchema={editMode ? EditTeamSchema : NewTeamSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, setFieldValue, isValid, values, dirty }) => (
                    <FForm autoComplete="off">
                        <Modal.Body>
                            {!editMode && (
                                <Form.Group>
                                    <Form.Label htmlFor="userId">
                                        {t('changelist.task.opListUserModal.name')}*
                                    </Form.Label>
                                    <Field
                                        id="userId"
                                        name="userId"
                                        as={Select}
                                        options={getUserOptions(
                                            opDepartment['@id'] ?? undefined,
                                            eligibleUsers,
                                            allOpListUsers
                                        )}
                                        onChange={(selectedOptions) => {
                                            setFieldValue('userId', selectedOptions ?? []);
                                        }}
                                    />
                                </Form.Group>
                            )}
                            <Form.Group>
                                <Form.Label htmlFor="opTeams">{t('changelist.task.opListUserModal.teams')}</Form.Label>
                                <Field
                                    isMulti
                                    id="opTeams"
                                    name="opTeams"
                                    as={Select}
                                    options={teamOptions}
                                    onChange={(selectedOptions) => {
                                        setFieldValue('opTeams', selectedOptions ?? []);
                                    }}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label htmlFor="labels">
                                    {t('changelist.task.opListUserModal.insuranceTypes')}
                                </Form.Label>
                                <Field
                                    isDisabled={!opDepartment.splitPerLabel}
                                    isMulti
                                    id="labels"
                                    name="labels"
                                    as={Select}
                                    // Exclude generalLabel. The generalLabel has a different function and should not be automatically assignable.
                                    options={getLabelOptions(allLabels, allFolders, opDepartment, [
                                        opDepartment.generalLabel,
                                    ])}
                                    onChange={(selectedOptions) => {
                                        setFieldValue('labels', selectedOptions ?? []);
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className="pt-2">
                                <Field
                                    disabled={!opDepartment.splitPerLabel}
                                    id="autoAssignLabels"
                                    name="autoAssignLabels"
                                    as={Form.Switch}
                                    checked={values.autoAssignLabels}
                                    label={t('changelist.task.opListUserModal.autoAssign')}
                                    onChange={(event) => {
                                        setFieldValue('autoAssignLabels', event.target.checked);
                                    }}
                                />
                                <Form.Text id="autoAssignLabels" muted>
                                    {t('changelist.task.opListUserModal.ifChecked')}
                                </Form.Text>
                            </Form.Group>
                        </Modal.Body>

                        <ModalFooter
                            isSubmitting={isSubmitting}
                            isValid={isValid}
                            dirty={dirty}
                            onHide={() => handleClose()}
                        />
                    </FForm>
                )}
            </Formik>
        </FormModal>
    );
}

export const getLabelOptions = (allLabels, allFolders, selectedDepartment, excludeLabels = []) => {
    if (selectedDepartment === '') {
        return [];
    }

    return allLabels
        .filter((_label) => (_label.folder ? selectedDepartment.labels.includes(_label.folder) : false))
        .filter((_label) => !excludeLabels.includes(_label['@id']))
        .map((label) => {
            return {
                value: label['@id'],
                label: label.name,
            };
        });
};

const getUserOptions = (selectedDepartment, eligibleUsers, allOpListUsers) => {
    if (selectedDepartment === '') {
        return [];
    }

    const departmentUserIds = allOpListUsers
        .filter((_opListUser) => _opListUser.opDepartment === selectedDepartment)
        .map((_opListUser) => _opListUser.userId);

    return eligibleUsers
        .filter((_user) => !departmentUserIds.includes(_user.id))
        .map((_user) => {
            return {
                value: _user.id,
                label: `${_user.firstName} ${_user.lastName}`,
            };
        });
};

const NewTeamSchema = Yup.object().shape({
    userId: Yup.object().required(),
    opTeam: Yup.object(),
    opList: Yup.string().required(),
    autoAssignLabels: Yup.boolean().required(),
});

const EditTeamSchema = Yup.object().shape({
    userId: Yup.string().required(),
    opTeam: Yup.object(),
    opList: Yup.string().required(),
    autoAssignLabels: Yup.boolean().required(),
});
