import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    fetchOperationsLists,
    fetchOpListDepartments,
    fetchOpListUsers,
    patchDepartment,
    selectOpListById,
} from '../../../features/operationsList/operationsListSlice';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../../global/LoadingSpinner';
import { selectDepartmentUserBelongsTo } from '../../../features/operationsList/opListUserSlice';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { FieldArray, Form as FForm, Formik } from 'formik';
import { Button, Form } from 'react-bootstrap';
import * as Yup from 'yup';
import Spinner from '../../global/Spinner';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { StatusCodeRow } from './StatusCodeRow';
import { fetchTasks } from '../../../features/operationsList/taskSlice';
import { WarningButton } from '../../../components/Buttons';
import { useTranslation } from 'react-i18next';

export default function StatusCodes() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const opList = useSelector((state) => selectOpListById(state.operations, id));
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (loading) {
            const options = { opListId: id };
            Promise.all([
                dispatch(fetchOperationsLists()),
                dispatch(fetchOpListUsers(options)),
                dispatch(fetchOpListDepartments(options)),
            ]).then(() => setLoading(false));
        }
    }, [dispatch, id]);

    useEffect(() => {
        dispatch(fetchTasks());
    }, [dispatch]);

    if (loading) {
        return <LoadingSpinner />;
    }

    return <StatusCodeList opList={opList} />;
}

function StatusCodeList({ opList }) {
    const [expandedItem, setExpandedItem] = useState(undefined);
    const department = useSelector(selectDepartmentUserBelongsTo(opList));
    const dispatch = useDispatch();
    const { t } = useTranslation('changelist');

    const handleSubmit = (values, { setSubmitting }) => {
        const formData = {
            statusCodes: values.statusCodes.map((_statusCode, index) => {
                return {
                    ..._statusCode,
                    slug: _statusCode.slug === '' ? uuidv4() : _statusCode.slug,
                    sortOrder: index,
                };
            }),
        };

        // Patch
        dispatch(patchDepartment({ uri: department['@id'], formData })).then(() => {
            setSubmitting(false);
        });
    };

    return (
        <Formik
            initialValues={{
                statusCodes:
                    _.size(department.statusCodes) > 0
                        ? department.statusCodes
                        : [
                              {
                                  label: t('changelist.statusCodes.new'),
                                  slug: 'nieuw',
                                  color: '#ccc',
                                  required: true,
                                  sortOrder: 0,
                              },
                              {
                                  label: t('changelist.statusCodes.completed'),
                                  slug: 'afgerond',
                                  color: '#7be262',
                                  required: true,
                                  sortOrder: 1,
                              },
                          ],
            }}
            validationSchema={StatusCodeSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, isValid, dirty, values, resetForm }) => (
                <FForm autoComplete="off">
                    <FieldArray name="statusCodes">
                        {({ remove, push, move }) => (
                            <Form.Group>
                                <div className="subheader d-flex align-items-start">
                                    <h3>Status codes</h3>
                                    <Button
                                        size="sm"
                                        variant="success"
                                        className="ml-auto"
                                        onClick={() => {
                                            push({
                                                label: t('changelist.statusCodes.nameless'),
                                                color: '#1127f5',
                                                slug: '',
                                            });
                                        }}
                                    >
                                        {t('changelist.statusCodes.btn.newCode')}
                                    </Button>
                                </div>

                                <EditDepartmentStatusCodes
                                    opList={opList}
                                    move={move}
                                    remove={remove}
                                    values={values.statusCodes}
                                    expandedItem={expandedItem}
                                    setExpandedItem={setExpandedItem}
                                />
                            </Form.Group>
                        )}
                    </FieldArray>

                    <div className="mb-3">
                        <WarningButton
                            disabled={isSubmitting || !isValid || !dirty || expandedItem !== undefined}
                            type="submit"
                        >
                            {t('btn.save')}
                        </WarningButton>
                        <Button
                            variant="link"
                            disabled={isSubmitting || !dirty || expandedItem !== undefined}
                            onClick={() => resetForm()}
                        >
                            {t('btn.cancel')}
                        </Button>
                        {isSubmitting && <Spinner />}
                    </div>
                </FForm>
            )}
        </Formik>
    );
}

function EditDepartmentStatusCodes({ opList, values = [], move, remove, expandedItem, setExpandedItem }) {
    const onDragEnd = (result) => {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        move(source.index, destination.index);
    };

    const getListStyle = () => ({
        background: '#f1f1f1',
        flexGrow: 1,
        border: '1px solid #efefef',
        borderRadius: 3,
    });

    return (
        <div className="d-flex">
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="statusCodes">
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                            {...provided.droppableProps}
                        >
                            {values.map((item, index) => (
                                <StatusCodeRow
                                    opList={opList}
                                    item={item}
                                    index={index}
                                    setExpandedItem={setExpandedItem}
                                    isExpanded={expandedItem !== undefined && expandedItem === index}
                                    hasExpandedItem={expandedItem !== undefined}
                                    remove={remove}
                                    key={`code-row-${index}`}
                                />
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
}

const StatusCodeSchema = Yup.object().shape({
    statusCodes: Yup.array()
        .of(
            Yup.object().shape({
                label: Yup.string().required(),
                color: Yup.string().required(),
                slug: Yup.string(),
                sortOrder: Yup.number(),
                required: Yup.boolean(),
            })
        )
        .required()
        .min(2),
});
