import { Col, Container, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import MainContentNav from '../../Navbar';
import { generatePath, useParams } from 'react-router-dom';
import {
    fetchOpListDepartments,
    fetchOpListUsers,
    selectOpListById,
} from '../../../features/operationsList/operationsListSlice';
import { useDispatch, useSelector } from 'react-redux';
import SubNav from '../nav/SubNav';
import _ from 'lodash';
import { selectDepartmentUserIsManagerOf } from '../../../features/operationsList/opListUserSlice';
import {
    fetchOpListExportTokens,
    selectOpListExportTokensByDepartment,
} from '../../../features/publications/publicationsSlice';
import LoadingSpinner from '../../global/LoadingSpinner';
import Constants from '../../../config/Constants';
import getEnv from '../../../config/Env';
import { BoxArrowUpRight, FileEarmarkExcel, FileEarmarkPdf } from 'react-bootstrap-icons';
import { DateTime } from 'luxon';
import cx from 'classnames';
import { Action } from '../../publications_v2/views/publication_row/PublicationRowDetails';
import SelectExportSegmentModal from './SelectExportSegmentModal';
import CreateExportModal from './CreateExportModal';
import { BASE_PATH, VIEW_PATH } from '../../../scenes/OperationsList';
import { useTranslation } from 'react-i18next';

export default function Export() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const opList = useSelector((state) => selectOpListById(state.operations, id));
    const { t } = useTranslation('changelist');

    const selectedDepartment = useSelector(selectDepartmentUserIsManagerOf(opList)); // only managers are able to see report
    const selectedDepartmentId = _.get(selectedDepartment, 'id');

    const [loading, setLoading] = useState(true);
    const [selectedExportToken, setSelectedExportToken] = useState(null);
    const [showExportModal, setShowExportModal] = useState(false);

    useEffect(() => {
        Promise.all([
            dispatch(fetchOpListUsers({ opListId: id })),
            dispatch(fetchOpListDepartments({ opListId: id })),
        ]).then(() => setLoading(false));
    }, [dispatch, id]);

    if (!selectedDepartment && !loading) {
        return t('changelist.export.export.noAccess');
    }

    return (
        <>
            <MainContentNav
                pages={[
                    { title: t('breadcrumb'), url: BASE_PATH },
                    {
                        title: opList?.name,
                        url: generatePath(VIEW_PATH, {
                            id: opList?.id,
                        }),
                    },
                    { title: t('changelist.export.export.report') },
                ]}
            />
            <SubNav />

            {loading ? (
                <LoadingSpinner />
            ) : (
                <div className="content-static-body has-subnav">
                    <div className="d-flex align-items-stretch h-100">
                        <div className="flex-grow-1 overflow-auto">
                            <div className="p-4">
                                <Container fluid>
                                    <ExportList
                                        departmentId={selectedDepartmentId}
                                        departmentName={selectedDepartment.name}
                                        setSelectedExportToken={setSelectedExportToken}
                                        setShowExportModal={setShowExportModal}
                                    />
                                    <SelectExportSegmentModal
                                        handleClose={() => setSelectedExportToken(null)}
                                        showModal={selectedExportToken !== null}
                                        payload={{ token: selectedExportToken }}
                                    />
                                    <CreateExportModal
                                        departmentId={selectedDepartmentId}
                                        handleClose={() => setShowExportModal(false)}
                                        showModal={showExportModal}
                                        payload={{ type: showExportModal }}
                                    />
                                </Container>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

function ExportList({ departmentId, departmentName, setSelectedExportToken, setShowExportModal }) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation('changelist');

    const exportTokens = useSelector(selectOpListExportTokensByDepartment(departmentId));

    useEffect(() => {
        dispatch(fetchOpListExportTokens({ departmentId })).then(() => setLoading(false));
    }, [dispatch, departmentId]);

    const hasPendingTokens = checkPendingTokens(exportTokens);

    useEffect(() => {
        const interval = setInterval(() => {
            if (hasPendingTokens) {
                // Refresh every 5 seconds when there are preview tokens
                dispatch(fetchOpListExportTokens({ departmentId }));
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [dispatch, hasPendingTokens, departmentId]);

    if (loading) {
        return <LoadingSpinner />;
    }

    return (
        <>
            <div className="font-weight-bold mb-1">
                {t('changelist.export.export.exportsForDepartment', { department: departmentName })}
            </div>
            <p className="uk-text-muted">{t('changelist.export.export.viewingPdf')}</p>

            <ExportActions setShowExportModal={setShowExportModal} />

            <div className="docrev-flex-table mb-4">
                <Container fluid>
                    <Row className="docrev-flex-head">
                        <Col md={1}>{t('changelist.export.export.number')}</Col>
                        <Col md={2}>{t('changelist.export.export.type')}</Col>
                        <Col md={2}>{t('changelist.export.export.format')}</Col>
                        <Col md={2}>{t('changelist.export.export.statusTitle')}</Col>
                        <Col md={3}>{t('changelist.export.export.availableUntil')}</Col>
                        <Col md={2}>{t('changelist.export.export.actions')}</Col>
                    </Row>

                    {loading && (
                        <Row>
                            <Col className="text-secondary">
                                <LoadingSpinner size="sm" inline />
                            </Col>
                        </Row>
                    )}

                    {!loading && exportTokens.length === 0 && (
                        <Row>
                            <Col className="text-secondary">{t('changelist.export.export.noExportsAvailable')}</Col>
                        </Row>
                    )}

                    {!loading && (
                        <ExportRows
                            departmentId={departmentId}
                            exportTokens={exportTokens}
                            setSelectedExportToken={setSelectedExportToken}
                        />
                    )}
                </Container>
            </div>
        </>
    );
}

function ExportRows({ departmentId, exportTokens, setSelectedExportToken }) {
    return exportTokens.map((token, index) => (
        <ExportRow
            exportTokens={exportTokens}
            token={token}
            index={index}
            setSelectedExportToken={setSelectedExportToken}
            key={`deparment-${departmentId}-token-${token.id}`}
        />
    ));
}

function ExportRow({ exportTokens, token, index, setSelectedExportToken }) {
    const number = exportTokens.length - index;
    const expiresAt = DateTime.fromISO(token.expiresAt);
    const itemEnabled = token.status === Constants.opListExportTokenStatus.processed;
    const statusClasses = cx('badge', {
        'badge-success': token.status === Constants.opListExportTokenStatus.processed,
        'badge-secondary': token.status === Constants.opListExportTokenStatus.pending,
        'badge-warning': token.status === Constants.opListExportTokenStatus.processing,
        'badge-danger': token.status === Constants.opListExportTokenStatus.failed,
    });
    const { t } = useTranslation('changelist');

    return (
        <Row className="docrev-flex-body align-items-center">
            <Col md={1}>
                <div className="text-secondary">#{number}</div>
            </Col>
            <Col md={2}>
                {token.action === Constants.publicationToken.export_changes && (
                    <div>{t('changelist.export.export.changes')}</div>
                )}
                {token.action === Constants.publicationToken.preview && (
                    <div>{t('changelist.export.export.preview')}</div>
                )}
            </Col>
            <Col md={2}>
                <div className="d-flex align-items-center">
                    {token.type === 'excel' && (
                        <>
                            <FileEarmarkExcel size={16} className="mr-1" />
                            <div>Excel</div>
                        </>
                    )}
                    {token.type === 'pdf' && (
                        <>
                            <FileEarmarkPdf size={16} className="mr-1" />
                            <div>Pdf</div>
                        </>
                    )}
                </div>
            </Col>
            <Col md={2}>
                <div className={statusClasses}>
                    {statusTranslation[token.status]
                        ? t(statusTranslation[token.status])
                        : t('changelist.export.export.unknown')}
                </div>
            </Col>
            <Col md={3}>
                <div>{expiresAt.toLocaleString(DateTime.DATETIME_SHORT)} uur</div>
            </Col>
            <Col md={2}>
                <div>
                    {itemEnabled &&
                        (token.type === 'pdf' && token?.totalSegments > 1 ? (
                            <a
                                className="d-inline-flex align-items-center badge badge-primary mr-1 pr-3"
                                href="#"
                                onClick={(event) => {
                                    event.preventDefault();
                                    setSelectedExportToken(token);
                                }}
                                data-uk-tooltip={t('changelist.export.export.view')}
                            >
                                <span className="mr-1">Pdf</span>
                                <BoxArrowUpRight className="flex-shrink-0" />
                            </a>
                        ) : (
                            <a
                                className="d-inline-flex align-items-center badge badge-primary mr-1 pr-3"
                                href={`${getEnv('PUBLICATION_API_URL')}/api/op_list_export_tokens/preview/${
                                    token.token
                                }`}
                                target="_blank"
                                data-uk-tooltip={t('changelist.export.export.view')}
                            >
                                <span className="mr-1">
                                    {token.type === 'pdf' && 'Pdf'}
                                    {token.type === 'excel' && 'Excel'}
                                </span>
                                <BoxArrowUpRight className="flex-shrink-0" />
                            </a>
                        ))}
                </div>
            </Col>
        </Row>
    );
}

function ExportActions({ setShowExportModal }) {
    return (
        <div className="d-flex mb-2">
            <Action tooltip="Excel export maken" handleClick={() => setShowExportModal('excel')}>
                <FileEarmarkExcel size={18} className="d-none d-xl-block" />
                <div>Excel</div>
            </Action>

            <Action tooltip="Pdf export maken" handleClick={() => setShowExportModal('pdf')}>
                <FileEarmarkPdf size={18} className="d-none d-xl-block" />
                <div>Pdf</div>
            </Action>
        </div>
    );
}

const checkPendingTokens = (tokens) => {
    const pendingStatusCodes = [
        Constants.opListExportTokenStatus.pending,
        Constants.opListExportTokenStatus.processing,
    ];

    return tokens.some((token) => {
        return pendingStatusCodes.includes(token.status);
    });
};

const statusTranslation = {
    [Constants.opListExportTokenStatus.processed]: 'changelist.export.export.status.clear',
    [Constants.opListExportTokenStatus.pending]: 'changelist.export.export.status.queued',
    [Constants.opListExportTokenStatus.processing]: 'changelist.export.export.status.processing',
    [Constants.opListExportTokenStatus.failed]: 'changelist.export.export.status.failed',
};
