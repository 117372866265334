import { useEffect, useState } from 'react';
import { Badge, Dropdown, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import {
    fetchDepartment,
    fetchOperationsLists,
    removeDepartment,
    selectDepartmentById,
    selectOpListById,
} from '../../../features/operationsList/operationsListSlice';
import { useDispatch, useSelector } from 'react-redux';
import HelperFunctions from '../../global/HelperFunctions';
import classNames from 'classnames';
import { ThreeDotsVertical } from 'react-bootstrap-icons';
import EditDepartmentModal from './EditDepartmentModal';
import { LabelsAsBadges } from '../team/OpListUsers';
import { useTranslation } from 'react-i18next';
import { useGetUserFullName } from '../../../hooks/useGetUserFullName';

function Departments({ showEditModal, setShowEditModal }) {
    const { id } = useParams();
    const dispatch = useDispatch();
    const opList = useSelector((state) => selectOpListById(state.operations, id));

    useEffect(() => {
        if (!opList) {
            dispatch(fetchOperationsLists());
        }
    }, [dispatch, opList]);

    return (
        <>
            {opList && <OpListDepartments opList={opList} setShowEditModal={setShowEditModal} />}

            {showEditModal !== false && (
                <EditDepartmentModal
                    handleClose={() => {
                        setShowEditModal(false);
                        dispatch(fetchOperationsLists());
                    }}
                    showModal={true}
                    opList={opList}
                    department={showEditModal}
                />
            )}
        </>
    );
}

function OpListDepartments(props) {
    const { opList } = props;
    const { t } = useTranslation('changelist');

    return (
        <div className="react-bootstrap-table">
            <Table hover>
                <thead>
                    <tr>
                        <th width="35%">{t('changelist.department.departments.name')}</th>
                        <th width="20%">{t('changelist.department.departments.admins')}</th>
                        <th width="20%">{t('changelist.department.departments.insuranceTypes')}</th>
                        <th width="20%">{t('changelist.department.departments.commentsLabels')}</th>
                        <th width="5%" />
                    </tr>
                </thead>
                <tbody>
                    {opList.departments.map((_departmentUri) => {
                        return <DepartmentRow {...props} uri={_departmentUri} key={_departmentUri} />;
                    })}
                </tbody>
            </Table>
        </div>
    );
}

function DepartmentRow({ uri, setShowEditModal }) {
    const [isHover, setHover] = useState(false);
    const department = useSelector((state) => selectDepartmentById(state.operations, uri));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchDepartment({ uri }));
    }, [dispatch]);

    if (!department || department.deletedAt) {
        return null;
    }

    return (
        <tr
            onClick={() => setShowEditModal(department)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            key={department.id}
        >
            <td>
                <div className="d-flex align-items-center">
                    <div className="bg-light font-weight-bold text-uppercase rounded-circle p-2 mr-1">
                        {department.code}
                    </div>
                    <div>{department.name}</div>
                </div>
            </td>
            <td>
                <DepartmentUsers department={department} />
            </td>
            <td>
                <LabelsAsBadges labelUris={department.labels ?? []} folders={true} />
            </td>
            <td>
                <LabelsAsBadges labelUris={department.commentLabels ?? []} />
            </td>
            <td>
                <Actions edit={setShowEditModal} row={department} isHover={isHover} />
            </td>
        </tr>
    );
}

function DepartmentUsers({ department }) {
    const { managers = [] } = department;

    return (
        <>
            {managers.map((userId) => (
                <DepartmentUser userId={userId} key={`dep-manager-${userId}`} />
            ))}
        </>
    );
}

function DepartmentUser({ userId }) {
    const fullName = useGetUserFullName(userId, false);

    return (
        <Badge variant="secondary" className="mr-1">
            {fullName}
        </Badge>
    );
}

const Actions = ({ row, isHover, edit }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('changelist');

    const confirmDelete = () => {
        HelperFunctions.confirmModal(
            t('changelist.department.departments.confirmDelete'),
            'danger',
            false,
            t('changelist.department.departments.btn.yesDelete'),
            t('btn.cancel')
        ).then(() => {
            dispatch(removeDepartment(row));
        });
    };

    return (
        <div
            className={classNames('d-flex flex-column align-items-end', { invisible: !isHover })}
            onClick={(e) => e.stopPropagation()}
        >
            <Dropdown>
                <Dropdown.Toggle bsPrefix="docrev-dropdown-toggle" as="span" id="dropdown-basic">
                    <ThreeDotsVertical />
                </Dropdown.Toggle>

                <Dropdown.Menu alignRight align="right">
                    <Dropdown.Item onClick={() => edit(row)}>
                        {t('changelist.department.departments.btn.edit')}
                    </Dropdown.Item>
                    <Dropdown.Item className="text-danger" onClick={confirmDelete}>
                        {t('changelist.department.departments.btn.delete')}
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default Departments;
