import { Route, Switch } from 'react-router-dom';
import { CreateAccount } from './CreateAccount';
import { Logo } from 'pages/global/Logo';
import { SignUp } from 'pages/welcome/SignUp';
import { useGetStripeProduct } from 'pages/welcome/hooks/useGetStripeProduct';
import { WELCOME_CREATE_ACCOUNT, WELCOME_SIGN_UP } from 'pages/welcome/config/Constants';

export function Welcome() {
    return (
        <DocRevPlaceHolder>
            <Switch>
                <Route path={WELCOME_SIGN_UP} exact strict render={() => <SignUp />} />
                <Route path={WELCOME_CREATE_ACCOUNT} exact strict render={() => <CreateAccount />} />
            </Switch>
        </DocRevPlaceHolder>
    );
}

function DocRevPlaceHolder({ children }) {
    const stripeProduct = useGetStripeProduct();

    return (
        <div className="docrev-setup-wrapper d-flex justify-content-center">
            <div className="docrev-setup-background" />
            <div className="d-flex justify-content-between" style={{ width: 920 }}>
                <div style={{ width: 380 }} className="pt-5">
                    <div>
                        <Logo />
                        {stripeProduct && (
                            <ProductInformation product={stripeProduct.product} price={stripeProduct.price} />
                        )}

                        <Switch>
                            <Route path={WELCOME_SIGN_UP} exact strict render={() => <NewsletterText />} />
                        </Switch>
                    </div>
                </div>

                <div style={{ width: 380 }} className="docrev-setup-modal-content pt-5">
                    <div className="pb-5">{children}</div>
                </div>
            </div>
        </div>
    );
}

function ProductInformation({ product, price }) {
    const formattedPrice = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR' }).format(
        price.unit_amount / 100,
    );

    return (
        <div>
            <div className="text-secondary mb-1">Try {product.name}, no obligations</div>
            <h2 className="mb-1">30 days free</h2>
            <div className="text-secondary mb-2">
                Then {formattedPrice} per {price.recurring.interval}. Additional VAT may apply.
            </div>
        </div>
    );
}

function NewsletterText() {
    return (
        <div className="pt-3 pb-2">
            <div className="text-secondary">
                <div className="font-weight-bold mb-1" style={{ color: '#1a1a1ae6' }}>
                    Stay Informed During Your Free Trial!
                </div>
                <div className="mb-1 extra-small">
                    As part of your 30-day free trial with IPIDcreator, you'll receive helpful emails with tips, tricks,
                    and important updates to help you make the most of our platform. These emails are designed to guide
                    you through the features of IPIDcreator and enhance your experience.
                </div>
                <div className="mb-1 extra-small">
                    You'll also receive reminders and recommendations to ensure a smooth transition if you choose to
                    upgrade to a paid subscription after your trial ends.
                </div>
                <div className="extra-small">
                    You can unsubscribe from these emails at any time by clicking the link in any email we send, but we
                    hope you'll find them useful!
                </div>
            </div>
        </div>
    );
}
