import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import getEnv from '../../config/Env';
import { baseQueryWithReauth, prepareHeaders } from '../api';

const aiApiUrl = getEnv('AI_API_BASE_URL');

const aiApiQuery = fetchBaseQuery({
    baseUrl: aiApiUrl,
    prepareHeaders,
});

interface ApiResponse {
    success: boolean;
    message: string;
}

export const aiApi = createApi({
    reducerPath: 'ai',
    baseQuery: (args, api, extraOptions) => baseQueryWithReauth(args, api, extraOptions, aiApiQuery),
    endpoints: (builder) => ({
        getAiSuggestion: builder.mutation<ApiResponse, string>({
            query: (body) => ({
                url: '/ask',
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const { useGetAiSuggestionMutation } = aiApi;
