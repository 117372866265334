import { TaskLabels } from 'pages/task_team/modals/view_task_modal/TaskLabels';
import { SecondaryButton } from 'components/Buttons';

export function EditTags({task, close}) {
    return (
        <div>
            <div className="border-bottom mb-3 pb-1">
                <div className="font-weight-bold">Tags</div>
            </div>

            <TaskLabels taskId={task.id}/>

            <SecondaryButton onClick={close}>
                Terug
            </SecondaryButton>
        </div>
    )
}
