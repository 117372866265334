import { useSelector } from 'react-redux';
import { useGetDocumentsQuery } from '../../features/documents/documents';

export default function RestrictedDocumentContent({ documentId, roles = [], children }) {
    const currentUserRole = useCurrentDocumentUserRole(parseInt(documentId));

    const hasPermissions = () => {
        return roles.includes(currentUserRole);
    };

    if (documentId === undefined || currentUserRole === undefined || !hasPermissions(roles)) {
        return null;
    }

    return <>{children}</>;
}

export function useCurrentDocumentUserRole(documentId) {
    const { activeOrganisation } = useSelector((state) => state.security);

    const { document } = useGetDocumentsQuery(
        {
            organisationId: activeOrganisation,
            deleted: false,
        },
        {
            selectFromResult: ({ data }) => ({
                document: data ? data.find((_document) => _document.id === documentId) : undefined,
            }),
        }
    );

    return document?.currentUserRole;
}
