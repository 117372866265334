import { taskVersionStatus } from 'pages/task_team/config/constants';
import { TaskVersionEvidence } from 'pages/task_team/modals/view_task_modal/TaskVersionEvidence';
import { TaskVersionFiles } from 'pages/task_team/modals/view_task_modal/TaskVersionFiles';

export function TaskFiles({ task, selectedVersion }) {
    if (!selectedVersion) {
        return null;
    }

    return (
        <div className="row">
            <div className="col">
                <TaskVersionFiles
                    versionUri={selectedVersion['@id']}
                    task={task}
                    canEdit={selectedVersion.status === taskVersionStatus.STATUS_DRAFT}
                />
            </div>
            <div className="col">
                <TaskVersionEvidence
                    versionUri={selectedVersion['@id']}
                    task={task}
                    canEdit={selectedVersion.status === taskVersionStatus.STATUS_DRAFT}
                />
            </div>
        </div>
    );
}
