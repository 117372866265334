import { useSidebarContext } from 'components/SettingsSidebar/hooks/useSidebarContext';
import { CheckBox, FormGroup } from 'components/SettingsSidebar/helpers/FormHelpers';

export function TeamUser() {
    const { entity } = useSidebarContext();
    const { team } = entity;

    return (
        <div className="sidebar-form">
            <FormGroup label="Indienen" help="Permissies voor het indienen van uitingen.">
                <CheckBox
                    name="settings.permissions.canCreateTask"
                    label="Mag indienen"
                    help="Mag een nieuwe uiting indienen"
                    props={{
                        disabled: team.permissions.canCreateTask === false,
                    }}
                />
                {/*<CheckBox*/}
                {/*    name="settings.permissions.canCreateFromExisting"*/}
                {/*    label="Mag van bestaand indienen"*/}
                {/*    help="Mag nieuwe uiting van bestaande indienen"*/}
                {/*    props={{*/}
                {/*        disabled: team.permissions.canCreateTask === false,*/}
                {/*    }}*/}
                {/*/>*/}
                <CheckBox
                    name="settings.permissions.canOverrideTaskStatus"
                    label="Afronden d.m.v. directiebesluit"
                    help="Mag een uiting afronden d.m.v. een directiebesluit."
                    props={{
                        disabled: team.permissions.canCreateTask === false,
                    }}
                />
            </FormGroup>

            <FormGroup label="Beoordelen" help="Permissies voor het beoordelen van uitingen.">
                <CheckBox
                    name="settings.permissions.canReviewTask"
                    label="Mag beoordelen"
                    help="Mag uitingen van het team beoordelen"
                    props={{
                        disabled: team.permissions.canReviewTask === false,
                    }}
                />
                <CheckBox
                    name="settings.permissions.canAssignReviewer"
                    label="Mag behandelaar toewijzen"
                    help="Mag een (andere) behandelaar aan een uiting toewijzen"
                    props={{
                        disabled: team.permissions.canReviewTask === false,
                    }}
                />
            </FormGroup>

            <FormGroup
                label="Algemene permissies"
                help="Permissies die voor elke gebruiker van dit team van toepassing zijn."
            >
                {/*<CheckBox*/}
                {/*    name="settings.permissions.canViewAllFromTeam"*/}
                {/*    label="Mag van andere teamleden inzien"*/}
                {/*    help="Mag alle uitingen van het team inzien"*/}
                {/*/>*/}
                <CheckBox
                    name="settings.permissions.canManageTeam"
                    label="Team beheren"
                    help="Mag de team instellingen aanpassen en gebruikers beheren"
                />
                <CheckBox
                    name="settings.permissions.canViewReports"
                    label="Rapportages bekijken"
                    help="Mag de rapportages van het team bekijken"
                />
                <CheckBox
                    name="settings.permissions.canChangeTaskOwner"
                    label="Eigenaar aanpassen"
                    help="Mag de eigenaar van een uiting aanpassen"
                />
                <CheckBox
                    name="settings.permissions.canViewConfidentialTasks"
                    label="Vertrouwelijke uitingen zien"
                    help="Mag uitingen die gemarkeerd zijn als vertrouwelijk zien"
                />
            </FormGroup>
        </div>
    );
}
