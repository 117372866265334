import { Button, Col, Container, Row } from 'react-bootstrap';
import { PlusCircleFill } from 'react-bootstrap-icons';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { COLLECTIVES_EDIT_PATH } from '../../../../scenes/Reimbursements';
import {
    reimbursementApi,
    useGetCollectiveGroupsQuery,
    useGetCollectivesQuery,
    useUpdateBatchReimbursementsMutation,
} from '../../../../features/reimbursements/reimbursements';
import HelperFunctions from '../../../global/HelperFunctions';
import cx from 'classnames';
import { Collective } from './Collective';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const emptyArray = [];

export default function Collectives({ categoryUri }) {
    const { folderId } = useParams();
    const [updateBatchReimbursements] = useUpdateBatchReimbursementsMutation();
    const dispatch = useDispatch();

    const { collectiveGroups } = useGetCollectiveGroupsQuery(categoryUri, {
        selectFromResult: ({ data }) => ({
            collectiveGroups: data
                ? data
                      .filter((_item) => {
                          if (folderId === undefined) {
                              return _item.parent === null;
                          }

                          return _item.id === parseInt(folderId);
                      })
                      .sort(HelperFunctions.dynamicSort('sortOrder'))
                : emptyArray,
        }),
    });

    const [getCollectives] = reimbursementApi.useLazyGetCollectivesQuery();

    const onDragEnd = (result) => {
        const { destination, source } = result;

        if (!destination) {
            // dropped outside the list
            return;
        }

        const sourceGroup = source.droppableId;
        const destinationGroup = destination.droppableId;

        // Sort within group
        if (sourceGroup === destinationGroup) {
            if (source.index === destination.index) {
                // Sort order not changed
                return;
            }

            getCollectives({ uri: categoryUri }, true).then(({ data }) => {
                const oldCollectives = data
                    .filter((collective) => collective.collectiveGroup === sourceGroup)
                    .sort(HelperFunctions.dynamicSort('sortOrder'));

                const newCollectives = HelperFunctions.arrayMove(oldCollectives, source.index, destination.index).map(
                    (_collective, index) => {
                        // Reset sort order
                        return {
                            ..._collective,
                            sortOrder: index,
                        };
                    }
                );

                updateBatch(newCollectives);
            });

            return;
        }

        // Moved into new group
        getCollectives({ uri: categoryUri }, true).then(({ data }) => {
            // Remove from old group
            const newCollectives1 = data
                .filter((collective) => collective.collectiveGroup === sourceGroup)
                .sort(HelperFunctions.dynamicSort('sortOrder'));
            const [removed] = newCollectives1.splice(source.index, 1);

            updateBatch(newCollectives1);

            // Add to new group
            const newCollective = {
                ...removed,
                collectiveGroup: destinationGroup,
            };

            const oldCollectives2 = data
                .filter((collective) => collective.collectiveGroup === destinationGroup)
                .sort(HelperFunctions.dynamicSort('sortOrder'));
            oldCollectives2.splice(destination.index, 0, newCollective);

            const newCollectives2 = oldCollectives2.map((_collective, index) => {
                // Reset sort order
                return {
                    ..._collective,
                    sortOrder: index,
                };
            });

            updateBatch(newCollectives2).then(() => {
                dispatch(
                    reimbursementApi.util.invalidateTags([
                        {
                            type: 'Collective',
                            id: 'LIST',
                        },
                        { type: 'CollectiveGroup', id: 'LIST' },
                    ])
                );
            });
        });
    };

    const updateBatch = (newCollectives) => {
        return updateBatchReimbursements({
            uri: categoryUri,
            collectives: newCollectives.map((_collective) => {
                return {
                    idAsInt: _collective.id,
                    id: _collective['@id'],
                    changes: {
                        sortOrder: _collective.sortOrder,
                        collectiveGroup: _collective.collectiveGroup,
                    },
                };
            }),
        });
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <div className="data-table data-table-propositions mb-4">
                {collectiveGroups.map((_group) => (
                    <CollectiveGroup group={_group} key={`list-group-${_group.id}`} />
                ))}
            </div>
        </DragDropContext>
    );
}

function CollectiveGroup({ group, level = 0 }) {
    const { folderId } = useParams();
    const { name, parent } = group;
    const { childGroups } = useGetCollectiveGroupsQuery(group.category, {
        selectFromResult: ({ data }) => ({
            childGroups: data
                ? data.filter((_item) => _item.parent === group['@id']).sort(HelperFunctions.dynamicSort('sortOrder'))
                : [],
        }),
        skip: parent !== null,
    });

    return (
        <Droppable droppableId={group['@id']} isDropDisabled={parent === null}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={cx('data-table-group data-table-group-droppable', 'data-table-group-level-' + level, {
                        'dragging-over': snapshot.isDraggingOver && group.parent !== null,
                        'dr-container mb-3': level === 1,
                    })}
                >
                    <div
                        className={cx('data-table-group-header d-flex align-items-center', {
                            'font-weight-normal mb-3': folderId !== undefined,
                        })}
                    >
                        <div>{name}</div>

                        {parent !== null && <AddButton folderId={group.id} />}
                    </div>

                    {childGroups.map((_childGroup) => (
                        <CollectiveGroup group={_childGroup} level={1} key={`list-group-${_childGroup.id}`} />
                    ))}

                    {parent !== null && (
                        <GroupCollectives
                            categoryUri={group.category}
                            collectiveGroupUri={group['@id']}
                            collectiveGroupId={group.id}
                            provided={provided}
                            level={level}
                        />
                    )}
                </div>
            )}
        </Droppable>
    );
}

function GroupCollectives({ categoryUri, collectiveGroupUri, collectiveGroupId, provided, level }) {
    const { categoryId } = useParams();
    const history = useHistory();
    const { t } = useTranslation('reimbursements');

    const { collectives, isUninitialized, isLoading } = useGetCollectivesQuery(
        { uri: categoryUri },
        {
            selectFromResult: ({ data, isUninitialized, isLoading }) => ({
                collectives: data
                    ? data
                          .filter((collective) => collective.collectiveGroup === collectiveGroupUri)
                          .sort(HelperFunctions.dynamicSort('sortOrder'))
                    : [],
                isUninitialized,
                isLoading,
            }),
        }
    );

    return (
        <div className={cx('mb-2', { 'dr-container': level === 0 })}>
            <GroupHeader />

            <div>
                {collectives.map((_item, index) => (
                    <Collective
                        categoryUri={categoryUri}
                        collectiveId={_item.id}
                        collectiveGroupId={collectiveGroupId}
                        index={index}
                        key={`collective-${_item.id}`}
                    />
                ))}

                {provided.placeholder}
            </div>

            {!isUninitialized && !isLoading && collectives.length === 0 && (
                <div className="text-center small text-secondary p-4" style={{ borderTop: '1px solid #f1f1f1' }}>
                    <p>{t('collectivity.noCollectivities')}</p>

                    <Button
                        variant="outline-primary"
                        onClick={() => {
                            history.push(
                                generatePath(COLLECTIVES_EDIT_PATH, {
                                    categoryId,
                                    action: 'properties',
                                    folderId: collectiveGroupId,
                                })
                            );
                        }}
                    >
                        {t('collectivity.btn.newCollectivity')}
                    </Button>
                </div>
            )}
        </div>
    );
}

function GroupHeader() {
    const { t } = useTranslation('reimbursements');

    return (
        <div className="small text-secondary py-3">
            <Container fluid>
                <Row>
                    <Col>
                        <div className="pl-2 ">{t('collectivity.name')}</div>
                    </Col>
                    <Col xs={2} />
                </Row>
            </Container>
        </div>
    );
}

function AddButton({ folderId }) {
    const { t } = useTranslation('reimbursements');
    const history = useHistory();
    const { categoryId } = useParams();

    return (
        <Button
            className="small ml-auto d-flex align-items-center mr-2 outline-small-button"
            size="sm"
            onClick={() => {
                history.push(
                    generatePath(COLLECTIVES_EDIT_PATH, {
                        categoryId,
                        action: 'properties',
                        folderId,
                    })
                );
            }}
        >
            <PlusCircleFill className="mr-1" />
            {t('collectivity.btn.collectivity')}
        </Button>
    );
}
