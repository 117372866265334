import getEnv from '../config/Env';
import baseAPI from './ApiConnector_v2';

const baseUrl = getEnv('CLONE_API_BASE_URL');
const apiUrl = baseUrl + '/api';

const CloneApi = {
    createCloneTask(entity, entityId, targetEntityId = null) {
        return baseAPI.post(apiUrl + `/${entity}/clone`, {
            entityId,
            targetEntityId,
        });
    },
    createBatchCloneTask(entity, formData) {
        return baseAPI.post(apiUrl + `/${entity}/clone`, formData);
    },
    cloneEntity(entity, entityId) {
        return baseAPI.post(apiUrl + `/${entity}/${entityId}/clone`);
    },
};

export default CloneApi;
