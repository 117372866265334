import { useParams } from 'react-router-dom';
import MainContentNav from '../../Navbar';
import { Start } from './ipid_import/Start';
import { createContext, useCallback, useState } from 'react';
import { Card } from './ipid_import/Card';
import { produce } from 'immer';
import { Preview } from './ipid_import/Preview';
import _ from 'lodash';
import { createAreaBlocks, filterBlocks, getChoiceVariantIds } from './ipid_import/Helpers';

export const ImportContext = createContext(null);

export function IpidImport() {
    const [formData, setFormData] = useState({
        documentTemplateId: undefined,
        documentTemplateSource: undefined,
        importedVariants: [],
        variants: [],
        labels: [],
        labelEntities: [],
        document: undefined,
        areas: [],
    });

    const updateFormData = useCallback((newFormData) => {
        setFormData(newFormData);
    }, []);

    const addAreaBlocks = useCallback((areaKey, blocks) => {
        setFormData(
            produce((draft) => {
                const index = draft.areas.findIndex((area) => area.key === areaKey);
                draft.areas[index].blocks.push(...blocks);
            })
        );
    }, []);

    const addArea = useCallback((area) => {
        setFormData(
            produce((draft) => {
                draft.areas.push(area);
            })
        );
    }, []);

    const updateAreaBlockContent = useCallback((areaKey, blockId, baseContent) => {
        setFormData(
            produce((draft) => {
                const areaIndex = draft.areas.findIndex((area) => area.key === areaKey);

                if (areaIndex !== -1) {
                    const index = draft.areas[areaIndex].blocks.findIndex((block) => block.key === blockId);
                    if (index !== -1) draft.areas[areaIndex].blocks[index].baseContent = baseContent;
                }
            })
        );
    }, []);

    const toggleBlockVariant = useCallback((variantId, areaId, blockId) => {
        setFormData(
            produce((draft) => {
                const areaIndex = draft.areas.findIndex((area) => area.key === areaId);

                if (areaIndex !== -1) {
                    const area = draft.areas[areaIndex];
                    const index = area.blocks.findIndex((block) => block.key === blockId);

                    if (index !== -1) {
                        const block = draft.areas[areaIndex].blocks[index];
                        const checked = block.variantIds.includes(variantId);

                        area.blocks.forEach((_block, _index) => {
                            if (_index === index) {
                                // current block, toggle the variant
                                draft.areas[areaIndex].blocks[_index].variantIds = _.xor(_block.variantIds, [
                                    variantId,
                                ]);
                            } else {
                                // if checking a variant, make sure it's not checked in other blocks
                                if (!checked) {
                                    draft.areas[areaIndex].blocks[_index].variantIds = _block.variantIds.filter(
                                        (_variantId) => _variantId !== variantId
                                    );
                                }
                            }
                        });
                    }
                }
            })
        );
    }, []);

    const toggleAreaChoiceVariant = useCallback((variantId, areaId) => {
        setFormData(
            produce((draft) => {
                const areaIndex = draft.areas.findIndex((area) => area.key === areaId);

                if (areaIndex !== -1) {
                    const area = draft.areas[areaIndex];

                    // toggle the variant
                    draft.areas[areaIndex].choiceVariantIds = _.xor(area.choiceVariantIds, [variantId]);
                }
            })
        );
    }, []);

    const editBlockTitle = useCallback((variantIndex, blockId, newTitle) => {
        setFormData(
            produce((draft) => {
                const variant = draft.variants[variantIndex];
                const blockIndex = variant.blockData.findIndex((block) => block.id === blockId);

                if (blockIndex !== -1) {
                    draft.variants[variantIndex].blockData[blockIndex].title = newTitle;
                }
            })
        );
    }, []);

    const findUnmatchedBlockArea = useCallback((block, variantId) => {
        setFormData(
            produce((draft) => {
                const blockData = [
                    {
                        ...block,
                        variantId,
                    },
                ];

                const exludedCategories = [1, 8, 9, 10];

                const areaToInsertBlockIndex = draft.areas.findIndex((area) => {
                    if (
                        area.freeForm ||
                        exludedCategories.includes(area.categoryId) ||
                        block.categoryId !== area.categoryId
                    ) {
                        return false;
                    }

                    const resultBlocks = filterBlocks(blockData, area);

                    return resultBlocks.length > 0;
                });

                if (areaToInsertBlockIndex !== -1) {
                    const blocksToInsert = createAreaBlocks(draft.areas[areaToInsertBlockIndex], blockData);

                    draft.areas[areaToInsertBlockIndex].blocks = [
                        ...draft.areas[areaToInsertBlockIndex].blocks,
                        ...blocksToInsert,
                    ];
                }
            })
        );
    }, []);

    return (
        <>
            <MainContentNav title="IPID import" />

            <ImportContext.Provider
                value={{
                    formData,
                    setFormData: updateFormData,
                    addAreaBlocks,
                    updateAreaBlockContent,
                    addArea,
                    toggleBlockVariant,
                    toggleAreaChoiceVariant,
                    editBlockTitle,
                    findUnmatchedBlockArea,
                }}
            >
                <View />
            </ImportContext.Provider>
        </>
    );
}

const RenderViews = {
    start: Start,
    card: Card,
    preview: Preview,
};

function View() {
    const { view = 'start' } = useParams();
    const ViewToRender = RenderViews[view] ?? DefaultView;

    return <ViewToRender />;
}

function DefaultView() {
    return <div>no view</div>;
}
