import { useGetAreaHistoryQuery } from 'features/documents/documents';
import Constants from 'config/Constants';
import { useContext } from 'react';
import { AreaContext } from 'pages/documents_v2/views/Area';
import { useGetDocument } from 'pages/documents_v2/hooks/useGetDocument';

export function useGetAreaHistory(areaId) {
    const { activeVariantId } = useContext(AreaContext);
    const document = useGetDocument();

    const { history } = useGetAreaHistoryQuery(
        { areaId, variantId: activeVariantId },
        {
            selectFromResult: ({ data }) => ({
                history: data ? data.blockUpdateGroups : [],
            }),
            skip: !areaId || !document || document.status === Constants.documentStatus.draft,
        },
    );

    return history;
}
