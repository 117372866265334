import { Button } from 'react-bootstrap';
import { set } from '../../features/app/appSlice';
import { useAppDispatch } from '../../store';
import RestrictedContent from '../../pages/global/RestrictedContent';
import Constants from '../../config/Constants';
import { entityTypes } from '../../pages/translation/config/Constants';
import { idToUri } from '../../pages/global/UriHelper';
import { CommentButton } from './views/comments/CommentButton';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { UiChecks } from 'react-bootstrap-icons';

export interface SettingsSidebarContext {
    entity: any;
    entityType: string;
    documentId: number;
    uri: string;
    parentUri?: string;
}

export default function SettingsSidebarButton({
    context,
    size = 20,
}: {
    context: SettingsSidebarContext;
    size?: number;
}) {
    const { sidebarFilters } = useSelector((state: any) => state.documents);
    const { showComments } = sidebarFilters;
    const { t } = useTranslation('documents');
    const dispatch = useAppDispatch();

    const handleMetaClick = (landingTab = 'settings') => {
        dispatch(
            set({
                key: 'sidebarContext',
                value: {
                    type: 'sidebarSettings',
                    context: {
                        ...context,
                        landingTab,
                    },
                },
            })
        );
    };

    const showCommentsButton = context.entityType === entityTypes.AREA || context.entityType === entityTypes.BLOCK;

    return (
        <>
            <div className="position-relative d-flex">
                <Button
                    variant="icon"
                    className="flex-shrink-0 btn-icon-only btn-settings uk-text-primary"
                    onClick={(event) => {
                        event.stopPropagation();
                        handleMetaClick();
                    }}
                    data-uk-tooltip={t('document.navbar.main.settingsSidebar.tooltip')}
                >
                    <UiChecks size={size} />
                </Button>

                {showCommentsButton && showComments && (
                    <RestrictedContent module={Constants.modules.comments}>
                        <CommentButton
                            uri={idToUri(context.entity.id, context.entityType)}
                            parentUri={idToUri(context.documentId, entityTypes.DOCUMENT)}
                            handleMetaClick={handleMetaClick}
                            entity={context.entity}
                        />
                    </RestrictedContent>
                )}
            </div>
        </>
    );
}
