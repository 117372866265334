import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import {
    opListExportTokenEntity,
    publicationEntity,
    publicationGroupEntity,
    publicationPreviewTokenEntity,
    publicationVersionEntity,
} from '../../schemas';
import PublicationApi from '../../api/PublicationApi';
import HelperFunctions from '../../pages/global/HelperFunctions';
import CloneApi from '../../api/CloneApi';

const PublicationAdapter = createEntityAdapter();
const PublicationVersionAdapter = createEntityAdapter({
    selectId: (entity) => entity['@id'],
});
const PublicationPreviewTokenAdapter = createEntityAdapter({
    selectId: (entity) => entity['@id'],
});
const PublicationGroupAdapter = createEntityAdapter({
    sortComparer: (a, b) => a.name.localeCompare(b.name),
});
const OpListExportTokenAdapter = createEntityAdapter();

export const fetchPublications = createAsyncThunk('publications/fetchPublications', async (folderId = undefined) => {
    const results = await PublicationApi.getPublications(folderId);
    const normalized = normalize(results['data']['hydra:member'], [publicationEntity]);

    return normalized.entities;
});

export const fetchPublicationPreviewTokens = createAsyncThunk(
    'publications/fetchPublicationPreviewTokens',
    async ({ id }) => {
        const results = await PublicationApi.getPublicationPreviewTokens(id);
        const normalized = normalize(results['data']['hydra:member'], [publicationPreviewTokenEntity]);

        return normalized.entities;
    }
);

export const fetchOpListExportTokens = createAsyncThunk(
    'publications/fetchOpListExportTokens',
    async ({ departmentId }) => {
        const results = await PublicationApi.getOpListExportTokens(departmentId);
        const normalized = normalize(results['data']['hydra:member'], [opListExportTokenEntity]);

        return normalized.entities;
    }
);

export const fetchPublicationGroups = createAsyncThunk('publications/fetchPublicationGroups', async () => {
    const results = await PublicationApi.getPublicationGroups();
    const normalized = normalize(results['data']['hydra:member'], [publicationGroupEntity]);

    return normalized.entities;
});

export const fetchPublication = createAsyncThunk('publications/fetchPublication', async ({ id }) => {
    const results = await PublicationApi.getPublication(id);
    const normalized = normalize(results.data, publicationEntity);

    return normalized.entities;
});

export const patchPublication = createAsyncThunk('publications/patchPublication', async ({ uri, formData }) => {
    const results = await PublicationApi.patch(uri, formData);
    const normalized = normalize(results.data, publicationEntity);

    return normalized.entities;
});

export const createPublication = createAsyncThunk('publications/createPublication', async ({ formData }) => {
    const results = await PublicationApi.createPublication(formData);
    const normalized = normalize(results.data, publicationEntity);

    return normalized.entities;
});

export const createPublicationPreviewToken = createAsyncThunk(
    'publications/createPublicationPreviewToken',
    async ({ id, formData }) => {
        const { data } = await PublicationApi.createPreviewToken(formData);
        const normalized = normalize(data, publicationPreviewTokenEntity);

        return normalized.entities;
    }
);

export const createOpListExportToken = createAsyncThunk(
    'publications/createOpListExportToken',
    async ({ formData }) => {
        const { data } = await PublicationApi.createOpListExportToken(formData);
        const normalized = normalize(data, opListExportTokenEntity);

        return normalized.entities;
    }
);

export const publishPublication = createAsyncThunk('publications/publishPublication', async ({ id, formData }) => {
    const { data } = await PublicationApi.publish(formData);
    const normalized = normalize(data, publicationEntity);

    return normalized.entities;
});

export const publishPublicationVersion = createAsyncThunk(
    'publications/publishPublicationVersion',
    async ({ id, formData }) => {
        const { data } = await PublicationApi.publish(formData);
        const normalized = normalize(data, publicationEntity);

        return normalized.entities;
    }
);

export const batchPublishPublications = createAsyncThunk(
    'publications/batchPublishPublications',
    async ({ formData }) => {
        const { data } = await PublicationApi.batchPublish(formData);
        const normalized = normalize(data.publicationEntities ?? [], [publicationEntity]);

        return normalized.entities;
    }
);

export const batchEditPublications = createAsyncThunk('publications/batchEditPublications', async ({ formData }) => {
    const { data } = await PublicationApi.batchEdit(formData);

    return data.publications;
});

export const batchEditPublicationGroups = createAsyncThunk(
    'publications/batchEditPublicationGroups',
    async ({ formData }) => {
        const { data } = await PublicationApi.batchEdit(formData);

        return data.publicationGroups;
    }
);

export const batchDuplicatePublications = createAsyncThunk(
    'publications/batchDuplicatePublications',
    async ({ formData }) => {
        const { data } = await CloneApi.createBatchCloneTask('publications', formData);

        return data;
    }
);

export const batchDeletePublications = createAsyncThunk(
    'publications/batchDeletePublications',
    async ({ formData }) => {
        const { data } = await PublicationApi.batchDelete(formData);

        return data;
    }
);

export const deletePublicationVersion = createAsyncThunk('publications/deletePublicationVersion', async ({ uri }) => {
    await PublicationApi.delete(uri);

    return uri;
});

export const addPublicationGroup = createAsyncThunk(
    'publications/addPublicationGroup',
    async ({ organisationId, formData }) => {
        const results = await PublicationApi.createPublicationGroup(formData);
        const normalized = normalize(results.data, publicationGroupEntity);

        return normalized.entities;
    }
);

export const patchPublicationGroup = createAsyncThunk(
    'publications/patchPublicationGroup',
    async ({ uri, formData }) => {
        const results = await PublicationApi.patch(uri, formData);
        const normalized = normalize(results.data, publicationGroupEntity);

        return normalized.entities;
    }
);

export const deletePublicationGroup = createAsyncThunk('publications/deletePublicationGroup', async ({ id, uri }) => {
    await PublicationApi.delete(uri);

    return id;
});

export const duplicatePublicationGroup = createAsyncThunk(
    'publications/duplicatePublicationGroup',
    async ({ id, formData }) => {
        const results = await CloneApi.createBatchCloneTask('publication_groups', formData);
        const normalized = normalize(results.data, publicationGroupEntity);

        return normalized.entities;
    }
);

const publicationsSlice = createSlice({
    name: 'publications',
    initialState: {
        publications: PublicationAdapter.getInitialState(),
        publicationGroups: PublicationGroupAdapter.getInitialState(),
        publicationVersions: PublicationGroupAdapter.getInitialState(),
        publicationPreviewTokens: PublicationPreviewTokenAdapter.getInitialState(),
        opListExportTokens: OpListExportTokenAdapter.getInitialState(),
        filters: {
            documentId: '',
            search: '',
        },
        expandedPublicationId: '',
        selectedRows: [],
    },
    reducers: {
        setFilter: {
            reducer(state, action) {
                const { key, value } = action.payload;
                state.filters[key] = value;
            },
        },
        setSelectedRows: {
            reducer(state, action) {
                state.selectedRows = action.payload;
            },
        },
        toggleRow: {
            reducer(state, action) {
                const id = action.payload;
                const { selectedRows } = state;

                state.selectedRows = selectedRows.includes(id)
                    ? selectedRows.filter((i) => i !== id)
                    : [...selectedRows, id];
            },
        },
        editPublications: {
            reducer(state, { payload }) {
                PublicationAdapter.updateMany(state.publications, payload);
            },
        },
        editPublicationGroups: {
            reducer(state, { payload }) {
                PublicationGroupAdapter.updateMany(state.publicationGroups, payload);
            },
        },
    },
    extraReducers: {
        ['security/resetState']: (state) => {
            Object.assign(state, publicationsSlice.getInitialState());
        },
        [fetchPublications.fulfilled]: (state, action) => {
            PublicationAdapter.upsertMany(state.publications, action.payload.publications ?? []);
        },
        [deletePublicationVersion.fulfilled]: (state, action) => {
            PublicationVersionAdapter.removeOne(state.publicationVersions, action.payload);
        },
        [publishPublication.fulfilled]: (state, action) => {
            PublicationAdapter.upsertMany(state.publications, action.payload.publications ?? []);
        },
        [publishPublicationVersion.fulfilled]: (state, action) => {
            PublicationAdapter.upsertMany(state.publications, action.payload.publications ?? []);
        },
        [batchPublishPublications.fulfilled]: (state, action) => {
            PublicationAdapter.upsertMany(state.publications, action.payload.publications ?? []);
        },
        [batchEditPublications.fulfilled]: (state, action) => {
            PublicationAdapter.updateMany(state.publications, action.payload ?? []);
        },
        [batchEditPublicationGroups.fulfilled]: (state, action) => {
            PublicationGroupAdapter.updateMany(state.publicationGroups, action.payload ?? []);
        },
        [batchDeletePublications.fulfilled]: (state, action) => {
            PublicationAdapter.removeMany(state.publications, action.payload.publications);
        },
        [batchDuplicatePublications.fulfilled]: (state, action) => {
            const { newPublications, publicationGroups } = action.payload;

            const normalizedPublications = normalize(newPublications ?? [], [publicationEntity]);
            const normalizedGroups = normalize(publicationGroups ?? [], [publicationGroupEntity]);

            PublicationAdapter.upsertMany(state.publications, normalizedPublications.entities.publications ?? []);
            PublicationGroupAdapter.upsertMany(
                state.publicationGroups,
                normalizedGroups.entities.publicationGroups ?? []
            );
        },
        [fetchPublicationPreviewTokens.fulfilled]: (state, action) => {
            PublicationPreviewTokenAdapter.upsertMany(
                state.publicationPreviewTokens,
                action.payload.publicationPreviewTokens ?? []
            );
        },
        [fetchOpListExportTokens.fulfilled]: (state, action) => {
            OpListExportTokenAdapter.upsertMany(state.opListExportTokens, action.payload.opListExportTokens ?? []);
        },
        [fetchPublicationGroups.fulfilled]: (state, action) => {
            PublicationGroupAdapter.upsertMany(state.publicationGroups, action.payload.publicationGroups ?? []);
        },
        [addPublicationGroup.fulfilled]: (state, action) => {
            PublicationGroupAdapter.upsertMany(state.publicationGroups, action.payload.publicationGroups ?? []);
        },
        [patchPublicationGroup.fulfilled]: (state, action) => {
            PublicationGroupAdapter.upsertMany(state.publicationGroups, action.payload.publicationGroups ?? []);
        },
        [duplicatePublicationGroup.fulfilled]: (state, action) => {
            PublicationGroupAdapter.upsertMany(state.publicationGroups, action.payload.publicationGroups ?? []);
        },
        [deletePublicationGroup.fulfilled]: (state, action) => {
            PublicationGroupAdapter.removeOne(state.publicationGroups, action.payload);
        },
        [fetchPublication.fulfilled]: (state, action) => {
            PublicationAdapter.upsertMany(state.publications, action.payload.publications ?? []);
        },
        [createPublicationPreviewToken.fulfilled]: (state, action) => {
            PublicationPreviewTokenAdapter.upsertMany(
                state.publicationPreviewTokens,
                action.payload.publicationPreviewTokens
            );
        },
        [createOpListExportToken.fulfilled]: (state, action) => {
            OpListExportTokenAdapter.upsertMany(state.opListExportTokens, action.payload.opListExportTokens);
        },
        [createPublication.fulfilled]: (state, action) => {
            PublicationAdapter.upsertMany(state.publications, action.payload.publications ?? []);
        },
        [patchPublication.fulfilled]: (state, action) => {
            PublicationAdapter.upsertMany(state.publications, action.payload.publications ?? []);
        },
    },
});
export const {
    setFilter,
    editPublications,
    editPublicationGroups,
    toggleRow,
    setSelectedRows,
} = publicationsSlice.actions;

export const { selectAll: selectAllPublications, selectById: selectPublicationById } = PublicationAdapter.getSelectors(
    (state) => state.publications
);

export const { selectAll: selectAllPublicationGroups, selectById: selectPublicationGroupById } =
    PublicationGroupAdapter.getSelectors((state) => state.publicationGroups);

export const publicationIsSelected = (id) =>
    createSelector([(state) => state.publications.selectedRows], (selectedRows) => {
        return selectedRows.includes(id);
    });

export const selectPublications = (groupId = undefined) =>
    createSelector(
        [
            (state) => state.security.activeOrganisation,
            (state) => selectAllPublications(state.publications),
            (state) => state.publications.filters,
        ],
        (activeOrganisation, allPublications, filters) => {
            const { documentId, search } = filters;

            let publications = allPublications
                .filter((_publication) => _publication.organisationId === activeOrganisation)
                .sort(HelperFunctions.dynamicSort('sortOrder'));

            // Undefined means we want all publications, null means only top-level
            if (groupId !== undefined) {
                publications = publications.filter((_publication) => _publication.publicationGroup === groupId);
            }

            if (documentId !== '') {
                publications = publications.filter((_publication) => _publication.documentIds.includes(documentId));
            }

            if (search !== '') {
                publications = publications.filter((_publication) => {
                    const searchValueClean = search.trim().toLowerCase();

                    if (_publication.prefix && _publication.prefix.toLowerCase().includes(searchValueClean)) {
                        return true;
                    }

                    return _publication.name.toLowerCase().includes(searchValueClean);
                });
            }

            return publications;
        }
    );

export const selectPublicationGroups = (parentGroup = undefined) =>
    createSelector(
        [(state) => state.security, (state) => selectAllPublicationGroups(state.publications)],
        ({ activeOrganisation }, allPublicationGroups) => {
            const publicationGroups = allPublicationGroups
                .filter((_group) => _group.organisationId === activeOrganisation)
                .filter((_group) => _group.rootLevel === false)
                .sort(HelperFunctions.dynamicSort('sortOrder'));

            if (parentGroup !== undefined) {
                return publicationGroups.filter((_group) => _group.parent === parentGroup);
            }

            return publicationGroups;
        }
    );

export const selectOrganisationPublicationGroups = () =>
    createSelector(
        [(state) => state.security, (state) => selectAllPublicationGroups(state.publications)],
        ({ activeOrganisation }, allPublicationGroups) => {
            return allPublicationGroups
                .filter((_group) => _group.organisationId === activeOrganisation)
                .sort(HelperFunctions.dynamicSort('sortOrder'));
        }
    );

export const selectRootLevelPublicationGroups = () =>
    createSelector(
        [(state) => state.security, (state) => selectAllPublicationGroups(state.publications)],
        ({ activeOrganisation }, allPublicationGroups) => {
            const defaultGroup = [
                {
                    id: null,
                    '@id': null,
                    name: 'Mappen',
                    sortOrder: 0,
                    organisationId: activeOrganisation,
                    rootLevel: true,
                },
            ];

            return defaultGroup
                .concat(allPublicationGroups)
                .filter((_group) => _group.organisationId === activeOrganisation)
                .filter((_group) => _group.rootLevel)
                .sort(HelperFunctions.dynamicSort('sortOrder'));
        }
    );

export default publicationsSlice.reducer;

export const { selectAll: selectAllPublicationVersions } = PublicationVersionAdapter.getSelectors(
    (state) => state.publicationVersions
);

export const selectVersionsByPublication = createSelector(
    [(state) => selectAllPublicationVersions(state), (state, publication) => publication],
    (entities, publication) => {
        return entities.filter((_entity) => _entity.publication === publication).reverse();
    }
);

const { selectAll: selectAllPublicationPreviewTokens } = PublicationPreviewTokenAdapter.getSelectors(
    (state) => state.publicationPreviewTokens
);

export const selectPreviewTokensByPublication = createSelector(
    [(state) => selectAllPublicationPreviewTokens(state), (state, publication) => publication],
    (entities, publication) => {
        return entities
            .filter(
                (_entity) => _entity.status !== null && _entity.publication === publication && _entity.expired === false
            )
            .reverse();
    }
);

export const selectOpListExportTokensByDepartment = (departmentId) =>
    createSelector(
        [
            (state) =>
                state.publications.opListExportTokens.ids.map(
                    (id) => state.publications.opListExportTokens.entities[id]
                ), // this is the same as selectAllDepartments
        ],
        (entities) => {
            return entities
                .filter(
                    (_entity) =>
                        _entity.opDepartmentId !== null &&
                        _entity.opDepartmentId === departmentId &&
                        _entity.expired === false
                )
                .reverse();
        }
    );
