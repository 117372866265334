import { FilterSelect } from 'pages/task_team/helpers/FilterHelper';
import { useGetTaskStatusOptions } from 'pages/task_team/config/useGetTaskStatusOptions';
import { yesNoOptions } from 'pages/task_team/helpers/FormHelper';

export const defaultFilterAllOptions = {
    archived: '0',
};

export function FilterMy() {
    const taskStatusOptions = useGetTaskStatusOptions(true);

    return (
        <>
            <FilterSelect placeholder="Status" label="Status..." options={taskStatusOptions} name="status" />

            <FilterSelect
                defaultFilterOptions={defaultFilterAllOptions}
                placeholder="Gearchiveerd..."
                label="Gearchiveerd"
                options={yesNoOptions}
                name="archived"
                isClearable={false}
            />
        </>
    );
}
