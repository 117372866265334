import ApiConnector from './ApiConnector';
import Constants from '../config/Constants';
import getEnv from '../config/Env';
import _ from 'lodash';
import baseAPI from './ApiConnector_v2';

const apiBaseUrl = getEnv('OPERATIONS_API_BASE_URL');
const apiUrl = getEnv('OPERATIONS_API_URL');

const OperationsListApi = {
    addDepartment(formData) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiPost(apiUrl + '/op_departments', formData)
                .then((result) => resolve(result))
                .catch(({ response }) => {
                    const context = response;
                    getEnv('APP_ENV') === Constants.environments.dev &&
                        console.log('unable to create department', context);
                    reject(context);
                });
        });
    },
    addTask(formData) {
        return ApiConnector.apiPost(apiUrl + '/tasks', formData).catch(
            ApiConnector.handleApiError('unable to create task')
        );
    },
    addField(formData) {
        return ApiConnector.apiPost(apiUrl + '/task_fields', formData).catch(
            ApiConnector.handleApiError('unable to create task_field')
        );
    },
    addOpList(formData) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiPost(apiUrl + '/op_lists', formData)
                .then((result) => resolve(result))
                .catch(({ response }) => {
                    const context = response;
                    getEnv('APP_ENV') === Constants.environments.dev && console.log('unable to create OpList', context);
                    reject(context);
                });
        });
    },
    addOpEntryDepartmentStatus(formData) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiPost(apiUrl + '/op_entry_department_statuses', formData)
                .then((result) => resolve(result))
                .catch(({ response }) => {
                    const context = response;
                    getEnv('APP_ENV') === Constants.environments.dev && console.log('unable to create status', context);
                    reject(context);
                });
        });
    },
    addOpEntry(formData) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiPost(apiUrl + '/op_entries', formData)
                .then((result) => resolve(result))
                .catch(({ response }) => {
                    const context = response;
                    getEnv('APP_ENV') === Constants.environments.dev &&
                        console.log('unable to create OpEntry', context);

                    // Format NoEntryLabels error to redux SerialzedError format so it can be handled
                    if (context.data['hydra:description'] === 'OpEntry-NoOpEntryLabels') {
                        reject({
                            name: 'OpEntry-NoOpEntryLabels',
                        });
                    }

                    reject(context);
                });
        });
    },
    addOpEntryLabel(formData) {
        return ApiConnector.apiPost(apiUrl + '/op_entry_labels', formData).catch(
            ApiConnector.handleApiError('unable to create OpEntryLabel')
        );
    },
    addOpTeam(formData) {
        return ApiConnector.apiPost(apiUrl + '/op_teams', formData).catch(
            ApiConnector.handleApiError('unable to create OpTeam')
        );
    },
    addUser(formData) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiPost(apiUrl + '/op_list_users', formData)
                .then((result) => resolve(result))
                .catch(({ response }) => {
                    const context = response;
                    getEnv('APP_ENV') === Constants.environments.dev && console.log('unable to create user', context);
                    reject(context);
                });
        });
    },
    cancelOpEntry(id, formData) {
        return ApiConnector.apiPatch(`${apiUrl}/op_entries/${id}/cancel`, formData).catch(
            ApiConnector.handleApiError('unable to cancel/uncancel opEntry')
        );
    },
    getDepartments() {
        return new Promise((resolve, reject) => {
            ApiConnector.apiGet(`${apiUrl}/op_departments`)
                .then((result) => resolve(result))
                .catch(({ response }) => {
                    const context = response;
                    getEnv('APP_ENV') === Constants.environments.dev &&
                        console.log('unable to load departments', context);
                    reject(context);
                });
        });
    },
    getDepartmentTasks(opListId, opDepartmentId) {
        return ApiConnector.apiGet(`${apiUrl}/op_lists/${opListId}/departments/${opDepartmentId}/tasks`).catch(
            ApiConnector.handleApiError('unable to load tasks for department')
        );
    },
    getTasks() {
        return ApiConnector.apiGet(`${apiUrl}/tasks`).catch(ApiConnector.handleApiError('unable to load tasks'));
    },
    getOperationsLists() {
        return new Promise((resolve, reject) => {
            ApiConnector.apiGet(`${apiUrl}/op_lists`)
                .then((result) => resolve(result))
                .catch(({ response }) => {
                    const context = response;
                    getEnv('APP_ENV') === Constants.environments.dev && console.log('unable to load oplists', context);
                    reject(context);
                });
        });
    },
    getOpListOpEntryLabels(id) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiGet(`${apiUrl}/op_lists/${id}/op_entry_labels`)
                .then((result) => resolve(result))
                .catch(({ response }) => {
                    const context = response;
                    getEnv('APP_ENV') === Constants.environments.dev && console.log('unable to load entries', context);
                    reject(context);
                });
        });
    },
    getOpEntryLabelsForOpEntry(opListId, opEntryId) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiGet(`${apiUrl}/op_lists/${opListId}/op_entries/${opEntryId}/op_entry_labels`)
                .then((result) => resolve(result))
                .catch(({ response }) => {
                    const context = response;
                    getEnv('APP_ENV') === Constants.environments.dev && console.log('unable to load entries', context);
                    reject(context);
                });
        });
    },
    getOpListBulkEntryLabels(id, filters = {}, page = 1, target = 'OpEntryLabel') {
        return baseAPI.post(`${apiUrl}/op_lists/${id}/bulk/op_entry_labels`, {
            filters,
            page,
            target,
        });
    },
    getOpTeamEntries(opTeamId) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiGet(`${apiUrl}/op_teams/${opTeamId}/op_team_entries`)
                .then((result) => resolve(result))
                .catch(({ response }) => {
                    const context = response;
                    getEnv('APP_ENV') === Constants.environments.dev && console.log('unable to load entries', context);
                    reject(context);
                });
        });
    },
    getOpListEntries(opListId, params) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiGet(`${apiUrl}/op_lists/${opListId}/op_entries`, undefined, { ...params })
                .then((result) => resolve(result))
                .catch(({ response }) => {
                    const context = response;
                    getEnv('APP_ENV') === Constants.environments.dev && console.log('unable to load entries', context);
                    reject(context);
                });
        });
    },
    getOpEntryActions(id) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiGet(`${apiUrl}/op_entries/${id}/op_entry_actions`)
                .then((result) => resolve(result))
                .catch(({ response }) => {
                    const context = response;
                    getEnv('APP_ENV') === Constants.environments.dev && console.log('unable to load variants', context);
                    reject(context);
                });
        });
    },
    getOpEntryDepartmentStatuses(id) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiGet(`${apiUrl}/op_entries/${id}/op_entry_department_statuses`)
                .then((result) => resolve(result))
                .catch(({ response }) => {
                    const context = response;
                    getEnv('APP_ENV') === Constants.environments.dev && console.log('unable to load variants', context);
                    reject(context);
                });
        });
    },
    getOpEntryVariants(id) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiGet(`${apiUrl}/op_entries/${id}/op_entry_variants`)
                .then((result) => resolve(result))
                .catch(({ response }) => {
                    const context = response;
                    getEnv('APP_ENV') === Constants.environments.dev && console.log('unable to load variants', context);
                    reject(context);
                });
        });
    },
    getOpEntryVariantBlocks(id) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiGet(`${apiUrl}/op_entry_variants/${id}/op_entry_blocks`)
                .then((result) => resolve(result))
                .catch(({ response }) => {
                    const context = response;
                    getEnv('APP_ENV') === Constants.environments.dev && console.log('unable to load blocks', context);
                    reject(context);
                });
        });
    },
    getOpEntryBlocksForOpEntry(opEntryId) {
        return ApiConnector.apiGet(`${apiUrl}/op_entries/${opEntryId}/op_entry_blocks`).catch(
            ApiConnector.handleApiError('unable to load blocks for opEntry')
        );
    },
    getOpEntryAreasForOpEntry(opEntryId) {
        return ApiConnector.apiGet(`${apiUrl}/op_entries/${opEntryId}/op_entry_areas`).catch(
            ApiConnector.handleApiError('unable to load opEntryAreas for opEntry')
        );
    },
    getOpListTeamsForOpList(opListId) {
        return ApiConnector.apiGet(`${apiUrl}/op_lists/${opListId}/op_teams`).catch(
            ApiConnector.handleApiError('unable to load teams for opList')
        );
    },
    getOpListTeamsForDepartment(departmentId) {
        return ApiConnector.apiGet(`${apiUrl}/op_departments/${departmentId}/op_teams`).catch(
            ApiConnector.handleApiError('unable to load teams for department')
        );
    },
    getOpListUsers(id) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiGet(`${apiUrl}/op_lists/${id}/op_list_users`)
                .then((result) => resolve(result))
                .catch(({ response }) => {
                    const context = response;
                    getEnv('APP_ENV') === Constants.environments.dev && console.log('unable to load users', context);
                    reject(context);
                });
        });
    },
    getOpListDepartments(id) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiGet(`${apiUrl}/op_lists/${id}/departments`)
                .then((result) => resolve(result))
                .catch(({ response }) => {
                    const context = response;
                    getEnv('APP_ENV') === Constants.environments.dev &&
                        console.log('unable to load departments', context);
                    reject(context);
                });
        });
    },
    getDepartmentReportData(departmentId, filters = {}) {
        return ApiConnector.apiGet(`${apiUrl}/op_departments/${departmentId}/reports_data`, undefined, {
            filters,
        }).catch(({ response }) => {
            const context = response;
            getEnv('APP_ENV') === Constants.environments.dev && console.log('unable to get report data', context);
            throw context;
        });
    },
    getEligibleDepartmentUsers(opListId, departmentId = null, allowManagers = null) {
        return Promise.resolve()
            .then(() => {
                const queryParams = {};

                if (departmentId) {
                    queryParams.departmentId = departmentId;
                }
                if (!_.isNil(allowManagers)) {
                    queryParams.allowManagers = allowManagers;
                }

                return ApiConnector.apiGet(
                    `${apiUrl}/op_lists/${opListId}/eligible_department_users`,
                    undefined,
                    queryParams
                );
            })
            .catch(ApiConnector.handleApiError('unable to retrieve eligible department users for opList'));
    },
    getOpListSummary(id, filters) {
        return baseAPI.post(`${apiUrl}/op_lists/${id}/summary`, { filters });
    },
    get(uri) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiGet(apiBaseUrl + uri)
                .then((result) => resolve(result))
                .catch(({ response }) => {
                    const context = response;
                    getEnv('APP_ENV') === Constants.environments.dev && console.log('unable to get item', context);
                    reject(context);
                });
        });
    },
    patch(uri, formData) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiPatch(apiBaseUrl + uri, formData)
                .then((result) => resolve(result))
                .catch(({ response }) => {
                    const context = response;
                    getEnv('APP_ENV') === Constants.environments.dev && console.log('unable to update entity', context);
                    reject(context);
                });
        });
    },
    remove(uri) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiDelete(apiBaseUrl + uri)
                .then((result) => resolve(result))
                .catch(({ response }) => {
                    const context = response;
                    getEnv('APP_ENV') === Constants.environments.dev && console.log('unable to remove entity', context);
                    reject(context);
                });
        });
    },
};

export default OperationsListApi;
