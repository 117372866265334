import { useGetCategoryOneLinersQuery } from 'features/metadata/metadata';
import { NumberParam, useQueryParam } from 'use-query-params';

const emptyArray = [];

export function useGetCategoryOneLiners(categoryUri) {
    const [page = 1] = useQueryParam('page', NumberParam);

    const params = {
        page,
        tags: true,
    };

    return useGetCategoryOneLinersQuery(
        { uri: categoryUri, params },
        {
            selectFromResult: (result) => {
                const { currentData, isUninitialized, isLoading, isFetching } = result;
                const data = currentData ? currentData['hydra:member'] : emptyArray;

                return {
                    ...result,
                    oneLiners: data,
                    totalItems: currentData ? currentData['hydra:totalItems'] : 0,
                    isEmpty: data.length === 0 && !isUninitialized && !isLoading && !isFetching,
                    isLoading: isUninitialized || (isFetching && data.length === 0),
                };
            },
            skip: !categoryUri,
        },
    );
}
