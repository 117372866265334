import { Check2Circle, ClockHistory, DashCircle, ExclamationCircle } from 'react-bootstrap-icons';
import Tooltip from '../../../global/Tooltip';
import { useGetTranslationsQuery } from '../../../../features/translations/translationApi';
import cx from 'classnames';
import { isEnabledForTranslation } from '../../helpers/TranslationKeyHelper';
import { useTranslation } from 'react-i18next';

export default function TranslationCompleteIcon({
    translationKey,
    languageIso,
    hasEntityReference = false,
    size = 18,
    className = '',
}) {
    const enabledForTranslation = isEnabledForTranslation(translationKey, languageIso);
    const { t } = useTranslation('translations');

    const { translation, isLoading } = useGetTranslationsQuery(translationKey['@id'], {
        selectFromResult: ({ data, isLoading }) => {
            return {
                translation: data?.find((_translation) => _translation.languageIso === languageIso),
                isLoading,
            };
        },
        skip: enabledForTranslation === false || hasEntityReference === false,
    });

    if (enabledForTranslation === false) {
        return (
            <Tooltip tooltip={t('translation.navbar.dashboard.document.status.excluded')}>
                <DashCircle size={size} className={cx('text-muted', className)} />
            </Tooltip>
        );
    }

    if (translation === undefined || isLoading) {
        return (
            <Tooltip tooltip={t('translation.navbar.dashboard.translation.noTranslation')}>
                <ExclamationCircle size={size} className={cx('text-danger', className)} />
            </Tooltip>
        );
    }

    const { unverified = true } = translation;

    if (unverified) {
        return (
            <Tooltip tooltip={t('translation.navbar.dashboard.constants.translationInProgress')}>
                <ClockHistory size={size} className={cx('text-warning', className)} />
            </Tooltip>
        );
    }

    return (
        <Tooltip tooltip={t('translation.navbar.dashboard.document.checkbox.completeTranslation')}>
            <Check2Circle size={size} className={cx('text-success', className)} />
        </Tooltip>
    );
}
