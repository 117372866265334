import HelperFunctions from '../../../../../global/HelperFunctions';
import { useGetLabelFolders } from '../../../../../labels/hooks/useGetLabelFolders';
import { DocRevSelect, InputMultipleSelect } from '../../../../../publications_v2/helpers/FieldHelper';
import Constants from '../../../../../../config/Constants';
import { useFormikContext } from 'formik';
import { idToUri } from '../../../../../global/UriHelper';
import { useEffect, useRef } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useGetLabels } from '../../../../../labels/hooks/useGetLabels';
import { useTranslation } from 'react-i18next';

export function LabelBlock({ block, index, readOnly = false, restrictions }) {
    const isReadOnly = readOnly === true || restrictions?.canChangeContent === false;
    const latestContent = HelperFunctions.tryParseJSON(block.latestContent, {
        labelFolder: '',
        options: [],
    });

    return (
        <div className="p-3">
            <LabelBlockContent block={block} latestContent={latestContent} index={index} readOnly={isReadOnly} />
        </div>
    );
}

function LabelBlockContent({ block, latestContent, index, readOnly }) {
    const { labelFolder, options } = latestContent;
    const { setFieldValue } = useFormikContext();
    const { t } = useTranslation('documents');

    const folders = useGetLabelFolders();

    // Filter folders
    const allowedTypes = Object.keys(Constants.labelFolderEntities).filter((_entity) => {
        return Constants.labelFolderEntities[_entity].includes('labelBlock');
    });

    const availableFolder = folders.filter((_folder) =>
        _folder.contentTypes.some((_type) => allowedTypes.includes(_type))
    );

    return (
        <div className="px-2 pt-3">
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4} htmlFor="labelFolder">
                    {t('document.navbar.main.editor.left.blocks.types.labelBlock.map')}
                </Form.Label>
                <Col sm={8}>
                    <DocRevSelect
                        name="labelFolder"
                        label={t('document.navbar.main.editor.left.blocks.types.labelBlock.map')}
                        props={{
                            isDisabled: readOnly,
                        }}
                        selectedValue={labelFolder}
                        options={HelperFunctions.prepareDropdownData(availableFolder)}
                        onChange={(selectedValue) => {
                            setFieldValue(
                                `areaBlocks.${index}.latestContent`,
                                JSON.stringify({
                                    ...latestContent,
                                    labelFolder: selectedValue.value,
                                })
                            );
                        }}
                    />
                </Col>
            </Form.Group>

            {labelFolder !== '' && (
                <LabelBlockOptions block={block} latestContent={latestContent} index={index} readOnly={readOnly} />
            )}
        </div>
    );
}

function LabelBlockOptions({ block, latestContent, index, readOnly }) {
    const { labelFolder, options } = latestContent;
    const { setFieldValue } = useFormikContext();
    const { t } = useTranslation('documents');

    const allLabels = useGetLabels();
    const labels = allLabels ? allLabels.filter((label) => label.folder === idToUri(labelFolder, 'LabelFolder')) : [];

    const { multiple, allow_add } = block.properties;
    const selectInputRef = useRef();

    useEffect(() => {
        if (!multiple && options.length > 1) {
            setFieldValue('options', []);
        }
    }, [multiple]);

    const handleSelect = (newValues) => {
        setFieldValue(
            `areaBlocks.${index}.latestContent`,
            JSON.stringify({
                ...latestContent,
                options: newValues,
            })
        );
    };

    return (
        <Form.Group as={Row}>
            <Form.Label column sm={4} htmlFor="options">
                {t('document.navbar.main.editor.left.blocks.types.labelBlock.value')}
            </Form.Label>
            <Col sm={8}>
                {multiple ? (
                    <div
                        onPaste={(event) => {
                            const pastedText = event.nativeEvent.clipboardData.getData('text');
                            const items = pastedText
                                .split(',')
                                .map((_item) => _item.trim())
                                .filter((_item) => options.find((_option) => _option.label === _item) === undefined);

                            if (items.length > 0) {
                                handleSelect(
                                    options.concat(
                                        items
                                            .filter((_item) => {
                                                return (
                                                    allow_add ||
                                                    labels.find((_label) => _label.name === _item) !== undefined
                                                );
                                            })
                                            .map((_item) => {
                                                const existingLabel = labels.find((_label) => _label.name === _item);

                                                return {
                                                    id: existingLabel?.id ?? undefined,
                                                    value: existingLabel?.id ?? _item,
                                                    label: existingLabel?.name ?? _item,
                                                };
                                            })
                                    )
                                );

                                selectInputRef.current.select.blur();
                            }
                        }}
                    >
                        <InputMultipleSelect
                            props={{
                                ref: selectInputRef,
                                isDisabled: readOnly,
                            }}
                            name="options"
                            value={options}
                            defaultValue={options}
                            creatable={allow_add}
                            options={HelperFunctions.prepareDropdownData(labels)}
                            onChange={(selection) => {
                                const selectedValues = selection.map((_selection) => ({
                                    id: _selection.id,
                                    value: _selection.value,
                                    label: _selection.label,
                                }));

                                handleSelect(selectedValues);
                            }}
                        />
                    </div>
                ) : (
                    <DocRevSelect
                        name="options"
                        props={{
                            isDisabled: readOnly,
                        }}
                        options={HelperFunctions.prepareDropdownData(labels)}
                        selectedValue={options.length === 1 ? options[0]['value'] : ''}
                        onChange={(selection) => {
                            handleSelect([
                                {
                                    id: selection.id,
                                    value: selection.value,
                                    label: selection.label,
                                },
                            ]);
                        }}
                    />
                )}
            </Col>
        </Form.Group>
    );
}
