import {ChromePicker, CustomPicker} from 'react-color';
import {CircleFill} from "react-bootstrap-icons";

function CirclePicker({show, hide, toggleShow, handleChange, color = '#ccc'}) {

    const popover = {
        position: 'absolute',
        zIndex: '1060',
    }

    const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    }

    return (
        <>
            <button className="btn btn-sm btn-icon" onClick={toggleShow} style={{color: color}}>
                <CircleFill />
            </button>
            {show ? <div style={popover}>
                <div style={cover} onClick={hide} />
                <ChromePicker color={color} onChange={handleChange} />
            </div> : null}
        </>
    )
}

export default CustomPicker(CirclePicker);
