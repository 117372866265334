import { useContext, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { patchOpEntryLabel } from '../../../../features/operationsList/operationsListSlice';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';
import Spinner from '../../../global/Spinner';
import { TasksContext } from '../OpTeamTasks';
import { FieldValue, OpEntryLabel, Task } from '../../../../models/operations_list.models';
import { useTranslation } from 'react-i18next';

export function TaskBulkEdit({
    availableTasks = [],
    opEntryLabel,
}: {
    availableTasks: Task[];
    opEntryLabel: OpEntryLabel;
}) {
    const { setSelectedTasks, selectedTasks }: { setSelectedTasks: (tasks: Task[]) => void; selectedTasks: Task[] } =
        useContext(TasksContext);
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const { t } = useTranslation('changelist');

    const intersectOptions = useMemo(() => {
        const primaryFields = selectedTasks.map((_task) => _task.fields[0]);
        const primaryFieldsOptions = primaryFields.map((_field) => _field.options);

        return _.intersection(...primaryFieldsOptions);
    }, [selectedTasks]);

    const handleAction = (eventKey: string | null) => {
        if (eventKey === null) {
            return;
        }

        setLoading(true);

        const newFieldValues: FieldValue = {
            ...opEntryLabel.fieldValues,
        };

        selectedTasks.forEach((_task) => {
            newFieldValues[_task.fields[0]['@id']] = eventKey;
        });

        dispatch(
            // @ts-ignore
            patchOpEntryLabel({
                uri: opEntryLabel['@id'],
                formData: {
                    fieldValues: newFieldValues,
                },
            })
            // @ts-ignore
        ).then(() => {
            setSelectedTasks([]);
            setLoading(false);
        });
    };

    return (
        <div className="d-flex align-items-center mb-1">
            <div className="text-secondary small mr-1">{t('changelist.tasks.select')}:</div>
            <Button size="sm" variant="link" className="px-0 py-0" onClick={() => setSelectedTasks(availableTasks)}>
                {t('changelist.tasks.btn.all')}
            </Button>
            <div className="text-secondary small">&nbsp;/&nbsp;</div>
            <Button size="sm" variant="link" className="px-0 py-0" onClick={() => setSelectedTasks([])}>
                {t('changelist.tasks.btn.none')}
            </Button>

            <div className="d-flex align-items-center ml-auto">
                {isLoading && <Spinner className="mr-2" />}

                {!isLoading && selectedTasks.length > 0 && (
                    <div className="mr-2 font-weight-bold" style={{ fontSize: 11 }}>
                        {t('changelist.tasks.selected', { number: selectedTasks.length })}
                    </div>
                )}

                <DropdownButton
                    id="dropdown-bulk"
                    title="Acties"
                    disabled={selectedTasks.length === 0}
                    onSelect={handleAction}
                    size="sm"
                >
                    <Dropdown.Header>
                        {t('changelist.tasks.btn.quickEdit')}{' '}
                        {intersectOptions.length === 0 && <> {t('changelist.tasks.btn.notAvailable')}</>}
                    </Dropdown.Header>
                    {intersectOptions.map((_option, index) => (
                        <Dropdown.Item eventKey={`${_option}`} key={`bulk-edit-${index}`}>
                            {_option}
                        </Dropdown.Item>
                    ))}
                </DropdownButton>
            </div>
        </div>
    );
}
