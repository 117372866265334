import { useGetAreasQuery } from 'features/documents/documents';
import HelperFunctions from 'pages/global/HelperFunctions';

const emptyArray = [];

export function useGetAreas(sectionId) {
    const { areas } = useGetAreasQuery(sectionId, {
        selectFromResult: ({ data }) => ({
            areas: data ? [...data].sort(HelperFunctions.dynamicSort('sortOrder')) : emptyArray,
        }),
    });

    return areas;
}
