import { useGetDocumentVariantsQuery } from '../../../features/documents/documents';
import { useParams } from 'react-router-dom';

export function useGetDocumentVariants(id = undefined) {
    const { documentId } = useParams();
    const idAsNumber = id ?? parseInt(documentId);

    const { documentVariants } = useGetDocumentVariantsQuery(idAsNumber, {
        selectFromResult: ({ data }) => ({
            documentVariants: data ?? [],
        }),
        skip: !idAsNumber,
    });

    return documentVariants;
}
