import { FormModal, ModalFooter } from 'pages/global/FormModal';
import { Form as FForm, Formik } from 'formik';
import { Modal } from 'react-bootstrap';
import { FieldSelect, FormField } from 'pages/publications_v2/helpers/FieldHelper';
import * as Yup from 'yup';
import _ from 'lodash';
import { documentApi, useUpdateAreasMutation } from 'features/documents/documents';
import { useTranslation } from 'react-i18next';
import { useGetDocument } from 'pages/documents_v2/hooks/useGetDocument';
import { useDispatch } from 'react-redux';

export default function AddAreaFromTemplate({ handleClose, payload }) {
    const dispatch = useDispatch();
    const document = useGetDocument(undefined, true);
    const [updateAreas] = useUpdateAreasMutation();
    const { t } = useTranslation('documents');

    const { sections = [] } = document;
    const templateOptions = sections.map((_section) => {
        return {
            value: _section.id,
            label: _section.title,
            options: _section.areas
                .filter((_area) => _area.deletedAt === null && _.get(_area.properties, 'isTemplate', false))
                .map((_area) => {
                    return {
                        value: _area.id,
                        label: _area.title,
                    };
                }),
        };
    });

    const handleSubmit = (values, { setSubmitting }) => {
        // Handle save
        updateAreas({
            documentId: document.id,
            body: [
                {
                    ...values,
                },
            ],
        }).then(() => {
            // Invalidate cache
            dispatch(documentApi.util.invalidateTags([{ type: 'Section', id: values.sectionId }]));

            setSubmitting(false);
            handleClose();
        });
    };

    return (
        <FormModal
            show
            onHide={handleClose}
            title={t('document.navbar.main.reviewDocument.addAreaFromTemplate.makeArticle')}
        >
            <Formik
                initialValues={{
                    ...payload,
                    title: '',
                    createdFromTemplate: undefined,
                }}
                validationSchema={NewAreaSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, dirty, isValid }) => (
                    <FForm autoComplete="off">
                        <Modal.Body>
                            <FieldSelect
                                label="Template"
                                options={templateOptions}
                                name="createdFromTemplate"
                                props={{
                                    placeholder: `${t(
                                        'document.navbar.main.reviewDocument.addAreaFromTemplate.titleSelect'
                                    )}...`,
                                }}
                            />

                            <FormField
                                name="title"
                                label={t('document.navbar.main.reviewDocument.addAreaFromTemplate.articleName')}
                            />
                        </Modal.Body>

                        <ModalFooter
                            isValid={isValid}
                            btnSubmitText={t('document.navbar.main.reviewDocument.addAreaFromTemplate.btn.addArticle')}
                            dirty={dirty}
                            isSubmitting={isSubmitting}
                            onHide={handleClose}
                        />
                    </FForm>
                )}
            </Formik>
        </FormModal>
    );
}

const NewAreaSchema = Yup.object().shape({
    title: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Required'),
    createdFromTemplate: Yup.number().required('Required'),
});
