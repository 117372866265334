import { useSelector } from 'react-redux';
import { useGetEntityReference } from '../../../../../../features/translations/translationApi';
import EntityCard from '../../EntityCard';
import { entityTypes } from '../../../../config/Constants';
import { useContext, useMemo } from 'react';
import { EditContext } from '../../../view/EditContextWrapper';
import { isEnabledForTranslation, isTranslationInProgress } from '../../../../helpers/EntityReferenceHelper';
import Constants from '../../../../../../config/Constants';

export default function Section({ section, languageIso, translationContainer, areaEntityReferences = [] }) {
    const { search } = useContext(EditContext);
    const { sidebarFilters } = useSelector((state) => state.translation);

    const { id, areas = [] } = section;
    const { showDisabledForTranslation = true, variantId, showComplete = true, showIncomplete = true } = sidebarFilters;

    const filteredAreas = useMemo(() => {
        return areas
            // Only show Areas (not linked Areas)
            .filter((_area) => _area.type === Constants.areaTypes.area)
            .map((_area) => {
                // Find entityReference object for each Area
                const entityReference = areaEntityReferences.find((_item) => _item.uri === `/api/area/${_area.id}`);
                return {
                    entity: _area,
                    entityReference,
                };
            })
            .filter(({ entityReference }) => entityReference !== undefined)
            .filter(({ entityReference }) => {
                const _isEnabledForTranslation = isEnabledForTranslation(entityReference, languageIso);

                // Filter 'Toon uitgesloten voor vertaling'
                return showDisabledForTranslation === true || _isEnabledForTranslation === true;
            })
            .filter(({ entity }) => {
                // Search title filter
                return search === '' || entity.title.toLowerCase().includes(search.trim().toLowerCase());
            })
            .filter(({ entity }) => {
                // Variant filter
                return variantId > 0 ? entity.documentVariants.includes(variantId) : true;
            })
            .filter(({ entityReference }) => {
                if (showComplete === false || showIncomplete === false) {
                    const translationInProgress = isTranslationInProgress(entityReference, languageIso);

                    // Filter 'Toon vertaling compleet'
                    if (showComplete === false && translationInProgress === false) {
                        return false;
                    }

                    // Filter 'Toon vertaling nog niet gereed'
                    if (showIncomplete === false && translationInProgress === true) {
                        return false;
                    }
                }

                return true;
            });

    }, [areas, areaEntityReferences, showComplete, showIncomplete, variantId, showDisabledForTranslation, search]);

    const entityReference = useGetEntityReference(
        translationContainer['@id'],
        entityTypes.SECTION,
        `/api/${entityTypes.SECTION}/${id}`
    );

    if (entityReference === undefined) {
        return null;
    }

    const enabledForTranslation = isEnabledForTranslation(entityReference, languageIso);

    // Hide Section if not enabled for translation and filter option is turned off
    if (showDisabledForTranslation === false && enabledForTranslation === false) {
        return null;
    }

    return (
        <EntityCard
            entity={section}
            languageIso={languageIso}
            entityChildren={filteredAreas}
            entityReference={entityReference}
            translationContainerUri={translationContainer['@id']}
        />
    );
}
