import { FormField } from '../../../../publications_v2/helpers/FieldHelper';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default function Properties() {
    const { t } = useTranslation('reimbursements');

    return (
        <>
            <div className="subheader">
                <h3>{t('proposition.editProposition.properties.title')}</h3>
            </div>

            <Row>
                <Col xs={8}>
                    <FormField label={`${t('proposition.editProposition.properties.name')}*`} name="name" />
                    <FormField label={t('proposition.editProposition.properties.description')} name="description" />
                </Col>
            </Row>
        </>
    );
}
