import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import getEnv from '../../config/Env';
import HelperFunctions from '../../pages/global/HelperFunctions';
import { baseQueryWithReauth, prepareHeaders, providesList } from '../api';
import queryString from 'query-string';
import baseAPI from 'api/ApiConnector_v2';

const baseUrl = getEnv('META_API_BASE_URL');
const uriPrefix = '/api/meta-api/api';
const oneLinerUriPrefix = '/api/meta-api/api/one-liners';

const metaDataApiQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders,
});

export const metaApi = createApi({
    reducerPath: 'metaApi',
    baseQuery: (args, api, extraOptions) => baseQueryWithReauth(args, api, extraOptions, metaDataApiQuery),
    tagTypes: ['Label', 'LabelFolder', 'LabelEntity', 'Category'],
    endpoints: (builder) => ({
        getLabels: builder.query({
            query: ({ organisationId }) => ({
                url: `${uriPrefix}/labels`,
                params: { organisationId },
            }),
            transformResponse: (response) => response['hydra:member'].sort(HelperFunctions.dynamicSort('name')),
            providesTags: (result) => providesList(result, 'Label'),
        }),
        addLabel: builder.mutation({
            query: (body) => {
                return {
                    url: `${uriPrefix}/labels`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: [
                { type: 'Label', id: 'LIST' },
                {
                    type: 'LabelFolder',
                    id: 'LIST',
                },
            ],
        }),
        updateLabel: builder.mutation({
            query: (data) => {
                const { uri, ...body } = data;
                return {
                    url: uri,
                    method: 'PUT',
                    body,
                };
            },
            invalidatesTags: (result, error, arg) => [
                { type: 'Label', id: arg.uri },
                {
                    type: 'LabelFolder',
                    id: 'LIST',
                },
            ],
        }),
        deleteLabel: builder.mutation({
            query: (uri) => {
                return {
                    url: uri,
                    method: 'DELETE',
                };
            },
            invalidatesTags: (result, error, arg) => [
                { type: 'Label', id: arg.uri },
                {
                    type: 'LabelFolder',
                    id: 'LIST',
                },
            ],
        }),
        getLabelFolders: builder.query({
            query: ({ organisationId }) => ({
                url: `${uriPrefix}/label_folders`,
                params: { organisationId },
            }),
            transformResponse: (response) => response['hydra:member'].sort(HelperFunctions.dynamicSort('name')),
            providesTags: (result) => providesList(result, 'LabelFolder'),
        }),
        addLabelFolder: builder.mutation({
            query: (body) => {
                return {
                    url: `${uriPrefix}/label_folders`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: [
                {
                    type: 'LabelFolder',
                    id: 'LIST',
                },
            ],
        }),
        updateLabelFolder: builder.mutation({
            query: (data) => {
                const { uri, ...body } = data;
                return {
                    url: uri,
                    method: 'PUT',
                    body,
                };
            },
            invalidatesTags: (result, error, arg) => [
                {
                    type: 'LabelFolder',
                    id: 'LIST',
                },
            ],
        }),
        deleteLabelFolder: builder.mutation({
            query: (uri) => {
                return {
                    url: uri,
                    method: 'DELETE',
                };
            },
            invalidatesTags: (result, error, arg) => [{ type: 'LabelFolder', id: arg.uri }],
        }),
        getLabelEntities: builder.query({
            query: (params) => ({
                url: `${uriPrefix}/label_entities`,
                params,
            }),
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result) => providesList(result, 'LabelEntity'),
        }),
        addLabelEntities: builder.mutation({
            async queryFn(labelEntities, _queryApi, _extraOptions, baseQuery) {
                for (const labelEntity of labelEntities) {
                    await baseQuery({
                        url: `${uriPrefix}/label_entities`,
                        method: 'POST',
                        body: labelEntity,
                    });
                }
                return { data: labelEntities.length };
            },
            invalidatesTags: [{ type: 'LabelEntity', id: 'LIST' }],
        }),
        deleteLabelEntities: builder.mutation({
            async queryFn(labelEntities, _queryApi, _extraOptions, baseQuery) {
                for (const uri of labelEntities) {
                    await baseQuery({
                        url: uri,
                        method: 'DELETE',
                    });
                }
                return { data: labelEntities.length };
            },
            invalidatesTags: [{ type: 'LabelEntity', id: 'LIST' }],
        }),
        getOneLinerCategories: builder.query({
            query: (params) => ({
                url:
                    `${oneLinerUriPrefix}/categories?order[name]&` +
                    queryString.stringify(params, { arrayFormat: 'bracket' }),
            }),
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result) => providesList(result, 'Category'),
        }),
        getOneLinerChildCategories: builder.query({
            query: (id) => ({
                url: `${oneLinerUriPrefix}/categories/${id}/children?order[name]`,
            }),
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result) => providesList(result, 'Category'),
        }),
        getOneLinerCategory: builder.query({
            query: (id) => ({
                url: `${oneLinerUriPrefix}/categories/${id}`,
            }),
            providesTags: (result, error, arg) => [{ type: 'Category', id: arg }],
        }),
        addOneLinerCategory: builder.mutation({
            query: (body) => ({
                url: `${oneLinerUriPrefix}/categories`,
                method: 'POST',
                body,
            }),
            invalidatesTags: [
                {
                    type: 'Category',
                    id: 'LIST',
                },
            ],
        }),
        updateOneLinerCategory: builder.mutation({
            query: ({ uri, body }) => ({
                url: uri,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Category', id: arg.uri }],
        }),
        deleteOneLinerCategory: builder.mutation({
            query: (uri) => ({
                url: uri,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Category', id: arg.uri }],
        }),
        getOneLiners: builder.query({
            query: (params) => ({
                url: `${oneLinerUriPrefix}/one_liners?` + queryString.stringify(params, { arrayFormat: 'bracket' }),
            }),
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result) => providesList(result, 'OneLiner'),
        }),
        getCategoryOneLiners: builder.query({
            query: ({ uri, params }) => ({
                url: `${uri}/one_liners?` + queryString.stringify(params, { arrayFormat: 'bracket' }),
            }),
            providesTags: (result, error, arg) => [{ type: 'Category', id: arg.uri }],
        }),
        updateOneLiners: builder.mutation({
            query: (body) => ({
                url: `${oneLinerUriPrefix}/batch`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'OneLiner' }],
        }),
        updateOneLiner: builder.mutation({
            query: ({ uri, body }) => ({
                url: uri,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'OneLiner', id: arg.uri }],
        }),
    }),
});

export function exportCategory(categoryId, formData = {}) {
    return baseAPI.post(`${baseUrl}${oneLinerUriPrefix}/categories/${categoryId}/export`, formData, {
        responseType: 'blob',
    });
}

export const {
    useGetLabelsQuery,
    useAddLabelMutation,
    useUpdateLabelMutation,
    useDeleteLabelMutation,
    useGetLabelFoldersQuery,
    useAddLabelFolderMutation,
    useUpdateLabelFolderMutation,
    useDeleteLabelFolderMutation,
    useGetLabelEntitiesQuery,
    useAddLabelEntitiesMutation,
    useDeleteLabelEntitiesMutation,
    useGetOneLinerCategoriesQuery,
    useGetOneLinerChildCategoriesQuery,
    useAddOneLinerCategoryMutation,
    useUpdateOneLinerCategoryMutation,
    useDeleteOneLinerCategoryMutation,
    useGetOneLinersQuery,
    useUpdateOneLinersMutation,
    useUpdateOneLinerMutation,
    useGetCategoryOneLinersQuery,
    useGetOneLinerCategoryQuery,
} = metaApi;
