import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPublication, selectPublicationById } from 'features/publications/publicationsSlice';

export function useGetPublication(id) {
    const dispatch = useDispatch();
    const publication = useSelector((state) => selectPublicationById(state.publications, id));

    useEffect(() => {
        if (!publication) {
            dispatch(fetchPublication({ id }));
        }
    }, [publication]);

    return publication;
}
