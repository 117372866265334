import _ from 'lodash';
import cx from 'classnames';
import { useContext } from 'react';
import { EditContext } from '../view/EditContextWrapper';
import EntityOptions from './EntityOptions';
import { isEnabledForTranslation } from '../../helpers/EntityReferenceHelper';
import { useGetTranslationContainerEntityReferencesQuery } from '../../../../features/translations/translationApi';
import EntityCardTitle from './EntityCardTitle';

export default function EntityChildRow({
    entity,
    languageIso,
    entityReferenceUri,
    entityType,
    entityTitleField = 'title',
    entityTitle,
    translationContainerUri,
}) {
    const { toggleEntity, entityIsSelected } = useContext(EditContext);

    const { entityReference = {} } = useGetTranslationContainerEntityReferencesQuery(
        {
            uri: translationContainerUri,
            entityType,
        },
        {
            selectFromResult: ({ data }) => ({
                entityReference: data ? data.find((_item) => _item['@id'] === entityReferenceUri) : undefined,
            }),
            skip: entityReferenceUri === undefined,
        }
    );

    const hasEntityReference = entityReferenceUri !== undefined;
    const { entityId, uri } = entityReference;

    const headingLevel = _.get(entity, 'exportProperties.headingLevel', 2);
    const enabledForTranslation = isEnabledForTranslation(entityReference, languageIso);
    const title = entityTitle ?? _.get(entity, entityTitleField, '');

    return (
        <div
            className={cx('d-flex align-items-center area-heading-level area-heading-level-' + headingLevel, {
                'area-excluded': enabledForTranslation === false,
            })}
        >
            <div className="d-flex align-items-center" style={{ width: 58 }}>
                <input
                    type="checkbox"
                    id={`entity-check-${entityId}`}
                    checked={entityIsSelected(uri)}
                    onChange={() => {
                        toggleEntity(
                            {
                                id: uri,
                                entityReference,
                                entity,
                            },
                            true
                        );
                    }}
                    className="mr-3"
                    onClick={(event) => event.stopPropagation()}
                />
            </div>

            <div className="area-heading-level-inner d-flex w-100">
                <div
                    className={cx('dr-card-row d-flex flex-grow-1 align-items-center', {
                        selected: entityIsSelected(uri),
                    })}
                    style={{
                        padding: '0.65rem 1.25rem',
                    }}
                    onClick={({ target }) => {
                        if (!target.className.includes('btn')) {
                            toggleEntity({
                                id: uri,
                                entityReference,
                                entity,
                            });
                        }
                    }}
                >
                    <div className="card-title font-weight-normal mb-0">
                        <EntityCardTitle
                            translationContainerUri={translationContainerUri}
                            entityType={entityType}
                            entityUri={uri}
                            title={title}
                            languageIso={languageIso}
                        />
                    </div>

                    {hasEntityReference && (
                        <EntityOptions entityReference={entityReference} languageIso={languageIso} />
                    )}
                </div>
            </div>
        </div>
    );
}
