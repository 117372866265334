import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { batchEditPublications, fetchPublicationGroups, selectPublicationGroups, setSelectedRows } from "features/publications/publicationsSlice";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import HelperFunctions from "pages/global/HelperFunctions";
import { DocRevSelect } from "../helpers/FieldHelper";

export function MovePublicationsModal({ show, onHide }) {
    const [targetGroup, setTargetGroup] = useState(null);
    const dispatch = useDispatch();
    const { t } = useTranslation('publications');
    const selectedRows = useSelector((state) => state.publications.selectedRows);
    const groups = useSelector(selectPublicationGroups());

    const handleMove = () => {
        const targetGroupObj = groups.find(g => g.id === parseInt(targetGroup));
        const newSortOrder = targetGroupObj.publications.length + 1;
        const changes = selectedRows.map((publicationId, index) => ({
            id: publicationId,
            changes: {
                publicationGroup: `/api/publication_groups/${targetGroup}`,
                sortOrder: newSortOrder + index,
            },
        }));
        dispatch(batchEditPublications({ formData: { publications: changes } })).then(() => {
            dispatch(setSelectedRows([]));
            dispatch(fetchPublicationGroups());
            setTargetGroup(null);
        });

        onHide();
    };

    const handleSelectChange = (selectedOption) => {
        setTargetGroup(selectedOption.value);
    };

    return (
        <Modal show={show} onHide={onHide} backdrop="static" centered>
            <Modal.Header closeButton>
                <Modal.Title>{t('publications.bulkActions.moveModal.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <label htmlFor="targetGroup">{t('publications.bulkActions.moveModal.selectGroup')}</label>
                <Row>
                    <Col>
                        <DocRevSelect
                            options={HelperFunctions.prepareDropdownData(groups)}
                            name="movePublications"
                            selectedValue={targetGroup}
                            onChange={handleSelectChange}
                            props={{
                                isClearable: true,
                                placeholder: t('publications.bulkActions.moveModal.selectGroupPlaceholder'),
                            }}
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    {t('btn.cancel')}
                </Button>
                <Button variant="primary" onClick={handleMove}>
                    {t('publications.bulkActions.moveModal.move')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

