import { DEFAULT_VIEW, Index } from 'pages/task_team/views/Index';
import { Route } from 'react-router-dom';
import { ReactTitle } from 'components/ReactTitle';

export const BASE_PATH = '/oke';
export const CHECKS_PATH = `${BASE_PATH}/checks/:page?`;
export const ALL_CHECKS_PATH = `${BASE_PATH}/checks/all`;
export const TEAMS_PATH = `${BASE_PATH}/teams`;
export const REPORTS_PATH = `${BASE_PATH}/reports`;
export const SETTINGS_PATH = `${BASE_PATH}/settings/:page?`;
export const VIEW_TEAM_PATH = `${BASE_PATH}/teams/:id/:page`;
export const DEFAULT_TASK_PATH = `${BASE_PATH}/${DEFAULT_VIEW}`;
export const VIEW_PATH = `${BASE_PATH}/:view?/:id?/:page?`;

export const PAGINATION_ITEMS_PER_PAGE = 15;

export function TaskTeam() {
    return (
        <>
            <ReactTitle title="Oké checks" />
            <Route path={VIEW_PATH} render={() => <Index />} />
        </>
    );
}
