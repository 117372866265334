import { schema } from 'normalizr';

// definite normalizr entity schemas
export const categoryEntity = new schema.Entity('categories');
export const reimbursementProductEntity = new schema.Entity('reimbursementProducts');
export const reimbursementEntity = new schema.Entity('reimbursements');
export const reimbursementProductCodeEntity = new schema.Entity('reimbursementProductCodes');
export const reimbursementProductTypeEntity = new schema.Entity('reimbursementProductTypes');
export const reimbursementProductFieldEntity = new schema.Entity('reimbursementProductFields');
export const reimbursementCodeEntity = new schema.Entity('reimbursementCodes');
export const labelEntity = new schema.Entity('labels');
export const folderEntity = new schema.Entity('folders');
export const labelEntityEntities = new schema.Entity('labelEntityEntities');
export const userEntity = new schema.Entity('users');
export const documentTemplateEntity = new schema.Entity('documentTemplates');
export const organisationEntity = new schema.Entity('organisations', {
    users: [userEntity],
    documentTemplates: [documentTemplateEntity],
});
export const areaEntity = new schema.Entity('areas');
export const documentVariantEntity = new schema.Entity('documentVariants');
export const commentEntity = new schema.Entity('comments');
export const blockEntity = new schema.Entity('blocks');

// Operations List entities
export const teamEntity = new schema.Entity(
    'teams',
    {},
    {
        idAttribute: '@id',
    }
);
export const opListEntity = new schema.Entity('operationLists');
export const opListUserEntity = new schema.Entity('opListUsers');
export const departmentEntity = new schema.Entity('departments');
export const opEntryEntity = new schema.Entity(
    'opEntries',
    {},
    {
        idAttribute: '@id',
    }
);
export const opEntryLabelEntity = new schema.Entity(
    'opEntryLabels',
    {},
    {
        idAttribute: '@id',
    }
);
export const opEntryBlockEntity = new schema.Entity('opEntryBlocks');
export const opEntryAreaEntity = new schema.Entity('opEntryAreas');
export const opEntryVariantEntity = new schema.Entity('opEntryVariants');
export const opEntryActionEntity = new schema.Entity('opEntryActions');
export const opEntryDepartmentStatusEntity = new schema.Entity('opEntryDepartmentStatuses');

// Tasks
export const taskEntity = new schema.Entity(
    'tasks',
    {},
    {
        idAttribute: '@id',
    }
);

export const reportsDataEntity = new schema.Entity(
    'reportsData',
    {},
    {
        idAttribute: 'departmentId',
    }
);

// Publications
export const publicationVersionEntity = new schema.Entity('publicationVersions');
export const publicationPreviewTokenEntity = new schema.Entity('publicationPreviewTokens');
export const opListExportTokenEntity = new schema.Entity('opListExportTokens');
export const publicationEntity = new schema.Entity('publications', {
    publicationVersions: [publicationVersionEntity],
    publicationPreviewTokens: [publicationPreviewTokenEntity],
});
export const publicationGroupEntity = new schema.Entity('publicationGroups');

// Media Library
export const fileEntity = new schema.Entity('files');
