import { useGetInsurerCardsQuery } from 'features/documents/ipidApi';
import HelperFunctions from '../../../global/HelperFunctions';
import { Badge, Table } from 'react-bootstrap';
import { PrimaryButton } from 'components/Buttons';
import { ImportContext } from '../IpidImport';
import { useContext } from 'react';
import { removeInvisibleSpaces } from './Helpers';
import _ from 'lodash';

export function InsurerTemplateCards({ insurer, template }) {
    const cardName = _.isEmpty(template.version) ? removeInvisibleSpaces(template.name) : undefined;
    const cardTemplateId = template.version ?? '';
    const templateIds = cardTemplateId.split(',').map((templateId) => parseInt(templateId));

    const { cards } = useGetInsurerCardsQuery(
        { id: insurer.id, cardName, templateIds },
        {
            selectFromResult: ({ data }) => ({
                cards: data
                    ? data.filter((card) => card.latestVersion !== null).sort(HelperFunctions.sortByString('name'))
                    : [],
            }),
        }
    );

    if (cards.length === 0) {
        return null;
    }

    return (
        <Table size="sm">
            <thead>
                <tr>
                    <th className="text-secondary font-weight-bold">ID</th>
                    <th className="text-secondary font-weight-bold">Template ID</th>
                    <th className="text-secondary font-weight-bold">Naam kaart</th>
                    <th className="text-secondary font-weight-bold">Gepubliceerde versie</th>
                    <th className="text-secondary font-weight-bold">Laatste versie</th>
                    <th className="text-secondary font-weight-bold">Kenmerk</th>
                    <th className="text-secondary font-weight-bold">Tags</th>
                </tr>
            </thead>
            <tbody>
                {cards.map((card) => (
                    <CardRow insurer={insurer} card={card} key={`card-row-${card.id}`} />
                ))}
            </tbody>
        </Table>
    );
}

function CardRow({ insurer, card }) {
    const { formData, setFormData } = useContext(ImportContext);
    const cardIdsInFormData = formData.importedVariants.map((variant) => parseInt(variant[2]));

    return (
        <tr>
            <td className="align-middle">{card.id}</td>
            <td className="align-middle">{card.templates.id}</td>
            <td className="align-middle font-weight-bolder">{card.name}</td>
            <td className="align-middle">{renderAddVersionButton(card.publishedVersion)}</td>
            <td className="align-middle">
                {renderAddVersionButton(card.latestVersion, card.publishedVersion === card.latestVersion)}
            </td>
            <td className="align-middle">{_.isEmpty(card.internKenmerk) ? '-' : card.internKenmerk}</td>
            <td className="align-middle">{renderCardTags(card)}</td>
        </tr>
    );

    function renderCardTags(card) {
        if (!card.tags) {
            return '-';
        }

        return card.tags.map((tag, index) => (
            <Badge variant="primary" className="mr-1 mb-1" key={`card-${card.id}-tag-${index}`}>
                {tag}
            </Badge>
        ));
    }

    function renderAddVersionButton(version, hideButton = false) {
        if (!version) {
            return '-';
        }

        if (hideButton) {
            return <>Versie {version}</>;
        }

        return (
            <div className="d-flex align-items-center">
                <div className="mr-1">Versie {version} -</div>
                <PrimaryButton
                    size="sm"
                    onClick={() => handleAdd(version)}
                    disabled={cardIdsInFormData.includes(card.id)}
                >
                    Voeg versie {version} toe
                </PrimaryButton>
            </div>
        );
    }

    function handleAdd(version) {
        setFormData({
            ...formData,
            importedVariants: [
                ...formData.importedVariants,
                [
                    formData.documentTemplateId,
                    _.trim(insurer.name),
                    card.id,
                    version,
                    _.trim(card.name),
                    '',
                    _.trim(card.polisvoorwaarden),
                    _.trim(card.url),
                    card.templates.id,
                ],
            ],
        });
    }
}
