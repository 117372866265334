import { reimbursementApi, useGetCategoriesQuery, useGetReimbursementProductsQuery } from './reimbursements';
import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import { Category, Reimbursement, ReimbursementProduct } from '../../models/reimbursement.models';
import { idToUri } from '../../pages/global/UriHelper';
import Constants from '../../config/Constants';
import {useGetReimbursements} from "../../pages/reimbursements/hooks/useGetReimbursements";

const selectCategoryById = (categoryId: number) =>
    createSelector([(result: { data?: Category[] }) => result.data], (data: Category[] | undefined) => {
        return data?.find((d: Category) => d.id === categoryId) ?? undefined;
    });

export function useGetCategory(categoryId: number): { category: Category | undefined } {
    const selectCategoryMemoized = useMemo(() => {
        return selectCategoryById(categoryId);
    }, [categoryId]);

    const { category } = useGetCategoriesQuery(undefined, {
        selectFromResult: (result) => ({
            category: selectCategoryMemoized(result),
        }),
    });

    return { category };
}

const selectReimbursementById = (reimbursementId: number) =>
    createSelector([(result: { data?: Reimbursement[] }) => result.data], (data: Reimbursement[] | undefined) => {
        return data?.find((d: Reimbursement) => d.id === reimbursementId) ?? undefined;
    });

export function useGetReimbursement(
    categoryId: number,
    reimbursementId: number
): Reimbursement | undefined {
    // const selectReimbursementMemoized = useMemo(() => {
    //     return selectReimbursementById(reimbursementId);
    // }, [categoryId, reimbursementId]);

    const { reimbursements } = useGetReimbursements(idToUri(categoryId, 'Category'));

    return reimbursements.find((reimbursement) => reimbursement.id === reimbursementId)

    // const { reimbursement } = reimbursementApi.endpoints.getReimbursements.useQueryState(
    //     idToUri(categoryId, 'Category'),
    //     {
    //         selectFromResult: (result) => ({
    //             ...result,
    //             reimbursement: selectReimbursementMemoized(result),
    //         }),
    //     }
    // );
    //
    // return { reimbursement };
}

export function useGetReimbursementProduct(
    categoryUri: string,
    reimbursementUri: string,
    productUri: string,
    selectedProductUri: string
): {
    reimbursementProduct: Partial<ReimbursementProduct>;
    isLoading: boolean;
    isUninitialized: boolean;
} {
    const {
        reimbursementProduct,
        isLoading,
        isUninitialized,
    }: {
        reimbursementProduct: Partial<ReimbursementProduct>;
        isLoading: boolean;
        isUninitialized: boolean;
    } = useGetReimbursementProductsQuery(reimbursementUri, {
        selectFromResult: (result) => {
            const reimbursementProduct = result.data
                ? result.data.find(
                      (_item: ReimbursementProduct) =>
                          _item.product === productUri && _item.selectedProduct === selectedProductUri
                  )
                : undefined;

            if (reimbursementProduct) {
                return {
                    ...result,
                    reimbursementProduct,
                };
            }

            // Return dummy object
            const dummy: Partial<ReimbursementProduct> = {
                category: categoryUri,
                coverage: Constants.coverage['n/a'],
                product: productUri,
                selectedProduct: selectedProductUri,
                reimbursement: reimbursementUri,
            };

            return {
                ...result,
                reimbursementProduct: dummy,
            };
        },
    });

    return {
        reimbursementProduct,
        isLoading,
        isUninitialized,
    };
}
