import { useParams } from 'react-router-dom';
import { Create } from '../users/Create';
import { List } from '../users/List';
import { Edit } from '../users/Edit';

const RenderAction = {
    list: List,
    create: Create,
    edit: Edit,
};

export function Users({ account }) {
    const { action = 'list' } = useParams();

    const ViewToRender = RenderAction[action] ?? DefaultView;

    return <ViewToRender account={account} />;
}

function DefaultView() {
    return <div>no view</div>;
}
