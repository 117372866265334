import { useSelector } from 'react-redux';
import { selectOpEntryActions } from 'features/operationsList/operationsListSlice';
import { ExpandableTrigger } from '../forms/FilterFields';
import { useState } from 'react';
import { ActionNameAndDate } from '../view/OpEntryHistory';
import { useTranslation } from 'react-i18next';

export default function TaskHistory({ opEntryLabel, task }) {
    const opEntryActions = useSelector(selectOpEntryActions(opEntryLabel.opEntry));
    const taskActions = opEntryActions.filter(
        (action) => action.opEntryLabel === opEntryLabel['@id'] && action.task === task['@id']
    );
    const [expanded, setExpanded] = useState(false);
    const { t } = useTranslation('changelist');

    if (taskActions.length === 0) {
        return null;
    }

    return (
        <div className="small pt-4">
            <div className="text-secondary">
                {t('changelist.task.taskHistory.lastEdited')} <ActionNameAndDate action={taskActions[0]} />
            </div>

            {taskActions.length > 1 && (
                <div className="mt-2">
                    <ExpandableTrigger expanded={expanded} setExpanded={setExpanded} />
                </div>
            )}

            {expanded && (
                <div>
                    <TaskActions taskActions={taskActions.slice(1)} />
                </div>
            )}
        </div>
    );
}

function TaskActions({ taskActions }) {
    return taskActions.map((action) => <TaskAction action={action} key={action['@id']} />);
}

function TaskAction({ action }) {
    const { t } = useTranslation('changelist');

    return (
        <div className="text-secondary mb-2">
            {t('changelist.task.taskHistory.modifiedBy')} <ActionNameAndDate action={action} />
        </div>
    );
}
