import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, Card, Col, Container, Row } from 'react-bootstrap';
import {
    fetchOpEntryActions,
    fetchOpEntryId,
    fetchOpEntryLabelsForOpEntry,
    fetchOpListDepartments,
    fetchOpListUsers,
    selectDepartmentsFromOpList,
    selectOpEntryById,
    selectOpEntryLabelsByOpEntry,
    selectOpListById,
} from 'features/operationsList/operationsListSlice';
import { generatePath, useParams } from 'react-router-dom';
import MainContentNav from '../../Navbar';
import SubNav from '../nav/SubNav';
import { OpEntryExpanded } from './OpEntry';
import _ from 'lodash';
import DepartmentAccordion from './DepartmentAccordion';
import { OpEntryCreatedHistory } from './OpEntryHistory';
import LoadingSpinner from '../../global/LoadingSpinner';
import { OpEntryTypesOfEdits } from './OpTeamEntryDetails';
import { BASE_PATH, VIEW_PATH } from 'scenes/OperationsList';
import { useGetLabelsQuery } from 'features/metadata/metadata';
import { useTranslation } from 'react-i18next';
import { AreaMetadata } from 'pages/operations_list/view/AreaMetadata';

function OpAdminEntryDetails() {
    const { id, opEntryId: opEntryId, selectedPage } = useParams();
    const dispatch = useDispatch();
    const opList = useSelector((state) => selectOpListById(state.operations, id));
    // TODO @id is needed but we don't have it. So this hardcoded url is a quickFix.
    const opEntryUri = `/api/operations-api/api/op_entries/${opEntryId}`;
    const opEntry = useSelector((state) => selectOpEntryById(state.operations, opEntryUri));
    const opEntryLabels = useSelector(selectOpEntryLabelsByOpEntry(opEntryUri));
    const departments = useSelector(selectDepartmentsFromOpList(_.get(opList, '@id', '')));
    const { activeOrganisation } = useSelector((state) => state.security);
    const { t } = useTranslation('changelist');

    const { labels = [] } = useGetLabelsQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                labels: data ?? [],
            }),
        }
    );

    useEffect(() => {
        dispatch(fetchOpListDepartments({ opListId: id }));
        dispatch(fetchOpListUsers({ opListId: id }));
        dispatch(fetchOpEntryLabelsForOpEntry({ opListId: id, opEntryId }));
    }, [dispatch, id]);

    useEffect(() => {
        dispatch(fetchOpEntryId({ id: opEntryId }));
        dispatch(fetchOpEntryActions({ id: opEntryId }));
    }, [dispatch, opEntryId]);

    if (!opList || !opEntry) {
        return <LoadingSpinner />;
    }

    const entryLabelsByDepartment = _.groupBy(opEntryLabels, 'opDepartment');

    const labelLut = [];
    _.forEach(labels, (label) => {
        labelLut[label['@id']] = label;
    });

    return (
        <>
            <MainContentNav
                pages={[
                    { title: t('breadcrumb'), url: BASE_PATH },
                    {
                        title: opList.name,
                        url: generatePath(VIEW_PATH, {
                            id: opList.id,
                            selectedPage,
                        }),
                    },
                    { title: opEntry.areaTitle },
                ]}
            />
            <SubNav />

            <div className="content-static-body has-subnav">
                <div className="d-flex align-items-stretch h-100">
                    <div className="flex-grow-1 overflow-auto">
                        <div id="content" className="px-5 pt-4 pb-3">
                            <Container fluid>
                                <Row>
                                    <Col md={8}>
                                        <OpEntryExpanded opEntry={opEntry} opList={opList} />
                                    </Col>
                                    <Col md={4}>
                                        <Card.Body>
                                            <div
                                                className="d-flex align-items-center font-weight-bolder mb-2"
                                                style={{ height: 32 }}
                                            >
                                                <div>{t('changelist.view.opAdminEntry.serial')}</div>
                                                <div className="ml-auto">#{opEntry.entryNumber}</div>
                                            </div>

                                            <OpEntryTypesOfEdits opEntry={opEntry} />

                                            {opEntryLabels &&
                                                departments &&
                                                labels &&
                                                Object.keys(entryLabelsByDepartment).map((departmentUri) => (
                                                    <Accordion key={departmentUri}>
                                                        <DepartmentAccordion
                                                            opList={opList}
                                                            entryLabelsByDepartment={entryLabelsByDepartment}
                                                            departments={departments}
                                                            departmentUri={departmentUri}
                                                            labelLut={labelLut}
                                                        />
                                                    </Accordion>
                                                ))}

                                            <OpEntryCreatedHistory opEntry={opEntry} />

                                            <AreaMetadata areaId={opEntry.areaId} />
                                        </Card.Body>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OpAdminEntryDetails;
