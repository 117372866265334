import { baseQueryWithReauth, prepareHeaders, providesList } from '../api';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import getEnv from '../../config/Env';
import queryString from 'query-string';

const ipidApiBaseUrl = getEnv('IPID_API_BASE_URL');

const ipidApiQuery = fetchBaseQuery({
    baseUrl: ipidApiBaseUrl,
    prepareHeaders,
});

export const ipidApi = createApi({
    reducerPath: 'ipidApi',
    baseQuery: (args, api, extraOptions) => baseQueryWithReauth(args, api, extraOptions, ipidApiQuery),
    tagTypes: ['CardsEiopaBlockData', 'CardsEiopa', 'Insurer'],
    endpoints: (builder) => ({
        getCard: builder.query({
            query: (id) => ({
                url: `/api/cards_eiopas/${id}`,
                method: 'GET',
            }),
            providesTags: (result) => (result ? [{ type: 'CardsEiopa', id: result.id }] : []),
        }),
        getInsurers: builder.query({
            query: (insurerIds = []) =>
                '/api/insurers?' +
                queryString.stringify(
                    {
                        deleted: 'false',
                        id: insurerIds,
                    },
                    { arrayFormat: 'bracket' }
                ),
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result) => providesList(result, 'Insurer'),
        }),
        getInsurer: builder.query({
            query: (id) => ({
                url: `/api/insurers/${id}`,
                method: 'GET',
            }),
            providesTags: (result) => (result ? [{ type: 'Insurer', id: result.id }] : []),
        }),
        getInsurerCards: builder.query({
            query: ({ id, cardName = undefined, templateIds = undefined }) =>
                `/api/insurers/${id}/cards_eiopas?` +
                queryString.stringify(
                    {
                        deleted: 'false',
                        'templates.cardName': cardName,
                        'templates.id': templateIds,
                    },
                    { arrayFormat: 'bracket' }
                ),
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result) => providesList(result, 'CardsEiopa'),
        }),
        getInsurerUsers: builder.query({
            query: (id) => ({
                url: `/api/insurers/${id}/users`,
                method: 'GET',
            }),
            transformResponse: (response) => response['hydra:member'],
        }),
        getCardData: builder.query({
            query: ({ id, version }) => ({
                url: `/api/cards_eiopas/${id}/cards_eiopas_data`,
                method: 'GET',
                params: {
                    version,
                },
            }),
            transformResponse: (response) => response['hydra:member'],
        }),
        getBlockData: builder.query({
            query: ({ id, version, templatesId }) => ({
                url: `/api/cards_eiopas/${id}/cards_eiopas_block_data`,
                method: 'GET',
                params: {
                    version,
                    templatesId,
                },
            }),
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result) => providesList(result, 'CardsEiopaBlockData'),
        }),
    }),
});

export const {
    useGetBlockDataQuery,
    useGetInsurerQuery,
    useGetInsurersQuery,
    useGetInsurerCardsQuery,
    useGetInsurerUsersQuery,
} = ipidApi;
