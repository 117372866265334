import MainContentNav from 'pages/Navbar';
import { Route, Switch, useParams } from 'react-router-dom';
import { BASE_PATH, CHECKS_PATH, REPORTS_PATH, SETTINGS_PATH, TEAMS_PATH } from 'scenes/TaskTeam';
import { Teams } from 'pages/task_team/views/Teams';
import { useGetTeamQuery } from 'features/comments/commentApi';
import { Settings } from 'pages/task_team/views/settings/Settings';
import { SubNav } from 'pages/task_team/views/SubNav';
import { Can } from 'ability/Can';
import { Checks } from 'pages/task_team/views/checks/Checks';
import { EventListener } from 'pages/task_team/helpers/EventListener';
import { PreviewFileModal } from 'pages/task_team/modals/PreviewFileModal';

export const DEFAULT_VIEW = 'checks';

export function Index() {
    return (
        <>
            <EventListener />
            <ViewContentNav />
            <SubNav />
            <PreviewFileModal />

            <div className="content-static-body has-subnav">
                <div className="d-flex align-items-stretch h-100">
                    <Switch>
                        <Route path={CHECKS_PATH} render={() => <Checks />} />
                        <Route path={TEAMS_PATH} render={() => <Teams />} />
                        <Route
                            path={SETTINGS_PATH}
                            render={() => (
                                <Can I="manage" a="TaskTeam.Settings">
                                    <Settings />
                                </Can>
                            )}
                        />
                    </Switch>
                </div>
            </div>
        </>
    );
}

function ViewContentNav() {
    const { view = DEFAULT_VIEW, id, page } = useParams();

    const { team } = useGetTeamQuery(id, {
        selectFromResult: (result) => ({
            team: result.data,
        }),
        skip: id === undefined || view !== 'teams',
    });

    let pages = [{ title: 'OKÉ-checks', url: BASE_PATH }];

    if (view === 'checks') {
        pages.push({
            title: 'Uitingen',
            url: CHECKS_PATH,
        });
    } else if (view === 'teams') {
        pages.push({
            title: 'Teams',
            url: TEAMS_PATH,
        });

        if (['users', 'settings', 'rules', 'checklist'].includes(page)) {
            pages.push({
                title: team?.name,
            });
        }
    } else if (view === 'reports') {
        pages.push({
            title: 'Rapporten',
            url: REPORTS_PATH,
        });
    } else if (view === 'settings') {
        pages.push({
            title: 'Instellingen',
            url: SETTINGS_PATH,
        });
    }

    return <MainContentNav pages={pages} />;
}
