import React from 'react';
import PropTypes from 'prop-types';
import Language from "../../language/Language";

const MoreInfoIcon = (props) => {
    const {className, invalidText, margin, status, tooltip, valid, validText} = props;

    // set the margin on the entire component
    let htmlClass = 'uk-margin-left ';
    switch (margin) {
        case undefined:
        case '':
            htmlClass = 'uk-margin-left ';
            break;
        case 'none':
            htmlClass = '';
            break;
        default:
            htmlClass = 'uk-margin-' + margin + ' ';
    }

    // determine which css class to use when status is invalid
    let invalidClass = 'uk-text-danger';
    if (status !== undefined && status !== '' && status === 'info') {
        invalidClass = 'uk-text-active';
    }
    htmlClass += valid ? 'uk-text-success' : invalidClass;

    // check of optional other css classes
    if (className !== undefined) {
        htmlClass += ' ' + className;
    }

    // set optional icon and tooltip text
    const icon = valid ? 'check' : 'info';
    const tooltipText = tooltip === true ? Language.getTranslation('fieldIsRequired','messages', 'global') : tooltip;

    return (
        <span className={htmlClass} data-uk-tooltip={valid ? null : tooltipText}><span className={validText || invalidText ? 'uk-margin-small-right' : ''} data-uk-icon={"icon: " + icon} />
            {valid && validText}
            {!valid && invalidText}
        </span>
    );
};

export default MoreInfoIcon;

MoreInfoIcon.propTypes = {
    className: PropTypes.string,
    invalidText: PropTypes.string,
    margin: PropTypes.string,
    status: PropTypes.string,
    tooltip: PropTypes.any,
    valid: PropTypes.bool,
    validText: PropTypes.string,
};