import { useGetDocumentTagsQuery, useGetEntityReference } from '../../../../../features/translations/translationApi';
import { useDispatch, useSelector } from 'react-redux';
import { entityTypes, views } from '../../../config/Constants';
import { Accordion } from 'react-bootstrap';
import EntityCard from '../EntityCard';
import { getDefaultActiveKey, setExpandedEntity } from '../../../../../features/translations/translationSlice';

export default function TagsContainer({ documentContainer, translationLanguage }) {
    const dispatch = useDispatch();
    const defaultActiveKey = useSelector(getDefaultActiveKey(translationLanguage['@id'], views.TAGS));

    const { documentId } = documentContainer;

    const { tags = [] } = useGetDocumentTagsQuery(documentId, {
        selectFromResult: ({ data }) => ({
            tags: data,
        }),
    });

    return (
        <>
            <div style={{ marginBottom: '2rem' }}>
                <h5 className="card-title">Tags</h5>
            </div>

            <div>
                <Accordion
                    className="dr-accordion"
                    defaultActiveKey={defaultActiveKey}
                    onSelect={(activeKey) => {
                        dispatch(
                            setExpandedEntity({
                                translationLanguage: translationLanguage['@id'],
                                activeKey,
                                view: views.TAGS,
                            })
                        );
                    }}
                >
                    {tags.map((_tag) => (
                        <Tag
                            translationContainer={documentContainer}
                            tag={_tag}
                            languageIso={translationLanguage.languageIso}
                            key={`tag-${_tag.id}`}
                        />
                    ))}
                </Accordion>
            </div>
        </>
    );
}

function Tag({ tag, translationContainer, languageIso }) {
    const { id } = tag;
    const entityReference = useGetEntityReference(
        translationContainer['@id'],
        entityTypes.TAG,
        `/api/${entityTypes.TAG}/${id}`
    );

    if (entityReference === undefined) {
        return null;
    }

    return (
        <EntityCard
            translationContainerUri={translationContainer['@id']}
            entity={{
                ...tag,
                title: tag.name,
            }}
            languageIso={languageIso}
            entityReference={entityReference}
            entityTitleField="name"
        />
    );
}
