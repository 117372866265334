import ApiConnector from './ApiConnector';
import { getActiveOrganisation } from './AuthApi';
import HelperFunctions from '../pages/global/HelperFunctions';
import Constants from '../config/Constants';
import getEnv from '../config/Env';
import baseAPI from './ApiConnector_v2';
import ApiConnector_v2 from './ApiConnector_v2';

const apiUrl = getEnv('APP_API_URL');

const DocumentApi = {
    deleteBlock(id) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiDelete(apiUrl + '/blocks/' + id)
                .then((result) => resolve(result))
                .catch((context) => reject(context));
        });
    },
    getBlockCloneContent(blockId) {
        return baseAPI.get(apiUrl + '/blocks/' + blockId + '/clone');
    },
    processBlockChanges(areaId, activeVariantId, formData) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiPut(
                apiUrl + '/areas/' + areaId + '/variants/' + activeVariantId + '/blocks/changes',
                formData
            )
                .then((result) => resolve(result))
                .catch((context) => {
                    getEnv('APP_ENV') === Constants.environments.dev && console.log('unable to finish area ', context);
                    reject(context);
                });
        });
    },
    resetBlockChanges(areaId, activeVariantId, formData) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiPut(
                apiUrl + '/areas/' + areaId + '/variants/' + activeVariantId + '/blocks/reset',
                formData
            )
                .then((result) => resolve(result))
                .catch((context) => {
                    reject(context);
                });
        });
    },
    getArea(id) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiGet(apiUrl + '/areas/' + id)
                .then((result) => resolve(result))
                .catch((context) => {
                    getEnv('APP_ENV') === Constants.environments.dev &&
                        console.log('error getting area blocks ', context);
                    reject(context);
                });
        });
    },
    /**
     * @param areaId
     * @param variantId
     * @param includeTagHistory if this is true, the changes of the tag since the beginning of the activerevision are included.
     * @returns {Promise<unknown>}
     */
    getAreaBlocks(areaId, variantId, includeTagHistory = false) {
        let queryString = '';
        if (includeTagHistory) {
            queryString = '?includeTagChanges=true';
        }

        return ApiConnector.apiGet(`${apiUrl}/areas/${areaId}/variants/${variantId}/blocks${queryString}`).catch(
            ApiConnector.handleApiError('error getting area blocks')
        );
    },
    getTagSearch(searchQuery, tagField, organisationId, documentId = null, variantId = null) {
        const url = `${apiUrl}/${
            documentId ? `documents/${documentId}` : `organisations/${organisationId}`
        }/search/tags`;

        return ApiConnector_v2.get(url, { params: { q: searchQuery, tagField, variantId } });
    },
    getDocument(id, loadTags = false, prefix = '') {
        return new Promise((resolve, reject) => {
            if (id === null) {
                reject('no id provided');
            }

            ApiConnector.apiGet(apiUrl + prefix + '/documents/' + id)
                .then((document) => {
                    if (document.activeRevisionId !== null) {
                        document.activeRevision = HelperFunctions.getByValue(
                            document.revisions,
                            'id',
                            document.activeRevisionId
                        );
                    } else {
                        document.activeRevision = null;
                    }

                    if (loadTags) {
                        new Promise((resolve1) => {
                            this.getDocumentTags(id).then((tags) => {
                                resolve1(tags);
                            });
                        }).then((tags) => {
                            const documentWithTags = {
                                ...document,
                                tags,
                            };

                            resolve(documentWithTags);
                        });
                    } else {
                        resolve(document);
                    }
                })
                .catch((context) => {
                    getEnv('APP_ENV') === Constants.environments.dev &&
                        console.log('unable to get document details ', context);
                    reject(context);
                });
        });
    },
    getDocumentTags(documentId) {
        return new Promise((resolve, reject) => {
            if (documentId === undefined) {
                reject();
            }
            ApiConnector.apiGet(apiUrl + '/documents/' + documentId + '/tags')
                .then((result) => resolve(result))
                .catch((context) => {
                    getEnv('APP_ENV') === Constants.environments.dev &&
                        console.log('unable to load tags for document ', documentId, context);
                    reject(context);
                });
        });
    },
    getDocumentVariantsHistory(documentId, params = []) {
        return new Promise((resolve, reject) => {
            if (documentId === undefined) {
                reject();
            }
            ApiConnector.apiGet(apiUrl + '/documents/' + documentId + '/variants/history', undefined, params)
                .then((result) => resolve(result))
                .catch((context) => {
                    getEnv('APP_ENV') === Constants.environments.dev &&
                        console.log('unable to load variants for document ', documentId, context);
                    reject(context);
                });
        });
    },
    getDocumentVariants(documentId) {
        return new Promise((resolve, reject) => {
            if (documentId === undefined) {
                reject();
            }
            ApiConnector.apiGet(apiUrl + '/documents/' + documentId + '/variants')
                .then((result) => resolve(result))
                .catch((context) => {
                    getEnv('APP_ENV') === Constants.environments.dev &&
                        console.log('unable to load variants for document ', documentId, context);
                    reject(context);
                });
        });
    },
    getTableTemplates() {
        return new Promise((resolve, reject) => {
            const organisationId = getActiveOrganisation();

            ApiConnector.apiGet(apiUrl + '/organisations/' + organisationId + '/documents/tabletemplates')
                .then((result) => resolve(result))
                .catch((context) => {
                    getEnv('APP_ENV') === Constants.environments.dev &&
                        console.log('unable to load table templates', context);
                    reject(context);
                });
        });
    },
    toggleAreaLock(areaId, lockOrUnlock, data) {
        if (lockOrUnlock === 'lock' || lockOrUnlock === 'unlock') {
            return new Promise((resolve, reject) => {
                ApiConnector.apiPut(apiUrl + '/areas/' + areaId + '/' + lockOrUnlock, data)
                    .then((result) => resolve(result))
                    .catch((context) => {
                        getEnv('APP_ENV') === Constants.environments.dev &&
                            console.log('error while locking/unlocking area ', context);
                        reject(context);
                    });
            });
        }
    },
    updateBlocks(areaId, variantId, formData) {
        return new Promise((resolve, reject) => {
            ApiConnector.apiPut(apiUrl + '/areas/' + areaId + '/variants/' + variantId + '/blocks', formData)
                .then((result) => resolve(result))
                .catch((context) => {
                    getEnv('APP_ENV') === Constants.environments.dev &&
                        console.log('unable to update blocks ', context);
                    reject(context);
                });
        });
    },
};

export default DocumentApi;
