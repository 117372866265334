import { Form as FForm, Formik } from 'formik';
import * as Yup from 'yup';
import { FormGroup } from 'components/SettingsSidebar/helpers/FormHelpers';
import { InputField, InputSelect } from 'pages/publications_v2/helpers/FieldHelper';
import { SecondaryButton, WarningButton } from 'components/Buttons';
import Spinner from 'pages/global/Spinner';
import HelperFunctions from 'pages/global/HelperFunctions';
import { useGetDepartments } from 'pages/task_team/hooks/useGetDepartments';
import { useUpdateTaskMutation } from 'features/comments/commentApi';
import { CheckBox, MultipleSelect, prepareTaskFormData, TextArea } from 'pages/task_team/helpers/FormHelper';
import { useGetBrands } from 'pages/task_team/hooks/useGetBrands';
import { useGetCustomerJourneys } from 'pages/task_team/hooks/useGetCustomerJourneys';
import { useGetTaskTypes } from 'pages/task_team/hooks/useGetTaskTypes';
import { AudienceTopicSelect } from 'pages/task_team/modals/add_check_modal/Step2Properties';
import { useGetTaskUsers } from 'pages/task_team/hooks/useGetTaskUsers';

export function EditProperties({ task, close }) {
    const [updateTask] = useUpdateTaskMutation();
    const departments = useGetDepartments();
    const users = useGetTaskUsers('client');
    const brands = useGetBrands();
    const customerJourneys = useGetCustomerJourneys();
    const taskTypes = useGetTaskTypes();

    return (
        <Formik
            initialValues={{
                title: task.title,
                client: task.client,
                explicitConsent: task.explicitConsent,
                confidential: task.confidential,
                description: task.description,
                department: task.department ? task.department['@id'] : '',
                customerJourney: task.customerJourney ? task.customerJourney['@id'] : '',
                taskType: task.taskType ? task.taskType['@id'] : '',
                brands: task.brands ? task.brands.map((brand) => brand['@id']) : [],
                topics: task.topics ? task.topics.map((topic) => topic['@id']) : [],
                targetAudiences: task.targetAudiences
                    ? task.targetAudiences.map((targetAudience) => targetAudience['@id'])
                    : [],
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            {({ isValid, isSubmitting, dirty }) => (
                <FForm>
                    <div className="border-bottom mb-3 pb-1">
                        <div className="font-weight-bold">Eigenschappen</div>
                    </div>

                    <div className="sidebar-form">
                        <FormGroup label="Naam*" htmlFor="title" help="De naam van de uiting">
                            <InputField
                                name="title"
                                props={{
                                    maxLength: 255,
                                    required: true,
                                    id: 'title',
                                    placeholder: 'De naam van de uiting',
                                }}
                            />
                        </FormGroup>

                        <FormGroup
                            label="Opdrachtgever*"
                            htmlFor="client"
                            help="Wie is de opdrachtgever van deze uiting?"
                        >
                            <InputSelect
                                name="client"
                                options={HelperFunctions.prepareDropdownData(users, 'fullName', 'userId')}
                            />
                        </FormGroup>

                        <FormGroup label="Afdeling*" htmlFor="department" help="">
                            <InputSelect
                                name="department"
                                options={HelperFunctions.prepareDropdownData(departments, 'name', '@id')}
                            />
                        </FormGroup>

                        <AudienceTopicSelect />

                        <FormGroup
                            label="Voor welk merk is de uiting?*"
                            htmlFor="brands"
                            help="Welk(e) merk(en) zijn betrokken bij deze uiting?"
                        >
                            <MultipleSelect options={brands} name="brands" />
                        </FormGroup>

                        <FormGroup
                            label="Expliciete toestemming"
                            htmlFor="explicitConsent"
                            help="Vraagt de uiting om uitdrukkelijke toestemming voor het gebruik van bijzondere persoonsgegevens?"
                        >
                            <CheckBox name="explicitConsent" label="De uiting vraagt om uitdrukkelijke toestemming" />
                        </FormGroup>

                        <FormGroup label="Classificatie" htmlFor="classification" help="">
                            <CheckBox name="confidential" label="Onder embargo" />
                        </FormGroup>

                        <FormGroup
                            label="Doel van de uiting*"
                            htmlFor="description"
                            help="Geef een korte omschrijving van het doel van de uiting"
                        >
                            <TextArea
                                name="description"
                                props={{
                                    required: true,
                                    placeholder: 'Doel van de uiting...',
                                }}
                            />
                        </FormGroup>

                        <FormGroup label="Klantreis*" htmlFor="customerJourney" help="">
                            <InputSelect
                                name="customerJourney"
                                options={HelperFunctions.prepareDropdownData(customerJourneys, 'name', '@id')}
                            />
                        </FormGroup>

                        <FormGroup label="Type uiting*" htmlFor="taskType" help="">
                            <InputSelect
                                name="taskType"
                                options={HelperFunctions.prepareDropdownData(taskTypes, 'name', '@id')}
                            />
                        </FormGroup>

                        <div className="d-flex align-items-center justify-content-end mt-4">
                            {isSubmitting && <Spinner />}

                            <SecondaryButton disabled={isSubmitting} onClick={close}>
                                Annuleer
                            </SecondaryButton>

                            <WarningButton disabled={isSubmitting || !isValid || !dirty} className="ml-2" type="submit">
                                Opslaan
                            </WarningButton>
                        </div>
                    </div>
                </FForm>
            )}
        </Formik>
    );

    function handleSubmit(values) {
        updateTask({ uri: task['@id'], body: prepareTaskFormData(values) }).then(() => {
            close();
        });
    }
}

const validationSchema = Yup.object().shape({
    title: Yup.string().required(),
    client: Yup.string().required(),
    department: Yup.string().required(),
    customerJourney: Yup.string().required(),
    topics: Yup.array().min(1),
    targetAudiences: Yup.array().min(1),
    brands: Yup.array().min(1),
    description: Yup.string().required(),
    taskType: Yup.string().required(),
});
