import { Dropdown } from 'react-bootstrap';
import { ThreeDots } from 'react-bootstrap-icons';

export function MoreActionsDropDown({ id, onSelect, children }) {
    return (
        <Dropdown onSelect={onSelect}>
            <Dropdown.Toggle bsPrefix="more-actions-dropdown-toggle" as="span" id={id}>
                <ThreeDots size={16} />
            </Dropdown.Toggle>

            <Dropdown.Menu>{children}</Dropdown.Menu>
        </Dropdown>
    );
}
