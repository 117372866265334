import { PaginatedTable, TableProvider } from 'components/PaginatedTable';
import { ArrowUpShort, PersonAdd } from 'react-bootstrap-icons';
import { useGetTaskTeamAssignments } from 'pages/task_team/hooks/useGetTaskTeamAssignments';
import { useGetUserFullName } from 'hooks/useGetUserFullName';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParam } from 'use-query-params';
import { GenericButton, PrimaryLinkButton } from 'components/Buttons';
import { TaskAssignmentStatusBadge } from 'pages/task_team/views/checks/Reviews';
import { taskTeamAssignmentStatus } from 'pages/task_team/config/constants';
import { useCanAssignReviewerPermission } from 'pages/task_team/hooks/permissions';
import {
    EmptyState,
    EmptyStateActions,
    EmptyStateBody,
    EmptyStateHeader,
    EmptyStateIcon,
    RocketIcon,
} from 'components/EmptyState';
import { generatePath } from 'react-router-dom';
import { CHECKS_PATH, PAGINATION_ITEMS_PER_PAGE } from 'scenes/TaskTeam';

export const UNASSIGNED_FILTER_PARAM = 'unassigned_filters';

export function Unassigned() {
    const defaultFilterOptions = useGetDefaultUnassignedFilterOptions();
    const {
        items = [],
        totalItems = 0,
        isEmpty,
    } = useGetTaskTeamAssignments(UNASSIGNED_FILTER_PARAM, defaultFilterOptions);

    return (
        <div>
            <div className="subheader">
                <h3>Niet toegewezen</h3>
            </div>

            <TableProvider>
                <PaginatedTable
                    items={items}
                    totalItems={totalItems}
                    clientSidePagination={false}
                    itemsPerPage={PAGINATION_ITEMS_PER_PAGE}
                    searchPlaceholder="Zoek op titel of nummer..."
                    colSpan={6}
                    pageParamPrefix={UNASSIGNED_FILTER_PARAM}
                    searchParamPrefix={UNASSIGNED_FILTER_PARAM}
                >
                    {({ items = [] }) => (
                        <>
                            <thead>
                                <tr>
                                    <th>
                                        # <ArrowUpShort />
                                    </th>
                                    <th>Titel</th>
                                    <th>Team</th>
                                    <th>Status</th>
                                    <th>Acties</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isEmpty ? (
                                    <NoUnassigned />
                                ) : (
                                    <>
                                        {items.map((assignment) => (
                                            <AssignmentRow
                                                assignment={assignment}
                                                key={`assignment-${assignment.id}`}
                                            />
                                        ))}
                                    </>
                                )}
                            </tbody>
                        </>
                    )}
                </PaginatedTable>
            </TableProvider>
        </div>
    );
}

function AssignmentRow({ assignment }) {
    const { t, i18n } = useTranslation();
    const [, setViewTask] = useQueryParam('viewTask', StringParam);
    const [, setAssignUser] = useQueryParam('assignTask', StringParam);
    const { task, team, taskVersion } = assignment;
    const createdByName = useGetUserFullName(task.createdBy, false);
    const createdAt = DateTime.fromISO(task.createdAt).setLocale(i18n.language).toLocaleString(DateTime.DATE_MED);
    const canAssignReviewerPermission = useCanAssignReviewerPermission();

    return (
        <tr>
            <td>
                #{task.sequence}-{taskVersion.versionNumber}
            </td>
            <td>
                <div>
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            setViewTask(task.id);
                        }}
                    >
                        {task.title}
                    </a>
                </div>
                <div>
                    {createdByName}
                    <span className="text-muted">&nbsp;&bull; {createdAt}</span>
                </div>
            </td>
            <td>{team.name}</td>
            <td>
                <TaskAssignmentStatusBadge assignment={assignment} />
            </td>
            <td className="actions">
                <div>
                    <GenericButton
                        className="d-flex align-items-center flex-shrink-0"
                        variant="link"
                        onClick={() => setAssignUser(assignment.id)}
                        disabled={!canAssignReviewerPermission}
                    >
                        <PersonAdd className="flex-shrink-0 mr-2" size={16} />
                        <div>Toewijzen</div>
                    </GenericButton>
                </div>
            </td>
        </tr>
    );
}

function NoUnassigned() {
    return (
        <tr>
            <td colSpan={6}>
                <EmptyState>
                    <EmptyStateHeader titleText={'Niks toe te wijzen'} icon={<EmptyStateIcon icon={RocketIcon} />} />
                    <EmptyStateBody>
                        Zodra er uitingen zijn die jij moet toewijzen verschijnen die op deze pagina.
                    </EmptyStateBody>
                    <EmptyStateActions>
                        <PrimaryLinkButton
                            to={generatePath(CHECKS_PATH, {
                                page: 'all',
                            })}
                        >
                            Naar alle uitingen
                        </PrimaryLinkButton>
                    </EmptyStateActions>
                </EmptyState>
            </td>
        </tr>
    );
}

export function useGetDefaultUnassignedFilterOptions() {
    return {
        status: taskTeamAssignmentStatus.STATUS_UNASSIGNED,
    };
}
