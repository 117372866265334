import { createContext, useEffect, useState } from 'react';
import { actions } from '../config/views';
import CheckAreaModal from '../../documents/misc/CheckArea';
import { useParams } from 'react-router-dom';
import { useGetDocumentVariantsQuery } from '../../../features/documents/documents';
import { EditArea } from './edit_area/EditArea';
import { ViewArea } from "./edit_area/ViewArea";
import {EditAreaOneLiners} from "./edit/one_liners/edit_area/EditAreaOneLiners";

export type AreaContextType = {
    activeVariantId: number;
    setActiveVariantId: (id: number) => void;
    draggedItemKey?: number;
    setDraggedItemKey: (id: number | undefined) => void;
};

export const AreaContext = createContext<AreaContextType | null>(null);

export function Area({ view }: { view: string }) {
    const { documentId }: { documentId: string } = useParams();
    const [activeVariantId, setActiveVariantId] = useState(0);
    const [draggedItemKey, setDraggedItemKey] = useState<number | undefined>(undefined);

    const { baseVariant } = useGetDocumentVariantsQuery(parseInt(documentId), {
        selectFromResult: ({ data }) => ({
            baseVariant: data ? data.find((variant) => variant.parentId === null) : undefined,
        }),
    });

    useEffect(() => {
        if (baseVariant && activeVariantId === 0) {
            setActiveVariantId(baseVariant.id);
        }
    }, [baseVariant]);

    return (
        <AreaContext.Provider value={{ activeVariantId, setActiveVariantId, draggedItemKey, setDraggedItemKey }}>
            {view === actions.EDIT && <EditArea />}

            {view === actions.CHECK && <CheckAreaModal />}

            {view === actions.VIEW && <ViewArea />}

            {view === actions.EDIT_ONE_LINERS && <EditAreaOneLiners />}
        </AreaContext.Provider>
    );
}
