import React, { useEffect, useState } from 'react';
import HelperFunctions from '../../global/HelperFunctions';
import { ExclamationCircle } from 'react-bootstrap-icons';
import { LoadingSpinner } from '../../global/Spinner';
import { DateTime } from 'luxon';
import { InfoButton, WarningButton } from '../../../components/Buttons';
import { useTranslation } from 'react-i18next';

export default function ProductForm(props) {
    const { formData, disableSubmitBtn, category, handleFormChange, saveInProgress } = props;
    const [selectedProduct, setSelectedProduct] = useState('');
    const [enableCodeEdit, setEnableCodeEdit] = useState(false);
    const isEditMode = formData.id !== null;
    const productList = [];
    const { t } = useTranslation('reimbursements');

    useEffect(() => {
        if (formData.id !== null) {
            setEnableCodeEdit(false);
        }
    }, [formData.id]);

    const addRelatedProduct = () => {
        props.addRelatedProduct(selectedProduct, props.formData['@id']);
        setSelectedProduct('');
    };

    const cancelEdit = () => {
        setSelectedProduct('');
        setEnableCodeEdit(false);

        props.cancelEdit();
    };

    const productAsList = (product, currentProductId, list = [], category, depth = 0) => {
        if (product.id === currentProductId) {
            return list;
        }

        if (product.hasOwnProperty('parent') === false || product.parent === null || depth > 0) {
            let name = '';

            if (depth > 0) {
                for (let i = 0; i < depth; i++) {
                    name += '-- ';
                }
            }

            if (product.code) {
                name += '(' + product.code + ') ';
            }

            name += product.name;

            list.push({
                '@id': product['@id'],
                id: product.id,
                name: name,
                depth: depth,
                productGroup: product.productGroup,
                enabled: product.enabled,
            });
        }

        if (product.children.length > 0) {
            product.children.forEach((childProduct) => {
                productAsList(childProduct, currentProductId, list, category, depth + 1);
            });
        }

        return list;
    };

    const selectProduct = (event) => {
        setSelectedProduct(event.target.value);
    };

    if (category) {
        category.products.forEach((product) => {
            productAsList(product, formData.id, productList, category);
        });
    }

    return (
        <div className="uk-padding-top">
            <form className="uk-form-horizontal" onSubmit={props.handleFormSubmit} autoComplete="off">
                <div className="uk-margin-remove line-height-button">
                    <div className="uk-text-primary uk-margin-right">{t('policy.form.title')}</div>

                    <div className="uk-margin-small">
                        <label className="uk-form-label" htmlFor="name">
                            {t('policy.form.name')}*
                        </label>
                        <div className="uk-form-controls">
                            <input
                                required
                                type="text"
                                id="name"
                                name="name"
                                className="uk-input"
                                value={formData.name}
                                onChange={handleFormChange}
                            />
                        </div>
                    </div>
                    <div className="uk-margin-small">
                        <label className="uk-form-label" htmlFor="code">
                            {t('policy.form.code')}*
                        </label>
                        <div className="uk-form-controls">
                            {formData.id === null || enableCodeEdit ? (
                                <input
                                    required
                                    type="text"
                                    id="code"
                                    name="code"
                                    className="uk-input"
                                    value={formData.code}
                                    onChange={handleFormChange}
                                />
                            ) : (
                                <div style={{ height: 37 }} className="pl-2">
                                    <span className="mr-2">{formData.code}</span>
                                    <span
                                        className="text-primary small cursor-pointer mr-1"
                                        onClick={() => setEnableCodeEdit(true)}
                                    >
                                        {t('policy.form.editCode')}
                                    </span>
                                    <ExclamationCircle
                                        className="text-primary"
                                        data-uk-tooltip={t('policy.form.tooltip.errorChanges')}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="uk-margin-small">
                        <label className="uk-form-label" htmlFor="description">
                            {t('policy.form.description')}
                        </label>
                        <div className="uk-form-controls">
                            <input
                                type="text"
                                id="description"
                                name="description"
                                className="uk-input"
                                value={formData.description}
                                onChange={handleFormChange}
                            />
                        </div>
                    </div>
                    <div className="uk-margin-small">
                        <label className="uk-form-label" htmlFor="abbreviation">
                            {t('policy.form.abbreviation')}
                        </label>
                        <div className="uk-form-controls">
                            <input
                                type="text"
                                id="abbreviation"
                                name="abbreviation"
                                className="uk-input"
                                value={formData.abbreviation}
                                onChange={handleFormChange}
                            />
                        </div>
                    </div>
                    <div className="uk-margin-small">
                        <label className="uk-form-label" htmlFor="productType">
                            {t('policy.form.productType')}
                        </label>
                        <div className="uk-form-controls">
                            <select
                                className="uk-select"
                                value={formData.productType ? formData.productType : ''}
                                onChange={handleFormChange}
                                name="productType"
                                id="productType"
                            >
                                <option value="">-</option>
                                {category.productTypes
                                    .filter((productType) => {
                                        return productType.enabled;
                                    })
                                    .map((productType) => {
                                        return (
                                            <option value={productType['@id']} key={'productTypeId-' + productType.id}>
                                                {productType.code ? '(' + productType.code + ') ' : ''}
                                                {productType.name}
                                            </option>
                                        );
                                    })}
                            </select>
                        </div>
                    </div>
                    <div className="uk-margin-small">
                        <label className="uk-form-label" htmlFor="parent">
                            {t('policy.form.positionUnder')}
                        </label>
                        <div className="uk-form-controls">
                            <select
                                className="uk-select"
                                value={formData.parent ? formData.parent : ''}
                                onChange={handleFormChange}
                                name="parent"
                                id="parent"
                            >
                                <option value="">-</option>
                                {productList
                                    .filter((product) => {
                                        return product.productGroup;
                                    })
                                    .map((product) => {
                                        return (
                                            <option
                                                disabled={!product.enabled}
                                                value={product['@id']}
                                                key={'productId-' + product.id}
                                            >
                                                {product.name}
                                            </option>
                                        );
                                    })}
                            </select>
                        </div>
                    </div>
                    <div className="uk-margin-small">
                        <label className="uk-form-label" htmlFor="identicalTo">
                            {t('policy.form.identicalTo')}
                        </label>
                        <div className="uk-form-controls">
                            <select
                                className="uk-select"
                                value={formData.identicalTo ? formData.identicalTo : ''}
                                onChange={handleFormChange}
                                name="identicalTo"
                                id="identicalTo"
                            >
                                <option value="">-</option>
                                {productList.map((product) => {
                                    return (
                                        <option
                                            disabled={!product.enabled || product.productGroup}
                                            value={product['@id']}
                                            key={'productId-' + product.id}
                                        >
                                            {product.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="uk-margin-small">
                        <label className="uk-form-label" htmlFor="productGroup">
                            {t('policy.form.group')}
                        </label>
                        <div className="uk-form-controls">
                            <input
                                type="checkbox"
                                checked={formData.productGroup}
                                disabled={isEditMode}
                                id="productGroup"
                                name="productGroup"
                                className="uk-checkbox"
                                onChange={handleFormChange}
                            />
                        </div>
                    </div>
                    <div className="uk-margin-small">
                        <label className="uk-form-label" htmlFor="startDate">
                            {t('policy.form.startingDate')}
                        </label>
                        <div className="uk-form-controls">
                            <input
                                type="date"
                                id="startDate"
                                name="startDate"
                                className="uk-input"
                                value={
                                    formData.startDate
                                        ? DateTime.fromISO(formData.startDate).toFormat('yyyy-MM-dd')
                                        : ''
                                }
                                onChange={(event) =>
                                    handleFormChange({
                                        target: {
                                            name: 'startDate',
                                            value: event.target.value === '' ? null : event.target.value,
                                        },
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div className="uk-margin-small">
                        <label className="uk-form-label" htmlFor="endDate">
                            {t('policy.form.endDate')}
                        </label>
                        <div className="uk-form-controls">
                            <input
                                type="date"
                                id="endDate"
                                name="endDate"
                                className="uk-input"
                                value={
                                    formData.endDate ? DateTime.fromISO(formData.endDate).toFormat('yyyy-MM-dd') : ''
                                }
                                onChange={(event) =>
                                    handleFormChange({
                                        target: {
                                            name: 'endDate',
                                            value: event.target.value === '' ? null : event.target.value,
                                        },
                                    })
                                }
                            />
                        </div>
                    </div>

                    {isEditMode && !formData.productGroup && (
                        <div className="uk-margin-small uk-padding-top">
                            <h4 className="mb-2">{t('policy.form.collectiveAdditions')}</h4>

                            {formData.extendedProducts.length > 0 && (
                                <div>
                                    <table className="uk-table uk-table-striped uk-table-small">
                                        <thead>
                                            <tr>
                                                <th>{t('policy.form.name')}</th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {formData.extendedProducts.map((productLink) => {
                                                const baseProduct = HelperFunctions.getByValue(
                                                    category.products,
                                                    '@id',
                                                    productLink.baseProduct
                                                );

                                                if (baseProduct === undefined) {
                                                    return null;
                                                }
                                                // It will be removed upon save. This is a virtually deleted item, so don't show.
                                                if (productLink.remove) {
                                                    return null;
                                                }

                                                return (
                                                    <tr key={`relatedProduct-${baseProduct.id}`}>
                                                        <td className="uk-table-expand">
                                                            {baseProduct.code && (
                                                                <span className="uk-text-muted">
                                                                    {baseProduct.code}{' '}
                                                                </span>
                                                            )}
                                                            {baseProduct.name}
                                                        </td>
                                                        <td>
                                                            <span
                                                                className="uk-text-primary uk-cursor-pointer uk-margin-small-right"
                                                                data-uk-icon={'icon: trash'}
                                                                onClick={() => props.removeRelatedProduct(productLink)}
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            )}

                            <div className="uk-padding-small-top uk-margin-medium-bottom uk-clearfix">
                                <select
                                    id="relatedProduct"
                                    name="relatedProduct"
                                    className="uk-select uk-form-width-medium uk-margin-small-right"
                                    value={selectedProduct}
                                    onChange={selectProduct}
                                >
                                    <option>-</option>
                                    {productList.map((product) => {
                                        const existingItem = HelperFunctions.getByValue(
                                            formData.extendedProducts,
                                            'baseProduct',
                                            product['@id']
                                        );
                                        let alreadyInExtendedProducts = existingItem !== undefined;

                                        // If the item that exists in the extendedProducts array is marked to be removed it isn't shown in the productLink list
                                        // and it is allowed to be added (again). So set alreadyInExtendedProducts to false
                                        if (existingItem && existingItem.remove) {
                                            alreadyInExtendedProducts = false;
                                        }

                                        return (
                                            <option
                                                disabled={
                                                    alreadyInExtendedProducts ||
                                                    product.id === formData.id ||
                                                    product.productGroup ||
                                                    !product.enabled
                                                }
                                                value={product['@id']}
                                                key={'selectRelatedProduct' + product.id}
                                            >
                                                {product.name}
                                            </option>
                                        );
                                    })}
                                </select>
                                <InfoButton type="button" onClick={addRelatedProduct} disabled={selectedProduct === ''}>
                                    {t('policy.form.btn.add')}
                                </InfoButton>
                            </div>
                        </div>
                    )}

                    <WarningButton type="submit" disabled={disableSubmitBtn}>
                        {t('btn.save')}
                    </WarningButton>

                    {isEditMode && (
                        <a className="uk-margin-left line-height-button" onClick={cancelEdit}>
                            {t('btn.cancel')}
                        </a>
                    )}
                    <LoadingSpinner isSubmitting={saveInProgress} />
                </div>
            </form>
        </div>
    );
}
