import { useEffect, useState } from 'react';

export function PdfViewer({ data }) {
    const [pdf, setPdf] = useState();

    useEffect(() => {
        const blob = new Blob([data], { type: 'application/pdf' });
        setPdf(URL.createObjectURL(blob));
    }, []);

    if (!pdf) {
        return null;
    }

    return <embed title="pdf" src={pdf} style={{ height: '1250px', width: '100%' }}></embed>;
}
