import { InputField } from 'pages/publications_v2/helpers/FieldHelper';
import { FormGroup } from 'components/SettingsSidebar/helpers/FormHelpers';
import { EditMetadata } from 'components/SettingsSidebar/views/EditMetadata';
import { useSidebarContext } from 'components/SettingsSidebar/hooks/useSidebarContext';

export function Category() {
    const { entity } = useSidebarContext();
    const { isGroup = true } = entity;

    return (
        <div className="sidebar-form">
            <FormGroup label="Naam *" htmlFor="name" help="De naam van de categorie.">
                <InputField
                    name="settings.name"
                    props={{
                        maxLength: 255,
                        required: true,
                        id: 'name',
                        placeholder: 'Naam van de categorie',
                    }}
                />
            </FormGroup>

            {!isGroup && (
                <div className="pt-3">
                    <EditMetadata allowEmptyValues={true} showMetadataForm={true} />
                </div>
            )}
        </div>
    );
}
