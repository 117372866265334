import { Button, Dropdown, Nav } from 'react-bootstrap';
import { CollectionFill, FolderFill, PlusCircle, ThreeDotsVertical, TrashFill } from 'react-bootstrap-icons';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    countFiles,
    deleteFolder,
    getFolders,
    removeFolder,
    selectFolders,
} from '../../../features/mediaLibrary/mediaLibrarySlice';
import HelperFunctions from '../../global/HelperFunctions';
import { useHistory, useRouteMatch, generatePath } from 'react-router-dom';
import cx from 'classnames';
import DropdownMenu from '../../global/DropdownMenu';
import { useTranslation } from 'react-i18next';

export const FOLDER_TRASH = 'trash';

export default function Folders({ setShowModal }) {
    const history = useHistory();
    const { path } = useRouteMatch();
    const rootFolders = useSelector(selectFolders(null));
    const selectedFolderId = useSelector((state) => state.mediaLibrary.selectedFolder);
    const dispatch = useDispatch();
    const trashCount = useSelector(countFiles(FOLDER_TRASH));
    const activeOrganisation = useSelector((state) => state.security.activeOrganisation);
    const { t } = useTranslation('global');

    useEffect(() => {
        dispatch(getFolders({ organisationId: activeOrganisation }));
    }, [activeOrganisation]);

    return (
        <div className="py-4">
            <Nav className="folder-nav flex-column mb-3">
                <Nav.Link
                    className="nav-dropdown-hover d-flex align-items-center"
                    active={selectedFolderId === null}
                    onClick={() => {
                        history.push(generatePath(`${path}`));
                    }}
                >
                    <div className="font-weight-bolder">
                        <CollectionFill size={14} className="mr-2" />
                        {t('contentSidebar.all')}
                    </div>

                    <Button
                        variant="link"
                        className="ml-auto text-body p-0"
                        onClick={() => {
                            setShowModal({});
                        }}
                        data-uk-tooltip={`${t('contentSidebar.map.chaptersMap')}...`}
                    >
                        <PlusCircle size={14} />
                    </Button>
                </Nav.Link>
                <Nav.Link
                    disabled={trashCount === 0}
                    className={cx('d-flex justify-content-between', {
                        'text-muted': trashCount === 0,
                    })}
                    active={selectedFolderId === FOLDER_TRASH}
                    onClick={() => {
                        history.push(
                            generatePath('/media-library/folder/:folder', {
                                folder: FOLDER_TRASH,
                            })
                        );
                    }}
                >
                    <div className="font-weight-bolder">
                        <TrashFill size={14} className="mr-2" />
                        {t('contentSidebar.trash')}
                    </div>
                </Nav.Link>
            </Nav>

            {rootFolders.map((_rootFolder) => (
                <RootFolder folder={_rootFolder} setShowModal={setShowModal} key={`root-folder-${_rootFolder.id}`} />
            ))}
        </div>
    );
}

function RootFolder({ folder, setShowModal }) {
    const childFolders = useSelector(selectFolders(folder['@id']));

    return (
        <div className="mb-3">
            <div
                className="nav-dropdown-hover d-flex align-items-center mb-1"
                style={{
                    padding: '0.6rem 1.15rem 0.4rem 1.5rem',
                }}
            >
                <span className="font-weight-bolder cursor-pointer sidebar-titles">{folder.name}</span>

                {folder.id !== null && (
                    <FolderActions setShowModal={setShowModal} folder={folder} childFolders={childFolders} />
                )}
            </div>

            <Nav className="folder-nav flex-column">
                {childFolders.map((_folder, index) => (
                    <Folder
                        index={index}
                        folder={_folder}
                        setShowModal={setShowModal}
                        key={`media-folders-${_folder.id}`}
                    />
                ))}
            </Nav>
        </div>
    );
}

function Folder({ folder, setShowModal }) {
    const history = useHistory();
    const selectedFolderId = useSelector((state) => state.mediaLibrary.selectedFolder);
    const isActive = selectedFolderId === folder.id;

    return (
        <Nav.Link
            as="div"
            className="d-flex align-items-center nav-dropdown-hover"
            active={isActive}
            onClick={() => {
                history.push(
                    generatePath('/media-library/folder/:folder', {
                        folder: folder.id,
                    })
                );
            }}
        >
            <div className="small">{folder.name}</div>

            <FolderActions setShowModal={setShowModal} folder={folder} />
        </Nav.Link>
    );
}

function FolderActions({ setShowModal, folder, childFolders = [] }) {
    const dispatch = useDispatch();
    const fileCount = useSelector(countFiles(folder.id));
    const { t } = useTranslation('global');

    const handleSelect = (eventKey) => {
        switch (eventKey) {
            case 'insert':
                setShowModal({ parentFolder: folder['@id'] });
                break;
            case 'edit':
                setShowModal(folder);
                break;
            case 'delete':
                HelperFunctions.confirmModal(
                    `${t('contentSidebar.map.confirmDelete', { name: folder.name })}`,
                    'danger',
                    false,
                    t('contentSidebar.map.yesDelete'),
                    t('btn.cancel')
                ).then(() => {
                    dispatch(removeFolder(folder['@id']));
                    dispatch(
                        deleteFolder({
                            uri: folder['@id'],
                        })
                    );
                });
                break;
        }
    };

    const canDelete = () => {
        return childFolders.length === 0 && fileCount === 0;
    };

    return (
        <div className="ml-auto">
            <Dropdown onSelect={handleSelect} className="mr-1">
                <Dropdown.Toggle bsPrefix="docrev-dropdown-toggle" as="span" id="dropdown-basic">
                    <ThreeDotsVertical size={16} />
                </Dropdown.Toggle>

                <DropdownMenu>
                    {folder.parentFolder === null && (
                        <Dropdown.Item eventKey="insert">
                            {t('contentSidebar.map.menu.subfolder')}&hellip;
                        </Dropdown.Item>
                    )}
                    <Dropdown.Item eventKey="edit">{t('contentSidebar.map.title.edit')}</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item eventKey="delete" disabled={!canDelete()}>
                        <span className={canDelete() ? 'text-danger' : 'text-muted'}>
                            {t('contentSidebar.map.menu.delete')}&hellip;
                        </span>
                    </Dropdown.Item>
                </DropdownMenu>
            </Dropdown>
        </div>
    );
}
