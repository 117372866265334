import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { useGetLabelFoldersQuery, useGetLabelsQuery } from 'features/metadata/metadata';

const emptyArray = [];

export function useGetAvailableTags() {
    const activeOrganisation = useActiveOrganisation();

    const { labelFolders = [] } = useGetLabelFoldersQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                labelFolders: data
                    ? data.filter((folder) => folder.contentTypes.includes('comments')).map((folder) => folder['@id'])
                    : emptyArray,
            }),
        },
    );

    const { availableTags = [] } = useGetLabelsQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                availableTags: data ? data.filter((label) => labelFolders.includes(label.folder)) : emptyArray,
            }),
            skip: labelFolders.length === 0,
        },
    );

    return availableTags;
}
