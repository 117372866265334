import { NewItemButton } from 'components/Buttons';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { PaginatedTable, TableProvider } from 'components/PaginatedTable';
import { ArrowDownShort } from 'react-bootstrap-icons';
import { DeleteButton, EditButton } from 'components/ActionButtons';
import HelperFunctions from 'pages/global/HelperFunctions';
import { useDeleteCustomerJourneyMutation } from 'features/comments/commentApi';
import { useTranslation } from 'react-i18next';
import { entityTypes } from 'pages/translation/config/Constants';
import { useShowSettingsSidebar } from 'components/SettingsSidebar/hooks/useShowSettingsSidebar';
import { useGetCustomerJourneys } from 'pages/task_team/hooks/useGetCustomerJourneys';

export function CustomerJourneys() {
    const customerJourneys = useGetCustomerJourneys();
    const [, setAddCustomerJourney] = useQueryParam('addCustomerJourney', BooleanParam);

    return (
        <div>
            <div className="subheader">
                <h3>
                    Klantreizen <span className="text-muted ml-1">{customerJourneys.length}</span>
                </h3>

                <div className="d-flex align-items-center ml-auto">
                    <NewItemButton size="sm" label="Klantreis toevoegen" onClick={() => setAddCustomerJourney(true)} />
                </div>
            </div>

            <TableProvider>
                <PaginatedTable
                    items={customerJourneys}
                    searchPlaceholder="Zoeken..."
                    searchProps={['name']}
                    colSpan={3}
                >
                    {({ items = [] }) => (
                        <>
                            <thead>
                                <tr>
                                    <th>
                                        Naam <ArrowDownShort />
                                    </th>
                                    <th>Acties</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((customerJourney) => (
                                    <CustomerJourney
                                        customerJourney={customerJourney}
                                        key={`customer-journey-${customerJourney.id}`}
                                    />
                                ))}
                            </tbody>
                        </>
                    )}
                </PaginatedTable>
            </TableProvider>
        </div>
    );
}

function CustomerJourney({ customerJourney }) {
    const { t } = useTranslation();
    const [deleteCustomerJourney] = useDeleteCustomerJourneyMutation();
    const [showSettingsSidebar] = useShowSettingsSidebar();

    return (
        <tr>
            <td>{customerJourney.name}</td>
            <td className="actions">
                <div>
                    <EditButton onClick={toggleSidebar} />
                    <DeleteButton onClick={handleDelete} />
                </div>
            </td>
        </tr>
    );

    function toggleSidebar() {
        showSettingsSidebar(customerJourney, entityTypes.CUSTOMER_JOURNEY);
    }

    function handleDelete() {
        HelperFunctions.confirmModal(
            `${customerJourney.name} verwijderen?`,
            'danger',
            false,
            t('global:confirm.yesDelete'),
            t('global:btn.cancel')
        )
            .then(() => {
                deleteCustomerJourney(customerJourney['@id']);
            })
            .catch(() => {});
    }
}
