import React, { Component } from 'react';
import HelperFunctions from '../../global/HelperFunctions';
import { animateScroll as scroll } from 'react-scroll';
import LoadingSpinner from '../../global/LoadingSpinner';
import Constants from '../../../config/Constants';
import deepcopy from 'deepcopy';
import ReimbursementApi from '../../../api/ReimbursementApi';
import LoadingSavedIndicator from '../../global/LoadingSavedIndicator';
import { Col, Container, Dropdown, Row, SplitButton } from 'react-bootstrap';
import { generatePath, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import RestrictedReimbursementContent from '../RestrictedReimbursementContent';
import { BASE_PATH, VIEW_PATH } from 'scenes/Reimbursements';
import { reimbursementApi } from 'features/reimbursements/reimbursements';
import MainContentNav from '../../Navbar';
import SubNav from '../views/SubNav';
import { useGetCategory } from 'features/reimbursements/reimbursementHooks';
import { WarningButton } from 'components/Buttons';
import { useTranslation } from 'react-i18next';
import { useCurrentOrganisation } from 'hooks/useCurrentOrganisation';

export default function SceneUpdateReimbursementCodesAsFunction() {
    const params = useParams();
    const categoryId = parseInt(params.categoryId);
    const dispatch = useDispatch();
    const { category } = useGetCategory(categoryId);
    const { t } = useTranslation('reimbursements');
    const currentOrganisation = useCurrentOrganisation();

    console.log(currentOrganisation);

    if (category === undefined) {
        return <LoadingSpinner />;
    }
    const invalidateTags = (tags = []) => {
        dispatch(reimbursementApi.util.invalidateTags(tags));
    };

    return (
        <RestrictedReimbursementContent
            categoryId={categoryId}
            roles={[
                Constants.reimbursementTeamRoles.admin,
                Constants.reimbursementTeamRoles.manager,
                Constants.reimbursementTeamRoles.finalEditor,
            ]}
        >
            <MainContentNav
                pages={[
                    { title: t('breadcrumb'), url: BASE_PATH },
                    { title: category?.name, url: generatePath(VIEW_PATH, { categoryId }) },
                    { title: t('reimbursementCode.title') },
                ]}
            />

            <SubNav />

            <div className="w-100">
                <div className="content-static-body has-subnav">
                    <div className="d-flex align-items-stretch h-100">
                        <div className="flex-grow-1 overflow-auto pt-5 pb-3">
                            <Container>
                                <Row>
                                    <Col>
                                        <SceneUpdateReimbursementCodes
                                            category={category}
                                            currentOrganisation={currentOrganisation}
                                            invalidateTags={invalidateTags}
                                            t={t}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </div>
            </div>
        </RestrictedReimbursementContent>
    );
}

class SceneUpdateReimbursementCodes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            changesSaved: false,
            category: {
                name: '',
                reimbursementCodes: [],
            },
            disableSubmitBtn: true,
            formData: this.getFormData(),
            formTranslations: [],
            formLanguage: Constants.language.defaultLanguage,
            isLoading: true,
            saveInProgress: false,
        };

        this.translatableFields = ['name'];
        this.requiredFormFields = ['code'];
        this.categoryId = this.props.category.id;
    }

    addEntity = (formData) => {
        if (formData.hasOwnProperty('id')) {
            delete formData.id;
        }

        // Make sure type is int
        if (formData.hasOwnProperty('type')) {
            formData['type'] = parseInt(formData['type']);
        }

        formData['category'] = this.state.category['@id'];

        return new Promise((resolve, reject) => {
            ReimbursementApi.createReimbursementCode(formData)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject();
                });
        });
    };

    cancelEdit = () => {
        this.setState({
            formData: this.getFormData(),
            disableSubmitBtn: true,
            formLanguage: Constants.language.defaultLanguage,
            formTranslations: [],
        });
    };

    changesSaved = () => {
        this.setState(
            {
                changesSaved: true,
                formData: this.getFormData(),
                disableSubmitBtn: true,
                formLanguage: Constants.language.defaultLanguage,
                formTranslations: [],
                saveInProgress: false,
            },
            () => {
                HelperFunctions.changesSaved().then(() => {
                    this.setState({ changesSaved: false });
                });
            }
        );
    };

    componentDidMount = () => {
        this.loadData(this.props.category);
    };

    loadData = (category) => {
        console.log('test2');
        return new Promise((resolve) => {
            ReimbursementApi.getReimbursementCodes(this.categoryId).then((data) => {
                category = {
                    ...category,
                    reimbursementCodes: data['hydra:member'],
                };

                resolve(category);
            });
        }).then(async (category) => {
            await Promise.all(
                category.reimbursementCodes.map((reimbursementCode) => {
                    if (reimbursementCode.translations.length > 0) {
                        return ReimbursementApi.getReimbursementCodeTranslations(reimbursementCode.id).then((data) => {
                            reimbursementCode.translations = data['hydra:member'];
                        });
                    }
                })
            );

            return new Promise((resolve) => {
                this.setState(
                    {
                        category,
                        isLoading: false,
                    },
                    () => {
                        resolve(category);
                    }
                );
            });
        });
    };

    editEntity = (entity) => {
        scroll.scrollToBottom();

        // Store default language
        const formTranslations = entity.hasOwnProperty('translations') ? deepcopy(entity.translations) : [];

        this.translatableFields.forEach((field) => {
            formTranslations.push({
                locale: Constants.language.defaultLanguage,
                field: field,
                content: entity[field],
            });
        });

        this.setState({
            formData: this.getFormData(entity),
            disableSubmitBtn: false,
            formLanguage: Constants.language.defaultLanguage,
            formTranslations,
        });
    };

    getFormData = (entity) => {
        let formData = {
            id: null,
            name: '',
            code: '',
            type: '1',
        };

        if (entity === undefined || entity === null) {
            return formData;
        }

        formData = {
            ...formData,
            id: entity.id,
            name: entity.name,
            code: entity.code,
            type: entity.type,
        };

        return formData;
    };

    handleFormChange = (event) => {
        const target = event.target;

        // Update data
        const formData = this.state.formData;

        let value = target.value;
        // Code must be uppercase, no spaces allowed
        if (target.name === 'code') {
            value = target.value.replace(' ', '_').toUpperCase();
        }
        // Type must be integer, string is not allowed
        if (target.name === 'type') {
            value = parseInt(target.value);
        }

        formData[target.name] = value;

        // Temp save translatable fields
        let formTranslations = this.state.formTranslations;

        if (this.translatableFields.includes(target.name)) {
            let translation = HelperFunctions.findTranslationInArray(
                this.state.formTranslations,
                this.state.formLanguage,
                target.name
            );

            if (translation === undefined) {
                formTranslations.push({
                    locale: this.state.formLanguage,
                    field: target.name,
                    content: value,
                });
            } else {
                translation['content'] = value;
            }
        }

        this.setState({
            formData,
            formTranslations,
            disableSubmitBtn: !HelperFunctions.formIsValid(formData, this.requiredFormFields),
        });
    };

    handleFormLanguageChange = (language) => {
        this.setState({
            formLanguage: language,
        });

        let formData = this.state.formData;

        this.translatableFields.forEach((field) => {
            const translation = HelperFunctions.findTranslationInArray(this.state.formTranslations, language, field);
            formData[field] = translation ? translation['content'] : '';
        });

        this.setState({
            formData: formData,
        });
    };

    handleFormTranslation = async (translations, reimbursementCode) => {
        await Promise.all(
            translations.map((translation) => {
                if (translation.hasOwnProperty('id') === false) {
                    translation.object = reimbursementCode['@id'];
                    return ReimbursementApi.createReimbursementCodeTranslation(translation);
                    // create
                } else {
                    // update
                    return ReimbursementApi.updateReimbursementCodeTranslation(translation.id, translation);
                }
            })
        );
    };

    handleFormSubmit = (event) => {
        event.preventDefault();

        this.setState(
            {
                disableSubmitBtn: true,
                saveInProgress: true,
            },
            () => {
                let formData = this.state.formData;
                let formTranslations = this.state.formTranslations;

                // Put default language in formData
                this.translatableFields.forEach((field) => {
                    const translation = HelperFunctions.findTranslationInArray(
                        formTranslations,
                        Constants.language.defaultLanguage,
                        field
                    );
                    formData[field] = translation ? translation['content'] : '';
                });

                // Remove default language items
                formTranslations = formTranslations.filter((formTranslation) => {
                    return formTranslation['locale'] !== Constants.language.defaultLanguage;
                });

                return new Promise((resolve) => {
                    if (formData.id === null) {
                        // Add new item
                        this.addEntity(formData).then((data) => {
                            resolve(data);
                        });
                    } else {
                        // Update item
                        this.updateEntity(formData).then((data) => {
                            resolve(data);
                        });
                    }
                })
                    .then((data) => {
                        // Invalidate Tags
                        this.props.invalidateTags([{ type: 'ReimbursementCode', id: 'LIST' }]);

                        return new Promise(async (resolve) => {
                            await this.handleFormTranslation(formTranslations, data);
                            resolve(data);
                        });
                    })
                    .then((data) => {
                        this.loadData(this.state.category).then((data) => {
                            this.changesSaved();
                        });
                    });
            }
        );
    };

    toggleEnable = (entity) => {
        const formData = {
            id: entity.id,
            enabled: !entity.enabled,
        };

        this.updateEntity(formData).then((data) => {
            const reimbursementCodes = this.state.category.reimbursementCodes.map((reimbursementCode) => {
                if (reimbursementCode.id === entity.id) {
                    reimbursementCode.enabled = data.enabled;
                }

                return reimbursementCode;
            });

            this.setState({
                category: {
                    ...this.state.category,
                    reimbursementCodes: reimbursementCodes,
                },
            });
        });
    };

    updateEntity = (formData) => {
        if (formData.hasOwnProperty('translations')) {
            delete formData.translations;
        }

        return new Promise((resolve, reject) => {
            ReimbursementApi.updateReimbursementCode(formData.id, formData)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    removeEntity = (entity) => {
        let t = this.props.t;
        HelperFunctions.confirmModal(
            t('reimbursementCode.deleteModal'),
            'danger',
            true,
            t('reimbursementCode.btn.yes'),
            t('btn.cancel')
        )
            .then(() => ReimbursementApi.deleteReimbursementCode(entity.id))
            .then(() => this.loadData(this.state.category)); // reload the reimbursementCodes
    };

    render() {
        if (this.state.isLoading) {
            return <LoadingSpinner />;
        }

        return (
            <div className="uk-position-relative uk-margin-medium-bottom">
                <div className="uk-margin-bottom">
                    {this.state.category.reimbursementCodes.length > 0 &&
                        this.state.category.reimbursementCodes.map((reimbursementCode) => {
                            return (
                                <ReimbursementCode
                                    editEntity={this.editEntity}
                                    removeEntity={this.removeEntity}
                                    languages={this.props.currentOrganisation?.language ?? []}
                                    reimbursementCode={reimbursementCode}
                                    toggleEnable={this.toggleEnable}
                                    key={reimbursementCode.id}
                                    t={this.props.t}
                                />
                            );
                        })}
                </div>

                <div className="uk-grid">
                    <div className="uk-width-1-2">
                        <NewReimbursementCode
                            changesSaved={this.state.changesSaved}
                            cancelEdit={this.cancelEdit}
                            disableSubmitBtn={this.state.disableSubmitBtn}
                            formData={this.state.formData}
                            formLanguage={this.state.formLanguage}
                            handleFormChange={this.handleFormChange}
                            handleFormLanguageChange={this.handleFormLanguageChange}
                            handleFormSubmit={this.handleFormSubmit}
                            languages={this.props.currentOrganisation?.language ?? []}
                            saveInProgress={this.state.saveInProgress}
                            t={this.props.t}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

class ReimbursementCode extends Component {
    render() {
        const { editEntity, removeEntity, languages, reimbursementCode, toggleEnable, t } = this.props;

        return (
            <div className="uk-grid uk-background-hover-parent uk-margin-remove-top uk-margin-bottom">
                <div
                    className={
                        'uk-width-1-4 uk-background-theme-light-child' +
                        (!reimbursementCode.enabled ? ' uk-text-muted' : '')
                    }
                >
                    <div className="line-height-button">
                        <span className="uk-text-small uk-text-muted">[{reimbursementCode.code}]</span>
                        {!reimbursementCode.enabled && (
                            <span className="uk-text-xsmall"> &nbsp;&hellip;{t('reimbursementCode.inactive')}</span>
                        )}
                    </div>
                    <div className="uk-text-small">
                        {t('reimbursementCode.format.format')}:{' '}
                        {reimbursementCode.type == 1 && t('reimbursementCode.format.amount')}
                        {reimbursementCode.type == 2 && t('reimbursementCode.format.percentage')}
                        {reimbursementCode.type == 3 && t('reimbursementCode.format.number')}
                    </div>
                </div>
                <div className={'uk-width-1-2 uk-background-theme-light-child'}>
                    <div className="uk-text-small uk-padding-small-top">
                        {languages.map((language) => {
                            return (
                                <div className="uk-margin-small-bottom" key={'name-' + language}>
                                    <span className="uk-text-primary uk-text-uppercase">{language}:</span>{' '}
                                    {HelperFunctions.findTranslation(reimbursementCode, language, 'name')}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="uk-width-1-4 uk-background-theme-light-child uk-padding-small-top">
                    <SplitButton
                        title={t('reimbursementCode.btn.edit')}
                        size="sm"
                        menuAlign="right"
                        onClick={() => editEntity(reimbursementCode)}
                    >
                        <Dropdown.Item onClick={() => toggleEnable(reimbursementCode)}>
                            {reimbursementCode.enabled
                                ? t('reimbursementCode.btn.disable')
                                : t('reimbursementCode.btn.enable')}
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item className="text-danger" onClick={() => removeEntity(reimbursementCode)}>
                            {t('reimbursementCode.btn.delete')}
                        </Dropdown.Item>
                    </SplitButton>
                </div>
            </div>
        );
    }
}

class LanguageTabs extends Component {
    switchLanguage = (lang) => {
        this.props.handleFormLanguageChange(lang);
    };

    render() {
        const { formLanguage, languages } = this.props;

        return (
            <div className="uk-inline">
                <button className="uk-button uk-button-default" type="button">
                    <span className="uk-text-uppercase">{formLanguage}</span> <span uk-icon="icon: triangle-down" />
                </button>
                <div id="form-language-dropdown" data-uk-dropdown="mode: click; pos: bottom-left">
                    <ul className="uk-nav uk-dropdown-nav">
                        {languages.map((language) => {
                            return (
                                <li className={formLanguage === language ? 'uk-active' : ''} key={language}>
                                    <a
                                        className="uk-text-uppercase"
                                        onClick={() => this.switchLanguage(language)}
                                        data-uk-toggle="#form-language-dropdown"
                                    >
                                        {language}
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        );
    }
}

class NewReimbursementCode extends Component {
    handleFormLanguageChange = (lang) => {
        this.props.handleFormLanguageChange(lang);
    };

    render() {
        const { changesSaved, formData, formLanguage, disableSubmitBtn, languages, saveInProgress, t } = this.props;
        const isEditMode = formData.id !== null;

        return (
            <div className="uk-padding-top">
                <form className="uk-form-horizontal" autoComplete="off" onSubmit={this.props.handleFormSubmit}>
                    <div className="uk-margin-remove line-height-button">
                        <div className="uk-text-primary uk-margin-right">{t('reimbursementCode.form.title')}</div>

                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="code">
                                {t('reimbursementCode.form.code')}
                            </label>
                            <div className="uk-form-controls">
                                <input
                                    type="text"
                                    id="code"
                                    name="code"
                                    className="uk-input"
                                    value={formData.code}
                                    onChange={this.props.handleFormChange}
                                />
                            </div>
                        </div>
                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="type">
                                {t('reimbursementCode.form.format')}
                            </label>
                            <div className="uk-form-controls">
                                <select
                                    id="type"
                                    name="type"
                                    className="uk-select"
                                    value={formData.type}
                                    onChange={this.props.handleFormChange}
                                >
                                    <option value="1">{t('reimbursementCode.format.amount')}</option>
                                    <option value="2">{t('reimbursementCode.format.percentage')}</option>
                                    <option value="3">{t('reimbursementCode.format.number')}</option>
                                </select>
                            </div>
                        </div>
                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="name">
                                {t('reimbursementCode.form.name')}
                            </label>
                            <div className="uk-form-controls uk-button-inline-group">
                                <LanguageTabs
                                    formLanguage={formLanguage}
                                    handleFormLanguageChange={this.handleFormLanguageChange}
                                    languages={languages}
                                />
                                <div className="uk-inline">
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        className="uk-input"
                                        value={formData.name}
                                        onChange={this.props.handleFormChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <WarningButton type="submit" disabled={disableSubmitBtn}>
                            {t('btn.save')}
                        </WarningButton>
                        {isEditMode && (
                            <button className="uk-button uk-button-link uk-margin-left" onClick={this.props.cancelEdit}>
                                {t('btn.cancel')}
                            </button>
                        )}
                        <LoadingSavedIndicator changesSaved={changesSaved} saveInProgress={saveInProgress} />
                    </div>
                </form>
            </div>
        );
    }
}
