import { Nav } from 'react-bootstrap';
import { generatePath, Link, useParams } from 'react-router-dom';
import cx from 'classnames';
import { SETTINGS_PATH } from '../../../../scenes/Reimbursements';
import { useTranslation } from 'react-i18next';

export default function Sidebar() {
    const { categoryId, view } = useParams();
    const { t } = useTranslation('reimbursements');

    const availableSettings = [
        {
            id: 'properties',
            name: t('settings.sidebar.properties'),
        },
        {
            id: 'team',
            name: t('settings.sidebar.team'),
        },
        {
            id: 'fields',
            name: t('settings.sidebar.fields'),
        },
    ];

    return (
        <div className="py-4">
            <div className="d-flex align-items-center justify-content-between mb-2 px-4">
                <span className="text-uppercase small text-muted font-weight-light">{t('settings.title')}</span>
            </div>

            <Nav className="sidebar-nav flex-column">
                {availableSettings.map((_setting, index) => (
                    <Link
                        to={generatePath(SETTINGS_PATH, {
                            categoryId,
                            view: _setting.id,
                        })}
                        className={cx('nav-link', {
                            active: view === _setting.id,
                        })}
                        key={`sidebar-item-${index}`}
                    >
                        {_setting.name}
                    </Link>
                ))}
            </Nav>
        </div>
    );
}
