import { useParams } from 'react-router-dom';
import Users from '../../publicationUsers/Users';
import Groups from '../../publicationUsers/Groups';
import Sidebar from '../../publicationUsers/Sidebar';
import MainContentNav from '../../Navbar';
import SubNav from './SubNav';
import { useTranslation } from 'react-i18next';
import { EditUserModal } from 'pages/publicationUsers/modals/EditUserModal';
import { CreateUserModal } from 'pages/publicationUsers/modals/CreateUserModal';
import { ViewGroup } from 'pages/publicationUsers/ViewGroup';
import { ViewUser } from 'pages/publicationUsers/ViewUser';
import { PublicationUsers } from 'pages/publications_v2/forms/users/PublicationUsers';

export function Settings() {
    const { page = 'groups' } = useParams();
    const { t } = useTranslation('publications');

    return (
        <>
            <MainContentNav title={t('tabs.accessControl')} />
            <SubNav />
            <EditUserModal />
            <CreateUserModal />

            <div className="content-static-body has-subnav">
                <div className="d-flex align-items-stretch h-100">
                    <div className="content-sidebar-document overflow-auto bg-light">
                        <Sidebar page={page} />
                    </div>
                    <div className="flex-grow-1 overflow-auto">
                        <div id="content" className="px-5 pt-5 pb-3">
                            <Setting page={page} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function Setting({ page }) {
    switch (page) {
        case 'groups':
            return <Groups />;
        case 'users':
            return <Users />;
        case 'view-group':
            return <ViewGroup />;
        case 'view-user':
            return <ViewUser />;
        case 'view-publication-group':
            return <PublicationUsers />;
    }
}
