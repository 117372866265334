import { PrimaryButton } from 'components/Buttons';
import HelperFunctions from 'pages/global/HelperFunctions';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useResetUserPasswordMutation } from 'features/security/authApi';
import { useUserProfile } from 'hooks/useUserProfile';

export function ResetPasswordButton({ user, isPasswordReset = false }) {
    const { t: tUsers } = useTranslation('users');
    const { t: tGlobal } = useTranslation('global');
    const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
    const [resetUserPassword] = useResetUserPasswordMutation();
    const userProfile = useUserProfile();

    return (
        <PrimaryButton
            size="sm"
            onClick={confirmPasswordReset}
            disabled={passwordResetSuccess === true || user.enabled === false || isPasswordReset}
            tooltip={tUsers('editUser.resetPasswordTooltip')}
        >
            {tUsers('btn.resetPassword')}
        </PrimaryButton>
    );

    function confirmPasswordReset() {
        HelperFunctions.confirmModal(
            tUsers('editUser.resetPasswordConfirm', {
                name: user.fullName,
            }),
            undefined,
            false,
            tUsers('editUser.resetPasswordConfirmLabel'),
            tGlobal('btn.cancel')
        )
            .then(() => {
                resetPassword();
            })
            .catch(() => {});
    }

    function resetPassword() {
        resetUserPassword({ accountId: userProfile.accountId, id: user.id }).then(({ data }) => {
            if (data?.status === 'ok') {
                setPasswordResetSuccess(true);
            }
        });
    }
}
