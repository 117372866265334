import getEnv from '../config/Env';
import baseAPI from './ApiConnector_v2';

const baseUrl = getEnv('PUBLICATION_API_URL');
const apiUrl = baseUrl + '/api';

const PublicationApi = {
    createPreviewToken(formData) {
        return baseAPI.post(apiUrl + '/publication_preview_tokens', formData);
    },
    createOpListExportToken(formData) {
        return baseAPI.post(apiUrl + '/op_list_export_tokens', formData);
    },
    createPublication(formData) {
        return baseAPI.post(apiUrl + '/publications', formData);
    },
    createPublicationGroup(formData) {
        return baseAPI.post(apiUrl + '/publication_groups', formData);
    },
    compareVersions(formData) {
        return baseAPI.post(apiUrl + '/publication_versions/compare', formData, {
            responseType: 'blob',
        });
    },
    downloadPublications(formData) {
        return baseAPI.post(apiUrl + '/publications/download', formData, {
            responseType: 'blob',
        });
    },
    comparePublications(formData) {
        return baseAPI.post(apiUrl + '/publications/compare', formData, {
            responseType: 'blob',
        });
    },
    exportPublicationUsers(formData) {
        return baseAPI.post(apiUrl + '/publication_users/export', formData, {
            responseType: 'blob',
        });
    },
    exportPublicationUserGroups(formData) {
        return baseAPI.post(apiUrl + '/user_groups/export', formData, {
            responseType: 'blob',
        });
    },
    duplicatePublicationGroup(id, formData) {
        return baseAPI.post(apiUrl + '/publication_groups/' + id + '/duplicate', formData);
    },
    publish(formData) {
        return baseAPI.post(apiUrl + '/publication_tokens', formData);
    },
    batchDelete(formData) {
        return baseAPI.post(apiUrl + '/batch_deletes', formData);
    },
    batchDuplicate(formData) {
        return baseAPI.post(apiUrl + '/batch_duplicates', formData);
    },
    batchPublish(formData) {
        return baseAPI.post(apiUrl + '/batch_publication_tokens', formData);
    },
    batchEdit(formData) {
        return baseAPI.post(apiUrl + '/batch_edits', formData);
    },
    delete(uri) {
        return baseAPI.delete(baseUrl + uri);
    },
    patch(uri, formData) {
        return baseAPI.patch(baseUrl + uri, formData);
    },
    getPublications(folderId = undefined) {
        return baseAPI.get(apiUrl + '/publications', { params: { publicationGroup: folderId } });
    },
    async getPublicationsForDocument(documentId) {
        const { data } = await baseAPI.get(apiUrl + '/publications');
        const publications = data['hydra:member'];

        return publications.filter((_publication) => _publication.documentIds.includes(documentId));
    },
    getPublication(id) {
        return baseAPI.get(apiUrl + '/publications/' + id);
    },
    updateTokenMutation(formData) {
        return baseAPI.post(apiUrl + '/publication_tokens/update', formData);
    },
    getPublicationVersions(id) {
        return baseAPI.get(apiUrl + '/publications/' + id + '/publication_versions');
    },
    getPublicationGroups() {
        return baseAPI.get(apiUrl + '/publication_groups');
    },
    getPublicationPreviewTokens(id) {
        return baseAPI.get(apiUrl + '/publications/' + id + '/publication_preview_tokens');
    },
    getOpListExportTokens(opDepartmentId) {
        return baseAPI.get(apiUrl + '/op_list_export_tokens?opDepartmentId=' + opDepartmentId);
    },
    getUserGroups() {
        return baseAPI.get(apiUrl + '/user_groups');
    },

    getPublicationUsers() {
        return baseAPI.get(apiUrl + '/publication_users');
    },

    addUserGroup(formData) {
        return baseAPI.post(apiUrl + '/user_groups', formData);
    },

    deleteUserGroup(id) {
        return baseAPI.delete(apiUrl + '/user_groups/' + id);
    },

    addPublicationUser(formData) {
        return baseAPI.post(apiUrl + '/publication_users', formData);
    },

    deletePublicationUser(id) {
        return baseAPI.delete(apiUrl + '/publication_users/' + id);
    },
    updateUserGroup(id, formData) {
        return baseAPI.patch(apiUrl + '/user_groups/' + id, formData);
    },

    updatePublicationUser(formData) {
        return baseAPI.post(apiUrl + `/publication_user_passwords`, formData);
    },
};

export default PublicationApi;
