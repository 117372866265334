import { Form as FForm, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';
import { useCreateAccountIpidImportMutation } from '../../../../features/security/authApi';
import { ADMIN_SETTING_ACCOUNT_PATH } from '../../../../scenes/Admin';
import { FormField } from '../../../publications_v2/helpers/FieldHelper';
import { WarningButton } from '../../../../components/Buttons';
import { LoadingSpinner } from '../../../global/Spinner';
import { Insurers } from '../Import';
import * as Yup from 'yup';

export function Import({ account }) {
    const { t } = useTranslation('global');
    const [createAccountIpidImport] = useCreateAccountIpidImportMutation();
    const history = useHistory();

    const handleSubmit = (values, { setSubmitting }) => {
        const body = {
            ...values,
            insurers: values.insurers.map((insurer) => ({
                ...insurer,
                users: insurer.users.filter((user) => user.checked),
            })),
        };

        createAccountIpidImport({ id: account.id, body }).then(({ data }) => {
            setSubmitting(false);

            history.push(
                generatePath(ADMIN_SETTING_ACCOUNT_PATH, {
                    id: data.id,
                    view: 'organisations',
                })
            );
        });
    };

    return (
        <div>
            <div className="subheader d-flex align-items-center justify-content-between pb-1 mb-3">
                <h3 className="mb-0">{account.name}: organisatie importeren</h3>
            </div>

            <Formik
                initialValues={{
                    accountName: '',
                    organisationName: '',
                    website: 'https://publicaties.docrevolution.nl/handleiding/verzekeringskaarten/',
                    insurers: [],
                }}
                onSubmit={handleSubmit}
                enableReinitialize
                validationSchema={importSchema}
            >
                {({ dirty, isSubmitting, isValid }) => (
                    <FForm autoComplete="off">
                        <FormField name="organisationName" label="Naam organisatie" props={{ required: true }} />
                        <FormField name="website" label="Documentation url" />
                        <Insurers />

                        <div className="mb-4 mt-4">
                            <WarningButton type="submit" disabled={isSubmitting || !dirty || !isValid}>
                                {t('btn.save')}
                            </WarningButton>

                            <LoadingSpinner isSubmitting={isSubmitting} />
                        </div>
                    </FForm>
                )}
            </Formik>
        </div>
    );
}

const importSchema = Yup.object().shape({
    organisationName: Yup.string().required(),
    insurers: Yup.array()
        .of(
            Yup.object().shape({
                urlName: Yup.string().required(),
            })
        )
        .min(1),
});
