import { Accordion } from 'react-bootstrap';
import { Draggable } from 'react-beautiful-dnd';
import React from 'react';
import Publication from './Publication';
import cx from 'classnames';
import { useHasPermission } from '../../../global/RestrictedContent';
import { Permissions } from '../../../../config/Constants';
import { useParams } from 'react-router-dom';

export default function GroupPublications({
    publications = [],
    setShowExportChangesModal,
    setShowPublishModal,
    setShowDuplicateModal,
}) {
    const { publicationId } = useParams();

    return (
        <Accordion defaultActiveKey={publicationId ? `publication-${publicationId}` : undefined}>
            <div className="data-table-items">
                <DraggablePublications
                    publications={publications}
                    setShowExportChangesModal={setShowExportChangesModal}
                    setShowPublishModal={setShowPublishModal}
                    setShowDuplicateModal={setShowDuplicateModal}
                />
            </div>
        </Accordion>
    );
}

function DraggablePublications({
    publications,
    setShowExportChangesModal,
    setShowPublishModal,
    setShowDuplicateModal,
}) {
    return publications.map((publication, index) => (
        <DraggablePublication
            publication={publication}
            setShowExportChangesModal={setShowExportChangesModal}
            setShowPublishModal={setShowPublishModal}
            index={index}
            setShowDuplicateModal={setShowDuplicateModal}
            key={`publication-${publication.id}`}
        />
    ));
}

function DraggablePublication({
    publication,
    setShowExportChangesModal,
    setShowPublishModal,
    index,
    setShowDuplicateModal,
}) {
    const hasReadWritePermission = useHasPermission(Permissions.Publication['Write.All']);

    return (
        <Draggable draggableId={publication['@id']} index={index} isDragDisabled={hasReadWritePermission === false}>
            {(provided, snapshot) => {
                return (
                    <div
                        id={`publication-item-${publication.id}`}
                        className={cx('data-table-item-wrapper', {
                            'is-dragging': snapshot.isDragging,
                        })}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <Publication
                            publication={publication}
                            setShowExportChangesModal={setShowExportChangesModal}
                            setShowPublishModal={setShowPublishModal}
                            setShowDuplicateModal={setShowDuplicateModal}
                        />
                    </div>
                );
            }}
        </Draggable>
    );
}
