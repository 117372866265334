import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import getEnv from '../../config/Env';
import { baseQueryWithReauth, prepareHeaders, providesList } from '../api';
import HelperFunctions from '../../pages/global/HelperFunctions';

const baseUrl = getEnv('APP_API_URL');

const documentApiQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders,
});

export const documentApi = createApi({
    reducerPath: 'documentApi',
    baseQuery: (args, api, extraOptions) => baseQueryWithReauth(args, api, extraOptions, documentApiQuery),
    tagTypes: ['Area', 'AreaVariant', 'Document', 'DocumentVariant', 'AreaHistory', 'DocumentGroup', 'Tag', 'Block'],
    endpoints: (builder) => ({
        getArea: builder.query({
            query: (id) => `/areas/${id}`,
            providesTags: (result, error, arg) => [{ type: 'Area', id: arg }],
        }),
        getBlock: builder.query({
            query: ({ blockId, variantId }) => `/blocks/${blockId}/variants/${variantId}`,
            providesTags: (result, error, { blockId }) => [{ type: 'Block', id: blockId }],
        }),
        deleteArea: builder.mutation({
            query: ({ id, sectionId }) => ({
                url: `/areas/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, { id, sectionId }) => [
                { type: 'Area', id },
                { type: 'Section', sectionId },
            ],
        }),
        getAreaBlocks: builder.query({
            query: ({ areaId, variantId }) => `/areas/${areaId}/variants/${variantId}/blocks`,
            providesTags: (result, error, arg) => [
                { type: 'Document', id: result.area.documentId },
                { type: 'Area', id: arg.areaId },
                { type: 'AreaBlocks', id: arg.areaId },
                {
                    type: 'AreaVariant',
                    id: `${arg.areaId}-${arg.variantId}`,
                },
            ],
        }),
        getBlockCloneContent: builder.query({
            query: (id) => `/blocks/${id}/clone`,
            providesTags: (result, error, arg) => [{ type: 'Block', id: arg }],
        }),
        getAreaHistory: builder.query({
            query: ({ areaId, variantId }) => `/areas/${areaId}/variants/${variantId}/groupedhistory`,
            providesTags: (result, error, arg) => [
                { type: 'Area', id: arg.areaId },
                { type: 'AreaHistory', id: arg.areaId },
                {
                    type: 'AreaVariant',
                    id: `${arg.areaId}-${arg.variantId}`,
                },
            ],
        }),
        updateAreaBlocks: builder.mutation({
            query: ({ areaId, variantId, body }) => ({
                url: `/areas/${areaId}/variants/${variantId}/blocks`,
                method: 'PUT',
                body,
            }),
            async onQueryStarted({ areaId, variantId, ...patch }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(
                        documentApi.util.updateQueryData('getAreaBlocks', { areaId, variantId }, (draft) => {
                            Object.assign(draft, data);
                        })
                    );
                } catch {}
            },
            invalidatesTags: (result, error, { areaId }) => [{ type: 'AreaHistory', id: areaId }],
        }),
        updateAreas: builder.mutation({
            query: ({ documentId, body }) => ({
                url: '/areas',
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, { documentId }) => {
                const areaTags = result.map((area) => ({
                    type: 'Area',
                    id: area.id,
                }));

                return [...areaTags, { type: 'Document', id: documentId }];
            },
        }),
        updateArea: builder.mutation({
            query: ({ areaId, body }) => ({
                url: `/areas/${areaId}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, { areaId }) => [{ type: 'Area', id: areaId }],
        }),
        addArea: builder.mutation({
            query: ({ sectionId, body }) => ({
                url: '/areas',
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error, { sectionId }) => [{ type: 'Section', id: sectionId }],
        }),
        updateAreaSortOrder: builder.mutation({
            query: ({ sectionId, body }) => ({
                url: '/areas',
                method: 'PUT',
                body,
            }),
            async onQueryStarted({ sectionId, ...patch }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    documentApi.util.updateQueryData('getAreas', sectionId, (draft) => {
                        const areas = draft.map((area) => {
                            const newArea = patch.body.find((areaData) => areaData.id === area.id);
                            return {
                                ...area,
                                sortOrder: newArea?.sortOrder ?? area.sortOrder,
                            };
                        });
                        Object.assign(draft, areas);
                    })
                );
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
            invalidatesTags: (result, error, { sectionId }) => [{ type: 'Section', id: sectionId }],
        }),
        updateSections: builder.mutation({
            query: ({ documentId, body }) => ({
                url: '/sections',
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, { documentId }) => [{ type: 'Document', id: documentId }],
        }),
        syncSections: builder.mutation({
            query: ({ documentId, body }) => ({
                url: `/sections/${documentId}/syncs`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error, { documentId }) => [{ type: 'Document', id: documentId }],
        }),
        deleteSection: builder.mutation({
            query: ({ id, documentId, forceDelete }) => ({
                url: `/sections/${id}`,
                method: 'DELETE',
                params: {
                    forceDelete,
                },
            }),
            invalidatesTags: (result, error, { id, documentId }) => [{ type: 'Document', documentId }],
        }),
        addDocument: builder.mutation({
            query: ({ organisationId, body }) => ({
                url: `/organisations/${organisationId}/documents`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Document'],
        }),
        updateDocument: builder.mutation({
            query: ({ id, uri, body, method = 'PUT' }) => ({
                url: uri,
                method,
                body,
            }),
            invalidatesTags: (result, error, { id }) => [
                { type: 'Document', id },
                { type: 'Document', id: 'LIST' },
            ],
        }),
        updateBlocks: builder.mutation({
            query: ({ areaId, variantId, body }) => ({
                url: `/areas/${areaId}/variants/${variantId}/blocks`,
                method: 'PUT',
                body,
            }),
        }),
        updateDocuments: builder.mutation({
            query: ({ organisationId, body }) => ({
                url: '/v2/documents/batches',
                method: 'POST',
                body,
            }),
            async onQueryStarted({ organisationId, body }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    documentApi.util.updateQueryData('getDocuments', { organisationId, deleted: false }, (draft) => {
                        draft.forEach((_document) => {
                            const documentPatch = body.find((patch) => patch.id === _document.id);

                            if (documentPatch) {
                                Object.assign(_document, {
                                    ..._document,
                                    ...documentPatch.changes,
                                });
                            }
                        });
                    })
                );
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
            invalidatesTags: [{ type: 'Document', id: 'LIST' }],
        }),
        deleteDocument: builder.mutation({
            query: (id) => ({
                url: `/documents/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Document', id: 'LIST' }],
        }),
        getDocument: builder.query({
            query: ({ id, showAreas = true }) => ({
                url: `/v2/documents/${id}`,
                params: {
                    showAreas,
                },
            }),
            providesTags: (result, error, { id }) => [{ type: 'Document', id }],
        }),
        getDocuments: builder.query({
            query: ({ organisationId, deleted = false }) =>
                deleted
                    ? `/organisations/${organisationId}/documents/deleted`
                    : `/organisations/${organisationId}/documents`,
            providesTags: (result) => providesList(result, 'Document', 'id'),
        }),
        getDocumentTags: builder.query({
            query: (id) => `/documents/${id}/tags`,
            providesTags: (result) => providesList(result, 'Tag', 'id'),
        }),
        getDocumentExport: builder.query({
            query: (id) => `/v2/documents/${id}/export`,
            providesTags: (result, error, { id }) => [{ type: 'Document', id }],
        }),
        getDocumentTag: builder.query({
            query: ({ documentId, id }) => `/documents/${documentId}/tags/${id}`,
            providesTags: (result, error, { id }) => [{ type: 'Tag', id }],
        }),
        getOrganisationTags: builder.query({
            query: (id) => `/organisations/${id}/tags`,
            providesTags: (result) => providesList(result, 'Tag', 'id'),
        }),
        updateTag: builder.mutation({
            query: ({ organisationId, id, body }) => ({
                url: `/organisations/${organisationId}/tags/${id}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, { id }) => [
                { type: 'Tag', id },
                { type: 'Tag', id: 'LIST' },
            ],
        }),
        addTag: builder.mutation({
            query: ({ organisationId, body }) => ({
                url: `/organisations/${organisationId}/tags`,
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'Tag', id: 'LIST' }],
        }),

        syncTags: builder.mutation({
            query: ({ documentId, body }) => ({
                url: `/documents/${documentId}/tags/syncs`,
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'Tag', id: 'LIST' }],
        }),

        deleteTag: builder.mutation({
            query: ({ organisationId, id }) => ({
                url: `/organisations/${organisationId}/tags/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Tag', id: 'LIST' }],
        }),
        getDocumentVariants: builder.query({
            query: (id) => `/documents/${id}/variants`,
            transformResponse: (response) => HelperFunctions.flatten([response.documentVariants]),
            providesTags: (result, error, arg) => [{ type: 'DocumentVariant', id: arg }],
        }),
        getAreaRevisionOperationsVariantGroups: builder.query({
            query: ({ areaId, revisionId }) => `/areas/${areaId}/revisions/${revisionId}/operations/variant/groups`,
            transformResponse: (response) => response.groups,
        }),
        getDocumentsBlocksSearch: builder.query({
            query: ({ id, q }) => ({
                url: `/documents/${id}/blocks/search`,
                params: {
                    q,
                },
            }),
        }),
        updateDocumentVariants: builder.mutation({
            query: ({ id, body }) => ({
                url: `/documents/${id}/variants`,
                method: 'PUT',
                body,
            }),
            async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedVariant } = await queryFulfilled;

                    dispatch(
                        documentApi.util.updateQueryData('getDocumentVariants', id, (draft) => {
                            Object.assign(draft, HelperFunctions.flatten([updatedVariant.documentVariants]));
                        })
                    );
                } catch {}
            },
        }),
        updateLinkedAreas: builder.mutation({
            query: (body) => ({
                url: `/linked-areas/`,
                method: 'PUT',
                body,
            }),
        }),
        lockArea: builder.mutation({
            query: ({ uri, body }) => ({
                url: uri,
                method: 'PUT',
                body,
            }),
        }),
        unlockArea: builder.mutation({
            query: (id) => ({
                url: `/areas/${id}/unlock`,
                method: 'PUT',
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Area', id: arg }],
        }),
        updateAreaStatus: builder.mutation({
            query: ({ id, body }) => ({
                url: `/areas/${id}/status`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Area', id }],
        }),
        getTableTemplates: builder.query({
            query: (id) => `/organisations/${id}/documents/tabletemplates`,
        }),
        getDocumentGroups: builder.query({
            query: () => `/documentgroups/groups`,
            providesTags: (result) => providesList(result, 'DocumentGroup', 'id'),
        }),
        addDocumentGroup: builder.mutation({
            query: (body) => ({
                url: '/documentgroups/groups',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['DocumentGroup'],
        }),
        updateDocumentGroup: builder.mutation({
            query: ({ id, body }) => ({
                url: `/documentgroups/${id}/group`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: (result, error, { id }) => [
                { type: 'DocumentGroup', id },
                { type: 'DocumentGroup', id: 'LIST' },
            ],
        }),
        updateDocumentGroups: builder.mutation({
            query: (body) => ({
                url: '/documentgroups/groups/batches',
                method: 'POST',
                body,
            }),
            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    documentApi.util.updateQueryData('getDocumentGroups', undefined, (draft) => {
                        draft.forEach((_group) => {
                            const groupPatch = body.find((patch) => patch.id === _group.id);

                            if (groupPatch) {
                                Object.assign(_group, {
                                    ..._group,
                                    ...groupPatch.changes,
                                });
                            }
                        });
                    })
                );
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
            invalidatesTags: [{ type: 'DocumentGroup', id: 'LIST' }],
        }),
        deleteDocumentGroup: builder.mutation({
            query: (id) => ({
                url: `/documentgroups/${id}/group`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'DocumentGroup', id: 'LIST' }],
        }),
        deleteDocumentVariant: builder.mutation({
            query: ({ documentId, variantId }) => ({
                url: `/documents/${documentId}/variants/${variantId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, { documentId }) => [{ type: 'DocumentVariant', documentId }],
            // invalidatesTags: [{ type: 'DocumentVariant', id: 'LIST' }],
        }),
        getAreas: builder.query({
            query: (sectionId) => `/sections/${sectionId}/areas`,
            providesTags: (result, error, id) => [
                ...providesList(result, 'Area', 'id'),
                {
                    type: 'Section',
                    id,
                },
            ],
        }),
        batchAssignDocumentUsers: builder.mutation({
            query: (body) => ({
                url: '/v2/documents/batchassigns',
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const {
    useGetAreaQuery,
    useGetBlockQuery,
    useDeleteAreaMutation,
    useDeleteSectionMutation,
    useDeleteDocumentMutation,
    useGetAreaBlocksQuery,
    useGetAreaHistoryQuery,
    useUpdateAreaBlocksMutation,
    useGetDocumentQuery,
    useGetDocumentsQuery,
    useGetDocumentGroupsQuery,
    useGetDocumentTagsQuery,
    useGetDocumentExportQuery,
    useGetDocumentTagQuery,
    useGetOrganisationTagsQuery,
    useUpdateTagMutation,
    useAddTagMutation,
    useSyncTagsMutation,
    useDeleteTagMutation,
    useGetDocumentVariantsQuery,
    useUpdateLinkedAreasMutation,
    useGetAreaRevisionOperationsVariantGroupsQuery,
    useGetDocumentsBlocksSearchQuery,
    useLockAreaMutation,
    useUnlockAreaMutation,
    useGetTableTemplatesQuery,
    useUpdateAreaMutation,
    useAddAreaMutation,
    useUpdateAreasMutation,
    useUpdateAreaSortOrderMutation,
    useUpdateSectionsMutation,
    useSyncSectionsMutation,
    useUpdateAreaStatusMutation,
    useUpdateDocumentMutation,
    useUpdateBlocksMutation,
    useUpdateDocumentsMutation,
    useUpdateDocumentVariantsMutation,
    useAddDocumentMutation,
    useAddDocumentGroupMutation,
    useUpdateDocumentGroupMutation,
    useUpdateDocumentGroupsMutation,
    useDeleteDocumentGroupMutation,
    useDeleteDocumentVariantMutation,
    useGetBlockCloneContentQuery,
    useGetAreasQuery,
    useBatchAssignDocumentUsersMutation,
} = documentApi;
