import cx from 'classnames';
import { Pencil } from 'react-bootstrap-icons';
import { Button, Form } from 'react-bootstrap';
import Spinner from '../global/Spinner';
import HelperFunctions from '../global/HelperFunctions';
import { WarningButton } from 'components/Buttons';
import { useGetDocument } from '../documents_v2/hooks/useGetDocument';
import { useTranslation } from 'react-i18next';
import { BooleanParam, NumberParam, useQueryParam } from 'use-query-params';

export function EditTag({
    closeSidebar,
    documentId,
    saveInProgress,
    formData,
    handleFormChange,
    handleFormSubmit,
    tags = [],
}) {
    const { t } = useTranslation('documents');
    const [editTag, setEditTag] = useQueryParam('editTag', NumberParam);
    const [editTagVariants, setEditTagVariants] = useQueryParam('variants', BooleanParam);
    const document = useGetDocument();

    const tag = editTag ? tags.find((_tag) => _tag.id === editTag) : undefined;
    const name = tag?.name ?? '';
    const description = tag?.description ?? '';
    const isDisabled = tag?.documentId === document?.modelId;

    return (
        <div
            id="sidebar"
            className={cx('border-left border-top position-absolute', {
                'is-closed': !editTag,
            })}
            style={{
                top: 70,
                bottom: 0,
            }}
        >
            <div className="p-4">
                <div className="d-flex align-items-start justify-content-between mb-4">
                    <h4>
                        <Pencil /> {t('document.navbar.tags.editModal.title')}
                    </h4>
                    <button type="button" className="close" onClick={closeSidebar}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <Form onSubmit={handleFormSubmit} autoComplete="off">
                    <Form.Group controlId="tag.name">
                        <Form.Label>{t('document.navbar.tags.editModal.name')}*</Form.Label>
                        <Form.Control
                            required
                            readOnly={
                                (documentId && formData.isOverride && isDisabled) ||
                                tag?.parentId ||
                                !formData.documentId
                            } // can't edit name when editing document specific values
                            type="text"
                            placeholder="Tag name"
                            value={formData?.name ?? ''}
                            onChange={(event) => {
                                handleFormChange(HelperFunctions.removeSpaces(event.target.value), 'name');
                            }}
                        />
                    </Form.Group>
                    <Form.Group controlId="tag.description">
                        <Form.Label>{t('document.navbar.tags.editModal.description')}</Form.Label>
                        <Form.Control
                            readOnly={
                                (documentId && formData.isOverride && isDisabled && !formData.documentId) ||
                                tag?.parentId ||
                                !formData.documentId
                            } // can't edit description when editing document specific values
                            type="text"
                            value={formData?.description ?? ''}
                            onChange={(event) => handleFormChange(event.target.value, 'description')}
                        />
                    </Form.Group>

                    {!documentId && (
                        <>
                            <Form.Group controlId="tag.text">
                                <Form.Label>{t('document.navbar.tags.editModal.value')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={formData?.tagValue?.text ?? ''}
                                    onChange={(event) => handleFormChange(event.target.value, 'text')}
                                />
                            </Form.Group>
                            <Form.Group controlId="tag.url">
                                <Form.Label>Link</Form.Label>
                                <Form.Control
                                    type="url"
                                    placeholder="https://"
                                    value={formData?.tagValue?.url ?? ''}
                                    onChange={(event) => handleFormChange(event.target.value, 'url')}
                                />
                            </Form.Group>
                            <Form.Group controlId="tag.tooltip">
                                <Form.Label>Tooltip</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={formData?.tagValue?.tooltip ?? ''}
                                    onChange={(event) => handleFormChange(event.target.value, 'tooltip')}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Button variant="primary" type="submit" disabled={saveInProgress && !editTagVariants}>
                                    {t('btn.save')}
                                </Button>
                                {saveInProgress && !editTagVariants && <Spinner />}
                            </Form.Group>
                        </>
                    )}

                    {documentId && (
                        <>
                            <Form.Group>
                                <div className="pt-3">
                                    <Button onClick={() => setEditTagVariants(true)} variant="primary" block>
                                        {t('document.navbar.tags.editModal.btn.variants')}
                                    </Button>
                                </div>
                            </Form.Group>
                            {!formData.isOverride && (
                                <Form.Group>
                                    <WarningButton
                                        type="submit"
                                        disabled={
                                            (saveInProgress && !editTagVariants) ||
                                            (name === formData.name &&
                                                (description === formData.description ||
                                                    formData.description === null)) ||
                                            isDisabled
                                        }
                                    >
                                        {t('btn.save')}
                                    </WarningButton>
                                    {saveInProgress && !editTagVariants && <Spinner />}
                                </Form.Group>
                            )}
                        </>
                    )}
                </Form>
            </div>
        </div>
    );
}
