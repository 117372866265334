import { useGetTranslationsQuery } from '../../../../features/translations/translationApi';
import { isEnabledForTranslation } from '../../helpers/TranslationKeyHelper';
import { StatusPencil } from './EntityStatus';
import { Translation, TranslationKey } from '../../../../models/translation.models';

export default function TranslationStatus({
    translationKey,
    languageIso,
    hasEntityReference = false,
    tooltipPlacement = 'top',
}: {
    translationKey: TranslationKey;
    languageIso: string;
    hasEntityReference: boolean;
    tooltipPlacement?: string;
}) {
    const enabledForTranslation: boolean = isEnabledForTranslation(translationKey, languageIso);

    const { translation }: { translation: Translation | undefined } = useGetTranslationsQuery(translationKey['@id'], {
        selectFromResult: ({ data }) => {
            return {
                translation: data?.find((_translation: Translation) => _translation.languageIso === languageIso),
            };
        },
        skip: !enabledForTranslation || !hasEntityReference,
    });

    if (!enabledForTranslation) {
        return null;
    }

    if (translation === undefined || translation.unverified) {
        return <StatusPencil translated={0} unverified={1} tooltipPlacement={tooltipPlacement} />;
    }

    return <StatusPencil translated={1} unverified={0} tooltipPlacement={tooltipPlacement} />;
}
