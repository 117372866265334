import { Form, Modal } from 'react-bootstrap';
import { Field, Form as FForm, Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { FormModal, ModalFooter } from '../../global/FormModal';
import { useAddDocumentGroupMutation, useUpdateDocumentGroupMutation } from '../../../features/documents/documents';
import { useTranslation } from 'react-i18next';

export default function EditFolderModal({ showModal, handleClose }) {
    const { activeOrganisation } = useSelector((state) => state.security);
    const [addDocumentGroup] = useAddDocumentGroupMutation();
    const [updateDocumentGroup] = useUpdateDocumentGroupMutation();
    const { t } = useTranslation('global');
    const handleSubmit = (values, { setSubmitting }) => {
        if (showModal.hasOwnProperty('id')) {
            updateDocumentGroup({ id: showModal.id, body: values }).then(() => {
                setSubmitting(false);
                handleClose();
            });
        } else {
            addDocumentGroup(values).then(() => {
                setSubmitting(false);
                handleClose();
            });
        }
    };

    return (
        <FormModal
            show={!!showModal}
            onHide={handleClose}
            title={
                showModal.hasOwnProperty('id')
                    ? `${t('contentSidebar.map.title.edit')}`
                    : `${t('contentSidebar.map.title.new')}`
            }
        >
            <Formik
                initialValues={{
                    name: showModal?.name ?? '',
                    parent: showModal?.parent ?? null,
                    sortOrder: showModal?.sortOrder ?? null,
                    organisationId: activeOrganisation,
                }}
                validationSchema={NewFolderSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched, dirty }) => (
                    <FForm autoComplete="off">
                        <Modal.Body>
                            <Form.Group>
                                <Field
                                    as={Form.Control}
                                    id="name"
                                    name="name"
                                    placeholder={t('contentSidebar.map.titleField')}
                                    isInvalid={errors.name && touched.name}
                                    isValid={!errors.name && touched.name}
                                />
                            </Form.Group>
                        </Modal.Body>

                        <ModalFooter dirty={dirty} isSubmitting={isSubmitting} onHide={handleClose} />
                    </FForm>
                )}
            </Formik>
        </FormModal>
    );
}

const NewFolderSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Required'),
});
