import { taskStatus } from 'pages/task_team/config/constants';

export function useGetTaskStatusOptions(includeDraft = false) {
    let taskStatusOptions = [
        {
            label: 'In behandeling',
            value: taskStatus.STATUS_REVIEW_IN_PROGRESS,
        },
        {
            label: 'Goedgekeurd',
            value: taskStatus.STATUS_APPROVED,
        },
        {
            label: 'Afgekeurd',
            value: taskStatus.STATUS_REJECTED,
        },
        {
            label: 'Ingetrokken',
            value: taskStatus.STATUS_WITDRAWN,
        },
    ];

    if (includeDraft) {
        taskStatusOptions.push({
            label: 'Concept',
            value: taskStatus.STATUS_DRAFT,
        });
    }

    return taskStatusOptions;
}
