import { useEffect, useMemo, useState } from 'react';
import { Search } from 'react-bootstrap-icons';
import { debounce } from 'lodash';

export function SearchInput({ onChange, placeholder = 'Search...' }) {
    const [search, setSearch] = useState('');

    const handleChange = (event) => {
        setSearch(event.target.value);
    };

    const debouncedResults = useMemo(() => {
        return debounce(handleChange, 300);
    }, []);

    useEffect(() => {
        onChange(search);
    }, [search]);

    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    });

    return (
        <div className="form-control-search">
            <div className="form-control-icon-prepend">
                <Search />
            </div>
            <input
                className="form-control"
                type="search"
                defaultValue={search}
                placeholder={placeholder}
                onChange={debouncedResults}
            />
        </div>
    );
}
