import { useGetOrganisationUsers } from 'hooks/useGetOrganisationUsers';
import { useGetDocument } from 'pages/documents_v2/hooks/useGetDocument';
import HelperFunctions from 'pages/global/HelperFunctions';

export function useGetDocumentUsers() {
    const users = useGetOrganisationUsers();
    const document = useGetDocument();

    return document?.documentUsers
        .map((documentUser) => {
            const user = users.find((user) => user.id === documentUser.userId);

            return {
                ...documentUser,
                user,
                fullName: user?.fullName,
            };
        })
        .filter((documentUser) => documentUser.user)
        .sort(HelperFunctions.sortByString('fullName'));
}
