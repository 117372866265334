import Block from '../BlockParser';
import { useEffect, useState } from 'react';
import DocumentHelper from '../../../global/DocumentHelper';

export default function BaseBlocks({ activeVariantId, area, baseBlocks = [], rightSideProps }) {
    const [blockIdsInLayout, setBlockIdsInLayout] = useState(undefined);

    useEffect(() => {
        if (blockIdsInLayout === undefined && baseBlocks.length > 0) {
            setBlockIdsInLayout(DocumentHelper.getBlockIdsInLayout(baseBlocks));
        }
    }, [blockIdsInLayout, baseBlocks]);

    if (blockIdsInLayout === undefined) {
        return null;
    }

    return (
        <div className="area-preview">
            {baseBlocks
                .filter((_block) => !blockIdsInLayout.includes(_block.key))
                .map((_block) => {
                    return (
                        <Block
                            baseBlocks={baseBlocks}
                            block={_block}
                            baseBlock={_block}
                            blockIdsInLayout={blockIdsInLayout}
                            {...rightSideProps}
                            key={area.id + '_' + activeVariantId + '_' + _block.key}
                        />
                    );
                })}
        </div>
    );
}
