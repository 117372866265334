import { useEffect, useState } from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import MainContentNav from '../../Navbar';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import {
    fetchBulkEntryLabels,
    fetchOpListDepartments,
    fetchOpListSummary,
    fetchOpListTeams,
    fetchOpListUsers,
    selectCurrentOpListPageTeamEntries,
    selectOpEntryById,
    selectOpListById,
    updateEntity,
} from '../../../features/operationsList/operationsListSlice';
import { useDispatch, useSelector } from 'react-redux';
import OpEntryLabel from './OpEntryLabel';
import getEnv from '../../../config/Env';
import SubNav from '../nav/SubNav';
import OpTeamEntrySidebar from '../op_team_entry/OpTeamEntrySidebar';
import OpListSummary from './OpListSummary';
import { selectDepartmentUserBelongsTo, selectUserDepartments } from '../../../features/operationsList/opListUserSlice';
import _ from 'lodash';
import OpEntry from './OpEntry';
import { fetchDepartmentTasks, selectAllDepartmentTasksForOpList } from '../../../features/operationsList/taskSlice';
import AddOpEntryLabelModal from './AddOpEntryLabelModal';
import LoadingSpinner from '../../global/LoadingSpinner';
import { BASE_PATH, VIEW_PATH } from '../../../scenes/OperationsList';
import { useGetLabelsQuery } from '../../../features/metadata/metadata';
import { useTranslation } from 'react-i18next';

export function useSelectedPage() {
    const { selectedPage = 1 } = useParams();

    return parseInt(selectedPage);
}

function View() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const opList = useSelector((state) => selectOpListById(state.operations, id));

    const { opListFilter } = useSelector((state) => state.operations);
    const filterOptions = opListFilter[id];

    const { activeOrganisation } = useSelector((state) => state.security);
    const [addEntryLabelModalData, setAddEntryLabelModalData] = useState(false);
    const [searchQuery, setSearchQuery] = useState(filterOptions?.searchQuery ?? '');
    const [loadingItems, setLoadingItems] = useState(true);
    const { t } = useTranslation('changelist');

    useEffect(() => {
        const options = { opListId: id };
        dispatch(fetchOpListUsers(options));
        dispatch(fetchOpListDepartments(options));
        dispatch(fetchOpListTeams(options));
    }, [dispatch, id]);

    return (
        <>
            <MainContentNav pages={[{ title: t('breadcrumb'), url: BASE_PATH }, { title: opList?.name ?? '' }]} />
            <SubNav />

            <div className="content-static-body has-subnav">
                <div className="d-flex align-items-stretch h-100">
                    <div className="content-sidebar overflow-auto bg-light">
                        {opList && (
                            <OpTeamEntrySidebar opList={opList} searchQuery={searchQuery} disabled={loadingItems} />
                        )}
                    </div>
                    <div className="flex-grow-1 overflow-auto">
                        <div id="content" className="px-4 pt-4">
                            <PaginatedView
                                opList={opList}
                                loadingItems={loadingItems}
                                setLoadingItems={setLoadingItems}
                                searchQuery={searchQuery}
                                setSearchQuery={setSearchQuery}
                                setAddEntryLabelModalData={setAddEntryLabelModalData}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {addEntryLabelModalData !== false && (
                <AddOpEntryLabelModal
                    showModal={true}
                    opList={opList}
                    baseEntryLabel={addEntryLabelModalData[0]}
                    opEntry={addEntryLabelModalData[1]}
                    handleClose={() => {
                        let loadPage = _.get(addEntryLabelModalData, '2');
                        setAddEntryLabelModalData(false);

                        if (loadPage) {
                            loadPage();
                        }
                    }}
                />
            )}
        </>
    );
}

function PaginatedView({
    opList,
    searchQuery,
    setSearchQuery,
    loadingItems,
    setLoadingItems,
    setAddEntryLabelModalData,
}) {
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const selectedPage = useSelectedPage();
    const { opListFilter, opListPagination } = useSelector((state) => state.operations);
    const opEntryLabels = useSelector(selectCurrentOpListPageTeamEntries());
    const [hasInitialData, setInitialData] = useState(false);
    const { t } = useTranslation('changelist');

    const filterOptions = opListFilter[opList.id];
    const adminView = shouldSeeAdminView(opList);

    // Initial load
    useEffect(() => {
        if (!hasInitialData) {
            loadPage();
        }
    }, [id]);

    useEffect(() => {
        if (hasInitialData) {
            // If we change the filter the loaded total count might change so we want to reset the pagination (else you could be stuck on a non-existing page
            if (selectedPage > 1) {
                history.push(
                    generatePath(VIEW_PATH, {
                        id,
                        selectedPage: 1,
                    })
                );
            } else {
                // But if the selectedPage was already at one it the selectedPage won't change and won't trigger a page load.
                // So just load directly instead.
                loadPage();
            }
        }
    }, [filterOptions]);

    useEffect(() => {
        // Only load when actual page is selected, not on initial run
        if (hasInitialData) {
            loadPage();
        }
    }, [selectedPage]);

    useEffect(() => {
        // Only processors see the list summary so no point in fetching for adminView
        if (!adminView) {
            dispatch(fetchOpListSummary({ opListId: id, filterOptions }));
        }
    }, [id, filterOptions]);

    function loadPage() {
        setLoadingItems(true);

        dispatch(
            fetchBulkEntryLabels({
                opListId: id,
                filterOptions,
                page: selectedPage,
                // In the admin view the listitems are entries, so we want to paginate by that.
                // In the processor view the listItems are entryLabels, so then we want to paginate by that.
                targetEntity: adminView ? 'OpEntry' : 'OpEntryLabel',
            })
        ).then(() => {
            setLoadingItems(false);
            setInitialData(true);
        });
    }

    if (!opList) {
        return <LoadingSpinner />;
    }

    return (
        <Col className="d-flex align-items-stretch mr-n3">
            <div id="content" className="px-4 pt-4">
                <div>
                    <Row>
                        <OpListMeta opList={opList} />
                    </Row>

                    <div className="uk-form-controls mb-3">
                        <input
                            type="search"
                            className="form-control"
                            name="searchQuery"
                            id="searchQuery"
                            placeholder={t('changelist.subnav.list.search')}
                            disabled={loadingItems}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            value={searchQuery}
                        />
                    </div>

                    {loadingItems ? (
                        <LoadingSpinner />
                    ) : (
                        <OpListItems
                            opList={opList}
                            opEntryLabels={opEntryLabels}
                            setAddEntryLabelModalData={setAddEntryLabelModalData}
                            loadPage={loadPage}
                            selectedPage={selectedPage}
                        />
                    )}
                </div>

                {!loadingItems && opListPagination && <PageNavigation opListPagination={opListPagination} />}
            </div>
        </Col>
    );
}

function OpListMeta({ opList }) {
    return (
        <>
            <Col md={9}>
                <OpListTitle opList={opList} />
            </Col>

            {!shouldSeeAdminView(opList) && (
                <Col md={3}>
                    <OpListSummary id={opList.id} />
                </Col>
            )}
        </>
    );
}

function PageNavigation({ opListPagination }) {
    const { id } = useParams();
    const history = useHistory();
    const totalPages = Math.ceil(opListPagination.count / opListPagination.pageSize);
    const currentPage = opListPagination.page;

    const showPrevious = currentPage > 1;
    const showNext = currentPage < totalPages;

    function selectPage(page) {
        if (page === currentPage) {
            return;
        }

        history.push(
            generatePath(VIEW_PATH, {
                id,
                selectedPage: page,
            })
        );
    }

    return (
        <div>
            <nav>
                <ul className="pagination">
                    {showPrevious && (
                        <li className="page-item" key="pagination-previous" onClick={() => selectPage(currentPage - 1)}>
                            <a className="page-link">&laquo;</a>
                        </li>
                    )}
                    {/*shows a maximum of 10 page numbers*/}
                    {_.times(Math.min(10, totalPages), (pageIndex) => {
                        // The below way of showing pages is a recreation of google's system.
                        let iteratedPage;
                        if (currentPage <= 6) {
                            // Page is lower than 6, show pages 1 to max 10.
                            iteratedPage = pageIndex + 1;
                        } else if (currentPage >= totalPages - 4) {
                            // Page is in the last few results.
                            // Show current page until the end.
                            iteratedPage = totalPages - 9 + pageIndex;
                        } else {
                            // Page is higher than 6, but not yet in the last few results
                            // Show 5 page numbers before the current page and 4 after.
                            iteratedPage = currentPage - 5 + pageIndex;
                        }

                        return (
                            <li
                                className={currentPage === iteratedPage ? 'page-item active' : 'page-item'}
                                key={'pagination-' + pageIndex}
                                onClick={() => selectPage(iteratedPage)}
                            >
                                <a className="page-link">{iteratedPage}</a>
                            </li>
                        );
                    })}
                    {showNext && (
                        <li className="page-item" key="pagination-next" onClick={() => selectPage(currentPage + 1)}>
                            <a className="page-link">&raquo;</a>
                        </li>
                    )}
                </ul>
            </nav>
        </div>
    );
}

function OpListItems({ opList, opEntryLabels, setAddEntryLabelModalData, loadPage }) {
    const dispatch = useDispatch();
    const { t } = useTranslation('changelist');

    const hub = getEnv('MERCURE_URL');
    const topicUrl = getEnv('OPERATIONS_API_URL');
    const topics = [topicUrl + '/op_entries/{id}', topicUrl + '/op_team_entries/{id}'];

    const update = (data) => {
        dispatch(
            updateEntity({
                entity: data,
            })
        );
    };

    useEffect(() => {
        const url = new URL(hub);

        topics.forEach(function (topic) {
            url.searchParams.append('topic', topic);
        });

        const eventSource = new EventSource(url);

        eventSource.onmessage = function (e) {
            update(JSON.parse(e.data));
        };
    }, [hub]);

    return (
        <>
            {opEntryLabels.length > 0 && (
                <OpEntryLabels
                    opList={opList}
                    opEntryLabels={opEntryLabels}
                    setAddEntryLabelModalData={setAddEntryLabelModalData}
                    loadPage={loadPage}
                />
            )}

            {opEntryLabels.length === 0 && <div className="text-muted">{t('changelist.subnav.list.noChanges')}</div>}
        </>
    );
}

function OpEntryLabels({ opEntryLabels, opList, setAddEntryLabelModalData, loadPage }) {
    const { activeOrganisation } = useSelector((state) => state.security);
    const { opListFilter } = useSelector((state) => state.operations);
    const filterOptions = opListFilter[opList.id];

    let opEntriesList = [...opEntryLabels];

    // Apply filters
    let forceExpanded = false;
    const adminView = shouldSeeAdminView(opList);

    if (filterOptions) {
        forceExpanded = filterOptions['filter-4'];
    }

    const { labels = [] } = useGetLabelsQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                labels: data ?? [],
            }),
        }
    );

    const labelLut = [];
    _.forEach(labels, (label) => {
        labelLut[label['@id']] = label;
    });

    if (adminView) {
        return (
            <AdminList
                labelLut={labelLut}
                opList={opList}
                opLabelEntriesList={opEntriesList}
                forceExpanded={forceExpanded}
                loadPage={loadPage}
            />
        );
    }

    return (
        <ProcessorList
            labelLut={labelLut}
            opList={opList}
            opLabelEntriesList={opEntriesList}
            forceExpanded={forceExpanded}
            setAddEntryLabelModalData={setAddEntryLabelModalData}
            loadPage={loadPage}
        />
    );
}

function AdminList({ labelLut, opList, opLabelEntriesList, forceExpanded, loadPage }) {
    const opEntries = _.groupBy(opLabelEntriesList, 'opEntry');

    return (
        <>
            {Object.keys(opEntries).map((opEntryIri) => {
                const opEntryLabels = opEntries[opEntryIri];

                return (
                    <OpAdminEntry
                        opList={opList}
                        opEntryIri={opEntryIri}
                        opEntryLabels={opEntryLabels}
                        labelLut={labelLut}
                        forceExpanded={forceExpanded}
                        loadPage={loadPage}
                        key={opEntryIri}
                    />
                );
            })}
        </>
    );
}

function OpAdminEntry({ opList, opEntryLabels, opEntryIri, labelLut, forceExpanded, loadPage }) {
    const opEntry = useSelector((state) => selectOpEntryById(state.operations, opEntryIri));

    return (
        <Accordion>
            <OpEntry
                opEntry={opEntry}
                opEntryLabels={opEntryLabels}
                opList={opList}
                labelLut={labelLut}
                forceExpanded={forceExpanded}
                loadPage={loadPage}
            />
        </Accordion>
    );
}

function ProcessorList({ labelLut, opList, opLabelEntriesList, forceExpanded, setAddEntryLabelModalData, loadPage }) {
    const tasks = useSelector(selectAllDepartmentTasksForOpList(opList));
    const department = useSelector(selectDepartmentUserBelongsTo(opList));
    const [tasksRetrieved, setTasksRetrieved] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!tasksRetrieved && department) {
            dispatch(fetchDepartmentTasks({ opListId: opList.id, opDepartmentId: department.id })).then(() =>
                setTasksRetrieved(true)
            );
        }
    }, [dispatch, department]);

    return (
        <>
            {opLabelEntriesList.map((_opEntryLabel) => (
                <OpTeamEntry
                    labelLut={labelLut}
                    opEntryLabel={_opEntryLabel}
                    opList={opList}
                    tasks={tasks}
                    forceExpanded={forceExpanded}
                    setAddEntryLabelModalData={setAddEntryLabelModalData}
                    loadPage={loadPage}
                    key={_opEntryLabel.id}
                />
            ))}
        </>
    );
}

function OpTeamEntry({ labelLut, opEntryLabel, opList, tasks, forceExpanded, setAddEntryLabelModalData, loadPage }) {
    const opEntry = useSelector((state) => selectOpEntryById(state.operations, opEntryLabel.opEntry));

    if (!opEntry) {
        return null;
    }

    return (
        <Accordion>
            <OpEntryLabel
                labelLut={labelLut}
                opEntry={opEntry}
                opEntryLabel={opEntryLabel}
                opList={opList}
                tasks={tasks}
                forceExpanded={forceExpanded}
                setAddEntryLabelModalData={setAddEntryLabelModalData}
                loadPage={loadPage}
            />
        </Accordion>
    );
}

function OpListTitle({ opList }) {
    const departments = useSelector(selectUserDepartments(opList));
    const department = useSelector(selectDepartmentUserBelongsTo(opList));
    const opListPagination = useSelector((state) => state.operations.opListPagination);
    const { count = 0 } = opListPagination ?? {};

    let titleDepartments = [];
    if (shouldSeeAdminView(opList)) {
        titleDepartments = departments;
    } else if (department) {
        titleDepartments = [department];
    }

    return (
        <div className="mb-4">
            <h3 className="text-primary mb-1">
                {opList.name}
                {count > 0 && (
                    <small className="text-muted">
                        &nbsp;{count} {count === 1 ? 'onderdeel' : 'onderdelen'}
                    </small>
                )}
            </h3>
            {titleDepartments.length > 0 && (
                <div className="text-secondary">
                    {titleDepartments.map((titleDepartment) => titleDepartment.name).join('/')}
                </div>
            )}
        </div>
    );
}

function shouldSeeAdminView(opList) {
    if (!opList) {
        return false;
    }

    return opList.userIsAdmin || opList.userIsEditor;
}

export default View;
