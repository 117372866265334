import getEnv, { getBaseName } from '../config/Env';
import RestrictedContent from './global/RestrictedContent';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import {
    BagPlusFill,
    BookmarksFill,
    Calendar2CheckFill,
    FileEarmarkRichtextFill,
    FileTextFill,
    Icon,
    ImageFill,
    ShieldFillCheck,
    TagsFill,
    Translate,
} from 'react-bootstrap-icons';
import Constants from '../config/Constants';
import { BASE_PATH as TRANSLATIONS_BASE_PATH } from 'scenes/Translations';
import { BASE_PATH as DOCUMENTS_BASE_PATH } from 'scenes/DocumentsV2';
import { BASE_PATH as REIMBURSEMENTS_BASE_PATH } from 'scenes/Reimbursements';
import { BASE_PATH as OPERATIONS_LIST_BASE_PATH } from 'scenes/OperationsList';
import { BASE_PATH as PUBLICATIONS_BASE_PATH } from 'scenes/PublicationsV2';
import { BASE_PATH as MEDIA_LIBRARY_BASE_PATH } from 'scenes/MediaLibrary';
import { BASE_PATH as TAGS_BASE_PATH } from 'scenes/Tags';
import { BASE_PATH as LABELS_BASE_PATH } from 'scenes/Labels';
import { ALL_CHECKS_PATH } from 'scenes/TaskTeam';
import { useTranslation } from 'react-i18next';
import { useCurrentOrganisation } from 'hooks/useCurrentOrganisation';
import _ from 'lodash';

export function MainSidebarNav() {
    const { t } = useTranslation('global');

    return (
        <div className="list-group list-group-flush">
            <NavLinkNew
                to={Constants.modules.documents}
                Icon={FileTextFill}
                label={t('mainSidebar.documents')}
                permission={Constants.permissions.menu.documents}
                module={Constants.modules.documents}
            />
            <NavLinkNew
                to={Constants.modules.reimbursements}
                Icon={BagPlusFill}
                label={t('mainSidebar.reimbursements')}
                permission={Constants.permissions.menu.reimbursements}
                module={Constants.modules.reimbursements}
            />
            <NavLinkNew
                to={Constants.modules.translations}
                Icon={Translate}
                label={t('mainSidebar.translations')}
                permission={Constants.permissions.menu.translations}
                module={Constants.modules.translations}
            />
            <NavLinkNew
                to={Constants.modules.operations_list}
                Icon={ShieldFillCheck}
                label={t('mainSidebar.changesList')}
                permission={Constants.permissions.menu.operations_list}
                module={Constants.modules.operations_list}
            />

            <div className="sidebar-nav-section">
                <hr />
                <NavLinkNew
                    theme="publications"
                    to={Constants.modules.publications}
                    Icon={FileEarmarkRichtextFill}
                    label={t('mainSidebar.publications')}
                    permission={Constants.permissions.menu.publications}
                    module={Constants.modules.publications}
                />
                <NavLinkNew
                    to={Constants.modules.task_team}
                    Icon={Calendar2CheckFill}
                    label={t('mainSidebar.taskTeam')}
                    permission={Constants.permissions.menu.task_team}
                    module={Constants.modules.task_team}
                />
            </div>

            <div className="sidebar-nav-section">
                <hr />
                <NavLinkNew
                    to="media_library"
                    Icon={ImageFill}
                    label={t('mainSidebar.media')}
                    module={Constants.modules.documents}
                    permission={Constants.permissions.menu.media}
                />
                <NavLinkNew
                    to={Constants.modules.tags}
                    Icon={TagsFill}
                    label={t('mainSidebar.globalTags')}
                    permission={Constants.permissions.menu.tags}
                    module={Constants.modules.tags}
                />
                <NavLinkNew
                    to={Constants.modules.labels}
                    Icon={BookmarksFill}
                    label={t('mainSidebar.labels')}
                    permission={Constants.permissions.menu.labels}
                    module={Constants.modules.labels}
                />
            </div>
        </div>
    );
}

function NavLinkNew({ to, label, module, permission, Icon, theme }) {
    const url = moduleRoutes[to];

    if (!url) {
        return null;
    }

    return (
        <RestrictedContent module={module} permission={permission}>
            <NavLink
                to={url}
                className={cx(
                    'list-group-item list-group-item-action list-group-item-nav',
                    theme ? 'list-group-item-' + theme : undefined,
                )}
                activeClassName="active"
            >
                <Icon /> {label}
            </NavLink>
        </RestrictedContent>
    );
}

export function MainSidebarLogo() {
    const metaTitle = getEnv('META_TITLE');
    const logo = _.defaultTo(getEnv('LOGO_FILE'), 'docrevolution.png');
    const baseName = _.defaultTo(getBaseName(), '');

    const currentOrganisation = useCurrentOrganisation();
    const currentAccount = currentOrganisation?.account;

    let docRevLogo = `${baseName}/images/application_logo/${logo}`;

    if (currentAccount?.customLogo) {
        docRevLogo = getEnv('CUSTOM_LOGO_URL') + currentAccount.customLogo;
    }

    return <img src={docRevLogo} className="img-fluid" alt={metaTitle} style={{ maxHeight: 58 }} />;
}

export const moduleRoutes = {
    [Constants.modules.documents]: DOCUMENTS_BASE_PATH,
    [Constants.modules.reimbursements]: REIMBURSEMENTS_BASE_PATH,
    [Constants.modules.translations]: TRANSLATIONS_BASE_PATH,
    [Constants.modules.operations_list]: OPERATIONS_LIST_BASE_PATH,
    [Constants.modules.publications]: PUBLICATIONS_BASE_PATH,
    [Constants.modules.task_team]: ALL_CHECKS_PATH,
    media_library: MEDIA_LIBRARY_BASE_PATH,
    [Constants.modules.tags]: TAGS_BASE_PATH,
    [Constants.modules.labels]: LABELS_BASE_PATH,
};
