import {
    ChangeField,
    ReimbursementCode,
    ReimbursementProductCode as ReimbursementProductCodeModel,
    ReimbursementProductHistory,
} from '../../../../models/reimbursement.models';
import { useContext, useState } from 'react';
import { FieldArray, FormikContext, useFormikContext } from 'formik';
import {
    useGetReimbursementCodesQuery,
    useGetReimbursementProductChangesQuery,
} from '../../../../features/reimbursements/reimbursements';
import HelperFunctions from '../../../global/HelperFunctions';
import { Button, Col, Row } from 'react-bootstrap';
import { DocRevSelect, InputField } from '../../../publications_v2/helpers/FieldHelper';
import { TrashFill } from 'react-bootstrap-icons';
import FieldHistory, { AcceptAndRejectButtons } from './FieldHistory';
import Constants from '../../../../config/Constants';
import RestrictedReimbursementContent from '../../RestrictedReimbursementContent';
import cx from 'classnames';
import CustomIcons from '../../../global/CustomIcons';
import { canAcceptChangesRoles } from '../../config/permissions';
import { HistoryContext } from './ReimbursementProduct';
import { useTranslation } from 'react-i18next';

export default function ReimbursementProductCodes({
    categoryUri,
    history,
    reimbursementId,
}: {
    categoryUri: string;
    history?: ReimbursementProductHistory;
    reimbursementId: number;
}) {
    const { values } = useContext(FormikContext);
    const [selectedCode, setSelectedCode] = useState('');
    const { t } = useTranslation('reimbursements');

    const existingCodeUris = values.reimbursementProductCodes.map(
        (_item: ReimbursementProductCodeModel) => _item.reimbursementCode
    );

    const { reimbursementCodes } = useGetReimbursementCodesQuery(categoryUri, {
        selectFromResult: ({ data }) => ({
            reimbursementCodes: data
                ? data
                      .map((_item: ReimbursementCode) => ({
                          ..._item,
                          value: _item['@id'],
                          label: `[${_item.code}] ${_item.name}`,
                          isDisabled: existingCodeUris.includes(_item['@id']),
                      }))
                      .sort(HelperFunctions.sortByString('code'))
                : [],
        }),
    });

    return (
        <div className="reimbursement-codes">
            <div className="py-3">
                <div className="text-primary" style={{ fontSize: '1.2rem' }}>
                    {t('main.dorpdown.reimbursementCodes')}
                </div>
            </div>

            <FieldArray name="reimbursementProductCodes">
                {({ push }) => (
                    <>
                        {values.reimbursementProductCodes.map(
                            (_field: ReimbursementProductCodeModel, index: number) => (
                                <ReimbursementProductCode
                                    reimbursementProductCode={_field}
                                    index={index}
                                    history={history}
                                    reimbursementId={reimbursementId}
                                    key={`code-${index}`}
                                />
                            )
                        )}

                        <RestrictedReimbursementContent
                            roles={[
                                Constants.reimbursementTeamRoles.editor,
                                Constants.reimbursementTeamRoles.finalEditor,
                                Constants.reimbursementTeamRoles.manager,
                                Constants.reimbursementTeamRoles.admin,
                            ]}
                        >
                            <Row noGutters className="my-3">
                                <Col xs={3}>
                                    <DocRevSelect
                                        name="code"
                                        selectedValue={selectedCode}
                                        options={reimbursementCodes}
                                        onChange={(selection: ReimbursementCode) => {
                                            setSelectedCode(selection ? selection['@id'] : '');
                                        }}
                                        props={{
                                            placeholder: `${t('reimbursement.product.selectACode')}...`,
                                            isClearable: true,
                                        }}
                                    />
                                </Col>

                                <Col>
                                    <Button
                                        variant="primary"
                                        className="mt-1 ml-2"
                                        disabled={selectedCode === ''}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            event.stopPropagation();
                                            setSelectedCode('');

                                            push({
                                                reimbursementCode: selectedCode,
                                                text: '',
                                                category: categoryUri,
                                            });
                                        }}
                                    >
                                        {t('reimbursement.product.misc.addCode')}
                                    </Button>
                                </Col>
                            </Row>
                        </RestrictedReimbursementContent>
                    </>
                )}
            </FieldArray>
        </div>
    );
}

function ReimbursementProductCode({
    reimbursementProductCode,
    index,
    history,
    reimbursementId,
}: {
    reimbursementProductCode: ReimbursementProductCodeModel;
    index: number;
    history?: ReimbursementProductHistory;
    reimbursementId: number;
}) {
    const { setFieldValue } = useFormikContext();
    const { scheduledForDeletion, markAsDeleted = false } = reimbursementProductCode;
    const { t } = useTranslation('reimbursements');

    const { reimbursementCode }: { reimbursementCode: ReimbursementCode | undefined } = useGetReimbursementCodesQuery(
        reimbursementProductCode.category,
        {
            selectFromResult: ({ data }) => ({
                reimbursementCode: data
                    ? data.find(
                          (_item: ReimbursementCode) => _item['@id'] === reimbursementProductCode.reimbursementCode
                      )
                    : undefined,
            }),
        }
    );

    const historyEntity: ReimbursementProductCodeModel | undefined = history
        ? history.reimbursementProductCodes.find((_code) => _code.id === reimbursementProductCode.id)
        : undefined;

    const { hasChanges }: { hasChanges: boolean } = useGetReimbursementProductChangesQuery(
        historyEntity?.reimbursementProduct,
        {
            selectFromResult: ({ data }) => {
                const field =
                    data?.fields.find(
                        (field: ChangeField) =>
                            field.class === 'ReimbursementProductCode' &&
                            field.id === historyEntity?.id &&
                            field.property === 'text'
                    ) ?? undefined;

                return {
                    hasChanges: field && field.changes.length > 0,
                };
            },
            skip: historyEntity === undefined,
        }
    );

    if (reimbursementCode === undefined) {
        return null;
    }

    return (
        <div
            className={cx('py-4px-3', {
                'border border-danger': scheduledForDeletion || markAsDeleted,
            })}
            style={{ backgroundColor: index % 2 ? undefined : '#f7fbff', border: '1px solid transparent' }}
        >
            {markAsDeleted && <MarkAsDeletedHeader index={index} />}

            {scheduledForDeletion && (
                <ScheduledForDeletionHeader
                    entityId={reimbursementProductCode.id}
                    reimbursementProductUri={reimbursementProductCode.reimbursementProduct}
                    reimbursementId={reimbursementId}
                />
            )}

            <Row className="py-4 px-3">
                <Col xs={3}>
                    <div className="d-flex align-items-start pt-1">
                        <RestrictedReimbursementContent
                            roles={[
                                Constants.reimbursementTeamRoles.editor,
                                Constants.reimbursementTeamRoles.finalEditor,
                                Constants.reimbursementTeamRoles.manager,
                                Constants.reimbursementTeamRoles.admin,
                            ]}
                        >
                            <TrashFill
                                size={14}
                                className={cx('flex-shrink-0 mr-2', {
                                    'text-danger cursor-pointer':
                                        !hasChanges && !scheduledForDeletion && !markAsDeleted,
                                    'text-muted': hasChanges || scheduledForDeletion || markAsDeleted,
                                })}
                                onClick={() => {
                                    if (!hasChanges && !scheduledForDeletion && !markAsDeleted) {
                                        setFieldValue(`reimbursementProductCodes.${index}.markAsDeleted`, true);
                                    }
                                }}
                                style={{ marginTop: 2 }}
                                data-uk-tooltip={
                                    hasChanges
                                        ? t('reimbursement.product.misc.cannotDeleteCode')
                                        : t('reimbursement.product.misc.deleteCode')
                                }
                            />
                        </RestrictedReimbursementContent>

                        <div>
                            <div className="font-weight-bold" style={{ color: '#344054', fontSize: 12 }}>
                                [{reimbursementCode.code}]
                            </div>
                            <div className="text-secondary small">{reimbursementCode.name}</div>
                        </div>
                    </div>
                </Col>
                <Col>
                    <Row>
                        <RestrictedReimbursementContent
                            roles={[
                                Constants.reimbursementTeamRoles.editor,
                                Constants.reimbursementTeamRoles.finalEditor,
                                Constants.reimbursementTeamRoles.manager,
                                Constants.reimbursementTeamRoles.admin,
                            ]}
                        >
                            <Col>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            {reimbursementCode.type === 1 && <>&euro;</>}
                                            {reimbursementCode.type === 2 && <>%</>}
                                            {reimbursementCode.type === 3 && <>#</>}
                                        </span>
                                    </div>
                                    <InputField
                                        name={`reimbursementProductCodes.${index}.text`}
                                        props={{ disabled: scheduledForDeletion || markAsDeleted }}
                                    />
                                </div>
                            </Col>
                        </RestrictedReimbursementContent>
                        <Col>
                            {historyEntity && (
                                <FieldHistory
                                    reimbursementProductUri={historyEntity.reimbursementProduct}
                                    reimbursementId={reimbursementId}
                                    className="ReimbursementProductCode"
                                    entity={historyEntity}
                                    propName="text"
                                />
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

function MarkAsDeletedHeader({ index }: { index: number }) {
    const { setFieldValue } = useFormikContext();
    const { t } = useTranslation('reimbursements');

    return (
        <Row>
            <Col>
                <div className="py-2 uk-background-danger-light uk-text-danger text-center small">
                    {t('reimbursement.product.misc.codeWillBeDeleted')}
                    <a
                        onClick={() => {
                            setFieldValue(`reimbursementProductCodes.${index}.markAsDeleted`, undefined);
                        }}
                    >
                        <CustomIcons icon="undo" className="ml-3 mr-1" /> {t('reimbursement.product.misc.restore')}
                    </a>
                </div>
            </Col>
        </Row>
    );
}

function ScheduledForDeletionHeader({
    entityId,
    reimbursementProductUri,
    reimbursementId,
}: {
    entityId: number;
    reimbursementProductUri: string;
    reimbursementId: number;
}) {
    const { endGroupId } = useContext(HistoryContext);
    const activeVersionIsMostRecent = endGroupId === 0;
    const { t } = useTranslation('reimbursements');

    const { field }: { field: ChangeField | undefined } = useGetReimbursementProductChangesQuery(
        reimbursementProductUri,
        {
            selectFromResult: ({ data }) => ({
                field:
                    data?.fields.find(
                        (field: ChangeField) =>
                            field.class === 'ReimbursementProductCode' &&
                            field.id === entityId &&
                            field.property === 'scheduledForDeletion'
                    ) ?? undefined,
            }),
        }
    );

    if (!field) {
        return null;
    }

    return (
        <Row>
            <Col>
                <div className="py-2 uk-background-danger-light uk-text-danger small d-flex align-items-center justify-content-center">
                    <div className="mr-3">{t('reimbursement.product.misc.markedForDeletion')}</div>

                    <RestrictedReimbursementContent roles={canAcceptChangesRoles}>
                        {activeVersionIsMostRecent && field.changes.length > 0 && (
                            <div className="d-flex justify-content-center">
                                <AcceptAndRejectButtons reimbursementId={reimbursementId} field={field} />
                            </div>
                        )}
                    </RestrictedReimbursementContent>
                </div>
            </Col>
        </Row>
    );
}
