import MediaSelector, { getFileUri, isValidUri } from '../../../../../media_library/views/MediaSelector';
import Constants from '../../../../../../config/Constants';
import HelperFunctions from '../../../../../global/HelperFunctions';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

export function FileBlock({ block, index, readOnly = false, restrictions }) {
    const blockContent = block?.latestContent || block?.baseContent || '';
    const file = HelperFunctions.tryParseJSON(blockContent, null);
    const fileUri = getFileUri(_.get(file, 'uri', ''));
    const hasMedia = isValidUri(fileUri);

    return (
        <div className="d-flex flex-row p-4">
            <FileMedia file={file} readOnly={readOnly} index={index} />

            {hasMedia && <FileOptions block={block} restrictions={restrictions} />}
        </div>
    );
}

function FileMedia({ file, readOnly, index }) {
    const fileUri = getFileUri(_.get(file, 'uri', ''));
    const { setFieldValue } = useFormikContext();

    return (
        <MediaSelector
            selectedValue={fileUri}
            readOnly={readOnly}
            onChange={(file) => {
                const description = _.get(file, 'description', null);

                const latestContent = JSON.stringify({
                    uri: _.get(file, '@id', ''),
                    options: {
                        align: FILE_OPTIONS_DEFAULT_ALIGN,
                        description: description ?? '',
                        alt: '',
                        url: '',
                        customSize: false,
                        width: '',
                        height: '',
                    },
                });

                setFieldValue(`areaBlocks.${index}.latestContent`, latestContent);
            }}
        />
    );
}

function FileOptions({ block, restrictions }) {
    const options = fileOptions(block);
    const { align, description, alt, url, customSize } = options;
    const labelMinWidth = 170;
    const { t } = useTranslation('documents');
    return (
        <>
            <div className="ml-3 mt-1">
                <div className="mb-1">
                    <div className="d-inline-block text-secondary" style={{ minWidth: labelMinWidth }}>
                        {t('document.navbar.main.editor.left.blocks.types.fileBlock.alignment')}
                    </div>
                    {Constants.fileBlock.alignOptions[align]}
                </div>

                {_.isEmpty(description) === false && (
                    <div className="mb-1">
                        <div className="d-inline-block text-secondary" style={{ minWidth: labelMinWidth }}>
                            {t('document.navbar.main.editor.left.blocks.types.fileBlock.description')}
                        </div>
                        {description}
                    </div>
                )}

                {_.isEmpty(alt) === false && (
                    <div className="mb-1">
                        <div className="d-inline-block text-secondary" style={{ minWidth: labelMinWidth }}>
                            {t('document.navbar.main.editor.left.blocks.types.fileBlock.altText')}
                        </div>
                        {alt}
                    </div>
                )}

                {_.isEmpty(url) === false && (
                    <div className="mb-1">
                        <div className="d-inline-block text-secondary" style={{ minWidth: labelMinWidth }}>
                            URL
                        </div>
                        {url}
                    </div>
                )}

                <div>
                    <div className="d-inline-block text-secondary" style={{ minWidth: labelMinWidth }}>
                        {t('document.navbar.main.editor.left.blocks.types.fileBlock.customDimensions')}
                    </div>
                    {restrictions.canChangeImageSettings && customSize
                        ? t('document.navbar.main.editor.left.blocks.types.fileBlock.yes')
                        : t('document.navbar.main.editor.left.blocks.types.fileBlock.no')}
                    {restrictions.canChangeImageSettings && customSize && (
                        <span className="small">
                            &nbsp;(
                            <span className="text-secondary">
                                {options['width']}/{options['height']}
                            </span>
                            pixels)
                        </span>
                    )}
                </div>
            </div>
        </>
    );
}

const FILE_OPTIONS_DEFAULT_ALIGN = 'left';

const getFileBlockOptions = (file, customOptions) => {
    return _.defaults(customOptions, file?.options ?? {}, {
        align: 'left',
        description: '',
        alt: '',
        url: '',
        customSize: false,
        width: '',
        height: '',
    });
};

export function fileOptions(block) {
    const file = HelperFunctions.tryParseJSON(block.latestContent, null);

    return getFileBlockOptions(file, block.properties.options);
}
