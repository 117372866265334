import React, { useState } from 'react';
import cx from 'classnames';
import { ArrowDown, ArrowUp } from 'react-bootstrap-icons';
import { Accordion, Button, Collapse } from 'react-bootstrap';
import { AiFillCaretRight } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';

const DataTableContext = React.createContext({
    canSelectItems: false,
    selectedRows: [],
    toggleRow: () => {},
    rowIsSelected: () => {},
    setSelectedRows: () => {},
});

export function DataTable({ variant = undefined, selectItems = false, ...props }) {
    const [selectedRows, setSelectedRows] = useState([]);
    const [canSelectItems, setSelectItems] = useState(selectItems);

    const toggleRow = (id) => {
        setSelectedRows(selectedRows.includes(id) ? selectedRows.filter((i) => i !== id) : [...selectedRows, id]);
    };
    const rowIsSelected = (id) => selectedRows.includes(id);

    return (
        <DataTableContext.Provider
            value={{
                canSelectItems,
                selectedRows,
                toggleRow,
                rowIsSelected,
                setSelectedRows: (rows) => {
                    setSelectedRows(rows);
                },
            }}
        >
            <div className={cx('data-table', variant ? `data-table-${variant}` : undefined)}>{props.children}</div>
        </DataTableContext.Provider>
    );
}

export function DataTableGroup({ name = '', level = 0, children, rowIds = [], handleMove }) {
    const [open, setOpen] = useState(true);
    const { t } = useTranslation('documents');

    const toggleGroup = () => {
        setOpen(!open);
    };

    const moveUp = () => {
        if (typeof handleMove === 'function') {
            handleMove('up');
        }
    };

    const moveDown = () => {
        if (typeof handleMove === 'function') {
            handleMove('down');
        }
    };

    return (
        <div
            className={cx('data-table-group', 'data-table-group-level-' + level, {
                'data-table-group-expanded': open,
            })}
        >
            <div className="data-table-group-header d-flex align-items-center">
                {level > 0 && <GroupCheckbox rowIds={rowIds} />}

                <div className="cursor-pointer mr-2" onClick={toggleGroup}>
                    {name}
                </div>

                {level === 0 && (
                    <>
                        <AiFillCaretRight size={14} className="cursor-pointer chevron" onClick={toggleGroup} />
                        <Button
                            variant="icon"
                            size="sm"
                            className="text-secondary btn-icon-only ml-2 px-1 py-0"
                            data-uk-tooltip={t('documents.folderMoveUp')}
                            onClick={moveUp}
                        >
                            <ArrowUp size={12} />
                        </Button>
                        <Button
                            variant="icon"
                            size="sm"
                            className="text-secondary btn-icon-only px-1 py-0"
                            data-uk-tooltip={t('documents.folderMoveDown')}
                            onClick={moveDown}
                        >
                            <ArrowDown size={12} />
                        </Button>
                    </>
                )}
            </div>

            <Collapse in={open}>
                <div>
                    <Accordion>
                        <div className="data-table-item">{children}</div>
                    </Accordion>
                </div>
            </Collapse>
        </div>
    );
}

function GroupCheckbox({ rowIds = [] }) {
    return (
        <DataTableContext.Consumer>
            {({ selectedRows, setSelectedRows, canSelectItems }) => {
                if (canSelectItems === false) {
                    return null;
                }

                const selectedGroupRows = rowIds.filter((id) => selectedRows.includes(id));

                const noneSelected = selectedGroupRows.length === 0;
                const allSelected = selectedGroupRows.length === rowIds.length;
                const indeterminate = !noneSelected && !allSelected;

                return (
                    <input
                        ref={(el) => el && (el.indeterminate = indeterminate)}
                        checked={!noneSelected}
                        disabled={rowIds.length === 0}
                        type="checkbox"
                        className="mr-2"
                        onChange={() => {
                            if (allSelected) {
                                // Deselect all
                                setSelectedRows(selectedRows.filter((id) => rowIds.includes(id) === false));
                            } else {
                                // Select all
                                setSelectedRows(
                                    selectedRows.filter((id) => rowIds.includes(id) === false).concat(rowIds)
                                );
                            }
                        }}
                    />
                );
            }}
        </DataTableContext.Consumer>
    );
}
