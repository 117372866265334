import React from 'react';
import { useTranslation } from 'react-i18next';
import { GB, NL, DE, FR } from 'country-flag-icons/react/3x2';
import { useUpdateUserPreferencesMutation } from 'features/security/authApi';
import { useSelector } from 'react-redux';

const LanguageSwitcher = () => {
    const [updateUserPreferences] = useUpdateUserPreferencesMutation();
    const { activeOrganisation } = useSelector((state) => state.security);
    const { userProfile } = useSelector((state) => state.security);
    const currentUserId = userProfile.userId;
    const lngs = {
        nl: { flag: <NL />, nativeName: 'Dutch' },
        en: { flag: <GB />, nativeName: 'English' },
        fr: { flag: <FR />, nativeName: 'Français' },
        de: { flag: <DE />, nativeName: 'Deutsch' },
    };
    const { i18n } = useTranslation();

    const handleLanguageChange = async (language) => {
        i18n.changeLanguage(language);

        if (currentUserId && activeOrganisation) {
            try {
                await updateUserPreferences({
                    organisationId: activeOrganisation,
                    userId: currentUserId,
                    languagePreference: language,
                });
            } catch (error) {
                console.error('Error updating user preferences:', error);
            }
        }
    };

    return (
        <>
            {Object.keys(lngs).map((lng) => (
                <button
                    key={lng}
                    type="button"
                    onClick={() => handleLanguageChange(lng)}
                    style={{
                        margin: '0 2px',
                        padding: 0,
                        width: '20px',
                        height: '20px',
                        border: 'none',
                        background: 'transparent',
                        cursor: 'pointer',
                    }}
                >
                    {lngs[lng].flag}
                </button>
            ))}
        </>
    );
};

export default LanguageSwitcher;
