// See https://www.tiny.cloud/docs/advanced/editor-control-identifiers/#toolbarcontrols for all available options

const TinymceConfig = {
    advlist_bullet_styles: 'disc',
    advlist_number_styles: 'default,lower-alpha,upper-alpha,lower-roman,upper-roman',
    block_formats: 'Paragraaf=p;Kop=h3;Subkop=h4;',
    style_formats: [
        { title: 'Vet', inline: 'span', classes: 'font-weight-bold' },
        { title: 'Toelichting', block: 'p', classes: 'toelichting' },
        { title: 'Antwoord', block: 'p', classes: 'answer' },
        { title: 'Intro', block: 'p', classes: 'intro' },
    ],
    branding: false,
    inline: true,
    language_url: '/tinymce/nl.js',
    menubar: false,
    min_height: 610,
    paste_as_text: true,
    plugins: 'link lists advlist anchor',
    resize: false,
    statusbar: false,
    toolbar:
        'undo redo | blocks styles bold italic underline superscript subscript | anchor link hr tags ref | numlist bullist startNumber | removeformat pastetext',
};

// New function to allow translation
export function getTinymceConfig(t, language = 'nl') {
    const languageIso = getTinyLanguage(language);

    return {
        advlist_bullet_styles: 'disc',
        advlist_number_styles: 'default,lower-alpha,upper-alpha,lower-roman,upper-roman',
        block_formats: `${t('global:editor.block_formats.paragraph')}=p;${t('global:editor.block_formats.heading')}=h3;${t('global:editor.block_formats.subHeading')}=h4;`,
        style_formats: [
            { title: t('global:editor.style_formats.bold'), inline: 'span', classes: 'font-weight-bold' },
            { title: t('global:editor.style_formats.explanation'), block: 'p', classes: 'toelichting' },
            { title: t('global:editor.style_formats.answer'), block: 'p', classes: 'answer' },
            { title: t('global:editor.style_formats.intro'), block: 'p', classes: 'intro' },
        ],
        branding: false,
        fixed_toolbar_container: '#editor-toolbar',
        inline: true,
        language: languageIso,
        language_url: `/tinymce/${languageIso}.js`,
        menubar: false,
        min_height: 610,
        paste_as_text: true,
        plugins: 'link lists advlist anchor',
        resize: false,
        statusbar: false,
        toolbar:
            'undo redo | blocks styles bold italic underline superscript subscript | anchor link hr tags ref | numlist bullist startNumber | removeformat pastetext',
    };
}

function getTinyLanguage(language = 'nl') {
    if (language === 'fr') {
        return 'fr_FR';
    }

    if (language === 'en') {
        return 'en_GB';
    }

    return language;
}

export default TinymceConfig;
