import MainContentNav from '../../Navbar';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import Sidebar from './index/Sidebar';
import {
    batchEditPublicationGroups,
    editPublicationGroups,
    fetchPublicationGroups,
    fetchPublications,
    selectOrganisationPublicationGroups,
    setSelectedRows,
} from 'features/publications/publicationsSlice';
import Publications from './index2/Publications';
import EditFolderModal from '../modals/EditFolderModal';
import Filters from './index/Filters';
import { useParams } from 'react-router-dom';
import PublishModal from '../modals/PublishModal';
import ExportChangesModal from '../modals/ExportChangesModal';
import DuplicateModal from '../modals/DuplicateModal';
import _ from 'lodash';
import HelperFunctions from '../../global/HelperFunctions';
import { useTranslation } from 'react-i18next';
import SubNav from './SubNav';
import Constants, { Permissions } from '../../../config/Constants';
import { useHasModuleEnabled, useHasPermission } from '../../global/RestrictedContent';
import cx from 'classnames';
import { KioskBulkEditModal } from '../modals/KioskBulkEditModal';
import { MovePublicationsModal } from '../modals/MovePublicationsModal';
import { useGetPublications } from 'pages/publications_v2/hooks/useGetPublications';
import { updateAbilityForPublications } from 'ability/Ability';
import { useAbility } from 'ability/useAbility';
import { useCurrentOrganisation } from 'hooks/useCurrentOrganisation';
import { useUserProfile } from 'hooks/useUserProfile';
import { NumberParam, useQueryParam } from 'use-query-params';

export default function Index() {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [showPublishModal, setShowPublishModal] = useState(false);
    const [showPublishModalQuery, setShowPublishModalQuery] = useQueryParam('showPublishModal', NumberParam);
    const [showExportChangesModal, setShowExportChangesModal] = useState(false);
    const [showDuplicateModal, setShowDuplicateModal] = useState(false);
    const [showMovePublicationsModal, setShowMovePublicationsModal] = useState(false);
    const [showKioskBulkEditModal, setShowKioskBulkEditModal] = useState(false);
    const currentOrganisation = useCurrentOrganisation();
    const { folderId } = useParams();
    const allGroups = useSelector(selectOrganisationPublicationGroups());
    const { t } = useTranslation('publications');
    const hasAccessControlModuleEnabled = useHasModuleEnabled(Constants.modules.publication_access_control);
    const hasKioskPanelEnabled = useHasPermission(Permissions.Kiosk.Create);
    const hasSubnav = hasAccessControlModuleEnabled || hasKioskPanelEnabled;
    const ability = useAbility();
    const userProfile = useUserProfile();

    const allPublications = useGetPublications();
    const numberOfPublications = allPublications.length;

    useEffect(() => {
        updateAbilityForPublications(ability, numberOfPublications, userProfile, currentOrganisation);
    }, [numberOfPublications, userProfile, currentOrganisation]);

    useEffect(() => {
        Promise.all([dispatch(fetchPublications()), dispatch(fetchPublicationGroups())]).then(() => {
            setLoading(false);
        });
    }, [dispatch]);

    useEffect(() => {
        dispatch(setSelectedRows([]));

        if (folderId !== undefined && isLoading === false) {
            dispatch(fetchPublications(folderId));
        }
    }, [folderId]);

    const onGroupDragEnd = (result) => {
        const { destination, source } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        const sourceGroup = source.droppableId === 'default' ? null : source.droppableId;
        const destinationGroup = destination.droppableId === 'default' ? null : destination.droppableId;

        // Sort within group
        if (sourceGroup === destinationGroup) {
            let newGroups = _.cloneDeep(allGroups.filter((_group) => _group.parent === destinationGroup));
            newGroups = HelperFunctions.arrayMove(newGroups, source.index, destination.index);

            // Reset sort order
            const changes = newGroups.map((_group, index) => {
                return {
                    id: _group.id,
                    changes: {
                        sortOrder: index,
                    },
                };
            });

            // Update local state first to prevent flickering
            dispatch(editPublicationGroups(changes));

            // Update APi
            dispatch(
                batchEditPublicationGroups({
                    formData: {
                        publicationGroups: changes,
                    },
                })
            );
        } else {
            // Remove from old group
            let oldGroups = _.cloneDeep(
                allGroups
                    .filter((_group) => _group.parent === sourceGroup && _group.rootLevel === false)
                    .sort(HelperFunctions.dynamicSort('sortOrder'))
            );
            const [removed] = oldGroups.splice(source.index, 1);

            if (!removed) {
                return;
            }

            // Add to new group
            removed.parent = destinationGroup;
            let newGroups = _.cloneDeep(
                allGroups
                    .filter((_group) => _group.parent === destinationGroup)
                    .sort(HelperFunctions.dynamicSort('sortOrder'))
            );
            newGroups.splice(destination.index, 0, removed);

            newGroups = newGroups.map((_group, index) => {
                return {
                    ..._group,
                    sortOrder: index,
                };
            });

            const changes = newGroups.map((_group) => {
                return {
                    id: _group.id,
                    changes: {
                        parent: _group.parent,
                        sortOrder: _group.sortOrder,
                    },
                };
            });

            // Update local state first to prevent flickering
            dispatch(editPublicationGroups(changes));

            // Update APi
            dispatch(
                batchEditPublicationGroups({
                    formData: {
                        publicationGroups: changes,
                    },
                })
            );
        }
    };

    const handleMove = (direction, group, index) => {
        if (direction === 'up' && index === 0) {
            return;
        }

        const payload = {
            draggableId: group.id,
            organisationId: currentOrganisation.id,
            source: {
                index,
                droppableId: group.parent,
            },
            destination: {
                index: direction === 'up' ? index - 1 : index + 1,
                droppableId: group.parent,
            },
        };

        onGroupDragEnd(payload);
    };

    return (
        <>
            <MainContentNav title={t('breadcrumb')} />

            {hasSubnav && <SubNav />}

            <div
                className={cx('content-static-body', {
                    'has-subnav': hasSubnav,
                })}
            >
                <div className="d-flex align-items-stretch h-100">
                    <div className="content-sidebar overflow-auto">
                        <Sidebar
                            setShowModal={setShowModal}
                            setShowDuplicateModal={setShowDuplicateModal}
                            handleMove={handleMove}
                            onGroupDragEnd={onGroupDragEnd}
                        />
                    </div>
                    <div className="flex-grow-1 overflow-auto" style={{ marginTop: 75 }}>
                        <div className="py-3 px-4">
                            <Publications
                                setShowPublishModal={setShowPublishModal}
                                setShowExportChangesModal={setShowExportChangesModal}
                                isLoading={isLoading}
                                setShowDuplicateModal={setShowDuplicateModal}
                                handleMove={handleMove}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div
                className={cx('content-static-filters px-4 py-3', {
                    'has-subnav': hasSubnav,
                })}
            >
                <Filters
                    setShowPublishModal={setShowPublishModal}
                    setShowDuplicateModal={setShowDuplicateModal}
                    setShowKioskBulkEditModal={setShowKioskBulkEditModal}
                    setShowMovePublicationsModal={setShowMovePublicationsModal}
                />
            </div>

            <EditFolderModal showModal={showModal} handleClose={() => setShowModal(false)} />

            <PublishModal
                handleClose={() => {
                    setShowPublishModal(false);
                    setShowPublishModalQuery(undefined);
                }}
                showModal={!!showPublishModal}
                payload={showPublishModal}
            />

            <ExportChangesModal
                handleClose={() => setShowExportChangesModal(false)}
                showModal={!!showExportChangesModal}
                payload={showExportChangesModal}
            />

            <DuplicateModal
                handleClose={() => setShowDuplicateModal(false)}
                showModal={!!showDuplicateModal}
                payload={showDuplicateModal}
            />

            <KioskBulkEditModal
                handleClose={() => setShowKioskBulkEditModal(false)}
                showModal={!!showKioskBulkEditModal}
                payload={showKioskBulkEditModal}
            />

            <MovePublicationsModal
                onHide={() => setShowMovePublicationsModal(false)}
                show={!!showMovePublicationsModal}
                payload={showMovePublicationsModal}
            />
        </>
    );
}

export const getSelectedGroupId = (asUri = false) => {
    const { folder, folderId } = useParams();

    if (folderId === undefined || folder === 'list') {
        return '0';
    }

    if (asUri) {
        return '/api/publication_groups/' + folderId;
    }

    return parseInt(folderId);
};
