import { TranslationContainer, TranslationKey, TranslationLanguage } from '../../../../../models/translation.models';
import { useParams } from 'react-router-dom';
import {
    translationApi,
    useGetEntityReference,
    useGetTranslationKeyQuery,
    useGetVariantPackagesQuery,
} from '../../../../../features/translations/translationApi';
import { VariantPackage } from '../../../../../models/documents.models';
import _ from 'lodash';
import { TranslationInner } from '../EntityTranslation';
import { useAppDispatch } from '../../../../../store';
import { entityTypes } from '../../../config/Constants';
import { useContext, useEffect } from 'react';
import { TranslationContext } from '../Translate';
import { useTranslation } from 'react-i18next';

export function TranslateVariantPackage({
    translationContainer,
    translationLanguage,
}: {
    translationContainer: TranslationContainer;
    translationLanguage: TranslationLanguage;
}) {
    const dispatch = useAppDispatch();
    const { setTitle, title }: { title: string; setTitle: (title: string) => void } = useContext(TranslationContext);
    const params: { entityId: string; translationKeyId: string; entityType: string } = useParams();
    const { t } = useTranslation('translations');

    const { translationKey }: { translationKey: TranslationKey | undefined } = useGetTranslationKeyQuery(
        params.translationKeyId,
        {
            selectFromResult: ({ data }) => ({
                translationKey: data,
            }),
        }
    );

    const entityReference = useGetEntityReference(
        translationContainer['@id'],
        params.entityType,
        `/api/${params.entityType}/${params.entityId}`
    );

    const { variantPackage }: { variantPackage: VariantPackage | undefined } = useGetVariantPackagesQuery(undefined, {
        selectFromResult: ({ data }) => ({
            variantPackage: data?.find(
                (_variantPackage: VariantPackage) => _variantPackage.id === _.toInteger(params.entityId)
            ),
        }),
    });

    useEffect(() => {
        if (title === '' && variantPackage && translationKey) {
            setTitle(_.get(variantPackage, translationKey.property));
        }
    }, [title, variantPackage, translationKey]);

    if (translationKey === undefined || entityReference === undefined || variantPackage === undefined) {
        return null;
    }

    const handleTranslationMutation = () => {
        dispatch(
            translationApi.endpoints.getTranslationContainerEntityReferences.initiate(
                {
                    uri: translationContainer['@id'],
                    entityType: entityTypes.VARIANT_PACKAGE,
                },
                { subscribe: false, forceRefetch: true }
            )
        );
    };

    return (
        <TranslationInner
            isHtml
            entityReference={entityReference}
            translationKey={translationKey}
            content={_.get(variantPackage, translationKey.property)}
            languageIso={translationLanguage.languageIso}
            translationContainer={translationContainer}
            entity={{
                ...variantPackage,
                title: t('translation.navbar.dashboard.translation.area.policy'),
            }}
            handleTranslationMutation={handleTranslationMutation}
        />
    );
}
