import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useDeletePropositionMutation } from '../../../../features/reimbursements/reimbursements';
import { ExclamationTriangle, JournalRichtext, PencilSquare } from 'react-bootstrap-icons';
import AssignLabelButton from '../../../labels/AssignLabelButton';
import { Dropdown, SplitButton } from 'react-bootstrap';
import { PROPOSITION_EDIT_PATH } from '../../../../scenes/Reimbursements';
import HelperFunctions from '../../../global/HelperFunctions';
import cx from 'classnames';
import { Draggable } from 'react-beautiful-dnd';
import { useState } from 'react';
import GenerateSummaryModal from '../modals/GenerateSummaryModal';
import { RxDragHandleDots2 } from 'react-icons/rx';
import { useTranslation } from 'react-i18next';

export function Proposition({ proposition, propositionGroupId, index }) {
    const history = useHistory();
    const [deleteProposition] = useDeletePropositionMutation();
    const { categoryId } = useParams();
    const { id, name, description = '', documentVariantIds = [] } = proposition;
    const [showGenerateSummaryModal, setShowGenerateSummaryModal] = useState(false);
    const { t } = useTranslation('reimbursements');

    const handleSelect = (action) => {
        switch (action) {
            case 'summary':
                setShowGenerateSummaryModal(true);
                break;
            case 'delete':
                if (canDelete) {
                    HelperFunctions.alertModal(t('proposition.editProposition.cannotDelete', { name }));
                } else {
                    HelperFunctions.confirmModal(
                        name + t('proposition.editProposition.deleteQuestion'),
                        'danger',
                        false
                    ).then(() => {
                        deleteProposition(proposition['@id']);
                    });
                }
                break;
        }
    };

    const canDelete = documentVariantIds.length === 0;

    return (
        <>
            <Draggable draggableId={proposition['@id']} index={index}>
                {(provided, snapshot) => (
                    <div
                        className={cx('data-table-item-wrapper', {
                            'is-dragging': snapshot.isDragging,
                        })}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <div className="data-table-item">
                            <div className="data-table-item-header px-4 py-3">
                                <div className="d-flex align-items-center">
                                    <RxDragHandleDots2 size={12} className="flex-shrink-0 icon-grip text-muted" />
                                    <JournalRichtext size={18} className="flex-shrink-0 text-dark-blue mr-3" />

                                    <div className="d-flex flex-column" style={{ marginBlock: -3.5 }}>
                                        <div className="d-flex align-items-center">
                                            <div className="field-title mr-2">{name}</div>
                                            <AssignLabelButton
                                                context={{
                                                    entity: {
                                                        ...proposition,
                                                        title: proposition.name,
                                                    },
                                                    entityType: 'variantPackage',
                                                }}
                                            />
                                        </div>

                                        {description !== '' && (
                                            <div
                                                className="d-inline-block text-truncate text-secondary"
                                                style={{ fontSize: 11 }}
                                            >
                                                {description}
                                            </div>
                                        )}
                                    </div>
                                    <div className="ml-auto">
                                        <SplitButton
                                            id={`btn-edit-${id}`}
                                            variant="primary"
                                            title={
                                                <>
                                                    <PencilSquare />
                                                    {t('proposition.editProposition.properties.title')}
                                                </>
                                            }
                                            onSelect={handleSelect}
                                            onClick={() => {
                                                history.push(
                                                    generatePath(PROPOSITION_EDIT_PATH, {
                                                        categoryId,
                                                        action: 'properties',
                                                        folderId: propositionGroupId,
                                                        propositionId: id,
                                                    })
                                                );
                                            }}
                                            alignRight={true}
                                        >
                                            <Dropdown.Item eventKey="summary">
                                                {t('proposition.editProposition.properties.overview')}&hellip;
                                            </Dropdown.Item>
                                            <Dropdown.Item eventKey="delete">
                                                <span className="text-danger">
                                                    {!canDelete && <ExclamationTriangle className="mr-1" />}
                                                    {t('proposition.editProposition.properties.delete')}
                                                </span>
                                            </Dropdown.Item>
                                        </SplitButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Draggable>

            {showGenerateSummaryModal && (
                <GenerateSummaryModal
                    proposition={proposition}
                    handleClose={() => setShowGenerateSummaryModal(false)}
                />
            )}
        </>
    );
}
