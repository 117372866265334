import { useEffect, useState } from 'react';
import Parser from 'html-react-parser';
import * as XLSX from 'xlsx';

export function ExcelViewer({ data }) {
    const [html, setHtml] = useState();

    useEffect(() => {
        const workbook = XLSX.read(data);
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const table = XLSX.utils.sheet_to_html(worksheet);

        setHtml(table);
    }, []);

    if (!html) {
        return null;
    }

    return <div id="excel">{Parser(html)}</div>;
}
