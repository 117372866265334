import { useEffect, useState } from 'react';

export function ImageViewer({ data }) {
    const [img, setImg] = useState();

    useEffect(() => {
        const blob = new Blob([data]);
        setImg(URL.createObjectURL(blob));
    }, []);

    if (!img) {
        return null;
    }

    return (
        <div id="image">
            <img src={img} className="img-fluid" />
        </div>
    );
}
