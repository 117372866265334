import { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useUploadPrivateFileMutation } from 'features/mediaLibrary/mediaApi';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { useAddAttachmentMutation } from 'features/comments/commentApi';
import mime from 'mime';

export function Dropzone({
    task,
    taskVersionUri = null,
    isDisabled = false,
    type = 'attachment',
    allowedExtensions = [],
}) {
    const [addAttachment] = useAddAttachmentMutation();
    const [uploadPrivateFile] = useUploadPrivateFileMutation();
    const organisationId = useActiveOrganisation();

    const accept = getAcceptedFileTypes();

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        accept,
        onDrop: onDrop,
        disabled: isDisabled,
    });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
            ...(isDisabled ? disabledStyle : {}),
        }),
        [isDragActive, isDragReject, isDragAccept, isDisabled],
    );

    return (
        <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <div>Klik hier of sleep bestanden naar dit vak</div>
        </div>
    );

    function onDrop(acceptedFiles) {
        Promise.all(
            acceptedFiles.map((file) => {
                return new Promise((resolve) => {
                    uploadPrivateFile({
                        file,
                        organisationId,
                    }).then((result) => {
                        const upload = result?.data;

                        if (upload) {
                            addAttachment({
                                organisationId,
                                task: task['@id'],
                                taskVersion: taskVersionUri,
                                uri: upload['@id'],
                                url: upload.url,
                                fileName: upload.fileName,
                                mime: upload.mime,
                                type,
                            });
                        }

                        resolve(result?.data);
                    });
                });
            }),
        );
    }

    function getAcceptedFileTypes() {
        const extensions = allowedExtensions.length > 0 ? allowedExtensions : [
            'txt',
            'png',
            'jpg',
            'jpeg',
            'docx',
            'doc',
            'pdf',
            'mp3',
            'mp4',
            'tiff',
            'gif',
            'ai',
            'eps',
            'xls',
            'xlsx',
            'eml',
            'msg',
            'flv',
            'mov',
            'ppt',
            'pptx',
        ];
        const accept = {};

        extensions.forEach((extension) => {
            const mimeType = mime.getType(extension);

            if (mimeType) {
                if (accept.hasOwnProperty(mimeType)) {
                    accept[mimeType].push(`.${extension}`);
                } else {
                    accept[mime.getType(extension)] = [`.${extension}`];
                }
            }
        });

        return accept;
    }
}

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
};

const activeStyle = {
    borderColor: '#2196f3',
};

const acceptStyle = {
    borderColor: '#00e676',
    backgroundColor: '#00e67614',
};

const rejectStyle = {
    borderColor: '#ff1744',
};

const disabledStyle = {
    opacity: 0.5,
};
