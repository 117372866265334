import { NumberParam, useQueryParam } from 'use-query-params';
import { Badge, Modal } from 'react-bootstrap';
import { ActionLinkButton, SecondaryButton } from 'components/Buttons';
import { useTranslation } from 'react-i18next';
import { useGetVariant } from '../../../hooks/useGetVariant';
import { generatePath, useHistory } from 'react-router-dom';
import { INDEX_PATH } from 'scenes/PublicationsV2';
import Constants from '../../../../../config/Constants';
import cx from 'classnames';
import { useGetDocumentVariantPublications } from 'pages/documents_v2/views/edit/variants/useGetDocumentVariantPublications';
import { useGetBaseVariant } from 'pages/documents_v2/hooks/useGetBaseVariant';

export function VariantPublicationsModal() {
    const { t } = useTranslation('documents');
    const { t: tGlobal } = useTranslation('global');
    const [variantPublications, setVariantPublications] = useQueryParam('variantPublications', NumberParam);
    const showModal = variantPublications !== undefined;

    const hide = () => {
        setVariantPublications(undefined);
    };

    return (
        <Modal show={showModal} onHide={hide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{t('document.navbar.variants.publications.title')}</Modal.Title>
            </Modal.Header>

            <Modal.Body>{showModal && <ModalContent variantId={variantPublications} />}</Modal.Body>

            <Modal.Footer>
                <SecondaryButton onClick={hide}>{tGlobal('btn.close')}</SecondaryButton>
            </Modal.Footer>
        </Modal>
    );
}

function ModalContent({ variantId }) {
    const { t } = useTranslation('documents');
    const variant = useGetVariant(variantId);
    const baseVariant = useGetBaseVariant();
    const publicationsForVariant = useGetDocumentVariantPublications(variantId, baseVariant);

    return (
        <>
            {variant && (
                <div className="mb-3">
                    {t('document.navbar.variants.publications.linkedPublications')}
                    &nbsp;<strong>{variant.name}</strong>:
                </div>
            )}

            {publicationsForVariant.length > 0 ? (
                <LinkedPublications linkedPublications={publicationsForVariant} />
            ) : (
                <div>...</div>
            )}
        </>
    );
}

function LinkedPublications({ linkedPublications = [] }) {
    const history = useHistory();

    return (
        <div>
            {linkedPublications.map((publication) => (
                <div
                    className="border p-2 mb-2 d-flex align-items-center justify-content-between"
                    key={`publication-${publication.id}`}
                >
                    <ActionLinkButton
                        onClick={() => {
                            const groupId = publication.publicationGroup
                                ? publication.publicationGroup.split('/').pop()
                                : '0';

                            history.push(
                                generatePath(INDEX_PATH, {
                                    folder: groupId !== '0' ? 'folder' : 'list',
                                    folderId: groupId,
                                    publicationId: publication.id,
                                })
                            );
                        }}
                    >
                        {publication.name}
                    </ActionLinkButton>

                    <PublicationStatus publication={publication} />
                </div>
            ))}
        </div>
    );
}

function PublicationStatus({ publication }) {
    const { status } = publication;
    const { t } = useTranslation('publications');

    const statusLabels = {
        [Constants.publicationStatus.published]: t('publications.badges.published'),
        [Constants.publicationStatus.publishing_failed]: t('publications.badges.failed'),
        [Constants.publicationStatus.draft]: t('publications.badges.notPublished'),
        [Constants.publicationStatus.publishing]: `${t('publications.badges.publishing')}...`,
    };

    return (
        <div className="font-weight-bolder font-size-base">
            <Badge
                variant={cx({
                    success: status === Constants.publicationStatus.published,
                    secondary: status === Constants.publicationStatus.draft,
                    danger: status === Constants.publicationStatus.publishing_failed,
                    warning: status === Constants.publicationStatus.publishing,
                })}
            >
                {statusLabels[status]}
            </Badge>
        </div>
    );
}
