import { useDispatch, useSelector } from 'react-redux';
import { getBlockHeight, setBlockPosition } from '../../../../features/documents/documentsV2Slice';
import { useHeight } from '../../../../hooks/useHeight';
import { useEffect } from 'react';
import cx from 'classnames';

export function BlockHeightWrapper({
    blockId,
    editorDisplaySection,
    markAsDeleted = false,
    blockIsInLayout = false,
    children,
}) {
    const dispatch = useDispatch();
    const altHeight = useSelector(getBlockHeight(blockId, editorDisplaySection === 'left' ? 'right' : 'left'));
    const [divHeight, ref] = useHeight();

    useEffect(() => {
        dispatch(
            setBlockPosition({
                blockId: blockId,
                view: editorDisplaySection,
                rect: {
                    height: divHeight,
                },
            })
        );
    }, [divHeight]);

    return (
        <div
            className={cx('area-block flex-grow-1 position-relative', {
                'uk-border uk-border-danger': markAsDeleted,
            })}
            style={{
                minHeight: altHeight > divHeight ? altHeight : undefined,
                marginBottom: blockIsInLayout ? '1rem' : '2.5rem',
            }}
        >
            <div ref={ref}>{children}</div>
        </div>
    );
}
