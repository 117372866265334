import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { FieldArray, Form as FForm, Formik } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import { LoadingSpinner } from '../../../global/Spinner';
import { InputSelect } from '../../../publications_v2/helpers/FieldHelper';
import { XCircleFill } from 'react-bootstrap-icons';
import {
    useAddTranslationLanguageMutation,
    useDeleteTranslationLanguageMutation,
    useGetTranslationContainerQuery,
} from '../../../../features/translations/translationApi';
import HelperFunctions from '../../../global/HelperFunctions';
import { getLanguageName, getLanguageOptions } from '../../helpers/LanguageHelper';
import { useTranslation } from 'react-i18next';

export default function Language() {
    const { translationContainerId } = useParams();
    const { t } = useTranslation('translations');

    const { translationContainer } = useGetTranslationContainerQuery(translationContainerId, {
        selectFromResult: ({ data }) => ({
            translationContainer: data,
        }),
    });

    return (
        <>
            <div className="subheader">
                <h3>{t('translation.navbar.settings.contentSidebar.languages.title')}</h3>
            </div>

            {translationContainer !== undefined && <LanguageForm translationContainer={translationContainer} />}
        </>
    );
}

function LanguageForm({ translationContainer }) {
    const [addTranslationLanguage] = useAddTranslationLanguageMutation();
    const { t } = useTranslation('translations');

    const handleSubmit = (values, { setSubmitting }) => {
        Promise.all(
            values.translationLanguages
                .filter((_language) => _language.hasOwnProperty('id') === false)
                .map((_language) =>
                    addTranslationLanguage({
                        ..._language,
                        translationContainer: translationContainer['@id'],
                        migrationCompleted: true,
                    })
                )
        ).then(() => {
            setSubmitting(false);
        });
    };

    return (
        <Formik
            initialValues={{
                translationLanguages:
                    translationContainer.translationLanguages.map((_language) => {
                        return {
                            ..._language,
                            id: _language['@id'],
                        };
                    }) ?? [],
                newRow: '',
            }}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({ values, dirty, isSubmitting, isValid, setFieldValue }) => (
                <FForm autoComplete="off">
                    <div className="mb-4">
                        <FieldArray name="translationLanguages">
                            {({ remove, push }) => (
                                <>
                                    <div className="mb-4" style={{ maxWidth: 320 }}>
                                        <AddRow values={values} push={push} setFieldValue={setFieldValue} />
                                    </div>

                                    <Row className="align-items-center mb-3 text-secondary font-weight-bolder">
                                        <Col md={6} lg={4} style={{ minWidth: 340 }}>
                                            {t('translation.navbar.settings.contentSidebar.languages.name')}
                                        </Col>
                                    </Row>

                                    {values.translationLanguages.length > 0 &&
                                        values.translationLanguages.map((_translationLanguage, index) => (
                                            <TranslationLanguageRow
                                                translationLanguage={_translationLanguage}
                                                remove={() => remove(index)}
                                                key={index}
                                            />
                                        ))}
                                </>
                            )}
                        </FieldArray>
                    </div>

                    <Button type="submit" disabled={isSubmitting || !dirty || !isValid}>
                        {t('btn.save')}
                    </Button>
                    <LoadingSpinner isSubmitting={isSubmitting} />
                </FForm>
            )}
        </Formik>
    );
}

function AddRow({ values, push, setFieldValue }) {
    const { newRow } = values;
    const { t } = useTranslation('translations');

    const allLanguages = getLanguageOptions();
    const availableLanguages = allLanguages.filter(
        (_language) =>
            values.translationLanguages.some(
                (_translationLanguage) => _translationLanguage.languageIso === _language.value
            ) === false
    );

    useEffect(() => {
        if (newRow !== '') {
            push({
                languageIso: newRow,
            });
            setFieldValue('newRow', '');
        }
    }, [newRow]);

    return (
        <InputSelect
            name="newRow"
            options={availableLanguages}
            props={{
                placeholder: t('translation.navbar.settings.contentSidebar.languages.addLanguage'),
            }}
        />
    );
}

function TranslationLanguageRow({ translationLanguage, remove }) {
    const languageName = getLanguageName(translationLanguage.languageIso);
    const [deleteTranslationLanguage] = useDeleteTranslationLanguageMutation();
    const { t } = useTranslation('translations');

    const confirmRemove = () => {
        HelperFunctions.confirmModal(
            `${t('translation.navbar.settings.contentSidebar.languages.deleteModal', { name: languageName })}`,
            'danger',
            false,
            t('translation.navbar.settings.contentSidebar.languages.btn.confirmDelete'),
            t('btn.cancel')
        ).then(() => {
            deleteTranslationLanguage(translationLanguage['@id']).then(() => {
                remove();
            });
        });
    };

    return (
        <Row className="align-items-center mb-2">
            <Col md={6} lg={4} style={{ minWidth: 340 }}>
                <span className="font-weight-bold">{languageName}</span>
            </Col>
            <Col md={6} lg={4} className="d-flex" style={{ minWidth: 300 }}>
                <Button variant="icon" size="sm" className="btn-icon-only text-danger ml-2" onClick={confirmRemove}>
                    <XCircleFill size={16} />
                </Button>
            </Col>
        </Row>
    );
}
