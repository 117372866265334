import { useState } from 'react';
import { useGetTaskVersion } from 'pages/task_team/hooks/useGetTaskVersion';
import { useIsTaskOwner } from 'pages/task_team/hooks/permissions';
import { LightOutlineButton, LinkButton, WarningButton } from 'components/Buttons';
import { DeadlineWarningIcon, RenderProperty } from 'pages/task_team/modals/view_task_modal/TaskProperties';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import _ from 'lodash';
import { useUpdateTaskVersionMutation } from 'features/comments/commentApi';
import { Form as FForm, Formik } from 'formik';
import Spinner from 'pages/global/Spinner';
import * as Yup from 'yup';
import HelperFunctions from 'pages/global/HelperFunctions';
import { FormGroup } from 'components/SettingsSidebar/helpers/FormHelpers';
import { InputField } from 'pages/publications_v2/helpers/FieldHelper';
import { prepareTaskVersionFormData } from 'pages/task_team/helpers/FormHelper';
import { taskVersionStatus } from 'pages/task_team/config/constants';

export function TaskVersionProperties({ task, selectedVersion }) {
    const [editMode, setEditMode] = useState(false);
    const version = useGetTaskVersion(_.get(selectedVersion, '@id'));
    const isTaskOwner = useIsTaskOwner(task);

    if (!version) {
        return null;
    }

    if (editMode) {
        return <EditVersionProperties version={version} close={() => setEditMode(false)} />;
    }

    const canEdit = selectedVersion.status === taskVersionStatus.STATUS_DRAFT;

    return (
        <div className="card oke-card mb-4">
            <div className="card-header">Planning</div>

            <div className="card-body">
                <RenderVersionProperties task={task} version={version} />
            </div>

            {isTaskOwner && (
                <div className="card-footer d-flex flex-row-reverse">
                    <LightOutlineButton disabled={!canEdit} onClick={() => setEditMode(true)}>
                        Planning bewerken
                    </LightOutlineButton>
                </div>
            )}
        </div>
    );
}

function RenderVersionProperties({ task, version }) {
    const { i18n } = useTranslation();

    const deadline = _.isEmpty(version.deadline)
        ? '-'
        : DateTime.fromISO(version.deadline).setLocale(i18n.language).toLocaleString(DateTime.DATETIME_MED);
    const startDate = _.isEmpty(version.startDate)
        ? '-'
        : DateTime.fromISO(version.startDate).setLocale(i18n.language).toLocaleString(DateTime.DATETIME_MED);
    const publicationDate = _.isEmpty(version.publicationDate)
        ? '-'
        : DateTime.fromISO(version.publicationDate).setLocale(i18n.language).toLocaleString(DateTime.DATETIME_MED);

    return (
        <>
            <RenderProperty label="Deadline">
                <div className="flex-center">
                    {deadline}
                    <DeadlineWarningIcon task={task} version={version} className="ml-1" />
                </div>
            </RenderProperty>
            <RenderProperty label="Startdatum">{startDate}</RenderProperty>
            <RenderProperty label="Geplande publicatiedatum">{publicationDate}</RenderProperty>
        </>
    );
}

function EditVersionProperties({ version, close }) {
    const [updateTaskVersion] = useUpdateTaskVersionMutation();

    const minDate = DateTime.now().startOf('day').toISO().slice(0, 16);

    return (
        <Formik
            initialValues={{
                deadline: version.deadline ? HelperFunctions.formatTimestampForInput(version.deadline) : '',
                startDate: version.startDate ? HelperFunctions.formatTimestampForInput(version.startDate) : '',
                publicationDate: version.publicationDate
                    ? HelperFunctions.formatTimestampForInput(version.publicationDate)
                    : '',
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            {({ isValid, isSubmitting, dirty }) => {
                return (
                    <FForm>
                        <div className="card oke-card oke-card-active mb-4">
                            <div className="card-header">Planning</div>

                            <div className="card-body">
                                <div className="sidebar-form">
                                    <FormGroup label="Deadline OKÉ-check*" htmlFor="deadline" help="">
                                        <InputField
                                            name="deadline"
                                            props={{
                                                required: true,
                                                id: 'deadline',
                                                type: 'datetime-local',
                                                min: minDate,
                                            }}
                                        />
                                    </FormGroup>

                                    <FormGroup label="Startdatum*" htmlFor="startDate" help="">
                                        <InputField
                                            name="startDate"
                                            props={{
                                                required: true,
                                                id: 'startDate',
                                                type: 'datetime-local',
                                                min: minDate,
                                            }}
                                        />
                                    </FormGroup>

                                    <FormGroup label="Geplande publicatiedatum" htmlFor="publicationDate" help="">
                                        <InputField
                                            name="publicationDate"
                                            props={{
                                                id: 'publicationDate',
                                                type: 'datetime-local',
                                                min: minDate,
                                            }}
                                        />
                                    </FormGroup>
                                </div>
                            </div>

                            <div className="card-footer d-flex align-items-center justify-content-end">
                                {isSubmitting && <Spinner />}

                                <LinkButton disabled={isSubmitting} onClick={close}>
                                    Annuleer
                                </LinkButton>

                                <WarningButton
                                    disabled={isSubmitting || !isValid || !dirty}
                                    className="ml-2"
                                    type="submit"
                                >
                                    Opslaan
                                </WarningButton>
                            </div>
                        </div>
                    </FForm>
                );
            }}
        </Formik>
    );

    function handleSubmit(values) {
        updateTaskVersion({ uri: version['@id'], body: prepareTaskVersionFormData(values) }).then(() => {
            close();
        });
    }
}

const validationSchema = Yup.object().shape({
    startDate: Yup.string().required(),
    deadline: Yup.string().required(),
});
