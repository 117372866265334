import { Form as FForm, Formik } from 'formik';
import { LoadingSpinner } from 'pages/global/Spinner';
import { useGetDocument } from '../../hooks/useGetDocument';
import { WarningButton } from 'components/Buttons';
import { useUpdateDocumentMutation } from 'features/documents/documents';
import { useTranslation } from 'react-i18next';
import { FormFieldWithTitle } from 'pages/publications_v2/helpers/FieldHelper';
import * as Yup from 'yup';

export default function OneLiners() {
    const { t } = useTranslation('documents');
    const document = useGetDocument();

    if (!document || document.properties?.enableOneLiners !== true) {
        return null;
    }

    return (
        <>
            <div className="dr-container p-4">
                <div className="subheader">
                    <h3>One-liners</h3>
                </div>

                <div className="dr-instellingen-container">
                    <OneLinersForm document={document} />
                </div>
            </div>
        </>
    );
}

function OneLinersForm({ document }) {
    const { t } = useTranslation('documents');
    const [updateDocument] = useUpdateDocumentMutation();

    const properties = document.properties.hasOwnProperty('oneLiners') ? document.properties.oneLiners : {};

    return (
        <Formik
            initialValues={{
                exportFileName: '',
                colors: '',
                defaultMetadata: '',
                ...properties,
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize
        >
            {({ dirty, isSubmitting, isValid }) => (
                <FForm autoComplete="off">
                    <FormFieldWithTitle
                        name="exportFileName"
                        label="Bestandsnaam exporteren"
                        props={{
                            required: true,
                        }}
                        help="Moet eindigen op .csv. Gebruik bijv. [polissoort] om metadata waardes te gebruiken."
                    />

                    <FormFieldWithTitle
                        name="colors"
                        label="Kleuren editor"
                        help="Kleuren beschikbaar in de editor. Lijst gescheiden door een komma."
                    />

                    <FormFieldWithTitle
                        name="defaultMetadata"
                        label="Standaard metadata velden"
                        help="Standaard metadata voor nieuwe categorieën. Lijst gescheiden door een komma."
                    />

                    <WarningButton type="submit" disabled={isSubmitting || !dirty || !isValid}>
                        {t('btn.save')}
                    </WarningButton>
                    <LoadingSpinner isSubmitting={isSubmitting} />
                </FForm>
            )}
        </Formik>
    );

    function handleSubmit(values, { setSubmitting }) {
        updateDocument({
            id: document.id,
            uri: `/v2/documents/${document.id}`,
            body: {
                properties: {
                    ...document.properties,
                    oneLiners: values,
                },
            },
            method: 'PATCH',
        }).then(() => {
            setSubmitting(false);
        });
    }
}

const validationSchema = Yup.object().shape({
    exportFileName: Yup.string()
        .required()
        .matches(/\.csv$/),
});
