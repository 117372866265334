import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { useGetTasksQuery } from 'features/comments/commentApi';
import { NumberParam, useQueryParam } from 'use-query-params';
import HelperFunctions from 'pages/global/HelperFunctions';

export function useGetDeletedTasks() {
    const organisationId = useActiveOrganisation();
    const [page = 1] = useQueryParam('page', NumberParam);

    let params = {
        organisationId,
        page,
        'exists[deletedAt]': true,
    };

    return useGetTasksQuery(
        { myTasks: true, params },
        {
            selectFromResult: (result) => HelperFunctions.queryResult(result),
        },
    );
}
