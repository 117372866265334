import { ChangeEvent, useContext, useEffect, useMemo } from 'react';
import _, { debounce } from 'lodash';
import { Col, Dropdown, DropdownButton, Form, Row } from 'react-bootstrap';
import cx from 'classnames';
import { ProductContext } from './ReimbursementProducts';
import { Product, ProductLink, Reimbursement } from '../../../../models/reimbursement.models';
import {
    reimbursementApi,
    useUpdateBatchReimbursementAcceptChangesMutation,
    useUpdateReimbursementMutation,
} from '../../../../features/reimbursements/reimbursements';
import { idToUri } from '../../../global/UriHelper';
import Constants from '../../../../config/Constants';
import RestrictedReimbursementContent from '../../RestrictedReimbursementContent';
import ProductHelper from '../../../global/ProductHelper';
import { canAcceptAllChangesRoles } from '../../config/permissions';
import { useTranslation } from 'react-i18next';

export function ProductSearchActions({
    search,
    setSearch,
    categoryId,
    reimbursementId,
}: {
    search: string;
    setSearch: (value: string) => void;
    categoryId: number;
    reimbursementId: number;
}) {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };
    const { t } = useTranslation('reimbursements');

    const debouncedResults = useMemo(() => {
        return debounce(handleChange, 300);
    }, []);

    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    });

    return (
        <div className="mb-2">
            <Row>
                <Col xs={5}>
                    <Form.Control
                        type="search"
                        className={cx('nav-form-search', {
                            'has-content': search !== '',
                        })}
                        placeholder={`${t('reimbursement.searchTitle')}...`}
                        onChange={debouncedResults}
                    />
                </Col>
                <Col xs={7}>
                    <RestrictedReimbursementContent
                        roles={[
                            Constants.reimbursementTeamRoles.finalEditor,
                            Constants.reimbursementTeamRoles.manager,
                            Constants.reimbursementTeamRoles.admin,
                        ]}
                    >
                        <div className="d-flex flex-row-reverse align-items-center">
                            <BulkActionsButton categoryId={categoryId} reimbursementId={reimbursementId} />
                        </div>
                    </RestrictedReimbursementContent>
                </Col>
            </Row>
        </div>
    );
}

function BulkActionsButton({ categoryId, reimbursementId }: { categoryId: number; reimbursementId: number }) {
    const [updateReimbursement] = useUpdateReimbursementMutation();
    const { selectedProducts, clearSelection } = useContext(ProductContext);
    const productCount = selectedProducts.length;
    const { t } = useTranslation('reimbursements');

    const [getReimbursements] = reimbursementApi.endpoints.getReimbursements.useLazyQuery();
    const [getProducts] = reimbursementApi.endpoints.getProducts.useLazyQuery();
    const [updateBatchReimbursementAcceptChanges] = useUpdateBatchReimbursementAcceptChangesMutation();

    const handleAction = (action: string | null) => {
        switch (action) {
            case 'accept_changes':
                Promise.all(
                    selectedProducts.map((uri) => {
                        return getProducts(categoryId, true).then(
                            async ({ data }: { data?: Product[] | undefined }) => {
                                const product = data?.find((product: Product) => product['@id'] === uri) ?? undefined;

                                if (product) {
                                    let selectedProductUris = [];
                                    const isLinkedProduct = ProductHelper.isLinkedProduct(product);
                                    const extendedProductUris = isLinkedProduct
                                        ? product.extendedProducts.map(
                                              (productLink: ProductLink) => productLink.baseProduct
                                          )
                                        : [product['@id']];

                                    if (extendedProductUris.length === 1) {
                                        selectedProductUris = extendedProductUris;
                                    } else {
                                        selectedProductUris =
                                            data
                                                ?.filter((product: Product) =>
                                                    extendedProductUris.includes(product['@id'])
                                                )
                                                .map((product: Product) => product['@id']) ?? [];
                                    }

                                    await updateBatchReimbursementAcceptChanges({
                                        uri: idToUri(reimbursementId, 'Reimbursement'),
                                        product: product['@id'],
                                        selectedProducts: selectedProductUris,
                                    });
                                }
                            }
                        );
                    })
                ).then(() => {
                    if (clearSelection) {
                        clearSelection();
                    }
                });

                break;

            case 'deactivate':
                getReimbursements(idToUri(categoryId, 'Category'), true).then(
                    ({ data }: { data?: Reimbursement[] | undefined }) => {
                        const reimbursement = data
                            ? data.find((reimbursement: Reimbursement) => reimbursement.id === reimbursementId)
                            : undefined;
                        if (reimbursement) {
                            updateReimbursement({
                                uri: reimbursement['@id'],
                                category: reimbursement.category,
                                disabledProducts: _.xor(reimbursement.disabledProducts, selectedProducts),
                            }).then(() => {
                                if (clearSelection) {
                                    clearSelection();
                                }
                            });
                        }
                    }
                );

                break;
        }
    };

    return (
        <>
            <DropdownButton
                disabled={productCount === 0}
                id="dropdown-bulk"
                title={t('reimbursement.product.actions.title')}
                onSelect={handleAction}
            >
                <RestrictedReimbursementContent categoryId={categoryId} roles={canAcceptAllChangesRoles}>
                    <Dropdown.Item eventKey="accept_changes">
                        {t('reimbursement.product.actions.approveChanges')}
                    </Dropdown.Item>
                </RestrictedReimbursementContent>
                <Dropdown.Item eventKey="deactivate">{t('reimbursement.product.actions.deactivate')}</Dropdown.Item>
            </DropdownButton>

            {productCount > 0 && (
                <div className="mr-2 font-weight-bold small">
                    {productCount}{' '}
                    {productCount > 1
                        ? t('reimbursement.product.actions.policies')
                        : t('reimbursement.product.actions.policy')}{' '}
                    {t('reimbursement.product.actions.selected')}
                </div>
            )}
        </>
    );
}
