import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Button, Card, Col, Container, Dropdown, Form, Row } from 'react-bootstrap';
import {
    fetchOpEntryActions,
    fetchOpEntryId,
    fetchOpEntryLabelId,
    fetchOpListDepartments,
    fetchOpListUsers,
    patchOpEntry,
    selectOpEntryById,
    selectOpEntryLabelById,
    selectOpListById,
} from 'features/operationsList/operationsListSlice';
import { generatePath, useParams } from 'react-router-dom';
import MainContentNav from '../../Navbar';
import { OpTeamEntryStatusDropdown, OpTeamResponsible } from '../op_team_entry/OpTeamEntryStatus';
import OpTeamTasks from '../op_team_entry/OpTeamTasks';
import SubNav from '../nav/SubNav';
import { OpEntryExpanded } from './OpEntry';
import { LastOpEntryEditBy, OpEntryCreatedHistory } from './OpEntryHistory';
import Constants from '../../../config/Constants';
import { resolveTeamEntryLabelName } from '../op_team_entry/OpTeamEntryLabelName';
import _ from 'lodash';
import LoadingSpinner from '../../global/LoadingSpinner';
import { CaretDownFill } from 'react-bootstrap-icons';
import { Field, Form as FForm, Formik } from 'formik';
import Select from 'react-select';
import RestrictedOpListContent from '../RestrictedOpListContent';
import * as Yup from 'yup';
import CommentButton from '../../comments/CommentButton';
import { BASE_PATH, VIEW_PATH } from 'scenes/OperationsList';
import { useGetLabelsQuery } from 'features/metadata/metadata';
import { WarningButton } from 'components/Buttons';
import { useTranslation } from 'react-i18next';
import { AreaMetadata } from 'pages/operations_list/view/AreaMetadata';

function OpTeamEntryDetails() {
    const { id, opEntryLabelId, selectedPage } = useParams();
    const dispatch = useDispatch();
    const opList = useSelector((state) => selectOpListById(state.operations, id));
    const opEntryLabel = useSelector((state) =>
        selectOpEntryLabelById(state.operations, `/api/operations-api/api/op_entry_labels/${opEntryLabelId}`)
    );
    const { t } = useTranslation('changelist');

    const opEntryUri = opEntryLabel?.opEntry;
    const opEntryId = _.last(_.split(opEntryUri, '/'));

    const opEntry = useSelector((state) => selectOpEntryById(state.operations, opEntryUri));
    const { activeOrganisation } = useSelector((state) => state.security);

    const { labels = [] } = useGetLabelsQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                labels: data ?? [],
            }),
        }
    );

    const labelLut = [];
    _.forEach(labels, (label) => {
        labelLut[label['@id']] = label;
    });

    useEffect(() => {
        dispatch(fetchOpListDepartments({ opListId: id }));
        dispatch(fetchOpListUsers({ opListId: id }));
    }, [dispatch, id]);

    useEffect(() => {
        if (opEntryId) {
            dispatch(fetchOpEntryId({ id: opEntryId }));
            dispatch(fetchOpEntryActions({ id: opEntryId }));
        }
    }, [dispatch, opEntryId]);

    useEffect(() => {
        dispatch(fetchOpEntryLabelId({ id: opEntryLabelId, includeEntry: true }));
    }, [dispatch, opEntryLabelId]);

    if (!opList || !opEntry || !opEntryLabel) {
        return <LoadingSpinner />;
    }

    // The teamEntry does have a label which means that the opEntry was split by labels
    const entryIsSplitByLabel = !!opEntryLabel.label;

    const labelName = resolveTeamEntryLabelName(labelLut, opEntryLabel);

    return (
        <>
            <MainContentNav
                pages={[
                    { title: t('breadcrumb'), url: BASE_PATH },
                    {
                        title: opList.name,
                        url: generatePath(VIEW_PATH, {
                            id: opList.id,
                            selectedPage,
                        }),
                    },
                    { title: opEntry.areaTitle },
                ]}
            />
            <SubNav />

            <div className="content-static-body has-subnav">
                <div className="d-flex align-items-stretch h-100">
                    <div className="flex-grow-1 overflow-auto">
                        <div id="content" className="px-5 pt-4 pb-3">
                            <Container fluid>
                                <Row>
                                    <Col md={8}>
                                        <OpEntryExpanded opEntry={opEntry} opList={opList} />
                                    </Col>
                                    <Col md={4}>
                                        <Card.Body>
                                            <div className="d-flex align-items-center mb-2" style={{ height: 32 }}>
                                                <div className="font-weight-bolder">
                                                    {t('changelist.view.OpTeamEntryDetails.lastEdited')}
                                                </div>
                                                <div className="ml-auto">
                                                    <LastOpEntryEditBy opEntryLabel={opEntryLabel} />
                                                </div>
                                            </div>
                                            <div
                                                className="d-flex align-items-center font-weight-bolder mb-2"
                                                style={{ height: 32 }}
                                            >
                                                <div>{t('changelist.view.OpTeamEntryDetails.handlers')}</div>
                                                <div className="ml-auto">
                                                    <OpTeamResponsible
                                                        opList={opList}
                                                        opEntryLabel={opEntryLabel}
                                                        showDropdown
                                                    />
                                                </div>
                                            </div>

                                            <OpEntryNumber opEntryLabel={opEntryLabel} labelName={labelName} />

                                            <OpEntryTypesOfEdits opEntry={opEntry} />

                                            {entryIsSplitByLabel && (
                                                <div
                                                    className="d-flex align-items-center font-weight-bolder mb-2"
                                                    style={{ height: 32 }}
                                                >
                                                    <div>{t('changelist.view.OpTeamEntryDetails.insuranceType')}</div>
                                                    <div className="ml-auto">{labelName}</div>
                                                </div>
                                            )}
                                            <div
                                                className="d-flex align-items-center font-weight-bolder mb-3"
                                                style={{ height: 32 }}
                                            >
                                                <div>{t('changelist.view.OpTeamEntryDetails.status')}</div>
                                                <div className="ml-auto">
                                                    <OpTeamEntryStatusDropdown
                                                        opEntryLabel={opEntryLabel}
                                                        opList={opList}
                                                    />
                                                </div>
                                            </div>

                                            <OpEntryCreatedHistory opEntry={opEntry} />

                                            <div className="mb-4 pt-4">
                                                <OpTeamTasks opEntryLabel={opEntryLabel} opList={opList} />
                                            </div>

                                            <AreaMetadata areaId={opEntry.areaId} />
                                        </Card.Body>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function OpEntryNumber({ opEntryLabel, labelName }) {
    const { entryNumber } = opEntryLabel;
    const { t } = useTranslation('changelist');

    return (
        <div className="d-flex align-items-center font-weight-bolder mb-2" style={{ height: 32 }}>
            <div>{t('changelist.view.OpTeamEntryDetails.serialNumber')}</div>
            <div className="d-flex align-items-center ml-auto">
                <OpEntryLabelComments opEntryLabel={opEntryLabel} labelName={labelName} />
                <div>#{entryNumber}</div>
            </div>
        </div>
    );
}

function OpEntryLabelComments({ opEntryLabel, labelName }) {
    const { entryNumber } = opEntryLabel;
    const commentUri = labelName ? `${opEntryLabel['@id']}/${encodeURI(labelName)}` : opEntryLabel['@id'];

    return (
        <div className="mr-2" style={{ marginTop: -2 }}>
            <CommentButton
                uri={commentUri}
                parentUri={opEntryLabel.opEntry}
                entity={{
                    ...opEntryLabel,
                    title: labelName ? `#${entryNumber}-${labelName}` : `#${entryNumber}`,
                }}
                entityType="OpEntryLabel"
            />
        </div>
    );
}

export function OpEntryTypesOfEdits({ opEntry }) {
    const params = useParams();
    const { typesOfEdit = [] } = opEntry;
    const { t } = useTranslation('changelist');

    return (
        <div className="d-flex align-items-start font-weight-bolder mb-2 justify-content-between">
            <div className="flex-shrink-0">{t('changelist.view.OpTeamEntryDetails.typeOfChange')}</div>
            <div className="d-flex flex-wrap justify-content-end">
                {typesOfEdit.map((typeOfEdit, key) => (
                    <Badge
                        variant="light"
                        style={{ fontSize: '0.7rem' }}
                        className="ml-1 mb-1"
                        key={`opEntry-${opEntry.id}-typeOfEdit-${key}`}
                    >
                        {t('changelist.view.opEntry.typeOfEdits.' + typeOfEdit)}
                    </Badge>
                ))}
            </div>

            <RestrictedOpListContent
                opListId={params.id}
                roles={[Constants.opListUserRoles.admin, Constants.opListUserRoles.editor]}
            >
                <OpEntryTypesOfEditsDropdown opEntry={opEntry} />
            </RestrictedOpListContent>
        </div>
    );
}

export function OpEntryTypesOfEditsDropdown({ opEntry }) {
    const dispatch = useDispatch();
    const [showDropdown, setShowDropdown] = useState(false);
    const { typesOfEdit = [] } = opEntry;
    const { t } = useTranslation('changelist');

    const typeOfEditOptions = Object.keys(Constants.opEntry.typeOfEdits).map((_type) => {
        return {
            value: _type,
            label: t('changelist.view.opEntry.typeOfEdits.' + _type),
        };
    });

    const handleSubmit = (values) => {
        dispatch(patchOpEntry({ uri: opEntry['@id'], formData: values })).then(() => {
            setShowDropdown(false);
        });
    };

    return (
        <div
            className="flex-shrink-0 ml-1"
            onClick={(event) => {
                event.stopPropagation();
            }}
        >
            <Dropdown
                onToggle={(isOpen) => {
                    setShowDropdown(isOpen);
                }}
                show={showDropdown}
            >
                <Dropdown.Toggle
                    bsPrefix="team-entry-types-of-edits-dropdown"
                    as="span"
                    id={'entry-' + opEntry.id + '-dropdown'}
                >
                    <CaretDownFill />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <div className="font-weight-normal px-4 py-3" style={{ minWidth: 400 }}>
                        {showDropdown && (
                            <Formik
                                initialValues={{
                                    typesOfEdit,
                                }}
                                onSubmit={handleSubmit}
                                validationSchema={EditOpEntrySchema}
                            >
                                {({ setFieldValue, isSubmitting, values, isValid, dirty }) => (
                                    <FForm autoComplete="off">
                                        <Form.Group>
                                            <Form.Label htmlFor="typesOfEdit">
                                                {t('changelist.view.OpTeamEntryDetails.typesOfChange')}
                                            </Form.Label>
                                            <Field
                                                as={Select}
                                                isMulti
                                                id="typesOfEdit"
                                                name="typesOfEdit"
                                                placeholder="Selecteer..."
                                                options={typeOfEditOptions}
                                                value={typeOfEditOptions.filter((_option) =>
                                                    values.typesOfEdit.includes(_option.value)
                                                )}
                                                onChange={(selectedOptions) => {
                                                    setFieldValue(
                                                        'typesOfEdit',
                                                        selectedOptions?.map(
                                                            (selectedOption) => selectedOption.value
                                                        ) ?? []
                                                    );
                                                }}
                                            />
                                        </Form.Group>

                                        <WarningButton disabled={isSubmitting || !isValid || !dirty} type="submit">
                                            {t('btn.save')}
                                        </WarningButton>
                                        <Button
                                            variant="link"
                                            disabled={isSubmitting}
                                            onClick={() => setShowDropdown(false)}
                                        >
                                            {t('btn.cancel')}
                                        </Button>
                                    </FForm>
                                )}
                            </Formik>
                        )}
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}

export default OpTeamEntryDetails;

const EditOpEntrySchema = Yup.object().shape({
    typesOfEdit: Yup.array().min(1),
});
