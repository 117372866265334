import { useSelector } from 'react-redux';
import { selectUserDepartments } from '../../../features/operationsList/opListUserSlice';
import CommentButton from '../../comments/CommentButton';

export function OpListCommentButton({ opList, entity, entityType, uri, parentUri, until }) {
    const departments = useSelector(selectUserDepartments(opList));
    const filter = departments.length === 1 ? departments[0].commentLabels : [];

    return (
        <div className="pt-1">
            <CommentButton
                uri={uri}
                parentUri={parentUri}
                entity={entity}
                entityType={entityType}
                filterByLabels={filter}
                until={until}
            />
        </div>
    );
}
