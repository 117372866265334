import React, { Fragment, useMemo } from 'react';
import Constants from '../../../../config/Constants';
import HelperFunctions from '../../../global/HelperFunctions';
import { ChevronRight } from 'react-bootstrap-icons';
import { DateTime } from 'luxon';
import { Col, Container, Row } from 'react-bootstrap';
import { useGetDocumentVariants } from '../../../documents_v2/hooks/useGetDocumentVariants';
import { useGetAreaQuery } from '../../../../features/documents/documents';
import { useTranslation } from 'react-i18next';
import { useGetDocument } from '../../../documents_v2/hooks/useGetDocument';
import { useGetOrganisationUsers } from '../../../../hooks/useGetOrganisationUsers';
import { useGetUserFullName } from '../../../../hooks/useGetUserFullName';

export default function ExpandedAreaRow({ area }) {
    const document = useGetDocument();
    const { t } = useTranslation('documents');
    const creatorName = useGetUserFullName(area.createdBy);

    const showAreaUsers =
        document.status !== Constants.documentStatus.draft &&
        area.scheduledForDeletion === false &&
        area.linkedTo === undefined;

    return (
        <div>
            <div className="text-secondary p-3 small">
                {t('document.navbar.main.expandedAreaRow.createdBy', {
                    user: creatorName,
                    day: HelperFunctions.getUniversalDate(area.createdAt),
                    at: HelperFunctions.getTime(area.createdAt),
                })}
            </div>

            <Container fluid className="mb-3">
                <Row>
                    <Col>
                        <AreaVariants area={area} document={document} />
                        <AreasLinkedToBlock areaId={area.id} documentType={document.type} />
                    </Col>
                </Row>
            </Container>

            {showAreaUsers && <AreaUsers areaId={area.id} documentUsers={document.documentUsers} />}
        </div>
    );
}

function AreaVariants({ area, document }) {
    const documentVariants = area.documentVariants ?? [];
    const allDocumentVariants = useGetDocumentVariants();
    const { t } = useTranslation('documents');

    if (document.type === Constants.documentType.library) {
        return null;
    }

    return (
        <div className="p-3 border border-secondary">
            <div className="mb-2 text-uppercase font-weight-bold">
                {t('document.navbar.main.expandedAreaRow.variants')}
            </div>
            {documentVariants.length > 1 ? (
                <>
                    {documentVariants.map((variantId) => {
                        const variant = HelperFunctions.getByValue(allDocumentVariants, 'id', variantId);

                        if (variant && variant.parent !== null && variant.parentId !== null) {
                            return (
                                <div className="mb-1" key={`variant-${variant.id}`}>
                                    {variant.prefix && <span className="text-muted mr-1">{variant.prefix}</span>}
                                    {variant.name}
                                </div>
                            );
                        } else {
                            return null;
                        }
                    })}
                </>
            ) : (
                <div className="text-warning">{t('document.navbar.main.expandedAreaRow.noVariants')}</div>
            )}
        </div>
    );
}

function AreasLinkedToBlock({ areaId, documentType }) {
    const { t } = useTranslation('documents');

    const { area } = useGetAreaQuery(areaId, {
        selectFromResult: ({ data }) => ({
            area: data,
        }),
    });

    if (area === undefined) {
        return null;
    }

    const { linkedAreas = [] } = area;

    if (documentType !== Constants.documentType.library && (linkedAreas === undefined || linkedAreas.length === 0)) {
        // Hide this section for 'normal' documents if empty
        return null;
    }

    const uniqueSectionIds = [];
    const uniqueLinkedAreas = [];

    linkedAreas.forEach((_linkedArea) => {
        const { section } = _linkedArea;

        if (!uniqueSectionIds.includes(section.id)) {
            uniqueSectionIds.push(section.id);
            uniqueLinkedAreas.push(_linkedArea);
        }
    });

    return (
        <div className="p-3 border border-secondary mt-3">
            <div className="mb-2 text-uppercase font-weight-bold">
                {t('document.navbar.main.expandedAreaRow.linkedChapters')}
            </div>

            {uniqueLinkedAreas.map((_linkedArea, index) => {
                return (
                    <div className="mb-2" key={`linked-area-${areaId}-${index}`}>
                        {_linkedArea.document.name} <ChevronRight className="text-muted" /> {_linkedArea.section.title}
                    </div>
                );
            })}

            {uniqueLinkedAreas.length === 0 && <div>{t('document.navbar.main.expandedAreaRow.notLinked')}</div>}
        </div>
    );
}

function AreaUsers({ areaId, documentUsers }) {
    const { t, i18n } = useTranslation('documents');
    const { areaExtended } = useGetAreaQuery(areaId, {
        selectFromResult: ({ data }) => ({
            areaExtended: data,
        }),
    });

    const organisationUsers = useGetOrganisationUsers();
    const allAreaUsers = areaExtended?.users ?? [];

    const areaUsers = useMemo(() => {
        const documentUserIds = documentUsers ? documentUsers.map((_documentUser) => _documentUser.userId) : [];

        return organisationUsers
            .filter((_user) => {
                return documentUserIds.includes(_user.id) && _user.enabled;
            })
            .map((_user) => {
                const areaUser = allAreaUsers.find((_areaUser) => _areaUser.userId === _user.id);

                return {
                    ..._user,
                    ...areaUser,
                };
            });
    }, [allAreaUsers, documentUsers, organisationUsers]);

    const loadUsers = () => {
        const userGroups = {
            opened: [],
            skipped: [],
            waiting: [],
        };

        if (areaUsers && areaUsers.length > 0) {
            areaUsers.forEach((_areaUser) => {
                let group;

                if (_areaUser.skipped) {
                    group = Constants.areaUserGroup.skipped;
                } else if (_areaUser.status === Constants.areaUserStatus.inProgress) {
                    group = Constants.areaUserGroup.opened;
                } else {
                    group = Constants.areaUserGroup.waiting;
                }

                userGroups[group].push(_areaUser);
            });
        }

        return userGroups;
    };

    const userGroups = useMemo(() => {
        return loadUsers();
    }, [areaUsers]);

    return (
        <Container fluid className="mb-3">
            <Row>
                <Col>
                    <div className="p-3 border border-secondary">
                        <div className="mb-2 text-uppercase font-weight-bold">
                            {t('document.navbar.main.expandedAreaRow.usersOpened')}
                        </div>
                        {userGroups !== undefined && userGroups.opened.length > 0 ? (
                            userGroups.opened.map((user) => {
                                const lastSeen = user.lastSeenTimestamp
                                    ? DateTime.fromSeconds(user.lastSeenTimestamp).setLocale(i18n.language).toRelative()
                                    : '-';
                                return (
                                    <div
                                        className="d-flex align-items-center mb-1"
                                        key={`area-${areaId}-opened-${user.id}`}
                                    >
                                        <div className="mr-3 uk-label uk-background-active text-white text-uppercase">
                                            {user.firstName} {user.lastName}
                                        </div>
                                        <div style={{ paddingTop: 1 }}>{lastSeen}</div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="text-muted">
                                {t('document.navbar.main.expandedAreaRow.noResultsUsersGroup')}
                            </div>
                        )}
                    </div>
                </Col>

                <Col>
                    <div className="p-3 border border-secondary">
                        <div className="mb-2 text-uppercase font-weight-bold">
                            {t('document.navbar.main.expandedAreaRow.usersWaiting')}
                        </div>
                        {userGroups !== undefined && userGroups.waiting.length > 0 ? (
                            <>
                                {userGroups.waiting.map((user) => {
                                    return (
                                        <div className="mb-1" key={`area-${areaId}-waiting-${user.id}`}>
                                            <div className="uk-label uk-background-light text-white text-uppercase">
                                                {user.firstName} {user.lastName}
                                            </div>
                                        </div>
                                    );
                                })}
                            </>
                        ) : (
                            <div className="text-muted">
                                {t('document.navbar.main.expandedAreaRow.noResultsUsersGroup')}
                            </div>
                        )}
                    </div>
                </Col>

                <Col>
                    <div className="p-3 border border-secondary">
                        <div className="mb-2 text-uppercase font-weight-bold">
                            {t('document.navbar.main.expandedAreaRow.usersSkipped')}
                        </div>
                        {userGroups !== undefined && userGroups.skipped.length > 0 ? (
                            <>
                                {userGroups.skipped.map((user) => {
                                    return (
                                        <div className="mb-1" key={`area-${areaId}-skipped-${user.id}`}>
                                            <div className="uk-label uk-background-light text-white text-uppercase">
                                                {user.firstName} {user.lastName}
                                            </div>
                                        </div>
                                    );
                                })}
                            </>
                        ) : (
                            <div className="text-muted">
                                {t('document.navbar.main.expandedAreaRow.noResultsUsersGroup')}
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
