import React, { memo } from 'react';
import { BlockLabels } from './BlockLabels';
import BlockActions from './BlockActions';
import isEqual from 'react-fast-compare';

export const BlockToolbar = memo(({ block, parentBlock, index, readOnly = false, isInsideBlockLayout = false }) => {
    return (
        <div className="hide-when-dragging editor-toolbar">
            <BlockLabels block={block} isInsideBlockLayout={isInsideBlockLayout} parentBlock={parentBlock} />

            {!readOnly && (
                <BlockActions
                    block={block}
                    parentBlock={parentBlock}
                    index={index}
                    isInsideBlockLayout={isInsideBlockLayout}
                />
            )}
        </div>
    );
}, isEqual);
