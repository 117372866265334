import React, { useEffect, useState } from 'react';
import { Button, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getFiles, getFolders } from '../../../../features/mediaLibrary/mediaLibrarySlice';
import Folders from './Folders';
import Files from './Files';
import Dropzone from '../Dropzone';
import RestrictedContent from '../../../global/RestrictedContent';
import { Permissions } from '../../../../config/Constants';
import { useTranslation } from 'react-i18next';

export default function MediaSelectorModal({ onHide, onSubmit }) {
    const [selectedMedia, setSelectedMedia] = useState(null);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const { t } = useTranslation('documents');

    const handleSubmit = () => {
        onSubmit(selectedMedia);
    };

    return (
        <Modal id="media-selector-modal" show onHide={onHide} size="lg" scrollable>
            <Modal.Header closeButton>
                <Modal.Title>
                    {t('document.navbar.main.editor.left.blocks.types.fileBlock.fileModal.media')}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <ModalBody
                    selectedMedia={selectedMedia}
                    setSelectedMedia={setSelectedMedia}
                    selectedFolder={selectedFolder}
                    setSelectedFolder={setSelectedFolder}
                />
            </Modal.Body>

            <Modal.Footer>
                <Button variant="link" onClick={onHide}>
                    {t('btn.close')}
                </Button>
                <Button variant="primary" disabled={selectedMedia === null} onClick={handleSubmit}>
                    {t('document.navbar.main.editor.left.blocks.types.fileBlock.fileModal.btn.select')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function ModalBody({ selectedMedia, setSelectedMedia, selectedFolder, setSelectedFolder }) {
    const activeOrganisation = useSelector((state) => state.security.activeOrganisation);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getFolders({ organisationId: activeOrganisation }));
        dispatch(getFiles({ organisationId: activeOrganisation }));
    }, [activeOrganisation]);

    return (
        <Row className="align-items-stretch h-100">
            <div className="col-3">
                <div className="position-fixed" style={{ width: 184, minWidth: 184 }}>
                    <Folders selectedFolder={selectedFolder} setSelectedFolder={setSelectedFolder} />
                </div>
            </div>
            <div className="col-9">
                <RestrictedContent permission={Permissions.Media['Write.All']}>
                    <div className="mb-3">
                        <Dropzone
                            onDropComplete={(files = []) => {
                                const lastFile = files.pop();

                                if (lastFile) {
                                    const { payload } = lastFile;
                                    const files = Object.values(payload.files);

                                    if (_.isArray(files) && files.length === 1) {
                                        setSelectedMedia(files[0]);
                                    }
                                }
                            }}
                        />
                    </div>
                </RestrictedContent>

                <Files
                    selectedMedia={selectedMedia}
                    setSelectedMedia={setSelectedMedia}
                    selectedFolder={selectedFolder}
                />
            </div>
        </Row>
    );
}
