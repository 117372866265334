import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import getEnv from '../../config/Env';
import { baseQueryWithReauth, prepareHeaders, providesList } from '../api';
import queryString from 'query-string';

const baseUrl = getEnv('PUBLICATION_API_URL');
const uriPrefix = '/api';

const publicationApiQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders,
});

export const publicationApi = createApi({
    reducerPath: 'publicationApi',
    baseQuery: (args, api, extraOptions) => baseQueryWithReauth(args, api, extraOptions, publicationApiQuery),
    tagTypes: ['Publication', 'PublicationUser', 'UserGroup', 'PublicationGroup', 'PublicationVersion'],
    endpoints: (builder) => ({
        addPropositionSummaryToken: builder.mutation({
            query: (body) => {
                return {
                    url: `${uriPrefix}/proposition_summary_tokens`,
                    method: 'POST',
                    body,
                };
            },
        }),
        addPublicationUserPassword: builder.mutation({
            query: (body) => {
                return {
                    url: `${uriPrefix}/publication_user_passwords`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: [{ type: 'PublicationUserPassword', id: 'LIST' }],
        }),
        deletePublicationUserPassword: builder.mutation({
            query: (uri) => {
                return {
                    url: uri,
                    method: 'DELETE',
                };
            },
            invalidatesTags: [{ type: 'PublicationUserPassword', id: 'LIST' }],
        }),
        putForBatch: builder.mutation({
            query: ({ uri, body }) => {
                return {
                    url: uri,
                    method: 'PUT',
                    body,
                };
            },
        }),
        postForBatch: builder.mutation({
            query: ({ uri, body }) => {
                return {
                    url: uri,
                    method: 'POST',
                    body,
                };
            },
        }),
        deleteForBatch: builder.mutation({
            query: (uri) => {
                return {
                    url: uri,
                    method: 'DELETE',
                };
            },
        }),
        getPublicationUserPasswords: builder.query({
            query: (id) => `${uriPrefix}/publications/${id}/publication_user_passwords`,
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result) => providesList(result, 'PublicationUserPassword'),
        }),
        addPublicationUser: builder.mutation({
            query: (body) => {
                return {
                    url: `${uriPrefix}/publication_users`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: [
                { type: 'PublicationUser', id: 'LIST' },
                { type: 'UserGroup', id: 'LIST' },
            ],
        }),
        addUserGroup: builder.mutation({
            query: (body) => {
                return {
                    url: `${uriPrefix}/user_groups`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: [{ type: 'UserGroup', id: 'LIST' }],
        }),
        deletePublicationUser: builder.mutation({
            query: (uri) => {
                return {
                    url: uri,
                    method: 'DELETE',
                };
            },
            invalidatesTags: [
                { type: 'PublicationUser', id: 'LIST' },
                { type: 'UserGroup', id: 'LIST' },
            ],
        }),
        deleteUserGroup: builder.mutation({
            query: (uri) => {
                return {
                    url: uri,
                    method: 'DELETE',
                };
            },
            invalidatesTags: [
                { type: 'PublicationUser', id: 'LIST' },
                { type: 'UserGroup', id: 'LIST' },
            ],
        }),
        editPublicationUser: builder.mutation({
            query: ({ uri, body }) => {
                return {
                    url: uri,
                    method: 'PUT',
                    body,
                };
            },
            invalidatesTags: [
                { type: 'PublicationUser', id: 'LIST' },
                { type: 'UserGroup', id: 'LIST' },
            ],
        }),
        editPublicationUserPassword: builder.mutation({
            query: ({ uri, body }) => {
                return {
                    url: uri,
                    method: 'PUT',
                    body,
                };
            },
            invalidatesTags: (result, error, arg) => [{ type: 'PublicationUserPassword', id: arg.uri }],
        }),
        editUserGroup: builder.mutation({
            query: ({ uri, body }) => {
                return {
                    url: uri,
                    method: 'PUT',
                    body,
                };
            },
            invalidatesTags: [
                { type: 'PublicationUser', id: 'LIST' },
                { type: 'UserGroup', id: 'LIST' },
            ],
        }),
        getUserGroups: builder.query({
            query: () => `${uriPrefix}/user_groups`,
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result) => providesList(result, 'UserGroup'),
        }),
        getPublications: builder.query({
            query: () => `${uriPrefix}/publications`,
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result) => providesList(result, 'Publication'),
        }),
        getDocumentVariantPublications: builder.query({
            query: (variantIds = []) =>
                `${uriPrefix}/publications/document-variants?` +
                queryString.stringify(
                    {
                        variant: variantIds,
                    },
                    { arrayFormat: 'bracket' },
                ),
            transformResponse: (response) => response['hydra:member'],
        }),
        getPublicationGroups: builder.query({
            query: () => `${uriPrefix}/publication_groups`,
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result) => providesList(result, 'PublicationGroup'),
        }),
        getPublicationUsers: builder.query({
            query: () => `${uriPrefix}/publication_users`,
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result) => providesList(result, 'PublicationUser'),
        }),
        getUserPublicationPasswords: builder.query({
            query: (id) => `${uriPrefix}/publication_users/${id}/publication_user_passwords`,
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result) => providesList(result, 'PublicationUserPassword'),
        }),
        getUserPasswordActions: builder.query({
            query: (uri) => `${uri}/user_password_actions`,
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result, error, arg) => [{ type: 'PublicationUserPassword', id: arg }],
        }),
        refreshKioskData: builder.mutation({
            query: (body) => {
                return {
                    url: `${uriPrefix}/publications/refresh-kiosk-data`,
                    method: 'POST',
                    body,
                };
            },
        }),
        updateToken: builder.mutation({
            query: (body) => {
                return {
                    url: `${uriPrefix}/publication_tokens/update`,
                    method: 'POST',
                    body,
                };
            },
        }),
        getPublicationVersions: builder.query({
            query: (id) => `${uriPrefix}/publications/${id}/publication_versions`,
            transformResponse: (response) => response['hydra:member'],
            providesTags: (result, error, arg) =>
                [
                    {
                        type: 'Publication',
                        id: `/api/publications/${arg}`,
                    },
                ].concat(providesList(result, 'PublicationVersion')),
        }),
    }),
});

export const {
    useAddPropositionSummaryTokenMutation,
    useAddPublicationUserPasswordMutation,
    useAddPublicationUserMutation,
    useAddUserGroupMutation,
    useDeletePublicationUserMutation,
    useDeletePublicationUserPasswordMutation,
    useDeleteUserGroupMutation,
    useEditPublicationUserMutation,
    useEditPublicationUserPasswordMutation,
    useEditUserGroupMutation,
    useGetUserGroupsQuery,
    useGetPublicationsQuery,
    useGetDocumentVariantPublicationsQuery,
    useGetPublicationUsersQuery,
    useGetUserPublicationPasswordsQuery,
    useGetPublicationGroupsQuery,
    useGetUserPasswordActionsQuery,
    useGetPublicationUserPasswordsQuery,
    useRefreshKioskDataMutation,
    useUpdateTokenMutation,
    useDeleteForBatchMutation,
    usePutForBatchMutation,
    usePostForBatchMutation,
    useGetPublicationVersionsQuery,
} = publicationApi;
