import { generatePath, useParams } from 'react-router-dom';
import MainContentNav from '../../Navbar';
import { BASE_PATH, VIEW_PATH } from '../../../scenes/DocumentsV2';
import { SubNav, availableSettings } from './SubNav';
import { Variants } from './edit/variants/Variants';
import HelperFunctions from '../../global/HelperFunctions';
import Packages from './edit/Packages';
import FindReplace from './edit/FindReplace';
import { Templates } from './edit/Templates';
import { Settings } from './Settings';
import { useGetDocument } from '../hooks/useGetDocument';
import { Tags } from './view/Tags';
import { useTranslation } from 'react-i18next';
import { OneLiners } from 'pages/documents_v2/views/edit/one_liners/OneLiners';

export function Edit() {
    const { documentId, view } = useParams();
    const document = useGetDocument();
    const { t } = useTranslation('documents');

    const setting = HelperFunctions.getByValue(availableSettings, 'id', view);

    return (
        <>
            <MainContentNav
                pages={[
                    { title: t('documents.breadcrumb'), url: BASE_PATH },
                    {
                        title: document?.name,
                        url: generatePath(VIEW_PATH, {
                            documentId: documentId,
                        }),
                    },
                    { title: setting.name ?? '[ONBEKENDE TITEL]' },
                ]}
            />

            <SubNav />

            <div className="w-100">
                <EditView />
            </div>
        </>
    );
}

function EditView() {
    const { view } = useParams();

    switch (view) {
        case 'variants':
            return <Variants />;
        case 'one-liners':
            return <OneLiners />;
        case 'tags':
            return <Tags />;
        case 'packages':
            return <Packages />;
        case 'find_replace':
            return <FindReplace />;
        case 'models':
            return <Templates />;
        case 'settings':
            return <Settings />;
    }

    return <div>Setting '{view}' is not supported.</div>;
}
