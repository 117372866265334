import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import getEnv from '../../config/Env';
import { baseQueryWithReauth, prepareHeaders, providesList } from '../api';
import { ModelVersion } from '../../models/template.models';
import queryString from 'query-string';

const templatesApiUrl = getEnv('MODEL_API_BASE_URL');

const templatesApiQuery = fetchBaseQuery({
    baseUrl: templatesApiUrl,
    prepareHeaders,
});

// const appApiUrl = getEnv('APP_API_URL');
// const appApiQuery = fetchBaseQuery({
//     baseUrl: appApiUrl,
//     prepareHeaders,
// });

interface ApiResponse<T> {
    'hydra:member': T[];
}

export const templatesApi = createApi({
    reducerPath: 'templates',
    baseQuery: (args, api, extraOptions) => baseQueryWithReauth(args, api, extraOptions, templatesApiQuery),
    tagTypes: ['ModelVersion'],
    endpoints: (builder) => ({
        getModelVersions: builder.query<ModelVersion[], void>({
            query: (id) => `/api/model_versions?documentId=${id}`,
            transformResponse(response: ApiResponse<ModelVersion>) {
                return response['hydra:member'];
            },
            providesTags: (result) => providesList(result, 'ModelVersion'),
        }),
        getModelVersionByTemplateCategories: builder.query<ModelVersion[], number>({
            query: (organisationId) =>
                '/api/model_versions/template_categories/?' +
                queryString.stringify(
                    {
                        organisationId,
                        fields: ['version'],
                    },
                    { arrayFormat: 'bracket' }
                ),
            transformResponse(response: ApiResponse<ModelVersion>) {
                return response['hydra:member'];
            },
            providesTags: (result) => providesList(result, 'ModelVersion'),
        }),
        getActiveModelVersion: builder.query<ModelVersion, { id: number; source: string }>({
            query: ({ id, source }) => ({
                url: `/api/model-versions/latest/${id}/${source}`,
                method: 'GET',
                active: true,
            }),
            providesTags: (result) => (result ? [{ type: 'ModelVersion', id: `${result.source}-${result.id}` }] : []),
        }),
        deleteTemplate: builder.mutation<void, string>({
            query: (id) => ({
                url: `/api/model_versions/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['ModelVersion'],
        }),
        addTemplate: builder.mutation<ModelVersion, { uri: string; body: Partial<ModelVersion> }>({
            query: (body) => ({
                url: '/api/model_versions',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['ModelVersion'],
        }),
        activateTemplate: builder.mutation<ModelVersion, { uri: string; body: Partial<ModelVersion> }>({
            query: (uri) => ({
                url: uri,
                method: 'PUT',
                body: {
                    active: true,
                },
            }),
            invalidatesTags: ['ModelVersion'],
        }),
        getTemplateCategoryTemplates: builder.query({
            query: (id) => `/model_versions/?templateCategoryId=${id}`,
        }),
    }),
});

export const {
    useGetActiveModelVersionQuery,
    useGetModelVersionsQuery,
    useGetModelVersionByTemplateCategoriesQuery,
    useAddTemplateMutation,
    useDeleteTemplateMutation,
    useActivateTemplateMutation,
} = templatesApi;
