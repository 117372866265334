import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { useGetTasksQuery } from 'features/comments/commentApi';
import { JsonParam, NumberParam, StringParam, useQueryParam } from 'use-query-params';
import _ from 'lodash';
import HelperFunctions from 'pages/global/HelperFunctions';

export function useGetTasks(myTasks = false, defaultSortOptions, defaultFilterOptions) {
    const [search] = useQueryParam('search', StringParam);
    const [page = 1] = useQueryParam('page', NumberParam);
    const organisationId = useActiveOrganisation();
    const [filterOptions] = useQueryParam('filters', JsonParam);
    const [sortOptions] = useQueryParam('sort', JsonParam);
    const [dateFilters] = useQueryParam('dateFilters', JsonParam);

    let params = {
        organisationId,
        page,
    };

    // Search
    if (search) {
        params['or[title]'] = search;
        params['or[sequence]'] = search;
    }

    // Filter
    const combinedFilterOptions = {
        ...defaultFilterOptions,
        ...filterOptions,
    };

    if (!_.isEmpty(combinedFilterOptions)) {
        Object.keys(combinedFilterOptions).forEach((filterKey) => {
            const filterValue = combinedFilterOptions[filterKey];

            if (!_.isEmpty(filterValue)) {
                params[`and[${filterKey}]`] = filterValue.trim();
            }
        });
    }

    // Filter deletedAt
    if (myTasks) {
        params['exists[deletedAt]'] = false;
    }

    // Sort
    const combinedSortOptions = {
        ...sortOptions,
        ...defaultSortOptions,
    };

    if (!_.isEmpty(combinedSortOptions)) {
        Object.keys(combinedSortOptions).forEach((sortKey) => {
            const sortValue = combinedSortOptions[sortKey];

            if (!_.isEmpty(sortValue)) {
                params[`order[${sortKey}]`] = sortValue;
            }
        });
    }

    // Date filters
    if (!_.isEmpty(dateFilters)) {
        Object.keys(dateFilters).forEach((dateKey) => {
            const dateValue = dateFilters[dateKey];

            if (!_.isEmpty(dateValue)) {
                if (dateKey.endsWith('[before]')) {
                    params[dateKey] = `${dateValue} 23:59:59`;
                } else {
                    params[dateKey] = dateValue;
                }
            }
        });
    }

    return useGetTasksQuery(
        { myTasks, params },
        {
            selectFromResult: (result) => HelperFunctions.queryResult(result),
        },
    );
}
