import Constants from '../config/Constants';
import getEnv from '../config/Env';
import ApiConnector_v2 from './ApiConnector_v2';

const ApiConnector = {
    async apiDelete(apiUrl) {
        const { data } = await ApiConnector_v2.delete(apiUrl);

        return data;
    },
    async apiGet(apiUrl, authenticate, params = {}) {
        const { data } = await ApiConnector_v2.get(apiUrl, { params });

        return data;
    },
    async apiPatch(apiUrl, formData) {
        const { data } = await ApiConnector_v2.patch(apiUrl, formData);

        return data;
    },
    async apiPost(apiUrl, formData) {
        const { data } = await ApiConnector_v2.post(apiUrl, formData);

        return data;
    },
    async apiPut(apiUrl, formData) {
        const { data } = await ApiConnector_v2.put(apiUrl, formData);

        return data;
    },

    handleApiError(message) {
        return function (context) {
            if (getEnv('APP_ENV') === Constants.environments.dev) {
                console.log(message, context);
            }

            return Promise.reject(context);
        };
    },
};

export default ApiConnector;
