import MainContentNav from '../Navbar';
import { generatePath, useParams } from 'react-router-dom';
import { useGetAdminAccountQuery } from '../../features/security/authApi';
import { Sidebar } from './views/account/Sidebar';
import { Users } from './views/account/Users';
import { AccountItem } from './views/account/AccountItem';
import { Organisations } from './views/account/Organisations';
import { ADMIN_SETTINGS_PATH } from '../../scenes/Admin';
import { EditAccountModal } from './views/modals/EditAccountModal';
import { Import } from './views/account/Import';

const RenderViews = {
    item: AccountItem,
    users: Users,
    organisations: Organisations,
    import: Import,
};

export function Account() {
    const { id, view = 'item' } = useParams();
    const ViewToRender = RenderViews[view] ?? DefaultView;

    const { account } = useGetAdminAccountQuery(parseInt(id), {
        selectFromResult: ({ data }) => ({
            account: data,
        }),
    });

    return (
        <>
            <MainContentNav
                pages={[
                    { title: 'Systeembeheer' },
                    {
                        title: 'Accounts',
                        url: generatePath(ADMIN_SETTINGS_PATH, {
                            view: 'accounts',
                        }),
                    },
                    { title: account?.name },
                ]}
            />

            <div className="content-static-body">
                <div className="d-flex align-items-stretch h-100">
                    <Sidebar />

                    <div className="flex-grow-1 overflow-auto">
                        <div id="content" className="px-5 pt-5 pb-3">
                            <div className="dr-container p-4">{account && <ViewToRender account={account} />}</div>
                        </div>
                    </div>
                </div>
            </div>

            <EditAccountModal />
        </>
    );
}

function DefaultView() {
    return <div>no view</div>;
}
