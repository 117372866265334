import HelperFunctions from "../../global/HelperFunctions";

export const generateRandomId = (content = []) => {
    const min = 1000, max = 9999;
    const random = Math.floor(Math.random() * (+max - +min)) + +min;

    if (content.length > 0) {
        const item = HelperFunctions.getByValue(content, 'id', random);
        if (item !== undefined) {
            // random id already in the list!
            return generateRandomId(content);
        }
    }

    return random;
};
