import { useParams } from 'react-router-dom';
import { useGetDocumentTagsQuery } from 'features/documents/documents';

const emptyArray = [];

export function useGetDocumentTags(id = undefined) {
    const { documentId } = useParams();
    const idAsNumber = id ?? parseInt(documentId);

    const { documentTags } = useGetDocumentTagsQuery(idAsNumber, {
        selectFromResult: ({ data }) => ({
            documentTags: data ?? emptyArray,
        }),
        skip: !idAsNumber,
    });

    return documentTags;
}
