import {
    useGetCollectiveGroupsQuery,
    useGetCollectivesQuery,
} from '../../../../../features/reimbursements/reimbursements';
import { useParams } from 'react-router-dom';
import { DocRevSelect } from '../../../../publications_v2/helpers/FieldHelper';
import { FieldArray, FormikContext } from 'formik';
import { useContext, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import HelperFunctions from '../../../../global/HelperFunctions';
import { createFilter } from 'react-select';
import { idToUri } from '../../../../global/UriHelper';
import cx from 'classnames';
import { InfoButton } from '../../../../../components/Buttons';
import { useTranslation } from 'react-i18next';

export default function Collectives() {
    const { categoryId } = useParams();
    const categoryUri = idToUri(categoryId, 'Category');
    const { t } = useTranslation('reimbursements');

    const { allCollectives } = useGetCollectivesQuery(
        { uri: categoryUri },
        {
            selectFromResult: ({ data }) => ({
                allCollectives: data ?? [],
            }),
        }
    );

    const { collectiveGroups } = useGetCollectiveGroupsQuery(categoryUri, {
        selectFromResult: ({ data }) => ({
            collectiveGroups: data ?? [],
        }),
    });

    return (
        <>
            <div className="subheader">
                <h3>{t('proposition.editProposition.collectivities.title')}</h3>
            </div>

            <FieldArray name="collectives">
                {({ remove, push }) => (
                    <>
                        <AddCollective
                            allCollectives={allCollectives}
                            collectiveGroups={collectiveGroups}
                            push={push}
                        />
                        <SelectedCollectives allCollectives={allCollectives} remove={remove} />
                    </>
                )}
            </FieldArray>
        </>
    );
}

function AddCollective({ allCollectives, collectiveGroups, push }) {
    const [selectedCollective, setSelectedCollective] = useState('');
    const { values } = useContext(FormikContext);
    const { t } = useTranslation('reimbursements');

    const optionValues = collectiveGroups
        .filter((_item) => _item.collectives.length > 0)
        .map((_item) => {
            const parent = collectiveGroups.find((_parent) => _parent['@id'] === _item.parent);

            return {
                label: `${parent.name} > ${_item.name}`,
                options: _item.collectives
                    .filter((uri) => allCollectives.find((_item) => _item['@id'] === uri) !== undefined)
                    .map((uri) => {
                        const collective = allCollectives.find((_item) => _item['@id'] === uri);
                        const { name, code = '', codeAndName } = collective;
                        const isDisabled =
                            values.collectives.includes(collective['@id']) || collective.variantPackages.length > 0;

                        return {
                            label: (
                                <>
                                    {code !== '' && (
                                        <span
                                            className={cx('mr-2', {
                                                'text-muted': isDisabled,
                                                'text-secondary': !isDisabled,
                                            })}
                                        >
                                            {code}
                                        </span>
                                    )}
                                    <span>{name}</span>
                                </>
                            ),
                            value: collective['@id'],
                            codeAndName,
                            isDisabled,
                        };
                    })
                    .sort(HelperFunctions.sortByString('codeAndName')),
            };
        });

    return (
        <div className="border-bottom mb-4 pb-4">
            <Row>
                <Col xs={4}>
                    <div className="font-weight-bold pt-1" style={{ color: '#344054', fontSize: 13 }}>
                        {t('proposition.editProposition.collectivities.addCollectivity')}
                    </div>
                </Col>
                <Col>
                    <DocRevSelect
                        name="add_collective"
                        options={optionValues}
                        selectedValue={selectedCollective}
                        props={{
                            placeholder: `${t('proposition.editProposition.collectivities.titleSelect')}...`,
                            filterOption: createFilter({
                                stringify: ({ data }) => data.codeAndName,
                            }),
                        }}
                        onChange={(selectedValue) => {
                            if (selectedValue !== null) {
                                setSelectedCollective(selectedValue.value);
                            }
                        }}
                    />
                </Col>
            </Row>

            <div className="d-flex flex-row-reverse mt-2">
                <InfoButton
                    disabled={selectedCollective === ''}
                    onClick={() => {
                        push(selectedCollective);
                        setSelectedCollective('');
                    }}
                >
                    {t('proposition.editProposition.collectivities.add')}
                </InfoButton>
            </div>
        </div>
    );
}

function SelectedCollectives({ allCollectives, remove }) {
    const { values } = useContext(FormikContext);
    const { t } = useTranslation('reimbursements');

    return (
        <Row>
            <Col xs={4}>
                <div className="font-weight-bold pt-1" style={{ color: '#344054', fontSize: 13 }}>
                    {t('proposition.editProposition.collectivities.collectivities')}
                </div>
                <div className="text-secondary mt-1" style={{ fontSize: 12 }}>
                    {t('proposition.editProposition.collectivities.note')}
                </div>
            </Col>
            <Col>
                <div className="border" style={{ borderRadius: '0.2rem' }}>
                    <table className="table table-team">
                        <thead>
                            <tr>
                                <th>{t('proposition.editProposition.collectivities.code')}</th>
                                <th>{t('proposition.editProposition.collectivities.name')}</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {values.collectives
                                .map((uri) => allCollectives.find((_item) => _item['@id'] === uri))
                                .sort(HelperFunctions.sortByString('codeAndName'))
                                .map((_item, index) => (
                                    <CollectiveRow
                                        collective={_item}
                                        remove={(uri) => {
                                            remove(values.collectives.findIndex((_item) => _item === uri));
                                        }}
                                        key={`collective-row-${index}`}
                                    />
                                ))}

                            {values.collectives.length === 0 && (
                                <tr>
                                    <td colSpan={3}>
                                        <div className="small text-secondary">
                                            {t('proposition.editProposition.collectivities.noLinked')}
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </Col>
        </Row>
    );
}

function CollectiveRow({ collective, remove }) {
    if (collective === undefined) {
        return null;
    }

    const { name, code = '' } = collective;
    const { t } = useTranslation('reimbursements');

    return (
        <tr>
            <td className="text-secondary">{code}</td>
            <td className="font-weight-bolder">{name}</td>
            <td className="align-middle">
                <div className="d-flex flex-row-reverse">
                    <a onClick={() => remove(collective['@id'])} className="font-weight-bold text-danger">
                        {t('proposition.editProposition.collectivities.delete')}
                    </a>
                </div>
            </td>
        </tr>
    );
}
