import MainContentNav from '../Navbar';
import { useParams } from 'react-router-dom';
import { Sidebar } from './views/Sidebar';
import { Import } from './views/Import';
import { AccountsList } from './views/AccountsList';
import { CreateAccountModal } from './views/modals/CreateAccountModal';

export function Admin() {
    const { view = 'accounts' } = useParams();

    const getTitle = (view) => {
        if (view === 'accounts') {
            return 'Accounts';
        }

        if (view === 'import') {
            return 'Account importeren';
        }

        return 'Instellingen';
    };

    return (
        <>
            <MainContentNav pages={[{ title: 'Systeembeheer' }, { title: getTitle(view) }]} />

            <div className="content-static-body">
                <div className="d-flex align-items-stretch h-100">
                    <Sidebar />
                    <View view={view} />
                </div>
            </div>

            <CreateAccountModal />
        </>
    );
}

const RenderViews = {
    accounts: AccountsList,
    import: Import,
};

function View({ view }) {
    const ViewToRender = RenderViews[view] ?? DefaultView;

    return (
        <div className="flex-grow-1 overflow-auto">
            <div id="content" className="px-5 pt-5 pb-3">
                <div className="dr-container p-4">
                    <ViewToRender />
                </div>
            </div>
        </div>
    );
}

function DefaultView() {
    return <div>no view</div>;
}
