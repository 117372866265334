import { FilterVariantSelect } from '../../../forms/FieldHelper';
import { useGetDocumentVariantsQuery } from '../../../../../features/translations/translationApi';
import { useParams } from 'react-router-dom';
import { entityTypes, views } from '../../../config/Constants';

export default function DocumentSidebar({ documentContainer }) {
    const { documentId } = documentContainer;
    const { data } = useGetDocumentVariantsQuery(documentId);
    const { view, entityType } = useParams();

    if (data === undefined) {
        return null;
    }

    return (
        <>
            {(view === views.DOCUMENT || entityType === entityTypes.SECTION || entityType === entityTypes.AREA) && (
                <FilterVariantSelect name="variantId" baseVariant={data.documentVariants} />
            )}
        </>
    );
}
