import { useGetMyTeamUsers } from 'pages/task_team/hooks/useGetMyTeamUsers';
import { useGetTeams } from 'pages/task_team/hooks/useGetTeams';
import { useUserProfile } from 'hooks/useUserProfile';
import { taskStatus } from 'pages/task_team/config/constants';

export function useHasCreateTaskPermission() {
    const myTeamUsers = useGetMyTeamUsers();
    const teams = useGetTeams();

    const hasTeamWithCreatePermissions = teams.some((team) => team.permissions.canCreateTask === true);

    return hasTeamWithCreatePermissions && myTeamUsers.some((teamUser) => teamUser.permissions.canCreateTask === true);
}

export function useHasReviewTaskPermission() {
    const myTeamUsers = useGetMyTeamUsers();
    const teams = useGetTeams();

    const hasTeamWithReviewPermissions = teams.some((team) => team.permissions.canReviewTask === true);

    return hasTeamWithReviewPermissions && myTeamUsers.some((teamUser) => teamUser.permissions.canReviewTask === true);
}

export function useCanAssignReviewerPermission() {
    const myTeamUsers = useGetMyTeamUsers();
    const teams = useGetTeams();

    const hasTeamWithReviewPermissions = teams.some((team) => team.permissions.canReviewTask === true);

    return (
        hasTeamWithReviewPermissions && myTeamUsers.some((teamUser) => teamUser.permissions.canAssignReviewer === true)
    );
}

export function useCanViewReports() {
    const myTeamUsers = useGetMyTeamUsers();

    return myTeamUsers.some((teamUser) => teamUser.permissions.canViewReports === true);
}

export function useCanViewConfidentialTasks() {
    const myTeamUsers = useGetMyTeamUsers();

    return myTeamUsers.some((teamUser) => teamUser.permissions.canViewConfidentialTasks === true);
}

export function useCanChangeTaskOwner() {
    const myTeamUsers = useGetMyTeamUsers();

    return myTeamUsers.some((teamUser) => teamUser.permissions.canChangeTaskOwner === true);
}

export function useIsTaskOwner(task) {
    const userProfile = useUserProfile();

    return task && userProfile.userId === task.createdBy;
}

export function useCanEditTaskProperties(task) {
    const isTaskOwner = useIsTaskOwner(task);
    const allowedStatusCodes = [taskStatus.STATUS_DRAFT];

    return task && isTaskOwner && allowedStatusCodes.includes(task.status) && !task.archived;
}

export function useCanOverrideTaskStatus() {
    const myTeamUsers = useGetMyTeamUsers();

    return myTeamUsers.some((teamUser) => teamUser.permissions.canOverrideTaskStatus === true);
}
