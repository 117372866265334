import React, { useContext, useMemo, useState } from 'react';
import { Col } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import { read, utils } from 'xlsx';
import { parseBlockData } from '../../global/BlockLayoutHelper';
import Constants from '../../../config/Constants';
import getTemplateParameters from '../../../components/SettingsSidebar/views/TemplateConstants';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { EditAreaContext } from 'pages/documents_v2/views/edit_area/EditArea';
import { useGetDocument } from 'pages/documents_v2/hooks/useGetDocument';

export function ImportCSVFileAsBlockLayoutContent({ handleClose, setSubmitting, values, areaBlocks, baseVariantId }) {
    const { t } = useTranslation('documents');
    const [file, setFile] = useState(null);
    const { insertBlocks } = useContext(EditAreaContext);
    const document = useGetDocument(undefined, true);

    const documentVariants = useMemo(() => {
        if (values.variants.includes(baseVariantId)) {
            return values.variants;
        }

        return [...values.variants, baseVariantId];
    }, [values.variants, baseVariantId]);

    if (!document) {
        return null;
    }

    const generateRandomKey = (existingKeys = [], keyPrefix) => {
        const randomNumber = Math.floor(Math.random() * 100000) + 1;
        const randomKey = parseInt(`${keyPrefix}${randomNumber}`);

        if (existingKeys.includes(randomKey)) {
            return generateRandomKey(existingKeys, keyPrefix);
        }

        return randomKey;
    };

    function addBlocksFromCSV(data, areaBlocks) {
        let content = [];
        let newAreaBlocks = [];
        let existingKeys = areaBlocks.map((block) => block.key);

        const currentDate = new Date();
        const keyPrefix = parseInt(`${currentDate.getFullYear()}${currentDate.getMonth()}`);

        data.forEach((row) => {
            let contentRow = [];

            row.forEach((cell) => {
                if (cell === '') {
                    contentRow.push([]);
                } else {
                    const randomKey = generateRandomKey(existingKeys, keyPrefix);
                    existingKeys.push(randomKey);

                    const newBlock = {
                        id: null,
                        key: randomKey,
                        documentVariants,
                        latestContent: `${applyCrossReferences({ cell: `${cell}`, document: document })}`,
                        properties: {
                            textLayout: 'default',
                            templateParameters: justTheParameters(Constants.blockTypes.text),
                        },
                        type: Constants.blockTypes.text,
                    };
                    newAreaBlocks.push(newBlock);
                    contentRow.push([{ id: newBlock.key }]);
                }
            });

            content.push(contentRow);
        });

        const layoutBlockContent = {
            rows: content.length,
            columns: content.length > 0 ? content[0].length : 0,
            data: content,
        };

        const newLayoutBlock = {
            id: null,
            key: generateRandomKey(existingKeys, keyPrefix),
            documentVariants,
            latestContent: parseBlockData(layoutBlockContent),
            properties: {
                type: 'default',
                showTableHeader: true,
                templateParameters: justTheParameters(Constants.blockTypes.blockLayout),
            },
            type: Constants.blockTypes.blockLayout,
        };

        newAreaBlocks.push(newLayoutBlock);

        return newAreaBlocks;
    }

    const handleDrop = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            setFile(file);
            setSubmitting(true);
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = read(new Uint8Array(data), { type: 'array', raw: true, FS: ';' });
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const csvData = utils.sheet_to_json(worksheet, {
                    header: 1,
                    blankrows: false,
                    defval: '',
                    rawNumbers: true,
                });
                const newAreaBlocks = addBlocksFromCSV(csvData, areaBlocks);

                insertBlocks(newAreaBlocks);
                handleClose();
            };
            reader.readAsArrayBuffer(file);
        }
    };

    return (
        <div className="mt-3">
            <Dropzone maxFiles={1} accept={{ 'text/csv': ['.csv'] }} onDrop={handleDrop}>
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps({ style })}>
                        <input {...getInputProps()} />
                        {file ? (
                            <Col className="outlined">
                                {' '}
                                <>
                                    {t(
                                        'document.navbar.main.editor.left.subnav.addBlock.blockLayout.dropZone.selectedFile'
                                    )}
                                </>{' '}
                                {file.name}
                            </Col>
                        ) : (
                            <div>
                                <span className="text-muted font-small-weighted pr-2">
                                    <>
                                        {t(
                                            'document.navbar.main.editor.left.subnav.addBlock.blockLayout.dropZone.placeholderText'
                                        )}
                                    </>
                                </span>
                            </div>
                        )}
                    </div>
                )}
            </Dropzone>
        </div>
    );
}

function justTheParameters({ blockType }) {
    const templateParameters = getTemplateParameters(blockType, {});
    return Object.assign({}, ...Object.values(templateParameters));
}

function applyCrossReferences({ cell, document }) {
    const crossReferencesList = [];

    document?.sections.forEach((section) => {
        if (section.deletedAt === null) {
            crossReferencesList.push({
                title: section.title,
                id: section.id,
                type: 'section',
            });
        }
        section.areas.forEach((area) => {
            if (area.deletedAt === null) {
                crossReferencesList.push({
                    title: area.title,
                    id: area.id,
                    type: 'area',
                });
            }
        });
    });

    if (crossReferencesList.length > 0) {
        cell = _.toString(cell);
        const pattern = crossReferencesList
            .map((entry) => entry.title.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'))
            .join('|');
        const regex = new RegExp(`\\{\\b(${pattern})\\b\\}`, 'g');

        cell = cell.replace(regex, (match, group1) => {
            const ref = crossReferencesList.find((item) => item.title === group1);
            if (ref) {
                return `[Ref id=${ref.id} type=${ref.type} document=${document.id}]`;
            }
            return match;
        });
    }
    return cell;
}

const style = {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
};
