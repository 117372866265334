import { CaretDownFill, SquareFill } from 'react-bootstrap-icons';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { patchOpEntryLabel, selectDepartmentById } from 'features/operationsList/operationsListSlice';
import { selectDepartmentUsers } from 'features/operationsList/opListUserSlice';
import { selectAllDepartmentTasksForOpList } from 'features/operationsList/taskSlice';
import { findDeep } from 'deepdash-es/standalone';
import { hasMissingFields } from './OpTeamTasks';
import { useMemo } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useGetOrganisationUsers } from 'hooks/useGetOrganisationUsers';

export const getStatus = (opEntryLabel, department) => {
    return department?.statusCodes.find((_status) => _status.slug === opEntryLabel.status) ?? undefined;
};

export function OpTeamEntryStatus({
    opEntry = {},
    opEntryLabel,
    marginLevel = '3',
    ignoreArchivedAndCancelled = false,
}) {
    const department = useSelector((state) => selectDepartmentById(state.operations, opEntryLabel.opDepartment));
    const status = getStatus(opEntryLabel, department);
    const statusColor = status?.color ?? '#000';
    const statusLabel = status?.label ?? 'Onbekend';

    const archived = !ignoreArchivedAndCancelled && opEntryLabel.archived;
    const cancelled = !ignoreArchivedAndCancelled && opEntry.cancelled;

    return (
        <OpTeamEntryStatusSquare
            statusLabel={statusLabel}
            statusColor={statusColor}
            archived={archived}
            cancelled={cancelled}
            marginLevel={marginLevel}
        />
    );
}

export function OpTeamEntryStatusSquare({
    statusLabel,
    statusColor,
    archived = false,
    cancelled = false,
    marginLevel = '3',
    fontSize = '1.125rem',
}) {
    const { t } = useTranslation('changelist');

    return (
        <>
            {archived && <div className="text-warning mr-2">{t('changelist.misc.archived')}</div>}
            {cancelled && <div className="text-danger mr-2">{t('changelist.misc.cancelled')}</div>}
            <SquareFill
                data-uk-tooltip={statusLabel}
                className={`mr-${marginLevel}`}
                style={{ color: statusColor, fontSize: fontSize, minWidth: fontSize }}
            />
        </>
    );
}

export function OpTeamEntryStatusDropdown({ opEntryLabel, opList }) {
    const department = useSelector((state) => selectDepartmentById(state.operations, opEntryLabel.opDepartment));
    const dispatch = useDispatch();
    const { t } = useTranslation('changelist');

    if (department === undefined) {
        return null;
    }

    const status = getStatus(opEntryLabel, department);
    const statusColor = status?.color ?? '#000';
    const statusLabel = status?.label ?? t('changelist.misc.unknown');

    const handleSelect = (eventKey) => {
        dispatch(
            patchOpEntryLabel({
                uri: opEntryLabel['@id'],
                formData: {
                    status: eventKey,
                },
            })
        );
    };

    return (
        <div
            onClick={(event) => {
                event.stopPropagation();
            }}
        >
            <Dropdown onSelect={handleSelect}>
                <Dropdown.Toggle
                    bsPrefix="team-entry-status-dropdown d-flex align-items-center"
                    as="span"
                    id={'entry-' + opEntryLabel.id + '-dropdown'}
                >
                    <SquareFill style={{ color: statusColor }} size={10} className="mr-2" />
                    <span className="font-weight-normal" style={{ fontSize: '0.875rem' }}>
                        {statusLabel}
                    </span>
                    <CaretDownFill className="ml-2" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {department.statusCodes.map((statusCode, index) => (
                        <OpTeamEntryStatusDropdownItem
                            statusCode={statusCode}
                            opEntryLabel={opEntryLabel}
                            opList={opList}
                            key={`op_list-${opList.id}-status_code-${index}`}
                        />
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}

function OpTeamEntryStatusDropdownItem({ statusCode, opEntryLabel, opList }) {
    const tasks = useSelector(selectAllDepartmentTasksForOpList(opList));
    const availableTasks = tasks.filter((_task) => !_task.deletedAt && _task.fields.length > 0);
    const isActive = statusCode.slug === opEntryLabel.status;
    const statusColor = statusCode?.color ?? '#000';

    const isCodeValid = useMemo(() => {
        return canSelectStatusCode(statusCode, opEntryLabel, availableTasks);
    }, [statusCode, opEntryLabel, availableTasks]);

    return (
        <Dropdown.Item
            disabled={!isCodeValid}
            eventKey={statusCode.slug}
            active={isActive}
            className={cx('d-flex align-items-center', { 'font-weight-bold bg-light': isActive })}
        >
            <SquareFill style={{ color: statusColor }} size={10} className="mr-2" />
            {statusCode.label}
        </Dropdown.Item>
    );
}

const canSelectStatusCode = (statusCode, opEntryLabel, availableTasks = []) => {
    const { requiredConditions = [] } = statusCode;
    let isCodeValid = true;

    if (requiredConditions.length > 0) {
        const { fieldValues = {} } = opEntryLabel;

        isCodeValid = requiredConditions.every((condition) => {
            const { field, fieldOption, type } = condition;
            const givenAnswer = fieldValues[field] ?? undefined;

            if (givenAnswer === undefined) {
                return false;
            }

            const taskFieldResult = findDeep(
                availableTasks,
                (value) => {
                    if (value['@type'] !== 'TaskField') {
                        return undefined;
                    }

                    return value['@id'] === field;
                },
                {
                    childrenPath: 'fields',
                }
            );

            let taskHasMissingFields = false;

            if (taskFieldResult) {
                const task = taskFieldResult.parent;
                taskHasMissingFields = hasMissingFields(opEntryLabel, task);
            }

            if (taskHasMissingFields) {
                return false;
            }

            if (type === 'not_equals') {
                return givenAnswer !== fieldOption;
            } else if (type === 'equals') {
                return givenAnswer === fieldOption;
            }

            // Fallback unknown type
            return false;
        });
    }

    return isCodeValid;
};

export function OpTeamResponsible({ opList, opEntryLabel, showDropdown = false }) {
    const dispatch = useDispatch();
    const organisationUsers = useGetOrganisationUsers();
    const departmentUsers = useSelector(selectDepartmentUsers(opList, organisationUsers));

    const handleSelect = (eventKey) => {
        const isResponsible = opEntryLabel.responsible.includes(eventKey);

        if (isResponsible) {
            // Remove User
            dispatch(
                patchOpEntryLabel({
                    uri: opEntryLabel['@id'],
                    formData: {
                        responsible: opEntryLabel.responsible.filter((userId) => userId !== eventKey),
                    },
                })
            );
        } else {
            // Add User
            dispatch(
                patchOpEntryLabel({
                    uri: opEntryLabel['@id'],
                    formData: {
                        responsible: [...opEntryLabel.responsible, eventKey],
                    },
                })
            );
        }
    };

    return (
        <div className="d-flex align-items-center">
            <ResponsibleUsers opEntryLabel={opEntryLabel} />

            {showDropdown && (
                <div
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                >
                    <Dropdown onSelect={handleSelect}>
                        <Dropdown.Toggle
                            bsPrefix="team-entry-status-dropdown"
                            as="span"
                            id={'entry-responsible-' + opEntryLabel.id + '-dropdown'}
                        >
                            <CaretDownFill />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {departmentUsers.map((user) => (
                                <Dropdown.Item
                                    eventKey={user.id}
                                    active={opEntryLabel.responsible.includes(user.id)}
                                    key={`op_team_responsible-${opEntryLabel.id}-${user.id}`}
                                >
                                    {`${user.firstName} ${user.lastName}`}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            )}
        </div>
    );
}

function ResponsibleUsers({ opEntryLabel }) {
    const { t } = useTranslation('changelist');
    const organisationUsers = useGetOrganisationUsers();
    const users = getUsers();

    if (users.length === 0) {
        return <div className="small mr-1">{t('changelist.misc.noHandlers')} </div>;
    }

    return (
        <>
            {users.map((user) => {
                const nameParts = user.fullName.split(' ');

                return (
                    <div
                        style={{ width: 32, height: 32, fontSize: '0.875rem' }}
                        className="d-flex justify-content-center align-items-center text-white font-weight-normal bg-primary rounded-circle mr-1"
                        data-uk-tooltip={`${user.fullName}`}
                        key={`op_entry_label-${opEntryLabel.id}-${user.id}`}
                    >
                        {nameParts[0].substr(0, 1)}
                        {nameParts[nameParts.length - 1].substr(0, 1)}
                    </div>
                );
            })}
        </>
    );

    function getUsers() {
        return organisationUsers.filter((user) => opEntryLabel.responsible.includes(user.id));
    }
}
