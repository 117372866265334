import { useGetActiveModelVersionQuery } from 'features/templates/templatesApi';

export function useGetTemplate(modelId, source = 'local') {
    const { activeModelVersion } = useGetActiveModelVersionQuery(
        { id: modelId, source },
        {
            skip: !modelId,
            selectFromResult: ({ data }) => ({
                activeModelVersion: data,
            }),
        }
    );

    if (!activeModelVersion) {
        return null;
    }

    if (activeModelVersion.payloadJson) {
        const jsonData = JSON.parse(activeModelVersion.payloadJson);

        return {
            ...jsonData,
            version: activeModelVersion.version,
            templateCategory: activeModelVersion.templateCategory,
        };
    }

    return null;
}
