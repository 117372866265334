import { InputMultipleSelect } from 'pages/publications_v2/helpers/FieldHelper';
import { useFormikContext } from 'formik';
import { Form } from 'react-bootstrap';
import HelperFunctions from 'pages/global/HelperFunctions';
import { useGetTargetAudiences } from 'pages/task_team/hooks/useGetTargetAudiences';
import { useGetTopics } from 'pages/task_team/hooks/useGetTopics';
import { useGetBrands } from 'pages/task_team/hooks/useGetBrands';

export function Brands() {
    const { values, setFieldValue } = useFormikContext();
    const brands = useGetBrands();
    const topics = useGetTopics();
    const targetAudiences = useGetTargetAudiences();

    const optionsBrands = HelperFunctions.prepareDropdownData(values.settings.brands);
    const optionsTopics = HelperFunctions.prepareDropdownData(values.settings.topics);
    const optionsTargetAudience = HelperFunctions.prepareDropdownData(values.settings.targetAudiences);

    return (
        <>
            <div className="mb-3">
                <Form.Group className="mb-2 label-title">
                    <div className="mb-2 text-color font-size-sm font-weight-bolder label-title-label">Merken</div>
                    <InputMultipleSelect
                        name="settings.brands"
                        value={optionsBrands}
                        defaultValue={optionsBrands}
                        creatable={true}
                        options={HelperFunctions.prepareDropdownData(brands)}
                        onChange={(selection) => handleChange(selection, 'settings.brands')}
                    />
                </Form.Group>
            </div>
            <div className="mb-3">
                <Form.Group className="mb-2 label-title">
                    <div className="mb-2 text-color font-size-sm font-weight-bolder label-title-label">Onderwerpen</div>
                    <InputMultipleSelect
                        name="settings.topics"
                        value={optionsTopics}
                        defaultValue={optionsTopics}
                        creatable={true}
                        options={HelperFunctions.prepareDropdownData(topics)}
                        onChange={(selection) => handleChange(selection, 'settings.topics')}
                    />
                </Form.Group>
            </div>
            <div className="mb-3">
                <Form.Group className="mb-2 label-title">
                    <div className="mb-2 text-color font-size-sm font-weight-bolder label-title-label">Doelgroepen</div>
                    <InputMultipleSelect
                        name="settings.targetAudiences"
                        value={optionsTargetAudience}
                        defaultValue={optionsTargetAudience}
                        creatable={true}
                        options={HelperFunctions.prepareDropdownData(targetAudiences)}
                        onChange={(selection) => handleChange(selection, 'settings.targetAudiences')}
                    />
                </Form.Group>
            </div>
        </>
    );

    function handleChange(selection, field) {
        setFieldValue(
            field,
            selection.map((item) => ({
                ...item,
                name: item.label,
            }))
        );
    }
}
