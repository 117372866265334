import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { useGetUserGroups } from 'pages/publications_v2/hooks/publicationUsers';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { EditUserModalInner } from 'pages/publicationUsers/modals/EditUserModal';
import { useParams } from 'react-router-dom';

export function CreateUserModal() {
    const [addUser] = useQueryParam('addUser', BooleanParam);
    const { id } = useParams();

    if (!addUser) {
        return null;
    }

    return <CreateUser defaultGroupId={id ? parseInt(id) : undefined} />;
}

function CreateUser({ defaultGroupId }) {
    const [addUser, setAddUser] = useQueryParam('addUser', BooleanParam);
    const organisationId = useActiveOrganisation();
    const userGroups = useGetUserGroups();
    const defaultGroups = defaultGroupId
        ? userGroups.filter((group) => group.id === defaultGroupId).map((group) => group['@id'])
        : [];

    return (
        <EditUserModalInner
            editedUser={{
                organisationId,
                userGroups: defaultGroups,
                isNewsletterActive: true,
                isDocumentUpdatesActive: true,
            }}
            close={close}
        />
    );

    function close() {
        setAddUser(undefined);
    }
}
