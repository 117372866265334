import { useState } from 'react';
import _ from 'lodash';
import { Button, Form, Modal } from 'react-bootstrap';
import AllMatchesProcessed from './AllMatchesProcessed';
import Loading from './Loading';
import { useGetOrganisationTags } from '../hooks/useGetOrganisationTags';
import { useGetDocumentTagQuery, useUpdateTagMutation } from '../../../features/documents/documents';
import Highlighter from 'react-highlight-words';
import { useActiveOrganisation } from '../../../hooks/useActiveOrganisation';
import { useTranslation } from 'react-i18next';

/**
 * Component that deals with the searchResults of the tag search query.
 *
 * @param close
 * @param setError
 * @param resetState
 * @param searchMatches
 * @param documentId
 * @returns {JSX.Element}
 * @constructor
 */
export default function Searched({ close, setError, resetState, searchMatches, documentId = null }) {
    if (_.isEmpty(searchMatches)) {
        return <NoMatchesFound close={close} resetState={resetState} />;
    }

    return (
        <MatchesFound
            close={close}
            setError={setError}
            resetState={resetState}
            searchMatches={searchMatches}
            documentId={documentId}
        />
    );
}

function NoMatchesFound({ close, resetState }) {
    const { t } = useTranslation('documents');

    return (
        <>
            <Modal.Body>
                <p>{t('document.navbar.tags.searchModal.noResults')}</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="light" onClick={close}>
                    {t('btn.cancel')}
                </Button>
                <Button variant="primary" onClick={resetState}>
                    {t('document.navbar.tags.searchModal.btn.otherCriteria')}
                </Button>
            </Modal.Footer>
        </>
    );
}

function MatchesFound({ close, setError, resetState, searchMatches, documentId = null }) {
    const [previousSelectedMatch, setPreviousSelectedMatch] = useState(0);
    const [selectedMatchIndex, setSelectedMatchIndex] = useState(0);
    const selectedMatch = searchMatches[selectedMatchIndex];

    // When selecting the next match we also want to store the previous/current match
    // So we can check if the matchEntity changed later on.
    const selectMatch = (newIndex, previousSelectedMatch) => {
        Promise.resolve()
            .then(() => setPreviousSelectedMatch(previousSelectedMatch))
            .then(() => setSelectedMatchIndex(newIndex));
    };

    // No match selected, that means we have run through all matches.
    // Show the finish page
    if (!selectedMatch) {
        return <AllMatchesProcessed close={close} resetState={resetState} />;
    }

    // If the matchEntity is the same for the previous and current match, then set the content of the previousMatch on the current one.
    // That is because for the old match the occurrence might have been replaced, this way we don't have to reload the tags
    // And we can just continue directly.
    const sameMatchEntity =
        previousSelectedMatch &&
        previousSelectedMatch.tagId === selectedMatch.tagId &&
        previousSelectedMatch.tagItemId === selectedMatch.tagItemId;
    if (sameMatchEntity && previousSelectedMatch.content !== selectedMatch.content) {
        selectedMatch.content = previousSelectedMatch.content;
    }

    return (
        <ResolveMatchTag
            setError={setError}
            searchMatches={searchMatches}
            selectedMatchIndex={selectedMatchIndex}
            selectMatch={selectMatch}
            selectedMatch={selectedMatch}
            matchEntityChanged={!sameMatchEntity}
            documentId={documentId}
        />
    );
}

/**
 * Helper component that makes sure to resolve the tag that the selected match refers to.
 *
 * @param setError
 * @param searchMatches
 * @param selectedMatchIndex
 * @param selectMatch
 * @param selectedMatch
 * @param matchEntityChanged
 * @param documentId
 * @returns {JSX.Element}
 * @constructor
 */
function ResolveMatchTag({
    setError,
    searchMatches,
    selectedMatchIndex,
    selectMatch,
    selectedMatch,
    matchEntityChanged,
    documentId = null,
}) {
    const organisationTags = useGetOrganisationTags();

    const { documentTag } = useGetDocumentTagQuery(
        { documentId: parseInt(documentId), id: selectedMatch.tagId },
        {
            selectFromResult: ({ data }) => ({
                documentTag: data,
            }),
            skip: !documentId,
        }
    );

    let selectedTag;
    if (documentId) {
        selectedTag = documentTag;
    } else {
        selectedTag = _.find(organisationTags, (tag) => tag.id === selectedMatch.tagId);
    }

    // Tag still needs to be loaded.
    if (!selectedTag) {
        return <Loading />;
    }

    return (
        <ReplaceMatchOccurrences
            setError={setError}
            searchMatches={searchMatches}
            selectedMatchIndex={selectedMatchIndex}
            selectMatch={selectMatch}
            selectedMatch={selectedMatch}
            matchEntityChanged={matchEntityChanged}
            selectedTag={selectedTag}
        />
    );
}

/**
 * Component where the occurrence of the selected match is shown and where it can be replaced.
 *
 * @param setError
 * @param searchMatches
 * @param selectedMatchIndex
 * @param selectMatch
 * @param selectedMatch
 * @param matchEntityChanged
 * @param selectedTag
 * @returns {JSX.Element}
 * @constructor
 */
function ReplaceMatchOccurrences({
    setError,
    searchMatches,
    selectedMatchIndex,
    selectMatch,
    selectedMatch,
    matchEntityChanged,
    selectedTag,
}) {
    const [positionOffset, setPositionOffset] = useState(0);
    const [replacement, setReplacement] = useState('');
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation('documents');

    const activeOrganisation = useActiveOrganisation();
    const [updateTag] = useUpdateTagMutation();

    // If the tag is different from the tag in the previous match, than any accumulated positionOffset should be reset.
    // No occurrences have been replaced in this new tag yet.
    if (positionOffset !== 0 && matchEntityChanged) {
        setPositionOffset(0);

        return <Loading />;
    }

    const startPos = selectedMatch.start + positionOffset;
    const endPos = selectedMatch.end + positionOffset;
    const content = selectedMatch.content;
    const selection = content.substring(startPos, endPos);

    const replaceOccurrence = () => {
        setLoading(true);

        // Replace the value of the match with the selected tag.
        const newContent = replaceSelection(content, replacement, startPos, endPos);

        // Because we don't want to re-search each time we replace a value in the block,
        // we instead determine how much the position of the match has shifted, now that it's value has been replaced.
        // E.g. the match is 'ABC' which is replaced by '[Tag Alphabet]', the match has now become 11 chars longer
        // To account for this in the positioning, we add this offset when we select/replace a match.
        // (This only works because we select/replace the matches from left to right.)
        const lengthDiff = replacement.length - selection.length;

        // Resolve which part of the tag to update.
        let formData = {};
        if (selectedMatch.tagItemId) {
            formData.tagItems = _.cloneDeep(selectedTag.tagItems).map((tagItem) => {
                if (selectedMatch.tagItemId === tagItem.id) {
                    tagItem[selectedMatch.tagField] = newContent;
                }
                return tagItem;
            });
            formData.name = selectedTag.name;
        } else {
            _.set(formData, ['tagValue', selectedMatch.tagField], newContent);
        }
        updateTag({ organisationId: activeOrganisation, id: selectedTag.id, body: formData })
            .then(() => {
                let previousSelectedMatch = _.cloneDeep(selectedMatch);
                previousSelectedMatch.content = newContent;

                return selectMatch(selectedMatchIndex + 1, previousSelectedMatch);
            })
            .then(() => setPositionOffset(positionOffset + lengthDiff))
            .then(() => setLoading(false))
            .catch(() => {
                setError(true);
            });
    };

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <Modal.Body>
                    <p>
                        {t('document.navbar.tags.searchModal.result')} {selectedMatchIndex + 1}/{searchMatches.length}
                    </p>
                    <h6>{selectedMatch.name}</h6>

                    <div className="small mb-3">
                        <Highlighter
                            highlightClassName="highlight-search"
                            searchWords={[selection]}
                            autoEscape={true}
                            textToHighlight={content}
                        />
                    </div>

                    <Form.Group>
                        <Form.Label>{t('document.navbar.tags.searchModal.replacedBy')}:</Form.Label>
                        <Form.Control
                            id="replacement"
                            name="replacement"
                            placeholder={`${t('document.navbar.tags.searchModal.replacement')}...`}
                            value={replacement}
                            onChange={(e) => setReplacement(e.target.value)}
                            autoFocus={true}
                        />
                    </Form.Group>
                </Modal.Body>
            )}

            <Modal.Footer>
                <Button
                    variant="light"
                    disabled={loading}
                    onClick={() => selectMatch(selectedMatchIndex + 1, selectedMatch)}
                >
                    {t('document.navbar.tags.searchModal.btn.skip')}
                </Button>
                <Button
                    variant="primary"
                    disabled={loading || _.isEmpty(replacement)}
                    onClick={() => replaceOccurrence()}
                >
                    {t('document.navbar.tags.searchModal.btn.replace')}
                </Button>
            </Modal.Footer>
        </>
    );
}

function replaceSelection(content, replacement, startPos, endPos) {
    return content.substring(0, startPos) + replacement + content.substring(endPos);
}
