import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Field, Form as FForm, Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FormModal, ModalFooter } from '../global/FormModal';
import { useAddLabelMutation, useGetLabelFoldersQuery } from '../../features/metadata/metadata';
import { useTranslation } from 'react-i18next';

export default function NewLabelModal(props) {
    const { showModal = false } = props;
    const { activeOrganisation } = useSelector((state) => state.security);
    const { folderId = null } = useParams();
    const [addLabel] = useAddLabelMutation();
    const { t } = useTranslation('labels');

    const { folder } = useGetLabelFoldersQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                folder: data ? data.find((folder) => folder.id === folderId) : undefined,
            }),
            skip: folderId === null,
        }
    );

    const handleSubmit = (values, { setSubmitting }) => {
        addLabel(values).then(() => {
            setSubmitting(false);

            // Close modal
            props.handleClose();
        });
    };

    return (
        <FormModal show={showModal} onHide={props.handleClose} title={t('newLabelModal.newLabel')}>
            <Formik
                initialValues={{ name: '', organisationId: activeOrganisation, folder: folder ? folder['@id'] : null }}
                validationSchema={NewLabelSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched, isValid, dirty }) => (
                    <FForm autoComplete="off">
                        <Modal.Body>
                            <Field
                                as={Form.Control}
                                name="name"
                                placeholder={t('newLabelModal.labelName')}
                                isInvalid={errors.name && touched.name}
                                isValid={!errors.name && touched.name}
                            />
                        </Modal.Body>

                        <ModalFooter
                            isSubmitting={isSubmitting}
                            onHide={props.handleClose}
                            isValid={isValid}
                            dirty={dirty}
                        />
                    </FForm>
                )}
            </Formik>
        </FormModal>
    );
}

const NewLabelSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Required'),
});
