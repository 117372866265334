import { useGetUploadsQuery } from '../../../features/mediaLibrary/mediaApi';
import { useActiveOrganisation } from '../../../hooks/useActiveOrganisation';

const emptyArray = [];

export function useGetUploads() {
    const currentOrganisationId = useActiveOrganisation();

    const { uploads } = useGetUploadsQuery(currentOrganisationId, {
        selectFromResult: ({ data }) => ({
            uploads: data ?? emptyArray,
        }),
    });

    return uploads;
}
