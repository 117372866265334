import { Route } from 'react-router-dom';
import { ReactTitle } from '../components/ReactTitle';
import Index from '../pages/operations_list/Index';
import View from '../pages/operations_list/view/View';
import Settings from '../pages/operations_list/view/Settings';
import Reports from '../pages/operations_list/view/reports/Reports';
import OpTeamEntryDetails from '../pages/operations_list/view/OpTeamEntryDetails';
import OpAdminEntryDetails from '../pages/operations_list/view/OpAdminEntryDetails';
import Export from '../pages/operations_list/export/Export';
import RestrictedOpListContent from '../pages/operations_list/RestrictedOpListContent';
import Constants from '../config/Constants';

export const BASE_PATH = '/operations';
export const VIEW_PATH = `${BASE_PATH}/view/:id/:selectedPage?`;
export const SETTINGS_PATH = `${BASE_PATH}/settings/:id/:setting`;
export const REPORTS_PATH = `${BASE_PATH}/reports/:id`;
export const EXPORT_PATH = `${BASE_PATH}/export/:id`;
export const VIEW_LABEL_PATH = `${BASE_PATH}/entryLabel/:id/:opEntryLabelId/:selectedPage?`;
export const VIEW_ENTRY_PATH = `${BASE_PATH}/entry/:id/:opEntryId/:selectedPage?`;
import { useTranslation } from 'react-i18next';

const OperationsList = () => {
    const { t } = useTranslation('changelist');

    return (
        <>
            <ReactTitle title={t('breadcrumb')} />

            <Route path={BASE_PATH} exact strict render={() => <Index />} />

            <Route
                path={VIEW_PATH}
                exact
                strict
                render={({ match }) => (
                    <RestrictedOpListContent opListId={match.params.id}>
                        <View />
                    </RestrictedOpListContent>
                )}
            />

            <Route
                path={SETTINGS_PATH}
                exact
                strict
                render={({ match }) => (
                    <RestrictedOpListContent opListId={match.params.id}>
                        <Settings />
                    </RestrictedOpListContent>
                )}
            />

            <Route
                path={REPORTS_PATH}
                exact
                strict
                render={({ match }) => (
                    <RestrictedOpListContent opListId={match.params.id} roles={[Constants.opListUserRoles.teamManager]}>
                        <Reports />
                    </RestrictedOpListContent>
                )}
            />

            <Route
                path={EXPORT_PATH}
                exact
                strict
                render={({ match }) => (
                    <RestrictedOpListContent opListId={match.params.id} roles={[Constants.opListUserRoles.teamManager]}>
                        <Export />
                    </RestrictedOpListContent>
                )}
            />

            <Route
                path={VIEW_LABEL_PATH}
                exact
                strict
                render={({ match }) => (
                    <RestrictedOpListContent opListId={match.params.id}>
                        <OpTeamEntryDetails />
                    </RestrictedOpListContent>
                )}
            />

            <Route
                path={VIEW_ENTRY_PATH}
                exact
                strict
                render={({ match }) => (
                    <RestrictedOpListContent opListId={match.params.id}>
                        <OpAdminEntryDetails />
                    </RestrictedOpListContent>
                )}
            />
        </>
    );
};

export default OperationsList;
