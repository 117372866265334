import { useGetBaseVariant } from './useGetBaseVariant';
import { DocumentVariant } from '../../../models/documents.models';
import { findDeep } from 'deepdash-es/standalone';

export function useGetVariant(id: number): DocumentVariant | undefined {
    const baseVariant: DocumentVariant | undefined = useGetBaseVariant();

    if (!baseVariant) {
        return undefined;
    }

    const result = findDeep(
        [baseVariant],
        (value: DocumentVariant) => {
            return value.id === id;
        },
        {
            // @ts-ignore
            childrenPath: 'children',
        }
    );

    return result?.value;
}
