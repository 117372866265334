import {
    useGetReimbursementsQuery,
    useUpdateBatchReimbursementsMutation,
} from '../../../../features/reimbursements/reimbursements';
import { useGetCategory } from '../../../../features/reimbursements/reimbursementHooks';
import { BASE_PATH } from '../../../../scenes/Reimbursements';
import MainContentNav from '../../../Navbar';
import SubNav from '../SubNav';
import { Col, Container, Row } from 'react-bootstrap';
import { Category as CategoryModel, Reimbursement } from '../../../../models/reimbursement.models';
import Sidebar from './Sidebar';
import CategoryFilters from './CategoryFilters';
import { CategoryFilters as CategoryFilterParams } from '../../config/params';
import React, { useMemo } from 'react';
import HelperFunctions from '../../../global/HelperFunctions';
import LoadingSpinner from '../../../global/LoadingSpinner';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { setEditReimbursement } from '../../../../features/reimbursements/reimbursementSlice';
import ReimbursementModal from '../modals/ReimbursementModal';
import _ from 'lodash';
import { LabelEntity, LabelFolder } from '../../../../models/labels.models';
import { useGetLabelEntitiesQuery, useGetLabelFoldersQuery } from '../../../../features/metadata/metadata';
import CategoryReimbursement from './CategoryReimbursement';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import cx from 'classnames';
import { createSelector } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { useGetReimbursements } from '../../hooks/useGetReimbursements';

function Category({ categoryId }: { categoryId: number }) {
    const { category } = useGetCategory(categoryId);
    const { editReimbursement }: { editReimbursement: Reimbursement | undefined } = useAppSelector(
        (state) => state.reimbursements,
    );
    const dispatch = useAppDispatch();
    const { t } = useTranslation('reimbursements');

    return (
        <>
            <MainContentNav pages={[{ title: t('breadcrumb'), url: BASE_PATH }, { title: category?.name }]} />
            <SubNav />

            <div className="content-static-body has-subnav">
                <div className="d-flex align-items-stretch h-100">
                    <Sidebar />
                    <CategoryContent category={category} />
                </div>
            </div>

            <CategoryFilters category={category} />

            {editReimbursement && (
                <ReimbursementModal
                    reimbursement={editReimbursement}
                    handleClose={() => {
                        dispatch(setEditReimbursement(undefined));
                    }}
                />
            )}
        </>
    );
}

function CategoryContent({ category }: { category?: CategoryModel }) {
    return (
        <div className="flex-grow-1" style={{ marginTop: 82 }}>
            <div className="d-flex h-100">
                <div className="flex-grow-1 overflow-auto">
                    <div className="py-3 px-4 ">
                        <Container className="dr-container">
                            <Row>
                                <Col>
                                    <div className="data-table data-table-reimbursements">
                                        <div className=" mb-2 mt-4">
                                            {category && <CategoryReimbursements category={category} />}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </div>
    );
}

function CategoryReimbursements({ category }: { category: CategoryModel }) {
    const { activeOrganisation } = useAppSelector((state) => state.security);
    const [updateBatchReimbursements] = useUpdateBatchReimbursementsMutation();

    const filters: CategoryFilterParams = useAppSelector((state) => state.reimbursements.filters);

    const { labelEntities }: { labelEntities: LabelEntity[] } = useGetLabelEntitiesQuery(
        {
            entityType: 'reimbursement',
            organisationId: activeOrganisation,
        },
        {
            selectFromResult: ({ data }) => ({
                labelEntities: data ?? [],
            }),
        },
    );

    const { filterFolders }: { filterFolders: LabelFolder[] } = useGetLabelFoldersQuery(
        { organisationId: activeOrganisation },
        {
            selectFromResult: ({ data }) => ({
                filterFolders: data
                    ? data
                          .filter(
                              (folder: LabelFolder) =>
                                  folder.contentTypes.includes('reimbursements_filter') &&
                                  _.intersection(folder.labels, filters.labels).length > 0,
                          )
                          .map((folder: LabelFolder) => ({
                              ...folder,
                              labels: _.intersection(folder.labels, filters.labels),
                          }))
                    : [],
            }),
        },
    );

    const selectReimbursementsForCategory = useMemo(() => {
        const emptyArray: Reimbursement[] = [];

        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (data: Reimbursement[] | undefined) => data,
            (data: Reimbursement[] | undefined, categoryUri: string) => categoryUri,
            (data, categoryUri) =>
                data
                    ? data
                          .filter((reimbursement: Reimbursement) => reimbursement.category === categoryUri)
                          .sort(HelperFunctions.dynamicSort('sortOrder'))
                    : emptyArray,
        );
    }, []);

    const {
        reimbursements,
        isLoading,
        isUninitialized,
    }: { reimbursements: Reimbursement[]; isLoading: boolean; isUninitialized: boolean } = useGetReimbursements(
        category['@id'],
    );

    const filteredReimbursements = useMemo(() => {
        return reimbursements
            .filter((reimbursement: Reimbursement) => {
                // "Hide inactive" filter
                if (filters.hide_inactive) {
                    return reimbursement.enabled;
                }

                return true;
            })
            .filter((reimbursement: Reimbursement) => {
                // Label filter
                if (filterFolders.length === 0) {
                    return true;
                }

                // All the Label uris of this Reimbursement
                const assignedLabels = labelEntities
                    .filter((labelEntity) => labelEntity.entityId === _.toString(reimbursement.id))
                    .map((labelEntity) => labelEntity.label);

                // Check if all filterFolders include at least 1 label
                return filterFolders.every((folder) => {
                    return _.intersection(folder.labels, assignedLabels).length > 0;
                });
            })
            .filter((reimbursement: Reimbursement) => {
                // Search filter
                if (filters.search === '') {
                    return true;
                }

                const searchValueClean = filters.search.trim().toLowerCase();

                if (reimbursement.code.toLowerCase().includes(searchValueClean)) {
                    return true;
                }

                return reimbursement.name.toLowerCase().includes(searchValueClean);
            })
            .filter((reimbursement: Reimbursement) => {
                // Team filter
                if (filters.team === 'all') {
                    return true;
                }

                if (filters.team === 'none') {
                    return reimbursement.teams.length === 0;
                }

                return reimbursement.teams.includes(parseInt(filters.team));
            });
    }, [reimbursements, filters]);

    if (isLoading || isUninitialized) {
        return (
            <div className="py-4">
                <LoadingSpinner />
            </div>
        );
    }

    const onDragEnd = ({ destination, source }: DropResult) => {
        if (_.isNil(destination) || source.index === destination.index) {
            return;
        }

        // source.index and destination.index are from the filtered array, find the index in the main array
        const sourceItem = filteredReimbursements[source.index];
        const destinationItem = filteredReimbursements[destination.index];

        const originalSourceIndex = reimbursements.findIndex((reimbursement) => reimbursement.id === sourceItem.id);
        const originalDestinationIndex = reimbursements.findIndex(
            (reimbursement) => reimbursement.id === destinationItem.id,
        );

        const newReimbursements = HelperFunctions.arrayMove(
            reimbursements,
            originalSourceIndex,
            originalDestinationIndex,
        ).map((_reimbursement, index) => {
            // Reset sort order
            return {
                ..._reimbursement,
                sortOrder: index,
            };
        });

        updateBatchReimbursements({
            uri: category['@id'],
            reimbursements: newReimbursements.map((_reimbursement) => {
                return {
                    idAsInt: _reimbursement.id,
                    id: _reimbursement['@id'],
                    changes: {
                        sortOrder: _reimbursement.sortOrder,
                    },
                };
            }),
        });
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="main">
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className={cx({
                            'dragging-over': snapshot.isDraggingOver,
                        })}
                    >
                        <div className="accordion">
                            <div className="data-table-items">
                                {filteredReimbursements.map((reimbursement: Reimbursement, index) => (
                                    <CategoryReimbursement
                                        reimbursementId={reimbursement.id}
                                        categoryUri={category['@id']}
                                        categoryId={category.id}
                                        index={index}
                                        key={`reimbursement-${reimbursement.id}`}
                                    />
                                ))}
                            </div>
                        </div>

                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}

export const CategoryMemo = React.memo(Category);
